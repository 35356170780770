import { FETCH_PATIENT_DETAILS_SUCCESS, FETCH_PATIENT_DETAILS_PENDING, SEARCH_PATIENT } from "../action/patientDetailReport";

const initialState = {
  patientData: [],
  pending: false,
};

const patientDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PATIENT_DETAILS_SUCCESS:
      debugger;
      return {
        ...state,
        patientData: action.payload,
        pending: false,
      };
    case FETCH_PATIENT_DETAILS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case SEARCH_PATIENT:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default patientDetailReducer;

export const getPatientlList = (state) => state?.patientData;
export const getPatientListPending = (state) => state.pending;
