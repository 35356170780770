import RestDataSource from "./restdatasource";
import { loginService } from "./loginService";
import { Global_var } from "../global/global_var";
import {
  error,
  success,
  warning,
  warningNotification,
  errorNotification,
  successNotification,
} from "../components/PP_Notification/PP_Notification";
export const contactusService = {
  addContactusData,
};

function addContactusData(contactInfo, userId, fn, fnError) {
  // debugger
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-token", res.responseObject);
      //     debugger
      //     sessionStorage.setItem("jwt-token", res.responseObject);
      //     debugger
      // var url = Global_var.URL_ADD_CONTACT_US;
      var url = Global_var.BASEURL + Global_var.URL_ADD_CONTACT_US;
      return new RestDataSource(url, userId, fn).Store(contactInfo, (res) => {
        // debugger
        if (res != null) {
          if (res["data"].status === "fail") {
            if (res["data"].reasonText === "Invalid Token") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/login");
            } else if (res["data"].reasonCode === "SM-02") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/login");
            } else if (res["data"].reasonCode === "SM-10") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/login");
            } else {
              warning("Something went wrong!" + res["data"].reasonText, warningNotification);
            }
          } else {
            fn(res);
          }
        }
      });
    }
  }, fnError);
}
