import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getIn } from "formik";
import Med1Logo from "../../../assets/images/Med1Logo.png";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormControlLabel,
  MenuItem,
  Select,
  FormLabel,
  TextField,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";

import { Grid } from "@material-ui/core";
import ImageTooltip from "../../../components/ImageTooltip";
import CardPaymentTooltip from "../../../assets/images/CardPaymentTooltip.jpg";
import { countryData, allStatesData, cityData } from "../../../util/StatesData";
import InputAdornment from "@material-ui/core/InputAdornment";
import amexImg from "../../../assets/images/amex.jpg";
import masterCardImg from "../../../assets/images/masterCard.png";
import visaCardImg from "../../../assets/images/visa.png";
import { validateCard } from "../../../util/ValidateCard";
import RocketIcon from "../../../assets/images/quick-pay-rocket-icon.svg";
import disabledCard from "../../../assets/images/disabled-card.png";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  creditcard: {
    textAlign: "left !important",
  },
  inputfield: {
    textAlign: "left !important",
    fontSize: 14,
    color: "#536777",
    fontWeight: "normal",
    paddingTop: 10,
  },
}));

const options = [
  { key: "Master Card", value: "Master Card" },
  { key: "Visa", value: "Visa" },
  { key: "American Card", value: "American Card" },
];

const intialvalues = {
  cardType: "Master Card",
  expiryDate: null,
  cvv: "",
  cardNumber: "",
  nameOnCard: "",
  futureDate: null,
  agree: false,
  country: "usa",
  StateCounty: "IN",
  city: "crystalLake",
  zipCode: "",
  agreeToterms: true,
  agreeToEmailUsage: true,
  billingAddress: "1193 MALVINA AVE",
};

const schema = Yup.object().shape({
  cardType: Yup.string().trim().required("Card Type is required"),
});

const CreditCard = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [cardImg, setCardImg] = useState("");

  const payNow = history?.location?.state?.payNow;

  const onSubmit = async (values, onSubmitProps) => {
    history.push(payNow ? "/successfull-payment" : "/payment-schedule");
  };

  const handleCancel = () => {
    history.push("/bill-details");
  };

  return (
    <Formik initialValues={intialvalues} onSubmit={onSubmit} validationSchema={schema}>
      {(props) => {
        const { values, touched, error, handleChange, handleBlur, errors, setFieldValue } = props;
        return (
          <Form id="login-form" className="input-field" noValidate="noValidate">
            <div className={classes.inputfield}>
              <div className="row">
                {!payNow && (
                  <div className="col-md-12 date-schedule">
                    <div className="form-group input-field">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid container className="date-picker">
                          <label htmlFor="futurePaymentDate" className="form-label w-100 required">
                            Future Payment Date
                          </label>
                          <DatePicker
                            disableFuture
                            name="futurePaymentDate"
                            autoOk
                            value={values.futurePaymentDate}
                            onChange={(val) => setFieldValue("futurePaymentDate", val)}
                            renderInput={(params) => <TextField {...params} helperText={null} margin="dense" />}
                          />
                        </Grid>
                      </LocalizationProvider>
                    </div>
                  </div>
                )}
                <div className="form-group col-md-12 mt-3">
                  <label htmlFor="nameOnCard" className="required">
                    Name on Card
                  </label>
                  <div className="row">
                    <div className="col-12 col-sm-4 mb-3">
                      <OutlinedInput
                        className="form-control"
                        id="nameOnCardFirstName"
                        placeholder="Enter First Name"
                        onChange={handleChange}
                        value={values["nameOnCardFirstName"]}
                        aria-label="text-field"
                        type="text"
                        error={Boolean(getIn(touched, "nameOnCardFirstName") && getIn(errors, "nameOnCardFirstName"))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        required="true"></OutlinedInput>
                    </div>
                    <div className="col-12 col-sm-4 mb-3">
                      <OutlinedInput
                        className="form-control"
                        id="nameOnCardMiddleName"
                        placeholder="Enter Middle Name"
                        onChange={handleChange}
                        value={values["nameOnCardMiddleName"]}
                        aria-label="text-field"
                        type="text"
                        error={Boolean(getIn(touched, "nameOnCardMiddleName") && getIn(errors, "nameOnCardMiddleName"))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        required="true"></OutlinedInput>
                    </div>
                    <div className="col-12 col-sm-4 mb-3">
                      <OutlinedInput
                        className="form-control"
                        id="nameOnCardLastName"
                        placeholder="Enter Last Name"
                        onChange={handleChange}
                        value={values["nameOnCardLastName"]}
                        aria-label="text-field"
                        type="text"
                        error={Boolean(getIn(touched, "nameOnCardLastName") && getIn(errors, "nameOnCardLastName"))}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        required="true"></OutlinedInput>
                    </div>
                 
                  </div>
                </div>

                <div className="form-group col-md-12">
                  <label htmlFor="cardNumber" className="required">
                    Card Number
                  </label>
                  <OutlinedInput
                    className="form-control"
                    id="cardNumber"
                    name="cardNumber"
                    placeholder="5333 3902 4532 0000"
                    value={values["cardNumber"]}
                    aria-label="text-field"
                    fullWidth
                    type="cardNumber"
                    onChange={(e) => {
                      handleChange(e);
                      validateCard(e.target.name, e.target.value, setCardImg);
                    }}
                    error={Boolean(getIn(touched, "cardNumber") && getIn(errors, "cardNumber"))}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <img
                          className="mr-2"
                          src={
                            cardImg === "visa"
                              ? visaCardImg
                              : cardImg === "mastercard"
                              ? masterCardImg
                              : cardImg === "amex"
                              ? amexImg
                              : disabledCard
                          }
                          width="50"
                          height="auto"
                          alt="Card Type"
                        />
                      </InputAdornment>
                    }
                    required="true"></OutlinedInput>
                  
                </div>
                <div className=" col-lg-6 col-md-12 form-group">
                  <FormControl variant="outlined" className="w-100" fullWidth>
                    <label htmlFor="creditCardType" className="form-label required">
                      Credit Card Type
                    </label>
                    <Select
                      fullWidth
                      name="cardType"
                      className="form-select form-control"
                      onChange={handleChange("cardType")}
                      value={values["cardType"]}
                      error={Boolean(getIn(touched, "cardType") && getIn(errors, "cardType"))}>
                      <MenuItem value="none" key="none" disabled>
                        Select Card Type
                      </MenuItem>
                      {options.map((option) => {
                        return (
                          <MenuItem value={option.value} key={option.key}>
                            {option.value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  
                  </FormControl>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Grid container className="date-picker">
                        <label htmlFor="expiryDate" className="form-label w-100 required">
                          Expiry Date
                        </label>
                        <DatePicker
                          disableFuture
                          name="expiryDate"
                          autoOk
                          value={values.expiryDate}
                          onChange={(val) => setFieldValue("expiryDate", val)}
                          renderInput={(params) => <TextField {...params} helperText={null} margin="dense" />}
                        />
                      </Grid>
                    </LocalizationProvider>
                  
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label htmlFor="cvv" className="mr-4 required">
                      CVV
                    </label>
                    <ImageTooltip img={CardPaymentTooltip} width="300px" alt="Credit Card Payment" placement="right">
                      <i className="fas fa-question-circle circle-icon" style={{ cursor: "pointer" }}></i>
                    </ImageTooltip>
                    <OutlinedInput
                      className="form-control"
                      id="cvv"
                      name="cvv"
                      placeholder="234"
                      onChange={handleChange}
                      value={values["cvv"]}
                      aria-label="text-field"
                      fullWidth
                      type="cvv"
                      error={Boolean(getIn(touched, "cvv") && getIn(errors, "cvv"))}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required="true"
                      inputProps={{
                        maxLength: 3,
                      }}></OutlinedInput>
                   
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label required htmlFor="Email Address" className="required">
                      Email Address
                    </label>
                    <OutlinedInput
                      name="emailAddress"
                      className="form-control"
                      value={values.emailAddress}
                      onChange={handleChange}
                      placeholder="Enter Your Email Address"
                      aria-label="text-field"
                      fullWidth
                      required></OutlinedInput>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label required htmlFor="Phone Number" className="required">
                      Phone Number
                    </label>
                    <div className="phone-number">
                      <select className="phone-number-select">
                        <option selected value="+1">
                          +1
                        </option>
                      </select>
                      <OutlinedInput
                        name="phoneNumber"
                        className="form-control"
                        value={values.phoneNumber}
                        onChange={handleChange}
                        placeholder="Enter Your Phone Number"
                        aria-label="text-field"
                        fullWidth
                        required></OutlinedInput>
                    </div>
                  </div>
                </div>
                <div className="form-group col-md-12">
                  <label required htmlFor="Billing Address" className="required">
                    Billing Address
                  </label>
                  <OutlinedInput
                    name="billingAddress"
                    className="form-control"
                    value={values.billingAddress}
                    onChange={handleChange}
                    placeholder="Enter Your Billing Address"
                    aria-label="text-field"
                    fullWidth
                    required></OutlinedInput>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <FormLabel for="exampleFormControlInput1" className="form-label required">
                        Country
                      </FormLabel>
                      <Select
                        fullWidth
                        name="country"
                        value={values.country}
                        onChange={handleChange}
                        className="form-select form-control">
                        <MenuItem value="none" disabled>
                          Select Country
                        </MenuItem>
                        {countryData.map((option) => {
                          return (
                            <MenuItem value={option.key} key={option.key}>
                              {option.value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <FormLabel for="exampleFormControlInput1" className="form-label required">
                        State/County
                      </FormLabel>
                      <Select
                        fullWidth
                        name="StateCounty"
                        value={values.StateCounty}
                        onChange={handleChange}
                        className="form-select form-control">
                        <MenuItem value="none" key="none" disabled>
                          Select State/County
                        </MenuItem>
                        {allStatesData.map((option) => {
                          return (
                            <MenuItem value={option.key} key={option.key}>
                              {option.value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <FormLabel for="exampleFormControlInput1" className="form-label required">
                        City
                      </FormLabel>
                      <Select
                        onChange={handleChange}
                        fullWidth
                        name="city"
                        value={values.city}
                        className="form-select form-control">
                        <MenuItem value="none" key="none" disabled>
                          Select City
                        </MenuItem>
                        {cityData.map((option) => {
                          return (
                            <MenuItem value={option.key} key={option.key}>
                              {option.value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <FormLabel for="exampleInputPassword1" className="required">
                      ZIP Code
                    </FormLabel>
                    <OutlinedInput
                      className="form-control"
                      name="zipCode"
                      value={values.zipCode}
                      onBlur={handleBlur}
                      onChange={(event) => {
                        const zip = event.target.value;
                        setFieldValue(
                          (values.zipCode =
                            zip.length > 5 && !zip.includes("-")
                              ? zip.substring(0, 5).concat("-") + zip.substring(5, zip.length)
                              : zip),
                        );
                      }}
                      inputProps={{
                        maxLength: 10,
                      }}
                      placeholder="Enter ZIP Code"
                      aria-label="text-field"
                      fullWidth
                      required></OutlinedInput>
                  </div>
                </div>
              </div>
            </div>
         
            <div className="agree-terms mt-2">
              <div className="col-md-12 check-FormLabel">
                <FormControl fullWidth component="fieldset" margin="normal">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="agreeToterms"
                        checked={values.agreeToterms}
                        onChange={handleChange}
                        value={values.agreeToterms}
                        color="primary"
                      />
                    }
                    label={
                      <span>
                        By checking this box,you signify you have reviewed,understand,meet and agree to the{" "}
                        <a href="/">Online Payment Terms and Conditions</a>
                      </span>
                    }
                  />
                </FormControl>
              </div>
              <div className="col-md-12 check-FormLabel">
                <FormControl fullWidth component="fieldset" margin="normal">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="agreeToEmailUsage"
                        value={values.agreeToEmailUsage}
                        checked={values.agreeToEmailUsage}
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label={
                      <span>
                        By checking this box,you agree that this is your personal email address and you authorise us to send
                        you emails,call your phone, or contact you via an automated dialer,leave voicemails, and/or send text
                        messages if necessary.
                      </span>
                    }
                  />
                </FormControl>
              </div>
            </div>
            <div className="proceed-cancel-buttons mb-4">
              <button onClick={handleCancel} className="secondary-button">
                Cancel
              </button>

              <button type="submit" className="primary-button">
                {payNow ? "Proceed to Pay" : "Schedule Payment"}
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreditCard;
