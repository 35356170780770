import { Global_var } from "../global/admin_global_var";
import AdminRestDataSource from "./adminrestdatasource";
import AdminPdfRestDataSource from "./adminpdfrestdatasource";
import { loginService } from "../services/loginService";
import { loginServicepdf } from "../admin_services/loginServicepdf";
import {
  error,
  success,
  warning,
  warningNotification,
  errorNotification,
  successNotification,
} from "../components/PP_Notification/PP_Notification";
export const pdfservices = {
  checkstatus,
  downloadpdf,
};

function checkstatus(username, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_PDF_DATA;
  var userId = "1234";

  return new AdminPdfRestDataSource(url, userId).GetData((res) => {
    "roleinfo";
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jti-tokencore", res.headers.token);
        }
        fn(res);
      }
    }
  });
}

function downloadpdf(roleInfo, userId, fn, fnError) {
  loginServicepdf.pdfTokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_PUBLIC_URL_DATA;
      return new AdminPdfRestDataSource(url, userId, fn).Store(roleInfo, (res) => {
        if (res != null) {
          if (res["data"].status === "fail") {
            if (res["data"].reasonText === "Invalid Token") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/login");
            } else if (res["data"].reasonCode === "SM-02") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/login");
            } else if (res["data"].reasonCode === "SM-10") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/login");
            } else {
              warning("Something went wrong!" + res["data"].reasonText, warningNotification);
            }
          } else {
            fn(res);
          }
        }
      });
    }
  }, fnError);
}
