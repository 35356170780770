import Axios from "axios";

export default class RestDataSource {
  constructor(base_url, userId, errorCallback) {
    const queryString = window.location.search;
    var businessidurl = "2";

    const urlParameter = new URLSearchParams(queryString);
    var bussid = urlParameter.get("buid");
    if (queryString && bussid) {
      sessionStorage.setItem("buid", bussid);
    }

    Axios.defaults.headers.common["token"] =
      sessionStorage.getItem("jwt-token") === null
        ? sessionStorage.getItem("jwt-token")
        : sessionStorage.getItem("jwt-token");

    Axios.defaults.headers.common["ApplicationId"] = "11101";
    Axios.defaults.headers.common["BuId"] =
      sessionStorage.getItem("buid") === null ? sessionStorage.getItem("buid") : sessionStorage.getItem("buid");
    Axios.defaults.headers.common["SubBuId"] = "0";
    Axios.defaults.headers.common["Environment"] = "dev";
    Axios.defaults.headers.common["issuer"] = "epi";
    Axios.defaults.headers.common["Content-Type"] = "application/json";

    Axios.defaults.headers.common["userId"] = "1234";
    Axios.defaults.headers.common["userlogin"] = "mayur@gmail.com";

    this.BASE_URL = base_url;
    this.handleError = errorCallback;
  }

  async GetData(callback) {
    this.SendRequest("get", this.BASE_URL, callback);
  }
  async GetOneByParam(id, callback) {
    this.SendRequest("get", `${this.BASE_URL}?${id}`, callback);
  }
  async GetOne(data, callback) {
    this.SendRequest("get", this.BASE_URL, callback, data);
  }

  async Store(data, callback) {
    this.SendRequest("post", this.BASE_URL, callback, data);
  }
  async Update(data, callback) {
    this.SendRequest("put", this.BASE_URL, callback, data);
  }
  async Delete(data, callback) {
    this.SendRequest("delete", this.BASE_URL, callback, data);
  }
  async SendRequest(method, url, callback, data) {
    try {
      let response = await Axios.request({
        method: method,
        url: url,
        data: data,
      });
      callback(response);
    } catch (err) {
      console.log(err);
    }
  }
}
