import MaterialTable from "material-table";
import React, { useState, useEffect } from "react";

import { useHistory, useLocation } from "react-router";
import SpinnerPage from "../../../../Common/PP_loader";
// import { PayButton } from "assets/MaterialControl";
// import TrackingUSer from "containers/TrackingUser";
import moment from "moment";
import { tableIcons } from "../../../../components/TableIcons";
import { Search } from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import PatchedPagination from "../../../../Common/TablePagination";
import { updateCampignViewPayment } from "../../../../util/purlFunctions";
import UpdateTrackingPage from "../../../../containers/UpdateTrackingPage";

const PayPlanOptionsSuccess = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const page = location?.state?.page;
  const frequency = location?.state?.frequencyType;
  const paymentTransactionData = location?.state?.data.body;
  const seedAccNum = location?.state?.seedAccNum;

  const { paymentOrderEntity, recurringPaymentEntity } = paymentTransactionData;

  const columns = [
    {
      title: "Account#",
      field: "seedAccNum",
    },
    {
      title: "Order Id",
      field: "paymentOrderId",
    },
    {
      title: "Frequency",
      field: "recurringPaymentType",
    },
    {
      title: "Date of Payment",
      field: "paymentDate",
    },
    {
      title: "Payment Amount",
      field: "recurringAmmount",
      cellStyle: { padding: "0px 50px", textAlign: "right" },
      headerStyle: { textAlign: "right" },
    },
  ];

  const data =
    paymentTransactionData &&
    paymentOrderEntity &&
    paymentOrderEntity.map((order, i) => {
      const { paymentDate, paymentOrderAmount, paymentOrderId } = order;
      const { recurringPaymentType } = recurringPaymentEntity;
      return {
        seedAccNum: seedAccNum,
        paymentOrderId: paymentOrderId,
        recurringPaymentType: recurringPaymentType,
        paymentDate: moment(paymentDate).format("MM/DD/YYYY"),
        recurringAmmount:
          "$ " +
          paymentOrderAmount.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          }),
      };
    });
  useEffect(() => {
    document.title = page === "quickPay" ? "MED-1 Quick Pay - Pay Plan Options" : "MED-1 Secure Login - Pay Plan Options";
    setLoader(true);
    setTimeout(() => setLoader(false), 2000);
  }, []);

  useEffect(() => {
    const campaignCode = sessionStorage.getItem("campaignCode");
    let passValuesUCVPC = {
      campaignCode: campaignCode || "",
      campaignViewId:
        sessionStorage.getItem("campaignViewId") && sessionStorage.getItem("campaignViewId") !== "undefined"
          ? JSON.parse(sessionStorage.getItem("campaignViewId"))
          : "", //mandatory
      medAccountNumber: sessionStorage.getItem("payerinfo")
        ? JSON.parse(sessionStorage.getItem("payerinfo"))?.accountReferenceNumber
        : "", // Non-Mandatory
      paidAmount: 0,
      partyId: sessionStorage.getItem("Userpartyid")
        ? JSON.parse(sessionStorage.getItem("Userpartyid"))
        : JSON.parse(sessionStorage.getItem("verifypayer"))
        ? JSON.parse(sessionStorage.getItem("verifypayer")).partyId
        : null, //mandatory
      payPlanAdded: "Y", //mandatory
      paymentComplete: "N", //mandatory
      scheduledAmount: sessionStorage.getItem("amounttopay") ? JSON.parse(sessionStorage.getItem("amounttopay")) : null, //mandatory,    //mandatory
    };
    // recurringPaymentEntity?.recurringAmmount
    updateCampignViewPayment(passValuesUCVPC, (res) => {
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentTransactionData]);

  return (
    <React.Fragment>
      <div className="transaction-card">
        <section
          className={
            sessionStorage.getItem("currentUserRole") === "Customer"
              ? `main-container-card main-container-sm`
              : `main-container-card`
          }>
          <div role="heading" aria-level="1" className="heading-style mb-3">
            <h2 className="content-title heading-style mb-0">Payment Arrangement</h2>
          </div>
          <div>
            <div>
              <Grid className="payplan-table">
                <MaterialTable
                  title={<h6 className="heading-style my-2">Payment Transactions</h6>}
                  columns={columns}
                  data={data}
                  icons={tableIcons}
                  options={{ search: false, emptyRowsWhenPaging: false }}
                  components={{
                    Pagination: PatchedPagination,
                  }}
                />
              </Grid>
            </div>

            <div className="mt-3 col-12 text-right">
              <button
                type="button"
                id="submitButton"
                className="primary-button themeing-buttons"
                onClick={() =>
                  sessionStorage.getItem("currentUserRole") === "Admin"
                    ? history.push("/pa/billing")
                    : sessionStorage.getItem("backToSecureLogin") === "CustomerSignIn"
                    ? history.push("/home/billing")
                    : history.push("/payment-outstanding")
                }>
                Back
              </button>
            </div>
            {loader && <SpinnerPage />}
          </div>
        </section>
      </div>
      {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
    </React.Fragment>
  );
};

export default PayPlanOptionsSuccess;
