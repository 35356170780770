import { whiteLableService } from "../core_services/whiteLableService";
export const WHITE_LABLE_PENDING = "WHITE_LABLE_PENDING";
export const WHITE_LABLE_SUCCESS = "WHITE_LABLE_SUCCESS";
export const WHITE_LABLE_FAILURE = "WHITE_LABLE_FAILURE";

export const GET_CONTENT_PENDING = "GET_CONTENT_PENDING";
export const GET_CONTENT_SUCCESS = "GET_CONTENT_SUCCESS";
export const GET_CONTENT_FAILURE = "GET_CONTENT_FAILURE";

export function whiteLablePending() {
  return {
    type: WHITE_LABLE_PENDING,
  };
}

export function fetchWhiteLabel(whiteLableData, fn) {
  return (dispatch) => {
    dispatch(whiteLablePending());
    whiteLableService.getWhiteLable(whiteLableData, (res) => {
      dispatch(whiteLableSucess(res.data.responseListObject));
      fn(res.data);
    });
  };
}

export function whiteLableSucess(whiteLableData) {
  return {
    type: WHITE_LABLE_SUCCESS,
    payload: whiteLableData,
  };
}

export function whiteLableError(error) {
  return {
    type: WHITE_LABLE_FAILURE,
    error: error,
  };
}

export function getContentPending() {
  return {
    type: GET_CONTENT_PENDING,
  };
}

export function fetchGetContent(getContentData, fn) {
  return (dispatch) => {
    dispatch(whiteLablePending());
    whiteLableService.getContentData(getContentData, (res) => {
      dispatch(getContentSucess(res.data.responseObject));
      fn(res.data);
    });
  };
}

export function getContentSucess(getContentData) {
  return {
    type: GET_CONTENT_SUCCESS,
    payload: getContentData,
  };
}

export function getContentError(error) {
  return {
    type: GET_CONTENT_FAILURE,
    error: error,
  };
}
