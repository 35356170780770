import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_SUCCESS_MESSAGE,
  API_STATUS_CODE,
  HIDE_ERROR_MESSAGE,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  initialURL: "/",
  error: "",
  loading: false,
  message: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return { ...state, error: "", loading: true };
    }
    case FETCH_SUCCESS: {
      return { ...state, error: "", loading: false };
    }
    case FETCH_ERROR: {
      return { ...state, loading: false, error: action.payload };
    }
    case HIDE_ERROR_MESSAGE: {
      return { ...state, loading: false, error: "", message: "", statusCode: null };
    }
    case SHOW_SUCCESS_MESSAGE: {
      return {
        ...state,
        error: "",
        message: action.payload,
        loading: false,
      };
    }

    case API_STATUS_CODE: {
      return { ...state, loading: false, statusCode: action.payload };
    }
    default:
      return state;
  }
};
