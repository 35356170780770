import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { getIn } from "formik";

const useStyles = makeStyles((theme) => ({
  signatureCard: {
    lineHeight: "0",
    textAlign: "center",
    padding: "55px 70px",
    minWidth: "57%",
    height: "100px",
    borderRadius: "10px",
    border: "2px solid #c3c3c3",
    [theme.breakpoints.down("sm")]: {
      minWidth: "85%",
      height: "80px",
      padding: "35px 20px",
    },
  },
  sign: {
    marginBottom: "0px !important",
    color: "#222222",
    fontStyle: "italic",
    fontSize: "32px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },
  form: {
    marginBottom: "25px",
  },
}));
function GenerateSign({ stateUpdate, setFieldValue, values, touched, handleChange, errors, updateUserSignature }) {
  const classes = useStyles();
  return (
    <div className="signatureModal">
      <div className="form-row">
        <div className={`col-sm-12  ${classes.form}`}>
          <TextField
            label="Name"
            type="text"
            name="italicSign"
            id="italicSign"
            autoComplete="off"
            variant="standard"
            onChange={(e) => {
              handleChange(e);
              stateUpdate();
              updateUserSignature({ italicSign: e.target.value }, values);
              if (e.target.value === "") {
                values.isItalicSign = false;
              } else values.isItalicSign = true;
            }}
            value={values.italicSign}
            fullWidth
            aria-label="text-field"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 15,
            }}
          />
        </div>
      </div>
      <div className="form-row px-2">
        <div className={`${classes.signatureCard} col-12 createbox-cp`}>
          <p className={`${classes.sign} ${classes.DancingScript}`}>{values.italicSign}</p>
        </div>
      </div>
    </div>
  );
}

export default GenerateSign;
