import {
  FETCH_OUTSTANDING_PAYER_DETAILS_SUCCESS,
  FETCH_OUTSTANDING_PAYER_DETAILS_PENDING,
  ADD_OUTSTANDING_PAYER,
  // UPDATE_OUTSTANDING_PAYER
} from "../action/pp_PayerOutstandingAction";

const initialState = {
  payerOutstandingData: [],
  pending: false,
};

const payerOutstandingReducer = (state = initialState, action) => {
  switch (action.type) {
    // fecth  payer List
    case FETCH_OUTSTANDING_PAYER_DETAILS_SUCCESS:
      debugger;
      return {
        ...state,
        payerOutstandingData: action.payload,
        pending: false,
      };
    case FETCH_OUTSTANDING_PAYER_DETAILS_PENDING:
      debugger;
      return {
        ...state,
        pending: true,
      };
    // case UPDATE_OUTSTANDING_PAYER:
    //   debugger
    // console.log("IDENTIFY_PAYER_DATA reducer")
    // return {
    //   ...state,
    //   data: action.payload,
    // };
    case ADD_OUTSTANDING_PAYER:
      debugger;
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default payerOutstandingReducer;

export const getOutstandingData = (state) => console.log(state, "STATE IN NEW BILLING");
export const getOutstandingDataPending = (state) => state?.pending;
