import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import * as Yup from "yup";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExapandMoreIcon from "@material-ui/icons/ExpandMore";
import visacard from "../../../assets/images/visacard.jpg";
import mastercard from "../../../assets/images/mastercard.jpg";
import creditcards from "../../../assets/images/credit-cards.png";
import Typography from "@material-ui/core/Typography";
import PaymentMethodSelect from "../Billing/PaymentMethodSelect";
import PaymentDetailsAccordion from "./PaymentDetailsAccordion";

const intialvalues = {
  username: "",
  password: "",
};

const schema = Yup.object().shape({
  username: Yup.string().trim().required("Username required"),
  password: Yup.string().trim().required("Password is required"),
});

const PaymentDetails = (props) => {
  const history = useHistory();

  const [expanded, setExpanded] = React.useState(true);
  const [billPaymentMethod, setBillPaymentMethod] = useState("");

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const onSubmit = async (values, onSubmitProps) => {
    history.push("/home");
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-md-12">
          <Accordion className="accordion-section" expanded={expanded} onChange={handleChange}>
            <AccordionSummary expandIcon={<ExapandMoreIcon />} className="accordion-header">
              <Typography variant="h6" aria-level="3">
                Payment Methods
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <div className="row">
                <div className="col-md-12">
                  <div className="payment-details">
                    <div className="heading1">Your saved credit and debit cards</div>
                    <div className="heading2">Expires</div>
                  </div>
                  <PaymentDetailsAccordion />
                </div>
              </div>
            </AccordionDetails>
            <div className="payment-methods">
              <h5 aria-level="4">Add a New Payment Method</h5>
              <div className="credit-debit-section">
                <div className="row">
                  <div className="col-lg-4 col-md-5">
                    <div className="form-group mt-2 mb-4">
                      <PaymentMethodSelect setBillPaymentMethod={setBillPaymentMethod} className=" ml-0" />
                      <button
                        onClick={() =>
                          sessionStorage.getItem("currentUserRole") === "Admin" && billPaymentMethod === "eCheck"
                            ? history.push("/pa/add-echeck")
                            : sessionStorage?.getItem("currentUserRole") === "Admin"
                            ? history.push("/pa/add-card")
                            : billPaymentMethod === "eCheck"
                            ? history.push("/home/add-echeck")
                            : history.push("/home/add-card")
                        }
                        className="primary-button my-2">
                        Add
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 text-right">
                    <img src={creditcards} alt="creditcards" />
                  </div>
                </div>
              </div>
            </div>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
