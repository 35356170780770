import React from "react";
import { Tooltip, withStyles } from "@material-ui/core";

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    fontSize: 11,
  },
}))(Tooltip);

const ImageTooltip = ({ children, img, width, alt = "", placement = "right" }) => {
  return (
    <>
      <LightTooltip
        title={<img src={img} className={width === "150px" ? "cvv-tooltip" : "image-tooltip"} alt={alt} />}
        placement={placement}
        arrow>
        {children}
      </LightTooltip>
    </>
  );
};

export default ImageTooltip;

function BasicTooltip({ title, children, placement }) {
  return (
    <Tooltip title={title} placement={placement}>
      {children}
    </Tooltip>
  );
}

export { BasicTooltip };
