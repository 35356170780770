/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import Med1Logo from "../../../assets/images/Med1Logo.svg";
import Med1AccNumberTooltip from "../../../assets/images/medAccountNumberTooltip.jpg";
import Captcha from "../../../components/Captcha";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import { getIn } from "formik";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { Translation } from "react-i18next";
import { Grid, IconButton, FormHelperText, TextField } from "@material-ui/core";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import ImageTooltip from "../../../components/ImageTooltip";
import { OutstandingPayerService } from "../../../services/payerOutstandingService";
import RocketIcon from "../../../assets/images/quick-pay-rocket-icon.svg";
import { useDispatch } from "react-redux";
import SpinnerPage from "../../../Common/PP_loader";
import { quickPayLogin } from "../../../redux/actions/loginService";
import { requestInfo } from "../../../services1/REST";
import { requestInfo_PP } from "../../../Common/functions";
import SignUpForm from "../../Auth/SignUp";
import moment from "moment";
import endOfDay from "date-fns/endOfDay";
import { ToastContainer, handleWarning } from "../../../components/Notifications/Toast";
import { ToastMsg } from "../../../locales/en/toastmessages";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";
import ClearIcon from "@material-ui/icons/Clear";
import { identifyPayerService } from "../../../services/identifyPayerService";

const intialvalues = {
  med1AccNum: "",
  ssn: "",
  patientDOB: null,
  email: "",
  phone: "",
  recaptcha: "",
};

const validate = (values) => {
  const errors = {};

  if (!values.recaptcha) {
    errors.recaptcha = "ReCaptcha is Required";
  }
  return errors;
};

const LandingPage = ({ match }) => {
  const history = useHistory();
  const path = match?.path?.toLowerCase();
  const dispatch = useDispatch();
  const [toggleForm, setToggleForm] = useState(false);
  const [showMed1AccNumber, setShowMed1AccNumber] = useState(true);
  const [showSocialSecurityNumber, setshowSocialSecurityNumber] = useState(false);
  const [showssn, setShowssn] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showdob, setShowdob] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [loader, setLoader] = useState(false);
  const onSubmit = async (values, onSubmitProps) => {
    sessionStorage.setItem("values", JSON.stringify(values));
    setLoader(true);
    const { med1AccNum, ssn, patientDOB, email, phone } = values;
    const payerBillSearch = {
      accountReferenceNumber: med1AccNum,
      uniqueIdentityId: ssn,
      dob: patientDOB,
      contactEmail: email,
      contactNumber: phone,
    };

    const PassValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      payerBillSearch,
    };
    const UserId = "1212";
    identifyPayerService.addIdentifyPayer(
      PassValues,
      UserId,
      (res) => {
        if (res["data"].responseInfo.status === "SUCCESSFUL") {
          setLoader(false);
          setToggleForm(true);
          if (path === "/landing-page") {
            history.push("/bill-details");
          }
        } else if (res["data"].responseInfo.status === "FAILED") {
          setLoader(false);
          handleWarning("Please enter valid details!");
        } else {
          setLoader(false);
          handleWarning("Please enter valid details!");
        }
      },
      (error) => {
        console.log(error);
        setLoader(false);
      },
    );
  };

  const handleMed1AccountNumber = () => setShowMed1AccNumber(!showMed1AccNumber);
  const handleSocialSecurityNumber = () => setshowSocialSecurityNumber(!showSocialSecurityNumber);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    localStorage.clear();
    const queryString = window.location.search;
    const urlParameter = new URLSearchParams(queryString);

    var businessid = "2";
    var bussid = urlParameter.get("buid");
    if (queryString && bussid) {
      sessionStorage.setItem("buid", bussid);
    } else {
      sessionStorage.setItem("buid", businessid);
    }
  }, []);

  const med1ApiCall = (value) => {
    setLoader(true);
    var PassValues1 = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      payerBillSearch: {
        accountReferenceNumber: value,
      },
    };
    const UserId = "1212";
    OutstandingPayerService.PP_Get_Patient_Details(
      PassValues1,
      UserId,
      (res) => {
        if (res["data"].responseInfo.status === "SUCCESSFUL") {
          if (res["data"].patientDetails[0].contactNo === true) {
            setShowContact(true);
            setLoader(false);
          }
          if (res["data"].patientDetails[0].contactEmail === true) {
            setShowEmail(true);
            setLoader(false);
          }
          if (res["data"].patientDetails[0].dob === true) {
            setShowdob(true);
            setLoader(false);
          }
          if (res["data"].patientDetails[0].uniqueIdentifierId === true) {
            setShowssn(true);
            setLoader(false);
          }
        } else if (res["data"].responseInfo.status === "FAILED") {
          handleWarning(
            <>
              {ToastMsg.getDetail_warningMsgWrong} <br />
              {ToastMsg.getDetail_warningMsgWrongsecond}
              <br />
              {ToastMsg.getDetail_warningMsgWrongthird}
            </>,
          );
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else {
          handleWarning(<Translation>{(t, { i18n }) => <label>{t("getDetail_warningMsgWrong")}</label>}</Translation>);
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  return (
    <>
      {!toggleForm ? (
        <div className="page-wrapper" role="main">
          <div className="container-fluid">
            <div className="text-white text-right">{/* <LanguageSwitcher /> */}</div>
            <div className="row justify-content-center">
              <div className="card-md registration-card">
                <div className="header">
                  <div className="rocket-icon">
                    <img src={RocketIcon} alt="" />
                  </div>
                  <div className="logo" role="heading" aria-level="1">
                    <img src={Med1Logo} className="img-fluid" alt="Med-1 Solutions Logo" />
                  </div>
                </div>
                <div className="main-heading">
                  <>
                    {path === "/landing-page" ? (
                      <>
                        <h1>
                          {" "}
                          Quick<span>Pay</span>
                        </h1>
                      </>
                    ) : null}
                  </>
                </div>
                <div className="registration-form-content">
                  {loader === true ? <SpinnerPage /> : ""}

                  <Formik
                    initialValues={intialvalues}
                    onSubmit={onSubmit}
                    validate={validate}
                    validationSchema={Yup.object().shape({
                      med1AccNum: Yup.string()
                        .required("MED-1 Account Number is required")
                        .min(5, "Minimum 5 digits required for MED-1 Account Number"),
                      ssn: Yup.string()
                        .max(4, "Enter last 4 digits")
                        .min(4, "Enter last 4 digits")
                        .matches(/^\d+$/, "Enter digits only")
                        .test("oneOfRequired", "Last 4 digits of SSN is required", function (item) {
                          return this.parent.ssn || this.parent.email || this.parent.phone || this.parent.patientDOB;
                        }),
                      patientDOB: Yup.date()
                        .typeError("Please enter a valid date")
                        .max(endOfDay(new Date()), "Must be a date in the past")
                        .min(moment(new Date(1870, 1, 1)).toDate(), "Date must be greater than 1870")
                        .nullable()
                        .test("oneOfRequired", "Date of Birth is required", function (item) {
                          return this.parent.ssn || this.parent.email || this.parent.phone || this.parent.patientDOB;
                        }),
                      email: Yup.string()
                        .nullable()
                        .email("Please enter valid email Address")
                        .test("oneOfRequired", "Email is required", function (item) {
                          return this.parent.ssn || this.parent.email || this.parent.phone || this.parent.patientDOB;
                        }),
                      phone: Yup.string()
                        .max(10, "Please enter 10 digits Phone Number")
                        .min(10, "Please enter 10 digits Phone Number")
                        .matches(/^[0-9]*$/, "Please enter a valid Phone Number")
                        .test("oneOfRequired", "Phone Number is Required", function (item) {
                          return this.parent.ssn || this.parent.email || this.parent.phone || this.parent.patientDOB;
                        }),
                    })}>
                    {(props) => {
                      const { values, touched, error, handleChange, errors, setFieldValue } = props;
                      return (
                        <Form
                          id="landing-page"
                          autoComplete="off"
                          className="input-field landing-page-form"
                          noValidate="noValidate">
                          <div className="input-field">
                            <div className="form-group account-number">
                              <div className="row mx-0 mt-3 justify-content-between label-spacing">
                                <label htmlFor="Med-1 Account Number" className="required">
                                  MED-1 Account Number
                                </label>
                                <ImageTooltip
                                  img={Med1AccNumberTooltip}
                                  width="320px"
                                  placement="left-start"
                                  alt="Med1 Bill">
                                  <i className="fas fa-info-circle circle-icon" style={{ cursor: "pointer" }}></i>
                                </ImageTooltip>
                              </div>
                              <div className="med1-input ">
                                <OutlinedInput
                                  className="form-control signup-fields"
                                  id="accountNumber"
                                  name="med1AccNum"
                                  placeholder="Enter MED-1 Account"
                                  onBlur={(e) => {
                                    let med1AccNum = e.target.value;
                                    if (med1AccNum.length >= 5 && med1AccNum.length <= 7) {
                                      med1ApiCall(e.target.value);
                                    }
                                  }}
                                  onChange={(e) => {
                                    setShowssn(false);
                                    setShowdob(false);
                                    setShowEmail(false);
                                    setShowContact(false);
                                    let med1AccNum = e.target.value;
                                    handleChange(e);
                                    if (med1AccNum.length > 7) {
                                      med1ApiCall(e.target.value);
                                    }
                                  }}
                                  value={values["med1AccNum"]}
                                  aria-label="text-field"
                                  fullWidth
                                  error={Boolean(getIn(touched, "med1AccNum") && getIn(errors, "med1AccNum"))}
                                  type={showMed1AccNumber ? "password" : "text"}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  startAdornment={
                                    <InputAdornment position="end">
                                      <LockOutlinedIcon />
                                    </InputAdornment>
                                  }
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleMed1AccountNumber}
                                        onMouseDown={handleMouseDownPassword}>
                                        {showMed1AccNumber ? <VisibilityOff /> : <Visibility />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  required="true"
                                  inputProps={{
                                    maxLength: 8,
                                  }}></OutlinedInput>
                                <ErrorMessage name="med1AccNum" className="error-text" component="div" />
                              </div>
                            </div>
                            {showContact || showEmail || showdob || showssn ? (
                              <div className="form-row mb-2">
                                <fieldset className="secure-login-fieldset">
                                  <legend>Enter Anyone of the following Details</legend>
                                  <div className="form-row member-details">
                                    {showssn && (
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label htmlFor="ssn">Social Security Number</label>

                                          <div className="security-input">
                                            <OutlinedInput
                                              className={`form-control security-input ${
                                                values?.email || values?.phone || values?.patientDOB
                                                  ? "inputFieldDisable"
                                                  : ""
                                              }`}
                                              id="ssn"
                                              name="ssn"
                                              placeholder="Enter last 4 digits"
                                              onChange={handleChange}
                                              value={values["ssn"]}
                                              aria-label="text-field"
                                              disabled={values?.patientDOB || values?.email || values?.phone}
                                              fullWidth
                                              error={Boolean(getIn(touched, "ssn") && getIn(errors, "ssn"))}
                                              type={showSocialSecurityNumber ? "text" : "password"}
                                              endAdornment={
                                                <InputAdornment position="end">
                                                  <IconButton
                                                    aria-label="toggle social security number visibility"
                                                    onClick={handleSocialSecurityNumber}>
                                                    {showSocialSecurityNumber ? <Visibility /> : <VisibilityOff />}
                                                  </IconButton>
                                                </InputAdornment>
                                              }
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              inputProps={{
                                                maxLength: 4,
                                                className: "inputFieldProps",
                                              }}></OutlinedInput>
                                            <ErrorMessage name="ssn" className="error-text" component="div" />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {showdob && (
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Grid
                                              container
                                              className={`date-picker custom-date-picker  ${
                                                values?.ssn || values?.phone || values?.email ? "date-picker-disable" : ""
                                              } 
                                        ${
                                          getIn(touched, "patientDOB") && getIn(errors, "patientDOB")
                                            ? "date-picker-errorBorder"
                                            : ""
                                        }`}>
                                              <label htmlFor="patientDOB" className="form-label w-100">
                                                Patient's Date of Birth
                                              </label>
                                              <DatePicker
                                                disableFuture
                                                name="patientDOB"
                                                autoOk
                                                disabled={values?.ssn || values?.phone || values?.email}
                                                value={values.patientDOB}
                                                onChange={(val) => setFieldValue("patientDOB", val)}
                                                InputProps={{
                                                  startAdornment: (
                                                    <IconButton
                                                      position="end"
                                                      onClick={() => setFieldValue("patientDOB", null)}
                                                      disabled={!values?.patientDOB}
                                                      style={{ order: 2 }}>
                                                      <ClearIcon
                                                        color={!values?.patientDOB ? "disabled" : "#808080"}
                                                        fontSize="small"
                                                      />
                                                    </IconButton>
                                                  ),
                                                }}
                                                InputAdornmentProps={{
                                                  position: "end",
                                                  style: { order: 2 },
                                                }}
                                                renderInput={(params) => {
                                                  const { inputProps } = params;
                                                  return (
                                                    <TextField
                                                      {...params}
                                                      helperText={null}
                                                      margin="dense"
                                                      inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: "MM/DD/YYYY",
                                                      }}
                                                    />
                                                  );
                                                }}
                                                inputProps={{
                                                  className:
                                                    values?.ssn || values?.phone || values?.email ? "inputFieldProps" : "",
                                                }}
                                              />
                                            </Grid>
                                          </LocalizationProvider>
                                          <ErrorMessage name="patientDOB" className="error-text" component="div" />
                                        </div>
                                      </div>
                                    )}
                                    {showEmail && (
                                      <div className="col-md-6 ">
                                        <div className="form-group">
                                          <label htmlFor="eMail">Email Address</label>
                                          <OutlinedInput
                                            className="form-control security-input"
                                            id="eMail"
                                            name="email"
                                            type="email"
                                            placeholder="Enter Email Address"
                                            onChange={handleChange}
                                            value={values["email"]}
                                            disabled={values?.ssn || values?.phone || values?.patientDOB}
                                            aria-label="text-field"
                                            fullWidth
                                            error={Boolean(getIn(touched, "email") && getIn(errors, "email"))}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            inputProps={{
                                              className:
                                                values?.ssn || values?.phone || values?.patientDOB
                                                  ? "inputFieldDisable"
                                                  : "",
                                            }}></OutlinedInput>
                                          <ErrorMessage name="email" className="error-text" component="div" />
                                        </div>
                                      </div>
                                    )}
                                    {showContact && (
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label htmlFor="phoneNumber">Phone Number</label>
                                          <OutlinedInput
                                            className="form-control security-input"
                                            id="phoneNumber"
                                            name="phone"
                                            disabled={values?.ssn || values?.email || values?.patientDOB}
                                            placeholder="Enter Phone Number"
                                            onChange={handleChange}
                                            value={values["phone"]}
                                            onKeyPress={(event) => {
                                              if (!/\d/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            aria-label="text-field"
                                            fullWidth
                                            error={Boolean(getIn(touched, "phone") && getIn(errors, "phone"))}
                                            inputProps={{
                                              className:
                                                values?.ssn || values?.email || values?.patientDOB
                                                  ? "inputFieldDisable"
                                                  : "",
                                              maxLength: 10,
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}></OutlinedInput>
                                          <ErrorMessage name="phone" className="error-text" component="div" />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </fieldset>
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group d-flex flex-column captcha">
                            <Captcha setFieldValue={setFieldValue} errors={errors} touched={touched} />
                          </div>
                          <div className="continue-payement-btn">
                            {path === "/landing-page" ? (
                              <button type="submit" className="primary-button btn-block" aria-label="continue to payment">
                                Continue to payment
                              </button>
                            ) : (
                              <button type="submit" className="primary-button btn-block" aria-label="verify">
                                Verify
                              </button>
                            )}
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
                <div className="text-center">
                  <div className="more-info">
                    {path === "/landing-page" ? (
                      <p>
                        Already have an account with us? <Link to="/signin">Sign In</Link> here, else{" "}
                        <Link to="/registration" aria-label="continue-to-payment">
                          Sign Up
                        </Link>{" "}
                        with us.
                      </p>
                    ) : (
                      <p>
                        Already have an account with us?{" "}
                        <Link to="/signin" aria-label="sign-in">
                          Sign In
                        </Link>{" "}
                        here.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="copy-right">
              <p>
                Need Help?{" "}
                <Link to="/contact-us" aria-label="contact-us-link">
                  <span className="contactus-link">Contact Us</span>
                </Link>
              </p>
              <p>Copyright {new Date().getFullYear()}. All Rights Reserved.</p>
              <p>
                WE ARE DEBT COLLECTORS. THIS IS AN ATTEMPT TO COLLECT A DEBT. ANY INFORMATION OBTAINED WILL BE USED FOR THAT
                PURPOSE.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <SignUpForm />
      )}
      {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
    </>
  );
};

export default LandingPage;
