/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import { tableIcons } from "../../../../components/TableIcons";
import { useHistory } from "react-router-dom";
import { Translation } from "react-i18next";
import { payplanService } from "../../../../services/payplanService";
import { requestInfo_PP } from "../../../../Common/CommonFn";
import {
  warning,
  error,
  errorNotification,
  warningNotification,
} from "../../../../components/PP_Notification/PP_Notification";
import { handleWarning } from "../../../../components/Notifications/Toast";

const tableTitle = {
  padding: "10px 0",
  color: "#644ad2",
  fontSize: "18px",
  fontWeight: 500,
  alignItems: "center",
  justifyContent: "flex-start",
  display: "flex",
  backgroundColor: "inherit",
};

const tableHeaderStyle = {
  textAlign: "inherit",
  color: "#000000",
  fontWeight: "500",
  padding: "15px",
  fontSize: "14px",
  fontFamily: "Poppins",
};
const rowStyle = {
  height: "30px",
  fontSize: "14px",
};

const PayPlanDetails = () => {
  const [paymentPlanData, setPaymentPlanData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const partyid =
      sessionStorage.getItem("currentUserRole") === "Admin"
        ? JSON.parse(sessionStorage?.getItem("verifypayer"))?.partyId
        : JSON.parse(sessionStorage.getItem("Userpartyid"));
    payplanService.getAllPayPlans(
      requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      partyid,
      1234,
      (res) => {
        if (res.status === 200 && res?.data?.statusCodeValue === 200) {
          setPaymentPlanData(res.data.body.recurringPayments);
        } else {
          handleWarning("Something went wrong!");
          sessionStorage.getItem("currentUserRole") === "Admin"
            ? history.push("/pa/payment-history")
            : history.push("/home/payment-history");
        }
      },
      (error) => {
        handleWarning("Something went wrong! Please try again");
      },
    );
  }, []);

  const payPlanColumns = [
    {
      title: "Payment Arrangement",
      field: "paymentarrangement",
      cellStyle: { padding: "0px 80px" },
      render: (rowData) => {
        return (
          <>
            <Link to={`/home/payment-plan-details?${rowData.paymentarrangement}`} aria-label="plan-details">
              {rowData.paymentarrangement}
            </Link>
          </>
        );
      },
    },
    {
      title: "Frequency",
      field: "frequency",
    },
    {
      title: "Start Date",
      field: "startdate",
    },
    {
      title: "Planned Amount",
      field: "plannedamount",
      cellStyle: { padding: "0px 50px", textAlign: "right" },
      headerStyle: {
        textAlign: "right",
      },
    },
  ];

  const options = {
    paging: false,
    search: false,
    headerStyle: tableHeaderStyle,
    rowStyle: rowStyle,
  };

  const payPlanData =
    paymentPlanData &&
    paymentPlanData.map((data) => {
      const { reccuringPaymentId, recurringPaymentType, startDate, recurringAmmount } = data;
      return {
        paymentarrangement: reccuringPaymentId,
        frequency: recurringPaymentType,
        startdate: startDate?.split(" ")[0],
        plannedamount: "$ " + recurringAmmount.toLocaleString(undefined, { minimumFractionDigits: 2 }),
      };
    });
  return (
    <>
      <Grid item xs={12}>
        <div className="payplan-table">
          <MaterialTable
            title={<div style={tableTitle}>Pay Plan Details</div>}
            aria-level="2"
            columns={payPlanColumns}
            icons={tableIcons}
            data={payPlanData}
            options={options}
          />
        </div>
      </Grid>
    </>
  );
};
export default PayPlanDetails;
