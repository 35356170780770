import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import Med1Logo from "../../../assets/images/Med1Logo.png";
import { Link } from "react-router-dom";
import Capture2 from "../../../assets/images/Capture2.jpg";
import RocketIcon from "../../../assets/images/quick-pay-rocket-icon.svg";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";

// import CircularProgress from "@material-ui/core/CircularProgress";
// import { toast } from "util/ReactToastify";
// import { hideErrorMessage } from "actions/Common";
const SignIn = (props) => {
  const history = useHistory();

  const onSubmit = async (values, onSubmitProps) => {
    history.push("/billing");
    // dispatch(postLoginDetails({ username: email, password }));
  };

  return (
    <div className="page-wrapper" role="main">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div>
            <div className="card-md">
              <div className="header">
                <div className="rocket-icon">
                  <img src={RocketIcon} alt="" />
                </div>
                <div className="logo">
                  <img src={Med1Logo} className="img-fluid" alt="Med-1 Solutions Logo" />
                </div>
              </div>
              <div className="main-heading">
                <h1>
                  Quick<span>Pay</span>
                </h1>
              </div>
              <div className="dashboard-content-card successfull-payment-card">
                <div className="quickpay-payment">
                  <div className="">
                    <div className="thank-you">
                      <h2>Payment Successful</h2>
                    </div>
                    <div className="signup-text">
                      <p>Thank you for using QuickPay,</p>
                      <p>
                        {" "}
                        <Link to="/registration" className="sign-up-link">
                          Sign Up
                        </Link>{" "}
                        with us for future payments
                      </p>
                      {/* <button onClick={() => history.push("/signup")} className="sign-up-link">
                        Sign Up
                      </button> */}
                    </div>
                    <div className="">
                      <div className="payment-receipt">
                        <h5 aria-level="2">Payment Receipt</h5>
                        <div className="row receipt-section">
                          <div className="col-md-6 col-5">
                            <p>Confirmation No.</p>
                          </div>
                          <div className="col-md-6 col-7">
                            <p>160574</p>
                          </div>
                          <div className="col-md-6 col-5">
                            <p>Transaction ID</p>
                          </div>
                          <div className="col-md-6 col-7">
                            <p>trn_be6a88ef-3c80-4e2b-a5b5-cf415c380e04</p>
                          </div>
                          <div className="col-md-6 col-5">
                            <p>Transaction Date</p>
                          </div>
                          <div className="col-md-6 col-7">
                            <p>4/22/2021 05:07 PM EST</p>
                          </div>
                          <div className="col-md-6 col-5">
                            <p>Email Address</p>
                          </div>
                          <div className="col-md-6 col-7">
                            <p>sweedling@gmail.com</p>
                          </div>
                          <div className="col-md-6 col-5">
                            <p>Phone Number</p>
                          </div>
                          <div className="col-md-6 col-7">
                            <p>+1 (317)753-1270</p>
                          </div>
                          <div className="col-md-6 col-5">
                            <p>Payment Method</p>
                          </div>
                          <div className="col-md-6 col-7">
                            <p>Credit Card</p>
                          </div>
                          <div className="col-md-6 col-5">
                            <p>From Account</p>
                          </div>
                          <div className="col-md-6 col-7">
                            <p>************3727</p>
                          </div>
                          <div className="col-md-6 col-5">
                            <p>Paid To</p>
                          </div>
                          <div className="col-md-6 col-7">
                            <p>MED1 Solutions,LLC</p>
                          </div>
                          <div className="col-md-6 col-5">
                            <p>Payment Amount</p>
                          </div>
                          <div className="col-md-6 col-7">
                            <p>$70.00</p>
                          </div>
                        </div>
                        <button className="download-button">Download Receipt</button>
                      </div>
                    </div>
                  </div>
                  <div className="success-img">
                    <img src={Capture2} alt="payament-success" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
    </div>
  );
};

export default SignIn;
