import { Card } from "@mui/material";
import React, { createRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getSurveyAnswersListById, getSurveyChartData } from "../../../../action/SurveyAction";
import MouseOverPopover from "../../../../Common/MousePopOver";
import { error, errorNotification } from "../../../../components/Admin_Notification/Admin_Notification";
import SurveyFeedback from "./SurveyFeedback";
import MaterialTable from "material-table";
import { tableIcons } from "../../../../components/TableIcons";
import PatchedPagination from "../../../../Common/TablePagination";
import { httpPaginationRequest } from "../../../../Common/httpPaginationRequest";
import { Global_var } from "../../../../global/global_var";
import SpinnerPage from "../../../../Common/PP_loader";
import ConfirmationDialog from "../../../../components/ConfirmationDialog/index";
// apex imports
import ReactApexChart from "react-apexcharts";
import { Tooltip } from "@material-ui/core";

const AdminSurveyDashboard = () => {
  const [rowData, setRowData] = useState([]);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const tableRef = createRef();
  const [arrayLast, setArrayLast] = useState(false);
  const [chartData, setChartData] = useState({});
  const [rows, setRows] = useState(5);

  const dispatch = useDispatch();
  const handleClose = () => {
    setFeedbackPopup(false);
    rowData?.lenght > 0 && setRowData([]);
  };

  useEffect(() => {
    dispatch(
      getSurveyChartData((res) => {
        if (res?.status === 200) {
          setChartData(res?.data);
          setIsDataLoading(false);
        } else {
          error(res?.message, errorNotification);
          setIsError(true);
          setIsDataLoading(false);
        }
      }),
    );
  }, []);

  const stateForDonut = {
    series: [chartData?.satisfiedPercent, chartData?.disSatisfiedPercent],
    options: {
      dataLabels: {
        formatter: function (val) {
          return val.toFixed(2) + "%";
        },
      },
      datasets: [
        {
          borderColor: ["black"],
        },
      ],
      grid: {
        borderColor: ["red"],
      },
      plotOptions: {
        pie: {
          donut: {
            size: "48%",
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val.toFixed(2) + "%";
          },
          title: {
            formatter: function (seriesName) {
              return seriesName.split(":")[0] + ": ";
            },
          },
        },
      },
      labels: [
        `Satisfied Customers: ${chartData?.satisfiedPercent?.toFixed(2)}%                              `,
        `Dissatisfied Customers: ${chartData?.disSatisfiedPercent?.toFixed(2)}%`,
      ],
      theme: {
        palette: "palette2", // upto palette10
      },
      chart: {
        type: "donut",
        height: "100%",
        width: "100%",
      },
      colors: ["#66c136", "#ff0000ba"],
      legend: {
        position: "bottom",
      },
      responsive: [
        {
          // breakpoint: 480,
          // options: {
          //   chart: {
          //     width: 500,
          //   },
          //   legend: {
          //     position: "bottom",
          //   },
          // },
        },
      ],
    },
  };

  const stateForDonut2 = {
    series: [chartData?.totalRecivedSurveyUserCount, chartData?.totalSkipedSurveyUserCount],
    options: {
      dataLabels: {
        formatter: function (val, ...rest) {
          return rest[0].w.config.series[rest[0].seriesIndex];
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "";
          },
          title: {
            formatter: function (seriesName) {
              return seriesName;
            },
          },
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "48%",
          },
        },
      },
      labels: [
        `Feedback Received: ${chartData?.totalRecivedSurveyUserCount}`,
        `Feedback Skipped: ${chartData?.totalSkipedSurveyUserCount} `,
      ],
      theme: {
        palette: "palette2", // upto palette10
      },
      chart: {
        type: "donut",
        height: "100%",
        width: "100%",
      },
      colors: ["#ffda4c", "#B0B0B0"],
      legend: {
        position: "bottom",
      },
      responsive: [
        {
          // breakpoint: 480,
          // options: {
          //   chart: {
          //     width: 500,
          //   },
          //   legend: {
          //     position: "bottom",
          //   },
          // },
        },
      ],
    },
  };
  const userData = {
    labels: [
      `Satisfied Customers: ${chartData?.satisfiedPercent?.toFixed(0)}%`,
      `Dissatisfied Customers: ${chartData?.disSatisfiedPercent?.toFixed(0)}%`,
    ],
    datasets: [
      {
        key: "D1",
        label: [
          `Satisfied Customers: ${chartData?.satisfiedPercent}`,
          `Dissatisfied Customers: ${chartData?.disSatisfiedPercent}`,
        ],
        data: [chartData?.satisfiedPercent, chartData?.disSatisfiedPercent],

        // backgroundColor: ["#66c136", "#ff0000ba"],
        borderColor: ["rgba(0,0,0,0.7)", "rgba(0,0,0,0.7)"],
        borderWidth: 2,
        tooltip: {
          // onDatasetHover: {
          //   backgroundColor: ["rgba(0, 0, 0, 0.7)"],
          //   Color: ["white"],
          // },
          callbacks: {
            label: function (context) {
              let label = context.label;
              let value = context.formattedValue;

              if (!label) label = "Unknown";

              let sum = 0;
              let dataArr = context.chart.data.datasets[0].data;
              dataArr.forEach((data) => {
                sum += Number(data);
              });

              let percentage = ((value * 100) / sum).toFixed(2) + "%";
              return label.split(":")[0] + ": " + percentage;
            },
          },
        },
      },
    ],
  };

  const skippedSurveyData = {
    labels: [
      `Feedback Received: ${chartData?.totalRecivedSurveyUserCount}`,
      `Feedback Skipped: ${chartData?.totalSkipedSurveyUserCount} `,
    ],
    datasets: [
      {
        label: "Survey",
        data: [chartData?.totalRecivedSurveyUserCount, chartData?.totalSkipedSurveyUserCount],
        backgroundColor: ["#ffda4c", "#3f3f3f"],
        borderColor: ["rgba(0,0,0,0.7)", "rgba(0,0,0,0.7)"],
        borderWidth: 2,
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.label;
              let value = context.formattedValue;

              if (!label) label = "Unknown";

              return label.split(":")[0] + ": " + value;
            },
          },
        },
      },
    ],
  };

  const columns = [
    {
      name: "userName",
      title: "User Name",
      field: "userName",
      render: (rowData) => {
        const data = rowData["userName"];
        return data ? data[0]?.toUpperCase() + data?.slice(1) : "";
      },
    },
    {
      name: "answer1",
      title: "Feedback",
      field: "answer1",
      render: (rowData) => {
        const data = rowData["answer1"];
        const feedbackData = data ? data[0]?.toUpperCase() + data?.slice(1) : "";
        return (
          feedbackData && (
            <p
              className="survey-feedback-comment"
              style={{
                color: feedbackData === "Satisfied" ? "green" : "red",
              }}>
              {feedbackData}
            </p>
          )
        );
      },
    },
    {
      name: "answer2",
      title: "Comments",
      field: "answer2",
      render: (rowData) => {
        const data = rowData["answer2"];
        const commentData = data ? data[0]?.toUpperCase() + data?.slice(1) : "";
        return (
          <>
            <Tooltip title={commentData} placement="top-start" className="comments-popover">
              <p className="survey-commentsColumn mb-0">{commentData}</p>
            </Tooltip>
            {/* <MouseOverPopover data={commentData} popOverData={commentData} /> */}
          </>
        );
      },
    },
    {
      name: "createdDate",
      title: "Date",
      field: "createdDate",
      render: (rowData) => {
        const data = rowData["createdDate"];
        return data && data?.split(" ")[0];
      },
    },
  ];

  if (isError) {
    return (
      <div className="row">
        <div className="col-md-12 col-sm-12 report-cp">
          <div style={{ width: "100%", textAlign: "center" }}>
            <h3>Something went wrong</h3>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      {!isDataLoading && (
        <div className="billdetails-content">
          <div className="row justify-content-center">
            {/* <div className="col-sm-6 mt-2">
              <div className="admin-piechart">
                <DoughnutChart
                  chartData={userData}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                  plugins={[htmlUserDataLegendPlugin("userData-legend")]}
                />
              </div>
              <div id="userData-legend"></div>
            </div> */}
            <div className="col-sm-6 mt-2">
              <div className="admin-piechart">
                <ReactApexChart
                  options={stateForDonut.options}
                  series={stateForDonut.series}
                  type="donut"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>

            <div className="col-sm-6 mt-2">
              <div className="admin-piechart">
                <ReactApexChart
                  options={stateForDonut2.options}
                  series={stateForDonut2.series}
                  type="donut"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>

            {/* <div className="col-sm-6 mt-2">
              <div className="admin-piechart">
                <DoughnutChart
                  chartData={skippedSurveyData}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                  plugins={[htmlUserDataLegendPlugin("skippedSurveyData-legend")]}
                />
              </div>
              <div id="skippedSurveyData-legend"></div>
            </div> */}
          </div>
        </div>
      )}

      <div className="payplan-table mt-4">
        <MaterialTable
          title=""
          localization={{
            pagination: {
              firstAriaLabel: "First Page",
              previousAriaLabel: "Previous Page",
              nextAriaLabel: "Next Page",
              lastAriaLabel: "Last Page",
            },
          }}
          columns={columns}
          icons={tableIcons}
          tableRef={tableRef}
          data={(query) =>
            new Promise((resolve) => {
              const bodyObj = {
                limit: query.pageSize ? query.pageSize : null,
                orderBy: "",
                orderDirection: "",
                page:
                  arrayLast && query?.page >= 1
                    ? query.page
                    : arrayLast
                    ? (query.page <= 1 && query.page + 1) || query.page - 1
                    : query.page,
              };
              httpPaginationRequest(Global_var.URL_GET_RP_SURVEY_ANSWERS, bodyObj, (res) => {
                if (res?.status === 200) {
                  const data = res.data.resultObjects;
                  resolve({
                    data: data.length > 0 ? data : [],
                    page: res.data.page,
                    totalCount: res.data.count,
                  });
                  setArrayLast(data.length === 1);
                }
              });
            })
          }
          onRowClick={(event, rowData) => {
            setIsLoading(true);
            dispatch(
              getSurveyAnswersListById(rowData["feedbackId"], (res) => {
                setIsLoading(false);
                setRowData(res);
                setFeedbackPopup(true);
              }),
            );
          }}
          options={{
            selection: false,
            search: false,
            emptyRowsWhenPaging: false,
            sorting: false,
            initialPage: 0,
            pageSize: rows,
            pageSizeOptions: [5, 10, 20],
          }}
          onChangeRowsPerPage={(params) => {
            setRows(params);
          }}
          components={{
            Pagination: PatchedPagination,
          }}
        />
      </div>

      <ConfirmationDialog
        open={feedbackPopup}
        handleClose={handleClose}
        fullWidth={true}
        title="Survey Feedback"
        noActionButtons={true}>
        <SurveyFeedback rowData={rowData} handleClose={handleClose} />
      </ConfirmationDialog>
      {isLoading && <SpinnerPage />}
    </div>
  );
};

export default AdminSurveyDashboard;
