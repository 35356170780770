import { Dialog, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { ErrorMessage, Field, Form, Formik, getIn } from "formik";
import * as Yup from "yup";

const DialogSettlementDetails = ({
  children,
  dialogClose1,
  dialogClose2,
  open1,
  open2,
  title,
  settleDetails,
  settleReject,
  settleRevised,
  data,
  handleSettlePush,
  handleSettlmentNo,
  currentSeedItem,
  handleSettlementRevise,
  handleSettlementReject,
  handleSettlementAccept,
  updateRevisedAmount,
  setNotesData,
  notesData,
}) => {
  const handleDisableCheckBox = (data1) => {
    if (["APPROVED", "REJECTED", "SETTLED", "REVISED"].includes(data1)) {
      return true;
    } else {
      return false;
    }
  };

  const schema = Yup.object().shape({
    revisedAmount: Yup.number()
      .required("Revised amount is required")
      .positive("Value can't start with a minus")
      .max(data?.outstandingBalance, "Revise amount should be less than outstanding amount")
      .moreThan(data?.proposedAmount, "Revise amount should be greater than proposed amount")
      .nullable(),
    notes: Yup.string().required("Notes are required"),
  });
  return (
    <Dialog
      open={open1 || open2}
      onClose={() => {
        dialogClose2();
      }}
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      {" "}
      {open2 ? (
        <DialogTitle
          className="popupstyles"
          onClose={() => {
            dialogClose2();
          }}>
          <div className="dialog-header">
            <Typography>{title}</Typography>
            <IconButton>
              <CloseIcon
                onClick={() => {
                  dialogClose2();
                }}
              />
            </IconButton>
          </div>
          {/* {/ <span className="settlement-dialog-title">{title}</span> /} */}
        </DialogTitle>
      ) : (
        <DialogTitle id="customized-dialog-title" onClose={dialogClose1}></DialogTitle>
      )}
      <DialogContent dividers className="modal-body">
        {open1 && (
          <>
            <div className="row">
              <span style={{ color: "#0F75BD", margin: "0 0 5px 5px" }}> Congratulations ! </span>
            </div>
            <span style={{ color: "#948f8f" }}>You have a settlement offer. would you like to claim it?</span>
            <div className="d-flex justify-content-center pt-1">
              <Button
                variant="contained"
                color="primary"
                type="button"
                size="medium"
                className="primary-button themeing-buttons mx-1"
                onClick={() => handleSettlePush()}>
                Yes
              </Button>
              <Button
                type="button"
                variant="contained"
                className="primary-button themeing-buttons mx-1"
                onClick={() => {
                  handleSettlmentNo();
                }}>
                No
              </Button>
            </div>
          </>
        )}
        {open2 && (
          <Formik
            initialValues={{
              revisedAmount: data?.revisedAmount,
              notes: data?.notes,
              inputOn: data?.settlementStatus === "REVISED",
            }}
            validationSchema={schema}
            enableReinitialize
            onSubmit={handleSettlementRevise}>
            {(props) => {
              // eslint-disable-next-line no-unused-vars
              const { values, handleChange, errors, touched, setFieldValue } = props;
              return (
                <Form id="profile-page" className="recurring-plans input-field" noValidate="noValidate" autocomplete="off">
                  <div className="modal-body settlement-popup">
                    <div className="row">
                      <div className={settleRevised ? "col-sm-6 account-number" : "col-sm-6 account-number  "}>
                        <p className="label">
                          Account Number: <span className=" value">{data.masterAccountReferenceNumber}</span>
                        </p>
                      </div>
                      <div className="col-sm-6 user-name ">
                        <p className="label">
                          User Name: <span className="account- value">{data?.userName ? data.userName : "-"}</span>
                        </p>
                      </div>
                      <div className="col-md-12 user-name ">
                        <p className="label">
                          Hospital <span className="value">{`: ${data?.hospital}`}</span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 my-1">
                        <div className="settlement-amount">
                          <p className="label settlement-amount-title">Outstanding Amount</p>
                          <p className="amount-settled mb-0">
                            {" "}
                            $
                            {data?.outstandingBalance?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-6 my-1">
                        <div className="settlement-amount">
                          <p className="label settlement-amount-title">Proposed Amount</p>
                          <p className="amount-settled mb-0">
                            {" "}
                            $
                            {data?.proposedAmount?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="revised-column row mt-2">
                      <div className="col-sm-6 form-group">
                        <input
                          type="checkbox"
                          onClick={(e) => setFieldValue((values.inputOn = e.target.checked))}
                          name="checkbox"
                          id="checkbox_id"
                          className="mr-2"
                          defaultChecked={data?.settlementStatus === "REVISED"}
                          // value="value"
                          disabled={handleDisableCheckBox(data?.settlementStatus)}
                        />
                        <label htmlfor="checkbox_id" className="label">
                          Revise the Settlement Offer
                        </label>
                      </div>
                      {values.inputOn || data?.settlementStatus === "REVISED" ? (
                        <div className="col-sm-6 form-group">
                          <div className="revised-amount">
                            <label htmlfor="revisedAmt" className="required label">
                              Enter the Revised Amount
                            </label>
                            <div>
                              <span className="mr-1">{`$  `}</span>
                              <Field
                                type="number"
                                maxLength="255"
                                autocomplete="new-password"
                                id="revisedAmount"
                                value={values.revisedAmount}
                                placeholder="Enter Revised amount"
                                onChange={(event) => {
                                  setFieldValue((values.revisedAmount = event.target.value));
                                }}
                                disabled={handleDisableCheckBox(data?.settlementStatus)}
                              />
                            </div>
                          </div>
                          <ErrorMessage component="div" name="revisedAmount" className=" error-text" />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="form-group settlement-addnotes">
                      <label className="active required">{"Add Notes"}</label>

                      <Field
                        name="notes"
                        component="textarea"
                        className="w-100 p-2"
                        rows={3}
                        cols={40}
                        placeholder="Add a note"
                        onChange={(event) => {
                          setFieldValue((values.notes = event.target.value));
                        }}
                        disabled={handleDisableCheckBox(data?.settlementStatus)}
                      />
                      <ErrorMessage component="div" name="notes" className="text-danger error-text" />
                    </div>

                    <>
                      <DialogActions className="d-flex justify-content-center">
                        <Button
                          type="button"
                          className="primary-button themeing-buttons"
                          disabled={values.inputOn || handleDisableCheckBox(data?.settlementStatus)}
                          onClick={(e) => {
                            if (errors.notes) {
                              e.preventDefault();
                            } else {
                              handleSettlementAccept(values);
                            }
                          }}>
                          Accept
                        </Button>
                        <Button
                          className="secondary-button mx-2"
                          type="button"
                          onClick={(e) => {
                            if (errors.notes) {
                              e.preventDefault();
                            } else {
                              handleSettlementReject(values);
                            }
                          }}
                          disabled={values.inputOn || handleDisableCheckBox(data?.settlementStatus)}>
                          Reject
                        </Button>
                        <Button
                          type="submit"
                          className="primary-button themeing-secondary-buttons ml-0"
                          disabled={!values.inputOn ? true : false || data?.settlementStatus === "REVISED"}>
                          Revise
                        </Button>
                      </DialogActions>
                    </>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default DialogSettlementDetails;
