import React from "react";
import "./overlayloader.css";

const SpinnerPage = () => {
  return (
    <>
      <div className="overlay-container">
        <div className="spinner-border text-warning" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </>
  );
};

export default SpinnerPage;
