import React from "react";
import { Formik, Form, ErrorMessage, getIn } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { I18nextProvider } from "react-i18next";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import i18n from "../../../../../i18n";
import { Button, Select, FormControl, MenuItem } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { saveSettlement } from "../../../../../action/ClientManagementAction";
import { handleError, handleSuccess } from "../../../../../components/Notifications/Toast";
import SpinnerPage from "../../../../../Common/PP_loader";
import DialogActions from "@material-ui/core/DialogActions";

const PaymentPlansOptionsPopup = ({
  settlementOptionsPopupData,
  isEdit,
  subBuId,
  hospitalList,
  handleClose,
  setIsUpdate,
}) => {
  const settleOptions = [
    { valueCode: "Auto", value: "Auto" },
    { valueCode: "Approval Required", value: "Approval Required" },
    { valueCode: "Not Allowed", value: "Not Allowed" },
  ];
  const getHospitalValue = (hospitalData, hospitalId) => {
    const hospitalValue = hospitalData.find((data) => data?.buId === hospitalId);
    return hospitalValue ? hospitalValue : "";
  };
  const settlementValue = (settleOptions, data) => {
    const settleValue = settleOptions.find((item) => item.value === data);
    return settleValue?.value ? settleValue?.value : "none";
  };
  let initialValues = {
    settlementType: isEdit
      ? settlementOptionsPopupData?.settlementType
        ? settlementValue(settleOptions, settlementOptionsPopupData?.settlementType)
        : ""
      : "none",
    hospital: isEdit ? (settlementOptionsPopupData?.subBuId ? getHospitalValue(hospitalList, subBuId) : "none") : "none",
    minSettlement: isEdit
      ? settlementOptionsPopupData?.minimumSettlementPercentage
        ? settlementOptionsPopupData?.minimumSettlementPercentage
        : ""
      : "",
    maxPercentage: isEdit
      ? settlementOptionsPopupData?.maximumPaymentLimit
        ? settlementOptionsPopupData?.maximumPaymentLimit
        : ""
      : "",
  };
  const validation = Yup.object().shape({
    settlementType: Yup.mixed().notOneOf(["none"], "Select Settlement Type").required("Select Settlement Type"),
    hospital: Yup.mixed().notOneOf(["none"], "Select a Hospital").required("Select a Hospital"),
    minSettlement: Yup.string()
      .matches(/^\d+\.?\d*$/, "Enter digits only")
      .required("Minimum Settlement Required"),
    maxPercentage: Yup.string()
      .matches(/^\d+\.?\d*$/, "Enter digits only")
      .required("Maximum Percentage Required"),
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    setIsLoading(true);
    dispatch(
      saveSettlement(
        {
          settlementOptionsId: isEdit ? settlementOptionsPopupData?.settlementOptionsId : 0,
          subBuId: values?.hospital?.buId ? Number(values?.hospital?.buId) : 0,
          settlementType: values?.settlementType ? values?.settlementType : "",
          minimumSettlementPercentage: values?.minSettlement ? Number(values?.minSettlement) : "",
          maximumPaymentLimit: values?.maxPercentage ? Number(values?.maxPercentage) : "",
        },
        (res) => {
          setIsLoading(false);
          if (res.data?.status === "SUCCESSFUL") {
            setIsUpdate(true);
            handleClose();
            res.data?.responseMessage?.length > 0 && handleSuccess(res.data?.responseMessage[0]);
          } else if (res.data?.status === "FAILED") {
            res.data?.responseMessage?.length > 0 && handleError(res.data?.responseMessage[0]);
          } else {
            handleError(res?.message);
          }
        },
      ),
    );
  };

  return (
    <I18nextProvider i18n={i18n}>
      <React.Fragment>
        <Formik enableReinitialize initialValues={initialValues} validationSchema={validation} onSubmit={handleSubmit}>
          {({ touched, errors, setFieldValue, values, resetForm, handleBlur, handleChange }) => (
            <Form autocomplete="new-password" className="input-field">
              <div>
                <div className="form-row">
                  <div className="col-md-6 col-sm-6 form-group">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <label htmlFor="hospital" className="form-label required">
                        Hospital Name
                      </label>
                      <Autocomplete
                        id="hospital"
                        options={hospitalList}
                        size="small"
                        className="hospital-dropdown"
                        autoHighlight
                        getOptionDisabled={(option) => option?.code === null}
                        value={values?.hospital}
                        disabled={isEdit}
                        getOptionLabel={(option) => {
                          return option && option.hospitalName ? option?.code + " - " + option?.hospitalName : "";
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            name="hospital"
                            placeholder="Select Hospital Name"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "disabled", // disable autocomplete and autofill
                            }}
                          />
                        )}
                        onChange={(e, value) => {
                          setFieldValue("hospital", value);
                        }}
                      />
                    </FormControl>
                    <ErrorMessage component="div" name="hospital" className="text-danger" />
                  </div>
                  <div className="col-md-6 col-sm-6 form-group">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <label htmlFor="optedStauts" className="form-label required">
                        Settlement Type
                      </label>
                      <Select
                        fullWidth
                        name="settlementType"
                        className="form-select form-control"
                        onChange={handleChange("settlementType")}
                        value={values["settlementType"]}>
                        <MenuItem value="none" key="none" disabled>
                          Select Settlement Type
                        </MenuItem>
                        {settleOptions.map((option) => {
                          return (
                            <MenuItem value={option.valueCode} key={option.valueCode}>
                              {option.value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <ErrorMessage component="div" name="settlementType" className="text-danger" />
                    </FormControl>
                  </div>
                  <div className="col-md-6 col-sm-12 form-group">
                    <label htmlFor="minSettlement" className="form-label required">
                      Minimum Settlement Percentage
                    </label>
                    <OutlinedInput
                      type="text"
                      id="minSettlement"
                      name={"minSettlement" + Math.random()}
                      value={values.minSettlement}
                      error={getIn(touched, "minSettlement") && getIn(errors, "minSettlement")}
                      placeholder="Enter Minimum Settlement"
                      className={`form-control admin-select-field ${
                        touched.minSettlement && errors.minSettlement ? "is-invalid" : ""
                      }`}
                      onChange={(event) => {
                        setFieldValue("minSettlement", event.target.value);
                      }}
                    />

                    <ErrorMessage component="div" name="minSettlement" className="error-text" />
                  </div>
                  <div className="col-md-6 col-sm-12 form-group">
                    <label htmlFor="maxPercentage" className="form-label required">
                      Maximum Percentage to Show Settlement
                    </label>
                    <OutlinedInput
                      type="text"
                      id="maxPercentage"
                      name={"maxPercentage" + Math.random()}
                      value={values.maxPercentage}
                      error={getIn(touched, "maxPercentage") && getIn(errors, "maxPercentage")}
                      placeholder="Enter Maximum Percentage"
                      className={`form-control admin-select-field ${
                        touched.maxPercentage && errors.maxPercentage ? "is-invalid" : ""
                      }`}
                      onChange={(event) => {
                        setFieldValue("maxPercentage", event.target.value);
                      }}
                    />
                    <ErrorMessage component="div" name="maxPercentage" className="error-text" />
                  </div>
                </div>
                <div className="d-flex justify-content-center my-2">
                  <DialogActions className="mt-2">
                    <Button type="submit" className="primary-button themeing-buttons mr-2">
                      Submit
                    </Button>
                    <Button className="secondary-button" variant="outlined" onClick={() => resetForm(initialValues)}>
                      Reset
                    </Button>
                  </DialogActions>
                </div>
              </div>{" "}
            </Form>
          )}
        </Formik>
        {isLoading && <SpinnerPage />}
        {/* <ToastContainer /> */}
      </React.Fragment>
    </I18nextProvider>
  );
};

export default PaymentPlansOptionsPopup;
