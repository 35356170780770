import { VoidTransactionService } from "../services/VoidAndRefundService.js";

export function fetchVoidAndRefundTransaction(payload, fn) {
  return (dispatch) => {
    VoidTransactionService.getVoidTransaction(payload, (res) => {
      fn(res.data);
    });
  };
}
export function postTheVoidTransaction(payload, fn) {
  return (dispatch) => {
    VoidTransactionService.postVoidTran(payload, (res) => {
      fn(res.data);
    });
  };
}
export function postTheReverseTransaction(payload, fn) {
  return (dispatch) => {
    VoidTransactionService.postReverseTran(payload, (res) => {
      fn(res.data);
    });
  };
}
