import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getIn } from "formik";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Grid } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";

class PatientDetails extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={{
                med1AccNum: "",
                ssn: "",
                patientDOB: null,
                email: "",
                phone: "",
              }}>
              {(props) => {
                const { values, touched, error, handleChange, errors, setFieldValue } = props;
                return (
                  <Form id="landing-page" className="input-field" noValidate="noValidate">
                    <div className="row">
                      <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                          <label htmlFor="firstName">First Name</label>
                          <OutlinedInput
                            className="form-control"
                            id="firstName"
                            name="firstName"
                            placeholder="Enter First Name"
                            onChange={handleChange}
                            value={values["firstName"]}
                            aria-label="text-field"
                            fullWidth
                            type="firstName"
                            error={Boolean(getIn(touched, "firstName") && getIn(errors, "firstName"))}></OutlinedInput>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                          <label htmlFor="lastName">Last Name</label>
                          <OutlinedInput
                            className="form-control"
                            id="lastName"
                            name="lastname"
                            placeholder="Enter Last Name"
                            onChange={handleChange}
                            value={values["lastname"]}
                            aria-label="text-field"
                            fullWidth
                            type="lastname"
                            error={Boolean(getIn(touched, "lastname") && getIn(errors, "lastname"))}></OutlinedInput>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                          <label htmlFor="socialSecurityNumber">Social Security Number</label>
                          <div className="security-input">
                            <OutlinedInput
                              className="form-control security-input"
                              id="socialSecurityNumber"
                              name="ssn"
                              placeholder="Please Enter SSN"
                              onChange={handleChange}
                              value={values["ssn"]}
                              aria-label="text-field"
                              fullWidth
                              error={Boolean(getIn(touched, "ssn") && getIn(errors, "ssn"))}
                              endAdornment={
                                <InputAdornment position="end">
                                  <i className="fas fa-eye eye-icon"></i>
                                </InputAdornment>
                              }></OutlinedInput>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                          <label htmlFor="emailAddress">Email Address</label>
                          <OutlinedInput
                            className="form-control security-input"
                            id="emailAddress"
                            name="email"
                            type="email"
                            placeholder="Please Enter Email Address"
                            onChange={handleChange}
                            value={values["email"]}
                            aria-label="text-field"
                            fullWidth
                            error={Boolean(getIn(touched, "email") && getIn(errors, "email"))}></OutlinedInput>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                          <label htmlFor="phoneNumber">Phone Number</label>
                          <OutlinedInput
                            className="form-control security-input"
                            id="phoneNumber"
                            name="phone"
                            placeholder="Please Enter Phone Number"
                            onChange={handleChange}
                            value={values["phone"]}
                            aria-label="text-field"
                            fullWidth
                            error={Boolean(getIn(touched, "phone") && getIn(errors, "phone"))}></OutlinedInput>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="proceed-cancel-buttons">
                          <button className="secondary-button" aria-label="cancel">
                            Cancel
                          </button>
                          <button type="submit" className="primary-button" aria-label="save">
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
          </div>
        </div>
      </div>
    );
  }
}
export default PatientDetails;
