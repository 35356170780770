import RestDataSource from "./restdatasource";
import { loginService } from "./loginService";
import { Global_var } from "../global/global_var";
import {
  error,
  success,
  warning,
  warningNotification,
  errorNotification,
  successNotification,
} from "../components/PP_Notification/PP_Notification";
export const identifyPayerService = {
  getIdentifyPayerList,
  addIdentifyPayer,
  refreshToken,
  getprofiledata,
};

function getIdentifyPayerList(payerInfo, userId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_VERIFY_PAYER;
  return new RestDataSource(url, userId, fn).Store(payerInfo, (res) => {
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-token", res.headers.token);
        }
        fn(res);
      }
    }
  });
  //   }
  // }, fnError);
}

// REfresh Token
function addIdentifyPayer(payerInfo, userId, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      // debugger
      sessionStorage.setItem("jwt-token", res.responseObject);
      //     debugger
      // var url = Global_var.URL_ADD_VERIFY_PAYER;
      var url = Global_var.BASEURL + Global_var.URL_ADD_VERIFY_PAYER;
      return new RestDataSource(url, userId, fn).Store(payerInfo, (res) => {
        // debugger
        if (res != null) {
          if (res["data"].status === "fail") {
            if (res["data"].reasonText === "Invalid Token") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/login");
            } else if (res["data"].reasonCode === "SM-02") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/login");
            } else if (res["data"].reasonCode === "SM-10") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/login");
            } else {
              warning("Something went wrong!" + res["data"].reasonText, warningNotification);
            }
          } else {
            if (res.headers["token"] != null) {
              sessionStorage.setItem("jwt-token", res.headers.token);
            }
            fn(res);
          }
        }
      });
    }
  }, fnError);
}
function refreshToken(userId, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-token", res.responseObject);
    }
  }, fnError);
}
function getprofiledata(payerInfo, userId, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_PROFILE_DETAILS;
      return new RestDataSource(url, userId, fn).Store(payerInfo, (res) => {
        // debugger
        if (res != null) {
          if (res["data"].status === "fail") {
            if (res["data"].reasonText === "Invalid Token") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/login");
            } else if (res["data"].reasonCode === "SM-02") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/login");
            } else if (res["data"].reasonCode === "SM-10") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/login");
            } else {
              warning("Something went wrong!" + res["data"].reasonText, warningNotification);
            }
          } else {
            if (res.headers["token"] != null) {
              sessionStorage.setItem("jwt-token", res.headers.token);
            }
            fn(res);
          }
        }
      });
    }
  }, fnError);
}
