import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SignIn from "./Auth/SignIn";
import Tabs from "./Auth/Tabs/Tabs";
import SignUp from "./Auth/SignUp";
import ErrorPage from "../util/Errorpage";
// import Billing from "./Pages/Billing";
import PaymentSchedule from "./Pages/PaymentSchedule";
// import CreditCard from "./Pages/CreditCardInformation";
import CreditCard from "../components/CreditCard";
import LandingPage from "./Pages/LandingPage";
import ForgotAccount from "./Auth/ForgotAccount/forgotAccount";
import ForgotPassword from "./Auth/ForgotPassword";
import ForgotUser from "./Auth/ForgotUser/ForgotUser";
import successfulPayment from "./Pages/SuccesfullPayment";
import AccountMyDetails from "./Pages/AccountMyDetails";
import MyProfile from "./Pages/MyProfile/";
import Pages from "./Pages";
import ContactUs from "./Pages/ContactUs";
import ChangePassword from "./Pages/ChangePassword";
import ECheck from "./Pages/ECheck";
import PaymentDetails from "./Pages/PaymentDetails";
import PAScreens from "./Pages/PAScreens";
import Billing from "./Pages/Billing";
import PALogin from "./Pages/PAScreens/PALogin";
import QuickpayEcheck from "./Pages/QuickpayEcheck";
import QuickPayPaymentMethod from "./Pages/QuickPayPaymentMethod";
import { Password } from "./Auth/SignIn/Password";
import AdminPassword from "./Auth/Admin/Password";
import Admin from "./Auth/Admin";
import PatientDetails from "./Pages/PatientDetails/index";
import QuickPayLanding from "./Pages/Quickpay/LandingPage";
import Paynow from "./Pages/Quickpay/Payment/index";
import Stepper from "./Pages/Quickpay/Stepper";
import AdminForgotPassword from "./Auth/Admin/AdminForgotPass";
import AddCardContainer from "../containers/PaymentMethodsContainer/AddCardContainer";
import EcheckCardContainer from "../containers/PaymentMethodsContainer/AddEchequeContainer";
import ThankYou from "./Pages/Quickpay/TranscationContainer/index";
import PP_TransactionFailContainer from "../containers/PP_Transaction_Fail/PP_TransactionFailContainer";
import PP_Payment_Receipt from "./Pages/Quickpay/LandingPage/ViewReciept";
import PaymentSchedule2 from "./Pages/Quickpay/Payment/FuturePay";
import MakePayment from "./Pages/Quickpay/QPlandingPage/MakePayment";
import NewErrorBoundaryComponent from "../util/NewErrorBoundary";
import BillingEcheque from "./Pages/Quickpay/E-Cheque/index";
import PayPlanOptions from "./Pages/PayPlanOptions";
import ResetPassword from "./Pages/ResetPassword";
import { AutoLoginPage } from "./Auth/SignIn/autoLogin";
import { AutoLoginContainer } from "../containers/login/autoLoginContainer";
// eslint-disable-next-line no-unused-vars

const Routes = () => {
  return (
    <React.Fragment>
      <Switch>
        {/* may require */}
        {/* <Route
          path={`/billing`}
          component={
            localStorage.getItem("token")
              ? Billing
              : ErrorPage
          }
        />
        <RestrictedRoute
          path="/billing"
          authUser={authUser}
          component={Billing}
        /> */}
        <Route path="/home" component={Pages} />
        <Route path="/pa" component={PAScreens} />
        <Route path="/pa-login" component={PALogin} />
        <Redirect exact from="/" to="signin" />
        <Route path="/signin" component={SignIn} />
        <Route path="/admin" component={Admin} />
        {/* <Route path="/signup" component={SignUp} /> */}
        <Route path="/payment-schedule" component={PaymentSchedule} />
        <Route path="/credit-Card" component={AddCardContainer} />
        <Route path="/payment-scheduled" component={PaymentSchedule2} />
        <Route path="/payment-info" component={QuickPayPaymentMethod} />
        <Route path="/landing-Page" component={LandingPage} />
        <Route path="/registration" component={LandingPage} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/admin-forgot-password" component={AdminForgotPassword} />
        <Route path="/successfull-payment" component={successfulPayment} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/account-myDetails" component={AccountMyDetails} />
        <Route path="/my-profile" component={MyProfile} />
        <Route path="/patient-details" component={PatientDetails} />
        <Route path="/payment-details" component={PaymentDetails} />
        <Route path="/bill-details" component={Billing} />
        <Route path="/echeck" component={ECheck} />
        <Route path="/password" component={Password} />
        <Route path="/payment-CreditCard" component={Paynow} />
        <Route path="/admin-password" component={AdminPassword} />
        <Route path="/quickpay-echeck" component={QuickpayEcheck} />
        <Route path="/forgot-account" component={ForgotAccount} />
        <Route path="/forgot-user" component={ForgotUser} />
        <Route path="/Transaction-Fail" component={PP_TransactionFailContainer} />
        <Route path="/payment-outstanding" component={MakePayment} />
        <Route path="/autoLogin" component={AutoLoginContainer} />
        {/* <Route path="/add-echeck" component={EcheckCardContainer} /> */}
        <Route path="/payment-echeck" component={BillingEcheque} />
        <Route path="/thank-you" component={ThankYou} />
        <Route path={`/view-reciept`} component={PP_Payment_Receipt} />
        <Route path={`/pay-plan-options`} component={PayPlanOptions} />
        <Route path={`/Reset-Password`} component={ResetPassword} />
        <Route path="*" component={NewErrorBoundaryComponent} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
