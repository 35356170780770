import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { postTheReverseTransaction } from "../../../action/voidAndRefundAction.js";
import { formatAmount } from "../../../util/amountFunction.js";
import { InputAdornment, OutlinedInput, TextField } from "@material-ui/core";
import { handleSuccess, handleError } from "../../../components/Notifications/Toast";
import NumberFormat from "react-number-format";
const RefundDetails = ({ refundTransactionData, handelclose, postTheReverseTransaction }) => {
  const [checked, setChecked] = useState(false);
  const [fieldValuues, setFieldValues] = useState({});
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(false);
  const {
    authorizationCode,
    refundOrderDetails,
    orderNumber,
    totalRefundAmount,
    transactionDate,
    transactionRefId,
    transactionNumber,
    firstName,
    lastName,
  } = refundTransactionData;

  const sumOfRefundAmount = () => {
    let sum = 0;
    for (const key in fieldValuues) {
      sum = Number(sum) + Number(fieldValuues[key]);
    }
    return sum;
  };

  const columns = [
    {
      name: "seedAccountNumber",
      label: "Seed Account Number",
      field: "seedAccountNumber",
      options: {
        sort: false,
        sortFilterList: false,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
              <b>Seed Account Number</b>
            </div>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
              }}>
              {value}
            </div>
          );
        },
      },
    },
    {
      name: "accountNumber",
      label: "Account Number",
      field: "accountNumber",
      options: {
        sort: false,
        sortFilterList: false,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
              <b>Account Number</b>
            </div>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>{value}</div>;
        },
      },
    },
    {
      name: "paidAmount",
      label: "Paid Balance",
      field: "paidAmount",
      options: {
        sort: false,
        sortFilterList: false,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
              <b>Paid Balance</b>
            </div>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
              }}>
              ${formatAmount(value)}
            </div>
          );
        },
      },
    },
    {
      name: "refundAmount",
      label: "Refund Amount",
      field: "refundAmount",
      options: {
        sort: false,
        sortFilterList: false,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
              <b>Refund Amount</b>
            </div>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="form-group">
              <div className="void-input">
                <TextField
                  className="form-control signup-fields p-0"
                  type="text"
                  name={`text_${tableMeta.rowIndex}`}
                  onChange={(e) => {
                    console.log(e.target.value, "value");
                    setFieldValues({
                      ...fieldValuues,
                      [tableMeta.rowIndex]: e.target.value,
                    });
                  }}
                  placeholder={formatAmount(value)}
                  value={
                    checked
                      ? formatAmount(tableMeta.rowData[2])
                      : fieldValuues[tableMeta.rowIndex] || fieldValuues[tableMeta.rowIndex] === ""
                      ? fieldValuues[tableMeta.rowIndex]
                      : ""
                  }
                  aria-label="text-field"
                  fullWidth
                  disabled={checked}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className="pl-3">
                        $
                      </InputAdornment>
                    ),
                    inputComponent: NumberFormat,
                  }}></TextField>
                {/* $<input type="text"   onChange={(e) => 
                          { 
                            
                            
                        }
                            }  /> */}
              </div>

              <div>
                {!checked &&
                fieldValuues[tableMeta.rowIndex] &&
                Number(fieldValuues[tableMeta.rowIndex]) > Number(tableMeta.rowData[2]) ? (
                  <p className="error-text">Please Enter Valid Refund Amount</p>
                ) : Math.sign(Number(fieldValuues[tableMeta.rowIndex])) === -1 && !checked ? (
                  <p className="error-text">Please Enter Valid Refund Amount</p>
                ) : null}
              </div>
            </div>
          );
        },
      },
    },
  ];
  const tableHeaderStyle = {
    textAlign: "inherit",
    color: "#000000",
    fontWeight: "500",
    padding: "15px",
    fontSize: "14px",
    fontFamily: "Poppins",
    whiteSpace: "nowrap",
  };
  const rowStyle = {
    height: "30px",
    fontSize: "14px",
    verticalAlign: "baseline",
  };
  const cellStyle = {
    padding: "0px 16px 16px 16px",
  };
  const options = {
    jsonMode: true,
    filter: false,
    filterType: false,
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    pagination: false,
    headerStyle: tableHeaderStyle,
    rowStyle: rowStyle,
    cellStyle: cellStyle,
    selectableRows: "none", // comment to show checkboxes

    responsive: "scroll",
  };
  const button = {
    marginRight: "16px",
    border: "none",
    background: "#073a5e",
    color: "white",
    padding: " 5px 10px",
    borderRadius: "5px",
  };
  return (
    <div div className="void-refund-container input-field">
      <div className="form-row">
        {/* <div  style={continer}>
                <h3 className=""><b>Card Number:</b> {cardNumber}</h3>
                <h3 className=""><b>Card Holder:</b> {cardHolder}</h3>
            </div> */}
        <div className="col-sm-6 col-md-6 col-lg-4 form-group">
          <div className="void-refund-details ">
            <div className="label mr-1">Transaction Date:</div>
            <div className="value">{transactionDate.split(" ")[0]}</div>
          </div>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-4 form-group">
          <div className="void-refund-details ">
            <div className="label mr-1">Paid Amount:</div>
            <div className="value">${formatAmount(totalRefundAmount)}</div>
          </div>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-4 form-group">
          <div className="void-refund-details ">
            <div className="label mr-1"> Authorization Code:</div>
            <div className="value">{authorizationCode}</div>
          </div>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-4 form-group ">
          <div>
            <input
              type="checkbox"
              id="refund"
              name="refund"
              className="refund-check"
              value={reason}
              onChange={() => {
                setChecked((checked) => !checked);
                setFieldValues({});
              }}
            />
            <label htmlFor="refund" className="label mb-0">
              Full Refund
            </label>
          </div>
        </div>
        <div className="col-sm-6 col-md-12 col-lg-8 form-group">
          <div className="form-row ">
            <div>
              <label htmlFor="refundRe" className="label mr-2" required>
                Refund reason <sup style={{ color: "red" }}>*</sup>
              </label>
            </div>
            <div>
              <input
                type="text"
                id="refundRe"
                name="refundRe"
                onChange={(e) => {
                  setReason(e.target.value);
                  reasonError && setReasonError(false);
                }}
                placeholder="Refund Reason"
              />
              <p className="error-text">{reasonError ? `Refund reason required` : null}</p>
              <p className="error-text">{reason.length > 150 ? `You can't use more than 150 characters` : null}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="void-refund-table">
        <MUIDataTable data={refundOrderDetails ? refundOrderDetails : []} columns={columns} options={options} />
      </div>
      <div className="d-flex justify-content-center mt-4 mb-2">
        <button
          onClick={() => {
            const payload = {
              refundOrderDetails: checked
                ? refundOrderDetails.map((ele) => {
                    return {
                      ...ele,
                      refundAmount: ele.paidAmount,
                    };
                  })
                : refundOrderDetails.map((ele, i) => {
                    return {
                      ...ele,
                      refundAmount: fieldValuues[i] ? Number(fieldValuues[i]) : ele.refundAmount,
                    };
                  }),
              orderNumber: orderNumber,
              transactionRefId: transactionRefId,
              action: "reverse",
              totalRefundAmount: checked ? totalRefundAmount : sumOfRefundAmount(),
              transactionNumber: transactionNumber,
              firstName: firstName,
              lastName: lastName,
              authorizationCode: authorizationCode,
              refundReason: reason,
            };

            if (reason.length <= 150 && reason.length !== 0) {
              const array = Object.keys(fieldValuues);

              const check = array.reduce(
                (accumulator, element, index) => {
                  if (
                    fieldValuues[element] <= refundOrderDetails[index].paidAmount &&
                    Math.sign(Number(fieldValuues[element])) !== -1
                  ) {
                    accumulator[0].push(true);
                  } else {
                    accumulator[1].push(true);
                  }
                  return accumulator;
                },
                [[], []],
              );
              if (check[1].length === 0 && !checked) {
                postTheReverseTransaction(payload, (res) => {
                  res.responseInfo.status === "SUCCESSFUL"
                    ? handleSuccess(`${res.responseInfo.responseMessage}`)
                    : handleError("Something Went Wrong");
                  handelclose();
                });
              }
              if (checked) {
                postTheReverseTransaction(payload, (res) => {
                  res.responseInfo.status === "SUCCESSFUL"
                    ? handleSuccess(`${res.responseInfo.responseMessage}`)
                    : handleError("Something Went Wrong");
                  handelclose();
                });
              }
            } else {
              reason.length === 0 && setReasonError(true);
            }
          }}
          aria-label="Refund"
          className="themeing-buttons primary-button ml-2">
          Refund
        </button>
        <button onClick={() => handelclose()} aria-label="Cancel" className="secondary-button ml-2" type="button">
          Cancel
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      postTheReverseTransaction,
    },
    dispatch,
  );
export default compose(connect(mapStateToProps, mapDispatchToProps))(RefundDetails);
