import { SurveyService } from "../services/SurveyService";
export const GET_QUESTIONS = "GET_QUESTIONS";
export const POST_SURVEY_DATA = "POST_SURVEY_DATA";
export const GET_SURVEY_ANSWERS = "GET_SURVEY_ANSWERS";

export function getquestions() {
  return {
    type: GET_QUESTIONS,
  };
}

export function getQuestionsList(fn) {
  return (dispatch) => {
    SurveyService.getQuestions((res) => {
      dispatch(getquestions());
      fn(res.data);
    });
  };
}

export function postSurveyData(postData, fn) {
  return (dispatch) => {
    dispatch({
      type: POST_SURVEY_DATA,
      payload: postData,
    });
    SurveyService.postSurvey(postData, (res) => fn(res));
  };
}

export function getSurveyAnswersList(postData, fn) {
  return (dispatch) => {
    dispatch({
      type: GET_SURVEY_ANSWERS,
      payload: postData,
    });
    SurveyService.getSurveyAnswers(postData, (res) => fn(res));
  };
}

export function getSurveyAnswersListById(id, fn) {
  return (dispatch) => {
    SurveyService.getSurveyAnswersById(id, (res) => {
      fn(res?.data);
    });
  };
}

export function getSurveyChartData(fn) {
  return (dispatch) => {
    SurveyService.getSurveychart((res) => {
      fn(res);
    });
  };
}
