/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import DatePicker from "@material-ui/lab/DatePicker";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import {
  OutlinedInput,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
} from "@material-ui/core";
import SpinnerPage from "../../../Common/PP_loader";
import Med1Logo from "../../../assets/images/Med1Logo.png";
import { Link } from "react-router-dom";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import * as Yup from "yup";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import RocketIcon from "../../../assets/images/quick-pay-rocket-icon.svg";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { handleSuccess, ToastContainer, handleError, handleWarning } from "../../../components/Notifications/Toast";
import { identifyPayerService } from "../../../services/identifyPayerService";
import { userService } from "../../../services/userDetalService";
import { RegionManagementService } from "../../../admin_services/regionManagementService";
import { securityImageService } from "../../../core_services/securityImageService";
import { partyGetService } from "../../../core_services/partyService";
import { Translation } from "react-i18next";
import { I18nextProvider } from "react-i18next";
import i18n from "../../../i18n";
import { requestInfo_PP, requestInfo_Core } from "../../../Common/functions";
import $ from "jquery";
// import { GoogleAnalystics } from "../../commons/commonfunctions/googleAnalytics";
import { whiteLabelService } from "../../../admin_services/whiteLabelService";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";

export default class PP_CreateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: true,
      disableSubmit: true,
      setDisableSubmit: true,
      recaptcha: "",
      showssn: false,
      showdob: false,
      showemail: false,
      showcontact: false,
      showloader: false,
      submitted: false,
      firstName: "",
      middlename: "",
      lastname: "",
      dob: "",
      gender: "",
      ssn: "",
      emailId: "",
      phoneno: "",
      country: "united states of america",
      state: "",
      city: "",
      streetaddress1: "",
      streetaddress2: "",
      Zipcode: "",
      countryList: [],
      cityList: [],
      pwdlength: false,
      pwdonecap: false,
      pwdonesmall: false,
      pwdonenum: false,
      pwdonespl: false,
      pwdnospace: false,
      toggleform: false,
      userpartyId: "",
      addressid: "",
      isupdated: "",
      ver_email: "",
      ver_mobile: "",
      auth_email: "",
      auth_mobile: "",
      newdate: "",
      showSsn: true,
      showPatientDob: true,
      isCommunicate: true,
      showPassword: false,
      showConfirmPassword: false,
      image: "Select Image",
      showSSN: false,
      isLoading: false,
      userLoginName: "",
    };
  }

  _handleSubmit = () => {
    const { values } = this.props;
    var myDate = values?.patientDoB;
    var mydate1 = "";
    if (myDate) {
      var mm = myDate.getMonth() + 1;
      var dd = myDate.getDate();
      var yy = myDate.getFullYear();
      var hh = myDate.getHours();
      var mn = myDate.getMinutes();
      var ss = myDate.getSeconds();

      mydate1 =
        (mm > 9 ? mm : "0" + mm) +
        "/" +
        (dd > 9 ? dd : "0" + dd) +
        "/" +
        yy +
        " " +
        (hh > 9 ? hh : "0" + hh) +
        ":" +
        (mn > 9 ? mn : "0" + mn) +
        ":" +
        (ss > 9 ? ss : "0" + ss);
    }

    const PassValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      payerBillSearch: {
        accountReferenceNumber: values?.med1AccNum,
        uniqueIdentityId: values?.ssn,
        dob: mydate1,
        contactNumber: values?.phone,
        contactEmail: values?.email,
      },
    };

    this.setState({
      countrycode: values?.countrycoder,
      mobileno: values?.phoneno,
    });

    const UserId = "1212";
    identifyPayerService.addIdentifyPayer(
      PassValues,
      UserId,
      (res) => {
        if (res["data"].responseInfo.status === "SUCCESSFUL") {
          this.setState({ isLoading: false });

          sessionStorage.setItem("verifypayer", JSON.stringify(res["data"].billGroups[0].bills[0]));
          sessionStorage.setItem(
            "Verifieduser",
            res["data"].billGroups[0].bills[0].payer.firstName + " " + res["data"].billGroups[0].bills[0].payer.lastName,
          );
          sessionStorage.setItem("payerinfo", JSON.stringify(PassValues.payerBillSearch));

          this.setState({
            firstName: res["data"].billGroups[0].bills[0].payer.firstName,
            firstName1: res["data"].billGroups[0].bills[0].payer.firstName,
            middlename: res["data"].billGroups[0].bills[0].payer.middleName,
            lastname: res["data"].billGroups[0].bills[0].payer.lastName,
            lastname1: res["data"].billGroups[0].bills[0].payer.lastName,
            dob: res["data"].billGroups[0].bills[0].payer.dob,
            dob1: res["data"].billGroups[0].bills[0].payer.dob,
            gender: "",
            ssn: res["data"].billGroups[0].bills[0].payer.uniqueIdentifierId,
            ssn1: res["data"].billGroups[0].bills[0].payer.uniqueIdentifierId,
            emailId: res["data"].billGroups[0].bills[0].payer.contactEmail,
            phoneno: res["data"].billGroups[0].bills[0].payer.contactNo,
            countryid: res["data"].billGroups[0].bills[0].payer?.addressVo?.countryId || "",
            country:
              res["data"].billGroups[0].bills[0].payer?.addressVo?.country.toLowerCase() || "united states of america",
            state: res["data"].billGroups[0].bills[0].payer?.addressVo?.state.toLowerCase() || "",
            stateid: res["data"].billGroups[0].bills[0].payer?.addressVo?.stateId || "",
            city: res["data"].billGroups[0].bills[0].payer?.addressVo?.city || "",
            cityid: res["data"].billGroups[0].bills[0].payer?.addressVo?.cityId || "",
            address1: res["data"].billGroups[0].bills[0].payer?.addressVo?.street || "",
            address2: res["data"].billGroups[0].bills[0].payer?.addressVo?.street2 || "",
            ZipCode:
              res["data"].billGroups[0].bills[0].payer?.addressVo?.postalCode.length > 5 &&
              !res["data"].billGroups[0].bills[0].payer?.addressVo?.postalCode.includes("-")
                ? res["data"].billGroups[0].bills[0].payer?.addressVo?.postalCode.substring(0, 5).concat("-") +
                  res["data"].billGroups[0].bills[0].payer?.addressVo?.postalCode.substring(
                    5,
                    res["data"].billGroups[0].bills[0].payer?.addressVo?.postalCode.length,
                  )
                : res["data"].billGroups[0].bills[0].payer?.addressVo?.postalCode,

            stateList: this.state.stateList ? this.state.stateList : [],
            cityList: this.state.cityList ? this.state.cityList : [],
            toggleform: true,
            userpartyId: res["data"].billGroups[0].bills[0].payer.partyId,
            userLoginName: res["data"]?.billGroups[0]?.bills[0]?.payer?.userloginName,
            addressid: res["data"].billGroups[0].bills[0].payer?.addressVo?.addressId || "",
          });
          sessionStorage.setItem("userpartyid", this.state.userpartyId);
          sessionStorage.setItem("countrycode", this.state.countrycoder);
          sessionStorage.setItem("mobileno", this.state.phoneno);
        } else if (res["data"].responseInfo.status === "FAILED") {
          this.setState({ isLoading: false });
          handleWarning("Please enter valid details!");
        } else {
          this.setState({ isLoading: false });

          handleWarning("Please enter valid details!");
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  getImg = () => {
    const passValues = { partyId: this.state.partydata, pageName: "AuthSecureImage", sectionName: "AuthSecureImage" };
    securityImageService.securityImageList(
      passValues,

      (res) => {
        if (res.data.status === "success") {
          this.setState({
            imgdata: res.data.responseObject.imageList[0].documentUrl,
            img2: res.data.responseObject.imageList[1].documentUrl,
            img3: res.data.responseObject.imageList[2].documentUrl,
            img4: res.data.responseObject.imageList[3].documentUrl,
            imgdataid: res.data.responseObject.imageList[0].documentId,
            img2id: res.data.responseObject.imageList[1].documentId,
            img3id: res.data.responseObject.imageList[2].documentId,
            img4id: res.data.responseObject.imageList[3].documentId,
            docid: res.data.responseObject.imageList[0].documentId,
            // img5:res.data.responseObject.imageList[4].documentUrl,
            // img6:res.data.responseObject.imageList[5].documentUrl
          });
        } else if (res["data"].responseInfo.status === "FAILED") {
          handleWarning("Oops..! " + res.data.responseInfo.responseMessage);
        } else {
          handleError("Something went wrong..! " + res.data.responseInfo.responseMessage);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    this._handleSubmit();
    document.title = "MED-1 Secure Login - Register";
    // GoogleAnalystics.GoogleAnalysticsLog("pageview");
    // GoogleAnalystics.GoogleAnalysticsEvent("a", "b", "c");
    // window.onbeforeunload = function () {return false;}
    window.scrollTo(0, 0);
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });

    const passValues = {
      countryId: "",
      countryName: "",
      limit: -1,
    };
    const userId = "1212";
    RegionManagementService.getCountry(passValues, userId, (res) => {
      if (res["data"].status === "success") {
        this.setState({
          countryList: res.data.responseListObject,
          country:
            res.data.responseListObject &&
            res.data.responseListObject.length > 0 &&
            res.data.responseListObject[0].countryName
              ? res.data.responseListObject[0].countryName
              : "united states of america",
        });

        var PassValues = {
          countryId: 1,
          limit: -1,
        };
        const UserId = "1234";
        RegionManagementService.getState(PassValues, UserId, (res) => {
          this.setState({
            stateList: res.data.responseListObject,
          });

          var payeraddrdata = JSON.parse(sessionStorage.getItem("billingpayerdata"));

          if (payeraddrdata[0].payer.addressVo.stateId) {
            var PassValues = {
              stateId: payeraddrdata[0].payer.addressVo.stateId,
            };
            const UserId = "1234";
            RegionManagementService.getCity(PassValues, UserId, (res) => {
              this.setState({
                cityList: res.data.responseListObject,
              });
            });
          }
        });
      }
    });

    // Get List of images
    const passValues1 = {
      partyTypeId: "100",
    };
    partyGetService.partyGetList(
      passValues1,

      (res) => {
        if (res.status === "success") {
          this.setState({ partydata: res.responseListObject[0].partyId });

          this.getImg();
        } else if (res["data"].responseInfo.status === "FAILED") {
          handleWarning("Oops..! " + res.data.responseInfo.responseMessage);
        } else {
          handleError("Something went wrong..! " + res.data.responseInfo.responseMessage);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }

  selectimg1 = (value) => {
    this.setState({
      selectedImage: this.state.imgdata,
    });
  };

  handleClick = (value, key) => () => {
    this.setState({
      selectedImage: value,
      docid: key,
      chosenimage: true,
      selectedsecurityimage: true,
    });
  };

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  };

  handleClickShowSSN = () => {
    this.setState({
      showSSN: !this.state.showSSN,
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  _createUser = (values, { actions }) => {
    this.setState({ isLoading: true });
    var cd = new Date();
    var mm = cd.getMonth() + 1;
    var dd = cd.getDate() - 1;
    var yy = cd.getFullYear();
    var hh = cd.getHours();
    var mn = cd.getMinutes();
    var ss = cd.getSeconds();

    var todaydate =
      (mm > 9 ? mm : "0" + mm) +
      "/" +
      (dd > 9 ? dd : "0" + dd) +
      "/" +
      yy +
      " " +
      (hh > 9 ? hh : "0" + hh) +
      ":" +
      (mn > 9 ? mn : "0" + mn) +
      ":" +
      (ss > 9 ? ss : "0" + ss);

    var todaydatenew =
      (dd > 9 ? dd : "0" + dd) +
      "/" +
      (mm > 9 ? mm : "0" + mm) +
      "/" +
      yy +
      " " +
      (hh > 9 ? hh : "0" + hh) +
      ":" +
      (mn > 9 ? mn : "0" + mn) +
      ":" +
      (ss > 9 ? ss : "0" + ss);

    if (values.verifymobile) {
      sessionStorage.setItem("verifymobilesection", true);
    } else {
      sessionStorage.setItem("verifymobilesection", false);
    }

    if (values.verifyemailid) {
      sessionStorage.setItem("verifyemailidsection", true);
    } else {
      sessionStorage.setItem("verifyemailidsection", false);
    }

    const userPassValues = {
      name: values.firstname + " " + values.lastname,
      userLoginName: values.username,
      userPassword: values.password,
      mobileNumber: values.usermobile,
      emailId: values.useremailid,
      isCommunicate: values.isCommunicate ? 1 : 0,
      authenticationType: "Local",
      partyID: this.state.userpartyId,
      userRoleMatrixList: [
        {
          roleId: {
            roleId: "4",
          },
        },
      ],
      forcePasswordChangeOnNextLoginFlag: "0",
      loginAttempt: 0,
      activateDate: todaydatenew,
      expiryDate: "02/02/2099 12:00:00",
      createdBy: "1",
      createdDate: todaydatenew,
      lastModifiedBy: "1",
      lastModifiedDateTime: todaydatenew,
      isActive: 1,
    };

    var checkpartyvalue = {
      partyID: this.state.userpartyId,
      userLoginName: this.state.userLoginName,
    };

    const userName = this.state.username;

    userService.searchUserName(
      userName,

      (res) => {
        if (res["data"].status === "success" && res["data"].responseObject !== null) {
          this.setState({ isLoading: false });
          handleError("User already exists!");
        } else {
          partyGetService.CreateUser(
            userPassValues,
            (res) => {
              if (res["data"].status === "success") {
                this.setState({ isLoading: false });
                handleSuccess("Registered Successfully!");
                var newuserid = res["data"].responseObject.userId;
                const newpassValues = {
                  userId: newuserid,
                  securityImagDocId: this.state.docid,
                };
                securityImageService.updateSecurityImageList(
                  newpassValues,

                  (res) => {
                    if (res.data.status === "success") {
                    } else if (res["data"].responseInfo.status === "FAILED") {
                      handleWarning("Oops..! " + res.data.responseInfo.responseMessage);
                    } else {
                      handleError("Something went wrong..! " + res.data.responseInfo.responseMessage);
                    }
                  },
                  (error) => {
                    console.log(error);
                  },
                );
                if (values.verifyemailid === true) {
                  const passValuesEmail = {
                    partyId: this.state.userpartyId,
                    emailId: values.useremailid,
                  };
                  partyGetService.VerifyUserEmail(
                    passValuesEmail,

                    (res) => {
                      if (res["data"].status === "success") {
                      } else if (res["data"].responseInfo.status === "FAILED") {
                      } else {
                      }
                    },
                    (error) => {
                      console.log(error);
                    },
                  );
                }
                const passValuesparty = {
                  requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
                  partyProfiles: [
                    {
                      partyId: this.state.userpartyId,
                      partyTypeId: 102,
                      appId: 11101,
                      buId: 2,
                      subBuId: 0,
                      partyAttribute: [],
                      lastName: values.lastname,
                      firstName: values.firstname,
                      contactNo: values.usermobile,
                      dob: this.state.newdob ? this.state.newdob : values.dob + " 00:00:00",
                      contactEmail: values.useremailid,
                      isActive: 1,
                      addressVo: [
                        {
                          addressId: this.state.addressid,
                          addressType: "HOME",
                          appId: 11101,
                          buId: 2,
                          subBuId: 0,
                          partyId: this.state.userpartyId,
                          street: values.address1 + ", " + values.address2,
                          street2: "",
                          street3: null,
                          city: values.city,
                          state: this.state.state,
                          country: this.state.country,
                          postalCode: values.ZipCode.replace("-", ""),
                          cityId: this.state.cityid,
                          stateId: this.state.stateid,
                          countryId: this.state.countryid,
                          locationLatLong: null,
                          createdBy: 1,
                          isActive: 1,
                          createdDate: todaydatenew,
                          lastModifiedBy: 1,
                          lastModifiedDate: todaydatenew,
                          errorMessage: null,
                        },
                      ],
                      createdBy: 1,
                      createdDate: todaydatenew,
                      lastModifiedBy: 1,
                      lastModifiedDate: todaydatenew,
                      uniqueIdentifierId: values.ssn,
                    },
                  ],
                };

                if (values.isupdated === true) {
                  partyGetService.UpdatePatientData(
                    passValuesparty,

                    (res) => {
                      if (res["data"].status === "success") {
                      } else if (res["data"].responseInfo.status === "FAILED") {
                      } else {
                      }
                    },
                    (error) => {
                      console.log(error);
                    },
                  );
                }
                if (values.verifymobile === true) {
                  const passValuesOtp = {
                    partyId: this.state.userpartyId,
                    countryCode: this.state.countrycoder,
                    mobileNumber: this.state.phoneno,
                  };
                  partyGetService.GenerateOtp(
                    passValuesOtp,

                    (res) => {
                      if (res["data"].status === "success") {
                       
                        var accesscode = res["data"].responseObject.verificationEncodedValue;
                        sessionStorage.setItem("otpaccesscode", accesscode);
                      } else if (res["data"].responseInfo.status === "FAILED") {
                      } else {
                      }
                    },
                    (error) => {
                      console.log(error);
                    },
                  );
                }
                const UserId = "1212";
                var Token = "";
                var jtiToken = "";
                const checkconsentValues = {
                  requestInfo: requestInfo_Core(Token, jtiToken),
                  partyId: this.state.userpartyId,
                };
                whiteLabelService.checkUserConsent(
                  checkconsentValues,
                  UserId,
                  (res) => {
                    if (res["data"].status === "Success") {
                      var consentdata = res["data"].responseListObject;
                      if (consentdata !== null) {
                        var updateconsent = "";
                        var consentjson = consentdata[0].consentSourceJson;
                        var temp = [];
                        temp = [
                          {
                            tag: "email",
                            value: values.emailuseconsent,
                          },
                          {
                            tag: "mobile",
                            value: values.mobileuseconsent,
                          },
                        ];
                        consentjson.map((consentjson, index) => {
                      
                          if (consentjson.tag !== "email" && consentjson.tag !== "mobile") {
                            temp.push(consentjson);
                          }
                        });
                        updateconsent = {
                          requestInfo: requestInfo_Core(Token, jtiToken),
                          buId: consentdata.buId,
                          subBuId: consentdata.subBuId,
                          partyId: consentdata.partyId,
                          partyType: 3,
                          partyConsentId: consentdata.partyConsentId,
                          consentObjectName: "CreditCardPayment",
                          consentActionValue: "1",
                          consentRecordedDatetime: new Date(),
                          consentSourceJson: temp,
                        };
                      } else {
                        updateconsent = {
                          requestInfo: requestInfo_PP(Token, jtiToken),
                          buId: sessionStorage.getItem("buid") !== null ? sessionStorage.getItem("buid") : "2",
                          subBuId: "1",
                          partyId: this.state.userpartyId,
                          partyType: 3,
                          consentObjectName: "CreditCardPayment",
                          consentActionValue: "1",
                          consentRecordedDatetime: new Date(),
                          consentSourceJson: [
                            {
                              tag: "email",
                              value: values.emailuseconsent,
                            },
                            {
                              tag: "mobile",
                              value: values.mobileuseconsent,
                            },
                          ],
                        };
                      }
                      whiteLabelService.saveUserConsent(
                        updateconsent,
                        UserId,
                        (res) => {
                          if (res["data"].status === "Success") {
                          } else if (res["data"].status === "fail") {
                          } else {
                          }
                        },
                        (error) => {
                          console.log(error);
                        },
                      );
                    } else {
                      // Add Consent
                      const updateconsent = {
                        requestInfo: requestInfo_Core(Token, jtiToken),
                        buId: sessionStorage.getItem("buid") !== null ? sessionStorage.getItem("buid") : "2",
                        subBuId: "1",
                        partyId: this.state.userpartyId,
                        partyType: 3,
                        consentObjectName: "CreditCardPayment",
                        consentActionValue: "1",
                        consentRecordedDatetime: new Date(),
                        consentSourceJson: [
                          {
                            tag: "email",
                            value: values.emailuseconsent,
                          },
                          {
                            tag: "mobile",
                            value: values.mobileuseconsent,
                          },
                        ],
                      };

                      whiteLabelService.saveUserConsent(
                        updateconsent,
                        UserId,
                        (res) => {
                          if (res["data"].status === "Success") {
                          } else if (res["data"].status === "fail") {
                          } else {
                          }
                        },
                        (error) => {
                          console.log(error);
                        },
                      );
                    }
                  },
                  (error) => {
                    console.log(error);
                  },
                );
                setTimeout(function () {
                  if (values.verifymobile === true) {
                    window.location.replace("/VerifyDetails");
                  } else if (values.verifyemailid === true) {
                    window.location.replace("/VerifyDetails");
                  } else {
                    window.location.replace("/");
                  }
                }, 3000);
              } else {
                this.setState({ isLoading: false });

                handleWarning(res["data"]?.reasonText);
              }
            },
            (error) => {
              if (error["data"].status === "success") {
                handleSuccess("Registered Successfully!");
                setTimeout(function () {
                  window.location.replace("/");
                }, 2000);
              } else {
                handleWarning(error["data"]?.reasonText);
              }
            },
          );
        }
      },
    );
  };

  _userValidation = Yup.object().shape({
    firstname: Yup.string()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters ,dash(-), dot(.), tilled and apostrophes(') are allowed")
      .min(1, "Please enter at least 1 Character")
      .max(20, "Max 20 characters allowed")
      .required("First Name is required"),
    middlename: Yup.string()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters, dash(-), dot(.), tilled and apostrophes(') are allowed")
      .min(1, "Please enter at least 1 Character")
      .max(20, "Max 20 characters allowed")
      .notRequired(),
    lastname: Yup.string()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters, dash(-), dot(.), tilled and apostrophes(') are allowed")
      .min(1, "Please enter at least 1 Character")
      .max(20, "Max 20 characters allowed"),

    ssn: Yup.string()
      .matches(/^[0-9]+$/, "Enter 9 digits Social Security Number")
      .min(9, "Enter 9 digits Social Security Number")
      .max(9, "Enter 9 digits Social Security Number"),
    // dob: Yup
    //     .string().required(<Translation>
    //         {
    //             (t, { i18n }) => <label >{t("dob_required")}</label>
    //         }
    //     </Translation>)
    // ,
    securityimageselected: Yup.string().required("Select Security Image"),
    useremailid: Yup.string().nullable().email("Please enter a valid Email Address").required("Email Address is required"),
    usermobile: Yup.string()
      .nullable()
      .trim()
      .matches(/^\d+\.?\d*$/, "Enter digits only")
      .min(10, "Please enter 10 digits Phone Number")
      .max(10, "Please enter 10 digits Phone Number")
      .required("Phone Number is required"),
    country: Yup.string().notOneOf(["select"], "Country is required").required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string()
      .nullable()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters , dash(-), dot(.), tilled and apostrophes(') are allowed")
      .required("City is required"),
    address1: Yup.string().required("Street Address is required"),
    ZipCode: Yup.string()
      .nullable()
      .trim()
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/g, "Please enter valid ZIP Code")
      .test("Invalid ZIP Code", (value) => !/^([0-9])\1{4,}$/.test(value))
      .required("ZIP Code is required")
      .test("ZipCode", "ZIP Code is invalid", (value) => value !== "00000-0000"),

    username: Yup.string()
      .nullable()
      .trim()
      .matches(/^[0-9-a-z-A-Z-.@]+$/g, "Only letters, numbers, dash(-), dot(.), @ are allowed")
      .min(3, "Please enter at least 3 Characters")
      .required("Username is required"),
    password: Yup.string()
      .nullable()
      .min(8, "Password must be minimum 8 characters")
      .max(20, "Password must be maximum 20 characters")
      .matches(/[a-z]/, "Password should have at least one lowercase character")
      .matches(/[A-Z]/, "Password should have at least one uppercase character")
      .matches(/(?=.*\d)/, "Password must contain at least one digit")
      .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one special character")
      .matches(/^\S*$/, "Password should not contain whitespace")
      .required("Password is required"),
    confirmpassword: Yup.string()
      .nullable()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  getAddressObject = (address_components) => {
    const ShouldBeComponent = {
      street_number: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      province: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      country: ["country"],
    };

    let address = {
      street_number: "",
      postal_code: "",
      street: "",
      province: "",
      city: "",
      country: "",
    };

    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "country") {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });
    // Fix the shape to match our schema
    address.address = address?.street_number + " " + address?.street;
    delete address.street_number;
    delete address.street;
    if (address.country === "US") {
      address.state = address.province;
      delete address.province;
    }
    return address;
  };
  getGoogleApiInfo = async (values, val) => {
    const geocodeObj = val && val.value && (await geocodeByPlaceId(val.value.place_id));
    const addressObject = geocodeObj && this.getAddressObject(geocodeObj[0].address_components);

    addressObject
      ? this.setState({
          ...values,
          country:
            addressObject?.country.toLocaleLowerCase() === "us" || addressObject.country.toLocaleLowerCase() === "usa"
              ? "united states of america"
              : addressObject.country,
          state: addressObject?.state,
          city: addressObject?.city,
          zipCode: addressObject?.postal_code,
          address1: addressObject?.address.trim(),
          address2: "",
          googleaddress: val,
          securitycode: values?.securitycode,
          cardCvvNumber: values?.securitycode,
        })
      : this.setState({
          ...values,
          googleaddress: "clear",
        });
  };
  render() {
    return (
      <>
        <div className="page-wrapper vertical-height" role="main">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="card-md registration-card-container">
                <div className="header">
                  <div className="rocket-icon">
                    <img src={RocketIcon} alt="" />
                  </div>
                  <div role="heading" aria-level="1" className="logo registration-card-logo">
                    <img src={Med1Logo} className="img-fluid" alt="Med-1 Solutions Logo" />
                  </div>
                </div>
                <div className="main-heading"></div>
                <div className="login-content registration-content">
                  <I18nextProvider i18n={i18n}>
                    {this.state.isLoading === true ? <SpinnerPage /> : ""}
                    <Formik
                      enableReinitialize
                      initialValues={{
                        firstname: this.state.firstName ? this.state.firstName : "",
                        middlename: this.state.middleName ? this.state.middleName : "",
                        lastname: this.state.lastname ? this.state.lastname : "",
                        dob: this.state.dob ? this.state.dob : "",
                        stateList: this.state.stateList ? this.state.stateList : [],
                        cityList: this.state.cityList ? this.state.cityList : [],
                        ssn: this.state.ssn ? this.state.ssn : "",
                        useremailid: this.state.emailId ? this.state.emailId : "",
                        usermobile: this.state.phoneno ? this.state.phoneno : "",
                        country: this.state.country ? this.state.country : "",
                        countryid: this.state.countryid ? this.state.countryid : "",
                        state: this.state.state ? this.state.state.toLowerCase() : "",
                        stateid: this.state.stateid ? this.state.stateid : "",
                        city: this.state.city ? this.state.city : "",
                        cityid: this.state.cityid ? this.state.cityid : "",
                        address1: this.state.address1 ? this.state.address1 : "",
                        address2: this.state.address2 ? this.state.address2 : "",
                        ZipCode: this.state.ZipCode ? this.state.ZipCode : "",
                        username: this.state.username ? this.state.username : "",
                        password: this.state.password ? this.state.password : "",
                        confirmpassword: this.state.confirmpassword ? this.state.confirmpassword : "",
                        emailuseconsent: this.state.auth_email ? this.state.auth_email : true,
                        mobileuseconsent: this.state.auth_mobile ? this.state.auth_mobile : true,
                        verifyemailid: this.state.ver_email ? this.state.ver_email : false,
                        verifymobile: this.state.ver_mobile ? this.state.ver_mobile : false,
                        securityimageselected: this.state.selectedsecurityimage ? this.state.selectedsecurityimage : "",
                        isupdated: this.state.isupdated === true ? true : false,
                        showSsn: this.state.showSsn,
                        showPatientDob: this.state.showPatientDob,
                        isCommunicate: this.state.isCommunicate,
                        // disableSubmit:true,
                        // setDisableSubmit:true
                      }}
                      validationSchema={this._userValidation}
                      onSubmit={this._createUser}>
                      {(props) => {
                        const { values, touched, error, handleChange, errors, setFieldValue } = props;
                        return (
                          <Form
                            id="landing-page"
                            autoComplete="off"
                            className="input-field landing-page-form"
                            noValidate="noValidate">
                            <div className="input-field">
                              <div className="form-row">
                                <div className="form-group col-md-6 col-sm-6">
                                  <div className="row m-0 justify-content-between align-items-center">
                                    <label htmlFor="userName" className="required">
                                      First Name
                                    </label>
                                  </div>
                                  <OutlinedInput
                                    className="form-control signup-fields"
                                    maxLength="50"
                                    autocomplete="new-password"
                                    id="firstname"
                                    name="firstname"
                                    defaultValue={this.state.firstName}
                                    value={values?.firstname}
                                    disabled={this.state.firstName1 ? true : false}
                                    placeholder={i18n.t("User_Firstname_Placeholder")}
                                    onChange={(event) => {
                                      setFieldValue(((values.firstname = event.target.value), (values.isupdated = true)));

                                      this.setState({
                                        firstName: event.target.value,
                                        isupdated: true,
                                      });
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    startAdornment={
                                      <InputAdornment position="end">
                                        <PermIdentityIcon />
                                      </InputAdornment>
                                    }
                                    inputProps={{
                                      maxLength: 50,
                                    }}></OutlinedInput>
                                  <ErrorMessage component="div" name="firstname" className="error-text" />
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                  <div className="row m-0 justify-content-between align-items-center">
                                    <label htmlFor="userName" className="required">
                                      Last Name
                                    </label>
                                  </div>
                                  <OutlinedInput
                                    className="form-control signup-fields"
                                    type="text"
                                    maxLength="50"
                                    autocomplete="new-password"
                                    id="lastname"
                                    name="lastname"
                                    value={values?.lastname}
                                    disabled={this.state.lastname1 ? true : false}
                                    placeholder={i18n.t("User_Lastname_Placeholder")}
                                    onChange={(event) => {
                                      setFieldValue(((values.middlename = event.target.value), (values.isupdated = true)));

                                      this.setState({
                                        lastname: event.target.value,
                                        isupdated: true,
                                      });
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    startAdornment={
                                      <InputAdornment position="end">
                                        <PermIdentityIcon />
                                      </InputAdornment>
                                    }
                                    required="true"
                                    inputProps={{
                                      maxLength: 50,
                                    }}></OutlinedInput>
                                  <ErrorMessage component="div" name="lastname" className="error-text" />
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Grid container className=" date-picker">
                                      <label htmlFor="patientDOB" className="form-label w-100">
                                        Patient's Date of Birth
                                      </label>
                                      <DatePicker
                                        disableFuture
                                        name="patientDOB"
                                        monthPlaceholder={`${values.showPatientDob && values.dob ? "**" : "MM"}`}
                                        dayPlaceholder={`${values.showPatientDob && values.dob ? "**" : "DD"}`}
                                        yearPlaceholder={`${values.showPatientDob && values.dob ? "****" : "YYYY"}`}
                                        disabled={this.state.dob1 ? true : false}
                                        autoOk
                                        value={!values.showPatientDob && values.dob}
                                        onChange={(value) => {
                                          var cd = new Date(value);
                                          var mm = cd.getMonth() + 1;
                                          var dd = cd.getDate();
                                          var yy = cd.getFullYear();

                                          var patientdob = dd + "/" + mm + "/" + yy + " " + "00:00:00";

                                          setFieldValue(
                                            ((values.dateOfBirth1 = value), (values.isupdated = true)),
                                            // values.newdob = patientdob
                                          );
                                          this.setState({
                                            dob: value,
                                            newdob: patientdob,
                                            isupdated: true,
                                          });
                                        }}
                                        renderInput={(params) => {
                                          return <TextField {...params} helperText={null} margin="dense" />;
                                        }}
                                      />
                                    </Grid>
                                  </LocalizationProvider>
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                  <div className="row m-0 justify-content-between align-items-center">
                                    <label htmlFor="userPassword" className="required">
                                      Social Security Number{" "}
                                    </label>
                                  </div>
                                  <OutlinedInput
                                    className="form-control signup-fields"
                                    type={this.state.showSSN ? "text" : "password"}
                                    maxLength="9"
                                    autocomplete="new-password"
                                    id="ssn"
                                    name="ssn"
                                    disabled={this.state.ssn1 ? true : false}
                                    placeholder={i18n.t("patientDetail_placeholderssn2")}
                                    value={values?.ssn}
                                    onChange={(event) => {
                                      setFieldValue(((values.ssn = event.target.value), (values.isupdated = true)));

                                      this.setState({
                                        ssn: event.target.value,
                                        isupdated: true,
                                      });
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={this.handleClickShowSSN}
                                          onMouseDown={this.handleMouseDownPassword}>
                                          {this.state.showSSN ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    required="true"
                                    inputProps={{
                                      maxLength: 9,
                                    }}></OutlinedInput>
                                  <ErrorMessage component="div" name="ssn" className="error-text" />
                                </div>
                              </div>
                            </div>

                            <div className="input-field">
                              <div className="form-row">
                                <div className="col-md-6 col-sm-6">
                                  <div className="form-group">
                                    <div className="">
                                      <label htmlFor="userName" className="required">
                                        Username
                                      </label>
                                    </div>
                                    <OutlinedInput
                                      className="form-control signup-fields"
                                      type="text"
                                      maxLength="50"
                                      autocomplete="new-password"
                                      id="username"
                                      name={"username" + Math.random()}
                                      value={values.username}
                                      placeholder={i18n.t("patient_Username")}
                                      onChange={(event) => {
                                        setFieldValue((values.username = event.target.value));
                                        this.setState({
                                          username: event.target.value,
                                        });
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      startAdornment={
                                        <InputAdornment position="end">
                                          <PermIdentityIcon />
                                        </InputAdornment>
                                      }
                                      required="true"
                                      inputProps={{
                                        maxLength: 50,
                                      }}></OutlinedInput>
                                    <ErrorMessage component="div" name="username" className="error-text" />
                                  </div>
                                  <div className="form-group">
                                    <div className="">
                                      <label htmlFor="userPassword" className="required">
                                        Password
                                      </label>
                                    </div>
                                    <OutlinedInput
                                      className="form-control signup-fields"
                                      maxLength="20"
                                      autocomplete="new-password"
                                      id="password"
                                      name={"password" + Math.random()}
                                      value={values.password}
                                      placeholder="Password"
                                      onChange={(event) => {
                                        setFieldValue((values.password = event.target.value));

                                        this.setState({
                                          password: event.target.value,
                                        });

                                        var str = event.target.value;
                                        var pattern1 = new RegExp(/[a-z]/);
                                        var pattern2 = new RegExp(/[A-Z]/);
                                        var pattern3 = new RegExp(/(?=.*\d)/);
                                        var pattern4 = new RegExp(/[!@#$%^&*(),.?\":{}|<>]/);
                                        var pattern5 = new RegExp(/^\S*$/);

                                        if (str.length > 8 || str.length < 20) {
                                          this.setState({
                                            pwdlength: true,
                                          });
                                        } else {
                                          this.setState({
                                            pwdlength: false,
                                          });
                                        }

                                        if (pattern1.test(str)) {
                                          this.setState({
                                            pwdonesmall: true,
                                          });
                                        } else {
                                          this.setState({
                                            pwdonesmall: false,
                                          });
                                        }

                                        if (pattern2.test(str)) {
                                          this.setState({
                                            pwdonecap: true,
                                          });
                                        } else {
                                          this.setState({
                                            pwdonecap: false,
                                          });
                                        }

                                        // Number validation
                                        if (pattern3.test(str)) {
                                          this.setState({
                                            pwdonenum: true,
                                          });
                                        } else {
                                          this.setState({
                                            pwdonenum: false,
                                          });
                                        }

                                        // Number special char
                                        if (pattern4.test(str)) {
                                          this.setState({
                                            pwdonespl: true,
                                          });
                                        } else {
                                          this.setState({
                                            pwdonespl: false,
                                          });
                                        }

                                        // space check
                                        if (pattern5.test(str)) {
                                          this.setState({
                                            pwdnospace: true,
                                          });
                                        } else {
                                          this.setState({
                                            pwdnospace: false,
                                          });
                                        }
                                      }}
                                      type={this.state.showPassword ? "text" : "password"}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      startAdornment={
                                        <InputAdornment position="end">
                                          <LockOutlinedIcon />
                                        </InputAdornment>
                                      }
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                            onMouseDown={this.handleMouseDownPassword}>
                                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      required="true"
                                      inputProps={{
                                        maxLength: 50,
                                      }}></OutlinedInput>
                                    <ErrorMessage component="div" name="password" className="error-text" />
                                  </div>
                                  <div className="form-group">
                                    <div className="">
                                      <label htmlFor="confirmPassword" className="required">
                                        Confirm Password
                                      </label>
                                    </div>
                                    <OutlinedInput
                                      className="form-control signup-fields"
                                      maxLength="20"
                                      type={this.state.showConfirmPassword ? "text" : "password"}
                                      autocomplete="new-password"
                                      id="confirmpassword"
                                      name={"confirmpassword" + Math.random()}
                                      value={values.confirmpassword}
                                      placeholder={i18n.t("patient_Confirm_Password")}
                                      onChange={(event) => {
                                        setFieldValue((values.confirmpassword = event.target.value));

                                        this.setState({
                                          confirmpassword: event.target.value,
                                        });
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      startAdornment={
                                        <InputAdornment position="end">
                                          <LockOutlinedIcon />
                                        </InputAdornment>
                                      }
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowConfirmPassword}
                                            onMouseDown={this.handleMouseDownPassword}>
                                            {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      required="true"
                                      inputProps={{
                                        maxLength: 50,
                                      }}></OutlinedInput>
                                    <ErrorMessage component="div" name="confirmpassword" className="error-text" />
                                  </div>
                                </div>
                                <div className="col-sm-6 col-md-6 mb-4">
                                  <div className="admin-light-blue-bg">
                                    <div className="admin-blue-title new-admin-blue-title">
                                      {" "}
                                      <Translation>
                                        {(t, { i18n }) => <label>{t("changePassword_PasswordPolicy")}</label>}
                                      </Translation>
                                    </div>
                                    <ul className="admin-character-password">
                                      <li>
                                        Min 8 to Max 20 Characters
                                        {values.password && values.password.length >= 8 && this.state.pwdlength === true ? (
                                          <span className="admin-close-icon">
                                            <i className="fas fa-check-circle admin-tick-icon-green"></i>
                                          </span>
                                        ) : (
                                          <span className="admin-close-icon">
                                            <i className="fas fa-times-circle admin-close-icon-red"></i>
                                          </span>
                                        )}
                                      </li>
                                      <li>
                                        At least 1 Character should be Capital ['A-Z']
                                        {this.state.pwdonecap === true ? (
                                          <span className="admin-close-icon">
                                            <i className="fas fa-check-circle admin-tick-icon-green"></i>
                                          </span>
                                        ) : (
                                          <span className="admin-close-icon">
                                            <i className="fas fa-times-circle admin-close-icon-red"></i>
                                          </span>
                                        )}
                                      </li>
                                      <li>
                                        At least 1 Character should be Small ['a-z']
                                        {this.state.pwdonesmall === true ? (
                                          <span className="admin-close-icon">
                                            <i className="fas fa-check-circle admin-tick-icon-green"></i>
                                          </span>
                                        ) : (
                                          <span className="admin-close-icon">
                                            <i className="fas fa-times-circle admin-close-icon-red"></i>
                                          </span>
                                        )}
                                      </li>
                                      <li>
                                        At least 1 Character should be Numeric ['0-9']
                                        {this.state.pwdonenum === true ? (
                                          <span className="admin-close-icon">
                                            <i className="fas fa-check-circle admin-tick-icon-green"></i>
                                          </span>
                                        ) : (
                                          <span className="admin-close-icon">
                                            <i className="fas fa-times-circle admin-close-icon-red"></i>
                                          </span>
                                        )}
                                      </li>
                                      <li>
                                        At least 1 Character should be Special [@#$%&*!_-+]
                                        {this.state.pwdonespl === true ? (
                                          <span className="admin-close-icon">
                                            <i className="fas fa-check-circle admin-tick-icon-green"></i>
                                          </span>
                                        ) : (
                                          <span className="admin-close-icon">
                                            <i className="fas fa-times-circle admin-close-icon-red"></i>
                                          </span>
                                        )}
                                      </li>

                                      <li>
                                        No Space is allowed [ ]
                                        {values.password && this.state.pwdnospace === true ? (
                                          <span className="admin-close-icon">
                                            <i className="fas fa-check-circle admin-tick-icon-green"></i>
                                          </span>
                                        ) : (
                                          <span className="admin-close-icon">
                                            <i className="fas fa-times-circle admin-close-icon-red"></i>
                                          </span>
                                        )}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mb-3">
                              <fieldset style={{ width: "-webkit-fill-available" }} className="registration-fieldset">
                                <legend>Contact Details</legend>
                                <div className="form-row member-details">
                                  <div className="col-sm-6 col-md-6">
                                    <div className="form-group">
                                      <label htmlFor="email" className="required">
                                        Email Address
                                      </label>

                                      <div className="security-input">
                                        <OutlinedInput
                                          className="form-control security-input"
                                          type="text"
                                          maxLength="255"
                                          autocomplete="new-password"
                                          id="useremailid"
                                          name={"useremailid" + Math.random()}
                                          value={values.useremailid}
                                          placeholder={i18n.t("useremailid_placeholder")}
                                          onChange={(event) => {
                                            setFieldValue(
                                              ((values.useremailid = event.target.value), (values.isupdated = true)),
                                            );

                                            this.setState({
                                              emailId: event.target.value,
                                              isupdated: true,
                                            });
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          inputProps={{
                                            maxLength: 255,
                                          }}></OutlinedInput>
                                        <ErrorMessage component="div" name="useremailid" className="error-text" />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-sm-6 col-md-6">
                                    <div id="phone">
                                      <div className="form-group">
                                        <label htmlFor="phone" className="required">
                                          Phone Number
                                        </label>
                                        <div className="form-row">
                                          <div className="col-4 security-input mb-2 mb-md-0">
                                            <Select
                                              className="form-control security-input"
                                              // type="text"
                                              autocomplete="new-password"
                                              id="countrycoder"
                                              name="countrycoder"
                                              value={values?.countrycoder}
                                              defaultValue="+1"
                                              component="select"
                                              // placeholder="Select Country"
                                              onChange={(event) => {
                                                setFieldValue((values.countrycoder = event.target.value));

                                                this.setState({
                                                  countrycoder: event.target.value,
                                                });
                                                sessionStorage.setItem("countrycode", event.target.value);
                                              }}>
                                              <MenuItem value="+1">+1</MenuItem>
                                            </Select>
                                          </div>

                                          <div className="col-8 security-input mb-2 mb-md-0">
                                            <OutlinedInput
                                              className="form-control security-input"
                                              type="text"
                                              maxLength="10"
                                              autocomplete="new-password"
                                              id="usermobile"
                                              name={"usermobile" + Math.random()}
                                              value={values.usermobile}
                                              placeholder={i18n.t("Usermobile_placeholder")}
                                              onKeyPress={(event) => {
                                                if (!/\d/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              onChange={(event) => {
                                                setFieldValue(
                                                  ((values.usermobile = event.target.value), (values.isupdated = true)),
                                                );

                                                this.setState({
                                                  phoneno: event.target.value,
                                                  isupdated: true,
                                                });

                                                sessionStorage.setItem("mobileno", event.target.value);
                                              }}
                                              required="true"
                                              inputProps={{
                                                maxLength: 10,
                                              }}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}></OutlinedInput>
                                            <ErrorMessage component="div" name="usermobile" className="error-text" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="d-flex align-items-start px-2">
                                    <Field
                                      type="checkbox"
                                      name="isCommunicate"
                                      id="isCommunicate"
                                      className="consent-checkbox mt-1 mr-2"
                                      checked={values.isCommunicate}
                                      onChange={(event) => {
                                        setFieldValue((values.isCommunicate = !values.isCommunicate));
                                      }}
                                    />
                                    <Translation>
                                      {(t, { i18n }) => (
                                        <label for="verifyemailid">{t("Registration_email_communication")}</label>
                                      )}
                                    </Translation>
                                  </div>
                                </div>
                              </fieldset>
                            </div>

                            <div className="row mb-3">
                              <fieldset className="registration-fieldset">
                                <legend>Mailing Address Details</legend>
                                <div className="form-row member-details">
                                  <div className="col-md-4 col-sm-4 ">
                                    <div className="form-group">
                                      <label htmlFor="country" className="required">
                                        Country
                                      </label>

                                      <div className="security-input">
                                        <Select
                                          className="form-control security-input"
                                          type="text"
                                          autocomplete="new-password"
                                          id="country"
                                          name="country"
                                          component="select"
                                          default={this.state.country}
                                          value={values?.country}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          placeholder="Select Country"
                                          onClick={(event) => {
                                            $(".prepopulatecountry").hide();
                                            this.setState({
                                              // country: event?.target?.value,
                                              city: "",
                                              state: "",
                                            });
                                          }}
                                          onChange={(event) => {
                                            $(".prepopulatecountry").remove();
                                            $(".prepopulate").remove();
                                            var csid = $("select#country").find(":selected").data("role");

                                            if (event.target.value !== "") {
                                              values.country = event.target?.value;
                                              this.setState({
                                                country: event.target?.value,
                                                city: "",
                                                state: "",
                                                isupdated: true,
                                              });
                                              var PassValues = {
                                                countryId: csid,
                                                limit: -1,
                                              };
                                              const UserId = "1234";
                                              RegionManagementService.getState(PassValues, UserId, (res) => {
                                                setFieldValue(
                                                  (values.stateList = res.data.responseListObject),
                                                  (values.country = event?.target?.value),
                                                );
                                              });
                                            } else {
                                              setFieldValue((values.country = "select"));
                                            }
                                          }}>
                                          {(this.state.countryList || null).map((country, index) => (
                                            <MenuItem key={index} data-role={country.countryId} value={country?.countryName}>
                                              {country?.countryName}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-4">
                                    <div className="form-group">
                                      <label htmlFor="state" className="required">
                                        State
                                      </label>

                                      <div className="security-input">
                                        <Select
                                          className="form-control security-input"
                                          type="text"
                                          autocomplete="new-password"
                                          id="state"
                                          name="state"
                                          component="select"
                                          placeholder="Select State"
                                          value={values?.state}
                                          required
                                          onChange={(event) => {
                                            $(".prepopulatestate").remove();
                                            $(".prepopulatecity").remove();
                                            setFieldValue(((values.city = ""), (values.isupdated = true)));
                                            var csid = $("select#state").find(":selected").data("role");

                                            if (event.target.value !== "") {
                                              values.state = event.target.value;

                                              this.setState({
                                                state: event.target.value,
                                                city: "",
                                                isupdated: true,
                                              });
                                              var PassValues = {
                                                stateId: csid,
                                              };
                                              const UserId = "1234";
                                              RegionManagementService.getCity(PassValues, UserId, (res) => {
                                                this.setState({
                                                  cityList: res["data"].responseListObject,
                                                });

                                                setFieldValue((values.cityList = res.data.responseListObject));
                                              });

                                              $("#city").focus();
                                            }
                                          }}>
                                          {(values.stateList || null).map((states, index) => (
                                            <MenuItem key={index} data-role={states.stateId} value={states.stateName}>
                                              {states?.stateName
                                                ?.split(" ")
                                                ?.map((word) => {
                                                  return word[0].toUpperCase() + word.substring(1);
                                                })
                                                .join(" ")}{" "}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                        <ErrorMessage component="div" name="state" className="error-text" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 cols-m4">
                                    <div className="form-group">
                                      <label htmlFor="city" className="required">
                                        City
                                      </label>

                                      <div className="security-input">
                                        <OutlinedInput
                                          className="form-control security-input"
                                          type="text"
                                          autocomplete="new-password"
                                          id="city"
                                          name={"city" + Math.random()}
                                          value={values.city}
                                          placeholder="Enter city"
                                          onChange={(event) => {
                                            $(".prepopulatecity").remove();

                                            setFieldValue(((values.ZipCode = ""), (values.isupdated = true)));
                                            this.setState({
                                              city: event.target.value,
                                              ZipCode: "",
                                              isupdated: true,
                                            });
                                            setFieldValue((values.city = event.target.value));
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}></OutlinedInput>
                                        <ErrorMessage component="div" name="city" className="error-text" />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-8 col-sm-8">
                                    <div className="form-group">
                                      <FormControl variant="outlined" className="w-100" fullWidth>
                                        <FormLabel htmlFor="billingAddress" className="required">
                                          Street Address
                                        </FormLabel>

                                        <GooglePlacesAutocomplete
                                          apiKey="AIzaSyAymCr1DkF6p_ULBgBIZIM2VNu9pg-zrN0"
                                          autocompletionRequest={{
                                            componentRestrictions: {
                                              country: ["us"],
                                            },
                                          }}
                                          selectProps={{
                                            isClearable: true,
                                            value: this.state.googleaddress,
                                            onChange: (val) => {
                                              this.getGoogleApiInfo({ ...values }, val);
                                            },
                                          }}
                                        />
                                      </FormControl>
                                    </div>
                                    <div className="form-group">
                                      {/* <label for="" className="required">Billing Address </label> */}
                                      {/* <label htmlFor="address" className="required">
                                        Street Address
                                      </label> */}
                                      <div className="security-input">
                                        <OutlinedInput
                                          type="text"
                                          autocomplete="new-password"
                                          id="address1"
                                          name={"address1" + Math.random()}
                                          value={
                                            values?.address2 ? values?.address1 + "," + values?.address2 : values?.address1
                                          }
                                          placeholder={i18n.t("patient_Address")}
                                          className="form-control security-input"
                                          onChange={(event) => {
                                            setFieldValue(
                                              ((values.address1 = event.target.value), (values.isupdated = true)),
                                            );
                                            this.setState({
                                              address1: event.target.value,
                                              isupdated: true,
                                            });
                                          }}
                                        />
                                        <ErrorMessage component="div" name="address1" className="error-text" />
                                      </div>
                                    </div>

                                    {/* <div className="form-group">
                                      <OutlinedInput
                                        type="text"
                                        autocomplete="new-password"
                                        id="address2"
                                        name={"address2" + Math.random()}
                                        value={values.address2}
                                        placeholder="Address Line 2"
                                        className="form-control security-input"
                                        onChange={(event) => {
                                          setFieldValue(((values.address2 = event.target.value), (values.isupdated = true)));
                                          this.setState({
                                            address2: event.target.value,
                                            isupdated: true,
                                          });
                                        }}
                                      />
                                    </div> */}
                                  </div>
                                  <div className="col-md-4 col-sm-4">
                                    <div className="form-group">
                                      <label htmlFor="state" className="required">
                                        ZIP Code{" "}
                                      </label>
                                      <div className="security-input">
                                        <OutlinedInput
                                          type="text"
                                          autocomplete="new-password"
                                          id="ZipCode"
                                          name={"ZipCode" + Math.random()}
                                          value={values.ZipCode}
                                          placeholder={i18n.t("credit_placeholderZipCode")}
                                          className="form-control security-input"
                                          onChange={(event) => {
                                            const zip = event.target.value;
                                            setFieldValue(
                                              (values.ZipCode =
                                                zip.length > 5 && !zip.includes("-")
                                                  ? zip.substring(0, 5).concat("-") + zip.substring(5, zip.length)
                                                  : zip),
                                            );
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              ZipCode:
                                                zip.length > 5 && !zip.includes("-")
                                                  ? zip.substring(0, 5).concat("-") + zip.substring(5, zip.length)
                                                  : zip,
                                            }));
                                          }}
                                          inputProps={{
                                            maxLength: 10,
                                            minLength: 5,
                                          }}
                                        />
                                        <ErrorMessage component="div" name="ZipCode" className="error-text" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                            </div>

                            <div className="row mb-3 input-field">
                              <fieldset className="registration-fieldset">
                                <legend>Security Authorization</legend>
                                <div className="form-row">
                                  <div className="col-md-4 col-sm-4 form-group">
                                    <div className="security-input">
                                      <Select
                                        className="form-control security-input signup-fields"
                                        type="text"
                                        autocomplete="new-password"
                                        id="state"
                                        name="state"
                                        component="select"
                                        defaultValue={"none"}
                                        placeholder="Select Image"
                                        onChange={() => {
                                          setFieldValue((values.image = "none"));
                                        }}
                                        value={"none"}
                                        required>
                                        <MenuItem value="none" disabled>
                                          Select Image
                                        </MenuItem>
                                        <div className="img-box admin-img-box">
                                          <a className="dropdown-item admin-secure-img">
                                            <img
                                              src={this?.state?.imgdata ? this?.state?.imgdata : ""}
                                              width="auto"
                                              className="admin-register-img"
                                              onClick={this.handleClick(this.state.imgdata, this.state.imgdataid)}
                                            />
                                          </a>
                                          <a className="dropdown-item admin-secure-img">
                                            <img
                                              src={this?.state?.img2 ? this?.state?.img2 : ""}
                                              width="auto"
                                              className="admin-register-img"
                                              onClick={this.handleClick(this.state.img2, this.state.img2id)}
                                            />
                                          </a>
                                          <a className="dropdown-item admin-secure-img">
                                            <img
                                              src={this.state.img3 ? this.state.img3 : ""}
                                              className="admin-register-img"
                                              onClick={this.handleClick(this.state.img3, this.state.img3id)}
                                            />
                                          </a>
                                        </div>
                                        <div className="img-box admin-img-box">
                                          <a className="dropdown-item admin-secure-img">
                                            <img
                                              src={this.state.img4 ? this.state.img4 : ""}
                                              width="auto"
                                              className="admin-register-img"
                                              onClick={this.handleClick(this.state.img4, this.state.img4id)}
                                            />
                                          </a>
                                        </div>
                                      </Select>
                                    </div>
                                    <ErrorMessage component="div" name="securityimageselected" className="error-text" />
                                  </div>
                                  <div className="col-md-6 col-sm-6 form-group">
                                    {this.state.selectedImage ? "Selected Image" : "Selected Image"}:{" "}
                                    {this.state.selectedImage ? (
                                      <img src={this.state.selectedImage} width="auto" className="user-img" />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-12 user-security-content">
                                  <p className="text-justify">
                                    The security image feature is an additional layer of identity verification for signing in
                                    to MED-1 Payment Portal. It protects you against identity theft and fraud. When you see
                                    your security image you can be certain you're at the valid MED-1 Payment Portal site and
                                    not a fraudulent look-alike site.
                                  </p>
                                </div>
                              </fieldset>
                            </div>

                            <div className="my-4">
                              <button
                                type="submit"
                                className="primary-button themeing-buttons"
                                aria-label="continue to Sign Up">
                                Sign Up
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </I18nextProvider>
                </div>
                <div className="text-center">
                  <div className="more-info">
                    <p>
                      Already have an account with us?{" "}
                      <Link to="/signin" aria-label="signin in here link">
                        Sign In here
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ToastContainer /> */}
      </>
    );
  }
}
