import React from "react";

import "./stepper.css";
import { I18nextProvider } from "react-i18next";
import { Translation } from "react-i18next";

import i18n from "../i18n";

class PP_QuickPayStepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountNumber: "132345678989",
      hospitalName: "wokard hospital",
      socialSecurityNumber: "***-**-1120",
      showForm: true,
    };
  }

  componentDidMount() {}

  // _paymentConfirmationValidation = Yup.object().shape({

  // })

  render() {
    return (
      <I18nextProvider i18n={i18n}>
        <React.Fragment>
          <div>
            <div className="progresscontainer">
              <ul className="progressbar">
                <li
                  className={
                    sessionStorage.getItem("screen") === "paymentoutstanding" ||
                    sessionStorage.getItem("screen") === "Paymentconfirmation" ||
                    sessionStorage.getItem("screen") === "Thankyou"
                      ? "active"
                      : ""
                  }>
                  <Translation>{(t, { i18n }) => <label>{t("stepper_makeapayment")}</label>}</Translation>
                </li>
                <li
                  className={
                    sessionStorage.getItem("screen") === "Paymentconfirmation" ||
                    sessionStorage.getItem("screen") === "Thankyou"
                      ? "active"
                      : ""
                  }>
                  <Translation>{(t, { i18n }) => <label>{t("stepper_processpayment")}</label>}</Translation>
                </li>
                <li
                  className={
                    sessionStorage.getItem("screen") === "Thankyou" || sessionStorage.getItem("screen") === "TransactionFail"
                      ? "active"
                      : "" || sessionStorage.getItem("screen") === "TransactionPending"
                      ? "active"
                      : ""
                  }>
                  <Translation>{(t, { i18n }) => <label>{t("stepper_thankyou")}</label>}</Translation>
                </li>
              </ul>
            </div>
          </div>
        </React.Fragment>
      </I18nextProvider>
    );
  }
}

export default PP_QuickPayStepper;
