export const signupMsgs = {
  usernameRequired: "Username is Required",
  passWordRequired: "Password is Required",
  passWordLength: "Password must be 8 characters length",
  passWordOneLetter: "Password needs one letter",
  passWordOneNumber: "Password needs one number",
  passwordSpecial: "Password needs one special character",
  confirmPassWordRequired: "Confirm Password is Required",
  passWordNotMatched: "Password is not matching",
};

export const changePasswordMsgs = {
  passWordRequired: "Password is Required",
  newPassWordRequired: "New Password is Required",
  confirmNewPassWordRequired: "Confirm New Password is Required",
};
