/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { Formik, Form, getIn } from "formik";
import * as Yup from "yup";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FormControl, FormHelperText, IconButton, MenuItem, Select } from "@material-ui/core";
import { changePasswordMsgs } from "../../../constants/ValidationMessages";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { changePassword } from "../../../action/changePasswordAction";
import { logoutUser } from "../../../action/loginAction";
import { getLogin } from "../../../reducer/loginReducer";
import { securityImageService } from "../../../core_services/securityImageService";
import { partyGetService } from "../../../core_services/partyService";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DisplayDataTooltip from "../../../components/DisplayDataTooltip";
import PageLoader from "../../../components/ContentLoader/PageLoader";
import { Oval } from "react-loader-spinner";
import { handleSuccess, ToastContainer, handleError, handleWarning } from "../../../components/Notifications/Toast";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoader: false,
      imgLoader: false,
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      emailid: "",
      name: "",
      partydata: "",
      imgdata: "",
      imgourl: "",
      img2: "",
      img3: "",
      img4: "",
      img5: "",
      img6: "",
      selectedImage: "",
      imgdataid: "",
      docid: "",
      pwdlength: false,
      pwdonecap: false,
      pwdonesmall: false,
      pwdonenum: false,
      pwdonespl: false,
      pwdnospace: false,
    };
  }

  intialvalues = {
    password: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  schema = Yup.object().shape({
    password: Yup.string().trim().required("Current Password is required"),
    newPassword: Yup.string()
      .required("New Password is required")
      .min(8, "Password must be minimum 8 characters")
      .max(20, "Password must be maximum 20 characters ")
      .matches(/[a-z]/, "Password should have at least one lowercase character")
      .matches(/[A-Z]/, "Password should have at least one uppercase character")
      .matches(/(?=.*\d)/, "Password must contain at least one digit")
      .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one special character")
      .matches(/^\S*$/, "Password should not contain whitespace"),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Password must match")
      .trim()
      .required(changePasswordMsgs.confirmNewPassWordRequired),
  });

  componentDidMount() {
    this.setState({ imgLoader: true });
    const passValues = {
      partyTypeId: "100",
    };
    partyGetService.partyGetList(
      passValues,
      (res) => {
        if (res.status === "success") {
          this.setState({ partydata: res.responseListObject[0].partyId });
          this.getImg();
        } else if (res["data"].responseInfo.status === "FAILED") {
          handleWarning("Oops..! " + res.data.responseInfo.responseMessage);
        } else {
          handleError("Something went wrong..! " + res.data.responseInfo.responseMessage);
        }
      },
      (error) => {
        console.log(error);
      },
    );

    if (sessionStorage.getItem("loginDetails") !== null) {
      var userinfo = JSON.parse(sessionStorage.getItem("loginDetails"));
      this.setState({
        emailid: userinfo.emailId,
        name: userinfo.userName,
      });
    }
  }

  handleSubmit = (values, onSubmitProps) => {
    const getRole = sessionStorage.getItem("currentUserRole");

    if (this.state.chosenimage === undefined) {
      handleWarning("Please select security image!");
      return false;
    } else {
      var userinfo = JSON.parse(sessionStorage.getItem("loginDetails"));
      this.setState({
        userLoginName: userinfo.userName,
        userPassword: values.newPassword,
        name: userinfo.name,
        userId: userinfo.userId,
      });

      let passValues = {
        oldPassword: values.password,
        userPassword: values.newPassword,
        userLoginName: userinfo.userName,
        userId: userinfo.userId,
      };

      this.props.changePassword(
        passValues,
        (res) => {
          if (res.status === "success") {
            this.setState({ pageLoader: true });
            const passValues = {
              userId: userinfo.userId,
              securityImagDocId: this.state.docid,
            };
            securityImageService.updateSecurityImageList(
              passValues,
              (res) => {
                if (res.data.status === "success") {
                  this.setState({ pageLoader: false });
                  handleSuccess(
                    "Your password & security image have been updated successfully. You will be redirected to login page to login again with changed password.",
                  );
                  setTimeout(() => {
                    getRole === "Customer" ? window.location.replace("/signin") : window.location.replace("/admin");
                  }, 4000);
                  this.props.logoutUser();
                } else if (res["data"].responseInfo.status === "FAILED") {
                  handleWarning("Oops..! " + res.data.responseInfo.responseMessage);
                } else {
                  handleError("Something went wrong..! " + res.data.responseInfo.responseMessage);
                }
              },
              (error) => {
                console.log(error);
              },
            );
          } else if (res.data.status === "fail") {
            this.setState({ error: res.reasonText });
            handleError("Something went wrong. " + res.reasonText);
          } else {
            handleWarning("Something went wrong. " + res.reasonText);
          }
        },
        (error) => {
          console.log(error);
        },
      );
    }
    onSubmitProps.resetForm();
  };

  backToDashboard = () => {
    if (sessionStorage.getItem("Forcepwdchange") === "true") {
      window.location.replace("/signin");
    } else {
      this.props.history.goBack();
    }
  };

  getImg = () => {
    const passValues = {
      partyId: this.state.partydata,
      pageName: "AuthSecureImage",
      sectionName: "AuthSecureImage",
    };
    securityImageService.securityImageList(
      passValues,
      (res) => {
        if (res.data.status === "success") {
          this.setState({
            imgdata: res.data.responseObject.imageList[0].documentUrl,
            img2: res.data.responseObject.imageList[1].documentUrl,
            img3: res.data.responseObject.imageList[2].documentUrl,
            img4: res.data.responseObject.imageList[3].documentUrl,
            imgdataid: res.data.responseObject.imageList[0].documentId,
            img2id: res.data.responseObject.imageList[1].documentId,
            img3id: res.data.responseObject.imageList[2].documentId,
            img4id: res.data.responseObject.imageList[3].documentId,
          });
          this.setState({ imgLoader: false });
        } else if (res["data"].responseInfo.status === "FAILED") {
          this.setState({ imgLoader: false });
          handleWarning("Oops..! " + res.data.responseInfo.responseMessage);
        } else {
          this.setState({ imgLoader: false });
          handleError("Something went wrong..! " + res.data.responseInfo.responseMessage);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  selectimg1 = (value) => {
    this.setState({
      selectedImage: this.state.imgdata,
    });
  };

  handleClick = (value, key) => () => {
    this.setState({
      selectedImage: value,
      docid: key,
      chosenimage: true,
    });
  };

  handleClickShowPassword = () => {
    this.setState({
      showCurrentPassword: !this.state.showCurrentPassword,
    });
  };

  handleClickShowNewPassword = () => {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  };

  handleClickShowConfirmNewPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  PasswordPolicy = () => {
    return (
      <>
        <div className="password-info" style={{ backgroundColor: "white" }}>
          <h6>Password Policy</h6>
          <ul>
            <li>Min 8 to Max 20 Characters</li>
            <li>Atleast 1 Character should be Capital ['A-Z']</li>
            <li>Atleast 1 Character should be Small ['a-z']</li>
            <li>Atleast 1 Character should be Numeric ['0-9']</li>
            <li>Atleast 1 Character should be Special [@#$%&*!_-+]</li>
            <li>No Space is allowed []</li>
          </ul>
        </div>
      </>
    );
  };

  render() {
    const getAdminRole = sessionStorage.getItem("currentUserRole");
    return (
      <div className="transaction-card" role="main">
        <div
          className={
            getAdminRole === "Admin"
              ? `change-password-container ${
                  window.location.href.includes("home/change-password") && getAdminRole && `main-container-sm`
                }`
              : "change-password-container main-container-sm"
          }>
          <h3 className="heading-style mb-2">Change Password</h3>
          <div className="dashboard-content-card" role="heading" aria-level="1">
            <>
              <Formik initialValues={this.intialvalues} validationSchema={this.schema} onSubmit={this.handleSubmit}>
                {(props) => {
                  const { values, touched, error, handleChange, errors, setFieldValue, handleBlur } = props;
                  return (
                    <Form className="input-field" noValidate="noValidate">
                      <div className="form-row">
                        <div className="col-lg-4  col-sm-6 form-group">
                          <FormControl variant="outlined" className="w-100" fullWidth>
                            <label htmlFor="currentPassword" className="required">
                              Current Password
                            </label>

                            <OutlinedInput
                              className="form-control signup-fields"
                              name="password"
                              id="currentPassword"
                              placeholder="Current Password"
                              onChange={handleChange}
                              value={values.password}
                              aria-label="text-field"
                              fullWidth
                              type={this.state.showCurrentPassword ? "text" : "password"}
                              error={Boolean(getIn(touched, "password") && getIn(errors, "password"))}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              startAdornment={
                                <InputAdornment position="end">
                                  <LockOutlinedIcon />
                                </InputAdornment>
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}>
                                    {this.state.showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              required="true"
                              inputProps={{
                                maxLength: 50,
                              }}></OutlinedInput>
                          </FormControl>
                          <FormHelperText className="component-error-text">
                            {getIn(touched, "password") && getIn(errors, "password") && getIn(errors, "password")}
                          </FormHelperText>
                        </div>
                        <div className="col-lg-4 col-sm-6 form-group">
                          <div className="row mx-0 justify-content-between align-items-center">
                            <label htmlFor="newPassword" className="required">
                              New Password
                            </label>
                            <span>
                              <DisplayDataTooltip
                                icon={<i className="fas fa-info-circle circle-icon"></i>}
                                className="toolTip">
                                <this.PasswordPolicy />
                              </DisplayDataTooltip>
                            </span>
                          </div>

                          <OutlinedInput
                            className="form-control signup-fields"
                            name="newPassword"
                            id="newPassword"
                            placeholder="New Password"
                            onChange={handleChange}
                            value={values.newPassword}
                            aria-label="text-field"
                            fullWidth
                            type={this.state.showNewPassword ? "text" : "password"}
                            error={Boolean(getIn(touched, "newPassword") && getIn(errors, "newPassword"))}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            startAdornment={
                              <InputAdornment position="end">
                                <LockOutlinedIcon />
                              </InputAdornment>
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowNewPassword}
                                  onMouseDown={this.handleMouseDownPassword}>
                                  {this.state.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            }
                            required="true"
                            inputProps={{
                              maxLength: 50,
                            }}></OutlinedInput>
                          <FormHelperText className="component-error-text">
                            {getIn(touched, "newPassword") && getIn(errors, "newPassword") && getIn(errors, "newPassword")}
                          </FormHelperText>
                        </div>
                        <div className="col-lg-4  col-sm-6 form-group">
                          <div className="row mx-0 justify-content-between align-items-center">
                            <label htmlFor="confirmNewPassword" className="required">
                              Confirm New Password
                            </label>
                            <span>
                              <DisplayDataTooltip
                                icon={<i className="fas fa-info-circle circle-icon"></i>}
                                className="toolTip">
                                <this.PasswordPolicy />
                              </DisplayDataTooltip>
                            </span>
                          </div>
                          <OutlinedInput
                            className="form-control signup-fields"
                            name="confirmNewPassword"
                            id="confirmNewPassword"
                            placeholder="Confirm New Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmNewPassword}
                            aria-label="text-field"
                            fullWidth
                            type={this.state.showConfirmPassword ? "text" : "password"}
                            error={Boolean(getIn(touched, "confirmNewPassword") && getIn(errors, "confirmNewPassword"))}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            startAdornment={
                              <InputAdornment position="end">
                                <LockOutlinedIcon />
                              </InputAdornment>
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowConfirmNewPassword}
                                  onMouseDown={this.handleMouseDownPassword}>
                                  {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            }
                            required="true"
                            inputProps={{
                              maxLength: 50,
                            }}></OutlinedInput>
                          <FormHelperText className="component-error-text">
                            {getIn(touched, "confirmNewPassword") &&
                              getIn(errors, "confirmNewPassword") &&
                              getIn(errors, "confirmNewPassword")}
                          </FormHelperText>
                        </div>
                        <div className="col-lg-4  col-sm-6 form-group">
                          <div className="row mx-0">
                            <label htmlFor="state">Security Authorization</label>
                          </div>
                          <div className="security-input">
                            <Select
                              className="form-control security-input signup-fields"
                              type="text"
                              autocomplete="new-password"
                              id="state"
                              name="state"
                              component="select"
                              defaultValue={"none"}
                              placeholder="Select Image"
                              onChange={() => {
                                setFieldValue((values.image = "none"));
                              }}
                              value={"none"}
                              required>
                              <MenuItem value="none" disabled>
                                Select Image
                              </MenuItem>
                              <div className="img-box admin-img-box">
                                <a className="dropdown-item admin-secure-img">
                                  <img
                                    src={this?.state?.imgdata ? this?.state?.imgdata : ""}
                                    width="auto"
                                    className="admin-register-img"
                                    onClick={this.handleClick(this.state.imgdata, this.state.imgdataid)}
                                  />
                                </a>
                                <a className="dropdown-item admin-secure-img">
                                  <img
                                    src={this?.state?.img2 ? this?.state?.img2 : ""}
                                    width="auto"
                                    className="admin-register-img"
                                    onClick={this.handleClick(this.state.img2, this.state.img2id)}
                                  />
                                </a>
                                <a className="dropdown-item admin-secure-img">
                                  <img
                                    src={this.state.img3 ? this.state.img3 : ""}
                                    className="admin-register-img"
                                    onClick={this.handleClick(this.state.img3, this.state.img3id)}
                                  />
                                </a>
                              </div>
                              <div className="img-box admin-img-box">
                                <a className="dropdown-item admin-secure-img">
                                  <img
                                    src={this.state.img4 ? this.state.img4 : ""}
                                    width="auto"
                                    className="admin-register-img"
                                    onClick={this.handleClick(this.state.img4, this.state.img4id)}
                                  />
                                </a>
                              </div>
                            </Select>
                          </div>
                          <FormHelperText className="component-error-text">
                            {getIn(touched, "securityimageselected") &&
                              getIn(errors, "securityimageselected") &&
                              getIn(errors, "securityimageselected")}
                          </FormHelperText>
                        </div>
                        <div className="col-lg-4  col-sm-6 form-group">
                          <div className="row mx-0 justify-content-between align-items-center">
                            <label htmlFor="state">{this.state.selectedImage && "Selected Image"} </label>
                          </div>
                          <div>
                            {this.state.selectedImage ? (
                              <img src={this.state.selectedImage} width="auto" className="user-img" />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col">
                          <p className="user-security-content">
                            The security image feature is an additional layer of identity verification for signing in to
                            MED-1 Payment Portal.{" "}
                          </p>
                          <p className="user-security-content">
                            It protects you against identity theft and fraud. When you see your security image you can be
                            certain you're at the valid MED-1 Payment Portal site and not a fraudulent look-alike site.
                          </p>
                        </div>
                      </div>

                      <div className="proceed-cancel-buttons">
                        <button
                          type="button"
                          onClick={this.backToDashboard}
                          className="secondary-button mx-1"
                          aria-label="Submit">
                          Cancel
                        </button>
                        <button type="submit" className="primary-button themeing-buttons mx-1" aria-label="Submit">
                          Submit
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
              {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
            </>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isUserLoggedIn: state.loginReducer,
  ...getLogin(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changePassword: changePassword,
      logoutUser: logoutUser,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
