/* eslint-disable jsx-a11y/no-onchange */
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getIn } from "formik";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import CloseIcon from "@material-ui/icons/Close";
import PayPlanDetails from "./PayPlanDetails";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { IconButton, TextField, Tooltip } from "@material-ui/core";
import {
  FormControl,
  RadioGroup,
  Radio,
  FormHelperText,
  FormControlLabel,
  option,
  Select,
  Button,
  MenuItem,
} from "@material-ui/core";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { Grid } from "@material-ui/core";
import CustomizedRadio from "../../../../components/CustomizedRadio";
import { transactionService } from "../../../../services/transactionDetailReportService";
import addTransaction from "../../../../action/transactionDetailReport";
import { requestInfo_PP } from "../../../../Common/CommonFn";
import RestDataSource from "../../../../services/restdatasource";
import MaterialTable from "material-table";
import TablePagination from "@material-ui/core/TablePagination";
import { tableIcons } from "../../../../components/TableIcons";
import moment from "moment";
import { ChevronLeft, ChevronRight, FirstPage, LastPage, ContentCopy } from "@material-ui/icons";
import FuturePaymentMethod from "./FuturePayment";
import PageLoader from "./../../../../components/ContentLoader/PageLoader";
import Admin_Footer from "../../../../components/admin_footer/footer";
import { handleError, handleWarning, ToastContainer } from "../../../../components/Notifications/Toast";
import UpdateTrackingPage from "../../../../containers/UpdateTrackingPage";
import ClearIcon from "@material-ui/icons/Clear";
import MouseOverPopover from "../../../../Common/MousePopOver";
import NumberFormat from "react-number-format";
import SpinnerPage from "../../../../Common/PP_loader";

const tableHeaderStyle = {
  textAlign: "inherit",
  color: "#000000",
  fontWeight: "500",
  padding: "15px",
  fontSize: "14px",
  fontFamily: "Poppins",
};
const rowStyle = {
  height: "30px",
  fontSize: "14px",
};
const labelStyles = {
  color: "#6d6d6d",
  fontWeight: "500",
  fontSize: "14px",
  backgroundColor: "#e0dae9",
  borderRadius: "8px",
  padding: "2px 8px",
  marginBottom: "4px",
};

const textStyles = {
  color: "#464646",
  paddingLeft: "5px",
  fontWeight: "600",
  fontSize: "14px",
};
const paymentMethodOptions = [
  { key: "eCheck", value: "eCheck" },
  { key: "Credit Card /Debit Card", value: "Credit Card / Debit Card" },
];

const paymentStatusOptions = [
  { key: "Success", value: "Success" },
  { key: "Failed", value: "Failed" },
];

class PaymentMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      advancedSearch: false,
      transactionComponent: "paymentHistory",
      reportdata: this.props.transactionData || [],
      startDate: null,
      endDate: null,
      noofrecords: 10,
      totalRecords: "",
      totalpages: 0,
      pageno: 0,
      showpagination: false,
      noofpages: 0,
      scheduledAmount: 0,
      rowsPerPage: 10,
      from: "",
      to: "",
      disableDate: true,
    };
  }
  componentDidMount() {
    if (
      this.props?.history?.location?.state?.from === "/home/payment-plan-details" ||
      this.props?.history?.location?.state?.from === "/pa/payment-plan-details"
    ) {
      this.setState({ transactionComponent: "futurePayment" });
    }
  }
  advanceGetFormattedDate = (date, format = "MM/DD/YYYY h:mm:ss") => {
    if (moment(date, "MM/DD/YYYY").isValid()) {
      return moment(date).format(format);
    }

    return "";
  };
  handleReset = (values) => {
    values.period = "Today";
    values.startDate = new Date();
    values.endDate = new Date();
    this.setState({ pageno: 0, noofpages: 0, reportdata: [], disableDate: true });
  };
  changepage = (number, values) => {
    let newTotalPages = [];

    for (let i = 0; i < this.state.totalRecords / values.selectRows; i++) {
      newTotalPages.push(i);
    }
    let pageN = number;
    if (newTotalPages.length !== this.state.noofpages.length) {
      pageN = 0;
    }

    this.setState({
      pageno: pageN,
      loader: true,
      rowsPerPage: values?.selectRows,
    });
    var logindetails = JSON.parse(sessionStorage.getItem("loginDetails"));

    const passValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      paymentTransactionSearch:
        sessionStorage?.getItem("currentUserRole") !== "Admin"
          ? {
              payerPartyId: logindetails.partyId,
              startDate: this.advanceGetFormattedDate(values.startDate),
              endDate: this.advanceGetFormattedDate(values.endDate),
              paymentMethodId:
                values.paymentMethod === "Credit Card / Debit Card"
                  ? 75001
                  : values.paymentMethod === "eCheck"
                  ? 75003
                  : null,
              transactionStatus:
                values.paymentStatus === "Success" ? 80002 : values.paymentStatus === "Failed" ? 80003 : null,
              pgTxnRefNumber: values.transactionId ? values.transactionId : null,
              seedAccountNumber: values.seedAccountNumber ? values.seedAccountNumber : null,
              facsAccountNumber: values.accountNumber ? values.accountNumber : null,
              authorizationCode: values.authorizationCode ? values.authorizationCode : null,
              pageNumber: pageN,
              pageSize: values.selectRows,
              noofpages: "",
              billAmount: values.billAmount ? values.billAmount : null,
              paymentAmount: values.paymentAmount ? values.paymentAmount : null,
              clientName: values.clientName ? values.clientName : null,
            }
          : {
              startDate: this.advanceGetFormattedDate(values.startDate),
              endDate: this.advanceGetFormattedDate(values.endDate),
              paymentMethodId:
                values.paymentMethod === "Credit Card / Debit Card"
                  ? 75001
                  : values.paymentMethod === "eCheck"
                  ? 75003
                  : null,
              transactionStatus:
                values.paymentStatus === "Success" ? 80002 : values.paymentStatus === "Failed" ? 80003 : null,
              pgTxnRefNumber: values.transactionId ? values.transactionId : null,
              seedAccountNumber: values.seedAccountNumber ? values.seedAccountNumber : null,
              facsAccountNumber: values.accountNumber ? values.accountNumber : null,
              authorizationCode: values.authorizationCode ? values.authorizationCode : null,
              pageNumber: pageN,
              pageSize: values.selectRows,
              noofpages: "",
              billAmount: values.billAmount ? values.billAmount : null,
              paymentAmount: values.paymentAmount ? values.paymentAmount : null,
              clientName: values.clientName ? values.clientName : null,
            },
    };

    transactionService.addTransaction(
      passValues,

      (res) => {
        if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
          this.setState({
            reportdata: res?.data?.paymentTtransactions,
            noofrecords: res?.data?.totalRecords,
            totalRecords: res?.data?.totalRecords,
            loader: false,
          });
          let temp = [];
          for (let i = 0; i < res.data.totalRecords / values.selectRows; i++) {
            temp.push(i);
          }

          this.setState({
            noofpages: temp,
            totalpages: temp.length,
          });
          this.handleRowChange(values.selectRows);
        } else if (res["data"]?.responseInfo?.status === "FAILED") {
          this.setState({ loader: false });
          handleWarning("Oops" + res.data?.responseInfo?.responseMessage);
        } else {
          this.setState({ loader: false });
          handleError("Something went wrong!" + res.data?.responseInfo?.responseMessage);
        }
      },
      (error) => {
        handleError("Something went wrong! Please try again");
      },
    );
  };
  changePageNo = (event, values) => {
    this.changepage(event.target.value, values);
  };
  handleScheduledAmount = (amount) => {
    this.setState({
      scheduledAmount: amount,
    });
  };
  handleRowChange = (rowsPerPage) => {
    let toValue =
      this.state.noofpages.length - 1 === this.state.pageno
        ? this.state.noofrecords
        : this.state.totalRecords < rowsPerPage
        ? this.state.totalRecords
        : this.state.pageno * rowsPerPage + rowsPerPage;

    this.setState({ from: this.state.pageno * rowsPerPage + 1, to: toValue });
  };
  render() {
    const tableColumns = [
      {
        name: "paymentTxnDatetime",
        title: (
          <>
            Transaction
            <br />
            Date
          </>
        ),
        field: "paymentTxnDatetime",
        render: (rowData) => {
          return <>{moment(rowData?.paymentTxnDatetime).format("MM/DD/YYYY")}</>;
        },
        cellStyle: {
          textAlign: "right",
        },
        headerStyle: {
          textAlign: "left",
        },
      },
      {
        name: "masterAccountReferenceNumber",
        title: (
          <>
            Seed
            <br />
            Account #
          </>
        ),
        field: "masterAccountReferenceNumber",
        cellStyle: {
          textAlign: "right",
        },
        headerStyle: {
          textAlign: "left",
        },
      },
      {
        name: "accountReferenceNumber",
        title: <>Account #</>,
        field: "accountReferenceNumber",
        cellStyle: {
          textAlign: "right",
        },
        headerStyle: {
          textAlign: "left",
        },
      },
      {
        name: "clientName",
        title: "Client Name",

        field: "clientName",
        headerStyle: {
          textAlign: "left",
        },
        render: (value) => {
          if (value.clientName != null) {
            let var5 = value.clientName;
            const title1 = <p style={{ fontSize: "16px" }}>{var5}</p>;

            return (
              <div>
                {/* <MouseOverPopover data={var5} popOverData={var5} codeLength={true} contentLength={15} /> */}
                <Tooltip title={title1} placement="top">
                  <div>{var5?.slice(0, 15) + "..."}</div>
                </Tooltip>
              </div>
            );
          } else {
            return <div class="client-name-colum">{value.clientName}</div>;
          }
        },
      },
      {
        name: "paidAmount",
        title: <>Total Amt Paid</>,
        field: "paidAmount",
        cellStyle: {
          textAlign: "right",
        },
        headerStyle: {
          textAlign: "left",
        },
        render: (rowData) => {
          if (rowData != null) {
            return <>{`$ ${rowData.paidAmount.toLocaleString("us-en", { minimumFractionDigits: 2 })}`}</>;
          } else {
            return <>{`$ ${rowData.paidAmount}`}</>;
          }
        },
      },
      {
        name: "actualPaidAmount",
        title: (
          <>
            Amt Applied <br />
            to Acct
          </>
        ),
        field: "actualPaidAmount",
        cellStyle: {
          textAlign: "right",
        },
        headerStyle: {
          textAlign: "left",
        },
        render: (rowData) => {
          if (rowData != null) {
            return <>{`$ ${rowData.actualPaidAmount.toLocaleString("us-en", { minimumFractionDigits: 2 })}`}</>;
          } else {
            return <>{`$ ${rowData.actualPaidAmount}`}</>;
          }
        },
      },
      {
        name: "refundedAmount",
        title: "Refund Amt",
        field: "refundedAmount",
        render: (rowData) => {
          if (rowData.refundedAmount && rowData.refundedAmount === 0) {
            <div className="paid-amount-colum">
              ${Number(rowData.refundedAmount).toLocaleString("us-en", { minimumFractionDigits: 2 })}
            </div>;
          } else {
            return <div style={{ textAlign: "center" }}>--</div>;
          }
        },
      },
      {
        name: "currentBalanceAmount",
        title: (
          <>
            Current Bal
            <br />
            Amt
          </>
        ),
        field: "currentBalanceAmount",
        cellStyle: {
          textAlign: "right",
        },
        headerStyle: {
          textAlign: "left",
        },
        render: (rowData) => {
          if (rowData != null) {
            return <>{`$ ${rowData.currentBalanceAmount.toLocaleString("us-en", { minimumFractionDigits: 2 })}`}</>;
          } else {
            return <>{`$ ${rowData.currentBalanceAmount}`}</>;
          }
        },
      },
      {
        name: "originalTotalAmount",
        title: "Bill Amt",
        field: "originalTotalAmount",
        cellStyle: {
          textAlign: "right",
        },
        headerStyle: {
          textAlign: "left",
        },
        render: (rowData) => {
          if (rowData != null) {
            return <>{`$ ${rowData.originalTotalAmount.toLocaleString("us-en", { minimumFractionDigits: 2 })}`}</>;
          } else {
            return <>{`$ ${rowData.originalTotalAmount}`}</>;
          }
        },
      },
      {
        name: "paymentGatewayTxnRefNo",
        title: "Transaction ID",
        field: "paymentGatewayTxnRefNo",
        headerStyle: {
          textAlign: "left",
        },
        render: (value) => {
          if (value.paymentGatewayTxnRefNo != null) {
            let var5 = value.paymentGatewayTxnRefNo;
            const button1 = (
              <ContentCopy onClick={() => navigator?.clipboard?.writeText(var5)} style={{ cursor: "pointer" }}></ContentCopy>
            );
            const title1 = (
              <p style={{ fontSize: "16px" }}>
                {var5}
                <span style={{ marginLeft: "16px" }}>{button1}</span>
              </p>
            );
            return (
              <div>
                {/* <MouseOverPopover data={var5} popOverData={var5} codeLength={true} contentLength={15} />
                 */}
                <Tooltip title={title1} placement="top">
                  <div>{var5?.slice(0, 15) + "..."}</div>
                </Tooltip>
              </div>
            );
          } else {
            return <div class="transaction-colum">{value.paymentGatewayTxnRefNo}</div>;
          }
        },
      },
      {
        name: "transactionStatusDescription",
        title: "Settlement Status",
        field: "transactionStatusDescription",
      },
      {
        name: "settlementDatetime",
        title: "Settlement Date",
        field: "settlementDatetime",
      },
      {
        name: "paymentGatewayAuthorizationNumber",
        title: (
          <>
            Authorization <br />
            Code
          </>
        ),
        field: "paymentGatewayAuthorizationNumber",
        cellStyle: {
          textAlign: "right",
        },
        headerStyle: {
          textAlign: "left",
        },
      },
      {
        name: "payerName",
        title: "Payer Name",
        field: "payerName",
        headerStyle: {
          textAlign: "left",
        },
      },
      {
        name: "paymentMethodName",
        title: "Payment Method",
        field: "paymentMethodName",
        cellStyle: {
          textAlign: "left",
        },
        headerStyle: {
          textAlign: "left",
        },
      },
      {
        name: "paidBy",
        title: "Paid By",
        field: "paidBy",
      },
      {
        name: "csaName",
        title: "PA Name",
        field: "csaName",
      },
      {
        name: "paymentGatewayStatus",
        title: "Payment Status",
        field: "paymentGatewayStatus",
        render: (value) => {
          if (value?.paymentGatewayStatus === 80002) {
            let var5 = "Success";
            return <div className="payment-status-colum success">{var5}</div>;
          } else {
            let var5 = "Failed";
            return <div className="payment-status-colum falied">{var5}</div>;
          }
        },
      },
      {
        name: "paymentGatewayResponseJson",
        title: "Failure Reason",
        field: "paymentGatewayResponseJson",
        render: (reportData) => {
          if (reportData.paymentGatewayResponseJson != null) {
            var date = JSON.parse(reportData.paymentGatewayResponseJson);
            var resSucss = date.response_code;
            if (
              resSucss === "A01" ||
              resSucss === "A02" ||
              resSucss === "A03" ||
              resSucss === "S01" ||
              resSucss === "S02" ||
              resSucss === "S03"
            ) {
              return "";
            } else {
              var data = date.response_desc;
              return <div className="failure-reason-colum">{data}</div>;
            }
          } else {
            return "";
          }
        },
      },
      {
        name: "refundReason",
        title: "Refund Reason",
        field: "refundReason",
        render: (rowData) => {
          let var5 = rowData.refundReason;
          return (
            <>
              <MouseOverPopover data={var5} popOverData={var5} codeLength={true} contentLength={15} />
            </>
          );
        },
      },
    ];
    const options = {
      selection: false,
      search: false,
      sorting: false,
      emptyRowsWhenPaging: false,
      pageSize: this.state.rowsPerPage,
    };

    const rows = Number(this.state.pageno) + 1;
    const futureDateSchema = Yup.object().shape({
      startDate: !this.state.disableDate && Yup.string().nullable().required("Start Date is Required"),
      endDate: !this.state.disableDate && Yup.string().nullable().required("End Date is Required"),
      accountNumber: Yup.string()
        .matches(/^[0-9]+$/, "Enter digits only")
        .min(5, "Minimum 5 digits required for MED-1 Account Number")
        .max(8, "Enter 7 or 8 digits MED-1 Account Number"),
      seedAccountNumber: Yup.string()
        .matches(/^[0-9]+$/, "Enter digits only")
        .max(35, "Max 35 characters allowed"),
      clientName: Yup.string()
        .trim()
        .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters ,dash(-), dot(.), tilled and apostrophes(') are allowed")
        .min(1, "Please enter at least 1 Character")
        .max(100, "Max 100 characters allowed"),
      billAmount: Yup.string()
        .matches(/^\d+\.?\d*$/, "Enter digits only")
        .max(35, "Max 35 digits allowed"),
      transactionAmount: Yup.string()
        .matches(/^\d+\.?\d*$/, "Enter digits only")
        .max(35, "Max 35 digits allowed"),
      transactionId: Yup.string().max(50, "Max 50 characters allowed"),
      authCode: Yup.string().max(35, "Max 35 characters allowed"),
    });
    return (
      <>
        <Formik
          initialValues={{
            period: "Today",
            startDate: new Date(),
            endDate: new Date(),
            paymentMethod: "none",
            paymentStatus: "none",
            transactionId: "",
            authCode: "",
            clientName: "",
            seedAccountNumber: "",
            med1AccNum: "",
            billAmount: "",
            transactionAmount: "",
            selectPage: Number(this.state.pageno),
            selectRows: Number(this.state.rowsPerPage),
            accountNumber: "",
          }}
          onReset={this.handleReset}
          onSubmit={(values, { resetForm }, actions) => {
            let logindetails = JSON.parse(sessionStorage.getItem("loginDetails"));
            this.setState({ loader: true });
            const {
              period,
              startDate,
              endDate,
              paymentMethod,
              paymentStatus,
              transactionId,
              authCode,
              clientName,
              seedAccountNumber,
              med1AccNum,
              billAmount,
              transactionAmount,
              selectPage,
              selectRows,
              accountNumber,
            } = values;
            const passValues = {
              requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
              paymentTransactionSearch:
                sessionStorage?.getItem("currentUserRole") !== "Admin"
                  ? {
                      payerPartyId: logindetails.partyId,
                      startDate: this.advanceGetFormattedDate(values.startDate),
                      endDate: this.advanceGetFormattedDate(values.endDate),
                      paymentMethodId:
                        paymentMethod === "Credit Card / Debit Card" ? 75001 : paymentMethod === "eCheck" ? 75003 : null,
                      transactionStatus: paymentStatus === "Success" ? 80002 : paymentStatus === "Failed" ? 80003 : null,
                      pgTxnRefNumber: transactionId ? transactionId : null,
                      seedAccountNumber: seedAccountNumber ? seedAccountNumber : null,
                      facsAccountNumber: accountNumber ? accountNumber : null,
                      authorizationCode: authCode ? authCode : null,
                      pageNumber: this.state.pageno,
                      pageSize: values.selectRows,
                      noofpages: "",
                      billAmount: billAmount ? billAmount : null,
                      paymentAmount: transactionAmount ? transactionAmount : null,
                      clientName: clientName ? clientName : null,
                    }
                  : {
                      startDate: this.advanceGetFormattedDate(values.startDate),
                      endDate: this.advanceGetFormattedDate(values.endDate),
                      paymentMethodId:
                        paymentMethod === "Credit Card / Debit Card" ? 75001 : paymentMethod === "eCheck" ? 75003 : null,
                      transactionStatus: paymentStatus === "Success" ? 80002 : paymentStatus === "Failed" ? 80003 : null,
                      pgTxnRefNumber: transactionId ? transactionId : null,
                      seedAccountNumber: seedAccountNumber ? seedAccountNumber : null,
                      facsAccountNumber: accountNumber ? accountNumber : null,
                      authorizationCode: authCode ? authCode : null,
                      pageNumber: this.state.pageno,
                      pageSize: values.selectRows,
                      noofpages: "",
                      billAmount: billAmount ? billAmount : null,
                      paymentAmount: transactionAmount ? transactionAmount : null,
                      clientName: clientName ? clientName : null,
                    },
            };
            transactionService.addTransaction(passValues, (res) => {
              if (res.data?.responseInfo?.status === "SUCCESSFUL") {
                this.setState({
                  reportdata: res.data.paymentTtransactions,
                  noofrecords: res.data.totalRecords,
                  totalRecords: res.data.totalRecords,
                  loader: false,
                });
                let temp = [];
                for (let i = 0; i < res.data.totalRecords / 10; i++) {
                  temp.push(i);
                }
                this.handleRowChange(values.selectRows);
                this.setState({
                  noofpages: temp,
                  totalpages: temp.length,
                });

                !res.data.paymentTtransactions?.length && handleWarning("No transaction records found");
              } else if (res.data?.responseInfo.status === "FAILED") {
                this.setState({ loader: false });
                handleWarning(res.data?.responseInfo.responseMessage[0]);
              } else {
                handleError("Something went wrong!" + res.data?.responseInfo?.responseMessage);
                this.setState({ loader: false });
              }
            });
          }}
          validationSchema={futureDateSchema}>
          {(props) => {
            const { values, touched, error, handleChange, errors, setFieldValue } = props;

            return (
              <>
                <div className="patient-details transaction-card inputbg">
                  <div
                    className={
                      sessionStorage.getItem("currentUserRole") === "Customer"
                        ? "main-container-card main-container-sm"
                        : "main-container-card"
                    }>
                    <Form id="transaction-search" className="input-field" noValidate="noValidate">
                      <div className="payments-buttons">
                        <button
                          type="button"
                          onClick={() => this.setState({ transactionComponent: "paymentHistory" })}
                          className={`outlined-btn mx-1 ${
                            this.state.transactionComponent === "paymentHistory" ? "themeing-buttons" : null
                          }`}>
                          Payment History
                        </button>
                        <button
                          type="button"
                          onClick={() => this.setState({ transactionComponent: "futurePayment" })}
                          disabled={Number(sessionStorage.getItem("TotalAmount")) <= 0}
                          className={`outlined-btn mx-1 ${
                            this.state.transactionComponent === "futurePayment" ? "themeing-buttons" : null
                          }
                          ${Number(sessionStorage.getItem("TotalAmount")) <= 0 ? "disabled-btn" : null}`}>
                          Future Payment
                        </button>
                      </div>
                      {this.state.transactionComponent === "paymentHistory" ? (
                        <>
                          <div className="row">
                            <div className="col-12 form-group mb-0">
                              <FormControl component="fieldset" variant="outlined" className="w-100" fullWidth>
                                <label htmlFor="selectPeriod">Select Period</label>
                                <RadioGroup
                                  name="period"
                                  value={values.period}
                                  onChange={handleChange}
                                  className="radio-btns">
                                  <div className="row mx-0 justify-content-start payment-radio-btns">
                                    <div>
                                      <FormControlLabel
                                        value="Today"
                                        control={<CustomizedRadio className="checkedRadioIcon" />}
                                        label="Today"
                                        onChange={() => {
                                          setFieldValue(
                                            (values.startDate = new Date()),
                                            (values.period = "Today"),
                                            (values.endDate = new Date()),
                                          );
                                          this.setState({
                                            disableDate: true,
                                          });
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <FormControlLabel
                                        value="Last 30 Days"
                                        control={<CustomizedRadio />}
                                        label="Last 30 Days"
                                        onChange={() => {
                                          let dt = new Date();
                                          let stDate = new Date(dt.setDate(dt.getDate() - 30));
                                          setFieldValue(
                                            (values.startDate = stDate),
                                            (values.period = "Last 30 Days"),
                                            (values.endDate = new Date()),
                                          );
                                          this.setState({
                                            disableDate: true,
                                          });
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <FormControlLabel
                                        value="Last 60 Days"
                                        control={<CustomizedRadio />}
                                        label="Last 60 Days"
                                        onChange={() => {
                                          let dt = new Date();
                                          let stDate = new Date(dt.setDate(dt.getDate() - 60));
                                          setFieldValue(
                                            (values.startDate = stDate),
                                            (values.period = "Last 60 Days"),
                                            (values.endDate = new Date()),
                                          );
                                          this.setState({
                                            disableDate: true,
                                          });
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <FormControlLabel
                                        value="Last 90 Days"
                                        control={<CustomizedRadio />}
                                        label="Last 90 Days"
                                        onChange={() => {
                                          let dt = new Date();
                                          let stDate = new Date(dt.setDate(dt.getDate() - 90));
                                          setFieldValue(
                                            (values.startDate = stDate),
                                            (values.period = "Last 90 Days"),
                                            (values.endDate = new Date()),
                                          );
                                          this.setState({
                                            disableDate: true,
                                          });
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <FormControlLabel
                                        value="Custom"
                                        control={<CustomizedRadio />}
                                        label="Custom"
                                        onChange={() => {
                                          setFieldValue(
                                            (values.startDate = null),
                                            (values.period = "Custom"),
                                            (values.endDate = null),
                                          );
                                          this.setState({
                                            disableDate: false,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </RadioGroup>
                              </FormControl>
                            </div>
                            <div className="col-12">
                              <div className="form-row">
                                <div className="col-lg-3 col-md-4 col-sm-6 form-group">
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Grid container className="custom-date-picker date-picker">
                                      <label htmlFor="startDate" className="form-label w-100 required">
                                        Start Date
                                      </label>
                                      <DatePicker
                                        disableFuture
                                        name="startDate"
                                        autoOk
                                        disabled={this.state.disableDate}
                                        value={values.startDate}
                                        onChange={(val) => setFieldValue("startDate", val)}
                                        InputProps={{
                                          startAdornment: this.state.disableDate === false && (
                                            <IconButton
                                              position="end"
                                              onClick={() => setFieldValue("startDate", null)}
                                              disabled={!values?.startDate}
                                              style={{ order: 1 }}>
                                              <ClearIcon
                                                color={!values?.startDate ? "disabled" : "#808080"}
                                                fontSize="small"
                                              />
                                            </IconButton>
                                          ),
                                        }}
                                        InputAdornmentProps={{
                                          position: "end",
                                          style: { order: 2 },
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            helperText={null}
                                            margin="dense"
                                            inputProps={{
                                              ...params.inputProps,
                                              placeholder: "MM/DD/YYYY",
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <FormHelperText error name="securitycode">
                                      {values.startDate === null && touched.startDate && errors.startDate ? (
                                        <div>{errors.startDate}</div>
                                      ) : null}
                                    </FormHelperText>
                                  </LocalizationProvider>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 form-group">
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Grid container className="custom-date-picker date-picker">
                                      <label htmlFor="endDate" className="form-label w-100 required">
                                        End Date
                                      </label>
                                      <DatePicker
                                        name="endDate"
                                        disableFuture
                                        autoOk
                                        maxDate={new Date()}
                                        minDate={new Date(values.startDate)}
                                        disabled={this.state.disableDate}
                                        value={values.endDate}
                                        onChange={(val) => setFieldValue("endDate", val)}
                                        InputProps={{
                                          startAdornment: this.state.disableDate === false && (
                                            <IconButton
                                              position="end"
                                              onClick={() => setFieldValue("endDate", null)}
                                              disabled={!values?.endDate}
                                              style={{ order: 1 }}>
                                              <ClearIcon
                                                color={!values?.endDate ? "disabled" : "#808080"}
                                                fontSize="small"
                                              />
                                            </IconButton>
                                          ),
                                        }}
                                        InputAdornmentProps={{
                                          position: "end",
                                          style: { order: 2 },
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            helperText={null}
                                            margin="dense"
                                            inputProps={{
                                              ...params.inputProps,
                                              placeholder: "MM/DD/YYYY",
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <FormHelperText error name="securitycode">
                                      {values.endDate === null && touched.endDate && errors.endDate ? (
                                        <div>{errors.endDate}</div>
                                      ) : null}
                                    </FormHelperText>
                                  </LocalizationProvider>
                                </div>
                                {this.state.advancedSearch ? (
                                  <div className="col-md-4 col-lg-6 advance-search">
                                    <IconButton onClick={() => this.setState({ advancedSearch: false })}>
                                      <HighlightOffIcon fontSize="default" />
                                    </IconButton>
                                  </div>
                                ) : (
                                  <div className="col-md-4 col-lg-6 advance-search">
                                    <Button color="primary" onClick={() => this.setState({ advancedSearch: true })}>
                                      Advanced Search
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </div>

                            {this.state.advancedSearch ? (
                              <div className="col-12 mt-3">
                                <div className="form-row">
                                  <div className="col-lg-3 col-md-4 col-sm-6 form-group">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <label htmlFor="paymentMethod" className="form-label">
                                        Payment Method
                                      </label>
                                      <Select
                                        fullWidth
                                        name="paymentMethod"
                                        className="form-select form-control selectdropdown"
                                        onChange={handleChange("paymentMethod")}
                                        value={values["paymentMethod"]}
                                        error={Boolean(getIn(touched, "paymentMethod") && getIn(errors, "paymentMethod"))}>
                                        <MenuItem value="none" key="none" disabled>
                                          Select Payment Method
                                        </MenuItem>
                                        {paymentMethodOptions.map((option) => {
                                          return (
                                            <MenuItem value={option.value} key={option.key}>
                                              {option.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6 form-group">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <label htmlFor="paymentStatus" className="form-label">
                                        Payment Status
                                      </label>
                                      <Select
                                        fullWidth
                                        name="paymentStatus"
                                        className="form-select form-control selectdropdown"
                                        onChange={handleChange("paymentStatus")}
                                        value={values["paymentStatus"]}
                                        error={Boolean(getIn(touched, "paymentStatus") && getIn(errors, "paymentStatus"))}>
                                        <MenuItem value="none" key="none" disabled>
                                          Select Payment Status
                                        </MenuItem>
                                        {paymentStatusOptions.map((option) => {
                                          return (
                                            <MenuItem value={option.value} key={option.key}>
                                              {option.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6 form-group">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <label htmlFor="transactionId">Transaction ID</label>

                                      <TextField
                                        className="form-control signup-fields p-0"
                                        name="transactionId"
                                        placeholder="Please Enter Transaction ID"
                                        onChange={handleChange}
                                        value={values["transactionId"]}
                                        aria-label="text-field"
                                        fullWidth
                                        error={Boolean(getIn(touched, "transactionId") && getIn(errors, "transactionId"))}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        required="true"
                                      />
                                    </FormControl>
                                    <FormHelperText component="h5" variant="outlined" error="true">
                                      {getIn(touched, "transactionId") &&
                                        getIn(errors, "transactionId") &&
                                        getIn(errors, "transactionId")}
                                    </FormHelperText>
                                  </div>

                                  <div className="col-lg-3 col-md-4 col-sm-6 form-group">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <label htmlFor="authorizationCode">Authorization Code</label>

                                      <TextField
                                        className="form-control signup-fields p-0"
                                        name="authCode"
                                        placeholder="Please Enter Authorization Code"
                                        onChange={handleChange}
                                        value={values["authCode"]}
                                        aria-label="text-field"
                                        fullWidth
                                        error={Boolean(getIn(touched, "authCode") && getIn(errors, "authCode"))}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        required="true"
                                        inputProps={{
                                          maxLength: 35,
                                        }}
                                      />
                                    </FormControl>
                                    <FormHelperText component="h5" variant="outlined" error="true">
                                      {getIn(touched, "authCode") && getIn(errors, "authCode") && getIn(errors, "authCode")}
                                    </FormHelperText>
                                  </div>

                                  <div className="col-lg-3 col-md-4 col-sm-6  form-group">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <label htmlFor="clientName">Client Name</label>

                                      <TextField
                                        className="form-control signup-fields p-0"
                                        name="clientName"
                                        placeholder="Please Enter Client Name"
                                        onChange={handleChange}
                                        value={values["clientName"]}
                                        aria-label="text-field"
                                        fullWidth
                                        error={Boolean(getIn(touched, "clientName") && getIn(errors, "clientName"))}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        required="true"
                                        inputProps={{
                                          maxLength: 100,
                                        }}
                                      />
                                    </FormControl>
                                    <FormHelperText component="h5" variant="outlined" error="true">
                                      {getIn(touched, "clientName") &&
                                        getIn(errors, "clientName") &&
                                        getIn(errors, "clientName")}
                                    </FormHelperText>
                                  </div>

                                  <div className="col-lg-3 col-md-4 col-sm-6 form-group">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <label htmlFor="accountNumber">MED-1 Account Number</label>

                                      <TextField
                                        className="form-control signup-fields p-0"
                                        name="accountNumber"
                                        type="text"
                                        placeholder="Please Enter MED-1 Account Number"
                                        onChange={handleChange}
                                        value={values["accountNumber"]}
                                        aria-label="text-field"
                                        fullWidth
                                        error={Boolean(getIn(touched, "accountNumber") && getIn(errors, "accountNumber"))}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        required="true"
                                        InputProps={{
                                          maxLength: 35,
                                          inputComponent: NumberFormat,
                                        }}
                                      />
                                    </FormControl>
                                    <FormHelperText component="h5" variant="outlined" error="true">
                                      {getIn(touched, "accountNumber") &&
                                        getIn(errors, "accountNumber") &&
                                        getIn(errors, "accountNumber")}
                                    </FormHelperText>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6 form-group">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <label htmlFor="med1AccountNumber">Seed Account Number</label>

                                      <TextField
                                        className="form-control signup-fields p-0"
                                        name="seedAccountNumber"
                                        type="text"
                                        placeholder="Please Enter Seed Account Number"
                                        onChange={handleChange}
                                        value={values["seedAccountNumber"]}
                                        aria-label="text-field"
                                        fullWidth
                                        error={Boolean(
                                          getIn(touched, "seedAccountNumber") && getIn(errors, "seedAccountNumber"),
                                        )}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        required="true"
                                        InputProps={{
                                          maxLength: 35,
                                          inputComponent: NumberFormat,
                                        }}
                                      />
                                    </FormControl>
                                    <FormHelperText component="h5" variant="outlined" error="true">
                                      {getIn(touched, "seedAccountNumber") &&
                                        getIn(errors, "seedAccountNumber") &&
                                        getIn(errors, "seedAccountNumber")}
                                    </FormHelperText>
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6 form-group">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <label htmlFor="billAmount">Bill Amount</label>

                                      <TextField
                                        className="form-control signup-fields p-0"
                                        name="billAmount"
                                        type="text"
                                        placeholder="Please Enter Bill Amount"
                                        onChange={handleChange}
                                        value={values["billAmount"]}
                                        aria-label="text-field"
                                        fullWidth
                                        error={Boolean(getIn(touched, "billAmount") && getIn(errors, "billAmount"))}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        required="true"
                                        InputProps={{
                                          maxLength: 35,
                                          inputComponent: NumberFormat,
                                        }}
                                      />
                                    </FormControl>
                                    <FormHelperText component="h5" variant="outlined" error="true">
                                      {getIn(touched, "billAmount") &&
                                        getIn(errors, "billAmount") &&
                                        getIn(errors, "billAmount")}
                                    </FormHelperText>
                                  </div>

                                  <div className="col-lg-3 col-md-4 col-sm-6 form-group">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <label htmlFor="transactionAmount">Transaction Amount</label>

                                      <TextField
                                        className="form-control signup-fields p-0"
                                        type="text"
                                        name="transactionAmount"
                                        placeholder="Please Enter Transaction Amount"
                                        onChange={handleChange}
                                        value={values["transactionAmount"]}
                                        aria-label="text-field"
                                        fullWidth
                                        error={Boolean(
                                          getIn(touched, "transactionAmount") && getIn(errors, "transactionAmount"),
                                        )}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        required="true"
                                        InputProps={{
                                          maxLength: 35,
                                          inputComponent: NumberFormat,
                                        }}
                                      />
                                    </FormControl>
                                    <FormHelperText component="h5" variant="outlined" error="true">
                                      {getIn(touched, "transactionAmount") &&
                                        getIn(errors, "transactionAmount") &&
                                        getIn(errors, "transactionAmount")}
                                    </FormHelperText>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              " "
                            )}
                            {/* end ************************************************/}
                            <div className="col-12">
                              <div className="report-btns">
                                <button type="submit" className="themeing-buttons primary-button mr-1">
                                  Generate Report
                                </button>
                                <button type="reset" className="secondary-button ml-1">
                                  Reset
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="scheduled-amount">
                            <p className="amount-text">
                              Amount Scheduled:
                              <span className="amount-scheduled">$ {this.state.scheduledAmount}</span>
                            </p>
                          </div>
                          <PayPlanDetails />
                          <FuturePaymentMethod handleScheduledAmount={(amount) => this.handleScheduledAmount(amount)} />
                        </>
                      )}
                      {this.state.loader ? (
                        <SpinnerPage />
                      ) : this.state.transactionComponent === "paymentHistory" && this.state.reportdata.length > 0 ? (
                        <>
                          <Grid item xs={12}>
                            <div className="payplan-table">
                              <MaterialTable
                                title={<></>}
                                aria-level="2"
                                data={this.state.reportdata || []}
                                columns={tableColumns}
                                icons={tableIcons}
                                options={options}
                                components={{
                                  Pagination: (props) => {
                                    return (
                                      <div className="custom-pagination">
                                        <div className="selectpageno">
                                          <span className="mr-1">Rows Per Page:</span>
                                          <Select
                                            disableUnderline
                                            variant="standard"
                                            name="selectRows"
                                            value={values.selectRows}
                                            onChange={(event) => {
                                              setFieldValue((values.selectRows = event.target.value));
                                              this.setState({ rowsPerPage: values.selectRows });
                                              this.handleRowChange(values.selectRows);
                                              this.changepage(Number(this.state.pageno), values);
                                            }}>
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={15}>15</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                          </Select>
                                        </div>
                                        <div className="selectpageno">
                                          <span>Page No : </span>

                                          {this.state.noofpages ? (
                                            <Select
                                              disableUnderline
                                              variant="standard"
                                              name="selectPage"
                                              value={Number(this.state.pageno)}
                                              onChange={(event) => this.changepage(event.target.value, values)}>
                                              {this.state.noofpages
                                                ? this.state.noofpages.map((value, index) => {
                                                    return (
                                                      <MenuItem value={index} key={index}>
                                                        {index + 1}
                                                      </MenuItem>
                                                    );
                                                  })
                                                : ""}
                                            </Select>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        <div className="nextprevious">
                                          <FirstPage
                                            className={this.state.pageno > 0 ? "arrow-icons" : "arrow-icons-disabled"}
                                            onClick={() => (this.state.pageno > 0 ? this.changepage(0, values) : "")}
                                          />
                                          <ChevronLeft
                                            className={this.state.pageno > 0 ? "arrow-icons" : "arrow-icons-disabled"}
                                            onClick={() =>
                                              this.state.pageno > 0
                                                ? this.changepage(Number(this.state.pageno) - 1, values)
                                                : ""
                                            }
                                          />

                                          <span>
                                            <span>{this.state.from}</span> -<span className="mr-1">{this.state.to}</span>
                                            of
                                            <span className="ml-1">{this.state.noofrecords}</span>
                                          </span>
                                          <ChevronRight
                                            className={
                                              this.state.pageno >= this.state.noofpages.length - 1
                                                ? "arrow-icons-disabled"
                                                : "arrow-icons"
                                            }
                                            onClick={(e) => {
                                              if (this.state.pageno >= this.state.noofpages.length - 1) {
                                                e.preventDefault();
                                              } else {
                                                this.changepage(Number(this.state.pageno) + 1, values);
                                              }
                                            }}
                                          />
                                          <LastPage
                                            className={
                                              this.state.pageno >= this.state.noofpages.length - 1
                                                ? "arrow-icons-disabled"
                                                : "arrow-icons"
                                            }
                                            onClick={(e) => {
                                              if (this.state.pageno >= this.state.noofpages.length - 1) {
                                                e.preventDefault();
                                              } else {
                                                this.changepage(Number(this.state.noofpages.length) - 1, values);
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    );
                                  },
                                }}
                              />
                            </div>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                    </Form>
                  </div>
                </div>
                {this.state.loader === true ? <SpinnerPage /> : ""}
              </>
            );
          }}
        </Formik>
        {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
      </>
    );
  }
}
export default PaymentMethod;
