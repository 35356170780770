import { Global_var } from "../global/new _admin_globalvar";
import RestDataSource from "./restdatasource";
import { loginService } from "./loginService";

export const settlementService = {
  sendApprovedSettlement,
  sendRejectSettlement,
  sendRevisedSettlement,
  rpSettlementTable,
};

function sendApprovedSettlement(userData, userId, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_SEND_APPROVED_SETTLEMENT;
      return new RestDataSource(url, userId, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  }, fnError);
}

function sendRejectSettlement(userData, userId, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_SEND_REJECT_SETTLEMENT;
      return new RestDataSource(url, userId, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  }, fnError);
}

function sendRevisedSettlement(userData, userId, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_SEND_REVISED_SETTLEMENT;
      return new RestDataSource(url, userId, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  }, fnError);
}

function rpSettlementTable(userData, fn, userId, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_RP_SETTLEMENT_TABLE;
      return new RestDataSource(url, userId, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  }, fnError);
}
