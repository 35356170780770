import { securityImageService } from "../core_services/securityImageService";
export const SECURITY_IMAGE_REQUEST = "SECURITY_IMAGE_REQUEST";
export const SECURITY_IMAGE_SUCCESS = "SECURITY_IMAGE_SUCCESS";
export const SECURITY_IMAGE_FAILURE = "SECURITY_IMAGE_FAILURE";

export function securityImagepending() {
  return {
    type: SECURITY_IMAGE_REQUEST,
  };
}

export function getImage(securityImageData, fn) {
  return (dispatch) => {
    dispatch(securityImagepending());
    securityImageService.securityImage(securityImageData, (res) => {
      dispatch(securityImageSucess(res.data.responseObject));
      fn(res.data);
    });
  };
}

export function securityImageSucess(securityImageData) {
  return {
    type: SECURITY_IMAGE_SUCCESS,
    payload: securityImageData,
  };
}

export function securityImageError(error) {
  return {
    type: SECURITY_IMAGE_FAILURE,
    error: error,
  };
}
