import React, { createRef, useEffect, useState } from "react";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { Formik, Form } from "formik";
import { TextField, FormControl } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { handleWarning, handleError } from "../../../../../components/Notifications/Toast";
import SpinnerPage from "../../../../../Common/PP_loader";
import { getHospitalList } from "../../../../../action/ClientManagementAction";
import { Autocomplete } from "@material-ui/lab";
import ConfirmationDialog from "../../../../../components/ConfirmationDialog/index";
import SettlementOptionsPopUp from "./settlementOptions";
import SettlementOptionsTable from "./SettlementOptionsTable";
import Icons from "../../../../../util/Icons";
const SettlementOptions = () => {
  let initialValues = {
    hospitalCode: "",
  };

  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState(null);
  const [hospitalList, setHostipalList] = useState([]);
  const tableRef = createRef();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [settlementOptionsPopupData, setSettlementOptionsPopupData] = useState({});
  const [subBuId, setSubBuid] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const handleClose = () => {
    setIsEdit(false);
    setSettlementOptionsPopupData({});
    setSubBuid(null);
    setDialogOpen(false);
  };

  useEffect(() => {
    dispatch(
      getHospitalList((res) => {
        if (res?.status === 200) {
          setHostipalList(res?.data?.hospitalDetails);
        } else {
          handleError("Something went wrong");
        }
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values) => {
    if (values?.hospitalCode?.code) {
      setIsSubmit(true);
      setSearchData(values);
    } else {
      handleWarning("Please select a hospital");
    }
  };
  return (
    <div className="transaction-card patient-details" role="main">
      <div className="main-container-card">
        <div role="heading" aria-level="1" className="heading-style mb-3">
          <Icons type="settlementOptions" classNames="icon-heading-styles" />
          <h2 className="heading-style mb-0">Client Management: Settlement Options</h2>
        </div>
        <div>
          <div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {(props) => {
                const { values, setFieldValue } = props;
                return (
                  <Form id="transaction-search" className="input-field" noValidate="noValidate">
                    <div className="form-row">
                      <div className="col-lg-4 col-md-4 col-sm-6 form-group">
                        <FormControl variant="outlined" className="w-100" fullWidth>
                          <label htmlFor="hospitalCode" className="form-label">
                            Hospital
                          </label>

                          <Autocomplete
                            className="form-autocomplete"
                            {...props}
                            id="hospitalCode"
                            options={hospitalList}
                            size="small"
                            autoHighlight
                            getOptionDisabled={(option) => option.code === null}
                            value={values.hospitalCode}
                            getOptionLabel={(option) => {
                              return option && option.code ? option.code + " - " + option.hospitalName : "";
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                fullWidth
                                name="hospitalCode"
                                placeholder="Select Hospital Name"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "disabled",
                                }}
                              />
                            )}
                            onChange={(e, value) => {
                              setFieldValue("hospitalCode", value);
                            }}
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div className="payment-history-btns">
                      <button aria-label="Generate Report" type="submit" className="primary-button themeing-buttons">
                        Search
                      </button>
                      <button
                        aria-label="Reset"
                        type="reset"
                        className="secondary-button mx-2"
                        onClick={() => {
                          setSearchData(null);
                        }}>
                        Reset
                      </button>
                      <button
                        aria-label="Generate Report"
                        type="button"
                        className="primary-button themeing-secondary-buttons"
                        onClick={() => {
                          setDialogOpen(true);
                          setIsEdit(false);
                        }}>
                        Add
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          {searchData ? (
            <SettlementOptionsTable
              isSubmit={isSubmit}
              isUpdate={isUpdate}
              searchData={searchData}
              setIsEdit={setIsEdit}
              setIsSubmit={setIsSubmit}
              setIsUpdate={setIsUpdate}
              setIsLoading={setIsLoading}
              setSubBuid={setSubBuid}
              setDialogOpen={setDialogOpen}
              setSettlementOptionsPopupData={setSettlementOptionsPopupData}
            />
          ) : null}
          <ConfirmationDialog
            open={dialogOpen}
            handleClose={handleClose}
            title={isEdit ? "Edit Settlement Options" : "Add Settlement Options"}
            noActionButtons={true}
            maxWidth="md">
            <SettlementOptionsPopUp
              handleClose={handleClose}
              isEdit={isEdit}
              hospitalList={hospitalList}
              settlementOptionsPopupData={settlementOptionsPopupData}
              tableRef={tableRef}
              subBuId={subBuId}
              setIsUpdate={setIsUpdate}
            />
          </ConfirmationDialog>
          {isLoading && <SpinnerPage />}
        </div>
      </div>
    </div>
  );
};

export default SettlementOptions;
