/* eslint-disable consistent-return */
import React, { useContext } from "react";
import { ThemeContext } from "../../../themes/ThemeProvider";
import PAPatientSearchForm from "./PatientSearch";

const PAPatientSearchFormContainer = () => {
  const { ReusableInputStyles } = useContext(ThemeContext);
  const classes = ReusableInputStyles();

  return (
    <>
      <PAPatientSearchForm classes={classes} />
    </>
  );
};

export default PAPatientSearchFormContainer;
