import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getPatientlList } from "../../../reducer/Admin_patientDataReducer";
import { fetchPatientDetailData } from "../../../action/patientDetailReport";
import { requestInfo_PP } from "../../../Common/CommonFn";
import PaymentDetailsAccordion from "../Account/PaymentDetailsAccordion";
import creditcards from "../../../assets/images/credit-cards.png";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddEcheckContainer from "../../../containers/Admin_Report/PaymentMethods/AddECheckContainer";
import { loginService } from "../../../services/loginService";
import { fetchCreditCardData, addCreditCardData } from "../../../action/pp_PaymentCreditCardAction";
import { getCreditCardDataList, getCreditCardDataListPending } from "../../../reducer/pp_PaymentCreditCardReducer";
import { geteChequePaymentList } from "../../../reducer/pp_eChequePaymentReducer";
import { addEchaqueData, fetchEchaqueData, getCountryData } from "../../../action/pp_eChequePaymentAction";
import EchequeDetailsAccordion from "../../../containers/Admin_Report/PaymentMethods/EchequeDetailsAccordion";
import AddIcon from "@material-ui/icons/Add";
import Admin_Footer from "../../../components/admin_footer/footer";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";

const mapStateToProps = (state) => ({
  patientData: getPatientlList(state.patientDetailReducer),
  creditCardData: getCreditCardDataList(state.paymentCreditCardReducer),
  eChequeData: geteChequePaymentList(state.eChequePaymentReducer),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPatientDetailData: fetchPatientDetailData,
      fetchCreditCardData: fetchCreditCardData,
      addCreditCardData: addCreditCardData,
      fetchEchaqueData: fetchEchaqueData,
      addEchaqueData: addEchaqueData,
    },
    dispatch,
  );
const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const PaymentMethods = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        billingMethod: "eCheck",
        page: "paymentMethod",
        showloader: false,
        addPaymentMethodAccess: false,
        deletePaymentMethodAccess: false,
        paymentMethodAccess: false,
      };
    }

    getPaymentDetails() {
      const jtiToken =
        sessionStorage.getItem("jti-token") === null
          ? sessionStorage.getItem("jwt-token")
          : sessionStorage.getItem("jti-token");
      const Token = sessionStorage.getItem("jwt-token") === null ? "" : sessionStorage.getItem("jwt-token");

      var userpartyinfo = JSON.parse(sessionStorage.getItem("verifypayer"));
      if (userpartyinfo !== null) {
        let EChequePassValues = {
          requestInfo: requestInfo_PP(Token, jtiToken),
          paymentInfoSearch: {
            paymentMethodId: 75003,
            partyId: userpartyinfo.partyId,
          },
        };

        let CardPassValues = {
          requestInfo: requestInfo_PP(Token, jtiToken),
          paymentInfoSearch: {
            paymentMethodId: "75001",
            partyId: userpartyinfo.partyId,
          },
        };

        const UserId = 1234;
        const { fetchCreditCardData, fetchEchaqueData } = this.props;
        this.setState({ showloader: true });
        fetchCreditCardData(CardPassValues, UserId);
        fetchEchaqueData(EChequePassValues, UserId);
        setTimeout(() => this.setState({ showloader: false }), 1000);
      }
    }

    componentDidMount() {
      // this.setState({ addPaymentMethodAccess: true, deletePaymentMethodAccess: true });
      JSON.parse(sessionStorage.getItem("roleData"))?.pages?.map((item) => {
        if (item?.functionalities?.["Payment methods"] && sessionStorage.getItem("currentUserRole") === "Admin") {
          this.setState({
            paymentMethodAccess: item?.functionalities?.["Payment methods"],
          });
        }
      });
      window.scrollTo(0, 0);
      document.title = "MED-1 Secure Login - Payment Methods";
      sessionStorage.setItem("screen", "Paymentconfirmation");
      var queryString = "";
      queryString = window.location.search.split("&");
      if (queryString[0] !== "" && queryString[0] !== null) {
        this.setState({ showloader: false });
        var urlAppId = queryString ? queryString[0].replace("?id=", "") : "";

        const AppId = atob(urlAppId);
        sessionStorage.setItem("SIC_Info", AppId);

        sessionStorage.setItem("buid", 6);
        debugger;
        loginService.TokenUser((res) => {
          if (res.status === "success") {
            debugger;
            sessionStorage.setItem("jwt-token", res.responseObject);
            sessionStorage.setItem("jwt-tokencore", res.responseObject);
          }
        }, console.log("Token Error"));
      } else {
        var userinfo = JSON.parse(sessionStorage.getItem("payerinfo"));
        this.getPaymentDetails();
      }
    }

    addEcheck() {
      sessionStorage?.getItem("currentUserRole") === "Admin"
        ? this.props.history.push({
            pathname: "/pa/add-echeck",
            state: {
              page: this.state.page,
            },
          })
        : this.props.history.push({
            pathname: "/home/add-echeck",
            state: {
              page: this.state.page,
            },
          });
    }

    addCard() {
      sessionStorage?.getItem("currentUserRole") === "Admin"
        ? this.props.history.push({
            pathname: "/pa/credit-card",
            state: {
              page: this.state.page,
            },
          })
        : this.props.history.push({
            pathname: "/home/credit-card",
            state: {
              page: this.state.page,
            },
          });
    }

    resetPage = () => {
      this.setState({ page: "paymentMethod" });
    };

    render() {
      const { creditCardData, eChequeData } = this.props;

      return (
        <React.Fragment>
          <div className="transaction-card">
            <section
              className={
                sessionStorage.getItem("currentUserRole") === "Customer"
                  ? "payment-method-container main-container-sm"
                  : "payment-method-container"
              }>
              {this.state?.page === "paymentMethod" ? (
                <div>
                  <div>
                    <div className="payment-header">
                      <div className="payment-header-buttons">
                        <h6 className="payment-method-heading">Payment Methods</h6>
                        <div className="payment-methods payment-options">
                          <div className="credit-debit-section">
                            <button
                              onClick={() => this.addCard()}
                              disabled={
                                sessionStorage.getItem("currentUserRole") === "Admin"
                                  ? !this.state?.paymentMethodAccess?.add
                                  : false
                              }
                              style={{
                                background:
                                  sessionStorage.getItem("currentUserRole") === "Admin"
                                    ? !this.state?.paymentMethodAccess?.add && "#c8c8c8"
                                    : "",
                              }}
                              className="primary-button themeing-buttons mb-2 mr-2">
                              <AddIcon /> Credit/Debit Card
                            </button>
                            <button
                              onClick={() => this.addEcheck()}
                              style={{
                                background:
                                  sessionStorage.getItem("currentUserRole") === "Admin"
                                    ? !this.state?.paymentMethodAccess?.add && "#c8c8c8"
                                    : "",
                              }}
                              disabled={
                                sessionStorage.getItem("currentUserRole") === "Admin"
                                  ? !this.state?.paymentMethodAccess?.add
                                  : false
                              }
                              className="primary-button themeing-buttons mb-2">
                              <AddIcon /> Echeck
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="payment-details">
                        <div className="heading1">Your saved payment options</div>
                        <div className="heading2">Expires</div>
                        <div className="heading2">Actions</div>
                      </div>
                    </div>
                    <PaymentDetailsAccordion
                      creditCardData={creditCardData}
                      showloader={this?.state?.showloader}
                      getPaymentDetails={() => this.componentDidMount()}
                      deleteAccess={this.state.paymentMethodAccess?.delete}
                    />
                    <EchequeDetailsAccordion
                      eChequeData={eChequeData}
                      showloader={this?.state?.showloader}
                      getPaymentDetails={() => this.componentDidMount()}
                      deleteAccess={this.state.paymentMethodAccess?.delete}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </section>

            {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
          </div>
        </React.Fragment>
      );
    }
  },
);

export default PaymentMethods;
