import React, { useState } from "react";
import { Field, Form, Formik, getIn } from "formik";
import { FormHelperText } from "@material-ui/core";
import * as Yup from "yup";
import DialogActions from "@material-ui/core/DialogActions";
import { FormControl, MenuItem, Select, Button } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
    },
  },
};

const EditPaymentOrder = ({
  creditCardData,
  eChequeData,
  getCardListData,
  getEchecqueListData,
  billData,
  handleClose,
  handleEditOrder,
}) => {
  const intialvalues = {
    paymentMethod: "none",
  };

  const schema = Yup.object().shape({
    paymentMethod: Yup.string().notOneOf(["none"], "Select a Valid Option").required("Select a Payment Method"),
  });

  const onSubmit = (values) => {
    handleEditOrder(billData.orderid, billData.paymentarrangement, values.paymentMethod);
  };

  return (
    <>
      <Formik initialValues={intialvalues} onSubmit={onSubmit} validationSchema={schema} enableReinitialize>
        {(props) => {
          // eslint-disable-next-line no-unused-vars
          const { values, touched, handleChange, errors, handleBlur, setFieldValue } = props;
          return (
            <Form id="profile-page" className="recurring-plans input-field" noValidate="noValidate" autoComplete="off">
              <div>
                <div>
                  <p>
                    Order Id: <b>{billData.orderid}</b>
                  </p>
                </div>
                <div>
                  <label htmlFor="paymentMethod" className="form-label required">
                    Payment Method
                  </label>

                  <div className="form-group">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <Select
                        autocomplete="new-password"
                        fullWidth
                        name="paymentMethod"
                        placeholder="Payment Method"
                        value={values.paymentMethod}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                        className="form-select form-control">
                        <MenuItem value="none" disabled>
                          Select Payment Method
                        </MenuItem>
                        {getCardListData(creditCardData)}
                        {getEchecqueListData(eChequeData)}
                        <MenuItem value={`card&Debit/Credit Card`}>Debit/Credit Card</MenuItem>
                        <MenuItem value={`echeque&eCheck`}>eCheck</MenuItem>
                      </Select>
                    </FormControl>
                    <FormHelperText style={{ marginLeft: "4px" }} error>
                      {getIn(touched, "paymentMethod") && getIn(errors, "paymentMethod") && getIn(errors, "paymentMethod")}
                    </FormHelperText>
                  </div>
                </div>

                <DialogActions>
                  <Button type="submit" variant="contained" color="primary" className="themeing-buttons">
                    Yes
                  </Button>
                  <Button onClick={handleClose} color="primary" variant="outlined" className="secondary-button">
                    No
                  </Button>
                </DialogActions>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default EditPaymentOrder;
