import { useHistory } from "react-router";
import Med1Logo from "../../assets/images/Med1Logo.png";
import Capture2 from "../../assets/images/Capture2.jpg";
import UpdateTrackingPage from "../../containers/UpdateTrackingPage";

const PayNowTransactionSuccess = (props) => {
  const history = useHistory();

  const onSubmit = async (values, onSubmitProps) => {
    history.push("/billing");
  };

  return (
    <div className="dashboard-content" role="main">
      <div className="dashboard-content-card success-card">
        <div className="quickpay-payment">
          <div className="row m-0">
            <div className="col-md-5 thank-you">
              <h2 aria-level="1">Payment Successful</h2>
            </div>
            <div className="col-md-7 p-0">
              <div className="payment-receipt">
                <h4 aria-level="2">Payment Receipt</h4>

                <div className="row receipt-section">
                  <div className="col-md-6 col-5">
                    <p>Confirmation No.</p>
                  </div>
                  <div className="col-md-6 col-7">
                    <p>160574</p>
                  </div>
                  <div className="col-md-6 col-5">
                    <p>Transaction ID</p>
                  </div>
                  <div className="col-md-6 col-7">
                    <p>trn_be6a88ef-3c80-4e2b-a5b5-cf415c380e04</p>
                  </div>
                  <div className="col-md-6 col-5">
                    <p>Transaction Date</p>
                  </div>
                  <div className="col-md-6 col-7">
                    <p>4/22/2021 05:07 PM EST</p>
                  </div>
                  <div className="col-md-6 col-5">
                    <p>Email Address</p>
                  </div>
                  <div className="col-md-6 col-7">
                    <p>sweedling@gmail.com</p>
                  </div>
                  <div className="col-md-6 col-5">
                    <p>Phone Number</p>
                  </div>
                  <div className="col-md-6 col-7">
                    <p>+1 (317)753-1270</p>
                  </div>
                  <div className="col-md-6 col-5">
                    <p>Payment Method</p>
                  </div>
                  <div className="col-md-6 col-7">
                    <p>Credit Card</p>
                  </div>
                  <div className="col-md-6 col-5">
                    <p>From Account</p>
                  </div>
                  <div className="col-md-6 col-7">
                    <p>************3727</p>
                  </div>
                  <div className="col-md-6 col-5">
                    <p>Paid To</p>
                  </div>
                  <div className="col-md-6 col-7">
                    <p>MED1 Solutions,LLC</p>
                  </div>
                  <div className="col-md-6 col-5">
                    <p>Payment Amount</p>
                  </div>
                  <div className="col-md-6 col-7">
                    <p>$70.00</p>
                  </div>
                </div>
                <button className="download-button">Download Receipt</button>
              </div>
            </div>
          </div>
          <div className="success-img">
            <img src={Capture2} alt="payament-success" />
          </div>
        </div>
      </div>
      {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
    </div>
  );
};

export default PayNowTransactionSuccess;
