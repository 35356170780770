import {
  FETCH_CREDIT_CARD_DETAILS_SUCCESS,
  SAVE_CREDIT_CARD_PAYMENT,
  FETCH_CREDIT_CARD_DETAILS_PENDING,
  GET_COUNTRY,
} from "../action/pp_PaymentCreditCardAction";

const initialState = {
  creditCardData: [],
  pending: false,
};

const paymentCreditCardReducer = (state = initialState, action) => {
  // console.log(action, "ACTION IN REDUCER");
  switch (action.type) {
    case FETCH_CREDIT_CARD_DETAILS_SUCCESS:
      return {
        ...state,
        creditCardData: action.payload,
        pending: false,
      };
    case FETCH_CREDIT_CARD_DETAILS_PENDING:
      return {
        ...state,
        pending: true,
      };

    case SAVE_CREDIT_CARD_PAYMENT:
      return {
        ...state,
        data: action.payload,
      };

    // case GET_COUNTRY:
    //   return {
    //     ...state,
    //     data: action.payload,
    //   };
    default:
      return state;
  }
};

export default paymentCreditCardReducer;

export const getCreditCardDataList = (state) => state.creditCardData;

export const getCreditCardDataListPending = (state) => state?.pending;
