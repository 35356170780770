import RestDataSource from "./restdatasource";
import { Global_var } from "../global/new _admin_globalvar";
import { loginService } from "./loginService";

export const ReportsService = {
  getAccountingReports,
  getPaymentPlansReport,
  getReconciliationOrder,
  getPaymentPlansDetails,
  getScheduledTransaction,
  getScheduledFuturePayment,
  getCommunicationBy,
  getCommunicationType,
};

function getAccountingReports(userData, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res?.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_PP_ACCOUNTING_REPORT;
      return new RestDataSource(url, null, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}

function getReconciliationOrder(reportOrder, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-tokencore", res?.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_RECONCILIATION_ORDER_REPORT;
      return new RestDataSource(url, null, fn).Store(reportOrder, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            localStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}

function getPaymentPlansReport(userData, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-tokencore", res?.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_PAYMENT_PLANS_REPORT;
      return new RestDataSource(url, null, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            localStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}

function getPaymentPlansDetails(userData, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-tokencore", res?.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_PAYMENT_PLANS_REPORT_DETAILS;
      return new RestDataSource(url, null, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            localStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}

function getScheduledTransaction(data, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-tokencore", res?.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_SCHEDULED_PAYMENT_PLAN_REPORT;
      return new RestDataSource(url, null, fn).Store(data, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            localStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}

function getScheduledFuturePayment(data, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-tokencore", res?.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_SCHEDULED_FUTURE_PAYMENT_REPORT;
      return new RestDataSource(url, null, fn).Store(data, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            localStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}
function getCommunicationBy(data, fn) {
  var url = Global_var.BASEURL + Global_var.URL_COMMUNICATION_BY_REPORT;
  return new RestDataSource(url, null, fn).Store(data, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        localStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
}

function getCommunicationType(data, fn) {
  var url = Global_var.BASEURL + Global_var.URL_COMMUNICATION_TYPE_REPORT;
  return new RestDataSource(url, null, fn).Store(data, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        localStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
}
