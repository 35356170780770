import React, { useEffect, useState } from "react";
import clsx from "clsx";
import CmtDropdownMenu from "./CmtDropdownMenu";
import { Avatar, Box, fade, makeStyles, Typography } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import HttpsOutlinedIcon from "@material-ui/icons/HttpsOutlined";
import SecurityIcon from "@material-ui/icons/Security";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import logo from "../../assets/images/profile.jpg";
import UserLogo from "../../assets/images/admin-logo.jpg";
import AdminLogo1 from "../../assets/images/admin_logo1.jpg";

const useStyles = makeStyles((theme) => ({
  profileContent: {
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
    "& .MuiAvatar-root": {
      width: 50,
      height: 50,
    },
  },
  title: {
    padding: "0px",
    fontSize: "1rem",
    whiteSpace: "noWrap",
    fontWeight: 700,
    color: "#070a28",
  },
  titleDiv: {
    display: "block",
    marginLeft: 10,
  },
  subTitle: {
    display: "block",
    fontSize: "0.813rem",
    whiteSpace: "noWrap",
    fontWeight: 500,
    color: "#070a28",
    padding: "5px 0",
  },
  arrowIcon: {
    color: "#C4C4C4",
    fontSize: 30,
    marginLeft: 15,
  },
}));

const Profile = ({ userName, route, subtitle, isSidebarCollapsed }) => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAuthenticate, setIsAuthenticate] = useState("");
  const [actionsList, setActionsList] = useState([
    {
      icon: <AccountCircleIcon />,
      label: "Update Profile",
    },
    {
      icon: <HttpsOutlinedIcon />,
      label: "Change Password",
    },
    {
      icon: <SecurityIcon />,
      label: "Enable Two Factor Authentication",
    },
    {
      icon: <SettingsIcon />,
      label: "Theme Settings",
    },
  ]);

  const open = Boolean(anchorEl);
  const classes = useStyles();

  const paActionsList = [
    {
      icon: <HttpsOutlinedIcon />,
      label: "Change Password",
    },
    {
      icon: <SettingsIcon />,
      label: "Theme Settings",
    },
  ];

  return (
    <Box className={clsx(classes.profileRoot, "Cmt-profile-pic")}>
      <CmtDropdownMenu
        isAuthenticate={isAuthenticate}
        setIsAuthenticate={setIsAuthenticate}
        open={open}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        route={route}
        TriggerComponent={
          <Box className={classes.profileContent}>
            {isSidebarCollapsed ? (
              <>
                <div className={classes.titleDiv}>
                  <div className={classes.title}>{userName}</div>
                  <div className={classes.subTitle}>{subtitle}</div>
                </div>
                {open ? (
                  <ExpandLessIcon className={classes.arrowIcon} fontSize="small" />
                ) : (
                  <ExpandMoreIcon className={classes.arrowIcon} fontSize="small" />
                )}
              </>
            ) : null}
          </Box>
        }
        items={sessionStorage?.getItem("currentUserRole") === "Admin" ? paActionsList : actionsList}
      />
    </Box>
  );
};

export default Profile;
