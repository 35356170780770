import { userService } from "../services/userDetalService";

export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_PENDING = "FETCH_USER_DETAILS_PENDING";
export const SEARCH_USER = "SEARCH_USER";

export function fetchUserDetailSuccess(patientData) {
  return {
    type: FETCH_USER_DETAILS_SUCCESS,
    payload: patientData,
  };
}
export function fetchPatientpending() {
  return {
    type: FETCH_USER_DETAILS_PENDING,
  };
}
export function fetchUserDetailData(patientData, userId, fn) {
  return (dispatch) => {
    dispatch(fetchPatientpending());
    userService.getUserList(patientData, userId, fn);
  };
}

export function searchUser(patientData, fn) {
  return (dispatch) => {
    dispatch(fetchPatientpending());
    userService.searchUser(patientData, (res) => fn(res));
  };
}
