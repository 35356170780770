/* eslint-disable react/jsx-pascal-case */
import React from "react";
import PP_QuickPayStepper from "../../Common/PP_QuickPayStepper";
import PP_Fail_Receipt from "../../Common/PP_TransactionFail";
import CreditCardStepper from "../../routes/Pages/Quickpay/Stepper/CreditCardStepper";
class PP_Transaction_Fail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: true,
    };
  }

  componentDidMount() {
    document.title = "MED-1 Quick Pay - Transaction Fail";
    window.scrollTo(0, 0);
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="row justify-content-center">
          <div className="col-md-8 col-sm-8 success-width">
            <div className="blue-bg"></div>

            <div className="white-bg">
              <CreditCardStepper />
              <div className="row">
                <div className="col-md-12 col-sm-12 success-width">
                  <PP_Fail_Receipt {...this.props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PP_Transaction_Fail;
