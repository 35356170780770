/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-script-url */
/* eslint-disable no-useless-constructor */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
class LeftSideMenu extends Component {
  constructor(props) {
    super(props);
  }

  dashboard = () => {
    window.location.replace("/Dashboard");
  };
  transactionReport = () => {
    if (window.matchMedia("(max-width: 991px)").matches) {
      document.querySelector("body").classList.toggle("sidebar-collapse");
      document.querySelector("body").classList.toggle("sidebar-closed");
      document.querySelector("body").classList.toggle("sidebar-open");
    }
    this.props.history.push("/Payment-History");
  };
  settlementReport = () => {
    window.location.replace("/Settlement-Report");
  };
  activityReport = () => {
    window.location.replace("/Activity-Report");
  };
  patientReport = () => {
    window.location.replace("/Patient-Details");
  };
  redirectToUser = () => {
    window.location.replace("/User-Management");
  };
  redirectToRole = () => {
    window.location.replace("/Role-Management");
  };
  viewbills = () => {
    if (window.matchMedia("(max-width: 991px)").matches) {
      document.querySelector("body").classList.toggle("sidebar-collapse");
      document.querySelector("body").classList.toggle("sidebar-closed");
      document.querySelector("body").classList.toggle("sidebar-open");
    }
    this.props.history.push("/AccountDetails");
  };
  viewPaymentMethods = () => {
    if (window.matchMedia("(max-width: 991px)").matches) {
      document.querySelector("body").classList.toggle("sidebar-collapse");
      document.querySelector("body").classList.toggle("sidebar-closed");
      document.querySelector("body").classList.toggle("sidebar-open");
    }
    this.props.history.push("/PaymentMethods");
  };
  render() {
    return (
      <React.Fragment>
        <aside className="main-sidebar sidebar-dark-primary elevation-4 admin-elevation-4">
          <a
            href={window?.location?.pathname === "/Patient-Details" ? "javascript:void(0)" : "/Patient-Details"}
            className="brand-link nav-logo-bg">
          </a>

          <div className="sidebar">
            <nav className="mt-3">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false">
                
                <li
                  className={
                    window.location.pathname === "/AccountDetails" ||
                    window.location.pathname === "/PayPlanOptions" ||
                    window.location.pathname === "/Dashboard"
                      ? "active nav-item"
                      : "nav-item"
                  }>
                  <a onClick={this.viewbills} className="nav-link admin-sidebarbtn">
                    <i class="fas fa-user nav-icon"></i>
                    <p>Account Details</p>
                  </a>
                </li>

                <li className={window.location.pathname == "/Payment-History" ? "active nav-item" : "nav-item"}>
                  <a onClick={this.transactionReport} className="nav-link admin-sidebarbtn">
                    <i class="fas fa-file-alt nav-icon"></i>
                    <p>Payment History</p>
                  </a>
                </li>

                <li
                  className={
                    window.location.pathname === "/PaymentMethods" ||
                    window.location.pathname === "/addCard" ||
                    window.location.pathname === "/addEcheck"
                      ? "active nav-item"
                      : "nav-item"
                  }>
                  <a onClick={this.viewPaymentMethods} className="nav-link admin-sidebarbtn">
                    <i class="fas fa-credit-card nav-icon"></i>
                    <p>Payment Methods</p>
                  </a>
                </li>

                
              </ul>
            </nav>
          </div>
        </aside>
      </React.Fragment>
    );
  }
}
export default withRouter(LeftSideMenu);
