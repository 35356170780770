import React, { useEffect, useState } from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import { I18nextProvider } from "react-i18next";
import i18n from "../../../../i18n";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { FormControl, Grid, TextField } from "@material-ui/core";
import { Autocomplete, LocalizationProvider } from "@material-ui/lab";
import SpinnerPage from "../../../../Common/PP_loader";
import { campaignTrackingService } from "../../../../services/campaignTrackingService";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import DatePicker from "@material-ui/lab/DatePicker";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import { handleError, handleWarning } from "../../../../components/Notifications/Toast";
import ReactApexChart from "react-apexcharts";

const paidChartLabels = ["Paid", "Not Paid", "Pay Plan", "Paid & Pay Plan"];

const initialValues = {
  startDate: null,
  endDate: null,
  campaign: "",
};

const PURLUsageReport = () => {
  const [purlUsagePaidData, setPurlUsagePaidData] = useState({});
  const [purlUsageViewedData, setPurlUsageViewedData] = useState({});
  const [campaignList, setCampaignList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    campaignTrackingService.getCampaignList(
      (res) => {
        setCampaignList(res.data?.campaignList);
      },
      (err) => {
        if (err) {
          handleError("Something went wrong!");
          setIsLoading(false);
        }
      },
    );
  }, []);

  const validationSchema = Yup.object().shape({
    // startDate: Yup.string().nullable().required("Start Date is requird"),
    // endDate: Yup.string().nullable().required("End Date is requird"),
    // campaign: Yup.string().nullable().required("Campaign is requird"),
  });

  const handleSubmit = (values) => {
    let showAlert = true;
    for (let key in values) {
      if (showAlert && Boolean(values[key])) {
        showAlert = false;
      }
    }

    if (showAlert) {
      handleWarning("Please select at least one field to search");
    } else {
      setIsLoading(true);
      setPurlUsagePaidData(null);
      setPurlUsageViewedData(null);
      const payload = {
        campaignName: values.campaign,

        startDate: values.startDate ? moment(values.startDate).format("YYYY-MM-DD") : "",

        endDate: values.endDate ? moment(values.endDate).format("YYYY-MM-DD") : "",

        pageSize: 10,

        pageNo: 1,
      };

      campaignTrackingService.getPurlPaidData(
        payload,
        (res) => {
          const data = res.data;
          setIsLoading(false);
          if (data.noOfTotalAccounts) {
            setPurlUsagePaidData(data);
          } else {
            setPurlUsagePaidData(null);
            handleWarning("No Purl Paid records found");
          }
        },
        (err) => {
          if (err) {
            handleError("Something went wrong!");
            setIsLoading(false);
          }
        },
      );

      campaignTrackingService.getPurlViewedData(
        payload,
        (res) => {
          const data = res.data;
          setIsLoading(false);
          if (data.purlSent) {
            setPurlUsageViewedData(data);
          } else {
            setPurlUsageViewedData(null);
            handleWarning("No Purl Viewed records found");
          }
        },
        (err) => {
          if (err) {
            handleError("Something went wrong!");
            setIsLoading(false);
          }
        },
      );
    }
  };
  const barState1 = {
    series: [
      {
        data: [
          purlUsagePaidData?.noOfPaidAccounts,
          purlUsagePaidData?.noOfNotPaidAccounts,
          purlUsagePaidData?.noOfPPAddedAccounts,
          purlUsagePaidData?.noOfPaidAndPPAddedAccounts,
        ],
      },
    ],
    options: {
      title: {
        text: "Purl Sent-Paid & Not Paid Accounts",
        align: "center",
        margin: 10,
        // offsetX: 0,
        // offsetY: 0,
        // floating: false,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          fontFamily: "poppins",
          color: "#263238",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0.5,
          inverseColors: false,
          gradientToColors: ["#03DA20", "#E80707", "#0454E9", "#F2CD00"],
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          let label = w.config.xaxis.categories[dataPointIndex][0];
          const tooltip = `<div class="purl-tooltip-box"><span class="purl-tooltip-colorBox" style="background-color: ${w.config.colors[dataPointIndex]};"></span><span class="ml-2 pr-1">${label}:</span><b>${series[seriesIndex][dataPointIndex]}</b></div>`;
          return tooltip;
        },
      },
      chart: {
        width: "100%",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors: ["#15FF35", "#FF2727", "#1E6DFF", "#FFDB12"],
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      xaxis: {
        categories: [["Paid"], ["Not Paid"], ["Pay Plan"], ["Paid & Pay Plan"]],
        labels: {
          style: {
            colors: ["#373737", "#373737", "#373737", "#373737"],
            fontSize: "14px",
            fontFamily: "poppins, Arial, sans-serif",
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: true,
          // color: '#78909C',
          offsetX: 0,
          offsetY: 0,
        },
        min: 0,
        max: purlUsagePaidData?.noOfTotalAccounts,
        tickAmount: 6,
        title: {
          text: "No. Of Accounts",
          style: {
            fontSize: "14px",
            fontFamily: "poppins, Arial, sans-serif",
            fontWeight: 500,
          },
        },
      },
    },
  };

  const barState2 = {
    series: [
      {
        data: [purlUsageViewedData?.viewed, purlUsageViewedData?.notViewedPaid],
      },
    ],
    options: {
      title: {
        text: "Purl Sent-Viewed & Not Viewed",
        align: "center",
        margin: 10,
        // offsetX: 0,
        // offsetY: 0,
        // floating: false,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          fontFamily: "poppins",
          color: "#263238",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0.5,
          inverseColors: false,
          gradientToColors: ["#03DA20", "#E80707"],
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          let label = w.config.xaxis.categories[dataPointIndex][0];
          const tooltip = `<div class="purl-tooltip-box"><span class="purl-tooltip-colorBox" style="background-color: ${w.config.colors[dataPointIndex]};"></span><span class="ml-2 pr-1">${label}:</span><b>${series[seriesIndex][dataPointIndex]}</b></div>`;
          return tooltip;
        },
      },
      chart: {
        width: "100%",
        type: "bar",
        toolbar: {
          show: false,
        },
      },

      colors: ["#15FF35", "#FF2727"],
      plotOptions: {
        bar: {
          columnWidth: "35%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      xaxis: {
        categories: [["Viewed"], ["Not Viewed"]],
        labels: {
          style: {
            colors: ["#373737", "#373737"],
            fontSize: "14px",
            fontFamily: "poppins, Arial, sans-serif",
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: true,
          // color: '#78909C',
          offsetX: 0,
          offsetY: 0,
        },
        min: 0,
        max: purlUsageViewedData?.purlSent,
        tickAmount: 6,
        title: {
          text: "No. Of Accounts",
          style: {
            fontSize: "14px",
            fontFamily: "poppins, Arial, sans-serif",
            fontWeight: 500,
          },
        },
      },
    },
  };

  return (
    <I18nextProvider i18n={i18n}>
      <React.Fragment>
        {isLoading && <SpinnerPage />}
        <div className="transaction-card" role="main">
          <div className="main-container-card">
            <div role="heading" aria-level="1" className="heading-style mb-3">
              <DataUsageIcon className="mr-2" />
              <h2 className="content-title heading-style mb-0"> Campaign Tracking: PURL Usage</h2>
            </div>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}>
              {({ touched, errors, setFieldValue, values, resetForm }) => (
                <Form className="input-field" noValidate="noValidate">
                  <div className="form-row">
                    <div className="col-lg-4 col-xl-4 col-sm-6 col-md-4 form-group">
                      <FormControl component="fieldset" variant="outlined" className="w-100" fullWidth>
                        <>
                          <label className="form-label" htmlFor="campaign">
                            Campaign
                          </label>
                          <Autocomplete
                            id="campaign"
                            options={campaignList && campaignList.length > 0 ? campaignList : []}
                            size="small"
                            className="form-autocomplete"
                            autoHighlight
                            value={values.campaign}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                fullWidth
                                name="campaign"
                                placeholder="Select Campaign"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "off", // disable autocomplete and autofill
                                }}
                              />
                            )}
                            onChange={(e, value) => {
                              setFieldValue("campaign", value);
                            }}
                          />
                        </>
                      </FormControl>
                      <ErrorMessage component="div" name="campaign" className="error-text" />
                    </div>
                    <div className="col-lg-4 col-xl-4 col-sm-6 col-md-4 form-group">
                      <FormControl component="fieldset" variant="outlined" className="w-100" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Grid container className="date-picker">
                            <label htmlFor="startDate" className="form-label w-100">
                              Start Date
                            </label>
                            <DatePicker
                              name="startDate"
                              autoOk
                              value={values.startDate}
                              onChange={(val) => setFieldValue("startDate", val)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helperText={null}
                                  margin="dense"
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "MM/DD/YYYY",
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </LocalizationProvider>
                      </FormControl>
                    </div>
                    <div className="col-lg-4 col-xl-4 col-sm-6 col-md-4 form-group">
                      <FormControl component="fieldset" variant="outlined" className="w-100" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Grid container className="date-picker">
                            <label htmlFor="endDate" className="form-label w-100">
                              End Date
                            </label>
                            <DatePicker
                              name="endDate"
                              autoOk
                              value={values.endDate}
                              onChange={(val) => setFieldValue("endDate", val)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helperText={null}
                                  margin="dense"
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "MM/DD/YYYY",
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </LocalizationProvider>
                      </FormControl>
                    </div>
                  </div>
                  <div className="payment-history-btns">
                    <button type="submit" className="primary-button themeing-buttons mr-2">
                      Search
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        resetForm();
                        setPurlUsagePaidData(null);
                        setPurlUsageViewedData(null);
                      }}
                      className="secondary-button">
                      Reset
                    </button>
                  </div>
                  {purlUsagePaidData &&
                  Object.keys(purlUsagePaidData).length > 0 &&
                  purlUsageViewedData &&
                  Object.keys(purlUsageViewedData).length > 0 ? (
                    <div className="purl-usage-container mt-3">
                      <div className="row purl-box">
                        {purlUsagePaidData && Object.keys(purlUsagePaidData).length > 0 ? (
                          <div className="col-md-6 ">
                            <div>
                              {/* <Bar options={paidChartOptions} data={paidData} /> */}
                              {/* <div id="chart"> */}
                              <ReactApexChart
                                options={barState1.options}
                                series={barState1.series}
                                type="bar"
                                className="purl-chart-1"
                                height={350}
                              />
                              {/* </div> */}
                            </div>
                            <div className="purl-table-wrapper">
                              <div className="purl-table-header purl-usage-header">
                                <div className="purl-count">Purl Sent-Paid & Not Paid Accounts</div>
                                <div style={{ fontWeight: "bold" }}>:</div>
                                <div className="purl-usage-count">{purlUsagePaidData.noOfTotalAccounts}</div>
                              </div>
                              <div
                                className="purl-table-header purl-table-row"

                                // style={{ backgroundColor: "rgb(216 234 247)" }}
                              >
                                <div className="purl-table-cell">
                                  <span className="table-cell-title">Paid</span>
                                </div>
                                <div className="purl-table-cell purl-usage-table-cell">
                                  <span className="table-cell-title"> {purlUsagePaidData.noOfPaidAccounts}</span>
                                </div>
                                <div className="purl-table-cell purl-usage-cell">
                                  <span className="table-cell-data"> {purlUsagePaidData.paidAccountsPercentage}%</span>
                                </div>
                              </div>
                              <div className="purl-table-header purl-table-row">
                                <div className="purl-table-cell ">
                                  <span className="table-cell-title">Not Paid</span>
                                </div>
                                <div className="purl-table-cell purl-usage-table-cell">
                                  <span className="table-cell-title">{purlUsagePaidData.noOfNotPaidAccounts}</span>
                                </div>
                                <div className="purl-table-cell purl-usage-cell">
                                  <span className="table-cell-data"> {purlUsagePaidData.notPaidAccountsPercentage}% </span>
                                </div>
                              </div>
                              <div className="purl-table-header purl-table-row">
                                <div className="purl-table-cell">
                                  <span className="table-cell-title">Pay Plan</span>
                                </div>
                                <div className="purl-table-cell purl-usage-table-cell">
                                  <span className="table-cell-title">{purlUsagePaidData?.noOfPPAddedAccounts}</span>
                                </div>
                                <div className="purl-table-cell purl-usage-cell">
                                  <span className="table-cell-data">{purlUsagePaidData?.ppAddedAccountsPercentage}% </span>
                                </div>
                              </div>
                              <div className="purl-table-header purl-table-row">
                                <div className="purl-table-cell">
                                  <span className="table-cell-title">Paid & Pay Plan</span>
                                </div>
                                <div className="purl-table-cell purl-usage-table-cell">
                                  <span className="table-cell-title">{purlUsagePaidData?.noOfPaidAndPPAddedAccounts}</span>
                                </div>
                                <div className="purl-table-cell purl-usage-cell">
                                  <span className="table-cell-data">
                                    {purlUsagePaidData?.paidAndPPAddedAccountsPercentage}%
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {purlUsageViewedData && Object.keys(purlUsageViewedData).length > 0 ? (
                          <div className="col-md-6">
                            <div className="d-flex justify-content-center">
                              {/* <Bar options={viewedChartOptions} data={viewData} /> */}
                              <ReactApexChart
                                options={barState2.options}
                                series={barState2.series}
                                type="bar"
                                className="purl-chart-2"
                                height={350}
                              />
                            </div>
                            <div className="purl-table-wrapper">
                              <div className="purl-table-header purl-usage-header">
                                <div className="purl-count">Purl Sent-Viewed & Not Viewed</div>
                                <div style={{ fontWeight: "bold" }}>:</div>
                                <div className="purl-usage-count">
                                  <span>{purlUsageViewedData.purlSent}</span>
                                </div>
                              </div>
                              <div className="purl-table-header purl-table-row">
                                <div className="purl-table-cell">
                                  <span className="table-cell-title">Viewed</span>
                                </div>
                                <div className="purl-table-cell purl-usage-table-cell">
                                  <span className="table-cell-title">{purlUsageViewedData.viewed}</span>
                                </div>
                                <div className="purl-table-cell purl-usage-cell">
                                  <span className="table-cell-data">{purlUsageViewedData.viewedPercentage}%</span>
                                </div>
                              </div>
                              <div className="purl-table-header purl-table-row">
                                <div className="purl-table-cell">
                                  <span className="table-cell-title">Not Viewed</span>
                                </div>
                                <div className="purl-table-cell purl-usage-table-cell">
                                  <span className="table-cell-title"> {purlUsageViewedData.notViewedPaid}</span>
                                </div>
                                <div className="purl-table-cell purl-usage-cell">
                                  <span className="table-cell-data"> {purlUsageViewedData.notViewedPercentage}%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </React.Fragment>
    </I18nextProvider>
  );
};

export default PURLUsageReport;

// notPaidPercentage: 96
// notViewedPercentage: 94
// paidPercentage: 4
// responseInfo: {applicationId: "11102", buId: "2", subBuId: "0", status: "SUCCESSFUL",…}
// totalNotPaid: 48
// totalNotViewedPaid: 47
// totalPaid: 2
// totalPurlSent: 50
// totalViewed: 3
// viewedPercentage: 6
