import React from "react";
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import i18n from "../../i18n";
import $ from "jquery";
import { I18nextProvider } from "react-i18next";

class PP_Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      setOpen: false,
      showMenu: false,
      footertext: "  ",
    };

    this.toggleframe = this.toggleframe.bind(this);
  }

  redirectToBack = (path) => {
    this.props.history.push("/Contact-Us");
  };
  componentDidMount() {
    var contentdata = JSON.parse(sessionStorage.getItem("contentdata"));

    var footerdata = "";

    var context = this;

    if (contentdata) {
      var url = contentdata[0].footer_text;

      if (url === "undefined") {
        window.location.reload();
      }

      $.get(
        url,
        function (html_string) {
          context.setState({
            footertext: html_string,
          });
          footerdata = html_string;
        },
        "html",
      );
    } else {
    }
  }
  toggleframe = () => {};
  render() {
    if (sessionStorage.getItem("screen") === "contactUs") {
      return (
        <I18nextProvider i18n={i18n}>
          <React.Fragment>
            <div className="footer">
              <Translation>{(t, { i18n }) => <label>{t("quickPayFooter_Copyrights")}</label>}</Translation>
              <br></br>
              WE ARE DEBT COLLECTORS. THIS IS AN ATTEMPT TO COLLECT A DEBT. ANY INFORMATION OBTAINED WILL BE USED FOR THAT
              PURPOSE.
            </div>
          </React.Fragment>
        </I18nextProvider>
      );
    } else
      return (
        <I18nextProvider i18n={i18n}>
          <React.Fragment>
            <div className="footer"></div>
          </React.Fragment>
        </I18nextProvider>
      );
  }
}

export default withRouter(PP_Footer);
