import React from "react";
import { withRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { Translation } from "react-i18next";
import i18n from "../../i18n";
import ContactUs from "../ContactUs/index";

class Admin_Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <I18nextProvider i18n={i18n}>
        <React.Fragment>
          <div>
            <div className="footer-cp">
              <div className="footer-contactus">
                <span>Need Help?</span>
                <span>
                  <ContactUs />
                </span>
              </div>
              {/* <Translation>{(t, { i18n }) => <label>{t("changePassword_copyrightFooter")} </label>}</Translation> */}
              {/* ©2020 all rights reserved. */}
              <div>
                <span>Copyright {new Date().getFullYear()}. All Rights Reserved.</span>
              </div>
              <div>
                WE ARE DEBT COLLECTORS. THIS IS AN ATTEMPT TO COLLECT A DEBT. ANY INFORMATION OBTAINED WILL BE USED FOR THAT
                PURPOSE.
              </div>
            </div>
          </div>
        </React.Fragment>
      </I18nextProvider>
    );
  }
}

export default withRouter(Admin_Footer);
