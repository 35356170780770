import React from "react";
import { TxnDoneButton } from "..//assets/MaterialControl";
import { Translation } from "react-i18next";
import i18n from "../i18n";
import { I18nextProvider } from "react-i18next";

class PP_Fail_Receipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      failureMessage: "",
      status: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });

    sessionStorage.setItem("screen", "Thankyou");
    var message = JSON.parse(sessionStorage.getItem("transactiondata"));

    var status = message?.transactionStatus;
    var reason = message?.transactionStatusMessage;

    this.setState({
      failureMessage: reason,
      status: status,
    });
  }
  componentWillMount() {
    sessionStorage.setItem("screen", "Thankyou");
  }
  gotologin = () => {
    sessionStorage.clear();
    this.props.history.push("/Login");
  };
  render() {
    return (
      <I18nextProvider i18n={i18n}>
        <React.Fragment>
          <div>
            <div id="container">
              <div className="box-left-success">
                <div className="heading-fail mb mt">Transaction Failed</div>
                <div className="button-box align">
                  <div className="receipt-message mb">
                    {" "}
                    Your payment transaction {this.state.status}
                    <br />
                    {this.state.failureMessage}
                  </div>
                  <div className="button-box align mt-4">
                    {sessionStorage.getItem("loginDetails") !== null ? (
                      <Translation>
                        {(t, { i18n }) => (
                          <TxnDoneButton
                            href={sessionStorage.getItem("currentUserRole") === "Admin" ? "/pa/billing" : "/home/billing"}
                            type="button"
                            className="button-border"
                            style={{ marginTop: "10px" }}>
                            {" "}
                            {t("receipt_DoneButton")}
                          </TxnDoneButton>
                        )}
                      </Translation>
                    ) : (
                      <Translation>
                        {(t, { i18n }) => (
                          <TxnDoneButton href="/payment-outstanding" type="button" className="button-border">
                            {" "}
                            {t("receipt_DoneButton")}
                          </TxnDoneButton>
                        )}
                      </Translation>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </I18nextProvider>
    );
  }
}

export default PP_Fail_Receipt;
