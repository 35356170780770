import React from "react";
import tickiconImg from "../../../../assets/images/tick-icon.png";

class ThankYou extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <div className="container" style={{ backgroundColor: "aliceblue", borderRadius: "4px" }}>
          <div style={{ textAlign: "center" }}>
            <div className="img-align">
              <img src={tickiconImg} alt="" width="54" height="54" />
            </div>
            <b>Transaction Succesfull</b>
          </div>
          <div className="container">
            <div className="row m-3">
              <div className="col-md-6">Confirmation No.</div>
              <div className="col-md-6"></div>
            </div>
            <div className="row m-3">
              <div className="col-md-6">Transaction ID</div>
              <div className="col-md-6" style={{ textAlign: "right", fontWeight: "600" }}>
                trn_9461a5da-e08b-442c-8aa3-b1f33fb51570
              </div>
            </div>
            <div className="row m-3">
              <div className="col-md-6">Transaction Date</div>
              <div className="col-md-6" style={{ textAlign: "right", fontWeight: "600" }}>
                04/26/2022
              </div>
            </div>
            <div className="row m-3">
              <div className="col-md-6">Email Address</div>
              <div className="col-md-6" style={{ textAlign: "right", fontWeight: "600" }}>
                dsds@gmail.com
              </div>
            </div>
            <div className="row m-3">
              <div className="col-md-6">Phone Number</div>
              <div className="col-md-6" style={{ textAlign: "right", fontWeight: "600" }}>
                +1 (999) 399-9999
              </div>
            </div>
            <div className="row m-3">
              <div className="col-md-6">Payment Method</div>
              <div className="col-md-6" style={{ textAlign: "right", fontWeight: "600" }}>
                Credit Card
              </div>
            </div>
            <div className="row m-3">
              <div className="col-md-6">From Account</div>
              <div className="col-md-6" style={{ textAlign: "right", fontWeight: "600" }}>
                ************1111
              </div>
            </div>
            <div className="row m-3">
              <div className="col-md-6">Paid To</div>
              <div className="col-md-6" style={{ textAlign: "right", fontWeight: "600" }}>
                MED-1 Solutions, LLC
              </div>
            </div>
            <div className="row m-3">
              <div className="col-md-6">Payment Amount</div>
              <div className="col-md-6" style={{ textAlign: "right", fontWeight: "600" }}>
                $ 1,000.00
              </div>
            </div>
            <div className="row">
              <div className="col-md-12" style={{ textAlign: "center", padding: "10px" }}>
                This receipt will be emailed to you shortly
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ThankYou;
