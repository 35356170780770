import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getIn } from "formik";
import { MenuItem, Select, FormControl, TextField } from "@material-ui/core";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { Grid } from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";

const intialvalues = {
  noOfInstallments: "none",
  day: "Monday",
  installmentStartDate: null,
  paymentAmount: "50",
};

const installmentOptions = [
  { key: 1, value: 1 },
  { key: 2, value: 2 },
  { key: 3, value: 3 },
  { key: 4, value: 4 },
  { key: 5, value: 5 },
  { key: 6, value: 6 },
  { key: 7, value: 7 },
  { key: 8, value: 8 },
];

const dayOptions = [
  { key: "Monday", value: "Monday" },
  { key: "Tuesday", value: "Tuesday" },
  { key: "Wednesday", value: "Wednesday" },
  { key: "Thursday", value: "Thursday" },
  { key: "Friday", value: "Friday" },
  { key: "Saturday", value: "Saturday" },
  { key: "Sunday", value: "Sunday" },
];

const schema = Yup.object().shape({});

const WeeklyForm = ({ handleClose }) => {
  const onSubmit = () => {
  };

  return (
    <div>
      <Formik initialValues={intialvalues} onSubmit={onSubmit} validationSchema={schema}>
        {(props) => {
          const { values, touched, error, handleChange, errors, setFieldValue } = props;
          return (
            <Form id="profile-page" className="input-field" noValidate="noValidate">
              {" "}
              <div className="row form-group m-3 ">
                <label htmlFor="paymentAmount">Payment Amount</label>

                <OutlinedInput
                  className="form-control security-input"
                  id="paymentAmount"
                  name="paymentAmount"
                  placeholder="Please Enter Amount"
                  onChange={handleChange}
                  value={values["paymentAmount"]}
                  aria-label="text-field"
                  fullWidth
                  error={Boolean(getIn(touched, "paymentAmount") && getIn(errors, "paymentAmount"))}
                  helperText={
                    getIn(touched, "paymentAmount") && getIn(errors, "paymentAmount") && getIn(errors, "paymentAmount")
                  }
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  InputLabelProps={{
                    shrink: true,
                  }}></OutlinedInput>
              </div>
              <div className="row form-group m-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container>
                    <label htmlFor="installmentStartDate" className="form-label">
                      Installment Start Date
                    </label>
                    <DatePicker
                      disableFuture
                      className="date-picker"
                      name="installmentStartDate"
                      autoOk
                      value={values.installmentStartDate}
                      onChange={(val) => setFieldValue("installmentStartDate", val)}
                      renderInput={(params) => <TextField {...params} helperText={null} margin="normal" />}
                    />
                  </Grid>
                </LocalizationProvider>
              </div>
              <div className="row form-group m-3">
                <FormControl variant="outlined" className="w-100" fullWidth>
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Number of Installments
                  </label>
                  <Select
                    fullWidth
                    name="noOfInstallments"
                    className="form-select form-control"
                    onChange={handleChange("noOfInstallments")}
                    value={values["noOfInstallments"]}
                    error={Boolean(getIn(touched, "noOfInstallments") && getIn(errors, "noOfInstallments"))}>
                    <MenuItem value="none" key="none" disabled>
                      Select number of Installments
                    </MenuItem>
                    {installmentOptions.map((option) => {
                      return (
                        <MenuItem value={option.value} key={option.key}>
                          {option.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="row form-group m-3">
                <FormControl variant="outlined" className="w-100" fullWidth>
                  <label htmlFor="selectDay" className="form-label">
                    Select Day
                  </label>
                  <Select
                    fullWidth
                    name="selectDay"
                    id="selectDay"
                    className="form-select form-control"
                    onChange={handleChange("day")}
                    value={values["day"]}
                    error={Boolean(getIn(touched, "day") && getIn(errors, "day"))}>
                    <MenuItem value="none" key="none" disabled>
                      Select Day
                    </MenuItem>
                    {dayOptions.map((option) => {
                      return (
                        <MenuItem value={option.value} key={option.key}>
                          {option.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </Form>
          );
        }}
      </Formik>
      <div className="submit-cancel-btns row">
        <div className="col-md-4 ">
          <button className="secondary-button w-100" onClick={handleClose}>
            Cancel
          </button>
        </div>
        <div className="col-md-8">
          <button className="primary-button w-100" onClick={handleClose}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default WeeklyForm;
