export const countryList = [
  {
    countryId: 1,
    countryName: "united states of america",
    countryCode: "1",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
];

export const stateList = [
  {
    stateId: 1,
    stateName: "alabama",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 2,
    stateName: "alaska",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 3,
    stateName: "arizona",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 4,
    stateName: "arkansas",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 5,
    stateName: "california",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 6,
    stateName: "colorado",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 7,
    stateName: "connecticut",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 8,
    stateName: "delaware",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 9,
    stateName: "district of columbia",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 10,
    stateName: "florida",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 11,
    stateName: "georgia",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 12,
    stateName: "hawaii",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 13,
    stateName: "idaho",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 14,
    stateName: "illinois",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 15,
    stateName: "indiana",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 16,
    stateName: "iowa",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 17,
    stateName: "kansas",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 18,
    stateName: "kentucky",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 19,
    stateName: "louisiana",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 20,
    stateName: "maine",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 21,
    stateName: "maryland",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 22,
    stateName: "massachusetts",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 23,
    stateName: "michigan",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 24,
    stateName: "minnesota",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 25,
    stateName: "mississippi",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 26,
    stateName: "missouri",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 27,
    stateName: "montana",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 28,
    stateName: "nebraska",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 29,
    stateName: "nevada",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 30,
    stateName: "new hampshire",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 31,
    stateName: "new jersey",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 32,
    stateName: "new mexico",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 33,
    stateName: "new york",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 34,
    stateName: "north carolina",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 35,
    stateName: "north dakota",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 36,
    stateName: "ohio",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 37,
    stateName: "oklahoma",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 38,
    stateName: "oregon",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 39,
    stateName: "pennsylvania",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 40,
    stateName: "puerto rico",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 41,
    stateName: "rhode island",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 43,
    stateName: "south carolina",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 42,
    stateName: "south dakota",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 44,
    stateName: "tennessee",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 45,
    stateName: "texas",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 46,
    stateName: "utah",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 47,
    stateName: "vermont",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 48,
    stateName: "virginia",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 49,
    stateName: "washington",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 50,
    stateName: "west virginia",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 51,
    stateName: "wisconsin",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
  {
    stateId: 52,
    stateName: "wyoming",
    isActive: 1,
    createdOn: "04/11/2020 07:59:15",
    modifiedOn: "04/11/2020 07:59:15",
  },
];

