import { Global_var } from "../global/global_var";
import RestDataSource from "./restdatasource";
import { loginService } from "./loginService";
// import { checkUserSession } from "../common/userSessionExpiry";

export const UnlockUserService = {
    getUnlockUser
};

function getUnlockUser(userData, fn) {
    loginService.TokenUser((res) => {
        if (res.status === "success") {
            sessionStorage.setItem("jwt-tokencore", res.responseObject);
            var url = Global_var.BASEURL + Global_var.URL_UNLOCK_USER;
            return new RestDataSource(url,0 , fn).Store(userData, (res) => {
                if (res != null) {
                    if (res.headers["token"] != null) {
                        sessionStorage.setItem("jwt-tokencore", res.headers.token);
                    }
                    fn(res);
                }
            });
        }
    });
}
