/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import logo from "../../../assets/images/Med1Logo.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { resetPasswordDataByEmail } from "../../../action/resetPasswordAction";
import { loginService } from "../../../core_services/loginService";
import { I18nextProvider } from "react-i18next";
import { Translation } from "react-i18next";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { ChangeCancelButton, ChangeResetButton } from "../../../assets/MaterialControl";
import { OutlinedInput, Grid, TextField, IconButton, InputAdornment, MenuItem, Select } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { handleError, handleSuccess, handleWarning } from "../../../components/Notifications/Toast";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmPassword: "",
      tokenReceived: "",
      emailid: "",
      name: "",
      partydata: "",
      imgdata: "",
      imgourl: "",
      img2: "",
      img3: "",
      img4: "",
      img5: "",
      img6: "",
      selectedImage: "",
      imgdataid: "",
      docid: "",
      pwdlength: false,
      pwdonecap: false,
      pwdonesmall: false,
      pwdonenum: false,
      pwdonespl: false,
      pwdnospace: false,
      showNewPassword: false,
      showConfirmPassword: false,
    };
    this.initialState = this.state;
  }

  componentDidMount() {
    const queryString = window.location.search;
    const urlParameter = new URLSearchParams(queryString);

    var businessid = "2";
    var bussid = urlParameter.get("buid");
    if (queryString && bussid) {
      sessionStorage.setItem("buid", bussid);
    } else {
      sessionStorage.setItem("buid", businessid);
    }
    loginService.TokenUser((resToken) => {
      if (resToken["status"] === "success") {
        sessionStorage.setItem("jwt-tokencore", resToken.responseObject);
      }
    });
    var params = this.props.location.search;
    params = params.replace("?token=", "");

    this.setState({ tokenReceived: params });
  }

  // Yup validation

  _resetPasswordValidation = Yup.object().shape({
    newPassword: Yup.string()
      .required("New password is required")
      .min(8, "Password must be minimum 8 characters")
      .max(20, "Password must be maximum 20 characters")
      .matches(/[a-z]/, "Password should have at least one lowercase character")
      .matches(/[A-Z]/, "Password should have at least one uppercase character")
      .matches(/(?=.*\d)/, "Password must contain at least one digit")
      .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one special character")
      .matches(/^\S*$/, "Password should not contain whitespace"),
    confirmPassword: Yup.string()
      .required("Comfirm new password is required")
      .min(8, "Password must be minimum 8 characters")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

  _handleSubmit = (values, actions) => {
    this.props.resetPasswordDataByEmail(
      {
        userPassword: values.newPassword,
        token: this.state.tokenReceived,
      },
      (res) => {
        if (res.data.status === "success") {
          handleSuccess("Your password has been reset successfully!");
          this.props.history.push("/");
        } else if (res.data.status === "fail") {
          handleWarning("Oops! " + res?.data?.reasonText);
        } else {
          handleError("Something wents wrong! " + res?.data?.reasonText);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  _backToDashboard = () => {
    this.props.history.push("/");
  };
  handleClickShowNewPassword = () => {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  };

  handleClickShowConfirmNewPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  render() {
    return (
      <I18nextProvider i18n={i18n}>
        <React.Fragment>
          <div className="page-wrapper" role="main">
            <div className="reset-password-container">
              <div className="dashboard-content-card">
                <Formik
                  enableReinitialize
                  initialValues={{
                    newPassword: this.state.newPassword,
                    confirmPassword: this.state.confirmPassword,
                    showPassword: false,
                    showConfirmPassword: false,
                  }}
                  validationSchema={this._resetPasswordValidation}
                  onSubmit={this._handleSubmit}>
                  {({ touched, errors, setFieldValue, values }) => (
                    <Form autoComplete="off">
                      <div className="admin-logo">
                        <img src={logo} width="100" alt="admin logo" />
                      </div>
                      <div className="input-field">
                        <div className="row mb-1">
                          <div className="col-md-5 col-sm-12">
                            <div className="text-left reset-password-text">
                              <h6> Reset Password </h6>
                            </div>
                            <div className="form-group">
                              <div className="">
                                <label htmlFor="userPassword" className="required">
                                  Password
                                </label>
                              </div>
                              <OutlinedInput
                                className="form-control signup-fields"
                                maxLength="20"
                                name="newPassword"
                                value={values.password}
                                placeholder="Password"
                                onChange={(event) => {
                                  setFieldValue((values.newPassword = event.target.value));

                                  this.setState({
                                    newPassword: event.target.value,
                                  });

                                  var str = event.target.value;
                                  var pattern1 = new RegExp(/[a-z]/);
                                  var pattern2 = new RegExp(/[A-Z]/);
                                  var pattern3 = new RegExp(/(?=.*\d)/);
                                  var pattern4 = new RegExp(/[!@#$%^&*(),.?\":{}|<>]/);
                                  var pattern5 = new RegExp(/^\S*$/);

                                  if (str.length > 8 || str.length < 20) {
                                    this.setState({
                                      pwdlength: true,
                                    });
                                  } else {
                                    this.setState({
                                      pwdlength: false,
                                    });
                                  }

                                  if (pattern1.test(str)) {
                                    this.setState({
                                      pwdonesmall: true,
                                    });
                                  } else {
                                    this.setState({
                                      pwdonesmall: false,
                                    });
                                  }

                                  if (pattern2.test(str)) {
                                    this.setState({
                                      pwdonecap: true,
                                    });
                                  } else {
                                    this.setState({
                                      pwdonecap: false,
                                    });
                                  }

                                  // Number validation
                                  if (pattern3.test(str)) {
                                    this.setState({
                                      pwdonenum: true,
                                    });
                                  } else {
                                    this.setState({
                                      pwdonenum: false,
                                    });
                                  }

                                  // Number special char
                                  if (pattern4.test(str)) {
                                    this.setState({
                                      pwdonespl: true,
                                    });
                                  } else {
                                    this.setState({
                                      pwdonespl: false,
                                    });
                                  }

                                  // space check
                                  if (pattern5.test(str)) {
                                    this.setState({
                                      pwdnospace: true,
                                    });
                                  } else {
                                    this.setState({
                                      pwdnospace: false,
                                    });
                                  }
                                }}
                                type={this.state.showNewPassword ? "text" : "password"}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                startAdornment={
                                  <InputAdornment position="end">
                                    <LockOutlinedIcon />
                                  </InputAdornment>
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowNewPassword}
                                      onMouseDown={this.handleMouseDownPassword}>
                                      {this.state.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                required="true"
                                inputProps={{
                                  maxLength: 50,
                                }}></OutlinedInput>
                              <ErrorMessage component="div" name="newPassword" className="error-text" />
                            </div>
                            <div className="form-group">
                              <div className="">
                                <label htmlFor="confirmPassword" className="required">
                                  Confirm Password
                                </label>
                              </div>
                              <OutlinedInput
                                className="form-control signup-fields"
                                maxLength="20"
                                type={this.state.showConfirmPassword ? "text" : "password"}
                                name="confirmPassword"
                                value={values.confirmPassword}
                                placeholder={i18n.t("patient_Confirm_Password")}
                                onChange={(event) => {
                                  setFieldValue((values.confirmPassword = event.target.value));

                                  this.setState({
                                    confirmPassword: event.target.value,
                                  });
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                startAdornment={
                                  <InputAdornment position="end">
                                    <LockOutlinedIcon />
                                  </InputAdornment>
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowConfirmNewPassword}
                                      onMouseDown={this.handleMouseDownPassword}>
                                      {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                required="true"
                                inputProps={{
                                  maxLength: 50,
                                }}></OutlinedInput>
                              <ErrorMessage component="div" name="confirmPassword" className="error-text" />
                            </div>
                            <div className="proceed-cancel-buttons">
                              <button onClick={this._backToDashboard} type="button" className="secondary-button mx-1">
                                Cancel
                              </button>

                              <button className="primary-button themeing-buttons mx-1" type="submit">
                                Continue
                              </button>
                            </div>
                          </div>

                          <div className="col-md-7 col-sm-12">
                            <div className="admin-light-blue-bg">
                              <div className="admin-blue-title">
                                <Translation>
                                  {(t, { i18n }) => <label>{t("resetPassword_PasswordPolicy")}</label>}
                                </Translation>
                              </div>
                              <ul className="admin-character-password">
                                <li>
                                  Min 8 chars to max 20 chars
                                  {this.state.pwdlength === true ? (
                                    <span className="admin-close-icon">
                                      <i className="fas fa-check-circle admin-tick-icon-green"></i>
                                    </span>
                                  ) : (
                                    <span className="admin-close-icon">
                                      <i className="fas fa-times-circle admin-close-icon-red"></i>
                                    </span>
                                  )}
                                </li>
                                <li>
                                  Min 1 char should be capital ['A-Z']
                                  {this.state.pwdonecap === true ? (
                                    <span className="admin-close-icon">
                                      <i className="fas fa-check-circle admin-tick-icon-green"></i>
                                    </span>
                                  ) : (
                                    <span className="admin-close-icon">
                                      <i className="fas fa-times-circle admin-close-icon-red"></i>
                                    </span>
                                  )}
                                </li>
                                <li>
                                  Min 1 char should be small ['a-z']
                                  {this.state.pwdonesmall === true ? (
                                    <span className="admin-close-icon">
                                      <i className="fas fa-check-circle admin-tick-icon-green"></i>
                                    </span>
                                  ) : (
                                    <span className="admin-close-icon">
                                      <i className="fas fa-times-circle admin-close-icon-red"></i>
                                    </span>
                                  )}
                                </li>
                                <li>
                                  Min 1 char should be numeric ['0-9']
                                  {this.state.pwdonenum === true ? (
                                    <span className="admin-close-icon">
                                      <i className="fas fa-check-circle admin-tick-icon-green"></i>
                                    </span>
                                  ) : (
                                    <span className="admin-close-icon">
                                      <i className="fas fa-times-circle admin-close-icon-red"></i>
                                    </span>
                                  )}
                                </li>
                                <li>
                                  Min 1 char should be special [@#$%&*!_-+]
                                  {this.state.pwdonespl === true ? (
                                    <span className="admin-close-icon">
                                      <i className="fas fa-check-circle admin-tick-icon-green"></i>
                                    </span>
                                  ) : (
                                    <span className="admin-close-icon">
                                      <i className="fas fa-times-circle admin-close-icon-red"></i>
                                    </span>
                                  )}
                                </li>

                                <li>
                                  No space is allowed [ ]
                                  {this.state.pwdnospace === true ? (
                                    <span className="admin-close-icon">
                                      <i className="fas fa-check-circle admin-tick-icon-green"></i>
                                    </span>
                                  ) : (
                                    <span className="admin-close-icon">
                                      <i className="fas fa-times-circle admin-close-icon-red"></i>
                                    </span>
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
        </React.Fragment>
      </I18nextProvider>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetPasswordDataByEmail: resetPasswordDataByEmail,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
