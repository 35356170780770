import React, { createContext, useEffect, useState } from "react";
import { fetchUserDetailData } from "../action/userDetailReport";

const defaultContext = {
  english: {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us",
  },
  spanish: {
    languageId: "spanish",
    locale: "es",
    name: "Spanish",
    icon: "es",
  },
};
// reference object for spanish
// {
//   languageId: 'spanish',
//   locale: 'es',
//   name: 'Spanish',
//   icon: 'es',
// },
export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [buID, setBuId] = useState("SampleJWt Token");
  const [locale, updateLocale] = useState(defaultContext.english);
  const [sidebar, setSidebar] = useState(false);
  const [reportBar, setReportBar] = useState(false);
  const [clientManagementBar, setClientManagementBar] = useState(false);
  const [twoFAuth, setTwoFAuth] = useState(false);
  const [twoFactorDisablePopup, setTwoFactorDisablePopup] = useState(false);
  const [isAuthenticate, setIsAuthenticate] = useState("");
  const [hoverSide, setHoverSide] = useState(false);
  const [hoverNav, setHoverNav] = useState(false);

  const value = {
    buID,
    locale,
    updateLocale,
    sidebar,
    setSidebar,
    setReportBar,
    reportBar,
    twoFAuth,
    setTwoFAuth,
    setTwoFactorDisablePopup,
    twoFactorDisablePopup,
    setIsAuthenticate,
    isAuthenticate,
    clientManagementBar,
    setClientManagementBar,
    setHoverSide,
    hoverSide,
    setHoverNav,
    hoverNav,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
