import React from "react";
import { useHistory } from "react-router";
import Payment from "../../../assets/images/PaymentsSchedule.jpg";
import Med1Logo from "../../../assets/images/Med1Logo.png";
import RocketIcon from "../../../assets/images/quick-pay-rocket-icon.svg";
import { Link } from "react-router-dom";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";

const PaymentSchedule = (props) => {
  const history = useHistory();
  return (
    <div className="page-wrapper  vertical-height" role="main">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div>
            <div className="card-md">
              <div className="header">
                <div className="rocket-icon">
                  <img src={RocketIcon} alt="" />
                </div>
                <div className="logo">
                  <img src={Med1Logo} className="img-fluid" alt="Med-1 Solutions Logo" />
                </div>
              </div>
              <div className="main-heading">
                <h1>
                  Quick<span>Pay</span>
                </h1>
              </div>
              <div className="successfull-payment-card">
                <div className="quickpay-payment">
                  <div>
                    <div className="thank-you payment-scheduled">
                      <h2>Payment Scheduled Successfully</h2>
                    </div>
                    <div className="signup-text">
                      <p>Thank you for using QuickPay,</p>
                      <p>
                        {" "}
                        <Link to="/registration" className="sign-up-link">
                          Sign Up here
                        </Link>{" "}
                        with us for future payments
                      </p>
                    </div>
                    <div className="">
                      <div className="payment-receipt">
                        <div className="payment-schedule-section py-2">
                          <img src={Payment} alt="payment" />
                          <h3 class="payment-processed">Payment will be processed on 20th April 2021</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
    </div>
  );
};

export default PaymentSchedule;
