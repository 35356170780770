import React, { Component } from "react";
import { bindActionCreators } from "redux";

import { connect } from "react-redux";

import { getPatientlList } from "../../../../reducer/Admin_patientDataReducer";
import { fetchPatientDetailData } from "../../../../action/patientDetailReport";
import { requestInfo_PP } from "../../../../Common/CommonFn";
import { unlockUserData } from "../../../../action/UnlockUserAction";

import { LockOpen } from "@material-ui/icons";
import UnlockUser from "./UnlockUser";

const mapStateToProps = (state) => ({
  patientData: getPatientlList(state.patientDetailReducer),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPatientDetailData: fetchPatientDetailData,
      unlockUserData: unlockUserData,
    },
    dispatch,
  );
const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const ResetPatientsContainer = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
    componentDidMount() {
      document.title = "MED-1 PA Login - Patient Details";
      const PassValues = {
        requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        billSearch: {
          billId: 47448,
          uniqueIdentityId: "",
          isActive: [0, 1],
        },
      };

      const UserId = "1212";
    }
    render() {
      const { patientData } = this.props;
      
      return (
        <div className="transaction-card" role="main">
          <div className="main-container-card" role="heading" aria-level="1">
            <div role="heading" aria-level="1" className="heading-style mb-3">
              <LockOpen className="mr-2" />
              <h2 className="heading-style">Unlock User</h2>
            </div>
            <div>
              <UnlockUser patientData={patientData} {...this.props} />
            </div>
          </div>
          <div></div>
        </div>
      );
    }
  },
);

export default ResetPatientsContainer;
