import React from "react";
import { Formik, Form } from "formik";
import { getIn } from "formik";
import SpinnerPage from "../../../../Common/PP_loader";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ImageTooltip from "../../../../components/ImageTooltip";
import Med1AccNumberTooltip from "../../../../assets/images/medAccountNumberTooltip.jpg";
import Icons from "../../../../util/Icons";
import * as Yup from "yup";
import { handleSuccess, ToastContainer, handleWarning, handleError } from "../../../../components/Notifications/Toast";
// import { Translation } from "react-i18next";
import { searchPatient } from "../../../../action/patientDetailReport";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestInfo_PP } from "../../../../Common/CommonFn";
// import Grid from "@material-ui/core/Grid";
// import MaterialTable from "material-table";
//import PaymentIcon from "@material-ui/icons/Payment";
// import { Tooltip } from "@material-ui/core";
import { capitalize } from "../../../../util/Captilize";
// import { tableIcons } from "../../../../components/TableIcons";
// import PatchedPagination from "../../../../Common/TablePagination";
// import { ViewPatientbtn } from "../../../../assets/MaterialControl";
import { identifyPayerService } from "../../../../services/identifyPayerService";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import moment from "moment";
import { FormHelperText, FormControl } from "@material-ui/core";
import PatientSearchTable from "./PatientSearchTable";

class PatientDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      med1AccNum: "",
      ssn: "",
      firstName: "",
      lastName: "",
      email: "",
      showloader: false,
      patientData: [],
      phoneNumber: "",
      verifyAndPayAccess: false,
      isSubmit: false,
      searchData: null,
    };
  }

  _handleReset = () => {
    this.setState({
      med1AccNum: "",
      ssn: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    });
  };
  viewPatient = (value) => {
    sessionStorage.setItem("verifyAndPay", true);
    var ssn = value?.payer?.uniqueIdentifierId;
    var n = 4;
    let billDob = value?.dob || "";

    const PassValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      payerBillSearch: {
        accountReferenceNumber: value?.accountReferenceNumber,
        uniqueIdentityId: ssn?.substring(ssn.length - n),
        dob: billDob || "",
        contactNumber: value?.payer?.contactNo || "",
        contactEmail: value?.payer?.contactEmail || "",
      },
    };
    const UserId = "1212";
    identifyPayerService.addIdentifyPayer(
      PassValues,
      UserId,
      (res) => {
        if (res["data"].responseInfo.status === "SUCCESSFUL") {
          handleSuccess(
            "Patient details of " +
              res?.data?.payer?.firstName.charAt(0).toUpperCase() +
              res?.data?.payer?.firstName.slice(1) +
              " " +
              res?.data?.payer?.lastName.charAt(0).toUpperCase() +
              res?.data?.payer?.lastName.slice(1) +
              " has been verified successfully. Fetching data...",
          );

          sessionStorage.setItem("verifypayer", JSON.stringify(res?.data?.payer));
          sessionStorage.setItem("Verifieduser", res?.data?.payer?.firstName + " " + res?.data?.payer?.lastName);
          sessionStorage.setItem("payerinfo", JSON.stringify(PassValues.payerBillSearch));
          var context = this;
          setTimeout(function () {
            window.location.replace("/pa/billing");
          }, 2000);
        } else if (res["data"].responseInfo.status === "FAILED") {
          handleWarning(
            <span>
              Can not find MED-1 Account with information supplied! <br />
              Please contact MED-1 support at : <br />
              (888) 323-0811{" "}
            </span>,
          );
        } else {
          handleWarning("Can not find MED-1 Account with information supplied!");
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };
  _handleSubmit = (values, { actions }) => {
    for (let i in JSON.parse(sessionStorage.getItem("roleData"))?.pages) {
      if (
        JSON.parse(sessionStorage.getItem("roleData"))?.pages[i]?.functionalities?.["Verify and pay"]?.["page access"] ===
        "true"
      ) {
        this.setState({
          verifyAndPayAccess: true,
        });
      }
    }
    // this.setState({
    //   showloader: true,
    //   pageno: 0,
    // });
    if (
      values.accountNumber ||
      values.firstName ||
      values.lastName ||
      values.socialSecurityNumber ||
      values.emailid ||
      values.contactno
    ) {
      this.setState({ ...this.state, isSubmit: true, searchData: values });
      var Dob = values.dateOfBirth;
      var DateOfBirth = "";
      if (Dob) {
        var mm = Dob.getMonth() + 1;
        var dd = Dob.getDate();
        var yy = Dob.getFullYear();
        var hh = Dob.getHours();
        var mn = Dob.getMinutes();
        var ss = Dob.getSeconds();

        DateOfBirth =
          (mm > 9 ? mm : "0" + mm) +
          "/" +
          (dd > 9 ? dd : "0" + dd) +
          "/" +
          yy +
          " " +
          (hh > 9 ? hh : "0" + hh) +
          ":" +
          (mn > 9 ? mn : "0" + mn) +
          ":" +
          (ss > 9 ? ss : "0" + ss);
      }
      const passValues = {
        requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        billSearch: {
          billId: "",

          payerPartyId: "",
          payerAccountId: "",
          masterAccountReferenceNumber: "",
          accountReferenceNumber: values.accountNumber,
          status: "",
          uniqueIdentityId: values.socialSecurityNumber,
          isActive: [1],
          dateOfBirth: DateOfBirth,
          firstName: values.firstName,
          lastName: values.lastName,
          emailId: values.emailid,
          contactNo: values.contactno,
          pageNumber: 0,
          pageSize: 10,
          noofpages: "",
        },
      };

      // this.props.searchPatient(
      //   passValues,
      //   (res) => {
      //     if (res?.data.responseInfo?.status === "SUCCESSFUL") {
      //       let completeBills = [];
      //       res?.data?.billGroups?.map((ele, ind) => {
      //         let tempArr = ele?.bills;
      //         if (ind === 0) {
      //           completeBills = tempArr;
      //         } else {
      //           completeBills = [...completeBills, ...tempArr];
      //         }
      //       });
      //       this.setState({
      //         patientData: completeBills,
      //         noofrecords: res?.data?.totalRecords,
      //         totalRecords: res?.data?.totalRecords,
      //       });
      //       let temp = [];
      //       for (let i = 0; i <= res?.data?.totalRecords / 10; i++) {
      //         temp.push(i);
      //       }
      //       this.setState({
      //         showloader: false,
      //         noofpages: temp,
      //         totalpages: temp.length,
      //       });
      //     } else if (res?.data?.responseInfo?.status === "FAILED") {
      //       this.setState({
      //         showloader: false,
      //         patientData: "",
      //       });

      //       handleWarning(
      //         res?.data?.responseInfo.responseMessage[0]
      //           ? res?.data?.responseInfo?.responseMessage[0]
      //           : "Something went wrong!",
      //       );
      //     } else {
      //       this.setState({
      //         showloader: false,
      //         patientData: "",
      //       });

      //       handleError("Something went wrong!" + res?.data?.responseInfo?.responseMessage);
      //     }
      //   },
      //   (error) => {
      //     console.log(error);

      //     this.setState({
      //       showloader: false,
      //     });
      //   },
      // );
    } else {
      this.setState({
        showloader: false,
      });
      handleWarning("Please fill atleast one of the fields");
    }
  };
  changepage = (number) => {
    this.setState({
      showloader: true,
    });

    this.setState({
      pageno: number,
    });
    const passValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      billSearch: {
        billId: "",
        payerPartyId: "",
        payerAccountId: "",
        masterAccountReferenceNumber: "",
        accountReferenceNumber: this.state.accountNumber,
        status: "",
        uniqueIdentityId: this.state.socialSecurityNumber,
        isActive: [1],
        dateOfBirth: this.state.DateOfBirth,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        emailId: this.state.email,
        contactNo: this.state.phoneNumber,
        pageNumber: number,
        pageSize: 10,
      },
    };
    this.props.searchPatient(
      passValues,
      (res) => {
        if (res.data.responseInfo.status === "SUCCESSFUL") {
          let completeBills = [];
          res.data.billGroups.map((ele, ind) => {
            let tempArr = ele?.bills;
            if (ind === 0) {
              completeBills = tempArr;
            } else {
              completeBills = [...completeBills, ...tempArr];
            }
          });
          this.setState({
            patientData: completeBills,
            noofrecords: res.data.totalRecords,
            totalRecords: res.data.totalRecords,
            showpagination: true,
          });

          this.setState({
            showloader: false,
          });

          this.maskunmask();
        } else if (res.data.responseInfo.status === "FAILED") {
          this.setState({
            showloader: false,
          });

          handleWarning(res.data.responseInfo.responseMessage || "Something went wrong!");
        } else {
          this.setState({
            showloader: false,
          });

          handleError("Something went wrong!" + res.data.responseInfo.responseMessage);
        }
      },
      (error) => {
        console.log(error);

        this.setState({
          showloader: false,
        });
      },
    );
  };
  updateState = (newState) => {
    this.setState({ ...this.state, ...newState });
  };
  _patientValidation = Yup.object().shape({
    accountNumber: Yup.string()
      .matches(/^[0-9]+$/, "Enter digits only")
      .min(5, "Minimum 5 digits required for MED-1 Account Number")
      .max(8, "Enter 7 or 8 digits MED-1 Account Number"),
    socialSecurityNumber: Yup.string()
      .matches(/^[0-9]+$/, "Enter digits only")
      .min(9, "Enter 9 digits Social Security Number")
      .max(9, "Enter 9 digits Social Security Number"),
    firstName: Yup.string()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters ,dash(-), dot(.), tilled and apostrophes(') are allowed")
      .min(1, "Please enter at least 1 character")
      .max(20, "Max 20 characters allowed"),
    lastName: Yup.string()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters, dash(-), dot(.), tilled and apostrophes(') are allowed")
      .min(1, "Please enter at least 1 character")
      .max(20, "Max 20 characters allowed"),
    emailid: Yup.string().nullable().email("Please enter a valid Email Address"),
    clientName: Yup.string()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters ,dash(-), dot(.), tilled and apostrophes(') are allowed")
      .min(1, "Please enter at least 1 character")
      .max(20, "Max 20 characters allowed"),
    contactno: Yup.string()
      .nullable()
      .min(10, "Please enter 10 digits Phone Number")
      .max(10, "Please enter 10 digits Phone Number")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Please enter 10 digits Contact Number",
      ),
  });
  render() {
    const { classes } = this.props;

    const tableHeaderStyle = {
      textAlign: "inherit",
      color: "#000000",
      fontWeight: "500",
      padding: "15px",
      fontSize: "14px",
      fontFamily: "Poppins",
      border: "none",
    };

    const rowStyle = {
      height: "30px",
      fontSize: "14px",
    };
    const tableTitle = {
      padding: "10px 0",
      color: "#644ad2",
      fontSize: "18px",
      fontWeight: 500,
      alignItems: "center",
      justifyContent: "flex-start",
      display: "flex",
      backgroundColor: "inherit",
    };

    return (
      <>
        <div className="main-container-card">
          {this.state.showloader ? <SpinnerPage /> : ""}
          <Formik
            initialValues={{
              firstName: this.state.firstName ? this.state.firstName : "",
              lastName: this.state.lastName ? this.state.lastName : "",
              clientName: this.state.clientName ? this.state.clientName : "",
              accountNumber: this.state.med1AccNum ? this.state.med1AccNum : "",
              socialSecurityNumber: this.state.ssn ? this.state.ssn : "",
              emailid: this.state.email ? this.state.email : "",
              dateOfBirth: this.state.dateOfBirth,
              contactno: this.state.phoneNumber ? this.state.phoneNumber : "",
              totalDueAmount: this.state.totalDueAmount,
              DateOfService: this.state.DateOfService,
            }}
            onSubmit={this._handleSubmit}
            validationSchema={this._patientValidation}>
            {(props) => {
              const { values, touched, handleChange, errors, resetForm } = props;
              return (
                <>
                  <Form className="input-field" noValidate="noValidate">
                    <div role="heading" aria-level="1" className="heading-style mb-3">
                      <Icons type="patientSearch" classNames="icon-heading-styles" />
                      <h2 className="heading-style">Patient Search</h2>
                    </div>
                    <div className="form-row">
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 form-group">
                        <FormControl variant="outlined" className="w-100" fullWidth>
                          <div className="label-spacing">
                            <label htmlFor="accountNumber">MED-1 Account Number</label>
                            <ImageTooltip img={Med1AccNumberTooltip} width="450px" placement="right-start" alt="Med1 Bill">
                              <i className="fas fa-question-circle circle-icon" style={{ cursor: "pointer" }}></i>
                            </ImageTooltip>
                          </div>
                          <OutlinedInput
                            className={`${classes.labelName} ${classes.inputLabel} `}
                            name="accountNumber"
                            id="accountNumber"
                            placeholder="Please Enter MED-1 Account"
                            onChange={handleChange}
                            value={values["accountNumber"]}
                            aria-label="text-field"
                            fullWidth
                            error={Boolean(getIn(touched, "accountNumber") && getIn(errors, "accountNumber"))}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              maxLength: 8,
                            }}></OutlinedInput>
                        </FormControl>
                        <FormHelperText error>
                          {getIn(touched, "accountNumber") &&
                            getIn(errors, "accountNumber") &&
                            getIn(errors, "accountNumber")}
                        </FormHelperText>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 form-group">
                        <FormControl variant="outlined" className="w-100" fullWidth>
                          <div className="d-flex">
                            <label htmlFor="socialSecurityNumber">Social Security Number</label>
                          </div>
                          <OutlinedInput
                            className={`${classes.labelName} ${classes.inputLabel} `}
                            id="socialSecurityNumber"
                            name="socialSecurityNumber"
                            placeholder="Please Enter SSN"
                            onChange={handleChange}
                            value={values["socialSecurityNumber"]}
                            aria-label="text-field"
                            fullWidth
                            error={Boolean(getIn(touched, "socialSecurityNumber") && getIn(errors, "socialSecurityNumber"))}
                            InputLabelProps={{
                              shrink: true,
                            }}></OutlinedInput>
                        </FormControl>
                        <FormHelperText error>
                          {getIn(touched, "socialSecurityNumber") &&
                            getIn(errors, "socialSecurityNumber") &&
                            getIn(errors, "socialSecurityNumber")}
                        </FormHelperText>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 form-group">
                        <FormControl variant="outlined" className="w-100" fullWidth>
                          <label htmlFor="contactno">Phone Number</label>
                          <OutlinedInput
                            className={`${classes.labelName} ${classes.inputLabel} `}
                            id="contactno"
                            name="contactno"
                            onKeyPress={(event) => {
                              if (!/\d/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            placeholder="Please Enter Phone Number"
                            onChange={handleChange}
                            value={values["contactno"]}
                            aria-label="text-field"
                            fullWidth
                            error={Boolean(getIn(touched, "contactno") && getIn(errors, "contactno"))}
                            inputProps={{
                              maxLength: 10,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}></OutlinedInput>
                        </FormControl>
                        <FormHelperText error>
                          {getIn(touched, "contactno") && getIn(errors, "contactno") && getIn(errors, "contactno")}
                        </FormHelperText>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 form-group">
                        <FormControl variant="outlined" className="w-100" fullWidth>
                          <label htmlFor="firstName">First Name</label>
                          <OutlinedInput
                            className={`${classes.labelName} ${classes.inputLabel} `}
                            name="firstName"
                            id="firstName"
                            placeholder="Enter your First Name"
                            onChange={handleChange}
                            value={values["firstName"]}
                            fullWidth
                            error={Boolean(getIn(touched, "firstName") && getIn(errors, "firstName"))}
                            type="firstName"></OutlinedInput>
                        </FormControl>
                        <FormHelperText error name="securitycode">
                          {touched.firstName && errors.firstName ? <div>{errors.firstName}</div> : null}
                        </FormHelperText>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 form-group">
                        <FormControl variant="outlined" className="w-100" fullWidth>
                          <label htmlFor="lastName">Last Name</label>
                          <OutlinedInput
                            className={`${classes.labelName} ${classes.inputLabel} `}
                            id="lastName"
                            name="lastName"
                            placeholder="Enter your Last Name"
                            onChange={handleChange}
                            value={values["lastName"]}
                            fullWidth
                            error={Boolean(getIn(touched, "lastName") && getIn(errors, "lastName"))}
                            type="lastName"></OutlinedInput>
                        </FormControl>
                        <FormHelperText error name="securitycode">
                          {touched.lastName && errors.lastName ? <div>{errors.lastName}</div> : null}
                        </FormHelperText>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 form-group">
                        <FormControl variant="outlined" className="w-100" fullWidth>
                          <label htmlFor="emailid">Email Address</label>
                          <OutlinedInput
                            className={`${classes.labelName} ${classes.inputLabel} `}
                            id="emailid"
                            name="emailid"
                            type="email"
                            placeholder="Please Enter Email Address"
                            onChange={handleChange}
                            value={values["emailid"]}
                            aria-label="text-field"
                            fullWidth
                            error={Boolean(getIn(touched, "emailid") && getIn(errors, "emailid"))}
                            InputLabelProps={{
                              shrink: true,
                            }}></OutlinedInput>
                        </FormControl>
                        <FormHelperText component="p" variant="outlined" error="true">
                          {getIn(touched, "emailid") && getIn(errors, "emailid") && getIn(errors, "emailid")}
                        </FormHelperText>
                      </div>
                    </div>

                    <div className="payment-history-btns">
                      <button type="submit" className="primary-button themeing-buttons">
                        Search
                      </button>
                      <button
                        className="secondary-button mx-2"
                        type="button"
                        onClick={() => {
                          resetForm();
                          this.setState({
                            patientData: [],
                            searchData: null,
                          });
                        }}>
                        Reset
                      </button>
                    </div>
                  </Form>
                  <div className="payplan-table">
                    {this.state.searchData ? (
                      <PatientSearchTable
                        tableData={this.state.patientData}
                        verifyAndPayAccess={this.state.verifyAndPayAccess}
                        viewPatient={this.viewPatient}
                        isSubmit={this.state.isSubmit}
                        updateState={this.updateState}
                        searchData={this.state.searchData}
                      />
                    ) : null}
                  </div>
                </>
              );
            }}
          </Formik>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  // patientData: getPatientlList(state.patientDetailReducer),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchPatient: searchPatient,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails);
