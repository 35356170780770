import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getIn } from "formik";
import Med1Logo from "../../../assets/images/Med1Logo.png";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import echeckPaymentRoutingNumber from "../../../assets/images/echeckPaymentRoutingNumber.jpg";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormControlLabel,
  MenuItem,
  Select,
  FormLabel,
  Typography,
  IconButton,
  TextField,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";

import { Grid } from "@material-ui/core";
import ImageTooltip from "../../../components/ImageTooltip";
import CardPaymentTooltip from "../../../assets/images/CardPaymentTooltip.jpg";
import { cityData, countryData, allStatesData } from "../../../util/StatesData";
import RocketIcon from "../../../assets/images/quick-pay-rocket-icon.svg";
import { Link } from "react-router-dom";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  eCheck: {
    textAlign: "left !important",
  },
  inputfield: {
    textAlign: "left !important",
    fontSize: 14,
    color: "#536777",
    fontWeight: "normal",
    paddingTop: 10,
  },
}));

const options = [
  { key: "Master Card", value: "Master Card" },
  { key: "Visa", value: "Visa" },
];
const intialvalues = {
  firstName: "",
  middleName: "",
  lastName: "",
  routingNumber: "",
  accountNumber: "",
  reAccountNumber: "",
  email: "",
  phoneNumber: "",
  message: "",
  accountType: "none",
  country: "usa",
  StateCounty: "IN",
  city: "crystalLake",
  zipCode: "",
  agreeToterms: true,
  agreeToEmailUsage: true,
  futureDate: null,
};

const schema = Yup.object().shape({});

const QuickpayEcheck = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const payNow = history?.location?.state?.payNow;

  const [isVisible, setIsVisible] = useState({
    routingNumber: true,
    accountNumber: true,
    reAccountNumber: true,
  });

  const handleRoutingNumber = () => {
    setIsVisible({ ...isVisible, routingNumber: !isVisible.routingNumber });
  };

  const handleAccountNumber = () => {
    setIsVisible({ ...isVisible, accountNumber: !isVisible.accountNumber });
  };
  const handleReAccountNumber = () => {
    setIsVisible({ ...isVisible, reAccountNumber: !isVisible.reAccountNumber });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCancel = () => {
    history.push("/bill-details");
  };

  const onSubmit = async (values, onSubmitProps) => {
    history.push(payNow ? "successfull-payment" : "payment-schedule");
  };

  return (
    <div className="page-wrapper" role="main">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div>
            <div className="card-md">
              <div className="header" role="heading" aria-level="1">
                <div className="rocket-icon">
                  <img src={RocketIcon} alt="" />
                </div>
                <div className="logo">
                  <img src={Med1Logo} className="img-fluid" alt="Med-1 Solutions Logo" />
                </div>
              </div>

              <div className="credit-card-section">
                <h2 class="text-left mb-2"> eCheck Payment</h2>
                <div className="account-balance">
                  <p className="p-0">Total Outstanding Amount</p>
                  <h3>$ 2,200.00</h3>
                </div>
                <div className="account-balance">
                  <p>Amount To Pay</p>
                  <h3 className="pt-3">$ 50.00</h3>
                </div>
                <div className={classes.eCheck}>
                  <Formik initialValues={intialvalues} onSubmit={onSubmit} validationSchema={schema}>
                    {(props) => {
                      const { values, touched, error, handleChange, handleBlur, errors, setFieldValue } = props;
                      return (
                        <Form id="login-form" className="input-field" noValidate="noValidate">
                          <div className={classes.inputfield}>
                            <div className="row mt-2">
                              {!payNow && (
                                <>
                                  <div className="col-md-12 date-schedule">
                                    <div className="form-group input-field">
                                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Grid container className="date-picker">
                                          <label htmlFor="futurePaymentDate" className="form-label w-100 required">
                                            Future Payment Date
                                          </label>
                                          <DatePicker
                                            disableFuture
                                            name="futurePaymentDate"
                                            autoOk
                                            value={values.futurePaymentDate}
                                            onChange={(val) => setFieldValue("futurePaymentDate", val)}
                                            renderInput={(params) => (
                                              <TextField {...params} helperText={null} margin="dense" />
                                            )}
                                          />
                                        </Grid>
                                      </LocalizationProvider>
                                    </div>
                                  </div>
                                  <div className="col-md-6" />
                                </>
                              )}
                              <div className="form-group col-md-12 mt-3">
                                <label htmlFor="nameOnCard" className="required">
                                  Name on Card
                                </label>
                                <div className="row">
                                  <div className="col-12 col-sm-4 mb-3">
                                    <OutlinedInput
                                      className="form-control"
                                      id="nameOnCardFirstName"
                                      placeholder="Enter First Name"
                                      onChange={handleChange}
                                      value={values["nameOnCardFirstName"]}
                                      aria-label="text-field"
                                      type="text"
                                      error={Boolean(
                                        getIn(touched, "nameOnCardFirstName") && getIn(errors, "nameOnCardFirstName"),
                                      )}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      fullWidth
                                      required="true"></OutlinedInput>
                                  </div>
                                  <div className="col-12 col-sm-4 mb-3">
                                    <OutlinedInput
                                      className="form-control"
                                      id="nameOnCardMiddleName"
                                      placeholder="Enter Middle Name"
                                      onChange={handleChange}
                                      value={values["nameOnCardMiddleName"]}
                                      aria-label="text-field"
                                      type="text"
                                      error={Boolean(
                                        getIn(touched, "nameOnCardMiddleName") && getIn(errors, "nameOnCardMiddleName"),
                                      )}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      fullWidth
                                      required="true"></OutlinedInput>
                                  </div>
                                  <div className="col-12 col-sm-4 mb-3">
                                    <OutlinedInput
                                      className="form-control"
                                      id="nameOnCardLastName"
                                      placeholder="Enter Last Name"
                                      onChange={handleChange}
                                      value={values["nameOnCardLastName"]}
                                      aria-label="text-field"
                                      type="text"
                                      error={Boolean(
                                        getIn(touched, "nameOnCardLastName") && getIn(errors, "nameOnCardLastName"),
                                      )}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      fullWidth
                                      required="true"></OutlinedInput>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 mt-2">
                                <div className="form-group">
                                  <div className="row m-0 justify-content-between align-items-center">
                                    <FormLabel for="routingNumber" className="required">
                                      Routing Number
                                    </FormLabel>
                                    <ImageTooltip img={echeckPaymentRoutingNumber} width="450px" placement="right">
                                      <i className="fas fa-question-circle circle-icon" style={{ cursor: "pointer" }}></i>
                                    </ImageTooltip>
                                  </div>
                                  <OutlinedInput
                                    className="form-control signup-fields"
                                    name="routingNumber"
                                    value={values.routingNumber}
                                    onChange={handleChange}
                                    type={isVisible.routingNumber ? "text" : "password"}
                                    placeholder="Enter Routing Number"
                                    aria-label="text-field"
                                    fullWidth
                                    required
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleRoutingNumber}
                                          onMouseDown={handleMouseDownPassword}>
                                          {isVisible.routingNumber ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    }></OutlinedInput>
                                </div>
                              </div>
                              <div className="col-md-6 mt-2">
                                <div className="form-group">
                                  <FormControl variant="outlined" className="w-100" fullWidth>
                                    <FormLabel for="accountType" className="form-label required">
                                      Account Type
                                    </FormLabel>
                                    <Select
                                      fullWidth
                                      name="accountType"
                                      onChange={handleChange("accountType")}
                                      value={values.accountType}
                                      className="form-select form-control">
                                      <MenuItem value="none" disabled>
                                        Select Account Type
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>

                              <div className="col-md-6 mt-2">
                                <div className="form-group">
                                  <div className="row m-0 justify-content-between align-items-center">
                                    <FormLabel for="accountNumber" className="required">
                                      Account Number
                                    </FormLabel>

                                    <ImageTooltip img={echeckPaymentRoutingNumber} width="450px" placement="right">
                                      <i className="fas fa-question-circle circle-icon" style={{ cursor: "pointer" }}></i>
                                    </ImageTooltip>
                                  </div>
                                  <OutlinedInput
                                    className="form-control signup-fields"
                                    name="accountNumber"
                                    value={values.accountNumber}
                                    onChange={handleChange}
                                    placeholder="Enter Account Number"
                                    aria-label="text-field"
                                    fullWidth
                                    type={isVisible.accountNumber ? "text" : "password"}
                                    required
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleAccountNumber}
                                          onMouseDown={handleMouseDownPassword}>
                                          {isVisible.accountNumber ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    }></OutlinedInput>
                                </div>
                              </div>
                              <div className="col-md-6 mt-2">
                                <div className="form-group">
                                  <FormLabel for="reAccountNumber" className="required">
                                    Re-enter Account Number
                                  </FormLabel>
                                  <OutlinedInput
                                    className="form-control signup-fields"
                                    name="reAccountNumber"
                                    value={values.reAccountNumber}
                                    onChange={handleChange}
                                    type={isVisible.reAccountNumber ? "text" : "password"}
                                    placeholder="Enter Re-Account Number"
                                    aria-label="text-field"
                                    fullWidth
                                    required
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleReAccountNumber}
                                          onMouseDown={handleMouseDownPassword}>
                                          {isVisible.reAccountNumber ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    }></OutlinedInput>
                                </div>
                              </div>
                              <div className="col-md-6 mt-2">
                                <div className="form-group">
                                  <FormLabel for="emailAddress" className="required">
                                    Email Address
                                  </FormLabel>
                                  <OutlinedInput
                                    className="form-control"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    type="email"
                                    placeholder="Enter Email Address"
                                    aria-label="text-field"
                                    fullWidth
                                    required></OutlinedInput>
                                </div>
                              </div>
                              <div className="col-md-6 mt-2">
                                <div className="form-group">
                                  <FormLabel for="phoneNumber" className="required">
                                    Phone Number
                                  </FormLabel>
                                  <OutlinedInput
                                    className="form-control"
                                    name="phoneNumber"
                                    value={values.phoneNumber}
                                    onChange={handleChange}
                                    placeholder="Enter Phone Number"
                                    aria-label="text-field"
                                    fullWidth
                                    required></OutlinedInput>
                                </div>
                              </div>
                              <div className="form-group col-md-12 mt-2">
                                <label htmlFor="billingAddress" className="required">
                                  Billing Address
                                </label>
                                <OutlinedInput
                                  name="message"
                                  className="form-control"
                                  value={values.message}
                                  onChange={handleChange}
                                  placeholder="Enter Your Billing Address"
                                  aria-label="text-field"
                                  fullWidth
                                  required></OutlinedInput>
                              </div>
                              <div className="col-md-6 mt-2">
                                <div className="form-group">
                                  <FormControl variant="outlined" className="w-100" fullWidth>
                                    <FormLabel for="country" className="form-label required">
                                      Country
                                    </FormLabel>
                                    <Select
                                      fullWidth
                                      name="country"
                                      value={values.country}
                                      onChange={handleChange}
                                      className="form-select form-control">
                                      <MenuItem value="none" disabled>
                                        Select Country
                                      </MenuItem>
                                      {countryData.map((option) => {
                                        return (
                                          <MenuItem value={option.key} key={option.key}>
                                            {option.value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                              <div className="col-md-6 mt-2">
                                <div className="form-group">
                                  <FormControl variant="outlined" className="w-100" fullWidth>
                                    <FormLabel for="state" className="form-label required">
                                      State/County
                                    </FormLabel>
                                    <Select
                                      fullWidth
                                      name="StateCounty"
                                      value={values.StateCounty}
                                      onChange={handleChange}
                                      className="form-select form-control">
                                      <MenuItem value="none" key="none" disabled>
                                        Select State/County
                                      </MenuItem>
                                      {allStatesData.map((option) => {
                                        return (
                                          <MenuItem value={option.key} key={option.key}>
                                            {option.value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                              <div className="col-md-6 mt-2">
                                <div className="form-group">
                                  <FormControl variant="outlined" className="w-100" fullWidth>
                                    <FormLabel for="city" className="form-label required">
                                      City
                                    </FormLabel>
                                    <Select
                                      onChange={handleChange}
                                      fullWidth
                                      name="city"
                                      value={values.city}
                                      className="form-select form-control">
                                      <MenuItem value="none" key="none" disabled>
                                        Select City
                                      </MenuItem>
                                      {cityData.map((option) => {
                                        return (
                                          <MenuItem value={option.key} key={option.key}>
                                            {option.value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                              <div className="col-md-6 mt-2">
                                <div className="form-group">
                                  <FormLabel for="zipCode" className="required">
                                    ZIP Code
                                  </FormLabel>
                                  <OutlinedInput
                                    className="form-control"
                                    name="zipCode"
                                    value={values.zipCode}
                                    onBlur={handleBlur}
                                    onChange={(event) => {
                                      const zip = event.target.value;
                                      setFieldValue(
                                        (values.zipCode =
                                          zip.length > 5 && !zip.includes("-")
                                            ? zip.substring(0, 5).concat("-") + zip.substring(5, zip.length)
                                            : zip),
                                      );
                                    }}
                                    placeholder="Enter ZIP Code"
                                    aria-label="text-field"
                                    fullWidth
                                    required></OutlinedInput>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div id="conditions" className="agree-terms check-label mt-2">
                            <FormControl fullWidth component="fieldset" margin="normal" className="mt-5">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={handleChange}
                                    name="agreeToterms"
                                    checked={values.agreeToterms}
                                    color="primary"
                                  />
                                }
                                className={classes.checkBoxLabel}
                                label={
                                  <span>
                                    By checking this box, you signify you have reviewed understand meet and agree to the
                                    <a href="/"> Online Payment Terms and Conditions</a>
                                  </span>
                                }
                              />
                            </FormControl>

                            <FormControl fullWidth component="fieldset" margin="normal">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={handleChange}
                                    name="agreeToEmailUsage"
                                    checked={values.agreeToEmailUsage}
                                    color="primary"
                                  />
                                }
                                label=" By checking this box, you agree that this is your personal email address and you
                                authorize us to send you emails, call your phone, or contact you via an automated dialer,
                                leave voicemails, and/or send text messages, if necessary."
                              />
                            </FormControl>
                          </div>

                          <div className="proceed-cancel-buttons">
                            <button onClick={handleCancel} className="secondary-button">
                              Cancel
                            </button>

                            <button className="primary-button">{payNow ? "Proceed to Pay" : "Schedule Payment"}</button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
    </div>
  );
};

export default QuickpayEcheck;
