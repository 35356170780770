import { changePasswordService } from "../core_services/changePasswordService";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWOR_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWOR_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWOR_FAILURE";

export function changePasswordPending() {
  return {
    type: CHANGE_PASSWORD_REQUEST,
  };
}
export function changePassword(data, fn) {
  return (dispatch) => {
    dispatch(changePasswordPending());
    changePasswordService.changePassword(data, (res) => {
      dispatch(changePasswordSucess(JSON.stringify(res)));
      fn(res);
    });
  };
}

export function changePasswordSucess(data) {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: data,
  };
}

export function changePasswordError(error) {
  return {
    type: CHANGE_PASSWORD_FAILURE,
    error: error,
  };
}
