import React from "react";
import PP_Success_Receipt from "./PP_Success_Receipt";
import PP_QuickPayStepper from "../../../../Common/PP_QuickPayStepper";
import Stepper from "../Stepper/CreditCardStepper";

class PP_Transaction_Successful extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // _routeToViewReceipt = () => {
  //     this.props.history.push('/View-Receipt');
  // }

  _handleSubmit = (values, { actions }) => {};

  render() {
    return (
      <React.Fragment>
        <div className="payment-successful-container">
          <div className="col-md-12 col-lg-9 col-xl-8 mx-auto px-lg-0 success-width payment-receipt-content-cp mb-3">
            <div className="blue-bg"></div>

            <div className="white-bg">
              <Stepper />
              <div>
                <div className="payment-content-cp">
                  {/* <a onClick={this._routeToViewReceipt} type="" > */}
                  <PP_Success_Receipt {...this.props} />
                  {/* </a> */}
                </div>
                {/* <div class="col-md-6 col-sm-12 ">
                            <PP_Register_link {...this.props} />
                        </div> */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PP_Transaction_Successful;
