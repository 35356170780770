import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
// import { createLogger } from "redux-logger";

// import loginReducer from "../reducer/loginReducer";

// import securityImageReducer from "../reducer/securityImageReducer";
// import transactionDetailReducer from "../reducer/transactionDetailReducer";
// import whiteLableReducer from "../reducer/whiteLableReducer";
// import changePasswordReducer from "../reducer/changePasswordReducer";

// import identifyPayerReducer from "../reducer/pp_identifyPayerReducer";
import paymentCreditCardReducer from "../reducer/pp_PaymentCreditCardReducer";
// import payerOutstandingReducer from "../reducer/pp_payerOutstandingReducer";
// import transactionSuccessReducer from "../reducer/pp_transactionSuccessReducer";
// import eChequePaymentReducer from "../reducer/pp_eChequePaymentReducer";
// import contactusReducer from "../reducer/pp_contactusReducer";
// import paymentGatewayProfileReducer from "../reducer/admin_reducer/admin_paymentGatewayProfileReducer";
// import whiteLabelReducer from "../reducer/admin_reducer/admin_whiteLabelReducer";
// import contentLabelReducer from "../reducer/admin_reducer/admin_contentLabelReducer";
// import patientDetailReducer from "../reducer/Admin_patientDataReducer";
// import userDetailReducer from "../reducer/Admin_userDataReducer";
// const loggerMiddleware = createLogger();
export default createStore(
  combineReducers({
    //   loginReducer,

    //   securityImageReducer,
    //   transactionDetailReducer,
    //   whiteLableReducer,
    //   changePasswordReducer,
    //   identifyPayerReducer,
    paymentCreditCardReducer,
    //     payerOutstandingReducer,
    //     transactionSuccessReducer,
    //     eChequePaymentReducer,
    //     contactusReducer,
    //     paymentGatewayProfileReducer,
    //     whiteLabelReducer,
    //   contentLabelReducer,
    //   patientDetailReducer,
    //   userDetailReducer
  }),
  applyMiddleware(thunkMiddleware),
);
