import React, { Component } from "react";
import { bindActionCreators } from "redux";
import HomeBilling from "./HomeBillling";
import { connect } from "react-redux";
import { getOutstandingData, getOutstandingDataPending } from "../../../reducer/pp_payerOutstandingReducer";
import { fetchpayerOutstandingData, addpayerOutstandingData } from "../../../action/pp_PayerOutstandingAction";

const mapStateToProps = (state) => ({
  getOutstandingData: getOutstandingData(state.payerOutstandingReducer),
  getOutstandingDataPending: getOutstandingDataPending(state.payerOutstandingReducer),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchpayerOutstandingData: fetchpayerOutstandingData,
      addpayerOutstandingData: addpayerOutstandingData,
    },
    dispatch,
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const Admin_PayerOutstandingContainer = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        billinginformation: "",
      };
    }

    componentDidMount() {
      this.setState({
        newbillingdata: this.props.newbillingdata,
        billinginformation: this.props.billinginformation,
        billinginfosummary: this.props.billinginfosummary,
        SocialSecurityNumber: this.props.SocialSecurityNumber,
        partyId: this.props.partyId,
      });
    }

    render() {
      return (
        <React.Fragment>
          <div id="slidepagearea" className="contentarea">
            <HomeBilling
              partyId={this.state.partyId}
              SocialSecurityNumber={this.state.SocialSecurityNumber}
              newbillingdata={this.state.newbillingdata}
              billinginformation={this.state.billinginformation}
              billinginfosummary={this.state.billinginfosummary}
              {...this.props}
            />
          </div>
        </React.Fragment>
      );
    }
  },
);

export default Admin_PayerOutstandingContainer;
