import { Global_var } from "../global/global_var";
import RestDataSource from "./restdatasource";
import { loginService } from "./loginService";
// import { checkUserSession } from "../common/userSessionExpiry";

export const purlService = {
  createPortalUsage,
  updatePortalUsage,
  createCampaignView,
  paymentPlanSetup,
  paymentComplete,
  paymentCompleteMain,
};

function createPortalUsage(userData, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_CREATE_PORTAL_USAGE;
      return new RestDataSource(url, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}

function updatePortalUsage(userData, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_UPDATE_PORTAL_USAGE;
      return new RestDataSource(url, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}

function createCampaignView(userData, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_CREATE_CAMPAIN_VIEW;
      return new RestDataSource(url, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}

function paymentComplete(userData, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_UPDATE_CAMPAIGN_VIEW_PAYMENT_COMPLETE;
      return new RestDataSource(url, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}

function paymentPlanSetup(userData, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_UPDATE_CAMPAIGN_VIEW_PAYMENT_PLAN;
      return new RestDataSource(url, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}

function paymentCompleteMain(userData, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_UPDATE_PAYMENT_MAIN;
      return new RestDataSource(url, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}
