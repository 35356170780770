import react from "react";
import { Tooltip, withStyles } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

const DisplayDataTooltip = ({ children, borderColor, icon, placement = "right" }) => {

  const LightTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: "#fff",
      border: `1px solid ${borderColor}`,
    },
  }))(Tooltip);
  return (
    <>
      <LightTooltip title={children} placement={placement}>
        {icon}
      </LightTooltip>
    </>
  );
};

export default DisplayDataTooltip;
