import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { getIn } from "formik";
import { FormHelperText } from "@material-ui/core";
import { Global_var } from "../../global/global_var";

const recaptchaRef = React.createRef();
const Captcha = ({ setFieldValue, errors, touched }) => {
  return (
    <div style={{ transform: "scale(0.93)" }}>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={Global_var.SITEKEY}
        onChange={(response) => {
          
          setFieldValue("recaptcha", response);
          setFieldValue("isCaptchaSelected", true);
        }}
        role="document"
      />
      <FormHelperText component="h5" variant="outlined" error="true">
        {getIn(touched, "recaptcha") && getIn(errors, "recaptcha") && getIn(errors, "recaptcha")}
      </FormHelperText>
    </div>
  );
};
export default Captcha;
