import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@material-ui/core/DialogActions";
import { Button, IconButton } from "@material-ui/core";

function ConfirmationDialog({
  maxWidth,
  fullWidth,
  open,
  title,
  removeCloseIcon,
  noActionButtons,
  noButtons,
  handleSubmit,
  handleClose,
  children,
  scroll,
}) {
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={maxWidth}
        open={open}
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle className="popupstyles">
          <div className="dialog-header">
            <Typography>{title}</Typography>
            {!removeCloseIcon && (
              <IconButton>
                <CloseIcon onClick={handleClose} />
              </IconButton>
            )}
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="modal-body">
            {children}

            {!noActionButtons && !noButtons && (
              <DialogActions className="mt-2">
                <Button
                  onClick={handleSubmit}
                  type="button"
                  variant="contained"
                  className="themeing-buttons mr-1"
                  color="primary">
                  Yes
                </Button>
                <Button onClick={handleClose} color="primary" variant="outlined" className="secondary-button">
                  No
                </Button>
              </DialogActions>
            )}
            {noButtons && (
              <DialogActions className="mt-2">
                <Button onClick={() => handleClose()} color="primary" variant="outlined" className="secondary-button ml-1">
                  Ok
                </Button>
              </DialogActions>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ConfirmationDialog;
