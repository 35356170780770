import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import flagone from "src/assets/flag-1.png";
import { connect } from "react-redux";

import { bindActionCreators, compose } from "redux";
import { error, success, errorNotification, successNotification } from "../Admin_Notification/Admin_Notification";
import { getLogin } from "../../reducer/loginReducer";
import { logoutUser } from "../../action/loginAction";
import { Translation } from "react-i18next";

const mapStateToProps = (state) => ({
  ...getLogin(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutUser: logoutUser,
    },
    dispatch,
  );

class LandingPage_Header extends Component {
  constructor(props) {
    super(props);

    this._handleLogOut = this._handleLogOut.bind(this);
    this.state = {
      emailid: "John@gmail.com",
      name: "hh",
      intialsname: "hh",
    };
  }
  componentDidMount() {
    if (sessionStorage.getItem("loginDetails") !== null) {
      var userinfo = JSON.parse(sessionStorage.getItem("loginDetails"));
      this.setState({
        emailid: userinfo.emailId,
        name: userinfo.userName,
        intialsname: userinfo.name,
      });
    }
    if (this.props.user.isUserLoggedIn === false) {
      this.props.history.push("/");
    }
  }
  changePassword = () => {
    this.props.history.push("/Change-Password");
  };
  _handleLogOut() {
    debugger;
    this.props.logoutUser(
      {
        userName: this.props.user.user.userName,
      },
      (userData) => {
        if (userData.status === "success") {
          sessionStorage.clear();
          success("Logout Successfully ", successNotification);
          this.props.history.push("/Admin-Login");
        }
        if (userData.reasonCode === "SM-10") {
          sessionStorage.clear();

          error(<Translation>{(t, { i18n }) => <span>{t("session_expired")}</span>}</Translation>, errorNotification);
          this.props.history.push("/Admin-Login");
        }
      },
    );
  }
  handleDropdownChange = (e) => {
    debugger;
    this.setState({ selectValue: e.target.value });
  };
  render() {
    return (
      <React.Fragment>
        <>sss</>
      </React.Fragment>
    );
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(LandingPage_Header);
