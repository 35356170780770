import React, { useEffect, useState } from "react";
import { FormControl, TextField } from "@material-ui/core";
import { I18nextProvider } from "react-i18next";
import i18n from "../../../../i18n";
import { ErrorMessage, Form, Formik, getIn } from "formik";
import PieChartIcon from "@mui/icons-material/PieChart";
import * as Yup from "yup";
import { tableIcons } from "../../../../components/TableIcons";
import MaterialTable from "material-table";
import SpinnerPage from "../../../../Common/PP_loader";
import { Autocomplete } from "@material-ui/lab";
import { campaignTrackingService } from "../../../../services/campaignTrackingService";
import { handleError } from "../../../../components/Notifications/Toast";
import ReactApexChart from "react-apexcharts";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

export const options = {
  responsive: true,
  maintainAspectRatio: false,
};

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context, chartData, currentChart) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const [[currentLabel]] = tooltip.body.map((b) => b.lines);
    let currentLabelData, bodyLines;
    if (currentChart === "account") {
      if (currentLabel.split(":")[0] === "Not Paid") {
        bodyLines = [`Not Paid Accounts: ${chartData?.noOfTotalAccounts - chartData?.noOfPaidOrPpAccounts}`];
      } else {
        currentLabelData =
          chartData.performances && chartData.performances.filter((el) => currentLabel.split(":")[0] === el.period)[0];

        bodyLines = [
          currentLabelData.period,
          `No of Paid Accounts: ${currentLabelData.noOfPaidAccounts}`,
          `No of Pay Plan Accounts: ${currentLabelData.noOfPayPlanAccounts}`,
        ];
      }
    } else {
      if (currentLabel.split(":")[0] === "Not Paid") {
        bodyLines = [`Not Paid Amount: $${formatAmount(chartData.notPaidAmount)}`];
      } else {
        currentLabelData =
          chartData.performances && chartData.performances.filter((el) => currentLabel.split(":")[0] === el.period)[0];

        bodyLines = [
          currentLabelData.period,
          `Paid Amount: $${formatAmount(currentLabelData.paidAmount)}`,
          `Scheduled Amount: $${formatAmount(currentLabelData.scheduledAmount)}`,
          `Total Amount: $${formatAmount(currentLabelData.totalAmount)}`,
        ];
      }
    }

    // "noOfPaidAccounts": 0,
    // "noOfPayPlanAccounts": 0,

    const tableHead = document.createElement("thead");

    titleLines.forEach((title) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = 0;

      const th = document.createElement("th");
      th.style.borderWidth = 0;
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    bodyLines &&
      bodyLines.forEach((body, i) => {
        const colors = tooltip.labelColors[0];

        const span = document.createElement("span");
        span.style.background = colors.backgroundColor;
        span.style.borderColor = colors.borderColor;
        span.style.borderWidth = "2px";
        span.style.marginRight = "10px";
        span.style.height = "10px";
        span.style.width = "10px";
        span.style.display = "inline-block";

        const tr = document.createElement("tr");
        tr.style.backgroundColor = "inherit";
        tr.style.borderWidth = 0;
        // tr.style.fontSize = "6px";

        const td = document.createElement("td");
        td.style.borderWidth = 0;
        // td.style.fontSize = 6;

        // const text = document.createTextNode(body);
        if (i === 0) {
          td.appendChild(span);
        }
        td.innerHTML += `<span style='font-size: 13px;'>${body}</span>`;
        tr.appendChild(td);
        // td.appendChild(text);
        tableBody.appendChild(tr);
      });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + "px " + tooltip.options.padding + "px";
};

export const formatAmount = (value) => {
  return value
    ? Number(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      })
    : "";
};

const PaymentsReceivedReport = () => {
  const [paymentsReceivedData, setPaymentsReceivedData] = useState();
  const [campaignList, setCampaignList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    campaignTrackingService.getCampaignList(
      (res) => {
        setCampaignList(res.data?.campaignList);
      },
      (err) => {
        if (err) {
          handleError("Something went wrong!");
        }
      },
    );
  }, []);
  const validationSchema = Yup.object().shape({
    campaign: Yup.string().nullable().required("Campaign is required"),
  });

  const handleSubmit = (values) => {
    setIsLoading(true);
    setPaymentsReceivedData(null);
    setPaymentsReceivedData(null);
    const payload = {
      campaignName: values.campaign,
    };
    campaignTrackingService.getPaymentsReceivedData(
      payload,
      (res) => {
        setIsLoading(false);
        if (res.data?.performances && res.data?.performances?.length !== 0) {
          setPaymentsReceivedData(res.data);
        } else {
          setPaymentsReceivedData(null);
          handleError("Something went wrong!");
        }
      },
      (err) => {
        if (err) {
          handleError(err.message);
          setIsLoading(false);
        }
      },
    );
  };

  const getChartData = (array, label) => {
    const arr = [...array];
    if (label === "account") {
      return [...arr.map((data) => data.noOfPaidAccounts), paymentsReceivedData.notPaidAccounts];
    } else {
      return [...arr.map((data) => data.totalAmount), paymentsReceivedData.notPaidAmount];
    }
  };

  const getDataLabels = (array, label) => {
    const arr = [...array];
    if (label === "account") {
      return [
        ...arr.map((data) => {
          return `${data.period}: ${data.noOfPaidAccounts}`;
        }),
        `Not Paid: ${paymentsReceivedData.notPaidAccounts}`,
      ];
    } else {
      return [
        ...arr.map((data) => {
          return `${data.period}: ${data.totalAmountPercentage}%`;
        }),
        `Not Paid: ${paymentsReceivedData.notPaidAmountPercentage}%`,
      ];
    }
  };

  const pieData1 = {
    options: {
      // colors: ["#4CBB17", "#6BDC35", "#80E550", "#90EA65", "#A3EE7F", "#FF3131"],
      colors: ["#2E8538", "#3AAE49", "#58BE66", "#7BCC87", "#A1DBA9", "#FC1111"],
      labels:
        paymentsReceivedData && paymentsReceivedData?.performances
          ? getDataLabels(paymentsReceivedData["performances"], "account")
          : [],
      // onHover: (event, chartElement) => {
      //   console.log(event,chartElement,"chartElement")
      //     const target = event.native ? event.native.target : event.target;
      //     target.style.color = chartElement[0] ? "pointer" : "default";
      //   },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          if (w.config.labels[seriesIndex].split(":")[0] === "Not Paid") {
            return `
              <div class="p-3">
              <div class="d-flex flex-row align-items-center">
                <p style="background:${w.config.colors[seriesIndex]}
                ; height: 10px; width: 10px">
                </p>
                <p class="ml-2">
                Not Paid Accounts: 
                ${paymentsReceivedData.notPaidAccounts}
                </p> 
                </div> 
                </div>
              `;
          } else {
            return `<div class="p-3 d-flex flex-column justify-content-center">
              <div class="d-flex flex-row align-items-center">
              <p style="background:${w.config.colors[seriesIndex]}; height: 10px; width: 10px">
              </p>
              <p class="ml-2">
              ${w.config.labels[seriesIndex].split(":")[0]}
              </p></div><span>No. of Paid Accounts: ${paymentsReceivedData.performances[seriesIndex].noOfPaidAccounts}
              </span>
              <span>
              No. of Pay Plan Accounts: ${paymentsReceivedData.performances[seriesIndex].noOfPayPlanAccounts}
              </span>
              <span>Total Paid or Pay Plan Accounts: ${paymentsReceivedData.performances[seriesIndex].noOfPaidOrPPAdded}
              </span></></div>
              `;
          }
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "left",
        markers: {
          width: 15,
          height: 10,
          // strokeWidth: 0,
          // strokeColor: '#fff',
          // fillColors: undefined,
          radius: 0,
        },
      },
      plotOptions: {
        pie: {
          size: "18%",
        },
      },
      chart: {
        type: "pie",
        height: "100%",
        width: "100%",
      },
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
            legend: {
              show: true,
            },
          },
        },
      ],
    },
    series:
      paymentsReceivedData && paymentsReceivedData?.performances
        ? getChartData(paymentsReceivedData["performances"], "account")
        : [],
  };
  const pieData2 = {
    options: {
      // colors: ["#1D8348", "#239B56", "#28B463", "#2ECC71", "#58D68D", "#E74C3C"],
      colors: ["#2E8538", "#3AAE49", "#58BE66", "#7BCC87", "#A1DBA9", "#FC1111"],
      labels:
        paymentsReceivedData && paymentsReceivedData?.performances
          ? getDataLabels(paymentsReceivedData["performances"], "amount")
          : [],
      // onHover: (event, chartElement) => {
      //   console.log(event,chartElement,"chartElement")
      //     const target = event.native ? event.native.target : event.target;
      //     target.style.color = chartElement[0] ? "pointer" : "default";
      //   },

      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function (val, opts) {
          const value = opts.w.globals.labels[opts.seriesIndex];
          return value.split(":")[1];
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          if (w.config.labels[seriesIndex].split(":")[0] === "Not Paid") {
            return `
              <div class="p-3">
              <div class="d-flex flex-row align-items-center">
              <p style="background:${w.config.colors[seriesIndex]}
              ; height: 10px; width: 10px"></p>
              <p class="ml-2">Not Paid Amount: $${formatAmount(paymentsReceivedData.notPaidAmount)}
              </p>
              </div>
              </div>
              `;
          } else {
            return `
              <div class="p-3 d-flex flex-column justify-content-center">
              <div class="d-flex flex-row align-items-center"><p style="background:${w.config.colors[seriesIndex]}
              ; height: 10px; width: 10px"></p><p class="ml-2">${w.config.labels[seriesIndex].split(":")[0]}
              </p></div><span>Paid Amount: $${formatAmount(paymentsReceivedData.performances[seriesIndex].paidAmount)}
              </span><span>Scheduled Amount: $${formatAmount(paymentsReceivedData.performances[seriesIndex].scheduledAmount)}
              </span></><span>Total Amount: $${formatAmount(paymentsReceivedData.performances[seriesIndex].totalAmount)}
              </span></></div>
              `;
          }
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "left",
        markers: {
          width: 15,
          height: 10,
          // strokeWidth: 0,
          // strokeColor: '#fff',
          // fillColors: undefined,
          radius: 0,
        },
      },
      plotOptions: {
        pie: {
          size: "18%",
        },
      },
      chart: {
        type: "pie",
        height: "100%",
        width: "100%",
      },
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
            legend: {
              show: true,
            },
          },
        },
      ],
    },
    series:
      paymentsReceivedData && paymentsReceivedData?.performances
        ? getChartData(paymentsReceivedData["performances"], "amount").map(Number)
        : [],
  };

  const tableColumns = [
    {
      field: "period",
      title: "Period",
      render: (rowData) => {
        const value = rowData["period"];
        if (value === "Total") {
          return <b>Total</b>;
        } else {
          return value;
        }
      },
    },

    {
      field: "noOfPaidAccounts",
      title: "No of Paid Accounts",
      render: (rowData, tableMeta) => {
        const value = rowData["noOfPaidAccounts"];
        const tableMetaData = paymentsReceivedData["performances"];

        if (value === "Total") {
          const total = tableMetaData.map((row) => row["noOfPaidAccounts"]);
          total.pop();
          return (
            <div
              style={{
                fontWeight: "bold",
                // width: "38%",
                // textAlign: "right",
              }}>
              {total.reduce((total, num) => total + num, 0)}
            </div>
          );
        } else {
          return (
            <div
              style={
                {
                  // width: "38%",
                  // textAlign: "right",
                }
              }>
              {value}
            </div>
          );
        }
      },
    },

    {
      field: "noOfPayPlanAccounts",
      title: "No of Pay Plan Accounts",
      render: (rowData) => {
        const value = rowData["noOfPayPlanAccounts"];
        const tableMetaData = paymentsReceivedData["performances"];

        if (value === "Total") {
          const total = tableMetaData.map((row) => row["noOfPayPlanAccounts"]);
          total.pop();
          return (
            <div
              style={{
                fontWeight: "bold",
                // width: "38%",
                // textAlign: "right",
              }}>
              {total.reduce((total, num) => total + num, 0)}
            </div>
          );
        } else {
          return (
            <div
              style={
                {
                  // width: "38%",
                  // textAlign: "right",
                }
              }>
              {value}
            </div>
          );
        }
      },
    },
    {
      field: "payPlanAddedAmount",
      title: "Pay Plan Amount",
      type: "numeric",
      hidden: true,
    },
    {
      field: "paidAmount",
      title: "Paid Amount",
      type: "numeric",
      render: (rowData) => {
        const value = rowData["paidAmount"];
        const tableMetaData = paymentsReceivedData["performances"];
        if (value === "Total") {
          const total = tableMetaData.map((row) => row["paidAmount"]);
          total.pop();

          return (
            <div
              style={{
                fontWeight: "bold",
                // width: "39%",
                // textAlign: "right",
              }}>
              $ {formatAmount(paymentsReceivedData.paidAmount)}
            </div>
          );
        } else {
          const totalValue = rowData["paidAmount"];
          return (
            <div
              style={
                {
                  // width: "39%",
                  // textAlign: "right",
                }
              }>
              $ {totalValue ? formatAmount(totalValue) : "0.00"}
            </div>
          );
        }
      },
    },
    {
      field: "scheduledAmount",
      title: "Scheduled Amount",
      type: "numeric",
      render: (rowData) => {
        const value = rowData["scheduledAmount"];
        const tableMetaData = paymentsReceivedData["performances"];
        if (value === "Total") {
          const total = tableMetaData.map((row) => row["scheduledAmount"]);
          total.pop();
          // total.pop();

          return (
            <div
              style={{
                fontWeight: "bold",
                // width: "39%",
                // textAlign: "right",
              }}>
              $ {formatAmount(paymentsReceivedData.scheduledAmount)}
            </div>
          );
        } else {
          const totalValue = rowData["scheduledAmount"];
          return (
            <div
              style={
                {
                  // width: "39%",
                  // textAlign: "right",
                }
              }>
              $ {totalValue ? formatAmount(totalValue) : "0.00"}
            </div>
          );
        }
      },
    },
  ];

  const getTableData = (data) => {
    // data.pop();
    // data.pop();
    let arr = [
      ...data,
      {
        period: "Total",
        noOfPaidAccounts: "Total",
        paidAmount: "Total",
        noOfPayPlanAccounts: "Total",
        scheduledAmount: "Total",
      },
    ];
    return arr;
  };
  const updateDataset = (e, chart, index) => {
    const myChart = chart.legend.chart;
    const currentEl = e.target.parentNode;
    const meta = myChart.getDatasetMeta(0);
    const labelEl = currentEl.querySelector(".legend-list-text");
    const result = meta.data[index].hidden === true ? false : true;
    if (result === true) {
      meta.data[index].hidden = true;
      labelEl.style.textDecoration = "line-through";
    } else {
      labelEl.style.textDecoration = "none";
      meta.data[index].hidden = false;
    }
    myChart.update();
  };

  const htmlLegendPluginAmount = {
    id: "htmlLegendAmount",
    afterUpdate(chart) {
      const items = chart.options.plugins.legend.labels.generateLabels(chart);
      const ul = document.createElement("div");
      ul.classList.add("legend-list");
      items.forEach((item, index) => {
        const li = document.createElement("div");
        li.style.cursor = "pointer";
        li.classList.add("legend-label");
        // li.classList.add("col-md-6");
        // const boxSpan = document.createElement("span");
        // boxSpan.style.background = item.fillStyle;
        // li.appendChild(boxSpan);
        // li.appendChild(document.createTextNode(item.text));
        li.innerHTML = `<div class="legend-label-color-box" style="background-color:
        ${item.fillStyle}"></div><span class="legend-list-text">${item.text}</span>`;
        li.addEventListener("click", (e) => {
          // updateData set(e, chart, index);
        });
        ul.appendChild(li);
      });
      const jsLegend = document.getElementById("js-legend-amount");
      if (!jsLegend.innerHTML) {
        jsLegend.appendChild(ul);
      }
    },
  };
  const initialValues = { campaign: "" };
  return (
    <I18nextProvider i18n={i18n}>
      <React.Fragment>
        {isLoading && <SpinnerPage />}
        <div className="transaction-card" role="main">
          <div className="main-container-card">
            <div role="heading" aria-level="1" className="heading-style mb-3">
              <MonetizationOnIcon className="mr-2" />
              <h2 className="heading-style mb-0"> Campaign Tracking: Payments Received/Scheduled</h2>
            </div>
            <Formik
              // enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}>
              {({ touched, errors, setFieldValue, values, resetForm, setTouched, setErrors }) => (
                <Form className="input-field" noValidate="noValidate">
                  <div className="form-row">
                    <div className="col-xl-4 col-lg-4 col-sm-6 col-md-6 form-group">
                      <FormControl component="fieldset" variant="outlined" className="w-100" fullWidth>
                        <>
                          <label className="form-label required" htmlFor="campaign">
                            Campaign
                          </label>
                          <Autocomplete
                            id="campaign"
                            className="form-autocomplete"
                            options={campaignList && campaignList.length > 0 ? campaignList : []}
                            size="small"
                            autoHighlight
                            value={values.campaign}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                fullWidth
                                name="campaign"
                                placeholder="Select Campaign"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "off", // disable autocomplete and autofill
                                }}
                              />
                            )}
                            onChange={(e, value) => {
                              setFieldValue("campaign", value);
                            }}
                          />
                        </>
                      </FormControl>
                      <ErrorMessage component="div" name="campaign" className="error-text" />
                    </div>
                  </div>
                  <div className="payment-history-btns">
                    <button type="submit" className="primary-button themeing-buttons mr-2">
                      Search
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        resetForm();
                        setPaymentsReceivedData(null);
                      }}
                      className="secondary-button">
                      Reset
                    </button>
                  </div>
                  {paymentsReceivedData && Object.values(paymentsReceivedData).length > 0 && (
                    <div className="payment-recieved-container mt-3">
                      <div className="purl-detail-box mt-4">
                        <div className="ml-2 purl-detail-box-1 purl-amount">
                          <div className="payments-received-header-item ">
                            Total Accounts: <b>{paymentsReceivedData?.noOfTotalAccounts}</b>
                          </div>
                          <div className="payments-received-header-item ">
                            No of Paid & Pay Plan Accounts:
                            <b>{paymentsReceivedData?.noOfPaidOrPpAccounts}</b>
                          </div>
                        </div>
                        <div className=" purl-detail-box-1 purl-detail-box-2">
                          <div className="payments-received-header-item">
                            Outstanding amount: <b> ${formatAmount(paymentsReceivedData?.outstandingAmount)}</b>
                          </div>
                          <div className="payments-received-header-item">
                            Collected amount: <b>${formatAmount(paymentsReceivedData?.totalPaidAmount)}</b>
                          </div>
                        </div>
                      </div>
                      <div className="card p-2 purl-achievement">
                        <div className="d-flex justify-content-center ">
                          <div className=" d-flex justify-content-center align-items-center">
                            {/* <StarBorderIcon className="star-icon"/> */}
                            <span className="star-icon">
                              <i class="fa fa-trophy" aria-hidden="true"></i>
                            </span>
                            <span> Campaign Success Rate </span>
                            <span>: </span>
                            <span className="ml-2">
                              {" "}
                              <b>{paymentsReceivedData?.camapaignSuccessRate}%</b>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row purl-box">
                        <div className="col-md-6">
                          {/* <div className="purl-pie-1">
                            <Pie
                              data={accountsData}
                              plugins={[htmlUserDataLegendPlugin("payment-received-account-legend", false)]}
                              // redraw={true}
                              options={{ ...options, ...accountOptions }}
                            />
                          </div>
                          <div id="payment-received-account-legend"></div> */}
                          <div className="purl-pie">
                            <ReactApexChart
                              options={pieData1.options}
                              series={pieData1.series}
                              type="pie"
                              width="100%"
                              height="100%"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          {/* <div className="purl-pie">
                            <Pie
                              data={amountData}
                              plugins={[htmlUserDataLegendPlugin("payment-received-amount-legend", false)]}
                              // redraw={true}
                              options={{ ...options, ...amountOptions }}
                            />
                          </div>
                          <div id="payment-received-amount-legend"></div> */}
                          <div className="purl-pie purl-pie-amount">
                            <ReactApexChart
                              options={pieData2.options}
                              series={pieData2.series}
                              type="pie"
                              width="100%"
                              height="100%"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="payplan-table paymentpurl-table mt-3">
                        <MaterialTable
                          title=""
                          localization={{
                            pagination: {
                              firstAriaLabel: "First Page",
                              previousAriaLabel: "Previous Page",
                              nextAriaLabel: "Next Page",
                              lastAriaLabel: "Last Page",
                            },
                          }}
                          columns={tableColumns}
                          data={
                            paymentsReceivedData["performances"]
                              ? getTableData([...paymentsReceivedData["performances"]])
                              : []
                          }
                          icons={tableIcons}
                          options={{
                            search: false,
                            pageSize: 10,
                            sorting: false,
                            emptyRowsWhenPaging: false,
                          }}
                          // components={{
                          //   Pagination: PatchedPagination,
                          // }}
                        />
                      </div>
                    </div>
                  )}
                  {/* </div> */}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </React.Fragment>
    </I18nextProvider>
  );
};

export default PaymentsReceivedReport;
