import React, { useEffect, useState } from "react";
import moment from "moment";
import { IconButton } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import CancelIcon from "@material-ui/icons/Cancel";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { makeStyles } from "@material-ui/core/styles";
import { requestInfo_PP } from "../../../Common/CommonFn";
import { connect } from "react-redux";
import EditPaymentOrder from "./EditPaymentOrder";
import { bindActionCreators } from "redux";
import { CancelPlan, DeletePlan } from "./CancelPlan";
import SpinnerPage from "../../../Common/PP_loader";

import { payplanService } from "../../../services/payplanService";
import { useHistory } from "react-router";
import { Translation } from "react-i18next";
import MaterialTable from "material-table";
import { tableIcons } from "../../../components/TableIcons";
import EditIcon from "@material-ui/icons/Edit";
import { handleSuccess, ToastContainer, handleError, handleWarning } from "../../../components/Notifications/Toast";
import PatchedPagination from "../../../Common/TablePagination";
import { Link } from "react-router-dom";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

const useStyles = makeStyles((theme) => ({
  createBtn: {
    outline: "none !important",
  },
  cancelBtn: {
    outline: "none !important",
  },
  createIcon: {
    fontSize: "28px",
  },
  cancelIcon: {
    fontSize: "28px",
  },
}));

const options = {
  jsonMode: true,
  filter: false,
  filterType: false,
  download: false,
  print: false,
  search: false,
  viewColumns: false,
  showTitle: false,
  toolbar: false,
  rowsPerPage: 10,
  rowsPerPageOptions: [5, 10, 20],
  pagination: {
    next: "Next",
    previous: "Previous",
    rowsPerPage: "Rows per page:",
    displayRows: "of",
  },
  selectableRows: "none",

  responsive: "scroll",
};

const PaymentPlanHospitalTable = ({
  bills,
  plan,
  getPaymentPlanDetails,
  creditCardData,
  eChequeData,
  getCardListData,
  getEchecqueListData,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [dialogopen, setDialogOpen] = useState({
    editDialog: false,
    deleteDialog: false,
  });

  const [billData, setBillData] = useState(false);
  const [loader, setLoader] = useState(false);
  const [cancelPaymentPlan, setCancelPaymentPlan] = useState(false);
  const [editPaymentPlan, setEditPaymentPlan] = useState(false);

  useEffect(() => {
    const getRoleData = JSON.parse(sessionStorage.getItem("roleData"))?.pages;
    for (let i in getRoleData) {
      if (getRoleData[i]?.functionalities?.["Pay plan options"]?.["delete"] === "true") {
        sessionStorage.getItem("currentUserRole") === "Admin" && setCancelPaymentPlan(true);
      }
    }
    for (let i in getRoleData) {
      if (getRoleData[i]?.functionalities?.["Pay plan options"]?.["update"] === "true") {
        sessionStorage.getItem("currentUserRole") === "Admin" && setEditPaymentPlan(true);
      }
    }
  }, []);
  const [rowData, SetRowData] = useState([]);
  const options = {
    jsonMode: true,
    filter: false,
    filterType: false,
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    showTitle: false,
    toolbar: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 20],
    pagination: {
      next: "Next",
      previous: "Previous",
      rowsPerPage: "Rows per page:",
      displayRows: "of",
    },
    emptyRowsWhenPaging: false,
    selectableRows: "none",

    responsive: "scroll",
  };

  const handleDeleteOrder = (paymentOrderId) => {
    setLoader(true);
    handleClose();
    payplanService.deleteSingleOrder(
      requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      paymentOrderId,
      1234,
      (res) => {
        if (res.status === 200 && res.data === "deleted successfully") {
          setLoader(false);
          handleSuccess(`Order ${paymentOrderId} Cancelled Successfully`);
          handleClose();
          getPaymentPlanDetails();
        } else {
          setLoader(false);
          handleWarning("Something went wrong!");
        }
      },
      (error) => {
        handleError("Something went wrong! Please try again");
        setLoader(false);
      },
    );
  };

  const handleClose = () => {
    setDialogOpen({
      editDialog: false,
      deleteDialog: false,
    });
  };

  const columns = [
    {
      field: "actions",
      title: "Actions",
    },
    {
      field: "paymentDate",
      title: "Due Date",
      name: "paymentDate",
    },
    {
      title: "Payment Type",
      field: "paymentType",
      name: "paymentType",
    },
    {
      field: "cardNumber",
      title: "Card Number",
      name: "cardNumber",
    },

    {
      title: "Planned Amount",
      field: "paymentOrderAmount",
      name: "paymentOrderAmount",
      type: "numeric",
    },

    {
      title: "Status",
      field: "paymentOrderStatus",
      cellStyle: { padding: "0px 20px" },
    },
  ];

  const handleDialogOpen = (bill, type) => {
    setDialogOpen({
      editDialog: type === "edit",
      deleteDialog: type === "delete",
    });
    setBillData(bill);
  };

  const data =
    bills &&
    bills.map((bill) => {
      const { paymentDate, paymentType, cardNumber, paymentOrderAmount, paymentOrderStatus } = bill;

      const status =
        paymentOrderStatus === "78004" ? (
          <span
            style={{
              fontWeight: "bold",
              color: "#656d04",
            }}>
            Scheduled
          </span>
        ) : paymentOrderStatus === "78001" ? (
          <span
            style={{
              fontWeight: "bold",
              color: "#4eb11b",
            }}>
            Paid
          </span>
        ) : paymentOrderStatus === "78003" ? (
          <span
            style={{
              fontWeight: "bold",
              color: "#d44747",
            }}>
            Failed
          </span>
        ) : paymentOrderStatus === "78002" ? (
          <span
            style={{
              fontWeight: "bold",
              color: "#d44747",
            }}>
            Cancelled
          </span>
        ) : null;
      const actions = (bill) =>
        paymentOrderStatus === "78004" ? (
          <div>
            <IconButton disabled={sessionStorage.getItem("currentUserRole") === "Admin" ? !editPaymentPlan : false}>
              <EditIcon className="paymentplan-icons" onClick={() => handleDialogOpen(bill, "edit")} />
            </IconButton>
            <IconButton disabled={sessionStorage.getItem("currentUserRole") === "Admin" ? !cancelPaymentPlan : false}>
              <CancelIcon className="paymentplan-icons" onClick={() => handleDialogOpen(bill, "delete")} />
            </IconButton>
          </div>
        ) : paymentOrderStatus === "78002" || paymentOrderStatus === "78001" || paymentOrderStatus === "78003" ? (
          <div>
            <IconButton>
              <EditIcon disabled className="paymentplan-icons-disabled" />
            </IconButton>
            <IconButton>
              <CancelIcon disabled className="paymentplan-icons-disabled" />
            </IconButton>
          </div>
        ) : null;

      return {
        actions: actions(bill),
        paymentDate: paymentDate?.split(" ")[0],
        paymentType: paymentType === "Card" ? "Card" : paymentType === "Echeck" ? "eCheck" : paymentType,
        cardNumber: "ending with " + cardNumber?.substr(-4),
        paymentOrderAmount: "$ " + paymentOrderAmount.toLocaleString(undefined, { minimumFractionDigits: 2 }),
        paymentOrderStatus: status,
      };
    });

  const handlePayPlan = (orderId, instrumentId) => {
    setLoader(true);
    handleClose();
    payplanService.updateSingleOrder(
      requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      orderId,
      instrumentId,
      1234,
      (res) => {
        if (res.status === 200 && res.data.statusCodeValue === 200) {
          setLoader(false);
          handleClose();
          handleSuccess(`Payment Method Updated Successfully with ${orderId}`);
          getPaymentPlanDetails();
        } else {
          setLoader(false);
          handleWarning("Something went wrong!");
        }
      },
      (error) => {
        handleError("Something went wrong! Please try again");
        setLoader(false);
      },
    );
  };

  const handleEditOrder = (orderId, instrument) => {
    if (instrument?.split("&")[0] === "echeque" || instrument === "echeck") {
      if (instrument?.split("&")[0] === "echeque") {
        const selectedECheck = eChequeData?.achAccount?.filter((item) => {
          return item.paymentInstrument?.paymentInstrumentId === Number(instrument?.split("&")[1]);
        });

        history.push({
          pathname: sessionStorage.getItem("currentUserRole") === "Admin" ? "/pa/add-echeck" : "/home/add-echeck",
          state: { page: "PaymentPlan", orderId, plan, selectedECheck },
        });
      } else {
        history.push({
          pathname: sessionStorage.getItem("currentUserRole") === "Admin" ? "/pa/add-echeck" : "/home/add-echeck",
          state: { page: "PaymentPlan", orderId, plan },
        });
      }
    } else {
      if (instrument?.split("&")[0] === "card") {
        const selectedCard = creditCardData?.cardAccount?.filter((item) => {
          return item.paymentInstrument?.paymentInstrumentId === Number(instrument?.split("&")[1]);
        });

        history.push({
          pathname: sessionStorage.getItem("currentUserRole") === "Admin" ? "/pa/credit-Card" : "/home/credit-Card",
          state: { page: "PaymentPlan", orderId, plan, selectedCard },
        });
      } else {
        history.push({
          pathname: sessionStorage.getItem("currentUserRole") === "Admin" ? "/pa/credit-Card" : "/home/credit-Card",
          state: { page: "PaymentPlan", orderId, plan },
        });
      }
    }
  };
  return (
    <React.Fragment>
      <ConfirmationDialog
        open={dialogopen.editDialog || dialogopen.deleteDialog}
        handleClose={handleClose}
        handleSubmit={() => handleDeleteOrder(billData.paymentOrderId)}
        fullWidth={true}
        title={
          (dialogopen.editDialog && `Are you sure you want to edit selected plan ${plan}?`) ||
          (dialogopen.deleteDialog && "Are you sure you want to delete selected plan")
        }
        noActionButtons={dialogopen.editDialog ? true : false}>
        {dialogopen.deleteDialog && <DeletePlan plan={plan} billData={billData} />}
        {dialogopen.editDialog && (
          <EditPaymentOrder
            billData={billData}
            creditCardData={creditCardData}
            eChequeData={eChequeData}
            getCardListData={getCardListData}
            getEchecqueListData={getEchecqueListData}
            handleClose={handleClose}
            handleEditOrder={handleEditOrder}
          />
        )}
      </ConfirmationDialog>

      {loader ? (
        <SpinnerPage />
      ) : (
        <MaterialTable
          data={data}
          columns={columns}
          options={options}
          icons={tableIcons}
          components={{
            Pagination: PatchedPagination,
          }}
        />
      )}
    </React.Fragment>
  );
};
export default PaymentPlanHospitalTable;
