import React from "react";
import AssessmentIcon from "@material-ui/icons/Assessment";
import PaymentReconcilation from "./PaymentReconcilation";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";

const PATransaction = () => {
  return (
    <div className="transaction-card" role="main">
      <div className="main-container-card">
        <div role="heading" aria-level="1" className="heading-style mb-3">
          <LocalAtmIcon className="mr-2" />
          <h2 className="content-title heading-style mb-0">Reports: Payment Reconciliation</h2>
        </div>
        <div>
          <PaymentReconcilation />
        </div>
      </div>
    </div>
  );
};

export default PATransaction;
