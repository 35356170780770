import RestDataSource from "./restdatasource";
import { Global_var } from "../global/global_var";
import { loginService } from "./loginService";
import {
  error,
  success,
  warning,
  warningNotification,
  errorNotification,
  successNotification,
} from "../components/PP_Notification/PP_Notification";

export const paymentCreditCardService = {
  saveCreditDetails,
  getPaymentDetails,
  paymentTransaction,
  saveUserConsent,
};

function getPaymentDetails(creditCardInfo, userId, fn, fnError) {
  // alert("in get service")
  // loginService.TokenUser((res) => {
  //   if (res.status === "success") {
  //     debugger
  //     sessionStorage.setItem("jwt-token", res.responseObject);
  //     debugger
  // var url =  Global_var.URL_GET_PAYMENT_DETAILS;
  var url = Global_var.BASEURL + Global_var.URL_GET_PAYMENT_DETAILS;
  return new RestDataSource(url, userId, fn).Store(creditCardInfo, (res) => {
    // debugger;
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
          window.location("/");
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-token", res.headers.token);
        }
        fn(res);
      }
    }
  });
  //   }
  // }, fnError);
}

function saveCreditDetails(creditCardInfo, userId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_SAVE_CREDIT_CARD_DETAILS;
  return new RestDataSource(url, userId, fn).Store(creditCardInfo, (res) => {
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
          window.location("/");
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-token", res.headers.token);
        }
        fn(res);
      }
    }
  });
  //   }
  // }, fnError);
}

// Payment Transaction

function paymentTransaction(paymentinfo, userId, fn, fnError) {
  // alert("in service")
  // loginService.TokenUser((res) => {

  //   if (res.status === "success") {

  //     sessionStorage.setItem("jwt-token", res.responseObject);
  //     // var url =  Global_var.URL_URL_PAYMENT_TRANSACTION;
  var url = Global_var.BASEURL + Global_var.URL_URL_PAYMENT_TRANSACTION;
  // var url =  "http://192.168.12.115:8054/pp-paymentgateway-service/transaction/api/v1/_create"
  return new RestDataSource(url, userId, fn).Store(paymentinfo, (res) => {
    // debugger
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
          window.location("/");
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-token", res.headers.token);
        }
        fn(res);
      }
    }
  });
  //   }
  // }, fnError);
}

//  User Consent

function saveUserConsent(creditCardInfo, userId, fn, fnError) {
  // alert("in service")
  // loginService.TokenUser((res) => {
  //   // alert("in loginService service")

  //   if (res.status === "success") {
  //     // alert("in res")

  //     sessionStorage.setItem("jwt-token", res.responseObject);
  // var url =  Global_var.URL_ADD_CONSENT;
  var url = Global_var.BASEURL + Global_var.URL_ADD_CONSENT;
  return new RestDataSource(url, userId, fn).Store(creditCardInfo, (res) => {
    // debugger
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
          window.location("/");
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-token", res.headers.token);
        }
        fn(res);
      }
    }
  });
  //   }
  // }, fnError);
}
