import React from "react";
import { Formik, Form, Field, ErrorMessage, getIn } from "formik";
import * as Yup from "yup";
// import "react-table/react-table.css";
// import "react-datepicker/dist/react-datepicker.css";
import { FormHelperText, Select, MenuItem, FormControl } from "@material-ui/core";
import { LoginButton } from "../../../../assets/MaterialControl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { I18nextProvider } from "react-i18next";
import { Translation } from "react-i18next";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import i18n from "../../../../i18n";
import { handleWarning, ToastContainer, handleSuccess, handleError } from "../../../../components/Notifications/Toast";
import { searchPatient } from "../../../../action/patientDetailReport";
import { UserManagementService } from "../../../../services/UserManagementService";
import { requestInfo_PP_management } from "../../../../Common/functions";
import { Button } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
const AddOrModify = ({ isEditMode, user, fetchTableApi, lookupData, handleClose }) => {
  const getRoleIdByName = (item) => {
    for (let i in lookupData) {
      if (lookupData[i]?.roleName === item) {
        return lookupData[i]?.roleId;
      }
    }
  };
  let initialValues = {
    userName: isEditMode && user ? user[2] : "",
    emailId: isEditMode && user ? user[3] : "",
    firstName: isEditMode && user ? user[0] : "",
    lastName: isEditMode && user ? user[1] : "",
    userRoles: isEditMode && user ? user[4] : "none",
  };
  const validation = Yup.object().shape({
    userName: Yup.string().required("User Name is required"),
    emailId: Yup.string().email("Invalid email format").required("Email Id is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    userRoles: Yup.string().notOneOf(["none"], "Select a Valid Option").required("Select a User Role"),
  });

  const handleSubmit = (values) => {
    const updateData = {
      requestInfo: requestInfo_PP_management(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      userLoginName: values.userName,
      emailId: values.emailId,
      firstName: values.firstName,
      lastName: values.lastName,
      roleId: getRoleIdByName(values.userRoles),
    };

    UserManagementService.addUserToTable(updateData, (res2) => {
      if (res2.data.status === "success") {
        handleSuccess(`${values.userRoles} added successfully!`);
        fetchTableApi();
        handleClose();
      } else if (res2?.data?.status === "fail") {
        handleWarning(res2.data.reasonText);
      }
    });
  };

  return (
    <I18nextProvider i18n={i18n}>
      <React.Fragment>
        <Formik enableReinitialize initialValues={initialValues} validationSchema={validation} onSubmit={handleSubmit}>
          {({ touched, errors, setFieldValue, values, resetForm, handleBlur, handleChange }) => (
            <Form autocomplete="new-password" className="input-field">
              <div>
                <div className="form-row">
                  <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                      <label htmlFor="userName" className="required">
                        User Name
                      </label>
                      <OutlinedInput
                        type="text"
                        id="userName"
                        name={"a" + Math.random()}
                        value={values.userName}
                        placeholder="Enter User Name"
                        onInput={(e) => {}}
                        error={getIn(touched, "userName") && getIn(errors, "userName")}
                        className={`form-control admin-select-field ${
                          touched.userName && errors.userName ? "is-invalid" : ""
                        }`}
                        onChange={(event) => {
                          setFieldValue("userName", event.target.value);
                        }}
                      />

                      <ErrorMessage component="div" name="userName" className="error-text" />
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                      <label htmlFor="firstName" className="required">
                        First Name
                      </label>
                      <OutlinedInput
                        type="text"
                        id="firstName"
                        name={"firstName" + Math.random()}
                        autocomplete="new-password"
                        error={getIn(touched, "firstName") && getIn(errors, "firstName")}
                        value={values.firstName}
                        placeholder="Enter First Name"
                        className={`form-control admin-select-field ${
                          touched.firstName && errors.firstName ? "is-invalid" : ""
                        }`}
                        onChange={(event) => {
                          setFieldValue("firstName", event.target.value);
                        }}
                      />
                      <ErrorMessage component="div" name="firstName" className="error-text" />
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                      <label htmlFor="lastName" className="required">
                        Last Name
                      </label>
                      <OutlinedInput
                        type="text"
                        id="lastName"
                        name={"lastName" + Math.random()}
                        value={values.lastName}
                        error={getIn(touched, "lastName") && getIn(errors, "lastName")}
                        placeholder="Enter Last Name"
                        className={`form-control admin-select-field ${
                          touched.lastName && errors.lastName ? "is-invalid" : ""
                        }`}
                        onChange={(event) => {
                          setFieldValue("lastName", event.target.value);
                        }}
                      />

                      <ErrorMessage component="div" name="lastName" className="error-text" />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label htmlFor="emailId" className="required">
                        Email Id
                      </label>
                      <OutlinedInput
                        type="text"
                        id="emailId"
                        name={"a" + Math.random()}
                        value={values.emailId}
                        autocomplete="new-password"
                        placeholder="Enter Email Id"
                        error={getIn(touched, "emailId") && getIn(errors, "emailId")}
                        className={`form-control admin-select-field ${
                          touched.emailId && errors.emailId ? "is-invalid" : ""
                        }`}
                        onChange={(event) => {
                          setFieldValue("emailId", event.target.value);
                        }}
                      />
                      <ErrorMessage component="div" name="emailId" className="error-text" />
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <label htmlFor="userRoles" className="form-label required">
                        User Roles
                      </label>
                      <FormControl variant="outlined" className="w-100" fullWidth>
                        <Select
                          autocomplete="off"
                          className={`form-control ${touched.userRoles && errors.userRoles ? "is-invalid" : ""}`}
                          name="userRoles"
                          placeholder="User Roles"
                          component="select"
                          // value={values.userRoles}
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={getIn(touched, "userRoles") && getIn(errors, "userRoles")}
                          value={values["userRoles"]}>
                          <MenuItem value="none" key="none" disabled>
                            Select User Role
                          </MenuItem>
                          {lookupData.map((each) => {
                            return (
                              <MenuItem value={each.roleName} key={each.key}>
                                {`${each.roleName}`}
                              </MenuItem>
                            );
                          })}
                          {/* <option value="ppSuperAdmin">PP Super Admin</option>
                        <option value="ppAccounting">PP Accounting</option>
                        <option value="ppAdmin">PP Admin</option>
                        <option value="ppUser">PP User</option>
                        <option value="rp">RP</option> */}
                        </Select>
                      </FormControl>
                      <ErrorMessage component="div" name="userRoles" className="error-text" />
                    </div>
                  </div>
                </div>
                <DialogActions>
                  <Button type="submit" className="primary-button themeing-buttons">
                    Submit
                  </Button>
                  <Button className="secondary-button" variant="outlined" onClick={() => resetForm(initialValues)}>
                    Reset
                  </Button>
                </DialogActions>
                {/* <div className="d-flex justify-content-center my-2">
                  <Translation>
                    {(t, { i18n }) => (
                      <LoginButton type="submit" size="small" className="change-role-buttons">
                        Submit
                      </LoginButton>
                    )}
                  </Translation>
                  <Translation>
                    {(t, { i18n }) => (
                      <LoginButton size="small" className="change-role-buttons" onClick={() => resetForm(initialValues)}>
                        Reset
                      </LoginButton>
                    )}
                  </Translation>
                </div> */}
              </div>{" "}
            </Form>
          )}
        </Formik>
        {/* <ToastContainer /> */}
      </React.Fragment>
    </I18nextProvider>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchPatient: searchPatient,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddOrModify);
