import {
  FETCH_ECHEQUE_DETAILS_SUCCESS,
  FETCH_ECHEQUE_DETAILS_PENDING,
  ADD_ECHAQUE,
  GET_COUNTRY,
} from "../action/pp_eChequePaymentAction";

const initialState = {
  eChequeData: [],
  pending: false,
};

const eChequePaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ECHEQUE_DETAILS_SUCCESS:
      return {
        ...state,
        eChequeData: action.payload,
        pending: false,
      };
    case FETCH_ECHEQUE_DETAILS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_ECHAQUE:
      return {
        ...state,
        data: action.payload,
      };
    // case GET_COUNTRY:
    //   return {
    //     ...state,
    //     data: action.payload,
    //   };
    default:
      return state;
  }
};

export default eChequePaymentReducer;

export const geteChequePaymentList = (state) => state.eChequeData;
export const geteChequePaymentListPending = (state) => state?.pending;
