import { Global_var } from "../global/global_var";
import RestDataSource from "./restdatasource";

export const loginService = {
  login,
  logout,
  TokenUser,
  AutoLogin,
};

function login(userData, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_LOGIN;
      return new RestDataSource(url, null, "CORE", fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.responseObject);
          }
          fn(res);
        }
      });
    }
  });
}

function logout(username, fn) {
  // remove user from local storage to log user out
  sessionStorage.removeItem("userData");
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_LOGOUT;
      return new RestDataSource(url, null, "CORE", fn).Store(username, (res) => fn(res));
    }
  });
}

function TokenUser(fn) {
  let url = Global_var.BASEURL + Global_var.URL_JWT_TRUST;
  return new RestDataSource(url, null, "CORE", fn).GetData((res) => fn(res.data));
}

function AutoLogin(userData, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_AUTO_LOGIN;
      return new RestDataSource(url, null, "CORE", fn).Store(userData, (res2) => {
        if (res2 != null) {
          if (res2.headers["token"] != null) {
            sessionStorage.setItem("jwt-token", res2["headers"].token);
          }
          fn(res2);
        }
      });
    }
  });
}
