import React from "react";
import MUIDataTable from "mui-datatables";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { postTheVoidTransaction } from "../../../action/voidAndRefundAction.js";
import { formatAmount } from "../../../util/amountFunction.js";

import { handleSuccess, handleError } from "../../../components/Notifications/Toast";
const EnableVoidDetails = ({ voidTransactionData, handelclose, postTheVoidTransaction }) => {
  const {
    authorizationCode,
    refundOrderDetails,
    orderNumber,
    totalRefundAmount,
    transactionDate,
    transactionRefId,
    transactionNumber,
    firstName,
    lastName,
  } = voidTransactionData;

  const columns = [
    {
      name: "seedAccountNumber",
      label: "Seed Account Number",
      field: "seedAccountNumber",
      options: {
        sort: false,
        sortFilterList: false,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div className="void-table-elements">
              <b>Seed Account Number</b>
            </div>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div className="void-table-elements">{value}</div>;
        },
      },
    },
    {
      name: "accountNumber",
      label: "Account Number",
      field: "accountNumber",
      options: {
        sort: false,
        sortFilterList: false,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div className="void-table-elements">
              <b>Account Number</b>
            </div>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div className="void-table-elements">{value}</div>;
        },
      },
    },
    {
      name: "paidAmount",
      label: "Paid Amount",
      field: "paidAmount",
      options: {
        sort: false,
        sortFilterList: false,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div className="void-table-elements">
              <b>Paid Amount</b>
            </div>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div className="void-table-elements">${formatAmount(value)}</div>;
        },
      },
    },
  ];
  const tableHeaderStyle = {
    textAlign: "inherit",
    color: "#000000",
    fontWeight: "500",
    padding: "15px",
    fontSize: "14px",
    fontFamily: "Poppins",
  };
  const rowStyle = {
    height: "30px",
    fontSize: "14px",
  };
  const options = {
    jsonMode: true,
    filter: false,
    filterType: false,
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    headerStyle: tableHeaderStyle,
    rowStyle: rowStyle,
    pagination: false,
    selectableRows: "none", // comment to show checkboxes
  };

  return (
    <div div className="void-refund-container input-field">
      <div className="form-row">
        {/* <div style={continer}>
                <h3 style={titleStyle}><b>Card Number:</b> {cardNumber}</h3>
                <h3 style={titleStyle}><b>Card Holder:</b> {cardHolder}</h3>
            </div> */}
        <div className="col-sm-6 col-md-6 col-lg-4 form-group">
          <div className="void-refund-details ">
            <div className="label mr-1">Transaction Date:</div>
            <div className="value">{transactionDate.split(" ")[0]}</div>
          </div>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-4 form-group">
          <div className="void-refund-details ">
            <div className="label mr-1">Paid Amount:</div>
            <div className="value">${formatAmount(totalRefundAmount)}</div>
          </div>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-4 form-group">
          <div className="void-refund-details ">
            <div className="label mr-1"> Authorization Code:</div>
            <div className="value">{authorizationCode}</div>
          </div>
        </div>
      </div>
      <div>
        <div className="void-table">
          <MUIDataTable data={refundOrderDetails ? refundOrderDetails : []} columns={columns} options={options} />
        </div>
        <div className="d-flex justify-content-center mt-4 mb-2">
          <button
            aria-label="Void"
            className="themeing-buttons primary-button mr-2"
            onClick={() => {
              const payload = {
                refundOrderDetails: refundOrderDetails,
                orderNumber: orderNumber,
                transactionRefId: transactionRefId,
                action: "void",
                totalRefundAmount: 0,
                transactionNumber: transactionNumber,
                firstName: firstName,
                lastName: lastName,
                authorizationCode: authorizationCode,
              };
              postTheVoidTransaction(payload, (res) => {
                res?.responseInfo?.status === "SUCCESSFUL"
                  ? handleSuccess(`${res.responseInfo.responseMessage}`)
                  : handleError("Something Went Wrong");
                handelclose();
              });
            }}>
            Void
          </button>
          <button onClick={() => handelclose()} aria-label="Cancel" className="secondary-button mr-2" type="button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      postTheVoidTransaction,
    },
    dispatch,
  );
export default compose(connect(mapStateToProps, mapDispatchToProps))(EnableVoidDetails);
