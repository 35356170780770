import React, { useContext, useEffect, useState } from "react";
import { Box, Menu, MenuItem, Drawer, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import CustomizerContent from "../../themes/CustomizerContent";
import { AppContext } from "../../context/AppProvider";
import { fetchUserDetailData } from "../../action/userDetailReport";
import TwofactorAuthentication from "../TwoFactorAuthentication/TwofactorAuthentication";
import { getQrImg } from "../../action/TwoFactorAction";
import { TwoFactorService } from "../../services/twoFactorService";
import { userService } from "../../services/userDetalService";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import HttpsOutlinedIcon from "@material-ui/icons/HttpsOutlined";
import SecurityIcon from "@material-ui/icons/Security";

const useStyles = makeStyles((theme) => ({
  customizerOption: {
    width: 300,
    [theme.breakpoints.up("sm")]: {
      width: 380,
    },
  },
}));

const CmtDropdownMenu = ({
  setIsAuthenticate,
  isAuthenticate,
  TriggerComponent,
  items,
  props,
  onItemClick,
  open,
  anchorEl,
  setAnchorEl,
  route,
}) => {
  const [menuItems, setMenuItems] = useState(items);
  const [twoFactorPopup, setTwoFactorPopup] = useState(false);
  const [twoFactorDisablePopup, setTwoFactorDisablePopup] = useState(false);
  const [twoFactorStepperNo, setTwoFactorStepperNo] = useState(0);
  const [showAuthenticatorKey, setShowAuthenticatorKey] = useState(false);
  const [qrImageUrl, setQrImageUrl] = useState("");
  const [qrSetUpKey, setQrSetUpKey] = useState("");
  const { sidebar, setSidebar, hoverNav, setHoverNav, hoverSide, setHoverSide } = useContext(AppContext);
  const role = sessionStorage.getItem("currentUserRole");
  useEffect(() => {
    if (sessionStorage.getItem("currentUserRole") === "Customer") {
      setMenuItems([
        {
          icon: <AccountCircleIcon />,
          label: "Update Profile",
        },
        {
          icon: <HttpsOutlinedIcon />,
          label: "Change Password",
        },
        {
          icon: <SecurityIcon />,
          label: isAuthenticate ? "Disable Two Factor Authentication" : "Enable Two Factor Authentication",
        },
        {
          icon: <SettingsIcon />,
          label: "Theme Settings",
        },
      ]);
    } else {
      setMenuItems([
        {
          icon: <HttpsOutlinedIcon />,
          label: "Change Password",
        },
        {
          icon: <SecurityIcon />,
          label: isAuthenticate ? "Disable Two Factor Authentication" : "Enable Two Factor Authentication",
        },
        {
          icon: <SettingsIcon />,
          label: "Theme Settings",
        },
      ]);
    }
  }, [isAuthenticate]);

  const history = useHistory();
  const classes = useStyles();
  const openMenu = (event) => {
    setSidebar(true);
    setAnchorEl(event.currentTarget);
  };
  const [isOpen, setOpen] = useState(false);

  const closeMenu = () => {
    setHoverNav(false);
    setAnchorEl(null);
  };

  const handleTwofactor = () => {
    if (isAuthenticate === 1) {
      setTwoFactorDisablePopup(true);
    } else if (isAuthenticate !== 1) {
      setTwoFactorPopup(true);
      const passValues2 = {
        emailId: JSON.parse(sessionStorage.getItem("userData"))?.emailId,
        firstName: JSON.parse(sessionStorage.getItem("userData"))?.userName,
      };
      TwoFactorService.getQrcode(passValues2, (res2) => {
        setQrImageUrl(res2?.data?.imageUrl);
        setQrSetUpKey(res2?.data?.Key);
      });
    }
  };

  const handleMenuItemClick = (item, selectedIndex) => {
    let updatedItem = { ...item };

    if (item && item.label && item.label === "Update Profile") {
      history.push(`/${route}/update-profile`);
    }
    if (
      item &&
      item.label &&
      (item.label === "Enable Two Factor Authentication" || item.label === "Disable Two Factor Authentication")
    ) {
      // setSidebar(false);
      handleTwofactor();
    }
    if (item && item.label && item.label === "Change Password") {
      setHoverNav(!hoverNav);
      history.push(`/${route}/change-password`);
    }
    if (item && item.label && item.label === "Theme Settings") {
      setOpen(true);
    }
    if (item.onClick && typeof item.onClick === "function") {
      updatedItem = item.onClick(item);
    } else if (onItemClick && typeof onItemClick === "function") {
      updatedItem = onItemClick(item);
    }

    setMenuItems(
      menuItems.map((item, index) => {
        if (updatedItem && selectedIndex === index) {
          item = updatedItem;
        }
        return item;
      }),
    );

    closeMenu();
  };

  return (
    <React.Fragment>
      <Box className="pointer">
        <TriggerComponent.type {...TriggerComponent.props} onClick={openMenu} />
      </Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={closeMenu}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}>
        {menuItems.map((item, index) => {
          return (
            <MenuItem key={index} disabled={item.disabled} onClick={() => handleMenuItemClick({ ...item }, index)}>
              {item.icon}
              <Box ml={2}>{item.label}</Box>
            </MenuItem>
          );
        })}
      </Menu>
      <Drawer
        variant="temporary"
        open={isOpen}
        anchor="right"
        onClose={() => setOpen(false)}
        classes={{
          paper: "drawer-sidebar",
        }}>
        <Box className={classes.customizerOption}>
          <CustomizerContent onClose={() => setOpen(false)} {...props}></CustomizerContent>
        </Box>
      </Drawer>
      <TwofactorAuthentication
        twoFactorPopup={twoFactorPopup}
        twoFactorDisablePopup={twoFactorDisablePopup}
        twoFactorStepperNo={twoFactorStepperNo}
        showAuthenticatorKey={showAuthenticatorKey}
        qrImageUrl={qrImageUrl}
        qrSetUpKey={qrSetUpKey}
        isAuthenticate={isAuthenticate}
        setTwoFactorPopup={setTwoFactorPopup}
        setTwoFactorDisablePopup={setTwoFactorDisablePopup}
        setTwoFactorStepperNo={setTwoFactorStepperNo}
        setShowAuthenticatorKey={setShowAuthenticatorKey}
        setQrImageUrl={setQrImageUrl}
        setQrSetUpKey={setQrSetUpKey}
        setIsAuthenticate={setIsAuthenticate}
      />
    </React.Fragment>
  );
};

CmtDropdownMenu.propTypes = {
  items: PropTypes.array.isRequired,
  TriggerComponent: PropTypes.element.isRequired,
  onItemClick: PropTypes.func,
};

export default CmtDropdownMenu;
