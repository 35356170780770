/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { getIn } from "formik";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { LoginButton } from "../../../../assets/MaterialControl";
import { I18nextProvider } from "react-i18next";
import { Translation } from "react-i18next";
import i18n from "../../../../i18n";
import ChangeRole from "./ChangeRole";
import SpinnerPage from "../../../../Common/PP_loader";
import { handleWarning, ToastContainer, handleSuccess, handleError } from "../../../../components/Notifications/Toast";
import { IconButton, MuiThemeProvider, Tooltip, FormHelperText, FormControl, Select, MenuItem } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { getMuiTheme } from "../../../../Common/ThemeFunctions";
import LockIcon from "@material-ui/icons/Lock";
import ReplayIcon from "@material-ui/icons/Replay";
import DeleteIcon from "@material-ui/icons/Delete";
import RefreshIcon from "@material-ui/icons/Refresh";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AddUserDialog from "../../../../containers/Admin_UserManagement/ConfirmationDialog";
import ConfirmationDialogUser from "./ConfirmationDialogUser";
import AddorModifyUser from "./AddorModifyUser";
import { makeStyles } from "@material-ui/core/styles";
import { UserManagementService } from "../../../../services/UserManagementService";
import ResetPassword from "./ResetPassword";
import ResetPasswordDialog from "./ResetPasswordDialog";
import ConfirmationDialog from "./../../../../components/ConfirmationDialog/index";
import MaterialTable from "material-table";
import { tableIcons } from "../../../../components/TableIcons";
import { ChevronLeft, ChevronRight, FirstPage, LastPage, ResetTvRounded } from "@material-ui/icons";
const useStyles = makeStyles({
  tooltip: {
    fontSize: "1em",
  },
});

const AdminUserManagementComponent = ({ props }) => {
  const classes = useStyles();

  const [searchAccess, setSearchAccess] = useState(false);
  const [resetAccess, setResetAccess] = useState(false);
  const [addUserAccess, setAddUserAccess] = useState(false);
  const [restoreAccess, setRestoreAccess] = useState(false);
  const [changeRoleAccess, setChangeRoleAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [twoFactorAccess, setTwoFactorAccess] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserDelete, setIsUserDelete] = useState(false);
  const [isRestore, setIsRestore] = useState(false);
  const [data, setData] = useState({
    emailId: "",
    userLoginName: "",
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isChangeRoleOpen, setIsChangeRoleOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [user, setUser] = useState([]);
  const [userTableData, setUserTableData] = useState([]);
  const [lookupData, setLookupData] = useState([]);
  const [isResetPasswordDialog, setIsResetPasswordDialog] = useState(false);
  const [paginationData, setPaginationData] = useState({
    pages: null,
    page: null,

    noOfPages: "",
    totalPages: "",
  });
  const [count, setCount] = useState(0);
  const [fromPage, setFromPage] = useState(0);
  const [toPage, setToPage] = useState(15);
  const [page, setPage] = useState(0);
  const [noOfPages, setNoOfPages] = useState(0);
  const initialValue = {
    emailId: "",
    userLoginName: "",
  };
  const handleRowChange = (total) => {
    let toValue = noOfPages === page ? total : total < rowsPerPage ? total : page * rowsPerPage + rowsPerPage;
    setFromPage(page * rowsPerPage + 1);
    setToPage(toValue);
  };
  const fetchTableApi = useCallback((values, reset) => {
    const PassValues3 = {
      roleLevel: JSON.parse(sessionStorage.getItem("userData"))?.role[0]?.roleLevel,
      userName: (values?.userLoginName || data?.userLoginName) && !reset ? values?.userLoginName || data?.userLoginName : "",
      emailId: (values?.emailId || data?.emailId) && !reset ? values?.emailId || data?.emailId : "",
      limit: rowsPerPage || 10, // pageSize
      offset: page || 0, //pageNo
    };
    setIsLoading(true);
    UserManagementService.getUsersTableData(
      PassValues3,
      (res2) => {
        let temp = [];
        for (let i = 0; i < res2?.data?.totalRecords / rowsPerPage; i++) {
          temp.push(i);
        }
        const total = res2?.data?.totalRecords;
        setNoOfPages(temp.length - 1);
        setUserTableData(res2?.data?.responseListObject);
        setCount(res2?.data?.totalRecords);
        handleRowChange(total);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
      },
    );
  });

  const emailConfirmationvalidation = Yup.object().shape({
    emailId: Yup.string()
      .test("oneOfRequired", "Email Id is required", function (item) {
        return this.parent.emailId || this.parent.userLoginName;
      })
      .nullable()
      .email("Please enter a valid Email Address"),
    userLoginName: Yup.string().test("oneOfRequired", " User Name is required", function (item) {
      return this.parent.emailId || this.parent.userLoginName;
    }),
  });

  const handleClose = () => {
    setDialogOpen(false);
    setIsUserDelete(false);
    setIsRestore(false);
    setIsChangeRoleOpen(false);
    setIsEditMode(false);
    setIsResetPasswordDialog(false);
  };
  const handleSubmit = (values) => {
    setToPage(10);
    setData({
      emailId: values?.emailId || "",
      userLoginName: values?.userLoginName || "",
    });
    fetchTableApi(values);
  };
  const id = JSON.parse(sessionStorage.getItem("userData"))?.role[0]?.roleLevel;
  useEffect(() => {
    fetchTableApi();
    UserManagementService.lookupRoles(id, (res) => {
      setLookupData(res?.data?.responseListObject);
    });

    if (id || id === 0) {
      UserManagementService?.roleAccess(id, (getRoleData) => {
        for (let i in getRoleData?.data?.responseObject?.pages) {
          if (getRoleData?.data?.responseObject?.pages[i]?.functionalities?.["Restore"]?.["page access"] === "true") {
            setRestoreAccess(true);
          }
        }
        for (let i in getRoleData?.data?.responseObject?.pages) {
          if (getRoleData?.data?.responseObject?.pages[i]?.functionalities?.["Reset password"]?.["page access"] === "true") {
            setResetAccess(true);
          }
        }
        for (let i in getRoleData?.data?.responseObject?.pages) {
          if (getRoleData?.data?.responseObject?.pages[i]?.functionalities?.["Delete"]?.["page access"] === "true") {
            setDeleteAccess(true);
          }
        }
        for (let i in getRoleData?.data?.responseObject?.pages) {
          if (getRoleData?.data?.responseObject?.pages[i]?.functionalities?.["Change role"]?.["page access"] === "true") {
            setChangeRoleAccess(true);
          }
        }
        for (let i in getRoleData?.data?.responseObject?.pages) {
          if (getRoleData?.data?.responseObject?.pages[i]?.functionalities?.["Add"]?.["page access"] === "true") {
            setAddUserAccess(true);
          }
        }
        for (let i in getRoleData?.data?.responseObject?.pages) {
          if (getRoleData?.data?.responseObject?.pages[i]?.functionalities?.["Search"]?.["page access"] === "true") {
            setSearchAccess(true);
          }
        }
        for (let i in getRoleData?.data?.responseObject?.pages) {
          if (getRoleData?.data?.responseObject?.pages[i]?.functionalities?.["Disable 2FA"]?.["page access"] === "true") {
            setTwoFactorAccess(true);
          }
        }
      });
    }
  }, []);

  const columns = [
    {
      name: "userName",
      title: "User Name",
      field: "userName",
      render: (rowData) => {
        return userTableData ? rowData.userName : "";
      },
    },
    {
      name: "firstName",
      title: "First Name",
      field: "firstName",
    },
    {
      name: "lastName",
      title: "Last Name",
      field: "lastName",
    },

    {
      name: "email",
      title: "Email Id",
      field: "email",
    },
    {
      name: "roleName",
      title: "Role",
      field: "roleName",
    },

    {
      name: "isActive",
      title: "Status",
      field: "isActive",
      render: (rowData) => {
        return rowData?.isActive === 1 ? (
          <p style={{ color: "green", marginBottom: 0 }}>Active</p>
        ) : (
          <p style={{ color: "red", marginBottom: 0 }}>In Active</p>
        );
      },
    },
    {
      title: "Action",
      render: (rowData) => {
        return (
          <div className="d-flex">
            <Tooltip classes={{ tooltip: classes.tooltip }} title="Reset Password">
              <IconButton
                // disabled={
                //   JSON.parse(sessionStorage.getItem("roleData"))?.level === 4
                //     ? !rowData?.roleLevel === 4
                //     : JSON.parse(sessionStorage.getItem("roleData"))?.level === 2 ||
                //       JSON.parse(sessionStorage.getItem("roleData"))?.level === 5 ||
                //       JSON.parse(sessionStorage.getItem("roleData"))?.level >= rowData?.roleLevel ||
                //       rowData?.roleLevel === 0 ||
                //       rowData?.roleLevel < JSON.parse(sessionStorage.getItem("roleData"))?.level
                // }
                disabled={!resetAccess}
                onClick={() => {
                  setIsResetPasswordDialog(true);
                  setUser(rowData);
                }}>
                <span className="reset-icon">
                  <LockIcon
                    disabled={rowData?.roleLevel < JSON.parse(sessionStorage.getItem("roleData"))?.level}
                    className="reset-lock"
                  />
                  <ReplayIcon
                    disabled={rowData?.roleLevel < JSON.parse(sessionStorage.getItem("roleData"))?.level}
                    className="reset-replay"
                  />
                </span>
              </IconButton>
            </Tooltip>
            <Tooltip classes={{ tooltip: classes.tooltip }} title="Deactivate User">
              <IconButton
                // disabled={
                //   JSON.parse(sessionStorage.getItem("roleData"))?.level === 4
                //     ? !rowData?.roleLevel === 4
                //     : JSON.parse(sessionStorage.getItem("roleData"))?.level === 2 ||
                //       JSON.parse(sessionStorage.getItem("roleData"))?.level === 5 ||
                //       JSON.parse(sessionStorage.getItem("roleData"))?.level >= rowData?.roleLevel ||
                //       rowData?.roleLevel === 0
                // }
                disabled={!deleteAccess}>
                <DeleteIcon
                  disabled={!deleteAccess}
                  onClick={() => {
                    setUser(rowData?.tableData?.id);
                    setIsUserDelete(true);
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip classes={{ tooltip: classes.tooltip }} title="Restore User">
              <IconButton
                // disabled={
                //   JSON.parse(sessionStorage.getItem("roleData"))?.level === 4
                //     ? !rowData?.roleLevel === 4
                //     : JSON.parse(sessionStorage.getItem("roleData"))?.level === 2 ||
                //       JSON.parse(sessionStorage.getItem("roleData"))?.level === 5 ||
                //       JSON.parse(sessionStorage.getItem("roleData"))?.level >= rowData?.roleLevel ||
                //       rowData?.isActive === 1
                // }
                disabled={!restoreAccess}>
                {" "}
                <RefreshIcon
                  disabled={!restoreAccess}
                  onClick={() => {
                    setUser(rowData?.tableData?.id);
                    restoreAccess && setIsRestore(true);
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip classes={{ tooltip: classes.tooltip }} title="Change Role">
              <IconButton
                // disabled={
                //   JSON.parse(sessionStorage.getItem("roleData"))?.level === 4
                //     ? !rowData?.roleLevel === 4
                //     : JSON.parse(sessionStorage.getItem("roleData"))?.level === 2 ||
                //       JSON.parse(sessionStorage.getItem("roleData"))?.level === 5 ||
                //       JSON.parse(sessionStorage.getItem("roleData"))?.level >= rowData?.roleLevel ||
                //       rowData?.roleLevel === 0
                // }
                disabled={!changeRoleAccess}>
                <SupervisorAccountIcon
                  disabled={!changeRoleAccess}
                  onClick={() => {
                    changeRoleAccess && setIsChangeRoleOpen(true);
                    setUser(rowData);
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip classes={{ tooltip: classes.tooltip }} title="Disable Two-Factor Authentication">
              <IconButton
                // disabled={
                //   JSON.parse(sessionStorage.getItem("roleData"))?.level === 4
                //     ? !rowData?.roleLevel === 4
                //     : JSON.parse(sessionStorage.getItem("roleData"))?.level === 2 ||
                //       JSON.parse(sessionStorage.getItem("roleData"))?.level === 5 ||
                //       JSON.parse(sessionStorage.getItem("roleData"))?.level >= rowData?.roleLevel ||
                //       rowData?.roleLevel === 0
                // }
                disabled={!twoFactorAccess}>
                <i
                  className="fa fa-google"
                  aria-hidden="true"
                  disabled={
                    JSON.parse(sessionStorage.getItem("roleData"))?.level === 2 ||
                    JSON.parse(sessionStorage.getItem("roleData"))?.level >= rowData?.roleLevel
                  }
                  onClick={() => {
                    const data = {
                      emailId: rowData?.email,
                    };
                    UserManagementService.disableTwoFactorForALowerGradeUser(data, (res) => {
                      if (res.data.responseInfo.status === "SUCCESSFUL") {
                        handleSuccess("Two factor authentication is disabled successfully!");
                      } else if (res.data.responseInfo.status === "FAILED") {
                        handleError(<Translation>{(t, { i18n }) => <span>{res?.status}</span>}</Translation>);
                      }
                    });
                  }}></i>
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const handleCloseDelete = () => {
    setIsUserDelete(false);
  };

  const handleDelete = () => {
    const id = userTableData[user].userId;
    UserManagementService.deleteUser(id, (res) => {
      if (res?.data?.status === "success") {
        handleCloseDelete();
        handleSuccess(`${userTableData[user]?.userName} deactivated successfully!`);
        fetchTableApi();
      } else {
        handleError(<Translation>{(t, { i18n }) => <span>{res?.status}</span>}</Translation>);
      }
    });
  };

  const handleCloseRestore = () => {
    setIsRestore(false);
  };

  const handleRestore = () => {
    const id = userTableData[user].userId;
    UserManagementService.restoreUser(id, (res) => {
      if (res?.data?.status === "success") {
        handleCloseRestore();
        handleSuccess(`${userTableData[user]?.userName} restored successfully!`);
        fetchTableApi();
      } else {
        handleError(<Translation>{(t, { i18n }) => <span>{res?.status}</span>}</Translation>);
      }
    });
  };

  useEffect(() => {
    if (rowsPerPage || page) {
      fetchTableApi(data);
    }
  }, [rowsPerPage, page]);

  const options = {
    serverSide: true,
    jsonMode: true,
    filter: false,
    sorting: false,
    filterType: false,
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    responsive: "scrollMaxHeight",
    selectableRows: "none",
    count: count || 0,
    rowsPerPage: rowsPerPage || 10,
    emptyRowsWhenPaging: false,
    //rowsPerPageOptions: [10, 15, 30, 50, 100],
    page: paginationData.page || 0,
    pageSize: rowsPerPage,
    toolbar: false,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setPaginationData({
            page: tableState?.page,
            rowsPerPage: tableState?.rowsPerPage,
          });

          break;
        case "changeRowsPerPage":
          setPaginationData({
            page: tableState?.page,
            rowsPerPage: tableState?.rowsPerPage,
          });

          break;
        default:
      }
    },
    pagination: {
      next: "Next",
      previous: "Previous",
      rowsPerPage: "Rows per page:",
      displayRows: "of",
    },
  };

  return (
    <I18nextProvider i18n={i18n}>
      <React.Fragment>
        <div>
          <Formik
            enableReinitialize
            initialValues={initialValue}
            validationSchema={emailConfirmationvalidation}
            onSubmit={handleSubmit}>
            {({ touched, errors, setFieldValue, values, resetForm }) => (
              <Form className="input-field" noValidate="noValidate">
                <div className="form-row">
                  <div className="col-xl-4 col-lg-4 col-sm-6 col-md-6 form-group">
                    <div>
                      <FormControl component="fieldset" variant="outlined" className="w-100" fullWidth>
                        <label htmlFor="userLoginName" className="required">
                          User Name{" "}
                        </label>
                        <OutlinedInput
                          type="text"
                          autocomplete="new-password"
                          name="userLoginName"
                          placeholder="Enter User Name"
                          style={{
                            background: values.emailId ? "#e7e7e7" : "",
                          }}
                          value={values.userLoginName}
                          disabled={values.emailId}
                          error={Boolean(getIn(touched, "userLoginName") && getIn(errors, "userLoginName"))}
                          className={`form-control admin-select-field ${
                            touched.userLoginName && errors.userLoginName ? "is-invalid" : ""
                          }`}
                          onChange={(event) => {
                            setFieldValue("userLoginName", event.target.value);
                          }}
                        />
                      </FormControl>
                      <ErrorMessage component="div" name="userLoginName" className="error-text" />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-6 col-md-6 form-group">
                    <div>
                      <FormControl component="fieldset" variant="outlined" className="w-100" fullWidth>
                        <label htmlFor="emailId" className="required">
                          Email Id{" "}
                        </label>
                        <OutlinedInput
                          type="text"
                          autocomplete="new-password"
                          name="emailId"
                          placeholder="Enter Email Id"
                          value={values.emailId}
                          error={Boolean(getIn(touched, "emailId") && getIn(errors, "emailId"))}
                          className={`form-control admin-select-field ${
                            touched.emailId && errors.emailId ? "is-invalid" : ""
                          }`}
                          style={{
                            background: values.userLoginName ? "#e7e7e7" : "",
                          }}
                          disabled={values.userLoginName}
                          onChange={(event) => {
                            setFieldValue("emailId", event.target.value);
                          }}
                        />
                      </FormControl>
                      <ErrorMessage component="div" name="emailId" className="error-text" />
                    </div>
                  </div>
                </div>
                <div className="payment-history-btns">
                  <button
                    className="primary-button themeing-buttons"
                    type={searchAccess ? "submit" : "button"}
                    disabled={!searchAccess}>
                    Search
                  </button>
                  <button
                    className="secondary-button mx-2"
                    type="reset"
                    onClick={() => {
                      setFromPage(0);
                      setToPage(0);
                      setPage(0);
                      handleRowChange(count);
                      setData({
                        emailId: "",
                        userLoginName: "",
                      });
                      resetForm({
                        values: {
                          emailId: "",
                          userLoginName: "",
                        },
                      });
                      let reset = true;

                      fetchTableApi(values, reset);
                    }}>
                    Reset
                  </button>
                  <button
                    className="primary-button themeing-secondary-buttons"
                    type="button"
                    disabled={!addUserAccess}
                    onClick={() => addUserAccess && setDialogOpen(true)}>
                    Add User
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <ConfirmationDialog
          open={isResetPasswordDialog}
          handleClose={handleClose}
          title={"Reset Password"}
          noActionButtons={true}>
          <ResetPassword handleClose={handleClose} lookupData={lookupData} fetchTableApi={fetchTableApi} user={user} />
        </ConfirmationDialog>

        <ConfirmationDialog open={dialogOpen} handleClose={handleClose} title={"Add/Modify user"} noActionButtons={true}>
          <AddorModifyUser
            handleClose={handleClose}
            lookupData={lookupData}
            isEditMode={isEditMode}
            user={user}
            fetchTableApi={fetchTableApi}
          />
        </ConfirmationDialog>

        <ConfirmationDialog open={isChangeRoleOpen} handleClose={handleClose} title={"Change Role"} noActionButtons={true}>
          <ChangeRole handleClose={handleClose} lookupData={lookupData} fetchTableApi={fetchTableApi} user={user} />
        </ConfirmationDialog>

        <ConfirmationDialog
          open={isUserDelete}
          handleClose={handleClose}
          handleCancel={handleCloseDelete}
          handleSubmit={handleDelete}
          title={"Deactivate User"}>
          <p>
            Are you sure you want to deactivate
            <b> {userTableData && userTableData[user]?.userName}</b>?
          </p>
        </ConfirmationDialog>

        <ConfirmationDialog
          open={isRestore}
          handleClose={handleClose}
          handleCancel={handleCloseRestore}
          handleSubmit={handleRestore}
          title={"Restore User"}>
          <p>
            Are you sure you want to restore
            <b> {userTableData && userTableData[user]?.userName}</b>?
          </p>
        </ConfirmationDialog>
        <div className="user-table">
          <MuiThemeProvider theme={getMuiTheme()}>
            <div className=" payplan-table custom-table user-table-data">
              {/* <MUIDataTable data={userTableData || []} columns={columns} options={options} /> */}
              {isLoading ? (
                <SpinnerPage />
              ) : (
                <MaterialTable
                  aria-level="2"
                  localization={{}}
                  icons={tableIcons}
                  data={userTableData || []}
                  columns={columns}
                  options={options}
                  components={{
                    Pagination: (props) => {
                      return (
                        <div className="custom-pagination">
                          <div className="selectpageno">
                            <span className="mr-1">Rows Per Page:</span>
                            <Select
                              disableUnderline
                              variant="standard"
                              name="selectRows"
                              value={rowsPerPage}
                              onChange={(event) => {
                                setPage(0);
                                setRowsPerPage(event.target.value);
                              }}>
                              <MenuItem value={5}>5</MenuItem>
                              <MenuItem value={10}>10</MenuItem>
                              <MenuItem value={15}>15</MenuItem>
                              <MenuItem value={20}>20</MenuItem>
                            </Select>
                          </div>

                          <div className="nextprevious">
                            <FirstPage
                              className={page > 0 ? "arrow-icons" : "arrow-icons-disabled"}
                              onClick={(e) => {
                                if (page === 0) {
                                  e.preventDefault();
                                } else {
                                  setPage(0);
                                  handleRowChange(count);
                                }
                              }}
                            />
                            <ChevronLeft
                              className={page > 0 ? "arrow-icons" : "arrow-icons-disabled"}
                              onClick={(e) => {
                                if (page === 0) {
                                  e.preventDefault();
                                } else {
                                  setPage(page - 1);
                                  handleRowChange(count);
                                }
                              }}
                            />

                            <span>
                              <span>{fromPage}</span>-<span className="mr-1">{toPage}</span>
                              of
                              <span className="ml-1">{count ? count : ""}</span>
                            </span>
                            <ChevronRight
                              className={page >= noOfPages ? "arrow-icons-disabled" : "arrow-icons"}
                              onClick={(e) => {
                                if (page >= noOfPages) {
                                  e.preventDefault();
                                } else {
                                  setPage(page + 1);
                                  handleRowChange(count);
                                }
                              }}
                            />
                            <LastPage
                              className={page >= noOfPages ? "arrow-icons-disabled" : "arrow-icons"}
                              onClick={(e) => {
                                if (page >= noOfPages) {
                                  e.preventDefault();
                                } else {
                                  setPage(noOfPages);
                                  handleRowChange(count);
                                }
                              }}
                            />
                          </div>
                        </div>
                      );
                    },
                  }}
                />
              )}
            </div>
          </MuiThemeProvider>
        </div>
      </React.Fragment>
    </I18nextProvider>
  );
};

export default AdminUserManagementComponent;
