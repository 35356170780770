/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import MonthlyForm from "./forms/MonthlyForm";
import WeeklyForm from "./forms/WeeklyForm";
import BiWeeklyForm from "./forms/BiWeeklyForm";
import CustomizedRadio from "../../../components/CustomizedRadio";
import PayPlanTransactions from "./payplanTransactions";
import hospital1 from "../../../assets/images/hospital1.png";
import HospitalBillDetails from "./hospitalBillDetails";
import { useLocation, useHistory } from "react-router";
import GetAppIcon from "@material-ui/icons/GetApp";
import { MenuItem } from "@material-ui/core";
import { requestInfo_PP } from "../../../Common/CommonFn";
import { payplanService } from "../../../services/payplanService";
import { fetchCreditCardData } from "../../../action/pp_PaymentCreditCardAction";
import { getCreditCardDataList, getCreditCardDataListPending } from "../../../reducer/pp_PaymentCreditCardReducer";
import { geteChequePaymentList } from "../../../reducer/pp_eChequePaymentReducer";
import { addEchaqueData, fetchEchaqueData, getCountryData } from "../../../action/pp_eChequePaymentAction";
import { fetchPatientDetailData } from "../../../action/patientDetailReport";
import { getPatientlList } from "../../../reducer/Admin_patientDataReducer";
import HeaderQP from "../../../components/admin_header/headerQP";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SpinnerPage from "../../../Common/PP_loader";
import { handleSuccess, ToastContainer, handleError, handleWarning } from "../../../../src/components/Notifications/Toast";
import Admin_Footer from "../../../components/admin_footer/footer";
import LegalForm from "./forms/LegalForm";
import index from "./../Billing/index";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";

const mapStateToProps = (state) => ({
  patientData: getPatientlList(state.patientDetailReducer),
  creditCardData: getCreditCardDataList(state.paymentCreditCardReducer),
  eChequeData: geteChequePaymentList(state.eChequePaymentReducer),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPatientDetailData: fetchPatientDetailData,
      fetchCreditCardData: fetchCreditCardData,
      fetchEchaqueData: fetchEchaqueData,
      addEchaqueData: addEchaqueData,
    },
    dispatch,
  );
const connectFunction = connect(mapStateToProps, mapDispatchToProps);
const PayPlanOptions = connectFunction((props) => {
  const history = useHistory();
  const [plan, setPlan] = useState("Pay Weekly");
  const location = useLocation();
  const [isLegal, setIsLegal] = useState(false);
  const [overRide, setOverRide] = useState(false);
  const [paymentAmt, setPaymentAmt] = useState({});
  const [success, setSuccess] = useState(false);
  const [loader, setLoader] = useState(true);
  const [seedAccNum, setSeedAccNum] = useState("");
  const [weeklyInstallments, setWeeklyInstallments] = useState([]);
  const [biWeeklyInstallments, setBiWeeklyInstallments] = useState([]);
  const [monthlyInstallments, setMonthlyInstallments] = useState([]);
  const [planEligibility, setPlanEligibility] = useState("");
  const [noOfDays, setNoOfDays] = useState(0);
  const [paymentTransactionData, setPaymentTransactionData] = useState({});
  const [dialogopen, setDialogOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [balance, setBalance] = useState(0);
  const [minPayment, setMinPayment] = useState(0);
  const [fetchPlan, setFetchPlan] = useState(null);
  const [showForm, setShowForm] = useState(true);
  const page = location?.state?.page;
  const ppValues = location?.state?.ppValues;
  const instrumentId = location?.state?.instrumentId;
  const frequency = location?.state?.frequencyType;
  const isEcheck = location?.state?.eCheck;
  const { fetchCreditCardData, fetchEchaqueData } = props;
  const { creditCardData, eChequeData } = props;
  const [openSettlementOption, setOpenSettlementOption] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const contactus = {
    cursor: "pointer",
    textDecoration: "underline",
  };
  const hospitalName = location?.state?.hospitalName;
  const handleChange = (event) => {
    setPlan(event.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let queryString = window.location.search.split("?");
    let [, seedaccNum] = queryString;

    if (!seedaccNum?.includes("planId")) {
      setSeedAccNum(seedaccNum);

      let PassValues = {
        requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),

        seedAccountNumber: seedAccNum || seedaccNum,
      };
      payplanService.getPayPlan(PassValues, 1212, (res) => {
        if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
          const {
            oustandingBalance,
            biweeklyPayments,
            monthlyPayments,
            weeklyPayments,
            minimumPayment,
            eligible,
            noOfDays,
            isLegal,
          } = res?.data;
          setBalance(oustandingBalance);
          setWeeklyInstallments(weeklyPayments);
          setBiWeeklyInstallments(biweeklyPayments);
          setMonthlyInstallments(monthlyPayments);
          setMinPayment(minimumPayment);
          setPlanEligibility(eligible);
          setNoOfDays(noOfDays);
          setIsLegal(isLegal);
        } else {
          history.goBack();
        }
      });
    } else {
      setIsLoader(true);
      let plan = seedaccNum.split("=")[1];

      payplanService.getSinglePayPlanDetails(
        requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        plan,
        1234,
        (res) => {
          if (res?.status === 200 && res?.data?.statusCodeValue === 200) {
            if (res?.data?.body?.recurringPayments[0]?.isOverride === "true") {
              setOverRide(true);
            }
            setFetchPlan(res?.data?.body?.recurringPayments[0]);
            setBalance(res?.data?.body?.recurringPayments[0].scheduledAmount);
            setWeeklyInstallments(52);
            setBiWeeklyInstallments(26);
            setMonthlyInstallments(12);
            setNoOfDays(7);
            setSeedAccNum(res?.data?.body?.recurringPayments[0]?.masterAccountReferenceNumber);
            setIsLoader(false);
          } else {
            setIsLoader(false);
            handleWarning("Something went wrong!");
            history.goBack();
          }
        },
      );
    }
  }, [history, seedAccNum]);
  // Can be used later if we dont find creditcards...
  useEffect(() => {
    var userpartyinfo = JSON.parse(sessionStorage.getItem("verifypayer"));
    if (userpartyinfo !== null) {
      let CardPassValues = {
        requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        paymentInfoSearch: {
          paymentMethodId: "75001",
          partyId: userpartyinfo.partyId,
        },
      };

      let EChequePassValues = {
        requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        paymentInfoSearch: {
          paymentMethodId: 75003,
          partyId: userpartyinfo.partyId,
        },
      };

      fetchCreditCardData(CardPassValues, 1234);
      fetchEchaqueData(EChequePassValues, 1234);
    }
  }, [fetchCreditCardData, fetchEchaqueData]);
  useEffect(() => {
    document.title = page === "quickPay" ? "MED-1 Quick Pay - Pay Plan Options" : "MED-1 Secure Login - Pay Plan Options";
    setLoader(true);
    setTimeout(() => setLoader(false), 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (frequency || fetchPlan) {
      if (frequency === "Weekly" || fetchPlan?.recurringPaymentType === "Weekly") {
        setPlan("Pay Weekly");
      } else if (frequency === "Bi-Weekly" || fetchPlan?.recurringPaymentType === "Bi-Weekly") {
        setPlan("Pay Bi-Weekly");
      } else if (frequency === "Monthly" || fetchPlan?.recurringPaymentType === "Monthly") {
        setPlan("Pay Monthly");
      }
    }
  }, [frequency, fetchPlan]);

  const handleFrequency = () => {
    return plan === "Pay Weekly" ? "Weekly" : plan === "Pay Bi-Weekly" ? "Bi-Weekly" : "Monthly";
  };

  const [creditCardValue, setCreditCardValue] = useState("");
  useEffect(() => {
    if (instrumentId) {
      if (isEcheck) {
        const eCheckFilter =
          eChequeData &&
          eChequeData.achAccount &&
          eChequeData.achAccount.length > 0 &&
          eChequeData.achAccount.filter((item) => item.paymentInstrument?.paymentInstrumentId === instrumentId);
        if (eCheckFilter && eCheckFilter.length > 0) {
          return setCreditCardValue(
            `echeque&${eCheckFilter[0].accountNumber}?${eCheckFilter[0].accountType}/${eCheckFilter[0].bankAchRoutingCode}$${eCheckFilter[0].paymentInstrument?.paymentInstrumentId}`,
          );
        }
      } else {
        const filteredMethod =
          creditCardData &&
          creditCardData.cardAccount &&
          creditCardData.cardAccount.length > 0 &&
          creditCardData.cardAccount.filter((item) => item.paymentInstrument?.paymentInstrumentId === instrumentId);
        if (filteredMethod && filteredMethod.length > 0) {
          return setCreditCardValue(
            `${filteredMethod[0].cardNumber}?${filteredMethod[0].cardExpiryMonth}/${filteredMethod[0].cardExpiryYear}$${filteredMethod[0].paymentInstrument?.paymentInstrumentId}`,
          );
        }
      }
    }
  }, [instrumentId, creditCardData, eChequeData, isEcheck]);
  const getCardListData = (data) => {
    const visapattern = /^4[0-9]{12}(?:[0-9]{3})?$/;
    const masterpattern = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/;
    const amexpattern = /^3[47][0-9]{13}$/;

    return (
      data &&
      data.cardAccount &&
      data.cardAccount.length > 0 &&
      data.cardAccount.map((item, index) => {
        if (item.cardType == "Visa") {
          return (
            <MenuItem
              key={index}
              value={`${item.cardNumber}?${item.cardExpiryMonth}/${item.cardExpiryYear}$${item.paymentInstrument?.paymentInstrumentId}`}>
              Visa Card ending with{" "}
              {item.cardNumber.substring(item.cardNumber.toString().length - 4, item.cardNumber.toString().length)}
            </MenuItem>
          );
        } else if (item.cardType == "Master Card") {
          return (
            <MenuItem
              key={index}
              value={`${item.cardNumber}?${item.cardExpiryMonth}/${item.cardExpiryYear}$${item.paymentInstrument?.paymentInstrumentId}`}>
              Master Card ending with{" "}
              {item.cardNumber.substring(item.cardNumber.toString().length - 4, item.cardNumber.toString().length)}
            </MenuItem>
          );
        } else if (item.cardType == "American Express") {
          return (
            <MenuItem
              key={index}
              value={`${item.cardNumber}?${item.cardExpiryMonth}/${item.cardExpiryYear}$${item.paymentInstrument?.paymentInstrumentId}`}>
              American Express Card ending with{" "}
              {item.cardNumber.substring(item.cardNumber.toString().length - 4, item.cardNumber.toString().length)}
            </MenuItem>
          );
        } else {
          return "";
        }
      })
    );
  };
  const getEchecqueListData = (data) => {
    return (
      data &&
      data.achAccount &&
      data.achAccount.length > 0 &&
      data.achAccount.map((item, index) => {
        return (
          <MenuItem
            key={index}
            value={`echeque&${item.accountNumber}?${item.accountType}/${item.bankAchRoutingCode}$${item.paymentInstrument.paymentInstrumentId}`}>
            eCheck ending with{" "}
            {item.accountNumber.substring(item.accountNumber.toString().length - 4, item.accountNumber.toString().length)}
          </MenuItem>
        );
      })
    );
  };
  const validateStartDate = () => {
    let originalDate = document.querySelectorAll(".react-date-picker__inputGroup > input")[0].value;
    let orgYear = originalDate.split("-")[0];
    let orgMonth = originalDate.split("-")[1];
    let orgDay = originalDate.split("-")[2];

    let month =
      document.querySelectorAll(".react-date-picker__inputGroup > input")[1].value < 10
        ? "0" + document.querySelectorAll(".react-date-picker__inputGroup > input")[1].value
        : document.querySelectorAll(".react-date-picker__inputGroup > input")[1].value;

    let day =
      document.querySelectorAll(".react-date-picker__inputGroup > input")[2].value < 10
        ? "0" + document.querySelectorAll(".react-date-picker__inputGroup > input")[2].value
        : document.querySelectorAll(".react-date-picker__inputGroup > input")[2].value;

    let year = document.querySelectorAll(".react-date-picker__inputGroup > input")[3].value;

    const myInstallmentDate = year + "-" + month + "-" + day;
    return orgYear !== year || orgMonth !== month || orgDay !== day;
  };
  const isUser = localStorage.getItem("role") === "user";
  const isPA = localStorage.getItem("role") === "PA";

  const hospitalsInfo = {
    hospitalName: "Community Health Network",
    totalOutStandingAmount: "400",
  };

  const handleSettleNow = () => {
    if (isUser) {
      history.push({
        pathname: "/home/payment-info",
        state: { hospitalsInfo, amount: "$ 320.00", discountAmount: "$ 80", payNow: true },
      });
    } else if (isPA) {
      history.push({
        pathname: "/pa/payment-info",
        state: { hospitalsInfo, amount: "$ 320.00", discountAmount: "$ 80", payNow: true },
      });
    }
  };
  const partyids = JSON.parse(sessionStorage.getItem("verifypayer"));
  let partyid = partyids.partyId;
  return (
    <>
      {window.location?.pathname === "/pay-plan-options" && <HeaderQP />}
      <div className="transaction-card pb-0" role="main">
        <div
          className={
            sessionStorage.getItem("currentUserRole") === "Customer"
              ? `main-container-card main-container-sm`
              : `main-container-card`
          }>
          <div role="heading" aria-level="1" className="heading-style mb-3">
            <h2 className="content-title heading-style mb-0 pl-0"> Payment Options</h2>
          </div>

          <div className="schedule-payments">
            <div className="amount-schedule">Schedule Payment</div>
            <div className="amount-schedule">
              Total Outstanding Amount:{" "}
              <span className="text-primary">
                ${" "}
                {balance?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </span>
            </div>
          </div>
          <div className="pay-plan-options schedule-pay-plan">
            <div className="d-flex justify-content-center">
              <FormControl component="fieldset" row>
                <RadioGroup name="plan" value={plan} onChange={handleChange}>
                  <div className="radio-btns">
                    <div className="">
                      <FormControlLabel value="Pay Weekly" control={<CustomizedRadio />} label="Pay Weekly" />
                    </div>
                    <div className="">
                      <FormControlLabel value="Pay Bi-Weekly" control={<CustomizedRadio />} label="Pay Bi-Weekly" />
                    </div>
                    <div className="">
                      <FormControlLabel value="Pay Monthly" control={<CustomizedRadio />} label="Pay Monthly" />
                    </div>
                  </div>
                </RadioGroup>
              </FormControl>
              {/* </div> */}
            </div>
            {plan === "Pay Weekly" && !overRide ? (
              <WeeklyForm
                seedAccNum={seedAccNum}
                fetchPlan={fetchPlan}
                payplanService={payplanService}
                totalInstallments={weeklyInstallments}
                creditCardData={creditCardData}
                eChequeData={eChequeData}
                setSuccess={setSuccess}
                getCardListData={getCardListData}
                validateStartDate={validateStartDate}
                getEchecqueListData={getEchecqueListData}
                outstandingBalance={balance || 0}
                setPaymentTransactionData={setPaymentTransactionData}
                page={page}
                creditCardValue={frequency === "Weekly" && creditCardValue}
                ppValues={frequency === "Weekly" ? ppValues : {}}
                frequencyType="Weekly"
                partyid={partyid}
                day={noOfDays}
                disableSave={disableSave}
                setDisableSave={setDisableSave}
                setShowForm={setShowForm}
                isLegal={isLegal}
                setOverRide={setOverRide}
              />
            ) : plan === "Pay Bi-Weekly" && !overRide ? (
              <BiWeeklyForm
                seedAccNum={seedAccNum}
                fetchPlan={fetchPlan}
                payplanService={payplanService}
                totalInstallments={biWeeklyInstallments}
                validateStartDate={validateStartDate}
                creditCardData={creditCardData}
                eChequeData={eChequeData}
                setSuccess={setSuccess}
                getCardListData={getCardListData}
                getEchecqueListData={getEchecqueListData}
                outstandingBalance={balance || 0}
                setPaymentTransactionData={setPaymentTransactionData}
                page={page}
                creditCardValue={frequency === "Bi-Weekly" && creditCardValue}
                ppValues={frequency === "Bi-Weekly" ? ppValues : {}}
                frequencyType="Bi-Weekly"
                partyid={partyid}
                day={noOfDays}
                disableSave={disableSave}
                setDisableSave={setDisableSave}
                setShowForm={setShowForm}
                isLegal={isLegal}
                setOverRide={setOverRide}
              />
            ) : plan === "Pay Monthly" && !overRide ? (
              <MonthlyForm
                seedAccNum={seedAccNum}
                fetchPlan={fetchPlan}
                payplanService={payplanService}
                totalInstallments={monthlyInstallments}
                creditCardData={creditCardData}
                eChequeData={eChequeData}
                setSuccess={setSuccess}
                getCardListData={getCardListData}
                validateStartDate={validateStartDate}
                getEchecqueListData={getEchecqueListData}
                outstandingBalance={balance || 0}
                setPaymentTransactionData={setPaymentTransactionData}
                page={page}
                creditCardValue={frequency === "Monthly" && creditCardValue}
                ppValues={frequency === "Monthly" ? ppValues : {}}
                frequencyType="Monthly"
                partyid={partyid}
                day={noOfDays}
                disableSave={disableSave}
                setDisableSave={setDisableSave}
                setShowForm={setShowForm}
                isLegal={isLegal}
                setOverRide={setOverRide}
              />
            ) : overRide ? (
              <LegalForm
                seedAccNum={seedAccNum}
                fetchPlan={fetchPlan}
                setOverRide={setOverRide}
                payplanService={payplanService}
                validateStartDate={validateStartDate}
                totalInstallments={weeklyInstallments}
                creditCardData={creditCardData}
                eChequeData={eChequeData}
                isEditPlan={location?.state?.edit}
                setSuccess={setSuccess}
                getCardListData={getCardListData}
                setPlan={plan}
                getEchecqueListData={getEchecqueListData}
                outstandingBalance={balance || 0}
                setPaymentTransactionData={setPaymentTransactionData}
                page={page}
                creditCardValue={frequency === handleFrequency() && creditCardValue}
                ppValues={frequency === handleFrequency() ? ppValues : {}}
                frequencyType={handleFrequency()}
                partyid={partyid}
                day={noOfDays}
                disableSave={disableSave}
                setDisableSave={setDisableSave}
                setShowForm={setShowForm}
              />
            ) : null}
          </div>
        </div>

        <div div className="footer-section-cp ml-0 mt-2">
          {window.location?.pathname === "/pay-plan-options" && <Admin_Footer />}
        </div>
      </div>
      {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
      {loader || isLoader ? <SpinnerPage /> : null}
    </>
  );
});

export default PayPlanOptions;
