import axios from "axios";
import { ApiUrl } from "./getUrl";

const defaultHeaders = {
  Accept: "application/json",
  ApplicationId: "11102",
  subBuId: "0",
  Environment: "dev",
  issuer: "epi",
  "Content-Type": "application/json",
  buId: 2,
  userId: "1234", // need to remove after checking cp api services
  userlogin: "mayur@gmail.com",
};

export const requestInfo = () => {
  return {
    applicationId: "11102", // fixed 11102 for PP
    buId: "2",
    // "buId": sessionStorage.getItem("buid"),
    subBuId: "0",
    action: "",
    issuer: "epi",
    userlogin: "shantesh@privacydatasystems.com",
    environment: "dev",
    userId: "1234",
    token: sessionStorage.getItem("jwt-token"),
  };
};

function buildHeaders() {
  if (sessionStorage.getItem("jwt-token")) {
    const authToken = sessionStorage.getItem("jwt-token");
    return { ...defaultHeaders, token: authToken };
  }
  return { ...defaultHeaders };
}

// eslint-disable-next-line no-unused-vars
function authHeaders() {
  return { ...defaultHeaders };
}

// status check methods
export const checkData = (response) => {
  //   if (response.status >= 200 && response.status < 400) {
  //     return {
  //       status: response.data?.status,
  //       data: response.data?.response,
  //       message: response.data,
  //     };
  //   }
  return response;
};

// REST API methods with token

// export const httpGet = (url) => axios.get(url).then(checkData)

export const checkError = (error) => {
  if (error && error.response && [401, 403].includes(error.response.status)) {
    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
    window.localStorage.clear();
    window.location.reload();
  }
  return error.response;
};

export const httpGet = (serviceName, url) => {
  return axios(ApiUrl(serviceName, url), {
    method: "GET",
    headers: buildHeaders(),
  })
    .then(checkData)
    .catch(checkError);

  // .catch(err => checkError(err));
};
// export const httpPost = (url, body) => axios.post(url, body).then(checkData)

export const httpPost = (serviceName, url, body) => {
  return axios
    .post(ApiUrl(serviceName, url), body, {
      headers: buildHeaders(),
    })
    .then(checkData)
    .catch(checkError);
};

export const httpPut = (serviceName, url, body) => {
  return axios
    .put(ApiUrl(serviceName, url), body, {
      headers: buildHeaders(),
    })
    .then(checkData)
    .catch(checkError);
};

export const httpDelete = (serviceName, url) => {
  return axios
    .delete(ApiUrl(serviceName, url), {
      headers: buildHeaders(),
    })
    .then(checkData)
    .catch(checkError);
};
