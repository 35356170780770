import React from "react";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";

const redtitle = {
  color: "red",
  fontFamily: "poppinssemibold",
  marginBottom: "20px",
};

const bluetitle = {
  color: "#007bff",
  fontFamily: "poppinsbold",
  fontSize: "18px !important",
  marginBottom: "20px",
};

const blacktitle = {
  color: "black",
  fontFamily: "poppinsbold",
  fontSize: "18px !important",
};
const typographyStyles = {
  fontWeight: "700",
  display: "inline-block",
};
const iconStyles = {
  cursor: "pointer",
};
const PPTermsConditionsContent = ({ handleClose }) => {
  return (
    <>
      {/* -------------------- Terms & Conditions code starts here -----------------------------                 */}
      <>
        <div>
          <p className="redtitle" style={redtitle}>
            <Typography style={typographyStyles}>Online Payment Terms and Conditions</Typography>
          </p>
          <p className="blacktitle" style={bluetitle}>
            <Typography style={typographyStyles}>PLEASE READ AND AGREE TO THE FOLLOWING AUTHORIZATION</Typography>
          </p>
          <p className="bluetitle" style={blacktitle}>
            <Typography style={typographyStyles}>Authorization for Automated Clearinghouse (ACH) Debits</Typography>
          </p>
          <p>
            <Typography>
              By checking the box “Online Payment Terms and Conditions,” you authorize MED-1 Solutions, LLC to initiate,
              modify or terminate an electronic single entry or recurring (if applicable) ACH debit(bank draft) from the
              financial institution where the checking or savings account you designated is located for the purpose of making
              a payment on your outstanding account balance. The ACH will be for the amount you designated and will be
              submitted on the date(s) you designated.
            </Typography>
          </p>
          <p className="bluetitle" style={blacktitle}>
            <Typography style={typographyStyles}>Authorization for Credit Card Payments</Typography>
          </p>
          <p>
            <Typography>
              {" "}
              By checking the box “Online Payment Terms and Conditions,” you authorize MED-1 Solutions, LLC to initiate,
              modify or terminate an electronic single entry or recurring credit card payments from the credit card you
              designated for the purpose of making a payment on your outstanding account balance. The credit card payment
              will be for the amount you designate and will be submitted on the date(s) you designated.
            </Typography>
          </p>
          <p className="bluetitle" style={blacktitle}>
            <Typography style={typographyStyles}>Fees</Typography>
          </p>
          <p>
            <Typography>
              You are responsible for any fees charged by your financial institution related to any payments, transactions,
              or other use of this website.
            </Typography>
          </p>
          <p className="bluetitle" style={blacktitle}>
            <Typography style={typographyStyles}>Failed Payment</Typography>
          </p>
          <p>
            <Typography>
              If a designated payment fails for any reason, you remain obligated to timely pay any amount then due in
              accordance with the terms and conditions of your Agreement. You acknowledge that your bank may charge you fees
              if a payment is denied (e.g., due to insufficient funds in your bank account to satisfy the entire designated
              payment amount), in addition to any charges we may assess to your account under the terms and conditions of
              your Agreement and as allowed by law.
            </Typography>
          </p>
          <p className="bluetitle" style={blacktitle}>
            <Typography style={typographyStyles}>Payment Timing and Credit</Typography>
          </p>
          <p>
            <Typography>
              You may make payments electronically,via ACH or credit card, through our website (online payments)
            </Typography>
          </p>
          <p>
            <Typography>
              Any online payment that you authorize will be credited to your account on the day that you designate, so long
              as we receive your payment by 1:00 p.m. Eastern Standard Time (EST) on a business day (Monday through Friday,
              excluding Federal holidays). If we receive your request after 1:00 p.m. EST or on a non-business day, we will
              credit your payment as of the next business day. Once your online payment is made, it may take up to 2 business
              days for your electronic payment, and up to 4 business days for an ACH payment (bank draft) to be reflected in
              your account records, including online account services.{" "}
            </Typography>
          </p>
          <p className="bluetitle" style={blacktitle}>
            <Typography style={typographyStyles}>Amounts Due at Maturity</Typography>
          </p>
          <p>
            <Typography>
              If you enroll in recurring payments, the amount due at maturity to satisfy your outstanding account balance may
              vary, in an amount that is more or less than the amount that you designated through recurring payments. If it
              is less than (or equal to) the designated amount, we will debit the remaining account balance on the next
              designated payment date. If it is more than the designated amount, we will only debit the designated amount on
              the next designated payment date, leaving an outstanding balance on your account. You agree to make alternative
              payment arrangements to satisfy any outstanding account balance at maturity to avoid, as applicable, any
              additional fees and late charges. You acknowledge that a failure to make such arrangements will result in your
              continued enrollment in recurring payments until your outstanding account balance is satisfied
            </Typography>
          </p>
          <p className="bluetitle" style={blacktitle}>
            <Typography style={typographyStyles}>Payment Termination or Modification</Typography>
          </p>
          <p>
            <Typography>
              You may terminate or modify a single or recurring payment by calling us at 888.323.0811 in time for us to
              receive your request, at least 3 business days before the payment is scheduled to be made. Business day shall
              mean any day of the week that is not a Sunday or a Federal holiday. The end of each business day shall be 6:00
              p.m. Eastern Standard Time Monday through Friday and 1:00 p.m. Eastern Standard Time on Saturday.{" "}
            </Typography>
          </p>
          <p className="bluetitle" style={blacktitle}>
            <Typography style={typographyStyles}>Documentation</Typography>
          </p>
          <p>
            <Typography>
              You agree to retain a copy of this authorization and all transaction confirmations that we email you to the
              email address provided.
            </Typography>
          </p>
        </div>

        <DialogActions className="d-flex justify-content-end">
          <Button onClick={handleClose} variant="contained" className="themeing-buttons primary-button mx-1">
            Close
          </Button>
        </DialogActions>
      </>
    </>
  );
};

export default PPTermsConditionsContent;
