import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Med1Logo from "../../../assets/images/Med1Logo.png";
import { forgotPasswordData } from "../../../action/forgotPasswordAction";
import { Formik, Form, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { getIn } from "formik";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import RocketIcon from "../../../assets/images/quick-pay-rocket-icon.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  warning,
  error,
  success,
  errorNotification,
  successNotification,
  warningNotification,
} from "../../../components/Admin_Notification/Admin_Notification.js";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BasicTooltip } from "../../../components/ImageTooltip/index";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";
import { handleError, handleSuccess } from "../../../components/Notifications/Toast";

const intialvalues = {
  email: "",
};

const options = {
  autoClose: 3000,
  hideProgressBar: false,
  className: "toaster-text",
  toastId: "",
};
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
    };
    this.initialState = this.state;
  }
  _cleardata = () => {
    this.setState(this.intialState);
  };
  _handleSubmit = (values, actions) => {
    this.props.forgotPasswordData(
      { userLoginName: values.userName, subBuId: 1 },
      (res) => {
        if (res.data.status === "success" || res.data.status === "fail") {
          handleSuccess(
            "If the user account entered matches our records, a message would be sent to the account holder with instructions on completing the reset process.",
          );
          this._cleardata();
          setTimeout(() => {
            this.props.history.push("/admin");
          }, 3000);
        } else {
          handleError("Something wents wrong..! " + res.data.reasonText);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };
  render() {
    const schema = Yup.object().shape({
      userName: Yup.string().required("Please enter the Username").max(100, "Must be less than 100 characters"),
    });
    return (
      <div className="page-wrapper" role="main">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div>
              <div className="card-sm">
                <div className="header">
                  <div className="rocket-icon">
                    <img src={RocketIcon} alt="" />
                  </div>
                  <div className="logo">
                    <img src={Med1Logo} className="img-fluid" alt="Med-1 Solutions Logo" />
                  </div>
                </div>
                <div className="main-heading">
                  <h1>
                    Quick<span>Pay</span>
                  </h1>
                </div>
                <div className="login-content">
                  <Formik
                    initialValues={{
                      userName: this.state.userName ? this.state.userName : "",
                    }}
                    validationSchema={schema}
                    onSubmit={this._handleSubmit}>
                    {(props) => {
                      const { values, touched, error, handleChange, errors, setFieldValue, handleBlur } = props;
                      return (
                        <Form id="login-form" className="input-field" noValidate="noValidate">
                          <div className="form-group">
                            <div className="row m-0 justify-content-between align-items-center">
                              <label htmlFor="exampleInputPassword1" className="required">Username</label>
                              <Link to={"/contact-us"}>
                                <BasicTooltip placement="top" title="Need Help? Contact Us">
                                  <i className="fas fa-question-circle circle-icon"></i>
                                </BasicTooltip>
                              </Link>
                            </div>
                            <OutlinedInput
                              className="form-control"
                              id="exampleInputEmail1"
                              name="userName"
                              placeholder="Please Enter Username"
                              //   onChange={handleChange}
                              onChange={(event) => {
                                setFieldValue((values.userName = event.target.value));
                              }}
                              onBlur={handleBlur}
                              value={values["userName"]}
                              aria-label="text-field"
                              fullWidth
                              type="userName"
                              error={Boolean(getIn(touched, "userName") && getIn(errors, "userName"))}
                              InputLabelProps={{
                                shrink: true,
                              }}></OutlinedInput>
                            <ErrorMessage name="userName" className="text-danger new-text-danger" component="div" />
                          </div>
                          <div className=" mt-4 mb-5">
                            <button type="submit" className="primary-button btn-block">
                              Recover Password
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                  <div className="text-center">
                    <div className="more-info">
                      <p>
                        <Link to="/admin" aria-label="forgot-account">
                          Back To Login
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  // error: getloginuser(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      forgotPasswordData: forgotPasswordData,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
