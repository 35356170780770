import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import { useLocation, useHistory } from "react-router";
import { Button } from "@material-ui/core";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { echequePaymentService } from "../../../services/echequePaymentService";
import { requestInfo_PP } from "../../../Common/CommonFn";
import { DeletePayment } from "../PayPlanOptions/CancelPlan";
import ConfirmationDialog from "../../../components/ConfirmationDialog/index";
import { capitalize } from "../../../util/Captilize";

import { handleSuccess, ToastContainer, handleWarning, handleError } from "../../../components/Notifications/Toast";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiButtonBase-root": {
      backgroundColor: "#f3f3f3",
    },
    "& .MuiCollapse-wrapper": {
      backgroundColor: "#f3f3f3",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "50%",
    flexShrink: 0,
    textAlign: "left",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  accordionBtnechcek: {
    marginLeft: "8rem !important",
    backgroundColor: "#000",
    color: "#000",
  },
  deleteIcon: {
    outline: "none",
  },
  deleteBtn: {
    outline: "none",
  },
}));

const getEchequeDetails = (data) => {
  const firstName = data && data.billingAddress && data.billingAddress.firstName ? data.billingAddress.firstName + " " : "";
  const middleName =
    data && data.billingAddress && data.billingAddress.middleName ? data.billingAddress.middleName + " " : "";
  const lastName = data && data.billingAddress && data.billingAddress.lastName ? data.billingAddress.lastName + " " : "";
  const street =
    data && data.billingAddress && data.billingAddress.street
      ? data.billingAddress.street
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ") + ", "
      : "";
  const street2 =
    data && data.billingAddress && data.billingAddress.street2
      ? data.billingAddress.street2
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ") + ", "
      : "";
  const city =
    data && data.billingAddress && data.billingAddress.city
      ? data.billingAddress.city
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ") + ", "
      : "";
  const state =
    data && data.billingAddress && data.billingAddress.state
      ? data.billingAddress.state
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ") + ", "
      : "";
  const country =
    data && data.billingAddress && data.billingAddress.country
      ? data.billingAddress.country
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ") + ". "
      : "";
  const postalCode =
    data && data.billingAddress && data.billingAddress.postalCode ? data.billingAddress.postalCode + " " : "";
  const name = firstName + middleName + lastName || "N/A";
  const billingAddress = street + street2 + city + state + country + postalCode || "N/A";
  return (
    <div className="billing-address-cp">
      <div>
        {" "}
        <b>Name: </b> {capitalize(name)}
      </div>
      <div>
        {" "}
        <b>Billing Address: </b> {billingAddress}
      </div>
    </div>
  );
};

const EchequeDetailsAccordion = ({ eChequeData, showloader, getPaymentDetails }) => {
  const classes = useStyles();
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [dialogOpenEcheck, setDialogOpenEcheck] = useState(false);
  const [echeck, setECheck] = useState();
  const [eChequeList, setEchequeList] = useState(eChequeData);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setEchequeList(eChequeData);
  }, [eChequeData]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClose = () => {
    setDialogOpenEcheck(false);
  };

  const handleDeletePayment = (paymentMethod, paymentId, instrumentId) => {
    setLoader(true);
    handleClose();
    echequePaymentService.deletePaymentMethod(
      requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      paymentMethod,
      paymentId,
      instrumentId,
      1234,
      (res) => {
        if (res.status === 200 && res?.data?.body === "Deleted") {
          getPaymentDetails();
          handleSuccess(`Payment Method Deleted Successfully`);
          setTimeout(() => {
            sessionStorage.getItem("currentUserRole") === "Admin"
              ? history.push("/pa/payment-methods")
              : history.push("/home/payment-methods");
          }, 2000);

          handleClose();
          let passValues = {
            requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jti-token")),
            paymentInfoSearch: {
              paymentMethodId: 75003,
              partyId: JSON.parse(sessionStorage.getItem("verifypayer")).partyId,
            },
          };
          echequePaymentService.getEchequePayment(passValues, 1234, (res) => setEchequeList(res.data.paymentInfos[0]));
        } else if (res.status === 200) {
          setLoader(false);
          handleClose();
          handleWarning(res?.data?.body);
        } else {
          setLoader(false);
          handleWarning("Something went wrong!");
          handleClose();
        }
      },
    );
  };

  return (
    <div className={`${classes.root} custom-accordion`}>
      <ConfirmationDialog
        open={dialogOpenEcheck}
        handleClose={handleClose}
        handleSubmit={() => handleDeletePayment("75003", echeck.achAccountId, echeck.paymentInstrument?.paymentInstrumentId)}
        fullWidth={true}
        title="Are you sure you want to delete the Echeck">
        <DeletePayment
          paymentMethod={"Echeck"}
          card={echeck?.accountNumber?.toString().substring(echeck.accountNumber.length - 4, echeck.accountNumber.length)}
        />
      </ConfirmationDialog>

      {eChequeList &&
        eChequeList.achAccount &&
        eChequeList.achAccount.length > 0 &&
        eChequeList.achAccount.map((echeque, i) => (
          <Accordion
            className="paymentmethods-cp mb-2"
            expanded={expanded === "panel" + i}
            onChange={handleChange("panel" + i)}
            key={echeque.accountNumber + i}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
              <div className={`e-check-heading ${classes.heading}`}>
                <div className="card-name">
                  <span>
                    eCheck ending with Account Number{" "}
                    {echeque.accountNumber
                      .toString()
                      .substring(echeque.accountNumber.length - 4, echeque.accountNumber.length)}
                  </span>
                </div>
              </div>
              <div className="date-edit-delete-accordion e-check-icon">
                <Typography className={classes.secondaryHeading}>---</Typography>
                <div className="edit-delete-icons">
                  <IconButton
                    className={classes.deleteBtn}
                    onClick={() => {
                      setDialogOpenEcheck(true);
                      setECheck(echeque);
                    }}>
                    <DeleteIcon className={classes.deleteIcon} />
                  </IconButton>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails className="customer-details">
              <Typography>{getEchequeDetails(echeque)}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
};

export default EchequeDetailsAccordion;
