/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, createRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { I18nextProvider } from "react-i18next";
import SpinnerPage from "../../../../Common/PP_loader";
import { handleWarning, handleSuccess, handleError } from "../../../../components/Notifications/Toast";
import { MuiThemeProvider, FormControl, Select, MenuItem, TextField, Grid } from "@material-ui/core";
import { getMuiTheme } from "../../../../Common/ThemeFunctions";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { tableIcons } from "../../../../components/TableIcons";
import { httpPaginationRequest } from "../../../../Common/httpPaginationRequest";
import { useDispatch } from "react-redux";
import { Global_var } from "../../../../global/new _admin_globalvar";
import DialogSettlementDetails from "./Dialog";
import { Link } from "react-router-dom";
import i18n from "../../../../i18n";
import { settlementService } from "../../../../services/RPsettlementService";
import { requestInfo_PP } from "../../../../Common/CommonFn";
import PatchedPagination from "../../../../Common/TablePagination";
import { formatAmount } from "../../../../util/formatAmount";
import NumberFormat from "react-number-format";
const useStyles = makeStyles({
  tooltip: {
    fontSize: "1em",
  },
});
const AdminRpSettlementsComponent = ({ props }) => {
  const initialValue = {
    accountNumber: "",
    settlementStatus: "none",
    userName: "",
  };
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = createRef();
  const [isSubmit, setIsSubmit] = useState(false);
  const [arrayLast, setArrayLast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState(initialValue);
  const [dialogData, setDialogData] = useState({});
  const [notesData, setNotesData] = useState("");
  const [revisedAmount, setRevisedAmount] = useState(0);
  const [count, setCount] = useState(0);
  const [searchAccNo, setSearchAccNo] = useState(null);
  const [searchUsername, setsearchUsername] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchStatus, setsearchStatus] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  // const [paginationData, setPaginationData] = useState({
  //   pages: null,
  //   page: null,
  //   noOfPages: "",
  //   totalPages: "",
  // });
  const installments = [
    { key: "Pending", value: "Pending" },
    { key: "Approved", value: "Approved" },
    { key: "Rejected", value: "Rejected" },
    { key: "Revised", value: "Revised" },
    { key: "Settled", value: "Settled" },
  ];

  //   const emailConfirmationvalidation = Yup.object().shape({
  //     emailId: Yup.string()
  //       .test("oneOfRequired", "Email Address is required", function (item) {
  //         return this.parent.emailId || this.parent.userLoginName;
  //       })
  //       .nullable()
  //       .email("Please enter a valid Email Address"),
  //     userLoginName: Yup.string().test("oneOfRequired", " User Login Name is required", function (item) {
  //       return this.parent.emailId || this.parent.userLoginName;
  //     }),
  //   });
  const handleSettlementOptionProceed = () => {
    // sessionStorage.setItem("settlementOpted", 1);
    // this.setState({
    //   ...this.state,
    //   isSettlementOption: true,
    //   initialSettlementOption: false,
    // });
  };
  const handleSettlementAccept = (values) => {
    let passValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      settlementRequest: {
        accountNumber: dialogData.masterAccountReferenceNumber,
        proposedAmount: dialogData.proposedAmount,
        settledByPAid: JSON.parse(sessionStorage.getItem("userData")).partyId,
        notes: values.notes,
      },
    };

    settlementService.sendApprovedSettlement(
      passValues,
      0,
      (res) => {
        if (res?.data?.status === "SUCCESSFUL") {
          handleClose();
          const passValues = {
            requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
            settlementRequest: {
              accountNumber: searchAccNo ? searchAccNo : null,
              settlementStatus: searchStatus ? (searchAccNo ? null : searchStatus) : null,
              userName: searchUsername ? searchUsername : null,
            },
          };
          setIsUpdate(false);
          setIsUpdate(true);
          // settlementService.rpSettlementTable(passValues, (res) => {
          //   this.setState({ tableData: res.data.settlementResponseModel });
          // });
          handleSuccess(res?.data?.responseMessage?.[0] || res?.data?.status);
        } else {
          handleError(res?.data?.responseMessage?.[0] || res?.data?.status);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  const handleSettlementReject = (values) => {
    let passValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      settlementRequest: {
        accountNumber: dialogData.masterAccountReferenceNumber,
        settledByPAid: JSON.parse(sessionStorage.getItem("userData")).partyId,
        notes: values.notes,
      },
    };

    settlementService.sendRejectSettlement(
      passValues,
      0,
      (res) => {
        if (res?.data?.status === "SUCCESSFUL") {
          handleClose();
          const passValues = {
            requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
            settlementRequest: {
              accountNumber: searchAccNo ? searchAccNo : null,
              settlementStatus: searchStatus ? (searchAccNo ? null : searchStatus) : null,
              userName: searchUsername ? searchUsername : null,
            },
          };
          setIsUpdate(false);
          setIsUpdate(true);
          // settlementService.rpSettlementTable(passValues, (res) => {
          //   this.setState({ tableData: res.data.settlementResponseModel });
          // });

          handleSuccess(res?.data?.responseMessage?.[0] || res?.data?.status);
        } else {
          handleError(res?.data?.responseMessage?.[0] || res?.data?.status);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  const handleSettlementRevise = (values) => {
    let passValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      settlementRequest: {
        accountNumber: dialogData?.masterAccountReferenceNumber,
        revisedAmount: values.revisedAmount,
        settledByPAid: JSON.parse(sessionStorage.getItem("userData")).partyId,
        notes: values.notes,
      },
    };

    settlementService.sendRevisedSettlement(
      passValues,
      0,
      (res) => {
        if (res?.data?.status === "SUCCESSFUL") {
          handleClose();
          const passValues = {
            requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
            settlementRequest: {
              accountNumber: searchAccNo ? searchAccNo : null,
              settlementStatus: searchStatus ? (searchAccNo ? null : searchStatus) : null,
              userName: searchUsername ? searchUsername : null,
            },
          };
          setIsUpdate(false);
          setIsUpdate(true);
          // settlementService.rpSettlementTable(passValues, (res) => {
          //   this.setState({ tableData: res.data.settlementResponseModel });
          // });
          handleSuccess(res?.data?.responseMessage?.[0] || res?.data?.status);
        } else {
          handleError(res?.data?.responseMessage?.[0] || res?.data?.status);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  const updateRevisedAmount = (amt) => {
    setRevisedAmount(amt);
  };
  const updatedNotes = (note) => {
    setNotesData(note);
  };
  const handleSubmit = (values) => {
    if (values.userName || values.accountNumber || values.settlementStatus !== "none") {
      setIsSubmit(true);
      setSearchData(values);
    } else {
      handleWarning("Please fill one of the fields");
    }
  };
  const handleClose = () => {
    setDialogOpen(false);
  };
  const columns = [
    {
      title: "Seed Account Number",
      field: "masterAccountReferenceNumber",
      render: (rowData) => {
        return (
          <Link
            onClick={() => {
              setDialogOpen(true);
              setDialogData(rowData);
            }}>
            {rowData.masterAccountReferenceNumber}
          </Link>
        );
      },
    },
    {
      title: "Hospital",
      field: "hospital",
      options: {
        sort: false,
      },
    },
    {
      title: "Outstanding Amount",
      field: "outstandingBalance",
      cellStyle: { textAlign: "right" },
      headerStyle: { textAlign: "right" },
      options: {
        sort: false,
      },
      render: (rowData) => {
        return rowData.outstandingBalance ? formatAmount(rowData.outstandingBalance) : <div className="text-center">-</div>;
      },
    },
    {
      title: "RP Proposed Amount",
      field: "proposedAmount",
      cellStyle: { textAlign: "right" },
      headerStyle: { textAlign: "right" },
      options: {
        sort: false,
      },
      render: (rowData) => {
        return rowData.proposedAmount ? formatAmount(rowData.proposedAmount) : <div className="text-center">-</div>;
      },
    },
    {
      title: "Revised Amount",
      field: "revisedAmount",
      cellStyle: { textAlign: "right" },
      headerStyle: { textAlign: "right" },
      options: {
        sort: false,
      },
      render: (rowData) => {
        return rowData.revisedAmount ? formatAmount(rowData.revisedAmount) : <div className="text-center">-</div>;
      },
    },
    {
      name: "rpProposedDate",
      title: "RP Proposed Date",
      field: "rpProposedDate",
      options: {
        sort: false,
      },
    },
    {
      name: "notes",
      title: "RP Notes",
      field: "notes",
      options: {
        sort: false,
      },
    },
    {
      name: "settlementStatus",
      title: "Status",
      field: "settlementStatus",
      options: {
        sort: false,
      },
    },
    {
      title: "Username",
      field: "userName",
      options: {
        sort: false,
      },
      render: (rowData) => {
        return rowData.userName ? rowData.userName : <div className="text-center">-</div>;
      },
    },
  ];
  useEffect(() => {
    if (tableRef?.current && isUpdate) {
      tableRef.current.onQueryChange();
    }
  }, [isUpdate]);

  useEffect(() => {
    if (tableRef?.current && isSubmit) {
      tableRef.current.onQueryChange();
    }
  }, [searchData]);
  return (
    <I18nextProvider i18n={i18n}>
      <React.Fragment>
        <div>
          <Formik
            enableReinitialize
            initialValues={initialValue}
            // validationSchema={emailConfirmationvalidation}
            onSubmit={handleSubmit}>
            {({ touched, errors, setFieldValue, values, resetForm, handleChange }) => (
              <Form className="input-field" noValidate="noValidate">
                <div className="form-row">
                  <div className="col-xl-4 col-lg-4 col-sm-6 col-md-6 form-group">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <label htmlFor="userName" className="form-label w-100">
                        User Name
                      </label>
                      <Grid container className="date-picker">
                        <TextField
                          name="userName"
                          id="userName"
                          placeholder="Enter User Name"
                          aria-label="text-field"
                          fullWidth
                          value={values.userName}
                          onChange={(event) => {
                            setFieldValue("userName", event.target.value);
                            setsearchUsername(event.target.value);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </FormControl>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-6 col-md-6 form-group">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <label htmlFor="accountNumber" className="form-label w-100">
                        Seed Account Number
                      </label>
                      <Grid container className="date-picker">
                        <TextField
                          id="accountNumber"
                          name="accountNumber"
                          type="text"
                          placeholder="Enter Seed Account Number"
                          onChange={(event) => {
                            setFieldValue("accountNumber", event.target.value);
                            setSearchAccNo(event.target.value);
                          }}
                          value={values.accountNumber}
                          aria-label="text-field"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            inputComponent: NumberFormat,
                          }}></TextField>
                      </Grid>
                    </FormControl>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-6 col-md-6 form-group ">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <label htmlFor="settlementStatus" className="form-label">
                        Settlement Status
                      </label>
                      <Select
                        fullWidth
                        name="settlementStatus"
                        className="form-select form-control"
                        onChange={(e) => {
                          setFieldValue("settlementStatus", e.target.value);
                          setsearchStatus(e.target.value);
                        }}
                        value={values["settlementStatus"]}>
                        <MenuItem value="none" key="none" disabled>
                          Select Settlement Status
                        </MenuItem>
                        {installments.map((option) => {
                          return (
                            <MenuItem value={option.value} key={option.key}>
                              {option.value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="payment-history-btns mt-2">
                  <button className="primary-button themeing-buttons" type={"submit"}>
                    Search
                  </button>
                  <button
                    className="secondary-button mx-2"
                    type="button"
                    onClick={() => {
                      setIsSubmit(true);
                      setSearchData({
                        accountNumber: "",
                        userName: "",
                        settlementStatus: "none",
                      });
                      resetForm({
                        values: {
                          accountNumber: "",
                          userName: "",
                          settlementStatus: "none",
                        },
                      });
                    }}>
                    Reset
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <DialogSettlementDetails
          open2={dialogOpen}
          title={"Settlements"}
          dialogClose2={handleClose}
          data={dialogData}
          handleSettlementRevise={handleSettlementRevise}
          handleSettlementReject={handleSettlementReject}
          handleSettlementAccept={handleSettlementAccept}
          handleSettlementOptionProceed={handleSettlementOptionProceed}
          updateRevisedAmount={updateRevisedAmount}
          setNotesData={setNotesData}
          notesData={notesData}
        />
        {/* </ConfirmationDialog> */}
        <div className="user-table">
          <MuiThemeProvider theme={getMuiTheme()}>
            <div className=" payplan-table custom-table user-table-data">
              {isLoading ? (
                <SpinnerPage />
              ) : (
                <MaterialTable
                  title=""
                  localization={{
                    pagination: {
                      firstAriaLabel: "First Page",
                      previousAriaLabel: "Previous Page",
                      nextAriaLabel: "Next Page",
                      lastAriaLabel: "Last Page",
                    },
                  }}
                  columns={columns}
                  icons={tableIcons}
                  tableRef={tableRef}
                  data={(query) =>
                    new Promise((resolve) => {
                      const bodyObj = {
                        requestInfo: requestInfo_PP(
                          sessionStorage.getItem("jwt-token"),
                          sessionStorage.getItem("jwt-token"),
                        ),
                        limit: query.pageSize ? query.pageSize : null,
                        orderBy: "",
                        orderDirection: "",
                        page:
                          isSubmit || isUpdate
                            ? 0
                            : arrayLast && query?.page >= 0
                            ? query.page
                            : arrayLast
                            ? (query.page <= 0 && query.page + 1) || query.page - 1
                            : query.page,
                        settlementRequest: {
                          accountNumber: searchData.accountNumber ? searchData.accountNumber : null,
                          settlementStatus:
                            searchData?.settlementStatus !== "none" ? searchData?.settlementStatus?.toUpperCase() : null,
                          userName: searchData.userName ? searchData.userName : null,
                        },
                      };
                      httpPaginationRequest(Global_var.URL_RP_SETTLEMENT_TABLE, bodyObj, (res) => {
                        setIsSubmit(false);
                        if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
                          const data = res?.data?.settlementResponseModel;
                          resolve({
                            data: data.length > 0 ? data : [],
                            page: res.data.page,
                            totalCount: res?.data?.count,
                          });
                          setArrayLast(data.length === 1);
                        } else if (res.data?.responseInfo?.status === "FAILED") {
                          handleError(res?.data?.responseInfo?.responseMessage[0]);
                          resolve({
                            data: [],
                            page: 0,
                            totalCount: 5,
                          });
                        } else {
                          resolve({
                            data: [],
                            page: 0,
                            totalCount: 5,
                          });
                          dispatch({
                            type: "FETCH_ERROR",
                            payload: res && res.message ? res.message : null,
                          });
                        }
                      });
                    })
                  }
                  options={{
                    selection: false,
                    sorting: false,
                    search: true,
                    emptyRowsWhenPaging: false,
                    rowsPerPage: 5,
                    rowsPerPageOptions: [5, 10, 20, 30],
                    onChangeRowsPerPage: () => {
                      return 5;
                    },
                    pagination: {
                      next: "Next",
                      previous: "Previous",
                      rowsPerPage: "Rows per page:",
                      displayRows: "of",
                    },
                  }}
                  components={{
                    Pagination: PatchedPagination,
                  }}
                />
              )}
            </div>
          </MuiThemeProvider>
        </div>
        {/* <ToastContainer /> */}
      </React.Fragment>
    </I18nextProvider>
  );
};

export default AdminRpSettlementsComponent;
