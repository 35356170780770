import React, { useState, createRef, useEffect, useRef } from "react";
import MaterialTable from "material-table";
import { tableIcons } from "../../../../../components/TableIcons";
import { handleError } from "../../../../../components/Notifications/Toast";
import moment from "moment";
import PatchedPagination from "../../../../../Common/TablePagination";
import { httpPaginationRequest } from "../../../../../Common/httpPaginationRequest";
import { Global_var } from "../../../../../global/new _admin_globalvar";
import { Tooltip } from "@material-ui/core";
import { capitalize } from "../../../../../util/Captilize";

const CommunicationTable = ({ searchData, isSubmit, setIsSubmit, setCommunicationsByReportData }) => {
  const tableRef = createRef();
  const page = useRef(null);
  const [rows, setRows] = useState(5);
  const isInitialRender = useRef(false);
  useEffect(() => {
    isInitialRender.current = true;
  }, []);

  useEffect(() => {
    if (tableRef?.current && isSubmit) {
      if (searchData && Object.keys(searchData).length > 0 && isInitialRender.current === false) {
        tableRef.current.onQueryChange();
      }
    }
    if (isSubmit && isInitialRender.current) {
      isInitialRender.current = false;
    }
  }, [searchData, isSubmit]);
  const columns = [
    {
      title: "Seed Account Number",
      field: "seedAccountNumber",
      type: "numeric",
    },
    {
      title: "First Name",
      field: "firstName",
      render: (rowData) => (rowData.firstName ? capitalize(rowData.firstName) : ""),
    },
    {
      title: "Last Name",
      field: "lastName",
      render: (rowData) => (rowData.lastName ? capitalize(rowData.lastName) : ""),
    },
    {
      title: "Letter Code",
      field: "letterCode",
      type: "numeric",
    },
    {
      title: "Last Sent Date",
      field: "lastModifiedDate",
    },
    {
      title: "Letter Description",
      field: "letterDescription",
    },
    {
      title: "Is Duff Email",
      field: "isDuffEmail",
      render: (rowData) => {
        return <div>{rowData?.isDuffEmail === false ? "False" : "True"}</div>;
      },
    },
    {
      title: "Is Secure Email",
      field: "isSecureEmail",
      render: (rowData) => {
        return <div>{rowData?.isSecureEmail === false ? "False" : "True"}</div>;
      },
    },
    {
      title: "Is SMS",
      field: "isSms",
      render: (rowData) => {
        return <div>{rowData?.isSms === false ? "False" : "True"}</div>;
      },
    },
    {
      title: "Letter Data",
      field: "letterData",
      render: (rowData) => {
        return (
          <>
            <Tooltip title={rowData?.letterData} placement="top-start" className="comments-popover">
              <p className="survey-commentsColumn">
                {rowData ? rowData?.letterData?.substring(0, 30) : null}
                {rowData?.letterData?.length >= 30 ? "..." : ""}
              </p>
            </Tooltip>
          </>
        );
      },
    },
  ];
  return (
    <MaterialTable
      title=""
      localization={{
        pagination: {
          firstAriaLabel: "First Page",
          previousAriaLabel: "Previous Page",
          nextAriaLabel: "Next Page",
          lastAriaLabel: "Last Page",
        },
      }}
      columns={columns}
      icons={tableIcons}
      tableRef={tableRef}
      data={(query) =>
        new Promise((resolve) => {
          if (!searchData) {
            resolve({
              data: [],
              page: 0,
              totalCount: 0,
            });
          } else {
            page.current = isSubmit ? 0 : query.page;
            const passValues = {
              firstName: searchData.firstName,
              lastName: searchData.lastName,
              seedAccountReferenceNumber: searchData.seedAccountReferenceNumber,
              letterCode: searchData.letterCode ? Number(searchData.letterCode) : 0,
              pageNo: isSubmit ? 0 : query.page,
              pageSize: query.pageSize,
              startDate: searchData?.startDate ? moment(searchData?.startDate).format("YYYY-MM-DD") : "",
              endDate: searchData?.endDate ? moment(searchData?.endDate).format("YYYY-MM-DD") : "",
            };
            httpPaginationRequest(Global_var.URL_COMMUNICATION_BY_REPORT, passValues, (res) => {
              const data = res?.data?.communications;
              if (res.data?.responseInfo?.status === "SUCCESSFUL" && data) {
                setIsSubmit(false);
                setCommunicationsByReportData(data.length > 0 ? data : []);
                resolve({
                  data: data.length > 0 ? data : [],
                  page: page.current,
                  totalCount: Number(res.data.totalCount),
                });
                // setArrayLast(data.length === 1);
              } else {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0,
                });
                handleError(res?.data?.responseInfo?.responseMessage[0]);
              }
            });
          }
        })
      }
      options={{
        selection: false,
        search: true,
        emptyRowsWhenPaging: false,
        sorting: false,
        pageSize: rows,
        pageSizeOptions: [5, 10, 20],
      }}
      onChangeRowsPerPage={(params) => {
        setRows(params);
      }}
      components={{
        Pagination: PatchedPagination,
      }}
    />
  );
};

export default CommunicationTable;
