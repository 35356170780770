/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Alert from "@material-ui/lab/Alert";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { requestInfo_PP } from "../../../Common/CommonFn";
import { settlementService } from "../../../services/settlementServices";
import {
  handleWarning,
  ToastContainer,
  handleError,
  handleSuccess,
  handleInfo,
} from "../../../components/Notifications/Toast";
import { OutstandingPayerService } from "../../../services/payerOutstandingService";
import * as Yup from "yup";
import HeaderQP from "../../../components/admin_header/headerQP";
import { identifyPayerService } from "../../../services/identifyPayerService";
// import UpdateTrackingPage from "containers/UpdateTrackingPage";

const SettlementPaymentOutstanding = (props) => {
  const [underQP, setUnderQP] = useState(() => JSON.parse(sessionStorage.getItem("underQuickPay")));

  const [seedItem, setSeedItem] = useState(() =>
    props.history?.location?.state?.settlementType ? props.history?.location?.state?.settlementType : [],
  );

  const [userData, setUserData] = useState({ email: "", phoneNo: "" });

  const getUserdetails = () => {
    let logindetails = JSON.parse(sessionStorage.getItem("loginDetails"));

    const PassValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      partySearch: {
        partyId: seedItem.bills?.[0]?.payer?.partyId ? seedItem.bills?.[0]?.payer?.partyId : "",
        limit: "1",
        offset: "0",
      },
    };

    const UserId = logindetails?.userId;
    identifyPayerService.getprofiledata(
      PassValues,
      UserId,
      (res) => {
        if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
          setUserData({
            email: res["data"]?.partyProfiles[0]?.contactEmail,
            phoneNo: res["data"]?.partyProfiles[0]?.contactNo,
          });
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  useEffect(() => {
    getUserdetails();
  }, []);

  const handleContinueSettlment = () => {
    // api for payment order
    //  alert("4")
    var order_FP_data = JSON.parse(sessionStorage.getItem("order_FP"));
    var order_FP_orderdetails = sessionStorage.getItem("accountdistribution_changeddata_sum")
      ? sessionStorage.getItem("accountdistribution_changeddata_sum")
      : sessionStorage.getItem("accountdistribution");
    var amtdistdata = JSON.parse(order_FP_orderdetails);
    var count = order_FP_data.length - 1;
    var userId = "1234";
    var userinfo = JSON.parse(sessionStorage.getItem("verifypayer"));
    var PassValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      paymentOrders: [
        {
          contactPartyId: order_FP_data[count].contactPartyId,
          payerPartyId: order_FP_data[count].partyId,
          // payerPartyId: this.props.partyId,
          paymentOrderCurrencyId: order_FP_data[count].paymentOrderCurrencyId,
          paymentOrderAmount: sessionStorage.getItem("amounttopay"),
          totalTransactionAmount: sessionStorage.getItem("amounttopay"),
          feesAmount: "7",
          payerAccountId: order_FP_data[count].payerAccountId,
          billId: order_FP_data[count].billId,
          paymentOrderStatus: "1", // need to check for now 1
          orderChannel: "1", //   credit / echeque ?
          isActive: "1", // 1 by default
          paymentOrderDetails: JSON.parse(order_FP_orderdetails),
        },
      ],
    };
    var userId = "1234";
    OutstandingPayerService.PP_Create_Payment_Order(PassValues, userId, (res) => {
      if (res["data"].responseInfo.status === "SUCCESSFUL") {
        sessionStorage.setItem("Ordernumber", res["data"].paymentOrders[0].paymentOrderId);
        props.history.location.state.paymentMethod === "echeck" || props.history.location.state.paymentMethod.includes("&")
          ? props.history.push({
              pathname: "/payment-echeck",
              state: {
                paymentMethod: props.history.location.state.paymentMethod,
                payInFuture: false,
                PassValues: {},
                isSettled: true,
                seedAccountNumber: seedItem.seedAccountNumber,
              },
            })
          : props.history.push({
              pathname: "/payment-Creditcard",
              state: {
                paymentMethod: props.history.location.state.paymentMethod,
                payInFuture: false,
                PassValues: {},
                isSettled: true,
                seedAccountNumber: seedItem.seedAccountNumber,
              },
            });
      } else if (res["data"].responseInfo.status === "FAILED") {
        handleError("Sorry Internal Error Occurred. Please login and try again.");
        localStorage.removeItem("currentUserRole");
        localStorage.clear();
        sessionStorage.clear();
        props.history.push("/signin");
      } else {
        handleWarning("Something went wrong!!");
      }
    });
  };

  const getSettleAccountData = (res, proposedAmount) => {
    var userdatainfo = JSON.parse(sessionStorage.getItem("verifypayer"));
    var userpartyid = JSON.parse(sessionStorage.getItem("order_FP"));
    var distributeddata = "";
    if (sessionStorage.getItem("accountdistribution_changeddata") !== null) {
      distributeddata = JSON.parse(sessionStorage.getItem("accountdistribution_changeddata"));
    } else {
      distributeddata = JSON.parse(sessionStorage.getItem("fulldistribution"));
    }

    var userId = "1234";
    //OutstandingPayerService.PP_Get_Settlement_Distributed_data(passValues, userId, (res) => {
    if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
      sessionStorage.setItem("billingdata_partial", res["data"]?.billDistribution?.billDistrubute);

      sessionStorage.setItem("amounttopay", proposedAmount);
      sessionStorage.setItem("TotalAmount", seedItem?.oustandingBalance);
      // sessionStorage.setItem(
      //   "apportionedamount",
      //   Number(
      //     event.target.value
      //   ).toFixed(2)
      // );
      var totalAmount = 0;
      var billinginformation = [];
      var temp = [];
      var rowjsonObj = [];
      var rowjsonitem_order = [];
      var rowjsonorderdetails = [];
      var rowpartjsonObj = [];
      var billingdata = res["data"].billDistribution.billDistrubute;
      billingdata.map((billitem, index) => {
        let temp1 = [];
        temp1["accountno"] = billitem?.tenantAccountReferenceNo;
        totalAmount += Number(billitem?.billDistributionAmount);
        temp.push(temp1);
        let item = {};
        let item_order = {};
        let item_orderdetails = {};
        item["acno"] = billitem?.accountReferenceNumber;
        item["outstanding"] = billitem?.totalAmount;
        item_order["billId"] = billitem?.billId;
        item_order["paymentOrderStatus"] = "1";
        item_order["orderChannel"] = "1";
        item_order["isActive"] = "1";
        item_orderdetails["mappedBillId"] = billitem?.billId;
        //   item_orderdetails['billAmount'] =  billitem.billAmount;
        // item_orderdetails['billDate'] = (billitem.billDate).split(" ")[0];
        item_orderdetails["billDate"] = billitem?.billDate;
        //item_orderdetails["billDate"] = "2020-09-11";
        item_orderdetails["otherCharges"] = billitem?.otherCharges;
        item_orderdetails["tax"] = billitem?.tax;
        item_orderdetails["totalBillAmount"] = billitem?.totalAmount;
        // item_orderdetails['currencyId'] = billitem.billCurrency;
        item_orderdetails["expectedPaymentAmount"] = billitem?.billDistributionAmount;
        item_orderdetails["isActive"] = "1";
        //item_orderdetails['acno'] = billitem.tenantAccountReferenceNo
        rowjsonObj.push(item);
        rowjsonitem_order.push(item_order);
        rowjsonorderdetails.push(item_orderdetails);
        let partitem = {};
        partitem["totalBillAmount"] = billitem?.billDistributionAmount;
        partitem["expectedPaymentAmount"] = billitem?.totalAmount;
        partitem["mappedBillId"] = billitem?.billId;
        partitem["billAmount"] = billitem?.totalAmount;
        partitem["otherCharges"] = billitem?.otherCharges ? billitem?.otherCharges : "00";
        partitem["tax"] = billitem?.tax ? billitem?.tax : "00";
        partitem["currencyid"] = billitem?.billCurrency;
        partitem["isActive"] = "1";
        //item["billDate"] = pay.dataset.billdate;
        var bill_date = billitem?.billDate?.split(" ")[0];
        var billtempdate = bill_date?.split("/");
        // partitem['billDate'] = billtempdate[2]+"-"+ billtempdate[0] +"-"+ billtempdate[1];
        partitem["billDate"] = billitem?.billDate;
        //item["billDate"] = "2020-09-11";
        if (Number(billitem?.billDistributionAmount) > 0) {
          rowpartjsonObj.push(partitem);
        }
      });
      sessionStorage.setItem("accountdistribution_partial", JSON.stringify(rowjsonObj));
      sessionStorage.setItem("order_partial", JSON.stringify(rowjsonitem_order));
      sessionStorage.setItem("orderdetails_partial", JSON.stringify(rowjsonorderdetails));
      sessionStorage.setItem("accountdistribution", JSON.stringify(rowpartjsonObj));
      sessionStorage.setItem("partaccountdistribution", JSON.stringify(rowpartjsonObj));
      handleContinueSettlment();
    } else {
      // this.setState({
      //   amtdistributeflag: false,
      // });
    }
    //});
  };

  const handleSubmit = (data) => {
    let passValuesNew = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token")),
      settlementRequest: {
        accountNumber: seedItem.seedAccountNumber,
        emailId: data.useremailid,
        minSettlementAmount: seedItem.minimumPayment,
        notes: data.note,
        outstandingBalance: seedItem.oustandingBalance,
        proposedAmount: Number(data.proposedAmount),
        userName: JSON.parse(sessionStorage.getItem("userData"))?.userId
          ? JSON.parse(sessionStorage.getItem("userData"))?.userId
          : 0,
        phone: data.phoneNo,
        customerName: `${
          JSON.parse(sessionStorage.getItem("userData"))?.name ? JSON.parse(sessionStorage.getItem("userData"))?.name : ""
        }`,
      },
    };

    settlementService.settlementOptionSaveRequest(passValuesNew, 0, (res) => {
      if (res["data"].responseInfo.status === "SUCCESSFUL") {
        if (res["data"].settlementType === "Auto") {
          getSettleAccountData(res, Number(data.proposedAmount));
        } else {
          handleSuccess(res["data"]?.responseInfo?.responseMessage?.[0]);
          if (underQP) {
            props.history.push("/payment-outstanding");
          } else {
            props.history.push("/home/billing");
          }
        }
      } else {
        res["data"].responseInfo.status === "FAILED" && handleError(res["data"]?.responseInfo?.responseMessage?.[0]);
      }
    });
  };

  const phoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
  const paymentConfirmationValidation = Yup.object().shape({
    useremailid: Yup.string().email("Invalid email format").required("Email is required"),
    phoneNo: Yup.string().matches(phoneRegex, "Invalid Phone Number").required("Phone Number is required"),
    proposedAmount: Yup.number()
      .positive("Value can't start with a minus")
      .required("Proposed amount is required")
      .min(
        Number(seedItem.minimumPayment).toFixed(2),
        `Minimum settlement amount to pay is $ ${Number(seedItem.minimumPayment?.toFixed(2))?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}`,
      )
      .max(
        Number(seedItem.oustandingBalance).toFixed(2),
        `Maximum settlement amount to pay is $ ${Number(seedItem.oustandingBalance)?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}`,
      ),
    note: Yup.string().required("Notes are required"),
  });

  return (
    <>
      {underQP && <HeaderQP />}
      <div class="transaction-card">
        <Formik
          initialValues={{
            proposedAmount: "",
            useremailid: userData?.email ? userData?.email : "",
            phoneNo: userData?.phoneNo ? userData?.phoneNo : "",
            note: "",
          }}
          validationSchema={paymentConfirmationValidation}
          onSubmit={handleSubmit}
          enableReinitialize>
          {({ touched, errors, setFieldValue, values }) => (
            <Form id="transaction-search" className="input-field" noValidate="noValidate">
              <div className="main-container-card  settlement-payment-content">
                <div className="listed-description">
                  {" "}
                  <Translation>
                    {(t, { i18n }) => (
                      // eslint-disable-next-line jsx-a11y/label-has-associated-control
                      <h5 aria-level="1">
                        Below is a list of your accounts eligible for settlement. Select a specific account and propose your
                        settlement offer by entering the settlement amount, Otherwise, you can continue without settlement.
                      </h5>
                    )}
                  </Translation>
                </div>

                {/* <div className="disclaimer my-2">
                <Alert severity="warning">
                  <b>DISCLAIMER:</b> The balance shown is not updated in real-time and may not reflect credit for all
                  previous payments and/or adjustments. If you have any questions about this account please contact us.
                </Alert>
              </div> */}

                {/* <table>
                    <thead>
                      <tr class="heading-title accordion-table">
                        <td
                          style={{
                            textAlign: "left",
                            minWidth: "10vw",
                          }}>
                          <Translation>{(t, { i18n }) => <label>{"Account Number"}</label>}</Translation>
                        </td>
                        <td
                          style={{
                            padding: "0px",
                            textAlign: "left",
                            minWidth: "10vw",
                          }}>
                          <Translation>{(t, { i18n }) => <label>{"Outstanding Balance"}</label>}</Translation>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            minWidth: "10vw",
                          }}>
                          <div class="">
                            <Translation>
                              {(t, { i18n }) => <label className="required">{"Proposed Settlement Amount"}</label>}
                            </Translation>
                          </div>
                        </td>
                      </tr>
                    </thead>
                  </table> */}
                <div className="settlement-payment-header" aria-label="billDetails">
                  <Table>
                    <TableBody>
                      <TableRow className="settlement-table settlement-heading-title">
                        <TableCell>
                          <span>Account Number</span>
                        </TableCell>
                        <TableCell>
                          <span>Outstanding Balance</span>
                        </TableCell>
                        <TableCell>
                          <span>Proposed Settlement Amount</span>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>

                <div>
                  <Accordion defaultExpanded className="accordion-section" key={seedItem.seedAccountNumber} expanded={true}>
                    <AccordionSummary
                      className="accordion-header settlement-accordion-header"
                      // expandIcon={<ExapandMoreIcon />}
                    >
                      <p className="mb-0">{seedItem.seedAccountNumber}</p>
                      <p className="mb-0">
                        Outstanding Balance:{" "}
                        <span>$ {seedItem?.oustandingBalance?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</span>
                      </p>

                      <div className="settlement-info-proposed">
                        <div className="d-flex">
                          <div className="mt-2 mx-2 text-danger"> $</div>
                          <div className="proposed-input">
                            <Field
                              type="number"
                              id={"proposedAmount"}
                              name={"proposedAmount"}
                              // step="0.01"
                              placeholder="0.00"
                              value={values.proposedAmount}
                              data-isActive="1"
                              className="form-control text-field"
                              // defaultValue={Number(values.proposedAmount).toFixed(2)}
                              defaultValue="0.00"
                              min="0"
                              onChange={(event) => {
                                // if (Number(event.target.value) < Number(seedItem.minimumPayment)) {
                                //   handleError("Proposed amount should be grater than minimum settlement amount");
                                //   setFieldValue((values.proposedAmount = ""));
                                // }
                                setFieldValue((values.proposedAmount = event.target.value));
                              }}
                            />
                            <ErrorMessage
                              component="div"
                              name="proposedAmount"
                              className="error-text proposed-helper-text"
                            />
                          </div>
                        </div>
                      </div>
                    </AccordionSummary>

                    <AccordionDetails role="region">
                      <Table className="settlement-table">
                        <TableBody>
                          {seedItem?.bills?.map((billitem, index) => {
                            return (
                              <TableRow key={billitem?.billType + index}>
                                <TableCell>
                                  <div class={` ${billitem?.billType === "Legal" ? "red-text" : ""}`}>
                                    {billitem.accountReferenceNumber}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div class={` ${billitem?.billType === "Legal" ? "red-text" : ""}`}>
                                    $
                                    {Number(billitem.currentBalanceAmount).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                    })}
                                  </div>
                                </TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className=" main-container-card">
                  <div class="row ">
                    <div class="col-sm-12">
                      <div className="heading-style mb-3">Contact Details</div>
                    </div>
                  </div>
                  <div class="row user-label-title">
                    <div class="col-md-5 col-lg-4  col-sm-6">
                      <div class="form-group">
                        <label className="active required">{"Email"}</label>
                        <OutlinedInput
                          type="text"
                          maxLength="255"
                          autocomplete="new-password"
                          id="useremailid"
                          value={values.useremailid}
                          placeholder={"Enter Email"}
                          className={`form-control text-field  ${
                            touched.useremailid && errors.useremailid ? "is-invalid" : ""
                          }`}
                          onChange={(event) => {
                            setFieldValue(((values.useremailid = event.target.value), (values.isupdated = true)));

                            // this.setState({
                            //   emailId: event.target.value,
                            //   isupdated: true,
                            // });
                          }}
                        />
                        <ErrorMessage component="div" name="useremailid" className="error-text" />
                      </div>
                    </div>
                    <div class="col-md-5 col-lg-4 col-sm-6">
                      <div class="form-group">
                        <label className="active required">{"Phone Number"}</label>
                        <OutlinedInput
                          type="number"
                          maxLength="255"
                          autocomplete="new-password"
                          id="phoneNo"
                          value={values.phoneNo}
                          placeholder={"Enter Phone Number"}
                          className={`form-control text-field  ${touched.phoneNo && errors.phoneNo ? "is-invalid" : ""}`}
                          onChange={(event) => {
                            setFieldValue(((values.phoneNo = event.target.value), (values.isupdated = true)));
                          }}
                        />
                        <ErrorMessage component="div" name="phoneNo" className="error-text" />
                      </div>
                    </div>
                  </div>

                  <>
                    <div>
                      <div class="form-group">
                        <label className="active required">{"Add Notes"}</label>

                        <Field
                          name="note"
                          component="textarea"
                          className="w-100"
                          rows={4}
                          cols={40}
                          placeholder="Add a note"
                          onChange={(event) => {
                            setFieldValue(((values.note = event.target.value), (values.isupdated = true)));
                          }}
                        />
                        <ErrorMessage component="div" name="note" className="error-text" />
                      </div>
                    </div>
                  </>
                </div>

                {/* <div className=" button-box billingbox">
              {!requestedEmail && <button type="submit">Submit</button>}
              <button
                type="button"
                onClick={() => {
                  props.history.push("/AccountDetails");
                }}>
                Cancel
              </button>
            </div> */}

                <div className="report-btns mt-4">
                  <button
                    type="reset"
                    className="secondary-button mx-2"
                    onClick={() =>
                      underQP ? props.history.push("/payment-outstanding") : props.history.push("/home/billing")
                    }>
                    Cancel
                  </button>

                  <button type="submit" className="themeing-buttons primary-button">
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SettlementPaymentOutstanding;
