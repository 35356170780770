import React from "react";
import { Link } from "react-router-dom";

import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";

export const getLabel = (labelid, labelvalue) => {
  // Master data from sessionStorage for localization
  var labeldata = {};
  labeldata["makeapayment"] = "Make a Payment";
  labeldata["processpayment"] = "Process QP Payment";
  labeldata["thankyou"] = "Thank You QP";

  if (sessionStorage.getItem(labeldata) === null) {
    sessionStorage.setItem("labeldata", JSON.stringify(labeldata));
  }

  // BU specific data from sessionStorage for localization
  var labeldataforBU = {};
  labeldataforBU["makeapayment"] = "Make a Payment";
  labeldataforBU["processpayment123"] = "Process Payment";
  labeldataforBU["thankyou"] = "Thank You QP";

  if (sessionStorage.getItem(labeldataforBU) === null) {
    sessionStorage.setItem("labeldataforBU", JSON.stringify(labeldataforBU));
  }

 
  var dynamiclabelforBU = JSON.parse(sessionStorage.getItem("labeldataforBU"));


  var dynamiclabel = JSON.parse(sessionStorage.getItem("labeldata"));

  
  var label = "";

  if (dynamiclabelforBU[labelid]) {
    label = dynamiclabelforBU[labelid];
  } else if (dynamiclabel[labelid]) {
    label = dynamiclabel[labelid];
  } else {
    label = labelvalue;
  }
  return label;
};

/*******************  Global Request Info********************/

export const requestInfo_PP = (jwt, jti) => {
  var userid = "";
  var useremail = "";
  if (sessionStorage.getItem("verifypayer")) {
    const userids = JSON.parse(sessionStorage.getItem("verifypayer"));

    if (userids.payer) {
      userid = userids.payer.partyId ? userids.payer.partyId : "1234";
      useremail = userids.payer.contactEmail ? userids.payer.contactEmail : "shantesh@privacydatasystems.com";
    } else {
      userid = userids.partyId ? userids.partyId : "1234";
      useremail = userids.contactEmail ? userids.contactEmail : "shantesh@privacydatasystems.com";
    }
  } else {
    userid = "1234";
    useremail = "shantesh@privacydatasystems.com";
  }
  return {
    applicationId: "11102", // fixed 11102 for PP
    //"buId":"2",  // Need to change as per BU, Need to confirm
    buId: sessionStorage.getItem("SIC_Info") !== null ? "6" : sessionStorage.getItem("buid"), // Need to change as per BU, Need to confirm
    subBuId: "0", // Need to change as per BU, Need to confirm
    action: "", // Need to change as per BU, Need to confirm
    issuer: "epi", // Need to change as per BU , Need to confirm
    userlogin: useremail, // Need to change as per the verified user
    environment: "dev", // Need to change as per BU, Need to confirm
    userId: userid, // Need to change as per the verified user
    token: jwt,
    // "jtitoken":jti,
  };
};

export const requestInfo_Core = (jwt, jti) => {
  return {
    applicationId: "11101", // fixed 11102 for PP
    //"buId":"2",
    buId: sessionStorage.getItem("buid"),
    subBuId: "0",
    action: "",
    issuer: "epi",
    userlogin: "shantesh@privacydatasystems.com",
    environment: "dev",
    userId: "1234",
    token: jwt,
    // "jtitoken":jti,
  };
};
export const requestInfo_PP_Txn = (jwt, jti) => {
  var userid = "";
  var useremail = "";
  if (sessionStorage.getItem("loginDetails")) {
    const userids = JSON.parse(sessionStorage.getItem("loginDetails"));

    if (userids.payer) {
      userid = userids.payer.partyId ? userids.payer.partyId : "1234";
      useremail = userids.payer.contactEmail ? userids.payer.contactEmail : "shantesh@privacydatasystems.com";
    } else {
      userid = userids.partyId ? userids.partyId : "1234";
      useremail = userids.contactEmail ? userids.contactEmail : "shantesh@privacydatasystems.com";
    }
  } else {
    userid = "1234";
    useremail = "shantesh@privacydatasystems.com";
  }
  return {
    applicationId: "11102", // fixed 11102 for PP
    //"buId":"2",  // Need to change as per BU, Need to confirm
    buId: sessionStorage.getItem("SIC_Info") !== null ? "6" : sessionStorage.getItem("buid"), // Need to change as per BU, Need to confirm
    subBuId: "0", // Need to change as per BU, Need to confirm
    action: "", // Need to change as per BU, Need to confirm
    issuer: "epi", // Need to change as per BU , Need to confirm
    userlogin: useremail, // Need to change as per the verified user
    environment: "dev", // Need to change as per BU, Need to confirm
    userId: userid, // Need to change as per the verified user
    token: jwt,
    // "jtitoken":jti,
  };
};

export const requestInfo_PP_management = (jwt, jti) => {
  var userid = "";
  var useremail = "";
  if (sessionStorage.getItem("loginDetails")) {
    const userids = JSON.parse(sessionStorage.getItem("loginDetails"));
    userid = userids.partyId ? userids.partyId : "1234";
    useremail = userids.emailId ? userids.emailId : "shantesh@privacydatasystems.com";
  } else {
    userid = "1234";
    useremail = "shantesh@privacydatasystems.com";
  }
  return {
    applicationId: "11101", // fixed 11102 for PP
    //"buId":"2",  // Need to change as per BU, Need to confirm
    buId: sessionStorage.getItem("buid"), // Need to change as per BU, Need to confirm
    subBuId: "0", // Need to change as per BU, Need to confirm
    action: "", // Need to change as per BU, Need to confirm
    issuer: "epi", // Need to change as per BU , Need to confirm
    userlogin: useremail, // Need to change as per the verified user
    environment: "dev", // Need to change as per BU, Need to confirm
    userId: userid, // Need to change as per the verified user
    token: jwt,
    // "jtitoken":jti,
  };
};
