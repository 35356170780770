/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import { OutstandingPayerService } from "../../services/payerOutstandingService";
import { Translation } from "react-i18next";
import { requestInfo_PP } from "../../Common/CommonFn";
import { handleError } from "../Notifications/Toast";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            color: "#948f8f",
            float: "right",
            padding: 0,
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogSettlementDetails = ({
  children,
  dialogClose1,
  dialogClose2,
  open1,
  open2,
  title,
  settleDetails,
  settleReject,
  settleRevised,
  settlePending,
  data,
  handleSettlePush,
  handleSettlmentNo,
  currentSeedItem,
  getCardListData,
  getEchecqueListData,
  creditCardData,
  eChequeData,
  history,
}) => {
  const [paymentmethod, setPaymentmethod] = useState("");
  const handleContinueSettlment = () => {
    // api for payment order

    //  alert("4")
    var order_FP_data = JSON.parse(sessionStorage.getItem("order_FP"));
    var order_FP_orderdetails = sessionStorage.getItem("accountdistribution_changeddata_sum")
      ? sessionStorage.getItem("accountdistribution_changeddata_sum")
      : sessionStorage.getItem("accountdistribution");
    var amtdistdata = JSON.parse(order_FP_orderdetails);

    var count = order_FP_data.length - 1;
    var userId = "1234";

    var userinfo = JSON.parse(sessionStorage.getItem("verifypayer"));
    var PassValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      paymentOrders: [
        {
          contactPartyId: order_FP_data[count].contactPartyId,
          payerPartyId: order_FP_data[count].partyId,
          // payerPartyId: this.props.partyId,
          paymentOrderCurrencyId: order_FP_data[count].paymentOrderCurrencyId,
          paymentOrderAmount: sessionStorage.getItem("amounttopay"),
          totalTransactionAmount: sessionStorage.getItem("amounttopay"),
          feesAmount: "7",
          payerAccountId: order_FP_data[count].payerAccountId,
          billId: order_FP_data[count].billId,

          paymentOrderStatus: "1", // need to check for now 1
          orderChannel: "1", //   credit / echeque ?
          isActive: "1", // 1 by default
          paymentOrderDetails: JSON.parse(order_FP_orderdetails),
        },
      ],
    };
    var userId = "1234";
    OutstandingPayerService.PP_Create_Payment_Order(PassValues, userId, (res) => {
      if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
        sessionStorage.setItem("Ordernumber", res["data"]?.paymentOrders[0]?.paymentOrderId);

        paymentmethod === "echeck" || paymentmethod.includes("&")
          ? history.push({
              pathname: "/payment-echeck",
              state: {
                paymentMethod: paymentmethod,
                payInFuture: false,
                PassValues: {},
                isSettled: true,
                seedAccountNumber: currentSeedItem.seedAccountNumber,
              },
            })
          : history.push({
              pathname: "/payment-Creditcard",
              state: {
                paymentMethod: paymentmethod,
                payInFuture: false,
                PassValues: {},
                isSettled: true,
                seedAccountNumber: currentSeedItem.seedAccountNumber,
              },
            });
      }
    });
  };

  const getSettleAccountData = (proposedAmount) => {
    var userdatainfo = JSON.parse(sessionStorage.getItem("verifypayer"));
    var userpartyid = JSON.parse(sessionStorage.getItem("order_FP"));
    var distributeddata = "";
    if (sessionStorage.getItem("accountdistribution_changeddata") !== null) {
      distributeddata = JSON.parse(sessionStorage.getItem("accountdistribution_changeddata"));
    } else {
      distributeddata = JSON.parse(sessionStorage.getItem("fulldistribution"));
    }
    const passValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      // partialAmount: Number(proposedAmount).toFixed(2),
      // seedAccount: currentSeedItem?.seedAccountNumber,
      // outstandingAmount: currentSeedItem?.oustandingBalance,
      accountNumber: currentSeedItem?.seedAccountNumber,
      outstandingBalance: currentSeedItem?.oustandingBalance,
      proposedAmount: Number(proposedAmount).toFixed(2),
    };

    var userId = "1234";
    OutstandingPayerService.PP_Get_Settlement_Distributed_data(passValues, userId, (res) => {
      if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
        sessionStorage.setItem("TotalAmount", currentSeedItem?.oustandingBalance);
        sessionStorage.setItem("billingdata_partial", res["data"]?.billDistribution?.billDistrubute);
        res.data.billDistribution.proposedAmount !== 0 &&
          sessionStorage.setItem("amounttopay", res.data.billDistribution.proposedAmount);
        // sessionStorage.setItem(
        //   "apportionedamount",
        //   Number(
        //     event.target.value
        //   ).toFixed(2)
        // );

        var totalAmount = 0;
        var billinginformation = [];
        var temp = [];

        var rowjsonObj = [];
        var rowjsonitem_order = [];
        var rowjsonorderdetails = [];
        var rowpartjsonObj = [];
        var billingdata = res["data"].billDistribution.billDistrubute;
        billingdata.map((billitem, index) => {
          let temp1 = [];
          temp1["accountno"] = billitem?.tenantAccountReferenceNo;

          totalAmount += Number(billitem?.billDistributionAmount);
          temp.push(temp1);

          let item = {};
          let item_order = {};
          let item_orderdetails = {};
          item["acno"] = billitem?.accountReferenceNumber;
          item["outstanding"] = billitem?.totalAmount;
          item_order["billId"] = billitem?.billId;
          item_order["paymentOrderStatus"] = "1";
          item_order["orderChannel"] = "1";
          item_order["isActive"] = "1";

          item_orderdetails["mappedBillId"] = billitem?.billId;
          //   item_orderdetails['billAmount'] =  billitem.billAmount;
          // item_orderdetails['billDate'] = (billitem.billDate).split(" ")[0];
          item_orderdetails["billDate"] = billitem?.billDate;
          //item_orderdetails["billDate"] = "2020-09-11";
          item_orderdetails["otherCharges"] = billitem?.otherCharges;
          item_orderdetails["tax"] = billitem?.tax;
          item_orderdetails["totalBillAmount"] = billitem?.totalAmount;
          // item_orderdetails['currencyId'] = billitem.billCurrency;
          item_orderdetails["expectedPaymentAmount"] = billitem?.billDistributionAmount;
          item_orderdetails["isActive"] = "1";
          //item_orderdetails['acno'] = billitem.tenantAccountReferenceNo

          rowjsonObj.push(item);
          rowjsonitem_order.push(item_order);
          rowjsonorderdetails.push(item_orderdetails);

          let partitem = {};

          partitem["totalBillAmount"] = billitem?.billDistributionAmount;
          partitem["expectedPaymentAmount"] = billitem?.totalAmount;
          partitem["mappedBillId"] = billitem?.billId;
          partitem["billAmount"] = billitem?.totalAmount;
          partitem["otherCharges"] = billitem?.otherCharges ? billitem?.otherCharges : "00";
          partitem["tax"] = billitem?.tax ? billitem?.tax : "00";
          partitem["currencyid"] = billitem?.billCurrency;
          partitem["isActive"] = "1";
          //item["billDate"] = pay.dataset.billdate;
          var bill_date = billitem?.billDate?.split(" ")[0];
          var billtempdate = bill_date?.split("/");
          // partitem['billDate'] = billtempdate[2]+"-"+ billtempdate[0] +"-"+ billtempdate[1];
          partitem["billDate"] = billitem?.billDate;
          //item["billDate"] = "2020-09-11";

          if (Number(billitem?.billDistributionAmount) > 0) {
            rowpartjsonObj.push(partitem);
          }
        });
        sessionStorage.setItem("accountdistribution_partial", JSON.stringify(rowjsonObj));
        sessionStorage.setItem("order_partial", JSON.stringify(rowjsonitem_order));
        sessionStorage.setItem("orderdetails_partial", JSON.stringify(rowjsonorderdetails));
        sessionStorage.setItem("accountdistribution", JSON.stringify(rowpartjsonObj));
        sessionStorage.setItem("partaccountdistribution", JSON.stringify(rowpartjsonObj));
        handleContinueSettlment();
      } else if (res["data"]?.responseInfo?.status === "FAILED") {
        // this.setState({
        //   amtdistributeflag: false,
        // });
        handleError(`${res["data"]?.responseInfo?.responseMessage[0]}`);
        // error(
        //   `${res["data"]?.responseInfo?.responseMessage[0]}`,
        //   errorNotification
        // );
      }
    });
  };

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={open1 || open2}
      onClose={dialogClose1 || dialogClose2}
      fullWidth
      className="settlement-dialog"
      maxWidth="sm">
      {" "}
      {open2 ? (
        <DialogTitle
          id="customized-dialog-title"
          className="popupstyles"
          onClose={() => {
            dialogClose2();
            setPaymentmethod("");
          }}>
          <div className="dialog-header">
            <Typography> {title} </Typography>
            <IconButton>
              <CloseIcon
                onClick={() => {
                  dialogClose2();
                }}
              />
            </IconButton>
          </div>
        </DialogTitle>
      ) : (
        <DialogTitle
          id="customized-dialog-title"
          className="popupstyles"
          onClose={() => {
            dialogClose1();
            setPaymentmethod("");
          }}>
          <div className="dialog-header">
            <Typography>Congratulations !</Typography>
            <IconButton>
              <CloseIcon
                onClick={() => {
                  dialogClose1();
                }}
              />
            </IconButton>
          </div>
        </DialogTitle>
      )}
      <DialogContent dividers>
        {open1 && (
          <>
            {/* <div className="row">
              <span className="settlement-header"> Congratulations ! </span>
            </div> */}
            <div className="modal-body">
              <p>You have a settlement offer. would you like to claim it?</p>

              <DialogActions className="">
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  size="medium"
                  className="primary-button themeing-buttons mx-1"
                  onClick={() => handleSettlePush()}>
                  Yes
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  className="secondary-button mx-1"
                  onClick={() => {
                    handleSettlmentNo();
                  }}>
                  No
                </Button>
              </DialogActions>
            </div>
          </>
        )}
        {open2 && (
          <>
            <div className="rp-settlement-dialog modal-body">
              <div className="balance-card">
                <p className="text-center">
                  {" "}
                  Hi <span className="amount-color">{currentSeedItem?.bills[0]?.payer?.firstName}, </span>
                  Your outstanding amount is{" "}
                  <span className="amount-color">
                    $ {currentSeedItem?.oustandingBalance.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </span>{" "}
                  and
                </p>
                {settleRevised && (
                  <p className="text-center">
                    You are approved for a settlement of{" "}
                    <span className="amount-color">
                      ${" "}
                      {Number(data?.revisedAmount).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </p>
                )}
                {settleReject && (
                  <p className="text-center">
                    you are proposed Amount is{" "}
                    <span className="amount-color">
                      ${" "}
                      {Number(data?.proposedAmount).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </p>
                )}
                {settlePending && (
                  <p className="text-center">
                    your proposed Amount is{" "}
                    <span className="amount-color">
                      ${" "}
                      {Number(data?.proposedAmount).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </p>
                )}
                {settleDetails && (
                  <p className="text-center">
                    You are approved for a settlement of{" "}
                    <span className="amount-color">
                      ${" "}
                      {Number(data?.proposedAmount).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </p>
                )}
              </div>

              <div className={settleDetails || settleReject ? "row  user-info settle-user-info mt-4" : "row user-info mt-3"}>
                <div className="col-md-12 account-number  settle-accountnumber">
                  <p>
                    Hospital name: <span className="account-color">{data.hospital}</span>
                  </p>
                </div>
                <div className={settleRevised ? "col-sm-6 account-number" : "col-md-6 account-number  settle-accountnumber"}>
                  <p>
                    Account Number: <span className="account-color">{data.masterAccountReferenceNumber}</span>
                  </p>
                </div>
                <div className="col-sm-6 user-name">
                  <p>
                    User Name: <span className="account-color">{currentSeedItem?.bills[0]?.payer?.firstName}</span>
                  </p>
                </div>
              </div>
              <div className={settleDetails || settleReject || settlePending ? "row justify-content-between" : "row"}>
                <div
                  className={
                    settleReject || settleDetails || settlePending
                      ? "col-md-6 col-sm-6 my-1"
                      : "col-md-4 col-sm-6 my-1 pr-md-0"
                  }>
                  <div className=" settlement-amount ">
                    <p className="settlement-title">Outstanding Amount</p>
                    <p className="amount-settled mt-4 mb-0">
                      {" "}
                      ${" "}
                      {Number(currentSeedItem?.oustandingBalance).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                </div>
                <div
                  className={
                    settleReject || settleDetails || settlePending ? "col-md-6 col-sm-6 my-1" : "col-md-4 col-sm-6 my-1 "
                  }>
                  {data.settlementStatus === "APPROVED" || data.settlementStatus === "PENDING" ? (
                    <div className="settlement-amount" style={{ backgroundColor: !settleRevised && "#d3e0f1" }}>
                      <p className="settlement-title">Amount to pay</p>
                      <p className="amount-settled mt-4 mb-0">
                        ${" "}
                        {Number(data.proposedAmount).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  ) : data.settlementStatus === "REJECTED" || data.settlementStatus === "REVISED" ? (
                    <div className="settlement-amount">
                      <p className="settlement-title">Proposed Amount</p>
                      <p className="amount-settled mt-3 mb-0">
                        ${" "}
                        {Number(data.proposedAmount).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {settleRevised && (
                  <div className="col-md-4 col-sm-6 pl-md-0 my-1">
                    <div className=" settlement-amount" style={{ backgroundColor: settleRevised && "#d3e0f1" }}>
                      <p className="settlement-title mb-1">Approved Amount to Pay</p>
                      <p className="amount-settled mb-0">
                        ${" "}
                        {Number(data?.revisedAmount).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={
                  settleDetails || settleReject ? "row m-0 admin-notes admin-settle-notes my-3" : "admin-notes my-3"
                }>
                <p className="account-color mb-1">
                  {" "}
                  Notes
                  <span>
                    <strong>{data?.settledByPaName}:</strong> <span>{data?.notes}</span>
                  </span>
                </p>
              </div>
              {settleDetails || settleRevised ? (
                <div className="row">
                  {/* Other payment metohds */}
                  <div className="col-md-12 heading4">
                    <Translation>
                      {(t, { i18n }) => <label className="required settlement-label">{t("Select Payment Method")}</label>}
                    </Translation>
                  </div>
                  {/* eslint-disable jsx-a11y/no-onchange */}
                  <div className="col-md-12 form-group ">
                    <select
                      className="form-control"
                      onChange={(event) => {
                        setPaymentmethod(event.target.value);
                      }}>
                      <Translation>{(t, { i18n }) => <option value="">{t("Select Payment Method")}</option>}</Translation>

                      {getCardListData(creditCardData)}
                      {getEchecqueListData(eChequeData)}
                      <option value="creditCard">Debit/Credit Card</option>
                      <option value="echeck">eCheck</option>
                    </select>
                  </div>
                </div>
              ) : null}
              <div className="row" style={{ justifyContent: "center", marginTop: "10px" }}>
                {settleDetails || settleRevised ? (
                  <Button
                    variant="contained"
                    type="button"
                    className="primary-button themeing-buttons mx-1"
                    style={{
                      background: !paymentmethod && "#e7e7e7",
                    }}
                    disabled={!paymentmethod}
                    onClick={() => {
                      let passAmount = settleRevised ? data?.revisedAmount : data?.proposedAmount;
                      getSettleAccountData(passAmount);
                    }}>
                    Pay Now
                  </Button>
                ) : null}
                {settleRevised || settleReject ? (
                  <Button
                    className="mx-1 secondary-button"
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      dialogClose2();
                      setPaymentmethod("");
                      // may require
                      // {settleRevised ? "Reject" : "Cancel"}
                    }}>
                    Cancel
                  </Button>
                ) : null}
              </div>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default DialogSettlementDetails;
