import RestDataSource from "./restdatasource";
import { Global_var } from "../global/global_var";
export const clientMaintainanceService = {
  getClientMainatinanceData,
  AddClientMainatinanceData,
  getHospitalData,
  isActiveMsg,
};

function getClientMainatinanceData(userData, fn) {
  var url = Global_var.BASEURL + Global_var.URL_CLIENT_MAINTAINANCE;
  return new RestDataSource(url, null, fn).Store(userData, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        localStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
}

function AddClientMainatinanceData(userData, fn) {
  var url = Global_var.BASEURL + Global_var.URL_CLIENT_MAINTAINANCE_SAVE;
  return new RestDataSource(url, null, fn).Store(userData, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        localStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
}

function getHospitalData(userData, fn) {
  var url = Global_var.BASEURL + Global_var.URL_CLIENT_MAINTAINANCE_HOSPITAL;
  return new RestDataSource(url, null, fn).Store(userData, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        localStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
}

function isActiveMsg(data, fn) {
  var url = Global_var.BASEURL + Global_var.URL_ISACTIVE;
  return new RestDataSource(url, null, fn).Store(data, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        localStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
}
