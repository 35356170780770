import React from "react";
import { Formik, Form, ErrorMessage, getIn } from "formik";
import * as Yup from "yup";
// import "react-table/react-table.css";
// import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { I18nextProvider } from "react-i18next";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import i18n from "../../../../../i18n";
import { Button, InputAdornment } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { UpdatePaymentPlansOptionsData, savePaymentPlansOptionsData } from "../../../../../action/ClientManagementAction";
import { handleError, handleSuccess } from "../../../../../components/Notifications/Toast";
import SpinnerPage from "../../../../../Common/PP_loader";
import DialogActions from "@material-ui/core/DialogActions";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

const PaymentPlansOptionsPopup = ({
  paymentPlansOptPopUpData,
  isEdit,
  subBuId,
  hospitalList,
  handleClose,
  formData,
  tableRef,
  setIsUpdate,
}) => {
  const getHospitalValue = (hospitalData, hospitalId) => {
    const hospitalValue = hospitalData.find((data) => data?.buId === hospitalId);
    return hospitalValue ? hospitalValue : "";
  };
  let initialValues = {
    programLength: isEdit ? (paymentPlansOptPopUpData?.programLength ? paymentPlansOptPopUpData?.programLength : "") : "",
    planLength: isEdit ? (paymentPlansOptPopUpData?.planLength ? paymentPlansOptPopUpData?.planLength : "") : "",
    minPayment: isEdit ? (paymentPlansOptPopUpData?.minPayment ? paymentPlansOptPopUpData?.minPayment : "50") : "50",
    minBalance: isEdit ? (paymentPlansOptPopUpData?.minBalance ? paymentPlansOptPopUpData?.minBalance : "") : "",
    maxBalance: isEdit ? (paymentPlansOptPopUpData?.maximumBalance ? paymentPlansOptPopUpData?.maximumBalance : "") : "",
    hospital: isEdit ? (paymentPlansOptPopUpData?.subBuId ? getHospitalValue(hospitalList, subBuId) : "") : "",
  };
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const validation = Yup.object().shape({
    programLength: Yup.string()
      .matches(/^\d+\.?\d*$/, "Enter digits only")
      .required("Program Length is required"),
    planLength: Yup.string()
      .matches(/^\d+\.?\d*$/, "Enter digits only")
      .required("Payment Plan Length is required"),
    minPayment: Yup.string()
      .matches(/^\d+\.?\d*$/, "Enter digits only")
      .required("Minimum Payment is required"),
    minBalance: Yup.string().matches(/^\d+\.?\d*$/, "Enter digits only"),
    maxBalance: Yup.string().matches(/^\d+\.?\d*$/, "Enter digits only"),
    hospital: Yup.mixed().notOneOf([""], "Please select a Hospital").required("Please select a Hospital"),
  });

  const handleSubmit = (values) => {
    setIsLoading(true);
    if (isEdit) {
      dispatch(
        UpdatePaymentPlansOptionsData(
          {
            maximumBalance: values?.maxBalance ? Number(values?.maxBalance) : 0,
            minBalance: values?.minBalance ? Number(values?.minBalance) : 0,
            minPayment: values?.minPayment ? Number(values?.minPayment) : 0,
            paymentPlanOptionsId: paymentPlansOptPopUpData?.paymentPlanOptionsId
              ? paymentPlansOptPopUpData?.paymentPlanOptionsId
              : 0,
            planLength: values?.planLength ? Number(values?.planLength) : 0,
            programLength: values?.programLength ? Number(values?.programLength) : 0,
            subBuId: values?.hospital?.buId ? Number(values?.hospital?.buId) : 0,
          },
          (res) => {
            setIsLoading(false);
            if (res.data?.status === "SUCCESSFUL") {
              setIsUpdate(true);
              handleClose();
              res.data?.responseMessage?.length > 0 && handleSuccess(res.data?.responseMessage[0]);
            } else if (res.data?.status === "FAILED") {
              res.data?.responseMessage?.length > 0 && handleError(res.data?.responseMessage[0]);
            } else {
              handleError(res?.message);
            }
          },
        ),
      );
    } else {
      dispatch(
        savePaymentPlansOptionsData(
          {
            maximumBalance: values?.maxBalance ? Number(values?.maxBalance) : 0,
            minBalance: values?.minBalance ? Number(values?.minBalance) : 0,
            minPayment: values?.minPayment ? Number(values?.minPayment) : 0,
            paymentPlanOptionsId: paymentPlansOptPopUpData?.paymentPlanOptionsId
              ? paymentPlansOptPopUpData?.paymentPlanOptionsId
              : 0,
            planLength: values?.planLength ? Number(values?.planLength) : 0,
            programLength: values?.programLength ? Number(values?.programLength) : 0,
            subBuId: values?.hospital?.buId ? Number(values?.hospital?.buId) : 0,
          },
          (res) => {
            setIsLoading(false);
            if (res.data?.status === "SUCCESSFUL") {
              setIsUpdate(true);
              handleClose();
              res.data?.responseMessage?.length > 0 && handleSuccess(res.data?.responseMessage[0]);
            } else if (res.data?.status === "FAILED") {
              res.data?.responseMessage?.length > 0 && handleError(res.data?.responseMessage[0]);
            } else {
              handleError(res?.message);
            }
          },
        ),
      );
    }
  };

  return (
    <I18nextProvider i18n={i18n}>
      <React.Fragment>
        <Formik enableReinitialize initialValues={initialValues} validationSchema={validation} onSubmit={handleSubmit}>
          {({ touched, errors, setFieldValue, values, resetForm, handleBlur, handleChange }) => (
            <Form autocomplete="new-password" className="input-field">
              <div>
                <div className="form-row">
                  <div className="col-md-4 col-sm-6 form-group">
                    <label htmlFor="hospital" className="form-label required">
                      Hospital Name
                    </label>
                    <Autocomplete
                      id="hospital"
                      options={hospitalList}
                      size="small"
                      className="hospital-dropdown"
                      autoHighlight
                      getOptionDisabled={(option) => option?.code === null}
                      value={values?.hospital}
                      disabled={isEdit}
                      getOptionLabel={(option) => {
                        return option && option.hospitalName ? option?.code + " - " + option?.hospitalName : "";
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name="hospital"
                          placeholder="Select Hospital Name"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "disabled", // disable autocomplete and autofill
                          }}
                        />
                      )}
                      onChange={(e, value) => {
                        setFieldValue("hospital", value);
                      }}
                    />
                    <ErrorMessage component="div" name="hospital" className="text-danger" />
                  </div>
                  <div class="col-md-4 col-sm-6 form-group">
                    <label htmlFor="programLength" className="required">
                      Program Length
                    </label>
                    <OutlinedInput
                      type="text"
                      id="programLength"
                      name={"a" + Math.random()}
                      value={values.programLength}
                      placeholder="Enter Program Length"
                      onInput={(e) => {}}
                      error={getIn(touched, "programLength") && getIn(errors, "programLength")}
                      className={`form-control admin-select-field ${
                        touched.programLength && errors.programLength ? "is-invalid" : ""
                      }`}
                      onChange={(event) => {
                        setFieldValue("programLength", event.target.value);
                      }}
                    />

                    <ErrorMessage component="div" name="programLength" className="error-text" />
                  </div>

                  <div class="col-md-4 col-sm-6 form-group">
                    <label htmlFor="planLength" className="required">
                      Plan Length
                    </label>
                    <OutlinedInput
                      type="text"
                      id="planLength"
                      name={"planLength" + Math.random()}
                      autocomplete="new-password"
                      error={getIn(touched, "planLength") && getIn(errors, "planLength")}
                      value={values.planLength}
                      placeholder="Enter Plan Length"
                      className={`form-control admin-select-field ${
                        touched.planLength && errors.planLength ? "is-invalid" : ""
                      }`}
                      onChange={(event) => {
                        setFieldValue("planLength", event.target.value);
                      }}
                    />
                    <ErrorMessage component="div" name="planLength" className="error-text" />
                  </div>

                  <div class="col-md-4 col-sm-6 form-group">
                    <label htmlFor="minPayment" className="required">
                      Min Payment
                    </label>
                    <OutlinedInput
                      type="text"
                      id="minPayment"
                      name={"minPayment" + Math.random()}
                      value={values.minPayment}
                      error={getIn(touched, "minPayment") && getIn(errors, "minPayment")}
                      placeholder="Enter Min Payment"
                      className={`form-control admin-select-field ${
                        touched.minPayment && errors.minPayment ? "is-invalid" : ""
                      }`}
                      onChange={(event) => {
                        setFieldValue("minPayment", event.target.value);
                      }}
                    />

                    <ErrorMessage component="div" name="minPayment" className="error-text" />
                  </div>
                  <div class="col-md-4 col-sm-6 form-group">
                    <label htmlFor="minBalance">Min Balance</label>
                    <OutlinedInput
                      type="text"
                      id="minBalance"
                      name={"minBalance" + Math.random()}
                      value={values.minBalance}
                      startAdornment={
                        <InputAdornment position="end">
                          <AttachMoneyIcon />
                        </InputAdornment>
                      }
                      // error={getIn(touched, "minBalance") && getIn(errors, "minBalance")}
                      placeholder="Enter Min Balance"
                      className={`form-control admin-select-field ${
                        touched.minBalance && errors.minBalance ? "is-invalid" : ""
                      }`}
                      onChange={(event) => {
                        setFieldValue("minBalance", event.target.value);
                      }}
                    />

                    {/* <ErrorMessage component="div" name="minBalance" className="error-text" /> */}
                  </div>
                  <div class="col-md-4 col-sm-6 form-group">
                    <label htmlFor="maxBalance">Max Balance</label>
                    <OutlinedInput
                      type="text"
                      id="maxBalance"
                      name={"maxBalance" + Math.random()}
                      value={values.maxBalance}
                      startAdornment={
                        <InputAdornment position="end">
                          <AttachMoneyIcon />
                        </InputAdornment>
                      }
                      // error={getIn(touched, "maxBalance") && getIn(errors, "maxBalance")}
                      placeholder="Enter Max Balance"
                      className={`form-control admin-select-field ${
                        touched.maxBalance && errors.maxBalance ? "is-invalid" : ""
                      }`}
                      onChange={(event) => {
                        setFieldValue("maxBalance", event.target.value);
                      }}
                    />

                    {/* <ErrorMessage component="div" name="maxBalance" className="error-text" /> */}
                  </div>
                </div>

                <DialogActions className="d-flex justify-content-center">
                  <Button type="submit" className="primary-button themeing-buttons">
                    Submit
                  </Button>
                  <Button className="secondary-button" variant="outlined" onClick={() => resetForm(initialValues)}>
                    Reset
                  </Button>
                </DialogActions>
              </div>{" "}
            </Form>
          )}
        </Formik>
        {isLoading && <SpinnerPage />}
        {/* <ToastContainer /> */}
      </React.Fragment>
    </I18nextProvider>
  );
};

export default PaymentPlansOptionsPopup;
