import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React from "react";
import { Translation } from "react-i18next";
import { GoogleAnalystics } from "../../../../Common/GoogleAnalystics";
import { requestInfo_PP } from "../../../../Common/CommonFn";
import { paymentSuccessService } from "../../../../services/paymentSuccessService";
import { pdfservices } from "../../../../admin_services/pdfservices";
import SpinnerPage from "../../../../Common/PP_loader";
import i18n from "../../../../i18n";
import { I18nextProvider } from "react-i18next";
import moment from "moment";

import $ from "jquery";

import { CancelButton, NextButton, TxnDoneButton } from "../../../../assets/MaterialControl";
import ConfirmationDialog from "../../../../containers/Admin_Report/ConfirmationDialog";
import QuickSurvey from "./QuickSurvey";
import { SurveyService } from "../../../../services/SurveyService";
import { updateCampignViewPayment } from "../../../../util/purlFunctions";

class PP_Success_Receipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      debitFrom: "123456789123",
      showForm: true,
      documentid: "",

      successMessage: <Translation>{(t, { i18n }) => <label>{t("receipt_transactionSuccess")}</label>}</Translation>,
      failureMessage: <Translation>{(t, { i18n }) => <label>{t("receipt_transactionPending")}</label>}</Translation>,
      intervalstart: true,
      downloadLink: "",
      showgloader: true,
      viewReciept: false,
      surveyPopup:
        sessionStorage.getItem("currentUserRole") === "Customer" || sessionStorage.getItem("currentUserRole") === "quickpay"
          ? true
          : false,
      surveyQuestions: [],
    };
  }
  componentDidMount() {
    document.title = "MED-1 Quick Pay - Transaction Successful";
    GoogleAnalystics.GoogleAnalysticsLog("pageview");
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });

    /********  PDF Download  */

    var context = this;
    var timeout = setInterval(function () {
      if (sessionStorage.getItem("transactionId")) {
        var PassValues = {
          requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
          documentDetails: {
            uniqueId: sessionStorage.getItem("transactionId"),
          },
        };

        var userId = "1234";
        paymentSuccessService.checkReceipt(PassValues, userId, (res) => {
          if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
            const campaignCode = sessionStorage.getItem("campaignCode");
            let passValuesUCVPC = {
              campaignCode: campaignCode || "",
              campaignViewId: sessionStorage.getItem("campaignViewId")
                ? JSON.parse(sessionStorage.getItem("campaignViewId"))
                : "", //mandatory
              medAccountNumber: sessionStorage.getItem("payerinfo")
                ? JSON.parse(sessionStorage.getItem("payerinfo"))?.accountReferenceNumber
                : "", // Non-Mandatory
              paidAmount: sessionStorage.getItem("amounttopay") ? JSON.parse(sessionStorage.getItem("amounttopay")) : null, //mandatory
              partyId: sessionStorage.getItem("order_FP")
                ? JSON.parse(sessionStorage.getItem("order_FP"))[0].partyId
                : JSON.parse(sessionStorage.getItem("verifypayer"))
                ? JSON.parse(sessionStorage.getItem("verifypayer")).partyId
                : null, //mandatory
              payPlanAdded: "N", //mandatory
              paymentComplete: "Y", //mandatory
              scheduledAmount: 0,
            };
            updateCampignViewPayment(passValuesUCVPC, (res) => {});

            clearInterval(timeout);

            var documentid = res["data"]?.documentDetails?.pdfDocumentId;
            context.setState({
              documentid: documentid,
            });

            const PassValues = {
              documentId: documentid,
              bucketTag: "revone-bucket-tag",
            };
            const UserId = "1212";
            pdfservices.downloadpdf(
              PassValues,
              UserId,
              (res) => {
                if (res["data"]?.status === "success") {
                  // success("Receipt Downloaded Successfully", successNotification);
                  context.setState({
                    downloadLink: res["data"]?.responseObject?.documentUrl,
                    showgloader: false,
                  });
                } else if (res["data"]?.status === "fail") {
                  context.setState({
                    showgloader: false,
                  });
                } else {
                  context.setState({
                    showgloader: false,
                  });
                }
              },
              (error) => {
                console.log(error);
              },
            );
          } else {
          }
        });
      }
    }, 2000);

    SurveyService.getQuestions((res) => {
      if (res.status === 200) {
        this.setState({ surveyQuestions: res?.data });
      } else {
        this.setState({ surveyQuestions: [] });
      }
    });
  }
  checkpdf = () => {
    if (sessionStorage.getItem("transactionId")) {
      var PassValues = {
        requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        documentDetails: {
          uniqueId: sessionStorage.getItem("transactionId"),
        },
      };

      var userId = "1234";
      paymentSuccessService.checkReceipt(PassValues, userId, (res) => {
        if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
          this.download();
        } else {
        }
      });
    }
  };

  _handleCloseViewReciept = () => {
    this.setState({ viewReciept: false });
  };

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  downloaddisable(documentid) {
    return false;
  }

  download() {
    const PassValues = {
      documentId: this.state.documentid,
      bucketTag: "revone-bucket-tag",
    };
    const UserId = "1212";
    pdfservices.downloadpdf(
      PassValues,
      UserId,
      (res) => {
        if (res["data"]?.status === "success") {
          this.setState({
            downloadLink: res["data"].responseObject.documentUrl,
            showgloader: false,
          });
        } else if (res["data"]?.status === "fail") {
          this.setState({
            showgloader: false,
          });
        } else {
          this.setState({
            showgloader: false,
          });
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }

  surveyPopup = () => {
    this.setState({
      surveyPopup: true,
    });
  };
  handleCloseSurvey = () => {
    SurveyService.postSurvey(
      {
        feedbackId: 0,
        paymentRPSurveyAnswersRequest: [
          {
            answerText: "",
            questionId: 1,
          },
          {
            answerText: "",
            questionId: 2,
          },
        ],
        userId: JSON.parse(sessionStorage.getItem("loginDetails"))?.partyId,
      },
      (res) => {
        if (res?.status === 200) {
          console.log(res?.data);
        }
      },
    );
    this.setState({
      surveyPopup: false,
    });
  };
  onSubmitClose = () => {
    this.setState({
      surveyPopup: false,
    });
  };

  render() {
    const data = JSON.stringify(this.props.paymentSuccessData);
    var showloader = true;
    if (data) {
      var receiptdata = this.props.paymentSuccessData.receiptDetail;
      sessionStorage.setItem("recieptData", JSON.stringify(receiptdata));
      var responcedata = this.props.paymentSuccessData.responseInfo;
      if (receiptdata) {
        var TxnId = this.props.paymentSuccessData.receiptDetail.paymentGatewayTxnRefNo;
        var Date = this.props.paymentSuccessData.receiptDetail.paymentTxnDatetime; //paymentTxnDatetime
        var Email = this.props.paymentSuccessData.receiptDetail.emailId;
        var PhoneNumber = this.props.paymentSuccessData.receiptDetail.phoneNumber;
        var PaymentType = this.props.paymentSuccessData.receiptDetail.paymentType;
        var DebitFrom = this.props.paymentSuccessData.receiptDetail.debitFrom.replace(/\d(?=\d{4})/g, "*");
        var PaidTo = this.props.paymentSuccessData.receiptDetail.paidTo;
        var PaidAmount = this.props.paymentSuccessData.receiptDetail.paidAmount;

        var pdfDocumentId = this.props.paymentSuccessData.receiptDetail.pdfDocumentId;
        showloader = false;
        var AuthorizationNumber = this.props.paymentSuccessData.receiptDetail.authorizationCode;
      }
      if (responcedata) {
        var status = this.props.paymentSuccessData.responseInfo.status;
      }
    }
    return (
      <>
        {showloader === true ? (
          <SpinnerPage />
        ) : (
          <div>
            <div className="main-container">
              <div className="mb-4 payment-success-container">
                <i class="fas fa-check-circle"></i>
                <Typography variant="h2" className="successfull-text">
                  Payment Successful
                </Typography>
              </div>
              <div className="success-transaction mb-4">
                <Typography variant="h9" component="h3" className="thankyou-text">
                  {`Thank you! Your payment of $${PaidAmount} has been received.`}
                </Typography>
                {/* <span style={{ display: "flex", justifyContent: "center" }}>{`Remaining Outstanding Amount is $ ${Number(
                  sessionStorage.getItem("TotalAmount"),
                )}`}</span> */}
              </div>
              <div className="confirm-transaction-ids mb-1 form-row ">
                <div className="confirmation-number col-sm-4 col-md-4">
                  <p>Confirmation No :</p>
                  <div className="value" variant="h9" component="h5">
                    {AuthorizationNumber}
                  </div>
                </div>
                <div className="transaction-details col-sm-8 col-md-8">
                  <p>Transaction Id :</p>
                  <div className="value" variant="h9" component="h5">
                    {TxnId}
                  </div>
                </div>
              </div>
              <div className="">
                {/* <Typography variant="h9" component="h4" style={{ color: "black" }}>
                  Payment Details
                </Typography> */}
              </div>
              <div className="transaction-details-container">
                <div className="form-row">
                  <div className="col-sm-5 col-md-5 col-xl-6">
                    <div className="details">
                      <div className="label">Transaction Date :</div>
                      <div className="value">{Date}</div>
                    </div>
                  </div>
                  <div className="col-sm-7 col-md-7 col-xl-6 ">
                    <div className="details">
                      <div className=" label">Email Address :</div>
                      <div className="value">{Email}</div>
                    </div>
                  </div>
                  <div className="col-sm-5 col-md-5 col-xl-6">
                    <div className="details">
                      <div className=" label">Phone Number :</div>
                      <div className="value">{PhoneNumber}</div>
                    </div>
                  </div>
                  <div className="col-sm-7 col-md-7  col-xl-6 ">
                    <div className="details">
                      <div className=" label">Payment Method :</div>
                      <div className="value">{PaymentType}</div>
                    </div>
                  </div>
                  <div className="col-sm-5 col-md-5  col-xl-6">
                    <div className="details">
                      <div className=" label">From Account :</div>
                      <div className="value">{DebitFrom}</div>
                    </div>
                  </div>
                  <div className="col-sm-7 col-md-7  col-xl-6">
                    <div className="details">
                      <div className=" label"> Paid To :</div>
                      <div className="value">{PaidTo}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2 d-flex flex-column justify-content-center align-items-center">
                {/* <span>{`You made a payment of $ ${PaidAmount}`}</span> */}
                {/* <span>{`Remaining Outstanding Amount is $ ${Number(sessionStorage.getItem("TotalAmount"))}`}</span> */}
              </div>
              <div className="buttons mt-3 text-center ">
                <button
                  className="primary-button themeing-buttons mx-1"
                  onClick={() => this.setState({ viewReciept: true })}>
                  View Reciept
                </button>

                <button
                  className="primary-button themeing-buttons mx-1"
                  onClick={() => {
                    sessionStorage.getItem("loginDetails")
                      ? this.props.history.push({
                          pathname: sessionStorage.getItem("currentUserRole") === "Admin" ? "/pa/billing" : "/home/billing",
                        })
                      : this.props.history.push({
                          pathname: "/payment-outstanding",
                        });
                  }}>
                  Done
                </button>
              </div>
            </div>

            <Dialog
              open={this.state.viewReciept}
              onClose={this._handleCloseViewReciept}
              scroll={"body"}
              maxWidth="md"
              fullWidth
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              className="receipt-dialog-container">
              <DialogTitle id="customized-dialog-title" onClose={this._handleCloseViewReciept} className="dialog-title">
                <span className="payment-receipt">Payment Receipt</span>
              </DialogTitle>
              <DialogContent dividers={"body"} className="dialog-body">
                <React.Fragment>
                  <div className="receipt-box">
                    <div className="form-row">
                      <div className="col-5  mb-3">
                        <div className="receipt-label label"> Confirmation No</div>
                      </div>

                      <div className="col-7 mb-3">
                        <div className="receipt-value value">{AuthorizationNumber}</div>
                      </div>

                      <div className="col-5 mb-3">
                        <div className="receipt-label label"> Transaction Id</div>
                      </div>

                      <div className="col-7 mb-3 ">
                        <div className="receipt-value value">{TxnId}</div>
                      </div>

                      <div className="col-5 mb-3">
                        <div className="receipt-label label">Transaction Date</div>
                      </div>

                      <div className="col-7 mb-3 ">
                        <div className="receipt-value value">{moment(Date).format("MM/DD/YYYY")}</div>
                      </div>

                      <div className="col-5 mb-3">
                        <div className="receipt-label label">Email Id</div>
                      </div>

                      <div className="col-7 mb-3 ">
                        <div className="receipt-value value">{Email}</div>
                      </div>

                      <div className="col-5 mb-3">
                        <div className="receipt-label label">Phone Number</div>
                      </div>

                      <div className="col-7 mb-3">
                        <div className="receipt-value value"> {this.formatPhoneNumber(PhoneNumber)}</div>
                      </div>

                      <div className="col-5 mb-3">
                        <div className="receipt-label label">Payment Method</div>
                      </div>

                      <div className="col-7 mb-3 ">
                        <div className="receipt-value value">{PaymentType}</div>
                      </div>

                      <div className="col-5 mb-3">
                        <div className="receipt-label label">From Account</div>
                      </div>

                      <div className="col-7 mb-3 ">
                        <div className="receipt-value value">{DebitFrom}</div>
                      </div>

                      <div className="col-5 mb-3">
                        <div className="receipt-label label">Paid To</div>
                      </div>

                      <div className="col-7 mb-3 ">
                        <div className="receipt-value value">{PaidTo}</div>
                      </div>

                      <div className="col-5 mb-3">
                        <div className="receipt-label label">
                          {/* <Translation> */}
                          Payment Amount
                        </div>
                      </div>

                      <div className="col-7 mb-3">
                        <div className="receipt-value value">$ {PaidAmount}</div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              </DialogContent>
              <DialogActions>
                <div className="w-100 d-flex justify-content-center align-items-center my-2">
                  <button className="themeing-buttons primary-button" onClick={() => this._handleCloseViewReciept()}>
                    Close
                  </button>
                </div>
              </DialogActions>
            </Dialog>
            <ConfirmationDialog
              open={this.state.surveyPopup}
              handleClose={this.handleCloseSurvey}
              handleCancel={this.handleCloseSurvey}
              noActions={true}
              style={{ padding: "0px !important" }}>
              <QuickSurvey
                handleClose={this.handleCloseSurvey}
                surveyQuestions={this.state.surveyQuestions}
                title="Patient Experience Survey"
                onSubmitClose={this.onSubmitClose}
              />
            </ConfirmationDialog>
          </div>
        )}
      </>
    );
  }
}
export default PP_Success_Receipt;
