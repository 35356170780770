import { loginService } from "../core_services/adminloginService";
export const USERS_LOGIN_REQUEST = "USERS_LOGIN_REQUEST";
export const USERS_LOGIN_SUCCESS = "USERS_LOGIN_SUCCESS";
export const USERS_LOGIN_FAILURE = "USERS_LOGIN_FAILURE";
export const USER_LOGOUT = "USER_LOGOUT";

export function loginpending() {
  return {
    type: USERS_LOGIN_REQUEST,
  };
}

export function login(userData, fn) {
  return (dispatch) => {
    dispatch(loginpending());
    loginService.login(userData, (res) => {
      dispatch(loginSucess(res.data.responseObject));
      fn(res.data);
    });
  };
}

export function loginSucess(userdata) {
  return {
    type: USERS_LOGIN_SUCCESS,
    payload: userdata,
  };
}

export function loginError(error) {
  return {
    type: USERS_LOGIN_FAILURE,
    error: error,
  };
}
export function logout() {
  return {
    type: USER_LOGOUT,
  };
}
export function logoutUser({ userName }, fn) {
  return (dispatch) => {
    loginService.logout({ userName }, (res) => {
      dispatch(logout(JSON.stringify(res.responseObject)));
      fn(res.data);
    });
  };
}
