import React, { Component } from "react";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import AdminSurveyDashboard from "../../routes/Pages/PAScreens/AdminSurveyDashboard";
class AdminSurveyDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="transaction-card" role="main">
          <div className="main-container-card">
            <div role="heading" aria-level="1" className="heading-style mb-3">
              <DonutSmallIcon className="mr-2" />
              <h2 className="heading-style "> Survey Dashboard</h2>
            </div>
            <div>
              <AdminSurveyDashboard />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AdminSurveyDashboardContainer;
