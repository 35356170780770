/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { Translation } from "react-i18next";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExapandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
//import { settlementService } from "../services/settlementService";
import { errorNotification } from "../../../components/PP_Notification/PP_Notification";
// import { requestInfo_PP } from "../commons/commonfunctions/functions";
//import UpdateTrackingPage from "containers/UpdateTrackingPage";
import Footer from "../../../components/admin_footer/footer";
import { requestInfo_PP } from "../../../Common/CommonFn";
import { settlementService } from "./../../../services/settlementServices";
import HeaderQP from "../../../components/admin_header/headerQP";

const SettlementPayment = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [underQP, setUnderQP] = useState(() => JSON.parse(sessionStorage.getItem("underQuickPay")));
  const handleContinueSettlment = () => {
    props.history.location.state.paymentMethod === "echeck" || props.history.location.state.paymentMethod.includes("&")
      ? props.history.push({
          pathname: "/payment-echeck",
          state: {
            paymentMethod: props.history.location.state.paymentMethod,
            payInFuture: false,
            PassValues: {},
          },
        })
      : props.history.push({
          pathname: "/payment-Creditcard",
          state: {
            paymentMethod: props.history.location.state.paymentMethod,
            payInFuture: false,
            PassValues: {},
          },
        });
  };

  const getSettlementStatus = (seedItem1) => {
    // eslint-disable-next-line no-unused-expressions
    props.history.location.state?.settlementData?.settlementResponseModel?.map((each) => {
      if (each.settlementStatus === "SETTLED") {
        return each.masterAccountReferenceNumber === seedItem1?.seedAccountNumber;
      }
    });
    return false;
  };

  return (
    <>
      {underQP && <HeaderQP />}
      <div>
        {/* {underQP ? (
        <HeaderQP />
      ) : (
        <>
          <Header />
          <LeftSideMenu />
        </>
      )} */}

        <div
          className={underQP ? "qp-homepage-container transaction-card" : "transaction-card"}
          id={underQP && "slidepagearea"}>
          <div className="main-container-card settlement-payment-content">
            <div className="listed-description">
              {" "}
              <Translation>
                {(t, { i18n }) => (
                  // eslint-disable-next-line jsx-a11y/label-has-associated-control
                  <h5 aria-level="1">
                    Below is a list of your accounts eligible for settlement. Select a specific account and propose your
                    settlement offer by entering the settlement amount, Otherwise, you can continue without settlement.
                  </h5>
                )}
              </Translation>
            </div>
            <div className="settlement-payment-header" aria-label="billDetails">
              <Table className="settlement-table">
                <TableBody>
                  <TableRow className="settlement-heading-title">
                    <TableCell>
                      <Translation>{(t, { i18n }) => <span>{"Account Number"}</span>}</Translation>
                    </TableCell>
                    <TableCell>
                      <Translation>{(t, { i18n }) => <span>{"Outstanding Balance"}</span>}</Translation>
                    </TableCell>
                    <TableCell>
                      <Translation>{(t, { i18n }) => <span>{"Settlement"}</span>}</Translation>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>

            {(props.history.location.state?.settlementOptionList || []).map((seedItem, index) => {
              return (
                <Accordion defaultExpanded className="accordion-section" key={seedItem.seedAccountNumber}>
                  <AccordionSummary className="accordion-header" expandIcon={<ExapandMoreIcon />}>
                    <p>{seedItem?.seedAccountNumber}</p>
                    <p>$ {seedItem?.oustandingBalance.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                    <div>
                      <Button
                        className="settle-button"
                        variant="outlined"
                        color="primary"
                        disable={getSettlementStatus(seedItem)}
                        onClick={() => {
                          let passValues = {
                            requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token")),
                            settlementRequest: {
                              accountNumber: seedItem?.seedAccountNumber,
                            },
                          };
                          settlementService.trackSettlementOption(
                            passValues,
                            0,
                            (res) => {
                              if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
                                props.history.push("/home/settlementPaymentOutstanding", {
                                  settlementType: seedItem,
                                  paymentMethod: props.history.location.state.paymentMethod,
                                });
                              } else {
                                if (res["data"]?.responseInfo?.status === "FAILED") {
                                  props.history.push("/home/settlementPaymentOutstanding", {
                                    settlementType: seedItem,
                                    paymentMethod: props.history.location.state.paymentMethod,
                                  });
                                }
                              }
                            },
                            (error) => {
                              error("Something went wrong! Please try again", errorNotification);
                            },
                          );
                        }}>
                        {getSettlementStatus(seedItem) ? "settled" : "settle"}
                      </Button>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails role="region">
                    <Table className="settlement-table">
                      <TableBody>
                        {seedItem?.bills.map((billitem, index) => {
                          return (
                            <TableRow key={billitem?.billType + index}>
                              <TableCell>
                                <div className={` ${billitem?.billType === "Legal" ? "red-text" : ""}`}>
                                  {billitem.accountReferenceNumber}
                                </div>
                              </TableCell>

                              <TableCell>
                                <div className={` ${billitem?.billType === "Legal" ? "red-text" : ""}`}>
                                  $
                                  {Number(billitem.currentBalanceAmount).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                                </div>
                              </TableCell>

                              <TableCell> </TableCell>
                              <TableCell className="settle-block-div"></TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              );
            })}
            <div className="d-flex justify-content-end mt-3">
              <button
                className="primary-button themeing-buttons"
                variant="outlined"
                color="primary"
                onClick={handleContinueSettlment}>
                CONTINUE WITHOUT SETTLEMENT
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SettlementPayment;
