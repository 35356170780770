import RestDataSource from "./restdatasource";
import { loginService } from "./loginService";
import { Global_var } from "../global/global_var";

import {
  error,
  success,
  warning,
  warningNotification,
  errorNotification,
  successNotification,
} from "../components/PP_Notification/PP_Notification";

export const echequePaymentService = {
  getEchequePayment,
  saveEchequePayment,
  deletePaymentMethod,
};

function deletePaymentMethod(passValues, paymentMethod, paymentId, instrumentId, userId, fn) {
  var url =
    Global_var.BASEURL + Global_var.URL_DELETE_PAYMENT_METHOD + `/${paymentMethod}` + `/${paymentId}` + `/${instrumentId}`;
  return new RestDataSource(url, userId, fn).Store(passValues, (res) => {
    // debugger;
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
          window.location("/");
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-token", res.headers.token);
        }
        fn(res);
      }
    }
  });
}

function getEchequePayment(echequeInfo, userId, fn, fnError) {
  //  debugger
  //     loginService.TokenUser((res) => {
  //       if (res.status === "success") {

  //         sessionStorage.setItem("jwt-token", res.responseObject);
  //     debugger
  // var url =  Global_var.URL_GET_ECHEQUE_DETAILS;
  var url = Global_var.BASEURL + Global_var.URL_GET_ECHEQUE_DETAILS;
  return new RestDataSource(url, userId, fn).Store(echequeInfo, (res) => {
    // debugger
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-token", res.headers.token);
        }
        fn(res);
      }
    }
  });
  //   }
  // }, fnError);
}
function saveEchequePayment(echequeInfo, userId, fn, fnError) {
  // loginService.TokenUser((res) => {
  //   if (res.status === "success") {

  //     sessionStorage.setItem("jwt-token", res.responseObject);

  // var url = Global_var.URL_SAVE_ECHEQUE_DETAILS;
  var url = Global_var.BASEURL + Global_var.URL_SAVE_ECHEQUE_DETAILS;
  return new RestDataSource(url, userId, fn).Store(echequeInfo, (res) => {
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-token", res.headers.token);
        }
        fn(res);
      }
    }
  });
  //   }
  // }, fnError);
}
