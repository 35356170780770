/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import SecurityImageContainer from "../../../containers/securityImage/securityImageContainer";
import { connect } from "react-redux";
import { login, logoutUser } from "../../../action/loginAction";
import { getImage } from "../../../action/securityImageAction";
import { bindActionCreators } from "redux";
import { getLogin } from "../../../reducer/loginReducer";
import { identifyPayerService } from "../../../services/identifyPayerService";
import { securityImageService } from "../../../core_services/securityImageService";
import { partyGetService } from "../../../core_services/partyService";
import moment from "moment";
import { I18nextProvider } from "react-i18next";
import i18n from "../../../i18n";
import { requestInfo_PP } from "../../../Common/CommonFn";
import { whiteLabelService } from "../../../admin_services/whiteLabelService";
import { stateList, countryList } from "../../../containers/RegionList";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { getIn } from "formik";
import { FormControl, FormHelperText, FormLabel, MenuItem, Select, TextField, Tooltip } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { IconButton } from "@material-ui/core";
import { handleSuccess, handleError, handleWarning } from "../../../components/Notifications/Toast";
import PageLoader from "./../../../components/ContentLoader/PageLoader";
import { Oval } from "react-loader-spinner";
import { RegionManagementService } from "../../../admin_services/regionManagementService";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";

class MyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: true,
      imgLoader: false,
      pageLoader: false,
      disableSubmit: true,
      setDisableSubmit: true,
      recaptcha: "",
      showssn: false,
      showdob: false,
      showemail: false,
      showcontact: false,
      showloader: false,
      submitted: false,
      firstName: "",
      middlename: "",
      lastname: "",
      dob: "",
      gender: "",
      ssn: "",
      emailId: "",
      phoneno: "",
      country: "",
      state: "",
      city: "",
      streetaddress: "",
      streetaddress2: "",
      zipcode: "",
      countryList: [],
      cityList: [],
      pwdlength: false,
      pwdonecap: false,
      pwdonesmall: false,
      pwdonenum: false,
      pwdonespl: false,
      pwdnospace: false,
      toggleform: false,
      userpartyId: "",
      addressid: "",
      selectedImage: "",
      showSsn: true,
      showPatientDob: true,
    };
  }

  componentDidMount() {
    this.setState({ imgLoader: true });
    document.title = "MED-1 Quick Pay - Register";
    window.scrollTo(0, 0);
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });

    sessionStorage.setItem("changesecurityimage", false);

    const passValues1 = {
      partyTypeId: "100",
    };
    partyGetService.partyGetList(
      passValues1,

      (res) => {
        if (res.status === "success") {
          this.setState({ partydata: res.responseListObject[0].partyId });
          var dataimg = this.state.partydata;
          this.getImg();
        } else if (res["data"].responseInfo.status === "FAILED") {
          handleWarning("Oops..! " + res.data.responseInfo.responseMessage);
        } else {
          handleError("Something went wrong..! " + res.data.responseInfo.responseMessage);
        }
      },
      (error) => {
        console.log(error);
      },
    );

    this.getbilldetails();
  }

  gotohome = () => {
    window.location.replace("/home/billing");
  };
  getImg = () => {
    var userinfo = JSON.parse(sessionStorage.getItem("loginDetails"));

    const passValues = {
      partyId: this.state.partydata,
      pageName: "AuthSecureImage",
      sectionName: "AuthSecureImage",
    };
    securityImageService.securityImageList(
      passValues,

      (res) => {
        if (res.data.status === "success") {
          this.setState({
            imgdata: res.data.responseObject.imageList[0].documentUrl,
            img2: res.data.responseObject.imageList[1].documentUrl,
            img3: res.data.responseObject.imageList[2].documentUrl,
            img4: res.data.responseObject.imageList[3].documentUrl,
            imgdataid: res.data.responseObject.imageList[0].documentId,
            img2id: res.data.responseObject.imageList[1].documentId,
            img3id: res.data.responseObject.imageList[2].documentId,
            img4id: res.data.responseObject.imageList[3].documentId,
            docid: res.data.responseObject.imageList[0].documentId,
          });
          this.setState({ imgLoader: false });
        } else if (res["data"].responseInfo.status === "FAILED") {
          this.setState({ imgLoader: false });
          handleWarning("Oops..! " + res.data.responseInfo.responseMessage);
        } else {
          this.setState({ imgLoader: false });
          handleError("Something went wrong..! " + res.data.responseInfo.responseMessage);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };
  selectimg1 = (value) => {
    this.setState({
      selectedImage: this.state.imgdata,
    });
  };

  handleClick = (value, key) => () => {
    this.setState({
      selectedImage: value,
      docid: key,
      chosenimage: true,
    });
    sessionStorage.setItem("changesecurityimage", true);
  };
  getAddressObject = (address_components) => {
    const ShouldBeComponent = {
      street_number: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      province: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      country: ["country"],
    };

    let address = {
      street_number: "",
      postal_code: "",
      street: "",
      province: "",
      city: "",
      country: "",
    };

    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "country") {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });

    // Fix the shape to match our schema
    address.address = address?.street_number + " " + address?.street;
    delete address.street_number;
    delete address.street;
    if (address.country === "US") {
      address.state = address.province;
      delete address.province;
    }
    return address;
  };
  getGoogleApiInfo = async (values, val) => {
    const geocodeObj = val && val.value && (await geocodeByPlaceId(val.value.place_id));
    const addressObject = geocodeObj && this.getAddressObject(geocodeObj[0].address_components);
    addressObject
      ? this.setState({
          ...values,
          country:
            addressObject?.country.toLocaleLowerCase() === "us" || addressObject.country.toLocaleLowerCase() === "usa"
              ? "united states of america"
              : addressObject.country,
          state: addressObject?.state,
          city: addressObject?.city,
          zipCode: addressObject?.postal_code,
          address1: addressObject?.address.trim(),
          address2: "",
          googleaddress: val,
        })
      : this.setState({
          ...values,
          googleaddress: "clear",
        });
  };

  verifyemail = () => {
    var userinfo = JSON.parse(sessionStorage.getItem("loginDetails"));
    const emaildata = {
      partyId: userinfo.partyId,
      emailId: userinfo.emailId,
    };
    var userId = userinfo.userId;
    whiteLabelService.isEmailVerified(
      emaildata,
      userId,
      (res) => {
        if (res["data"].status === "success") {
          if (res["data"].responseObject.isVefified == "1") {
            this.setState({
              emailverified: true,
            });
          } else {
            this.setState({
              emailverified: false,
            });
          }
        } else if (res["data"].status === "fail") {
          this.setState({
            emailverified: false,
          });
        } else {
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  verifymobile = () => {
    var userinfo = JSON.parse(sessionStorage.getItem("loginDetails"));

    const emaildata = {
      partyId: userinfo.partyId,
      mobileNumber: this.state.phoneno,
    };
    var userId = userinfo.userId;
    whiteLabelService.isMobileVerified(
      emaildata,
      userId,
      (res) => {
        if (res["data"].status === "success") {
          if (res["data"].responseObject.isVefified == "1") {
            this.setState({
              mobileverified: true,
            });
          } else {
            this.setState({
              mobileverified: false,
            });
          }
        } else {
          this.setState({
            mobileverified: false,
          });
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  getbilldetails = () => {
    this.setState({ pageLoader: true });

    var logindetails = JSON.parse(sessionStorage.getItem("loginDetails"));
    var userName = logindetails.userName;
    this.props.getImage({ userName }, (imageData) => {});

    const PassValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      partySearch: {
        partyId: logindetails.partyId,
        uniqueIdentifierId: "",
        dob: "",
        firstName: "",
        lastName: "",
        contactEmail: "",
        contactNo: "",
        partyTypeId: "",
        limit: "1",
        offset: "0",
      },
    };

    const UserId = logindetails.userId;
    identifyPayerService.getprofiledata(
      PassValues,
      UserId,
      (res) => {
        if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
          this.setState({ pageLoader: false });
          var partydata = res["data"]?.partyProfiles[0];

          sessionStorage.setItem("verifypayer", JSON.stringify(res["data"]?.partyProfiles[0]));
          var myDate = res["data"]?.partyProfiles[0]?.dob;
          var mydate1 = "";
          if (myDate) {
            mydate1 = myDate.split(" ")[0];

            mydate1 = mydate1.split("/");

            mydate1 = mydate1[1] + "/" + mydate1[0] + "/" + mydate1[2] + " " + "00" + ":" + "00" + ":" + "00";
          }

          var payeraddrdata = partydata;

          if (payeraddrdata.addressVo.length > 0) {
            const selectedState = stateList.filter(
              (item) => item?.stateName?.toLocaleLowerCase() === payeraddrdata?.addressVo[0]?.state?.toLocaleLowerCase(),
            );
            var PassValues = {
              stateId: selectedState[0]?.stateId,
            };
            const UserId = "1234";
            RegionManagementService.getCity(PassValues, UserId, (res) => {
              this.setState({
                cityList: res.data.responseListObject,
                stateid: PassValues.stateId,
              });
            });
          }

          this.setState((prvState) => ({
            ...prvState,
            firstName: res["data"]?.partyProfiles[0]?.firstName,
            middlename: res["data"]?.partyProfiles[0]?.middleName,
            lastname: res["data"]?.partyProfiles[0]?.lastName,
            dob: mydate1,
            dob1: mydate1,
            gender: "",
            ssn: res["data"]?.partyProfiles[0]?.uniqueIdentifierId,
            emailId: res["data"]?.partyProfiles[0]?.contactEmail,
            phoneno: res["data"]?.partyProfiles[0]?.contactNo,
            countryid: partydata.addressVo[0]?.countryId,
            country: partydata.addressVo[0]?.country?.toLocaleLowerCase(),
            state: partydata.addressVo[0]?.state,
            city: partydata.addressVo[0]?.city,
            cityid: partydata.addressVo[0]?.cityId,
            address1: partydata.addressVo[0]?.street + ", " + (partydata.addressVo[0]?.street2 || ""),
            address2: "",
            zipCode:
              partydata.addressVo[0]?.postalCode?.length > 5 && !partydata?.addressVo[0]?.postalCode?.includes("-")
                ? partydata.addressVo[0]?.postalCode?.substring(0, 5)?.concat("-") +
                  partydata.addressVo[0]?.postalCode?.substring(5, partydata.addressVo[0]?.postalCode.length)
                : partydata.addressVo[0].postalCode,
            stateList: stateList || [],
            toggleform: true,
            userpartyId: res["data"]?.partyProfiles[0]?.partyId,

            addressid: partydata.addressVo[0].addressId,
          }));

          sessionStorage.setItem("userpartyid", this.state.userpartyId);
          sessionStorage.setItem("countrycode", this.state.countrycoder);
          sessionStorage.setItem("mobileno", this.state.phoneno);

          this.verifyemail();
          this.verifymobile();
        } else if (res["data"]?.responseInfo?.status === "FAILED") {
          this.setState({ pageLoader: false });
          handleWarning("Please enter valid details!");
        } else {
          this.setState({ pageLoader: false });
          handleWarning("Please enter valid details!");
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  _createUser = (values, { actions }) => {
    var cd = new Date();
    var mm = cd.getMonth() + 1;
    var dd = cd.getDate();
    var yy = cd.getFullYear();
    var hh = cd.getHours();
    var mn = cd.getMinutes();
    var ss = cd.getSeconds();

    var todaydatenew =
      (dd > 9 ? dd : "0" + dd) +
      "/" +
      (mm > 9 ? mm : "0" + mm) +
      "/" +
      yy +
      " " +
      (hh > 9 ? hh : "0" + hh) +
      ":" +
      (mn > 9 ? mn : "0" + mn) +
      ":" +
      (ss > 9 ? ss : "0" + ss);

    var logindetails = JSON.parse(sessionStorage.getItem("loginDetails"));

    var newuserid = logindetails.userId;
    const newpassValues = {
      userId: newuserid,
      securityImagDocId: this.state.docid,
    };

    if (this.state.selectedImage !== "") {
      securityImageService.updateSecurityImageList(
        newpassValues,

        (res) => {
          if (res.data.status === "success") {
            var context = this;
          } else if (res["data"].responseInfo.status === "FAILED") {
            handleWarning("Oops..! " + res.data.responseInfo.responseMessage);
          } else {
            handleError("Something went wrong..! " + res.data.responseInfo.responseMessage);
          }
        },
        (error) => {
          console.log(error);
        },
      );
    }

    const passValuesparty = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      partyProfiles: [
        {
          partyId: this.state.userpartyId,
          partyTypeId: 102,
          appId: 11101,
          buId: 2,
          subBuId: 0,
          partyAttribute: [],
          lastName: values.lastname,
          firstName: values.firstname,
          contactNo: values.usermobile,
          dob: this.state.newdob
            ? moment(this.state.newdob).format("MM/DD/YYYY") + " 00:00:00"
            : moment(values.dob).format("MM/DD/YYYY") + " 00:00:00",
          contactEmail: values.useremailid,
          isActive: 1,
          uniqueIdentifierId: values.ssn,
          addressVo: [
            {
              addressId: this.state.addressid,
              addressType: "HOME",
              appId: 11101,
              buId: 2,
              subBuId: 0,
              partyId: this.state.userpartyId,
              street: values.address1,
              street2: "",
              street3: null,
              city: values.city,
              state: this.state.state,
              country: this.state.country,
              postalCode: values.zipCode.replace("-", ""),
              cityId: this.state.cityid,
              stateId: this.state.stateid,
              countryId: this.state.countryid,
              locationLatLong: null,
              createdBy: 1,
              isActive: 1,
              createdDate: todaydatenew,
              lastModifiedBy: 1,
              lastModifiedDate: todaydatenew,
              errorMessage: null,
            },
          ],
        },
      ],
    };
    partyGetService.UpdatePatientData(
      passValuesparty,
      (res) => {
        if (res["data"]?.responseInfo.status === "SUCCESSFUL") {
          handleSuccess("Profile updated successfully");

          window.location.replace("/home/billing");
          sessionStorage.setItem("profileEmail", res["data"]?.partyProfiles[0]?.contactEmail);
        } else if (res["data"].responseInfo.status === "FAILED") {
          handleWarning(res["data"]?.responseInfo.responseMessage[0]);
        } else {
          handleWarning("Technical issue, Try again later!");
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  payerValidation = Yup.object().shape({
    useremailid: Yup.string().trim().email("Email Address must be valid").required("Email Address Required"),
    usermobile: Yup.string().trim().min(10).max(10).required("Phone Number Required"),
    country: Yup.string().required("Please Select Country"),
    address1: Yup.string().trim().required("Street Address Required"),
    state: Yup.string().required("Please Select State"),
    city: Yup.string()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters , dash(-), dot(.), tilled and apostrophes(') are allowed")
      .required("City Is Required"),
    zipCode: Yup.string()
      .nullable()
      .trim()
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/g, "Please enter a valid ZIP Code")
      .test("Invalid ZIP Code", (value) => !/^([0-9])\1{4,}$/.test(value))
      .required("ZIP Code is  required"),
  });
  handleCardNumber = (e) => {
    this.setState({ cardnumber: !this.state.cardnumber });
  };
  handleCardNumbers = (e) => {
    this.setState({ cardnumbers: !this.state.cardnumbers });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  render() {
    return (
      <I18nextProvider i18n={i18n}>
        <React.Fragment>
          <div className="transaction-card">
            <div className=" main-container-card main-container-sm" role="main">
              <h4 className="update-profile-heading">My Profile</h4>

              <div>
                <Formik
                  enableReinitialize
                  initialValues={{
                    firstname: this.state.firstName ? this.state.firstName : "",
                    firstname1: this.state.firstName ? this.state.firstName : "",
                    middlename: this.state.middleName ? this.state.middleName : "",
                    lastname: this.state.lastname ? this.state.lastname : "",
                    lastname1: this.state.lastname ? this.state.lastname : "",
                    dob: this.state.dob ? moment(this.state.dob).format("MM/DD/YYYY") : "",
                    dob1: this.state.dob1 ? this.state.dob1 : "",
                    stateList: this.state.stateList ? this.state.stateList : [],
                    ssn: this.state.ssn ? this.state.ssn : "",
                    ssn1: this.state.ssn ? this.state.ssn : "",
                    useremailid: this.state.emailId ? this.state.emailId : "",
                    usermobile: this.state.phoneno ? this.state.phoneno : "",
                    country: this.state.country ? this.state.country : "none",
                    countryid: this.state.countryid ? this.state.countryid : "",
                    state: this.state.state ? this.state.state : "",
                    stateid: this.state.stateid ? this.state.stateid : "",
                    city: this.state.city ? this.state.city : "",
                    cityid: this.state.cityid ? this.state.cityid : "",
                    address1: this.state.address1 ? this.state.address1 : "",
                    address2: this.state.address2 ? this.state.address2 : "",
                    zipCode: this.state.zipCode ? this.state.zipCode : "",
                    username: this.state.username ? this.state.username : "",
                    password: this.state.password ? this.state.password : "",
                    confirmpassword: this.state.confirmpassword ? this.state.confirmpassword : "",
                    showSsn: this.state.showSsn,
                    showPatientDob: this.state.showPatientDob,
                    countrycoder: "+ 1",
                  }}
                  validationSchema={this.payerValidation}
                  onSubmit={this._createUser}>
                  {({ touched, errors, setFieldValue, values, handleBlur, handleChange, handleMouseDownPassword }) => (
                    <Form autoComplete="off" className="input-field  myprofile-form ">
                      <div className="form-row">
                        <div className="col-md-6 col-lg-3 col-sm-6 form-group">
                          <label htmlFor="firstName">First Name</label>
                          <OutlinedInput
                            type="text"
                            id="firstname"
                            name="firstname"
                            maxLength="50"
                            autocomplete="off"
                            disabled={this.state.firstname1 !== "" ? true : false}
                            value={values?.firstname.charAt(0).toUpperCase() + values?.firstname.slice(1)}
                            placeholder="Enter your First Name"
                            className="form-control"
                          />
                        </div>
                        <div className="col-md-6 col-lg-3 col-sm-6 form-group">
                          <label htmlFor="lastName">Last Name</label>
                          <OutlinedInput
                            type="text"
                            id="lastname"
                            name="lastname"
                            value={values?.lastname.charAt(0).toUpperCase() + values?.lastname.slice(1)}
                            disabled={this.state.lastname1 !== "" ? true : false}
                            placeholder="Enter your Last Name"
                            fullWidth
                            className="form-control"
                          />
                        </div>
                        <div className="col-md-6 col-lg-3 col-sm-6 form-group">
                          <label htmlFor="patientDOB" className="form-label w-100">
                            Patient Date of Birth
                          </label>
                          <OutlinedInput
                            type={this.state.cardnumbers ? "text" : "password"}
                            id="dob"
                            name="dob"
                            className="visibledate form-control"
                            disabled={this.state.dob1 !== "" ? true : false}
                            fullWidth
                            value={values.dob}
                            inputProps={{
                              maxLength: 50,
                            }}
                            endAdornment={
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleCardNumbers}
                                onMouseDown={this.handleMouseDownPassword}>
                                {this.state.cardnumbers ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            }></OutlinedInput>
                        </div>
                        <div className="col-md-6 col-lg-3 col-sm-6 form-group security-input">
                          <label htmlFor="ssn">Social Security Number</label>
                          <OutlinedInput
                            type={this.state.cardnumber ? "text" : "password"}
                            id="ssn"
                            name="ssn"
                            className="form-control security-input"
                            aria-label="text-field"
                            fullWidth
                            value={values.ssn}
                            disabled={this.state.ssn1 !== "" ? true : false}
                            error={Boolean(getIn(touched, "ssn") && getIn(errors, "ssn"))}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            endAdornment={
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleCardNumber}
                                onMouseDown={this.handleMouseDownPassword}>
                                {this.state.cardnumber ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            }></OutlinedInput>
                        </div>
                      </div>
                      <h4 className="update-profile-heading">Contact Details</h4>
                      <div className="form-row">
                        <div className="col-md-6 col-lg-6 col-sm-6 form-group">
                          <div className="label-spacing">
                            <label htmlFor="emailAddress" className="required">
                              Email Address
                            </label>
                            {this.state.mobileverified === true ? (
                              <div class="user-verify">
                                <Tooltip title="Verified" placement="top">
                                  <CheckCircleIcon color="success" />
                                </Tooltip>
                              </div>
                            ) : (
                              <div class="user-not-verify">
                                <Tooltip title="Not Verified" placement="top">
                                  <CancelIcon color="warning" />
                                </Tooltip>
                              </div>
                            )}
                          </div>
                          <OutlinedInput
                            type="email"
                            id="useremailid"
                            name="useremailid"
                            placeholder="Please Enter Email"
                            className="form-control security-input"
                            value={values.useremailid}
                            aria-label="text-field"
                            onChange={(event) => {
                              setFieldValue((values.useremailid = event.target.value));

                              this.setState({
                                emailId: event.target.value,
                              });
                            }}></OutlinedInput>
                          <FormHelperText className="component-error-text">
                            {getIn(touched, "useremailid") && getIn(errors, "useremailid") && getIn(errors, "useremailid")}
                          </FormHelperText>
                        </div>

                        <div className="col-md-6 col-lg-6 col-sm-6 form-group">
                          <div className="label-spacing">
                            <label htmlFor="phoneNumber" className="required mr-auto">
                              Phone Number
                            </label>
                            {this.state.mobileverified === true ? (
                              <div class="user-verify">
                                <Tooltip title="Verified" placement="top">
                                  <CheckCircleIcon color="success" />
                                </Tooltip>
                              </div>
                            ) : (
                              <div class="user-not-verify">
                                <Tooltip title="Not Verified" placement="top">
                                  <CancelIcon color="warning" />
                                </Tooltip>
                              </div>
                            )}
                          </div>
                          <div className="form-row">
                            <div className="col-3">
                              <Select
                                className={`form-control  browser-default text-field texttype ${
                                  touched.state && errors.state ? "is-invalid" : ""
                                }`}
                                // fullWidth
                                name="countrycoder"
                                onChange={(event) => {
                                  setFieldValue((values.countrycoder = event.target.value));
                                  this.setState({
                                    countrycoder: event.target.value,
                                  });
                                }}
                                onBlur={handleBlur}
                                value={values.countrycoder || "none"}>
                                {values.countrycoder ? (
                                  <MenuItem value={values.countrycoder} data-role={values.countrycoder} selected>
                                    {values?.countrycoder}
                                  </MenuItem>
                                ) : (
                                  <MenuItem value="none" selected disabled>
                                    Select State
                                  </MenuItem>
                                )}
                              </Select>
                            </div>
                            <div className="col-9">
                              <OutlinedInput
                                type="number"
                                id="usermobile"
                                name="usermobile"
                                placeholder="Please Enter Phone Number"
                                className="ml-auto form-control security-input"
                                value={values.usermobile}
                                aria-label="text-field"
                                fullWidth
                                onKeyPress={(event) => {
                                  if (!/\d/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onChange={(event) => {
                                  setFieldValue((values.usermobile = event.target.value));

                                  this.setState({
                                    phoneno: event.target.value,
                                  });

                                  sessionStorage.setItem("mobileno", event.target.value);
                                }}></OutlinedInput>
                              <FormHelperText className="component-error-text">
                                {getIn(touched, "usermobile") && getIn(errors, "usermobile") && getIn(errors, "usermobile")}
                              </FormHelperText>
                            </div>
                          </div>
                        </div>
                      </div>

                      <h4 className="update-profile-heading">Mailing Address Details</h4>

                      <div className="form-row user-label-title">
                        <div className="col-md-6 ">
                          <div className="form-group">
                            <label htmlFor="streetAddress" className="required">
                              Street Address
                            </label>

                            <GooglePlacesAutocomplete
                              apiKey="AIzaSyAymCr1DkF6p_ULBgBIZIM2VNu9pg-zrN0"
                              autocompletionRequest={{
                                componentRestrictions: {
                                  country: ["us"],
                                },
                              }}
                              selectProps={{
                                isClearable: true,
                                value: this.state.googleaddress,
                                onChange: (val) => {
                                  this.getGoogleApiInfo({ ...values }, val);
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group col-md-6 ">
                          <FormControl variant="outlined" className="w-100" fullWidth>
                            <FormLabel htmlFor="address1" className="label-spacing mt-md-4"></FormLabel>
                            <TextField
                              type="text"
                              autocomplete="new-password"
                              id="address1"
                              name="address1"
                              value={values?.address2 ? values?.address1 + "," + values?.address2 : values?.address1}
                              placeholder="Please Enter Street Address"
                              className={`text-field ${touched.address1 && errors.address1 ? "is-invalid" : ""}`}
                              onChange={(event) => {
                                setFieldValue((values.address1 = event.target.value));
                                this.setState({
                                  address1: event.target.value,
                                });
                              }}>
                              {" "}
                            </TextField>
                          </FormControl>
                          <FormHelperText className="component-error-text">
                            {getIn(touched, "address1") && getIn(errors, "address1") && getIn(errors, "address1")}
                          </FormHelperText>
                        </div>
                        <div className="col-md-3 col-sm-6 form-group inputbg ">
                          <FormControl variant="outlined" className="w-100" fullWidth>
                            <label htmlFor="country" className="form-label required">
                              Country
                            </label>

                            <Select
                              fullWidth
                              name="country"
                              value={values.country || "none"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-select form-control">
                              <MenuItem value="none" selected disabled>
                                Select Country
                              </MenuItem>
                              {countryList.map((option) => {
                                return (
                                  <MenuItem value={option.countryName} key={option.countryId}>
                                    {option?.countryName.charAt(0).toUpperCase() + option?.countryName.slice(1)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          <FormHelperText error>
                            {touched.country && errors.country ? <div>{errors.country}</div> : null}
                          </FormHelperText>
                        </div>
                        <div className="col-md-3 col-sm-6 form-group ">
                          <label htmlFor="state" className="required">
                            State
                          </label>
                          <Select
                            className={`form-control  browser-default text-field texttype ${
                              touched.state && errors.state ? "is-invalid" : ""
                            }`}
                            fullWidth
                            name="state"
                            onChange={(event) => {
                              setFieldValue(((values.city = ""), (values.zipCode = "")));
                              var csid = values.stateList.filter((each) => {
                                if (each.stateName === event.target.value) {
                                  return each;
                                }
                              })[0].stateId;

                              if (event.target.value !== "") {
                                values.state = event.target.value;

                                this.setState({
                                  state: event.target.value,
                                  city: "",
                                  zipCode: "",
                                });
                                // values.stateList = [];
                                // values.cityList = [];

                                var PassValues = {
                                  stateId: csid,
                                };
                                const UserId = "1234";
                                RegionManagementService.getCity(PassValues, UserId, (res) => {
                                  this.setState({
                                    cityList: res["data"].responseListObject,
                                  });

                                  setFieldValue((values.cityList = res.data.responseListObject));
                                });
                              }
                            }}
                            onBlur={handleBlur}
                            value={values.state || "none"}>
                            {values.state ? (
                              <MenuItem value={values.state} data-role={values.stateid} selected>
                                {values?.state}
                              </MenuItem>
                            ) : (
                              <MenuItem value="none" selected disabled>
                                Select State
                              </MenuItem>
                            )}

                            {(values.stateList || null).map((states, index) => {
                              return (
                                <MenuItem value={states.stateName} data-role={states.stateId} key={index}>
                                  {states?.stateName.charAt(0).toUpperCase() + states?.stateName.slice(1)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText className="component-error-text">
                            {getIn(touched, "state") && getIn(errors, "state") && getIn(errors, "state")}
                          </FormHelperText>
                        </div>
                        <div className="col-md-3 col-sm-6 form-group ">
                          <label htmlFor="city" className="required">
                            City
                          </label>
                          <OutlinedInput
                            type="text"
                            id="city"
                            name="city"
                            placeholder="Enter city"
                            value={values.city}
                            aria-label="text-field"
                            fullWidth
                            className={`form-control browser-default text-field texttype ${
                              touched.city && errors.city ? "is-invalid" : ""
                            }`}
                            onChange={(event) => {
                              setFieldValue((values.city = event.target.value));
                              setFieldValue((values.zipCode = ""));
                              this.setState({
                                city: event.target.value,
                                zipCode: "",
                              });
                            }}></OutlinedInput>

                          <FormHelperText className="component-error-text">
                            {getIn(touched, "city") && getIn(errors, "city") && getIn(errors, "city")}
                          </FormHelperText>
                        </div>
                        <div className="col-md-3 col-sm-6 ">
                          <div className="form-group">
                            <label htmlFor="zipCode" className="required">
                              ZIP Code
                            </label>
                            <OutlinedInput
                              className="form-control security-input"
                              id="zipCode"
                              name="zipCode"
                              type="text"
                              placeholder="Please Enter ZIP Code"
                              inputProps={{
                                maxLength: 10,
                              }}
                              onBlur={handleBlur}
                              onChange={(event) => {
                                const zip = event.target.value;
                                setFieldValue(
                                  (values.zipCode =
                                    zip.length > 5 && !zip.includes("-")
                                      ? zip.substring(0, 5).concat("-") + zip.substring(5, zip.length)
                                      : zip),
                                );
                              }}
                              value={values.zipCode}
                              aria-label="text-field"
                              fullWidth
                              // error={Boolean(getIn(touched, "zipCode") && getIn(errors, "zipCode"))}
                              InputLabelProps={{
                                shrink: true,
                              }}></OutlinedInput>
                            <FormHelperText className="component-error-text">
                              {getIn(touched, "zipCode") && getIn(errors, "zipCode") && getIn(errors, "zipCode")}
                            </FormHelperText>
                          </div>
                        </div>
                      </div>

                      <h4 className="update-profile-heading">Security Authorization</h4>

                      <div className="form-row">
                        <div className="col-lg-4 col-sm-6 form-group">
                          <div className="row mx-0">
                            <label htmlFor="state">Security Authorization</label>
                          </div>
                          <div className="security-input">
                            <Select
                              className="form-control security-input signup-fields"
                              type="text"
                              autocomplete="new-password"
                              id="state"
                              name="state"
                              component="select"
                              defaultValue={"none"}
                              placeholder="Select Image"
                              onChange={() => {
                                setFieldValue(((values.city = ""), (values.zipCode = "")));
                                setFieldValue((values.image = "none"));
                              }}
                              value={"none"}
                              required>
                              <MenuItem value="none" disabled>
                                Select Image
                              </MenuItem>
                              <div className="img-box admin-img-box">
                                <a className="dropdown-item admin-secure-img">
                                  <img
                                    src={this?.state?.imgdata ? this?.state?.imgdata : ""}
                                    width="auto"
                                    className="admin-register-img"
                                    onClick={this.handleClick(this.state.imgdata, this.state.imgdataid)}
                                  />
                                </a>
                                <a className="dropdown-item admin-secure-img">
                                  <img
                                    src={this?.state?.img2 ? this?.state?.img2 : ""}
                                    width="auto"
                                    className="admin-register-img"
                                    onClick={this.handleClick(this.state.img2, this.state.img2id)}
                                  />
                                </a>
                                <a className="dropdown-item admin-secure-img">
                                  <img
                                    src={this.state.img3 ? this.state.img3 : ""}
                                    className="admin-register-img"
                                    onClick={this.handleClick(this.state.img3, this.state.img3id)}
                                  />
                                </a>
                              </div>
                              <div className="img-box admin-img-box">
                                <a className="dropdown-item admin-secure-img">
                                  <img
                                    src={this.state.img4 ? this.state.img4 : ""}
                                    width="auto"
                                    className="admin-register-img"
                                    onClick={this.handleClick(this.state.img4, this.state.img4id)}
                                  />
                                </a>
                              </div>
                            </Select>
                          </div>
                          <FormHelperText className="component-error-text">
                            {getIn(touched, "securityimageselected") &&
                              getIn(errors, "securityimageselected") &&
                              getIn(errors, "securityimageselected")}
                          </FormHelperText>
                        </div>
                        <div className="col-lg-4 col-sm-6 form-group">
                          <div className="row mx-0 justify-content-between align-items-center">
                            <label htmlFor="state">{this.state.selectedImage && "Selected Image"} </label>
                          </div>
                          <div>
                            {this.state.selectedImage ? (
                              <img src={this.state.selectedImage} width="auto" className="user-img" />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col profile-image-update">
                        Current Image:
                        <div className="currentsecimage">
                          {" "}
                          <SecurityImageContainer></SecurityImageContainer>{" "}
                        </div>
                      </div>

                      <p className="user-security-content">
                        The security image feature is an additional layer of identity verification for signing in to MED-1
                        Payment Portal. It protects you against identity theft and fraud. When you see your security image
                        you can be certain you're at the valid MED-1 Payment Portal site and not a fraudulent look-alike
                        site.
                      </p>

                      <div className="proceed-cancel-buttons">
                        <button
                          type="submit"
                          id="submitButton"
                          className="primary-button themeing-buttons mx-1"
                          onClick={() => this.props.history.goBack()}>
                          Submit
                        </button>
                        <button
                          type="button"
                          id="submitButton"
                          className="secondary-button mx-1"
                          onClick={() => {
                            window.location.replace("/home/billing");
                          }}>
                          Cancel
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          {this.state.pageLoader && <PageLoader />}
          {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
        </React.Fragment>
      </I18nextProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  ...getLogin(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login: login,
      getImage: getImage,
      logoutUser: logoutUser,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
