import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
const BootstrapDialogTitle = (props) => {
  const { children, onClose } = props;
  return (
    <DialogTitle className="popupstyles">
      {children}
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose} style={{ color: "white" }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
function ConformDialogBox({ maxWidth, fullWidth, open, title, handleClose, children, disableBackDropOnClick }) {
  return (
    <div>
      <Dialog
        disableBackdropClick={disableBackDropOnClick}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {title && (
          <BootstrapDialogTitle
            onClose={() => {
              handleClose();
            }}>
            <h1 className="dialog-header" style={{ fontSize: "23px" }}>
              {title}
            </h1>
          </BootstrapDialogTitle>
        )}
        <DialogContent className="p-0">
          <div>{children}</div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ConformDialogBox;
