import { Global_var } from "../global/global_var";
import RestDataSource from "./restdatasource";
import { loginService } from "./loginService";
import axios from "axios";

export const UserManagementService = {
  getUsersTableData,
  updateUserData,
  addUserToTable,
  deleteUser,
  restoreUser,
  lookupRoles,
  disableTwoFactorForALowerGradeUser,
  roleAccess,
};

function getUsersTableData(userData, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_USER_TABLE_DATA;
      // return new RestDataSource(url).GetData((res) => fn(res.data));
      return new RestDataSource(url, 0, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}

function updateUserData(userData, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_UPDATE_USER;
      // return new RestDataSource(url).GetData((res) => fn(res.data));
      return new RestDataSource(url, 0, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}

function addUserToTable(userData, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_ADD_USER_TO_TABLE;
      // return new RestDataSource(url).GetData((res) => fn(res.data));
      return new RestDataSource(url, 0, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}

function deleteUser(id, fn) {
  // loginService.TokenUser((res) => {
  //     if (res.status === "success") {
  //         sessionStorage.setItem("jwt-tokencore", res.responseObject);
  //         var url = Global_var.BASEURL + Global_var.URL_DELETE_USER + `${id}`;
  //         // return new RestDataSource(url).GetData((res) => fn(res.data));
  //         return new RestDataSource(url,0 , fn).Delete(, (res) => {
  //             if (res != null) {
  //                 if (res.headers["token"] != null) {
  //                     sessionStorage.setItem("jwt-tokencore", res.headers.token);
  //                 }
  //                 fn(res);
  //             }
  //         });
  //     }
  // });
  axios.get(`${Global_var.BASEURL}${Global_var.URL_DELETE_USER}${id}`).then((res) => {
    fn(res);
  });
}

function restoreUser(id, fn) {
  // loginService.TokenUser((res) => {
  //     if (res.status === "success") {
  //         sessionStorage.setItem("jwt-tokencore", res.responseObject);
  //         var url = Global_var.BASEURL + Global_var.URL_RESTORE_USER + `${id}`;
  //         // return new RestDataSource(url).GetData((res) => fn(res.data));
  //         return new RestDataSource(url,0 , fn).Store(id, (res) => {
  //             if (res != null) {
  //                 if (res.headers["token"] != null) {
  //                     sessionStorage.setItem("jwt-tokencore", res.headers.token);
  //                 }
  //                 fn(res);
  //             }
  //         });
  //     }
  // });
  axios.get(`${Global_var.BASEURL}${Global_var.URL_RESTORE_USER}${id}`).then((res) => {
    fn(res);
  });
}

function disableTwoFactorForALowerGradeUser(data, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_DISABLE2FA_TO_USER;
      // return new RestDataSource(url).GetData((res) => fn(res.data));
      return new RestDataSource(url, 0, fn).Store(data, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}

function lookupRoles(id, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = `${Global_var.BASEURL}${Global_var.URL_ROLES_LOOKUP}${id}`;
      // return new RestDataSource(url).GetData((res) => fn(res.data));
      return new RestDataSource(url, 0, fn).GetData((res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}
function roleAccess(id, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = `${Global_var.BASEURL}${Global_var.URL_ROLES_ACCESS}${id}`;
      // return new RestDataSource(url).GetData((res) => fn(res.data));
      return new RestDataSource(url, 0, fn).GetData((res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}
