import React, { useEffect, useState } from "react";

import CancelIcon from "@material-ui/icons/Cancel";
import CreateIcon from "@material-ui/icons/Create";

import { Formik, Form, getIn } from "formik";
import { FormHelperText, makeStyles, MenuItem, Select } from "@material-ui/core";
import PaymentPlanHospitalTable from "./PaymentPlanHospitalTable";
import { payplanService } from "../../../services/payplanService";
import { useHistory } from "react-router";
import SpinnerPage from "../../../Common/PP_loader";
import { requestInfo_PP } from "../../../Common/CommonFn";
import { I18nextProvider } from "react-i18next";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import {
  success,
  successNotification,
  error,
  errorNotification,
  warning,
  warningNotification,
} from "../../../../src/components/PP_Notification/PP_Notification";
import { CancelPlan } from "./CancelPlan";
import EditPaymentPlan from "./EditPaymentPlan";
import { getCreditCardDataList } from "../../../reducer/pp_PaymentCreditCardReducer";
import { geteChequePaymentList } from "../../../reducer/pp_eChequePaymentReducer";
import { fetchCreditCardData } from "../../../action/pp_PaymentCreditCardAction";
import { fetchEchaqueData } from "../../../action/pp_eChequePaymentAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IconButton } from "@material-ui/core";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import ConfirmationDialog from "./../../../components/ConfirmationDialog/index";
import { handleSuccess, ToastContainer, handleError, handleWarning } from "../../../../src/components/Notifications/Toast";
import { set } from "date-fns";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";
const mapStateToProps = (state) => ({
  creditCardData: getCreditCardDataList(state.paymentCreditCardReducer),
  eChequeData: geteChequePaymentList(state.eChequePaymentReducer),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCreditCardData: fetchCreditCardData,
      fetchEchaqueData: fetchEchaqueData,
    },
    dispatch,
  );

const initialValues = {
  planId: "none",
};

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const PaymentPlanDetails = connectFunction((props) => {
  const [plan, setPlan] = useState("");
  const [bills, setBills] = useState([]);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState({});
  const [planIdOptions, setplanidOptions] = useState([]);
  const history = useHistory();
  const [dialogopen, setDialogOpen] = useState({
    cancelPayPlan: false,
    editPayPlan: false,
  });
  const [loader, setLoader] = useState(false);
  const [updatePaymentMethod, setUpdatePaymentMethod] = useState(false);
  const [cancelPaymentPlan, setCancelPaymentPlan] = useState(false);
  const [editPaymentPlan, setEditPaymentPlan] = useState(false);
  const [scheduledAmount, setScheduledAmount] = useState(0);
  const [paidAmount, setPaindAmount] = useState(0);
  const [amountFailed, setAmountFailed] = useState(0);
  const [amountCancelled, setCancelledAmount] = useState(0);
  const { creditCardData, eChequeData, fetchCreditCardData, fetchEchaqueData } = props;
  const enableEdit = sessionStorage.getItem("currentUserRole") === "Admin" ? !editPaymentPlan : false;
  const enableCancel = sessionStorage.getItem("currentUserRole") === "Admin" ? !cancelPaymentPlan : false;
  const enablePaymentMethod = sessionStorage.getItem("currentUserRole") === "Admin" ? !updatePaymentMethod : false;
  useEffect(() => {
    const getRoleData = JSON.parse(sessionStorage.getItem("roleData"))?.pages;

    for (let i in getRoleData) {
      if (getRoleData[i]?.functionalities?.["Payment methods"]?.["update"] === "true") {
        sessionStorage.getItem("currentUserRole") === "Admin" && setUpdatePaymentMethod(true);
      }
    }
    for (let i in getRoleData) {
      if (getRoleData[i]?.functionalities?.["Pay plan options"]?.["delete"] === "true") {
        sessionStorage.getItem("currentUserRole") === "Admin" && setCancelPaymentPlan(true);
      }
    }
    for (let i in getRoleData) {
      if (getRoleData[i]?.functionalities?.["Pay plan options"]?.["update"] === "true") {
        sessionStorage.getItem("currentUserRole") === "Admin" && setEditPaymentPlan(true);
      }
    }
  }, []);
  useEffect(() => {
    var userpartyinfo = JSON.parse(sessionStorage.getItem("verifypayer"));
    if (userpartyinfo !== null) {
      let CardPassValues = {
        requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        paymentInfoSearch: {
          paymentMethodId: "75001",
          partyId: userpartyinfo.partyId,
        },
      };

      let EChequePassValues = {
        requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        paymentInfoSearch: {
          paymentMethodId: 75003,
          partyId: userpartyinfo.partyId,
        },
      };
      fetchCreditCardData(CardPassValues, 1234);
      fetchEchaqueData(EChequePassValues, 1234);
    }
  }, [fetchCreditCardData, fetchEchaqueData]);

  const getCardListData = (data) => {
    // const visapattern = /^4[0-9]{12}(?:[0-9]{3})?$/;
    // const masterpattern = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/;
    // const amexpattern = /^3[47][0-9]{13}$/;

    return (
      data &&
      data.cardAccount &&
      data.cardAccount.length > 0 &&
      data.cardAccount.map((item, index) => {
        if (item.cardType === "Visa") {
          return (
            <MenuItem value={`card&${item.paymentInstrument?.paymentInstrumentId}`} key={index}>
              Visa Card ending with{" "}
              {item.cardNumber.substring(item.cardNumber.toString().length - 4, item.cardNumber.toString().length)}
            </MenuItem>
          );
        } else if (item.cardType === "Master Card") {
          return (
            <MenuItem value={`card&${item.paymentInstrument?.paymentInstrumentId}`} key={index}>
              Master Card ending with{" "}
              {item.cardNumber.substring(item.cardNumber.toString().length - 4, item.cardNumber.toString().length)}
            </MenuItem>
          );
        } else if (item.cardType === "American Express") {
          return (
            <MenuItem value={`card&${item.paymentInstrument?.paymentInstrumentId}`} key={index}>
              American Express Card ending with{" "}
              {item.cardNumber.substring(item.cardNumber.toString().length - 4, item.cardNumber.toString().length)}
            </MenuItem>
          );
        } else {
          return "";
        }
      })
    );
  };

  const getEchecqueListData = (data) => {
    return (
      data &&
      data.achAccount &&
      data.achAccount.length > 0 &&
      data.achAccount.map((item, index) => {
        return (
          <MenuItem value={`echeque&${item.paymentInstrument?.paymentInstrumentId}`} key={index}>
            eCheck ending with{" "}
            {item.accountNumber.substring(item.accountNumber.toString().length - 4, item.accountNumber.toString().length)}
          </MenuItem>
        );
      })
    );
  };

  useEffect(() => {
    setLoader(true);
    const partyids = JSON.parse(sessionStorage.getItem("verifypayer"));
    let partyid = partyids.partyId;
    payplanService.getAllPayPlans(
      requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      partyid,
      1234,
      (res) => {
        if (res.status === 200 && res.data.statusCodeValue === 200) {
          let tempArr = [];
          res?.data?.body?.recurringPayments &&
            res?.data?.body?.recurringPayments.length > 0 &&
            res.data.body.recurringPayments.map((payplan) =>
              tempArr.push({
                key: payplan.reccuringPaymentId,
                value: payplan.reccuringPaymentId,
              }),
            );
          setplanidOptions(tempArr);
        } else {
          handleWarning("Something went wrong! Please try again");
        }
      },
      (error) => {
        handleError("Something went wrong! Please try again");
      },
    );

    const selectedPayplan = window.location.search?.split("?")[1];
    setPlan(selectedPayplan);

    initialValues.planId = selectedPayplan;

    payplanService.getSinglePayPlanDetails(
      requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      selectedPayplan,
      1234,
      (res) => {
        if (res.status === 200 && res.data.statusCodeValue === 200) {
          if (res.data.body.recurringPayments.length > 0) {
            const checkBills =
              res.data.body.recurringPayments[0].paymentOrders === null
                ? []
                : res.data.body.recurringPayments[0].paymentOrders;
            setBills(checkBills);
          }

          setSelectedPlanDetails(res.data.body.recurringPayments);
          setPaindAmount(res.data.body.paidAmount ? res.data.body.paidAmount : "0");
          setScheduledAmount(res.data.body.scheduledAmount ? res.data.body.scheduledAmount : "0");
          setAmountFailed(res.data.body.failedAmount ? res.data.body.failedAmount : "0");
          setCancelledAmount(res.data.body.cancelledAmount ? res.data.body.cancelledAmount : "0");
          setLoader(false);
        } else {
          setLoader(false);
          handleWarning("Something went wrong!");
          setTimeout(() => {
            sessionStorage?.getItem("currentUserRole") === "Admin"
              ? history.push("/pa/payment-history")
              : history.push("/home/payment-history");
          }, 2000);
        }
      },
      (error) => {
        setLoader(false);
        handleError("Something went wrong! Please try again");
      },
    );
  }, [history, plan]);

  const getPaymentPlanDetails = () => {
    const selectedPayplan = window.location.search?.split("?")[1];
    setPlan(selectedPayplan);

    initialValues.planId = selectedPayplan;
    payplanService.getSinglePayPlanDetails(
      requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      selectedPayplan,
      1234,
      (res) => {
        if (res.status === 200 && res.data.statusCodeValue === 200) {
          if (res.data.body.recurringPayments.length > 0) {
            setBills(res.data.body.recurringPayments[0].paymentOrders);
          }

          setSelectedPlanDetails(res.data.body.recurringPayments);
          setPaindAmount(res.data.body.paidAmount ? res.data.body.paidAmount : "0");
          setScheduledAmount(res.data.body.scheduledAmount ? res.data.body.scheduledAmount : "0");
          setAmountFailed(res.data.body.failedAmount ? res.data.body.failedAmount : "0");
          setCancelledAmount(res.data.body.cancelledAmount ? res.data.body.cancelledAmount : "0");
          setLoader(false);
        } else {
          setLoader(false);
          handleWarning("Something went wrong!");
          setTimeout(() => {
            sessionStorage?.getItem("currentUserRole") === "Admin"
              ? this.props.history.push("/pa/payment-history")
              : history.push("/home/payment-history");
          }, 2000);
        }
      },
    );
  };

  const labelStyles = {
    color: "#6d6d6d",
    fontWeight: "500",
    fontSize: "14px",
  };

  const textStyles = {
    color: "#464646",
    paddingLeft: "5px",
    fontWeight: "600",
    fontSize: "14px",
  };
  const installmentStyles = {
    color: "#6d6d6d",
    fontWeight: "500",
    fontSize: "14px",
    textAlign: "center",
  };
  const cancelBtnStyles = {
    padding: "3px 15px",
    minWidth: "150px",
    background: "#fff 0 0 no-repeat padding-box",
    border: "1px solid #0f75bd",
    outline: "none",
    borderRadius: "6px",
    marginRight: "15px",
  };

  const editBtnStyles = {
    padding: "3px 15px",
    backgroundColor: "#0f75bd",
    outline: "none",
    borderRadius: "6px",
    border: "none",
    color: "#fff",
  };

  const handleClose = () => {
    setDialogOpen({ cancelPayPlan: false, editPayPlan: false });
  };

  const handleCancelPlan = () => {
    setLoader(true);
    handleClose();
    payplanService.deletePayPlan(
      requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      plan,
      1234,
      (res) => {
        if (res.status === 200 && res.data === "deleted successfully") {
          setLoader(false);
          handleClose();
          handleSuccess(`Plan ${plan} Cancelled Successfully`);
          setTimeout(() => {
            history.push({
              pathname:
                sessionStorage.getItem("currentUserRole") === "Admin" ? "/pa/payment-history" : "/home/payment-history",
              state: {
                from:
                  sessionStorage.getItem("currentUserRole") === "Admin"
                    ? "/pa/payment-plan-details"
                    : "/home/payment-plan-details",
              },
            });
          }, 2000);
        } else {
          setLoader(false);
          handleWarning("Something went wrong!");
        }
        setLoader(false);
      },
      (error) => {
        handleError("Something went wrong! Please try again");
        setLoader(false);
      },
    );
  };

  const useStyles = makeStyles((theme) => ({
    createBtn: {
      outline: "none !important",
      padding: 0,
    },
    cancelBtn: {
      marginLeft: "1rem",
      outline: "none !important",
    },
    createIcon: {
      color: "rgb(15 117 189)",
      fontSize: "28px",
    },
    cancelIcon: {
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "28px",
    },
  }));

  const handleCancel = () => {
    setDialogOpen({ cancelPayPlan: false, editPayPlan: false });
  };

  const handleDialogOpen = (type) => {
    setDialogOpen({
      cancelPayPlan: type === "cancel",
      editPayPlan: type === "edit",
    });
  };

  // const handlePayPlan = (instrumentId) => {
  //   setLoader(true);
  //   handleCancel();
  //   payplanService.updatePaymentPlan(
  //     requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
  //     plan,
  //     instrumentId,
  //     1234,
  //     (res) => {
  //       if (res.status === 200 && res.data.statusCodeValue === 200) {
  //         setLoader(false);
  //         handleSuccess(`Payment Method Updated Successfully for plan ${plan}`);
  //         handleClose();
  //         getPaymentPlanDetails();
  //       } else {
  //         setLoader(false);
  //         handleWarning("Something went wrong!");
  //       }
  //     },
  //     (error) => {
  //       handleError("Something went wrong! Please try again");
  //       setLoader(false);
  //     },
  //   );
  // };

  const handleEditPlan = (instrument) => {
    if (instrument?.split("&")[0] === "echeque" || instrument === "echeck") {
      if (instrument?.split("&")[0] === "echeque") {
        const selectedECheck = eChequeData?.achAccount?.filter((item) => {
          return item.paymentInstrument?.paymentInstrumentId === Number(instrument?.split("&")[1]);
        });

        history.push({
          pathname: sessionStorage.getItem("currentUserRole") === "Admin" ? "/pa/add-echeck" : "/home/add-echeck",
          state: { page: "PaymentPlan", plan, selectedECheck },
        });
      } else {
        history.push({
          pathname: sessionStorage.getItem("currentUserRole") === "Admin" ? "/pa/add-echeck" : "/home/add-echeck",
          state: { page: "PaymentPlan", plan },
        });
      }
    } else {
      if (instrument?.split("&")[0] === "card") {
        const selectedCard = creditCardData?.cardAccount?.filter((item) => {
          return item.paymentInstrument?.paymentInstrumentId === Number(instrument?.split("&")[1]);
        });

        history.push({
          pathname: sessionStorage.getItem("currentUserRole") === "Admin" ? "/pa/credit-Card" : "/home/credit-Card",
          state: { page: "PaymentPlan", plan, selectedCard },
        });
      } else {
        history.push({
          pathname: sessionStorage.getItem("currentUserRole") === "Admin" ? "/pa/credit-Card" : "/home/credit-Card",
          state: { page: "PaymentPlan", plan },
        });
      }
    }
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <ConfirmationDialog
        open={dialogopen.editPayPlan || dialogopen.cancelPayPlan}
        handleClose={handleClose}
        handleSubmit={handleCancelPlan}
        fullWidth={true}
        title={
          (dialogopen.editPayPlan && `Are you sure you want to edit Payment Method?`) ||
          (dialogopen.cancelPayPlan && "Are you sure you want to cancel plan?")
        }
        noActionButtons={dialogopen.editPayPlan ? true : false}>
        {dialogopen.editPayPlan && (
          <EditPaymentPlan
            plan={plan}
            creditCardData={creditCardData}
            eChequeData={eChequeData}
            getCardListData={getCardListData}
            getEchecqueListData={getEchecqueListData}
            handleClose={handleClose}
            handleEditPlan={handleEditPlan}
          />
        )}
        {dialogopen.cancelPayPlan && <CancelPlan plan={plan} />}
      </ConfirmationDialog>

      <div className="transaction-card" role="main">
        <div
          className={
            window.location.href.includes("pa/payment-plan-details") ||
            sessionStorage.getItem("currentUserRole") === "quickpay"
              ? `main-container-card`
              : `main-container-card main-container-sm`
          }>
          <section class="content user-content-box ">
            <div>
              <div role="heading" aria-level="1" className="heading-style mb-3">
                <h2 className="content-title heading-style mb-0"> Payment Plan Details</h2>
              </div>

              <div className="amount-box">
                {loader ? (
                  <SpinnerPage />
                ) : (
                  <Formik initialValues={initialValues}>
                    {(props) => {
                      const { values, touched, handleChange, errors, setFieldValue } = props;

                      return (
                        <>
                          <div className="form-row">
                            <div className="col-sm-6 col-md-4 col-xl-3 form-group ">
                              <div className="paymentplan-details">
                                <div className="label">Plan ID: </div>
                                <div className="value">
                                  <span>
                                    <Select
                                      className="bg-white  rounded paymentplan-select"
                                      style={labelStyles}
                                      aria-label="Payplan Select Id"
                                      name="planId"
                                      id="planId"
                                      onChange={(e) => {
                                        handleChange(e);
                                        history.push({
                                          pathname:
                                            sessionStorage.getItem("currentUserRole") === "Admin"
                                              ? "/pa/payment-plan-details"
                                              : "/home/payment-plan-details",
                                          search: `?${e.target.value}`,
                                        });
                                        setPlan(e.target.value);
                                      }}
                                      value={values["planId"]}>
                                      <option value="none" key="none" disabled>
                                        Select a Plan Id
                                      </option>

                                      {planIdOptions.map((option) => {
                                        return (
                                          <MenuItem value={option.value} key={option.key}>
                                            {option.value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    <FormHelperText component="p" error>
                                      {getIn(touched, "planId") && getIn(errors, "planId") && getIn(errors, "planId")}
                                    </FormHelperText>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-xl-3 form-group ">
                              <div className="paymentplan-details">
                                <div className="label">Frequency: </div>
                                <div className="value">
                                  {selectedPlanDetails && selectedPlanDetails.length > 0
                                    ? selectedPlanDetails.map((plan) => {
                                        const { recurringPaymentType } = plan;
                                        return recurringPaymentType;
                                      })
                                    : ""}
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6  col-md-4  col-xl-3 form-group">
                              <div className="paymentplan-details">
                                <div className="label"> Account Number: </div>
                                <div className="value">
                                  {selectedPlanDetails && selectedPlanDetails.length > 0
                                    ? selectedPlanDetails.map((plan) => {
                                        const { masterAccountReferenceNumber } = plan;
                                        return masterAccountReferenceNumber;
                                      })
                                    : ""}
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4  col-xl-3 form-group">
                              <div className="paymentplan-details">
                                <div className="label"> Number of Installments: </div>
                                <div className="value">
                                  {selectedPlanDetails && selectedPlanDetails.length > 0
                                    ? selectedPlanDetails.map((plan) => {
                                        const { noOfPayments } = plan;
                                        return noOfPayments;
                                      })
                                    : ""}
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4  col-xl-3 form-group">
                              <div className="paymentplan-details">
                                <div className="label"> Start Date: </div>
                                <div className="value">
                                  {selectedPlanDetails && selectedPlanDetails.length > 0
                                    ? selectedPlanDetails.map((plan) => {
                                        const { startDate } = plan;
                                        return startDate?.split(" ")[0];
                                      })
                                    : ""}
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4  col-xl-3 form-group">
                              <div className="paymentplan-details">
                                <div className="label"> Amount Paid:{""} </div>
                                <div className="value">
                                  ${" "}
                                  {paidAmount.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4  col-xl-3 form-group">
                              <div className="paymentplan-details">
                                <div className="label"> Amount Scheduled:{""} </div>
                                <div className="value">
                                  ${" "}
                                  {scheduledAmount.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-xl-3 form-group">
                              <div className="paymentplan-details">
                                <div className="label"> Amount Failed:{""} </div>
                                <div className="value">
                                  ${" "}
                                  {amountFailed.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-xl-3 form-group">
                              <div className="paymentplan-details">
                                <div className="label"> Amount Cancelled:{""} </div>
                                <div className="value">
                                  ${" "}
                                  {amountCancelled.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="col-xl-12 col-sm-12 text-right">
                              <div className="payPlanDetails-Btn  payment-options-detailsbtns-cp">
                                <button
                                  className={`${
                                    enableEdit ? " disabled-btn " : " themeing-buttons "
                                  } primary-button mx-1 mb-1 mb-sm-0 payplan-buttons`}
                                  disabled={sessionStorage.getItem("currentUserRole") === "Admin" ? !editPaymentPlan : false}
                                  onClick={() =>
                                    sessionStorage.getItem("currentUserRole") === "Admin"
                                      ? history.push(`/pa/pay-plan-options?planId=${values["planId"]}`)
                                      : history.push(`/home/pay-plan-options?planId=${values["planId"]}`)
                                  }>
                                  <CreateIcon color="#fff" className="pr-1" />
                                  Edit Plan
                                </button>
                                <button
                                  className={`${
                                    enableCancel ? " disabled-btn " : ""
                                  } secondary-button mx-1 mb-1 mb-sm-0 payplan-buttons`}
                                  disabled={
                                    sessionStorage.getItem("currentUserRole") === "Admin" ? !cancelPaymentPlan : false
                                  }
                                  onClick={() => handleDialogOpen("cancel")}>
                                  <CancelIcon color="#565656" className="pr-1" />
                                  Cancel Plan
                                </button>
                                <button
                                  className={`${
                                    enablePaymentMethod ? " disabled-btn " : " themeing-secondary-buttons "
                                  }  primary-button mx-1 mb-1 mb-sm-0 payplan-buttons`}
                                  onClick={() => handleDialogOpen("edit")}
                                  disabled={
                                    sessionStorage.getItem("currentUserRole") === "Admin" ? !updatePaymentMethod : false
                                  }>
                                  <CreateIcon color="#fff" className="pr-1" />
                                  Edit Payment method
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    }}
                  </Formik>
                )}
              </div>
              {loader ? (
                <SpinnerPage />
              ) : (
                <div className="payplan-table">
                  <PaymentPlanHospitalTable
                    bills={bills}
                    plan={plan}
                    getPaymentPlanDetails={getPaymentPlanDetails}
                    creditCardData={creditCardData}
                    eChequeData={eChequeData}
                    getCardListData={getCardListData}
                    getEchecqueListData={getEchecqueListData}
                  />
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
      {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
    </React.Fragment>
  );
});

export default PaymentPlanDetails;
