import React from "react";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { Formik, Form } from "formik";
import { Grid } from "@material-ui/core";
import { IconButton, TextField } from "@material-ui/core";
import { InputLabel, MenuItem, FormControl, Select, Box } from "@material-ui/core";
class AccountsUpdated extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const paymentMethodOptions = [
      { key: "eCheck", value: "eCheck" },
      { key: "Credit Card", value: "Credit Card" },
    ];
    return (
      <div>
        <Formik
          initialValues={{
            patientDOB: null,
            endDate: null,
            userName: "",
            paymentMethod: "none",
          }}
         
        >
          {(props) => {
            const { values, touched, error, handleChange, errors, setFieldValue } = props;
            return (
              <Form id="transaction-search" className="input-field main-container-card" noValidate="noValidate">
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-3 col-md-4 form-group mb-0">
                      <label htmlFor="userName" className="form-label w-100">
                        User Name
                      </label>

                      <Grid container className="date-picker">
                        <TextField
                          id="userName"
                          name="userName"
                          placeholder="Please User Name"
                          onChange={handleChange}
                          value={values["userName"]}
                          aria-label="text-field"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}></TextField>
                      </Grid>
                    </div>
                    <div className="col-lg-3 col-md-4 form-group mb-0">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid container className="date-picker">
                          <label htmlFor="patientDOB" className="form-label w-100">
                            Start Date
                          </label>
                          <DatePicker
                            disableFuture
                            name="patientDOB"
                            autoOk
                            value={values.patientDOB}
                            onChange={(val) => setFieldValue("patientDOB", val)}
                            renderInput={(params) => <TextField {...params} helperText={null} margin="dense" />}
                          />
                        </Grid>
                      </LocalizationProvider>
                    </div>
                    <div className="col-lg-3 col-md-4  form-group mb-0">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid container className="date-picker">
                          <label htmlFor="endDate" className="form-label w-100">
                            End Date
                          </label>
                          <DatePicker
                            disableFuture
                            name="endDate"
                            autoOk
                            value={values.endDate}
                            onChange={(val) => setFieldValue("endDate", val)}
                            renderInput={(params) => <TextField {...params} helperText={null} margin="dense" />}
                          />
                        </Grid>
                      </LocalizationProvider>
                    </div>

                    
                    <div className="col-lg-3 col-md-4 form-group mb-0">
                      <FormControl variant="outlined" className="w-100" fullWidth>
                        <label htmlFor="paymentMethod" className="form-label">
                          Payment Method
                        </label>
                        <Select
                          fullWidth
                          name="paymentMethod"
                          className="form-select form-control"
                          onChange={handleChange("paymentMethod")}
                          value={values["paymentMethod"]}>
                          <MenuItem value="none" key="none" disabled>
                            Select Payment Method
                          </MenuItem>
                          {paymentMethodOptions.map((option) => {
                            return (
                              <MenuItem value={option.value} key={option.key}>
                                {option.value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="proceed-cancel-buttons history-btns">
                    <button aria-label="Generate Report" type="submit" className="primary-button">
                      Search
                    </button>
                    <button aria-label="Reset" type="reset" className="secondary-button">
                      Cancel
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}
export default AccountsUpdated;
