import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage, getIn, Field } from "formik";
import * as Yup from "yup";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import {
  MenuItem,
  Select,
  FormControl,
  TextField,
  FormLabel,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import $ from "jquery";
import calendar from "../../../../assets/images/calendar.png";
import moment from "moment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import leftSideMenu from "../../../../components/admin_header/leftSideMenu/leftSideMenu";
import { requestInfo_PP } from "../../../../Common/CommonFn";
import { useHistory } from "react-router";
import {
  error,
  success,
  warning,
  warningNotification,
  errorNotification,
  successNotification,
} from "../../../../components/PP_Notification/PP_Notification";
import TermsAndConditions from "../../../../components/TermsAndConditions/TermsAndConditions";
import { Grid } from "@material-ui/core";
import { handleSuccess, ToastContainer, handleError, handleWarning } from "../../../../components/Notifications/Toast";

const dayOptions = [
  { key: "Monday", value: "Monday" },
  { key: "Tuesday", value: "Tuesday" },
  { key: "Wednesday", value: "Wednesday" },
  { key: "Thursday", value: "Thursday" },
  { key: "Friday", value: "Friday" },
  { key: "Saturday", value: "Saturday" },
  { key: "Sunday", value: "Sunday" },
];

const WeeklyForm = ({
  seedAccNum,
  fetchPlan,
  payplanService,
  handleClose,
  totalInstallments,
  setSuccess,
  creditCardData,
  eChequeData,
  getCardListData,
  getEchecqueListData,
  outstandingBalance,
  setPaymentTransactionData,
  page,
  creditCardValue,
  ppValues,
  frequencyType,
  partyid,
  validateStartDate,
  day,
  disableSave,
  setDisableSave,
  setShowForm,
  setSettlementOption,
  isLegal,
  setOverRide,
  isEditPlan,
  setPlan,
}) => {
  const [paymentMethod, setPaymentMethod] = useState({
    paymentMethod: "none",
    showCreditCard: true,
    showEcheck: true,
  });
  const history = useHistory();
  const [installments, setInstallments] = useState([]);
  const [textData, setTextData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [data, resetData] = useState({});
  const [isPaymentOptions, setPaymentOptions] = useState(true);
  const [futureDate, setFutureDate] = useState(null);
  const [futureMonthDays, setFutureMonthDays] = useState(null);
  const [lastDayOfMonth, setLastDayOfMonth] = useState(moment().endOf("month").format("MM/DD/YYYY"));
  const [secondLastDayOfMonth, setSecondLastDayOfMonth] = useState(moment(lastDayOfMonth).subtract(1, "days"));
  const [thirdLastDayOfMonth, setThirdLastDayOfMonth] = useState(moment(lastDayOfMonth).subtract(2, "days"));
  const minAmount = 0;

  const intialvalues = {
    noOfInstallments: fetchPlan?.recurringPaymentType === frequencyType ? fetchPlan?.noOfPayments : "",
    installmentStartDate:
      fetchPlan !== null && fetchPlan?.recurringPaymentType === frequencyType ? moment().toDate(fetchPlan?.startDate) : null,
    paymentAmount: fetchPlan?.recurringPaymentType === frequencyType ? fetchPlan?.recurringAmmount : "",
    paymentMethod: creditCardValue || "none",
    agreeToterms: false,
    agreeToEmailUsage: false,
    reccuringPaymentId: fetchPlan?.reccuringPaymentId || null,
  };
  const yesterday = new Date(Date.now() - 86400000);
  const schema = Yup.object().shape({
    noOfInstallments: Yup.string().notOneOf(["none"], "Select a Valid Option").required("Required"),
    paymentAmount: Yup.number()
      .min(
        minAmount,
        <span>
          <b>$ {minAmount}</b> is the minimum amount
        </span>,
      )
      .max(
        outstandingBalance,
        <span>
          <b>$ {outstandingBalance.toFixed(2)}</b> is the maximum amount
        </span>,
      )
      .typeError("Please Enter Valid Amount")
      .required("Payment Amount is Required"),
    installmentStartDate: Yup.date()
      .nullable()
      .min(yesterday, "Past date is not allowed")
      .typeError("Invalid Date")
      .required("Installment Start Date is required"),

    paymentMethod: Yup.string().notOneOf(["none"], "Select a Valid Option").required("Select a Payment Method"),
    agreeToterms: Yup.boolean()
      .oneOf([true], "Please agree to terms and conditions to continue")
      .required("Checkbox is required"),
  });

  useEffect(() => {
    if (!isEditPlan) {
      var intialvalues = {
        noOfInstallments: "",
        installmentStartDate: null,
        paymentAmount: "",
        paymentMethod: "none",
        agreeToterms: false,
        agreeToEmailUsage: false,
      };
      resetData(intialvalues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPlan]);

  useEffect(() => {
    var currentDate = moment().toDate();
    var futureMonth = moment(currentDate).add(1, "M");
    var futureMonthEnd = moment(futureMonth).endOf("month");
    var formattedFutureDate = moment(futureMonthEnd._d).format("MM/DD/YYYY");
    var daysInMonth = moment(formattedFutureDate).daysInMonth();
    setFutureMonthDays(daysInMonth);
    setFutureDate(formattedFutureDate);
  }, []);

  useEffect(() => {
    let allInstallments = [];

    [...Array(totalInstallments).keys()].map((i) => {
      return allInstallments.push({ key: i + 1, value: i + 1 });
    });

    setInstallments(allInstallments);
  }, [totalInstallments]);

  useEffect(() => {
    if (history?.location?.state?.saveDetails) {
      setShowForm(false);
      setLoader(true);
      let queryString = window.location.search.split("?");
      let [, seedAccountNumber] = queryString;
      let PassValues = {
        requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        recurringPayment: {
          // Id must be 0 for new one
          reccuringPaymentId: 0,
          recurringPaymentType: frequencyType,
          recurringAmmount: history?.location?.state?.ppValues?.paymentAmount,
          noOfPayments: history?.location?.state?.ppValues?.noOfInstallments,
          startDate: moment(history?.location?.state?.ppValues?.installmentStartDate).format("MM/DD/YYYY"),
          paymentInstrumentId: history?.location?.state?.instrumentId,
          masterAccountReferenceNumber: seedAccountNumber,
          payerPartyId: partyid,
        },
      };
      payplanService.savePayPlan(
        PassValues,
        1234,
        (res) => {
          if (res?.data?.status === "Success") {
            setLoader(false);
            setShowForm(false);
            setDisableSave(true);
            setPaymentTransactionData(res.data.body);
          } else {
            setLoader(false);
            setShowForm(true);
            handleError("Can't save Payment Arrangement  details");
          }
        },
        (error) => {
          handleError("Something went wrong! Please try again");
          setLoader(false);
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values, onSubmitProps) => {
    const { setFieldError } = onSubmitProps;
    if (values.paymentMethod === "echeck" || values.paymentMethod.split("&")[0] === "echeque") {
      if (values.paymentMethod.split("$")[1]) {
        let selectedECheck = "";
        selectedECheck = eChequeData?.achAccount?.filter((item) => {
          return item.paymentInstrument?.paymentInstrumentId === Number(values.paymentMethod.split("$")[1]);
        });

        history.push({
          pathname: "add-echeck",
          state: {
            seedAccNum,
            page,
            values,
            frequencyType,
            selectedECheck,
            fromOverride: true,
          },
        });
      } else {
        history.push({
          pathname: "add-echeck",
          state: {
            seedAccNum,
            page,
            values,
            frequencyType,
            fromOverride: true,
          },
        });
      }
    } else {
      if (values.paymentMethod.split("$")[1]) {
        let selectedCard = "";
        selectedCard = creditCardData?.cardAccount?.filter((item) => {
          return item.paymentInstrument?.paymentInstrumentId === Number(values.paymentMethod.split("$")[1]);
        });

        history.push({
          pathname: "/credit-card",
          state: {
            seedAccNum,
            page,
            values,
            frequencyType,
            selectedCard,
            fromOverride: true,
          },
        });
      } else {
        history.push({
          pathname: "/credit-card",
          state: {
            seedAccNum,
            page,
            values,
            frequencyType,
            fromOverride: true,
          },
        });
      }
    }
  };

  const logout = () => {
    localStorage.removeItem("currentUserRole");
    localStorage.clear();
    sessionStorage.clear();
    history.push("/signin");
  };
  return (
    <div className="payplan-forms">
      <div
        className="modal"
        id="termsConditions"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-width" role="document"></div>
      </div>
      <Formik initialValues={intialvalues} onSubmit={onSubmit} validationSchema={schema} enableReinitialize>
        {(props) => {
          // eslint-disable-next-line no-unused-vars
          const { values, touched, error, handleChange, errors, handleBlur, setFieldValue } = props;
          return (
            <Form id="profile-page" className="recurring-plans input-field" noValidate="noValidate" autocomplete="off">
              {" "}
              <div className="form-row">
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 form-group">
                  <FormControl variant="outlined" className="w-100" fullWidth>
                    <label htmlFor="paymentAmount" className="required">
                      Payment Amount
                    </label>
                    <OutlinedInput
                      className="form-control security-input"
                      name="paymentAmount"
                      autocomplete="new-password"
                      placeholder="Please Enter Amount"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        const data = e.target.value;
                        handleChange(e);
                        setFieldValue((values.paymentAmount = data));
                        setFieldValue("noOfInstallments", Math.ceil(outstandingBalance / data));
                      }}
                      value={values["paymentAmount"]}
                      aria-label="text-field"
                      fullWidth
                      error={Boolean(getIn(touched, "paymentAmount") && getIn(errors, "paymentAmount"))}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}></OutlinedInput>
                  </FormControl>
                  <FormHelperText error>
                    {getIn(touched, "paymentAmount") && getIn(errors, "paymentAmount") && getIn(errors, "paymentAmount")}
                  </FormHelperText>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 form-group">
                  <FormControl variant="outlined" className="w-100" fullWidth>
                    <label htmlFor="numberOfInstallments" className="form-label required">
                      Select Number of Installments
                    </label>
                    <OutlinedInput
                      className="form-control security-input"
                      name="noOfInstallments"
                      autocomplete="new-password"
                      placeholder="Please Enter Installment"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        const data = e.target.value;
                        if (data > Math.ceil(outstandingBalance / values.paymentAmount)) {
                          handleWarning(
                            `You can enter maximum ${Math.ceil(
                              outstandingBalance / values.paymentAmount,
                            )} installments as per your payment amount`,
                            warningNotification,
                          );
                        } else if (data[0] !== "0") {
                          if (data.match("^[0-9]*$")) {
                            handleChange(e);
                            setFieldValue((values.noOfInstallments = data));
                          }
                        }
                      }}
                      value={isFinite(values["noOfInstallments"]) ? values["noOfInstallments"] : ""}
                      aria-label="text-field"
                      fullWidth
                      error={Boolean(
                        getIn(touched, "noOfInstallments") && getIn(errors, "noOfInstallments"),
                      )}></OutlinedInput>

                    <FormHelperText error>
                      {getIn(touched, "noOfInstallments") &&
                        getIn(errors, "noOfInstallments") &&
                        getIn(errors, "noOfInstallments")}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 form-group date-picker">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid container className="date-picker">
                      <label htmlFor="dateTwo" className="form-label w-100 required">
                        Installment Start Date
                      </label>
                      <DatePicker
                        format="M/d/y"
                        monthPlaceholder="MM"
                        returnValue="end"
                        calendarIcon={<img src={calendar} alt="calendar" width="22px" />}
                        name="installmentStartDate"
                        minDate={moment().toDate()}
                        maxDate={
                          moment(lastDayOfMonth).isSame(moment().toDate(), "day") ||
                          moment(secondLastDayOfMonth).isSame(moment().toDate(), "day") ||
                          (moment(thirdLastDayOfMonth).isSame(moment().toDate(), "day") &&
                            (futureMonthDays === 28 || futureMonthDays === 29))
                            ? moment(futureDate).endOf("month").toDate()
                            : moment().daysInMonth() === 30
                            ? moment().add(29, "days").toDate()
                            : moment().daysInMonth() === 29
                            ? moment().add(28, "days").toDate()
                            : moment().daysInMonth() === 28
                            ? moment().add(27, "days").toDate()
                            : moment().add(30, "days").toDate()
                        }
                        dayPlaceholder="DD"
                        yearPlaceholder="YYYY"
                        className="visibledate"
                        value={values.installmentStartDate}
                        autoOk
                        onChange={(value) => {
                          setFieldValue("installmentStartDate", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={null}
                            margin="dense"
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "MM/DD/YYYY",
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </LocalizationProvider>

                  <FormHelperText error>
                    {getIn(touched, "installmentStartDate") &&
                      getIn(errors, "installmentStartDate") &&
                      getIn(errors, "installmentStartDate")}
                  </FormHelperText>
                </div>

                {/* Other payment metohds */}

                <div className="form-group col-xl-4 col-lg-6 col-md-6 col-sm-12">
                  <FormControl variant="outlined" className="w-100" fullWidth>
                    <label htmlFor="paymentMethod" className="form-label required">
                      Payment Method
                    </label>
                    <Select
                      autocomplete="off"
                      id="paymentMethod"
                      name="paymentMethod"
                      placeholder="Payment Method"
                      component="select"
                      onBlur={handleBlur}
                      value={values.paymentMethod}
                      className={`form-control ${touched.paymentMethod && errors.paymentMethod ? "is-invalid" : ""}`}
                      onChange={handleChange}
                      error={Boolean(getIn(touched, "paymentMethod") && getIn(errors, "paymentMethod"))}>
                      <MenuItem value="none" key="none" disabled>
                        Select Payment Method
                      </MenuItem>
                      {getCardListData(creditCardData)}
                      {getEchecqueListData(eChequeData)}
                      {paymentMethod.showCreditCard === true ? (
                        <MenuItem value="creditCard">Debit/Credit Card</MenuItem>
                      ) : (
                        ""
                      )}
                      {paymentMethod.showEcheck === true ? <MenuItem value="echeck">eCheck</MenuItem> : ""}
                    </Select>
                  </FormControl>
                  <FormHelperText error>
                    {getIn(touched, "paymentMethod") && getIn(errors, "paymentMethod") && getIn(errors, "paymentMethod")}
                  </FormHelperText>
                </div>

                <div className="agree-terms">
                  <div className=" col-md-12 check-FormLabel authorization-input">
                    <FormControl fullWidth component="fieldset" margin="normal">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="agreeToterms"
                            value={values.agreeToterms}
                            checked={values.agreeToterms}
                            onChange={handleChange}
                            color="primary"
                          />
                        }
                        label={
                          <span>
                            <TermsAndConditions />
                          </span>
                        }
                      />
                    </FormControl>
                    <FormHelperText error name="termsconditions">
                      {touched.agreeToterms && errors.agreeToterms ? <div>{errors.agreeToterms}</div> : null}
                    </FormHelperText>
                  </div>
                  {/* <div className="col-md-12 check-FormLabel">
                    <FormControl fullWidth component="fieldset" margin="normal">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="agreeToterms"
                            value={values.agreeToterms}
                            checked={values.agreeToterms}
                            onChange={handleChange}
                            color="primary"
                          />
                        }
                        label={
                          <span>
                            By checking this box, you acknowledge that you have reviewed, understand, meet and agree to the{" "}
                            Online Payment Terms and Conditions
                          </span>
                        }
                      />
                    </FormControl>
                    <FormHelperText error>
                      {getIn(touched, "agreeToterms") && getIn(errors, "agreeToterms") && getIn(errors, "agreeToterms")}
                    </FormHelperText>
                  </div> */}
                  <div className="col-md-12 check-FormLabel authorization-input">
                    <FormControl fullWidth component="fieldset" margin="normal">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="agreeToEmailUsage"
                            value={values.agreeToEmailUsage}
                            checked={values.agreeToEmailUsage}
                            onChange={handleChange}
                            color="primary"
                          />
                        }
                        label={
                          <span>
                            By checking this box, you acknowledge that this is your personal email address and you authorize
                            us to send you emails, call your phone, or contact you via an automated dialer, leave voicemails,
                            and/or send text messages if necessary.
                          </span>
                        }
                      />
                    </FormControl>
                    <FormHelperText error>
                      {getIn(touched, "agreeToEmailUsage") &&
                        getIn(errors, "agreeToEmailUsage") &&
                        getIn(errors, "agreeToEmailUsage")}
                    </FormHelperText>
                  </div>
                </div>
              </div>
              <div className="cancel-save-btn">
                <button
                  className="btn secondary-button mr-1"
                  onClick={(e) => {
                    !isEditPlan ? setOverRide(false) : history.goBack();
                  }}
                  type="button">
                  Cancel
                </button>
                <button type="submit" className="btn primary-button themeing-buttons ml-1" disabled={disableSave}>
                  Save
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
      {/* <div className="d-flex justify-content-end align-items-center">
        <button className="btn btn-secondary" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn btn-primary ml-3" onClick={handleClose}>
          Save
        </button>
      </div> */}
    </div>
  );
};

export default WeeklyForm;
