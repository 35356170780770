import React, { useState, useEffect } from "react";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { Formik, Form } from "formik";
import { FormHelperText, Grid } from "@material-ui/core";
import { IconButton, TextField } from "@material-ui/core";
import { InputLabel, MenuItem, FormControl, Select, Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import moment from "moment";
import { requestInfo_PP } from "../../../../../Common/CommonFn";
import MaterialTable from "material-table";
import { tableIcons } from "../../../../../components/TableIcons";
import SpinnerPage from "../../../../../Common/PP_loader";
import PatchedPagination from "../../../../../Common/TablePagination";
import { getPaymentPlansReportList, getPaymentPlansDetailsData } from "../../../../../action/ReportsAction";
import { handleError, handleWarning } from "../../../../../components/Notifications/Toast";
import { ExportToExcel } from "../../../../../Common/exportToExcel";
import * as Yup from "yup";
import { getIn } from "formik";
import { capitalize } from "../../../../../util/Captilize";
import ClearIcon from "@material-ui/icons/Clear";
import PaymentPlansTable from "./PaymentPlansTable";
import NumberFormat from "react-number-format";
const PaymentPlans = () => {
  const paymentMethodOptions = [
    { key: "Active", value: "Active" },
    { key: "Cancelled", value: "Cancelled" },
  ];
  const paymentType = [
    { status: 75001, value: "Credit Card" },
    { status: 75002, value: "Debit Card" },
    { status: 75003, value: "ACH" },
  ];
  const getValue = (status) => {
    const property = paymentType?.find((obj) => {
      if (obj.status === status) {
        return obj;
      } else {
        return null;
      }
    });
    return property?.value;
  };
  const dollarFormat = (value) => {
    if (value != null) {
      let amount = value;
      return `$${Number(amount).toLocaleString("us-en", {
        minimumFractionDigits: 2,
      })}`;
    } else {
      return value;
    }
  };

  let initialValues = {
    userName: "",
    seedAccountReferenceNumber: "",
    status: "none",
    startDate: null,
    endDate: null,
  };
  const dispatch = useDispatch();
  const [payplanReportData, setPayplanReportData] = useState([]);
  const [formData, setFormdata] = useState(initialValues);
  const [count, setCount] = useState(0);
  const [paymentPlanPopup, setPaymentPlanPopup] = useState(false);
  const [paymentPlanPopupData, setPaymentPlanPopupData] = useState([]);
  const [countPay, setCountPay] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [paginationData, setPaginationData] = useState({
    page: null,
    rowsPerPage: null,
  });
  const handlePayplanReport = (values) => {
    setFormdata(values);
    setIsLoading(true);
    dispatch(
      getPaymentPlansReportList(
        {
          requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token")),
          PaymentPlansReportModel: {
            name: values.userName,
            status: values.status === "none" ? "" : values.status,
            seedAccountReferenceNumber: values?.seedAccountReferenceNumber,
            pageNo: paginationData.page || 0,
            pageSize: paginationData.rowsPerPage || 10,
            startDate: values?.startDate ? moment(values?.startDate).format("YYYY-MM-DD") : null,
            endDate: values?.endDate ? moment(values?.endDate).format("YYYY-MM-DD") : null,
          },
        },
        (res) => {
          setIsLoading(false);
          if (res.data?.responseInfo?.status === "SUCCESSFUL") {
            setCount(res?.data?.totalCount);
            setPayplanReportData(res?.data?.paymentPlansReportResponse ? res?.data?.paymentPlansReportResponse : []);
          } else if (res.data?.responseInfo?.status === "FAILED") {
            setPayplanReportData([]);
            handleError(res.data.responseInfo?.responseMessage[0]);
          } else {
            setPayplanReportData([]);
            handleError(res?.message);
          }
        },
      ),
    );
  };
  const ExcelData = (data) => {
    const DataToExp = data.map((obj) => {
      const {
        masterAccountReferenceNumber,
        firstName,
        paymentMethodId,
        recurringAmmount,
        noOfPayments,
        startDate,
        recurringPaymentType,
        nextDueDate,
        remainingBalance,
      } = obj;

      const newObject = {
        "Seed Account Number": masterAccountReferenceNumber ? masterAccountReferenceNumber : "-",
        "Payee Name": firstName ? capitalize(firstName) : "-",
        "Payment Type": paymentMethodId ? getValue(paymentMethodId) : "-",
        "Payment Amount": recurringAmmount || recurringAmmount === 0 ? dollarFormat(recurringAmmount) : "-",
        "No Of Payments": noOfPayments ? noOfPayments : "-",
        "First Payment Date": startDate ? startDate : "-",
        "Payment Method": recurringPaymentType ? recurringPaymentType : "-",
        "Next Due Date": nextDueDate ? nextDueDate : "-",
        "Remaining Balance": remainingBalance || remainingBalance === 0 ? dollarFormat(remainingBalance) : "-",
      };
      return newObject;
    });

    ExportToExcel(DataToExp, "Payment Plan Report");
  };
  const schema = Yup.object().shape({
    startDate: Yup.string().nullable().required("Start Date is Required"),
    endDate: Yup.string().nullable().required("End Date is Required"),
  });
  const handlePayplanReportDownload = (values) => {
    setIsLoading(true);
    dispatch(
      getPaymentPlansReportList(
        {
          requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token")),
          PaymentPlansReportModel: {
            name: values.userName,
            status: values.status === "none" ? "" : values.status,
            seedAccountReferenceNumber: values?.seedAccountReferenceNumber,
            pageNo: 0,
            pageSize: -1,
            startDate: values?.startDate ? moment(values?.startDate).format("YYYY-MM-DD") : null,
            endDate: values?.endDate ? moment(values?.endDate).format("YYYY-MM-DD") : null,
          },
        },
        (res) => {
          setIsLoading(false);
          if (res.data?.responseInfo?.status === "SUCCESSFUL") {
            ExcelData(res?.data?.paymentPlansReportResponse);
            res.data.responseInfo?.responseMessage?.length > 0 && handleWarning(res.data.responseInfo?.responseMessage[0]);
          } else if (res.data?.responseInfo?.status === "FAILED") {
            handleError(res.data.responseInfo?.responseMessage[0]);
          } else {
            handleError(res?.message);
          }
        },
      ),
    );
  };
  const handleSubmit = (values) => {
    setSearchData(values);
    setIsSubmit(true);
  };
  return (
    <div>
      <Formik
        initialValues={{
          userName: "",
          seedAccountReferenceNumber: "",
          status: "none",
          startDate: null,
          endDate: null,
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        {(props) => {
          const { values, touched, error, handleChange, errors, setFieldValue } = props;
          return (
            <Form id="transaction-search" className="input-field" noValidate="noValidate">
              <div>
                <div className="form-row">
                  <div className="col-lg-3 col-md-4 col-sm-6 form-group ">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <label htmlFor="account" className="form-label w-100">
                        Seed Account Number
                      </label>

                      <Grid container className="date-picker">
                        <TextField
                          id="seedAccountReferenceNumber"
                          name="seedAccountReferenceNumber"
                          placeholder="Enter Seed Account Number"
                          type="text"
                          onChange={handleChange}
                          value={values.seedAccountReferenceNumber}
                          aria-label="text-field"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            inputComponent: NumberFormat,
                          }}></TextField>
                      </Grid>
                    </FormControl>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 form-group ">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid container className="custom-date-picker date-picker">
                          <label htmlFor="patientDOB" className="form-label w-100 required">
                            Start Date
                          </label>
                          <DatePicker
                            name="startDate"
                            id="startDate"
                            autoOk
                            value={values.startDate}
                            onChange={(value) => {
                              setFieldValue("startDate", value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={errors?.startDate}
                                helperText={null}
                                margin="dense"
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "MM/DD/YYYY",
                                }}
                              />
                            )}
                            InputProps={{
                              startAdornment: (
                                <IconButton
                                  position="end"
                                  onClick={() => setFieldValue("startDate", null)}
                                  style={{ order: 1, padding: 0 }}>
                                  <ClearIcon fontSize="small" />
                                </IconButton>
                              ),
                            }}
                            InputAdornmentProps={{
                              position: "end",
                              style: { order: 2 },
                            }}
                            error={errors?.startDate}
                          />
                        </Grid>
                      </LocalizationProvider>
                    </FormControl>
                    <FormHelperText error className="component-error-text">
                      {getIn(touched, "startDate") && getIn(errors, "startDate") && getIn(errors, "startDate")}
                    </FormHelperText>
                  </div>
                  <div className="col-lg-3 col-md-4  col-sm-6 form-group ">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid container className="custom-date-picker date-picker">
                          <label htmlFor="endDate" className="form-label w-100 required">
                            End Date
                          </label>
                          <DatePicker
                            name="endDate"
                            autoOk
                            value={values.endDate}
                            minDate={values.startDate - 1}
                            onChange={(val) => setFieldValue("endDate", val)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={errors?.endDate}
                                helperText={null}
                                margin="dense"
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "MM/DD/YYYY",
                                }}
                              />
                            )}
                            InputProps={{
                              startAdornment: (
                                <IconButton
                                  position="end"
                                  onClick={() => setFieldValue("endDate", null)}
                                  style={{ order: 1, padding: 0 }}>
                                  <ClearIcon fontSize="small" />
                                </IconButton>
                              ),
                            }}
                            InputAdornmentProps={{
                              position: "end",
                              style: { order: 2 },
                            }}
                            error={errors?.endDate}
                          />
                        </Grid>
                      </LocalizationProvider>
                    </FormControl>
                    <FormHelperText error className="component-error-text">
                      {getIn(touched, "endDate") && getIn(errors, "endDate") && getIn(errors, "endDate")}
                    </FormHelperText>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 form-group ">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <label htmlFor="status" className="form-label">
                        Status
                      </label>
                      <Select
                        fullWidth
                        name="status"
                        className="form-select form-control"
                        onChange={handleChange("status")}
                        value={values["status"]}>
                        <MenuItem value="none" key="none" disabled>
                          Select Status
                        </MenuItem>
                        {paymentMethodOptions.map((option) => {
                          return (
                            <MenuItem value={option.value} key={option.key}>
                              {option.value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 form-group ">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <label htmlFor="userName" className="form-label w-100">
                        Username
                      </label>

                      <Grid container className="date-picker">
                        <TextField
                          id="userName"
                          name="userName"
                          placeholder="Enter Username"
                          onChange={handleChange}
                          value={values.userName}
                          aria-label="text-field"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}></TextField>
                      </Grid>
                    </FormControl>
                  </div>
                </div>
                <div className="payment-history-btns">
                  <button aria-label="Generate Report" type="submit" className="primary-button themeing-buttons">
                    Search
                  </button>
                  <button
                    aria-label="Reset"
                    type="reset"
                    className="secondary-button mx-2"
                    onClick={() => {
                      setPayplanReportData([]);
                      setSearchData(null);
                    }}>
                    Reset
                  </button>
                  {payplanReportData.length > 0 && (
                    <button
                      type="button"
                      aria-label="Generate Report"
                      className="primary-button themeing-secondary-buttons"
                      onClick={() => {
                        handlePayplanReportDownload(values);
                      }}>
                      Download
                    </button>
                  )}
                </div>
              </div>
              {searchData ? (
                <PaymentPlansTable
                  searchData={searchData}
                  isSubmit={isSubmit}
                  setPayplanReportData={setPayplanReportData}
                  payplanReportData={payplanReportData}
                />
              ) : null}
            </Form>
          );
        }}
      </Formik>

      {isLoading && <SpinnerPage />}
    </div>
  );
};
export default PaymentPlans;
