import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import * as Yup from "yup";
import PamentMethod from "./PaymentMethodRadio";
import CreditCard from "./CreditCard";
import ECheck from "./ECheck";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  creditcard: {
    textAlign: "left !important",
  },
  inputfield: {
    textAlign: "left !important",
    fontSize: 14,
    color: "#536777",
    fontWeight: "normal",
    paddingTop: 10,
  },
}));

const options = [
  { key: "Master Card", value: "Master Card" },
  { key: "Visa", value: "Visa" },
  { key: "American Card", value: "American Card" },
];

const intialvalues = {
  cardType: "Master Card",
  expiryDate: null,
  cvv: "",
  cardNumber: "",
  nameOnCard: "",
  futureDate: null,
  agree: false,
  country: "usa",
  StateCounty: "IN",
  city: "crystalLake",
  zipCode: "",
  agreeToterms: true,
  agreeToEmailUsage: true,
  billingAddress: "1193 MALVINA AVE",
};

const schema = Yup.object().shape({
  cardType: Yup.string().trim().required("Card Type is required"),
});

const PaymentMethod = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [cardImg, setCardImg] = useState("");
  const [payType, setPayType] = useState("creditDebitCards");

  const payNow = history?.location?.state?.payNow;
  const amount = history?.location?.state?.amount;
  const hospitalInfo = history?.location?.state?.hospitalsInfo;

  const onSubmit = async (values, onSubmitProps) => {
    history.push(payNow ? "pay-now-success" : "pay-future-success");
  };

  const isUser = localStorage.getItem("role") === "user";
  const isPA = sessionStorage.getItem("currentUserRole") === "Admin";

  const handleCancel = () => {
    if (isUser) {
      history.push("/home/billing");
    } else if (isPA) {
      history.push("/pa/billing");
    }
  };

  return (
    <div className="dashboard-content" role="main">
      <div role="heading" aria-level="1">
        <h2 className="content-title">Payment Method Information</h2>
      </div>

      <div className="dashboard-content-card ">
        <div className={classes.inputfield}>
          <div className="account-balance">
            <p className="p-0">Total Outstanding Amount</p>
            <h3>$ 2,200.00</h3>
          </div>

          {hospitalInfo && hospitalInfo.totalOutStandingAmount && (
            <div className="account-balance">
              <p className="p-0">Total Outstanding Amount for {hospitalInfo.hospitalName}:</p>
              <h3>$ {hospitalInfo.totalOutStandingAmount}</h3>
            </div>
          )}

          {hospitalInfo && hospitalInfo.totalOutStandingAmount && (
            <div className="account-balance mb-4">
              <p className="p-0">Amount paid for that particular hospital</p>
              <h3>$ {hospitalInfo.totalOutStandingAmount}</h3>
            </div>
          )}

          {!(hospitalInfo && hospitalInfo.totalOutStandingAmount) && (
            <div className="account-balance mb-4">
              <p>Amount To Pay</p>
              <h3 className="pt-3">{amount ? amount : "$ 50.00"}</h3>
            </div>
          )}
          <PamentMethod payType={payType} setPayType={setPayType} />
          <hr />
          {payType === "creditDebitCards" && <CreditCard />}
          {payType === "eCheck" && <ECheck />}
        </div>
      </div>
      {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
    </div>
  );
};

export default PaymentMethod;
