import React, { useState, createRef, useEffect, useRef } from "react";

import { handleSuccess, ToastContainer, handleWarning, handleError } from "../../../../../components/Notifications/Toast";
// import { Translation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";

import { Tooltip } from "@material-ui/core";

import { tableIcons } from "../../../../../components/TableIcons";
import { capitalize } from "../../../../../util/Captilize";
import { httpPaginationRequest } from "../../../../../Common/httpPaginationRequest";
import { Global_var } from "../../../../../global/new _admin_globalvar";
import moment from "moment";
import { requestInfo_PP } from "../../../../../Common/CommonFn";
import PatchedPagination from "../../../../../Common/TablePagination";
import ConfirmationDialog from "../../../../../components/ConfirmationDialog/index";
import PaymentPlanDetailsPopup from "./PaymentPlanDetailsPopup";

const PaymentPlansTable = ({ tableData, searchData, isSubmit, updateState, setPayplanReportData, payplanReportData }) => {
  const tableRef = createRef();
  const [rows, setRows] = useState(5);
  const [paymentPlanId, setPaymentPlanId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  //   const [page, setPage] = useState(0);
  const page = useRef(null);
  useEffect(() => {
    paymentPlanId && setDialogOpen(true);
  }, [paymentPlanId]);

  const handleClose = () => {
    setDialogOpen(false);
    setPaymentPlanId(null);
  };
  const columns = [
    {
      title: "Seed Account Number",
      field: "masterAccountReferenceNumber",
      type: "numeric",
    },
    {
      title: "Payee Name",
      field: "firstName",
      render: (rowData) => {
        return rowData?.firstName ? capitalize(rowData?.firstName) : "--";
      },
    },
    {
      title: "Payment Type",
      field: "paymentMethodId",
      render: (rowData) => {
        return rowData ? getValue(rowData?.paymentMethodId) : "--";
      },
    },
    {
      title: "Payment Amount",
      field: "recurringAmmount",
      type: "numeric",
      render: (rowData) => (rowData.recurringAmmount ? dollarFormat(rowData.recurringAmmount) : "--"),
    },
    {
      title: "No Of Payments",
      field: "noOfPayments",
      type: "numeric",
    },
    {
      title: "First Payment Date",
      field: "startDate",
    },
    {
      title: "Payment Method",
      field: "recurringPaymentType",
    },
    {
      title: "Next Due Date",
      field: "nextDueDate",
    },
    {
      title: "Remaining Balance",
      field: "remainingBalance",
      type: "numeric",
      render: (rowData) => (rowData.remainingBalance ? dollarFormat(rowData.remainingBalance) : "--"),
    },
  ];
  const paymentType = [
    { status: 75001, value: "Credit Card" },
    { status: 75002, value: "Debit Card" },
    { status: 75003, value: "ACH" },
  ];
  const getValue = (status) => {
    const property = paymentType?.find((obj) => {
      if (obj.status === status) {
        return obj;
      } else {
        return null;
      }
    });
    return property?.value;
  };
  const dollarFormat = (value) => {
    if (value != null) {
      let amount = value;
      return `$ ${Number(amount).toLocaleString("us-en", {
        minimumFractionDigits: 2,
      })}`;
    } else {
      return value;
    }
  };
  const isInitialRender = useRef(false);
  useEffect(() => {
    isInitialRender.current = true;
  }, []);
  useEffect(() => {
    if (tableRef?.current && isSubmit && isInitialRender.current === false && payplanReportData) {
      if (searchData && Object.keys(searchData).length > 0) {
        tableRef.current.onQueryChange();
      }
    }
    if (isSubmit && isInitialRender.current) {
      isInitialRender.current = false;
    }
  }, [isSubmit, searchData]);
  return (
    <>
      <ConfirmationDialog
        open={dialogOpen}
        handleClose={handleClose}
        title="Payment Plan Details"
        noActionButtons={true}
        maxWidth="md">
        <PaymentPlanDetailsPopup
          handleClose={handleClose}
          tableRef={tableRef}
          paymentPlanId={paymentPlanId}
          dollarFormat={dollarFormat}
        />
      </ConfirmationDialog>
      <Grid item>
        <div className="payplan-table">
          <MaterialTable
            icons={tableIcons}
            columns={columns}
            tableRef={tableRef}
            localization={{
              pagination: {
                firstAriaLabel: "First Page",
                previousAriaLabel: "Previous Page",
                nextAriaLabel: "Next Page",
                lastAriaLabel: "Last Page",
              },
            }}
            data={(query) =>
              new Promise((resolve) => {
                if (!searchData) {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                } else {
                  page.current = query.page;
                  const passValues = {
                    requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token")),
                    PaymentPlansReportModel: {
                      name: searchData.userName,
                      status: searchData.status === "none" ? "" : searchData.status,
                      seedAccountReferenceNumber: searchData?.seedAccountReferenceNumber,
                      pageNo: query.page,
                      pageSize: query.pageSize ? query.pageSize : null,
                      startDate: searchData?.startDate ? moment(searchData?.startDate).format("YYYY-MM-DD") : null,
                      endDate: searchData?.endDate ? moment(searchData?.endDate).format("YYYY-MM-DD") : null,
                    },
                  };
                  httpPaginationRequest(Global_var.URL_PAYMENT_PLANS_REPORT, passValues, (res) => {
                    if (res?.status === 200 && res?.data?.paymentPlansReportResponse) {
                      // updateState({ isSubmit: false });
                      resolve({
                        data: res?.data?.paymentPlansReportResponse.length > 0 ? res?.data?.paymentPlansReportResponse : [],
                        page: page.current,
                        totalCount: Number(res.data.totalCount),
                      });
                      setPayplanReportData(res?.data?.paymentPlansReportResponse);
                    } else {
                      resolve({
                        data: [],
                        page: 0,
                        totalCount: 0,
                      });
                      handleError(res?.data?.responseInfo?.responseMessage[0]);
                    }
                  });
                }
              })
            }
            options={{
              selection: false,
              search: false,
              emptyRowsWhenPaging: false,
              sorting: false,
              pageSize: rows,
              pageSizeOptions: [5, 10, 20],
            }}
            onChangeRowsPerPage={(params) => {
              setRows(params);
            }}
            onRowClick={(event, rowData) => {
              setPaymentPlanId(rowData?.reccuringPaymentId);
            }}
            components={{
              Pagination: PatchedPagination,
            }}
            title={""}
            aria-level="2"
          />
        </div>
      </Grid>
    </>
  );
};

export default PaymentPlansTable;
