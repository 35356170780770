/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import { bindActionCreators, compose } from "redux";
import { success, successNotification } from "../Admin_Notification/Admin_Notification";
import { getLogin } from "../../reducer/loginReducer";
import { logoutUser } from "../../action/loginAction";
import Med1Logo from "../../assets/images/Med1Logo.png";
import WarningIcon from "@material-ui/icons/Warning";
import { useHistory } from "react-router-dom";
import { handleSuccess } from "../Notifications/Toast";
const mapStateToProps = (state) => ({
  ...getLogin(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutUser: logoutUser,
    },
    dispatch,
  );

class Admin_Header extends Component {
  constructor(props) {
    super(props);

    this._handleLogOut = this._handleLogOut.bind(this);
    this.state = {
      emailid: "John@gmail.com",
      name: "hh",
      applicationId: "",
    };
  }

  componentDidMount() {
    var queryString = window.location.search.split("&");

    if (queryString[0] !== "" && queryString[0] !== null) {
    } else {
      if (sessionStorage.getItem("loginDetails") !== null) {
        var userinfo = JSON.parse(sessionStorage.getItem("loginDetails"));
        this.setState({
          emailid: userinfo.emailId,
          name: userinfo.userName,
          intialsname: userinfo.name,
        });
      } else if (sessionStorage.getItem("verifypayer") !== null) {
        var userinfo = JSON.parse(sessionStorage.getItem("verifypayer"));
        this.setState({
          emailid: userinfo.contactEmail,
          name: userinfo.firstName,
          intialsname: userinfo.firstName,
        });
      } else {
      }
    }

    $(".nav-link").on("click", function () {
      $("body").toggleClass("sidebar-collapse");
    });
  }
  _handleLogOut() {
    sessionStorage.clear();
    localStorage.clear();
    this.props.history.push("/signin");
    handleSuccess("Logout Successfully");
  }

  editprofile = () => {
    this.props.history.push("/MyProfile");
  };

  render() {
    return (
      <React.Fragment>
        <header class="csapayheader">
          <nav className="navbar navbar-expand navbar-white navbar-light admin-main-header">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a
                  href={
                    sessionStorage.getItem("userData")
                      ? "/payment-outstanding"
                      : window.location.pathname === "/get-details" || window.location.pathname === "/payment-outstanding"
                      ? "javascript:void(0)"
                      : "/payment-outstanding"
                  }
                  className="nav-link logolink"
                  role="button">
                  <img src={Med1Logo} className="img-fluid" alt="Med-1 Solutions Logo" />
                </a>
              </li>
            </ul>
            {!(window.location.pathname === "/payment-scheduled") && (
              <div className="warning-message-card">
                <span>
                  <WarningIcon color="warning" />
                </span>
                <span className="warning-message">
                  <b>DISCLAIMER:</b> The balance shown is not updated in real-time and may not reflect credit for all
                  previous payments and/or adjustments. If you have any questions about this account please contact us.
                </span>
              </div>
            )}
            {sessionStorage.getItem("loginDetails") !== null || sessionStorage.getItem("verifypayer") !== null ? (
              <ul className="navbar-nav ml-auto logout-link">
                <li className="nav-item admin-diaplay-none">
                  <a
                    className="nav-link"
                    title="Logout"
                    data-widget="control-sidebar"
                    data-slide="true"
                    onClick={this._handleLogOut}
                    role="button">
                    <i className="fas fa-sign-out-alt admin-logout"></i>
                  </a>
                </li>
              </ul>
            ) : (
              ""
            )}
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Admin_Header);
