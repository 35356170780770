import React, { Component } from "react";
// import sicLogo from "../../../assets/img/sic-logo.png";

class whiteLable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partyWhiteLabelId: "1",
      partyId: "",
      applicationId: "11102",
      contentIds: "",
      pageName: "suman",
      moduleName: "aws",
      publicURL: "",
      publicterms: "",
    };
  }
  componentDidMount() {
    // loginService.TokenUser((resToken) => {
    //     if (resToken["status"] === "success") {
    //         sessionStorage.setItem(
    //             "jwt-tokencore",
    //             resToken.responseObject
    //         );
    //         jwttoken = resToken.responseObject
    //     }
    // });

    const queryString = window.location.search;
    const urlParameter = new URLSearchParams(queryString);

    var businessid = "2";
    var bussid = urlParameter.get("buid");
    if (queryString && bussid) {
      //const urlParams = new URLSearchParams(queryString);
      // const businessid = urlParams.get('buid');
      //businessidurl = bussid;
      sessionStorage.setItem("buid", bussid);
    } else {
      sessionStorage.setItem("buid", businessid);
    }

    // var PassValues = {
    //   "whitelabelConfigId":"",
    //   "applicationId": "11102"
    // }

    const WhiteLabelData = {
      whitelabelConfigId: "",
      applicationId: "11102",
      // partyWhiteLabelId: "1",
      // partyId: "",
      // applicationId: "11102"
    };
    if (sessionStorage.getItem("CompanyLogo") === null) {
      this.props.fetchWhiteLabel(
        WhiteLabelData,
        (res) => {
          if (res.status === "success") {
            var contentIds = [];
            contentIds.push(res.responseListObject[0].attributeJson.filter((item) => item.attributeName == "Logo")[0].value);
            contentIds.push(
              res.responseListObject[0].attributeJson.filter((item) => item.attributeName == "Terms&Condition")[0].value,
            );

            var contentid = contentIds.join();

            const contentData = {
              contentIds: contentid,
              buId: businessid,
              applicationId: 11102,
              pageName: "suman",
              moduleName: "aws",
            };
            this.props.fetchGetContent(
              contentData,
              (res) => {
                if (res.status === "success") {
                  this.setState({ publicURL: res.responseListObject[0].contentPublicURL });
                  sessionStorage.setItem("CompanyLogo", res.responseListObject[0].contentPublicURL);

                  this.setState({ publicterms: res.responseListObject[1].contentPublicURL });
                  sessionStorage.setItem("CompanyTerms", res.responseListObject[1].contentPublicURL);
                } else {
                }
              },
              (error) => {
                console.log({ error });
              },
            );
          } else {
          }
        },
        (error) => {
          console.log({ error });
        },
      );
    } else {
      this.setState({ publicURL: sessionStorage.getItem("CompanyLogo") });
    }
  }
  render() {
    return <React.Fragment> {/* <img alt="img" src={this.state.publicURL} width="95" height="33"></img> */}</React.Fragment>;
  }
}
export default whiteLable;
