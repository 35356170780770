import { Global_var } from "../global/admin_global_var";
import RestDataSource from "./restdatasource";
import { loginService } from "./loginService";

import {
  error,
  success,
  warning,
  warningNotification,
  errorNotification,
  successNotification,
} from "../components/PP_Notification/PP_Notification";

export const whiteLabelService = {
  getWhiteLabelList,
  saveUserConsent,
  updateUserConsent,
  checkUserConsent,
  isMobileVerified,
  isEmailVerified,
};

function getWhiteLabelList(whiteLabelData, userId, fn, fnError) {
  debugger;
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      debugger;
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_WHITE_LABEL_DATA;

      return new RestDataSource(url, userId, fn).Store(whiteLabelData, (res) => {
        debugger;
        if (res != null) {
          if (res["data"].status === "fail") {
            if (res["data"].reasonText === "Invalid Token") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/login");
            } else if (res["data"].reasonCode === "SM-02") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/login");
            } else if (res["data"].reasonCode === "SM-10") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/login");
            } else {
              console.log("Session expired! Please refresh the page");
            }
          } else {
            if (res.headers["token"] != null) {
              sessionStorage.setItem("jwt-tokencore", res.headers.token);
            }

            fn(res);
          }
        }
      });
    }
  }, fnError);
}

function saveUserConsent(creditCardInfo, userId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_ADD_CONSENT;
  return new RestDataSource(url, userId, fn).Store(creditCardInfo, (res) => {
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else {
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-tokencore", res.headers.token);
        }
        fn(res);
      }
    }
  });
}

function checkUserConsent(creditCardInfo, userId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_CONSENT;
  return new RestDataSource(url, userId, fn).Store(creditCardInfo, (res) => {
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else {
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-tokencore", res.headers.token);
        }
        fn(res);
      }
    }
  });
}

function updateUserConsent(creditCardInfo, userId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_UPDATE_CONSENT;
  return new RestDataSource(url, userId, fn).Store(creditCardInfo, (res) => {
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else {
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-tokencore", res.headers.token);
        }
        fn(res);
      }
    }
  });
}

function isEmailVerified(creditCardInfo, userId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_VERIFIED_EMAIL;
  return new RestDataSource(url, userId, fn).Store(creditCardInfo, (res) => {
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else {
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-tokencore", res.headers.token);
        }
        fn(res);
      }
    }
  });
}

function isMobileVerified(creditCardInfo, userId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_VERIFIED_MOBILE;
  return new RestDataSource(url, userId, fn).Store(creditCardInfo, (res) => {
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else {
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-tokencore", res.headers.token);
        }
        fn(res);
      }
    }
  });
}
