import React, { useContext, useEffect, useState } from "react";
import Routes from "./Routes";
import SideNav from "./SideNav";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Profile from "../../components/Profile";
import { AppContext } from "../../context/AppProvider";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { useLocation } from "react-router";
import Admin_Footer from "../../components/admin_footer/footer";
import WarningIcon from "@material-ui/icons/Warning";

export default function PAScreens(props) {
  const { sidebar, setSidebar, hoverNav, setHoverNav } = useContext(AppContext);
  const [underQP, setUnderQP] = useState(() => JSON.parse(sessionStorage.getItem("underQuickPay")));
  const location = useLocation();
  const role = sessionStorage.getItem("currentUserRole");
  const toggleClass = () => {
    if (sidebar === "true") {
      props.toggleSidenavClass(true);
    } else {
      props.toggleSidenavClass(sidebar);
    }
  };
  useEffect(() => {
    (location.pathname === "/credit-card" || location.pathname === "/add-echeck") && toggleClass();
  }, [sidebar]);

  const expandNav = () => setHoverNav(!hoverNav);
  // const expandNavOut = () => {
  //   setHoverNav(!hoverNav);
  //   role === "Admin" && setSidebar(false);
  // };
  return (
    <div className="admin-dashboard">
      {underQP !== true && (
        <>
          <div className={sidebar ? "dashboard-header" : "dashboard-header-collapse"}>
            <nav className={sidebar ? "navbar-collapse home-billing-navbar" : "home-billing-navbar"}>
              <ul>
                <li>
                  {sidebar ? (
                    <ArrowBackIosIcon
                      onClick={() => {
                        setHoverNav(false);
                        setSidebar(!sidebar);
                      }}
                    />
                  ) : (
                    <ArrowForwardIosIcon
                      onClick={() => {
                        setHoverNav(!hoverNav);
                        setSidebar(!sidebar);
                      }}
                    />
                  )}
                </li>
              </ul>
              <div
                className={
                  sidebar ? "warning-content-collapse billingdetails-warning-content" : " billingdetails-warning-content "
                }>
                <span>
                  <WarningIcon color="warning" />
                </span>{" "}
                <span className="billingdetails-warning-message">
                  <b>DISCLAIMER:</b> The balance shown is not updated in real-time and may not reflect credit for all
                  previous payments and/or adjustments. If you have any questions about this account please contact us.
                </span>
              </div>

              <ul>
                <li>{/* <LanguageSwitcher /> */}</li>
              </ul>
            </nav>
          </div>

          <div onMouseEnter={expandNav} onMouseLeave={expandNav}>
            <SideNav hoverNav={hoverNav} setHoverNav={setHoverNav} />
          </div>
        </>
      )}
      <div className={sidebar ? "dashboard-section" : underQP !== true && "dashboard-section-collapse"} role="article">
        <Routes />
      </div>
      <div className={sidebar ? "footer-section-cp" : "footer-section-collapse"}>
        <Admin_Footer />
      </div>
    </div>
  );
}
