import React from "react";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import { useHistory } from "react-router";
import PAPatientSearchFormContainer from "./PAPatientSearchFormContainer";

const PatientDetails = () => {
  const history = useHistory();
  const columns = [
    {
      title: "First Name",
      field: "firstName",
      minWidth: 90,
      width: 90,
      cellStyle: { textAlign: "left" },
    },
    {
      title: "Last Name",
      field: "lastName",
      minWidth: 90,
      width: 90,
      cellStyle: { textAlign: "left" },
    },
    {
      title: "MED-1 Account Number",
      field: "med1AccountNumber",
      minWidth: 170,
      width: 170,
      type: "numeric",
    },
    {
      title: "Social Security Number",
      field: "socialSecurityNumber",
      minWidth: 170,
      width: 170,
      type: "numeric",
    },
    {
      title: "Balance Amount",
      field: "balanceAmount",
      minWidth: 90,
      width: 90,
      type: "numeric",
    },
    {
      title: "Email ID",
      field: "emailId",
      minWidth: 90,
      width: 90,
      cellStyle: { textAlign: "left" },
    },
    {
      title: "Phone Number",
      field: "phoneNumber",
      minWidth: 90,
      width: 90,
      type: "numeric",
    },
    {
      title: "Date of Service",
      field: "dateOfService",
      minWidth: 90,
      width: 90,
      cellStyle: { textAlign: "center" },
    },
  ];
  const data = [
    {
      firstName: "Deina R",
      lastName: "Delacroix",
      med1AccountNumber: "28170753",
      socialSecurityNumber: "*****1309",
      emailId: "test@gmail.com",
      phoneNumber: "9999999999",
      balanceAmount: "$55.00",
      dateOfService: "06/22/2017",
    },
    {
      firstName: "Stevan",
      lastName: "Southwood",
      med1AccountNumber: "28170753",
      socialSecurityNumber: "*****1309",
      emailId: "test@gmail.com",
      phoneNumber: "9999999999",
      balanceAmount: "$4.00",
      dateOfService: "06/22/2017",
    },
    {
      firstName: "Linda",
      lastName: "Hawley",
      med1AccountNumber: "28170753",
      socialSecurityNumber: "*****1309",
      emailId: "test@gmail.com",
      phoneNumber: "9999999999",
      balanceAmount: "$4.00",
      dateOfService: "06/22/2017",
    },
    {
      firstName: "Diana",
      lastName: "Kirby",
      med1AccountNumber: "28170753",
      socialSecurityNumber: "*****1309",
      emailId: "test@gmail.com",
      phoneNumber: "9999999999",
      balanceAmount: "$4.00",
      dateOfService: "06/22/2017",
    },
    {
      firstName: "Elizabeth",
      lastName: "Rush",
      med1AccountNumber: "28170753",
      socialSecurityNumber: "*****1309",
      emailId: "test@gmail.com",
      phoneNumber: "9999999999",
      balanceAmount: "$4.00",
      dateOfService: "06/22/2017",
    },
  ];

  return (
    <div className="patient-details transaction-card" role="main">
      <div>
        <PAPatientSearchFormContainer />
        
      </div>
    </div>
  );
};

export default PatientDetails;
