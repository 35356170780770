import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getIn } from "formik";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormControlLabel,
  MenuItem,
  Select,
  FormLabel,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import ImageTooltip from "../../../components/ImageTooltip";
import CardPaymentTooltip from "../../../assets/images/CardPaymentTooltip.jpg";
import ECheckAddDialog from "./EcheckAddedDialog";
import { allStatesData, cityData, countryData } from "../../../util/StatesData";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import echeckPaymentRoutingNumber from "../../../assets/images/echeckPaymentRoutingNumber.jpg";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  creditcard: {
    textAlign: "left !important",
  },
  inputfield: {
    textAlign: "left !important",
    fontSize: 14,
    color: "#536777",
    fontWeight: "normal",
    paddingTop: 10,
  },
}));

const options = [
  { key: "Master Card", value: "Master Card" },
  { key: "Visa", value: "Visa" },
];

const intialvalues = {
  firstName: "",
  middleName: "",
  lastName: "",
  routingNumber: "",
  accountNumber: "",
  reAccountNumber: "",
  email: "",
  phoneNumber: "",
  message: "",
  accountType: "none",
  country: "usa",
  StateCounty: "IN",
  city: "crystalLake",
  zipCode: "",
  agreeToterms: true,
  agreeToEmailUsage: true,
};

const schema = Yup.object().shape({
  cardType: Yup.string().trim().required("Card Type is required"),
});

const AddECheck = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  const [isVisible, setIsVisible] = useState({
    routingNumber: true,
    accountNumber: true,
    reAccountNumber: true,
  });
  const handleRoutingNumber = () => {
    setIsVisible({ ...isVisible, routingNumber: !isVisible.routingNumber });
  };

  const handleAccountNumber = () => {
    setIsVisible({ ...isVisible, accountNumber: !isVisible.accountNumber });
  };
  const handleReAccountNumber = () => {
    setIsVisible({ ...isVisible, reAccountNumber: !isVisible.reAccountNumber });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const payNow = history?.location?.state?.payNow;

  return (
    <div className="dashboard-content" role="main">
      <ECheckAddDialog open={open} handleClose={handleClose} />
      <div role="heading" aria-level="1">
        <h2 class="content-title">eCheck Information</h2>
      </div>

      <div className="dashboard-content-card">
        <Formik initialValues={intialvalues} validationSchema={schema}>
          {(props) => {
            const { values, touched, error, handleChange, handleBlur, errors, setFieldValue } = props;
            return (
              <Form className="input-field e-check-bg" noValidate="noValidate">
                <div className="row">
                  <div className="col-md-12 mx-auto">
                    <div className="echeck-card">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <FormLabel for="firstName" className="required">
                              First Name
                            </FormLabel>
                            <OutlinedInput
                              className="form-control"
                              name="firstName"
                              value={values.firstName}
                              onChange={handleChange}
                              placeholder="Enter First Name"
                              aria-label="text-field"
                              fullWidth
                              required></OutlinedInput>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <FormLabel for="middleName" className="required">
                              Middle Name
                            </FormLabel>
                            <OutlinedInput
                              className="form-control"
                              name="middleName"
                              value={values.middleName}
                              onChange={handleChange}
                              placeholder="Enter Middle Name"
                              aria-label="text-field"
                              fullWidth
                              required></OutlinedInput>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <FormLabel for="lastName" className="required">
                              Last Name
                            </FormLabel>
                            <OutlinedInput
                              className="form-control"
                              name="lastName"
                              value={values.lastName}
                              onChange={handleChange}
                              placeholder="Enter Last Name"
                              aria-label="text-field"
                              fullWidth
                              required></OutlinedInput>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row m-0 justify-content-between align-items-center">
                              <FormLabel for="routingNumber" className="required">
                                Routing Number
                              </FormLabel>
                              <ImageTooltip img={echeckPaymentRoutingNumber} width="450px" placement="right">
                                <i className="fas fa-question-circle circle-icon" style={{ cursor: "pointer" }}></i>
                              </ImageTooltip>
                            </div>
                            <OutlinedInput
                              className="form-control signup-fields"
                              name="routingNumber"
                              value={values.routingNumber}
                              onChange={handleChange}
                              type={isVisible.routingNumber ? "text" : "password"}
                              placeholder="Enter Routing Number"
                              aria-label="text-field"
                              fullWidth
                              required
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleRoutingNumber}
                                    onMouseDown={handleMouseDownPassword}>
                                    {isVisible.routingNumber ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }></OutlinedInput>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <FormControl variant="outlined" className="w-100" fullWidth>
                              <FormLabel for="exampleFormControlInput1" className="form-label required">
                                Account Type
                              </FormLabel>
                              <Select
                                fullWidth
                                name="accountType"
                                onChange={handleChange("accountType")}
                                value={values.accountType}
                                className="form-select form-control">
                                <MenuItem value="none" disabled>
                                  Select Account Type
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row m-0 justify-content-between align-items-center">
                              <FormLabel for="exampleInputEmail1" className="required">
                                Account Number
                              </FormLabel>

                              <ImageTooltip img={echeckPaymentRoutingNumber} width="450px" placement="right">
                                <i className="fas fa-question-circle circle-icon" style={{ cursor: "pointer" }}></i>
                              </ImageTooltip>
                            </div>
                            <OutlinedInput
                              className="form-control signup-fields"
                              name="accountNumber"
                              value={values.accountNumber}
                              onChange={handleChange}
                              placeholder="Enter Account Number"
                              aria-label="text-field"
                              fullWidth
                              type={isVisible.accountNumber ? "text" : "password"}
                              required
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleAccountNumber}
                                    onMouseDown={handleMouseDownPassword}>
                                    {isVisible.accountNumber ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }></OutlinedInput>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <FormLabel for="reAccountNumber" className="required">
                              Re-enter Account Number
                            </FormLabel>
                            <OutlinedInput
                              className="form-control signup-fields"
                              name="reAccountNumber"
                              value={values.reAccountNumber}
                              onChange={handleChange}
                              type={isVisible.reAccountNumber ? "text" : "password"}
                              placeholder="Enter Re-Account Number"
                              aria-label="text-field"
                              fullWidth
                              required
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleReAccountNumber}
                                    onMouseDown={handleMouseDownPassword}>
                                    {isVisible.reAccountNumber ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }></OutlinedInput>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <FormLabel for="email" className="required">
                              Email Address
                            </FormLabel>
                            <OutlinedInput
                              className="form-control"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              type="email"
                              placeholder="Enter Email Address"
                              aria-label="text-field"
                              fullWidth
                              required></OutlinedInput>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <FormLabel for="phoneNumber" className="required">
                              Phone Number
                            </FormLabel>
                            <OutlinedInput
                              className="form-control"
                              name="phoneNumber"
                              value={values.phoneNumber}
                              onChange={handleChange}
                              placeholder="Enter Phone Number"
                              aria-label="text-field"
                              fullWidth
                              required></OutlinedInput>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <FormLabel for="billingAddress" className="required">
                              Billing Address
                            </FormLabel>
                            <OutlinedInput
                              name="message"
                              className="form-control"
                              value={values.message}
                              onChange={handleChange}
                              rows={5}
                              placeholder="Enter Your Billing Address"
                              aria-label="text-field"
                              fullWidth
                              required></OutlinedInput>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <FormControl variant="outlined" className="w-100" fullWidth>
                              <FormLabel for="country" className="form-label required">
                                Country
                              </FormLabel>
                              <Select
                                fullWidth
                                name="country"
                                value={values.country}
                                onChange={handleChange}
                                className="form-select form-control">
                                <MenuItem value="none" disabled>
                                  Select Country
                                </MenuItem>
                                {countryData.map((option) => {
                                  return (
                                    <MenuItem value={option.key} key={option.key}>
                                      {option.value}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <FormControl variant="outlined" className="w-100" fullWidth>
                              <FormLabel for="state" className="form-label required">
                                State/County
                              </FormLabel>
                              <Select
                                fullWidth
                                name="StateCounty"
                                value={values.StateCounty}
                                onChange={handleChange}
                                className="form-select form-control">
                                <MenuItem value="none" key="none" disabled>
                                  Select State/County
                                </MenuItem>
                                {allStatesData.map((option) => {
                                  return (
                                    <MenuItem value={option.key} key={option.key}>
                                      {option.value}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <FormControl variant="outlined" className="w-100" fullWidth>
                              <FormLabel for="city" className="form-label required">
                                City
                              </FormLabel>
                              <Select
                                onChange={handleChange}
                                fullWidth
                                name="city"
                                value={values.city}
                                className="form-select form-control">
                                <MenuItem value="none" key="none" disabled>
                                  Select City
                                </MenuItem>
                                {cityData.map((option) => {
                                  return (
                                    <MenuItem value={option.key} key={option.key}>
                                      {option.value}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <FormLabel for="zipCode" className="required">
                              ZIP Code
                            </FormLabel>
                            <OutlinedInput
                              className="form-control"
                              name="zipCode"
                              value={values.zipCode}
                              onChange={(event) => {
                                const zip = event.target.value;
                                setFieldValue(
                                    (values.zipCode = zip.length > 5 && !zip.includes("-") ? zip.substring(0, 5).concat("-") + zip.substring(5, zip.length) : zip)
                                );
                            }}
                              onBlur={handleBlur}
                              inputProps={{
                                maxLength: 10,
                              }}
                              placeholder="Enter ZIP Code"
                              aria-label="text-field"
                              fullWidth
                              required></OutlinedInput>
                          </div>
                        </div>
                      </div>
                      <div className="agree-terms">
                        <div className=" check-FormLabel">
                          <FormControl fullWidth component="fieldset" margin="normal">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="agreeToterms"
                                  checked={values.agreeToterms}
                                  onChange={handleChange}
                                  value={values.agreeToterms}
                                  color="primary"
                                />
                              }
                              label={
                                <span>
                                  By checking this box,you signify you have reviewed,understand,meet and agree to the{" "}
                                  <a href="/">Online Payment Terms and Conditions</a>
                                </span>
                              }
                            />
                          </FormControl>
                        </div>
                        <div className="check-FormLabel">
                          <FormControl fullWidth component="fieldset" margin="normal">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="agreeToEmailUsage"
                                  value={values.agreeToEmailUsage}
                                  checked={values.agreeToEmailUsage}
                                  onChange={handleChange}
                                  color="primary"
                                />
                              }
                              label={
                                <span>
                                  By checking this box,you agree that this is your personal email address and you authorise
                                  us to send you emails,call your phone, or contact you via an automated dialer,leave
                                  voicemails, and/or send text messages if necessary.
                                </span>
                              }
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="proceed-cancel-buttons">
                        <button
                          onClick={() =>
                            sessionStorage.getItem("currentUserRole") === "Admin"
                              ? history.push("/pa/account")
                              : history.push("/home/account")
                          }
                          className="secondary-button">
                          Cancel
                        </button>
                        <button
                          onClick={() => {
                            setOpen(true);
                          }}
                          className="primary-button">
                          Add eCheck
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddECheck;
