import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getIn } from "formik";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Grid } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ImageTooltip from "../../../components/ImageTooltip";
import Med1AccNumberTooltip from "../../../assets/images/medAccountNumberTooltip.jpg";

const intialvalues = {
  med1AccNum: "",
  ssn: "",
  patientDOB: null,
  email: "",
  phone: "",
};

const schema = Yup.object().shape({
  med1AccNum: Yup.string().trim().required("Account Number is required"),
});

const MyDetails = () => {
  const [expanded, setExpanded] = React.useState(true);

  const handleExpandedChange = () => {
    setExpanded(!expanded);
  };
  const onSubmit = async (values, onSubmitProps) => {
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <Formik initialValues={intialvalues} onSubmit={onSubmit} validationSchema={schema}>
            {(props) => {
              const { values, touched, error, handleChange, errors, setFieldValue } = props;
              return (
                <Form id="landing-page" className="input-field" noValidate="noValidate">
                  <Accordion className="accordion-section" expanded={expanded} onChange={handleExpandedChange}>
                    <AccordionSummary
                      className="up-arrow accordion-header"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <Typography variant="h6" aria-level="2">
                        My Details
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordian-details">
                      <div className="row">
                        <div className="col-md-6 col-lg-4">
                          <div className="form-group">
                            <label htmlFor="firstName">First Name</label>
                            <OutlinedInput
                              className="form-control"
                              id="firstName"
                              name="firstName"
                              placeholder="Enter First Name"
                              onChange={handleChange}
                              value={values["firstName"]}
                              aria-label="text-field"
                              fullWidth
                              type="firstName"
                              error={Boolean(getIn(touched, "firstName") && getIn(errors, "firstName"))}></OutlinedInput>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <div className="form-group">
                            <label htmlFor="lastName">Last Name</label>
                            <OutlinedInput
                              className="form-control"
                              id="lastName"
                              name="lastname"
                              placeholder="Enter Last Name"
                              onChange={handleChange}
                              value={values["lastname"]}
                              aria-label="text-field"
                              fullWidth
                              type="lastname"
                              error={Boolean(getIn(touched, "lastname") && getIn(errors, "lastname"))}></OutlinedInput>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <div className="form-group">
                            <label htmlFor="socialSecurityNumber">Social Security Number</label>
                            <div className="security-input">
                              <OutlinedInput
                                className="form-control security-input"
                                id="socialSecurityNumber"
                                name="ssn"
                                placeholder="Please Enter SSN"
                                onChange={handleChange}
                                value={values["ssn"]}
                                aria-label="text-field"
                                fullWidth
                                error={Boolean(getIn(touched, "ssn") && getIn(errors, "ssn"))}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <i className="fas fa-eye eye-icon"></i>
                                  </InputAdornment>
                                }></OutlinedInput>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <div className="form-group">
                            <label htmlFor="emailAddress">Email Address</label>
                            <OutlinedInput
                              className="form-control security-input"
                              id="emailAddress"
                              name="email"
                              type="email"
                              placeholder="Please Enter Email"
                              onChange={handleChange}
                              value={values["email"]}
                              aria-label="text-field"
                              fullWidth
                              error={Boolean(getIn(touched, "email") && getIn(errors, "email"))}></OutlinedInput>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <div className="form-group">
                            <label htmlFor="phoneNumber">Phone Number</label>
                            <OutlinedInput
                              className="form-control security-input"
                              id="phoneNumber"
                              name="phone"
                              placeholder="Please Enter Phone Number"
                              onChange={handleChange}
                              value={values["phone"]}
                              aria-label="text-field"
                              fullWidth
                              error={Boolean(getIn(touched, "phone") && getIn(errors, "phone"))}></OutlinedInput>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="proceed-cancel-buttons">
                            <button className="secondary-button" aria-label="cancel">
                              Cancel
                            </button>
                            <button type="submit" className="primary-button" aria-label="save">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default MyDetails;
