import React from "react";
import { Link } from "react-router-dom";
import UpdateTrackingPage from "../containers/UpdateTrackingPage";
import TermsAndConditions from "./../components/ContactUs/index";

export default function ErrorPage() {
  let userRole = sessionStorage.getItem("currentUserRole");
  return (
    <div className="errorpage-section" role="main">
      <div className="container-fluid">
        <div className="error-page-form-section">
          <div className="page-error-content">
            <div className="d-flex justify-content-center text-center error-heading-style error-button-style ">
              Something went wrong. Please <TermsAndConditions />
            </div>
            <div className="text-center">
              <Link to={userRole && userRole === "Admin" ? "/admin" : "/signin"} className="primary">
                Go to Sign in Page
              </Link>
            </div>
          </div>
        </div>
      </div>
      {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
    </div>
  );
}
