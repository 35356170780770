import { Global_var } from "../global/global_var";
import { loginService } from "./loginService";
import RestDataSource from "./restdatasource";
import { warning, warningNotification } from "../components/Admin_Notification/Admin_Notification";

export const VoidTransactionService = {
  getVoidTransaction,
  postVoidTran,
  postReverseTran,
};

function getVoidTransaction(payload, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_TRANSACTION_DETAILS;
      return new RestDataSource(url, "", fn).Store(payload, (res) => {
        if (res != null) {
          if (res["data"].status === "fail") {
            if (res["data"].reasonText === "Invalid Token") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/login");
            } else {
              warning("Session expired! Please refresh the page", warningNotification);
            }
          } else {
            if (res.headers["token"] != null) {
              sessionStorage.setItem("jwt-token", res.headers.token);
            }

            fn(res);
          }
        }
      });
    }
  }, fnError);
}

function postVoidTran(payload, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-token", res.responseObject);
      // var url = Global_var.URL_GET_CONTENT_LABEL_DATA;
      //  var url= "http://localhost:8056/pp-report-service/transaction/api/v1/_details"
      var url = Global_var.BASEURL + Global_var.URL_POST_VOID_TRANSACTION;
      return new RestDataSource(url, "", fn).Store(payload, (res) => {
        if (res != null) {
          if (res["data"].status === "fail") {
            if (res["data"].reasonText === "Invalid Token") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/login");
            } else {
              warning("Session expired! Please refresh the page", warningNotification);
            }
          } else {
            if (res.headers["token"] != null) {
              sessionStorage.setItem("jwt-token", res.headers.token);
            }

            fn(res);
          }
        }
      });
    }
  }, fnError);
}

function postReverseTran(payload, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-token", res.responseObject);
      // var url = Global_var.URL_GET_CONTENT_LABEL_DATA;
      //  var url= "http://localhost:8056/pp-report-service/transaction/api/v1/_details"
      var url = Global_var.BASEURL + Global_var.URL_POST_REFUND_TRANSACTION;
      return new RestDataSource(url, "", fn).Store(payload, (res) => {
        if (res != null) {
          if (res["data"].status === "fail") {
            if (res["data"].reasonText === "Invalid Token") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/login");
            } else {
              warning("Session expired! Please refresh the page", warningNotification);
            }
          } else {
            if (res.headers["token"] != null) {
              sessionStorage.setItem("jwt-token", res.headers.token);
            }

            fn(res);
          }
        }
      });
    }
  }, fnError);
}
