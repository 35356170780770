import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "@material-ui/core";

const MinimumBalancePlan = ({ handleClose }) => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <p>
            Sorry! Payment Arrangement Option is not available for this account. Please pay through other options{" "}
            <span className="value">'Pay Now'</span>
            or <span className="value">'Pay in Future'</span>.
          </p>
        </div>
      </div>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary" variant="outlined" className="themeing-buttons ml-1">
          Ok
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default MinimumBalancePlan;
