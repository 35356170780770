export const ToastMsg = {
  Introduction: "Introduction",
  Advantages: "Advantages",
  admin_Loginheader: "Login",
  username: "Med1 Username",
  password: "Med1 Password",
  ForgotPassword: "Forgot Password?",
  ForgotUser: "Forgot Username?",
  admin_LOGIN: "LOGIN",
  usernamerequired: "Username is required",
  passwordrequired: "Password is required",
  "admin_Don’thaveanaccount": "Don’t have an account?",
  admin_Register: "Register",
  admin_ContactUs: "Contact Us",
  admin_NeedHelp: "Need Help?",
  echeque_totalAutstandingAmount: "Total Outstanding Amount ",
  echeque_routingnumber: "Routing Number",
  echeque_amountToPay: "Amount to Pay",
  echeque_echecquePayment: "eCheck Payment",
  echeque_add: "Add eCheck",
  echeque_needhelp: "Need Help?",
  echeque_accountType: "Account Type",
  echeque_accountNumber: "Account Number",
  echeque_reenteraccountNumber: "Re-enter Account Number",
  echeque_emailAddress: "Email Address",
  echeque_phoneNumber: "Phone Number",
  echeque_billingAddress: "Billing Address",
  echeque_country: "Country",
  echeque_selectcountry: "Select Country",
  echeque_state: "State",
  echeque_selectstate: "Select State",
  echeque_selectcity: "Select City",
  echeque_city: "City",
  echeque_zipCode: "ZIP Code",
  echeque_inputChcekbox:
    "By checking this box, you agree that this is your personal email address and you authorize us to send you emails, call your phone, or contact you via an automated dialer, leave voicemails, and/or send text messages, if necessary",
  echeque_submitButton: " PAY",
  echeque_addButton: "ADD eCheck",
  echeque_cancelButton: "CANCEL",
  verify_erraccountNumber: "MED-1 Account Number is required",
  echeque_erraccountNumber: "Account Number is required",
  echeque_erraddress1: "Billing Address is required",
  echeque_errcity: "City is required",
  echeque_errcountry: "Country is required",
  echeque_erremail: "Email Address is required",
  echeque_errphnumber: "Phone Number is required",
  echeque_selectacctype: "Select Account Type",
  echeque_errreenteraccnum: "Re-enter Account Number is required",
  echeque_erraccountType: "Account Type is required",
  echeque_erraccnummatch: "Account Number must match",
  echeque_errrountingnum: "Routing Number is required",
  echeque_errstatee: "State is required",
  echeque_errzipcode: "ZIP Code is required",
  echeque_successTransactionProcessed: "Transaction Processed Successfully",
  echeque_errorMsgwentWrong: "Something went wrong!!",
  echeque_warningMsgwentWrong: "Something went wrong!!",
  echeque_errTermsAndConditions: "You must agree to the Online Payment Terms and Conditions",
  echeque_placeholderRoutingNumber: "Routing Number",
  echeque_placeholderAccountNumber: "Account Number",
  echeque_placeholderReenterAccountNumber: "Re-enter Account Number",
  echeque_placeholderEmailAddress: "Email Address",
  echeque_placeholderPhoneNumber: "Phone Number",
  echeque_placeholderBillingAddress: "Billing Address",
  echeque_placeholderZipCode: "ZIP Code",
  echeque_firstName: "First Name",
  echeque_middleName: "Middle Name",
  echeque_placeholderMiddleName: "Middle Name",
  echeque_placeholderFirstName: "First Name",
  echeque_lastName: "Last Name",
  echeque_placeholderLastName: "Last Name",
  err_echeque_firstName: "First Name is required",
  err_echeque_lastName: "Last Name is required",
  err_echeque_middlename: "Middle Name is required",
  getDetail_quickpay: "Quick Pay",
  getDetail_placeholderAccNum: "MED-1 Account Number",
  getDetail_placeholderSSN: "Social Security Number",
  getDetail_medoneaccountNumber: "MED-1 Account Number",
  date_of_birth: "Date Of Birth",
  getDetail_ssn: "Social Security Number",
  getDetail_patientDOB: "Patient's Date of Birth",
  getDetail_Recaptcha: "reCaptcha",
  getDetail_submitButton: "NEXT",
  getDetail_errmsgAccNum: "Account Number is required",
  getDetail_errmsgRecapcha: "Check reCaptcha",
  getDetail_errLastFourDigitMin:
    "Either only last 4 digits of Social Security Number or Patient’s Date of Birth are required",
  getDetail_errLastFourDigitMax:
    "Either only last 4 digits of Social Security Number or Patient’s Date of Birth are required",
  getDetail_errLastFourDigit: "Either last 4 digits of Social Security Number or Patient’s Date of Birth are required",
  getDetail_errmsgSSN: "Please enter last 4 digits of Social Security Number",
  getDetail_errmsgoneodSSN: "One of Social Security Number, date Of Birth must be entered",
  getDetail_errmsgoneodDOB: "One of Social Security Number, date Of Birth must be entered",
  getDetail_errorMsgwentWrong: "Something went wrong!!",
  getDetail_warningMsgWrong: "Can not find MED-1 Account with information supplied!",
  getDetail_warningMsgWrongsecond: "Please contact MED-1 support at :",
  getDetail_warningMsgWrongthird: "(888) 323-0811",
  getDetail_SucessPayerMsg: "Payer verified successfully, fetching billing details",
  contactus_Contact: "Contact Us",
  contactus_FAQ: "FAQs",
  contactus_Emailsupport: "Email Support",
  contactus_patientadvocate: "Speak to a Patient Advocate",
  contactus_communicateheader:
    "If you need further assistance, you can communicate with us by entering your information below, and we will contact you.",
  contactus_yourName: "Your Name",
  contactus_phoneNumber: "Phone Number",
  contactus_emailID: "Email Address",
  contactus_yourQuery: "Your Message Here",
  contactus_submitButton: "SUBMIT",
  contactus_cancelButton: "CANCEL",
  contactus_erryourname: "Please enter your Name",
  contactus_erremailadd: "Email Address is required",
  contactus_erremailaddmaching: "Please enter a valid Email Address",
  contactus_errorMessage: "Something went wrong!!",
  contactus_warningMessage: "Something went wrong!!",
  contactus_successMessage: "Your message has been received. Someone will respond to you shortly.",
  contactus_phnumber: "Phone Number is required",
  contactus_errcontactnum: "Please enter 10 digits Contact Number",
  contactus_errmincontactnum: "Please enter 10 digits Phone Number",
  contactus_errmaxcontactnum: "Please enter 10 digits Phone Number",
  contactus_erryourquery: "Your Message is required here",
  contactus_placeholderYourName: "Your Name",
  contactus_placeholderPhoneNumber: "Phone Number",
  contactus_placeholderEmailAddress: "Email Address",
  contactus_placeholderEnterYourMessageHere: "Enter Your Message Here",
  credit_totalAccountBal: "Total Account Balance",
  credit_amountToPay: "Amount to Pay",
  credit_cardName: "Name on Card",
  credit_cardFName: "First Name on Card",
  credit_cardMName: "Middle Name on Card",
  credit_cardLName: "Last Name on Card",
  credit_cardNume: "Card Number",
  credit_expiryDate: "Expiration Date",
  credit_month: "Month",
  credit_year: "Year",
  credit_needhelp: "Need Help?",
  credit_securityCode: "Security Code",
  crdit_emailId: "Email Address",
  credit_phoneNum: "Phone Number",
  credit_billAddress: "Billing Address",
  credit_country: "Country",
  credit_selectcountry: "Select Country",
  credit_state: "State",
  credit_selectstate: "Select State",
  credit_city: "City",

  credit_selectcity: "Select City",
  credit_zipcode: "ZIP Code",
  credit_consent:
    "By checking this box, you acknowledge that this is your personal email address and you authorize us to send you emails, call your phone, or contact you via an automated dialer, leave voicemails, and/or send text messages, if necessary.",
  echeck_consent:
    "I authorize MED-1 Solutions to electronically debit my checking/savings account for the amount and term indicated above. I understand that electronic debits will be returned if there are insufficient funds in the account at the time of the debit. I also understand that if my payment is returned to MED-1 Solutions as NSF for any reason by the bank that I authorize MED-1 Solutions to initiate an additional and separate fee in accordance with Oregon Revised Statutes §30.701 for the returned check expenses. This authorization is to remain in full force and effect until MED-1 Solutions has received written notification from me of its termination in such manner as to afford MED-1 Solutions a reasonable opportunity to act on it (3 business days).",
  credit_payButton: "PAY",
  credit_addcard: "Add Card",
  credit_cancelButton: "CANCEL",
  credit_minnameOnCard: "Please enter at least 1 Characters",
  regusername: "Please enter at least 3 Characters",
  credit_firstnameOnCard: "First Name is required",
  credit_middlenameOnCard: "Middle Name is required",
  credit_lastnameOnCard: "Last Name is required",
  credit_maxnameOnCard: "Max 20 characters allowed",
  credit_cardnum: "Valid Card Number is required",
  credit_selectmonth: "Select Month",
  credit_selectyear: "Select Year",
  credit_emailaddress: "Email Address is required",
  credit_cvvnumber: "Security Code is required",
  credit_phnumber: "Phone Number is required",
  credit_address: "Billing Address is required",
  credit_errcountry: "Country is required",
  credit_errstate: "State is required",
  credit_errcity: "City is required",
  credit_errzipcode: "ZIP Code is required",
  credit_warningSomethingWrong: "Something went wrong!!",
  credit_errorMsgSomethingWrong: "Something went wrong!!",
  credit_erremailaddmaching: "Please enter a valid Email Address",
  credit_errTermsAndConditions: "You must agree to the Online Payment Terms and Conditions",
  credit_successTransactionProcessed: "Transaction Processed Successfully",

  credit_placeholderNameOnCard: "Name on Card",
  credit_placeholderfirstname: "First Name",
  credit_placeholdermiddlename: "Middle Name",
  credit_placeholderlastname: "Last Name",
  credit_placeholderZipCode: "ZIP Code",
  credit_placeholderCardNumber: "Enter Card Number",
  credit_placeholderSecurityCode: "Security Code",
  credit_placeholderEmailAddress: "Email Address",
  credit_placeholderPhoneNumber: "Phone Number",
  credit_placeholderBillingAddress: "Billing Address",
  receipt_payment: "You made a payment of",
  receipt_totalOutstansingAmt: "Remaining Outstanding Amount is",
  receipt_ConfirmationNum: "Confirmation No.",
  receipt_AuthorizationNum: "Transaction ID",
  receipt_TransDate: "Transaction Date",
  receipt_transactionSuccess: "Transaction Successful",
  receipt_transactionPending: "Transaction Pending",
  receipt_Email: "Email Address",
  receipt_Phone: "Phone Number",
  receipt_PaymentType: "Payment Method",
  receipt_FromAccount: "From Account",
  receipt_PaidTo: "Paid To",
  receipt_PaymentAmount: "Payment Amount",
  receipt_EmailedMessage: "This receipt will be emailed to you shortly",
  receipt_ViewButton: "View Receipt",
  receipt_DownloadButton: "Download Receipt",
  receipt_DoneButton: "DONE",
  receipt_successDownloadReceipt: "You can Download receipt",
  receipt_errorMsgSomethimgWrong: "Something went wrong!!",
  receipt_warningSomethingWRONG: "Something went wrong!!",
  receipt_warningPdfGeneration: "PDF generation is in progress, you can download once it is generated",
  viewreceipt_label: "Payment Receipt",
  viewreceipt_confirmationNum: "Confirmation No.",
  viewreceipt_AuthorizationNum: "Transaction ID",
  viewreceipt_transDate: "Transaction Date",
  viewreceipt_emailId: "Email Address",
  viewreceipt_phoneNum: "Phone Number",
  viewreceipt_paymentType: "Payment Method",
  viewreceipt_fromAccount: "From Account",
  viewreceipt_paidTo: "Paid To",
  viewreceipt_paymentAmt: "Payment Amount",
  viewreceipt_doneButton: "CLOSE",
  quickpaycard_title: "Make a secure payment through your bank account or credit/debit card.",
  quickpaycard_quickpay: "Quick Pay",
  quickpaycard_processpayment: "Process Payment",
  getDetail_needhelp: "Need Help?",
  getDetail_ssnTitle: "Please enter last 4 digits of Social Security Number or Date of Birth",
  getDetail_or: "OR",

  FAQ_Faq: "Frequently Asked Questions",
  FAQ_quesone: "Why am I being charged a late fee?",
  FAQ_questwo:
    " Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.",
  FAQ_questhree: "How is Simple safer than cards and other modes of payment?",
  FAQ_quesfourth:
    "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.",
  FAQ_quesfifth: "How does billing cycle work?",
  FAQ_quessixth:
    " Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.",
  FAQ_quesseventh: "How long do refunds take on MED-1 Solutions?",
  FAQ_queseight:
    "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.",
  FAQ_quesnine: " How long do refunds take on MED-1 Solutions?",
  FAQ_questenth:
    " Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.",
  FAQ_ViewMore: "View More",
  FAQ_closeButton: "CLOSE",
  credit_creditCardPayment: "Debit/Credit Card Payment",
  credit_addCard: "Add Debit/Credit Card",
  receipt_PaymentReceipt: "Payment Receipt",
  receipt_Generatepdf: "Generating PDF",
  paymentSummary_ssn: "Social Security Number",
  paymentSummary_selectPaymentMethod: "Payment Method",
  paymentSummary_errormsgselectPaymentMethod: "Select payment method",
  paymentSummary_payFullblc: "Pay Full Balance",
  paymentSummary_payOtherAmt: "Pay Other Amount",
  paymentSummary_fees: "Fees",
  paymentSummary_GrandTotal: "Grand Total",
  paymentSummary_PAY: "PAY",
  paymentSummary_PAY_Now: "Pay Now",
  paymentSummary_IN_Future: "Pay in Future",
  paymentSummary_CANCEL: "CANCEL",
  paymentSummary_selectpaymethodplaceholder: "Select Payment Method",
  paymentSummary_warningApportionedPayment: "Please adjust the opted payment amount amongst listed accounts appropriately",
  paymentSummary_warningAmtLessThanFullBlc: "Payment cannot be greater than Full Balance",
  paymentSummary_warningotherAmtAtLeast: "Minimum payment should be at least $50.00",
  paymentSummary_warningotherAmtlessThan: "Payment cannot be greater than Full Balance",
  paymentSummary_errorMsgTechnical: "Technical Error",
  paymentSummary_warningMsgTechnical: "Something went wrong!!",
  paymentSummary_errorselectPaymentMethod: "Select Payment Method",
  billSummary_errormsgFillAmt: "Please enter the amount to pay",
  billSummary_errormsgNoAmt: "No outstanding bills",
  billSummary_header:
    "Below is a list of your accounts.  If you would like to apply your payment to a specific account, enter the amount for that account.  Otherwise, payments will be applied to the oldest account first.",
  billSummary_paymentAmount: "Payment Amt.",
  billSummary_DateofService: "Date of Service",
  billSummary_outstandingAmt: "Outstanding Amt.",
  billSummary_AccountNumberr: "Account Number",
  "billSummary_Account Type": "Account Type",
  billSummary_PAY: "PAY",
  billSummary_CANCEL: "CANCEL",
  billSummary_worningOutAmt: "Payment amount cannot be greater than Outstanding Amount",
  billSummary_worningPartialAmt: "Payment amount cannot be greater than Outstanding Amount",
  billSummary_errorMsgSomethingWrong: "Something went wrong!",
  billSummary_placeholderPaymentAmt: "Payment Amt.",
  stepper_makeapayment: "Make a Payment",
  stepper_thankyou: "Thank You",
  stepper_processpayment: "Process Payment",
  quickPayFooter_ContactUs: "Contact Us",
  quickPayFooter_Copyrights: "©2020 All Rights Reserved.",
  quickPayFooter_content:
    "WE ARE DEBT COLLECTORS. THIS IS AN ATTEMPT TO COLLECT A DEBT. ANY INFORMATION OBTAINED WILL BE USED FOR THAT PURPOSE.",
  quickPayFooter_needHelp: "Need Help?",
  termsandcondition_header:
    " By checking this box, you acknowledge that you have reviewed, understand, meet and agree to the",
  termsandcondition_onlinepayTrem: "Online Payment Terms and Conditions",
  termsandcondition_term: "Terms and Conditions",
  termsandcondition_times: "&times;",
  termsandcondition_OnlinePaymentTermConditions: "Online Payment Terms and Conditions",
  termsandcondition_AgreePaymentTermConditions: "PLEASE READ AND AGREE TO THE FOLLOWING AUTHORIZATION",
  termsandcondition_autorization: "Authorization for Automated Clearinghouse (ACH) Debits",
  termsandcondition_authCHeckBox:
    "By checking the box “Online Payment Terms and Conditions,” you authorize MED-1 Solutions, LLC to initiate, modify or terminate an electronic single entry or recurring (if applicable) ACH debit(bank draft) from the financial institution where the checking or savings account you designated is located for the purpose of making a payment on your outstanding account balance.  The ACH will be for the amount you designated and will be submitted on the date(s) you designated.",
  termsandcondition_autorizationCreditCard: "Authorization for Credit Card Payments",
  termsandcondition_autorizationchecking:
    " By checking the box “Online Payment Terms and Conditions,” you authorize MED-1 Solutions, LLC to initiate, modify or terminate an electronic single entry or recurring credit card payments from the credit card you designated for the purpose of making a payment on your outstanding account balance. The credit card payment will be for the amount you designate and will be submitted on the date(s) you designated.",
  termsandcondition_Fees: "Fees",
  termsandcondition_responsibility:
    "You are responsible for any fees charged by your financial institution related to any payments, transactions, or other use of this website. A $7.00 transaction fee will be added to your payment however to avoid this fee your payment can be mailed to the following address, 517 US Highway 31 N. Greenwood, IN, 46142. Please include your account number on the check for proper assignment of your payment.",
  termsandcondition_FailedPayment: "Failed Payment",
  termsandcondition_FailedPaymentText:
    "If a designated payment fails for any reason, you remain obligated to timely pay any amount then due in accordance with the terms and conditions of your Agreement. You acknowledge that your bank may charge you fees if a payment is denied (e.g., due to insufficient funds in your bank account to satisfy the entire designated payment amount), in addition to any charges we may assess to your account under the terms and conditions of your Agreement and as allowed by law.",
  termsandcondition_PaymentTiming: "Payment Timing and Credit",
  termsandcondition_AchcreditCard:
    "You may make payments electronically,via ACH or credit card, through our website (online payments)",
  termsandcondition_AnyPaymentMethod:
    "Any online payment that you authorize will be credited to your account on the day that you designate, so long as we receive your payment by 1:00 p.m. Eastern Standard Time (EST) on a business day (Monday through Friday, excluding Federal holidays). If we receive your request after 1:00 p.m. EST or on a non-business day, we will credit your payment as of the next business day. Once your online payment is made, it may take up to 2 business days for your electronic payment, and up to 4 business days for an ACH payment (bank draft) to be reflected in your account records, including online account services. ",
  termsandcondition_AmountsDueMaturity: "Amounts Due at Maturity",
  termsandcondition_enroll:
    "If you enroll in recurring payments, the amount due at maturity to satisfy your outstanding account balance may vary, in an amount that is more or less than the amount that you designated through recurring payments. If it is less than (or equal to) the designated amount, we will debit the remaining account balance on the next designated payment date. If it is more than the designated amount, we will only debit the designated amount on the next designated payment date, leaving an outstanding balance on your account. You agree to make alternative payment arrangements to satisfy any outstanding account balance at maturity to avoid, as applicable, any additional fees and late charges. You acknowledge that a failure to make such arrangements will result in your continued enrollment in recurring payments until your outstanding account balance is satisfied.",
  termsandcondition_PaymentModification: "Payment Termination or Modification",
  termsandcondition_PaymentTerminateModification:
    "You may terminate or modify a single or recurring payment by calling us at 888.323.0811 in time for us to receive your request, at least 3 business days before the payment is scheduled to be made. Business day shall mean any day of the week that is not a Sunday or a Federal holiday. The end of each business day shall be 6:00 p.m. Eastern Standard Time Monday through Friday and 1:00 p.m. Eastern Standard Time on Saturday. ",
  termsandcondition_Documentation: "Documentation",
  termsandcondition_AddressProvide:
    "You agree to retain a copy of this authorization and all transaction confirmations that we email you to the email address provided.",
  termsandcondition_btnClose: "Close",
  registerLink_headerOne: "View your bill history",
  registerLink_headertwo: "View your payment history",
  registerLink_headerThree: "Make consolidated payments for bills across hospitals",
  registerLink_headerfour: "Schedule payments",
  registerLink_headerFive: "Create a family account and many more",
  registerLink_header: "Please register to avail the following features",
  registerLink_registerrNow: "REGISTER NOW",
  echeque__numerlimit: "Phone number must be 10 digits",
  userdata_consent: "Please check the Email Consent checkboxes",
  paymentdata_consent: "Please check the Terms & Conditions Consent checkboxes",
  newtabmessage: "Login to continue!",
  paymentSummary_amtLessThanFullBlc: "Payment amount should be less than full balance amount",
  err_echeque_phnumber: "Phone Number is required",
  err_echeque_errcontactnum: "Please enter 10 digits Contact Number",
  err_echeque_errmincontactnum: "Please enter 10 digits Phone Number",
  err_echeque_errmaxcontactnum: "Please enter 10 digits Phone Number",
  err_echeque_erremailaddmaching: "Please enter a valid Email Address",
  echeque_minnameOnCard: "Please enter at least 1 Characters",
  echeque_maxnameOnCard: "Max 20 characters allowed",
  address_maxcharacters: "Max 255 characters allowed",
  payment_initited_csa: "Payment initiated by PA :",
  payment_done_csa: "Payment done by PA :",

  transactionReport_title: "Reports: ",
  transactionReport_title1: " Transaction Search",
  transactionReport_title2: " Payment History",
  viewbills_title2: "Account Details",
  transactionReport_selectPeriod: "Select Period",
  transactionReport_toDays: "Today",
  transactionReport_Last30Days: "Last 30 Days",
  transactionReport_Last60Days: "Last 60 Days",
  transactionReport_Last90Days: "Last 90 Days",
  transactionReport_Custom: "Custom",
  transactionReport_StartDate: "Start Date",
  transactionReport_EndDate: "End Date",
  transactionReport_AdvanceSearch: "Advance Search",
  transactionReport_PaymentMethod: "Payment Method",
  transactionReport_PaymentStatus: "Payment Status",
  transactionReport_TransactionID: "Transaction ID",
  transactionReport_AuthorizationCode: "Authorization Code",
  transactionReport_ClientName: "Client Name",
  transactionReport_SeedAccountNumber: "Seed Account Number",
  transactionReport_AccountNumber: " MED-1 Account Number",
  transactionReport_Amount: "Transaction Amount",
  transactionReport_GENERATEREPORT: "GENERATE REPORT",
  transactionReport_RESET: "RESET",
  transactionReport_EXPORTTOEXCEL: "EXPORT TO EXCEL",
  patientDetail_PatientSearch: "Patient Search",
  "patientDetail_MED-1AccountNumber": "MED-1 Account Number",
  patientDetail_SocialSecurityNumber: "Social Security Number",
  patientDetail_DateofBirth: "Date of Birth",
  patientDetail_FirstName: "First Name",
  patientDetail_LastName: "Last Name",
  patientDetail_SEARCH: "SEARCH",
  patientDetail_RESET: "RESET",
  patientDetail_EXPORTTOEXCEL: "EXPORT TO EXCEL",

  patientDetail_Pleasefillatleastoneofthefields: "Please fill atleast one of the fields",
  ViewBill_search: "Enter Med1 Account no",
  patientDetail_Somethingwentswrong: "Something wents wrong!",
  patientDetail_Oops: "Oops!",
  patientDetail_placeholderMED1accnumber: "MED-1 Account Number",
  patientDetail_placeholderssn: "Social Security Number",
  patientDetail_placeholderssn2: "Enter Social Security Number",
  patientDetail_placeholderFirstName: "First Name",
  patientDetail_placeholderLastName: "Last Name",
  patientDetail_placeholderTransactionID: "Transaction ID",
  patientDetail_placeholderAuthorizationCode: "Authorization Code",
  patientDetail_placeholderClientName: "Client Name",
  patientDetail_placeholderSeedAccountNumber: "Seed Account Number",
  patientDetail_placeholderAccountNumber: "Account Number",
  changePassword_CSAFirstTimeLogin: "CSA First Time Login",
  changePassword_OldPassword: "Old Password",
  changePassword_NewPassword: "New Password",
  changePassword_ConfirmPassword: "Confirm New Password",
  changePassword_PasswordPolicy: "Password Policy",
  changePassword_policy1: "Min 8 chars to Max 20 chars",
  changePassword_SecurityAuthorization: "Security Authorization",
  changePassword_SelectImage: "Select Image",
  changePassword_SelectedImage: "Selected Image:",
  changePassword_SelectedImageFeature:
    "The security image feature is an additional layer of identity verification for signing in to MED-1 Payment Portal.",
  changePassword_SelectedImageFeature3:
    "It protects you against identity theft and fraud. When you see your security image you can be certain you're at the valid MED-1 Payment Portal site and not a fraudulent look-alike site.",
  changePassword_copyrightFooter: "Copyright 2020. All Rights Reserved.",
  changePassword_Cancelbutton: "Cancel",
  changePassword_Continue: "Continue",
  changePassword_passwordChangeSuccessMsg:
    "Your password & security image have been updated successfully. You will be redirected to login page to login again with changed password.",
  changePassword_Somethingwentswrong: "Something wents wrong!",
  changePassword_CHangePasswordLogin: "Change Password?",
  transactionReport_BillAmount: "Bill Amount",
  transactionReport_placeholderBillAmount: "Bill Amount",
  transactionReport_PaymentAmount: "Paid Amount",
  transactionReport_PaymentAmoun1t: "Transaction Amount",
  transactionReport_placeholderPaymentAmount: "Paid Amount",
  transactionReport_placeholderPaymentAmount1: "Transaction Amount",
  report_no_records: "No transaction records found!",
  login_tiltleLogin: "Login",
  login_Username: "Username",
  login_ForgotUsername: "Forgot Username?",
  login_SecurityImage: "Security Image",
  login_registered: " Is this the security image that you selected when you registered?",
  login_Password: "Password",
  login_ForgotPassword: "Forgot Password?",
  login_LOGIN: "LOGIN",
  login_CONTINUE: "CONTINUE",
  forgetPassword_title: "Forgot Password?",
  forgetUser_title: "Forgot Username?",
  forgotMedAccount: "Forgot MED-1 Account Number?",
  forgetPassword_title2: "Enter the username associated with your account.",
  forgetPassword_EmailID: "Username",
  forgetPassword_RecoverPassword: "Recover Password",
  forgetPassword_BackToLogin: "Back To Login",
  forgotAccount_BackToQuickpay: "Back To Quick Pay",
  forgetPassword_title3: "You will receive an email with a link to reset your password.",
  resetPassword_ResetPassword: "Reset Password?",
  resetPassword_NewPassword: "New Password",
  resetPassword_PasswordPolicy: "Password Policy",
  resetPassword_ConfirmNewPassword: "Confirm New Password",
  resetPassword_EnterEmailID: "Enter Username",
  resetPassword_OldPassword: "Old Password",
  User_blocked:
    "Your account has exceeded the maximum login attempts and is blocked. Please contact system Administrator to unblock your account.",
  Clear_previous_session: "Please login again!",
  Session_closed_Unexpectedly: "Last session closed Unexpectedly. Clearing Previous session..! ",
  Enter_valid_data:
    "Please enter valid username and password! Your account will be blocked after 10 consecutive wrong attempts",
  No_permission: "You do not have permission to access this page",
  session_expired: "Your session has expired. Please login again...!",
  get_email: "Email Address",
  getEmail_placeholder: "Enter Email Address",
  get_contact: "Phone Number",
  getcontact_placeholder: "Enter Phone Number",
  getDetail_patientDetails_Title: "For security purposes, please verify your account with one of the following",
  err_verify_phnumber: "Phone Number is required",
  err_verify_emailid: "Email Address is required",
  getDetail_errLastFourDigitSSN: "Last 4 digits of Social Security Number is required",
  getDetail_errLastFourDigitDOB: "Patient’s Date of Birth is required",
  getpatientDetail_placeholderSSN: "Enter last 4 digits of SSN",
  wrongDetail_warningMsgWrong: "Please enter valid details!",
  paymentSummary_warningpartialApportionedPayment:
    "Minimum payment should be at least $50.00 or pay the full payment amount amongst listed accounts appropriately to close accounts with balance less than $50 ",
  paymentminimumAmountMessage: "No Outstanding balance to pay",
  Register_label: "Registration",
  User_Firstname: "First Name",
  User_Firstname_Placeholder: "Enter First Name",
  User_Middlename: "MIddle Name",
  User_Middlename_Placeholder: "Enter MIddle Name",
  User_Lastname: "Last Name",
  User_Lastname_Placeholder: "Enter Last Name",
  getDetail_errmsgSSN2: "Enter last 4 digits of SSN",
  patient_Username: "Username",
  patient_password: "Password",
  patient_Confirm_Password: "Confirm Password",
  useremailid: "Email Address",
  useremailid_placeholder: "Enter Email Address",
  Usermobile: "Phone Number",
  Usermobile_placeholder: "Enter Phone Number",
  patient_Address: "Street Address",
  patient_Address1: "Street Address",
  patient_Address2: "",
  firstname_required: "First name is required",
  middlename_required: "Middle name is required",
  lastname_required: "Last name is required",
  ssn_required: "Social Security Number is required",
  dob_required: "Patient's Date of Birth is required",
  useremail_required: "Email Address is required",
  cantactno_required: "Phone Number is required",
  country_required: "Country is required",
  state_required: "State is required",
  city_required: "City is required",
  address_required: "Street Address is required",
  zip_required: "Enter 5 or 9 digit ZIP Code",
  cnf_passwordrequired: "Confirm Password is required",
  register_success: "Registered Successfully!",
  getDetail_verifyButton: "VERIFY",
  Bill_details_header: " Account Details",
  transaction_history_header: "Payment History ",
  transaction_history_header2: "(payments made on this portal in year 2021)",
  secure_login_tiltleLogin: "Secure Login",
  Dashboard_Label: "Dashboard",
  profile_update: "Profile updated successfully",
  profile_update_error: "Technical issue, Try again later!",
  mobileuseconsent_placeholder: "Authorize us to contact you by cell phone?",
  emailuseconsent_placeholder: "Authorize us to contact you by email?",
  verifyemailid_placeholder: "Verify Email Address",
  verifymobile_placeholder: "Verify Phone Number",
  img_required: "Select Security Image",
  Profile_label: "My Profile",
  Registration_email_communication: "Uncheck to avoid receiving email communications.",
};
