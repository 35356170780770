import React from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
    backgroundColor: "#343a40",
    color: "#fff",
  },
  paperWidth: {
    padding: theme.spacing(1),
    backgroundColor: "#343a40",
    color: "#fff",
    width: "200px",
  },
  typoFont: {
    fontSize: "16px",
    color: "#202020",
  },
}));

export default function MouseOverPopover({ data, popOverData, codeLength, contentLength = 9 }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="custom-comments">
      {codeLength ? (
        <Typography
          className={classes.typoFont}
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}>
          {data?.substring(0, contentLength)} {data?.length >= contentLength ? "..." : ""}
        </Typography>
      ) : (
        <Typography
          className={classes.typoFont}
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}>
          {data?.substring(0, 30)} {data?.length >= 30 ? "..." : ""}
        </Typography>
      )}

      <Popover
        id="mouse-over-popover"
        placement="left"
        className={classes.popover}
        classes={{
          paper: popOverData?.length <= 30 ? classes.paper : classes.paperWidth,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "top",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <Typography variant="body1">
          <div>{popOverData}</div>
        </Typography>
      </Popover>
    </div>
  );
}
