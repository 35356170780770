import React, { useState, useEffect } from "react";
import Med1Logo from "../../../assets/images/Med1Logo.png";
import Captcha from "../../../components/Captcha";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getIn } from "formik";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Grid, IconButton } from "@material-ui/core";
import { useHistory } from "react-router";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import RocketIcon from "../../../assets/images/quick-pay-rocket-icon.svg";
import { Link } from "react-router-dom";

const intialvalues = {
  username: "robert@med1solutions.com",
  password: "Test#34234H",
};

const schema = Yup.object().shape({
  username: Yup.string(),
  password: Yup.string(),
});

const PALogin = ({ match }) => {
  const history = useHistory();

  const onSubmit = async (values, onSubmitProps) => {
    localStorage.setItem("role", "PA");
    history.push("/pa/patientDetails");

  };

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="page-wrapper py-5 vertical-height" role="main">
      <div className="container-fluid py-2">
        <div className="row justify-content-center">
          <div className="card-sm">
            <div className="header" role="heading" aria-level="1">
              <div className="rocket-icon">
                <img src={RocketIcon} alt="" />
              </div>
              <div className="logo">
                <img src={Med1Logo} className="img-fluid" alt="Med-1 Solutions Logo" />
              </div>
            </div>
            <div className="login-content">
              <Formik initialValues={intialvalues} onSubmit={onSubmit} validationSchema={schema}>
                {(props) => {
                    // eslint-disable-next-line no-unused-vars
                  const { values, touched, error, handleChange, errors, setFieldValue } = props;
                  return (
                    <Form id="landing-page" className="input-field mt-4" noValidate="noValidate">
                      <div className="input-field">
                        <div className="form-group">
                          <div className="row m-0 justify-content-between">
                            <label htmlFor="exampleInputEmail1">Username{"  "}*</label>

                          </div>
                          <div className="med1-input ">
                            <OutlinedInput
                              className="form-control signup-fields"
                              name="username"
                              placeholder="Please Enter Your Username"
                              onChange={handleChange}
                              value={values["username"]}
                              aria-label="text-field"
                              fullWidth
                              error={Boolean(getIn(touched, "username") && getIn(errors, "username"))}
                              type="text"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              startAdornment={
                                <InputAdornment position="end">
                                  <PermIdentityIcon />
                                </InputAdornment>
                              }
                              inputProps={{
                                maxLength: 30,
                              }}></OutlinedInput>
                          </div>
                        </div>
                      </div>
                      <div className="input-field mt-4">
                        <div className="form-group">
                          <div className="row m-0 justify-content-between">
                            <label htmlFor="exampleInputEmail1">Password{"  "}*</label>
                          </div>
                          <div className="med1-input ">
                            <OutlinedInput
                              className="form-control signup-fields"
                              name="password"
                              placeholder="Please Enter Your Password"
                              onChange={handleChange}
                              value={values["password"]}
                              aria-label="text-field"
                              fullWidth
                              error={Boolean(getIn(touched, "password") && getIn(errors, "password"))}
                              type={showPassword ? "text" : "password"}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              startAdornment={
                                <InputAdornment position="end">
                                  <LockOutlinedIcon />
                                </InputAdornment>
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleShowPassword}
                                    onMouseDown={handleMouseDownPassword}>
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              required="true"
                              inputProps={{
                                maxLength: 8,
                              }}></OutlinedInput>
                          </div>
                        </div>
                      </div>
                      <div className="my-5">
                        <button type="submit" className="primary-button btn-block">
                          Continue
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
            <div className="text-center">
              <div className="forgot-password">
                <p>
                  Forgot Password?
                  <Link to="/forgot-password" aria-label="forgot password">
                    Click Me
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-right">
          <p>
            Need Help?{" "}
            <Link to="/contact-us" aria-label="contact-us-link">
              Contact Us
            </Link>
          </p>
          <p>Copyright 2020. All Rights Reserved.</p>
          <p>
            WE ARE DEBT COLLECTORS. THIS IS AN ATTEMPT TO COLLECT A DEBT. ANY INFORMATION OBTAINED WILL BE USED FOR THAT
            PURPOSE.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PALogin;
