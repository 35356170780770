import { ReportsService } from "../services/ReportsService";

export const GET_ACCOUNTING_REPORT = "GET_ACCOUNTING_REPORT";
export const GET_RECONCILIATION_ORDER = "GET_RECONCILIATION_ORDER";
export const GET_PAYMENT_PLAN_REPORT = "GET_PAYMENT_PLAN_REPORT";
export const GET_SCHEDULED_TRANSACTION = "GET_SCHEDULED_TRANSACTION";
export const GET_SCHEDULED_FUTURE_PAYMENT = "GET_SCHEDULED_FUTURE_PAYMENT";
export const GET_COMMUNICATION_BY = "GET_COMMUNICATION_BY";
export const GET_COMMUNICATION_TYPE = "GET_COMMUNICATION_TYPE";

export function getAccountingReportsList(Data, fn) {
  return (dispatch) => {
    dispatch({
      type: GET_ACCOUNTING_REPORT,
      payload: Data,
    });
    ReportsService.getAccountingReports(Data, (res) => fn(res));
  };
}

export function getReconciliationOrderReport(Data, fn) {
  return (dispatch) => {
    dispatch({
      type: GET_RECONCILIATION_ORDER,
      payload: Data,
    });
    ReportsService.getReconciliationOrder(Data, (res) => fn(res));
  };
}

export function getPaymentPlansReportList(Data, fn) {
  return (dispatch) => {
    dispatch({
      type: GET_PAYMENT_PLAN_REPORT,
      payload: Data,
    });
    ReportsService.getPaymentPlansReport(Data, (res) => fn(res));
  };
}

export function getPaymentPlansDetailsData(Data, fn) {
  return (dispatch) => {
    dispatch({
      type: GET_PAYMENT_PLAN_REPORT,
      payload: Data,
    });
    ReportsService.getPaymentPlansDetails(Data, (res) => fn(res));
  };
}

export function getScheduledTransactionList(Data, fn) {
  return (dispatch) => {
    dispatch({
      type: GET_SCHEDULED_TRANSACTION,
      payload: Data,
    });
    ReportsService.getScheduledTransaction(Data, (res) => fn(res));
  };
}
export function getScheduledFuturepaymentList(Data, fn) {
  return (dispatch) => {
    dispatch({
      type: GET_SCHEDULED_FUTURE_PAYMENT,
      payload: Data,
    });
    ReportsService.getScheduledFuturePayment(Data, (res) => fn(res));
  };
}
export function getCommunicationByReportList(Data, fn) {
  return (dispatch) => {
    dispatch({
      type: GET_COMMUNICATION_BY,
      payload: Data,
    });
    ReportsService.getCommunicationBy(Data, (res) => fn(res));
  };
}
export function getCommunicationTypeReportList(Data, fn) {
  return (dispatch) => {
    dispatch({
      type: GET_COMMUNICATION_TYPE,
      payload: Data,
    });
    ReportsService.getCommunicationType(Data, (res) => fn(res));
  };
}
