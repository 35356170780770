import { Global_var } from "../global/global_var";
import RestDataSource from "./restdatasource";
import { loginService } from "./loginService";

export const whiteLableService = {
  getWhiteLable,
  getContentData,
};

function getWhiteLable(whiteLabelData, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_WHITE_LABEL;

      return new RestDataSource(url, null, "CORE", fn).Store(whiteLabelData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  }, fnError);
}

function getContentData(getContentData, fn, fnError) {
  // loginService.TokenUser((res) => {
  //   if (res.status === "success") {
  //     sessionStorage.setItem("jwt-tokencore", res.responseObject);
  var url = Global_var.BASEURL + Global_var.URL_GET_CONTENT;

  return new RestDataSource(url, null, "CORE", fn).Store(getContentData, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        sessionStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
  //   }
  // }, fnError);
}
