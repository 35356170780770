import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  getWhiteLableData,
  getWhiteLableDataPending,
  getWhiteLableDataError,
  getContentData,
  getContentDataPending,
  getContentDataError,
} from "../../reducer/whiteLableReducer";
import { fetchWhiteLabel, fetchGetContent } from "../../action/whiteLableAction";
import WhiteLable from "../../Common/whiteLabel/whiteLable";
// import WhiteLable from "../../components/commonComponents/whiteLabel/whiteLable";
// //sending hard code image until API implementation

const mapStateToProps = (state) => ({
  getWhiteLableData: getWhiteLableData(state.whiteLableReducer),
  getWhiteLableDataPending: getWhiteLableDataPending(state.whiteLableReducer),
  getWhiteLableDataError: getWhiteLableDataError(state.whiteLableReducer),

  getContentData: getContentData(state.whiteLableReducer),
  getContentDataPending: getContentDataPending(state.whiteLableReducer),
  getContentDataError: getContentDataError(state.whiteLableReducer),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchWhiteLabel: fetchWhiteLabel,
      fetchGetContent: fetchGetContent,
    },
    dispatch,
  );

class whiteLableContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <WhiteLable {...this.props}></WhiteLable>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(whiteLableContainer);
