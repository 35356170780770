import React, { Component } from "react";
import { getImage } from "../../action/securityImageAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getSecurityImage } from "../../reducer/securityImageReducer";
import SecurityImage from "../../../src/routes/Pages/SecurityImage/SecurityImage";

const mapStateToProps = (state) => ({
  ...getSecurityImage(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getImage: getImage,
    },
    dispatch,
  );

class securityImageContainer extends Component {
  render() {
    return (
      <React.Fragment>
        <SecurityImage actualImage={(this.props.securityImage.securityImage || "").documentHash}></SecurityImage>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(securityImageContainer);
