const visapattern = /^4\d{3}(| |-)(?:\d{4}\1){2}\d{4}$/;
const masterpattern = /^5[1-5]\d{2}(| |-)(?:\d{4}\1){2}\d{4}$/;
const amexpattern = /^3[47]\d{1,2}(| |-)\d{6}\1\d{6}$/;

export const validateCard = (name, value, setter) => {
  let cardtype = "";

  if (visapattern.exec(value.replace(/-/g, ""))) {
    cardtype = "visa";
  } else if (masterpattern.exec(value.replace(/-/g, ""))) {
    cardtype = "mastercard";
  } else if (amexpattern.exec(value.replace(/-/g, ""))) {
    cardtype = "amex";
  } else {
    cardtype = "";
  }
  setter(cardtype);
};
