import React, { createContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";

export const ThemeContext = createContext();

const useStyles = makeStyles((theme) => ({
  lightTheme: {
    backgroundColor: "blue",
    color: "white",
  },
  darkTheme: {
    backgroundColor: "black",
    color: "white",
  },
  yellowTheme: {
    backgroundColor: "yellow",
    color: "white",
  },
  greenTheme: {
    backgroundColor: "green",
    color: "white",
  },
  redTheme: {
    backgroundColor: "red",
    color: "white",
  },
}));

const ThemeProvider = ({ children }) => {
  const classes = useStyles();
  const [themeMode, setThemeMode] = useState("lightTheme"); //Setting default as light theme
  const [sidebarTheme, setSidebarTheme] = useState("blackSideBarTheme");
  const [showWidgetSection, setShowWidgetSection] = useState(false);
  const [labelColor, setLabelColor] = useState("#222222");
  const [radiobtnColor, setRadiobtnColor] = useState("#320469");
  useEffect(() => {
    // Setting the default as light theme
    document.documentElement.classList.add(`theme-lightTheme`);
    document.documentElement.classList.add(`sidebar-linearBlueSideBarTheme`);
  }, []);
  useEffect(() => {
    if (sidebarTheme === "blackSideBarTheme") {
      setLabelColor("#222222");
    } else if (sidebarTheme === "lightBlueSideBarTheme") {
      setLabelColor("#03dac5");
    } else if (sidebarTheme === "darkBlueSideBarTheme") {
      setLabelColor("#23036a");
    } else if (sidebarTheme === "blueSideBarTheme") {
      setLabelColor("#6200EE");
    } else if (sidebarTheme === "redSideBarTheme") {
      setLabelColor("#B94343");
    } else if (sidebarTheme === "greenSideBarTheme") {
      setLabelColor("#2BA444");
    } else if (sidebarTheme === "skyBlueSideBarTheme") {
      setLabelColor("#369FFF");
    }
  }, [sidebarTheme]);
  useEffect(() => {
    if (themeMode === "lightTheme") {
      setRadiobtnColor("#320469");
    } else if (themeMode === "redTheme") {
      setRadiobtnColor("#274edd");
    } else if (themeMode === "greenTheme") {
      setRadiobtnColor("#493d5d");
    } else if (themeMode === "darkenBlueTheme") {
      setRadiobtnColor("#1e2a78");
    } else if (themeMode === "blueGreyTheme") {
      setRadiobtnColor("#040353");
    } else if (themeMode === "darkTheme") {
      setRadiobtnColor("#191548");
    } else if (themeMode === "skyBlueSideBarTheme") {
      setRadiobtnColor("#369FFF");
    }
  }, [themeMode]);
  // Handling Themes in the application
  const toggleTheme = (theme) => {
    let classNames = document.documentElement.classList;

    let prevRadiusClass = Object.values(classNames).filter((ele) => ele.split("-")[0] === "theme");
    prevRadiusClass.length > 0 && document.documentElement.classList.remove(prevRadiusClass[0]);
    document.documentElement.classList.add(`theme-${theme}`);
    setThemeMode(theme);
  };
  // Handling Border Radius in the application
  const toggleBorderRadius = (radius) => {
    let classNames = document.documentElement.classList;
    let prevRadiusClass = Object.values(classNames).filter((ele) => ele.split("-")[0] === "radius");
    prevRadiusClass.length > 0 && document.documentElement.classList.remove(prevRadiusClass[0]);
    document.documentElement.classList.add(`radius-${radius}`);
  };
  // Handling SideBar Color Theme
  const toggleSideBarTheme = (theme) => {
    let classNames = document.documentElement.classList;
    let prevSideBarClass = Object.values(classNames).filter((ele) => ele.split("-")[0] === "sidebar");
    prevSideBarClass.length > 0 && document.documentElement.classList.remove(prevSideBarClass[0]);
    document.documentElement.classList.add(`sidebar-${theme}`);
    setSidebarTheme(theme);
  };

  const toggleWidgetSection = () => {
    setShowWidgetSection(!showWidgetSection);
  };
  const ReusableInputStyles = makeStyles((theme) => ({
    checkedIcon: {
      backgroundColor: `${radiobtnColor} !important`,
      backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: 20,
        height: 20,
        backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
        content: '""',
      },
      "input:hover ~ &": {
        backgroundColor: "#644ad2",
      },
    },
    cardStyling: {
      whiteSpace: "nowrap",
      padding: "10px",
      borderRadius: 8,
      backgroundColor: "#ededed",
      marginBottom: 10,
      boxShadow: "0px 1px 0px rgb(0 0 0 / 10%), 0px 0px 1px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 10%)",
      "& .MuiTypography-h3": {
        padding: "3px 0 12px 0;",
      },
    },
    cardStyleWhite: {
      whiteSpace: "nowrap",
      padding: "10px",
      borderRadius: 8,
      backgroundColor: "#fff",
      marginBottom: 10,
      boxShadow: "0px 1px 0px rgb(0 0 0 / 10%), 0px 0px 1px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 10%)",
    },
    cardStyles: {
      whiteSpace: "nowrap",
      padding: "15px",
      borderRadius: 8,
      marginBottom: 10,
      backgroundColor: "#ededed",
      border: "2px solid #3e3e3e",
    },
    cardBordernoneStyles: {
      whiteSpace: "nowrap",
      padding: "15px",
      borderRadius: "0px 0px 8px 8px",
      backgroundColor: "#ffffff",
      marginBottom: 10,
      boxShadow: "0px 1px 0px rgb(0 0 0 / 10%), 0px 0px 1px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 10%)",
    },
    tabCardStyles: {
      whiteSpace: "nowrap",
      padding: "15px",
      borderRadius: 8,
      backgroundColor: "#ededed",
      marginBottom: 10,
      boxShadow: "0px 1px 0px rgb(0 0 0 / 10%), 0px 0px 1px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 10%)",
    },
    dialoguecardStyles: {
      padding: "0px 15px",
    },
    popper: {
      zIndex: "9 !important",
    },
    dropDown: {
      zIndex: "11 !important",
    },
    labelName: {
      "& .MuiOutlinedInput-input": {
        color: `${labelColor} !important`,
        opacity: "1 !important",
        fontFamily: "poppins",
        fontWeight: 500,
        [theme.breakpoints.up(1920)]: {
          // margin: "20px 0 0",
        },
      },
      "& fieldset": {
        top: "0px !important",
        "& legend": {
          display: "none !important",
        },
      },
    },
    inputLabel: {
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        fontWeight: "500 !important",
        fontSize: "0.938rem",
        color: "#fff !important",
        width: "100%",
        padding: "3px 14px !important",
        backgroundColor: "#696969 !important",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
        transform: "translate(0, 0) scale(1) !important",
        [theme.breakpoints.down("sm")]: {
          fontSize: "0.813rem !important",
          padding: "4px 14px! important",
        },
        [theme.breakpoints.up(1920)]: {
          fontSize: "1rem !important",
          padding: "6px 14px! important",
        },
      },
      "& .MuiOutlinedInput-inputMarginDense": {
        paddingTop: "10.5px !important",
        paddingBottom: "5.5px !important",
        [theme.breakpoints.up(1920)]: {
          paddingTop: "13.5px !important",
          paddingBottom: "6.5px !important",
          fontSize: "1rem !important",
        },
      },
    },
    AccountMessagesLabel: {
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        fontWeight: "500 !important",
        fontSize: "0.938rem",
        color: "#fff !important",
        width: "100%",
        padding: "3px 14px",
        backgroundColor: "#696969 !important",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
        transform: "translate(0, 0) scale(1) !important",
        [theme.breakpoints.down("sm")]: {
          fontSize: "0.813rem !important",
          padding: "4px 14px! important",
        },
        [theme.breakpoints.up(1920)]: {
          fontSize: "1rem !important",
          padding: "6px 14px! important",
        },
      },
      "& .MuiOutlinedInput-inputMarginDense": {
        paddingTop: "10.5px !important",
        paddingBottom: "5.5px !important",
        [theme.breakpoints.up(1920)]: {
          paddingTop: "13.5px !important",
          paddingBottom: "6.5px !important",
          fontSize: "1rem !important",
        },
      },
      "& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense": {
        paddingTop: "3.5px",
        paddingBottom: "10.5px",
        paddingLeft: "13px",
      },
    },
    textField: {
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        fontWeight: "500 !important",
        fontSize: "0.938rem",
        color: "#fff !important",
        width: "100%",
        padding: "3px 14px !important",
        backgroundColor: "#696969 !important",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
        transform: "translate(0, 0) scale(1) !important",
        [theme.breakpoints.down("sm")]: {
          fontSize: "0.813rem !important",
          padding: "3px 8px! important",
        },
        [theme.breakpoints.up(1920)]: {
          fontSize: "1rem !important",
          padding: "6px 14px! important",
        },
      },
      "& .MuiOutlinedInput-adornedEnd": {
        paddingRight: "40px !important",
      },
    },
    selectGender: {
      fontSize: "13px",
    },
    selectLabel: {
      "& .MuiAutocomplete-endAdornment": {
        top: "20px",
        [theme.breakpoints.up(1920)]: {
          top: "28px",
        },
        [theme.breakpoints.down("md")]: {
          top: "18px",
        },
      },
      "& .MuiSelect-iconOutlined": {
        top: "25px !important",
        [theme.breakpoints.up(1920)]: {
          top: "30px",
        },
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        fontWeight: "500 !important",
        fontSize: "0.938rem",
        color: "#fff !important",
        width: "100%",
        padding: "3px 14px !important",
        backgroundColor: "#696969 !important",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
        transform: "translate(0, 0) scale(1) !important",
        [theme.breakpoints.down("sm")]: {
          fontSize: "0.813rem !important",
          padding: "3px 8px! important",
        },
        [theme.breakpoints.up(1920)]: {
          fontSize: "1rem !important",
          padding: "6px 14px! important",
        },
      },
      "& .MuiOutlinedInput-inputMarginDense": {
        paddingTop: "10.5px !important",
        paddingBottom: "5.5px !important",
        paddingRight: "50px !important",
        [theme.breakpoints.up(1920)]: {
          paddingTop: "13.5px !important",
          paddingBottom: "6.5px !important",
          fontSize: "1rem !important",
        },
      },
    },
    // custom windows
    selectBox: {
      "& .MuiAutocomplete-endAdornment": {
        top: "10px !important",
      },
    },
    // custom windows
    selectdropdown: {
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        margin: "15px 0 0 ",
        [theme.breakpoints.up(1920)]: {
          margin: "20px 0 0",
        },
      },

      "& .MuiSelect-iconOutlined": {
        top: "25px !important",
        [theme.breakpoints.down("sm")]: {
          top: "21px !important",
        },
        [theme.breakpoints.up(1920)]: {
          top: "30px !important",
        },
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        fontWeight: "500 !important",
        fontSize: "0.938rem",
        color: "#fff !important",
        width: "100%",
        padding: "3px 14px !important",
        backgroundColor: "#696969 !important",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
        transform: "translate(0, 0) scale(1) !important",
        [theme.breakpoints.down("sm")]: {
          fontSize: "0.813rem !important",
          padding: "3px 8px! important",
        },
        [theme.breakpoints.up(1920)]: {
          fontSize: "1rem !important",
          padding: "6px 14px! important",
        },
      },
      "& .MuiOutlinedInput-inputMarginDense": {
        paddingTop: "10.5px !important",
        paddingBottom: "5.5px !important",
        paddingRight: "25px !important",
        [theme.breakpoints.up(1920)]: {
          paddingTop: "13.5px !important",
          paddingBottom: "6.5px !important",
          fontSize: "1rem !important",
        },
      },
    },

    //Recordformat-Label-fontsize//
    recordformatLabel: {
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        [theme.breakpoints.down("md")]: {
          fontSize: "0.68rem !important",
        },
      },
    },
    //Recordformat-Label-fontsize//

    //run a report
    selectrunadropdown: {
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        margin: "13px 0 0 ",
        [theme.breakpoints.up(1920)]: {
          margin: "20px 0 0",
        },
      },
      "& .MuiSelect-iconOutlined": {
        top: "25px !important",
        [theme.breakpoints.down("sm")]: {
          top: "21px !important",
        },
        [theme.breakpoints.up(1920)]: {
          top: "28px !important",
        },
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        fontWeight: "500 !important",
        fontSize: "0.8rem !important",
        color: "#fff !important",
        width: "100%",
        padding: "3px 14px !important",
        backgroundColor: "#696969 !important",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
        transform: "translate(0, 0) scale(1) !important",

        [theme.breakpoints.down("md")]: {
          fontSize: "0.75rem !important",
        },
        [theme.breakpoints.up(1920)]: {
          fontSize: "1rem !important",
          padding: "6px 14px !important",
        },
      },
      "& .MuiOutlinedInput-inputMarginDense": {
        paddingTop: "10.5px !important",
        paddingBottom: "5.5px !important",
        paddingRight: "36px !important",
        [theme.breakpoints.up(1920)]: {
          paddingTop: "13.5px !important",
          paddingBottom: "6.5px !important",
          fontSize: "1rem !important",
        },
      },
    },
    //run a report rulegroup
    selectruledropdown: {
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        margin: "13px 0 0 ",
        [theme.breakpoints.up(1920)]: {
          margin: "18px 0 0",
        },
      },
      "& .MuiSelect-iconOutlined": {
        top: "25px !important",
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        fontWeight: "500 !important",
        fontSize: "0.8rem !important",
        color: "#fff !important",
        width: "100%",
        padding: "3px 14px !important",
        backgroundColor: "#696969 !important",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
        transform: "translate(0, 0) scale(1) !important",

        [theme.breakpoints.down("md")]: {
          fontSize: "0.75rem !important",
        },
        [theme.breakpoints.up(1920)]: {
          fontSize: "1rem !important",
          padding: "6px 14px! important",
        },
      },
      "& .MuiOutlinedInput-inputMarginDense": {
        paddingTop: "10.5px !important",
        paddingBottom: "5.5px !important",
        paddingRight: "10px !important",
        [theme.breakpoints.up(1920)]: {
          paddingTop: "13.5px !important",
          paddingBottom: "6.5px !important",
          fontSize: "1rem !important",
        },
      },
    },
    datePicker: {
      "& .MuiIconButton-root": {
        padding: "0 !important",
        margin: "22px 0 0 !important",
        [theme.breakpoints.up(1920)]: {
          margin: "28px 0 0 !important",
        },
        " & .MuiSvgIcon-root": {
          fontSize: "1.3rem",
        },
        [theme.breakpoints.up("lg")]: {
          marginBottom: "2px !important",
        },
      },

      "& .MuiOutlinedInput-input": {
        fontSize: "16px",
      },
      "& .MuiOutlinedInput-adornedEnd": {
        paddingRight: "6px",
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        fontWeight: "500 !important",
        fontSize: "0.938rem",
        color: "#fff !important",
        width: "100%",
        padding: "3px 15px",
        backgroundColor: "#696969 !important",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
        transform: "translate(0, 0) scale(1) !important",
        [theme.breakpoints.up(1920)]: {
          fontSize: "1rem !important",
          padding: "6px 14px! important",
        },
      },
      "& .MuiOutlinedInput-inputMarginDense": {
        paddingTop: "10.5px !important",
        paddingBottom: "5.5px !important",
        [theme.breakpoints.up(1920)]: {
          paddingTop: "13.5px !important",
          paddingBottom: "6.5px !important",
          fontSize: "1rem !important",
        },
      },
      "& .MuiFormHelperText-root": {
        top: "55px !important",
      },
    },
    listBox: {
      overflowY: "auto !important",
      overflowX: "hidden !important",
    },
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
    toolTip: {
      zIndex: "10 !important",
      "& .MuiTooltip-tooltipPlacementBottom": {
        margin: "5px !important",
      },
    },
    buttonRoot: {
      lineHeight: 1.75,
      [theme.breakpoints.down("md")]: {
        lineHeight: 1.5,
        padding: "6px 15px !important",
      },
      "& .MuiButton-label": {
        fontSize: "0.813rem",
        [theme.breakpoints.down("md")]: {
          fontSize: "0.688rem !important",
          letterSpacing: 1,
        },
        "& .MuiSvgIcon-root": {
          [theme.breakpoints.down("md")]: {
            fontSize: "1.1rem !important",
          },
        },
      },
    },
    option: {
      padding: "6px 16px",
      fontSize: "16px",
      [theme.breakpoints.down("md")]: {
        padding: "6px 10px",
        fontSize: "12px",
      },
    },
    dashboard: {
      padding: 0,
      marginBottom: 15,
      color: "#ffffff",
    },
    placementDate: {
      [theme.breakpoints.up("md")]: {
        "& .MuiOutlinedInput-input": {
          padding: "10.5px 5px",
        },
      },
    },
    globalButtons: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "10px",
    },

    cardHeight: {
      minHeight: "480px",
    },
    systemNotesWrapper: {
      "& > .MuiPaper-root > .MuiCollapse-root > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner > .MuiAccordion-region > .MuiAccordionDetails-root": {
        display: "unset",
      },
    },
    mapRuleGroup: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },

    globalPopup: {
      margin: "0 10px",
    },

    heading: {
      margin: "10px 15px",
    },
    searchText: {
      color: "#000000",
      fontSize: "16px",
      fontWeight: "900",
      marginBottom: "0",
    },
    firstCardDiv: {
      paddingRight: 0,
      paddingLeft: 0,
    },
    secondCardDiv: {
      paddingRight: 0,
      paddingLeft: 0,
    },
    letterControlButtons: {
      justifyContent: "center",
      margin: "10px 0 15px",
    },
    htmlRadio: {
      "& .Mui-checked": {
        color: "#6200EE !important",
      },
      "& .MuiIconButton-colorSecondary": {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
    formRoot: {
      "& .MuiFormControl-root ": {
        "&.MuiFormControlLabel-root": {
          marginBottom: "0px !important",
          marginRight: "0px !important",
        },
      },
    },

    globalField: {
      padding: "6px 8px",
      color: "#ffffff",
      marginBottom: "10px",
    },
    disabling: {
      opacity: "0.5 !important",
    },
    legend: {
      backgroundColor: "#ededed",
      position: "absolute",
      top: -10,
      left: 15,
      color: "#222",
      padding: "2px 4px",
      fontWeight: 700,
      width: "auto",
      fontSize: "14px",
      margin: "2px 0 10px",
      display: "inline-block",
      whiteSpace: "nowrap",
    },
    radioGroup: {
      fontWeight: "bold",
      fontSize: "16px !important",
      "& .MuiFormControlLabel-root": {
        marginRight: "4px !important",
      },
    },
    DragAndDropWrapper: {
      "& .dropzone": {
        flexDirection: "row !important",
        alignItems: "center !important",
        padding: "12px !important",
        border: `2px dashed #5c5c5c`,
        backgroundColor: `transparent`,
        "& > p": {
          padding: "0px 10px 0px 0px",
        },
      },
    },
    radioLabel: {
      fontWeight: "500 !important",
      fontSize: "0.938rem !important",
      color: "#fff !important",
      width: "100%",
      padding: "3px 15px !important",
      backgroundColor: "#696969",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
      transform: "translate(0, 0) scale(1) !important",
      marginBottom: "3px !important",
      [theme.breakpoints.down("lg")]: {
        borderTopLeftRadius: "0 !important",
        borderTopRightRadius: "0 !important",
      },
      "& ..MuiFormControlLabel-root": {
        marginRight: "5px !important",
      },
    },
    outputType: {
      border: "1px solid #000",
      height: "70px",
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
      borderRadius: "5px",
      marginLeft: "5px",
      [theme.breakpoints.down("md")]: {
        height: "78px",
      },
      " & .MuiFormGroup-row": {
        paddingLeft: "14px",
        "& .MuiFormControlLabel-root": {
          [theme.breakpoints.down("md")]: {
            marginRight: "3px",
          },
        },
      },
    },
    buttonRoot2: {
      backgroundColor: "#D1D1D1",
      fontSize: "10px",
      borderRadius: "15px",
      marginTop: "20px",
      marginLeft: "8px",
      padding: "2px 16px !important",
      [theme.breakpoints.down("md")]: {
        fontSize: "8px",
        marginTop: "15px",
      },
    },
    addGlobalButtons: {
      textAlign: "right",
      marginBottom: "7px",
    },
    filterGroupButton: {
      backgroundColor: "#D1D1D1",
      fontSize: "10px",
      borderRadius: "15px",
      marginTop: "19px",
      padding: 0,
    },
    multiselectlabel: {
      "& .MuiAutocomplete-tagSizeSmall": {
        marginTop: "10px !important",
        height: "20px !important",
        [theme.breakpoints.up(1920)]: {
          marginTop: "22px !important",
        },
        "& .MuiChip-label": {
          fontWeight: "bold !important",
        },
      },
      "& .MuiAutocomplete-tag": {
        marginTop: "12px !important",
      },
      "& .MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root]": {
        padding: "10px 50px 0 8px !important",
      },
    },

    MTableAlign: {
      "& .MuiAccordionDetails-root": {
        display: "inherit",
      },
    },
    mapRuleButton: {
      padding: "6px 5px !important",
    },
    iconSearch: {
      marginTop: "20px",
      cursor: "pointer",
    },
    checkRoot: {
      // margin: 0,
      marginTop: 5,
      marginLeft: -11,
      [theme.breakpoints.down("md")]: {
        " & .MuiSvgIcon-root": {
          fontSize: "1.2rem",
        },
      },
    },

    labelRoot: {
      "& .MuiTypography-root": {
        whiteSpace: "pre-line",
      },
    },
  }));
  const appliedTheme = classes[themeMode];
  const appliedFont = classes[sidebarTheme];
  const value = {
    themeMode,
    sidebarTheme,
    toggleTheme,
    toggleBorderRadius,
    toggleSideBarTheme,
    appliedTheme,
    showWidgetSection,
    toggleWidgetSection,
    appliedFont,
    ReusableInputStyles,
    labelColor,
  };

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export default ThemeProvider;
