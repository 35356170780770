import React from "react";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import { tableIcons } from "../../../../../components/TableIcons";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AccountsUpdated from "./AccountsUpdated";

const PATransaction = () => {
  const columns = [
    {
      title: "Seed Account Number",
      field: "transactionId",
    },
    {
      title: "Account Number",
      field: "TransactionDate",
    },
    {
      title: "Date of Payment",
      field: "authCode",
    },
    {
      title: "Payment Type",
      field: "seedAccountNumber",
      type: "numeric",
      minWidth: 160,
      width: 160,
    },
    {
      title: "	Payment Amount",
      field: "account",
      type: "numeric",
      minWidth: 130,
      width: 130,
    },
  ];

  const data = [
    {
      transactionId: "f2545754bfa3",
      TransactionDate: "02/02/2021 11:20:17",
      authCode: "4CS297",
      seedAccountNumber: "28170753",
      account: "28170753",
      clientName: "Deaconess Health System, Inc",
      payerName: "Deina R",
      paymentMethod: "Credit Card",
      transactionAmount: "$ 4.00",
      billwisePaidAmount: "$ 4.00",
      billAmount: "$ 4.00",
      currentBalanceAmount: "$ 0.00",
      paidBy: "PA",
      paName: "Jennifer",
      paymentStatus: "Failed",
      settledDate: "02/02/2021",
      failureReason: "Account Not Approved",
    },
    {
      transactionId: "f2545754bd2g",
      TransactionDate: "02/02/2021 10:30:27",
      authCode: "4CS284",
      seedAccountNumber: "67814045",
      account: "67814045",
      clientName: "Deaconess Health System, Inc",
      payerName: "Reid Jr.",
      paymentMethod: "eCheck",
      transactionAmount: "$ 50.00",
      billwisePaidAmount: "$ 50.00",
      billAmount: "$ 84.00",
      currentBalanceAmount: "$ 71.00",
      paidBy: "SELF",
      paName: "",
      paymentStatus: "Success",
      settledDate: "02/02/2021",
      failureReason: "",
    },
    {
      transactionId: "f2545754b65d",
      TransactionDate: "02/02/2021 9:50:10",
      authCode: "4CF456",
      seedAccountNumber: "67814045",
      account: "67814045",
      clientName: "Community Health Network",
      payerName: "Reid Jr.",
      paymentMethod: "Credit Card",
      transactionAmount: "$ 50.00",
      billwisePaidAmount: "$ 25.00",
      billAmount: "$ 50.00",
      currentBalanceAmount: "$ 25.00",
      paidBy: "SELF",
      paName: "",
      paymentStatus: "Success",
      settledDate: "02/02/2021",
      failureReason: "",
    },
    {
      transactionId: "f2545754b6g4",
      TransactionDate: "02/02/2021 9:50:07",
      authCode: "4CZ297",
      seedAccountNumber: "67814045",
      account: "67814045",
      clientName: "Community Health Network",
      payerName: "Reid Jr.",
      paymentMethod: "Credit Card",
      transactionAmount: "$ 50.00",
      billwisePaidAmount: "$ 13.00",
      billAmount: "$ 84.00",
      currentBalanceAmount: "$ 71.00",
      paidBy: "SELF",
      paName: "",
      paymentStatus: "Success",
      settledDate: "02/02/2021",
      failureReason: "",
    },
    {
      transactionId: "f2545754bl1w",
      TransactionDate: "02/02/2021 08:40:47",
      authCode: "4CF697",
      seedAccountNumber: "67814045",
      account: "67814045",
      clientName: "Baptist Health Floyd",
      payerName: "Reid Jr.",
      paymentMethod: "Credit Card",
      transactionAmount: "$ 50.00",
      billwisePaidAmount: "$ 12.00",
      billAmount: "$ 84.00",
      currentBalanceAmount: "$ 72.00",
      paidBy: "SELF",
      paName: "",
      paymentStatus: "Success",
      settledDate: "02/02/2021",
      failureReason: "",
    },
    {
      transactionId: "f2545754cfr",
      TransactionDate: "02/02/2021 07:56:47",
      authCode: "4CZ497",
      seedAccountNumber: "67814045",
      account: "67814045",
      clientName: "Baptist Health Floyd",
      payerName: "Reid Jr.",
      paymentMethod: "Credit Card",
      transactionAmount: "$ 10.00",
      billwisePaidAmount: "$ 10.00",
      billAmount: "$ 10.00",
      currentBalanceAmount: "$ 0.00",
      paidBy: "SELF",
      paName: "",
      paymentStatus: "Success",
      settledDate: "02/02/2021",
      failureReason: "",
    },
    {
      transactionId: "f2545754b54f",
      TransactionDate: "02/02/2021 06:47:07",
      authCode: "4CA5297",
      seedAccountNumber: "67814045",
      account: "67814045",
      clientName: "Baptist Health Floyd",
      payerName: "Reid Jr.",
      paymentMethod: "Credit Card",
      transactionAmount: "$ 4.00",
      billwisePaidAmount: "$ 4.00",
      billAmount: "$ 4.00",
      currentBalanceAmount: "$ 0.00",
      paidBy: "SELF",
      paName: "",
      paymentStatus: "Success",
      settledDate: "02/02/2021",
      failureReason: "",
    },
  ];

  return (
    <div className="dashboard-content patient-details" role="main">
      <div role="heading" aria-level="1">
        <h2 className="content-title">
          <AssessmentIcon /> Reports: Accounts Updated
        </h2>
      </div>
      <div>
        <div>
          <AccountsUpdated />

          <Grid item>
            <div className="payplan-table">
              <MaterialTable
                title=""
                localization={{
                  pagination: {
                    firstAriaLabel: "First Page",
                    previousAriaLabel: "Previous Page",
                    nextAriaLabel: "Next Page",
                    lastAriaLabel: "Last Page",
                  },
                }}
                columns={columns}
                icons={tableIcons}
                options={{
                  selection: false,
                  search: false,
                  emptyRowsWhenPaging: false,
                 
                }}
              />
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default PATransaction;
