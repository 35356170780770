/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import {logo} from "../../../assets/admin_assets/img/m-logo.jpg"
import WhiteLableContainer from "../../../containers/whiteLable/whiteLableContainer";
class LeftSideMenu extends Component {
  constructor(props) {
    super(props);
  }

  dashboard = () => {
    window.location.replace("/AccountDetails");
  };
  transactionReport = () => {
    if (window.matchMedia("(max-width: 991px)").matches) {
      document.querySelector("body").classList.toggle("sidebar-collapse");
      document.querySelector("body").classList.toggle("sidebar-closed");
      document.querySelector("body").classList.toggle("sidebar-open");
    }
    this.props.history.push("/Payment-History");
  };
  settlementReport = () => {
    window.location.replace("/Settlement-Report");
  };
  activityReport = () => {
    window.location.replace("/Activity-Report");
  };
  patientReport = () => {
    window.location.replace("/Patient-Details");
  };
  redirectToUser = () => {
    window.location.replace("/User-Management");
  };
  redirectToRole = () => {
    window.location.replace("/Role-Management");
  };
  viewbills = () => {
    if (window.matchMedia("(max-width: 991px)").matches) {
      document.querySelector("body").classList.toggle("sidebar-collapse");
      document.querySelector("body").classList.toggle("sidebar-closed");
      document.querySelector("body").classList.toggle("sidebar-open");
    }

    this.props.history.push("/AccountDetails");
  };
  viewPaymentMethods = () => {
    if (window.matchMedia("(max-width: 991px)").matches) {
      document.querySelector("body").classList.toggle("sidebar-collapse");
      document.querySelector("body").classList.toggle("sidebar-closed");
      document.querySelector("body").classList.toggle("sidebar-open");
    }
    this.props.history.push("/PaymentMethods");
  };
  render() {
    return (
      <React.Fragment>
        <aside className="main-sidebar sidebar-dark-primary elevation-4 admin-elevation-4">
          <a
            href={window.location.pathname === "/AccountDetails" ? "javascript:void(0)" : "/AccountDetails"}
            className="brand-link nav-logo-bg">
            <WhiteLableContainer></WhiteLableContainer>
          </a>

          <div className="sidebar">
            <nav className="mt-3">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false">
                {/* <li className="nav-item ">
            <a onClick={this.dashboard}  className="nav-link admin-menuactive admin-sidebarbtn">
            
			
			  <i className="nav-icon fas fa-tachometer-alt"></i>
              <p>
               Dashbaord
                
              </p>
            </a>
          </li> */}

                {/* 		  
          <li className="nav-item has-treeview">
            <a href="dashboard-personalization.html" className="nav-link admin-menuactive admin-sidebarbtn">
             <i className="nav-icon fas fa-cog"></i>
              <p>
                Configuration
                <i className="fas fa-angle-right right"></i>
               
              </p>
            </a>
            <ul className="nav nav-treeview">
              <li className="nav-item">
                <a href="#" className="nav-link admin-menuactive admin-sidebarbtn">
                  <i className="far fa-circle nav-icon"></i>
                  <p>Dashboard Settings</p>
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link admin-menuactive admin-sidebarbtn">
                  <i className="far fa-circle nav-icon"></i>
                  <p>Email Template</p>
                </a>
              </li>  
            </ul>
          </li> */}
                {/* <li class="nav-item has-treeview">
            <a href="#" class="nav-link admin-menuactive admin-sidebarbtn">
            <i class="nav-icon  fas fa-shield-alt"></i>
              <p>
                Security Management
                <i class="fas fa-angle-right right"></i>
               
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a onClick={this.redirectToUser} class="nav-link admin-menuactive admin-sidebarbtn">
                  <i class="far fa-circle nav-icon"></i>
                  <p>User Management</p>
                </a>
              </li>
              <li class="nav-item">
                <a onClick={this.redirectToRole} class="nav-link admin-menuactive admin-sidebarbtn">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Role Management</p>
                </a>
              </li>
            
            </ul>
          </li> */}

                {/* <li className={ window.location.pathname =="/Dashboard" ? "active nav-item" : "nav-item"}>
            <a  onClick={this.dashboard} className=" nav-link admin-menuactive admin-sidebarbtn">
         
          <i class="fas fa-tachometer-alt nav-icon"></i>
              <p>
             Dashboard
                
              </p>
            </a>
          </li>           */}
                {/* <li className="nav-item">
            <a  onClick={this.patientReport} className="nav-link admin-menuactive admin-sidebarbtn">
          <i className="fas fa-user nav-icon"></i>
              <p>
             Patient Search
                
              </p>
            </a>
          </li> */}

                <li
                  className={
                    window.location.pathname === "/AccountDetails" ||
                    window.location.pathname === "/PayPlanOptions" ||
                    window.location.pathname === "/Dashboard"
                      ? "active nav-item"
                      : "nav-item"
                  }>
                  <a onClick={this.viewbills} className="nav-link admin-sidebarbtn">
                    <i class="fas fa-user nav-icon"></i>
                    <p>Account Detail</p>
                  </a>
                </li>

                <li className={window.location.pathname == "/Payment-History" ? "active nav-item" : "nav-item"}>
                  <a onClick={this.transactionReport} className="nav-link admin-sidebarbtn">
                    <i class="fas fa-file-alt nav-icon"></i>
                    <p>Payment History</p>
                  </a>
                </li>

                <li
                  className={
                    window.location.pathname === "/PaymentMethods" ||
                    window.location.pathname === "/addCard" ||
                    window.location.pathname === "/addEcheck"
                      ? "active nav-item"
                      : "nav-item"
                  }>
                  <a onClick={this.viewPaymentMethods} className="nav-link admin-sidebarbtn">
                    <i class="fas fa-credit-card nav-icon"></i>
                    <p>Payment Methods</p>
                  </a>
                </li>

                {/* <li className="nav-item has-treeview">
            <a  className="nav-link admin-menuactive admin-sidebarbtn">
              <i className="nav-icon fas fa-chart-pie"></i>
              <p>
               Reports
                <i className="right fas fa-angle-right"></i>
              </p>
            </a>
            <ul className="nav nav-treeview">
              <li className="nav-item">
                <a onClick={this.transactionReport} className="nav-link admin-menuactive admin-sidebarbtn">
                  <i className="far fa-circle nav-icon"></i>
                  <p>Payment History</p>
                </a>
              </li>
              <li className="nav-item">
                <a onClick={this.viewbills} className="nav-link admin-menuactive admin-sidebarbtn">
                  <i className="far fa-circle nav-icon"></i>
                  <p>View Bills</p>
                </a>
              </li>
              {/* <li className="nav-item">
                <a  className="nav-link admin-menuactive admin-sidebarbtn">
                  <i className="far fa-circle nav-icon"></i>
                  <p>Authorization Report</p>
                </a>
              </li>
              <li className="nav-item">
                <a  onClick={this.settlementReport} className="nav-link admin-menuactive admin-sidebarbtn">
                  <i className="far fa-circle nav-icon"></i>
                  <p>Settlement Details</p>
                </a>
              </li>
			  
			   <li className="nav-item">
                <a  onClick={this.activityReport} className="nav-link admin-menuactive admin-sidebarbtn">
                  <i className="far fa-circle nav-icon"></i>
                  <p>Activity Report</p>
                </a>
              </li> * /}
			  		 
			 
            </ul>
				    
          </li> */}
              </ul>
            </nav>
          </div>
        </aside>
      </React.Fragment>
    );
  }
}
export default withRouter(LeftSideMenu);
