import { Global_var } from "../global/global_var";
import RestDataSource from "./restdatasource";
import { loginService } from "./loginService";
// import { checkUserSession } from "../common/userSessionExpiry";

export const forgotPasswordService = {
  getForgotPassword,
  getForgotUsername,
  resetAccountNumber,
};

function getForgotPassword(userData, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_FORGOT_PASSWORD;
      return new RestDataSource(url, 0, fn).Store(userData, (res) => {
        // checkUserSession.UserSession(res);
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}

function getForgotUsername(userData, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_FORGET_USER_NAME;
      return new RestDataSource(url, 0, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}

function resetAccountNumber(userData, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_RESET_ACCOUNT_NUMBER;
      return new RestDataSource(url, 0, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  });
}
