import { Global_var } from "../global/global_var";
import { loginService } from "./loginService";
import RestDataSource from "./restdatasource";
import {
  error,
  success,
  warning,
  warningNotification,
  errorNotification,
  successNotification,
} from "../routes/Pages/Notification/Notification.js";

export const patientService = {
  getPatientList,
  searchPatient,
};

function getPatientList(patientData, userId, fn, fnError) {
  // debugger;
  // loginService.TokenUser((res) => {
  //   if (res.status === "success") {
  //     debugger
  //     sessionStorage.setItem("jwt-token", res.responseObject);
  //     debugger
  // var url = Global_var.URL_GET_CONTENT_LABEL_DATA;
  //  var url= "http://localhost:8056/pp-report-service/transaction/api/v1/_details"
  var url = Global_var.BASEURL + Global_var.URL_GET_PATIENT_DATA;
  return new RestDataSource(url, userId, fn).Store(patientData, (res) => {
    // debugger;
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else {
          warning("Session expired! Please refresh the page", warningNotification);
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-token", res.headers.token);
        }

        fn(res);
      }
    }
  });
  //   }
  // }, fnError);
}

function searchPatient(patientData, fn, fnError) {
  // debugger;
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      // debugger;
      sessionStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_PATIENT_DATA;

      return new RestDataSource(url, null, fn).Store(patientData, (res) => {
        if (res != null) {
          if (res["data"].status === "fail") {
            if (res["data"].reasonText === "Invalid Token") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/");
            } else if (res["data"].reasonCode === "SM-02") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/");
            } else if (res["data"].reasonCode === "SM-10") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/");
            } else {
              warning("Session expired! Please refresh the page", warningNotification);
            }
          } else {
            if (res.headers["token"] != null) {
              sessionStorage.setItem("jwt-token", res.headers.token);
            }

            fn(res);
          }
        }
      });
    }
  }, fnError);
}
