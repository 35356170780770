import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router";
import ErrorPage from "../../../util/Errorpage";
import PatientDetails from "./PatientDetails";
import PrivateRoute from "../../../PrivateRoute";
import PATransaction from "./PATransaction";
import MyProfile from "../MyProfile";
import Account from "../Account";
import SpinnerPage from "../../../Common/PP_loader";
import Billing from "../Billing";
import CreditCard from "../HomeCreditCardInfo";
import Echeck from "../ECheck";
import PaymentHistory from "../Transactions/PaymentHistory/index";
import PayPlanOptions from "../PayPlanOptions";
import PayNowTransactionSuccess from "../../../components/TransactionSuccessPages/PayNowTransactionSuccess";
import PayFutureTransactionSuccess from "../../../components/TransactionSuccessPages/PayFutureTransactionSuccess";
import AddCardContainer from "../../../containers/PaymentMethodsContainer/AddCardContainer";
import EditCard from "../EditCard";
import PaymentMethod from "../PaymentMethod";
import PaymentReconcilation from "./Reports/Payment-Reconcilation/index";
import AccountsUpdated from "./Reports/AccountsUpdated/index";
import Communication from "./Reports/Communication/index";
import Opt from "./Reports/Opt-in/index";
import PaymentPlans from "./Reports/Payment-Plans/index";
import ScheduledTransaction from "./Reports/ScheduledTransaction";
import EmailConfirmationComponent from "./EmailConfirmation";
import { UserManagementService } from "../../../services/UserManagementService";
import AddCard from "../AddCard";
import EcheckCardContainer from "../../../containers/PaymentMethodsContainer/AddEchequeContainer";
import PaymentPlanHospital from "../PayPlanOptions/PaymentPlanHospital";
import Epic from "../Epic";
import Payment from "../Transactions/Payment-Methods";
import NewCreditCard from "../../../components/CreditCard/index";
import NewEcheck from "../../../components/eCheck/index";
import PayPlanOptionsSuccess from "../PayPlanOptions/PaymentArrangement/index";
import UserManagementContainer from "./UserManagement/userManagementContainer";
import UnlockUser from "./UnlockUser/index";
import FutureScheduledTransaction from "./Reports/FutureScheduledTransaction";
import SettlementOptions from "./ClientManagement/SettlementOptions";
import ClientMaintenance from "./ClientManagement/ClientMaintenance";
import PaymentPlanOptionsPage from "./ClientManagement/PaymentPlanOptions";
import AdminSurveyDashboardContainer from "../../../containers/Admin_SurveyDashboard/AdminSurveyDashboardContainer";
import PAScreens from "../../Pages/PAScreens";
import ChangePassword from "../ChangePassword";
import PaymentsReceivedReport from "./campaignTracking/PaymentsReceivedReport";
import ViewedButNotPaidReport from "./campaignTracking/ViewedButNotPaidReport";
import PURLUsageReport from "./campaignTracking/PURLUsageReport";
import RPSettlementContainer from "./RPSettlements/RPSettlementContainer";

const Routes = ({ match }) => {
  const getRole = sessionStorage.getItem("currentUserRole") === "Admin" ? true : false;
  const [sidenavPatientSearch, setSidenavPatientSearch] = useState(false);

  const [sidenavAccountsUpdated, setSidenavAccountsUpdated] = useState(false);
  const [sidenavCommunications, setSidenavCommunications] = useState(false);
  const [sidenavOptin, setSidenavOptin] = useState(false);
  const [sidenavPaymentPlans, setSidenavPaymentPlans] = useState(false);
  const [sidenavPaymentReconciliation, setSidenavPaymentReconciliation] = useState(false);
  const [sidenavTransactionSearch, setSidenavTransactionSearch] = useState(false);
  const [sidenavMail, setSidenavMail] = useState(false);
  const [sidenavUnlockUser, setSidenavUnlockUser] = useState(false);
  const [sidenavUserManagement, setSidenavUserManagement] = useState(false);
  const [sidenavReverseTransaction, setSidenavReverseTransaction] = useState(false);
  const [sidenavSurveyDashboard, setSidenavSurveyDashboard] = useState(true);
  const [reports, setReports] = useState(true);
  const [sidenavScheduledTransaction, setSidenavScheduledTransaction] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientMaintenance, setClientMaintenance] = useState(false);
  const [paymentPlanOptions, setPaymentPlanOptions] = useState(false);
  const [settilementOptions, setSettilementOptions] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const id = JSON.parse(sessionStorage.getItem("userData"))?.role[0]?.roleLevel;

    if (id || (id === 0 && sessionStorage.getItem("currentUserRole") === "Admin")) {
      UserManagementService.roleAccess(id, (res) => {
        if (Object.keys(res).length > 1) {
          sessionStorage.setItem("roleData", JSON.stringify(res?.data?.responseObject));
        }
        if (res?.data?.responseObject?.level === 0) {
          setReports(false);
        }
        setIsLoading(false);
        res?.data?.responseObject?.pages?.map((item) => {
          if (item?.functionalities?.["Patient Search"]?.["page access"]) {
            setSidenavPatientSearch(item?.functionalities?.["Patient Search"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["Reports- Accounts Updated"]?.["page access"]) {
            setSidenavAccountsUpdated(item?.functionalities?.["Reports- Accounts Updated"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["Reports- Scheduled transactions"]?.["page access"]) {
            setSidenavScheduledTransaction(
              item?.functionalities?.["Reports- Scheduled transactions"]?.["page access"] === "true",
            );
          }
          if (item?.functionalities?.["Reports- Communications By"]?.["page access"]) {
            setSidenavCommunications(item?.functionalities?.["Reports- Communications By"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["Reports- Opt-in/Opt-out by communication Type"]?.["page access"]) {
            setSidenavOptin(
              item?.functionalities?.["Reports- Opt-in/Opt-out by communication Type"]?.["page access"] === "true",
            );
          }
          if (item?.functionalities?.["Reports- Payment Plans"]?.["page access"]) {
            setSidenavPaymentPlans(item?.functionalities?.["Reports- Payment Plans"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["Reports- Payment Reconciliation"]?.["page access"]) {
            setSidenavPaymentReconciliation(
              item?.functionalities?.["Reports- Payment Reconciliation"]?.["page access"] === "true",
            );
          }
          if (item?.functionalities?.["Reports- Transaction Search"]?.["page access"]) {
            setSidenavTransactionSearch(item?.functionalities?.["Reports- Transaction Search"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["Reports- Reverse Transaction"]?.["page access"]) {
            setSidenavReverseTransaction(
              item?.functionalities?.["Reports- Reverse Transaction"]?.["page access"] === "true",
            );
          }
          if (item?.functionalities?.["Client Maintenance"]?.["page access"]) {
            setClientMaintenance(item?.functionalities?.["Client Maintenance"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["Payment Plan Options"]?.["page access"]) {
            setPaymentPlanOptions(item?.functionalities?.["Payment Plan Options"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["Settlement Options"]?.["page access"]) {
            setSettilementOptions(item?.functionalities?.["Settlement Options"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["Survey dashboard"]?.["page access"]) {
            setSidenavSurveyDashboard(item?.functionalities?.["Survey dashboard"]?.["page access"] === "true");
          }
          if (item?.name === "Dashboard Sidenav" && item?.functionalities?.["Resend Confirmation Email"]?.["page access"]) {
            setSidenavMail(item?.functionalities?.["Resend Confirmation Email"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["Unlock user"]?.["page access"]) {
            setSidenavUnlockUser(item?.functionalities?.["Unlock user"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["User Management"]?.["page access"]) {
            setSidenavUserManagement(item?.functionalities?.["User Management"]?.["page access"] === "true");
          }
        });
      });
    }
  }, []);
  return (
    <React.Fragment>
      <Switch>
        {sidenavPatientSearch ? (
          <Redirect exact from="/pa" to="pa/patientDetails" />
        ) : (
          <Redirect exact from="/pa" to="pa/user-management" />
        )}
        <PrivateRoute
          path={`${match.url}/patientDetailsBack`}
          component={getRole && isLoading ? SpinnerPage : getRole && sidenavPatientSearch ? PAScreens : ErrorPage}
        />
        <PrivateRoute
          path={`${match.url}/patientDetails`}
          component={getRole && isLoading ? SpinnerPage : getRole && sidenavPatientSearch ? PatientDetails : ErrorPage}
        />
        <PrivateRoute
          exact
          path={`${match.url}/reports/transaction-search`}
          component={getRole ? PATransaction : ErrorPage}
        />
        <PrivateRoute
          path={`${match.url}/reports/payment-reconcilation`}
          component={sidenavPaymentReconciliation ? PaymentReconcilation : ErrorPage}
        />
        <PrivateRoute
          path={`${match.url}/reports/account-updated`}
          component={sidenavAccountsUpdated ? AccountsUpdated : ErrorPage}
        />
        <PrivateRoute
          path={`${match.url}/reports/communication`}
          component={sidenavCommunications ? Communication : ErrorPage}
        />
        <PrivateRoute path={`${match.url}/reports/opt`} component={sidenavOptin ? Opt : ErrorPage} />
        <PrivateRoute
          path={`${match.url}/reports/payment-plans`}
          component={sidenavPaymentPlans ? PaymentPlans : ErrorPage}
        />
        <PrivateRoute
          path={`${match.url}/reports/scheduled-PP-transaction`}
          component={sidenavScheduledTransaction ? ScheduledTransaction : ErrorPage}
        />
        <PrivateRoute
          path={`${match.url}/reports/scheduled-FP-transaction`}
          component={sidenavScheduledTransaction ? FutureScheduledTransaction : ErrorPage}
        />
        <PrivateRoute
          path={`${match.url}/clientManagement/clientMaintenance`}
          component={clientMaintenance ? ClientMaintenance : ErrorPage}
        />
        <PrivateRoute
          path={`${match.url}/clientManagement/paymentPlanOptions`}
          component={paymentPlanOptions ? PaymentPlanOptionsPage : ErrorPage}
        />
        <PrivateRoute
          path={`${match.url}/clientManagement/settilementOptions`}
          component={settilementOptions ? SettlementOptions : ErrorPage}
        />
        <PrivateRoute
          path={`${match.url}/survey-dashboard`}
          component={sidenavSurveyDashboard ? AdminSurveyDashboardContainer : ErrorPage}
        />
        <PrivateRoute
          path={`${match.url}/resend-email-confirmation`}
          component={sidenavMail ? EmailConfirmationComponent : ErrorPage}
        />

        <PrivateRoute path={`${match.url}/update-profile`} component={MyProfile} />
        <PrivateRoute path={`${match.url}/pay-now-success`} component={PayNowTransactionSuccess} />
        <PrivateRoute path={`${match.url}/pay-future-success`} component={PayFutureTransactionSuccess} />
        <PrivateRoute path={`${match.url}/change-password`} component={ChangePassword} />
        <PrivateRoute path={`${match.url}/billing`} component={Billing} />
        {/* <PrivateRoute path={`${match.url}/credit-Card`} component={CreditCard} /> */}
        <PrivateRoute path={`${match.url}/payment-info`} component={PaymentMethod} />
        <PrivateRoute path={`${match.url}/echeck`} component={Echeck} />
        <PrivateRoute path={`${match.url}/unlock-user`} component={UnlockUser} />
        <PrivateRoute path={`${match.url}/pay-plan-options`} component={PayPlanOptions} />
        <PrivateRoute path={`${match.url}/edit-card`} component={EditCard} />

        <PrivateRoute path={`${match.url}/billing`} component={getRole ? Billing : ErrorPage} />
        <PrivateRoute path={`${match.url}/pay-now-success`} component={PayNowTransactionSuccess} />
        <PrivateRoute path={`${match.url}/pay-future-success`} component={PayFutureTransactionSuccess} />
        <PrivateRoute path={`${match.url}/pay-plan-options`} component={PayPlanOptions} />
        <PrivateRoute path={`${match.url}/payment-methods`} component={Payment} />
        <PrivateRoute path={`${match.url}/payment-history`} component={PaymentHistory} />
        <PrivateRoute path={`${match.url}/account`} component={Account} />
        <PrivateRoute path={`${match.url}/update-profile`} component={MyProfile} />
        <PrivateRoute path={`${match.url}/credit-Card`} component={AddCardContainer} />
        <PrivateRoute path={`${match.url}/payment-info`} component={PaymentMethod} />
        <PrivateRoute path={`${match.url}/echeck`} component={Echeck} />
        <PrivateRoute path={`${match.url}/add-card`} component={AddCard} />
        <PrivateRoute path={`${match.url}/edit-card`} component={EditCard} />
        <PrivateRoute path={`${match.url}/add-echeck`} component={EcheckCardContainer} />
        <PrivateRoute path={`${match.url}/payment-plan-details`} component={PaymentPlanHospital} />
        <PrivateRoute path={`${match.url}/epic-poc`} component={Epic} />
        <PrivateRoute path={`${match.url}/PayPlanOptionsSuccess`} component={PayPlanOptionsSuccess} />
        <PrivateRoute path={`${match.url}/new-credit-card`} component={NewCreditCard} />
        {/* <PrivateRoute path={`${match.url}/new-echeck`} component={NewEcheck} /> */}
        <PrivateRoute path={`${match.url}/payments-received`} component={PaymentsReceivedReport} />
        <PrivateRoute path={`${match.url}/purl-usage`} component={PURLUsageReport} />
        <PrivateRoute path={`${match.url}/viewed-but-not-paid`} component={ViewedButNotPaidReport} />
        <PrivateRoute
          path={`${match.url}/rp-settlements`}
          component={getRole && isLoading ? SpinnerPage : getRole ? RPSettlementContainer : ErrorPage}
        />
        <PrivateRoute
          path={`${match.url}/user-management`}
          component={
            getRole && isLoading ? SpinnerPage : getRole && sidenavUserManagement ? UserManagementContainer : ErrorPage
          }
        />
        <PrivateRoute path="*" component={ErrorPage} />
      </Switch>
    </React.Fragment>
  );
};

export default withRouter(Routes);
