import {
  USERS_LOGIN_SUCCESS,
  USERS_LOGIN_FAILURE,
  USERS_LOGIN_REQUEST,
  USER_LOGOUT,
} from "../action/loginAction";

const userdata =
sessionStorage.getItem("userData") === ""
    ? ""
    : JSON.parse(sessionStorage.getItem("userData"));

const initialState = {
  pending: false,
  isUserLoggedIn: userdata ? true : false,
  user: userdata ? userdata : [],
  error: null,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERS_LOGIN_REQUEST:
      return {
        ...state,
        pending: true,
        isUserLoggedIn: false,
      };

    case USERS_LOGIN_SUCCESS:
      return {
        ...state,
        pending: false,
        isUserLoggedIn: true,
        user: action.payload,
      };

    case USERS_LOGIN_FAILURE:
      return {
        ...state,
        pending: false,
        isUserLoggedIn: false,
        error: action.error,
      };
    case USER_LOGOUT:
      return {
        ...state,
        pending: false,
        isUserLoggedIn: false,
      };

    default:
      return state;
  }
};
export default loginReducer;
export const getLogin = (state) => ({
  user: state.loginReducer,
  pending: state.loginReducer,
  isUserLoggedIn: state.loginReducer,
  error: state.loginReducer,
});

