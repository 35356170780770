import React, { useState, useEffect } from "react";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { Formik, Form } from "formik";
import { Grid } from "@material-ui/core";
import { IconButton, TextField, FormControl } from "@material-ui/core";
import { useDispatch } from "react-redux";
import moment from "moment";
import { requestInfo_PP } from "../../../../../Common/CommonFn";
import { ExportToExcel } from "../../../../../Common/exportToExcel";
import SpinnerPage from "../../../../../Common/PP_loader";
import { getAccountingReportsList, getReconciliationOrderReport } from "../../../../../action/ReportsAction";
import { handleSuccess, ToastContainer, handleError, handleWarning } from "../../../../../components/Notifications/Toast";
import { FormHelperText } from "@material-ui/core";
import * as Yup from "yup";
import { capitalize } from "../../../../../util/Captilize";
import ClearIcon from "@material-ui/icons/Clear";
import PaymentReconcilationTable from "./PaymentReconcilationTable";
import NumberFormat from "react-number-format";
const PaymentReconcilation = () => {
  let initialValues = {
    accountReferenceNumber: "",
    startDate: null,
    endDate: null,
  };
  const dispatch = useDispatch();
  const [accountingReportData, setAccountingReportData] = useState([]);
  const [countRep, setCountRep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [reconciliationData, setReconciliationData] = useState([]);
  const [reconciliationDataPopup, setReconciliationDataPopUp] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const authStatus = [
    { status: 80001, value: "INITIATED" },
    { status: 80002, value: "SUCCESS" },
    { status: 80003, value: "FAILED" },
    { status: 80004, value: "PENDING" },
    { status: 80005, value: "SETTLED" },
    { status: 80006, value: "REJECTED" },
    { status: 80007, value: "DENIED" },
  ];
  const getValue = (status) => {
    const property = authStatus?.find((obj) => {
      if (obj.status === status) {
        return obj;
      } else {
        return null;
      }
    });
    return property?.value;
  };

  const dollarFormat = (value) => {
    if (value != null) {
      let amount = value;
      return `$ ${Number(amount).toLocaleString("us-en", {
        minimumFractionDigits: 2,
      })}`;
    } else {
      return value;
    }
  };
  const handleAccountingReportDownload = (values) => {
    setIsLoading(true);
    dispatch(
      getAccountingReportsList(
        {
          requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token")),
          ppAccountingReportModel: {
            startDate: moment(values?.startDate).format("YYYY-MM-DD"),
            endDate: moment(values?.endDate).format("YYYY-MM-DD"),
            accountReferenceNumber: values?.accountReferenceNumber,
            pageNo: 0,
            pageSize: -1,
          },
        },
        (res) => {
          setIsLoading(false);
          if (res.data?.responseInfo?.status === "SUCCESSFUL") {
            ExcelData(res?.data?.PPAccountingReportResponse);
            res.data.responseInfo?.responseMessage?.length > 0 && handleWarning(res.data.responseInfo?.responseMessage[0]);
          } else if (res.data?.responseInfo?.status === "FAILED") {
            handleError(res.data.responseInfo?.responseMessage[0]);
          } else {
            handleError(res?.message);
          }
        },
      ),
    );
  };

  const handlingReconcilationData = (
    data,
    paginationDataRep = {
      page: null,
      rowsPerPage: null,
    },
  ) => {
    dispatch(
      getReconciliationOrderReport(
        {
          requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token")),
          reconciliationOrderReportModel: {
            orderNumber: data,
            pageNo: paginationDataRep.page || 0,
            pageSize: paginationDataRep.rowsPerPage || 10,
          },
        },
        (res) => {
          setIsLoading(false);
          if (res.data?.responseInfo?.status === "SUCCESSFUL") {
            setCountRep(res?.data?.totalCount);
            setReconciliationDataPopUp(true);
            setReconciliationData(
              res?.data?.reconciliationOrderReportResponse ? res?.data?.reconciliationOrderReportResponse : [],
            );
          } else if (res.data?.responseInfo?.status === "FAILED") {
            handleError(res.data.responseInfo?.responseMessage[0]);
          } else {
          }
        },
      ),
    );
  };
  const ExcelData = (data) => {
    const DataToExp = data.map((obj) => {
      const {
        paymentGatewayTxnRefNo,
        accountReferenceNumber,
        paymentTxnDatetime,
        firstName,
        paidAmount,
        billWisePaidAmount,
        paymentGatewayStatus,
        paymentGatewayAuthorizationNumber,
        settlementDatetime,
        settlementStatus,
        settlementRefNo,
      } = obj;

      const newObject = {
        "Gateway Transaction Id": paymentGatewayTxnRefNo ? paymentGatewayTxnRefNo : "-",
        "Account Number": accountReferenceNumber ? accountReferenceNumber : "-",
        "Transaction Date": paymentTxnDatetime ? paymentTxnDatetime : "-",
        "Payee Name": firstName ? capitalize(firstName) : "-",
        "Paid bill Amount": billWisePaidAmount || billWisePaidAmount === 0 ? dollarFormat(billWisePaidAmount) : "-",
        "Total paid amount": paidAmount || paidAmount === 0 ? dollarFormat(paidAmount) : "-",
        "Auth Status": paymentGatewayStatus ? getValue(paymentGatewayStatus) : "-",
        "Auth Code": paymentGatewayAuthorizationNumber ? paymentGatewayAuthorizationNumber : "-",
        "Settlement Date": settlementDatetime ? settlementDatetime : "-",
        "Settlement Status": settlementStatus ? settlementStatus : "-",
        "Settlement Ref Number": settlementRefNo ? settlementRefNo : "-",
      };
      return newObject;
    });

    ExportToExcel(DataToExp, "Payment Reconciliation Report");
  };
  const handleSubmit = (values) => {
    setSearchData(values);
    setIsSubmit(true);
  };

  const schema = Yup.object().shape({
    startDate: Yup.string().nullable().required("Start Date is Required"),
    endDate: Yup.string().nullable().required("End Date is Required"),
  });

  return (
    <div>
      <Formik
        initialValues={{
          startDate: null,
          endDate: null,
          accountReferenceNumber: "",
          accountNumber: "",
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        {(props) => {
          const { values, touched, error, handleChange, errors, setFieldValue } = props;
          return (
            <Form id="transaction-search" className="input-field" noValidate="noValidate">
              <div>
                <div className="form-row">
                  <div className="col-lg-3 col-md-4 col-sm-6 form-group">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <label htmlFor="accountReferenceNumber" className="form-label w-100">
                        Account Number
                      </label>

                      <Grid container className="date-picker">
                        <TextField
                          id="accountReferenceNumber"
                          name="accountReferenceNumber"
                          type="text"
                          placeholder="Enter Account Number"
                          onChange={(event) => {
                            setFieldValue("accountReferenceNumber", event.target.value);
                          }}
                          value={values.accountReferenceNumber}
                          aria-label="text-field"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            inputComponent: NumberFormat,
                          }}></TextField>
                      </Grid>
                    </FormControl>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 form-group">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Grid container className="custom-date-picker date-picker">
                        <label htmlFor="patientDOB" className="form-label w-100 required">
                          Start Date{" "}
                        </label>
                        <DatePicker
                          name="startDate"
                          id="startDate"
                          autoOk
                          required
                          value={values.startDate}
                          onChange={(value) => {
                            setFieldValue("startDate", value);
                          }}
                          InputProps={{
                            startAdornment: (
                              <IconButton
                                position="end"
                                onClick={() => setFieldValue("startDate", null)}
                                style={{ order: 1, padding: 0 }}>
                                <ClearIcon fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          InputAdornmentProps={{
                            position: "end",
                            style: { order: 2 },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              helperText={null}
                              margin="dense"
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "MM/DD/YYYY",
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <FormHelperText error name="securitycode">
                        {touched.startDate && errors.startDate ? <div>{errors.startDate}</div> : null}
                      </FormHelperText>
                    </LocalizationProvider>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 form-group">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Grid container className="custom-date-picker date-picker">
                        <label htmlFor="endDate" className="form-label w-100 required">
                          End Date{" "}
                        </label>
                        <DatePicker
                          name="endDate"
                          autoOk
                          value={values.endDate}
                          onChange={(val) => setFieldValue("endDate", val)}
                          minDate={values.startDate - 1}
                          InputProps={{
                            startAdornment: (
                              <IconButton
                                position="end"
                                onClick={() => setFieldValue("endDate", null)}
                                style={{ order: 1, padding: 0 }}>
                                <ClearIcon fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          InputAdornmentProps={{
                            position: "end",
                            style: { order: 2 },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              helperText={null}
                              margin="dense"
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "MM/DD/YYYY",
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <FormHelperText error name="securitycode">
                        {touched.endDate && errors.endDate ? <div>{errors.endDate}</div> : null}
                      </FormHelperText>
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="payment-history-btns">
                  <button aria-label="Generate Report" type="submit" className="primary-button themeing-buttons">
                    Search
                  </button>
                  <button
                    aria-label="Reset"
                    type="reset"
                    className="secondary-button mx-2"
                    onClick={() => {
                      setAccountingReportData([]);
                      setSearchData(null);
                    }}>
                    Reset
                  </button>
                  {accountingReportData.length > 0 && (
                    <button
                      type="button"
                      aria-label="Generate Report"
                      className="primary-button themeing-secondary-buttons"
                      onClick={() => handleAccountingReportDownload(values)}>
                      Download
                    </button>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      <Grid item>
        <div className=" payplan-table">
          {searchData ? (
            <PaymentReconcilationTable
              setAccountingReportData={setAccountingReportData}
              dollarFormat={dollarFormat}
              getValue={getValue}
              isSubmit={isSubmit}
              setIsSubmit={setIsSubmit}
              searchData={searchData}
            />
          ) : null}
        </div>
      </Grid>
      {isLoading && <SpinnerPage />}
    </div>
  );
};
export default PaymentReconcilation;
