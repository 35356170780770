import React from "react";

export const CancelPlan = ({ plan }) => {
  return <div>Are you sure you want to Cancel selected plan {plan}?</div>;
};

export const DeletePlan = ({ plan, billData }) => {
  return (
    <div>
      Order: <b>{billData?.paymentOrderId}</b>
      {plan ? ` with selected plan ${plan} ?` : ""}
    </div>
  );
};

export const DeletePayment = ({ paymentMethod, card }) => {
  return (
    <div>
      Are you sure you want to delete {paymentMethod} ending with {card}?
    </div>
  );
};
