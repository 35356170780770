import React from "react";
import { useHistory } from "react-router";
import Payment from "../../assets/images/PaymentsSchedule.jpg";
import Med1Logo from "../../assets/images/Med1Logo.png";
import UpdateTrackingPage from "../../containers/UpdateTrackingPage";

const PayFutureTransactionSuccess = (props) => {
  const history = useHistory();

  return (
    <div className="dashboard-content" role="main">
      {/* <p>Change Password</p> */}
      <div className="dashboard-content-card success-card">
        <div className="quickpay-payment">
          <div className="row m-0">
            <div className="col-md-5 thank-you payment-scheduled">
              <div role="heading" aria-level="1">
                <h2>Payment Scheduled Successfully</h2>
              </div>
            </div>
            <div className="col-md-7 p-0">
              <div className="payment-receipt">
                <div className="payment-schedule-section py-2">
                  <img src={Payment} alt="payment" />

                  <h3 class="payment-processed">Payment will be processed on 20th April 2021</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
    </div>
  );
};

export default PayFutureTransactionSuccess;
