/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  handleClick = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    window.location.replace("/signin");
  };

  render() {
    const { cookieEnabled } = navigator;
    const { errorMessage = "Something went wrong." } = this.props;
    if (this.state.hasError) {
      return (
        <div className="errorboundary-section">
          <div className="container-fluid">
            <div className="errorboundary-form-section">
              <div className="row">
                <div className="col">
                  <div className="errorboundary-form">
                    <div className="row">
                      <div className="col p-0">
                        <div className="form-card ">
                          <div className="page-error-container">
                            <div className="page-error-content">
                              <div className="error-code"> </div>
                              <h2 className="text-center">{errorMessage}</h2>
                              <h4 className="text-center">
                                {cookieEnabled
                                  ? ""
                                  : "Your browser has cookies disabled. Make sure that your cookies are enabled and try again."}
                              </h4>

                              <div className="text-center login-btn">
                                <Button onClick={() => this.handleClick()} className="primary">
                                  Sign in Again
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
