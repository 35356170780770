import { resetPasswordService } from "../core_services/AdminResetPasswordService";
export const ADMIN_RESET_PASSWORD_SUCCESS = "ADMIN_RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_PENDING = "RESET_PASSWORD_PENDING";

export function resetPasswordSuccess(userData) {
  return {
    type: ADMIN_RESET_PASSWORD_SUCCESS,
    payload: userData,
  };
}

export function adminResetPasswordData(userData, fn) {
  return (dispatch) => {
    dispatch({
      type: ADMIN_RESET_PASSWORD_SUCCESS,
      payload: userData,
    });
    resetPasswordService.adminResetPassword(userData, (res) => fn(res));
  };
}
