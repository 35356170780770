import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import { Button } from "@material-ui/core";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useLocation, useHistory } from "react-router";
import { IconButton } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import visacard from "../../../assets/images/visa.png";
import mastercard from "../../../assets/images/masterCard.png";
import amexcard from "../../../assets/images/amex.jpg";
import creditcards from "../../../assets/images/credit-cards.png";
import DeleteIcon from "@material-ui/icons/Delete";
import { echequePaymentService } from "../../../services/echequePaymentService";
import { requestInfo_PP } from "../../../Common/CommonFn";
import { DeletePayment } from "../../../containers/Admin_Report/PayPlanOptions/CancelPlan";
import PageLoader from "./../../../components/ContentLoader/PageLoader";
import { handleSuccess, ToastContainer, handleWarning } from "../../../components/Notifications/Toast";
import { capitalize } from "../../../util/Captilize";
import ConfirmationDialog from "../../../components/ConfirmationDialog/index";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiButtonBase-root": {
      backgroundColor: "#f3f3f3",
    },
    "& .MuiCollapse-wrapper": {
      backgroundColor: "#f3f3f3",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "50%",
    flexShrink: 0,
    textAlign: "left",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  accordionBtn: {
    marginLeft: "6rem",
    backgroundColor: "#000",
    color: "#000",
  },
  deleteIcon: {
    outline: "none",
  },
  deleteBtn: {
    outline: "none",
  },
}));

const getCardDetails = (data) => {
  const street =
    data && data.billingAddress && data.billingAddress.street
      ? data.billingAddress.street
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ") + ", "
      : "";
  const street2 =
    data && data.billingAddress && data.billingAddress.street2
      ? data.billingAddress.street2
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ") + ", "
      : "";
  const city =
    data && data.billingAddress && data.billingAddress.city
      ? data.billingAddress.city
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ") + ", "
      : "";
  const state =
    data && data.billingAddress && data.billingAddress.state
      ? data.billingAddress.state
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ") + ", "
      : "";
  const country =
    data && data.billingAddress && data.billingAddress.country
      ? data.billingAddress.country
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ") + ". "
      : "";
  const postalCode =
    data && data.billingAddress && data.billingAddress.postalCode ? data.billingAddress.postalCode + " " : "";
  const cardName = data.cardName || "N/A";
  const billingAddress = street + street2 + city + state + country + postalCode || "N/A";

  return (
    <div className="billing-address-cp">
      <div>
        <b>Name: </b> {capitalize(cardName)}
      </div>
      <div>
        {" "}
        <b>Billing Address: </b> {billingAddress}
      </div>
    </div>
  );
};

export default function PaymentDetailsAccordion({ creditCardData, showloader, getPaymentDetails, deleteAccess }) {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [card, setcard] = useState();
  const [cardData, setCardData] = useState(creditCardData);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setCardData(creditCardData);
  }, [creditCardData]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const visapattern = /^4[0-9]{12}(?:[0-9]{3})?$/;
  const masterpattern = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/;
  const amexpattern = /^3[47][0-9]{13}$/;
  let cardType = "";
  const validateCard = (value) => {
    if (value === "Visa") {
      cardType = "Visa Card";
      return visacard;
    } else if (value === "Master Card") {
      cardType = "Master Card";
      return mastercard;
    } else if (value === "American Express") {
      cardType = "American Express Card";
      return amexcard;
    } else {
      return;
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleDeletePayment = (paymentMethod, paymentId, instrumentId) => {
    setLoader(true);
    setExpanded(false);
    handleClose();
    echequePaymentService.deletePaymentMethod(
      requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      paymentMethod,
      paymentId,
      instrumentId,
      1234,
      (res) => {
        if (res.status === 200 && res.data?.body === "Deleted") {
          getPaymentDetails();
          setLoader(false);
          handleClose();
          handleSuccess(`Payment Method Deleted Successfully`);

          let passValues = {
            requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jti-token")),
            paymentInfoSearch: {
              paymentMethodId: "75001",
              partyId: JSON.parse(sessionStorage.getItem("verifypayer")).partyId,
            },
          };
          echequePaymentService.getEchequePayment(passValues, 1234, (res) => setCardData(res.data.paymentInfos[0]));
        } else if (res.status === 200) {
          setLoader(false);
          handleClose();
          handleWarning(res?.data?.body);
        } else {
          setLoader(false);
          handleWarning("Something went wrong!");
          handleClose();
        }
      },
    );
  };
  return (
    <div className={`${classes.root} custom-accordion`}>
      {loader ? <PageLoader /> : ""}

      <ConfirmationDialog
        open={dialogOpen}
        handleClose={handleClose}
        handleSubmit={() => handleDeletePayment("75001", card.cardId, card.paymentInstrument?.paymentInstrumentId)}
        fullWidth={true}
        title="Are you sure you want to delete the card">
        <DeletePayment
          paymentMethod={"Card"}
          card={card?.cardNumber?.substring(card.cardNumber.length - 4, card.cardNumber.length)}
        />
      </ConfirmationDialog>

      {cardData &&
        cardData.cardAccount &&
        cardData.cardAccount.length > 0 &&
        cardData.cardAccount.map((card, i) => (
          <Accordion
            key={i}
            className="paymentmethods-cp mb-2"
            expanded={expanded === "panel" + i}
            onChange={handleChange("panel" + i)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
              <div className={`card-heading ${classes.heading}`}>
                <div className="card-name">
                  <img src={validateCard(card.cardType)} alt="Card" />
                  <span>
                    {cardType} ending with {card.cardNumber.substring(card.cardNumber.length - 4, card.cardNumber.length)}
                  </span>
                </div>
              </div>
              <div className="date-edit-delete-accordion">
                <Typography className={classes.secondaryHeading}>
                  {`${card.cardExpiryMonth}/${card.cardExpiryYear}`}
                </Typography>
                <div className="edit-delete-icons">
                  <IconButton
                    className={classes.deleteBtn}
                    disabled={sessionStorage.getItem("currentUserRole") === "Admin" ? !deleteAccess : false}
                    onClick={() => {
                      setDialogOpen(true);
                      setcard(card);
                    }}>
                    <DeleteIcon
                      className={classes.deleteIcon}
                      disabled={sessionStorage.getItem("currentUserRole") === "Admin" ? !deleteAccess : false}
                    />
                  </IconButton>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails className="customer-details">
              <Typography>{getCardDetails(card)}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      {showloader ? <PageLoader /> : ""}
    </div>
  );
}
