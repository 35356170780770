import React from "react";
import Communication from "./Comunication";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ForumIcon from "@mui/icons-material/Forum";

const PATransaction = () => {
  return (
    <div className="transaction-card" role="main">
      <div className="main-container-card">
        <div role="heading" aria-level="1" className="heading-style mb-3">
          <ForumIcon className="mr-2" />
          <h2 className="heading-style">Reports: Communication By</h2>
        </div>
        <div>
          <Communication />
        </div>
      </div>
    </div>
  );
};

export default PATransaction;
