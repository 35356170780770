import React, { useState } from "react";
import PAScreens from "../../routes/Pages";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {
  Checkbox,
  MenuItem,
  FormControl,
  FormControlLabel,
  Select,
  FormLabel,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { IconButton } from "@material-ui/core";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import ImageTooltip from "../../components/ImageTooltip";
import echeckPaymentRoutingNumber from "../../assets/images/echeckPaymentRoutingNumber.jpg";
import { cityData, countryData, allStatesData, stateList } from "../../util/StatesData";
import { Grid } from "@material-ui/core";
import { countryList } from "../../containers/RegionList";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import Signature from "../../components/Signature";
// import Signature from "../../containers/Admin_Report/Signature";
import { echequePaymentService } from "../../services/echequePaymentService";
import {
  error,
  success,
  warning,
  warningNotification,
  errorNotification,
  successNotification,
} from "../../components/PP_Notification/PP_Notification";
import { handleSuccess, ToastContainer, handleWarning, handleError } from "../../components/Notifications/Toast";
import { requestInfo_PP } from "../../Common/CommonFn";
import TermsAndConditions from "./../../components/TermsAndConditions/TermsAndConditions";
import moment from "moment";
import { payplanService } from "../../services/payplanService";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import UpdateTrackingPage from "../UpdateTrackingPage";
import SpinnerPage from "../../Common/PP_loader";

class EcheckCardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideNavToggleClass: false,
      accountType: "checking",
      showAnumber: true,
      showRnumber: true,
      cardnumber: true,
      routingNumber: true,
      reAccountNumber: true,
      showForm: true,
      showloader: false,
      countryList: [],
      cityList: [],
      country: "United States of America",
      state: "",
      city: "",
      address1: "",
      carddata: "",
      payeraddrdata: "",
      isMaskOne: true,
      instrumentid: "",
      ES_AppId: "",
      ES_PartyId: "",
      ES_Amount: "",
      ES_SubId: "",
      ES_Method: "",
      ES_OrderId: "",
      ES_url: "",
      ES_PlanId: "",
      ES_PriceSubId: "",
      ES_PurchaseParameters: "",
      ES_JsonData: "",
      seedAccNum: this.props.location?.state?.seedAccNum,
      values: this.props.location?.state?.values,
      frequencyType: this.props.location?.state?.frequencyType,
      page: this.props.location?.state?.page,
      authorizeElectronicDebit: true,
      agreeToEmailUsage: true,
      agreeToterms: true,
      editECheckData:
        this.props.location?.state?.selectedECheck &&
        this.props.location?.state?.selectedECheck.length > 0 &&
        this.props.location?.state?.selectedECheck[0],
      orderId: this.props.location?.state?.orderId,
      plan: this.props.location?.state?.plan,
      linkedPaymentMethodEntityId: 0,
      billingAddressId: 0,
      accountNumber: "",
      maskedAccountNumber: "",
      maskedRoutingNumber: "",
      billingAddress: {
        billingAddressId: 0,
        city: "",
        country: "United States of America",
        email: "",
        firstName: "",
        lastName: "",
        middleName: "",
        phoneNo: "",
        postalCode: "",
        state: "",
        street: "",
        street2: "",
      },
      isSignatureModalOpen: false,
      italicSign: "",
      signature: "",
      routingnumber: "",
      showreAccountNumber: true,
      paymentOptions: this.props.location.state?.isPaymentOptions || false,
    };
    this.updateUserSignature = this.updateUserSignature.bind(this);
    this.toggleShowAnumber = this.toggleShowAnumber.bind(this);
    this.handleRoutingNumber = this.handleRoutingNumber.bind(this);
    this.handleReAccountNumber = this.handleReAccountNumber.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      accountNumber: this.state.editECheckData?.accountNumber,
      reenteraccountNumber: this.state.editECheckData?.reenteraccountNumber,
      routingnumber: this.state.editECheckData?.bankAchRoutingCode,
      accountType: this.state.editECheckData?.accountType === 76002 ? "savings" : "checking",
      paymentInstrumentId: this.state.editECheckData?.paymentInstrument?.paymentInstrumentId,
    });

    const passValues = {
      countryId: "",
      countryName: "",
      limit: -1,
    };
    const userId = "1212";

    let payeruserdata = JSON.parse(sessionStorage.getItem("verifypayer"));

    if (this.state?.editECheckData) {
      const selectedState = stateList?.filter(
        (item) =>
          item?.stateName.toLocaleLowerCase() === this.state.editECheckData?.billingAddress?.state.toLocaleLowerCase(),
      );
      var PassValues = {
        stateId: selectedState[0]?.stateId,
      };
      const UserId = "1234";
      this.setState({ showloader: true });
    } else {
      var PassValues = {
        stateId: payeruserdata?.addressVo?.stateId,
      };
      const UserId = "1234";
      this.setState({ showloader: true });
    }

    if (this.state.editECheckData) {
      const card = this.state.editECheckData;
      const billAddress = this.state.editECheckData?.billingAddress;

      let getAccountNumber = card?.accountNumber;

      let getRoutingNumber = card?.bankAchRoutingCode;

      let getLastFourDigits = getAccountNumber?.slice(-4);

      let getLastFourDigitsRouting = getRoutingNumber?.slice(-4);

      let maskedAccountNumber = getLastFourDigits.padStart(getAccountNumber.length, "*");

      let maskedRoutingNumber = getLastFourDigitsRouting.padStart(getRoutingNumber.length, "*");

      this.setState({
        ...this.state,
        accountNumber: card?.accountNumber || "",
        reenteraccountNumber: card?.accountNumber || "",
        routingnumber: card?.bankAchRoutingCode || "",
        maskedAccountNumber: maskedAccountNumber || "",
        maskedRoutingNumber: maskedRoutingNumber || "",
        accountType: card?.accountType === 76001 ? "checking" : "savings" || "",
        stateList: this.state.stateList ? this.state.stateList : [],
        cityList: this.state.cityList ? this.state.cityList : [],
        termsconditions: true,
        userdata: true,
        billingAddress: {
          billingAddressId: billAddress?.billingAddressId,
          email: billAddress?.email,
          phoneNo: billAddress?.phoneNo && billAddress?.phoneNo.replace(/[^\d]/g, ""),
          street: billAddress?.street,
          street2: billAddress?.street2,
          country: billAddress?.country || "United States of America",
          countryId: billAddress?.country,
          state: billAddress?.state,
          stateId: billAddress?.stateId,
          city: billAddress?.city,
          cityId: billAddress?.city,
          postalCode:
            billAddress?.postalCode.length > 5 && !billAddress?.postalCode.includes("-")
              ? billAddress?.postalCode.substring(0, 5).concat("-") +
                billAddress?.postalCode.substring(5, billAddress?.postalCode.length)
              : billAddress?.postalCode,
          firstName: billAddress?.firstName,
          middleName: billAddress?.middleName || "",
          lastName: billAddress?.lastName,
          paymentInstrumentId: billAddress?.paymentInstrument?.paymentInstrumentId,
        },
      });
    } else if (payeruserdata) {
      this.setState({
        ...this.state,
        billingAddress: {
          ...this.state.billingAddress,
          billingAddressId: 0,
          email: payeruserdata.contactEmail,
          phoneNo: payeruserdata?.contactNo?.replace(/[^\d]/g, ""),
          street: payeruserdata?.addressVo?.street,
          street2: payeruserdata?.addressVo?.street2,
          country: "United States of America",
          state: payeruserdata?.addressVo?.state,
          stateId: payeruserdata?.addressVo?.stateId,
          city: payeruserdata?.addressVo?.city,
          postalCode:
            payeruserdata?.addressVo?.postalCode?.length > 5 && !payeruserdata?.addressVo?.postalCode?.includes("-")
              ? payeruserdata?.addressVo?.postalCode?.substring(0, 5).concat("-") +
                payeruserdata?.addressVo?.postalCode?.substring(5, payeruserdata?.addressVo?.postalCode?.length)
              : payeruserdata?.addressVo?.postalCode,
          firstName: payeruserdata.firstName,
          lastName: payeruserdata.lastName,
          middleName: payeruserdata.middleName,
        },
      });
    }
  }
  updateUserSignature(nextState, values) {
    this.setState((prevState) => ({
      ...prevState,
      italicSign: nextState?.italicSign,
      signature: nextState?.signature,
      agreeToEmailUsage: prevState?.agreeToEmailUsage,
      agreeToterms: prevState?.agreeToterms,
      authorizeElectronicDebit: prevState?.authorizeElectronicDebit,
    }));
  }
  handleCardNumber = () => {
    this.setState({ showAnumber: !this.state.showAnumber });
  };

  handleRoutingNumber = () => {
    this.setState({ showRnumber: !this.state.showRnumber });
  };

  handleAccountNumber = () => {
    this.setState({ accountNumber: !this.state.accountNumber });
  };

  handleReAccountNumber = () => {
    this.setState({ showreAccountNumber: !this.state.showreAccountNumber });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  toggleShowAnumber = () => {
    this.setState({ showAnumber: !this.state.showAnumber });
  };
  schema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters,dash(-),dot(.),tilled and apostrophes(') are allowed")
      .required("First Name required")
      .nullable()
      .max(20, "Max 20 characters allowed"),
    futureDate: Yup.date().required("future date is required").nullable(),
    middleName: Yup.string()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters,dash(-),dot(.),tilled and apostrophes(') are allowed")

      .nullable()
      .max(20, "Max 20 characters allowed"),
    lastName: Yup.string()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters,dash(-),dot(.),tilled and apostrophes(') are allowed")
      .required("Last Name required")
      .nullable()
      .max(20, "Max 20 characters allowed"),
    routingnumber: Yup.string().min(9, "Invalid Routing Number").required("Routing Number is required"),
    accountType: Yup.string().required("Account Type is required"),
    accountnumber: Yup.string().required("Account Number is required").min(6, "Invalid Account Number"),
    reenteraccountNumber: Yup.string()
      .required("Re-enter Account Number is required")
      .oneOf([Yup.ref("accountnumber"), null], "Account Number must match"),
    emailAddress: Yup.string()
      .when("accountnumber", (accountnumber, field) => {
        return sessionStorage.getItem("currentUserRole") && sessionStorage.getItem("currentUserRole") !== "Admin"
          ? field.required("Email Address Required")
          : field.email("Email Address must be valid");
      })
      .nullable(),
    phoneNumber: Yup.string()
      .min(10, "Please enter 10 digits Phone Number")
      .max(10)
      .required("Phone Number required")
      .nullable(),
    address1: Yup.string().required("Billing Address is required").max(255, "Max 255 characters allowed").nullable(),
    address2: Yup.string().nullable().max(255, "Max 255 characters allowed"),
    country: Yup.string().required("Country required").nullable(),
    state: Yup.string().required("State is required").nullable(),
    city: Yup.string()
      .nullable()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters, dash(-), dot(.), tilled and apostrophes(') are allowed")
      .required("City is required"),
    zipCode: Yup.string()
      .nullable()
      .trim()
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/g, "Please enter a valid ZIP Code")
      .test("Invalid ZIP Code", (value) => !/^([0-9])\1{4,}$/.test(value))
      .required("ZIP Code is  required"),
    agreeToterms:
      sessionStorage.getItem("currentUserRole") !== "Admin" &&
      Yup.boolean().oneOf([true], "You must agree to the Online Payment Terms and Conditions"),
    authorizeElectronicDebit:
      sessionStorage.getItem("currentUserRole") !== "Admin" &&
      Yup.boolean().oneOf([true], "You must agree to the Online Payment Terms and Conditions"),
    agreeToEmailUsage:
      sessionStorage.getItem("currentUserRole") !== "Admin" &&
      Yup.boolean().oneOf([true], "You must agree to the Online Payment Terms and Conditions"),

    italicSign: Yup.string(),
    signature: Yup.string(),
    italicorsignature: Yup.bool().when(["italicSign", "signature"], {
      is: (italicSign, signature) => !(italicSign || signature),
      then: Yup.bool().required("Signature is required"),

      otherwise: Yup.bool(),
    }),
  });
  _paymentConfirmationValidationForExisting = Yup.object().shape({
    agreeToterms:
      sessionStorage.getItem("currentUserRole") !== "Admin" &&
      Yup.boolean().oneOf([true], "You must agree to the Online Payment Terms and Conditions"),
    authorizeElectronicDebit:
      sessionStorage.getItem("currentUserRole") !== "Admin" &&
      Yup.boolean().oneOf([true], "You must agree to the Online Payment Terms and Conditions"),
    agreeToEmailUsage:
      sessionStorage.getItem("currentUserRole") !== "Admin" &&
      Yup.boolean().oneOf([true], "You must agree to the Online Payment Terms and Conditions"),
  });
  _savePaymentPlan = (frequencyType, ppValues, seedAccountNumber, instrumentId, partyId) => {
    this.setState({ showloader: true });
    let PassValues1 = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      recurringPayment: {
        // Id must be 0 for new one
        reccuringPaymentId: 0,
        recurringPaymentType: frequencyType,
        recurringAmmount: ppValues?.paymentAmount,
        noOfPayments: ppValues?.noOfInstallments,
        startDate: moment(ppValues?.installmentStartDate).format("MM/DD/YYYY"),
        paymentInstrumentId: instrumentId,
        masterAccountReferenceNumber: seedAccountNumber,
        payerPartyId: partyId,
        isOverride: this.props.location?.state?.fromOverride ? true : false,
        reminderSms: ppValues?.reminderSms && ppValues?.reminderSms === true ? 1 : 0,
        reminderEmail: ppValues?.reminderEmail && ppValues?.reminderEmail === true ? 1 : 0,
        paymentSms: ppValues?.paymentSms && ppValues?.paymentSms === true ? 1 : 0,
        paymentEmail: ppValues?.paymentEmail && ppValues?.paymentEmail === true ? 1 : 0,
      },
    };
    let PassValues2 = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      recurringPayment: {
        // Id must be 0 for new one
        reccuringPaymentId: ppValues?.reccuringPaymentId || 0,
        recurringPaymentType: frequencyType,
        recurringAmmount: ppValues?.paymentAmount,
        noOfPayments: ppValues?.noOfInstallments,
        startDate: moment(ppValues?.installmentStartDate).format("MM/DD/YYYY"),
        paymentInstrumentId: instrumentId,
        masterAccountReferenceNumber: seedAccountNumber,
        payerPartyId: partyId,
        isOverride: this.props.location?.state?.fromOverride ? true : false,
        reminderSms: ppValues?.reminderSms && ppValues?.reminderSms === true ? 1 : 0,
        reminderEmail: ppValues?.reminderEmail && ppValues?.reminderEmail === true ? 1 : 0,
        paymentSms: ppValues?.paymentSms && ppValues?.paymentSms === true ? 1 : 0,
        paymentEmail: ppValues?.paymentEmail && ppValues?.paymentEmail === true ? 1 : 0,
      },
    };

    if (!ppValues?.reccuringPaymentId) {
      payplanService.savePayPlan(
        PassValues1,
        1234,
        (res) => {
          if (res?.data?.status === "Success") {
            handleSuccess("Payment Arrangement  Scheduled Successfully");
            setTimeout(() => {
              this.setState({ showloader: false });
              this.props.history.push({
                pathname:
                  sessionStorage?.getItem("currentUserRole") === "Admin"
                    ? "/pa/PayPlanOptionsSuccess"
                    : "/home/PayPlanOptionsSuccess",
                search: `?${this.state.seedAccNum}`,
                state: {
                  data: res.data,
                  seedAccNum: this.state.seedAccNum,
                  page: this.state.page,
                },
              });
            }, 2000);
          } else {
            this.setState({ showloader: false });
            handleError("Can't save Payment Arrangement  details");
          }
        },
        (error) => {
          handleError("Something went wrong! Please try again");
          this.setState({ showloader: false });
        },
      );
    } else {
      payplanService.editPayPlan(
        PassValues2,
        1234,
        (res) => {
          if (res?.data?.status === "Success") {
            this.props.history.push({
              pathname:
                sessionStorage?.getItem("currentUserRole") === "Admin"
                  ? "/pa/PayPlanOptionsSuccess"
                  : "/home/PayPlanOptionsSuccess",
              search: `?${this.state.seedAccNum}`,
              state: {
                data: res.data,
                seedAccNum: this.state.seedAccNum,
                page: this.state.page,
              },
            });
            this.setState({ showloader: false });
            handleSuccess("Payment Arrangement Scheduled Successfully");
          } else {
            this.setState({ showloader: false });
            handleError("Can't save Payment Arrangement  details");
          }
        },
        (error) => {
          handleError("Something went wrong! Please try again");
          this.setState({ showloader: false });
        },
      );
    }
  };
  _handlePaymentPlanOrder = (orderId, instrumentId, tab) => {
    if (orderId) {
      payplanService.updateSingleOrder(
        requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        orderId,
        instrumentId,
        1234,
        (res) => {
          if (res.status === 200 && res.data.statusCodeValue === 200) {
            handleSuccess(`Payment Method Updated Successfully with Order Id ${orderId}`);
            setTimeout(() => {
              if (tab === "PaymentPlan") {
                this.props.history.push({
                  pathname:
                    sessionStorage.getItem("currentUserRole") === "Admin"
                      ? "/pa/payment-plan-details"
                      : "/home/payment-plan-details",
                  search: `${this.state.plan}`,
                });
              } else if (tab === "FuturePayment") {
                this.props.history.push({
                  pathname:
                    sessionStorage.getItem("currentUserRole") === "Admin" ? "/pa/payment-history" : "/home/payment-history",
                  state: { from: "paymentPlanDetails" },
                });
              }
            }, 2000);
          } else {
            handleWarning("Something went wrong!");
          }
        },
        (error) => {
          handleError("Something went wrong! Please try again");
          this.setState({ showloader: false });
        },
      );
    } else {
      payplanService.updatePaymentPlan(
        requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        this.state.plan,
        instrumentId,
        1234,
        (res) => {
          if (res.status === 200 && res.data.statusCodeValue === 200) {
            handleSuccess(`Payment Method Updated Successfully for plan ${this.state.plan}`);
            this.props.history.push({
              pathname:
                sessionStorage.getItem("currentUserRole") === "Admin"
                  ? "/pa/payment-plan-details"
                  : "/home/payment-plan-details",
              search: `${this.state.plan}`,
            });
          } else {
            handleWarning("Something went wrong!");
          }
        },
        (error) => {
          handleError("Something went wrong! Please try again");
          this.setState({ showloader: false });
        },
      );
    }
  };
  _handleSubmit = (values) => {
    this.setState({ showloader: true });
    const partyids = JSON.parse(sessionStorage.getItem("verifypayer"));
    let partyid = partyids.partyId;
    if (this.state.paymentInstrumentId) {
      if (this.state.page === "PaymentPlan") {
        this._handlePaymentPlanOrder(this.state.orderId, this.state.paymentInstrumentId, "PaymentPlan");
      } else if (this.state.page === "FuturePayment") {
        this._handlePaymentPlanOrder(this.state.orderId, this.state.paymentInstrumentId, "FuturePayment");
      } else {
        this._savePaymentPlan(
          this.state?.frequencyType,
          this.state?.values,
          this.state.seedAccNum,
          this.state.paymentInstrumentId,
          partyid,
        );
      }
    } else {
      let modifieddata = new Date();
      let dateString =
        modifieddata.getUTCFullYear() +
        "/" +
        (modifieddata.getUTCMonth() + 1) +
        "/" +
        modifieddata.getUTCDate() +
        " " +
        modifieddata.getUTCHours() +
        ":" +
        modifieddata.getUTCMinutes() +
        ":" +
        modifieddata.getUTCSeconds();

      const partyids = JSON.parse(sessionStorage.getItem("verifypayer"));
      let partyid = partyids.partyId;

      const PassValues = {
        requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        paymentInfos: [
          {
            achAccount: [
              {
                achAccountId: this.state.editECheckData?.achAccountId || 0,
                accountNumber: values.accountnumber,
                payerPartyId: partyid,
                accountName: "",
                bankAchRoutingCode: values.routingnumber,
                accountType: values.accountType === "savings" ? 76002 : 76001,
                paymentMethodId: 75003,
                isActive: 1,
                signature: values.signature,
                italicSign: values.italicSign,
                booleanItalicSign: values.italicSign ? true : false,
                paymentInstrument: {
                  paymentInstrumentId: this.state.editECheckData?.paymentInstrument?.paymentInstrumentId || 0,
                  linkedPaymentMethodEntityId:
                    this.state.editECheckData?.paymentInstrument?.linkedPaymentMethodEntityId || 0,
                  payerPartyId: partyid,
                  paymentMethodId: 75003,
                  isDefault: null,
                  isExpired: null,
                  isBlocked: null,
                  isBlacklisted: null,
                  lastUsageDatetime: dateString,
                },
                billingAddress: {
                  billingAddressId: this.state?.billingAddress?.billingAddressId || 0,
                  firstName: values.firstName,
                  middleName: values.middleName,
                  lastName: values.lastName,
                  email: values.emailAddress,
                  phoneNo: values.phoneNumber,
                  street: values.address1,
                  street2: "",
                  city: values.city,
                  state: values.state,
                  country: values.country,
                  postalCode: values.zipCode,
                },
              },
            ],
            cardAccount: null,
            bankAccount: null,
            walletAccount: null,
            virtualAccount: null,
          },
        ],
      };
      echequePaymentService.saveEchequePayment(PassValues, 1212, (res) => {
        if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
          if (this.state.page === "PaymentPlan") {
            this._handlePaymentPlanOrder(
              this.state.orderId,
              res?.data?.paymentInfos[0]?.achAccount[0]?.paymentInstrument?.paymentInstrumentId,
              "PaymentPlan",
            );
          } else if (this.state.page === "FuturePayment") {
            this._handlePaymentPlanOrder(
              this.state.orderId,
              res?.data?.paymentInfos[0]?.achAccount[0]?.paymentInstrument?.paymentInstrumentId,
              "FuturePayment",
            );
          } else if (this.state.page === "paymentMethod") {
            handleSuccess("Card Added Successfully");
            sessionStorage?.getItem("currentUserRole") === "Admin"
              ? this.props.history.push("/pa/payment-methods")
              : this.props.history.push("/home/payment-methods");
          } else {
            this._savePaymentPlan(
              this.state?.frequencyType,
              this.state?.values,
              this.state.seedAccNum,
              res?.data?.paymentInfos[0]?.achAccount[0]?.paymentInstrument?.paymentInstrumentId,
              partyid,
            );
          }
          // may required
          // if (this.state.paymentOptions) {
          //   this._savePaymentPlan(
          //     this.state?.frequencyType,
          //     this.state?.values,
          //     this.state.seedAccNum,
          //     res?.data?.paymentInfos[0]?.achAccount[0]?.paymentInstrument?.paymentInstrumentId,
          //     partyid,
          //   );
          // } else {
          //   handleSuccess("eCheck Successfully Added");
          //   setTimeout(() => {
          //     sessionStorage.getItem("currentUserRole") === "Admin"
          //       ? this.props.history.push("/pa/payment-methods")
          //       : this.props.history.push("/home/payment-methods");
          //   }, 2000);
          // }
        } else {
          this.setState({ showloader: false });
          handleError("Can't save eCheck details");
        }
      });
    }
  };
  toggleSidenavClass = (bool) => {
    this.setState({
      sideNavToggleClass: bool,
    });
  };
  getAddressObject = (address_components) => {
    const ShouldBeComponent = {
      street_number: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      province: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      country: ["country"],
    };

    let address = {
      street_number: "",
      postal_code: "",
      street: "",
      province: "",
      city: "",
      country: "",
    };

    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "country") {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });
    // Fix the shape to match our schema
    address.address = address?.street_number + " " + address?.street;
    delete address.street_number;
    delete address.street;
    if (address.country === "US") {
      address.state = address.province;
      delete address.province;
    }
    return address;
  };
  getGoogleApiInfo = async (values, val) => {
    const geocodeObj = val && val.value && (await geocodeByPlaceId(val.value.place_id));
    const addressObject = geocodeObj && this.getAddressObject(geocodeObj[0].address_components);
    addressObject
      ? this.setState({
          ...values,
          country:
            addressObject?.country.toLocaleLowerCase() === "us" ||
            addressObject.country.toLocaleLowerCase() === "usa" ||
            addressObject === null
              ? "United States of America"
              : addressObject.country,
          state: addressObject?.state,
          city: addressObject?.city,
          zipCode: addressObject?.postal_code,
          address1: addressObject?.address.trim(),
          address2: "",
          googleaddress: val,
        })
      : this.setState({
          ...values,
          googleaddress: "clear",
        });
  };
  render() {
    const billing = this.state.billingAddress;
    return (
      <>
        <div className="addcard-container">
          <div
            className={
              this.state.sideNavToggleClass
                ? `transaction-card echeck-credit-container`
                : `transaction-card echeck-credit-container-collapse echeck-credit-container`
            }>
            <div className="change-password" role="main">
              <div
                className={
                  sessionStorage.getItem("currentUserRole") === "Customer"
                    ? `dashboard-content-card main-container-sm`
                    : `dashboard-content-card`
                }>
                <Formik
                  enableReinitialize
                  initialValues={{
                    accountnumber: this.state.accountNumber || "",
                    reenteraccountNumber: this.state.reenteraccountNumber || "",
                    routingnumber: this.state.routingnumber || "",
                    accountType: this.state.accountType,
                    stateList: stateList ? stateList : [],
                    cityList: this.state.cityList || [],
                    emailAddress: this.state.email || billing.email,
                    phoneNumber: this.state.phoneNumber || billing.phoneNo,
                    address1: this.state.address1 || billing.street,
                    address2: this.state.address2 || billing.street2,
                    country: this.state.country || billing.country,
                    countryid: this.state.countryid ? this.state.countryid : "",
                    state: this.state.state || billing.state,
                    stateid: this.state.stateid ? this.state.stateid : "",
                    city: this.state.city || billing.city,
                    cityid: this.state.cityid ? this.state.cityid : "",
                    zipCode: this.state.zipCode || billing.postalCode,
                    termsconditions: true,
                    userdata: true,
                    authorizeElectronicDebit: this.state.authorizeElectronicDebit,
                    agreeToEmailUsage: this.state.agreeToEmailUsage,
                    agreeToterms: this.state.agreeToterms,
                    firstName: this.state.firstName ? this.state.firstName : billing.firstName,
                    lastName: this.state.lastName || billing.lastName,
                    middleName: this.state.middleName || billing.middleName,
                    italicSign: this.state.italicSign || "",
                    signature: this.state.signature || "",
                    isItalicSign: false,
                    showRoutingNumber: false,
                    showAccountNumber1: false,
                    showAccountNumber2: false,
                    maskedAccountNumber: this.state.maskedAccountNumber,
                    maskedRoutingNumber: this.state.maskedRoutingNumber,
                    futureDate: new Date(),
                    paymentInstrument: {
                      paymentInstrumentId: this.state.paymentInstrumentId || 0,
                    },
                    countrycode: "+1",
                  }}
                  onSubmit={this._handleSubmit}
                  validationSchema={
                    this.state.paymentInstrumentId ? this._paymentConfirmationValidationForExisting : this.schema
                  }>
                  {(props) => {
                    const { values, touched, error, handleChange, errors, setFieldValue, handleBlur } = props;

                    return (
                      <Form className="input-field" noValidate="noValidate">
                        <div>
                          <div className="e-check-bg-card e-check-bg">
                            <div className="echeck-card">
                              <div role="heading" aria-level="1" className="heading-style mb-3">
                                <h2 className="content-title heading-style echeck-header mb-0 ">
                                  {this.state.editECheckData ? "Edit eCheck" : "eCheck Payment"}
                                </h2>
                              </div>

                              <div>
                                {this.props.futurePayment && (
                                  <>
                                    <div className="col-md-6 form-group">
                                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Grid container className="date-picker">
                                          <label htmlFor="futureDate" className="form-label required">
                                            Future Date
                                          </label>
                                          <DatePicker
                                            disableFuture
                                            name="futureDate"
                                            autoOk
                                            value={values.futureDate}
                                            onChange={(val) => setFieldValue("futureDate", val)}
                                            renderInput={(params) => (
                                              <TextField {...params} helperText={null} margin="dense" />
                                            )}
                                          />
                                        </Grid>
                                      </LocalizationProvider>
                                      <FormHelperText error name="securitycode">
                                        {touched.futureDate && errors.futureDate ? <div>{errors.futureDate}</div> : null}
                                      </FormHelperText>
                                    </div>
                                  </>
                                )}
                                {this.state.editECheckData ? (
                                  <>
                                    <div className="cardpersonal-details my-3">
                                      <div className="form-row">
                                        <div className="col-md-4 col-sm-6 form-group">
                                          <div className="edit-card-details">
                                            <div className="label ">
                                              <span>First Name</span> :
                                            </div>
                                            <div className="value">
                                              {values.firstName
                                                ? values.firstName?.charAt(0).toUpperCase() + values.firstName?.slice(1)
                                                : "---"}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 form-group">
                                          <div className="edit-card-details">
                                            <div className="label ">
                                              <span>Middle Name</span> :
                                            </div>
                                            <div className="value">
                                              {values.middleName
                                                ? values.middleName?.charAt(0).toUpperCase() + values.middleName?.slice(1)
                                                : "---"}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 form-group">
                                          <div className="edit-card-details">
                                            <div className="label ">
                                              <span>Last Name</span> :
                                            </div>
                                            <div className="value">
                                              {values.lastName
                                                ? values.lastName?.charAt(0).toUpperCase() + values.lastName?.slice(1)
                                                : "---"}
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-4 col-sm-6 form-group">
                                          <div className="edit-card-details">
                                            <div className="label ">
                                              <span>Routing Number</span> :
                                            </div>
                                            <div className="value">
                                              {values.maskedRoutingNumber || values.routingnumber
                                                ? values.maskedRoutingNumber || values.routingnumber
                                                : "---"}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 form-group">
                                          <div className="edit-card-details">
                                            <div className="label ">
                                              <span>Account Type</span> :
                                            </div>
                                            <div className="value">
                                              {values.accountType
                                                ? values.accountType.charAt(0).toUpperCase() + values.accountType?.slice(1)
                                                : "---"}
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-4 col-sm-6 form-group">
                                          <div className="edit-card-details">
                                            <div className="label ">
                                              <span>Account Number</span> :
                                            </div>
                                            <div className="value">
                                              {values.maskedAccountNumber || values.accountNumber
                                                ? values.maskedAccountNumber || values.accountNumber
                                                : "---"}
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-8 col-sm-6 form-group">
                                          <div className="edit-card-details">
                                            <div className="label ">
                                              <span>Email Address</span> :
                                            </div>
                                            <div className="value">{values.emailAddress ? values.emailAddress : "---"}</div>
                                          </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 form-group">
                                          <div className="edit-card-details">
                                            <div className="label ">
                                              <span>Phone Number</span> :
                                            </div>
                                            <div className="value">{values.phoneNumber ? values.phoneNumber : "---"}</div>
                                          </div>
                                        </div>

                                        <div className="col-md-12  billing-details">
                                          <div className="edit-card-details">
                                            <div className="label ">
                                              <span>Billing Address</span> :
                                            </div>
                                            <div className="value">
                                              {values.address1
                                                ? `${values.address1.charAt(0).toUpperCase() + values.address1?.slice(1)},${
                                                    values.city.charAt(0).toUpperCase() + values.city?.slice(1)
                                                  },${values.state.charAt(0).toUpperCase() + values.state?.slice(1)},${
                                                    values.country.charAt(0).toUpperCase() + values.country?.slice(1)
                                                  },${values.zipCode}`.replace(/,/g, ", ")
                                                : "---"}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="form-row  debitcard">
                                      <div className="col-md-4 col-sm-6 form-group">
                                        <FormControl variant="outlined" className="w-100" fullWidth>
                                          <FormLabel htmlFor="firstName" className="required">
                                            First Name
                                          </FormLabel>
                                          <OutlinedInput
                                            className="form-control"
                                            name="firstName"
                                            value={values?.firstName.charAt(0).toUpperCase() + values?.firstName.slice(1)}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Enter First Name"
                                            aria-label="text-field"
                                            fullWidth
                                            required></OutlinedInput>
                                        </FormControl>
                                        <FormHelperText error name="firstName">
                                          {touched.firstName && errors.firstName ? <div>{errors.firstName}</div> : null}
                                        </FormHelperText>
                                      </div>
                                      <div className="col-md-4 col-sm-6 form-group">
                                        <FormControl variant="outlined" className="w-100" fullWidth>
                                          <FormLabel htmlFor="middleName">Middle Name</FormLabel>
                                          <OutlinedInput
                                            className="form-control"
                                            name="middleName"
                                            value={values.middleName}
                                            onChange={handleChange}
                                            placeholder="Enter Middle Name"
                                            aria-label="text-field"
                                            fullWidth></OutlinedInput>
                                        </FormControl>
                                      </div>
                                      <div className="col-md-4 col-sm-6 form-group">
                                        <FormControl variant="outlined" className="w-100" fullWidth>
                                          <FormLabel htmlFor="lastName" className="required">
                                            Last Name
                                          </FormLabel>
                                          <OutlinedInput
                                            className="form-control"
                                            name="lastName"
                                            value={values?.lastName.charAt(0).toUpperCase() + values?.lastName.slice(1)}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Enter Last Name"
                                            aria-label="text-field"
                                            fullWidth
                                            required></OutlinedInput>
                                        </FormControl>
                                        <FormHelperText error>
                                          {touched.lastName && errors.lastName ? <div>{errors.lastName}</div> : null}
                                        </FormHelperText>
                                      </div>
                                      <div className="col-md-6 col-sm-6 form-group ">
                                        <FormControl variant="outlined" className="w-100" fullWidth>
                                          <div className="form-row mx-0 justify-content-between align-items-center">
                                            <FormLabel htmlFor="routingNumber" className="required">
                                              Routing Number
                                            </FormLabel>
                                            <ImageTooltip img={echeckPaymentRoutingNumber} width="350px" placement="right">
                                              <i
                                                className="fas fa-question-circle circle-icon"
                                                style={{ cursor: "pointer" }}></i>
                                            </ImageTooltip>
                                          </div>
                                          <OutlinedInput
                                            className="form-control signup-fields"
                                            name="routingnumber"
                                            value={values.maskedRoutingNumber || this.state.routingnumber}
                                            maxLength="9"
                                            onChange={(e) => {
                                              this.setState({ routingnumber: e.target.value });
                                            }}
                                            onBlur={handleBlur}
                                            onKeyPress={(event) => {
                                              if (!/\d/.test(event.key) || values?.routingnumber?.length >= 9) {
                                                event.preventDefault();
                                              }
                                            }}
                                            disabled={this.state.editECheckData}
                                            type={
                                              this.state.editECheckData
                                                ? "text"
                                                : this.state.showRnumber
                                                ? "password"
                                                : "text"
                                            }
                                            readOnly={this.state.editECheckData ? "read only" : ""}
                                            placeholder="Enter Routing Number"
                                            aria-label="text-field"
                                            fullWidth
                                            required
                                            endAdornment={
                                              <InputAdornment position="end">
                                                {!this.state.editECheckData ? (
                                                  <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleRoutingNumber}
                                                    onMouseDown={this.handleMouseDownPassword}>
                                                    {this.state.showRnumber ? <VisibilityOff /> : <Visibility />}
                                                  </IconButton>
                                                ) : (
                                                  " "
                                                )}
                                              </InputAdornment>
                                            }></OutlinedInput>
                                        </FormControl>
                                        <FormHelperText error>
                                          {touched.routingnumber && errors.routingnumber ? (
                                            <div>{errors.routingnumber}</div>
                                          ) : null}
                                        </FormHelperText>
                                      </div>
                                      <div className="col-md-6 col-sm-6 form-group">
                                        <FormControl variant="outlined" className="w-100" fullWidth>
                                          <div className="form-row mx-0 justify-content-between align-items-center">
                                            <FormLabel htmlFor="accountType" className="form-label required">
                                              Account Type
                                            </FormLabel>
                                          </div>
                                          <Select
                                            fullWidth
                                            name="accountType"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.accountType}
                                            className="form-select form-control">
                                            <MenuItem value="none" disabled>
                                              Select Account Type
                                            </MenuItem>
                                            <MenuItem value="savings">Savings</MenuItem>
                                            <MenuItem value="checking">Checking</MenuItem>
                                          </Select>
                                        </FormControl>
                                        <FormHelperText error>
                                          {touched.accountType && errors.accountType ? (
                                            <div>{errors.accountType}</div>
                                          ) : null}
                                        </FormHelperText>
                                      </div>
                                      <div className="col-md-6 col-sm-6 form-group ">
                                        <FormControl variant="outlined" className="w-100" fullWidth>
                                          <div className="form-row mx-0 justify-content-between align-items-center">
                                            <FormLabel htmlFor="accountnumber" className="required">
                                              Account Number
                                            </FormLabel>

                                            <ImageTooltip img={echeckPaymentRoutingNumber} width="350px" placement="right">
                                              <i
                                                className="fas fa-question-circle circle-icon"
                                                style={{ cursor: "pointer" }}></i>
                                            </ImageTooltip>
                                          </div>
                                          <OutlinedInput
                                            className="form-control signup-fields"
                                            name="accountnumber"
                                            maxLength="20"
                                            value={this.state.accountNumber}
                                            onChange={(e) => {
                                              this.setState({ accountNumber: e.target.value });
                                            }}
                                            onBlur={handleBlur}
                                            onKeyPress={(event) => {
                                              if (!/\d/.test(event.key) || values?.accountnumber?.length >= 20) {
                                                event.preventDefault();
                                              }
                                            }}
                                            disabled={this.state.editECheckData}
                                            placeholder="Enter Account Number"
                                            aria-label="text-field"
                                            fullWidth
                                            type={
                                              this.state.editECheckData
                                                ? "text"
                                                : this.state.showAnumber
                                                ? "password"
                                                : "number"
                                            }
                                            required
                                            endAdornment={
                                              <InputAdornment position="end">
                                                {!this.state.editECheckData ? (
                                                  <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.toggleShowAnumber}
                                                    onMouseDown={this.handleMouseDownPassword}>
                                                    {this.state.showAnumber ? <VisibilityOff /> : <Visibility />}
                                                  </IconButton>
                                                ) : (
                                                  ""
                                                )}
                                              </InputAdornment>
                                            }></OutlinedInput>
                                        </FormControl>
                                        <FormHelperText error>
                                          {touched.accountnumber && errors.accountnumber ? (
                                            <div>{errors.accountnumber}</div>
                                          ) : null}
                                        </FormHelperText>
                                      </div>

                                      <div className="col-md-6 col-sm-6 form-group">
                                        <FormControl variant="outlined" className="w-100" fullWidth>
                                          <div className="form-row mx-0 justify-content-between align-items-center">
                                            <FormLabel htmlFor="reAccountNumber" className="required">
                                              Re-enter Account Number
                                            </FormLabel>
                                          </div>
                                          <OutlinedInput
                                            className="form-control signup-fields"
                                            name="reenteraccountNumber"
                                            value={this.state.reenteraccountNumber}
                                            disabled={this.state.editECheckData}
                                            onChange={(e) => {
                                              this.setState({ reenteraccountNumber: e.target.value });
                                            }}
                                            onBlur={handleBlur}
                                            onKeyPress={(event) => {
                                              if (!/\d/.test(event.key) || values?.reenteraccountNumber?.length >= 20) {
                                                event.preventDefault();
                                              }
                                            }}
                                            type={
                                              this.state.editECheckData
                                                ? "text"
                                                : this.state.showreAccountNumber
                                                ? "password"
                                                : "text"
                                            }
                                            placeholder="Re-enter Account Number"
                                            aria-label="text-field"
                                            fullWidth
                                            required
                                            endAdornment={
                                              <InputAdornment position="end">
                                                {!this.state.editECheckData ? (
                                                  <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleReAccountNumber}
                                                    onMouseDown={this.handleMouseDownPassword}>
                                                    {this.state.showreAccountNumber ? <VisibilityOff /> : <Visibility />}
                                                  </IconButton>
                                                ) : (
                                                  ""
                                                )}
                                              </InputAdornment>
                                            }></OutlinedInput>
                                        </FormControl>
                                        <FormHelperText error>
                                          {touched.reenteraccountNumber && errors.reenteraccountNumber ? (
                                            <div>{errors.reenteraccountNumber}</div>
                                          ) : null}
                                        </FormHelperText>
                                      </div>
                                    </div>
                                    <div className="form-row mx-0">
                                      <div className="col-md-6 col-sm-12 form-group billing-address-cp">
                                        <FormControl variant="outlined" className="w-100" fullWidth>
                                          <FormLabel htmlFor="billingAddress" className="required">
                                            Billing Address
                                          </FormLabel>
                                          <GooglePlacesAutocomplete
                                            apiKey="AIzaSyAymCr1DkF6p_ULBgBIZIM2VNu9pg-zrN0"
                                            autocompletionRequest={{
                                              componentRestrictions: {
                                                country: ["us"],
                                              },
                                            }}
                                            selectProps={{
                                              isClearable: true,
                                              value: this.state.googleaddress,
                                              onChange: (val) => {
                                                this.getGoogleApiInfo({ ...values }, val);
                                              },
                                            }}
                                          />
                                        </FormControl>
                                      </div>
                                      <div className="col-md-6 col-sm-12 form-group">
                                        <div className="label-spacing mb-md-3">
                                          <FormLabel></FormLabel>
                                        </div>
                                        <FormControl variant="outlined" className="w-100 mt-md-1" fullWidth>
                                          {/* <FormLabel htmlFor="address1">&nbsp;</FormLabel> */}
                                          <TextField
                                            type="text"
                                            autocomplete="new-password"
                                            id="address1"
                                            name="address1"
                                            value={
                                              values?.address2 ? values?.address1 + "," + values?.address2 : values?.address1
                                            }
                                            placeholder="Please Enter Street Address"
                                            className={`text-field ${
                                              touched.address1 && errors.address1 ? "is-invalid" : ""
                                            }`}
                                            onChange={handleChange}
                                            onBlur={handleBlur}></TextField>
                                        </FormControl>
                                        <FormHelperText error name="securitycode">
                                          {touched.address1 && errors.address1 ? <div>{errors.address1}</div> : null}
                                        </FormHelperText>
                                      </div>

                                      <div className="col-md-3 col-sm-6 form-group">
                                        <FormControl variant="outlined" className="w-100" fullWidth>
                                          <FormLabel htmlFor="country" className="form-label required">
                                            Country
                                          </FormLabel>
                                          <Select
                                            fullWidth
                                            name="country"
                                            value={values.country}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="form-select form-control">
                                            {countryData.map((option) => {
                                              return (
                                                <MenuItem value={option.value} key={option.key}>
                                                  {option.value}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                        <FormHelperText error>
                                          {touched.country && errors.country ? <div>{errors.country}</div> : null}
                                        </FormHelperText>
                                      </div>
                                      <div className="col-md-3 col-sm-6 form-group">
                                        <FormControl variant="outlined" className="w-100" fullWidth>
                                          <FormLabel htmlFor="state" className="form-label required">
                                            State/County
                                          </FormLabel>
                                          <Select
                                            fullWidth
                                            name="state"
                                            value={values?.state?.toLowerCase() || "none"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="form-select form-control">
                                            <MenuItem value="none" disabled>
                                              Select Country
                                            </MenuItem>
                                            {stateList.map((option) => {
                                              return (
                                                <MenuItem value={option.stateName} key={option.stateId}>
                                                  {option.stateName}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                        <FormHelperText error>
                                          {touched.state && errors.state ? <div>{errors.state}</div> : null}
                                        </FormHelperText>
                                      </div>
                                      <div className="col-md-3 col-sm-6 form-group">
                                        <FormControl variant="outlined" className="w-100" fullWidth>
                                          <FormLabel htmlFor="city" className="form-label required">
                                            City
                                          </FormLabel>
                                          <OutlinedInput
                                            className="form-control"
                                            name="city"
                                            value={values.city}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Enter City"
                                            aria-label="text-field"
                                            fullWidth
                                            required></OutlinedInput>
                                        </FormControl>
                                        <FormHelperText error>
                                          {touched.city && errors.city ? <div>{errors.city}</div> : null}
                                        </FormHelperText>
                                      </div>
                                      <div className="col-md-3 col-sm-6 form-group">
                                        <FormControl variant="outlined" className="w-100" fullWidth>
                                          <FormLabel htmlFor="zipCode" className="required">
                                            ZIP Code
                                          </FormLabel>
                                          <OutlinedInput
                                            className="form-control"
                                            name="zipCode"
                                            value={values.zipCode}
                                            onChange={(event) => {
                                              const zip = event.target.value;
                                              setFieldValue(
                                                (values.zipCode =
                                                  zip.length > 5 && !zip.includes("-")
                                                    ? zip.substring(0, 5).concat("-") + zip.substring(5, zip.length)
                                                    : zip),
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            inputProps={{
                                              maxLength: 10,
                                            }}
                                            placeholder="Enter ZIP Code"
                                            type="text"
                                            aria-label="text-field"
                                            fullWidth
                                            required></OutlinedInput>
                                        </FormControl>
                                        <FormHelperText error>
                                          {touched.zipCode && errors.zipCode ? <div>{errors.zipCode}</div> : null}
                                        </FormHelperText>
                                      </div>

                                      <div className="col-md-6 col-sm-6 form-group">
                                        <FormControl variant="outlined" className="w-100" fullWidth>
                                          <FormLabel
                                            htmlFor="emailAddress"
                                            className={
                                              sessionStorage?.getItem("currentUserRole") === "Admin" ? "" : "required"
                                            }>
                                            Email Address
                                          </FormLabel>
                                          <OutlinedInput
                                            className="form-control"
                                            name="emailAddress"
                                            value={values.emailAddress}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type="email"
                                            placeholder="Enter Email Address"
                                            aria-label="text-field"
                                            fullWidth
                                            required></OutlinedInput>
                                        </FormControl>
                                        <FormHelperText error>
                                          {touched.emailAddress && errors.emailAddress ? (
                                            <div>{errors.emailAddress}</div>
                                          ) : null}
                                        </FormHelperText>
                                      </div>

                                      <div className="col-md-6 col-sm-6 form-group">
                                        <FormControl variant="outlined" className="w-100" fullWidth>
                                          <FormLabel htmlFor="phoneNumber" className="required">
                                            Phone Number
                                          </FormLabel>
                                          <div className="row">
                                            <div className="col-2 pr-0 country-code">
                                              <Select
                                                name="countrycode"
                                                value={values.countrycode}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                className="form-select form-control">
                                                <MenuItem value={"+1"}>+1</MenuItem>
                                              </Select>
                                            </div>
                                            <div className="col-10 pl-1">
                                              <OutlinedInput
                                                className="form-control"
                                                name="phoneNumber"
                                                value={values.phoneNumber}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onKeyPress={(event) => {
                                                  if (!/\d/.test(event.key) || values.phoneNumber.length >= 10) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                placeholder="Enter Phone Number"
                                                aria-label="text-field"
                                                type="number"
                                                fullWidth
                                                required></OutlinedInput>
                                            </div>
                                          </div>
                                        </FormControl>
                                        <FormHelperText error>
                                          {touched.phoneNumber && errors.phoneNumber ? (
                                            <div>{errors.phoneNumber}</div>
                                          ) : null}
                                        </FormHelperText>
                                      </div>
                                    </div>
                                    <div>
                                      <Signature
                                        values={values}
                                        touched={touched}
                                        handleChange={handleChange}
                                        stateUpdate={() => {
                                          this.setState({
                                            ...this.state,
                                            ...values,
                                            email: values.emailAddress,
                                          });
                                        }}
                                        errors={errors}
                                        setFieldValue={setFieldValue}
                                        updateUserSignature={this.updateUserSignature}
                                      />
                                      <FormHelperText error>
                                        {touched.signature && errors.signature ? <div>{errors.signature}</div> : null}
                                      </FormHelperText>
                                    </div>
                                  </>
                                )}

                                <div className="agree-terms addcard-terms">
                                  <div className=" check-FormLabel authorization-input">
                                    <FormControl fullWidth component="fieldset" margin="normal">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name="agreeToterms"
                                            checked={values.agreeToterms}
                                            onChange={handleChange}
                                            value={values.agreeToterms}
                                            color="primary"
                                          />
                                        }
                                        label={
                                          <span>
                                            <TermsAndConditions />
                                            <FormHelperText error name="agreeToterms">
                                              {touched.agreeToterms && errors.agreeToterms ? (
                                                <div>{errors.agreeToterms}</div>
                                              ) : null}
                                            </FormHelperText>
                                          </span>
                                        }
                                      />
                                    </FormControl>
                                  </div>
                                  <div className="check-FormLabel authorization-input">
                                    <FormControl fullWidth component="fieldset" margin="normal">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name="agreeToEmailUsage"
                                            value={values.agreeToEmailUsage}
                                            checked={values.agreeToEmailUsage}
                                            onChange={handleChange}
                                            color="primary"
                                          />
                                        }
                                        label={
                                          <span>
                                            By checking this box,you agree that this is your personal email address and you
                                            authorize us to send you emails,call your phone, or contact you via an automated
                                            dialer,leave voicemails, and/or send text messages if necessary.
                                            <FormHelperText error name="agreeToEmailUsage">
                                              {touched.agreeToEmailUsage && errors.agreeToEmailUsage ? (
                                                <div>{errors.agreeToEmailUsage}</div>
                                              ) : null}
                                            </FormHelperText>
                                          </span>
                                        }
                                      />
                                    </FormControl>
                                  </div>
                                  <div className=" check-FormLabel authorization-input">
                                    <FormControl fullWidth component="fieldset" margin="normal">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name="authorizeElectronicDebit"
                                            checked={values.authorizeElectronicDebit}
                                            onChange={handleChange}
                                            value={values.authorizeElectronicDebit}
                                            color="primary"
                                          />
                                        }
                                        label={
                                          <span>
                                            I authorize MED-1 Solutions to electronically debit my checking/savings account
                                            for the amount and term indicated above. I understand that electronic debits will
                                            be returned if there are insufficient funds in the account at the time of the
                                            debit. I also understand that if my payment is returned to MED-1 Solutions as NSF
                                            for any reason by the bank that I authorize MED-1 Solutions to initiate an
                                            additional and separate fee in accordance with Oregon Revised Statutes §30.701
                                            for the returned check expenses. This authorization is to remain in full force
                                            and effect until MED-1 Solutions has received written notification from me of its
                                            termination in such manner as to afford MED-1 Solutions a reasonable opportunity
                                            to act on it (3 business days).
                                            <FormHelperText error name="authorizeElectronicDebit">
                                              {touched.authorizeElectronicDebit && errors.authorizeElectronicDebit ? (
                                                <div>{errors.authorizeElectronicDebit}</div>
                                              ) : null}
                                            </FormHelperText>
                                          </span>
                                        }
                                      />
                                    </FormControl>
                                  </div>
                                </div>
                                <div className="proceed-cancel-buttons">
                                  <button type="submit" className="primary-button themeing-buttons mr-1">
                                    {this.state.editECheckData ? "Save eCheck" : "Add eCheck"}
                                  </button>
                                  <button
                                    type="button"
                                    className="secondary-button ml-1"
                                    onClick={() => this.props.history.goBack()}>
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
                {this.state.showloader === true ? <SpinnerPage /> : ""}
                {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EcheckCardContainer;
