import RestDataSource from "./restdatasource";
import { loginService } from "./loginService";
import { Global_var } from "../global/global_var";
import {
  error,
  success,
  warning,
  warningNotification,
  errorNotification,
  successNotification,
} from "../components/PP_Notification/PP_Notification";
export const OutstandingPayerService = {
  GetOutstandingPayerdata,
  AddOutstandingPayerdata,
  PP_Create_Payment_Order,
  PP_Get_Apportioned_data,
  Getcardecheckdata,
  PP_Get_Fees,
  PP_Get_Patient_Details,
  resetAccount,
  PP_Get_Settlement_Distributed_data,
};

function resetAccount(passValues, seedAccountNumber, userid, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_RESET_ACCOUNT + `?seedAccountNumber=${seedAccountNumber}`;
  return new RestDataSource(url, userid, fn).GetData((res) => {
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
          window.location("/");
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-token", res.headers.token);
        }
        fn(res);
      }
    }
  }, fnError);
}

function GetOutstandingPayerdata(payerInfo, userId, fn, fnError) {
  // debugger;
  // loginService.TokenUser((res) => {
  //   if (res.status === "success") {
  //     // debugger
  //     sessionStorage.setItem("jwt-token", res.responseObject);
  // var url = Global_var.URL_GET_PAYER_ACCOUNT_DEAILS;
  var url = Global_var.BASEURL + Global_var.URL_GET_PAYER_ACCOUNT_DEAILS;
  // var url = "http://192.168.12.115:8051/pp-billing-service/bill/api/v1/_get"
  return new RestDataSource(url, userId, fn).Store(payerInfo, (res) => {
    // debugger;
    if (res != null) {
      if (res["data"]?.status === "fail") {
        if (res["data"]?.reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-token", res.headers.token);
        }
        fn(res);
      }
    }
  });

  //   }
  // }, fnError);
}

function AddOutstandingPayerdata(payerInfo, userId, fn, fnError) {
  // debugger;
  // loginService.TokenUser((res) => {
  //   if (res.status === "success") {
  //     debugger
  //     sessionStorage.setItem("jwt-token", res.responseObject);
  //     debugger
  var url = Global_var.BASEURL + Global_var.URL_GET_PAYER_ACCOUNT_DEAILS;
  // var url =  Global_var.URL_GET_PAYER_ACCOUNT_DEAILS;
  // var url = "http://192.168.12.115:8051/pp-billing-service/bill/api/v1/_get"
  return new RestDataSource(url, userId, fn).Store(payerInfo, (res) => {
    // debugger;
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-token", res.headers.token);
        }
        fn(res);
      }
    }
  });

  //   }
  // }, fnError);
}

// Create Payment Order API Service Method
function PP_Create_Payment_Order(payerInfo, userId, fn, fnError) {
  // debugger;
  // loginService.TokenUser((res) => {
  //   if (res.status === "success") {
  //     // debugger
  //     sessionStorage.setItem("jwt-token", res.responseObject);
  //       var url =  Global_var.URL_CREATE_PAYMENT_ORDER;
  var url = Global_var.BASEURL + Global_var.URL_CREATE_PAYMENT_ORDER;
  // var url = "http://192.168.12.115:8051/pp-billing-service/paymentorder/api/v1/_create"
  return new RestDataSource(url, userId, fn).Store(payerInfo, (res) => {
    // debugger;
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-token", res.headers.token);
        }
        fn(res);
      }
    }
  });

  //   }
  // }, fnError);
}

// Get Apportioned Data
function PP_Get_Apportioned_data(payerInfo, userId, fn, fnError) {
  // debugger;
  // loginService.TokenUser((res) => {
  //   if (res.status === "success") {
  //     // debugger
  //     sessionStorage.setItem("jwt-token", res.responseObject);
  //       // var url =  Global_var.URL_GET_APPORTIONED_DATA;
  var url = Global_var.BASEURL + Global_var.URL_GET_APPORTIONED_DATA;
  // var url = "http://192.168.12.115:8051/pp-billing-service/bill/api/v1/_distribution"
  return new RestDataSource(url, userId, fn).Store(payerInfo, (res) => {
    // debugger;
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-token", res.headers.token);
        }
        fn(res);
      }
    }
  });

  //   }
  // }, fnError);
}

// Get Card/Echeque Details
function Getcardecheckdata(payerInfo, userId, fn, fnError) {
  // debugger;
  // loginService.TokenUser((res) => {
  //   if (res.status === "success") {
  //     // debugger
  //     sessionStorage.setItem("jwt-token", res.responseObject);
  // var url = Global_var.URL_GET_CARD_DETAILS;
  var url = Global_var.BASEURL + Global_var.URL_GET_CARD_DETAILS;
  // var url = "http://192.168.12.115:8051/pp-billing-service/bill/api/v1/_get"
  return new RestDataSource(url, userId, fn).Store(payerInfo, (res) => {
    // debugger;
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-token", res.headers.token);
        }
        fn(res);
      }
    }
  });

  //   }
  // }, fnError);
}

// Get Fees

// Get Apportioned Data
function PP_Get_Fees(payerInfo, userId, fn, fnError) {
  // debugger;
  // loginService.TokenUser((res) => {
  //   if (res.status === "success") {
  //     // debugger
  //     sessionStorage.setItem("jwt-token", res.responseObject);
  // var url =  Global_var.URL_GET_FEES;
  var url = Global_var.BASEURL + Global_var.URL_GET_FEES;
  // var url = "http://192.168.12.115:8051/pp-billing-service/bill/api/v1/_distribution"
  return new RestDataSource(url, userId, fn).Store(payerInfo, (res) => {
    // debugger;
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-token", res.headers.token);
        }
        fn(res);
      }
    }
  });

  //   }
  // }, fnError);
}
function PP_Get_Patient_Details(payerInfo, userId, fn, fnError) {
  // loginService.TokenUser((res) => {
  //   if (res.status === "success") {
  //     sessionStorage.setItem("jwt-token", res.responseObject);
  // var url =  Global_var.URL_GET_FEES;
  var url = Global_var.BASEURL + Global_var.URL_GET_Patient_Details;
  // var url = "http://192.168.12.115:8051/pp-billing-service/bill/api/v1/_distribution"
  return new RestDataSource(url, userId, fn).Store(payerInfo, (res) => {
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-token", res.headers.token);
        }
        fn(res);
      }
    }
  });

  //   }
  // }, fnError);
}

function PP_Get_Settlement_Distributed_data(payerInfo, userId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_DISTRIBUTED_DATA;
  return new RestDataSource(url, userId, fn).Store(payerInfo, (res) => {
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-token", res.headers.token);
        }
        fn(res);
      }
    }
  });
}
