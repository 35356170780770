import React from "react";
import { ToastContainer as TC, toast } from "react-toastify";

const options = {
  autoClose: 3000,
  hideProgressBar: false,

  position: "top-center",
};

export const handleSuccess = (message) => {
  return toast.success(message, options);
};
export const handleError = (message) => {
  
  return toast.error(message, options);
};
export const handleWarning = (message) => {
  return toast.warning(message, options);
};
export const ToastContainer = () => <TC theme="colored" />;
