import {
  FETCH_IDENTIFY_PAYER_DETAILS_SUCCESS,
  FETCH_IDENTIFY_PAYER_DETAILS_PENDING,
  ADD_IDENTIFY_PAYER,
  // UPDATE_IDENTIFY_PAYER
} from "../action/pp_IdentifyPayerAction";

const initialState = {
  identifyPayerData: [],
  pending: false,
};

const identifyPayerReducer = (state = initialState, action) => {
  switch (action.type) {
    // fecth  payer List
    case FETCH_IDENTIFY_PAYER_DETAILS_SUCCESS:
      debugger;
      return {
        ...state,
        identifyPayerData: action.payload,
        pending: false,
      };
    case FETCH_IDENTIFY_PAYER_DETAILS_PENDING:
      debugger;
      return {
        ...state,
        pending: true,
      };
    // case UPDATE_IDENTIFY_PAYER:
    //   debugger
    // console.log("IDENTIFY_PAYER_DATA reducer")
    // return {
    //   ...state,
    //   data: action.payload,
    // };
    case ADD_IDENTIFY_PAYER:
      debugger;
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default identifyPayerReducer;

export const getIdentifyPayerList = (state) => state?.identifyPayerData;
export const getIdentifyPayerListPending = (state) => state?.pending;
