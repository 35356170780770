import React from "react";
import { FormControlLabel, InputAdornment, TextField, MenuItem, FormControl, Select, Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { Link } from "react-router-dom";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExapandMoreIcon from "@material-ui/icons/ExpandMore";
import Alert from "@material-ui/lab/Alert";
import CustomizedRadio from "../../../../components/CustomizedRadio";

const isUser = localStorage.getItem("role") === "user";
const isPA = localStorage.getItem("role") === "PA";

const payAmountInput = {
  width: "100px",
  padding: "2px 5px",
  outline: "none",
  height: "25px",
  border: "none",
  fontWeight: "600",
};
const payAmountInputforTable = {
  width: "60px",
  padding: "2px 5px",
  outline: "none",
};
const ssNumberText = {
  marginBottom: 0,
};
const userName = {
  fontSize: "25px",
  fontFamily: "poppinssemibold",
  fontWeight: 600,
};
const ssnumber = {
  fontSize: "16px",
  fontFamily: "poppinssemibold",
};
const paymentMethodText = {
  fontSize: "16px",
};
const amountToPayContainer = {
  pading: "0px",
  backgroundColor: "white",
  boxShadow: "0px 0px 5px lightgrey",
  borderRadius: "6px",
};
const amountToPay = { padding: "10px" };
const amountToPayText = {
  fontSize: "16px",
  padding: "10px",
  fontWeight: "500",
};
const disclaimerContent = {
  backgroundColor: "rgb(255,244,229)",
  padding: "15px",
  marginBottom: "10px",
};
const disclaimerAlert = { fontSize: "medium" };
const accountHeaderCell = {
  width: "19vw",
  textAlign: "left",
};
const accountTypeHeaderCell = { width: "18vw" };
const dateServiceHeader = { width: "20vw" };
const outstandingAmountHeader = { width: "20vw" };
const paymentHeader = { width: "15vw" };
const outstandingBalance = {
  fontWeight: "400",
  fontSize: "initial",
};
const viewplanDetails = {
  border: "1px solid rgba(0, 0, 0, 0.12)",
  color: "rgba(0, 0, 0, 0.26)",
};
const outstandingAmountCell = { width: "18%" };
const accountCell = { width: "19%" };
const collectionsCell = { width: "18%" };
const dateServiceCell = { width: "20%" };
const paymentAmountCell = { width: "15%" };
class ProcessPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethod: "none",
      billPaymentMethod: "",
      fullAmonut: "2,220.00",
      otherAmount: "00.00",
      hospital: "",
      open: false,
      selectedAmount: null,
      feesWaived: "7.00",
      finalAmount: 0,
    };
  }
  handleAmountChange = (event) => {
    this.setState({ selectedAmount: event.target.value });
  };
  handleOtherAmountChange = (event) => {
    this.setState({ otherAmount: event.target.value });
  };
  render() {
    return (
      <>
        <div className="dashboard-content" role="main">
          <div className={`payment-portal-login ${isUser || isPA ? "" : "quickpay-bill-details"}`}>
            <div className="payment-section">
              <div className="row">
                <div className="col-md-12">
                  <div className={`payment-content ${isUser || isPA ? "" : "quick-payment-content"}`}>
                    <div className="row">
                      <div className="col-md-6">
                        <h1 style={userName}>David Smith</h1>
                        <p style={ssNumberText}>Social Security Number</p>
                        <h1 style={ssnumber}>***_**_4585</h1>
                      </div>
                      <div className="col-md-6">
                        <h1 style={paymentMethodText}>Payment Method</h1>
                        <div className="form-group mb-4">
                          <FormControl variant="outlined" margin="dense" className="echeckbutton-cp">
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={this.state.paymentMethod}
                              onChange={(e) => {
                                if (e.target.value) {
                                  this.props.setDebitCard(e.target.value);
                                }
                                this.setState({
                                  paymentMethod: e.target.value,
                                });
                                this.props.setDebitCard(e.target.value);
                              }}>
                              <MenuItem value="none" disabled>
                                Select Payment Method
                              </MenuItem>
                              <MenuItem value="visacard111">Visa Card Ending With 111</MenuItem>
                              <MenuItem value="Debit/Credit Card">Debit/Credit Card</MenuItem>
                              <MenuItem value="eCheck">eCheck</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center justify-content-center">
                      <div className="col-md-12 col-lg-8">
                        <div className="row payment-cards">
                          <div className="col-md-4">
                            <div className="input-group-text custom-billing-radio">
                              <FormControlLabel
                                value="fullAmount"
                                control={
                                  <CustomizedRadio
                                    checked={this.state.selectedAmount === "fullAmount"}
                                    onChange={this.handleAmountChange}
                                    value="fullAmount"
                                    inputProps={{ "aria-label": "fullAmount" }}
                                  />
                                }
                              />
                              <div className="input-fields">
                                <label htmlFor="fullBalance" className="para">
                                  Pay Full Balance
                                </label>
                                <span className="num">${this.state.fullAmonut}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 pl-lg-0">
                            <div className="input-group-text custom-billing-radio">
                              <FormControlLabel
                                value="fullAmount"
                                control={
                                  <CustomizedRadio
                                    checked={this.state.selectedAmount === "variableAmount"}
                                    onChange={this.handleAmountChange}
                                    value="variableAmount"
                                    inputProps={{ "aria-label": "variableAmount" }}
                                  />
                                }
                              />
                              <div className="input-fields">
                                <label htmlFor="otherAmount" className="para text-left">
                                  Pay Other Amount
                                </label>
                                <span style={{ fontWeight: 600 }}>
                                  ${" "}
                                  <input
                                    onChange={this.handleOtherAmountChange}
                                    type="number"
                                    placeholder="00.00"
                                    className="payAmountInput"
                                    style={payAmountInput}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-6 pl-lg-0">
                            <div className="content">
                              <span className="para">Grand Total</span>
                              <span className="num">
                                ${" "}
                                {this.state.selectedAmount === "fullAmount"
                                  ? this.state.fullAmonut
                                  : this.state.otherAmount
                                  ? this.state.otherAmount
                                  : "00.00"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-4">
                        <div className="row align-items-center justify-content-around payment-cards pay-btns">
                          <div className="col-md-6 col-6 pl-lg-0">
                            <button onClick={this.props.handleNext} type="button" className="primary-button w-100">
                              Pay Now
                            </button>
                          </div>
                          <div className="col-md-6 col-6 pl-lg-0">
                            <button
                              type="button"
                              onClick={() => {
                                this.props.setFuturePayment(true);
                                this.props.handleNext();
                              }}
                              className="btn-warning w-100">
                              Pay in Future
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12" style={amountToPayContainer}>
                <div>
                  <div style={amountToPay}>
                    <h4 style={amountToPayText}>Please Enter Amount to Pay</h4>

                    <div style={disclaimerContent}>
                      <Alert severity="warning" style={disclaimerAlert}>
                        DISCLAIMER: The balance shown is not updated in real-time and may not reflect credit for all previous
                        payments and/or adjustments. If you have any questions about this account please contact us.
                      </Alert>
                    </div>
                    <div className="billdetails-content">
                      <div aria-label="billDetails">
                        <TableBody>
                          <TableRow className="heading-title accordion-table">
                            <TableCell style={accountHeaderCell}>Account #</TableCell>
                            <TableCell style={accountTypeHeaderCell}>Account Type</TableCell>
                            <TableCell style={dateServiceHeader}>Date of Service</TableCell>
                            <TableCell style={outstandingAmountHeader}>Outstanding Amt</TableCell>
                            <TableCell style={paymentHeader}>Payment Amt</TableCell>
                          </TableRow>
                        </TableBody>
                      </div>

                      <Accordion defaultExpanded className="accordion-section">
                        <AccordionSummary className="accordion-header" expandIcon={<ExapandMoreIcon />}>
                          <div className="d-flex justify-content-between align-items-center w-100 pr-lg-5">
                            <span style={{ fontSize: "initial" }}>23487985</span>
                            <span>
                              <span style={outstandingBalance}> Outstanding Balance:</span>{" "}
                              <span style={{ fontSize: "initial" }}>$ 200</span>
                            </span>

                            <Tooltip
                              title="To view paln details, Please login using credentials from secure login"
                              aria-label="add">
                              <Button variant="outlined" style={viewplanDetails}>
                                View Plan Details
                              </Button>
                            </Tooltip>
                          </div>
                        </AccordionSummary>

                        <AccordionDetails role="region">
                          <Table>
                            <TableBody>
                              <TableRow className="accordion-table">
                                <TableCell style={accountCell}>23487985</TableCell>
                                <TableCell style={collectionsCell}>Collections</TableCell>
                                <TableCell style={dateServiceCell}>02/12/2021</TableCell>
                                <TableCell className="outstanding-amount" style={outstandingAmountCell}>
                                  $ 200.00
                                </TableCell>
                                <TableCell className="payment-amount" style={paymentAmountCell}>
                                  ${" "}
                                  <input
                                    type="number"
                                    placeholder="00.00"
                                    className="payAmountInput"
                                    style={payAmountInputforTable}
                                  />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          <div className="clearfix"></div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ProcessPayment;
