import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  dollar: {
    fontSize: "12px",
  },
  errorPage: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  errorName: {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "800",
    lineHeight: "1",
    textShadow: "10px 6px 8px hsl(0deg 0% 46% / 80%)",
    marginBottom: "0px",
    fontSize: "160px",
  },
  errorMessage: {
    color: "rgb(158, 158, 158)",
    marginBottom: "32px",
    fontSize: "24px",
  },
  searchBox: {
    display: "flex",
    position: "relative",
  },
  input: {
    border: "1px solid #ced4da",
    padding: "15px 50px 15px 15px",
    position: "relative",
    fontSize: "16px",
    borderRadius: "4px;",
    backgroundColor: "#FFFFFF",
    minWidth: "260px",
  },
  searchButton: {
    border: "none",
    backgroundColor: "transparent",
  },
  goHome: {
    marginTop: "30px",
  },
  homeButton: {
    padding: "6px 16px",
    fontSize: "13px",
    fontWeight: "bold",
    fontFamily: "roboto",
    lineHeight: "1.75",
    borderRadius: "4px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    backgroundColor: "#1D2F82",
    color: "#FFFFFF",
    border: "none",
  },
}));
export default function ErrorBoundaryComponent() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.errorPage}>
        <div className={classes.errorName}>
          <p>404</p>
        </div>
        <div className={classes.errorMessage}>
          <h2>Oops, an error has occurred. Page not found!</h2>
        </div>
        <div className={classes.goHome}>
          <button className={classes.homeButton} onClick={() => window?.location?.replace("/signin")}>
            Go To Home
          </button>
        </div>
      </div>
    </>
  );
}
