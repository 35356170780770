import { Global_var } from "../global/global_var";
import { loginService } from "./loginService";
import RestDataSource from "./restdatasource";
import {
  error,
  success,
  warning,
  warningNotification,
  errorNotification,
  successNotification,
} from "../components/Admin_Notification/Admin_Notification";

export const transactionService = {
  getTransactionList,
  addTransaction,
};

function getTransactionList(transactionData, userId, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-token", res.responseObject);
      //     debugger
      // var url = Global_var.URL_GET_CONTENT_LABEL_DATA;
      //  var url= "http://localhost:8056/pp-report-service/transaction/api/v1/_details"
      var url = Global_var.BASEURL + Global_var.URL_GET_TRANSACTION_DATA;
      return new RestDataSource(url, userId, fn).Store(transactionData, (res) => {
        if (res != null) {
          if (res["data"].status === "fail") {
            if (res["data"].reasonText === "Invalid Token") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/login");
            } else {
              warning("Session expired! Please refresh the page", warningNotification);
            }
          } else {
            if (res.headers["token"] != null) {
              sessionStorage.setItem("jwt-token", res.headers.token);
            }

            fn(res);
          }
        }
      });
    }
  }, fnError);
}

function addTransaction(transactionData, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_TRANSACTION_DATA;

      return new RestDataSource(url, null, fn).Store(transactionData, (res) => {
        if (res != null) {
          if (res["data"].status === "fail") {
            if (res["data"].reasonText === "Invalid Token") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/");
            } else if (res["data"].reasonCode === "SM-02") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/");
            } else if (res["data"].reasonCode === "SM-10") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/");
            } else {
              warning("Session expired! Please refresh the page", warningNotification);
            }
          } else {
            if (res.headers["token"] != null) {
              sessionStorage.setItem("jwt-token", res.headers.token);
            }

            fn(res);
          }
        }
      });
    }
  }, fnError);
}
