import {
  WHITE_LABLE_SUCCESS,
  WHITE_LABLE_PENDING,
  WHITE_LABLE_FAILURE,
  GET_CONTENT_PENDING,
  GET_CONTENT_SUCCESS,
  GET_CONTENT_FAILURE,
} from "../action/whiteLableAction";

const initialState = {
  whiteLableData: [],
  getContentData: [],
  pending: false,
  error: "",
};

const whiteLableReducer = (state = initialState, action) => {
  switch (action.type) {
    case WHITE_LABLE_SUCCESS:
      return {
        ...state,
        whiteLableData: action.payload,
        pending: false,
      };
    case WHITE_LABLE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case WHITE_LABLE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload,
      };

    case GET_CONTENT_SUCCESS:
      return {
        ...state,
        getContentData: action.payload,
        pending: false,
      };
    case GET_CONTENT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_CONTENT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default whiteLableReducer;

export const getWhiteLableData = (state) => state?.whiteLableData;
export const getWhiteLableDataPending = (state) => state?.pending;
export const getWhiteLableDataError = (state) => state?.error;

export const getContentData = (state) => state?.getContentData;
export const getContentDataPending = (state) => state?.pending;
export const getContentDataError = (state) => state?.error;
