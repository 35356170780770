import { ClientManagementService } from "../services/ClientManagementService";
const GET_PAYMENT_PLANS_OPTIONS = "GET_PAYMENT_PLANS_OPTIONS";
const GET_PAYMENT_PLANS_OPTIONS_BY_ID = "GET_PAYMENT_PLANS_OPTIONS_BY_ID";
const SAVE_PAYMENT_PLANS_OPTIONS = "SAVE_PAYMENT_PLANS_OPTIONS";
const DELETE_PAYMENT_PLAN_OPTIONS = "DELETE_PAYMENT_PLANS_OPTIONS";
const POST_SETTLEMENT_OPTIONS = "POST_SETTLEMENT_OPTIONS";
const GET_SETTLEMENT_OPTIONS = "GET_SETTLEMENT_OPTIONS";
const GET_SETTLEMENT_OPTIONS_BY_ID = "GET_SETTLEMENT_OPTIONS_BY_ID";

export function getPaymentPlansOptionsList(Data, fn) {
  return (dispatch) => {
    dispatch({
      type: GET_PAYMENT_PLANS_OPTIONS,
      payload: Data,
    });
    ClientManagementService.getPaymentPlansOptions(Data, (res) => fn(res));
  };
}

export function getPaymentPlansOptionsDataById(Data, fn) {
  return (dispatch) => {
    dispatch({
      type: GET_PAYMENT_PLANS_OPTIONS_BY_ID,
      payload: Data,
    });
    ClientManagementService.getPaymentPlansOptionsById(Data, (res) => fn(res));
  };
}
export function deletePaymentPlanOptions(Data, fn) {
  return (dispatch) => {
    dispatch({
      type: DELETE_PAYMENT_PLAN_OPTIONS,
      payload: Data,
    });
    ClientManagementService.deletePaymentPlanOption(Data, (res) => fn(res));
  };
}
export function getSettlementOptionsById(Data, fn) {
  return (dispatch) => {
    dispatch({
      type: GET_SETTLEMENT_OPTIONS_BY_ID,
      payload: Data,
    });
    ClientManagementService.getSettlementOptionsById(Data, (res) => fn(res));
  };
}

export function savePaymentPlansOptionsData(Data, fn) {
  return (dispatch) => {
    dispatch({
      type: SAVE_PAYMENT_PLANS_OPTIONS,
      payload: Data,
    });
    ClientManagementService.savePaymentPlansOptions(Data, (res) => fn(res));
  };
}
export function UpdatePaymentPlansOptionsData(Data, fn) {
  return (dispatch) => {
    dispatch({
      type: SAVE_PAYMENT_PLANS_OPTIONS,
      payload: Data,
    });
    ClientManagementService.UpdatePaymentPlansOptions(Data, (res) => fn(res));
  };
}

export function getHospitalList(fn) {
  return (dispatch) => {
    ClientManagementService.getHospitalListData((res) => fn(res));
  };
}

export function saveSettlement(Data, fn) {
  return (dispatch) => {
    dispatch({
      type: POST_SETTLEMENT_OPTIONS,
      payload: Data,
    });
    ClientManagementService.PostSettlementOptions(Data, (res) => fn(res));
  };
}
export function getSettlementList(Data, fn) {
  return (dispatch) => {
    dispatch({
      type: GET_SETTLEMENT_OPTIONS,
      payload: Data,
    });
    ClientManagementService.getSettlementOptionsList(Data, (res) => fn(res));
  };
}
