import { resendConfirmationEmail } from "../services/emailConfirmationService";

export const GET_RESEND_EMAIL_CONFIRMATION_SUCCESS = "GET_RESEND_EMAIL_CONFIRMATION_SUCCESS";

export function resendConfirmationEmailSuccess(userData) {
  return {
    type: GET_RESEND_EMAIL_CONFIRMATION_SUCCESS,
    payload: userData,
  };
}

export function resendConfirmationEmailData(userData, fn) {
  return (dispatch) => {
    dispatch({
      type: GET_RESEND_EMAIL_CONFIRMATION_SUCCESS,
      payload: userData,
    });
    resendConfirmationEmail.getResendConfirmationEmail(userData, (res) => fn(res));
  };
}
