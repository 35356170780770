import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Common from "./Common";
import Auth from "./Auth";
import SecurityImageReducer from "../../reducer/securityImageReducer";
import PaymentCreditCardReducer from "../../reducer/pp_PaymentCreditCardReducer";
import EChequePaymentReducer from "../../reducer/pp_eChequePaymentReducer";
import TransactionSuccessReducer from "../../reducer/pp_transactionSuccessReducer";
import PayerOutstandingReducer from "../../reducer/pp_payerOutstandingReducer";
import PatientDetailReducer from "../../reducer/Admin_patientDataReducer";

// eslint-disable-next-line import/no-anonymous-default-export
export default (history) =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    securityImageReducer: SecurityImageReducer,
    paymentCreditCardReducer: PaymentCreditCardReducer,
    eChequePaymentReducer: EChequePaymentReducer,
    transactionSuccessReducer: TransactionSuccessReducer,
    payerOutstandingReducer: PayerOutstandingReducer,
    patientDetailReducer: PatientDetailReducer,
  });
