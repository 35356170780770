import React, { useContext, useState } from "react";
import Routes from "./Routes";
import SideNav from "../SideNav";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Profile from "../../../components/Profile";
import { AppContext } from "../../../context/AppProvider";
// import LanguageSwitcher from "../../../components/LanguageSwitcher";
import NotificationsIcon from "@material-ui/icons/Notifications";

export default function PAScreens() {
  const { sidebar, setSidebar } = useContext(AppContext);
  const [hoverNav, setHoverNav] = useState(false);
  const expandNav = () => setHoverNav(!hoverNav);
  return (
    // eslint-disable-next-line react/style-prop-object
    <div className="admin-dashboard">
      <div className={sidebar ? "dashboard-header" : "dashboard-header-collapse"}>
        <nav>
          <ul className="float-left mt-3">
            <li>
              {/* <MenuIcon /> */}
              {sidebar ? (
                <ArrowBackIosIcon
                  onClick={() => {
                    setHoverNav(false);
                    setSidebar(!sidebar);
                  }}
                />
              ) : (
                <ArrowForwardIosIcon
                  onClick={() => {
                    setHoverNav(false);
                    setSidebar(!sidebar);
                  }}
                />
              )}
            </li>
          </ul>

          <ul className="float-right">
            <li>
              {/* <NotificationsIcon /> */}
              {/* <Profile userName="John Doe" route="pa" /> */}
            </li>
          </ul>
          <ul className="float-right">
            <li>{/* <LanguageSwitcher /> */}</li>
          </ul>
        </nav>
      </div>
      <div onMouseEnter={expandNav} onMouseLeave={expandNav}>
        <SideNav hoverNav={hoverNav} setHoverNav={setHoverNav} />
      </div>
      <div
        className={
          sidebar ? "dashboard-section admin-dashboard-section" : "dashboard-section-collapse admin-dashboard-section"
        }>
        <Routes />
      </div>
    </div>
  );
}
