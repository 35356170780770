import React, { Component } from "react";

class securityImage extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {}
  render() {
    return (
      <React.Fragment>
        <div className="admin-img-col">
          <div className="admin-imgbox">
            <img src={"data:image/image/png;base64," + this.props.actualImage} alt="" width="51"></img>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default securityImage;
