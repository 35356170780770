/* eslint-disable no-unused-vars */
import React from "react";
import { Formik, Form, getIn } from "formik";
import * as Yup from "yup";
import EmailIcon from "@material-ui/icons/Email";
import { FormHelperText } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { InputLabel, FormControl } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import i18n from "../../../i18n";
import SpinnerPage from "../../../Common/PP_loader";
import { requestInfo_PP } from "../../../Common/CommonFn";
import { resendConfirmationEmailData } from "../../../action/resendEmailConfirmationAction";
import { handleSuccess, handleWarning, ToastContainer, handleError } from "../../../components/Notifications/Toast";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resendConfirmationEmailData: resendConfirmationEmailData,
    },
    dispatch,
  );

class EmailConfirmationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailId: "",
      isLoading: false,
    };
  }

  initialValues = {
    emailId: "",
  };

  schema = Yup.object().shape({
    emailId: Yup.string().required("Email Address is required").nullable().email(i18n.t("credit_erremailaddmaching")),
  });

  handleSubmit = (values, onSubmitProps) => {
    this.setState({
      isLoading: true,
    });

    const passValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      contactEmail: values.emailId,
    };

    this.props.resendConfirmationEmailData(passValues, (res) => {
      if (res?.data?.responseInfo.status === "SUCCESSFUL") {
        handleSuccess("Confirmation Email Sent Successfully.");
        this.setState({
          isLoading: false,
        });
      } else {
        handleWarning("Email Address not found");
        this.setState({
          isLoading: false,
        });
      }
    });

    onSubmitProps.resetForm();
  };
  render() {
    return (
      <div className="transaction-card patient-details" role="main">
        <div>
          <div className="main-container-card">
            <Formik initialValues={this.initialValues} onSubmit={this.handleSubmit} validationSchema={this.schema}>
              {(props) => {
                const { values, touched, error, handleChange, errors, setFieldValue } = props;
                return (
                  <Form id="transaction-search" className="input-field" noValidate="noValidate">
                    <div role="heading" aria-level="1" className="heading-style mb-3">
                      <EmailIcon className="mr-2" />
                      <h2 className="content-title heading-style mb-0">Resend Confirmation Email</h2>
                    </div>
                    <div className="form-row">
                      <div className="col-xl-3 col-lg-3 col-sm-8 col-md-4 form-group">
                        <FormControl variant="outlined" className="w-100" fullWidth>
                          <label htmlFor="emailId" className="form-label w-100 required">
                            Email Address
                          </label>
                          <Grid container>
                            <TextField
                              type="text"
                              id="emailId"
                              name="emailId"
                              value={values.emailId}
                              placeholder={i18n.t("echeque_emailAddress")}
                              onChange={handleChange}
                              aria-label="text-field"
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}></TextField>
                          </Grid>
                        </FormControl>
                        <FormHelperText error>
                          {getIn(touched, "emailId") && getIn(errors, "emailId") && getIn(errors, "emailId")}
                        </FormHelperText>
                      </div>
                    </div>
                    <button aria-label="Generate Report" type="submit" className="primary-button themeing-buttons">
                      Submit
                    </button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>

        {this.state.isLoading && <SpinnerPage />}
        {/* <ToastContainer /> */}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmationComponent);
