import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import Tooltip from "@material-ui/core/Tooltip";
import Popover from "@material-ui/core/Popover";
import InfoIcon from "@material-ui/icons/Info";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
    [theme.breakpoints.down("sm")]: {
      top: "-20px !important",
    },
  },
  paper: {
    padding: theme.spacing(1),
  },
  clear: {
    marginTop: "1px",
    position: "absolute",
    left: "53%",
    top: "0",
    [theme.breakpoints.down("sm")]: {
      left: "85%",
      top: "-40px",
    },
  },
  drawCanvasSignature: {
    position: "relative",
  },
  imaginaryLine: {
    borderBottom: "2px solid rgba(0, 0, 0, 0.12)",
    bottom: "2px",
    width: "55%",
    position: "absolute",
    zIndex: "2",
    [theme.breakpoints.down("sm")]: {
      width: "85%",
    },
  },
}));

const DrawSign = ({ setFieldValue, handleBase64String, updateUserSignature }) => {
  const classes = useStyles();
  const sigPad = useRef({});
  const [anchorEl, setAnchorEl] = React.useState(null);

  const clear = () => {
    sigPad.current.clear();
    setFieldValue("signature", "");
  };

  const handleSignatureEnd = () => {
    let img = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
    setFieldValue("signature", img);
    updateUserSignature({ signature: img });
    handleBase64String(img);
  };

  return (
    <div className="draw-sign">
      <h6>
        E-Signature
        <div className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorPrimary d-inline ml-1">
          <InfoIcon
            aria-describedby={"info-sign-popup"}
            aria-owns={Boolean(anchorEl) ? "info-sign-popup" : undefined}
            aria-haspopup="true"
            variant="contained"
            style={{ color: "#0365A5" }}
            onMouseEnter={(event) => {
              setAnchorEl(event.currentTarget);
            }}
            onMouseLeave={() => {
              setAnchorEl(null);
            }}
          />
        </div>
        <Popover
          id={"info-sign-popup"}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          onClose={() => {
            setAnchorEl(null);
          }}
          disableRestoreFocus>
          <span style={{ fontSize: "12px" }}>Please use mouse or touch screen to sign.</span>
        </Popover>
      </h6>
      <div className={classes.drawCanvasSignature}>
        <div className={classes.imaginaryLine}> </div>

        <SignatureCanvas
          penColor="black"
          ref={sigPad}
          canvasProps={{ className: "sigCanvas" }}
          style={{ width: "400px" }}
          onEnd={handleSignatureEnd}
          name="signature"
        />
        <div className={classes.clear}>
          <Tooltip title="Clear Signature">
            <IconButton color="primary" aria-label="clear signature" onClick={clear} component="span">
              <ClearOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default DrawSign;
