import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    margin: "0.625rem 1.625rem 1.5rem 0",
    backgroundColor: "#fff",
    borderRadius: "7px",
    "& .MuiInputLabel-root": {
      color: "#144167",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#B9B9B9",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function PaymentMethodSelect({ setBillPaymentMethod, className }) {
  const classes = useStyles();
  const [paymentMethod, setPaymentMethod] = React.useState("none");

  const handleChange = (event) => {
    setPaymentMethod(event.target.value);
    setBillPaymentMethod && setBillPaymentMethod(event.target.value);
  };

  return (
    <FormControl variant="outlined" margin="dense" className={`${classes.formControl} ${className ? className : null}`}>
      <Select id="demo-simple-select-outlined" value={paymentMethod} onChange={handleChange}>
        <MenuItem value="none" disabled>
          Select Payment Method
        </MenuItem>
        <MenuItem value="DebitOrCredit">Debit/Credit Card</MenuItem>
        <MenuItem value="eCheck">eCheck</MenuItem>
      </Select>
    </FormControl>
  );
}
