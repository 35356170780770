import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MakePayment from "../LandingPage/MakePayment";
// import ProcessPayment from "../LandingPage/ProcessPayment";
// import Payment from "../Payment/index";
import PayNow from "../Payment/index";
import ThankYou from "../LandingPage/Thankyou";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Make a Payment", "Process Payment", "Thank You"];
}

export default function HorizontalLinearStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(1);
  const [skipped, setSkipped] = React.useState(new Set());

  const [debitCard, setDebitCard] = React.useState("");
  const [futurePayment, setFuturePayment] = React.useState(null);
  const steps = getSteps();

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  function getStepContent(step) {
    switch (step) {
      case 0:
        return <MakePayment handleNext={handleNext} setDebitCard={setDebitCard} setFuturePayment={setFuturePayment} />;
      case 1:
        return <PayNow props={props?.location?.state} />;
      case 2:
        return <ThankYou />;
      default:
        return "Unknown step";
    }
  }

  return (
    <div
      className={classes.root}
      style={{
        padding: "30px",
        boxShadow: "0px 0px 5px lightGrey",
        margin: "10px auto",
        width: "98%",
        borderRadius: "4px",
      }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        <>
          <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>{getStepContent(activeStep)}</div>
          <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "23px", paddingRight: "12px" }}>
            <div>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
                style={{ marginRight: "5px" }}>
                Pay Now
              </Button> */}
              {/* <Button
                variant="contained"
                style={{ backgroundColor: "rgb(23,162,184)" }}
                onClick={() => {
                  window.location.replace("/signin");
                }}
                className={classes.button}>
             
                Cancel
              </Button> */}
            </div>
          </div>
          <div className="copy-righ" style={{ color: "gray", textAlign: "center" }}>
            <p style={{ fontSize: "14px", marginTop: "1rem", marginBottom: "25px" }}>
              Need Help?{" "}
              <Link to="/contact-us" aria-label="contact-us-link">
                Contact Us
              </Link>
            </p>
            <p style={{ fontSize: "14px", marginTop: "1rem" }}>Copyright 2020. All Rights Reserved.</p>
            <p style={{ fontSize: "14px", marginTop: "1rem" }}>
              WE ARE DEBT COLLECTORS. THIS IS AN ATTEMPT TO COLLECT A DEBT. ANY INFORMATION OBTAINED WILL BE USED FOR THAT
              PURPOSE.
            </p>
          </div>
        </>
        {/* )} */}
      </div>
    </div>
  );
}
