import { Global_var } from "../global/admin_global_var";
import RestDataSource from "./restdatasource";
import { loginService } from "./loginService";
import { warning, warningNotification } from "../components/PP_Notification/PP_Notification";

export const RegionManagementService = {
  getCountry,
  getState,
  getCity,
};

function getCountry(countryData, userId, fn, fnError) {
  var url = "https://prodservices.pdsnew.com" + Global_var.URL_GETCOUNTRY;

  return new RestDataSource(url, userId, fn).Store(countryData, (res) => {
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-tokencore", res.headers.token);
        }
        fn(res);
      }
    }
  });
}
function getState(stateData, userId, fn, fnError) {
  var url = "https://prodservices.pdsnew.com" + Global_var.URL_GETSTATE;

  return new RestDataSource(url, userId, fn).Store(stateData, (res) => {
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-tokencore", res.headers.token);
        }
        fn(res);
      }
    }
  });
}

function getCity(stateData, userId, fn, fnError) {
  var url = "https://prodservices.pdsnew.com" + Global_var.URL_GETCITY;

  return new RestDataSource(url, userId, fn).Store(stateData, (res) => {
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/login");
        } else {
          warning("Something went wrong!" + res["data"].reasonText, warningNotification);
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-tokencore", res.headers.token);
        }
        fn(res);
      }
    }
  });
}
