import React, { Component } from "react";
import ReactGA from "react-ga";

export const GoogleAnalystics = {
  GoogleAnalysticsLog,
  GoogleAnalysticsEvent,
};

function GoogleAnalysticsLog(hitType) {
  // debugger
  var userid = "";
  if (sessionStorage.getItem("verifypayer")) {
    const userids = JSON.parse(sessionStorage.getItem("verifypayer"));

    if (userids.payer) {
      userid = userids.payer.partyId ? userids.payer.partyId : "1234";
    } else {
      userid = userids.partyId ? userids.partyId : "1234";
    }
  } else {
    userid = "1234";
  }

  //alert("Environment :" + process.env.NODE_ENV)
  //
  var trackingId = "-6ZEECW7199";

  if (process.env.NODE_ENV === "development") {
    trackingId = "-6ZEECW7199"; //  Dev tracking code
  } else {
    trackingId = "G-DNJPFCSQYZ"; //  Production tracking code
  }
  ReactGA.initialize(trackingId);
  ReactGA.set({
    // userId: auth.currentUserId(),
    userId: userid,
    // any data that is relevant to the user session
    // that you would like to track with google analytics
  });
  ReactGA.send({
    hitType: hitType, //'pageview',
    page: window.location.pathname,
  });
}

// export const Event = (category, action, label) => {

//   };

function GoogleAnalysticsEvent(category, action, label) {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
}
