import { Global_var } from "../global/admin_global_var";
import { loginService } from "./loginService";
import RestDataSource from "../admin_services/restdatasource";
import { warning, warningNotification } from "../components/Admin_Notification/Admin_Notification";

export const userService = {
  getUserList,
  searchUser,
  searchUserName,
};

function getUserList(patientData, id, fn) {
  var url = Global_var.BASEURL + Global_var.URL_GET_USER_DATA;
  return new RestDataSource(url, fn).Store(patientData, (res) => {
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-02") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-10") {
          sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else {
          warning("Session expired! Please refresh the page", warningNotification);
        }
      } else {
        if (res.headers["token"] != null) {
          sessionStorage.setItem("jwt-token", res.headers.token);
        }
        fn(res);
      }
    }
  });
}

function searchUser(patientData, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_USER_DATA;

      return new RestDataSource(url, null, fn).Store(patientData, (res) => {
        if (res != null) {
          if (res["data"].status === "fail") {
            if (res["data"].reasonText === "Invalid Token") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/");
            } else if (res["data"].reasonCode === "SM-02") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/");
            } else if (res["data"].reasonCode === "SM-10") {
              sessionStorage.clear();
              warning("Token Expired, Login/Verify again", warningNotification);
              window.location.replace("/");
            } else {
              warning("Session expired! Please refresh the page", warningNotification);
            }
          } else {
            if (res.headers["token"] != null) {
              sessionStorage.setItem("jwt-token", res.headers.token);
            }

            fn(res);
          }
        }
      });
    }
  }, fnError);
}

function searchUserName(userName, fn) {
  var url = Global_var.BASEURL + Global_var.URL_SEARCH_USER_NAME + userName;
  return new RestDataSource(url, null, fn).GetData((res) => fn(res));
}
