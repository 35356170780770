import { echequePaymentService } from "../services/echequePaymentService";

export const FETCH_ECHEQUE_DETAILS_SUCCESS = "FETCH_ECHEQUE_DETAILS_SUCCESS";
export const FETCH_ECHEQUE_DETAILS_PENDING = "FETCH_ECHEQUE_DETAILS_PENDING";
export const FETCH_COUNTRY_DETAILS_SUCCESS = "FETCH_COUNTRY_DETAILS_SUCCESS";
export const ADD_ECHAQUE = "ADD_ECHAQUE";

export const GET_COUNTRY = "GET_COUNTRY";

export function fetchEchaqueDataSuccess(echaqueData) {
  return {
    type: FETCH_ECHEQUE_DETAILS_SUCCESS,
    payload: echaqueData,
  };
}
export function fetchEchaqueDatapending() {
  return {
    type: FETCH_ECHEQUE_DETAILS_PENDING,
  };
}

export function fetchEchaqueData(echaqueInfo, userId) {
  return (dispatch) => {
    dispatch(fetchEchaqueDatapending());
    echequePaymentService.getEchequePayment(echaqueInfo, userId, (res) => {
      dispatch(fetchEchaqueDataSuccess(res?.data?.paymentInfos[0]));
    });
  };
}
export function addEchaqueData(echaqueInfo, userId, fn) {
  return (dispatch) => {
    dispatch({
      type: ADD_ECHAQUE,
      payload: echaqueInfo,
    });
    echequePaymentService.saveEchequePayment(echaqueInfo, userId, (res) => fn(res));
  };
}
