import { FETCH_TRANSACTION_DATA_SUCCESS, FETCH_TRANSACTION_DATA_PENDING } from "../action/pp_TransactionSuccessAction";

const initialState = {
  paymentSuccessData: [],
  pending: false,
};

const transactionSuccessReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSACTION_DATA_SUCCESS:
      return {
        ...state,
        paymentSuccessData: action.payload,
        pending: false,
      };
    case FETCH_TRANSACTION_DATA_PENDING:
      return {
        ...state,
        pending: true,
      };

    //   case SAVE_CREDIT_CARD_PAYMENT:
    //     return {
    //       ...state,
    //       data: action.payload,
    //     };
    default:
      return state;
  }
};

export default transactionSuccessReducer;

export const getTransactionDataList = (state) => state?.paymentSuccessData;
export const getTransactionDataListPending = (state) => state?.pending;
