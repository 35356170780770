import { Global_var } from "../global/global_var";
import RestDataSource from "../services/restdatasource";

export const httpPaginationRequest = (targetUrl, payload, fn) => {
  const url = Global_var.BASEURL + targetUrl;
  return new RestDataSource(url, null, fn).Store(payload, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        localStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
};
