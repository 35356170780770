/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import Med1Logo from "../../../assets/images/Med1Logo.png";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getIn } from "formik";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { FormHelperText } from "@material-ui/core";
import RocketIcon from "../../../assets/images/quick-pay-rocket-icon.svg";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import {
  error,
  errorNotification,
  success,
  successNotification,
  warning,
  warningNotification,
} from "../../../components/PP_Notification/PP_Notification";
import { GoogleAnalystics } from "../../../Common/GoogleAnalystics";
import { requestInfo_PP } from "../../../Common/CommonFn.js";
import { contactusService } from "../../../services/contactusService";
import i18n from "../../../i18n";
import SpinnerPage from "../../../Common/PP_loader.js";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";

const intialvalues = {
  firstName: "",
  lastname: "",
  phone: "",
  email: "",
  message: "",
};

const schema = Yup.object().shape({
  firstName: Yup.string().trim().required("First Name required"),
  lastname: Yup.string().trim().required("Last Name required"),
  email: Yup.string().trim().email("Email Address must be valid").required("Email Address required"),
  phone: Yup.string().trim().min(10).max(10).required("Phone Number required"),
  message: Yup.string().trim().required("Message required"),
});

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
    };
  }

  componentDidMount() {
    document.title = "MED-1 Quick Pay - Contact Us";
    GoogleAnalystics.GoogleAnalysticsLog("pageview");
    window.scrollTo(0, 0);
  }

  componentWillMount() {
    sessionStorage.setItem("screen", "contactUs");
  }

  gotologin = () => {
    this.props.history.push("/login");
  };

  redirectToBack = () => {
    this.props.history.goBack();
  };

  gotofaqs = () => {
    this.props.history.push("/FAQs");
  };

  onSubmit = async (values, onSubmitProps) => {
    this.setState({
      loader: true,
    });

    const PassValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      paymentQuery: {
        personName: values.firstName + " " + values.lastname,
        countryCode: 1,
        phoneNumber: values.phone,
        personEmail: values.email,
        question: values.message,
      },
      adminEmail: "monika@privacydatasystems.com",
    };
    const UserId = "1234";
    contactusService.addContactusData(
      PassValues,
      UserId,
      (res) => {
        if (res["data"].requestInfo.status === "SUCCESSFUL") {
          this.setState({
            loader: false,
          });
          success(
            <Translation>{(t, { i18n }) => <label>{t("contactus_successMessage")} </label>}</Translation>,
            successNotification,
          );
        } else if (res["data"].requestInfo.status === "FAILED") {
          this.setState({
            loader: false,
          });

          error(i18n.t("contactus_errorMessage"), errorNotification);
        } else {
          this.setState({
            loader: false,
          });

          warning(
            <Translation>{(t, { i18n }) => <label>{t("contactus_warningMessage")} </label>}</Translation>,
            warningNotification,
          );
        }
      },
      (error) => {
        console.log(error);
      },
    );
    onSubmitProps.resetForm();
  };

  render() {
    return (
      <div className="page-wrapper" role="main">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="card-md">
              <div className="header" role="heading" aria-level="1">
                <div className="rocket-icon">
                  <img src={RocketIcon} alt="" />
                </div>
                <div className="logo">
                  <img src={Med1Logo} className="img-fluid" alt="Med-1 Solutions Logo" />
                </div>
              </div>
              <div className="main-heading">
                <h1>
                  Quick<span>Pay</span>
                  <p>Contact Us</p>
                </h1>
              </div>
              <div className="login-content contact-us-content">
                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="email-icon">
                          <i className="fa fa-envelope"></i>
                        </div>
                        <div className="card-text">
                          <p>Email Support</p>
                          <Link to={this.props.history?.location?.pathname}>help@med1solutions.com</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="email-icon">
                          <i className="fa fa-envelope"></i>
                        </div>
                        <div className="card-text">
                          <p>Speak to a Patient Advocate</p>
                          <a href="tel:+18883230811">1-888-323-0811</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contact-you">
                  <p>
                    If you need any further assistance, you can communicate with us by entering your information and we will
                    contact you.
                  </p>
                </div>
                <Formik initialValues={intialvalues} onSubmit={this.onSubmit} validationSchema={schema}>
                  {(props) => {
                    const { values, touched, error, handleChange, errors, setFieldValue, handleReset } = props;
                    return (
                      <Form id="login-form" className="input-field" noValidate="noValidate">
                        <div className="row input-field">
                          <div className="col-md-6 form-group">
                            <div className="label-spacing">
                              <label htmlFor="firstName">
                                First Name<span>*</span>
                              </label>
                            </div>
                            <OutlinedInput
                              className="form-control"
                              id="firstName"
                              name="firstName"
                              placeholder="Enter First Name"
                              onChange={handleChange}
                              value={values["firstName"]}
                              aria-label="text-field"
                              fullWidth
                              type="firstName"
                              error={Boolean(getIn(touched, "firstName") && getIn(errors, "firstName"))}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required="true"
                              inputProps={{
                                maxLength: 50,
                              }}></OutlinedInput>
                            <FormHelperText className="component-error-text">
                              {getIn(touched, "firstName") && getIn(errors, "firstName") && getIn(errors, "firstName")}
                            </FormHelperText>
                          </div>
                          <div className="col-md-6 form-group">
                            <div className="label-spacing">
                              <label htmlFor="lastName">
                                Last Name<span>*</span>
                              </label>
                            </div>
                            <OutlinedInput
                              className="form-control"
                              id="lastName"
                              name="lastname"
                              placeholder="Enter Last Name"
                              onChange={handleChange}
                              value={values["lastname"]}
                              aria-label="text-field"
                              fullWidth
                              type="lastname"
                              error={Boolean(getIn(touched, "lastname") && getIn(errors, "lastname"))}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required="true"
                              inputProps={{
                                maxLength: 50,
                              }}></OutlinedInput>
                            <FormHelperText className="component-error-text">
                              {getIn(touched, "lastname") && getIn(errors, "lastname") && getIn(errors, "lastname")}
                            </FormHelperText>
                          </div>
                          <div className="col-md-6 form-group">
                            <div className="label-spacing">
                              <label htmlFor="phoneNumber">
                                Phone Number<span>*</span>
                              </label>
                            </div>
                            <OutlinedInput
                              className="form-control phone-number-contact"
                              id="phoneNumber"
                              name="phone"
                              placeholder="Enter Phone Number"
                              onChange={handleChange}
                              value={values["phone"]}
                              aria-label="text-field"
                              type="text"
                              fullWidth
                              error={Boolean(getIn(touched, "phone") && getIn(errors, "phone"))}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required="true"></OutlinedInput>
                            <FormHelperText className="component-error-text">
                              {getIn(touched, "phone") && getIn(errors, "phone") && getIn(errors, "phone")}
                            </FormHelperText>
                          </div>
                          <div className="col-md-6 form-group">
                            <div className="label-spacing">
                              <label htmlFor="emailAddress">
                                Email Address<span>*</span>
                              </label>
                            </div>
                            <OutlinedInput
                              className="form-control"
                              id="emailAddress"
                              name="email"
                              type="email"
                              placeholder="Enter Email Address"
                              onChange={handleChange}
                              value={values["email"]}
                              aria-label="text-field"
                              fullWidth
                              error={Boolean(getIn(touched, "email") && getIn(errors, "email"))}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required="true"
                              inputProps={{
                                maxLength: 50,
                              }}></OutlinedInput>
                            <FormHelperText className="component-error-text">
                              {getIn(touched, "email") && getIn(errors, "email") && getIn(errors, "email")}
                            </FormHelperText>
                          </div>
                          <div className="col-md-12 form-group">
                            <div className="label-spacing">
                              <label htmlFor="message">
                                Your Message Here<span>*</span>
                              </label>
                            </div>
                            <OutlinedInput
                              id="message"
                              name="message"
                              type="textarea"
                              multiline="true"
                              rows={5}
                              placeholder="Enter Your Message Here"
                              onChange={handleChange}
                              value={values["message"]}
                              aria-label="text-field"
                              fullWidth
                              error={Boolean(getIn(touched, "message") && getIn(errors, "message"))}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required="true"
                              inputProps={{
                                maxLength: 50,
                              }}></OutlinedInput>
                            <FormHelperText className="component-error-text">
                              {getIn(touched, "message") && getIn(errors, "message") && getIn(errors, "message")}
                            </FormHelperText>
                          </div>
                        </div>
                        <div className="cancel-submit-buttons">
                          <button className="secondary-button" aria-label="cancel" onClick={this.redirectToBack}>
                            Cancel
                          </button>

                          <button type="submit" className="primary-button" aria-label="submit">
                            Submit
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
          <div className="footer-section">
            <p>Copyright {new Date().getFullYear()}. All Rights Reserved.</p>
            <p>
              WE ARE DEBT COLLECTORS. THIS IS AN ATTEMPT TO COLLECT A DEBT. ANY INFORMATION OBTAINED WILL BE USED FOR THAT
              PURPOSE.
            </p>
          </div>
        </div>
        {this.state.loader && <SpinnerPage />}
        {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
      </div>
    );
  }
}

export default ContactUs;
