import React from "react";
import { bindActionCreators } from "redux";
import Med1Logo from "../../../assets/images/Med1Logo.svg";
import Med1AccNumberTooltip from "../../../assets/images/medAccountNumberTooltip.jpg";
import * as Yup from "yup";
import Captcha from "../../../components/Captcha";
import { Formik, Form, getIn, ErrorMessage } from "formik";
import { connect } from "react-redux";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { requestInfo_PP } from "../../../Common/CommonFn";
import { Grid, IconButton, CircularProgress, FormHelperText, TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import ImageTooltip from "../../../components/ImageTooltip";
import { ToastMsg } from "../../../locales/en/toastmessages";
import RocketIcon from "../../../assets/images/quick-pay-rocket-icon.svg";
import { identifyPayerService } from "../../../services/identifyPayerService";
import { requestInfo } from "../../../services1/REST";
import { OutstandingPayerService } from "../../../services/payerOutstandingService";
import { getIdentifyPayerList, getIdentifyPayerListPending } from "../../../reducer/pp_identifyPayerReducer";
import { fetchIdentifyPayerData, addIdentifyPayerData } from "../../../action/pp_IdentifyPayerAction";
import { handleError, handleWarning, ToastContainer } from "../../../components/Notifications/Toast";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import DatePicker from "@material-ui/lab/DatePicker";
import moment from "moment";
import endOfDay from "date-fns/endOfDay";
import { Numberformat } from "../../../Common/NumberFormat";
import { Oval } from "react-loader-spinner";
import ClearIcon from "@material-ui/icons/Clear";
import SpinnerPage from "../../../Common/PP_loader";

const mapStateToProps = (state) => ({
  identifyPayerData: getIdentifyPayerList(state?.identifyPayerReducer),
  pending: getIdentifyPayerListPending(state?.identifyPayerReducer),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchIdentifyPayerData: fetchIdentifyPayerData,
      addIdentifyPayerData: addIdentifyPayerData,
    },
    dispatch,
  );
class QuickPay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMed1AccNumber: true,
      showSocialSecurityNumber: false,
      captcha: false,
      displayCaptchaError: false,
      accountNumber: "",
      showssn: false,
      showdob: false,
      showemail: false,
      showcontact: false,
      showloader: false,
    };
  }

  med1ApiCall = (value) => {
    if (value?.length === 8) {
      const data = {
        requestInfo: requestInfo(),
        payerBillSearch: { accountReferenceNumber: value },
      };
    }
  };

  handleMed1AccountNumber = () => this.setState({ showMed1AccNumber: !this.state.showMed1AccNumber });
  handleSocialSecurityNumber = () => this.setState({ showSocialSecurityNumber: !this.state.showSocialSecurityNumber });

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  _handleSubmit = (values, { actions }) => {
    if (!(this.state.displayCaptchaError && values?.recaptcha?.length < 1)) {
      var myDate = values.patientDOB;
      var mydate1 = "";
      if (myDate) {
        var mm = myDate.getMonth() + 1;
        var dd = myDate.getDate();
        var yy = myDate.getFullYear();
        var hh = myDate.getHours();
        var mn = myDate.getMinutes();
        var ss = myDate.getSeconds();

        mydate1 =
          (mm > 9 ? mm : "0" + mm) +
          "/" +
          (dd > 9 ? dd : "0" + dd) +
          "/" +
          yy +
          " " +
          (hh > 9 ? hh : "0" + hh) +
          ":" +
          (mn > 9 ? mn : "0" + mn) +
          ":" +
          (ss > 9 ? ss : "0" + ss);
      }

      const PassValues = {
        requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        payerBillSearch: {
          accountReferenceNumber: values.accountNumber,
          uniqueIdentityId: values.socialSecurityNumber,
          dob: mydate1,
          contactNumber: values.phone,
          contactEmail: values.email,
        },
      };

      const UserId = "1212";
      identifyPayerService.addIdentifyPayer(
        PassValues,
        UserId,
        (res) => {
          if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
            sessionStorage.setItem("verifypayer", JSON.stringify(res.data.payer));
            sessionStorage.setItem("Verifieduser", res.data.payer.firstName + " " + res.data.payer.lastName);
            sessionStorage.setItem("payerinfo", JSON.stringify(PassValues.payerBillSearch));
            this.props.history.push("/payment-outstanding");
          } else if (res["data"]?.responseInfo?.status === "FAILED") {
            handleWarning("Please enter valid details!");
          } else {
            handleWarning("Please enter valid details!");
          }
        },
        (error) => {
          console.log(error);
        },
      );
    }
  };

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="card-md registration-card">
              <div className="header">
                <div className="rocket-icon">
                  <img src={RocketIcon} alt="" />
                </div>
                <div className="logo" role="heading" aria-level="1">
                  <img src={Med1Logo} className="img-fluid" alt="Med-1 Solutions Logo" />
                </div>
              </div>
              <div className="main-heading"></div>
              <div className="login-content registration-form-content">
                <Formik
                  enableReinitialize
                  initialValues={{
                    accountNumber: "",
                    socialSecurityNumber: "",
                    ssn: "",
                    patientDOB: null,
                    email: "",
                    phone: "",
                    isCaptchaSelcted: false,
                    recaptcha: "",
                  }}
                  onSubmit={this._handleSubmit}
                  validationSchema={Yup.object().shape({
                    accountNumber: Yup.string()
                      .matches(/^[0-9]+$/, "Enter digits only")
                      .min(5, "Minimum 5 digits required for MED-1 Account Number")
                      .max(8, "Enter 8 digits MED-1 Account Number")
                      .required("MED-1 Account Number is required"),
                    socialSecurityNumber: Yup.string()
                      .max(4, "Enter last 4 digits")
                      .min(4, "Enter last 4 digits")
                      .matches(/^\d+$/, "Enter digits only")
                      .test("oneOfRequired", "Last 4 digits of Social Security Number is required", function (item) {
                        return (
                          this.parent.socialSecurityNumber ||
                          this.parent.email ||
                          this.parent.phone ||
                          this.parent.patientDOB
                        );
                      }),
                    patientDOB: Yup.date()
                      .typeError("Please enter a valid date")
                      .max(endOfDay(new Date()), "Must be a date in the past")
                      .min(moment(new Date(1870, 1, 1)).toDate(), "Date must be greater than 1870")
                      .nullable()
                      .test("oneOfRequired", "Date of Birth is required", function (item) {
                        return (
                          this.parent.socialSecurityNumber ||
                          this.parent.email ||
                          this.parent.phone ||
                          this.parent.patientDOB
                        );
                      }),
                    email: Yup.string()
                      .nullable()
                      .email("Please enter valid email Address")
                      .test("oneOfRequired", "Email is required", function (item) {
                        return (
                          this.parent.socialSecurityNumber ||
                          this.parent.email ||
                          this.parent.phone ||
                          this.parent.patientDOB
                        );
                      }),
                    phone: Yup.string()
                      .max(10, "Please enter 10 digits Phone Number")
                      .min(10, "Please enter 10 digits Phone Number")
                      .matches(/^[0-9]*$/, "Enter digits only")
                      .test("oneOfRequired", "Phone Number is Required", function (item) {
                        return (
                          this.parent.socialSecurityNumber ||
                          this.parent.email ||
                          this.parent.phone ||
                          this.parent.patientDOB
                        );
                      }),
                  })}>
                  {(props) => {
                    const { values, touched, error, handleChange, errors, setFieldValue, handleBlur } = props;

                    return (
                      <Form id="landing-page" className="input-field" noValidate="noValidate">
                        <div className="input-field">
                          <div className="form-group account-number">
                            <div className="label-spacing mt-3">
                              <label htmlFor="accountNumber" className="required">
                                MED-1 Account Number
                              </label>
                              <div>
                                <ImageTooltip img={Med1AccNumberTooltip} placement="right-start" alt="Med1 Bill">
                                  <i className="fas fa-info-circle circle-icon" style={{ cursor: "pointer" }}></i>
                                </ImageTooltip>
                                <em>Need Help?</em>
                              </div>
                            </div>
                            <div className="med1-input ">
                              <OutlinedInput
                                className="form-control signup-fields"
                                id="accountNumber"
                                name="accountNumber"
                                placeholder="Enter MED-1 Account"
                                value={values.accountNumber}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  setFieldValue("accountNumber", e.target.value);
                                  this.setState({
                                    accountNumber: e.target.value,
                                    showloader: true,
                                    submitted: true,
                                  });

                                  let accountNumberCopy = e.target.value;
                                  if (accountNumberCopy?.length >= 5 && accountNumberCopy?.length < 8) {
                                    var PassValues1 = {
                                      requestInfo: requestInfo_PP(
                                        sessionStorage.getItem("jwt-token"),
                                        sessionStorage.getItem("jwt-token"),
                                      ),
                                      payerBillSearch: {
                                        accountReferenceNumber: accountNumberCopy,
                                      },
                                    };

                                    const UserId = "1212";
                                    OutstandingPayerService.PP_Get_Patient_Details(
                                      PassValues1,
                                      UserId,
                                      (res) => {
                                        if (res["data"]?.responseInfo.status === "SUCCESSFUL") {
                                          if (res["data"].patientDetails[0].contactNo === true) {
                                            this.setState({
                                              showcontact: true,
                                              showloader: false,
                                            });
                                          }
                                          if (res["data"].patientDetails[0].contactEmail === true) {
                                            this.setState({
                                              showemail: true,
                                              showloader: false,
                                            });
                                          }
                                          if (res["data"].patientDetails[0].dob === true) {
                                            this.setState({
                                              showdob: true,
                                              showloader: false,
                                            });
                                          }
                                          if (res["data"].patientDetails[0].uniqueIdentifierId === true) {
                                            this.setState({
                                              showssn: true,
                                              showloader: false,
                                            });
                                          }
                                        } else if (res["data"]?.responseInfo.status === "FAILED") {
                                          handleWarning(
                                            <>
                                              {ToastMsg.getDetail_warningMsgWrong} <br />
                                              {ToastMsg.getDetail_warningMsgWrongsecond}
                                              <br />
                                              {ToastMsg.getDetail_warningMsgWrongthird}
                                            </>,
                                          );
                                          this.setState({
                                            showloader: false,
                                          });
                                        } else {
                                          handleWarning(ToastMsg.getDetail_warningMsgWrong);
                                          this.setState({
                                            showloader: false,
                                          });
                                        }
                                      },
                                      (error) => {
                                        console.log(error);
                                      },
                                    );
                                  } else {
                                    this.setState({
                                      showloader: false,
                                    });
                                  }
                                }}
                                onChange={(e) => {
                                  e.preventDefault();
                                  handleChange(e);
                                  setFieldValue("accountNumber", e.target.value);
                                  this.setState({
                                    accountNumber: e.target.value,
                                    showloader: true,
                                    submitted: true,
                                    showssn: false,
                                    showdob: false,
                                    showemail: false,
                                    showcontact: false,
                                  });

                                  let accountNumberCopy = e.target.value;

                                  if (accountNumberCopy?.length > 7) {
                                    var PassValues1 = {
                                      requestInfo: requestInfo_PP(
                                        sessionStorage.getItem("jwt-token"),
                                        sessionStorage.getItem("jwt-token"),
                                      ),
                                      payerBillSearch: {
                                        accountReferenceNumber: accountNumberCopy,
                                      },
                                    };

                                    const UserId = "1212";
                                    OutstandingPayerService.PP_Get_Patient_Details(
                                      PassValues1,
                                      UserId,
                                      (res) => {
                                        if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
                                          if (res["data"]?.patientDetails[0]?.contactNo === true) {
                                            this.setState({
                                              showcontact: true,
                                              showloader: false,
                                            });
                                          }
                                          if (res["data"].patientDetails[0]?.contactEmail === true) {
                                            this.setState({
                                              showemail: true,
                                              showloader: false,
                                            });
                                          }
                                          if (res["data"].patientDetails[0]?.dob === true) {
                                            this.setState({
                                              showdob: true,
                                              showloader: false,
                                            });
                                          }
                                          if (res["data"].patientDetails[0]?.uniqueIdentifierId === true) {
                                            this.setState({
                                              showssn: true,
                                              showloader: false,
                                            });
                                          }
                                        } else if (res["data"]?.responseInfo?.status === "FAILED") {
                                          handleWarning(
                                            <>
                                              {ToastMsg.getDetail_warningMsgWrong}
                                              <br />
                                              {ToastMsg.getDetail_warningMsgWrongsecond}
                                              <br />
                                              {ToastMsg.getDetail_warningMsgWrongthird}
                                            </>,
                                          );
                                          this.setState({
                                            showloader: false,
                                          });
                                        } else {
                                          handleWarning(<>{ToastMsg.getDetail_warningMsgWrong}</>);
                                          this.setState({
                                            showloader: false,
                                          });
                                        }
                                      },
                                      (error) => {
                                        console.log(error);
                                        handleError(error);
                                      },
                                    );
                                  } else {
                                    this.setState({
                                      showcontact: false,
                                      showdob: false,
                                      showssn: false,
                                      showemail: false,
                                      showloader: false,
                                    });
                                  }
                                }}
                                aria-label="text-field"
                                fullWidth
                                error={Boolean(getIn(touched, "accountNumber") && getIn(errors, "accountNumber"))}
                                helperText={
                                  getIn(touched, "accountNumber") &&
                                  getIn(errors, "accountNumber") &&
                                  getIn(errors, "accountNumber")
                                }
                                type={this.state.showMed1AccNumber ? "password" : "text"}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                startAdornment={
                                  <InputAdornment position="end">
                                    <LockOutlinedIcon />
                                  </InputAdornment>
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleMed1AccountNumber}
                                      onMouseDown={this.handleMouseDownPassword}>
                                      {this.state.showMed1AccNumber ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                required
                                inputProps={{
                                  maxLength: 8,
                                }}></OutlinedInput>
                              <ErrorMessage name="accountNumber" className="error-text" component="div" />
                            </div>
                            {this.state.showloader && <SpinnerPage />}
                          </div>
                          {this.state.showssn || this.state.showdob || this.state.showemail || this.state.showcontact ? (
                            <div className="form-row mb-2">
                              <fieldset className="secure-login-fieldset">
                                <legend
                                  className={`${
                                    !(values.socialSecurityNumber || values.phone || values.email || values?.patientDOB)
                                      ? "quickpayErr"
                                      : null
                                  }`}>
                                  For security purposes, please verify your account with one of the following
                                </legend>
                                <div className="row member-details">
                                  {this.state.showssn && (
                                    <div className="col-sm-6 form-group ssn-helper">
                                      <div className="label-spacing">
                                        <label htmlFor="ssn">Social Security Number</label>
                                      </div>
                                      <div className="security-input">
                                        <OutlinedInput
                                          className={`form-control security-input ${
                                            values?.email || values?.phone || values?.patientDOB ? "inputFieldDisable" : ""
                                          }`}
                                          id="socialSecurityNumber"
                                          name="socialSecurityNumber"
                                          placeholder="Enter last 4 digits"
                                          onChange={handleChange}
                                          value={values["socialSecurityNumber"]}
                                          aria-label="text-field"
                                          fullWidth
                                          disabled={values?.patientDOB || values?.email || values?.phone}
                                          error={Boolean(
                                            getIn(touched, "socialSecurityNumber") && getIn(errors, "socialSecurityNumber"),
                                          )}
                                          type={this.state.showSocialSecurityNumber ? "text" : "password"}
                                          endAdornment={
                                            <InputAdornment position="end">
                                              <IconButton
                                                className="p-1"
                                                aria-label="toggle social security number visibility"
                                                onClick={this.handleSocialSecurityNumber}>
                                                {this.state.showSocialSecurityNumber ? <Visibility /> : <VisibilityOff />}
                                              </IconButton>
                                            </InputAdornment>
                                          }
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          inputProps={{
                                            maxLength: 4,
                                            className: "inputFieldProps",
                                          }}></OutlinedInput>
                                        <FormHelperText error>
                                          {getIn(touched, "socialSecurityNumber") &&
                                          getIn(errors, "socialSecurityNumber") ? (
                                            <div>{getIn(errors, "socialSecurityNumber")}</div>
                                          ) : (
                                            ""
                                          )}
                                        </FormHelperText>
                                      </div>
                                    </div>
                                  )}
                                  {this.state.showdob && (
                                    <div className="col-sm-6 form-group">
                                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Grid
                                          container
                                          className={`custom-date-picker date-picker ${
                                            values?.socialSecurityNumber || values?.phone || values?.email
                                              ? "date-picker-disable"
                                              : ""
                                          } 
                                        ${
                                          getIn(touched, "patientDOB") && getIn(errors, "patientDOB")
                                            ? "date-picker-errorBorder"
                                            : ""
                                        }`}>
                                          <label htmlFor="patientDOB">Date of Birth</label>
                                          <DatePicker
                                            disableFuture
                                            name="patientDOB"
                                            autoOk
                                            value={values.patientDOB}
                                            disabled={values?.socialSecurityNumber || values?.phone || values?.email}
                                            error={Boolean(getIn(touched, "patientDOB") && getIn(errors, "patientDOB"))}
                                            required="true"
                                            onChange={(val) => setFieldValue("patientDOB", val)}
                                            InputProps={{
                                              startAdornment: (
                                                <IconButton
                                                  position="end"
                                                  onClick={() => setFieldValue("patientDOB", null)}
                                                  disabled={!values?.patientDOB}
                                                  style={{ order: 2 }}>
                                                  <ClearIcon
                                                    color={!values?.patientDOB ? "disabled" : "#808080"}
                                                    fontSize="small"
                                                  />
                                                </IconButton>
                                              ),
                                            }}
                                            InputAdornmentProps={{
                                              position: "end",
                                              style: { order: 2 },
                                            }}
                                            renderInput={(params) => {
                                              return (
                                                <TextField
                                                  {...params}
                                                  helperText={null}
                                                  margin="dense"
                                                  inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: "MM/DD/YYYY",
                                                  }}
                                                />
                                              );
                                            }}
                                            inputProps={{
                                              className:
                                                values?.socialSecurityNumber || values?.phone || values?.email
                                                  ? "inputFieldProps"
                                                  : "",
                                            }}
                                          />
                                        </Grid>
                                        <FormHelperText component="p" variant="outlined" error="true">
                                          {getIn(touched, "patientDOB") && getIn(errors, "patientDOB") ? (
                                            <div className="h-auto">{getIn(errors, "patientDOB")}</div>
                                          ) : null}
                                        </FormHelperText>
                                      </LocalizationProvider>
                                    </div>
                                  )}
                                  {this.state.showemail && (
                                    <div className="col-sm-6 form-group mt-1">
                                      <div className="label-spacing">
                                        <label htmlFor="eMail">Email Address</label>
                                      </div>
                                      <OutlinedInput
                                        className="form-control security-input"
                                        id="eMail"
                                        name="email"
                                        type="email"
                                        placeholder="Enter Email Address"
                                        onChange={handleChange}
                                        value={values["email"]}
                                        disabled={values?.socialSecurityNumber || values?.phone || values?.patientDOB}
                                        aria-label="text-field"
                                        fullWidth
                                        error={Boolean(getIn(touched, "email") && getIn(errors, "email"))}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        inputProps={{
                                          className:
                                            values?.socialSecurityNumber || values?.phone || values?.patientDOB
                                              ? "inputFieldDisable"
                                              : "",
                                        }}></OutlinedInput>
                                      <ErrorMessage name="email" className="error-text" component="div" />
                                    </div>
                                  )}
                                  {this.state.showcontact && (
                                    <div className="col-sm-6 form-group mt-1">
                                      <div className="label-spacing">
                                        <label htmlFor="phoneNumber">Phone Number</label>
                                      </div>
                                      <OutlinedInput
                                        className="form-control security-input"
                                        id="phoneNumber"
                                        name="phone"
                                        placeholder="Enter Phone Number"
                                        type="text"
                                        onKeyPress={(event) => {
                                          if (!/\d/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                        onChange={handleChange}
                                        value={values["phone"]}
                                        aria-label="text-field"
                                        disabled={values?.socialSecurityNumber || values?.email || values?.patientDOB}
                                        fullWidth
                                        maxLength="10"
                                        error={Boolean(getIn(touched, "phone") && getIn(errors, "phone"))}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        inputProps={{
                                          className:
                                            values?.socialSecurityNumber || values?.email || values?.patientDOB
                                              ? "inputFieldDisable"
                                              : "",
                                          maxLength: 10,
                                        }}
                                        InputProps={{ inputComponent: Numberformat }}></OutlinedInput>
                                      <ErrorMessage name="phone" className="error-text" component="div" />
                                    </div>
                                  )}
                                </div>
                              </fieldset>
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group d-flex flex-column captcha">
                          <Captcha setFieldValue={setFieldValue} />
                          {this.state.displayCaptchaError && values?.recaptcha?.length < 1 ? (
                            <p className="quickpay-captcha">Check reCaptcha is required</p>
                          ) : null}
                        </div>
                        <div className="continue-payement-btn">
                          <button
                            type="submit"
                            onClick={() => this.setState({ displayCaptchaError: true })}
                            className="primary-button btn-block"
                            aria-label="verify">
                            Verify
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
              <div className="text-center">
                <div className="more-info">
                  <p>
                    <Link to="/forgot-account" aria-label="forgot-account">
                      Forgot MED-1 Account Number?
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(QuickPay);
