import { Global_var } from "../global/global_var";
import { loginService } from "./loginService";
import RestDataSource from "./restdatasource";

export const securityImageService = {
  securityImage,
  TokenUser,
  securityImageList,
  updateSecurityImageList,
};

function securityImage(securityData, fn) {
  // debugger;
  // securityImageService.TokenUser((res) => {
  //   if (res.status === "success") {
  //     localStorage.setItem("jwt-token", res.responseObject);
  var url = Global_var.BASEURL + Global_var.URL_GET_SECURITY_IMAGE;
  return new RestDataSource(url, null, "CORE", fn).Store(securityData, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        sessionStorage.setItem("jwt-token", res.headers.token);
      }
      fn(res);
    }
  });
  //   }
  // });
}

function securityImageList(securityData, fn) {
  // debugger;
  // loginService.TokenUser((res) => {
  //   if (res.status === "success") {
  //     localStorage.setItem("jwt-token", res.responseObject);
  var url = Global_var.BASEURL + Global_var.URL_GET_SECURITY_IMAGE_LIST;
  return new RestDataSource(url, null, "CORE", fn).Store(securityData, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        sessionStorage.setItem("jwt-token", res.headers.token);
      }
      fn(res);
    }
  });
  //   }
  // });
}
function updateSecurityImageList(securityData, fn) {
  // debugger;
  // loginService.TokenUser((res) => {
  //   if (res.status === "success") {
  //     localStorage.setItem("jwt-tokencore", res.responseObject);
  var url = Global_var.BASEURL + Global_var.URL_UPDATE_SECURITY_IMAGE_LIST;
  return new RestDataSource(url, null, "CORE", fn).Store(securityData, (res) => {
    if (res != null) {
      // if (res.headers["token"] != null) {
      //   sessionStorage.setItem("jwt-tokencore", res.headers.token);
      // }
      fn(res);
    }
  });
  //   }
  // });
}
function TokenUser(fn) {
  let url = Global_var.BASEURL + Global_var.URL_JWT_TRUST;
  return new RestDataSource(url).GetData((res) => fn(res.data));
}
