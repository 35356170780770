import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import PP_PaymentCreditCard from "../../..";
// import Payment from "./PayNow";
import Pay2 from "./Pay2";
import { getCreditCardDataList } from "../../../../reducer/pp_PaymentCreditCardReducer";
import { fetchCreditCardData, addCreditCardData } from "../../../../action/pp_PaymentCreditCardAction";
import { requestInfo_PP } from "../../../../Common/CommonFn";
import { loginService } from "../../../../services/loginService";
import UpdateTrackingPage from "../../../../containers/UpdateTrackingPage";

const mapStateToProps = (state) => ({
  creditCardData: getCreditCardDataList(state.paymentCreditCardReducer),
  // pending: getCreditCardDataListPending(state.paymentCreditCardReducer),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCreditCardData: fetchCreditCardData,
      addCreditCardData: addCreditCardData,
    },
    dispatch,
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const PP_PaymentCreditCardContainer = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
    }
    componentWillMount() {
      sessionStorage.setItem("screen", "Paymentconfirmation");
    }
    componentDidMount() {
      sessionStorage.setItem("screen", "Paymentconfirmation");
      var queryString = "";
      queryString = window.location.search.split("&");
      // alert(queryString)
      if (queryString[0] !== "" && queryString[0] !== null) {
        var urlAppId = queryString ? queryString[0].replace("?id=", "") : "";
        var urlPartyId = queryString[1] ? queryString[1].replace("p=", "") : "";
        var urlAmount = queryString[2] ? queryString[2].replace("a=", "") : "";
        var urlSubscriberId = queryString[3] ? queryString[3].replace("s=", "") : "";
        var urlMethod = queryString[4] ? queryString[4].replace("b=", "") : "";
        var urlPlanID = queryString[7] ? queryString[7].replace("pl=", "") : "";
        var urlPriceSubID = queryString[8] ? queryString[8].replace("ps=", "") : "";

        const AppId = atob(urlAppId);
        const PartyId = atob(urlPartyId);
        const Amount = atob(urlAmount);
        const SubId = atob(urlSubscriberId);
        const Method = atob(urlMethod);
        const PlanId = atob(urlPlanID);
        const PriceSubscriptionId = atob(urlPriceSubID);
        sessionStorage.setItem("SIC_Info", AppId);

        sessionStorage.setItem("buid", 6);
        // alert(QueryString)
        debugger;
        loginService.TokenUser((res) => {
          if (res.status === "success") {
            debugger;
            sessionStorage.setItem("jwt-token", res.responseObject);
            sessionStorage.setItem("jwt-tokencore", res.responseObject);
          }
        }, console.log("Token Error"));
      } else {
        var userinfo = JSON.parse(sessionStorage.getItem("payerinfo"));

        const jtiToken =
          sessionStorage.getItem("jti-token") === null
            ? sessionStorage.getItem("jwt-token")
            : sessionStorage.getItem("jti-token");
        const Token = sessionStorage.getItem("jwt-token") === null ? "" : sessionStorage.getItem("jwt-token");

        var userpartyinfo = JSON.parse(sessionStorage.getItem("verifypayer"));

        if (userpartyinfo !== null) {
          var PassValues = {
            requestInfo: requestInfo_PP(Token, jtiToken),
            paymentInfoSearch: {
              paymentMethodId: "75001",
              partyId: userpartyinfo.partyId,
              // "codeTypeName": "forte payment method"
            },
          };
          const UserId = 1234;
          const { fetchCreditCardData } = this.props;
          fetchCreditCardData(PassValues, UserId);
        }
      }
    }
    render() {
      const { creditCardData } = this.props;
      let selectedCarddetails = this.props.location?.state?.paymentMethod;
      let passValues = this.props.location?.state?.PassValues;
      const payInFuture = this.props.location?.state?.payInFuture;
      // let passValues = this.props?.props?.PassValues;
      // const payInFuture = this.props?.props?.payInFuture;
      let selectedCard = "";
      let cardNumber = "";
      let cardMonth = "";
      let cardYear = "";
      if (selectedCarddetails && selectedCarddetails !== "creditCard" && selectedCarddetails !== "echeck") {
        cardNumber = selectedCarddetails && selectedCarddetails.split("?")[0];
        cardMonth = selectedCarddetails && selectedCarddetails.split("?")[1].split("/")[0];
        cardYear = selectedCarddetails && selectedCarddetails.split("?")[1].split("/")[1];
      }

      if (cardNumber && cardMonth && cardYear) {
        selectedCard = creditCardData?.cardAccount?.filter(
          (item) => item.cardNumber === cardNumber && item.cardExpiryMonth === cardMonth && item.cardExpiryYear === cardYear,
        );
      }

      return (
        <React.Fragment>
          {/* {<Header {...this.props} />} */}

          {/* <Payment
                  creditCardData={creditCardData}
                  passValues={passValues}
                  payInFuture={payInFuture}
                  selectedCard={selectedCard}
                  {...this.props}
                /> */}
          <Pay2
            creditCardData={creditCardData}
            passValues={passValues}
            payInFuture={payInFuture}
            selectedCard={selectedCard}
            selectedCarddetails={selectedCarddetails}
            {...this.props}
          />

          {/* {<PP_Footer />} */}

          {/* <TrackingUSer/> */}
          <UpdateTrackingPage path={window.location.href.slice(22)} />
        </React.Fragment>
      );
    }
  },
);

export default PP_PaymentCreditCardContainer;
