import React from "react";
import IntlMessages from "../../../util/IntlMessages";
import Med1Logo from "../../../assets/images/Med1Logo.png";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getIn } from "formik";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Link } from "react-router-dom";
import { getLogin } from "../../../reducer/loginReducer";
import { login, logoutUser } from "../../../action/loginAction";
import { loginService } from "../../../core_services/adminloginService";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getImage } from "../../../action/securityImageAction";
import InputAdornment from "@material-ui/core/InputAdornment";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import RocketIcon from "../../../assets/images/quick-pay-rocket-icon.svg";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Checkbox } from "@material-ui/core";
import LandingPage from "../../Pages/LandingPage/QuickPay";
import QucikPayLogin from "../../Pages/LandingPage/QuickPayLogin";
import { handleSuccess, ToastContainer, handleError, handleWarning } from "../../../components/Notifications/Toast";
import SecurityImageContainer from "../../../containers/securityImage/securityImageContainer";
import WhiteLableContainer from "../../../containers/whiteLable/whiteLableContainer";
import SpinnerPage from "../../../Common/PP_loader";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { IconButton, FormHelperText } from "@material-ui/core";
import { Translation } from "react-i18next";
import { verifyQr } from "../../../action/TwoFactorAction";
import {
  error,
  errorNotification,
  success,
  successNotification,
} from "../../../components/Admin_Notification/Admin_Notification";

export class UserName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trylogin: false,
      label: true,
      showPassword: false,
      showloader: false,
      showHideUsername: true,
      //2fa state
      isAuthenticate: false,
      isOtpValidated: false,
      qrSetUpKey: "",
      email: "",
    };
  }
  componentWillMount() {
    localStorage.clear();
    sessionStorage.clear();
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
  }
  componentDidMount() {
    sessionStorage.setItem("currentUserRole", "Customer");

    const queryString = window.location.search;
    const urlParameter = new URLSearchParams(queryString);

    var businessid = "2";
    var bussid = urlParameter.get("buid");
    if (queryString && bussid) {
      sessionStorage.setItem("buid", bussid);
    } else {
      sessionStorage.setItem("buid", businessid);
    }

    loginService.TokenUser((resToken) => {
      if (resToken["status"] === "success") {
        sessionStorage.setItem("jwt-token", resToken.responseObject);
      }
    });
  }

  handleQuickPay = () => {
    this.setState({ label: true });
  };
  handleLabel = () => {
    this.setState({ label: false });
  };
  _handleClickShowPassword = () => {
    this.setState({
      showPassword: this.state.showPassword ? false : true,
    });
  };
  render() {
    return (
      <>
        {this.state.showloader && <SpinnerPage />}
        <Formik
          validateOnChange
          initialValues={{
            username: "",
            password: "",
            handleContinueButton: "",
            validImage: false,
            seurityImage: "",
            showPassword: false,
            passwordCheck: false,
            otp: "",
          }}
          validationSchema={Yup.object().shape({
            username: Yup.string().max(50, "Must be less than 50 characters").required("Please enter the Username"),
            password: Yup.string().required("Please enter the Password"),
            passwordCheck: Yup.boolean().oneOf([true], "Please Check Security image").nullable(),
            otp:
              this.state.isAuthenticate &&
              Yup.string().required("OTP is required").min(6, "OTP must be 6 Characters").max(6, "OTP must be 6 Characters"),
          })}
          onSubmit={(values, { resetForm }, actions) => {
            this.setState({
              trylogin: true,
              showloader: true,
            });
            var userLoginName = values.username,
              userPassword = values.password,
              userName = values.username;
            const { login } = this.props;

            loginService.logout({ userName }, (userData) => {
              login(
                { userLoginName, userPassword },
                (userData) => {
                  this.setState({
                    isAuthenticate: userData?.responseObject?.isAuthenticate,
                    qrSetUpKey: userData?.responseObject?.key,
                    email: userData?.responseObject?.emailId,
                  });
                  if (this.state.isAuthenticate === 1 ? this.state.isOtpValidated : true) {
                    if (userData == undefined) {
                      this.setState({
                        trylogin: false,
                      });
                      handleWarning("Service not running..! ");
                      this.props.history.push("/signin");
                    } else if (userData.status === "success") {
                      this.setState({
                        trylogin: false,
                        isAuthenticate: userData?.responseObject?.isAuthenticate,
                      });
                      var tempData = JSON.stringify(userData.responseObject);
                      sessionStorage.setItem("userData", tempData);
                      sessionStorage.setItem("loginDetails", tempData);

                      sessionStorage.setItem("Userpartyid", userData.responseObject.partyId);
                      sessionStorage.setItem("profileEmail", JSON.parse(tempData)?.emailId);

                      var tempData = userData.responseObject;

                      if (tempData.forcePasswordChangeOnNextLoginFlag === "1") {
                        sessionStorage.setItem("Forcepwdchange", true);
                        window.location.replace("/change-password");
                      } else {
                        if (tempData.role[0].roleId === 7) {
                          handleWarning(<>{(t, { i18n }) => <span>{t("No_permission")}</span>}</>);
                        } else if (tempData.role[0].roleId === 2) {
                          handleWarning(<>{(t, { i18n }) => <span>{t("No_permission")}</span>}</>);
                        } else if (tempData.role[0].roleId === 4) {
                          sessionStorage.setItem("currentUserRole", "Customer");
                          sessionStorage.setItem("backToSecureLogin", "CustomerSignIn");
                          this.props.history.push("/home/billing");
                        } else if (tempData.role[0].roleId === 1) {
                          window.location.replace("/signin");
                        } else {
                          handleWarning("You do not have permission to access this page");
                          this.setState({
                            showloader: false,
                          });
                        }
                      }
                    } else if (userData.reasonCode === "SM-03") {
                      this.setState({
                        trylogin: false,
                        showloader: false,
                        showHideUsername: true,
                      });
                      handleError(
                        "Please enter valid username and password! Your account will be blocked after 10 consecutive wrong attempts",
                      );
                      resetForm();
                    } else if (userData.reasonCode === "SM-02") {
                      this.setState({
                        trylogin: false,
                        showloader: false,
                      });
                      handleError("Something went wrong, please try again..! ");
                    } else if (userData.reasonCode === "SM-07") {
                      this.setState({
                        trylogin: false,
                      });
                      loginService.logout({ userName }, (userData) => {
                        if (userData.status === "success") {
                          handleWarning("Last session closed Unexpectedly. Clearing Previous session..! ");
                        } else {
                          sessionStorage.clear();
                          handleWarning("Please login again!");
                          resetForm();
                        }
                        window.location.replace("/signin");
                      });
                    } else if (userData.status === "fail") {
                      this.setState({
                        trylogin: false,
                        showloader: false,
                      });
                      if (userData.reasonCode === "SM-12") {
                        handleWarning(
                          "Your account has exceeded the maximum login attempts and is blocked. Please contact system Administrator to unblock your account.",
                        );
                      } else {
                        handleWarning(userData?.reasonText);
                        this.setState({
                          showHideUsername: true,
                        });
                      }
                      resetForm();
                    } else {
                      this.setState({
                        trylogin: false,
                        showloader: false,
                      });
                    }
                    this.setState({ isOtpValidated: false });
                  } else {
                    this.setState({
                      trylogin: false,
                      showloader: false,
                    });
                  }
                },
                (error) => {
                  console.log(error);
                },
              );
            });
          }}
          render={({ errors, touched, handleSubmit, handleChange, isValid, values, setFieldValue, handleBlur }) => (
            <React.Fragment>
              <div className="page-wrapper vertical-height" role="main">
                <a href="/">
                  <WhiteLableContainer></WhiteLableContainer>
                </a>
                <div className="btn-Login-Quickpay">
                  <button
                    className={this.state.label ? `primary-button-login-notactive` : `primary-button-login`}
                    aria-label="continue to SignIn"
                    onClick={this.handleLabel}>
                    Secure Login
                  </button>
                  <button
                    className={this.state.label ? `primary-button-login` : `primary-button-login-notactive`}
                    aria-label="continue to SignIn"
                    onClick={this.handleQuickPay}>
                    Quick Pay
                  </button>
                </div>
                {this.state.label === false ? (
                  <div className="container-fluid">
                    <div className="row justify-content-center">
                      <div className="card-sm">
                        <div className="header" role="heading" aria-level="1">
                          <div className="rocket-icon">
                            <img src={RocketIcon} alt="" />
                          </div>
                          <div className="logo">
                            <img src={Med1Logo} className="img-fluid" alt="Med-1 Solutions Logo" />
                          </div>
                        </div>
                        <div className="login-content">
                          <Form onSubmit={handleSubmit} id="login-form" className="input-field" noValidate="noValidate">
                            {this.state.showHideUsername ? (
                              <div className="form-group">
                                <div className="row m-0 justify-content-between align-items-center">
                                  <label htmlFor="username" className="required">
                                    <IntlMessages id={"signin.userNameOrEmail"} />
                                  </label>
                                </div>
                                <OutlinedInput
                                  className="form-control signup-fields"
                                  placeholder="Please Enter Username"
                                  name="username"
                                  id="username"
                                  autocomplete="off"
                                  onChange={(event) => {
                                    setFieldValue((values.username = event.target.value));
                                    setFieldValue((values.handleContinueButton = true));
                                  }}
                                  onBlur={handleBlur}
                                  aria-label="username"
                                  fullWidth
                                  error={Boolean(getIn(touched, "username") && getIn(errors, "username"))}
                                  helperText={
                                    getIn(touched, "username") && getIn(errors, "username") && getIn(errors, "username")
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  startAdornment={
                                    <InputAdornment position="end">
                                      <PermIdentityIcon />
                                    </InputAdornment>
                                  }
                                  required="true"></OutlinedInput>
                                <FormHelperText style={{ marginLeft: "4px" }} error>
                                  {getIn(touched, "username") && getIn(errors, "username") && getIn(errors, "username")}
                                </FormHelperText>
                              </div>
                            ) : !this.state.isAuthenticate ? (
                              <div>
                                <div className="row">
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-2">
                                        <SecurityImageContainer></SecurityImageContainer>{" "}
                                      </div>
                                      <div className="col-10 security-check">
                                        <Checkbox
                                          onChange={handleChange}
                                          checked={values.passwordCheck}
                                          inputProps={{ "aria-label": "controlled" }}
                                          name="passwordCheck"
                                          className="signin-check"
                                        />
                                        <b>
                                          {" "}
                                          Security Image{" "}
                                          {getIn(touched, "passwordCheck") && getIn(errors, "passwordCheck") ? (
                                            <span className="accept-security-message">{`*`}</span>
                                          ) : (
                                            <span className="accept-security-message">{"*"}</span>
                                          )}
                                        </b>
                                        <div className="form-group">
                                          <p className="mb-0">
                                            Is this the security image that you selected when you registered?
                                          </p>
                                          <div className="accept-security-message">
                                            {" "}
                                            {getIn(touched, "passwordCheck") && getIn(errors, "passwordCheck") ? (
                                              <div>
                                                <p className="error-text">You must accept the Security Image</p>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row m-0 justify-content-between align-items-center">
                                      <label htmlFor="password" className="required">
                                        {" "}
                                        <IntlMessages id={"signin.password"} />
                                      </label>
                                    </div>
                                    <OutlinedInput
                                      className="form-control signup-fields"
                                      id="password"
                                      name="password"
                                      placeholder="Please Enter Password"
                                      onChange={handleChange}
                                      value={values["password"]}
                                      aria-label="text-field"
                                      fullWidth
                                      type={this.state.showPassword ? "text" : "password"}
                                      error={Boolean(getIn(touched, "password") && getIn(errors, "password"))}
                                      helperText={
                                        getIn(touched, "password") && getIn(errors, "password") && getIn(errors, "password")
                                      }
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      startAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => this._handleClickShowPassword()}>
                                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      required="true"
                                      inputProps={{
                                        maxLength: 50,
                                      }}></OutlinedInput>
                                    <FormHelperText>
                                      {getIn(touched, "password") && getIn(errors, "password") && getIn(errors, "password")}
                                    </FormHelperText>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="row">
                                  <div className="form-group col-12">
                                    <div className="row m-0 justify-content-between align-items-center">
                                      <label htmlFor="password" className="required">
                                        {" "}
                                        <IntlMessages id={"signin.otp"} />
                                      </label>
                                    </div>
                                    <OutlinedInput
                                      className="form-control signup-fields"
                                      id="otp"
                                      name="otp"
                                      placeholder="Enter OTP From Authenticator App"
                                      onChange={handleChange}
                                      value={values["otp"]}
                                      aria-label="text-field"
                                      fullWidth
                                      type="number"
                                      error={Boolean(getIn(touched, "otp") && getIn(errors, "otp"))}
                                      helperText={getIn(touched, "otp") && getIn(errors, "otp") && getIn(errors, "otp")}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      required="true"
                                      inputProps={{
                                        maxLength: 50,
                                      }}></OutlinedInput>
                                    <FormHelperText className="component-error-text">
                                      {getIn(touched, "otp") && getIn(errors, "otp") && getIn(errors, "otp")}
                                    </FormHelperText>
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className="mt-2">
                              {values.handleContinueButton ? (
                                <button
                                  type="button"
                                  onClick={(event) => {
                                    if (values.username !== "" && values.username.length <= 50) {
                                      var userName = values.username.toLowerCase();
                                      this.props.getImage({ userName }, (imageData) => {
                                        this.setState({
                                          showHideUsername: false,
                                        });
                                        setFieldValue((values.handleContinueButton = false));
                                      });
                                    }
                                  }}
                                  className="primary-button btn-block"
                                  aria-label="continue to SignIn">
                                  Continue
                                </button>
                              ) : !this.state.isAuthenticate === 1 ? (
                                <button type="submit" className="primary-button btn-block" aria-label="continue to SignIn">
                                  Continue
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  onClick={() => {
                                    if (values?.otp && !errors?.otp) {
                                      const elements = {
                                        authenticationCode: values.otp,
                                        emailId: this.state.email,
                                        key: this.state.qrSetUpKey,
                                        passWord: values.password,
                                      };
                                      this.props.verifyQr(elements, (res) => {
                                        if (res.data.responseInfo.status === "SUCCESSFUL") {
                                          this.setState({
                                            isOtpValidated: true,
                                          });
                                          success(
                                            <Translation>
                                              {(t, { i18n }) => <div>OTP verification successful</div>}
                                            </Translation>,
                                            successNotification,
                                          );
                                        } else if (res.data.responseInfo.status === "FAILED") {
                                          handleError(
                                            <>
                                              <b>Error!</b>
                                              <div>{res.data.responseInfo.responseMessage[0]}</div>
                                            </>,
                                          );
                                        }
                                      });
                                    }
                                  }}
                                  className="primary-button btn-block"
                                  aria-label="continue to SignIn">
                                  Continue
                                </button>
                              )}
                            </div>
                          </Form>
                        </div>
                        <div className="text-center">
                          {this.state.showHideUsername ? (
                            <div className="forgot-password">
                              <p>
                                <Link to="/forgot-user" aria-label="forgot user">
                                  Forgot Username?
                                </Link>
                              </p>
                            </div>
                          ) : (
                            <div className="forgot-password">
                              <p>
                                <Link to="/forgot-password" aria-label="forgot user">
                                  Forgot Password?
                                </Link>
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="text-center">
                          <div className="more-info">
                            <p>
                              Don’t have account with us?{" "}
                              <Link to="/registration" aria-label="sign up here link">
                                Sign Up here
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <QucikPayLogin history={this.props?.history} />
                )}
              </div>
            </React.Fragment>
          )}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  ...getLogin(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login: login,
      getImage: getImage,
      logoutUser: logoutUser,
      verifyQr: verifyQr,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserName);
