import React, { useState, createRef, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import AssessmentIcon from "@material-ui/icons/Assessment";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { Formik, Form, ErrorMessage } from "formik";
import { TextField, MenuItem, FormControl, Select, IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { getCommunicationTypeReportList } from "../../../../../action/ReportsAction";
import { handleWarning, handleError } from "../../../../../components/Notifications/Toast";
import SpinnerPage from "../../../../../Common/PP_loader";
import moment from "moment";
import { ExportToExcel } from "../../../../../Common/exportToExcel";
import { capitalize } from "../../../../../util/Captilize";
import ClearIcon from "@material-ui/icons/Clear";
import OptInTable from "./OptInTable";
import FlakyIcon from "@mui/icons-material/Flaky";
import * as Yup from "yup";
import NumberFormat from "react-number-format";
const PATransaction = () => {
  let initialValues = {
    accountReferenceNumber: "",
    firstName: "",
    lastName: "",
    phoneNum: "",
    email: "",
    optedStatus: "none",
    startDate: null,
    endDate: null,
  };
  const validation = Yup.object().shape({
    email: Yup.string().email("Enter a valid email"),
  });
  const dispatch = useDispatch();
  const [communicationsTypeReportData, setCommunicationsTypeReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleCommunicationsTypeReportDownload = (values) => {
    setIsLoading(true);
    dispatch(
      getCommunicationTypeReportList(
        {
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNum,
          email: values.email,
          accountReferenceNumber: values.accountReferenceNumber,
          optOut: values?.optedStatus === "none" ? "" : values.optedStatus,
          pageNo: 0,
          pageSize: -1,
          startDate: values?.startDate ? moment(values?.startDate).format("YYYY-MM-DD") : "",
          endDate: values?.endDate ? moment(values?.endDate).format("YYYY-MM-DD") : "",
        },
        (res) => {
          setIsLoading(false);
          if (res.data?.responseInfo?.status === "SUCCESSFUL") {
            ExcelData(res?.data?.OptOutReportResponseDetails);
            res.data.responseInfo?.responseMessage?.length > 0 && handleWarning(res.data.responseInfo?.responseMessage[0]);
          } else if (res.data?.responseInfo?.status === "FAILED") {
            handleError(res.data.responseInfo?.responseMessage[0]);
          } else {
            handleError(res?.message);
          }
        },
      ),
    );
  };

  const ExcelData = (data) => {
    const DataToExp = data.map((obj) => {
      const { accountReferenceNumber, firstName, lastName, phoneNumber, email, optOut, requestedDate } = obj;

      const newObject = {
        "Seed Account Number": accountReferenceNumber ? accountReferenceNumber : "-",
        "First Name": firstName ? capitalize(firstName) : "-",
        "Last Name": lastName ? capitalize(lastName) : "-",
        "Phone Number": phoneNumber ? phoneNumber : "-",
        "Email Id": email ? email : "-",
        "Opted In/Out": optOut === "0" ? "Opt-In" : optOut === "1" ? "Opt-Out" : "-",
        "Requested Date": requestedDate ? requestedDate : "-",
      };
      return newObject;
    });

    ExportToExcel(DataToExp, "Opt-in/Opt-out by Communication Type");
  };

  const paymentMethodOptions = [
    { valueLabel: "Select Opt-in/Opt-out", value: "none", disable: true },
    { valueLabel: "Opt-in", value: "0", disable: false },
    { valueLabel: "Opt-out", value: "1", disable: false },
  ];

  const handleSubmit = (values) => {
    if (
      values.accountReferenceNumber ||
      values.firstName ||
      values.lastName ||
      values.phoneNum ||
      values.email ||
      values.optedStatus !== "none" ||
      values.startDate ||
      values.endDate
    ) {
      setSearchData(values);
      setIsSubmit(true);
    } else {
      handleWarning("Please fill at least one field to search");
    }
  };

  return (
    <div className="transaction-card patient-details" role="main">
      <div className="main-container-card">
        <div role="heading" aria-level="1" className="heading-style mb-3">
          <FlakyIcon className="mr-2" />
          <h2 className="heading-style">Reports: Opt-in/Opt-out by communication Type</h2>
        </div>
        <div>
          <div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validation}>
              {(props) => {
                const { values, touched, error, handleChange, errors, setFieldValue } = props;
                return (
                  <>
                    <Form id="transaction-search" className="input-field" noValidate="noValidate">
                      <div className="form-row">
                        <div className="col-xl-3 col-lg-3 col-sm-6 col-md-4 form-group ">
                          <FormControl variant="outlined" className="w-100" fullWidth>
                            <label htmlFor="accountReferenceNumber" className="form-label w-100">
                              Seed Account Number
                            </label>
                            <Grid container className="date-picker">
                              <TextField
                                id="accountReferenceNumber"
                                name="accountReferenceNumber"
                                placeholder="Enter Seed Account Number"
                                type="text"
                                onChange={handleChange}
                                value={values["accountReferenceNumber"]}
                                aria-label="text-field"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  inputComponent: NumberFormat,
                                }}></TextField>
                            </Grid>
                          </FormControl>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-sm-6 col-md-4 form-group ">
                          <FormControl variant="outlined" className="w-100" fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Grid container className="date-picker">
                                <label htmlFor="startDate" className="form-label w-100">
                                  Start Date
                                </label>
                                <DatePicker
                                  name="startDate"
                                  autoOk
                                  value={values.startDate}
                                  onChange={(val) => setFieldValue("startDate", val)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      helperText={null}
                                      margin="dense"
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "MM/DD/YYYY",
                                      }}
                                    />
                                  )}
                                  InputProps={{
                                    startAdornment: (
                                      <IconButton
                                        position="end"
                                        onClick={() => setFieldValue("startDate", null)}
                                        style={{ order: 1, padding: 0 }}>
                                        <ClearIcon fontSize="small" />
                                      </IconButton>
                                    ),
                                  }}
                                  InputAdornmentProps={{
                                    position: "end",
                                    style: { order: 2 },
                                  }}
                                />
                              </Grid>
                            </LocalizationProvider>
                          </FormControl>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-sm-6 col-md-4 form-group ">
                          <FormControl variant="outlined" className="w-100" fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Grid container className="date-picker">
                                <label htmlFor="endDate" className="form-label w-100">
                                  End Date
                                </label>
                                <DatePicker
                                  name="endDate"
                                  autoOk
                                  value={values.endDate}
                                  minDate={values.startDate - 1}
                                  onChange={(val) => setFieldValue("endDate", val)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      helperText={null}
                                      margin="dense"
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "MM/DD/YYYY",
                                      }}
                                    />
                                  )}
                                  InputProps={{
                                    startAdornment: (
                                      <IconButton
                                        position="end"
                                        onClick={() => setFieldValue("endDate", null)}
                                        style={{ order: 1, padding: 0 }}>
                                        <ClearIcon fontSize="small" />
                                      </IconButton>
                                    ),
                                  }}
                                  InputAdornmentProps={{
                                    position: "end",
                                    style: { order: 2 },
                                  }}
                                />
                              </Grid>
                            </LocalizationProvider>
                          </FormControl>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-sm-6 col-md-4 form-group ">
                          <FormControl variant="outlined" className="w-100" fullWidth>
                            <label htmlFor="firstName" className="form-label w-100">
                              First Name
                            </label>

                            <Grid container className="date-picker">
                              <TextField
                                id="firstName"
                                name="firstName"
                                placeholder="Enter First Name"
                                onChange={handleChange}
                                value={values["firstName"]}
                                aria-label="text-field"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}></TextField>
                            </Grid>
                          </FormControl>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-sm-6 col-md-4 form-group ">
                          <FormControl variant="outlined" className="w-100" fullWidth>
                            <label htmlFor="lastName" className="form-label w-100">
                              Last Name
                            </label>

                            <Grid container className="date-picker">
                              <TextField
                                id="lastName"
                                name="lastName"
                                placeholder="Enter Last Name"
                                onChange={handleChange}
                                value={values["lastName"]}
                                aria-label="text-field"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}></TextField>
                            </Grid>
                          </FormControl>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-sm-6 col-md-4 form-group ">
                          <FormControl variant="outlined" className="w-100" fullWidth>
                            <label htmlFor="phoneNum" className="form-label w-100">
                              Phone Number
                            </label>
                            <Grid container className="date-picker">
                              <TextField
                                id="phoneNum"
                                name="phoneNum"
                                placeholder="Enter Phone Number"
                                onChange={handleChange}
                                value={values["phoneNum"]}
                                aria-label="text-field"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  inputComponent: NumberFormat,
                                }}></TextField>
                            </Grid>
                          </FormControl>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-sm-6 col-md-4 form-group ">
                          <FormControl variant="outlined" className="w-100" fullWidth>
                            <label htmlFor="email" className="form-label w-100">
                              Email Id
                            </label>
                            <Grid container className="date-picker">
                              <TextField
                                id="email"
                                name="email"
                                placeholder="Enter Email Id"
                                onChange={handleChange}
                                value={values["email"]}
                                aria-label="text-field"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}></TextField>
                            </Grid>
                          </FormControl>
                          <ErrorMessage component="div" name="email" className="error-text" />
                        </div>
                        <div className="col-xl-3 col-lg-3 col-sm-6 col-md-4 form-group ">
                          <FormControl variant="outlined" className="w-100" fullWidth>
                            <label htmlFor="optedStatus" className="form-label">
                              Opt-in/Opt-out
                            </label>
                            <Select
                              fullWidth
                              name="optedStatus"
                              className="form-select form-control"
                              onChange={handleChange}
                              value={values["optedStatus"]}>
                              {paymentMethodOptions.map((option) => {
                                return (
                                  <MenuItem value={option.value} key={option.key} disabled={option.disable}>
                                    {option.valueLabel}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <div className="payment-history-btns">
                        <button aria-label="Generate Report" type="submit" className="primary-button themeing-buttons">
                          Search
                        </button>
                        <button
                          aria-label="Reset"
                          type="reset"
                          className="secondary-button mx-2"
                          onClick={() => {
                            setCommunicationsTypeReportData([]);
                            setSearchData(null);
                          }}>
                          Reset
                        </button>
                        {communicationsTypeReportData.length > 0 && (
                          <button
                            aria-label="Generate Report"
                            className="primary-button themeing-secondary-buttons"
                            onClick={() => {
                              handleCommunicationsTypeReportDownload(values);
                            }}>
                            Download
                          </button>
                        )}
                      </div>
                    </Form>
                    <Grid item>
                      <div className="payplan-table ">
                        {searchData ? (
                          <OptInTable
                            isSubmit={isSubmit}
                            setCommunicationsTypeReportData={setCommunicationsTypeReportData}
                            searchData={searchData}
                            setIsSubmit={setIsSubmit}
                          />
                        ) : null}
                      </div>
                    </Grid>
                  </>
                );
              }}
            </Formik>
          </div>

          {isLoading && <SpinnerPage />}
        </div>
      </div>
    </div>
  );
};

export default PATransaction;
