import React, { useState, createRef, useEffect, useRef } from "react";

import { handleSuccess, ToastContainer, handleWarning, handleError } from "../../../../components/Notifications/Toast";
// import { Translation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";

import { Tooltip } from "@material-ui/core";

import { tableIcons } from "../../../../components/TableIcons";
import { capitalize } from "../../../../util/Captilize";
import { httpPaginationRequest } from "../../../../Common/httpPaginationRequest";
import { Global_var } from "../../../../global/global_var";
import moment from "moment";
import { requestInfo_PP } from "../../../../Common/CommonFn";
import PatchedPagination from "../../../../Common/TablePagination";
const PatientSearchTable = ({ tableData, searchData, viewPatient, verifyAndPayAccess, isSubmit, updateState }) => {
  const tableRef = createRef();
  const [rows, setRows] = useState(5);
  //   const [page, setPage] = useState(0);
  const page = useRef(null);
  const columns = [
    {
      title: "First Name",
      minWidth: 90,
      width: 90,
      cellStyle: { textAlign: "left" },
      render: (rowData) => {
        return <>{capitalize(rowData?.payer?.firstName)}</>;
      },
    },
    {
      title: "Last Name",
      minWidth: 90,
      width: 90,
      cellStyle: { textAlign: "left" },
      render: (rowData) => {
        return <>{capitalize(rowData?.payer?.lastName)}</>;
      },
    },

    {
      title: "User Name",
      minWidth: 90,
      width: 90,
      cellStyle: { textAlign: "left" },
      render: (rowData) => {
        return <>{capitalize(rowData?.payer?.userloginName)}</>;
      },
    },

    {
      title: "MED-1 Account Number",
      field: "accountReferenceNumber",
      minWidth: 170,
      width: 170,
      type: "numeric",
    },
    {
      title: "Social Security Number",
      field: "uniqueIdentifierId",
      minWidth: 170,
      width: 170,
      type: "numeric",
      render: (rowData) => {
        return <>{"*****" + rowData?.payer?.uniqueIdentifierId?.slice(-4)}</>;
      },
    },

    {
      title: "Email Address",
      field: "contactEmail",
      minWidth: 90,
      width: 90,
      cellStyle: { textAlign: "left" },
      render: (rowData) => {
        return <>{rowData?.payer?.contactEmail}</>;
      },
    },
    {
      title: "Phone Number",
      field: "contactNo",
      minWidth: 90,
      width: 90,
      type: "numeric",
      render: (rowData) => {
        return <>{rowData?.payer?.contactNo}</>;
      },
    },
    {
      title: "Date of Service",
      field: "billDate",
      minWidth: 90,
      width: 90,
      cellStyle: { textAlign: "center" },
      render: (rowData) => {
        return <>{moment(rowData.billDate).format("MM/DD/YYYY")}</>;
      },
    },
  ];

  const isInitialRender = useRef(false);
  useEffect(() => {
    isInitialRender.current = true;
  }, []);
  useEffect(() => {
    if (tableRef?.current && isSubmit && isInitialRender.current === false) {
      if (searchData && Object.keys(searchData).length > 0) {
        tableRef.current.onQueryChange();
      }
    }
    if (isSubmit && isInitialRender.current) {
      isInitialRender.current = false;
    }
  }, [isSubmit, searchData]);
  return (
    <Grid item>
      <MaterialTable
        actions={[
          (rowData) => ({
            icon: () => (
              <div className="d-block" aria-label="pay">
                <Tooltip title="Pay" aria-label="pay">
                  <button disabled={!verifyAndPayAccess} className="primary-button themeing-buttons">
                    Verify {"&"} Pay
                  </button>
                </Tooltip>
              </div>
            ),
            onClick: () => viewPatient(rowData),
          }),
        ]}
        icons={tableIcons}
        columns={columns}
        tableRef={tableRef}
        localization={{
          pagination: {
            firstAriaLabel: "First Page",
            previousAriaLabel: "Previous Page",
            nextAriaLabel: "Next Page",
            lastAriaLabel: "Last Page",
          },
        }}
        data={(query) =>
          new Promise((resolve) => {
            if (!searchData) {
              resolve({
                data: [],
                page: 0,
                totalCount: 0,
              });
            } else {
              var Dob = searchData.dateOfBirth;
              var DateOfBirth = "";
              if (Dob) {
                var mm = Dob.getMonth() + 1;
                var dd = Dob.getDate();
                var yy = Dob.getFullYear();
                var hh = Dob.getHours();
                var mn = Dob.getMinutes();
                var ss = Dob.getSeconds();

                DateOfBirth =
                  (mm > 9 ? mm : "0" + mm) +
                  "/" +
                  (dd > 9 ? dd : "0" + dd) +
                  "/" +
                  yy +
                  " " +
                  (hh > 9 ? hh : "0" + hh) +
                  ":" +
                  (mn > 9 ? mn : "0" + mn) +
                  ":" +
                  (ss > 9 ? ss : "0" + ss);
              }
              page.current = isSubmit ? 0 : query.page;
              const passValues = {
                requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
                billSearch: {
                  billId: "",
                  payerPartyId: "",
                  payerAccountId: "",
                  masterAccountReferenceNumber: "",
                  accountReferenceNumber: searchData.accountNumber,
                  status: "",
                  uniqueIdentityId: searchData.socialSecurityNumber,
                  isActive: [1],
                  dateOfBirth: DateOfBirth,
                  firstName: searchData.firstName,
                  lastName: searchData.lastName,
                  emailId: searchData.emailid,
                  contactNo: searchData.contactno,
                  pageNumber: isSubmit ? 0 : query.page,
                  pageSize: query.pageSize ? query.pageSize : null,
                  noofpages: "",
                },
              };
              httpPaginationRequest(Global_var.URL_GET_PATIENT_DATA, passValues, (res) => {
                if (res?.status === 200 && res?.data?.bills) {
                  let completeBills = [];
                  res.data.billGroups.map((ele, ind) => {
                    let tempArr = ele?.bills;
                    if (ind === 0) {
                      completeBills = tempArr;
                    } else {
                      completeBills = [...completeBills, ...tempArr];
                    }
                  });
                  updateState({ isSubmit: false });
                  resolve({
                    data: completeBills.length > 0 ? completeBills : [],
                    page: page.current,
                    totalCount: Number(res.data.totalRecords),
                  });
                } else {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                  handleError(res?.data?.responseInfo?.responseMessage[0]);
                }
              });
            }
          })
        }
        options={{
          selection: false,
          search: false,
          emptyRowsWhenPaging: false,
          sorting: false,
          pageSize: rows,
          pageSizeOptions: [5, 10, 20],
        }}
        onChangeRowsPerPage={(params) => {
          setRows(params);
        }}
        components={{
          Pagination: PatchedPagination,
        }}
        title={""}
        aria-level="2"
      />
    </Grid>
  );
};

export default PatientSearchTable;
