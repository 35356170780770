import { resetPasswordService } from "../core_services/resetPasswordService";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_PENDING = "RESET_PASSWORD_PENDING";
export const RESET_PASSWORD_SUCCESS_BY_EMAIL = "RESET_PASSWORD_SUCCESS_BY_EMAIL";

export function resetPasswordSuccess(userData) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: userData,
  };
}
export function resetPasswordPending() {
  return {
    type: RESET_PASSWORD_PENDING,
  };
}

export function resetPasswordData(userData, fn) {
  return (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: userData,
    });
    resetPasswordService.getResetPassword(userData, (res) => fn(res));
  };
}

export function resetPasswordDataByEmail(userData, fn) {
  return (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_SUCCESS_BY_EMAIL,
      payload: userData,
    });
    resetPasswordService.getResetPasswordByEmail(userData, (res) => fn(res));
  };
}
