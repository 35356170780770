import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getIn } from "formik";
import MaterialTable from "material-table";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { IconButton, TextField, Tooltip } from "@material-ui/core";
import moment from "moment";
import {
  FormControl,
  RadioGroup,
  Radio,
  FormHelperText,
  FormControlLabel,
  MenuItem,
  Select,
  Button,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight, FirstPage, LastPage, ContentCopy } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import $ from "jquery";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { handleSuccess, ToastContainer, handleError, handleWarning } from "../../../components/Notifications/Toast";
import { Grid } from "@material-ui/core";
import CustomizedRadio from "../../../components/CustomizedRadio";
import { tableIcons } from "../../../components/TableIcons";
import { requestInfo_PP_Txn } from "../../../Common/functions";
import { transactionService } from "../../../services/transactionDetailReportService";
import SpinnerPage from "../../../Common/PP_loader";
import PageLoader from "../../../components/ContentLoader/PageLoader";
import { ExportToExcel } from "../../../Common/exportToExcel";
import { formatAmount } from "../../../util/formatAmount";
import { capitalize } from "../../../util/Captilize";
import ClearIcon from "@material-ui/icons/Clear";

import DialogBox from "../../../components/DialogBox/index";
import ConfirmationDialog from "../../../../src/components/ConfirmationDialog";
import EnableVoidDetails from "./PAvoidTransaction";
import RefundDetails from "./PArefundTransaction.js";
import { fetchVoidAndRefundTransaction } from "../../../action/voidAndRefundAction";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import MouseOverPopover from "../../../Common/MousePopOver";
import NumberFormat from "react-number-format";
const paymentMethodOptions = [
  { key: "eCheck", value: "eCheck" },
  { key: "Credit Card", value: "Credit Card" },
];

const paymentStatusOptions = [
  { key: "Success", value: "Success" },
  { key: "Failed", value: "Failed" },
];
const tableHeaderStyle = {
  textAlign: "inherit",
  color: "#000000",
  fontWeight: "500",
  padding: "15px",
  fontSize: "14px",
  fontFamily: "Poppins",
};
const rowStyle = {
  height: "30px",
  fontSize: "14px",
};
const schema = Yup.object().shape({
  startDate: Yup.date().typeError("Please enter a valid date").nullable().required("Start Date is required"),
  endDate: Yup.date()
    .test("endDate", "End date should be greater than or equal to start date", function (val) {
      const { startDate } = this.parent;
      if (startDate > val) {
        return false;
      } else {
        return true;
      }
    })
    .nullable()
    .required("End Date is required"),
  med1AccNum: Yup.string()
    .matches(/^[0-9]+$/, "Enter digits only")
    .min(5, "Minimum 5 digits required for MED-1 Account Number")
    .max(8, "Enter 7 or 8 digits MED-Account Number"),
  seedAccNum: Yup.string()
    .matches(/^[0-9]+$/, "Enter digits only")
    .max(35, "Max 35 characters allowed"),
  clientName: Yup.string()
    .trim()
    .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters ,dash(-), dot(.), tilled and apostrophes(') are allowed")
    .min(1, "Please enter at least 1 Character")
    .max(100, "Max 100 characters allowed"),
  billAmount: Yup.string()
    .matches(/^\d+\.?\d*$/, "Enter digits only")
    .max(35, "Max 35 digits allowed"),
  transactionAmount: Yup.string()
    .matches(/^\d+\.?\d*$/, "Enter digits only")
    .max(35, "Max 35 digits allowed"),
  authCode: Yup.string().max(35, "Max 35 characters allowed"),
  transactionId: Yup.string().max(50, "Max 50 characters allowed"),
});
const dollarFormat = (value) => {
  if (value != null) {
    let amount = value;
    return `$${Number(amount).toLocaleString("us-en", {
      minimumFractionDigits: 2,
    })}`;
  } else {
    return value;
  }
};
class PATransactionSearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      disabled: false,
      filtered: [], ////Filters for datatable
      filterAll: "", ////Filters for datatable
      startDate: null,
      endDate: null,
      flagtoday: true,
      flagthirty: false,
      flagSixty: false,
      flagNinty: false,
      flagCustom: false,
      visible: true,
      reportdata: this.props.transactionData || [],
      showloader: false,
      noofrecords: 10,
      totalRecords: "",
      totalpages: 0,
      pageno: 0,
      showpagination: false,
      flag: 0,
      advancedSearch: false,
      noofpages: 0,
      scheduledAmount: 0,
      rowsPerPage: 10,
      from: "",
      to: "",
      voidTransactionData: [],
      voidOpen: false,
      refundTransactionData: [],
      reFundOpen: false,
    };
    this.initialState = this.state;
    this.filterAll = this.filterAll.bind(this);
  }
  componentDidMount() {
    // Mask/Unmask Row
    $("body").on("click", ".unmaskbtn", function () {
      var ids = $(this).closest("tr").attr("id");
      $("#" + ids + "  .maskfieldAC").toggleClass("showmask");
    });

    let now = new Date();
    const lastdays = new Date(now.setDate(now.getDate() - 30));

    const todayDate = new Date();

    this.setState({
      startDate: todayDate,
      endDate: todayDate,
      flag: 0,
    });
  }
  advanceGetFormattedDate = (date, format = "MM/DD/YYYY h:mm:ss") => {
    if (moment(date, "MM/DD/YYYY").isValid()) {
      return moment(date).format(format);
    }

    return "";
  };
  maskunmask = () => {
    var ids = $(this).closest("tr").attr("id");
    $("#" + ids + "  .maskfieldAC").toggleClass("showmask");
  };
  _handleSubmit = (values) => {
    this.setState({
      showloader: true,
    });
    var myDate = this.state.startDate;
    var mydate1 = "";
    var endDate = this.state.endDate;
    var enddate1 = "";
    if (myDate) {
      var mm = myDate.getMonth() + 1;
      var dd = myDate.getDate();
      var yy = myDate.getFullYear();
      var hh = myDate.getHours();
      var mn = myDate.getMinutes();
      var ss = myDate.getSeconds();

      mydate1 =
        (mm > 9 ? mm : "0" + mm) +
        "/" +
        (dd > 9 ? dd : "0" + dd) +
        "/" +
        yy +
        " " +
        (hh > 9 ? hh : "0" + hh) +
        ":" +
        (mn > 9 ? mn : "0" + mn) +
        ":" +
        (ss > 9 ? ss : "0" + ss);
    }
    if (endDate) {
      var mm = endDate.getMonth() + 1;
      var dd = endDate.getDate();
      var yy = endDate.getFullYear();
      var hh = endDate.getHours();
      var mn = endDate.getMinutes();
      var ss = endDate.getSeconds();
    }
    const passValues = {
      requestInfo: requestInfo_PP_Txn(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      paymentTransactionSearch: {
        startDate: this.advanceGetFormattedDate(values.startDate),
        endDate: this.advanceGetFormattedDate(values.endDate),
        paymentMethodId: values.paymentMethod === "Credit Card" ? 75001 : values.paymentMethod === "eCheck" ? 75003 : null,
        transactionStatus: values.paymentStatus === "Success" ? 80002 : values.paymentStatus === "Failed" ? 80003 : null,
        pgTxnRefNumber: values.transactionId ? values.transactionId : null,
        seedAccountNumber: values.seedAccNum ? values.seedAccNum : null,
        facsAccountNumber: values.med1AccNum ? values.med1AccNum : null,
        authorizationCode: values.authCode ? values.authCode : null,
        pageNumber: this.state.pageno,
        pageSize: 10,
        noofpages: "",
        billAmount: values.billAmount ? values.billAmount : null,
        paymentAmount: values.transactionAmount ? values.transactionAmount : null,
        clientName: values.clientName ? values.clientName : null,
      },
    };
    transactionService.addTransaction(
      passValues,
      (res) => {
        if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
          this.setState({
            reportdata: res.data.paymentTtransactions,
            noofrecords: res.data.totalRecords,
            totalRecords: res.data.totalRecords,
          });
          let temp = [];
          for (let i = 0; i < res.data.totalRecords / 10; i++) {
            temp.push(i);
          }
          this.setState({
            showloader: false,
            noofpages: temp,
            totalpages: temp.length,
          });
          this.handleRowChange(values.selectRows);

          if (this.state.reportdata == "") {
            handleWarning("No records found");
          }
          this.setState({
            showloader: false,
          });
          // this.maskunmask();
        } else if (res["data"]?.responseInfo?.status === "FAILED") {
          this.setState({
            showloader: false,
            reportdata: "",
          });

          handleWarning(res.data.responseInfo.responseMessage);
        } else {
          this.setState({
            showloader: false,
            reportdata: "",
          });
          handleError("Something went wrong!");
        }
      },
      (error) => {
        console.log(error);
        this.setState({
          showloader: false,
        });
      },
    );
  };
  // excelData = (data) => {
  //   const DataToExp = data.map((obj) => {
  //     const {
  //       paymentGatewayTxnRefNo,
  //       paymentTxnDatetime,
  //       paymentGatewayAuthorizationNumber,
  //       masterAccountReferenceNumber,
  //       accountReferenceNumber,
  //       clientName,
  //       payerName,
  //       paymentMethodId,
  //       paidAmount,
  //       actualPaidAmount,
  //       originalTotalAmount,
  //       currentBalanceAmount,
  //       paidBy,
  //       csaName,
  //       paymentGatewayStatus,
  //       paymentGatewayResponseJson,
  //       settlementDatetime,
  //       isSettled,
  //       refundedAmount,
  //       transactionStatusDescription,
  //       refundReason,
  //     } = obj;
  //     const failureReason = () => {
  //       if (paymentGatewayResponseJson != null) {
  //         let date = JSON.parse(paymentGatewayResponseJson);
  //         let resSucss = date.response_code;
  //         if (
  //           resSucss === "A01" ||
  //           resSucss === "A02" ||
  //           resSucss === "A03" ||
  //           resSucss === "S01" ||
  //           resSucss === "S02" ||
  //           resSucss === "S03"
  //         ) {
  //           return "-";
  //         } else {
  //           let data = date.response_desc;
  //           return data;
  //         }
  //       } else {
  //         return "-";
  //       }
  //     };
  //     const settStatus = () => {
  //       if (paymentGatewayStatus === 80002) {
  //         if (isSettled === 1) {
  //           return "Settled";
  //         } else {
  //           return "In-progress";
  //         }
  //       } else {
  //         return "-";
  //       }
  //     };
  //     const newObject = {
  //       "Transaction Date": paymentTxnDatetime ? paymentTxnDatetime : "-",
  //       "Seed Account #": masterAccountReferenceNumber ? masterAccountReferenceNumber : "-",
  //       "Account #": accountReferenceNumber ? accountReferenceNumber : "-",
  //       "Client Name": clientName ? clientName : "-",
  //       "Total Amt Paid": paidAmount ? formatAmount(paidAmount) : "-",
  //       "Amt Applied to Acct": actualPaidAmount ? formatAmount(actualPaidAmount) : "-",
  //       "Refund Amt": refundedAmount ? formatAmount(refundedAmount) : "-",
  //       "Current Bal Amt": currentBalanceAmount ? formatAmount(currentBalanceAmount) : "-",
  //       "Bill Amt": originalTotalAmount ? formatAmount(originalTotalAmount) : "-",
  //       "Transaction ID": paymentGatewayTxnRefNo ? paymentGatewayTxnRefNo : "-",
  //       "Settlement Status": transactionStatusDescription,
  //       "Settlement Date": settlementDatetime ? settlementDatetime : "-",
  //       "Authorization Code": paymentGatewayAuthorizationNumber ? paymentGatewayAuthorizationNumber : "-",
  //       "Payer Name": payerName ? payerName : "-",
  //       "Payment Method":
  //         paymentMethodId && paymentMethodId === 75001 ? "Credit Card" : paymentMethodId === 75003 ? "eCheck" : "-",
  //       "Paid By": paidBy ? paidBy : "-",
  //       "PA Name": csaName ? csaName : "-",
  //       "Payment Status":
  //         paymentGatewayStatus && paymentGatewayStatus === 80002
  //           ? "Success"
  //           : paymentGatewayStatus === 80003
  //           ? "Failed"
  //           : "-",
  //       "Failure Reason": failureReason(),
  //       "Refund Reason": refundReason ? refundReason : "-",
  //     };
  //     return newObject;
  //   });

  //   ExportToExcel(DataToExp, "Transaction search report");
  // };

  // downloadReportData = (values) => {
  //   this.setState({
  //     showloader: true,
  //   });
  //   var myDate = this.state.startDate;
  //   var mydate1 = "";
  //   var endDate = this.state.endDate;
  //   var enddate1 = "";
  //   if (myDate) {
  //     var mm = myDate.getMonth() + 1;
  //     var dd = myDate.getDate();
  //     var yy = myDate.getFullYear();
  //     var hh = myDate.getHours();
  //     var mn = myDate.getMinutes();
  //     var ss = myDate.getSeconds();

  //     mydate1 =
  //       (mm > 9 ? mm : "0" + mm) +
  //       "/" +
  //       (dd > 9 ? dd : "0" + dd) +
  //       "/" +
  //       yy +
  //       " " +
  //       (hh > 9 ? hh : "0" + hh) +
  //       ":" +
  //       (mn > 9 ? mn : "0" + mn) +
  //       ":" +
  //       (ss > 9 ? ss : "0" + ss);
  //   }
  //   if (endDate) {
  //     var mm = endDate.getMonth() + 1;
  //     var dd = endDate.getDate();
  //     var yy = endDate.getFullYear();
  //     var hh = endDate.getHours();
  //     var mn = endDate.getMinutes();
  //     var ss = endDate.getSeconds();
  //   }
  //   const passValues = {
  //     requestInfo: requestInfo_PP_Txn(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
  //     paymentTransactionSearch: {
  //       startDate: this.advanceGetFormattedDate(values.startDate),
  //       endDate: this.advanceGetFormattedDate(values.endDate),
  //       paymentMethodId: values.paymentMethod === "checking" ? 75001 : values.paymentMethod === "saving" ? 75003 : null,
  //       transactionStatus: values.paymentStatus === "Success" ? 80002 : values.paymentStatus === "Failed" ? 80003 : null,
  //       pgTxnRefNumber: values.transactionId ? values.transactionId : null,
  //       seedAccountNumber: values.seedAccNum ? values.seedAccNum : null,
  //       facsAccountNumber: values.med1AccNum ? values.med1AccNum : null,
  //       authorizationCode: values.authCode ? values.authCode : null,
  //       pageNumber: this.state.pageno,
  //       pageSize: -1,
  //       noofpages: "",
  //       billAmount: values.billAmount ? values.billAmount : null,
  //       paymentAmount: values.transactionAmount ? values.transactionAmount : null,
  //       clientName: this.state.clientName ? this.state.clientName : null,
  //     },
  //   };
  //   transactionService.addTransaction(passValues, (res) => {
  //     this.setState({
  //       showloader: false,
  //     });
  //     if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
  //       this.excelData(res?.data?.paymentTtransactions);
  //     } else if (res["data"]?.responseInfo?.status === "FAILED") {
  //       res.data.responseInfo.responseMessage.length > 0 && handleWarning(res.data.responseInfo.responseMessage[0]);
  //     } else {
  //       handleError("Something went wrong!");
  //     }
  //   });
  // };
  changepage = (number, values) => {
    let newTotalPages = [];

    for (let i = 0; i < this.state.totalRecords / values.selectRows; i++) {
      newTotalPages.push(i);
    }
    let pageN = number;
    if (newTotalPages.length !== this.state.noofpages.length) {
      pageN = 0;
    }

    this.setState({
      pageno: pageN,
      showloader: true,
      rowsPerPage: values?.selectRows,
    });

    const passValues = {
      requestInfo: requestInfo_PP_Txn(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      paymentTransactionSearch: {
        startDate: this.advanceGetFormattedDate(values.startDate),
        endDate: this.advanceGetFormattedDate(values.endDate),
        paymentMethodId: values.paymentMethod === "Credit Card" ? 75001 : values.paymentMethod === "eCheck" ? 75003 : null,
        transactionStatus: values.paymentStatus === "Success" ? 80002 : values.paymentStatus === "Failed" ? 80003 : null,
        pgTxnRefNumber: values.transactionId ? values.transactionId : null,
        seedAccountNumber: values.seedAccountNumber ? values.seedAccountNumber : null,
        facsAccountNumber: values.accountNumber ? values.accountNumber : null,
        authorizationCode: values.authorizationCode ? values.authorizationCode : null,
        pageNumber: pageN,
        pageSize: values.selectRows,
        billAmount: values.billAmount ? values.billAmount : null,
        paymentAmount: values.paymentAmount ? values.paymentAmount : null,
        clientName: values.clientName ? values.clientName : null,
      },
    };

    transactionService.addTransaction(
      passValues,

      (res) => {
        if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
          this.setState({
            reportdata: res?.data?.paymentTtransactions,
            noofrecords: res?.data?.totalRecords,
            totalRecords: res?.data?.totalRecords,
            showloader: false,
          });
          let temp = [];
          for (let i = 0; i < res.data.totalRecords / values.selectRows; i++) {
            temp.push(i);
          }

          this.setState({
            noofpages: temp,
            totalpages: temp.length,
          });
          this.handleRowChange(values.selectRows);
        } else if (res["data"]?.responseInfo?.status === "FAILED") {
          this.setState({ showloader: false });

          handleWarning("Oops" + res.data?.responseInfo?.responseMessage);
        } else {
          this.setState({ showloader: false });
          handleError("Something went wrong!" + res.data?.responseInfo?.responseMessage);
        }
      },
      (error) => {
        handleError("Something went wrong! Please try again");
      },
    );
  };

  changePageNo = (event, values) => {
    this.changepage(event.target.value, values);
  };
  filterAll(e) {
    const { value } = e.target;
    const filterAll = value;
    const filtered = [{ id: "all", value: filterAll }];
    // NOTE: this completely clears any COLUMN filters
    this.setState({ filterAll, filtered });
  }

  intialvalues = {
    period: "Today",
    startDate: new Date(),
    endDate: new Date(),
    paymentMethod: "none",
    paymentStatus: "none",
    transactionId: "",
    authCode: "",
    clientName: "",
    seedAccNum: "",
    med1AccNum: "",
    billAmount: "",
    transactionAmount: "",
    selectPage: 0,
    selectRows: 10,
  };

  handleRowChange = (rowsPerPage) => {
    let toValue =
      this.state.noofpages.length - 1 === this.state.pageno
        ? this.state.noofrecords
        : this.state.totalRecords < rowsPerPage
        ? this.state.totalRecords
        : this.state.pageno * rowsPerPage + rowsPerPage;

    this.setState({ from: this.state.pageno * rowsPerPage + 1, to: toValue });
  };

  render() {
    const options = {
      selection: false,
      search: false,
      sorting: false,
      emptyRowsWhenPaging: false,
      pageSize: this.state.rowsPerPage,
    };
    const roleData = sessionStorage.getItem("roleData");
    const reverseAndRefund = JSON.parse(roleData).pages[1].functionalities["Reverse/Refund"]?.["page access"];
    const columns = [
      {
        name: "paymentTxnDatetime",
        title: (
          <>
            Transaction
            <br />
            Date
          </>
        ),
        field: "paymentTxnDatetime",
        render: (rowData) => {
          return <>{rowData.paymentTxnDatetime ? rowData.paymentTxnDatetime.split(" ")[0] : ""}</>;
        },
      },
      {
        name: "masterAccountReferenceNumber",
        title: (
          <>
            Seed
            <br />
            Account #
          </>
        ),
        field: "masterAccountReferenceNumber",

        options: {
          // setCellProps: () => ({ style: { width: "195px" } }),
          sort: false,
          sortFilterList: false,
          // sortCompare: (order) => {
          //   return (obj1, obj2) => {
          //     console.log(order);
          //     let val1 = parseInt(obj1.data, 10);
          //     let val2 = parseInt(obj2.data, 10);
          //     return (val1 - val2) * (order === "asc" ? 1 : -1);
          //   };
          // },
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value != null) {
              let var5 = value;

              return <div class="maskfieldAC111 seed-number-colum">{var5}</div>;
            } else {
              return <div class="seed-number-colum">{value}</div>;
            }
          },
        },
      },
      {
        name: "accountReferenceNumber",
        title: <>Account #</>,
        field: "accountReferenceNumber",
        options: {
          sort: false,
          sortFilterList: false,
          sortCompare: (order) => {
            return (obj1, obj2) => {
              let val1 = parseInt(obj1.data, 10);
              let val2 = parseInt(obj2.data, 10);
              return (val1 - val2) * (order === "asc" ? 1 : -1);
            };
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            //alert(JSON.stringify(value))
            if (value) {
              let var5 = value;
              return <div class="maskfieldAC111 account-number-colum">{var5}</div>;
            } else {
              return value;
            }
          },
        },
      },
      {
        name: "clientName",
        title: "Client Name",

        field: "clientName",
        options: {
          sort: false,
          sortFilterList: false,
          sortCompare: (order) => {
            return (obj1, obj2) => {
              let val1 = parseInt(obj1.data, 10);
              let val2 = parseInt(obj2.data, 10);
              return (val1 - val2) * (order === "asc" ? 1 : -1);
            };
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            // alert(JSON.stringify(value))
          },
        },
        render: (value) => {
          if (value.clientName != null) {
            let var5 = value.clientName;
            const title1 = <p style={{ fontSize: "16px" }}>{var5}</p>;
            return (
              <>
                <Tooltip title={title1} placement="top">
                  <div>{var5?.slice(0, 15) + "..."}</div>
                </Tooltip>
                {/* <MouseOverPopover data={var5} popOverData={var5} codeLength={true} contentLength={15} /> */}
              </>
            );
          } else {
            return <div class="client-name-colum">{value.clientName}</div>;
          }
        },
      },
      {
        name: "paidAmount",
        title: <>Total Amt Paid</>,
        field: "paidAmount",
        options: {
          sort: false,
          sortFilterList: false,
          sortCompare: (order) => {
            return (obj1, obj2) => {
              // debugger
              //     console.log("obj1,obj2");
              // alert(obj1,obj2);
              let val1 = parseInt(obj1.data, 10);
              let val2 = parseInt(obj2.data, 10);
              return (val1 - val2) * (order === "asc" ? 1 : -1);
            };
          },
        },
        render: (rowData) => {
          if (rowData.paidAmount) {
            let var5 = rowData.paidAmount;
            return (
              <div className="paid-amount-colum">$ {Number(var5).toLocaleString("us-en", { minimumFractionDigits: 2 })}</div>
            );
          } else {
            return <div className="paid-amount-colum">{rowData.paidAmount}</div>;
          }
        },
      },
      {
        name: "actualPaidAmount",
        title: (
          <>
            Amt Applied <br />
            to Acct
          </>
        ),
        field: "actualPaidAmount",
        options: {
          sort: false,
          sortFilterList: false,
          sortCompare: (order) => {
            return (obj1, obj2) => {
              // debugger
              //     console.log("obj1,obj2");
              // alert(obj1,obj2);
              let val1 = parseInt(obj1.data, 10);
              let val2 = parseInt(obj2.data, 10);
              return (val1 - val2) * (order === "asc" ? 1 : -1);
            };
          },
        },
        render: (rowData) => {
          // alert(JSON.stringify(value))
          if (rowData.actualPaidAmount != null) {
            let var5 = rowData.actualPaidAmount;
            return (
              <div className="paid-amount-colum">$ {Number(var5).toLocaleString("us-en", { minimumFractionDigits: 2 })}</div>
            );
          } else {
            return <div className="paid-amount-colum">{rowData.actualPaidAmount}</div>;
          }
        },
      },
      {
        name: "refundedAmount",
        title: "Refund Amt",
        field: "refundedAmount",
        render: (rowData) => {
          if (rowData.refundedAmount || rowData.refundedAmount === 0) {
            return (
              <div className="paid-amount-colum">
                $ {Number(rowData.refundedAmount).toLocaleString("us-en", { minimumFractionDigits: 2 })}
              </div>
            );
          } else {
            return <div style={{ textAlign: "center" }}>--</div>;
          }
        },
      },
      {
        name: "currentBalanceAmount",
        title: (
          <>
            Current Bal
            <br />
            Amt
          </>
        ),
        field: "currentBalanceAmount",
        options: {
          sort: false,
          sortFilterList: false,
        },
        render: (rowData) => {
          // alert(JSON.stringify(value))
          if (rowData.currentBalanceAmount != null) {
            let var5 = rowData.currentBalanceAmount;
            return (
              <div className="paid-amount-colum">$ {Number(var5).toLocaleString("us-en", { minimumFractionDigits: 2 })}</div>
            );
          } else {
            return <div className="paid-amount-colum">{rowData.currentBalanceAmount}</div>;
          }
        },
      },
      {
        name: "originalTotalAmount",
        title: "Bill Amt",
        field: "originalTotalAmount",
        options: {
          sort: false,
          sortFilterList: false,
          sortCompare: (order) => {
            return (obj1, obj2) => {
              // debugger
              //     console.log("obj1,obj2");
              // alert(obj1,obj2);
              let val1 = parseInt(obj1.data, 10);
              let val2 = parseInt(obj2.data, 10);
              return (val1 - val2) * (order === "asc" ? 1 : -1);
            };
          },
        },
        render: (rowData) => {
          // alert(JSON.stringify(value))
          if (rowData.originalTotalAmount != null) {
            let var5 = rowData.originalTotalAmount;
            return (
              <div className="paid-amount-colum">$ {Number(var5).toLocaleString("us-en", { minimumFractionDigits: 2 })}</div>
            );
          } else {
            return <div className="paid-amount-colum">{rowData.originalTotalAmount}</div>;
          }
        },
      },
      {
        name: "paymentGatewayTxnRefNo",
        title: "Transaction ID",
        field: "paymentGatewayTxnRefNo",
        render: (value) => {
          if (value.paymentGatewayTxnRefNo != null) {
            let var5 = value.paymentGatewayTxnRefNo;
            const button1 = (
              <ContentCopy onClick={() => navigator?.clipboard?.writeText(var5)} style={{ cursor: "pointer" }}></ContentCopy>
            );
            const title1 = (
              <p style={{ fontSize: "16px" }}>
                {var5}
                <span style={{ marginLeft: "16px" }}>{button1}</span>
              </p>
            );
            return (
              <div>
                {/* <MouseOverPopover data={var5} popOverData={var5} codeLength={true} contentLength={15} /> */}
                <Tooltip title={title1} placement="top">
                  <div>{var5?.slice(0, 15) + "..."}</div>
                </Tooltip>
              </div>
            );
          } else {
            return <div class="transaction-colum">{value.paymentGatewayTxnRefNo}</div>;
          }
        },
      },
      {
        name: "transactionStatusDescription",
        title: "Settlement Status",
        field: "transactionStatusDescription",
        render: (rowData) => {
          const styles = {
            backgroundColor: "#FFBF00",
            padding: "6px",
            fontSize: "14px",
            borderRadius: "6px",
            textAlign: "center",
            fontWeight: "bold",
            color: "white",
            marginTop: "7px",
            marginBottom: "7px",
          };
          if (rowData?.transactionStatusDescription) {
            if (rowData?.transactionStatusDescription === "SETTLED") {
              return <p style={{ ...styles, backgroundColor: "#4eb11b" }}>{rowData?.transactionStatusDescription}</p>;
            } else if (rowData?.transactionStatusDescription === "REFUNDED") {
              return <p style={{ ...styles, backgroundColor: "#4eb11b" }}>{rowData?.transactionStatusDescription}</p>;
            } else {
              return <p style={styles}>{rowData?.transactionStatusDescription}</p>;
            }
          } else {
            return <p>--</p>;
          }
        },
      },
      {
        name: "Actions",
        title: "Actions",
        field: "Actions",
        headerStyle: {
          display: reverseAndRefund === "true" ? "" : "none",
        },
        cellStyle: {
          display: reverseAndRefund === "true" ? "" : "none",
        },
        render: (rowData) => {
          const styles = {
            width: "100%",
            border: "none",
            padding: "4px 8px",
            background: "#0f75bc",
            color: "white",
            borderRadius: "6px",
          };
          if (Number(rowData.allowVoid) === 1) {
            return (
              <button
                style={styles}
                onClick={() => {
                  this.props.fetchVoidAndRefundTransaction(
                    {
                      orderNumber: `${rowData.mappedOrderNumber}`,
                      transactionRefId: `${rowData.paymentGatewayTxnRefNo}`,
                      action: "void",
                    },
                    (res) => {
                      if (res) {
                        this.setState({
                          voidTransactionData: res,
                          voidOpen: true,
                        });
                      }
                    },
                  );
                }}>
                Void
              </button>
            );
          } else if (Number(rowData.allowRefund) === 1) {
            return (
              <button
                style={styles}
                onClick={() => {
                  this.props.fetchVoidAndRefundTransaction(
                    {
                      orderNumber: `${rowData.mappedOrderNumber}`,
                      transactionRefId: `${rowData.paymentGatewayTxnRefNo}`,
                      action: "reverse",
                    },
                    (res) => {
                      if (res) {
                        this.setState({
                          refundTransactionData: res,
                          reFundOpen: true,
                        });
                      }
                    },
                  );
                }}>
                Refund
              </button>
            );
          }
        },
      },
      {
        name: "settlementDatetime",
        title: "Settlement Date",
        field: "settlementDatetime",
        render: (rowData) => {
          if (rowData?.settlementDatetime != null) {
            var date = rowData?.settlementDatetime.split(" ")[0];
            return <div className="settlement-date-colum">{date}</div>;
          } else {
            return <div className="settlement-date-colum">{rowData?.settlementDatetime}</div>;
          }
        },
      },
      {
        name: "paymentGatewayAuthorizationNumber",
        title: (
          <>
            Authorization <br />
            Code
          </>
        ),
        field: "paymentGatewayAuthorizationNumber",
        options: {
          render: (rowData) => {
            return (
              <>
                <div className="authorization-code-colum">{rowData?.paymentGatewayAuthorizationNumber}</div>
              </>
            );
          },
        },
      },
      {
        name: "payerName",
        title: "Payer Name",
        field: "payerName",
        options: {
          sort: false,
          sortFilterList: false,
          sortCompare: (order) => {
            return (obj1, obj2) => {
              let val1 = parseInt(obj1.data, 10);
              let val2 = parseInt(obj2.data, 10);
              return (val1 - val2) * (order === "asc" ? 1 : -1);
            };
          },
          // customBodyRender: (value, tableMeta, updateValue) => {
          //   // alert(JSON.stringify(value))

          //   if (value != null) {
          //     let var5 = value.split("")[0].toUpperCase() + value.slice(1);
          //     console.log(var5, "var5")
          //     return <div class="maskfieldAC111 payer-name-colum">{var5}</div>;
          //   } else {
          //     return "";
          //   }
          // },
        },
        render: (rowData) => {
          if (rowData.payerName != null) {
            let var5 = rowData.payerName;
            return <div class="maskfieldAC111 payer-name-colum">{capitalize(var5)}</div>;
          } else {
            return "";
          }
        },
      },
      {
        name: "paymentMethodName",
        title: (
          <>
            Payment
            <br />
            Method{" "}
          </>
        ),
        field: "paymentMethodName",
        options: {
          sort: false,

          customBodyRender: (value, tableMeta, updateValue) => {
            // alert(JSON.stringify(value))
            if (value === 75001) {
              let var5 = "Credit Card";
              return <div class="payment-method-colum">{var5}</div>;
            } else if (value === 75003) {
              return "eCheck";
            } else {
              return null;
            }
          },
        },
      },
      {
        name: "paidBy",
        title: "Paid By",
        field: "paidBy",
        options: {
          sort: false,
          sortFilterList: false,
          filter: false,
          setCellProps: () => ({ style: { width: "195px" } }),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div className="paid-amount-colum">{value}</div>;
          },
        },
      },
      {
        name: "csaName",
        title: "PA Name",
        field: "csaName",
        options: {
          sort: false,
          sortFilterList: false,
          filter: false,
          setCellProps: () => ({ style: { width: "195px" } }),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div className="csa-name-colum">{value}</div>;
          },
        },
      },
      {
        name: "paymentGatewayStatus",
        title: (
          <>
            Payment
            <br />
            Status
          </>
        ),
        field: "paymentGatewayStatus",
        render: (rowData) => {
          return (
            <React.Fragment>
              {rowData?.paymentGatewayStatus === 80002 ? (
                <span className="payment-status-colum" style={{ color: "rgb(78, 177, 27)" }}>
                  Success
                </span>
              ) : rowData?.paymentGatewayStatus === 80003 ? (
                <span className="payment-status-colum" style={{ color: "#FF4848" }}>
                  Failed
                </span>
              ) : (
                <span>{rowData?.paymentGatewayStatus}</span>
              )}
            </React.Fragment>
          );
        },
      },
      {
        name: "paymentGatewayResponseJson",
        title: "Failure Reason",
        field: "paymentGatewayResponseJson",
        render: (rowData) => {
          // alert(JSON.stringify(value))
          //  debuggerresponse_code":"A01
          if (rowData?.paymentGatewayResponseJson != null) {
            var date = JSON.parse(rowData?.paymentGatewayResponseJson);
            var resSucss = date.response_code;
            if (
              resSucss === "A01" ||
              resSucss === "A02" ||
              resSucss === "A03" ||
              resSucss === "S01" ||
              resSucss === "S02" ||
              resSucss === "S03"
            ) {
              return "";
            } else {
              var data = date.response_desc;
              return <div className="failure-reason-colum">{data}</div>;
            }
          } else {
            return "";
          }
        },
      },
      {
        name: "refundReason",
        title: "Refund Reason",
        field: "refundReason",
        render: (rowData) => {
          let var5 = rowData.refundReason;
          return (
            <>
              <MouseOverPopover data={var5} popOverData={var5} codeLength={true} contentLength={15} />
            </>
          );
        },
      },

      // {
      //   name: "Action",
      //   title: "Action",
      //   field: "accountNumber",
      //   render: (rowData) => {
      //     return (
      //       <div class="actionptdetails actionptdetailsreport">
      //         <span class="spanbtn unmaskbtn">
      //           <ViewPatientbtn onClick={() => this.maskunmask()}> View </ViewPatientbtn>
      //         </span>
      //       </div>
      //     );
      //   },
      // },
    ];
    return (
      <div>
        {this.state.showloader && <SpinnerPage />}
        <Formik
          initialValues={{
            period: "Today",
            startDate: new Date(),
            endDate: new Date(),
            paymentMethod: "none",
            paymentStatus: "none",
            transactionId: "",
            authCode: "",
            clientName: "",
            seedAccNum: "",
            med1AccNum: "",
            billAmount: "",
            transactionAmount: "",
            selectPage: Number(this.state.pageno),
            selectRows: Number(this.state.rowsPerPage),
            accountNumber: "",
          }}
          onSubmit={this._handleSubmit}
          validationSchema={schema}>
          {(props) => {
            // eslint-disable-next-line no-unused-vars
            const { values, touched, error, handleChange, errors, setFieldValue, setErrors, setTouched } = props;
            return (
              <>
                <Form id="transaction-search" className="input-field" noValidate="noValidate">
                  <div className="form-row">
                    <div className="col-12 form-group mb-0">
                      <FormControl
                        component="fieldset"
                        variant="outlined"
                        className="w-100"
                        fullWidth
                        onChange={() => {
                          setErrors({});
                          setTouched({});
                        }}>
                        <label htmlFor="selectPeriod">Select Period</label>
                        <RadioGroup name="period" value={values[`period`]} onChange={handleChange} className="radio-btns ">
                          <div className="row mx-0 justify-content-start payment-radio-btns">
                            <div>
                              <FormControlLabel
                                value="Today"
                                control={
                                  <CustomizedRadio
                                    onChange={() => {
                                      setFieldValue("startDate", new Date());
                                      setFieldValue("endDate", new Date());
                                      this.setState({ flag: 0 });
                                    }}
                                  />
                                }
                                label="Today"
                              />
                            </div>
                            <div>
                              <FormControlLabel
                                value="Last 30 Days"
                                control={
                                  <CustomizedRadio
                                    onChange={() => {
                                      setFieldValue("startDate", moment().add(-30, "days"));
                                      setFieldValue("period", "Last 30 Days");
                                      setFieldValue("endDate", new Date());
                                      this.setState({ flag: 0 });
                                    }}
                                  />
                                }
                                label="Last 30 Days"
                              />
                            </div>
                            <div>
                              <FormControlLabel
                                value="Last 60 Days"
                                control={
                                  <CustomizedRadio
                                    onChange={() => {
                                      setFieldValue("period", "Last 60 Days");
                                      setFieldValue("startDate", moment().add(-60, "days"));
                                      setFieldValue("endDate", new Date());
                                      this.setState({ flag: 0 });
                                    }}
                                  />
                                }
                                label="Last 60 Days"
                              />
                            </div>
                            <div>
                              <FormControlLabel
                                value="Last 90 Days"
                                control={
                                  <CustomizedRadio
                                    onChange={() => {
                                      setFieldValue("startDate", moment().add(-90, "days"));
                                      setFieldValue("endDate", new Date());
                                      this.setState({ flag: 0 });
                                    }}
                                  />
                                }
                                label="Last 90 Days"
                              />
                            </div>
                            <div>
                              <FormControlLabel
                                value="Custom"
                                control={
                                  <CustomizedRadio
                                    onClick={() => {
                                      setFieldValue("startDate", null);
                                      setFieldValue("endDate", null);
                                      this.setState({ startDate: "", endDate: "", flag: 1 });
                                    }}
                                  />
                                }
                                label="Custom"
                              />
                            </div>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className="col-12">
                      <div className="form-row">
                        <div className="col-lg-4 col-xl-3 col-sm-4 col-md-4 form-group">
                          <FormControl component="fieldset" variant="outlined" className="w-100" fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Grid container className="custom-date-picker date-picker">
                                <label htmlFor="startDate" className="form-label w-100 required">
                                  Start Date
                                </label>
                                <DatePicker
                                  disableFuture
                                  name="startDate"
                                  autoOk
                                  value={values.startDate}
                                  disabled={this.state.flag === 0 ? true : false}
                                  onChange={(val) => setFieldValue("startDate", val)}
                                  InputProps={{
                                    startAdornment: (
                                      <IconButton
                                        position="end"
                                        onClick={() => setFieldValue("startDate", null)}
                                        style={{ order: 1, padding: 0 }}>
                                        {values.period === "Custom" ? <ClearIcon fontSize="small" className="p-0" /> : ""}
                                      </IconButton>
                                    ),
                                  }}
                                  InputAdornmentProps={{
                                    position: "end",
                                    style: { order: 2 },
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      helperText={null}
                                      margin="dense"
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "MM/DD/YYYY",
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                            </LocalizationProvider>
                          </FormControl>
                          <FormHelperText error>
                            {touched.startDate && errors.startDate ? <div>{errors.startDate}</div> : null}
                          </FormHelperText>
                        </div>
                        <div className="col-lg-4 col-xl-3 col-sm-4 col-md-4  form-group ">
                          <FormControl component="fieldset" variant="outlined" className="w-100" fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Grid container className="custom-date-picker date-picker">
                                <label htmlFor="endDate" className="form-label w-100 required">
                                  End Date
                                </label>
                                <DatePicker
                                  disableFuture
                                  name="endDate"
                                  autoOk
                                  value={values.endDate}
                                  disabled={this.state.flag === 0 ? true : false}
                                  minDate={values.startDate - 1}
                                  onChange={(val) => setFieldValue("endDate", val)}
                                  InputProps={{
                                    startAdornment: (
                                      <IconButton
                                        position="end"
                                        onClick={() => setFieldValue("endDate", null)}
                                        style={{ order: 1, padding: 0 }}>
                                        {values.period === "Custom" ? <ClearIcon fontSize="small" /> : ""}
                                      </IconButton>
                                    ),
                                  }}
                                  InputAdornmentProps={{
                                    position: "end",
                                    style: { order: 2 },
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      helperText={null}
                                      margin="dense"
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "MM/DD/YYYY",
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                            </LocalizationProvider>
                          </FormControl>
                          <FormHelperText error>
                            {touched.endDate && errors.endDate ? <div>{errors.endDate}</div> : null}
                          </FormHelperText>
                        </div>
                        {this.state.advancedSearch ? (
                          <div className="col-md-4 col-lg-6 advance-search">
                            <IconButton onClick={() => this.setState({ advancedSearch: false })}>
                              <HighlightOffIcon fontSize="default" />
                            </IconButton>
                          </div>
                        ) : (
                          <div className="col-md-4 col-xl-6 col-lg-4 advance-search">
                            <Button color="primary" onClick={() => this.setState({ advancedSearch: true })}>
                              Advanced Search
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* start ****************************************************/}
                    {this.state.advancedSearch ? (
                      <div className="col-12 mt-3">
                        <div className="form-row">
                          <div className="col-lg-4 col-xl-3 col-sm-6 col-md-4 form-group">
                            <FormControl variant="outlined" className="w-100" fullWidth>
                              <label htmlFor="paymentMethod" className="form-label">
                                Payment Method
                              </label>
                              <Select
                                fullWidth
                                name="paymentMethod"
                                className="form-select form-control selectdropdown"
                                onChange={handleChange("paymentMethod")}
                                value={values["paymentMethod"]}
                                error={Boolean(getIn(touched, "paymentMethod") && getIn(errors, "paymentMethod"))}>
                                <MenuItem value="none" key="none" disabled>
                                  Select Payment Method
                                </MenuItem>
                                {paymentMethodOptions.map((option) => {
                                  return (
                                    <MenuItem value={option.value} key={option.key}>
                                      {option.value}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                          <div className="col-lg-4 col-xl-3 col-sm-6 col-md-4  form-group">
                            <FormControl variant="outlined" className="w-100" fullWidth>
                              <label htmlFor="paymentStatus" className="form-label">
                                Payment Status
                              </label>
                              <Select
                                fullWidth
                                name="paymentStatus"
                                className="form-select form-control selectdropdown"
                                onChange={handleChange("paymentStatus")}
                                value={values["paymentStatus"]}
                                error={Boolean(getIn(touched, "paymentStatus") && getIn(errors, "paymentStatus"))}>
                                <MenuItem value="none" key="none" disabled>
                                  Select Payment Status
                                </MenuItem>
                                {paymentStatusOptions.map((option) => {
                                  return (
                                    <MenuItem value={option.value} key={option.key}>
                                      {option.value}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                          <div className="col-lg-4 col-xl-3 col-md-4 col-sm-6 form-group">
                            <FormControl variant="outlined" className="w-100" fullWidth>
                              <label htmlFor="transactionId">Transaction ID</label>

                              <TextField
                                className="form-control signup-fields p-0"
                                name="transactionId"
                                placeholder="Please Enter Transaction ID"
                                onChange={handleChange}
                                value={values["transactionId"]}
                                aria-label="text-field"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required="true"></TextField>
                            </FormControl>
                            <FormHelperText error>
                              {touched.transactionId && errors.transactionId ? <div>{errors.transactionId}</div> : null}
                            </FormHelperText>
                          </div>

                          <div className="col-lg-4 col-xl-3 col-md-4 col-sm-6 form-group">
                            <FormControl variant="outlined" className="w-100" fullWidth>
                              <label htmlFor="authorizationCode">Authorization Code</label>

                              <TextField
                                className="form-control signup-fields p-0"
                                name="authCode"
                                placeholder="Please Enter Authorization Code"
                                onChange={handleChange}
                                value={values["authCode"]}
                                aria-label="text-field"
                                fullWidth
                                error={Boolean(getIn(touched, "authCode") && getIn(errors, "authCode"))}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required="true"
                                inputProps={{
                                  maxLength: 35,
                                }}></TextField>
                            </FormControl>
                            <FormHelperText error>
                              {touched.authCode && errors.authCode ? <div>{errors.authCode}</div> : null}
                            </FormHelperText>
                          </div>

                          <div className="col-lg-4 col-xl-3 col-md-4 col-sm-6 form-group">
                            <FormControl variant="outlined" className="w-100" fullWidth>
                              <label htmlFor="clientName">Client Name</label>

                              <TextField
                                className="form-control signup-fields p-0"
                                name="clientName"
                                placeholder="Please Enter Client Name"
                                onChange={handleChange}
                                value={values["clientName"]}
                                aria-label="text-field"
                                fullWidth
                                error={Boolean(getIn(touched, "clientName") && getIn(errors, "clientName"))}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required="true"
                                inputProps={{
                                  maxLength: 100,
                                }}></TextField>
                            </FormControl>
                            <FormHelperText error>
                              {touched.clientName && errors.clientName ? <div>{errors.clientName}</div> : null}
                            </FormHelperText>
                          </div>

                          <div className="col-lg-4 col-xl-3 col-md-4 col-sm-6 form-group">
                            <FormControl variant="outlined" className="w-100" fullWidth>
                              <label htmlFor="seetAccountNumber">Seed Account Number</label>

                              <TextField
                                className="form-control signup-fields p-0"
                                name="seedAccNum"
                                type="text"
                                placeholder="Please Enter Seed Account Number"
                                onChange={handleChange}
                                value={values["seedAccNum"]}
                                aria-label="text-field"
                                fullWidth
                                error={Boolean(getIn(touched, "seedAccNum") && getIn(errors, "seedAccNum"))}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required="true"
                                InputProps={{
                                  maxLength: 35,
                                  inputComponent: NumberFormat,
                                }}></TextField>
                            </FormControl>
                            <FormHelperText error>
                              {touched.seedAccNum && errors.seedAccNum ? <div>{errors.seedAccNum}</div> : null}
                            </FormHelperText>
                          </div>

                          <div className="col-lg-4 col-xl-3 col-md-4 col-sm-6 form-group">
                            <FormControl variant="outlined" className="w-100" fullWidth>
                              <label htmlFor="med1AccountNumber">MED-1 Account Number</label>

                              <TextField
                                className="form-control signup-fields p-0"
                                name="med1AccNum"
                                type="text"
                                placeholder="Please Enter MED-1 Account Number"
                                onChange={handleChange}
                                value={values["med1AccNum"]}
                                aria-label="text-field"
                                fullWidth
                                error={Boolean(getIn(touched, "med1AccNum") && getIn(errors, "med1AccNum"))}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required="true"
                                InputProps={{
                                  maxLength: 35,
                                  inputComponent: NumberFormat,
                                }}
                              />
                            </FormControl>
                            <FormHelperText error>
                              {touched.med1AccNum && errors.med1AccNum ? <div>{errors.med1AccNum}</div> : null}
                            </FormHelperText>
                          </div>

                          <div className="col-lg-4 col-xl-3 col-md-4 col-sm-6 form-group">
                            <FormControl variant="outlined" className="w-100" fullWidth>
                              <label htmlFor="billAmount">Bill Amount</label>

                              <TextField
                                className="form-control signup-fields p-0"
                                name="billAmount"
                                type="text"
                                placeholder="Please Enter Bill Amount"
                                onChange={handleChange}
                                value={values["billAmount"]}
                                aria-label="text-field"
                                fullWidth
                                error={Boolean(getIn(touched, "billAmount") && getIn(errors, "billAmount"))}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required="true"
                                InputProps={{
                                  maxLength: 35,
                                  inputComponent: NumberFormat,
                                }}></TextField>
                            </FormControl>
                            <FormHelperText error>
                              {touched.billAmount && errors.billAmount ? <div>{errors.billAmount}</div> : null}
                            </FormHelperText>
                          </div>

                          <div className="col-lg-4 col-xl-3 col-md-4 col-sm-6 form-group">
                            <FormControl variant="outlined" className="w-100" fullWidth>
                              <label htmlFor="transactionAmount">Transaction Amount</label>

                              <TextField
                                className="form-control signup-fields p-0"
                                name="transactionAmount"
                                placeholder="Please Enter Transaction Amount"
                                onChange={handleChange}
                                type="text"
                                value={values["transactionAmount"]}
                                aria-label="text-field"
                                fullWidth
                                error={Boolean(getIn(touched, "transactionAmount") && getIn(errors, "transactionAmount"))}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required="true"
                                InputProps={{
                                  maxLength: 35,
                                  inputComponent: NumberFormat,
                                }}></TextField>
                            </FormControl>
                            <FormHelperText error>
                              {touched.transactionAmount && errors.transactionAmount ? (
                                <div>{errors.transactionAmount}</div>
                              ) : null}
                            </FormHelperText>
                          </div>
                        </div>
                      </div>
                    ) : (
                      " "
                    )}
                    {/* end ************************************************/}
                  </div>
                  <div className="report-btns">
                    <button aria-label="Generate Report" type="submit" className="themeing-buttons primary-button ml-1">
                      Generate Report
                    </button>
                    <button
                      aria-label="Reset"
                      type="reset"
                      className="secondary-button mx-2"
                      onClick={() => {
                        this.setState({
                          reportdata: [],
                          flag: 0,
                        });
                      }}>
                      Reset
                    </button>
                    {/* {this.state.reportdata?.length > 0 && (
                      <button
                        aria-label="Generate Report"
                        type="button"
                        className="primary-button themeing-secondary-buttons ml-1"
                        onClick={() => {
                          this.downloadReportData(values);
                        }}>
                        Download
                      </button>
                    )} */}
                  </div>
                </Form>
                {this.state.showloader ? (
                  <SpinnerPage />
                ) : this.state.reportdata?.length > 0 ? (
                  <Grid item>
                    <div className="payplan-table">
                      <MaterialTable
                        title={<></>}
                        aria-level="2"
                        data={this.state.reportdata || []}
                        columns={columns}
                        icons={tableIcons}
                        options={options}
                        components={{
                          Pagination: (props) => {
                            return (
                              <div className="custom-pagination">
                                <div className="selectpageno">
                                  <span className="mr-1">Rows Per Page:</span>
                                  <Select
                                    disableUnderline
                                    variant="standard"
                                    name="selectRows"
                                    id="selectRows"
                                    value={values.selectRows}
                                    onChange={(event) => {
                                      setFieldValue((values.selectRows = event.target.value));
                                      this.setState({ rowsPerPage: values.selectRows });
                                      this.handleRowChange(event.target.value);
                                      this.changepage(Number(this.state.pageno), values);
                                    }}>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={15}>15</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                  </Select>
                                </div>
                                <div className="selectpageno">
                                  <span>Page No : </span>

                                  {this.state.noofpages ? (
                                    <Select
                                      disableUnderline
                                      variant="standard"
                                      name="selectPage"
                                      value={Number(this.state.pageno)}
                                      onChange={(event) => this.changepage(event.target.value, values)}>
                                      {this.state.noofpages
                                        ? this.state.noofpages.map((value, index) => {
                                            return (
                                              <MenuItem value={index} key={index}>
                                                {index + 1}
                                              </MenuItem>
                                            );
                                          })
                                        : ""}
                                    </Select>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="nextprevious">
                                  <FirstPage
                                    className={this.state.pageno > 0 ? "arrow-icons" : "arrow-icons-disabled"}
                                    onClick={() => (this.state.pageno > 0 ? this.changepage(0, values) : "")}
                                  />
                                  <ChevronLeft
                                    className={this.state.pageno > 0 ? "arrow-icons" : "arrow-icons-disabled"}
                                    onClick={() =>
                                      this.state.pageno > 0 ? this.changepage(Number(this.state.pageno) - 1, values) : ""
                                    }
                                  />

                                  <span>
                                    <span>{this.state.from}</span> -<span className="mr-1">{this.state.to}</span>
                                    of
                                    <span className="ml-1">{this.state.noofrecords}</span>
                                  </span>
                                  <ChevronRight
                                    className={
                                      this.state.pageno >= this.state.noofpages.length - 1
                                        ? "arrow-icons-disabled"
                                        : "arrow-icons"
                                    }
                                    onClick={(e) => {
                                      if (this.state.pageno >= this.state.noofpages.length - 1) {
                                        e.preventDefault();
                                      } else {
                                        this.changepage(Number(this.state.pageno) + 1, values);
                                      }
                                    }}
                                  />
                                  <LastPage
                                    className={
                                      this.state.pageno >= this.state.noofpages.length - 1
                                        ? "arrow-icons-disabled"
                                        : "arrow-icons"
                                    }
                                    onClick={(e) => {
                                      if (this.state.pageno >= this.state.noofpages.length - 1) {
                                        e.preventDefault();
                                      } else {
                                        this.changepage(Number(this.state.noofpages.length) - 1, values);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          },
                        }}
                      />
                    </div>
                  </Grid>
                ) : null}
                <ConfirmationDialog
                  handleClose={() => {
                    this.setState({ voidOpen: false });
                  }}
                  maxWidth="md"
                  fullWidth={true}
                  open={this.state.voidOpen}
                  title="Void Transactions"
                  noActionButtons={true}>
                  <EnableVoidDetails
                    voidTransactionData={this.state.voidTransactionData}
                    handelclose={() => {
                      this._handleSubmit(values);
                      this.setState({ voidOpen: false });
                    }}
                  />
                </ConfirmationDialog>

                <ConfirmationDialog
                  maxWidth="md"
                  fullWidth={true}
                  handleClose={() => {
                    this.setState({ reFundOpen: false });
                  }}
                  open={this.state.reFundOpen}
                  title="Refund Transactions"
                  noActionButtons={true}>
                  <RefundDetails
                    refundTransactionData={this.state.refundTransactionData}
                    handelclose={() => {
                      this._handleSubmit(values);
                      this.setState({ reFundOpen: false });
                    }}
                  />
                </ConfirmationDialog>
              </>
            );
          }}
        </Formik>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchVoidAndRefundTransaction,
    },
    dispatch,
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(PATransactionSearchForm);
