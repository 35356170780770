import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import * as Yup from "yup";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExapandMoreIcon from "@material-ui/icons/ExpandMore";
import visacard from "../../../assets/images/visacard.jpg";
import mastercard from "../../../assets/images/mastercard.jpg";
import creditcards from "../../../assets/images/credit-cards.png";
import PaymentMethodSelect from "../Billing/PaymentMethodSelect";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";

const intialvalues = {
  username: "",
  password: "",
};

const schema = Yup.object().shape({
  username: Yup.string().trim().required("Username required"),
  password: Yup.string().trim().required("Password is required"),
});

const SignIn = (props) => {
  const history = useHistory();

  const onSubmit = async (values, onSubmitProps) => {
    history.push("/home");
    // dispatch(postLoginDetails({ username: email, password }));
  };

  return (
    <div className="container" role="main">
      <div className="row">
        <div className="col-md-8 offset-md-2" role="heading" aria-level="1">
          <Accordion>
            <AccordionSummary expandIcon={<ExapandMoreIcon />} role="heading" aria-level="1">
              Payment Details
            </AccordionSummary>
            <AccordionDetails>
              <table className="table">
                <thead>
                  <tr>
                    <th className="heading">Your saved credit and debit cards</th>
                    <th>Expires</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="visacard">
                    <td className="d-flex align-items-center">
                      <img src={visacard} alt="visacard" />
                      <p className="m-0">HDFC Bank Debit card ending in 7142</p>
                    </td>
                    <td>
                      <p>09/2023</p>
                    </td>
                    <td>
                      <span>
                        <ExapandMoreIcon />
                      </span>
                    </td>
                  </tr>
                  <tr className="mastercard">
                    <td className="d-flex align-items-center">
                      <img src={mastercard} alt="mastercard" />
                      <p className="m-0">HDFC Bank Debit card ending in 7142</p>
                    </td>
                    <td>
                      <p>09/2023</p>
                    </td>
                    <td>
                      <span>
                        <ExapandMoreIcon />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </AccordionDetails>
            <div className="payment-section">
              <h5>Add a New Payment Method</h5>
              <div className="row sections">
                <div className="col-md-6 credit-debit-section">
                  <h6>credit or debit cards</h6>
                  <div className="col-md-6 form-group">
                    <PaymentMethodSelect />
                    <button className="primary-button">Add</button>
                  </div>
                </div>
                <div className="col-md-6 creditcards">
                  <img src={creditcards} alt="creditcards" />
                </div>
              </div>
            </div>
          </Accordion>
        </div>
        {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
      </div>
    </div>
  );
};

export default SignIn;
