import React from "react";
import PaymentDetails from "./PaymentDetails";
import Preferences from "./Preferences";
import MyDetails from "./MyDetails";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";

const Account = () => {
  return (
    <div className="dashboard-content" role="main">
      <div role="heading" aria-level="1">
        <h2 className="content-title">Account</h2>
      </div>

      <div>
        <MyDetails />
        <PaymentDetails />
        {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
      </div>
    </div>
  );
};
export default Account;
