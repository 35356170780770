import RestDataSource from "./restdatasource";
import { Global_var } from "../global/new _admin_globalvar";

export const ClientManagementService = {
  getPaymentPlansOptions,
  getPaymentPlansOptionsById,
  savePaymentPlansOptions,
  deletePaymentPlanOption,
  getHospitalListData,
  PostSettlementOptions,
  getSettlementOptionsList,
  getSettlementOptionsById,
  UpdatePaymentPlansOptions,
};

function getPaymentPlansOptions(data, fn) {
  var url = Global_var.BASEURL + Global_var.URL_GET_PAYMENTPLAN_OPTIONS;
  return new RestDataSource(url, null, fn).Store(data, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        localStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
}

function getPaymentPlansOptionsById(id, fn) {
  var url = Global_var.BASEURL + Global_var.URL_GET_PAYMENTPLAN_OPTIONS_BY_ID + `/${id}`;
  return new RestDataSource(url, null, fn).GetData((res) => fn(res));
}

function deletePaymentPlanOption(id, fn) {
  var url = Global_var.BASEURL + Global_var.URL_DELETE_PATMENT_PLAN + `/${id}`;
  return new RestDataSource(url, null, fn).Delete(null, (res) => fn(res));
}

function savePaymentPlansOptions(data, fn) {
  var url = Global_var.BASEURL + Global_var.URL_PAYMENT_PLAN_OPTIONS_SAVE;
  return new RestDataSource(url, null, fn).Store(data, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        localStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
}
function UpdatePaymentPlansOptions(data, fn) {
  var url = Global_var.BASEURL + Global_var.URL_PAYMENT_PLAN_OPTIONS_UPDATE;
  return new RestDataSource(url, null, fn).Store(data, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        localStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
}
function getHospitalListData(fn) {
  var url = Global_var.BASEURL + Global_var.URL_GET_HOSPITAL_LIST;
  return new RestDataSource(url, null, fn).Store(null, (res) => fn(res));
}

function PostSettlementOptions(userData, fn) {
  var url = Global_var.BASEURL + Global_var.URL_SETTLEMENT_OPTIONS_ADD;
  return new RestDataSource(url, null, fn).Store(userData, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        localStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
}
function getSettlementOptionsList(userData, fn) {
  var url = Global_var.BASEURL + Global_var.URL_SETTLEMENT_OPTIONS_LIST;
  return new RestDataSource(url, null, fn).Store(userData, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        localStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
}
function getSettlementOptionsById(data, fn) {
  var url = Global_var.BASEURL + Global_var.URL_GET_SETTLEMENT_OPTIONS_BY_ID;
  return new RestDataSource(url, null, fn).Store(data, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        localStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
}
