import React, { useState } from "react";
import { useHistory } from "react-router";
import { FormControlLabel } from "@material-ui/core";
import CustomizedRadio from "../../../components/CustomizedRadio";

const PamentMethod = ({ payType, setPayType }) => {
  const handlePayType = (event) => {
    setPayType(event.target.value);
  };
  const history = useHistory();

  const isUser = localStorage.getItem("role") === "user";
  const isPA = localStorage.getItem("role") === "PA";

  const handleCancel = () => {
    if (isUser) {
      history.push("/home/billing");
    } else if (isPA) {
      history.push("/pa/billing");
    }
  };

  return (
    <div>
      {/* <p>Credit/Debit Card Information</p> */}

      <div>
        <div className="row">
          <div className="col-md-6">
            <div className="input-group-text payment-method-bg custom-billing-radio">
              <FormControlLabel
                value="creditDebitCards"
                className="payment-method-label"
                label="Credit/Debit Card"
                control={
                  <CustomizedRadio
                    checked={payType === "creditDebitCards"}
                    onChange={handlePayType}
                    value="creditDebitCards"
                    inputProps={{ "aria-label": "creditDebitCards" }}
                  />
                }
              />
            </div>
          </div>
          <div className="col-md-6 pl-lg-0">
            <div className="input-group-text payment-method-bg custom-billing-radio">
              <FormControlLabel
                className="payment-method-label"
                value="eCheck"
                label="eCheck"
                control={
                  <CustomizedRadio
                    checked={payType === "eCheck"}
                    onChange={handlePayType}
                    value="eCheck"
                    inputProps={{ "aria-label": "eCheck" }}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PamentMethod;
