import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import MakePayment from "../LandingPage/MakePayment";
import PayNow from "../Payment/index";
import ThankYou from "../LandingPage/Thankyou";
import { useLocation } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  txnFail: {
    padding: "20px",
    margin: "10px auto",
    width: "70%",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      padding: "20px 15px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "15px 5px",
    },
  },
  cerditCard: {
    padding: "20px",
    margin: "10px auto",
    width: "70% !important",
  },
}));
let locationPath = window?.location?.pathname;
function getSteps() {
  return ["Make a Payment", "Process Payment", "Thank You"];
}

export default function HorizontalLinearStepper(props) {
  const location = useLocation();
  // alert(location?.pathname);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const [debitCard, setDebitCard] = React.useState("");
  const [futurePayment, setFuturePayment] = React.useState(null);
  const steps = getSteps();

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  function getStepContent(step) {
    switch (step) {
      case 0:
        return <MakePayment handleNext={handleNext} setDebitCard={setDebitCard} setFuturePayment={setFuturePayment} />;
      case 1:
        return <PayNow props={props?.location?.state} />;
      case 2:
        return <ThankYou />;
      default:
        return "Unknown step";
    }
  }
  return (
    // <div className={`${locationPath === "/Transaction-Fail" ? classes.txnFail : classes.cerditCard}}`}>
    <div className={`custom-qp-stepper ${classes.txnFail}`}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
