import React, { useState, createRef, useEffect, useRef } from "react";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import { tableIcons } from "../../../../../components/TableIcons";
import { handleError } from "../../../../../components/Notifications/Toast";
import moment from "moment";
import PatchedPagination from "../../../../../Common/TablePagination";
import { capitalize } from "../../../../../util/Captilize";
import { httpPaginationRequest } from "../../../../../Common/httpPaginationRequest";
import { Global_var } from "../../../../../global/new _admin_globalvar";
const OptInTable = ({ searchData, setCommunicationsTypeReportData, isSubmit, setIsSubmit }) => {
  const tableRef = createRef();
  const page = useRef(null);
  const [rows, setRows] = useState(5);
  const isInitialRender = useRef(false);
  useEffect(() => {
    isInitialRender.current = true;
  }, []);

  useEffect(() => {
    if (tableRef?.current && isSubmit && isInitialRender.current === false) {
      if (searchData && Object.keys(searchData).length > 0) {
        tableRef.current.onQueryChange();
      }
    }
    if (isSubmit && isInitialRender.current) {
      isInitialRender.current = false;
    }
  }, [searchData, isSubmit]);
  const columns = [
    {
      title: "Seed Account Number",
      field: "accountReferenceNumber",
      render: (rowData, tableMeta, updateValue) => {
        const value = rowData["accountReferenceNumber"];
        return value ? value : "-";
      },
    },
    {
      title: "First Name",
      field: "firstName",
      render: (rowData, tableMeta, updateValue) => {
        const value = rowData["firstName"];
        return value ? capitalize(value) : "-";
      },
    },
    {
      title: "Last Name",
      field: "lastName",
      render: (rowData, tableMeta, updateValue) => {
        const value = rowData["lastName"];
        return value ? capitalize(value) : "-";
      },
    },
    {
      title: "Phone Number",
      field: "phoneNumber",
      render: (rowData, tableMeta, updateValue) => {
        const value = rowData["phoneNumber"];
        return value ? value : "-";
      },
    },
    {
      title: "Email Id",
      field: "email",
      render: (rowData, tableMeta, updateValue) => {
        const value = rowData["email"];
        return value ? value : "-";
      },
    },
    {
      title: "Opted In/Out",
      field: "optOut",
      render: (rowData, tableMeta, updateValue) => {
        const value = rowData["optOut"];
        return value === "0" ? "Opt-In" : value === "1" ? "Opt-Out" : "-";
      },
    },
    {
      title: "Requested Date",
      field: "requestedDate",
      render: (rowData, tableMeta, updateValue) => {
        const value = rowData["requestedDate"];
        return value ? value : "-";
      },
    },
  ];
  return (
    <MaterialTable
      title=""
      localization={{
        pagination: {
          firstAriaLabel: "First Page",
          previousAriaLabel: "Previous Page",
          nextAriaLabel: "Next Page",
          lastAriaLabel: "Last Page",
        },
      }}
      columns={columns}
      icons={tableIcons}
      tableRef={tableRef}
      data={(query) =>
        new Promise((resolve) => {
          if (!searchData) {
            resolve({
              data: [],
              page: 0,
              totalCount: 0,
            });
          } else {
            page.current = isSubmit ? 0 : query.page;
            const passValues = {
              firstName: searchData.firstName,
              lastName: searchData.lastName,
              phoneNumber: searchData.phoneNum,
              email: searchData.email,
              accountReferenceNumber: searchData.accountReferenceNumber,
              optOut: searchData?.optedStatus === "none" ? "" : searchData.optedStatus,
              pageNo: isSubmit ? 0 : query.page,
              pageSize: query.pageSize,
              startDate: searchData?.startDate ? moment(searchData?.startDate).format("YYYY-MM-DD") : "",
              endDate: searchData?.endDate ? moment(searchData?.endDate).format("YYYY-MM-DD") : "",
            };
            httpPaginationRequest(Global_var.URL_COMMUNICATION_TYPE_REPORT, passValues, (res) => {
              if (res?.status === 200 && res?.data?.OptOutReportResponseDetails) {
                setIsSubmit(false);
                setCommunicationsTypeReportData(
                  res?.data?.OptOutReportResponseDetails.length > 0 ? res?.data?.OptOutReportResponseDetails : [],
                );
                resolve({
                  data: res?.data?.OptOutReportResponseDetails.length > 0 ? res?.data?.OptOutReportResponseDetails : [],
                  page: page.current,
                  totalCount: Number(res.data.totalCount),
                });
                // setArrayLast(data.length === 1);
              } else {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0,
                });
                handleError(res?.data?.responseInfo?.responseMessage[0]);
              }
            });
          }
        })
      }
      options={{
        selection: false,
        search: true,
        emptyRowsWhenPaging: false,
        sorting: false,
        pageSize: rows,
        pageSizeOptions: [5, 10, 20],
      }}
      onChangeRowsPerPage={(params) => {
        setRows(params);
      }}
      components={{
        Pagination: PatchedPagination,
      }}
    />
  );
};

export default OptInTable;
