import React from "react";
import Med1Logo from "../../../assets/images/Med1Logo.png";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getIn } from "formik";
import { Link } from "react-router-dom";
import RocketIcon from "../../../assets/images/quick-pay-rocket-icon.svg";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { Grid, TextField, FormHelperText } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { IconButton } from "@material-ui/core";
import { requestInfo_PP } from "../../../Common/CommonFn";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { forgotAccountNumberData } from "../../../action/forgotPasswordAction";
import SpinnerPage from "../../../Common/PP_loader";
import { handleSuccess, ToastContainer, handleError } from "../../../components/Notifications/Toast";
import moment from "moment";
import endOfDay from "date-fns/endOfDay";
import ClearIcon from "@material-ui/icons/Clear";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";
let initialValues = {
  emailaddress: "",
  socialSecurityNumber: "",
  patientDOB: null,
};
const validationSchema = Yup.object().shape({
  emailaddress: Yup.string().required("Email Address is required"),
  socialSecurityNumber: Yup.string()
    .max(4, "Enter last 4 digits")
    .min(4, "Enter last 4 digits")
    .matches(/^\d+$/, "Enter digits only")
    .test("oneOfRequired", "Last 4 digits of Social Security Number is required", function (item) {
      return this.parent.socialSecurityNumber || this.parent.patientDOB;
    }),

  patientDOB: Yup.date()
    .typeError("Please enter a valid date")
    .max(endOfDay(new Date()), "Must be a date in the past")
    .min(moment(new Date(1870, 1, 1)).toDate(), "Date must be greater than 1870")
    .nullable()
    .test("oneOfRequired", "Date of Birth is required", function (item) {
      return this.parent.socialSecurityNumber || this.parent.patientDOB;
    }),
});

export class ForgotAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ssnshowPassword: false,
    };
  }
  componentDidMount() {
    initialValues = {
      emailaddress: "",
      socialSecurityNumber: "",
      patientDOB: null,
    };
  }
  _handleSsnShowPassword = () => {
    this.setState({
      ssnshowPassword: this.state.ssnshowPassword ? false : true,
    });
  };

  _handleSubmit = (values, { resetForm }) => {
    this.setState({
      loading: true,
    });
    var myDate = values.patientDOB;
    var myDate1 = "";
    if (myDate) {
      var mm = myDate.getMonth() + 1;
      var dd = myDate.getDate();
      var yy = myDate.getFullYear();
      myDate1 = (dd > 9 ? dd : "0" + dd) + "/" + (mm > 9 ? mm : "0" + mm) + "/" + yy;
    }
    const passValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      dob: myDate1,
      uniqueIdentifierId: values.socialSecurityNumber,
      contactEmail: values.emailaddress,
    };
    this.props.forgotAccountNumberData(passValues, (res) => {
      this.setState({
        loading: true,
      });
      if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
        handleSuccess("Account number has been sent to your mail Id");
        resetForm({
          values: {
            emailaddress: "",
            socialSecurityNumber: "",
            patientDOB: null,
          },
        });

        this.setState({
          loading: false,
        });
        this.props.history.push("/");
      } else if (res?.data?.responseInfo?.status === "FAILED") {
        handleError("Please provide valid details");
        resetForm({
          values: {
            emailaddress: "",
            socialSecurityNumber: "",
            patientDOB: null,
          },
        });

        this.setState({
          loading: false,
        });
      }
    });
  };

  render() {
    return (
      <>
        {this.state.loading && <SpinnerPage />}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this._handleSubmit}
          render={({ errors, touched, handleSubmit, handleChange, isValid, values, setFieldValue }) => (
            <React.Fragment>
              <div className="page-wrapper vertical-height" role="main">
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="card-sm">
                      <div className="header" role="heading" aria-level="1">
                        <div className="rocket-icon">
                          <img src={RocketIcon} alt="" />
                        </div>
                        <div className="logo">
                          <img src={Med1Logo} className="img-fluid" alt="Med-1 Solutions Logo" />
                        </div>
                      </div>
                      <div className="main-heading">
                        <h6 aria-level="2">Forgot MED-1 Account Number? </h6>
                      </div>

                      <div className="forgot-form-content">
                        <Form onSubmit={handleSubmit} id="login-form" className="input-field" noValidate="noValidate">
                          <div className="col-12 form-group">
                            <div className="label-spacing">
                              <label htmlFor="emailaddress" className="required">
                                Email Address
                              </label>
                            </div>
                            <TextField
                              hiddenLabel
                              variant="outlined"
                              className="signup-fields"
                              placeholder="Enter Email Adress"
                              name="emailaddress"
                              id="emailaddress"
                              value={values.emailaddress}
                              autocomplete="off"
                              maxLength="100"
                              onChange={(event) => {
                                setFieldValue((values.emailaddress = event.target.value));
                              }}
                              aria-label="emailaddress"
                              fullWidth
                              error={Boolean(getIn(touched, "emailaddress") && getIn(errors, "emailaddress"))}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required="true"
                            />
                            <div className="error-text">
                              {getIn(touched, "emailaddress") &&
                                getIn(errors, "emailaddress") &&
                                getIn(errors, "emailaddress")}
                            </div>
                          </div>
                          <div className="col-12 form-group">
                            <div className="label-spacing">
                              <label htmlFor="socialSecurityNumber" className="required">
                                Social Security Number
                              </label>
                            </div>
                            <TextField
                              hiddenLabel
                              variant="outlined"
                              className={`signup-fields ${values?.patientDOB ? "inputFieldDisable" : ""}`}
                              placeholder="Enter last 4 digits of SSN"
                              name="socialSecurityNumber"
                              id="socialSecurityNumber"
                              value={values.socialSecurityNumber}
                              autocomplete="off"
                              disabled={values?.patientDOB}
                              maxLength="4"
                              type={this.state.ssnshowPassword ? "text" : "password"}
                              onChange={(event) => {
                                setFieldValue((values.socialSecurityNumber = event.target.value));
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => this._handleSsnShowPassword()}>
                                      {this.state.ssnshowPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              aria-label="username"
                              fullWidth
                              error={Boolean(
                                getIn(touched, "socialSecurityNumber") && getIn(errors, "socialSecurityNumber"),
                              )}
                              inputProps={{
                                maxLength: 4,
                                className: "inputFieldProps",
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required="true"
                            />
                            <div className="error-text">
                              {getIn(touched, "socialSecurityNumber") &&
                                getIn(errors, "socialSecurityNumber") &&
                                getIn(errors, "socialSecurityNumber")}
                            </div>
                          </div>
                          <div className="col-12 form-group">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Grid
                                container
                                className={`custom-date-picker date-picker ${
                                  values?.socialSecurityNumber ? "date-picker-disable" : ""
                                }`}>
                                <label htmlFor="patientDOB" className="required">
                                  Patient's Date of Birth
                                </label>
                                <DatePicker
                                  disableFuture
                                  disabled={values.socialSecurityNumber}
                                  name="patientDOB"
                                  autoOk
                                  value={values.patientDOB}
                                  error={Boolean(getIn(touched, "patientDOB") && getIn(errors, "patientDOB"))}
                                  required="true"
                                  onChange={(val) => setFieldValue("patientDOB", val)}
                                  InputProps={{
                                    startAdornment: (
                                      <IconButton
                                        position="end"
                                        onClick={() => setFieldValue("patientDOB", null)}
                                        style={{ order: 1 }}>
                                        <ClearIcon fontSize="small" />
                                      </IconButton>
                                    ),
                                  }}
                                  InputAdornmentProps={{
                                    position: "end",
                                    style: { order: 2 },
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      helperText={null}
                                      margin="dense"
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "MM/DD/YYYY",
                                      }}
                                      error={Boolean(getIn(touched, "patientDOB") && getIn(errors, "patientDOB"))}
                                    />
                                  )}
                                  inputProps={{
                                    className: values?.socialSecurityNumber ? "inputFieldProps" : "",
                                  }}
                                />
                              </Grid>
                            </LocalizationProvider>
                            <div className="error-text">
                              {getIn(touched, "patientDOB") && getIn(errors, "patientDOB") && getIn(errors, "patientDOB")}
                            </div>
                          </div>
                          <div>
                            <button
                              type="submit"
                              className="primary-button btn-block submit-btn"
                              aria-label="continue to SignIn">
                              Submit
                            </button>
                          </div>
                        </Form>
                      </div>
                      <div className="text-center">
                        <div className="more-info">
                          <p>
                            <Link to="/signin" aria-label="forgot-account">
                              Back To Quick Pay
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
            </React.Fragment>
          )}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      forgotAccountNumberData: forgotAccountNumberData,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ForgotAccount);
