/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import configureStore, { history } from "./redux/store";
import { Switch } from "react-router-dom";
import { IntlProvider } from "react-intl";
import Routes from "./routes/index";
import AppLocale from "./i18n1";
import App1 from "../src/containers/App";
import { AppContext } from "./context/AppProvider";
import ErrorBoundaryComponent from "./util/NewErrorBoundary";
import { format } from "date-fns";
import { useIdleTimer } from "react-idle-timer";
import CountDownTimer from "./components/CountdownTimer";
import ScrollToTop from "./util/ScrollToTop";
import CacheBuster from "./Cachebuster";
import { ToastContainer } from "react-toastify";

export const store = configureStore();

export default function App() {
  const { locale } = useContext(AppContext);
  const currentAppLocale = AppLocale[locale.locale];

  //  Timer Start End
  return (
    <CacheBuster>
      {({ loading, isLatestVersion }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches
              .keys()
              .then(function (names) {
                for (let name of names) caches.delete(name);
              })
              .catch(() => {});
          }

          // delete browser cache and hard reload
          window.location.reload(true);
        }
        return (
          <ErrorBoundary FallbackComponent={ErrorBoundaryComponent}>
            <App1 />
            <Provider store={store}>
              <ConnectedRouter history={history}>
                <ScrollToTop />
                <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                  <Switch>
                    <Routes />
                  </Switch>
                </IntlProvider>
              </ConnectedRouter>
            </Provider>
            <ToastContainer theme="colored" />
          </ErrorBoundary>
        );
      }}
    </CacheBuster>
  );
}
