import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Header from "../../components/admin_header/headerQP";
import PP_Footer from "../../components/footer/footer";
import LandingPage_Header from "../../components/admin_header/landingPageHeader";
import { connect } from "react-redux";

import PP_Transaction_Fail from "../../components/PP_Transactio_Fail/PP_Transaction_Fail";
import UpdateTrackingPage from "../UpdateTrackingPage";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const PP_TransactionFailContainer = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <div id="outer-container">
          <React.Fragment>
            <main id="page-wrap">
              <div id="slidepagearea" className="contentarea">
                <PP_Transaction_Fail {...this.props} />
              </div>
            </main>
            {<PP_Footer />}
            {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
          </React.Fragment>
        </div>
      );
    }
  },
);

export default PP_TransactionFailContainer;
