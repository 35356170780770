import React from "react";
import AssessmentIcon from "@material-ui/icons/Assessment";
import PaymentPlans from "./PaymentPlans";
import PaymentsIcon from "@mui/icons-material/Payments";

const PATransaction = () => {
  return (
    <div className="transaction-card" role="main">
      <div className="main-container-card">
        <div role="heading" aria-level="1" className="heading-style mb-3">
          <PaymentsIcon className="mr-2" />
          <h2 className="heading-style">Reports: Payment Plans</h2>
        </div>
        <div>
          <PaymentPlans />
        </div>
      </div>
    </div>
  );
};

export default PATransaction;
