import { Store } from "react-notifications-component";

export const successNotification = {
  title: "Success!",
  message: "",
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated, animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: false,
    showIcon: true,
  },
};

export const warningNotification = {
  title: "Warning!",
  message: "",
  type: "warning",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated, animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: false,
    showIcon: true,
  },
};

export const infoNotification = {
  title: "Info!",
  message: "",
  type: "info",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated, animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: false,
    showIcon: true,
  },
};

export const errorNotification = {
  title: "Error!",
  message: "",
  type: "danger",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated, animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: false,
    showIcon: true,
  },
};

export const sessionNotification = {
  title: "Session Error!",
  message: "",
  type: "danger",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated, animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: false,
    showIcon: true,
  },
};

export const success = (message, notification) => {
  Store?.addNotification({
    ...notification,
    message: message,
    dismiss: {
      duration: 5000,
    },
  });
};

export const warning = (message, notification) => {
  Store.addNotification({
    ...notification,
    message: message,
  });
};

export const info = (message, notification) => {
  Store.addNotification({
    ...notification,
    message: message,
  });
};

export const error = (message, notification) => {
  Store.addNotification({
    ...notification,
    message: message,
  });
};

export const sessionerror = (message, notification) => {
  Store.addNotification({
    ...notification,
    message: message,
  });
};
