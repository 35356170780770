/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import Med1Logo from "../../assets/images/Med1Logo.png";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getIn } from "formik";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { FormHelperText } from "@material-ui/core";
import RocketIcon from "../../assets/images/quick-pay-rocket-icon.svg";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";

import { GoogleAnalystics } from "../../Common/GoogleAnalystics";
import { requestInfo_PP } from "../../Common/CommonFn.js";
import { contactusService } from "../../services/contactusService";
import i18n from "../../i18n";
import SpinnerPage from "../../Common/PP_loader.js";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { handleSuccess, handleError, handleWarning } from "../../components/Notifications/Toast";

const schema = Yup.object().shape({
  yourName: Yup.string()
    .trim()
    .required("First Name required")
    .matches(/^[aA-zZ\s]+$/, "Only letters are allowed"),
  emailID: Yup.string().trim().email("Email Address must be valid").required("Email Address required"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]*$/, "Only Numbers are allowed")
    .min(10, "Please enter 10 digits Phone Number")
    .max(10, "Please enter 10 digits Phone Number")
    .required("Phone Number required"),

  yourQuery: Yup.string().trim().required("Message required"),
});
const iconStyles = {
  cursor: "pointer",
  marginLeft: "35vw",
};
const typographyStyles = {
  fontWeight: "700",
  display: "inline-block",
};
class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showloader: false,
      yourName: "",
      yourQuery: "",
      emailID: "",
      phoneNumber: "",
      showForm: true,
      emailsupport: "help@med1solutions.com",
      phoneNumberSupport: "1-888-323-0811",
    };
  }

  componentDidMount() {
    document.title = "MED-1 Quick Pay - Contact Us";
    GoogleAnalystics.GoogleAnalysticsLog("pageview");
    window.scrollTo(0, 0);
  }

  componentWillMount() {
    sessionStorage.setItem("screen", "contactUs");
  }

  gotologin = () => {
    this.props.history.push("/login");
  };

  redirectToBack = () => {
    this.props.history.goBack();
  };

  gotofaqs = () => {
    this.props.history.push("/FAQs");
  };

  _handleSubmit = (values, actions) => {
    this.setState({
      showloader: true,
    });
    const PassValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),

      paymentQuery: {
        personName: values.yourName,
        countryCode: 1,
        phoneNumber: values.phoneNumber,
        personEmail: values.emailID,
        question: values.yourQuery,
      },
      adminEmail: "monika@privacydatasystems.com",
    };

    const UserId = "1234";
    contactusService.addContactusData(
      PassValues,
      UserId,
      (res) => {
        if (res["data"].requestInfo.status === "SUCCESSFUL") {
          this.setState({
            showloader: false,
          });

          handleSuccess("Your message has been received. Someone will respond to you shortly");
          this.props.handleClose();
          this.setState(this.initialState);
          actions.resetForm({
            values: {
              yourName: "",
              phoneNumber: "",
              emailID: "",
              yourQuery: "",
            },
          });
        } else if (res["data"].requestInfo.status === "FAILED") {
          this.setState({
            showloader: false,
          });

          handleError("Something went wrong!!");
        } else {
          this.setState({
            showloader: false,
          });

          handleWarning("Something went wrong!!");
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  render() {
    return (
      <div className="dashboard-content" role="main">
        <div>
          <Formik
            initialValues={{
              yourName: this.state.yourName ? this.state.yourName : "",
              emailID: this.state.emailID ? this.state.emailID : "",
              phoneNumber: this.state.phoneNumber ? this.state.phoneNumber : "",
              yourQuery: this.state.yourQuery ? this.state.yourQuery : "",
            }}
            onSubmit={this._handleSubmit}
            validationSchema={schema}>
            {(props) => {
              const { values, touched, error, handleChange, errors, setFieldValue } = props;

              return (
                <Form id="transaction-search" className="input-field mt-0 p-0" noValidate="noValidate">
                  <div className="contactus-card">
                    <div className="form-row">
                      <div className="col-md-6 col-sm-12">
                        <div className="card">
                          <div className="card-body  contactus-card-body">
                            <div className="email-icon">
                              <i className="fa fa-envelope"></i>
                            </div>
                            <div className="card-text">
                              <div className="support">
                                <p>Email Support</p>
                              </div>
                              <div>
                                <a href="mailto:help@med1solutions.com">help@med1solutions.com</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="card">
                          <div className="card-body  contactus-card-body">
                            <div className="email-icon">
                              <i className="fa fa-phone"></i>

                              <div className="card-text">
                                <div className="support">
                                  <p>Speak to a Patient Advocate</p>
                                  <div>
                                    {" "}
                                    <a href="tel:+18883230811">1-888-323-0811</a>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="contact-you col-md-12 mt-3">
                        <p>
                          If you need any further assistance,you can communicate with us by entering your information and we
                          will contact you.
                        </p>
                      </div>
                      <div className="col-lg-6 col-sm-6 form-group mb-4">
                        <div className="label-spacing">
                          <label htmlFor="firstName" className="required">
                            First Name
                          </label>
                        </div>
                        <OutlinedInput
                          className="form-control"
                          id="yourName"
                          name="yourName"
                          placeholder="Enter First Name"
                          onChange={handleChange}
                          value={values["yourName"]}
                          aria-label="text-field"
                          fullWidth
                          type="yourName"
                          error={Boolean(getIn(touched, "yourName") && getIn(errors, "yourName"))}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required="true"
                          inputProps={{
                            maxLength: 50,
                          }}></OutlinedInput>
                        <FormHelperText className="component-error-text">
                          {getIn(touched, "yourName") && getIn(errors, "yourName") && getIn(errors, "yourName")}
                        </FormHelperText>
                      </div>

                      <div className="col-lg-6 col-sm-6 form-group mb-4">
                        <div className="label-spacing">
                          <label htmlFor="phoneNumber" className="required">
                            Phone Number
                          </label>
                        </div>
                        <OutlinedInput
                          className="form-control phone-number-contact"
                          id="phoneNumber"
                          name="phoneNumber"
                          placeholder="Enter Phone Number"
                          onChange={handleChange}
                          onKeyPress={(event) => {
                            if (!/\d/.test(event.key) || values.phoneNumber.length >= 10) {
                              event.preventDefault();
                            }
                          }}
                          value={values["phoneNumber"]}
                          aria-label="text-field"
                          fullWidth
                          error={Boolean(getIn(touched, "phoneNumber") && getIn(errors, "phoneNumber"))}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required="true"></OutlinedInput>
                        <FormHelperText className="component-error-text">
                          {getIn(touched, "phoneNumber") && getIn(errors, "phoneNumber") && getIn(errors, "phoneNumber")}
                        </FormHelperText>
                      </div>

                      <div className="col-lg-6 col-sm-6 form-group mb-4">
                        <div className="label-spacing">
                          <label htmlFor="emailID" className="required">
                            Email Address
                          </label>
                        </div>
                        <OutlinedInput
                          className="form-control"
                          id="emailID"
                          name="emailID"
                          type="emailID"
                          placeholder="Enter Email Address"
                          onChange={handleChange}
                          value={values["emailID"]}
                          aria-label="text-field"
                          fullWidth
                          error={Boolean(getIn(touched, "emailID") && getIn(errors, "emailID"))}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required="true"
                          inputProps={{
                            maxLength: 50,
                          }}></OutlinedInput>
                        <FormHelperText className="component-error-text">
                          {getIn(touched, "emailID") && getIn(errors, "emailID") && getIn(errors, "emailID")}
                        </FormHelperText>
                      </div>

                      <div className="col-lg-12 col-sm-12 form-group enter-message-field">
                        <div className="label-spacing ">
                          <label htmlFor="message" className="required">
                            Your Message Here
                          </label>
                        </div>
                        <OutlinedInput
                          id="yourQuery"
                          name="yourQuery"
                          type="textarea"
                          multiline="true"
                          rows={2}
                          placeholder="Enter Your Message Here"
                          onChange={handleChange}
                          value={values["yourQuery"]}
                          aria-label="text-field"
                          fullWidth
                          error={Boolean(getIn(touched, "yourQuery") && getIn(errors, "yourQuery"))}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required="true"
                          inputProps={{
                            maxLength: 50,
                          }}></OutlinedInput>
                        <FormHelperText className="component-error-text">
                          {getIn(touched, "yourQuery") && getIn(errors, "yourQuery") && getIn(errors, "yourQuery")}
                        </FormHelperText>
                      </div>
                    </div>

                    <div className="contactus-buttons ">
                      <button type="submit" className="primary-button themeing-buttons" aria-label="submit">
                        Submit
                      </button>
                      <button
                        aria-label="Reset"
                        onClick={() => this.props.handleClose()}
                        type="reset"
                        className="secondary-button">
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          {this.state.showloader === true ? <SpinnerPage /> : ""}
        </div>
      </div>
    );
  }
}

export default ContactUs;
