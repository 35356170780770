import { forgotPasswordService } from "../core_services/forgotPasswordService";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_PENDING = "FORGOT_PASSWORD_PENDING";
export const FORGOT_USER_SUCCESS = "FORGOT_USER_SUCCESS";
export const FORGET_ACCOUNT_NUMBER = "FORGET_ACCOUNT_NUMBER";

export function forgetPasswordSuccess(userData) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: userData,
  };
}
export function forgetPasswordPending() {
  return {
    type: FORGOT_PASSWORD_PENDING,
  };
}

export function forgotUserName() {
  return {
    type: FORGOT_USER_SUCCESS,
  };
}

export function forgetAccountNumber() {
  return {
    type: FORGET_ACCOUNT_NUMBER,
  };
}

export function forgotPasswordData(userData, fn) {
  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: userData,
    });
    forgotPasswordService.getForgotPassword(userData, (res) => fn(res));
  };
}

export function forgotUserData(userData, fn) {
  return (dispatch) => {
    dispatch({
      type: FORGOT_USER_SUCCESS,
      payload: userData,
    });
    forgotPasswordService.getForgotUsername(userData, (res) => fn(res));
  };
}

export function forgotAccountNumberData(userData, fn) {
  return (dispatch) => {
    dispatch({
      type: FORGET_ACCOUNT_NUMBER,
      payload: userData,
    });
    forgotPasswordService.resetAccountNumber(userData, (res) => fn(res));
  };
}
