import React, { useState } from "react";
import { Field, Form, Formik, getIn } from "formik";
import { FormHelperText, FormControl, MenuItem, Select, Button } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import * as Yup from "yup";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
    },
  },
};

const EditPaymentPlan = ({
  creditCardData,
  eChequeData,
  getCardListData,
  getEchecqueListData,
  billData,
  handleClose,
  handleEditPlan,
}) => {
  const intialvalues = {
    paymentMethod: "none",
  };

  const schema = Yup.object().shape({
    paymentMethod: Yup.string().notOneOf(["none"], "Select a Valid Option").required("Select a Payment Method"),
  });

  const onSubmit = (values) => {
    handleEditPlan(values.paymentMethod, creditCardData, eChequeData);
  };

  return (
    <React.Fragment>
      <Formik initialValues={intialvalues} onSubmit={onSubmit} validationSchema={schema} enableReinitialize>
        {(props) => {
          // eslint-disable-next-line no-unused-vars
          const { values, touched, handleChange, errors, handleBlur, setFieldValue } = props;
          return (
            <Form id="profile-page" className="recurring-plans input-field" noValidate="noValidate">
              <div>
                <div>
                  <label htmlFor="paymentMethod" className="form-label required">
                    Payment Method
                  </label>
                  <div className="form-group">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <Select
                        autocomplete="off"
                        className={`form-control select-field ${
                          touched.paymentMethod && errors.paymentMethod ? "is-invalid" : ""
                        }`}
                        name="paymentMethod"
                        placeholder="Payment Method"
                        component="select"
                        value={values.paymentMethod}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                        style={{ textTransform: "none" }}>
                       
                        <MenuItem value="none" disabled>
                          Select Payment Method
                        </MenuItem>
                       

                        {getCardListData(creditCardData)}
                        {getEchecqueListData(eChequeData)}
                        <MenuItem value="creditCard">Debit/Credit Card</MenuItem>
                        <MenuItem value="echeck">eCheck</MenuItem>
                      </Select>
                    </FormControl>
                    <FormHelperText error>
                      {getIn(touched, "paymentMethod") && getIn(errors, "paymentMethod") && getIn(errors, "paymentMethod")}
                    </FormHelperText>
                  </div>
                </div>
                <DialogActions>
                  <Button type="submit" variant="contained" color="primary" className="themeing-buttons">
                    Yes
                  </Button>
                  <Button onClick={handleClose} color="primary" variant="outlined" className="secondary-button">
                    No
                  </Button>
                </DialogActions>
              </div>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default EditPaymentPlan;
