import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { handleWarning, ToastContainer, handleSuccess, handleError } from "../../../../components/Notifications/Toast";
import { useDispatch } from "react-redux";
import { forgotPasswordData } from "../../../../action/forgotPasswordAction";
import { I18nextProvider } from "react-i18next";
import i18n from "../../../../i18n";
import { Button } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
const ForgetPassword = ({ handleClose, user }) => {
  const dispatch = useDispatch();
  const validation = Yup.object().shape({
    userName: Yup.string().required("User Name is required"),
  });
  const [submit, setSubmit] = useState(false);

  const handleSubmit = (values, actions) => {
    setSubmit(true);
    dispatch(
      forgotPasswordData(
        { userLoginName: values.userName, subBuId: 1 },
        (res) => {
          handleClose();
          if (res.data.status === "success") {
            setSubmit(false);
            handleSuccess("Kindly check your email to reset password");
          } else if (res.data.status === "fail") {
            setSubmit(false);
            handleWarning("Oops..! " + res.data.reasonText);
          } else {
            setSubmit(false);
            handleError("Something wents wrong..! " + res.data.reasonText);
          }
        },
        (error) => {
          console.log(error);
        },
      ),
    );
  };

  return (
    <I18nextProvider i18n={i18n}>
      <React.Fragment>
        <Formik
          enableReinitialize
          initialValues={{
            userName: user[0] || user?.userName || "",
          }}
          validationSchema={validation}
          onSubmit={handleSubmit}>
          {({ touched, errors, setFieldValue, values }) => (
            <Form>
              <div>
                <p>
                  Are you sure you want to reset password for <b>{values.userName}</b>?
                </p>
                <DialogActions className="mt-2">
                  <Button type="submit" disabled={submit} className="themeing-buttons">
                    Yes
                  </Button>
                  <Button className="secondary-button" variant="outlined" onClick={() => handleClose()}>
                    No
                  </Button>
                </DialogActions>
              </div>
            </Form>
          )}
        </Formik>
        {/* <ToastContainer /> */}
      </React.Fragment>
    </I18nextProvider>
  );
};

export default ForgetPassword;
