import { purlService } from "../services/purlService";

// on login once(Customer Login/Quick Pay/Admin Portal/PURL) call api in redirecting page after login in these portals and for perl do in autologin component {component didmount} check url and call if its purl
export function CreatePortalUsage(trackData, fn) {
  // let passValuesCPU = {
  //     accessMode: "QUICK PAY",  //Mandatory
  //     campaignCode: "",         //If Purl then it's mandatory
  //     lastPageAccessed: "Payment Details",          //Mandatory
  //     medAccountNumber: "123456789",                 //Mandatory
  //     partyId: 11567,                                //Mandatory
  //     portalUsageId: 0					//It should be 0
  // }
  purlService.createPortalUsage(trackData, (res) => fn(res));
}

// on route change(Customer Login/Quick Pay/Admin Portal/PURL)
export function UpdatePortalUsage(trackData, fn) {
  purlService.updatePortalUsage(trackData, (res) => fn(res));
}

// name may be TxnCampaignView
// on login once(Customer Login/Quick Pay/Admin Portal/PURL) call api in redirecting page after login in these portals and for perl do in autologin component {component didmount} check url and call if its purl
export function CreateCampaignView(trackData, fn) {
  // let passValuesCCV = {
  //     campaignCode: "",   //Mandatory in case of PURL
  //     campaignViewId: 0,   //Non-mandatory
  //     medAccountNumber: "123456789",  // Mandatory
  //     paidAmount: 0,    //Non-mandatory
  //     partyId: 11567,    //mandatory
  //     payPlanAdded: "",  //Non-mandatory
  //     paymentComplete: "",   //Non-mandatory
  //     purlViewCount: 0   //Non-mandatory
  // }
  purlService.createCampaignView(trackData, (res) => fn(res));
  //    // response med1-account-number, campaign_code(this only comes )
}

// call after successful payment complete
// campaign_view_id in this args is portal_usage_id got from res of 1st api
export function updateCampignViewPaymentComplete(trackData, fn) {

  // let passValuesUCVPC = {
  //     campaignCode: "",   //non-required
  //     campaignViewId: 15,   //mandatory
  //     medAccountNumber: "",  // Non-Mandatory
  //     paidAmount: 20,    //mandatory
  //     partyId: 11567,    //mandatory
  //     payPlanAdded: "",  //Non-mandatory
  //     paymentComplete: "",   //Non-mandatory
  //     purlViewCount: 0   //Non-mandatory
  // }
  purlService.paymentComplete(trackData, (res) => fn(res));
}

//name may be PaymentPlanSetup
// call this in payment options --> pay weekly --> save  --> add card .. here one table will be there there in that page this should be called
// payment_amount in this args will be there in the page of above point table
export function updateCampignViewPaymentPlan(trackData, fn) {
  // let passValuesUCVPP = {
  //     campaignCode: "",   //non-required
  //     campaignViewId: 15,   //mandatory
  //     medAccountNumber: "",  // Non-Mandatory
  //     paidAmount: 20,    //mandatory
  //     partyId: 11567,    //mandatory
  //     payPlanAdded: "",  //Non-mandatory
  //     paymentComplete: "",   //Non-mandatory
  //     purlViewCount: 0   //Non-mandatory
  // }
  purlService.paymentPlanSetup(trackData, (res) => fn(res));
}

export function updateCampignViewPayment(trackData, fn) {
  // let passValuesUCVPP = {
  //     campaignCode: "",   //non-required
  //     campaignViewId: 15,   //mandatory
  //     medAccountNumber: "",  // Non-Mandatory
  //     paidAmount: 20,    //mandatory
  //     partyId: 11567,    //mandatory
  //     payPlanAdded: "",  //Non-mandatory
  //     paymentComplete: "",   //Non-mandatory
  //     purlViewCount: 0   //Non-mandatory
  // }
  purlService.paymentCompleteMain(trackData, (res) => fn(res));
}
