import React from "react";
import IntlMessages from "../../../util/IntlMessages";
import Med1Logo from "../../../assets/images/Med1Logo.png";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getIn } from "formik";
import { OutlinedInput, IconButton, FormHelperText } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { getLogin } from "../../../reducer/loginReducer";
import { login, logoutUser } from "../../../action/loginAction";
import { ToastContainer, handleError, handleWarning, handleSuccess } from "../../../components/Notifications/Toast";
import { loginService } from "../../../core_services/adminloginService";
import SpinnerPage from "../../../Common/PP_loader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getImage } from "../../../action/securityImageAction";
import InputAdornment from "@material-ui/core/InputAdornment";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import RocketIcon from "../../../assets/images/quick-pay-rocket-icon.svg";
import { Checkbox } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import SecurityImageContainer from "../../Pages/SecurityImage/SecurityImageContainer";
import { verifyQr } from "../../../action/TwoFactorAction";
import { error, errorNotification, success, successNotification } from "../../../components/PP_Notification/PP_Notification";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
export class UserName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trylogin: false,
      showPassword: false,
      showloader: false,
      isAuthenticate: false,
      isOtpValidated: false,
      qrSetUpKey: "",
      email: "",
    };
  }
  componentWillMount() {
    localStorage.clear();
    sessionStorage.clear();
  }
  componentDidMount() {
    sessionStorage.setItem("currentUserRole", "Admin");
    const queryString = window.location.search;
    const urlParameter = new URLSearchParams(queryString);

    var businessid = "2";
    var bussid = urlParameter.get("buid");
    if (queryString && bussid) {
      sessionStorage.setItem("buid", bussid);
    } else {
      sessionStorage.setItem("buid", businessid);
    }
    loginService.TokenUser((resToken) => {
      if (resToken["status"] === "success") {
        sessionStorage.setItem("jwt-token", resToken.responseObject);
      }
    });
  }
  _handleClickShowPassword = () => {
    this.setState({
      showPassword: this.state.showPassword ? false : true,
    });
  };

  render() {
    return (
      <>
        {this.state.showloader && <SpinnerPage />}

        <Formik
          initialValues={{
            username: "",
            password: "",
            handleContinueButton: "",
            validImage: false,
            seurityImage: "",
            showHideUsername: true,
            passwordCheck: false,
          }}
          validationSchema={Yup.object().shape({
            username: Yup.string().required("Please enter the Username").max(100, "Must be less than 100 characters"),
            password: Yup.string().required("Please enter the Password"),
            passwordCheck: Yup.boolean().oneOf([true], "Please Check Security image").nullable(),
            otp:
              this.state.isAuthenticate &&
              Yup.string().required("OTP is required").min(6, "OTP must be 6 Characters").max(6, "OTP must be 6 Characters"),
          })}
          onSubmit={(values, { resetForm }, actions) => {
            this.setState({
              trylogin: true,
              showloader: true,
            });
            var userLoginName = values.username.toLowerCase(),
              userPassword = values.password,
              userName = values.username.toLowerCase();
            const { login } = this.props;

            loginService.logout({ userName }, (userData) => {
              login(
                { userLoginName, userPassword },
                (userData) => {
                  this.setState({
                    isAuthenticate: userData?.responseObject?.isAuthenticate,
                    qrSetUpKey: userData?.responseObject?.key,
                    email: userData?.responseObject?.emailId,
                  });
                  if (this.state.isAuthenticate === 1 ? this.state.isOtpValidated : true) {
                    if (userData === undefined) {
                      this.setState({
                        trylogin: false,
                      });
                      handleWarning("Service not running..! ");
                      this.props.history.push("/admin");
                    } else if (userData.status === "success") {
                      this.setState({
                        trylogin: false,
                      });
                      var tempData = JSON.stringify(userData.responseObject);
                      sessionStorage.setItem("userData", tempData);
                      sessionStorage.setItem("loginDetails", tempData);

                      sessionStorage.setItem("Userpartyid", userData.responseObject.partyId);

                      // eslint-disable-next-line no-redeclare
                      var tempData = userData.responseObject;

                      if (tempData.forcePasswordChangeOnNextLoginFlag === "1") {
                        sessionStorage.setItem("Forcepwdchange", true);
                        window.location.replace("/change-password");
                      } else {
                        if (tempData.role[0].roleId === 7) {
                          sessionStorage.setItem("currentUserRole", "Admin");
                          sessionStorage.setItem("verifyAndPay", "No");
                          window.location.replace("pa/patientDetails");
                          sessionStorage.setItem("homePage", "/pa/patientDetails");
                        } else if (tempData.role[0].roleId === 22) {
                          sessionStorage.setItem("currentUserRole", "Admin");
                          sessionStorage.setItem("verifyAndPay", "No");
                          window.location.replace("pa/patientDetails");
                          sessionStorage.setItem("homePage", "/pa/patientDetails");
                        } else if (tempData.role[0].roleId === 2 || tempData.role[0].roleId === 6) {
                          sessionStorage.setItem("currentUserRole", "Admin");
                          sessionStorage.setItem("verifyAndPay", "No");
                          window.location.replace("pa/patientDetails");
                          sessionStorage.setItem("homePage", "/pa/patientDetails");
                        } else if (
                          tempData.role[0].roleId === 1 ||
                          tempData.role[0].roleId === 24 ||
                          tempData.role[0].roleId === 27
                        ) {
                          sessionStorage.setItem("currentUserRole", "Admin");
                          sessionStorage.setItem("verifyAndPay", "No");
                          window.location.replace("pa/patientDetails");
                          sessionStorage.setItem("homePage", "/pa/patientDetails");
                        } else if (tempData.role[0].roleId === 23) {
                          sessionStorage.setItem("currentUserRole", "Admin");
                          sessionStorage.setItem("verifyAndPay", "No");
                          sessionStorage.setItem("homePage", "/pa/user-management");
                          window.location.replace("pa/user-management");
                        } else if (tempData.role[0]?.roleId) {
                          sessionStorage.setItem("currentUserRole", "Admin");
                          sessionStorage.setItem("verifyAndPay", "No");
                          window.location.replace("pa/patientDetails");
                          sessionStorage.setItem("homePage", "/pa/patientDetails");
                        } else {
                          handleWarning("You do not have permission to access this page");
                        }
                      }
                    } else if (userData.reasonCode === "SM-03") {
                      this.setState({
                        trylogin: false,
                        showloader: false,
                      });
                      handleError("User Account not found!");
                      resetForm();
                      setTimeout(() => {
                        window.location.reload();
                      }, 2500);
                    } else if (userData.reasonCode === "SM-15") {
                      this.setState({
                        trylogin: false,
                      });
                      handleError("User has been locked. Please contact administrator!");
                      resetForm();
                      setTimeout(() => {
                        window.location.reload();
                      }, 2500);
                    } else if (userData.reasonCode === "SM-11") {
                      this.setState({
                        trylogin: false,
                      });
                      handleError(
                        "Please enter valid username and password! Your account will be blocked after 10 consecutive wrong attempts",
                      );
                      resetForm();
                      setTimeout(() => {
                        window.location.reload();
                      }, 2500);
                    } else if (userData.reasonCode === "SM-02") {
                      this.setState({
                        trylogin: false,
                        showloader: false,
                      });
                      handleWarning("Something went wrong, please try again..! ");
                      window.location.reload();
                    } else if (userData.reasonCode === "SM-07") {
                      this.setState({
                        trylogin: false,
                        showloader: false,
                      });
                      loginService.logout({ userName }, (userData) => {
                        if (userData.status === "success") {
                          handleWarning("Last session closed Unexpectedly. Clearing Previous session..! ");
                        } else {
                          sessionStorage.clear();
                          handleError("Please login again!");
                          resetForm();
                        }
                        window.location.replace("/admin");
                      });
                    } else if (userData.status === "fail") {
                      this.setState({
                        trylogin: false,
                        showloader: false,
                      });
                      if (userData.reasonCode === "SM-12") {
                        handleWarning(
                          "Your account has exceeded the maximum login attempts and is blocked. Please contact system Administrator to unblock your account.",
                        );
                      } else {
                        handleWarning(userData?.reasonText);
                      }
                      resetForm();
                    } else {
                      this.setState({
                        trylogin: false,
                        showloader: false,
                      });
                    }
                    this.setState({ isOtpValidated: false });
                  } else {
                    this.setState({
                      trylogin: false,
                      showloader: false,
                    });
                  }
                },
                (error) => {
                  // eslint-disable-next-line no-console
                  console.log(error);
                },
              );
            });
          }}
          render={({ errors, touched, handleSubmit, handleChange, isValid, values, setFieldValue }) => (
            <React.Fragment>
              <div className="page-wrapper vertical-height" role="main">
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="card-sm">
                      <div className="header" role="heading" aria-level="1">
                        <div className="rocket-icon">
                          <img src={RocketIcon} alt="" />
                        </div>
                        {!values.showHideUsername && (
                          <button
                            className="login-back-arrow"
                            onClick={() => {
                              values.showHideUsername = true;
                              this.setState({ isAuthenticate: false });
                            }}>
                            <ArrowBackIcon />
                          </button>
                        )}
                        <div className="logo">
                          <img src={Med1Logo} className="img-fluid" alt="Med-1 Solutions Logo" />
                        </div>
                      </div>
                      <div className="main-heading">
                        <h6 aria-level="2">PA Login </h6>
                      </div>
                      <div className="login-content">
                        <Form onSubmit={handleSubmit} id="login-form" className="input-field" noValidate="noValidate">
                          {values.showHideUsername ? (
                            <div className="form-group">
                              <div className="row m-0 justify-content-between align-items-center">
                                <label htmlFor="username" className="required">
                                  <IntlMessages id={"signin.userNameOrEmail"} />
                                </label>
                              </div>
                              <OutlinedInput
                                className="form-control signup-fields"
                                placeholder="Enter Username"
                                name="username"
                                id="username"
                                autocomplete="off"
                                maxlength="100"
                                onChange={(event) => {
                                  if (event.target.value === "") {
                                    setFieldValue((values.username = event.target.value));
                                    setFieldValue((values.handleContinueButton = false));
                                  } else {
                                    setFieldValue((values.username = event.target.value));
                                    setFieldValue((values.handleContinueButton = true));
                                  }
                                }}
                                aria-label="username"
                                fullWidth
                                error={Boolean(getIn(touched, "username") && getIn(errors, "username"))}
                                helperText={
                                  getIn(touched, "username") && getIn(errors, "username") && getIn(errors, "username")
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                startAdornment={
                                  <InputAdornment position="end">
                                    <PermIdentityIcon />
                                  </InputAdornment>
                                }
                                required="true"></OutlinedInput>
                              <ErrorMessage name="username" className="text-danger new-text-danger" component="div" />
                            </div>
                          ) : !this.state.isAuthenticate ? (
                            <div>
                              <div className="row">
                                <div className="form-group">
                                  <div className="row ">
                                    <div className="col-2 col-md-2">
                                      <SecurityImageContainer />
                                    </div>
                                    <div className="col-10 col-md-10 security-check">
                                      <Checkbox
                                        onChange={handleChange}
                                        checked={values.passwordCheck}
                                        inputProps={{ "aria-label": "controlled" }}
                                        name="passwordCheck"
                                        className="signin-check"
                                      />
                                      <b>
                                        {" "}
                                        Security Image{" "}
                                        {getIn(touched, "passwordCheck") && getIn(errors, "passwordCheck") ? (
                                          <span className="accept-security-message">{`*`}</span>
                                        ) : (
                                          <span className="accept-security-message">{"*"} </span>
                                        )}
                                      </b>
                                      <div className="form-group">
                                        <p className="mb-0">
                                          Is this the security image that you selected when you registered?
                                        </p>
                                        <div className="accept-security-message">
                                          {" "}
                                          {getIn(touched, "passwordCheck") && getIn(errors, "passwordCheck") ? (
                                            <span>
                                              <p className="error-text">You must accept the Security Image</p>
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <label htmlFor="password" className="required">
                                      {" "}
                                      <IntlMessages id={"signin.password"} />
                                    </label>

                                    <OutlinedInput
                                      className="form-control signup-fields"
                                      id="password"
                                      name="password"
                                      placeholder="Please Enter Password"
                                      onChange={handleChange}
                                      value={values["password"]}
                                      aria-label="text-field"
                                      fullWidth
                                      type={this.state.showPassword ? "text" : "password"}
                                      error={Boolean(getIn(touched, "password") && getIn(errors, "password"))}
                                      helperText={
                                        getIn(touched, "password") && getIn(errors, "password") && getIn(errors, "password")
                                      }
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      startAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => this._handleClickShowPassword()}>
                                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      required="true"></OutlinedInput>
                                    <ErrorMessage name="password" className="error-text" component="div" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="row">
                                <div className="form-group col-12">
                                  <div className="row m-0 justify-content-between align-items-center">
                                    <label htmlFor="password" className="required">
                                      {" "}
                                      <IntlMessages id={"signin.otp"} />
                                    </label>
                                  </div>
                                  <OutlinedInput
                                    className="form-control signup-fields"
                                    id="otp"
                                    name="otp"
                                    placeholder="Enter OTP From Authenticator App"
                                    onChange={handleChange}
                                    value={values["otp"]}
                                    aria-label="text-field"
                                    fullWidth
                                    error={Boolean(getIn(touched, "otp") && getIn(errors, "otp"))}
                                    helperText={getIn(touched, "otp") && getIn(errors, "otp") && getIn(errors, "otp")}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    required="true"
                                    inputProps={{
                                      maxLength: 50,
                                    }}></OutlinedInput>
                                  <FormHelperText className="component-error-text">
                                    {getIn(touched, "otp") && getIn(errors, "otp") && getIn(errors, "otp")}
                                  </FormHelperText>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className={`mt-2 ${values.showHideUsername ? "mb-5" : "mb-3"}`}>
                            {values.handleContinueButton ? (
                              <button
                                type="button"
                                onClick={(event) => {
                                  if (values.username !== "" && values.username.length <= 100) {
                                    var userName = values.username.toLowerCase();
                                    this.props.getImage({ userName }, (imageData) => {
                                      setFieldValue((values.showHideUsername = false));
                                      setFieldValue((values.handleContinueButton = false));
                                    });
                                  }
                                }}
                                className="primary-button btn-block"
                                aria-label="continue to SignIn">
                                Continue
                              </button>
                            ) : !this.state.isAuthenticate === 1 ? (
                              <button type="submit" className="primary-button btn-block" aria-label="continue to SignIn">
                                Continue
                              </button>
                            ) : (
                              <button
                                type="submit"
                                onClick={() => {
                                  if (values?.otp && !errors?.otp) {
                                    const elements = {
                                      authenticationCode: values.otp,
                                      emailId: this.state.email,
                                      key: this.state.qrSetUpKey,
                                      passWord: values.password,
                                    };
                                    this.props.verifyQr(elements, (res) => {
                                      if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
                                        this.setState({
                                          isOtpValidated: true,
                                        });
                                        handleSuccess("OTP verification successful");
                                      } else if (res?.data?.responseInfo?.status === "FAILED") {
                                        handleError(res?.data?.responseInfo?.responseMessage[0]);
                                      }
                                    });
                                  }
                                }}
                                className="primary-button btn-block"
                                aria-label="continue to SignIn">
                                Continue
                              </button>
                            )}
                          </div>
                        </Form>
                      </div>
                      {!values.showHideUsername && (
                        <div className="text-center">
                          <div className="forgot-password">
                            <p>
                              <Link to="/admin-forgot-password" aria-label="forgot password">
                                Forgot Password?
                              </Link>
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  ...getLogin(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login: login,
      getImage: getImage,
      logoutUser: logoutUser,
      verifyQr: verifyQr,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserName);
