import React from "react";

export const CancelPlan = ({ plan }) => {
  return <div>Are you sure you want to Cancel selected plan {plan}?</div>;
};

export const DeletePlan = ({ plan, billData }) => {
  return (
    <>
      <div>
        Order Id:<b className="order-number ml-2">{billData?.orderid}</b>
        {plan && plan !== "S" ? ` with selected plan ${plan} ?` : ""}
      </div>
    </>
  );
};

export const DeletePayment = ({ paymentMethod, card }) => {
  return (
    <div>
      Are you sure you want to delete {paymentMethod} ending with {card}?
    </div>
  );
};
