import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Dialog } from "@material-ui/core";

const CardAddedDialog = ({ open, handleClose }) => {
  const history = useHistory();
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <div className="p-4">
          <h6 className="text-center pb-3">Your Card is Added</h6>
          <div className="proceed-cancel-buttons">
            <button className="secondary-button" onClick={handleClose}>
              Cancel
            </button>

            <button
              className="primary-button"
              onClick={() => {
                handleClose();
                sessionStorage.getItem("currentUserRole") === "Admin"
                  ? history.push("/pa/account")
                  : history.push("/home/account");
              }}>
              Continue
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default CardAddedDialog;
