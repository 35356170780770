import RestDataSource from "./restdatasource";
import { loginService } from "./loginService";
import { Global_var } from "../global/global_var";
import {
  error,
  success,
  warning,
  warningNotification,
  errorNotification,
  successNotification,
} from "../components/Admin_Notification/Admin_Notification";
import { handleSuccess, ToastContainer, handleError, handleWarning } from "../components/Notifications/Toast";

export const changePasswordService = {
  changePassword,
};

function changePassword(userData, fn) {
  var url = Global_var.BASEURL + Global_var.URL_CHANGEPASSWORD;
  return new RestDataSource(url, 0, fn).Store(userData, (res) => {
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          // sessionStorage.clear();
          handleWarning("Token Expired, Login/Verify again");
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-02") {
          // sessionStorage.clear();
          handleWarning("Token Expired, Login/Verify again");
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-10") {
          // sessionStorage.clear();
          handleWarning("Token Expired, Login/Verify again");
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-03") {
          // sessionStorage.clear();
          handleWarning(res?.data?.reasonText);
        } else {
          handleWarning("Session expired! Please refresh the page");
        }
      } else {
        // if (res.headers["token"] != null) {
        //   sessionStorage.setItem("jwt-tokencore", res.headers.token);
        // }

        fn(res.data);
      }
    }
  });
  //   }
  // });
}
