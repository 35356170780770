import React from "react";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { withStyles, Button } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
const DialogContent = withStyles((theme) => ({
  root: {
    padding: "20px 10px 10px 15px",
  },
}))(MuiDialogContent);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {},
  heading: {
    borderBottom: "1px solid #d3d3d3",
    padding: 10,
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
});

const ConfirmationDialog = ({ open, handleClose, disableBackDropOnClick }) => {
  return (
    <Dialog
      disableBackdropClick={disableBackDropOnClick}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="dialog-box">
      <DialogContent>
        {" "}
        <div className="modal-body">
          Sorry! Payment Arrangement is not available for this account. Please pay through other options 'Pay Now' or 'Pay in
          'Pay in Future'.
          <DialogActions>
            <Button onClick={handleClose} type="submit" className="btn themeing-buttons">
              Ok
            </Button>
          </DialogActions>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationDialog;
