import React from "react";
import { useHistory } from "react-router";
import IntlMessages from "../../../util/IntlMessages";
import Med1Logo from "../../../assets/images/Med1Logo.png";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getIn } from "formik";
import { Checkbox, OutlinedInput } from "@material-ui/core";
import { Link } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import RocketIcon from "../../../assets/images/quick-pay-rocket-icon.svg";
import { getLogin } from "../../../reducer/loginReducer";
import { login, logoutUser } from "../../../action/loginAction";
import { loginService } from "../../../core_services/adminloginService";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getImage } from "../../../action/securityImageAction";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";

const intialvalues = {
  username: "demo@gmail.com",
  password: "d#em&o1*(234",
  passwordCheck: false,
};
const schema = Yup.object().shape({
  username: Yup.string().trim().required("Username required"),
  password: Yup.string().trim().required("Password is required"),
  passwordCheck: Yup.boolean().oneOf([true], "Please Check Security image").nullable(),
});

export class Password extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Formik
        initialValues={intialvalues}
        validationSchema={schema}
        onSubmit={(values, { resetForm }, actions) => {
          this.setState({
            trylogin: true,
          });
          var userLoginName = values.username.toLowerCase(),
            userPassword = values.password,
            userName = values.username.toLowerCase();
          const { login } = this.props;

          loginService.logout({ userName }, (userData) => {
            login(
              { userLoginName, userPassword },
              (userData) => {
                if (userData == undefined) {
                  this.setState({
                    trylogin: false,
                  });
                  // warning("Service not running..! ", warningNotification);
                  this.props.history.push("/signin");
                } else if (userData.status === "success") {
                  this.setState({
                    trylogin: false,
                  });
                  var tempData = JSON.stringify(userData.responseObject);
                  sessionStorage.setItem("userData", tempData);
                  sessionStorage.setItem("loginDetails", tempData);

                  sessionStorage.setItem("Userpartyid", userData.responseObject.partyId);

                  var tempData = userData.responseObject;

                  if (tempData.forcePasswordChangeOnNextLoginFlag === "1") {
                    sessionStorage.setItem("Forcepwdchange", true);
                    window.location.replace("/Change-Password");
                  } else {
                    if (tempData.role[0].roleId === 7) {
                      window.location.replace("home/billing");
                    } else if (tempData.role[0].roleId === 22) {
                      window.location.replace("home/billing");
                    } else if (tempData.role[0].roleId === 2) {
                      window.location.replace("/Landing-Page");
                    } else if (tempData.role[0].roleId === 1) {
                      window.location.replace("/Landing-Page");
                    } else if (tempData.role[0].roleId === 21) {
                      window.location.replace("home/billing");
                    } else {
                    }
                  }
                } else if (userData.reasonCode === "SM-03") {
                  this.setState({
                    trylogin: false,
                  });
                  window.location.reload();
                  resetForm();
                } else if (userData.reasonCode === "SM-02") {
                  this.setState({
                    trylogin: false,
                  });
                  window.location.reload();
                } else if (userData.reasonCode === "SM-07") {
                  this.setState({
                    trylogin: false,
                  });
                  loginService.logout({ userName }, (userData) => {
                    if (userData.status === "success") {
                    } else {
                      sessionStorage.clear();
                      resetForm();
                    }
                    window.location.replace("/signin");
                  });
                } else if (userData.status === "fail") {
                  this.setState({
                    trylogin: false,
                  });
                  if (userData.reasonCode === "SM-12") {
                    // warning(<span>{t("User_blocked")}</span>, warningNotification);
                  } else {
                    // warning("User not exists. Please contact to administrator..! ", warningNotification);
                  }
                  resetForm();
                } else {
                  this.setState({
                    trylogin: false,
                  });
                }
              },
              (error) => {
                console.log(error);
              },
            );
          });
        }}
        render={({ errors, touched, handleSubmit, handleChange, isValid, values, setFieldValue }) => (
          <>
            <div className="page-wrapper vertical-height" role="main">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="card-sm">
                    <div className="header" role="heading" aria-level="1">
                      <div className="rocket-icon">
                        <img src={RocketIcon} alt="" />
                      </div>
                      <div className="logo">
                        <img src={Med1Logo} className="img-fluid" alt="Med-1 Solutions Logo" />
                      </div>
                    </div>
                    <div className="main-heading">
                      <h6 aria-level="2">Secure Login</h6>
                    </div>
                    <div className="login-content">
                      <Formik initialValues={intialvalues} onSubmit={handleSubmit} validationSchema={schema}>
                        {(props) => {
                          const { values, touched, handleChange, errors, setFieldValue } = props;
                          
                          return (
                            <Form id="login-form" className="input-field" noValidate="noValidate">
                              <div className="form-group">
                                <div>
                                  <Checkbox
                                    onChange={handleChange}
                                    checked={values.passwordCheck}
                                    inputProps={{ "aria-label": "controlled" }}
                                    name="passwordCheck"
                                  />
                                  <b>
                                    {" "}
                                    Security Image{" "}
                                    {getIn(touched, "passwordCheck") && getIn(errors, "passwordCheck") ? (
                                      <span style={{ color: "red" }}>{`*`}</span>
                                    ) : (
                                      "*"
                                    )}
                                  </b>
                                </div>
                                <div>
                                  <p>Is this the security image that you selected when you registered?</p>
                                </div>
                                <div>
                                  {" "}
                                  {getIn(touched, "passwordCheck") && getIn(errors, "passwordCheck") ? (
                                    <span style={{ color: "red" }}>
                                      <p>You must accept the Security Image</p>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="row m-0 justify-content-between align-items-center">
                                  <label htmlFor="password" className="required">
                                    {" "}
                                    <IntlMessages id={"signin.password"} />
                                  </label>
                                </div>
                                <OutlinedInput
                                  className="form-control signup-fields"
                                  id="password"
                                  name="password"
                                  placeholder="Please Enter Password"
                                  onChange={handleChange}
                                  value={values["password"]}
                                  aria-label="text-field"
                                  fullWidth
                                  type="password"
                                  error={Boolean(getIn(touched, "password") && getIn(errors, "password"))}
                                  helperText={
                                    getIn(touched, "password") && getIn(errors, "password") && getIn(errors, "password")
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  startAdornment={
                                    <InputAdornment position="end">
                                      <LockOutlinedIcon />
                                    </InputAdornment>
                                  }
                                  required="true"
                                  inputProps={{
                                    maxLength: 50,
                                  }}></OutlinedInput>
                              </div>
                              <div className="mt-5">
                                <button type="submit" className="primary-button btn-block" aria-label="continue to SignIn">
                                  Continue
                                </button>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                    <div className="text-center">
                      <div className="forgot-password">
                        <p>
                          Forgot Password?
                          <Link to="/forgot-password" aria-label="forgot password">
                            Click Me
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="text-center">
                      <div className="more-info">
                        <p>
                          Don’t have account with us?{" "}
                          <Link to="/registration" aria-label="sign up here link">
                            Sign Up here
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
          </>
        )}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  ...getLogin(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login: login,
      getImage: getImage,
      logoutUser: logoutUser,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(Password);
