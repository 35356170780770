import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import MonthlyForm from "./forms/MonthlyForm";
import WeeklyForm from "./forms/WeeklyForm";
import BiWeeklyForm from "./forms/BiWeeklyForm";
import CustomizedRadio from "../../../components/CustomizedRadio";
const PayPlanActions = ({ open, hospital, handleClickOpen, handleClose }) => {
  const [plan, setPlan] = useState("Pay Weekly");

  const handleChange = (event) => {
    setPlan(event.target.value);
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogContent className="pay-plan-options">
            <div className="hospital-heading">
              <p className="text-center">{hospital}</p>

              <p>Pay Plan Options</p>
            </div>
            <FormControl component="fieldset">
              <RadioGroup name="plan" value={plan} onChange={handleChange} className="radio-btns">
                <div className="row m-0 justify-content-between">
                  <div className="col-4 pr-0">
                    <FormControlLabel value="Pay Weekly" control={<CustomizedRadio />} label="Pay Weekly" />
                  </div>
                  <div className="col-4  ">
                    <FormControlLabel value="Pay Bi-Weekly" control={<CustomizedRadio />} label="Pay Bi-Weekly" />
                  </div>
                  <div className="col-4 pay-monthly">
                    <FormControlLabel value="Pay Monthly" control={<CustomizedRadio />} label="Pay Monthly" />
                  </div>
                </div>
              </RadioGroup>
            </FormControl>

            <DialogContentText id="alert-dialog-description">
              {plan === "Pay Weekly" ? (
                <WeeklyForm handleClose={handleClose} />
              ) : plan === "Pay Bi-Weekly" ? (
                <BiWeeklyForm handleClose={handleClose} />
              ) : plan === "Pay Monthly" ? (
                <MonthlyForm handleClose={handleClose} />
              ) : null}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default PayPlanActions;
