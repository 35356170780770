import React, { useState } from "react";
import { Translation } from "react-i18next";
import i18n from "../../i18n";
import ContactUs from "./Contactus";
import { I18nextProvider } from "react-i18next";
import ConfirmationDialog from "../ConfirmationDialog/index";

function TermsAndConditions() {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <I18nextProvider i18n={i18n}>
        <div>
          {
            <Translation>
              {(t, { i18n }) => (
                <label className="form-check-label">
                  {/* {t("contac_us")}{" "} */}
                  <button
                    className="contact-btn"
                    type="button"
                    onClick={() => {
                      setOpen(true);
                    }}>
                    {t("contac_us")}
                  </button>
                </label>
              )}
            </Translation>
          }
          <ConfirmationDialog
            open={open}
            handleClose={handleClose}
            fullWidth={true}
            maxWidth={"md"}
            title="Contact Us"
            noActionButtons={true}>
            <ContactUs handleClose={handleClose} />
          </ConfirmationDialog>
        </div>
      </I18nextProvider>
    </>
  );
}

export default TermsAndConditions;
