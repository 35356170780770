import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import { CancelButton, NextButton } from "../../assets/MaterialControl";
import { error, errorNotification, success, successNotification } from "../PP_Notification/PP_Notification";
import * as Yup from "yup";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators, compose } from "redux";
import { logoutUser } from "../../action/loginAction";
import { disable2Fa, getQrImg, verifyQr } from "../../action/TwoFactorAction";
import { fetchUserDetailData } from "../../action/userDetailReport";
import { handleError, handleSuccess } from "../Notifications/Toast";
import Tooltip from "@mui/material/Tooltip";
import { FormHelperText, Button } from "@material-ui/core";
import ConfirmationDialog from "../ConfirmationDialog";
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutUser: logoutUser,
      disable2Fa: disable2Fa,
      verifyQr: verifyQr,
      getQrImg: getQrImg,
      fetchUserDetailData: fetchUserDetailData,
    },
    dispatch,
  );

export class TwofactorAuthentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qrSetUpKey1: "",
    };
  }

  componentDidMount() {
    const PassValues3 = {
      partyID: JSON.parse(sessionStorage.getItem("userData"))?.partyId,
      userLoginName: JSON.parse(sessionStorage.getItem("userData"))?.userName,
    };

    this.props.fetchUserDetailData(PassValues3, 0, (res2) => {
      this.props.setIsAuthenticate(res2.data.responseObject.isAuthenticate);
      this.props.setQrSetUpKey(res2.data.responseObject.key);
      this.setState({ qrSetUpKey1: res2.data.responseObject.key });
    });
  }

  _handleCloseTwoFactor = () => {
    if (this.state.twoFactorStepperNo === 1) {
      this.props.setTwoFactorStepperNo(0);
    } else {
      this.props.setTwoFactorPopup(false);
      this.props.setShowAuthenticatorKey(false);
      this.props.setTwoFactorStepperNo(0);
      this.setState({});
    }
  };

  _handleCloseDisableTwoFactor = () => {
    this.props.setTwoFactorDisablePopup(false);
  };

  render() {
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            password: this.state.password ? this.state.password : "",
            securityImageList: this.state.securityImageList,
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string().required("Please enter the Password"),
            passwordTwoFactor: Yup.string().required("Please enter the Password"),
            otp: Yup.string()
              .required("OTP is required")
              .min(6, "OTP must be 6 Characters")
              .max(6, "OTP must be 6 Characters"),
          })}>
          {({
            touched,
            errors,
            validateForm,
            setFieldValue,
            values,
            isSubmitting,
            setErrors,
            setTouched,
            resetForm,
            handleChange,
          }) => (
            <Form>
              <ConfirmationDialog
                open={this.props.twoFactorPopup}
                handleClose={() => {
                  this._handleCloseTwoFactor();
                  setErrors({});
                  setTouched({});
                  this.setState({
                    twoFactorStepperNo: 0,
                  });
                }}
                scroll={"body"}
                maxWidth={"sm"}
                fullWidth
                title={"Enable Two-Factor Authentication"}
                noActionButtons={true}>
                <div className="input-field">
                  {this.props.twoFactorStepperNo === 0 && (
                    <div className="authentication-content">
                      <Typography className="authentication-heading">Set up two-factor authentication</Typography>
                      <Typography className="authentication-description">
                        This wizard will enable Two-Factor Authentication (2FA) on your MED-1 account. Two-factor
                        authentication will make your MED-1 account more secure so, we recommend enabling it.
                      </Typography>
                    </div>
                  )}

                  {this.props.twoFactorStepperNo === 1 && (
                    <div className="authentication-content">
                      <Typography className="authentication-heading">Set up two-factor authentication</Typography>
                      <Typography className="authentication-description">
                        Scan this code with your two-factor authentication device to set up your account.
                      </Typography>
                      <div className="scan-QR-code my-3">
                        {!this.props.showAuthenticatorKey ? (
                          <img
                            src={this.props.qrImageUrl && "data:image/png;base64, " + this.props.qrImageUrl}
                            alt="QR code"
                            id="base64image"
                          />
                        ) : (
                          <Typography className=" font-weight-bold" varient="h6">
                            {this.props.qrSetUpKey}
                          </Typography>
                        )}
                      </div>
                    </div>
                  )}

                  {this.props.twoFactorStepperNo === 2 && (
                    <div className="form-row authentication-content">
                      <Typography className="authentication-heading  mb-2" varient="h1">
                        Set up two-factor authentication
                      </Typography>
                      <div className="col-sm-12 col-md-12 form-group">
                        <FormControl variant="outlined" className="w-100" fullWidth>
                          <label htmlFor="otp" className="required">
                            {" "}
                            Two-factor authentication code
                          </label>
                          <TextField
                            type="number"
                            id="otp"
                            name="otp"
                            placeholder="Enter OTP From authenticator app"
                            variant="outlined"
                            size="small"
                            className={` ${touched.otp && errors.otp ? "is-invalid" : ""}`}
                            onChange={handleChange}></TextField>
                        </FormControl>
                        <FormHelperText error>
                          {touched.otp && errors.otp && <ErrorMessage component="div" name="otp" />}
                        </FormHelperText>
                      </div>
                      <div className="col-sm-12 col-md-12 form-group">
                        <FormControl variant="outlined" className="w-100" fullWidth>
                          <div className="d-flex justify-content-start">
                            <label htmlFor="password" className="required mr-2">
                              Password
                            </label>
                            <div className="myDIV setup-info-message">
                              <Tooltip title="Please enter the account password" placement="right-start" arrow>
                                <i className="fa fa-question-circle i-info10" aria-hidden="true"></i>
                              </Tooltip>
                            </div>
                          </div>
                          <TextField
                            type="password"
                            id="passwordTwoFactor"
                            name="passwordTwoFactor"
                            placeholder="Enter Password"
                            variant="outlined"
                            size="small"
                            className={` ${touched.passwordTwoFactor && errors.passwordTwoFactor ? "is-invalid" : ""}`}
                            onChange={handleChange}></TextField>
                        </FormControl>
                        <FormHelperText error>
                          {touched.passwordTwoFactor && errors.passwordTwoFactor && (
                            <ErrorMessage component="div" name="passwordTwoFactor" />
                          )}
                        </FormHelperText>
                      </div>
                    </div>
                  )}

                  <DialogActions>
                    <div className="mr-auto">
                      {this.props.twoFactorStepperNo === 1 && (
                        <button
                          type="button"
                          onClick={() => this.props.setShowAuthenticatorKey(!this.props.showAuthenticatorKey)}
                        
                          tabIndex={0}
                          className="enter-key-btn">
                          {!this.props.showAuthenticatorKey ? "Enter key manually instead." : "Scan QR Code"}
                        </button>
                      )}
                    </div>
                    <div>
                      <Button
                        type="button"
                        color="primary"
                        variant="outlined"
                        className="secondary-button mr-1"
                        onClick={() => {
                          if (this.props.twoFactorStepperNo === 0 || this.props.twoFactorStepperNo === 2) {
                            this._handleCloseTwoFactor();
                          }
                          setErrors({});
                          setTouched({});
                          this.props.setTwoFactorStepperNo(0);
                        }}>
                        {this.props.twoFactorStepperNo === 1 ? "Back" : "Cancel"}
                      </Button>

                      <Button
                        type={this.props.twoFactorStepperNo === 2 ? "submit" : ""}
                        variant="contained"
                        className="themeing-buttons ml-1"
                        color="primary"
                        onClick={async (e) => {
                          if (this.props.twoFactorStepperNo !== 2) {
                            this.props.setTwoFactorStepperNo(this.props.twoFactorStepperNo + 1);
                          } else if (this.props.twoFactorStepperNo === 2) {
                            setTouched({ passwordTwoFactor: true, otp: true });
                            validateForm();
                            const elements = {
                              authenticationCode: values.otp,
                              emailId: JSON.parse(sessionStorage.getItem("userData"))?.emailId,
                              key: this.props.qrSetUpKey || this.state.qrSetUpKey1,
                              passWord: values.passwordTwoFactor,
                            };
                            if (values.otp && values.passwordTwoFactor) {
                              this.props.verifyQr(elements, (res) => {
                                if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
                                  this._handleCloseTwoFactor();
                                  const PassValues3 = {
                                    partyID: JSON.parse(sessionStorage.getItem("userData"))?.partyId,
                                    userLoginName: JSON.parse(sessionStorage.getItem("userData"))?.userName,
                                  };
                                  this.props.fetchUserDetailData(PassValues3, 0, (res2) => {
                                    this.props.setIsAuthenticate(res2.data.responseObject.isAuthenticate);
                                    this.props.setQrSetUpKey(res2.data.responseObject.key);
                                    this.setState({ qrSetUpKey1: res2.data.responseObject.key });
                                    handleSuccess("Two-factor authentication enabled successfully");
                                  });
                                } else if (res?.data?.responseInfo?.status === "FAILED") {
                                  handleError(res.data.responseInfo.responseMessage[0]);
                                }
                              });
                            }
                          }
                        }}>
                        {this.props.twoFactorStepperNo === 2 ? "Verify" : "Next"}
                      </Button>
                    </div>
                  </DialogActions>
                </div>
              </ConfirmationDialog>

              <ConfirmationDialog
                open={this.props.twoFactorDisablePopup}
                handleClose={() => {
                  resetForm();
                  this._handleCloseDisableTwoFactor();
                }}
                scroll={"body"}
                maxWidth={"sm"}
                fullWidth
                title={"Disable Two-Factor Authentication"}
                noActionButtons={true}>
                <div className="input-field">
                  <div className="form-row">
                    <div className="col-md-12 form-group">
                      <FormControl variant="outlined" className="w-100" fullWidth>
                        <label htmlFor="otp" className="required">
                          {" "}
                          Two-factor authentication code
                        </label>

                        <TextField
                          type="number"
                          id="otp"
                          name="otp"
                          placeholder="Enter OTP From authenticator app"
                          variant="outlined"
                          size="small"
                          className={` ${touched.otp && errors.otp ? "is-invalid" : ""}`}
                          onChange={(event) => {
                            setFieldValue((values.otp = event.target.value));
                          }}></TextField>
                      </FormControl>
                      <FormHelperText error>{touched.otp && <ErrorMessage component="div" name="otp" />}</FormHelperText>
                    </div>
                    <div className="col-md-12 form-group">
                      <FormControl variant="outlined" className="w-100" fullWidth>
                        <div className="d-flex justify-content-start">
                          <label htmlFor="password" className="required mr-2">
                            Password
                          </label>
                          <Tooltip title="Please enter the account password" placement="right-start" arrow>
                            <div className="myDIV setup-info-message">
                              <i className="fa fa-question-circle i-info10" aria-hidden="true"></i>
                            </div>
                          </Tooltip>
                        </div>

                        <TextField
                          type="password"
                          id="passwordTwoFactor"
                          name="passwordTwoFactor"
                          placeholder="Enter Password"
                          variant="outlined"
                          size="small"
                          className={` ${touched.passwordTwoFactor && errors.passwordTwoFactor ? "is-invalid" : ""}`}
                          onChange={(event) => {
                            setFieldValue((values.passwordTwoFactor = event.target.value));
                          }}></TextField>
                      </FormControl>
                      <FormHelperText error>
                        {touched.passwordTwoFactor && <ErrorMessage component="div" name="passwordTwoFactor" />}
                      </FormHelperText>
                    </div>
                  </div>
                  <DialogActions>
                    <Button
                      type="button"
                      color="primary"
                      variant="outlined"
                      className="secondary-button mr-1"
                      onClick={() => {
                        resetForm();
                        this._handleCloseDisableTwoFactor();
                      }}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      className="themeing-buttons ml-1"
                      color="primary"
                      onClick={(e) => {
                        setTouched({ passwordTwoFactor: true, otp: true });
                        validateForm();
                        if (values.otp && values.passwordTwoFactor) {
                          const elements = {
                            authenticationCode: values.otp,
                            emailId: JSON.parse(sessionStorage.getItem("userData"))?.emailId,
                            key: this.props.qrSetUpKey || this.state.qrSetUpKey1,
                            password: values.passwordTwoFactor,
                          };
                          this.props.disable2Fa(elements, (res) => {
                            if (res?.data?.responseInfo?.status === "FAILED") {
                              handleError(res?.data?.responseInfo?.responseMessage[0]);
                            } else if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
                              this.props.setTwoFactorDisablePopup(false);
                              const PassValues3 = {
                                partyID: JSON.parse(sessionStorage.getItem("userData"))?.partyId,
                                userLoginName: JSON.parse(sessionStorage.getItem("userData"))?.userName,
                              };
                              this.props.fetchUserDetailData(PassValues3, 0, (res2) => {
                                this.props.setIsAuthenticate(res2.data.responseObject.isAuthenticate);
                              });
                              handleSuccess("Two-factor authentication disabled successfully");
                            }
                          });
                        }
                      }}>
                      Disable
                    </Button>
                  </DialogActions>
                </div>
              </ConfirmationDialog>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TwofactorAuthentication);
