import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import { handleSuccess, ToastContainer, handleError } from "../../../../components/Notifications/Toast";
import SpinnerPage from "../../../../Common/PP_loader";
import { postSurveyData } from "../../../../action/SurveyAction";

function QuickSurvey({ handleClose, surveyQuestions, title, onSubmitClose }) {
  const [isSatisfied, setIsSatisied] = useState("Satisfied");
  const [submit, setSumbit] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [feedback, setFeedback] = useState("");
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      {isLoading && <SpinnerPage />}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setSumbit(true);
          if (isSatisfied && feedback) {
            setIsloading(true);
            dispatch(
              postSurveyData(
                {
                  feedbackId: 0,
                  paymentRPSurveyAnswersRequest: [
                    {
                      answerText: isSatisfied,
                      questionId: 1,
                    },
                    {
                      answerText: feedback,
                      questionId: 2,
                    },
                  ],
                  userId: JSON.parse(sessionStorage.getItem("verifypayer"))?.partyId,
                },
                (res) => {
                  setIsloading(false);
                  if (res?.status === 200) {
                    onSubmitClose();
                    handleSuccess(res?.data);
                  } else {
                    onSubmitClose();
                    handleError(res?.message);
                  }
                },
              ),
            );
          }
        }}>
        <div className="quick-survey-box">
          <div className="quick-survey-box-header">
            <Typography variant="h6 quick-survey-title-style">{title}</Typography>
          </div>
          <div className="quick-survey">
            {surveyQuestions &&
              surveyQuestions.map((data, index) => {
                return data?.questionId === 1 ? (
                  <WithIocns
                    data={data}
                    isSatisfied={isSatisfied}
                    setIsSatisied={setIsSatisied}
                    submit={submit}
                    key={index}
                  />
                ) : (
                  <WithTextArea data={data} feedback={feedback} setFeedback={setFeedback} submit={submit} key={index} />
                );
              })}
            <div className="d-flex mt-2 justify-content-center">
              <button type="button" className="secondary-button mr-1" onClick={handleClose}>
                SKIP
              </button>
              <button type="submit" className="themeing-buttons primary-button  ml-1">
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </form>
      {/* <ToastContainer /> */}
    </React.Fragment>
  );
}

const WithIocns = ({ isSatisfied, setIsSatisied, data, submit }) => {
  return (
    <div>
      <div className="quick-survey-box-question">
        <span>{data.question}</span>
      </div>
      <div className="quick-survey-box-answers d-flex justify-content-center mb-2">
        <div>
          <SentimentVerySatisfiedIcon
            className={`${
              isSatisfied === "Satisfied" ? "feedback-emoji1-active" : "feedback-emoji"
            } feedback-emoji1 feedback-emoji`}
            onClick={() => {
              setIsSatisied("Satisfied");
            }}
          />
          {isSatisfied === "Satisfied" && <div className="satisfy-status">Satisfied</div>}
        </div>
        <div>
          <SentimentVeryDissatisfiedIcon
            className={` ${
              isSatisfied === "Dissatisfied" ? "feedback-emoji2-active" : "feedback-emoji"
            } feedback-emoji2 feedback-emoji`}
            onClick={() => {
              setIsSatisied("Dissatisfied");
            }}
          />
          {isSatisfied === "Dissatisfied" && <div className="satisfy-status">Dissatisfied</div>}
        </div>
      </div>
    </div>
  );
};
const WithTextArea = ({ feedback, setFeedback, submit, data }) => {
  return (
    <div className="quick-survey-box-question">
      <span>{data?.question}</span>
      <div className="survey-feedback-box">
        <textarea
          className="survey-textarea"
          name=""
          id=""
          rows="5"
          maxLength="300"
          placeholder="Enter Feedback"
          onChange={(e) => {
            setFeedback(e.target.value);
          }}></textarea>
        {submit && feedback.length === 0 && <div className="text-danger text-left">Please enter feedback</div>}
      </div>
    </div>
  );
};

export default QuickSurvey;
