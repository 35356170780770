import React from "react";
import ReactDOM from "react-dom";
import "./scss/main.scss";
import App from "./MainApp";
// import TimerContainer from "../src/containers/App";
import ThemeProvider from "./themes/ThemeProvider";
import { Provider } from "react-redux";
import store from "../src/store";
import { ReactNotifications } from "react-notifications-component";
// import "react-notifications-component/dist/scss";
import * as serviceWorker from "./serviceWorker";
import AppProvider from "./context/AppProvider";

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <ThemeProvider>
        <Provider store={store}>
          <ReactNotifications />
          <App />
          {/* <TimerContainer /> */}
        </Provider>
      </ThemeProvider>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
