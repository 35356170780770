import React from "react";
import { DropzoneArea } from "mui-file-dropzone";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));
function UploadSign({ setFieldValue, handleBase64String, updateUserSignature }) {
  const handleImage = async (files) => {
    const imagedata = files?.length > 0 && files[0];
    if (imagedata) {
      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => {
            reject(error);
            console.log(error, "file size");
          };
        });
      const base64Data = await toBase64(imagedata);
      handleBase64String(base64Data);
      updateUserSignature({ signature: base64Data });
    }
  };

  const handleChangeStatus = ({ meta }, status) => {
   
  };

  const classes = useStyles();
  return (
    <div className="upload-sec mb-2">
      <DropzoneArea
        acceptedFiles={["image/png", "image/jpeg", "image/jpg"]}
        dropzoneText={
          <span style={{ fontSize: "15px" }}>
            <Typography>Drag 'n' drop signature file here, or click to select file</Typography>
            <em>(Accepted File Formats: JPG, PNG, JPEG. Max file size 250KB)</em>
          </span>
        }
        maxFileSize={266000}
        filesLimit={1}
        name="signature"
        onChangeStatus={handleChangeStatus}
        onChange={(files) => {
          handleImage(files);
        }}
        onDelete={() => setFieldValue("signature", "")}
        getDropRejectMessage={(p) => {
          return `${"File " + p?.name + " was rejected. File is too big. Size limit is 250KB"}`;
        }}
      />
    </div>
  );
}

export default UploadSign;
