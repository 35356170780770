import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { Collapse, List, Tooltip } from "@material-ui/core";
import ListItemButton from "@mui/material/ListItemButton";
import CampaignIcon from "@mui/icons-material/Campaign";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import ForumIcon from "@mui/icons-material/Forum";
import FlakyIcon from "@mui/icons-material/Flaky";
import Icons from "../../../util/Icons";
import { Link, useLocation } from "react-router-dom";
import Med1Logo from "../../../assets/images/Med1Logo.png";
import SideBar from "../../../components/SideBar";
import Profile from "../../../components/Profile";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PaymentsIcon from "@mui/icons-material/Payments";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import { AppContext } from "../../../context/AppProvider";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import EmailIcon from "@material-ui/icons/Email";
import DescriptionIcon from "@material-ui/icons/Description";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import HandshakeIcon from "@mui/icons-material/Handshake";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { UserManagementService } from "../../../services/UserManagementService";
import PieChartIcon from "@mui/icons-material/PieChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import TableChartIcon from "@mui/icons-material/TableChart";
import PersonIcon from "@mui/icons-material/Person";
import { handleSuccess } from "../../../components/Notifications/Toast";

const PASideNav = ({ hoverNav, setHoverNav }) => {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const {
    sidebar,
    setSidebar,
    reportBar,
    setReportBar,
    clientManagementBar,
    setClientManagementBar,
    hoverSide,
    setHoverSide,
  } = useContext(AppContext);
  const role = sessionStorage.getItem("currentUserRole");
  const isVerifyAndPay = sessionStorage.getItem("verifyAndPay");
  const isBack = sessionStorage.getItem("back");
  const history = useHistory();
  const [expanded, setExpanded] = React.useState("panel1");
  const [sidenavPatientSearch, setSidenavPatientSearch] = useState(false);
  const [sidenavAccountsUpdated, setSidenavAccountsUpdated] = useState(false);
  const [sidenavCommunications, setSidenavCommunications] = useState(false);
  const [sidenavOptin, setSidenavOptin] = useState(false);
  const [sidenavPaymentPlans, setSidenavPaymentPlans] = useState(false);
  const [sidenavPaymentReconciliation, setSidenavPaymentReconciliation] = useState(false);
  const [sidenavTransactionSearch, setSidenavTransactionSearch] = useState(false);
  const [sidenavMail, setSidenavMail] = useState(false);
  const [sidenavUnlockUser, setSidenavUnlockUser] = useState(false);
  const [sidenavUserManagement, setSidenavUserManagement] = useState(false);
  const [sidenavReverseTransaction, setSidenavReverseTransaction] = useState(false);
  const [sidenavpaymentMethods, setSidenavpaymentMethods] = useState(false);
  const [sidenavSurveyDashboard, setSidenavSurveyDashboard] = useState(true);
  const [sidenavPaymentHistory, setSidenavPaymentHistory] = useState(false);
  const [campaignTracking, setCampaignTracking] = useState(false);
  const [reports, setReports] = useState(true);
  const [clientMaintenance, setClientMaintenance] = useState(false);
  const [paymentPlanOptions, setPaymentPlanOptions] = useState(false);
  const [settilementOptions, setSettilementOptions] = useState(false);
  const [sidenavScheduledTransaction, setSidenavScheduledTransaction] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const [sidenavRPSettlements, setSidenavRPSettlements] = useState(false);
  useEffect(() => {
    setSidenavPaymentHistory(true);
    setSidenavpaymentMethods(true);
    const id = JSON.parse(sessionStorage.getItem("userData"))?.role[0]?.roleLevel;

    if (id || (id === 0 && sessionStorage.getItem("currentUserRole") === "Admin")) {
      UserManagementService.roleAccess(id, (res) => {
        if (Object.keys(res).length > 1) {
          sessionStorage.setItem("roleData", JSON.stringify(res?.data?.responseObject));
        }
        if (res?.data?.responseObject?.level === 0) {
          setReports(false);
        }

        res?.data?.responseObject?.pages?.map((item) => {
          if (item?.functionalities?.["Patient Search"]?.["page access"]) {
            setSidenavPatientSearch(item?.functionalities?.["Patient Search"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["Reports- Accounts Updated"]?.["page access"]) {
            setSidenavAccountsUpdated(item?.functionalities?.["Reports- Accounts Updated"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["Reports- Scheduled transactions"]?.["page access"]) {
            setSidenavScheduledTransaction(
              item?.functionalities?.["Reports- Scheduled transactions"]?.["page access"] === "true",
            );
          }
          if (item?.functionalities?.["Reports- Communications By"]?.["page access"]) {
            setSidenavCommunications(item?.functionalities?.["Reports- Communications By"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["Reports- Opt-in/Opt-out by communication Type"]?.["page access"]) {
            setSidenavOptin(
              item?.functionalities?.["Reports- Opt-in/Opt-out by communication Type"]?.["page access"] === "true",
            );
          }
          if (item?.functionalities?.["Reports- Payment Plans"]?.["page access"]) {
            setSidenavPaymentPlans(item?.functionalities?.["Reports- Payment Plans"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["Reports- Payment Reconciliation"]?.["page access"]) {
            setSidenavPaymentReconciliation(
              item?.functionalities?.["Reports- Payment Reconciliation"]?.["page access"] === "true",
            );
          }
          if (item?.functionalities?.["Reports- Transaction Search"]?.["page access"]) {
            setSidenavTransactionSearch(item?.functionalities?.["Reports- Transaction Search"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["Reports- Reverse Transaction"]?.["page access"]) {
            setSidenavReverseTransaction(
              item?.functionalities?.["Reports- Reverse Transaction"]?.["page access"] === "true",
            );
          }
          if (item?.functionalities?.["Client Maintenance"]?.["page access"]) {
            setClientMaintenance(item?.functionalities?.["Client Maintenance"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["Payment Plan Options"]?.["page access"]) {
            setPaymentPlanOptions(item?.functionalities?.["Payment Plan Options"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["Settlement Options"]?.["page access"]) {
            setSettilementOptions(item?.functionalities?.["Settlement Options"]?.["page access"] === "true");
          }
          if (item?.name === "Dashboard Sidenav" && item?.functionalities?.["Resend Confirmation Email"]?.["page access"]) {
            setSidenavMail(item?.functionalities?.["Resend Confirmation Email"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["Unlock user"]?.["page access"]) {
            setSidenavUnlockUser(item?.functionalities?.["Unlock user"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["User Management"]?.["page access"]) {
            setSidenavUserManagement(item?.functionalities?.["User Management"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["Survey dashboard"]?.["page access"]) {
            setSidenavSurveyDashboard(item?.functionalities?.["Survey dashboard"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["Payment methods"]?.["page access"]) {
            setSidenavpaymentMethods(item?.functionalities?.["Payment methods"]?.["page access"]);
          }

          if (item?.functionalities?.["Payment history"]?.["page access"]) {
            setSidenavPaymentHistory(item?.functionalities?.["Payment history"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["PURL Usage"]?.["page access"]) {
            setCampaignTracking(item?.functionalities?.["PURL Usage"]?.["page access"] === "true");
          }
          if (item?.functionalities?.["Settelment options PA"]?.["page access"]) {
            setSidenavRPSettlements(item?.functionalities?.["Settelment options PA"]?.["page access"] === "true");
          }
        });
      });
    }
  }, []);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  function capitalizeFirstLetter(string) {
    if (string) {
      return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    } else {
      return "";
    }
  }
  let userinfo = JSON.parse(sessionStorage.getItem("verifypayer"));
  let paUserinfo = JSON.parse(sessionStorage?.getItem("loginDetails"));
  if (
    window.location.href.includes("/pa/unlock-user") ||
    window.location.href.includes("/pa/user-management") ||
    window.location.href.includes("/pa/clientManagement/clientMaintenance") ||
    window.location.href.includes("/pa/clientManagement/paymentPlanOptions") ||
    window.location.href.includes("/pa/clientManagement/settilementOptions") ||
    window.location.href.includes("/pa/survey-dashboard") ||
    window.location.href.includes("/pa/reports/transaction-search") ||
    window.location.href.includes("/pa/reports/payment-reconcilation") ||
    window.location.href.includes("/pa/reports/account-updated") ||
    window.location.href.includes("/pa/reports/communication") ||
    window.location.href.includes("/pa/reports/opt") ||
    window.location.href.includes("/pa/reports/payment-plans") ||
    window.location.href.includes("/pa/reports/scheduled-PP-transaction") ||
    window.location.href.includes("/pa/reports/scheduled-FP-transaction") ||
    window.location.href.includes("/pa/rp-settlements") ||
    window.location.href.includes("/pa/payments-received") ||
    window.location.href.includes("/pa/purl-usage") ||
    window.location.href.includes("/pa/viewed-but-not-paid") ||
    window.location.href.includes("/pa/patientDetails")
  ) {
    sessionStorage.setItem("verifyAndPay", "No");
  } else if (
    window.location.href.includes("/pa/billing") ||
    window.location.href.includes("/pa/payment-history") ||
    window.location.href.includes("/pa/payment-methods")
  ) {
    sessionStorage.setItem("verifyAndPay", true);
  }

  return (
    <SideBar logo={Med1Logo} isSidebarCollapsed={sidebar ? true : false}>
      <div className="profile mt-3">
        
        <div>
          {role === "Admin" && isVerifyAndPay === "No" ? (
            <Profile
              userName={paUserinfo ? capitalizeFirstLetter(paUserinfo?.userName) : ""}
              route={"pa"}
              subtitle={paUserinfo ? paUserinfo?.role?.[0]?.roleName : ""}
              isSidebarCollapsed={sidebar || hoverNav ? true : false}
            />
          ) : (
            <Profile
              userName={capitalizeFirstLetter(userinfo?.firstName) + " " + capitalizeFirstLetter(userinfo?.lastName)}
              route={"home"}
              subtitle={"23487985"}
              isSidebarCollapsed={sidebar || hoverNav ? true : false}
            />
          )}
        </div>
      </div>
      <div className={`nav-links ${sidebar ? "" : "d-none-label"}`}>
        {role === "Admin" && isVerifyAndPay === "No" ? (
          <>
            {sidenavPatientSearch && (
              <Link
                to="/pa/patientDetails"
                aria-label="patient search"
                className={location?.pathname === "/pa/patientDetails" ? "active link-item" : "link-item"}>
                {/* <PermIdentityIcon />  */}
                <Icons type="patientSearch" />
                <span>Patient Search</span>
              </Link>
            )}

            {sidenavUnlockUser && (
              <Link
                to="/pa/unlock-user"
                aria-label="reports"
                onClick={() => setHoverNav(true)}
                className={location?.pathname === "/pa/unlock-user" ? "active link-item" : "link-item"}>
                <LockOpenIcon /> <span>Unlock User</span>
              </Link>
            )}

            {sidenavMail && (
              <Link
                to="/pa/resend-email-confirmation"
                className={
                  location?.pathname === "/pa/resend-email-confirmation"
                    ? "active link-item d-flex"
                    : "resend-verification link-item d-flex"
                }
                onClick={() => setHoverNav(true)}
                aria-label="reports">
                <div>
                  <EmailIcon />
                </div>
                <div>
                  <span>Resend Confirmation Email</span>
                </div>
              </Link>
            )}

            {sidenavUserManagement && (
              <Link
                to="/pa/user-management"
                onClick={() => setHoverNav(true)}
                className={location?.pathname === "/pa/user-management" ? "active link-item" : "link-item"}
                aria-label="reports">
                <Icons type="userManagement" /> <span>User Management</span>
              </Link>
            )}

            {(clientMaintenance || paymentPlanOptions || settilementOptions) && (
              <Link
                className={clientManagementBar ? "active" : "link-item"}
                onClick={() => {
                  clientManagementBar === false ? setClientManagementBar(true) : setClientManagementBar(false);
                  setHoverNav(true);
                }}>
                <Icons type="clientMaintenance" />{" "}
                <span>Client Management {clientManagementBar ? <ExpandLess /> : <ExpandMore />}</span>
              </Link>
            )}

            {clientManagementBar === true ? (
              <div className="reportContainer">
                {clientMaintenance && (
                  <Link
                    to="/pa/clientManagement/clientMaintenance"
                    aria-label="reports"
                    onClick={() => setHoverNav(true)}
                    className={
                      location?.pathname === "/pa/clientManagement/clientMaintenance" ? "active link-item" : "link-item"
                    }>
                    {"     "}
                    <Icons type="clientManagement" /> <span>Client Maintenance</span>
                  </Link>
                )}
                {paymentPlanOptions && (
                  <Link
                    to="/pa/clientManagement/paymentPlanOptions"
                    aria-label="reports"
                    onClick={() => setHoverNav(true)}
                    className={
                      location?.pathname === "/pa/clientManagement/paymentPlanOptions" ? "active link-item" : "link-item"
                    }>
                    {"     "}
                    <Icons type="paymentPlan" /> <span>Payment Plan Options</span>
                  </Link>
                )}
                {settilementOptions && (
                  <Link
                    to="/pa/clientManagement/settilementOptions"
                    aria-label="reports"
                    onClick={() => setHoverNav(true)}
                    className={
                      location?.pathname === "/pa/clientManagement/settilementOptions" ? "active link-item" : "link-item"
                    }>
                    {"     "}
                    <Icons type="settlementOptions" /> <span>Settlement Options</span>
                  </Link>
                )}
              </div>
            ) : null}

            {sidenavSurveyDashboard && (
              <Link
                to="/pa/survey-dashboard"
                onClick={() => setHoverNav(true)}
                className={location?.pathname === "/pa/survey-dashboard" ? "active link-item" : "link-item"}
                aria-label="reports">
                <DonutSmallIcon /> <span>Survey Dashboard</span>
              </Link>
            )}

            {reports && (
              <Link
                className={reportBar ? "active" : "link-item"}
                onClick={() => {
                  reportBar === false ? setReportBar(true) : setReportBar(false);
                  setReportOpen((prev) => !prev);
                  setHoverNav(true);
                }}>
                <AssessmentIcon /> <span>Reports {!reportBar ? <ExpandMore /> : <ExpandLess />}</span>
              </Link>
            )}

            {reportBar === true ? (
              <div className="reportContainer">
                {sidenavTransactionSearch && (
                  <Link
                    to="/pa/reports/transaction-search"
                    aria-label="reports"
                    className={location?.pathname === "/pa/reports/transaction-search" ? "active link-item" : "link-item"}>
                    {" "}
                    <ManageSearchIcon /> <span>Transaction Search</span>
                  </Link>
                )}
                {sidenavPaymentReconciliation && (
                  <Link
                    to="/pa/reports/payment-reconcilation"
                    aria-label="reports"
                    className={
                      location?.pathname === "/pa/reports/payment-reconcilation" ? "active link-item" : "link-item"
                    }>
                    {" "}
                    <LocalAtmIcon /> <span>Payment Reconciliation</span>
                  </Link>
                )}
                {sidenavAccountsUpdated && (
                  <Link
                    to="/pa/reports/account-updated"
                    aria-label="reports"
                    className={location?.pathname === "/pa/reports/account-updated" ? "active link-item" : "link-item"}>
                    {" "}
                    <AssessmentIcon /> <span>Accounts Updated</span>
                  </Link>
                )}
                {sidenavCommunications && (
                  <Link
                    to="/pa/reports/communication"
                    aria-label="reports"
                    className={location?.pathname === "/pa/reports/communication" ? "active link-item" : "link-item"}>
                    {" "}
                    <ForumIcon /> <span>Communication By</span>
                  </Link>
                )}
                {sidenavOptin && (
                  <Link
                    to="/pa/reports/opt"
                    aria-label="reports"
                    className={location?.pathname === "/pa/reports/opt" ? "active link-item" : "link-item"}>
                    {" "}
                    <FlakyIcon /> <span>Opt-In/Opt-Out by communication type</span>
                  </Link>
                )}
                {sidenavPaymentPlans && (
                  <Link
                    to="/pa/reports/payment-plans"
                    aria-label="reports"
                    className={location?.pathname === "/pa/reports/payment-plans" ? "active link-item" : "link-item"}>
                    {" "}
                    <PaymentsIcon /> <span>Payment Plans</span>
                  </Link>
                )}
                {sidenavScheduledTransaction && (
                  <Link
                    to="/pa/reports/scheduled-PP-transaction"
                    aria-label="reports"
                    className={
                      location?.pathname === "/pa/reports/scheduled-PP-transaction"
                        ? "active d-flex link-item"
                        : "d-flex link-item"
                    }>
                    {" "}
                    <div>
                      <Icons type="reportsScheduledTransactions" />
                    </div>
                    <div>
                      <span>Scheduled Transactions- Payment Plans</span>
                    </div>
                  </Link>
                )}
                {sidenavScheduledTransaction && (
                  <Link
                    to="/pa/reports/scheduled-FP-transaction"
                    aria-label="reports"
                    className={
                      location?.pathname === "/pa/reports/scheduled-FP-transaction"
                        ? "active d-flex link-item"
                        : "d-flex link-item"
                    }>
                    {" "}
                    <div className="scheduled-icons">
                      <CalendarTodayIcon className="scheduled-calender" /> <AttachMoneyIcon className="scheduled-dollar" />{" "}
                    </div>
                    <div>
                      <span>Scheduled Transactions-Future Payments</span>
                    </div>
                  </Link>
                )}
              </div>
            ) : null}

            {sidenavRPSettlements && (
              <Link
                to="/pa/rp-settlements"
                onClick={() => setHoverNav(true)}
                className={location?.pathname === "/pa/rp-settlements" ? "active link-item" : "link-item"}
                aria-label="reports">
                <AccountBalanceWalletIcon /> <span>RP Settlements</span>
              </Link>
            )}

            {campaignTracking ? (
              <Link
                className={open ? "active" : "link-item"}
                onClick={() => {
                  setOpen((prevState) => !prevState);
                  setHoverNav(true);
                }}>
                <CampaignIcon /> <span>Campaign Tracking {open ? <ExpandLess /> : <ExpandMore />}</span>
              </Link>
            ) : null}
            <Collapse in={open} timeout="auto" unmountOnExit>
              <div className="reportContainer">
                <Link
                  onClick={() => history.push("/pa/payments-received")}
                  className={location?.pathname === "/pa/payments-received" ? "active link-item" : "link-item"}>
                  {"     "}
                  <MonetizationOnIcon /> <span>Payments Received</span>
                </Link>
                <Link
                  onClick={() => history.push("/pa/purl-usage")}
                  className={location?.pathname === "/pa/purl-usage" ? "active link-item" : "link-item"}>
                  {"     "}
                  <DataUsageIcon /> <span>PURL Usage</span>
                </Link>
                <Link
                  onClick={() => history.push("/pa/viewed-but-not-paid")}
                  className={location?.pathname === "/pa/viewed-but-not-paid" ? "active link-item" : "link-item"}>
                  {"     "}
                  <TableChartIcon /> <span>Viewed But Not Paid</span>
                </Link>
              </div>
            </Collapse>
            <Link
              className={
                reportBar && sidebar
                  ? "logout-link link-item"
                  : sidebar
                  ? "logout-link link-item"
                  : "logout-collapsed link-item"
              }
              to="/admin"
              aria-label="logout"
              onClick={() => {
                localStorage.removeItem("currentUserRole");
                localStorage.clear();
                sessionStorage.clear();
                handleSuccess("Logout Successfully");
              }}>
              <ExitToAppIcon /> <span className={sidebar ? "" : ""}>Logout </span>
            </Link>
          </>
        ) : (
          <>
            <Link
              to={isVerifyAndPay ? "/pa/billing" : "/home/billing"}
              aria-label="billing"
              onClick={() => setHoverNav(true)}
              className={
                location?.pathname === "/home/billing" || location?.pathname === "/pa/billing"
                  ? "active link-item"
                  : "link-item"
              }>
              <PersonIcon />
              <span>Account Details</span>
            </Link>

            {sidenavPaymentHistory && (
              <Link
                to={isVerifyAndPay ? "/pa/payment-history" : "/home/payment-history"}
                aria-label="account"
                onClick={() => setHoverNav(true)}
                className={
                  location?.pathname === "/home/payment-history" || location?.pathname === "/pa/payment-history"
                    ? "active link-item"
                    : "link-item"
                }>
                <DescriptionIcon /> <span>Payment History</span>
              </Link>
            )}
            {sidenavpaymentMethods && (
              <Link
                to={isVerifyAndPay ? "/pa/payment-methods" : "/home/payment-methods"}
                aria-label="transactions"
                onClick={() => setHoverNav(true)}
                className={
                  location?.pathname === "/home/payment-methods" || location?.pathname === "/pa/payment-methods"
                    ? "active link-item"
                    : "link-item"
                }>
                <CreditCardIcon /> <span>Payment Methods</span>
              </Link>
            )}

            <Link
              className={sidebar ? "logout-link link-item" : "logout-collapsed link-item"}
              to={isVerifyAndPay ? "/admin" : "/signin"}
              aria-label="signin"
              onClick={() => {
                localStorage.removeItem("currentUserRole");
                localStorage.clear();
                sessionStorage.clear();
                handleSuccess("Logout Successfully");
              }}>
              <ExitToAppIcon /> <span className={sidebar ? "" : ""}>Logout </span>
            </Link>
          </>
        )}
      </div>
    </SideBar>
  );
};

export default PASideNav;
