import React from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Checkbox, MenuItem, FormControl, FormControlLabel, Select, FormLabel, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { IconButton } from "@material-ui/core";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ImageTooltip from "../ImageTooltip";
import echeckPaymentRoutingNumber from "../../assets/images/echeckPaymentRoutingNumber.jpg";
import { cityData, countryData, allStatesData } from "../../util/StatesData";
import { Grid } from "@material-ui/core";
import { countryList, stateList } from "../../containers/RegionList";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import Signature from "../Signature";
import UpdateTrackingPage from "../../containers/UpdateTrackingPage";

class Echeck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardnumber: true,
      routingNumber: true,
      accountNumberShow: true,
      reAccountNumber: true,
      showForm: true,
      showloader: false,
      countryList: [],
      cityList: [],
      country: "united states of america",
      state: "",
      city: "",
      carddata: "",
      payeraddrdata: "",
      isMaskOne: true,
      instrumentid: "",
      ES_AppId: "",
      ES_PartyId: "",
      ES_Amount: "",
      ES_SubId: "",
      ES_Method: "",
      ES_OrderId: "",
      ES_url: "",
      ES_PlanId: "",
      ES_PriceSubId: "",
      ES_PurchaseParameters: "",
      ES_JsonData: "",
      seedAccNum: this.props.location?.state?.seedAccNum,
      values: this.props.location?.state?.values,
      frequencyType: this.props.location?.state?.frequencyType,
      page: this.props.location?.state?.page,
      editECheckData:
        this.props.location?.state?.selectedECheck &&
        this.props.location?.state?.selectedECheck.length > 0 &&
        this.props.location?.state?.selectedECheck[0],
      orderId: this.props.location?.state?.orderId,
      plan: this.props.location?.state?.plan,
      paymentInstrumentId: 0,
      linkedPaymentMethodEntityId: 0,
      billingAddressId: 0,
      accountNumber: "",
      maskedAccountNumber: "",
      maskedRoutingNumber: "",
      billingAddress: {
        billingAddressId: 0,
        city: "",
        country: "",
        email: "",
        firstName: "",
        lastName: "",
        middleName: "",
        phoneNo: "",
        postalCode: "",
        state: "",
        street: "",
        street2: "",
      },
      isSignatureModalOpen: false,
      italicSign: "",
      signature: "",
    };
    this.updateUserSignature = this.updateUserSignature.bind(this);
  }

  schema = Yup.object().shape({});
  onSubmit = (values) => {
    console.log(values, ":values");
  };
  updateUserSignature(nextState) {
    let sign = nextState?.signature;
    this.setState({ signature: sign });
  }
  handleCardNumber = () => {
    this.setState({ cardnumber: !this.state.cardnumber });
  };

  handleRoutingNumber = () => {
    this.setState({ routingNumber: !this.state.routingNumber });
  };

  handleAccountNumber = () => {
    this.setState({ accountNumberShow: !this.state.accountNumberShow });
  };

  handleReAccountNumber = () => {
    this.setState({ reAccountNumber: !this.state.reAccountNumber });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  render() {
    const billing = this.state.billingAddress;
    return (
      <>
        <div className="change-password dashboard-content" role="main" style={{ padding: "0", borderRadius: "4px" }}>
          <div className="dashboard-content-card">
            <Formik
              initialValues={{
                accountNumber: this.state.accountNumber || "",
                reenteraccountNumber: this.state.reenteraccountNumber || "",
                routingnumber: this.state.routingnumber || "",
                accountType: this.state.accountType,
                stateList: stateList ? stateList : [],
                cityList: this.state.cityList || [],
                emailAddress: this.state.emailAddress || billing.email,
                phoneNumber: this.state.phoneNumber || billing.phoneNo,
                address1: this.state.address1 || billing.street,
                address2: this.state.address2 || billing.street2,
                country: this.state.country || billing.country,
                countryid: this.state.countryid ? this.state.countryid : "",
                state: this.state.state || billing.state,
                stateid: this.state.stateid ? this.state.stateid : "",
                city: this.state.city || billing.city,
                cityid: this.state.cityid ? this.state.cityid : "",
                zipCode: this.state.zipCode || billing.postalCode,
                termsconditions: true,
                userdata: true,
                authorizeElectronicDebit: true,
                firstName: this.state.firstName || billing.firstName,
                lastName: this.state.lastName || billing.lastName,
                middleName: this.state.middleName || billing.middleName,
                italicSign: this.state.italicSign || "",
                signature: this.state.signature || "",
                isItalicSign: false,
                showRoutingNumber: false,
                showAccountNumber1: false,
                showAccountNumber2: false,
                maskedAccountNumber: this.state.maskedAccountNumber,
                maskedRoutingNumber: this.state.maskedRoutingNumber,
                futureDate: new Date(),
              }}
              onSubmit={this.onSubmit}>
              {(props) => {
                const { values, touched, error, handleChange, errors, setFieldValue } = props;
                return (
                  <Form className="input-field e-check-bg" noValidate="noValidate">
                    <div className="row">
                      <div className="col-md-7 mx-auto">
                        <div className="echeck-card">
                          <div className="row">
                            {this.props.futurePayment && (
                              <>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <Grid container className="date-picker">
                                        <label htmlFor="futureDate" className="form-label">
                                          Future Date
                                        </label>
                                        <DatePicker
                                          disableFuture
                                          name="futureDate"
                                          autoOk
                                          value={values.futureDate}
                                          onChange={(val) => setFieldValue("futureDate", val)}
                                          renderInput={(params) => (
                                            <TextField {...params} helperText={null} margin="dense" />
                                          )}
                                        />
                                      </Grid>
                                    </LocalizationProvider>
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="row">
                              <div className="col-md-12">
                                <div className="row ml-1 debitcard">
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <FormLabel htmlFor="firstName" className="required">
                                        First Name
                                      </FormLabel>
                                      <OutlinedInput
                                        className="form-control"
                                        name="firstName"
                                        value={values.firstName}
                                        onChange={handleChange}
                                        placeholder="Enter First Name"
                                        aria-label="text-field"
                                        fullWidth
                                        required></OutlinedInput>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <FormLabel htmlFor="middleName" className="required">
                                        Middle Name
                                      </FormLabel>
                                      <OutlinedInput
                                        className="form-control"
                                        name="middleName"
                                        value={values.middleName}
                                        onChange={handleChange}
                                        placeholder="Enter Middle Name"
                                        aria-label="text-field"
                                        fullWidth
                                        required></OutlinedInput>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <FormLabel htmlFor="lastName" className="required">
                                        Last Name
                                      </FormLabel>
                                      <OutlinedInput
                                        className="form-control"
                                        name="lastName"
                                        value={values.lastName}
                                        onChange={handleChange}
                                        placeholder="Enter Last Name"
                                        aria-label="text-field"
                                        fullWidth
                                        required></OutlinedInput>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group inputbg">
                                      <div className="row m-0 justify-content-between align-items-center">
                                        <FormLabel htmlFor="routingNumber" className="required">
                                          Routing Number
                                        </FormLabel>
                                        <ImageTooltip img={echeckPaymentRoutingNumber} width="450px" placement="right">
                                          <i
                                            className="fas fa-question-circle circle-icon"
                                            style={{ cursor: "pointer" }}></i>
                                        </ImageTooltip>
                                      </div>
                                      <OutlinedInput
                                        className="form-control signup-fields"
                                        name="routingNumber"
                                        value={values.routingNumber}
                                        onChange={handleChange}
                                        type={this.state.routingNumber ? "password" : "text"}
                                        placeholder="Enter Routing Number"
                                        aria-label="text-field"
                                        fullWidth
                                        required
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={this.handleRoutingNumber}
                                              onMouseDown={this.handleMouseDownPassword}>
                                              {this.state.routingNumber ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                          </InputAdornment>
                                        }></OutlinedInput>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group inputbg">
                                      <FormControl variant="outlined" className="w-100" fullWidth>
                                        <FormLabel htmlFor="accountType" className="form-label required">
                                          Account Type
                                        </FormLabel>
                                        <Select
                                          fullWidth
                                          name="accountType"
                                          onChange={handleChange("accountType")}
                                          value={values.accountType}
                                          className="form-select form-control">
                                          <MenuItem value="none" disabled>
                                            Select Card Type
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group inputbg">
                                      <div className="row m-0 justify-content-between align-items-center">
                                        <FormLabel htmlFor="accountNumber" className="required">
                                          Account Number
                                        </FormLabel>

                                        <ImageTooltip img={echeckPaymentRoutingNumber} width="450px" placement="right">
                                          <i
                                            className="fas fa-question-circle circle-icon"
                                            style={{ cursor: "pointer" }}></i>
                                        </ImageTooltip>
                                      </div>
                                      <OutlinedInput
                                        className="form-control signup-fields"
                                        name="accountNumber"
                                        value={values.accountNumber}
                                        onChange={handleChange}
                                        placeholder="Enter Account Number"
                                        aria-label="text-field"
                                        fullWidth
                                        type={this.state.accountNumberShow ? "password" : "text"}
                                        required
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={this.handleAccountNumber}
                                              onMouseDown={this.handleMouseDownPassword}>
                                              {this.state.accountNumberShow ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                          </InputAdornment>
                                        }></OutlinedInput>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group inputbg">
                                      <FormLabel htmlFor="reAccountNumber" className="required">
                                        Re-enter Account Number
                                      </FormLabel>
                                      <OutlinedInput
                                        className="form-control signup-fields"
                                        name="reAccountNumber"
                                        value={values.reAccountNumber}
                                        onChange={handleChange}
                                        type={this.state.reAccountNumber ? "password" : "text"}
                                        placeholder="Re-enter Account Number"
                                        aria-label="text-field"
                                        fullWidth
                                        required
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={this.handleReAccountNumber}
                                              onMouseDown={this.handleMouseDownPassword}>
                                              {this.state.reAccountNumber ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                          </InputAdornment>
                                        }></OutlinedInput>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 d-flex flex-wrap">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <FormLabel htmlFor="emailAddress" className="required">
                                      Email Address
                                    </FormLabel>
                                    <OutlinedInput
                                      className="form-control"
                                      name="email"
                                      value={values.email}
                                      onChange={handleChange}
                                      type="email"
                                      placeholder="Enter Email Address"
                                      aria-label="text-field"
                                      fullWidth
                                      required></OutlinedInput>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <FormLabel htmlFor="phoneNumber" className="required">
                                      Phone Number
                                    </FormLabel>
                                    <OutlinedInput
                                      className="form-control"
                                      name="phoneNumber"
                                      value={values.phoneNumber}
                                      onChange={handleChange}
                                      placeholder="Enter Phone Number"
                                      aria-label="text-field"
                                      fullWidth
                                      required></OutlinedInput>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <FormLabel htmlFor="billingAddress" className="required">
                                      Billing Address
                                    </FormLabel>
                                    <OutlinedInput
                                      className="form-control"
                                      name="message"
                                      value={values.message}
                                      onChange={handleChange}
                                      rows={5}
                                      placeholder="Enter Your Billing Address"
                                      aria-label="text-field"
                                      fullWidth
                                      required></OutlinedInput>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group inputbg">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <FormLabel htmlFor="country" className="form-label required">
                                        Country
                                      </FormLabel>
                                      <Select
                                        fullWidth
                                        name="country"
                                        value={values.country}
                                        onChange={handleChange}
                                        className="form-select form-control">
                                        <MenuItem value="none" disabled>
                                          Select Country
                                        </MenuItem>
                                        {countryData.map((option) => {
                                          return (
                                            <MenuItem value={option.key} key={option.key}>
                                              {option.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group inputbg">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <FormLabel htmlFor="state" className="form-label required">
                                        State/County
                                      </FormLabel>
                                      <Select
                                        fullWidth
                                        name="StateCounty"
                                        value={values.StateCounty}
                                        onChange={handleChange}
                                        className="form-select form-control">
                                        <MenuItem value="none" key="none" disabled>
                                          Select State/County
                                        </MenuItem>
                                        {allStatesData.map((option) => {
                                          return (
                                            <MenuItem value={option.key} key={option.key}>
                                              {option.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group inputbg">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <FormLabel htmlFor="city" className="form-label required">
                                        City
                                      </FormLabel>
                                      <Select
                                        onChange={handleChange}
                                        fullWidth
                                        name="city"
                                        value={values.city}
                                        className="form-select form-control">
                                        <MenuItem value="none" key="none" disabled>
                                          Select City
                                        </MenuItem>
                                        {cityData.map((option) => {
                                          return (
                                            <MenuItem value={option.key} key={option.key}>
                                              {option.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <FormLabel htmlFor="zipCode" className="required">
                                      ZIP Code
                                    </FormLabel>
                                    <OutlinedInput
                                      className="form-control"
                                      name="zipCode"
                                      value={values.zipCode}
                                      onChange={handleChange}
                                      placeholder="Enter ZIP Code"
                                      aria-label="text-field"
                                      fullWidth
                                      required></OutlinedInput>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <Signature
                              values={values}
                              touched={touched}
                              handleChange={handleChange}
                              errors={errors}
                              setFieldValue={setFieldValue}
                              updateUserSignature={this.updateUserSignature}
                            />
                          </div>
                          <div className="agree-terms">
                            <div className=" check-FormLabel">
                              <FormControl fullWidth component="fieldset" margin="normal">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="agreeToterms"
                                      checked={values.agreeToterms}
                                      onChange={handleChange}
                                      value={values.agreeToterms}
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <span>
                                      By checking this box,you signify you have reviewed,understand,meet and agree to the{" "}
                                      <a href="/">Online Payment Terms and Conditions</a>
                                    </span>
                                  }
                                />
                              </FormControl>
                            </div>
                            <div className="check-FormLabel">
                              <FormControl fullWidth component="fieldset" margin="normal">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="agreeToEmailUsage"
                                      value={values.agreeToEmailUsage}
                                      checked={values.agreeToEmailUsage}
                                      onChange={handleChange}
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <span>
                                      By checking this box,you agree that this is your personal email address and you
                                      authorise us to send you emails,call your phone, or contact you via an automated
                                      dialer,leave voicemails, and/or send text messages if necessary.
                                    </span>
                                  }
                                />
                              </FormControl>
                            </div>
                            <div className=" check-FormLabel">
                              <FormControl fullWidth component="fieldset" margin="normal">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="authorizeElectronicDebit"
                                      checked={values.authorizeElectronicDebit}
                                      onChange={handleChange}
                                      value={values.authorizeElectronicDebit}
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <span>
                                      I authorize MED-1 Solutions to electronically debit my checking/savings account for the
                                      amount and term indicated above. I understand that electronic debits will be returned
                                      if there are insufficient funds in the account at the time of the debit. I also
                                      understand that if my payment is returned to MED-1 Solutions as NSF for any reason by
                                      the bank that I authorize MED-1 Solutions to initiate an additional and separate fee in
                                      accordance with Oregon Revised Statutes §30.701 for the returned check expenses. This
                                      authorization is to remain in full force and effect until MED-1 Solutions has received
                                      written notification from me of its termination in such manner as to afford MED-1
                                      Solutions a reasonable opportunity to act on it (3 business days).
                                    </span>
                                  }
                                />
                              </FormControl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
          </div>
        </div>
      </>
    );
  }
}

export default Echeck;
