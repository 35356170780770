import { paymentCreditCardService } from "../services/paymentCreditCardService";

export const FETCH_CREDIT_CARD_DETAILS_SUCCESS = "FETCH_CREDIT_CARD_DETAILS_SUCCESS";
export const FETCH_CREDIT_CARD_DETAILS_PENDING = "FETCH_CREDIT_CARD_DETAILS_PENDING";

export const FETCH_COUNTRY_DETAILS_SUCCESS = "FETCH_COUNTRY_DETAILS_SUCCESS";

export const SAVE_CREDIT_CARD_PAYMENT = "SAVE_CREDIT_CARD_PAYMENT";

export const GET_COUNTRY = "GET_COUNTRY";

export function fetchCreditCardDataSuccess(cardData) {
  return {
    type: FETCH_CREDIT_CARD_DETAILS_SUCCESS,
    payload: cardData,
  };
}
export function fetchCreditCardDatapending() {
  return {
    type: FETCH_CREDIT_CARD_DETAILS_PENDING,
  };
}

export function fetchCreditCardData(userId, cardInfo) {
  return (dispatch) => {
    dispatch(fetchCreditCardDatapending());
    paymentCreditCardService.getPaymentDetails(userId, cardInfo, (res) => {
      dispatch(fetchCreditCardDataSuccess(res.data?.paymentInfos[0]));
    });
  };
}

export function addCreditCardData(cardInfo, userId, fn) {
  return (dispatch) => {
    dispatch({
      type: SAVE_CREDIT_CARD_PAYMENT,
      payload: cardInfo,
    });
    paymentCreditCardService.saveCreditDetails(cardInfo, userId, (res) => fn(res));
  };
}
