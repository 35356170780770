import React from "react";
import { Redirect, Route, Switch, useHistory, withRouter } from "react-router";
import Billing from "./Billing/index";
import ErrorPage from "../../util/Errorpage";
// import Transactions from "./Transactions";
import PrivateRoute from "../../PrivateRoute";
import PaymentHistory from "./Transactions/PaymentHistory/index";
import Account from "./Account";
import MyProfile from "./MyProfile";
import ChangePassword from "./ChangePassword";
import AddCardContainer from "../../containers/PaymentMethodsContainer/AddCardContainer";
import Echeck from "./ECheck";
import AddCard from "./AddCard";
import PayPlanOptions from "./PayPlanOptions";
import PayNowTransactionSuccess from "../../components/TransactionSuccessPages/PayNowTransactionSuccess";
import PayFutureTransactionSuccess from "../../components/TransactionSuccessPages/PayFutureTransactionSuccess";
import AddECheck from "./AddEcheck";
import EditCard from "./EditCard";
import PaymentMethod from "./PaymentMethod";
import PaymentPlanHospital from "./PayPlanOptions/PaymentPlanHospital";
import Epic from "./Epic";
import Payment from "./Transactions/Payment-Methods";
import EcheckCardContainer from "../../containers/PaymentMethodsContainer/AddEchequeContainer";
import NewCreditCard from "../../components/CreditCard/index";
import NewEcheck from "../../components/eCheck/index";
import PayPlanOptionsSuccess from "./PayPlanOptions/PaymentArrangement/index";
import SettlementPaymentOutstanding from "./Billing/SettlementPaymentOutstanding";
import SettlementPayment from "./Billing/SettlementPayment";

const Routes = ({ match }) => {
  const getRole = sessionStorage.getItem("currentUserRole") === "Customer" ? true : false;
  return (
    <React.Fragment>
      <Switch>
        <Redirect exact from="/home" to="home/billing" />
        <PrivateRoute path={`${match.url}/billing`} component={getRole ? Billing : ErrorPage} />
        <PrivateRoute path={`${match.url}/pay-now-success`} component={PayNowTransactionSuccess} />
        <PrivateRoute path={`${match.url}/pay-future-success`} component={PayFutureTransactionSuccess} />
        <PrivateRoute path={`${match.url}/pay-plan-options`} component={PayPlanOptions} />
        <PrivateRoute path={`${match.url}/payment-methods`} component={Payment} />
        <PrivateRoute path={`${match.url}/payment-history`} component={PaymentHistory} />
        <PrivateRoute path={`${match.url}/account`} component={Account} />
        <PrivateRoute path={`${match.url}/update-profile`} component={MyProfile} />
        <PrivateRoute path={`${match.url}/change-password`} component={ChangePassword} />
        <PrivateRoute path={`${match.url}/credit-Card`} component={AddCardContainer} />
        <PrivateRoute path={`${match.url}/payment-info`} component={PaymentMethod} />
        <PrivateRoute path={`${match.url}/echeck`} component={Echeck} />
        <PrivateRoute path={`${match.url}/add-card`} component={AddCard} />
        <PrivateRoute path={`${match.url}/edit-card`} component={EditCard} />
        <PrivateRoute path={`${match.url}/add-echeck`} component={EcheckCardContainer} />
        <PrivateRoute path={`${match.url}/payment-plan-details`} component={PaymentPlanHospital} />
        <PrivateRoute path={`${match.url}/epic-poc`} component={Epic} />

        <PrivateRoute path={`${match.url}/PayPlanOptionsSuccess`} component={PayPlanOptionsSuccess} />
        <PrivateRoute path={`${match.url}/new-credit-card`} component={NewCreditCard} />
        <PrivateRoute path={`${match.url}/new-echeck`} component={NewEcheck} />
        <PrivateRoute path={`${match.url}/settlementPayment`} component={SettlementPayment}></PrivateRoute>
        <PrivateRoute
          path={`${match.url}/settlementPaymentOutstanding`}
          component={SettlementPaymentOutstanding}></PrivateRoute>

        <Route path="*" component={ErrorPage} />
      </Switch>
    </React.Fragment>
  );
};

export default withRouter(Routes);
