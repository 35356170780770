import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authToken = sessionStorage.getItem("jwt-token");
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /login page
    <Route
      {...rest}
      render={(props) =>
        authToken ? <Component {...props} /> : <Redirect to={{ pathname: "/signin", state: { from: props.location } }} />
      }
    />
  );
};

export default PrivateRoute;
