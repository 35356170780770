import React, { useState, useEffect, createRef, useRef } from "react";
import { requestInfo_PP } from "../../../../../Common/CommonFn";
import { tableIcons } from "../../../../../components/TableIcons";
import MaterialTable from "material-table";
import { handleError } from "../../../../../components/Notifications/Toast";
import PatchedPagination from "../../../../../Common/TablePagination";
import { httpPaginationRequest } from "../../../../../Common/httpPaginationRequest";
import { Global_var } from "../../../../../global/new _admin_globalvar";

const ReconciliationDetailsTable = ({ dollarFormat, mappedOrderNumber, handleClose }) => {
  const tableRef = createRef();
  const [rows, setRows] = useState(5);
  const page = useRef(null);
  const columns = [
    {
      title: "Seed Account Number",
      field: "seedAccountNumber",
      type: "numeric",
    },
    {
      title: "Account Number",
      field: "Account",
      type: "numeric",
    },
    {
      title: "Paid Amount",
      field: "totalBill",
      type: "numeric",
      render: (rowData) => (rowData?.totalBill ? dollarFormat(rowData?.totalBill) : "--"),
    },
  ];

  return (
    <>
      <div className="payplan-table">
        <MaterialTable
          title=""
          localization={{
            pagination: {
              firstAriaLabel: "First Page",
              previousAriaLabel: "Previous Page",
              nextAriaLabel: "Next Page",
              lastAriaLabel: "Last Page",
            },
          }}
          columns={columns}
          tableRef={tableRef}
          icons={tableIcons}
          data={(query) =>
            new Promise((resolve) => {
              if (!mappedOrderNumber) {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0,
                });
              } else {
                page.current = query.page;
                const passValues = {
                  requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token")),
                  reconciliationOrderReportModel: {
                    orderNumber: mappedOrderNumber,
                    pageNo: query?.page,
                    pageSize: query.pageSize,
                  },
                };
                httpPaginationRequest(Global_var.URL_RECONCILIATION_ORDER_REPORT, passValues, (res) => {
                  if (res?.status === 200 && res.data?.responseInfo?.status === "SUCCESSFUL") {
                    resolve({
                      data: res?.data?.reconciliationOrderReportResponse ? res?.data?.reconciliationOrderReportResponse : [],
                      page: page.current,
                      totalCount: Number(res.data.totalCount),
                    });
                  } else {
                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0,
                    });
                    handleError(res?.data?.responseInfo?.responseMessage?.[0]);
                  }
                });
              }
            })
          }
          options={{
            selection: false,
            search: true,
            emptyRowsWhenPaging: false,
            sorting: false,
            draggable: false,
            pageSize: rows,
            pageSizeOptions: [5, 10, 15, 20],
          }}
          onChangeRowsPerPage={(params) => {
            setRows(params);
          }}
          components={{
            Pagination: PatchedPagination,
          }}
        />
      </div>
      <div className="my-2 d-flex justify-content-center">
        <button size="small" className="secondary-button mx-2" onClick={handleClose}>
          Close
        </button>
      </div>
    </>
  );
};

export default ReconciliationDetailsTable;
