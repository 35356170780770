import { store } from "react-notifications-component";

export const successNotification = {
  title: "Success!",
  message: "",
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animated", "flipInY"],
  animationOut: ["animated", "bounceOut"],
  dismiss: {
    duration: 5000,
    onScreen: false,
    showIcon: true,
  },
};

export const warningNotification = {
  title: "Warning!",
  message: "",
  type: "warning",
  insert: "top",
  container: "top-center",
  animationIn: ["animated", "flipInY"],
  animationOut: ["animated", "bounceOut"],
  dismiss: {
    duration: 5000,
    onScreen: false,
    showIcon: true,
  },
};

export const infoNotification = {
  title: "Info!",
  message: "",
  type: "info",
  insert: "top",
  container: "top-center",
  animationIn: ["animated", "flipInY"],
  animationOut: ["animated", "bounceOut"],
  dismiss: {
    duration: 5000,
    onScreen: false,
    showIcon: true,
  },
};

export const errorNotification = {
  title: "Error!",
  message: "",
  type: "danger",
  insert: "top",
  container: "top-center",
  animationIn: ["animated", "flipInY"],
  animationOut: ["animated", "bounceOut"],
  dismiss: {
    duration: 5000,
    onScreen: false,
    showIcon: true,
  },
};

export const sessionNotification = {
  title: "Session Error!",
  message: "",
  type: "danger",
  insert: "top",
  container: "top-center",
  animationIn: ["animated", "flipInY"],
  animationOut: ["animated", "bounceOut"],
  dismiss: {
    duration: 5000,
    onScreen: false,
    showIcon: true,
  },
};

export const success = (message, notification) => {
  store.addNotification({
    ...notification,
    message: message,
  });
};

export const warning = (message, notification) => {
  store.addNotification({
    ...notification,
    message: message,
  });
};

export const info = (message, notification) => {
  store.addNotification({
    ...notification,
    message: message,
  });
};

export const error = (message, notification) => {
  store.addNotification({
    ...notification,
    message: message,
  });
};

export const sessionerror = (message, notification) => {
  store.addNotification({
    ...notification,
    message: message,
  });
};
