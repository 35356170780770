// import { Global_var } from "../global/global_var";
import { Global_var } from "../global/global_var";
import RestDataSource from "./restdatasource";

export const SurveyService = {
  getQuestions,
  postSurvey,
  getSurveyAnswers,
  getSurveyAnswersById,
  getSurveychart,
};

function getQuestions(fn) {
  let url = Global_var.BASEURL + Global_var.URL_GET_SURVEY_QUESTIONS;
  return new RestDataSource(url).GetData((res) => fn(res));
}

function postSurvey(surveyData, fn) {
  var url = Global_var.BASEURL + Global_var.URL_POST_SURVEY_ANSWERS;
  return new RestDataSource(url, null, fn).Store(surveyData, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        localStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
}

function getSurveyAnswers(surveyData, fn) {
  var url = Global_var.BASEURL + Global_var.URL_GET_RP_SURVEY_ANSWERS;
  return new RestDataSource(url, null, fn).Store(surveyData, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        localStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
}

function getSurveyAnswersById(id, fn) {
  var url = Global_var.BASEURL + Global_var.URL_GET_RP_SURVEY_ANSWERS_BY_RP_ID + `/${id}`;
  return new RestDataSource(url).GetData((res) => fn(res));
}

function getSurveychart(fn) {
  var url = Global_var.BASEURL + Global_var.URL_GET_SURVEY_CHART_DATA;
  return new RestDataSource(url).GetData((res) => fn(res));
}
