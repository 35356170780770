import {Global_var} from "../global/global_var"
import RestDataSource from "./restdatasource";

export const loginService = {
  login,
  logout,
  getMacAddress,
  TokenUser,
};

function login(userData, fn) {
// debugger
 loginService.TokenUser((res) => {
   
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
		  var url = Global_var.BASEURL + Global_var.URL_LOGIN;
		  return new RestDataSource(url, null, fn).Store(userData, (res) => fn(res));
	}
	}, console.log("Token Error"));  
}

function getMacAddress(userName, isFirstTimeLogin, fn) {
  var url =
    Global_var.BASEURL +
    Global_var.URL_GET_MACID +
    "?userName=" +
    userName +
    "&isFirstTimeLogin=1";
  return new RestDataSource(url, null, fn).GetData((res) => fn(res));
}

function logout(username,fn) {
  // remove user from local storage to log user out
  //alert("logging out")
  sessionStorage.removeItem("userData");
    var url = Global_var.BASEURL + Global_var.URL_LOGOUT;
	var userData = username
		  return new RestDataSource(url, null, fn).Store(userData, (res) => fn(res));
}

function TokenUser(fn) {
  let url = Global_var.BASEURL + Global_var.URL_JWT_TRUST;
  return new RestDataSource(url).GetData((res) => fn(res.data));
}
