import React from "react";
import Med1Logo from "../../../assets/images/Med1Logo.png";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getIn } from "formik";
import { Link } from "react-router-dom";
import RocketIcon from "../../../assets/images/quick-pay-rocket-icon.svg";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { Grid, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Numberformat } from "../../../Common/NumberFormat";
import { bindActionCreators } from "redux";
import { requestInfo_PP } from "../../../Common/CommonFn";
import { forgotUserData } from "../../../action/forgotPasswordAction";
import SpinnerPage from "../../../Common/PP_loader";
import { handleSuccess, ToastContainer, handleError, handleWarning } from "../../../components/Notifications/Toast";
import { OutstandingPayerService } from "../../../services/payerOutstandingService";
import { ToastMsg } from "../../../locales/en/toastmessages";
import moment from "moment";
import endOfDay from "date-fns/endOfDay";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";
import ClearIcon from "@material-ui/icons/Clear";

const validationSchema = Yup.object().shape({
  accountnumber: Yup.string()
    .matches(/^[0-9]+$/, "Enter digits only")
    .min(5, "Minimum 5 digits required for MED-1 Account Number")
    .max(8, "Enter 7 or 8 digits MED-1 Account Number")
    .required("MED-1 Account Number is required")
    .nullable(),
  socialSecurityNumber: Yup.string().required("Social Security Number is required").nullable(),
  patientDOB: Yup.date()
    .typeError("Please enter a valid date")
    .max(endOfDay(new Date()), "Must be a date in the past")
    .min(moment(new Date(1870, 1, 1)).toDate(), "Date must be greater than 1870")
    .required("Date of Birth is required")
    .nullable(),
});

export class ForgotUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountNumber: null,
      socialSecurityNumber: null,
      DOB: null,
      accountnumbershowPassword: false,
      ssnshowPassword: false,
      showloader: false,
      loading: false,
    };
  }
  _handleAcShowPassword = () => {
    this.setState({
      accountnumbershowPassword: this.state.accountnumbershowPassword ? false : true,
    });
  };
  _handleSsnShowPassword = () => {
    this.setState({
      ssnshowPassword: this.state.ssnshowPassword ? false : true,
    });
  };
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        accountNumber: null,
        socialSecurityNumber: null,
        DOB: null,
      });
    }
  }
  _handleSubmit = (values, { resetForm }) => {
    this.setState({
      loading: true,
    });
    var myDate = values.patientDOB;
    var myDate1 = "";
    if (myDate) {
      var mm = myDate.getMonth() + 1;
      var dd = myDate.getDate();
      var yy = myDate.getFullYear();
      myDate1 = (dd > 9 ? dd : "0" + dd) + "/" + (mm > 9 ? mm : "0" + mm) + "/" + yy;
    }
    const passValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      dob: myDate1,
      uniqueIdentifierId: values.socialSecurityNumber,
      accountReferenceNumber: values.accountnumber,
    };
    this.props.forgotUserData(passValues, (res) => {
      this.setState({
        loading: true,
      });
      if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
        handleSuccess("Username has been sent to your mail Id");
        this.setState({
          loading: false,
        });
        setTimeout(() => {
          this.props.history.push("/");
        }, 2500);
      } else if (res?.data?.responseInfo?.status === "FAILED") {
        this.setState({
          loading: false,
        });
        handleError("Please provide valid credentials");
      }
    });
  };

  render() {
    const initialValues = {
      accountnumber: this.state.accountNumber,
      socialSecurityNumber: this.state.socialSecurityNumber,
      patientDOB: this.state.DOB,
    };
    return (
      <>
        {this.state.loading && <SpinnerPage />}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this._handleSubmit}
          render={({ errors, touched, handleSubmit, handleChange, isValid, values, handleBlur, setFieldValue }) => (
            <React.Fragment>
              <div className="page-wrapper vertical-height" role="main">
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="card-sm">
                      <div className="header" role="heading" aria-level="1">
                        <div className="rocket-icon">
                          <img src={RocketIcon} alt="" />
                        </div>
                        <div className="logo">
                          <img src={Med1Logo} className="img-fluid" alt="Med-1 Solutions Logo" />
                        </div>
                      </div>
                      <div className="main-heading">
                        <h6 aria-level="2">Forgot Username? </h6>
                      </div>

                      <div className="forgot-form-content">
                        <Form onSubmit={handleSubmit} id="login-form" className="input-field" noValidate="noValidate">
                          <div className="col-12 form-group">
                            <div className="label-spacing">
                              <label htmlFor="accountnumber" className="required">
                                MED-1 Account Number
                              </label>
                            </div>
                            <TextField
                              hiddenLabel
                              variant="outlined"
                              className="signup-fields"
                              placeholder="MED-1 Account Number"
                              name="accountnumber"
                              id="accountnumber"
                              autocomplete="off"
                              maxLength="8"
                              value={values.accountnumber}
                              onChange={(event) => {
                                setFieldValue((values.accountnumber = event.target.value));
                                if (values.accountnumber?.length > 7) {
                                  var PassValues1 = {
                                    requestInfo: requestInfo_PP(
                                      sessionStorage.getItem("jwt-token"),
                                      sessionStorage.getItem("jwt-token"),
                                    ),
                                    payerBillSearch: {
                                      accountReferenceNumber: values.accountNumber,
                                    },
                                  };
                                  const UserId = "1212";
                                  OutstandingPayerService.PP_Get_Patient_Details(
                                    PassValues1,
                                    UserId,
                                    (res) => {
                                      if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
                                        setFieldValue((values.showloader = false));
                                      } else if (res["data"]?.responseInfo?.status === "FAILED") {
                                        setFieldValue((values.showloader = false));
                                        handleWarning(
                                          <>
                                            {ToastMsg.getDetail_warningMsgWrong}
                                            <br />
                                            {ToastMsg.getDetail_warningMsgWrongsecond}
                                            <br />
                                            {ToastMsg.getDetail_warningMsgWrongthird}
                                          </>,
                                        );
                                        setTimeout(function () {
                                          // window.location.reload();
                                        }, 2000);
                                      } else {
                                        handleWarning(<>{ToastMsg.getDetail_warningMsgWrong}</>);
                                        setTimeout(function () {
                                          // window.location.reload();
                                        }, 2000);
                                      }
                                    },
                                    (error) => {
                                      console.log(error);
                                    },
                                  );
                                }
                              }}
                              onBlur={(event) => {
                                handleBlur(event);

                                if (values.accountNumber?.length >= 7 || values.accountNumber?.length >= 5) {
                                  setFieldValue((values.showloader = true));
                                  var PassValues1 = {
                                    requestInfo: requestInfo_PP(
                                      sessionStorage.getItem("jwt-token"),
                                      sessionStorage.getItem("jwt-token"),
                                    ),
                                    payerBillSearch: {
                                      accountReferenceNumber: values.accountNumber,
                                    },
                                  };

                                  const UserId = "1212";
                                  OutstandingPayerService.PP_Get_Patient_Details(
                                    PassValues1,
                                    UserId,
                                    (res) => {
                                      if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
                                        setFieldValue((values.showloader = false));

                                        
                                      } else if (res["data"]?.responseInfo?.status === "FAILED") {
                                        setFieldValue((values.showloader = false));

                                        handleWarning(
                                          <>
                                            {ToastMsg.getDetail_warningMsgWrong}
                                            <br />
                                            {ToastMsg.getDetail_warningMsgWrongsecond}
                                            <br />
                                            {ToastMsg.getDetail_warningMsgWrongthird}
                                          </>,
                                        );
                                        setTimeout(function () {
                                          // window.location.reload();
                                        }, 2000);
                                      } else {
                                        setFieldValue((values.showloader = false));
                                        handleWarning(<>{ToastMsg.getDetail_warningMsgWrong}</>);

                                        setTimeout(function () {
                                          // window.location.reload();
                                        }, 2000);
                                      }
                                    },
                                    (error) => {
                                      setFieldValue((values.showloader = false));
                                      console.log(error);
                                    },
                                  );
                                }
                              }}
                              type={this.state.accountnumbershowPassword ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => this._handleAcShowPassword()}>
                                      {this.state.accountnumbershowPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              aria-label="accountnumber"
                              fullWidth
                              error={Boolean(getIn(touched, "accountnumber") && getIn(errors, "accountnumber"))}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required="true"
                            />
                            <div className="error-text">
                              {getIn(touched, "accountnumber") &&
                                getIn(errors, "accountnumber") &&
                                getIn(errors, "accountnumber")}
                            </div>
                          </div>

                          <div className="col-12 form-group">
                            <div className="label-spacing">
                              <label htmlFor="socialSecurityNumber" className="required">
                                Social Security Number
                              </label>
                            </div>
                            <TextField
                              hiddenLabel
                              variant="outlined"
                              className="signup-fields"
                              placeholder="Enter last 4 digits of SSN"
                              name="socialSecurityNumber"
                              id="socialSecurityNumber"
                              autocomplete="off"
                              value={values.socialSecurityNumber}
                              maxLength="4"
                              type={this.state.ssnshowPassword ? "text" : "password"}
                              onChange={(event) => {
                                setFieldValue((values.socialSecurityNumber = event.target.value));
                                this.setState({ socialSecurityNumber: event.target.value });
                              }}
                              inputProps={{ maxLength: 4 }}
                              InputProps={{
                                inputComponent: Numberformat,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => this._handleSsnShowPassword()}>
                                      {this.state.ssnshowPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              aria-label="username"
                              fullWidth
                              error={Boolean(
                                getIn(touched, "socialSecurityNumber") && getIn(errors, "socialSecurityNumber"),
                              )}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required="true"
                            />
                            <div className="error-text">
                              {getIn(touched, "socialSecurityNumber") &&
                                getIn(errors, "socialSecurityNumber") &&
                                getIn(errors, "socialSecurityNumber")}
                            </div>
                          </div>

                          <div className="col-12 form-group">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Grid container className="date-picker">
                                <label htmlFor="patientDOB" className="required">
                                  Date of Birth
                                </label>
                                <DatePicker
                                  disableFuture
                                  name="patientDOB"
                                  autoOk
                                  value={values.patientDOB}
                                  error={Boolean(getIn(touched, "patientDOB") && getIn(errors, "patientDOB"))}
                                  required="true"
                                  onChange={(val) => setFieldValue("patientDOB", val)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      helperText={null}
                                      margin="dense"
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "MM/DD/YYYY",
                                      }}
                                    />
                                  )}
                                  InputProps={{
                                    startAdornment: (
                                      <IconButton
                                        position="end"
                                        onClick={() => setFieldValue("patientDOB", null)}
                                        style={{ order: 1, padding: 0 }}>
                                        <ClearIcon fontSize="small" />
                                      </IconButton>
                                    ),
                                  }}
                                  InputAdornmentProps={{
                                    position: "end",
                                    style: { order: 2 },
                                  }}
                                />
                              </Grid>
                            </LocalizationProvider>
                            <div className="error-text">
                              {getIn(touched, "patientDOB") && getIn(errors, "patientDOB") && getIn(errors, "patientDOB")}
                            </div>
                          </div>

                          <div>
                            <button
                              type="submit"
                              className="primary-button btn-block mb-3 mt-5"
                              aria-label="continue to SignIn">
                              Submit
                            </button>
                          </div>
                        </Form>
                      </div>
                      <div className="text-center">
                        <div className="more-info">
                          <p>
                            <Link to="/signin" aria-label="forgot-account">
                              Back To Login
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
            </React.Fragment>
          )}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      forgotUserData: forgotUserData,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ForgotUser);
