import React from "react";
import { Formik } from "formik";
import { Translation } from "react-i18next";
import { withTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import { I18nextProvider } from "react-i18next";
import { TxnDoneButton } from "../../../../assets/MaterialControl";
import { GoogleAnalystics } from "../../../../Common/GoogleAnalystics";
import moment from "moment";
import UpdateTrackingPage from "../../../../containers/UpdateTrackingPage";

class PP_Payment_Receipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: true,
    };
  }

  componentDidMount() {
    document.title = "MED-1 Quick Pay - View Receipt";
    GoogleAnalystics.GoogleAnalysticsLog("pageview");
    window.scrollTo(0, 0);

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }

  gotofaqs = () => {
    this.props.history.push("/Transaction-Successful");
  };
  _handleSubmit = (values, { actions }) => {};
  formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  render() {
    const data = JSON.parse(sessionStorage.getItem("recieptData"));
    if (data) {
      var TxnId = data.paymentGatewayTxnRefNo;
      var Date = data.paymentTxnDatetime;
      var Email = data.emailId;
      var PhoneNumber = data.phoneNumber;
      var PaymentMethod = data.paymentType;
      var DebitFrom = data.debitFrom.replace(/\d(?=\d{4})/g, "*");
      var PaidTo = data.paidTo;
      var PaidAmount = data.paidAmount;
      var AuthNumber = data.authorizationCode;
    }
    return (
      <I18nextProvider i18n={i18n}>
        <React.Fragment>
          <div className="p-4 d-flex flex-column justify-content-center align-items-center" style={{ height: "100vh" }}>
            <div
              className="shadow d-flex flex-column justify-content-center align-items-center"
              style={{ width: "48vw", height: "76vh", borderRadius: "10px" }}>
              <div
                style={{ height: "10vh", backgroundColor: "#e9f7f4", width: "100%", fontSize: "28px", fontWeight: "bold" }}
                className="d-flex flex-column justify-content-center align-items-center">
                <Translation>{(t, { i18n }) => <label>{t("viewreceipt_label")}</label>}</Translation>
              </div>
              <div className="receipt-box mt-4">
                <div className="row mb">
                  <div className="col-md-6 col-sm-12 d-flex flex-start mb-3">
                    <div className="heading12">
                      {" "}
                      <Translation>{(t, { i18n }) => <label>{t("viewreceipt_confirmationNum")}</label>}</Translation>{" "}
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12 font-weight-bold">
                    <div className="heading13">{AuthNumber}</div>
                  </div>
                </div>

                <div className="row mb">
                  <div className="col-md-6 col-sm-12 mb-3">
                    <div className="heading12">
                      {" "}
                      <Translation>{(t, { i18n }) => <label>{t("viewreceipt_AuthorizationNum")}</label>}</Translation>{" "}
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12  font-weight-bold">
                    <div className="heading13">{TxnId}</div>
                  </div>
                </div>

                <div className="row mb d-flex flex-row justify-content-space-between">
                  <div className="col-md-6 col-sm-12 mb-3">
                    <div className="heading12">
                      <Translation>{(t, { i18n }) => <label>{t("viewreceipt_transDate")}</label>}</Translation>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12  font-weight-bold">
                    <div className="heading13">{moment(Date).format("MM/DD/YYYY")}</div>
                  </div>
                </div>

                <div className="row mb d-flex flex-row justify-content-space-between">
                  <div className="col-md-6 col-sm-12 mb-3">
                    <div className="heading12">
                      <Translation>{(t, { i18n }) => <label>{t("viewreceipt_emailId")}</label>}</Translation>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12  font-weight-bold">
                    <div className="heading13 wraptext">{Email}</div>
                  </div>
                </div>

                <div className="row mb">
                  <div className="col-md-6 col-sm-12 mb-3 ">
                    <div className="heading12 ">
                      <Translation>{(t, { i18n }) => <label>{t("viewreceipt_phoneNum")}</label>}</Translation>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12  font-weight-bold">
                    <div className="heading13"> {this.formatPhoneNumber(PhoneNumber)}</div>
                  </div>
                </div>

                <div className="row mb">
                  <div className="col-md-6 col-sm-12 mb-3">
                    <div className="heading12">
                      <Translation>{(t, { i18n }) => <label>{t("viewreceipt_paymentType")}</label>}</Translation>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12  font-weight-bold">
                    <div className="heading13">{PaymentMethod}</div>
                  </div>
                </div>

                <div className="row mb">
                  <div className="col-md-6 col-sm-12 mb-3">
                    <div className="heading12">
                      <Translation>{(t, { i18n }) => <label>{t("viewreceipt_fromAccount")}</label>}</Translation>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12  font-weight-bold">
                    <div className="heading13">{DebitFrom}</div>
                  </div>
                </div>

                <div className="row mb">
                  <div className="col-md-6 col-sm-12 mb-4">
                    <div className="heading12">
                      <Translation>{(t, { i18n }) => <label>{t("viewreceipt_paidTo")}</label>}</Translation>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12  font-weight-bold">
                    <div className="heading13">{PaidTo}</div>
                  </div>
                </div>

                <div className="row mb" style={{ width: "40vw" }}>
                  <div className="col-md-6 col-sm-12 mb-4">
                    <div className="heading12 d-flex flex-column justify-content-center align-items-left">
                      <Translation>
                        {(t, { i18n }) => <label className="receipt-label">{t("viewreceipt_paymentAmt")}</label>}
                      </Translation>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12  font-weight-bold">
                    {/* <div className="heading14">$ {Number(PaidAmount).toLocaleString(undefined, {minimumFractionDigits: 2})}</div> */}
                    <div className="heading14">$ {PaidAmount}</div>
                  </div>
                </div>

                <div className=" mb-4 button-box mt-4 align d-flex flex-column justify-content-center align-items-center">
                  <Translation>
                    {(t, { i18n }) => (
                      <TxnDoneButton
                        href="javascript:void(0)"
                        onClick={() => {
                          this.props.history.push("./thank-you");
                        }}
                        type="button"
                        className="button-border">
                        {t("viewreceipt_doneButton")}
                      </TxnDoneButton>
                    )}
                  </Translation>
                </div>
              </div>
            </div>
          </div>
          {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
        </React.Fragment>
      </I18nextProvider>
    );
  }
}

export default PP_Payment_Receipt;
