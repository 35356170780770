import RestDataSource from "./restdatasource";
import { loginService } from "./loginService";
import { Global_var } from "../global/global_var";
import {
  error,
  success,
  warning,
  warningNotification,
  errorNotification,
  successNotification,
} from "../components/Admin_Notification/Admin_Notification";

export const partyGetService = {
  partyGetList,
  publicUrlList,
  UpdatePatientData,
  CreateUser,
  VerifyUserEmail,
  VerifyPatientEmail,
  ValidateOtp,
  GenerateOtp,
};

function partyGetList(userData, fn) {
  var url = Global_var.BASEURL + Global_var.URL_PARTYGET;
  return new RestDataSource(url, 0, fn).Store(userData, (res) => {
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          // sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-02") {
          //   sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-10") {
          //   sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else {
          warning("Session expired! Please refresh the page", warningNotification);
        }
      } else {
        //   if (res.headers["token"] != null) {
        //     sessionStorage.setItem("jwt-tokencore", res.headers.token);
        //   }

        fn(res.data);
      }
    }
  });
  //   }
  // });
}
function publicUrlList(userData, fn) {
  var url = Global_var.BASEURL + Global_var.URL_GET_WHITE_LABEL_DATA;
  var url = Global_var.BASEURL + Global_var.URL_PUBLIC_URL_GET;
  return new RestDataSource(url, 0, fn).Store(userData, (res) => {
    if (res != null) {
      if (res["data"].status === "fail") {
        if (res["data"].reasonText === "Invalid Token") {
          //   sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-02") {
          // sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else if (res["data"].reasonCode === "SM-10") {
          // sessionStorage.clear();
          warning("Token Expired, Login/Verify again", warningNotification);
          window.location.replace("/");
        } else {
          warning("Session expired! Please refresh the page", warningNotification);
        }
      } else {
        // if (res.headers["token"] != null) {
        //   sessionStorage.setItem("jwt-tokencore", res.headers.token);
        // }

        fn(res.data);
      }
    }
  });
  //   }
  // });
}
//  create Patient User
function CreateUser(userData, fn) {
  var url = Global_var.BASEURL + Global_var.URL_CREATE_USER;
  return new RestDataSource(url, 1, "CORE", fn).Store(userData, (res) => {
    if (res !== null) {
      fn(res);
    } else {
      fn(res.data);
    }
  });
  //   }
  // });
}

// Verify email ID
//  create Patient User
function VerifyUserEmail(userData, fn) {
  var url = Global_var.BASEURL + Global_var.URL_VERIFY_EMAIL;
  return new RestDataSource(url, 1, "CORE", fn).Store(userData, (res) => {
    if (res !== null) {
      fn(res);
    } else {
      fn(res.data);
    }
  });
  //   }
  // });
}

// Update Patient Data on register screen  URL_UPDATE_PATIENT
function UpdatePatientData(userData, fn) {
  var url = Global_var.BASEURL + Global_var.URL_UPDATE_PATIENT;
  return new RestDataSource(url, 1, "CORE", fn).Store(userData, (res) => {
    if (res !== null) {
      fn(res);
    } else {
      fn(res.data);
    }
  });
  //   }
  // });
}

// Verify email link  URL_VERIFY_EMAIL_LINK  VerifyPatientEmail ValidateOtp GenerateOtp
function VerifyPatientEmail(userData, fn) {
  var url = Global_var.BASEURL + Global_var.URL_VERIFY_EMAIL_LINK;
  return new RestDataSource(url, 1, "CORE", fn).Store(userData, (res) => {
    if (res !== null) {
      fn(res);
    } else {
      fn(res.data);
    }
  });
  //   }
  // });
}

// Generate OTP  URL_GENERATE_SMS_OTP
function GenerateOtp(userData, fn) {
  var url = Global_var.BASEURL + Global_var.URL_GENERATE_SMS_OTP;
  return new RestDataSource(url, 1, "CORE", fn).Store(userData, (res) => {
    if (res !== null) {
      fn(res);
    } else {
      fn(res.data);
    }
  });
  //   }
  // });
}

// validate mobile OTP  URL_VERIFY_MOBILE
function ValidateOtp(userData, fn) {
  var url = Global_var.BASEURL + Global_var.URL_VERIFY_MOBILE;
  return new RestDataSource(url, 1, "CORE", fn).Store(userData, (res) => {
    if (res !== null) {
      fn(res);
    } else {
      fn(res.data);
    }
  });
  //   }
  // });
}
