import React from "react";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import { tableIcons } from "../../../components/TableIcons";
import PATransactionSearchForm from "./PATransactionSearchForm";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

const PATransaction = () => {
  const columns = [
    {
      title: "Transaction ID",
      field: "transactionId",
    },
    {
      title: "Transaction Date",
      field: "TransactionDate",
      type: "dateTime",
      align: "center",
    },
    {
      title: "Authorization Code",
      field: "authCode",
    },
    {
      title: "MED-1 Account Number",
      field: "seedAccountNumber",
      type: "numeric",
      minWidth: 160,
      width: 160,
    },
    {
      title: "Account #",
      field: "account",
      type: "numeric",
      minWidth: 130,
      width: 130,
    },
    {
      title: "Client Name",
      field: "clientName",
      minWidth: 220,
      width: 220,
    },
    {
      title: "Payer Name",
      field: "payerName",
      minWidth: 90,
      width: 90,
    },
    {
      title: "Payment Method",
      field: "paymentMethod",
      minWidth: 90,
      width: 90,
    },
    {
      title: "Transaction Amount",
      field: "transactionAmount",
      minWidth: 90,
      width: 90,
      type: "numeric",
    },
    {
      title: "Bill Wise Paid Amount",
      field: "billwisePaidAmount",
      minWidth: 150,
      width: 150,
      type: "numeric",
    },
    {
      title: "Bill Amount",
      field: "billAmount",
      minWidth: 90,
      width: 90,
      type: "numeric",
    },
    {
      title: "Current Balance Amount",
      field: "currentBalanceAmount",
      minWidth: 170,
      width: 170,
      type: "numeric",
    },
    {
      title: "Paid By",
      field: "paidBy",
      minWidth: 90,
      width: 90,
    },
    {
      title: "PA Name",
      field: "paName",
      minWidth: 80,
      width: 80,
    },
    {
      title: "Payment Status",
      field: "paymentStatus",
      minWidth: 90,
      width: 90,
      headerStyle: { textAlign: "center" },
      cellStyle: { textAlign: "left" },
      render: (rowData) => {
        return (
          <>
            <span className={`${rowData.paymentStatus === "Success" ? "payment-success" : "payment-failed"} payment-amount`}>
              {rowData.paymentStatus}
            </span>
          </>
        );
      },
    },
    {
      title: "Failure Reason",
      field: "failureReason",
      minWidth: 90,
      width: 90,
    },
    {
      title: "Settled Date",
      field: "settledDate",
      minWidth: 90,
      width: 90,
      headerStyle: { textAlign: "center" },
      cellStyle: { textAlign: "center" },
    },
  ];

  const data = [
    {
      transactionId: "f2545754bfa3",
      TransactionDate: "02/02/2021 11:20:17",
      authCode: "4CS297",
      seedAccountNumber: "28170753",
      account: "28170753",
      clientName: "Deaconess Health System, Inc",
      payerName: "Deina R",
      paymentMethod: "Credit Card",
      transactionAmount: "$ 4.00",
      billwisePaidAmount: "$ 4.00",
      billAmount: "$ 4.00",
      currentBalanceAmount: "$ 0.00",
      paidBy: "PA",
      paName: "Jennifer",
      paymentStatus: "Failed",
      settledDate: "02/02/2021",
      failureReason: "Account Not Approved",
    },
    {
      transactionId: "f2545754bd2g",
      TransactionDate: "02/02/2021 10:30:27",
      authCode: "4CS284",
      seedAccountNumber: "67814045",
      account: "67814045",
      clientName: "Deaconess Health System, Inc",
      payerName: "Reid Jr.",
      paymentMethod: "eCheck",
      transactionAmount: "$ 50.00",
      billwisePaidAmount: "$ 50.00",
      billAmount: "$ 84.00",
      currentBalanceAmount: "$ 71.00",
      paidBy: "SELF",
      paName: "",
      paymentStatus: "Success",
      settledDate: "02/02/2021",
      failureReason: "",
    },
    {
      transactionId: "f2545754b65d",
      TransactionDate: "02/02/2021 9:50:10",
      authCode: "4CF456",
      seedAccountNumber: "67814045",
      account: "67814045",
      clientName: "Community Health Network",
      payerName: "Reid Jr.",
      paymentMethod: "Credit Card",
      transactionAmount: "$ 50.00",
      billwisePaidAmount: "$ 25.00",
      billAmount: "$ 50.00",
      currentBalanceAmount: "$ 25.00",
      paidBy: "SELF",
      paName: "",
      paymentStatus: "Success",
      settledDate: "02/02/2021",
      failureReason: "",
    },
    {
      transactionId: "f2545754b6g4",
      TransactionDate: "02/02/2021 9:50:07",
      authCode: "4CZ297",
      seedAccountNumber: "67814045",
      account: "67814045",
      clientName: "Community Health Network",
      payerName: "Reid Jr.",
      paymentMethod: "Credit Card",
      transactionAmount: "$ 50.00",
      billwisePaidAmount: "$ 13.00",
      billAmount: "$ 84.00",
      currentBalanceAmount: "$ 71.00",
      paidBy: "SELF",
      paName: "",
      paymentStatus: "Success",
      settledDate: "02/02/2021",
      failureReason: "",
    },
    {
      transactionId: "f2545754bl1w",
      TransactionDate: "02/02/2021 08:40:47",
      authCode: "4CF697",
      seedAccountNumber: "67814045",
      account: "67814045",
      clientName: "Baptist Health Floyd",
      payerName: "Reid Jr.",
      paymentMethod: "Credit Card",
      transactionAmount: "$ 50.00",
      billwisePaidAmount: "$ 12.00",
      billAmount: "$ 84.00",
      currentBalanceAmount: "$ 72.00",
      paidBy: "SELF",
      paName: "",
      paymentStatus: "Success",
      settledDate: "02/02/2021",
      failureReason: "",
    },
    {
      transactionId: "f2545754cfr",
      TransactionDate: "02/02/2021 07:56:47",
      authCode: "4CZ497",
      seedAccountNumber: "67814045",
      account: "67814045",
      clientName: "Baptist Health Floyd",
      payerName: "Reid Jr.",
      paymentMethod: "Credit Card",
      transactionAmount: "$ 10.00",
      billwisePaidAmount: "$ 10.00",
      billAmount: "$ 10.00",
      currentBalanceAmount: "$ 0.00",
      paidBy: "SELF",
      paName: "",
      paymentStatus: "Success",
      settledDate: "02/02/2021",
      failureReason: "",
    },
    {
      transactionId: "f2545754b54f",
      TransactionDate: "02/02/2021 06:47:07",
      authCode: "4CA5297",
      seedAccountNumber: "67814045",
      account: "67814045",
      clientName: "Baptist Health Floyd",
      payerName: "Reid Jr.",
      paymentMethod: "Credit Card",
      transactionAmount: "$ 4.00",
      billwisePaidAmount: "$ 4.00",
      billAmount: "$ 4.00",
      currentBalanceAmount: "$ 0.00",
      paidBy: "SELF",
      paName: "",
      paymentStatus: "Success",
      settledDate: "02/02/2021",
      failureReason: "",
    },
  ];

  return (
    <div className="transaction-card patient-details" role="main">
      <div className="main-container-card">
        <div role="heading" aria-level="1" className="heading-style mb-3">
          <ManageSearchIcon className="mr-2" />
          <h2 className="heading-style">Reports: Transaction Search</h2>
        </div>

        <div>
          <PATransactionSearchForm />
        </div>
      </div>
    </div>
  );
};

export default PATransaction;
