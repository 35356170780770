/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, createRef, useEffect, useRef } from "react";
import moment from "moment";
import { formatAmount } from "../../../../util/formatAmount";
import { tableIcons } from "../../../../components/TableIcons";
import MaterialTable from "material-table";
import { handleError, handleWarning } from "../../../../components/Notifications/Toast";
import { httpPaginationRequest } from "../../../../Common/httpPaginationRequest";
import { Global_var } from "../../../../global/global_var";
import PatchedPagination from "../../../../Common/TablePagination";
import { Grid } from "@material-ui/core";

const ViewedButNotPaidReportTable = ({ searchData, isSubmit }) => {
  const tableRef = createRef();
  const [rows, setRows] = useState(5);
  const page = useRef(null);

  const tableColumns = [
    {
      field: "campaignName",
      title: "Campaign",
    },
    {
      field: "campaignDate",
      title: "Campaign Date",
    },
    {
      field: "clientName",
      title: "Client Name",
    },
    {
      field: "seedAccountNumber",
      title: "Seed Account Number",
    },
    {
      field: "outstandingBalance",
      title: "Outstanding Amount",
      type: "numeric",
      render: (rowData) => {
        const value = rowData["outstandingBalance"];
        return <div className="purl-table-columns">{value || value === 0 ? formatAmount(value) : "-"}</div>;
      },
    },
    {
      field: "recentAccessedDate",
      title: "Recent Accessed Date",
    },
    {
      field: "recentAccessedPage",
      title: "Recent Accessed Page",
      render: (rowData) => {
        const value = rowData["recentAccessedPage"];
        return <a href="#">{value}</a>;
      },
    },
  ];
  const isInitialRender = useRef(false);
  useEffect(() => {
    isInitialRender.current = true;
  }, []);
  useEffect(() => {
    if (tableRef?.current && isSubmit && isInitialRender.current === false) {
      if (searchData && Object.keys(searchData).length > 0) {
        tableRef.current.onQueryChange();
      }
    }
    if (isSubmit && isInitialRender.current) {
      isInitialRender.current = false;
    }
  }, [isSubmit, searchData]);

  return (
    <Grid>
      <div className="payplan-table">
        <MaterialTable
          title=""
          localization={{
            pagination: {
              firstAriaLabel: "First Page",
              previousAriaLabel: "Previous Page",
              nextAriaLabel: "Next Page",
              lastAriaLabel: "Last Page",
            },
          }}
          tableRef={tableRef}
          columns={tableColumns}
          //data={tableData ? tableData : []}
          data={(query) =>
            new Promise((resolve) => {
              if (!searchData) {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0,
                });
              } else {
                page.current = query.page;
                const payload = {
                  campaignName: searchData.campaign,

                  startDate: searchData.startDate ? moment(searchData.startDate).format("YYYY-MM-DD") : "",

                  endDate: searchData.endDate ? moment(searchData.endDate).format("YYYY-MM-DD") : "",

                  pageSize: query.pageSize ? query.pageSize : null,

                  pageNo: query.page,
                };
                httpPaginationRequest(Global_var.URL_VIEWED_BUT_NOT_PAID, payload, (res) => {
                  if (res?.status === 200 && res.data.campaignList) {
                    const data = res.data;

                    if (data.campaignList.length === 0) {
                      resolve({
                        data: [],
                        page: res.data.pageIndex,
                        totalCount: Number(res.data.totalCount),
                      });
                      handleWarning("No records found");
                    } else {
                      resolve({
                        data: res.data.campaignList.length > 0 ? res.data.campaignList : [],
                        page: res.data.pageIndex,
                        totalCount: Number(res.data.totalCount),
                      });
                      //setIsData(true);
                    }
                  } else {
                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0,
                    });
                    handleError(res?.data?.responseInfo?.responseMessage[0]);
                  }
                });
              }
            })
          }
          icons={tableIcons}
          options={{
            selection: false,
            search: false,
            emptyRowsWhenPaging: false,
            sorting: false,
            pageSize: rows,
            pageSizeOptions: [5, 10, 20],
          }}
          onChangeRowsPerPage={(params) => {
            setRows(params);
          }}
          components={{
            Pagination: PatchedPagination,
          }}
        />
      </div>
    </Grid>
  );
};

export default ViewedButNotPaidReportTable;
