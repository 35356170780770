import React, { useState, useEffect, createRef, useRef } from "react";
import moment from "moment";
import { requestInfo_PP } from "../../../../../Common/CommonFn";
import { tableIcons } from "../../../../../components/TableIcons";
import MaterialTable from "material-table";
import { handleError } from "../../../../../components/Notifications/Toast";
import PatchedPagination from "../../../../../Common/TablePagination";
import { capitalize } from "../../../../../util/Captilize";
import { httpPaginationRequest } from "../../../../../Common/httpPaginationRequest";
import { Global_var } from "../../../../../global/new _admin_globalvar";
import ConfirmationDialog from "../../../../../components/ConfirmationDialog/index";
import ReconciliationDetailsPopup from "./ReconciliationDetailsPopup";

const PaymentReconcilationTable = ({
  setAccountingReportData,
  dollarFormat,
  getValue,
  isSubmit,
  searchData,
  setIsSubmit,
}) => {
  const tableRef = createRef();
  const page = useRef(null);
  const [rows, setRows] = useState(5);
  const [mappedOrderNumber, setMappedOrderNumber] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const isInitialRender = useRef(false);

  useEffect(() => {
    isInitialRender.current = true;
  }, []);

  useEffect(() => {
    if (tableRef?.current && isSubmit && isInitialRender.current === false) {
      if (searchData && Object.keys(searchData).length > 0) {
        tableRef.current.onQueryChange();
      }
    }
    if (isSubmit && isInitialRender.current) {
      isInitialRender.current = false;
    }
  }, [searchData, isSubmit]);

  useEffect(() => {
    mappedOrderNumber && setDialogOpen(true);
  }, [mappedOrderNumber]);

  const handleClose = () => {
    setDialogOpen(false);
    setMappedOrderNumber(null);
  };

  const columns = [
    {
      title: "Gateway Transaction Id",
      field: "paymentGatewayTxnRefNo",
    },
    {
      title: "Account Number",
      field: "accountReferenceNumber",
    },
    {
      title: "Transaction Date",
      field: "paymentTxnDatetime",
    },
    {
      title: "Payee Name",
      field: "firstName",
      render: (rowData) => (rowData?.firstName ? capitalize(rowData?.firstName) : "--"),
    },
    {
      title: "Paid bill Amount",
      field: "billWisePaidAmount",
      type: "numeric",
      render: (rowData) =>
        rowData.billWisePaidAmount || rowData.billWisePaidAmount === 0 ? dollarFormat(rowData.billWisePaidAmount) : "-",
    },
    {
      title: "Total paid amount",
      field: "paidAmount",
      type: "numeric",
      render: (rowData) => (rowData.paidAmount || rowData.paidAmount === 0 ? dollarFormat(rowData.paidAmount) : "-"),
    },
    {
      title: "Auth Status",
      field: "paymentGatewayStatus",
      render: (rowData) => (rowData?.paymentGatewayStatus ? getValue(rowData?.paymentGatewayStatus) : "--"),
    },
    {
      title: "Auth Code",
      field: "paymentGatewayAuthorizationNumber",
    },
    {
      title: "Settlement Date",
      field: "settlementDatetime",
      render: (rowData) => rowData?.settlementDatetime || "--",
    },
    {
      title: "Settlement Status",
      field: "settlementStatus",
      render: (rowData) => rowData?.settlementStatus || "--",
    },
    {
      title: "Settlement Ref Number",
      field: "settlementRefNo",
      render: (rowData) => rowData?.settlementRefNo || "--",
    },
  ];

  return (
    <>
      <ConfirmationDialog
        open={dialogOpen}
        handleClose={handleClose}
        title={`Reconciliation Details`}
        noActionButtons={true}
        maxWidth="sm">
        <ReconciliationDetailsPopup
          handleClose={handleClose}
          tableRef={tableRef}
          mappedOrderNumber={mappedOrderNumber}
          dollarFormat={dollarFormat}
        />
      </ConfirmationDialog>
      <MaterialTable
        title=""
        localization={{
          pagination: {
            firstAriaLabel: "First Page",
            previousAriaLabel: "Previous Page",
            nextAriaLabel: "Next Page",
            lastAriaLabel: "Last Page",
          },
        }}
        columns={columns}
        tableRef={tableRef}
        icons={tableIcons}
        data={(query) =>
          new Promise((resolve) => {
            if (!searchData) {
              resolve({
                data: [],
                page: 0,
                totalCount: 0,
              });
            } else {
              page.current = isSubmit ? 0 : query.page;
              const passValues = {
                requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token")),
                ppAccountingReportModel: {
                  startDate: moment(searchData?.startDate).format("YYYY-MM-DD"),
                  endDate: moment(searchData?.endDate).format("YYYY-MM-DD"),
                  accountReferenceNumber: searchData?.accountReferenceNumber,
                  pageNo: isSubmit ? 0 : query.page,
                  pageSize: query.pageSize,
                },
              };

              httpPaginationRequest(Global_var.URL_PP_ACCOUNTING_REPORT, passValues, (res) => {
                if (res?.status === 200 && res?.data?.PPAccountingReportResponse) {
                  setIsSubmit(false);
                  setAccountingReportData(
                    res?.data?.PPAccountingReportResponse.length > 0 ? res?.data?.PPAccountingReportResponse : [],
                  );
                  resolve({
                    data: res?.data?.PPAccountingReportResponse.length > 0 ? res?.data?.PPAccountingReportResponse : [],
                    page: page.current,
                    totalCount: Number(res.data.totalCount),
                  });
                } else {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0,
                  });
                  handleError(res?.data?.responseInfo?.responseMessage[0]);
                }
              });
            }
          })
        }
        // data={accountingReportData.length > 0 ? accountingReportData : []}
        options={{
          selection: false,
          search: true,
          emptyRowsWhenPaging: false,
          sorting: false,
          draggable: false,
          pageSize: rows,
          pageSizeOptions: [5, 10, 20],
        }}
        onChangeRowsPerPage={(params) => {
          setRows(params);
        }}
        onRowClick={(event, rowData) => {
          setMappedOrderNumber(rowData?.mappedOrderNumber);
        }}
        components={{
          Pagination: PatchedPagination,
        }}
      />
    </>
  );
};

export default PaymentReconcilationTable;
