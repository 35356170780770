import React, { useState, useContext } from "react";
import CreditCard from "../../components/CreditCard";
import LeftSideMenu from "../../containers/patient_flow/LeftSideMenu_patient";
import PAScreens from "../../routes/Pages";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Checkbox, MenuItem, FormControl, FormControlLabel, Select, FormLabel, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { FormHelperText, IconButton } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import ImageTooltip from "../../components/ImageTooltip";
import echeckPaymentRoutingNumber from "../../assets/images/echeckPaymentRoutingNumber.jpg";
import CardPaymentTooltip from "../../assets/images/CardPaymentTooltip.jpg";
import { cityData, countryData, allStatesData, stateList } from "../../util/StatesData";
import { useHistory } from "react-router";
import { Grid } from "@material-ui/core";
import { paymentCreditCardService } from "../../services/paymentCreditCardService";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import PASideNav from "../../routes/Pages/SideNav";
import { requestInfo_PP } from "../../Common/CommonFn";
import cardImage from "../../assets/images/card.png";
import visaImage from "../../assets/images/visa.png";
import masterImage from "../../assets/images/master-card.png";
import amexImage from "../../assets/images/amx.png";
import discoverImage from "../../assets/images/discover.png";
import { error, errorNotification, success, successNotification } from "../../components/PP_Notification/PP_Notification";
import { AppContext } from "../../context/AppProvider";
import TermsAndConditions from "./../../components/TermsAndConditions/TermsAndConditions";
import moment from "moment";
import { payplanService } from "../../services/payplanService";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import { handleSuccess, ToastContainer, handleError, handleWarning } from "../../components/Notifications/Toast";
import HeaderQP from "../../components/admin_header/headerQP";
import visa from "../../assets/images/visa.png";
import master from "../../assets/images/master-card.png";
import amx from "../../assets/images/amx.png";
import CardFormatter from "../../util/CreditCardFormatter";
import UpdateTrackingPage from "../UpdateTrackingPage";
import SpinnerPage from "../../Common/PP_loader";

class AddCardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardnumberFlag: false,
      sideNavToggleClass: false,
      showForm: true,
      showloader: false,
      countryList: [],
      cityList: [],
      country: "United States of America",
      state: "",
      city: "",
      carddata: "",
      payeraddrdata: "",
      isMaskOne: true,
      instrumentid: "",
      currentyear: new Date().getFullYear(),
      ES_AppId: "",
      ES_PartyId: "",
      ES_Amount: "",
      ES_SubId: "",
      ES_Method: "",
      ES_OrderId: "",
      ES_url: "",
      ES_PlanId: "",
      ES_PriceSubId: "",
      ES_PurchaseParameters: "",
      ES_JsonData: "",
      page: this.props.location?.state?.page,
      editCardData: this.props.location?.state?.selectedCard,
      editCard: this.props.location?.state?.editCard,
      selectedCardData: {
        cardNumber: "",
        cardMonth: "none",
        cardYear: "none",
        cardType: "",
      },
      billingAddress: {
        billingAddressId: "",
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phoneNo: "",
        street: "",
        street2: "",
        city: "",
        state: "Select State",
        country: "United States of America",
        postalCode: "",
      },
      showMaskedCardNumber: "",
      values: this.props.location?.state?.values,
      frequencyType: this.props.location?.state?.frequencyType,
      seedAccNum: this.props.location?.state?.seedAccNum,
      paymentOptions: this.props.location.state?.isPaymentOptions || false,
      orderId: this.props.location?.state?.orderId,
      plan: this.props.location?.state?.plan,
      cardtype: false,
    };
  }

  schema = Yup.object().shape({});

  _savePaymentPlan = (frequencyType, ppValues, seedAccountNumber, instrumentId, partyId) => {
    this.setState({ showloader: true });
    let PassValues1 = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      recurringPayment: {
        // Id must be 0 for new one
        reccuringPaymentId: 0,
        recurringPaymentType: frequencyType,
        recurringAmmount: ppValues?.paymentAmount,
        noOfPayments: ppValues?.noOfInstallments,
        startDate: moment(ppValues?.installmentStartDate).format("MM/DD/YYYY"),
        paymentInstrumentId: instrumentId,
        masterAccountReferenceNumber: seedAccountNumber,
        payerPartyId: partyId,
        isOverride: this.props.location?.state?.fromOverride ? true : false,
        reminderSms: ppValues?.reminderSms && ppValues?.reminderSms === true ? 1 : 0,
        reminderEmail: ppValues?.reminderEmail && ppValues?.reminderEmail === true ? 1 : 0,
        paymentSms: ppValues?.paymentSms && ppValues?.paymentSms === true ? 1 : 0,
        paymentEmail: ppValues?.paymentEmail && ppValues?.paymentEmail === true ? 1 : 0,
      },
    };
    let PassValues2 = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      recurringPayment: {
        // Id must be 0 for new one
        reccuringPaymentId: ppValues?.reccuringPaymentId || 0,
        recurringPaymentType: frequencyType,
        recurringAmmount: ppValues?.paymentAmount,
        noOfPayments: ppValues?.noOfInstallments,
        startDate: moment(ppValues?.installmentStartDate).format("MM/DD/YYYY"),
        paymentInstrumentId: instrumentId,
        masterAccountReferenceNumber: seedAccountNumber,
        payerPartyId: partyId,
        isOverride: this.props.location?.state?.fromOverride ? true : false,
        reminderSms: ppValues?.reminderSms && ppValues?.reminderSms === true ? 1 : 0,
        reminderEmail: ppValues?.reminderEmail && ppValues?.reminderEmail === true ? 1 : 0,
        paymentSms: ppValues?.paymentSms && ppValues?.paymentSms === true ? 1 : 0,
        paymentEmail: ppValues?.paymentEmail && ppValues?.paymentEmail === true ? 1 : 0,
      },
    };
    if (!ppValues?.reccuringPaymentId) {
      payplanService.savePayPlan(
        PassValues1,
        1234,
        (res) => {
          if (res?.data?.status === "Success") {
            handleSuccess("Payment Arrangement  Scheduled Successfully");
            setTimeout(() => {
              this.setState({ showloader: false });
              this.props.history.push({
                pathname:
                  sessionStorage?.getItem("currentUserRole") === "Admin"
                    ? "/pa/PayPlanOptionsSuccess"
                    : "/home/PayPlanOptionsSuccess",
                search: `?${this.state.seedAccNum}`,
                state: {
                  data: res.data,
                  seedAccNum: this.state.seedAccNum,
                  page: this.state.page,
                },
              });
            }, 2000);
          } else {
            this.setState({ showloader: false });
            handleError("Can't save Payment Arrangement  details");
          }
        },
        (error) => {
          handleError("Something went wrong! Please try again");
          this.setState({ showloader: false });
        },
      );
    } else {
      payplanService.editPayPlan(
        PassValues2,
        1234,
        (res) => {
          if (res?.data?.status === "Success") {
            this.props.history.push({
              pathname:
                sessionStorage?.getItem("currentUserRole") === "Admin"
                  ? "/pa/PayPlanOptionsSuccess"
                  : "/home/PayPlanOptionsSuccess",
              search: `?${this.state.seedAccNum}`,
              state: {
                data: res.data,
                seedAccNum: this.state.seedAccNum,
                page: this.state.page,
              },
            });
            this.setState({ showloader: false });
            handleSuccess("Payment Arrangement Scheduled Successfully");
          } else {
            this.setState({ showloader: false });
            handleError("Can't save Payment Arrangement  details");
          }
        },
        (error) => {
          handleError("Something went wrong! Please try again");
          this.setState({ showloader: false });
        },
      );
    }
  };

  _handlePaymentPlan = (orderId, instrumentId, tab) => {
    this.setState({ showloader: true });
    if (orderId) {
      payplanService.updateSingleOrder(
        requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        orderId,
        instrumentId,
        1234,
        (res) => {
          if (res.status === 200 && res.data.statusCodeValue === 200) {
            this.setState({ showloader: false });
            handleSuccess(`Payment Method Updated Successfully with Order Id ${orderId}`);
            setTimeout(() => {
              if (tab === "PaymentPlan") {
                this.props.history.push({
                  pathname:
                    sessionStorage?.getItem("currentUserRole") === "Admin"
                      ? "/pa/payment-plan-details"
                      : "/home/payment-plan-details",
                  search: `${this.state.plan}`,
                });
              } else if (tab === "FuturePayment") {
                this.props.history.push({
                  pathname: "Payment-History",
                  state: {
                    from:
                      sessionStorage?.getItem("currentUserRole") === "Admin"
                        ? "/pa/payment-plan-details"
                        : "/home/payment-plan-details",
                  },
                });
              }
            }, 2000);
          } else {
            this.setState({ showloader: false });
            handleWarning("Something went wrong!");
          }
        },
        (error) => {
          handleError("Something went wrong! Please try again");
          this.setState({ showloader: false });
        },
      );
    } else {
      payplanService.updatePaymentPlan(
        requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        this.state.plan,
        instrumentId,
        1234,
        (res) => {
          if (res.status === 200 && res.data.statusCodeValue === 200) {
            this.setState({ showloader: false });
            handleSuccess(`Payment Method Updated Successfully for plan ${this.state.plan}`);
            this.props.history.push({
              pathname:
                sessionStorage?.getItem("currentUserRole") === "Admin"
                  ? "/pa/payment-plan-details"
                  : "/home/payment-plan-details",
              search: `${this.state.plan}`,
            });
          } else {
            this.setState({ showloader: false });
            handleWarning("Something went wrong!");
          }
        },
        (error) => {
          handleError("Something went wrong! Please try again");
          this.setState({ showloader: false });
        },
      );
    }
  };
  _handleSubmit = async (values, onSubmitProps) => {
    const partyids = JSON.parse(sessionStorage.getItem("verifypayer"));
    let partyid = partyids.partyId;
    if (this.state.selectedCardData.instrumentId) {
      if (this.state.page === "PaymentPlan") {
        this._handlePaymentPlan(this.state.orderId, this.state.selectedCardData.instrumentId, "PaymentPlan");
      } else if (this.state.page === "FuturePayment") {
        this._handlePaymentPlan(this.state.orderId, this.state.selectedCardData.instrumentId, "FuturePayment");
      } else {
        this._savePaymentPlan(
          this.state?.frequencyType,
          this.state?.values,
          this.state.seedAccNum,
          this.state.selectedCardData.instrumentId,
          partyid,
        );
      }
    } else {
      this.setState({ showloader: true });

      let modifieddata = new Date();
      let dateString =
        modifieddata.getUTCFullYear() +
        "/" +
        (modifieddata.getUTCMonth() + 1) +
        "/" +
        modifieddata.getUTCDate() +
        " " +
        modifieddata.getUTCHours() +
        ":" +
        modifieddata.getUTCMinutes() +
        ":" +
        modifieddata.getUTCSeconds();

      const PassValues = {
        requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        paymentInfos: [
          {
            achAccount: null,
            cardAccount: [
              {
                cardId: values.cardId || 0,
                payerPartyId: partyid,
                cardNumber: values.cardNumber,
                cardExpiryMonth: values.month,
                cardExpiryYear: `${values.year}`,
                cardVerificationCode: values.cardCvvNumber,
                cardName: values.firstname + " " + values.lastname,
                cardNetworkId: 72000,
                cardTypeId: 73000,
                paymentMethodId: "75001",
                isActive: 1,
                paymentInstrument: {
                  paymentInstrumentId: this.state.selectedCardData.instrumentId || 0,
                  linkedPaymentMethodEntityId: this.state.selectedCardData.linkedPaymentMethodEntityId || 0,
                  payerPartyId: partyid,
                  paymentMethodId: 75001,
                  isDefault: null,
                  isExpired: null,
                  isBlocked: null,
                  isBlacklisted: null,
                  lastUsageDatetime: dateString,
                },
                billingAddress: {
                  billingAddressId: this.state.selectedCardData.billingAddressId || 0,
                  firstName: values.firstname,
                  middleName: values.middlename || "",
                  lastName: values.lastname,
                  email: values.emailAddress,
                  phoneNo: values.phoneNumber,
                  street: values.address1,
                  street2: "",
                  city: values.city,
                  state: values.state,
                  country: values.country,
                  postalCode: values.zipCode.replace("-", ""),
                },
              },
            ],
            bankAccount: null,
            walletAccount: null,
            virtualAccount: null,
          },
        ],
      };
      paymentCreditCardService.saveCreditDetails(PassValues, 1212, (res) => {
        if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
          if (this.state.page === "PaymentPlan") {
            this._handlePaymentPlan(
              this.state.orderId,
              res?.data?.paymentInfos[0]?.cardAccount[0]?.paymentInstrument?.paymentInstrumentId,
              "PaymentPlan",
            );
          } else if (this.state.page === "FuturePayment") {
            this._handlePaymentPlan(
              this.state.orderId,
              res?.data?.paymentInfos[0]?.cardAccount[0]?.paymentInstrument?.paymentInstrumentId,
              "FuturePayment",
            );
          } else if (this.state.page === "paymentMethod") {
            handleSuccess("Card Added Successfully");
            sessionStorage?.getItem("currentUserRole") === "Admin"
              ? this.props.history.push("/pa/payment-methods")
              : this.props.history.push("/home/payment-methods");
          } else {
            this._savePaymentPlan(
              this.state?.frequencyType,
              this.state?.values,
              this.state.seedAccNum,
              res?.data?.paymentInfos[0]?.cardAccount[0]?.paymentInstrument?.paymentInstrumentId,
              partyid,
            );
          }
        } else {
          this.setState({ showloader: false });
          handleError("Can't save card details");
        }
      });
    }
  };

  componentDidMount() {
    let payeruserdata = JSON.parse(sessionStorage.getItem("verifypayer"));
    if (this.state?.editCardData?.length > 0) {
      let data = this.state.editCardData;

      this.setState({
        ...this.state,
        billingAddress: {
          ...this.state.billingAddress,
          firstName: data[0]?.billingAddress?.firstName,
          middleName: data[0]?.billingAddress?.middleName,
          lastName: data[0]?.billingAddress?.lastName,
          email: data[0]?.billingAddress?.email,
          phoneNo: data[0]?.billingAddress?.phoneNo,
          street: data[0]?.billingAddress?.street,
          street2: data[0]?.billingAddress?.street2,
          city: data[0]?.billingAddress?.city,
          state: data[0]?.billingAddress?.state,
          stateId: data[0]?.billingAddress?.stateId,
          country: "United States of America",
          postalCode: data[0]?.billingAddress?.postalCode,
        },
        selectedCardData: {
          cardNumber: data[0]?.cardNumber,
          cardMonth: data[0]?.cardExpiryMonth,
          cardYear: data[0]?.cardExpiryYear,
          billingAddressId: this.state.editCardData[0].billingAddress?.billingAddressId || 0,
          instrumentId: this.state.editCardData[0].paymentInstrument?.paymentInstrumentId || 0,
          linkedPaymentMethodEntityId: this.state.editCardData[0].paymentInstrument?.linkedPaymentMethodEntityId || 0,
          cardType: this.state?.editCardData[0]?.cardType,
        },
      });
      window.scrollTo(0, 0);
    } else if (payeruserdata)
      this.setState({
        ...this.state,
        billingAddress: {
          ...this.state.billingAddress,
          firstName: payeruserdata.firstName,
          middleName: payeruserdata.middleName,
          lastName: payeruserdata.lastName,
          email: payeruserdata.contactEmail,
          phoneNo: payeruserdata.contactNo,
          street: payeruserdata?.addressVo?.street,
          street2: payeruserdata?.addressVo?.street2,
          city: payeruserdata?.addressVo?.city,
          state: payeruserdata?.addressVo?.state,
          stateId: payeruserdata?.addressVo?.stateId,
          country: "United States of America",
          postalCode:
            payeruserdata?.addressVo?.postalCode?.length > 5 && !payeruserdata?.addressVo?.postalCode?.includes("-")
              ? payeruserdata?.addressVo?.postalCode?.substring(0, 5).concat("-") +
                payeruserdata?.addressVo?.postalCode?.substring(5, payeruserdata?.addressVo?.postalCode?.length)
              : payeruserdata?.addressVo?.postalCode,
        },
      });
    window.scrollTo(0, 0);
  }

  _paymentConfirmationValidationWithoutCard = Yup.object().shape({
    termsconditions:
      sessionStorage.getItem("currentUserRole") !== "Admin" &&
      Yup.boolean().oneOf([true], "You must agree to the Online Payment Terms and Conditions"),
  });

  _paymentConfirmationValidation = Yup.object({
    firstname: Yup.string()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters ,dash(-), dot(.), tilled and apostrophes(') are allowed")
      .min(1, "Please enter at least 1 Character")
      .max(20, "Max 20 characters allowed")
      .required("First Name is required"),
    middlename: Yup.string()
      .nullable()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters, dash(-), dot(.), tilled and apostrophes(') are allowed")
      .min(1, "Please enter at least 1 Characters")
      .max(20, "Max 20 characters allowed")
      .notRequired(),
    lastname: Yup.string()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters, dash(-), dot(.), tilled and apostrophes(') are allowed")
      .min(1, "Please enter at least 1 Characters")
      .max(20, "Max 20 characters allowed")
      .required("Last Name is required"),
    cardNumber: Yup.string()
      .matches(
        /^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|3[47][0-9]{13})$/,
        "Only Visa, Master and American Express cards are allowed",
      )
      .required("Valid Card Number is required"),
    year: Yup.string()
      .required("Select Year")
      .test("year", "Select Year", (val) => val !== "none"),
    month: Yup.number()
      .typeError("Select Month")
      .when("year", (year, schema) => {
        return schema.test({
          test: (month) =>
            Number(year) === Number(new Date().getFullYear()) ? Number(month) > Number(new Date().getMonth()) : true,
          message: "Card is expired",
        });
      })
      .required("Select Month"),
    emailAddress: Yup.string()
      .when("accountnumber", (accountnumber, field) => {
        return sessionStorage.getItem("currentUserRole") && sessionStorage.getItem("currentUserRole") !== "Admin"
          ? field.required("Email Address Required")
          : field.email("Email Address must be valid");
      })
      .nullable(),
    securitycode: Yup.string()
      .nullable()
      .matches(/^[0-9]*$/, "Please enter a valid Security Code")
      .when("cardtype", {
        is: "American Express",
        then: Yup.string().min(4, "Enter 4 digits only").max(4, "Enter 4 digits only"),
        otherwise: Yup.string().min(3, "Enter 3 digits only").max(3, "Enter 3 digits only"),
      })
      .when("currentUserRole", {
        is: (val) => val !== "Admin",
        then: Yup.string().required("Security Code is required"),
      }),
    phoneNumber: Yup.string()
      .nullable()
      .trim()
      .matches(/^\d+\.?\d*$/, "Enter digits only")

      .min(10, "Please enter 10 digits Phone Number")
      .max(10, "Please enter 10 digits Phone Number")

      .required("Phone Number is required"),

    address1: Yup.string().nullable().max(255, "Max 255 characters allowed").required("Billing Address is required"),
    address2: Yup.string().nullable().max(255, "Max 255 characters allowed"),
    country: Yup.string().nullable().required("Country is required"),
    state: Yup.string().nullable().required("State is required"),
    city: Yup.string()
      .nullable()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters, dash(-), dot(.), tilled and apostrophes(') are allowed")
      .required("City is required"),
    zipCode: Yup.string()
      .nullable()
      .trim()
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/g, "Please enter a valid ZIP Code")
      .test("Invalid ZIP Code", (value) => !/^([0-9])\1{4,}$/.test(value))
      .required("ZIP Code is required"),
    termsconditions:
      sessionStorage.getItem("currentUserRole") !== "Admin" &&
      Yup.boolean().oneOf([true], "You must agree to the Online Payment Terms and Conditions"),
  });

  validateVisa = (value) => {
    const re = /^4\d{12}(?:\d{3})?$/;
    return value === "Visa";
  };
  validateMaster = (value) => {
    const re = /^5[1-5][0-9]{14}|^(222[1-9]|22[3-9]\\d|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{12}$/;
    return value === "Master Card";
  };
  validateAmex = (value) => {
    const re = /^3[47]\d{13,14}$/;
    return value == "American Express";
  };

  handleCardNumber = (e) => {
    if (!this.state.cardnumberFlag) {
      this.setState({ cardnumberFlag: true });
    } else {
      this.setState({ cardnumberFlag: false });
    }
  };
  handleRoutingNumber = () => {
    this.setState({ routingNumber: !this.state.routingNumber });
  };

  handleAccountNumber = () => {
    this.setState({ accountNumber: !this.state.accountNumber });
  };

  handleReAccountNumber = () => {
    this.setState({ reAccountNumber: !this.state.reAccountNumber });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  toggleSidenavClass = (bool) => {
    this.setState({
      sideNavToggleClass: bool,
    });
  };
  getAddressObject = (address_components) => {
    const ShouldBeComponent = {
      street_number: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      province: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      country: ["country"],
    };

    let address = {
      street_number: "",
      postal_code: "",
      street: "",
      province: "",
      city: "",
      country: "",
    };

    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "country") {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });
    // Fix the shape to match our schema
    address.address = address?.street_number + " " + address?.street;
    delete address.street_number;
    delete address.street;
    if (address.country === "US") {
      address.state = address.province;
      delete address.province;
    }
    return address;
  };
  getGoogleApiInfo = async (values, val) => {
    const geocodeObj = val && val.value && (await geocodeByPlaceId(val.value.place_id));
    const addressObject = geocodeObj && this.getAddressObject(geocodeObj[0].address_components);

    addressObject
      ? this.setState({
          ...values,
          country:
            addressObject?.country.toLocaleLowerCase() === "us" || addressObject.country.toLocaleLowerCase() === "usa"
              ? "United States of America"
              : addressObject.country,
          state: addressObject?.state,
          city: addressObject?.city,
          zipCode: addressObject?.postal_code,
          address1: addressObject?.address.trim(),
          address2: "",
          googleaddress: val,
          securitycode: values?.securitycode,
          cardCvvNumber: values?.securitycode,
        })
      : this.setState({
          ...values,
          googleaddress: "clear",
        });
  };

  cardImages = [
    {
      name: "visaLogo",
      cardImage: visa,
    },
    {
      name: "masterLogo",
      cardImage: master,
    },
    {
      name: "amxLogo",
      cardImage: amx,
    },
  ];

  render() {
    const billing = this.state.billingAddress;
    const monthsData = [
      {
        value: "01",
        label: "Jan",
      },
      {
        value: "02",
        label: "Feb",
      },
      {
        value: "03",
        label: "Mar",
      },
      {
        value: "04",
        label: "Apr",
      },
      {
        value: "05",
        label: "May",
      },
      {
        value: "06",
        label: "Jun",
      },
      {
        value: "07",
        label: "Jul",
      },
      {
        value: "08",
        label: "Aug",
      },
      {
        value: "09",
        label: "Sep",
      },
      {
        value: "10",
        label: "Oct",
      },
      {
        value: "11",
        label: "Nov",
      },
      {
        value: "12",
        label: "Dec",
      },
    ];
    return (
      <>
        {this.props.location?.state?.page === "QuickpayDB" ? <HeaderQP /> : null}

        {this.state.showloader === true ? <SpinnerPage /> : ""}
        <div className="addcard-container">
          <div
            className={
              this.state.sideNavToggleClass
                ? `transaction-card echeck-credit-container credit-card-container`
                : `transaction-card echeck-credit-container-collapse echeck-credit-container`
            }>
            <div className="change-password dashboard-content" role="main">
              <div
                className={
                  sessionStorage.getItem("currentUserRole") === "Customer"
                    ? `dashboard-content-card main-container-sm`
                    : `dashboard-content-card`
                }>
                <Formik
                  enableReinitialize
                  initialValues={{
                    cardtype: this.state.selectedCardData.cardType || this.state.cardtype,
                    nameOnCard: this.state.nameOnCard,
                    firstname: this.state.firstName || billing.firstName,
                    lastname: this.state.lastName || billing.lastName,
                    middlename: this.state.middleName || billing.middleName,
                    cardNumber: this.state.cardNumber || this.state.selectedCardData.cardNumber,
                    cardmaskedNumber: this.state.cardmaskedNumber || this.state.selectedCardData.cardNumber,
                    month: this.state.month || this.state.selectedCardData.cardMonth,
                    year: this.state.year || this.state.selectedCardData.cardYear,
                    securitycode: this.state.cardCvvNumber,
                    emailAddress: this.state.emailAddress || billing.email,
                    phoneNumber: this.state.phoneNumber || billing.phoneNo,
                    address1: this.state.address1 || billing.street,
                    address2: this.state.address2 || billing.street2,
                    cityList: this.state.cityList || [],
                    country: this.state.country || billing.country,
                    countryid: this.state.countryid ? this.state.countryid : "",
                    state: this.state.state || billing.state,
                    stateId: this.state.stateid || billing.stateId,
                    city: this.state.city || billing.city,
                    cityid: this.state.cityid ? this.state.cityid : "",
                    zipCode: this.state.zipCode || billing.postalCode,
                    userdata: true,
                    termsconditions: true,
                    flag: true,
                    isActive: "",
                    showCardNumber: false,
                    currentUserRole: sessionStorage.getItem("currentUserRole") || "",
                    countrycode: "+1",
                  }}
                  key="myform"
                  validationSchema={
                    this.state.editCardData?.length > 0
                      ? this._paymentConfirmationValidationWithoutCard
                      : this._paymentConfirmationValidation
                  }
                  onSubmit={this._handleSubmit}>
                  {(props) => {
                    const { values, touched, error, handleChange, setErrors, errors, setFieldValue, handleBlur } = props;
                    return (
                      <Form className="input-field e-check-bg" noValidate="noValidate">
                        <div>
                          <div className="e-check-bg-card ">
                            <div className="echeck-card">
                              <div role="heading" className="e-check-header" aria-level="1">
                                {" "}
                                <h2 className=" heading-style">
                                  {this.state.editCard ? "Edit Debit/Credit Card" : "Add Debit/Credit Card"}
                                </h2>
                                <div className="card-images">
                                  {this.cardImages.map((items) => {
                                    return <img src={items.cardImage} alt={items.name} key={items.name} className="m-1" />;
                                  })}
                                </div>
                              </div>
                              {this.state.selectedCardData.instrumentId ? (
                                <>
                                  <div className="cardpersonal-details my-3">
                                    <div className="form-row">
                                      <div className="col-md-4 col-sm-6 form-group">
                                        <div className="edit-card-details">
                                          <div className="label ">
                                            <span>First Name</span> :
                                          </div>
                                          <div className="value">
                                            {values.firstname
                                              ? values.firstname?.charAt(0).toUpperCase() + values.firstname?.slice(1)
                                              : "---"}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6  form-group">
                                        <div className="edit-card-details">
                                          <div className="label ">
                                            <span> Middle Name</span> :
                                          </div>
                                          <div className="value">
                                            {values.middlename
                                              ? values.middlename?.charAt(0).toUpperCase() + values.middlename?.slice(1)
                                              : "---"}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6 form-group">
                                        <div className="edit-card-details">
                                          <div className="label ">
                                            <span> Last Name</span> :
                                          </div>
                                          <div className="value">
                                            {values.lastname
                                              ? values.lastname?.charAt(0).toUpperCase() + values.lastname?.slice(1)
                                              : "---"}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-4 col-sm-6 form-group">
                                        <div className="edit-card-details">
                                          <div className="label ">
                                            <span> Card Number </span> :
                                          </div>
                                          <div className="value">{values.cardNumber ? values.cardNumber : "---"}</div>
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6 form-group">
                                        <div className="edit-card-details">
                                          <div className="label ">
                                            <span>Expiration Date </span> :
                                          </div>
                                          <div className="value">
                                            {values.month && values.year ? `${values.month} / ${values.year}` : "---"}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-8 col-sm-6 form-group">
                                        <div className="edit-card-details">
                                          <div className="label ">
                                            <span>Email Address </span> :
                                          </div>
                                          <div className="value">{values.emailAddress ? values.emailAddress : "---"}</div>
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6 form-group">
                                        <div className="edit-card-details">
                                          <div className="label ">
                                            <span>Phone Number </span> :
                                          </div>
                                          <div className="value"> {values.phoneNumber ? values.phoneNumber : "---"}</div>
                                        </div>
                                      </div>
                                      <div className="col-md-12 billing-details">
                                        <div className="edit-card-details">
                                          <div className="label ">
                                            <span>Billing Address </span> :
                                          </div>
                                          <div className="value">
                                            {values.address1
                                              ? `${values.address1.charAt(0).toUpperCase() + values.address1?.slice(1)} ${
                                                  values.city.charAt(0).toUpperCase() + values.city?.slice(1)
                                                } ${values?.city?.length > 0 && `,`}${
                                                  values.state.charAt(0).toUpperCase() + values.state?.slice(1)
                                                } ${values?.state?.length > 0 && `,`}${
                                                  values.country.charAt(0).toUpperCase() + values.country?.slice(1)
                                                }${values?.country?.length > 0 && `,`}${values.zipCode}`.replace(/,/g, ", ")
                                              : "---"}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div>
                                  {this.props.futurePayment && (
                                    <>
                                      <div className="col-md-4 date-schedule form-group">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                          <Grid container className="date-picker">
                                            <label htmlFor="futureDate" className="form-label">
                                              Future Date
                                            </label>
                                            <DatePicker
                                              disableFuture
                                              name="futureDate"
                                              autoOk
                                              value={values.futureDate}
                                              onChange={(val) => setFieldValue("futureDate", val)}
                                              renderInput={(params) => (
                                                <TextField {...params} helperText={null} margin="dense" />
                                              )}
                                            />
                                          </Grid>
                                        </LocalizationProvider>
                                      </div>
                                    </>
                                  )}

                                  <div className="form-row debitcard pb-2 ">
                                    <div className="col-lg-4 col-md-6 col-sm-6 form-group">
                                      <FormControl variant="outlined" className="w-100" fullWidth>
                                        <FormLabel htmlFor="firstName" className="required">
                                          First Name
                                        </FormLabel>
                                        <OutlinedInput
                                          className="form-control"
                                          name="firstname"
                                          value={values?.firstname.charAt(0).toUpperCase() + values?.firstname.slice(1)}
                                          onChange={handleChange}
                                          placeholder="Enter First Name"
                                          aria-label="text-field"
                                          onBlur={handleBlur}
                                          touched
                                          fullWidth
                                          required></OutlinedInput>
                                      </FormControl>
                                      <FormHelperText error name="firstname">
                                        {touched.firstname && errors.firstname ? <div>{errors.firstname}</div> : null}
                                      </FormHelperText>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6 form-group">
                                      <FormControl variant="outlined" className="w-100" fullWidth>
                                        <FormLabel htmlFor="middlename">Middle Name</FormLabel>
                                        <OutlinedInput
                                          className="form-control"
                                          name="middlename"
                                          value={values.middlename}
                                          onChange={handleChange}
                                          placeholder="Enter Middle Name"
                                          aria-label="text-field"
                                          fullWidth
                                          required></OutlinedInput>
                                      </FormControl>
                                      <FormHelperText error name="middlename">
                                        {touched.middlename && errors.middlename ? <div>{errors.middlename}</div> : null}
                                      </FormHelperText>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6 form-group">
                                      <FormControl variant="outlined" className="w-100" fullWidth>
                                        <FormLabel htmlFor="lastName" className="required">
                                          Last Name
                                        </FormLabel>
                                        <OutlinedInput
                                          className="form-control"
                                          name="lastname"
                                          value={values?.lastname.charAt(0).toUpperCase() + values?.lastname.slice(1)}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          placeholder="Enter Last Name"
                                          aria-label="text-field"
                                          fullWidth
                                          required></OutlinedInput>
                                      </FormControl>
                                      <FormHelperText error name="lastname">
                                        {touched.lastname && errors.lastname ? <div>{errors.lastname}</div> : null}
                                      </FormHelperText>
                                    </div>

                                    <div className="col-lg-7 col-md-6 col-sm-6 form-group">
                                      <div className="form-row mx-0  justify-content-between align-items-center">
                                        <FormLabel htmlFor="cardnumber" className="required">
                                          Card Number
                                        </FormLabel>
                                      </div>
                                      <TextField
                                        type={values.showCardNumber ? "text" : "password"}
                                        margin="dense"
                                        variant="outlined"
                                        name="cardNumber"
                                        placeholder="Enter Card Number"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        className={`form-control text-field maskfieldcc h-auto ${
                                          touched.cardNumber && errors.cardNumber ? "is-invalid" : ""
                                        }`}
                                        value={values.cardNumber}
                                        onChange={(e) => {
                                          // e.preventDefault();
                                          setErrors({ ...errors, cardNumber: "" });
                                          let cardno = e.target.value;
                                          var cardtype = false;
                                          var visapattern = /^4[0-9]{12}(?:[0-9]{3})?$/;
                                          var masterpattern = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/;
                                          var amexpattern = /^3[47][0-9]{13}$/;
                                          if (visapattern.exec(cardno.replace(/ /g, ""))) {
                                            cardtype = "Visa";
                                          } else if (masterpattern.exec(cardno.replace(/ /g, ""))) {
                                            cardtype = "Master Card";
                                          } else if (amexpattern.exec(cardno.replace(/ /g, ""))) {
                                            cardtype = "American Express";
                                          } else {
                                            cardtype = false;
                                          }
                                          setFieldValue("cardNumber", cardno);
                                          setFieldValue("cardtype", cardtype);
                                          setFieldValue("securitycode", "");
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <>
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={() => {
                                                    setFieldValue((values.showCardNumber = !values.showCardNumber));
                                                  }}>
                                                  {values.showCardNumber ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                              </>
                                            </InputAdornment>
                                          ),
                                          endAdornment: (
                                            <InputAdornment position="end" className="cardnumber-input">
                                              <>
                                                <span className="cards">
                                                  <img
                                                    src={
                                                      values.cardtype === "Visa"
                                                        ? visaImage
                                                        : values.cardtype === "Master Card"
                                                        ? masterImage
                                                        : values.cardtype === "American Express"
                                                        ? amexImage
                                                        : values.cardtype === "Discover"
                                                        ? discoverImage
                                                        : ""
                                                    }
                                                    alt={""}
                                                  />
                                                </span>
                                              </>
                                            </InputAdornment>
                                          ),
                                          inputComponent: values.showCardNumber ? CardFormatter : "",
                                        }}
                                        onBlur={handleBlur}
                                        onKeyPress={(e) => {
                                          if (!/\d/.test(e.key) || values?.cardNumber?.length > 15) {
                                            e.preventDefault();
                                          }
                                        }}
                                        fullWidth
                                        disabled={this.state.selectedCardData?.instrumentId}
                                      />
                                      <FormHelperText error name="cardNumber">
                                        {touched.cardNumber && errors.cardNumber ? <div>{errors.cardNumber}</div> : null}
                                      </FormHelperText>
                                    </div>
                                    <div className="col-md-6 col-lg-3 col-sm-6  form-group inputbg">
                                      <FormControl variant="outlined" className="w-100" fullWidth>
                                        <FormLabel htmlFor="Expiration Date" className="form-label required">
                                          Expiration Date
                                        </FormLabel>
                                        <div className="form-row mx-0">
                                          <div className="col-6 pl-0">
                                            <Select
                                              fullWidth
                                              name="month"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.month}
                                              className="form-select form-control">
                                              <MenuItem value="none">Month</MenuItem>
                                              {monthsData.map((month, index) => {
                                                return (
                                                  <MenuItem value={month.value} key={index}>
                                                    {month.label}
                                                  </MenuItem>
                                                );
                                              })}
                                            </Select>
                                            <FormHelperText error name="month">
                                              {touched.month && errors.month ? <div>{errors.month}</div> : null}
                                            </FormHelperText>
                                          </div>
                                          <div className="col-6">
                                            <Select
                                              fullWidth
                                              name="year"
                                              onChange={handleChange("year")}
                                              onBlur={handleBlur}
                                              value={values.year}
                                              className="form-select form-control">
                                              <MenuItem value="none">Year</MenuItem>
                                              <MenuItem value={Number(this.state.currentyear)}>
                                                {Number(this.state.currentyear)}
                                              </MenuItem>
                                              <MenuItem value={Number(this.state.currentyear) + 1}>
                                                {Number(this.state.currentyear) + 1}
                                              </MenuItem>
                                              <MenuItem value={Number(this.state.currentyear) + 2}>
                                                {Number(this.state.currentyear) + 2}
                                              </MenuItem>
                                              <MenuItem value={Number(this.state.currentyear) + 3}>
                                                {Number(this.state.currentyear) + 3}
                                              </MenuItem>
                                              <MenuItem value={Number(this.state.currentyear) + 4}>
                                                {Number(this.state.currentyear) + 4}
                                              </MenuItem>
                                              <MenuItem value={Number(this.state.currentyear) + 5}>
                                                {Number(this.state.currentyear) + 5}
                                              </MenuItem>
                                              <MenuItem value={Number(this.state.currentyear) + 6}>
                                                {Number(this.state.currentyear) + 6}
                                              </MenuItem>
                                              <MenuItem value={Number(this.state.currentyear) + 7}>
                                                {Number(this.state.currentyear) + 7}
                                              </MenuItem>
                                              <MenuItem value={Number(this.state.currentyear) + 8}>
                                                {Number(this.state.currentyear) + 8}
                                              </MenuItem>
                                              <MenuItem value={Number(this.state.currentyear) + 9}>
                                                {Number(this.state.currentyear) + 9}
                                              </MenuItem>
                                            </Select>
                                            <FormHelperText error name="year">
                                              {touched.year && errors.year ? <div>{errors.year}</div> : null}
                                            </FormHelperText>
                                          </div>
                                        </div>
                                      </FormControl>
                                    </div>
                                    <div className="col-md-6 col-lg-2 col-sm-6 form-group">
                                      <FormControl variant="outlined" className="w-100" fullWidth>
                                        <div className="form-row mx-0 justify-content-between align-items-center">
                                          <FormLabel
                                            htmlFor="securitycode"
                                            className={
                                              sessionStorage?.getItem("currentUserRole") === "Admin" ? "" : "required"
                                            }>
                                            CVV
                                          </FormLabel>
                                          <ImageTooltip img={CardPaymentTooltip} width="150px" placement="right">
                                            <i className="fas fa-question-circle circle-icon"></i>
                                          </ImageTooltip>
                                          {/* </div> */}
                                        </div>
                                        <OutlinedInput
                                          className="form-control signup-fields"
                                          name="securitycode"
                                          id="securitycode"
                                          value={values.cardNumber === "" ? "" : values.securitycode}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          placeholder="CVV"
                                          maxLength={4}
                                          onKeyPress={(event) => {
                                            if (!/\d/.test(event.key) || values?.securitycode?.length >= 4) {
                                              event.preventDefault();
                                            }
                                          }}
                                          aria-label="text-field"
                                          fullWidth
                                          type="password"
                                          required
                                        />
                                      </FormControl>
                                      <FormHelperText error name="securitycode">
                                        {touched.securitycode && errors.securitycode ? (
                                          <div>{errors.securitycode}</div>
                                        ) : null}
                                      </FormHelperText>
                                    </div>
                                  </div>

                                  <div className="form-row">
                                    <div className="col-md-4 col-lg-6 col-sm-12 form-group billing-address-cp">
                                      <FormControl variant="outlined" className="w-100" fullWidth>
                                        <FormLabel htmlFor="billingAddress" className="required">
                                          Billing Address
                                        </FormLabel>

                                        <GooglePlacesAutocomplete
                                          apiKey="AIzaSyAymCr1DkF6p_ULBgBIZIM2VNu9pg-zrN0"
                                          autocompletionRequest={{
                                            componentRestrictions: {
                                              country: ["us"],
                                            },
                                          }}
                                          selectProps={{
                                            isClearable: true,
                                            value: this.state.googleaddress,
                                            onChange: (val) => {
                                              this.getGoogleApiInfo({ ...values }, val);
                                            },
                                          }}
                                        />
                                      </FormControl>
                                    </div>
                                    <div className="col-md-8 col-lg-6 col-sm-12  form-group ">
                                      <div className="label-spacing mb-md-3">
                                        <FormLabel></FormLabel>
                                      </div>
                                      <FormControl variant="outlined" className="w-100 mt-md-1" fullWidth>
                                        <TextField
                                          type="text"
                                          autocomplete="new-password"
                                          id="address1"
                                          name="address1"
                                          value={
                                            values?.address2 ? values?.address1 + "," + values?.address2 : values?.address1
                                          }
                                          placeholder="Please Enter Street Address"
                                          className={` text-field ${
                                            touched.address1 && errors.address1 ? "is-invalid" : ""
                                          }`}
                                          onChange={(event) => {
                                            setFieldValue((values.address1 = event.target.value));
                                          }}></TextField>
                                      </FormControl>
                                      <FormHelperText error name="securitycode">
                                        {touched.address1 && errors.address1 ? <div>{errors.address1}</div> : null}
                                      </FormHelperText>
                                    </div>

                                    <div className="col-md-6 col-lg-3 col-sm-6 form-group">
                                      <FormControl variant="outlined" className="w-100" fullWidth>
                                        <FormLabel htmlFor="country" className="form-label required">
                                          Country
                                        </FormLabel>
                                        <Select
                                          fullWidth
                                          name="country"
                                          value={values.country}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          className="form-select form-control">
                                          {countryData.map((option) => {
                                            return (
                                              <MenuItem value={option.value} key={option.key}>
                                                {option.value}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                      <FormHelperText error name="country">
                                        {touched.country && errors.country ? <div>{errors.country}</div> : null}
                                      </FormHelperText>
                                    </div>
                                    <div className="col-md-6 col-lg-3  col-sm-6 form-group">
                                      <FormControl variant="outlined" className="w-100" fullWidth>
                                        <FormLabel htmlFor="state" className="form-label required">
                                          State
                                        </FormLabel>
                                        <Select
                                          fullWidth
                                          name="state"
                                          value={values?.state?.toLowerCase() || "none"}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          className="form-select form-control">
                                          <MenuItem value="none" key="none" disabled>
                                            Select State/County
                                          </MenuItem>
                                          {stateList.map((option) => {
                                            return (
                                              <MenuItem value={option.stateName} key={option.key}>
                                                {option.stateName.charAt(0).toUpperCase() + option.stateName.slice(1)}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                      <FormHelperText error name="state">
                                        {touched.state && errors.state ? <div>{errors.state}</div> : null}
                                      </FormHelperText>
                                    </div>

                                    <div className="col-md-6 col-lg-3 col-sm-6 form-group">
                                      <FormControl variant="outlined" className="w-100" fullWidth>
                                        <FormLabel htmlFor="city" className="form-label required">
                                          City
                                        </FormLabel>
                                        <OutlinedInput
                                          className="form-control"
                                          name="city"
                                          value={values.city}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          placeholder="Enter City"
                                          aria-label="text-field"
                                          fullWidth
                                          required></OutlinedInput>
                                      </FormControl>
                                      <FormHelperText error name="city">
                                        {touched.city && errors.city ? <div>{errors.city}</div> : null}
                                      </FormHelperText>
                                    </div>
                                    <div className="col-md-6 col-lg-3 col-sm-6 form-group">
                                      <FormControl variant="outlined" className="w-100" fullWidth>
                                        <FormLabel htmlFor="zipCode" className="required">
                                          ZIP Code
                                        </FormLabel>
                                        <OutlinedInput
                                          className="form-control"
                                          name="zipCode"
                                          value={values.zipCode}
                                          onChange={(event) => {
                                            const zip = event.target.value;
                                            setFieldValue(
                                              (values.zipCode =
                                                zip.length > 5 && !zip.includes("-")
                                                  ? zip.substring(0, 5).concat("-") + zip.substring(5, zip.length)
                                                  : zip),
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          inputProps={{
                                            maxLength: 10,
                                          }}
                                          type="text"
                                          placeholder="Enter ZIP Code"
                                          aria-label="text-field"
                                          fullWidth
                                          required></OutlinedInput>
                                      </FormControl>
                                      <FormHelperText error name="city">
                                        {touched.zipCode && errors.zipCode ? <div>{errors.zipCode}</div> : null}
                                      </FormHelperText>
                                    </div>

                                    <div className="col-md-6 col-lg-6 col-sm-6 form-group">
                                      <FormControl variant="outlined" className="w-100" fullWidth>
                                        <FormLabel
                                          htmlFor="emailAddress"
                                          className={
                                            sessionStorage?.getItem("currentUserRole") === "Admin" ? "" : "required"
                                          }>
                                          Email Address
                                        </FormLabel>
                                        <OutlinedInput
                                          className="form-control"
                                          name="emailAddress"
                                          value={values.emailAddress}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          type="email"
                                          placeholder="Enter Email Address"
                                          aria-label="text-field"
                                          fullWidth
                                          required></OutlinedInput>
                                      </FormControl>
                                      <FormHelperText error name="emailAddress">
                                        {touched.emailAddress && errors.emailAddress ? (
                                          <div>{errors.emailAddress}</div>
                                        ) : null}
                                      </FormHelperText>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-6 form-group">
                                      <FormControl variant="outlined" className="w-100">
                                        <FormLabel htmlFor="phoneNumber" className="required">
                                          Phone Number
                                        </FormLabel>
                                        <div className="form-row">
                                          <div className="col-3">
                                            <Select
                                              name="countrycode"
                                              value={values.countrycode}
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              className="form-select form-control">
                                              <MenuItem value={"+1"}>+1</MenuItem>
                                            </Select>
                                          </div>
                                          <div className="col-9">
                                            <OutlinedInput
                                              className="form-control"
                                              name="phoneNumber"
                                              value={values.phoneNumber}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              onKeyPress={(event) => {
                                                if (!/\d/.test(event.key) || values.phoneNumber.length >= 10) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              placeholder="Enter Phone Number"
                                              aria-label="text-field"
                                              fullWidth
                                              required></OutlinedInput>
                                          </div>
                                        </div>
                                      </FormControl>
                                      <FormHelperText error name="phoneNumber">
                                        {touched.phoneNumber && errors.phoneNumber ? <div>{errors.phoneNumber}</div> : null}
                                      </FormHelperText>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="agree-terms">
                                <div className="check-FormLabel authorization-input">
                                  <FormControl fullWidth component="fieldset" margin="normal">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="termsconditions"
                                          checked={values.termsconditions}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.termsconditions}
                                          color="primary"
                                        />
                                      }
                                      label={
                                        <span>
                                          <TermsAndConditions />
                                          {sessionStorage?.getItem("currentUserRole") === "Admin" ? (
                                            ""
                                          ) : (
                                            <FormHelperText error name="termsconditions">
                                              {touched.termsconditions && errors.termsconditions ? (
                                                <div>{errors.termsconditions}</div>
                                              ) : null}
                                            </FormHelperText>
                                          )}
                                        </span>
                                      }
                                    />
                                  </FormControl>
                                </div>
                                <div className="check-FormLabel authorization-input">
                                  <FormControl fullWidth component="fieldset" margin="normal">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="userdata"
                                          value={values.userdata}
                                          checked={values.userdata}
                                          onChange={handleChange}
                                          color="primary"
                                        />
                                      }
                                      label={
                                        <span>
                                          By checking this box,you agree that this is your personal email address and you
                                          authorize us to send you emails,call your phone, or contact you via an automated
                                          dialer,leave voicemails, and/or send text messages if necessary.
                                        </span>
                                      }
                                    />
                                  </FormControl>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="proceed-cancel-buttons">
                                  <button type="submit" className="primary-button themeing-buttons mx-1">
                                    Add Card
                                  </button>

                                  <button
                                    type="button"
                                    className="secondary-button mx-1"
                                    onClick={() => this.props.history.goBack()}>
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
                {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default AddCardContainer;
