import { Button } from "@material-ui/core";
import React from "react";
function SurveyFeedback({ rowData, handleClose }) {
  const capitalize = (s) => s[0].toUpperCase() + s.slice(1);
  const getValue = (id) => {
    const property = rowData?.paymentRPSurveyAnswersEntity?.find((obj) => {
      if (obj["questionId"] === id) {
        return obj;
      }
    });
    return capitalize(property?.answerText);
  };

  return (
    <div>
      <div className="form-row input-field">
        <div className=" form-group col-12 col-md-7 survey-feedback">
          <div className="survey-feedback-details">
            <div className="label">User Name:</div>
            <div className="value">{capitalize(rowData?.userName)}</div>
          </div>
        </div>

        <div className="form-group col-12 col-md-5  survey-feedback">
          <div className="survey-feedback-details">
            <div className="label">Date:</div>
            <div className="value">{rowData?.paymentRPSurveyAnswersEntity[0]?.createdDate.split(" ")[0]}</div>
          </div>
        </div>
        <div className="form-group col-12 col-md-12 survey-feedback">
          <div className="survey-feedback-details">
            <div className="label">Feedback:</div>
            <div className="value">{getValue(1)}</div>
          </div>
        </div>
        <div className="form-group col-12 col-md-12 survey-feedback">
          <div>
            <div className="label">Comments:</div> <div className="value">{getValue(2)}</div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <Button size="small" className="change-role-buttons secondary-button" onClick={handleClose}>
          Close
        </Button>
      </div>
    </div>
  );
}

export default SurveyFeedback;
