import React from "react";
import NumberFormat from "react-number-format";

export const Numberformat = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      decimalScale={0}
      allowNegative={false}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      // thousandSeparator
      // isNumericStringf
    />
  );
};
