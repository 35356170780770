/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import App from "./App";
import configureStore, { history } from "./redux/store";
import ErrorBoundary from "./util/ErrorBoundary";
// import store from '../src/store/index'

// const middlewares = [thunk];
export const store = configureStore();

const MainApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <ErrorBoundary>
          <Route path="/" component={App} />
        </ErrorBoundary>
      </Switch>
    </ConnectedRouter>
  </Provider>
);

export default MainApp;
