/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { Button, Typography, Card } from "@material-ui/core";
import { ThemeContext } from "./ThemeProvider";

const ThemeCard = ({ header, reset, children }) => {
  const { toggleTheme, toggleSideBarTheme, toggleBorderRadius } = useContext(ThemeContext);

  const handleReset = (reset) => {
    if (reset === "color") {
      toggleTheme("lightTheme");
    } else if (reset === "border") {
      //   toggleBorderRadius('default') => to be discus and implement the default one
    } else if (reset === "sidebar") {
      toggleSideBarTheme("blackSideBarTheme");
    }
  };

  return (
    <Card className="py-2 my-2">
      <div className="d-flex justify-content-between">
        <Typography className="p-2" variant="h6">
          {header}
        </Typography>
        <Button onClick={() => handleReset(reset)}>RESET</Button>
      </div>
      {children}
    </Card>
  );
};

export default ThemeCard;
