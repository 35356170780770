import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Med1Logo from "../../../assets/images/Med1Logo.png";
import { Formik, Form, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { getIn } from "formik";
import SpinnerPage from "../../../Common/PP_loader";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { I18nextProvider } from "react-i18next";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import RocketIcon from "../../../assets/images/quick-pay-rocket-icon.svg";
import { forgotPasswordData } from "../../../action/forgotPasswordAction";
import { bindActionCreators } from "redux";
import { BasicTooltip } from "../../../components/ImageTooltip/index";
import { handleSuccess, ToastContainer, handleError, handleWarning } from "../../../components/Notifications/Toast";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";

let intialvalues = {
  userName: "",
};

const mapStateToProps = (state) => ({
  // error: getloginuser(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      forgotPasswordData: forgotPasswordData,
    },
    dispatch,
  );

const ForgotPassword = (props) => {
  const [loader, setLoader] = useState(false);
  const [initialState, setInitialState] = useState({
    userName: "",
  });

  const options = {
    autoClose: 3000,
    hideProgressBar: false,
    className: "toaster-text",
    toastId: "",
  };

  const cleardata = () => {
    setInitialState({
      userName: "",
    });
  };

  const handleSubmit = (values, actions, resetForm) => {
    setLoader(true);
    props.forgotPasswordData(
      { userLoginName: values.userName, subBuId: 1 },
      (res) => {
        if (res.data.status === "success") {
          handleSuccess("Kindly check your email to reset password");
          setTimeout(() => {
            setLoader(false);
            props.history.push("/");
          }, 3000);
          cleardata();
          resetForm();
        } else if (res.data.status === "fail") {
          setLoader(false);
          handleWarning(res?.data?.reasonText);
        } else {
          setLoader(false);
          handleError(res?.data?.reasonText);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  return (
    <>
      <I18nextProvider i18n={i18n}>
        <div className="page-wrapper" role="main">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div>
                <div className="card-sm">
                  <div className="header">
                    <div className="rocket-icon">
                      <img src={RocketIcon} alt="" />
                    </div>
                    <div className="logo">
                      <img src={Med1Logo} className="img-fluid" alt="Med-1 Solutions Logo" />
                    </div>
                  </div>
                  <div className="main-heading">
                    <h1>{/* Quick<span>Pay</span> */}</h1>
                  </div>
                  <div className="login-content">
                    <Formik
                      initialValues={initialState}
                      onSubmit={(values, { resetForm }, actions) => handleSubmit(values, actions, resetForm)}
                      validationSchema={Yup.object().shape({
                        userName: Yup.string()
                          .required("Please enter the Username")
                          .max(100, "Must be less than 100 characters"),
                      })}>
                      {(props) => {
                        const { values, touched, error, handleChange, errors, setFieldValue } = props;
                        return (
                          <Form id="login-form" className="input-field" noValidate="noValidate">
                            {loader && <SpinnerPage />}
                            <div className="admin-forgot-box">
                              <div className="admin-title">
                                <Translation>{(t, { i18n }) => <label>{t("forgetPassword_title")}</label>}</Translation>
                                <br></br>
                                <div className="admin-check-message">
                                  <Translation>{(t, { i18n }) => <label>{t("forgetPassword_title2")}</label>}</Translation>
                                  <br></br>
                                  <Translation>{(t, { i18n }) => <label>{t("forgetPassword_title3")}</label>}</Translation>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="label-spacing">
                                <label htmlFor="userName" className="required">
                                  Username
                                </label>
                                <Link to={"/contact-us"}>
                                  <BasicTooltip placement="top" title="Need Help? Contact Us">
                                    <i className="fas fa-question-circle circle-icon"></i>
                                  </BasicTooltip>
                                </Link>
                              </div>
                              <OutlinedInput
                                className="form-control"
                                id="userName"
                                name="userName"
                                placeholder="Please Enter Username"
                                onChange={(event) => {
                                  setFieldValue((values.userName = event.target.value));
                                }}
                                value={values["userName"]}
                                aria-label="text-field"
                                fullWidth
                                error={Boolean(getIn(touched, "userName") && getIn(errors, "userName"))}
                                helperText={getIn(errors, "userName") && getIn(errors, "userName")}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required="true"
                                inputProps={{
                                  maxLength: 50,
                                }}></OutlinedInput>
                              <ErrorMessage name="userName" className="error-text" component="div" />
                            </div>
                            <div className="">
                              <button type="submit" className="primary-button btn-block">
                                Recover Password
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                    <div className="text-center">
                      <div className="more-info">
                        <p>
                          <Link to="/signin" aria-label="forgot-account">
                            Back To Login
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </I18nextProvider>
      {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
