import { OutstandingPayerService } from "../services/payerOutstandingService";
export const FETCH_OUTSTANDING_PAYER_DETAILS_SUCCESS = "FETCH_OUTSTANDING_PAYER_DETAILS_SUCCESS";
export const FETCH_OUTSTANDING_PAYER_DETAILS_PENDING = "FETCH_OUTSTANDING_PAYER_DETAILS_PENDING";

export const ADD_OUTSTANDING_PAYER = "ADD_OUTSTANDING_PAYER";

export function fetchoutstandingPayerSuccess(payerOutstandingData) {
  debugger;
  return {
    type: FETCH_OUTSTANDING_PAYER_DETAILS_SUCCESS,
    payload: payerOutstandingData,
  };
}
export function fetchoutstandingPayerpending() {
  debugger;
  return {
    type: FETCH_OUTSTANDING_PAYER_DETAILS_PENDING,
  };
}
export function fetchpayerOutstandingData(identifyPayerInfo, userId) {
  return (dispatch) => {
    dispatch(fetchoutstandingPayerpending());
    OutstandingPayerService.GetOutstandingPayerdata(identifyPayerInfo, userId, (res) => {
      dispatch(fetchoutstandingPayerSuccess(res));
    });
  };
}
export function addpayerOutstandingData(identifyPayerBody, userId, fn) {
  debugger;
  return (dispatch) => {
    dispatch({
      type: ADD_OUTSTANDING_PAYER,
      payload: identifyPayerBody,
    });
    OutstandingPayerService.AddOutstandingPayerdata(identifyPayerBody, userId, (res) => fn(res));
  };
}
