import RestDataSource from "./restdatasource";
import { Global_var } from "../global/global_var";
import { loginService } from "./loginService";

import {
    error,
    success,
    warning,
    warningNotification,
    errorNotification,
    successNotification,
  } from "../components/PP_Notification/PP_Notification";

export const paymentSuccessService = {
    getPaymentSuccessDetails, downloadReceipt, checkReceipt
};

function getPaymentSuccessDetails(paymentSuccessInfo, userId, fn, fnError) {
    // alert("in get service")
    // loginService.TokenUser((res) => {
    //     if (res.status === "success") {
    //         sessionStorage.setItem("jwt-token", res.responseObject);
            // var url = Global_var.URL_GET_PAYMENT_DEAILS;
            var url = Global_var.BASEURL + Global_var.URL_GET_PAYMENT_DEAILS
            return new RestDataSource(url, userId, fn).Store(
                paymentSuccessInfo,
                (res) => {
                    if (res != null) {
                        if(res['data'].status === "fail" )
                        {
                            if(res['data'].reasonText === "Invalid Token")
                            {
                                sessionStorage.clear();
                                warning(
                                  "Token Expired, Login/Verify again",
                                  warningNotification
                              );
                              window.location.replace("/login");
                            }else if(res['data'].reasonCode === "SM-02"){
                                sessionStorage.clear();
                                warning(
                                  "Token Expired, Login/Verify again",
                                  warningNotification
                              );
                              window.location.replace("/login");
                              }
                              else if(res['data'].reasonCode === "SM-10"){
                                sessionStorage.clear();
                                warning(
                                  "Token Expired, Login/Verify again",
                                  warningNotification
                              );
                              window.location.replace("/login");
                              }
                            else
                            {
                              warning(
                                "Something went wrong!" + res['data'].reasonText,
                                warningNotification
                            );
                            }
                        }
                        else
                        {
                            if (res.headers["token"] != null) {
                                sessionStorage.setItem("jwt-token", res.headers.token);
                              }
                        
                          fn(res);
                        }
                    }
                }
            );
    //     }
    // }, fnError);
}

function downloadReceipt(downloadInfo, userId, fn, fnError) {
    // alert("in get service")
    // loginService.TokenUser((res) => {
    //     if (res.status === "success") {
    //         sessionStorage.setItem("jwt-token", res.responseObject);
           // var url = "http://192.168.12.58:8019/downloadPdf"
        var url = Global_var.BASEURL + Global_var.URL_DOWNLOAD_PDF_DEAILS
            return new RestDataSource(url, userId, fn).Store(
                downloadInfo,
                (res) => {
                    if (res != null) {
                        if(res['data'].status === "fail" )
                        {
                            if(res['data'].reasonText === "Invalid Token")
                            {
                                sessionStorage.clear();
                                warning(
                                  "Token Expired, Login/Verify again",
                                  warningNotification
                              );
                              window.location.replace("/login");
                            }else if(res['data'].reasonCode === "SM-02"){
                                sessionStorage.clear();
                                warning(
                                  "Token Expired, Login/Verify again",
                                  warningNotification
                              );
                              window.location.replace("/login");
                              }
                              else if(res['data'].reasonCode === "SM-10"){
                                sessionStorage.clear();
                                warning(
                                  "Token Expired, Login/Verify again",
                                  warningNotification
                              );
                              window.location.replace("/login");
                              }
                            else
                            {
                              warning(
                                "Something went wrong!" + res['data'].reasonText,
                                warningNotification
                            );
                            }
                        }
                        else
                        {
                            if (res.headers["token"] != null) {
                                sessionStorage.setItem("jwt-token", res.headers.token);
                              }
                          fn(res);
                        }
                    }
                }
            );
    //     }
    // }, fnError);
}


// Need to commit
function convertHTML(htmlFile, userId, fn, fnError) {
   // alert("in HTML service")
    // loginService.TokenUser((res) => {
    //     if (res.status === "success") {
    //         sessionStorage.setItem("jwt-token", res.responseObject);
         //   var url = "http://192.168.10.222:9054/pp-payersaccount-service/paymentreceipt/api/v1/_convert"
         var url = Global_var.BASEURL + Global_var.URL_RECEIPT_CONVERT_DEAILS
            return new RestDataSource(url, userId, fn).Store(
                htmlFile,
                (res) => {
                    if (res != null) {
                        if(res['data'].status === "fail" )
                        {
                            if(res['data'].reasonText === "Invalid Token")
                            {
                                sessionStorage.clear();
                                warning(
                                  "Token Expired, Login/Verify again",
                                  warningNotification
                              );
                              window.location.replace("/login");
                            }else if(res['data'].reasonCode === "SM-02"){
                                sessionStorage.clear();
                                warning(
                                  "Token Expired, Login/Verify again",
                                  warningNotification
                              );
                              window.location.replace("/login");
                              }
                              else if(res['data'].reasonCode === "SM-10"){
                                sessionStorage.clear();
                                warning(
                                  "Token Expired, Login/Verify again",
                                  warningNotification
                              );
                              window.location.replace("/login");
                              }
                            else
                            {
                              warning(
                                "Something went wrong!" + res['data'].reasonText,
                                warningNotification
                            );
                            }
                        }
                        else
                        {
                            if (res.headers["token"] != null) {
                                sessionStorage.setItem("jwt-token", res.headers.token);
                              }
                          fn(res);
                        }
                    }
                }
            );
    //     }
    // }, fnError);
}



// Check Receipt
function checkReceipt(downloadInfo, userId, fn, fnError) {
    // alert("in get service")
    // loginService.TokenUser((res) => {
    //     if (res.status === "success") {
    //         sessionStorage.setItem("jwt-token", res.responseObject);
            //var url = "http://192.168.12.58:8019/downloadPdf"
			 // var url = Global_var.URL_CHECK_RECEIPT_STATUS;
              var url = Global_var.BASEURL + Global_var.URL_CHECK_RECEIPT_STATUS
            return new RestDataSource(url, userId, fn).Store(
                downloadInfo,
                (res) => {
                    if (res != null) {
                        if(res['data'].status === "fail" )
                        {
                            if(res['data'].reasonText === "Invalid Token")
                            {
                                sessionStorage.clear();
                                warning(
                                  "Token Expired, Login/Verify again",
                                  warningNotification
                              );
                              window.location.replace("/login");
                            }else if(res['data'].reasonCode === "SM-02"){
                                sessionStorage.clear();
                                warning(
                                  "Token Expired, Login/Verify again",
                                  warningNotification
                              );
                              window.location.replace("/login");
                              }
                              else if(res['data'].reasonCode === "SM-10"){
                                sessionStorage.clear();
                                warning(
                                  "Token Expired, Login/Verify again",
                                  warningNotification
                              );
                              window.location.replace("/login");
                              }
                            else
                            {
                              warning(
                                "Something went wrong!" + res['data'].reasonText,
                                warningNotification
                            );
                            }
                        }
                        else
                        {
                            if (res.headers["token"] != null) {
                                sessionStorage.setItem("jwt-token", res.headers.token);
                              }
                          fn(res);
                        }
                    }
                }
            );
    //     }
    // }, fnError);
}

