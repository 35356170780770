import { paymentSuccessService } from "../services/paymentSuccessService";
export const FETCH_TRANSACTION_DATA_SUCCESS = "FETCH_TRANSACTION_DATA_SUCCESS";
export const FETCH_TRANSACTION_DATA_PENDING = "FETCH_TRANSACTION_DATA_PENDING";

export function fetchTransactionDataSuccess(paymentData) {
  return {
    type: FETCH_TRANSACTION_DATA_SUCCESS,
    payload: paymentData,
  };
}
export function fetchTransactionDatapending() {
  return {
    type: FETCH_TRANSACTION_DATA_PENDING,
  };
}
export function fetchTransactionData(userId, paymentInfo) {
  return (dispatch) => {
    dispatch(fetchTransactionDatapending());
    paymentSuccessService.getPaymentSuccessDetails(userId, paymentInfo, (res) => {
      sessionStorage.setItem("htmltrackerid", res.data?.receiptDetail?.htmlTrackerId);
      dispatch(fetchTransactionDataSuccess(res.data));
    });
  };
}
