import { identifyPayerService } from "../services/identifyPayerService";
export const FETCH_IDENTIFY_PAYER_DETAILS_SUCCESS = "FETCH_IDENTIFY_PAYER_DETAILS_SUCCESS";
export const FETCH_IDENTIFY_PAYER_DETAILS_PENDING = "FETCH_IDENTIFY_PAYER_DETAILS_PENDING";

export const ADD_IDENTIFY_PAYER = "ADD_IDENTIFY_PAYER";

export function fetchIdentifyPayerSuccess(identifyPayerData) {
  debugger;
  return {
    type: FETCH_IDENTIFY_PAYER_DETAILS_SUCCESS,
    payload: identifyPayerData,
  };
}
export function fetchIdentifyPayerpending() {
  debugger;
  return {
    type: FETCH_IDENTIFY_PAYER_DETAILS_PENDING,
  };
}
export function fetchIdentifyPayerData(identifyPayerInfo, userId) {
  return (dispatch) => {
    dispatch(fetchIdentifyPayerpending());
    identifyPayerService.getIdentifyPayerList(identifyPayerInfo, userId, (res) => {
      dispatch(fetchIdentifyPayerSuccess(res.data.responseListObject));
    });
  };
}
export function addIdentifyPayerData(identifyPayerBody, userId, fn) {
  debugger;
  return (dispatch) => {
    dispatch({
      type: ADD_IDENTIFY_PAYER,
      payload: identifyPayerBody,
    });
    identifyPayerService.addIdentifyPayer(identifyPayerBody, userId, (res) => fn(res));
  };
}
