import { patientService } from "../services/patientDetalService";

export const FETCH_PATIENT_DETAILS_SUCCESS = "FETCH_PATIENT_DETAILS_SUCCESS";
export const FETCH_PATIENT_DETAILS_PENDING = "FETCH_PATIENT_DETAILS_PENDING";
export const SEARCH_PATIENT = "SEARCH_PATIENT";

export function fetchPatientDetailSuccess(patientData) {
  return {
    type: FETCH_PATIENT_DETAILS_SUCCESS,
    payload: patientData,
  };
}
export function fetchPatientpending() {
  return {
    type: FETCH_PATIENT_DETAILS_PENDING,
  };
}
export function fetchPatientDetailData(patientData, userId) {
  return (dispatch) => {
    dispatch(fetchPatientpending());
    patientService.getPatientList(patientData, userId, (res) => {
      dispatch(fetchPatientDetailSuccess(res.data.bills));

      return res.data.bills;
    });
  };
}

export function searchPatient(patientData, fn) {
  return (dispatch) => {
    dispatch(fetchPatientpending());
    patientService.searchPatient(patientData, (res) => fn(res));
  };
}
