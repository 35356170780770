import React, { createRef, useEffect, useState, useRef } from "react";
import MaterialTable from "material-table";
import { tableIcons } from "../../../../../components/TableIcons";
import { useDispatch } from "react-redux";
import { handleError } from "../../../../../components/Notifications/Toast";
import PatchedPagination from "../../../../../Common/TablePagination";
import { getPaymentPlansOptionsDataById } from "../../../../../action/ClientManagementAction";
import { httpPaginationRequest } from "../../../../../Common/httpPaginationRequest";
import { Global_var } from "../../../../../global/new _admin_globalvar";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const PaymentPlanOptionsTable = ({
  searchData,
  setIsUpdate,
  dollarFormat,
  setIsLoading,
  isSubmit,
  isUpdate,
  setIsEdit,
  setPaymentPlansOptionsId,
  setDeletePlanData,
  setIsDelete,
  setIsSubmit,
  setSubBuid,
  setDialogOpen,
  setPaymentPlansOptPopUpData,
}) => {
  const tableRef = createRef();
  const [arrayLast, setArrayLast] = useState(false);
  const [rows, setRows] = useState(5);
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Hospital Name",
      field: "buName",
    },
    {
      title: "Hospital Code",
      field: "buCode",
    },
    {
      title: "Program Length (Days)",
      field: "programLength",
      type: "numeric",
      render: (rowData) => (rowData?.programLength ? rowData?.programLength : "-"),
    },
    {
      title: "Plan Length (Days)",
      field: "planLength",
      type: "numeric",
      render: (rowData) => (rowData?.planLength ? rowData?.planLength : "-"),
    },
    {
      title: "Min Payment",
      field: "minPayment",
      type: "numeric",
      render: (rowData) => (rowData.minPayment || rowData.minPayment === 0 ? dollarFormat(rowData.minPayment) : "-"),
    },
    {
      title: "Min Balance",
      field: "minBalance",
      type: "numeric",

      render: (rowData) => (rowData.minBalance || rowData.minBalance === 0 ? dollarFormat(rowData.minBalance) : "-"),
    },
    {
      title: "Max Balance",
      field: "maximumBalance",
      type: "numeric",

      render: (rowData) =>
        rowData.maximumBalance || rowData.maximumBalance === 0 ? dollarFormat(rowData.maximumBalance) : "-",
    },
  ];

  useEffect(() => {
    if (tableRef?.current && isSubmit) {
      if (searchData && Object.keys(searchData).length > 0) {
        tableRef.current.onQueryChange();
      }
    }
  }, [isSubmit, searchData]);
  useEffect(() => {
    if (tableRef?.current && isUpdate) {
      tableRef.current.onQueryChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate]);
  return (
    <div className="payplan-table">
      <MaterialTable
        title=""
        localization={{
          pagination: {
            firstAriaLabel: "First Page",
            previousAriaLabel: "Previous Page",
            nextAriaLabel: "Next Page",
            lastAriaLabel: "Last Page",
          },
        }}
        columns={columns}
        icons={tableIcons}
        tableRef={tableRef}
        data={(query) =>
          new Promise((resolve) => {
            const bodyObj = {
              limit: query.pageSize ? query.pageSize : null,
              orderBy: "",
              orderDirection: "",
              page:
                isSubmit || isUpdate
                  ? 0
                  : arrayLast && query?.page >= 0
                  ? query.page
                  : arrayLast
                  ? (query.page <= 0 && query.page + 1) || query.page - 1
                  : query.page,
              hospitalCode: searchData?.hospitalCode?.code || "",
            };
            httpPaginationRequest(Global_var.URL_GET_PAYMENTPLAN_OPTIONS, bodyObj, (res) => {
              // setIsSubmit(false);
              setIsUpdate(false);
              if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
                const data = res?.data?.resultObjects;
                resolve({
                  data: data.length > 0 ? data : [],
                  page: res.data.page,
                  totalCount: res?.data?.count,
                });
                setArrayLast(data.length === 1);
              } else if (res.data?.responseInfo?.status === "FAILED") {
                handleError(res?.data?.responseInfo?.responseMessage[0]);
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 5,
                });
              } else {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 5,
                });
                dispatch({
                  type: "FETCH_ERROR",
                  payload: res && res.message ? res.message : null,
                });
              }
            });
          })
        }
        actions={[
          {
            icon: () => (
              <>
                <EditIcon className="paymentplan-icons" />
              </>
            ),
            tooltip: "Edit",
            onClick: (event, rowData) => {
              setIsLoading(true);
              dispatch(
                getPaymentPlansOptionsDataById(rowData?.paymentPlanOptionsId, (res) => {
                  setIsLoading(false);
                  setIsEdit(true);
                  setSubBuid(rowData?.subBuId);
                  if (res?.status === 200) {
                    setDialogOpen(true);
                    setPaymentPlansOptPopUpData(res.data);
                  } else {
                    handleError("Something went wrong");
                  }
                }),
              );
            },
          },
          {
            icon: () => (
              <>
                <DeleteIcon className="paymentplan-icons" />
              </>
            ),
            tooltip: "Delete",
            onClick: (event, rowData) => {
              setIsDelete(true);
              setDeletePlanData(rowData);
              setPaymentPlansOptionsId(rowData?.paymentPlanOptionsId);
            },
          },
        ]}
        options={{
          selection: false,
          search: false,
          emptyRowsWhenPaging: false,
          sorting: false,
          pageSize: rows,
          pageSizeOptions: [5, 10, 20],
        }}
        onChangeRowsPerPage={(params) => {
          setRows(params);
        }}
        components={{
          Pagination: PatchedPagination,
        }}
      />
    </div>
  );
};

export default PaymentPlanOptionsTable;
