/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExapandMoreIcon from "@material-ui/icons/ExpandMore";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import WarningIcon from "@material-ui/icons/Warning";
import { Table, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { capitalize } from "../../../../util/Captilize";
import Tooltip from "@mui/material/Tooltip";
import { error, warning, warningNotification } from "../../../../components/PP_Notification/PP_Notification";
import { getCreditCardDataList } from "../../../../reducer/pp_PaymentCreditCardReducer";
import { geteChequePaymentList } from "../../../../reducer/pp_eChequePaymentReducer";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchCreditCardData } from "../../../../action/pp_PaymentCreditCardAction";
import { fetchEchaqueData } from "../../../../action/pp_eChequePaymentAction";
import $ from "jquery";
import { requestInfo_PP } from "../../../../Common/CommonFn";
import { OutstandingPayerService } from "../../../../services/payerOutstandingService";
import { payplanService } from "../../../../services/payplanService";
import { Formik, Form, Field, getIn } from "formik";
import { Translation } from "react-i18next";
import SpinnerPage from "../../../../Common/PP_loader";
import { Oval } from "react-loader-spinner";
import Stepper from "../Stepper/QuickPayStepper";
import HeaderQP from "../../../../components/admin_header/headerQP";
import { handleWarning, ToastContainer, handleError } from "../../../../components/Notifications/Toast";
import { FormHelperText } from "@material-ui/core";
import * as Yup from "yup";
import { ToastMsg } from "../../../../locales/en/toastmessages";
import ConfirmationDialog from "../../../../components/ConfirmationDialog/index";
import MinimumBalancePlan from "../../../../containers/Admin_Report/PayPlanOptions/MinimumBalancePlan";
import Admin_Footer from "../../../../components/admin_footer/footer";
import { CreateCampaignView, CreatePortalUsage } from "../../../../util/purlFunctions";
import UpdateTrackingPage from "../../../../containers/UpdateTrackingPage";

import { settlementService } from "../../../../services/settlementServices";
import moment from "moment";
import DialogSettlementDetails from "../../../../components/ReusableComponents/Dialog";
import PaymentConfirmationDialog from "../../Billing/PaymentComformantionDialog";

const contactus = {
  cursor: "pointer",
  textDecoration: "underline",
};
const payAmountInput = {
  width: "85px",
  padding: "2px 5px",
  outline: "none",
};
const isUser = localStorage.getItem("role") === "user";
const isPA = localStorage.getItem("role") === "PA";
const mapStateToProps = (state) => ({
  creditCardData: getCreditCardDataList(state.paymentCreditCardReducer),
  eChequeData: geteChequePaymentList(state.eChequePaymentReducer),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCreditCardData: fetchCreditCardData,
      fetchEchaqueData: fetchEchaqueData,
    },
    dispatch,
  );
const connectFunction = connect(mapStateToProps, mapDispatchToProps);
const HomeBilling = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        paymentMethod: "none",
        billPaymentMethod: "",
        otherAmount: "0.00",
        open: false,
        hospital: "",
        selectedAmount: null,
        feesWaived: "7.00",
        finalAmount: 0,
        newbillingdata: [],
        closedBillingData: null,
        allBills: [],
        click: "",
        accountNumber: 1286754345,
        dueDate: "Past Due",
        outstandingAmount: 1500,
        paymentAmount: "",
        paymentAmount2: "",
        paymentAmount3: "",
        outstandingAmount2: 1000,
        outstandingAmount3: 500,
        remainingData: "",
        remainingData2: "",
        showForm: true,
        no_of_acs: 0,
        showloader: true,
        SocialSecurityNumber: "***-**-1120",
        totalAmount: 0.0,
        grandTotal: 0.0,
        fees: 0.0,
        accountname: "",
        showcreditcard: true,
        showecheck: true,
        amtdistributeflag: false,
        paymentmethod: "",
        billamtarray: [],
        allowpartial: false,
        showpartialerror: false,
        showpartialerrorfifty: false,
        alertBox: false,
        customCardData: { cardNumber: "", type: "" },
        dialogopen: false,
        tablePaymentAmount: 0,
        paymentAmtArr: [],

        seedAccountNumberList: null,
        settleOpen: false,
        settlementOptionList: null,
        settleDetails: false,
        settleReject: false,
        settleRevised: false,
        settlePending: false,
        passPaymentMethod: null,
        settlementData: [],
        DialogData: [],
        currentSeedItem: {},
        restrictedBills: [],
        settledBills: [],
        paymentConfirmationAlert: false,
      };
    }
    handlePayAmt = (event) => {
      if (this.state.paymentAmtArr?.length < 2) {
        this.setState({
          paymentAmtArr: [...this.state.paymentAmtArr, Number(event.target.value)],
        });
      } else {
        if (this.state.paymentAmtArr[0] !== Number(event.target.value)) {
          this.setState({
            paymentAmtArr: [...this.state.paymentAmtArr, this.state.paymentAmtArr.splice(0, 1)],
          });
          this.setState({
            paymentAmtArr: [...this.state.paymentAmtArr, Number(event.target.value)],
          });
        } else if (this.state.paymentAmtArr[1] !== Number(event.target.value)) {
          this.setState({
            paymentAmtArr: [...this.state.paymentAmtArr, this.state.paymentAmtArr.shift()],
          });
          this.setState({
            paymentAmtArr: [...this.state.paymentAmtArr, Number(event.target.value)],
          });
        }
      }
    };
    handleAmountChange = (event) => {
      this.setState({ selectedAmount: event.target.value });
    };
    handleOtherAmountChange = (event) => {
      this.setState({ otherAmount: event.target.value });
    };
    handleBlur = (event) => {
      this.setState({ finalAmount: event.target.value });
    };
    handleInputFessWaived = (event) => {
      this.setState({ feesWaived: event.target.value });
    };
    handleClickOpen = (hospitalName) => {
      if (hospitalName === "Community Health Network" && (isUser || isPA)) {
        this.setState({ hospital: hospitalName });
        this.setState({ open: true });
      }
    };
    handlePayPlanOptions = (seedAccountNumber) => {
      this.setState({ showloader: true });
      let PassValues = {
        requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        seedAccountNumber: Number(seedAccountNumber),
      };
      payplanService.getPayPlan(
        PassValues,
        1212,
        (res) => {
          if (res?.data?.responseInfo?.status === "SUCCESSFUL" && res?.data?.eligible === "true") {
            this.setState({ showloader: false });
            this.props.history.push({
              pathname: `/pay-plan-options`,
              search: `?${seedAccountNumber}`,
              state: { page: "QuickpayDB" },
            });
          } else if (res?.data?.eligible === "false") {
            this.setState({ showloader: false });
            this.setState({ dialogopen: true });
          } else {
            this.setState({ showloader: false });

            handleWarning(ToastMsg?.billSummary_errorMsgSomethingWrong);
          }
        },
        (error) => {
          handleError("Something went wrong! Please try again");
          this.setState({ showloader: false });
        },
      );
    };
    handleClose = () => {
      this.setState({ dialogopen: false });
    };
    getEchecqueListData = (data) => {
      return (
        data &&
        data.achAccount &&
        data.achAccount.length > 0 &&
        data.achAccount.map((item, index) => {
          return (
            <option
              key={index}
              value={`echeque&${item.accountNumber}?${item.accountType}/${item.bankAchRoutingCode}$${item.paymentInstrument?.paymentInstrumentId}`}>
              echeck ending with{" "}
              {item.accountNumber.substring(item.accountNumber.toString().length - 4, item.accountNumber.toString().length)}
            </option>
          );
        })
      );
    };
    getCardListData = (data) => {
      return (
        data &&
        data?.cardAccount?.length > 0 &&
        data?.cardAccount?.map((item, index) => {
          if (item.cardType === "Visa") {
            return (
              <option value={`${item.cardNumber}?${item.cardExpiryMonth}/${item.cardExpiryYear}`} key={index}>
                Visa Card ending with{" "}
                {item.cardNumber.substring(item.cardNumber.toString().length - 4, item.cardNumber.toString().length)}
              </option>
            );
          } else if (item.cardType === "Master Card") {
            return (
              <option value={`${item.cardNumber}?${item.cardExpiryMonth}/${item.cardExpiryYear}`} key={index}>
                Master Card ending with{" "}
                {item.cardNumber.substring(item.cardNumber.toString().length - 4, item.cardNumber.toString().length)}
              </option>
            );
          } else if (item.cardType === "American Express") {
            return (
              <option value={`${item.cardNumber}?${item.cardExpiryMonth}/${item.cardExpiryYear}`} key={index}>
                American Express Card ending with{" "}
                {item.cardNumber.substring(item.cardNumber.toString().length - 4, item.cardNumber.toString().length)}
              </option>
            );
          } else {
            return "";
          }
        })
      );
    };
    _payInFuture = (values) => {
      if (sessionStorage.getItem("partialpaymentflag") === "true") {
        var apportioned = Number(sessionStorage.getItem("apportionedamount"));

        if (values.payAmount === "" || values.payAmount == "0.00" || Number(values.payAmount) <= 0) {
          handleWarning(<>{ToastMsg.paymentSummary_warningotherAmtAtLeast}</>);
        } else if (Number(values.payAmount) <= 49.99) {
          handleWarning(<>{ToastMsg.paymentSummary_warningotherAmtAtLeast}</>);
        } else if (apportioned <= "49.99" && this.state.allowpartial === false) {
          handleWarning(<>{ToastMsg.paymentSummary_warningotherAmtAtLeast}</>);
        } else if (
          Number(sessionStorage.getItem("amounttopay")).toFixed(2) !==
            Number(sessionStorage.getItem("apportionedamount")).toFixed(2) &&
          this.state.totalAmount > 50
        ) {
        } else if (
          this.state.showpartialerrorfifty === true &&
          Number(sessionStorage.getItem("amounttopay")) < 50 &&
          this.state.totalAmount > 50
        ) {
        } else {
          function parcelSetInterval() {
            let a = setInterval(() => {
              var order_FP_data = JSON.parse(sessionStorage.getItem("order_FP"));
              var order_FP_orderdetails = sessionStorage.getItem("accountdistribution_changeddata_sum")
                ? sessionStorage.getItem("accountdistribution_changeddata_sum")
                : sessionStorage.getItem("accountdistribution");

              var amtdistdata = JSON.parse(order_FP_orderdetails);

              var amtdata = this.state.billamtarray;
              var errorarray = [];

              for (let i = 0; i < amtdistdata.length; i++) {
                if (
                  amtdata.includes(Number(amtdistdata[i].totalBillAmount)) &&
                  Number(amtdistdata[i].totalBillAmount) < 50
                ) {
                  if (Number(amtdistdata[i].totalBillAmount) === Number(amtdistdata[i].expectedPaymentAmount)) {
                    this.setState({
                      showpartialerror: false,
                    });
                    errorarray.push(false);
                  } else {
                    this.setState({
                      showpartialerror: true,
                    });
                    errorarray.push(true);
                  }
                } else {
                  this.setState({
                    showpartialerror: true,
                  });
                  errorarray.push(true);
                }
              }

              var errflag = errorarray.includes(true);

              if (apportioned <= "49.99" && errflag == true && this.state.totalAmount > 50) {
                return;
              }

              var count = order_FP_data.length - 1;
              var userId = "1234";

              var userinfo = JSON.parse(sessionStorage.getItem("verifypayer"));

              var PassValues = {
                requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
                paymentOrders: [
                  {
                    contactPartyId: order_FP_data[count].contactPartyId,
                    payerPartyId: this.props.partyId,
                    paymentOrderCurrencyId: order_FP_data[count].paymentOrderCurrencyId,
                    paymentOrderAmount: sessionStorage.getItem("amounttopay"),
                    totalTransactionAmount: sessionStorage.getItem("amounttopay"),
                    feesAmount: "7",
                    payerAccountId: order_FP_data[count].payerAccountId,
                    billId: order_FP_data[count].billId,

                    paymentOrderStatus: "1", // need to check for now 1
                    orderChannel: "1", //   credit / echeque ?
                    isActive: "1", // 1 by default
                    paymentOrderDetails: JSON.parse(order_FP_orderdetails),
                    paymentSms: null,
                    paymentEmail: null,
                    reminderEmail: null,
                    reminderSms: null,
                  },
                ],
              };

              var userId = "1234";
              if (values.paymentMethod === "echeck" || values.paymentMethod.includes("&")) {
                clearInterval(a);
              } else {
                clearInterval(a);
              }
              values.paymentMethod === "echeck" || values.paymentMethod.includes("&")
                ? this?.props?.history?.push({
                    pathname: "/payment-echeck",
                    state: {
                      paymentMethod: values.paymentMethod,
                      payInFuture: true,
                      PassValues,
                    },
                  })
                : this?.props?.history?.push({
                    pathname: "/payment-CreditCard",
                    state: {
                      paymentMethod: values.paymentMethod,
                      payInFuture: true,
                      PassValues,
                    },
                  });
            }, 2000);
          }
          parcelSetInterval.call(this);
        }
      } else {
        var totalamt = sessionStorage.getItem("TotalAmount");
        sessionStorage.setItem("amounttopay", totalamt);

        if (sessionStorage.getItem("amounttopay") > this.state.totalAmount) {
        } else if (this.state.totalAmount <= 0 || sessionStorage.getItem("amounttopay") <= 0) {
        } else {
          var order_FP_data = JSON.parse(sessionStorage.getItem("order_FP"));
          var order_FP_orderdetails = sessionStorage.getItem("orderdetails_FP");

          var count = order_FP_data.length - 1;

          var userinfo = JSON.parse(sessionStorage.getItem("verifypayer"));

          var PassValues = {
            requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
            paymentOrders: [
              {
                contactPartyId: order_FP_data[count].contactPartyId,
                payerPartyId: this.props.partyId,
                paymentOrderCurrencyId: order_FP_data[count].paymentOrderCurrencyId,
                paymentOrderAmount: sessionStorage.getItem("amounttopay"),
                totalTransactionAmount: sessionStorage.getItem("amounttopay"),
                feesAmount: "7",
                payerAccountId: order_FP_data[count].payerAccountId,
                billId: order_FP_data[count].billId,
                paymentOrderStatus: "1", // need to check for now 1
                orderChannel: "1", //   credit / echeque ?
                isActive: "1", // 1 by default
                paymentOrderDetails: JSON.parse(order_FP_orderdetails),
              },
            ],
          };

          var userId = "1234";
          values.paymentMethod === "echeck" || values.paymentMethod.includes("&")
            ? this?.props?.history?.push({
                pathname: "/payment-echeck",
                state: {
                  paymentMethod: values.paymentMethod,
                  payInFuture: true,
                  PassValues,
                },
              })
            : this?.props?.history?.push({
                pathname: "/payment-CreditCard",
                state: {
                  paymentMethod: values.paymentMethod,
                  payInFuture: true,
                  PassValues,
                },
              });
        }
      }
    };

    purlTrackingUser() {
      // const accRefNo = this.state.masterAccNo;
      const accRefNo = JSON.parse(sessionStorage.getItem("payerinfo"))?.accountReferenceNumber;
      const partyId = JSON.parse(sessionStorage.getItem("verifypayer")).partyId;
      const campaignCode = sessionStorage.getItem("campaignCode");
      const accountRefAutologin = JSON.parse(sessionStorage.getItem("accountReferenceNumber"));
      if (accRefNo || accountRefAutologin) {
        const passValuesCPU = {
          accessMode: "QUICK PAY", //Mandatory
          campaignCode: campaignCode || "", //If Purl then it's mandatory
          lastPageAccessed: "PaymentOutstanding", //Mandatory
          medAccountNumber: accRefNo || accountRefAutologin, //Mandatory
          partyId: partyId, //Mandatory
          portalUsageId: sessionStorage.getItem("portalUsageId") ? JSON.parse(sessionStorage.getItem("portalUsageId")) : 0, //It should be 0
        };
        CreatePortalUsage(passValuesCPU, (res) => {
          if (res.data.responseInfo.status === "SUCCESSFUL") {
            !sessionStorage.getItem("portalUsageId") &&
              sessionStorage.setItem("portalUsageId", res.data.portalUsage.portalUsageId);
            if (accRefNo || accountRefAutologin) {
              const passValuesCCV = {
                campaignCode: campaignCode || "",
                campaignViewId: sessionStorage.getItem("campaignViewId")
                  ? JSON.parse(sessionStorage.getItem("campaignViewId"))
                  : 0,
                medAccountNumber: accRefNo || accountRefAutologin,
                paidAmount: 0,
                partyId: partyId,
                payPlanAdded: "",
                paymentComplete: "",
                purlViewCount: 0,
              };
              CreateCampaignView(passValuesCCV, (res) => {
                !sessionStorage.getItem("campaignViewId") &&
                  sessionStorage.setItem("campaignViewId", res.data.compaignView.campaignViewId);
                this.setState({ isCalledPurl: true });
              });
            }
          }
        });
      }
    }
    componentDidMount() {
      sessionStorage.setItem("currentUserRole", "quickpay");
      $(".amount3.grand-amt1 span.amount1").hide();
      // document.title = "MED-1 Quick Pay - Bill Details"
      // GoogleAnalystics.GoogleAnalysticsLog("pageview");

      sessionStorage.removeItem("apportionedamount");
      sessionStorage.setItem("partialpaymentflag", true);
      if (sessionStorage.getItem("payerinfo") !== null) {
        var userinfo = JSON.parse(sessionStorage.getItem("payerinfo"));
        this.setState({
          SocialSecurityNumber: userinfo?.ssnNumber,
        });
      }
      sessionStorage.setItem("amounttopay", Number(this.state.totalAmount + this.state.fees));
      sessionStorage.setItem("underQuickPay", true);

      // Get Fees
      const partyids = JSON.parse(sessionStorage.getItem("verifypayer"));
      var partyid = partyids?.partyId;
      var contactpartyid = partyids?.partyTypeId;

      const feesValues = {
        requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        feeSearch: {
          feeSetupId: "",
          payerPartyId: partyid,
          stateId: null,
          isActive: [1],
        },
      };
      var userId = "1212";
      OutstandingPayerService.PP_Get_Fees(feesValues, userId, (res) => {
        if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
        }
      });

      // Get fees End

      userId = "1212";
      if (sessionStorage.getItem("payerinfo") !== null) {
        var userinfo = JSON.parse(sessionStorage.getItem("verifypayer"));

        var masteacno = "";

        const passValues = {
          requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
          partyId: userinfo?.partyId,
          isActive: [1],
        };

        OutstandingPayerService.GetOutstandingPayerdata(passValues, userId, (res) => {
          // alert("API CALLED")

          if (res["data"]?.responseInfo.status === "SUCCESSFUL") {
            this.purlTrackingUser(res?.data?.payer.uniqueIdentifierId);
            let completeBills = [];
            res?.data?.billGroups.map((ele, ind) => {
              let tempArr = ele?.bills;

              if (ind === 0) {
                completeBills = tempArr;
              } else {
                completeBills = [...completeBills, ...tempArr];
              }
            });

            let tempSeedAccountNumberList = (res.data.billGroups || []).map(({ seedAccountNumber }) => seedAccountNumber);
            let passValues11 = {
              requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token")),
              settlementRequest: {
                accountNumberList: tempSeedAccountNumberList,
              },
            };
            settlementService.getSettlementOptionStatus(passValues11, 0, (res) => {
              this.setState({
                settlementData: res?.data,
              });
            });

            this.setState({
              newbillingdata: res?.data?.billGroups,
              closedBillingData: res?.data?.closedBills,
              restrictedBills: res?.data?.restrictedBills,
              settledBills: res?.data?.settledBills,
              billinginformation: completeBills,
              billinginfosummary: completeBills,
              seedAccountNumberList: tempSeedAccountNumberList,
            });
            sessionStorage.setItem("billingdata", completeBills);
            sessionStorage.setItem("billingpayerdata", JSON.stringify(JSON.stringify(completeBills)));

            let partyId = res["data"].billGroups[0].bills[0].payer.partyId;

            let CardPassValues = {
              requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
              paymentInfoSearch: {
                paymentMethodId: "75001",
                partyId: partyId,
              },
            };

            let EChequePassValues = {
              requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
              paymentInfoSearch: {
                paymentMethodId: 75003,
                partyId: partyId,
              },
            };

            const { fetchCreditCardData, fetchEchaqueData } = this.props;
            fetchCreditCardData(CardPassValues, 1234);
            fetchEchaqueData(EChequePassValues, 1234);

            var fulldistribution = [];

            var rowjsonObj = [];
            var rowjsonitem_order = [];
            var rowjsonorderdetails = [];
            var partialdistribution = [];

            var tempamt = [];
            if (this.state.billinginfosummary) {
              let data = this.state.billinginfosummary;
              var billingdata = data;
              var totalAmount = 0;
              var billinginfosummary = [];
              var temp = [];
              billingdata.map((billitem, index) => {
                if (!this.state.alertBox && billitem.billType == "Legal") {
                  this.setState({ alertBox: true });
                }
                let temp1 = [];
                temp1["accountno"] = billitem.accountReferenceNumber;
                temp1["duedata"] = billitem.billDate;
                temp1["outstandingamt"] = billitem.currentBalanceAmount;
                temp1["ssnNumber"] = billitem.payer.uniqueIdentifierId;
                temp1["firstName"] = billitem.payer.firstName;
                temp1["lastName"] = billitem.payer.lastName;

                totalAmount += Number(billitem.currentBalanceAmount);
                temp.push(temp1);

                let item = {};
                let item_order = {};
                let item_orderdetails = {};
                item["acno"] = billitem.accountReferenceNumber;
                item["outstanding"] = billitem.currentBalanceAmount;
                item["paidamount"] = billitem.currentBalanceAmount;
                item["ssnNumber"] = billitem.payer.uniqueIdentifierId;
                item["firstName"] = billitem.payer.firstName;
                item["lastName"] = billitem.payer.lastName;
                item["otherCharges"] = billitem.otherCharges;
                item["tax"] = billitem.taxAmount;
                item["billType"] = billitem.billType;
                item["inPayPlan"] = billitem.inPayPlan;
                item_order["contactPartyId"] = billitem.contactPartyId;
                item_order["partyId"] = billitem.payer.partyId;
                item_order["paymentOrderCurrencyId"] = billitem.billCurrency;
                item_order["paymentOrderAmount"] = totalAmount;
                item_order["payerAccountId"] = billitem.payerAccountId !== null ? billitem.payerAccountId : 2;
                item_order["billId"] = "";
                item_order["paymentOrderStatus"] = "1";
                item_order["orderChannel"] = "1";
                item_order["isActive"] = "1";

                item_orderdetails["mappedBillId"] = billitem.billId;
                item_orderdetails["billAmount"] = billitem.currentBalanceAmount;

                var bill_date = billitem.billDate.split(" ")[0];
                var billtempdate = bill_date.split("/");

                item_orderdetails["billDate"] = billitem.billDate;
                item_orderdetails["billType"] = billitem.billType;
                item_orderdetails["inPayPlan"] = billitem.inPayPlan;
                item_orderdetails["otherCharges"] = billitem.otherCharges;
                item_orderdetails["tax"] = billitem.taxAmount;
                item_orderdetails["totalBillAmount"] = billitem.currentBalanceAmount;
                item_orderdetails["currencyId"] = billitem.billCurrency;
                item_orderdetails["expectedPaymentAmount"] = billitem.currentBalanceAmount;
                item_orderdetails["isActive"] = "1";

                // Generate bill amount array
                tempamt.push(Number(billitem.currentBalanceAmount));

                if (billitem.currentBalanceAmount < 50) {
                  this.setState({
                    allowpartial: true,
                  });
                }
                // Create Partial distribution JSON
                let distribute = {};
                distribute["billId"] = billitem.billId;
                distribute["accountReferenceNumber"] = billitem.accountReferenceNumber;
                distribute["totalAmount"] = billitem.currentBalanceAmount;
                distribute["billDate"] = billitem.billDate;
                distribute["billType"] = billitem.billType;
                distribute["inPayPlan"] = billitem.inPayPlan;
                // Create Partial distribution JSON

                rowjsonObj.push(item);
                rowjsonitem_order.push(item_order);

                if (Number(billitem.totalAmount) > 0) {
                  rowjsonorderdetails.push(item_orderdetails);
                }

                partialdistribution.push(distribute);
              });

              var temp2 = JSON.stringify(temp);

              sessionStorage.setItem("TotalAmount", Number(totalAmount) + this.state.fees);
              this.setState({
                totalAmount: totalAmount,
                grandTotal: Number(totalAmount) + Number(this.state.fees),
                showloader: false,
                billamtarray: tempamt,
              });

              sessionStorage.setItem("amounttopay", Number(totalAmount) + this.state.fees);
              sessionStorage.setItem("accountdistribution_FP", JSON.stringify(rowjsonObj));
              sessionStorage.setItem("order_FP", JSON.stringify(rowjsonitem_order));
              sessionStorage.setItem("orderdetails_FP", JSON.stringify(rowjsonorderdetails));
              sessionStorage.setItem("partialdistribution", JSON.stringify(partialdistribution));
            }

            if (this.state.billinginformation) {
              let data = this.state.billinginformation;
              var billingdata = data;
              var totalAmount = 0;
              var billinginformation = [];
              var temp = [];
              var lessthanmin = [];
              billingdata.map((billitem, index) => {
                let temp1 = [];
                temp1["accountno"] = billitem.accountReferenceNumber;
                temp1["duedata"] = billitem.billDate;
                temp1["outstandingamt"] = billitem.currentBalanceAmount;
                temp1["ssnnumber"] = billitem.payer.uniqueIdentifierId;
                temp1["firstName"] = billitem.payer.firstName;
                temp1["lastName"] = billitem.payer.lastName;
                temp1["billType"] = billitem.billType;
                temp1["inPayPlan"] = billitem.inPayPlan;
                temp1["mappedBillId"] = billitem.billId;
                temp1["billAmount"] = billitem.billAmount;
                temp1["billDate"] = billitem.billDate;
                temp1["otherCharges"] = billitem.otherCharges;
                temp1["tax"] = billitem.taxAmount;
                temp1["currencyId"] = billitem.billCurrency;
                temp1["expectedPaymentAmount"] = "";
                temp1["isActive"] = "1";

                totalAmount += billitem.currentBalanceAmount;
                temp.push(temp1);

                if (billitem.currentBalanceAmount > 50) {
                  lessthanmin.push(true);
                }

                // Create Partial distribution JSON
                let distribute = {};
                distribute["billId"] = billitem.billId;
                distribute["accountReferenceNumber"] = billitem.accountReferenceNumber;
                distribute["totalAmount"] = billitem.currentBalanceAmount;
                distribute["billDate"] = billitem.billDate;
                distribute["tax"] = billitem.taxAmount;
                distribute["otherCharges"] = billitem.otherCharges;
                distribute["billType"] = billitem.billType;
                distribute["inPayPlan"] = billitem.inPayPlan;
                // Create Partial distribution JSON
                fulldistribution.push(distribute);
              });

              var errflag = lessthanmin.includes(false);

              if (errflag === true) {
                this.setState({
                  showpartialerrorfifty: true,
                });
              }

              this.setState({
                billingdata: temp,
                no_of_acs: temp.length,
                showloader: false,
              });
              sessionStorage.setItem("fulldistribution", JSON.stringify(fulldistribution));
              if (sessionStorage.getItem("TotalAmount") <= 50) {
                this._payFullAmountDistribution();
              }
            }
          } else {
            this.setState({
              showloader: false,
            });

            if (res["data"]?.responseInfo?.responseMessage[0] == "Failed to Get due : No Bills Found") {
              $(".button-box .btn-primary").addClass("disable");
              $("#payAmount").hide();
              $(".form-group, .row.mb").addClass("disable");
              $(".button-box .btn-primary").one("click", function (event) {
                event.preventDefault();
              });
            } else {
            }
          }
        });
      }
      this.setState({
        SocialSecurityNumber: userinfo?.uniqueIdentifierId,
        accountname: userinfo?.firstName + " " + userinfo?.lastName,
      });

      var paymentmethods = JSON.parse(sessionStorage.getItem("paymentmethods"));

      var context = this;
      setTimeout(function () {
        // $(".amount3.grand-amt1 span.amount1").text("0.00");

        $(".amount3.grand-amt1 span.amount1").show();
        $(".amount3.grand-amt1 span.amount1").text("0.00");
      }, 4000);
    }

    _handleContinuePayment = (values) => {
      values.paymentMethod === "echeck" || values.paymentMethod.includes("&")
        ? this.props.history.push({
            pathname: "/payment-echeck",
            state: {
              paymentMethod: values.paymentMethod,
              payInFuture: false,
              PassValues: {},
              showecheck: this.state.showecheck,
              page: "quickPay",
            },
          })
        : this.props.history.push({
            pathname: "/payment-Creditcard",
            state: {
              paymentMethod: values.paymentMethod,
              payInFuture: false,
              PassValues: {},
              showcreditcard: this.state.showcreditcard,
              page: "quickPay",
            },
          });
    };

    _handleSubmit = (values) => {
      if (sessionStorage.getItem("partialpaymentflag") === "true") {
        var apportioned = Number(sessionStorage.getItem("apportionedamount"));

        if (values.payAmount === "" || values.payAmount == "0.00" || Number(values.payAmount) <= 0) {
          handleWarning(<>{ToastMsg.paymentSummary_warningotherAmtAtLeast}</>);
        } else if (apportioned <= "49.99" && this.state.allowpartial === false) {
          handleWarning(
            "Minimum payment should be at least $50.00 or pay the full payment amount amongst listed accounts appropriately to close accounts with balance less than $50",
          );
        } else if (
          Number(sessionStorage.getItem("amounttopay")).toFixed(2) !==
            Number(sessionStorage.getItem("apportionedamount")).toFixed(2) &&
          this.state.totalAmount > 50
        ) {
          warning(
            <Translation>{(t, { i18n }) => <label>{t("paymentSummary_warningApportionedPayment")}</label>}</Translation>,
            warningNotification,
          );
        } else if (this.state.showpartialerrorfifty === true && Number(sessionStorage.getItem("amounttopay")) < 50) {
          handleWarning(
            "Minimum payment should be at least $50.00 or pay the full payment amount amongst listed accounts appropriately to close accounts with balance less than $50",
          );
        } else {
          function parcelSetInterval(self) {
            let a = setInterval(() => {
              // if (self.state.payOtherAmountFlag === "success") {
              var order_FP_data = JSON.parse(sessionStorage.getItem("order_FP"));
              var order_FP_orderdetails = sessionStorage.getItem("accountdistribution_changeddata_sum")
                ? sessionStorage.getItem("accountdistribution_changeddata_sum")
                : sessionStorage.getItem("accountdistribution");

              var amtdistdata = JSON.parse(order_FP_orderdetails);

              var amtdata = self.state.billamtarray;
              var errorarray = [];

              for (let i = 0; i < amtdistdata.length; i++) {
                if (
                  amtdata.includes(Number(amtdistdata[i].totalBillAmount)) &&
                  Number(amtdistdata[i].totalBillAmount) < 50
                ) {
                  if (Number(amtdistdata[i].totalBillAmount) === Number(amtdistdata[i].expectedPaymentAmount)) {
                    self.setState({
                      showpartialerror: false,
                    });
                    errorarray.push(false);
                  } else {
                    self.setState({
                      showpartialerror: true,
                    });
                    errorarray.push(true);
                  }
                } else {
                  self.setState({
                    showpartialerror: true,
                  });
                  errorarray.push(true);
                }

                console.log(errorarray);
              }

              var errflag = errorarray.includes(true);

              if (apportioned <= "49.99" && errflag == true && self.state.totalAmount > 50) {
                handleWarning(
                  "Minimum payment should be at least $50.00 or pay the full payment amount amongst listed accounts appropriately to close accounts with balance less than $50",
                );
                return clearInterval(a);
              }

              var count = order_FP_data.length - 1;
              var userId = "1234";

              var userinfo = JSON.parse(sessionStorage.getItem("verifypayer"));

              var PassValues = {
                requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
                paymentOrders: [
                  {
                    contactPartyId: order_FP_data[count].contactPartyId,
                    payerPartyId: userinfo?.partyId,
                    paymentOrderCurrencyId: order_FP_data[count].paymentOrderCurrencyId,
                    paymentOrderAmount: sessionStorage.getItem("amounttopay"),
                    totalTransactionAmount: sessionStorage.getItem("amounttopay"),
                    feesAmount: "7",
                    payerAccountId: order_FP_data[count].payerAccountId,
                    billId: order_FP_data[count].billId,

                    paymentOrderStatus: "1", // need to check for now 1
                    orderChannel: "1", //   credit / echeque ?
                    isActive: "1", // 1 by default
                    paymentOrderDetails: JSON.parse(order_FP_orderdetails),
                  },
                ],
              };

              var userId = "1234";
              OutstandingPayerService.PP_Create_Payment_Order(PassValues, userId, (res) => {
                clearInterval(a);
                if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
                  self.setState({
                    ...self.state,
                    payOtherAmountFlag: "Reset",
                  });
                  if (sessionStorage.getItem("currentUserRole") !== "quickpay") {
                    sessionStorage.setItem("Ordernumber", res["data"].paymentOrders[0].paymentOrderId);
                    if (res["data"]?.displayAlert === "Y") {
                      self.setState({
                        ...self.state,
                        paymentConfirmationAlert: true,
                        showloader: false,
                      });
                      return;
                    } else {
                      values.paymentMethod === "echeck" ||
                      values.paymentMethod.includes("&") ||
                      values.paymentMethod.includes("echeck")
                        ? self.props.history.push({
                            pathname: "/payment-echeck",
                            state: { paymentMethod: values.paymentMethod, PassValues, page: "quickPay" },
                          })
                        : self.props.history.push({
                            pathname: "/payment-Creditcard",
                            state: { paymentMethod: values.paymentMethod, PassValues, page: "quickPay" },
                          });
                    }
                  } else {
                    let passValues = {
                      seedAccountNumber: self.state.seedAccountNumberList,
                      amountToBePaid: Number(sessionStorage.getItem("amounttopay")),
                      outstandingAmmount: Number(sessionStorage.getItem("TotalAmount")),
                    };
                    settlementService.getSettlementOptionInfo(
                      passValues,
                      0,
                      (settlementRes) => {
                        if (
                          settlementRes?.data?.responseInfo?.status === "SUCCESSFUL" &&
                          settlementRes?.data?.settlementOptionResponses?.length
                        ) {
                          sessionStorage.setItem("Ordernumber", res["data"].paymentOrders[0].paymentOrderId);
                          self.setState({
                            //...values,
                            settleOpen: true,
                            settlementOptionList: settlementRes?.data?.settlementOptionResponses,
                            passPaymentMethod: values.paymentMethod,
                            showloader: false,
                          });
                        } else if (settlementRes?.data?.responseInfo?.status === "FAILED") {
                          handleError(res["data"]?.responseInfo?.responseMessage?.[0] || "Something went wrong...");
                        } else {
                          self.setState({
                            //...values,
                            showloader: false,
                          });
                          sessionStorage.setItem("Ordernumber", res["data"].paymentOrders[0].paymentOrderId);

                          if (res["data"]?.displayAlert === "Y") {
                            self.setState({
                              ...self.state,
                              paymentConfirmationAlert: true,
                              showloader: false,
                            });
                            return;
                          } else {
                            values.paymentMethod === "echeck" || values.paymentMethod.includes("&")
                              ? self.props.history.push({
                                  pathname: "/payment-echeck",
                                  state: {
                                    paymentMethod: values.paymentMethod,
                                    payInFuture: false,
                                    PassValues: {},
                                    showecheck: self.state.showecheck,
                                    page: "quickPay",
                                  },
                                })
                              : self.props.history.push({
                                  pathname: "/payment-Creditcard",
                                  state: {
                                    paymentMethod: values.paymentMethod,
                                    payInFuture: false,
                                    PassValues: {},
                                    showcreditcard: self.state.showcreditcard,
                                    page: "quickPay",
                                  },
                                });
                          }
                        }
                      },
                      (error) => {
                        self.setState({
                          //...values,
                          showloader: false,
                        });
                        console.log(error);
                      },
                    );
                  }
                } else if (res["data"].responseInfo.status === "FAILED") {
                  handleError("Sorry Internal Error Occurred. Please login and try again.");
                  localStorage.removeItem("currentUserRole");
                  localStorage.clear();
                  sessionStorage.clear();
                  self.props.history.push("/signin");
                } else {
                  warning(
                    <Translation>{(t, { i18n }) => <label>{t("paymentSummary_warningMsgTechnical")}</label>}</Translation>,
                    warningNotification,
                  );
                }
              });
              // }
            }, 2000);
          }
          parcelSetInterval(this);
        }
      } else {
        var totalamt = sessionStorage.getItem("TotalAmount");
        sessionStorage.setItem("amounttopay", totalamt);

        if (sessionStorage.getItem("amounttopay") > this.state.totalAmount) {
          warning(
            <Translation>{(t, { i18n }) => <label>{t("paymentSummary_amtLessThanFullBlc")}</label>}</Translation>,

            warningNotification,
          );
        } else if (this.state.totalAmount <= 0 || sessionStorage.getItem("amounttopay") <= 0) {
          warning(
            <Translation>{(t, { i18n }) => <label>{t("paymentminimumAmountMessage")}</label>}</Translation>,

            warningNotification,
          );
        } else {
          let parcelSetInterval = setInterval(() => {
            if (this.state.payOtherAmountFlag === "success") {
              var order_FP_data = JSON.parse(sessionStorage.getItem("order_FP"));
              var order_FP_orderdetails = sessionStorage.getItem("orderdetails_FP");

              var count = order_FP_data?.length - 1;

              var userinfo = JSON.parse(sessionStorage.getItem("verifypayer"));

              var PassValues = {
                requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
                paymentOrders: [
                  {
                    contactPartyId: order_FP_data[count].contactPartyId,
                    payerPartyId: userinfo?.partyId,
                    paymentOrderCurrencyId: order_FP_data[count].paymentOrderCurrencyId,
                    paymentOrderAmount: sessionStorage.getItem("amounttopay"),
                    totalTransactionAmount: sessionStorage.getItem("amounttopay"),
                    feesAmount: "7",
                    payerAccountId: order_FP_data[count].payerAccountId,
                    billId: order_FP_data[count].billId,
                    paymentOrderStatus: "1", // need to check for now 1
                    orderChannel: "1", //   credit / echeque ?
                    isActive: "1", // 1 by default
                    paymentOrderDetails: JSON.parse(order_FP_orderdetails),
                  },
                ],
              };

              var userId = "1234";
              OutstandingPayerService.PP_Create_Payment_Order(PassValues, userId, (res) => {
                clearInterval(parcelSetInterval);
                if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
                  sessionStorage.setItem("Ordernumber", res["data"].paymentOrders[0].paymentOrderId);

                  values.paymentMethod === "echeck" ||
                  values.paymentMethod.includes("&") ||
                  values.paymentMethod.includes("echeck")
                    ? this.props.history.push({
                        pathname: "/payment-echeck",
                        state: { paymentMethod: values.paymentMethod, PassValues, page: "quickPay" },
                      })
                    : this.props.history.push({
                        pathname: "/payment-Creditcard",
                        state: { paymentMethod: values.paymentMethod, PassValues, page: "quickPay" },
                      });
                } else if (res["data"]?.responseInfo?.status === "FAILED") {
                  handleError("Sorry Internal Error Occurred. Please login and try again.");
                  localStorage.removeItem("currentUserRole");
                  localStorage.clear();
                  sessionStorage.clear();
                  this.props.history.push("/signin");
                } else {
                  warning(
                    <Translation>{(t, { i18n }) => <label>{t("paymentSummary_warningMsgTechnical")}</label>}</Translation>,
                    warningNotification,
                  );
                }
              });
            }
          }, 2000);
          parcelSetInterval();
        }
      }
    };

    componentWillMount() {
      sessionStorage.setItem("screen", "adminBillDetails");
    }
    componentWillMount() {
      sessionStorage.setItem("screen", "paymentoutstanding");
    }

    _payFullAmountDistribution = () => {
      sessionStorage.setItem("amounttopay", sessionStorage.getItem("TotalAmount"));
      sessionStorage.setItem("partialpaymentflag", false);

      sessionStorage.setItem("apportionedamount", sessionStorage.getItem("TotalAmount"));
      var userdatainfo = JSON.parse(sessionStorage.getItem("verifypayer"));
      var userpartyid = JSON.parse(sessionStorage.getItem("order_FP"));

      var distributeddata = JSON.parse(sessionStorage.getItem("fulldistribution"));
      if (distributeddata) {
        const passValues = {
          requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jti-token")),

          billDistribution: {
            billDistrubute: distributeddata,
            partialAmount: Number(sessionStorage.getItem("TotalAmount")).toFixed(2),
          },
        };
        var userId = "1234";
        OutstandingPayerService.PP_Get_Apportioned_data(passValues, userId, (res) => {
          this.setState({
            ...this.state,
            payOtherAmountFlag: "pending",
          });
          if (res["data"].responseInfo.status === "SUCCESSFUL") {
            this.setState({
              ...this.state,
              payOtherAmountFlag: "success",
            });
            sessionStorage.setItem("billingdata_partial", res["data"].billDistribution.billDistrubute);

            var totalAmount = 0;
            var billinginformation = [];
            var temp = [];

            var rowjsonObj = [];
            var rowjsonitem_order = [];
            var rowjsonorderdetails = [];
            var rowpartjsonObj = [];
            var billingdata = res["data"].billDistribution.billDistrubute;
            billingdata.map((billitem, index) => {
              let temp1 = [];

              let item = {};
              let item_order = {};
              let item_orderdetails = {};

              item_order["contactPartyId"] = billitem.contactPartyId;
              item_order["paymentOrderCurrencyId"] = billitem.billCurrency;
              item_order["paymentOrderAmount"] = billitem.totalAmount;
              item_order["payerAccountId"] = billitem.payerAccountId !== null ? billitem.payerAccountId : 2;
              item_order["billId"] = "";
              item_order["paymentOrderStatus"] = "1";
              item_order["orderChannel"] = "1";
              item_order["isActive"] = "1";

              item_orderdetails["mappedBillId"] = billitem.billId;
              item_orderdetails["billAmount"] = billitem.billAmount;
              // item_orderdetails['billDate'] = (billitem.billDate).split(" ")[0];
              item_orderdetails["billDate"] = billitem.billDate;

              //item_orderdetails["billDate"] = "2020-09-11";
              item_orderdetails["otherCharges"] = billitem.otherCharges;
              item_orderdetails["tax"] = billitem.taxAmount;
              item_orderdetails["totalBillAmount"] = billitem.totalAmount;
              item_orderdetails["currencyId"] = billitem.billCurrency;
              item_orderdetails["expectedPaymentAmount"] = billitem.billDistributionAmount;
              item_orderdetails["isActive"] = "1";
              item_orderdetails["othercharges"] = billitem.otherCharges;
              item_orderdetails["tax"] = billitem.taxAmount;

              rowjsonitem_order.push(item_order);
              rowjsonorderdetails.push(item_orderdetails);

              let partitem = {};

              partitem["totalBillAmount"] = billitem.billDistributionAmount;
              partitem["expectedPaymentAmount"] = billitem.totalAmount;
              partitem["mappedBillId"] = billitem.billId;
              partitem["billAmount"] = billitem.totalAmount;

              var bill_date = billitem.billDate.split(" ")[0];
              var billtempdate = bill_date.split("/");

              partitem["billDate"] = billitem.billDate;

              //item["billDate"] = "2020-09-11";
              partitem["othercharges"] = billitem.otherCharges;
              partitem["tax"] = billitem.taxAmount;
              partitem["currencyid"] = billitem.billCurrency;
              partitem["isActive"] = "1";

              if (Number(billitem.billDistributionAmount) > 0) {
                rowpartjsonObj.push(partitem);
              }
              $('input[data-mappedbillid="' + billitem.billId + '"]').val(billitem.billDistributionAmount);
            });

            sessionStorage.setItem("order_partial", JSON.stringify(rowjsonitem_order));
            sessionStorage.setItem("orderdetails_partial", JSON.stringify(rowjsonorderdetails));
            sessionStorage.setItem("accountdistribution", JSON.stringify(rowpartjsonObj));
          }
        });
        $(".grand-amt1 .amount1").text(
          Number(sessionStorage.getItem("amounttopay")).toLocaleString(undefined, {
            minimumFractionDigits: 2,
          }),
        );
      }
    };
    schema = Yup.object().shape({
      paymentMethod: Yup.string().notOneOf(["none"], "Select a Valid Option").required("Select a Payment Method"),
    });

    // dialog functions
    handleSettlePush = () => {
      this.props.history.push("/home/settlementPayment", {
        settlementOptionList: this.state.settlementOptionList,
        paymentMethod: this.state.passPaymentMethod,
        settlementData: this.state.settlementData,
      });
    };
    handleSettleClose = () => {
      this.setState({ settleOpen: false, dialogopen: false });
    };
    handleDetailsClose = () => {
      // this.setState({ settleDetails: false });
      // this.setState({ settleReject: false });
      this.setState({
        settleRevised: false,
        settleOpen: false,
        settleDetails: false,
        settleReject: false,
        settlePending: false,
      });
    };

    _handleSettlmentNo = () => {
      this.state?.passPaymentMethod === "echeck" || this.state?.passPaymentMethod.includes("&")
        ? this.props?.history.push({
            pathname: "/payment-echeck",
            state: {
              paymentMethod: this.state.passPaymentMethod,
              payInFuture: false,
              PassValues: {},
            },
          })
        : this.props?.history.push({
            pathname: "/payment-Creditcard",
            state: {
              paymentMethod: this.state.passPaymentMethod,
              payInFuture: false,
              PassValues: {},
            },
          });
    };

    handleMannualSettlement = (mannualData, mannualSeedItem) => {
      if (mannualData?.settlementStatus === "REVISED") {
        this.setState({
          settleRevised: true,
          settleDetails: false,
          settleReject: false,
          settlePending: false,
          DialogData: mannualData,
          currentSeedItem: mannualSeedItem,
        });
      }
      if (mannualData.settlementStatus === "APPROVED") {
        this.setState({
          settleRevised: false,
          settleDetails: true,
          settleReject: false,
          settlePending: false,
          DialogData: mannualData,
          currentSeedItem: mannualSeedItem,
        });
      }
      if (mannualData.settlementStatus === "REJECTED") {
        this.setState({
          settleRevised: false,
          settleDetails: false,
          settleReject: true,
          settlePending: false,
          DialogData: mannualData,
          currentSeedItem: mannualSeedItem,
        });
      }
      if (mannualData.settlementStatus === "PENDING") {
        this.setState({
          settleRevised: false,
          settleDetails: false,
          settleReject: false,
          settlePending: true,
          DialogData: mannualData,
          currentSeedItem: mannualSeedItem,
        });
      }
    };

    _handlePaymentAlert = () => {
      this.setState({ paymentConfirmationAlert: false });
    };

    // end of dialog functions

    render() {
      const { creditCardData, eChequeData } = this.props;
      return (
        <>
          <ConfirmationDialog
            open={this.state.dialogopen}
            handleClose={this.handleClose}
            noActionButtons={true}
            title={"Payment Options"}>
            <MinimumBalancePlan handleClose={this.handleClose} />
          </ConfirmationDialog>
          <DialogSettlementDetails
            open1={this.state.settleOpen}
            open2={
              this.state.settleReject || this.state.settleDetails || this.state.settleRevised || this.state.settlePending
            }
            dialogClose2={this.handleDetailsClose}
            dialogClose1={this.handleSettleClose}
            settleDetails={this.state.settleDetails}
            settleReject={this.state.settleReject}
            settleRevised={this.state.settleRevised}
            settlePending={this.state.settlePending}
            data={this.state.DialogData}
            title="Settlements"
            handleSettlePush={this.handleSettlePush}
            handleSettlmentNo={this._handleSettlmentNo}
            currentSeedItem={this.state.currentSeedItem}
            getCardListData={this.getCardListData}
            getEchecqueListData={this.getEchecqueListData}
            creditCardData={creditCardData}
            eChequeData={eChequeData}
            {...this.props}
          />
          {this.state.showloader === true ? <SpinnerPage /> : ""}
          <HeaderQP />
          <Formik
            enableReinitialize
            initialValues={{
              payAmount:
                sessionStorage.getItem("partialpaymentflag") === "true"
                  ? this.state.payAmount
                    ? this.state.payAmount
                    : ""
                  : sessionStorage.getItem("amounttopay"),
              paymentMethod: this.state.paymentmethod ? this.state.paymentmethod : "",
              totalAmount: 3500,
              PayTotalAmount:
                sessionStorage.getItem("TotalAmount") <= 50
                  ? true
                  : sessionStorage.getItem("partialpaymentflag") === "false"
                  ? true
                  : false,
              PayPartialAmount:
                sessionStorage.getItem("TotalAmount") <= 50
                  ? false
                  : sessionStorage.getItem("partialpaymentflag") === "true"
                  ? true
                  : false,
              accountNumber: "",
              dueDate: "Past Due",
              outstandingAmount: this.state.outstandingAmount ? this.state.outstandingAmount : "1500",
              paymentAmount: "",
              outstandingAmount3: 500,
              outstandingAmount2: 1000,
              remainingData: "",
              remainingData2: "",
              showloader: false,
            }}
            validationSchema={this.schema}
            onSubmit={this.state.click === "Now" ? this._handleSubmit : this._payInFuture}>
            {({ touched, errors, setFieldValue, values }) => (
              <>
                <PaymentConfirmationDialog
                  open={this.state.paymentConfirmationAlert}
                  handleClose={this._handlePaymentAlert}
                  handleContinue={this._handleContinuePayment.bind(this, values)}
                />

                <div className="qp-homepage-container">
                  <div className={`payment-portal-login ${isUser || isPA ? "" : "qp-homepage-container"}`}>
                    <Form noValidate>
                      <div>
                        <div className="qp-stepper">
                          <Stepper />
                        </div>
                        <div className="make-payment-section">
                          <div className="form-row pay-balance-card mx-0">
                            <div className="col-md-12 col-lg-4 pay-balance-card-one px-0">
                              <div
                                className={`payment-content make-payment-card ${
                                  isUser || isPA ? "" : "quick-payment-card"
                                }`}>
                                <div className="form-row align-items-center">
                                  {/* value={values?.firstname.charAt(0).toUpperCase() + values?.firstname.slice(1)} */}
                                  <div className="form-row w-100">
                                    <div className="col-md-3 col-lg-12">
                                      <h1 className="accountholder-name">{capitalize(this?.state?.accountname)}</h1>
                                    </div>
                                    <div className="col-sm-6 col-md-5 col-lg-12">
                                      <div className="qp-ssn">
                                        <p className="security-number mb-0">
                                          Social Security Number
                                          <b> : {"***-**-" + this.state?.SocialSecurityNumber?.slice(-4)}</b>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-12">
                                      {/* <label htmlFor="paymentMethod" className="form-label required">
                                Payment Method
                              </label> */}
                                      <div className="form-group mt-lg-2">
                                        <Field
                                          type="text"
                                          autocomplete="off"
                                          id="paymentMethod"
                                          name="paymentMethod"
                                          placeholder="Payment Method"
                                          component="select"
                                          className="form-select form-control"
                                          onChange={(event) => {
                                            setFieldValue(
                                              (values.paymentMethod = event.target.value),
                                              // (values.payAmount = "0.00")
                                            );
                                            this.setState({
                                              paymentmethod: event.target.value,
                                            });

                                            if (values.PayPartialAmount === true) {
                                              setFieldValue(
                                                (values.PayPartialAmount = true),
                                                (values.PayTotalAmount = false),
                                                (values.showloader = false),
                                              );

                                              if (
                                                Number(sessionStorage.getItem("amounttopay")) ===
                                                Number(sessionStorage.getItem("TotalAmount"))
                                              ) {
                                                // alert("1")
                                                this.setState({
                                                  payAmount: "0.00",
                                                });
                                              } else {
                                                //  alert("2")
                                                this.setState({
                                                  payAmount: Number(sessionStorage.getItem("amounttopay")),
                                                });
                                              }
                                            } else {
                                              //alert("3")
                                              sessionStorage.setItem("amounttopay", 0);
                                              this.setState({
                                                payAmount: "0.00",
                                              });
                                            }
                                          }}>
                                          <Translation>
                                            {(t, { i18n }) => <option value="">{t("Select Payment Method")}</option>}
                                          </Translation>

                                          {this.getCardListData(creditCardData)}
                                          {this.getEchecqueListData(eChequeData)}

                                          {this.state.showcreditcard === true ? (
                                            <option value="creditCard" selected>
                                              Debit/Credit Card
                                            </option>
                                          ) : (
                                            ""
                                          )}
                                          {this.state.showecheck === true ? <option value="echeck">eCheck</option> : ""}
                                        </Field>
                                        <FormHelperText style={{ marginLeft: "4px" }} error>
                                          {getIn(touched, "paymentMethod") &&
                                            getIn(errors, "paymentMethod") &&
                                            getIn(errors, "paymentMethod")}
                                        </FormHelperText>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-6 col-12 col-md-3 col-lg-12">
                                    <div className="pay-balance">
                                      <div className="d-flex align-items-center">
                                        <Field
                                          className="mr-1"
                                          type="checkbox"
                                          id="PayTotalAmount"
                                          name="PayTotalAmount"
                                          onChange={(event) => {
                                            setFieldValue(
                                              ((values.PayTotalAmount = true),
                                              (values.PayPartialAmount = false),
                                              (values.payAmount = "")),
                                            );
                                            sessionStorage.setItem("amounttopay", sessionStorage.getItem("TotalAmount"));
                                            sessionStorage.setItem("partialpaymentflag", false);

                                            sessionStorage.setItem(
                                              "apportionedamount",
                                              sessionStorage.getItem("TotalAmount"),
                                            );
                                            var userdatainfo = JSON.parse(sessionStorage.getItem("verifypayer"));
                                            var userpartyid = JSON.parse(sessionStorage.getItem("order_FP"));

                                            var distributeddata = JSON.parse(sessionStorage.getItem("fulldistribution"));
                                            const passValues = {
                                              requestInfo: requestInfo_PP(
                                                sessionStorage.getItem("jwt-token"),
                                                sessionStorage.getItem("jti-token"),
                                              ),

                                              billDistribution: {
                                                billDistrubute: distributeddata,
                                                partialAmount: Number(sessionStorage.getItem("TotalAmount")).toFixed(2),
                                              },
                                            };
                                            var userId = "1234";
                                            OutstandingPayerService.PP_Get_Apportioned_data(passValues, userId, (res) => {
                                              // alert("API CALLED2")
                                              this.setState({
                                                ...this.state,
                                                payOtherAmountFlag: "pending",
                                              });
                                              if (res["data"].responseInfo.status === "SUCCESSFUL") {
                                                this.setState({
                                                  ...this.state,
                                                  payOtherAmountFlag: "success",
                                                });
                                                sessionStorage.setItem(
                                                  "billingdata_partial",
                                                  res["data"].billDistribution.billDistrubute,
                                                );

                                                var totalAmount = 0;
                                                var billinginformation = [];
                                                var temp = [];

                                                var rowjsonObj = [];
                                                var rowjsonitem_order = [];
                                                var rowjsonorderdetails = [];
                                                var rowpartjsonObj = [];
                                                var billingdata = res["data"].billDistribution.billDistrubute;
                                                billingdata.map((billitem, index) => {
                                                  let temp1 = [];

                                                  let item = {};
                                                  let item_order = {};
                                                  let item_orderdetails = {};

                                                  item_order["contactPartyId"] = billitem.contactPartyId;
                                                  item_order["paymentOrderCurrencyId"] = billitem.billCurrency;
                                                  item_order["paymentOrderAmount"] = billitem.totalAmount;
                                                  item_order["payerAccountId"] =
                                                    billitem.payerAccountId !== null ? billitem.payerAccountId : 2;
                                                  item_order["billId"] = "";
                                                  item_order["paymentOrderStatus"] = "1";
                                                  item_order["orderChannel"] = "1";
                                                  item_order["isActive"] = "1";

                                                  item_orderdetails["mappedBillId"] = billitem.billId;
                                                  item_orderdetails["billAmount"] = billitem.billAmount;
                                                  // item_orderdetails['billDate'] = (billitem.billDate).split(" ")[0];
                                                  item_orderdetails["billDate"] = billitem.billDate;

                                                  //item_orderdetails["billDate"] = "2020-09-11";
                                                  item_orderdetails["otherCharges"] = billitem.otherCharges;
                                                  item_orderdetails["tax"] = billitem.taxAmount;
                                                  item_orderdetails["totalBillAmount"] = billitem.totalAmount;
                                                  item_orderdetails["currencyId"] = billitem.billCurrency;
                                                  item_orderdetails["expectedPaymentAmount"] =
                                                    billitem.billDistributionAmount;
                                                  item_orderdetails["isActive"] = "1";
                                                  item_orderdetails["othercharges"] = billitem.otherCharges;
                                                  item_orderdetails["tax"] = billitem.taxAmount;
                                                  //item_orderdetails['acno'] = billitem.tenantAccountReferenceNo

                                                  // rowjsonObj.push(item);
                                                  rowjsonitem_order.push(item_order);
                                                  rowjsonorderdetails.push(item_orderdetails);

                                                  let partitem = {};

                                                  partitem["totalBillAmount"] = billitem.billDistributionAmount;
                                                  partitem["expectedPaymentAmount"] = billitem.totalAmount;
                                                  partitem["mappedBillId"] = billitem.billId;
                                                  partitem["billAmount"] = billitem.totalAmount;

                                                  //item["billDate"] = pay.dataset.billdate;
                                                  var bill_date = billitem.billDate.split(" ")[0];
                                                  var billtempdate = bill_date.split("/");

                                                  //  partitem['billDate'] = billtempdate[2]+"-"+ billtempdate[0] +"-"+ billtempdate[1];
                                                  partitem["billDate"] = billitem.billDate;

                                                  //item["billDate"] = "2020-09-11";
                                                  partitem["othercharges"] = billitem.otherCharges;
                                                  partitem["tax"] = billitem.taxAmount;
                                                  partitem["currencyid"] = billitem.billCurrency;
                                                  partitem["isActive"] = "1";

                                                  if (Number(billitem.billDistributionAmount) > 0) {
                                                    rowpartjsonObj.push(partitem);
                                                  }
                                                  $('input[data-mappedbillid="' + billitem.billId + '"]').val(
                                                    billitem.billDistributionAmount,
                                                  );
                                                });

                                                var totalAmount = 0;
                                                var billinginformation = [];
                                                var temp = [];

                                                var rowjsonObj = [];
                                                var rowjsonitem_order = [];
                                                var rowjsonorderdetails = [];
                                                var rowpartjsonObj = [];
                                                var billingdata = res["data"].billDistribution.billDistrubute;
                                                billingdata.map((billitem, index) => {
                                                  let temp1 = [];

                                                  let item = {};
                                                  let item_order = {};
                                                  let item_orderdetails = {};

                                                  item_order["contactPartyId"] = billitem.contactPartyId;
                                                  item_order["paymentOrderCurrencyId"] = billitem.billCurrency;
                                                  item_order["paymentOrderAmount"] = billitem.totalAmount;
                                                  item_order["payerAccountId"] =
                                                    billitem.payerAccountId !== null ? billitem.payerAccountId : 2;
                                                  item_order["billId"] = "";
                                                  item_order["paymentOrderStatus"] = "1";
                                                  item_order["orderChannel"] = "1";
                                                  item_order["isActive"] = "1";

                                                  item_orderdetails["mappedBillId"] = billitem.billId;
                                                  item_orderdetails["billAmount"] = billitem.billAmount;
                                                  // item_orderdetails['billDate'] = (billitem.billDate).split(" ")[0];
                                                  item_orderdetails["billDate"] = billitem.billDate;

                                                  //item_orderdetails["billDate"] = "2020-09-11";
                                                  item_orderdetails["otherCharges"] = billitem.otherCharges;
                                                  item_orderdetails["tax"] = billitem.taxAmount;
                                                  item_orderdetails["totalBillAmount"] = billitem.totalAmount;
                                                  item_orderdetails["currencyId"] = billitem.billCurrency;
                                                  item_orderdetails["expectedPaymentAmount"] =
                                                    billitem.billDistributionAmount;
                                                  item_orderdetails["isActive"] = "1";
                                                  item_orderdetails["othercharges"] = billitem.otherCharges;
                                                  item_orderdetails["tax"] = billitem.taxAmount;
                                                  //item_orderdetails['acno'] = billitem.tenantAccountReferenceNo

                                                  // rowjsonObj.push(item);
                                                  rowjsonitem_order.push(item_order);
                                                  rowjsonorderdetails.push(item_orderdetails);

                                                  let partitem = {};

                                                  partitem["totalBillAmount"] = billitem.billDistributionAmount;
                                                  partitem["expectedPaymentAmount"] = billitem.totalAmount;
                                                  partitem["mappedBillId"] = billitem.billId;
                                                  partitem["billAmount"] = billitem.totalAmount;

                                                  //item["billDate"] = pay.dataset.billdate;
                                                  var bill_date = billitem.billDate.split(" ")[0];
                                                  var billtempdate = bill_date.split("/");

                                                  //  partitem['billDate'] = billtempdate[2]+"-"+ billtempdate[0] +"-"+ billtempdate[1];
                                                  partitem["billDate"] = billitem.billDate;

                                                  //item["billDate"] = "2020-09-11";
                                                  partitem["othercharges"] = billitem.otherCharges;
                                                  partitem["tax"] = billitem.taxAmount;
                                                  partitem["currencyid"] = billitem.billCurrency;
                                                  partitem["isActive"] = "1";

                                                  if (Number(billitem.billDistributionAmount) > 0) {
                                                    rowpartjsonObj.push(partitem);
                                                  }
                                                  $('input[data-mappedbillid="' + billitem.billId + '"]').val(
                                                    billitem.billDistributionAmount,
                                                  );
                                                });

                                                sessionStorage.setItem("order_partial", JSON.stringify(rowjsonitem_order));
                                                sessionStorage.setItem(
                                                  "orderdetails_partial",
                                                  JSON.stringify(rowjsonorderdetails),
                                                );
                                                sessionStorage.setItem(
                                                  "accountdistribution",
                                                  JSON.stringify(rowpartjsonObj),
                                                );
                                              }

                                              setFieldValue((values.showloader = false));
                                            });
                                            $(".grand-amt1 .amount1").text(
                                              Number(sessionStorage.getItem("amounttopay")).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                              }),
                                            );
                                          }}
                                        />

                                        <Typography
                                          className="form-check-label"
                                          onClick={() => {
                                            setFieldValue(
                                              ((values.PayTotalAmount = true),
                                              (values.PayPartialAmount = false),
                                              (values.payAmount = "")),
                                            );
                                            sessionStorage.setItem("amounttopay", sessionStorage.getItem("TotalAmount"));

                                            sessionStorage.setItem("partialpaymentflag", false);
                                            sessionStorage.setItem(
                                              "apportionedamount",
                                              sessionStorage.getItem("TotalAmount"),
                                            );
                                            var userdatainfo = JSON.parse(sessionStorage.getItem("verifypayer"));
                                            var userpartyid = JSON.parse(sessionStorage.getItem("order_FP"));
                                            var distributeddata = JSON.parse(sessionStorage.getItem("fulldistribution"));

                                            const passValues = {
                                              requestInfo: requestInfo_PP(
                                                sessionStorage.getItem("jwt-token"),
                                                sessionStorage.getItem("jti-token"),
                                              ),
                                              billDistribution: {
                                                billDistrubute: distributeddata,
                                                partialAmount: sessionStorage.getItem("TotalAmount"),
                                              },
                                            };
                                            var userId = "1234";
                                            OutstandingPayerService.PP_Get_Apportioned_data(passValues, userId, (res) => {
                                              this.setState({
                                                ...this.state,
                                                payOtherAmountFlag: "pending",
                                              });
                                              if (res["data"].responseInfo.status === "SUCCESSFUL") {
                                                this.setState({
                                                  ...this.state,
                                                  payOtherAmountFlag: "success",
                                                });
                                                sessionStorage.setItem(
                                                  "billingdata_partial",
                                                  res["data"].billDistribution.billDistrubute,
                                                );

                                                var totalAmount = 0;
                                                var billinginformation = [];
                                                var temp = [];

                                                var rowjsonObj = [];
                                                var rowjsonitem_order = [];
                                                var rowjsonorderdetails = [];
                                                var rowpartjsonObj = [];
                                                var billingdata = res["data"].billDistribution.billDistrubute;
                                                billingdata.map((billitem, index) => {
                                                  let temp1 = [];

                                                  let item = {};
                                                  let item_order = {};
                                                  let item_orderdetails = {};

                                                  item_order["contactPartyId"] = billitem.contactPartyId;

                                                  item_order["paymentOrderCurrencyId"] = billitem.billCurrency;
                                                  item_order["paymentOrderAmount"] = billitem.totalAmount;
                                                  item_order["payerAccountId"] =
                                                    billitem.payerAccountId !== null ? billitem.payerAccountId : 2;
                                                  item_order["billId"] = "";
                                                  item_order["paymentOrderStatus"] = "1";
                                                  item_order["orderChannel"] = "1";
                                                  item_order["isActive"] = "1";

                                                  item_orderdetails["mappedBillId"] = billitem.billId;
                                                  item_orderdetails["billAmount"] = billitem.billAmount;

                                                  item_orderdetails["billDate"] = billitem.billDate;

                                                  item_orderdetails["otherCharges"] = billitem.otherCharges;
                                                  item_orderdetails["tax"] = billitem.taxAmount;
                                                  item_orderdetails["totalBillAmount"] = billitem.totalAmount;
                                                  item_orderdetails["currencyId"] = billitem.billCurrency;
                                                  item_orderdetails["expectedPaymentAmount"] =
                                                    billitem.billDistributionAmount;
                                                  item_orderdetails["isActive"] = "1";
                                                  item_orderdetails["othercharges"] = billitem.otherCharges;
                                                  item_orderdetails["tax"] = billitem.taxAmount;

                                                  rowjsonitem_order.push(item_order);
                                                  rowjsonorderdetails.push(item_orderdetails);

                                                  let partitem = {};

                                                  partitem["totalBillAmount"] = billitem.billDistributionAmount;
                                                  partitem["expectedPaymentAmount"] = billitem.totalAmount;
                                                  partitem["mappedBillId"] = billitem.billId;
                                                  partitem["billAmount"] = billitem.totalAmount;

                                                  var bill_date = billitem.billDate.split(" ")[0];
                                                  var billtempdate = bill_date.split("/");

                                                  partitem["billDate"] = billitem.billDate;

                                                  partitem["othercharges"] = billitem.otherCharges;
                                                  partitem["tax"] = billitem.taxAmount;
                                                  partitem["currencyid"] = billitem.billCurrency;
                                                  partitem["isActive"] = "1";

                                                  rowpartjsonObj.push(partitem);

                                                  $('input[data-mappedbillid="' + billitem.billId + '"]').val(
                                                    billitem.billDistributionAmount,
                                                  );
                                                });

                                                sessionStorage.setItem("order_partial", JSON.stringify(rowjsonitem_order));
                                                sessionStorage.setItem(
                                                  "orderdetails_partial",
                                                  JSON.stringify(rowjsonorderdetails),
                                                );
                                                sessionStorage.setItem(
                                                  "accountdistribution",
                                                  JSON.stringify(rowpartjsonObj),
                                                );
                                              }

                                              setFieldValue((values.showloader = false));
                                            });
                                            $(".grand-amt1 .amount1").text(
                                              Number(sessionStorage.getItem("amounttopay")).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                              }),
                                            );
                                          }}>
                                          Pay Full Balance
                                        </Typography>
                                      </div>
                                      <div className="bill-amount">
                                        ${" "}
                                        <span className="">
                                          {this.state.totalAmount.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-6 col-12 col-md-3 col-lg-12 billingamount-payamount ">
                                    <div className="pay-balance">
                                      <div className="d-flex align-items-center">
                                        <Field
                                          className="mr-1"
                                          type="checkbox"
                                          id="PayPartialAmount"
                                          name="PayPartialAmount"
                                          disabled={sessionStorage.getItem("TotalAmount") <= 50}
                                          onChange={(event) => {
                                            setFieldValue(
                                              ((values.PayPartialAmount = true),
                                              (values.PayTotalAmount = false),
                                              (values.payAmount = "0.00")),
                                            );

                                            $(".chosenamount").val("0.00");
                                            $(".grand-amt1 .amount1").text("0.00");
                                            sessionStorage.setItem("amounttopay", "0.00");
                                            sessionStorage.setItem("apportionedamount", "0");
                                            sessionStorage.setItem("partialpaymentflag", true);
                                          }}
                                        />
                                        <Typography
                                          className="form-check-label"
                                          for="inlineRadio1"
                                          onClick={(event) => {
                                            if (sessionStorage.getItem("TotalAmount") > 50) {
                                              setFieldValue(
                                                ((values.PayPartialAmount = true),
                                                (values.PayTotalAmount = false),
                                                (values.payAmount = "0.00")),
                                              );
                                              $(".chosenamount").val("0.00");
                                              $(".grand-amt1 .amount1").text("0.00");
                                              sessionStorage.setItem("amounttopay", "0.00");
                                              sessionStorage.setItem("apportionedamount", "0");
                                              sessionStorage.setItem("partialpaymentflag", true);
                                            }
                                          }}>
                                          Pay Other Amount
                                        </Typography>
                                      </div>
                                      <div className="pay-amount text-right">
                                        <span className="bill-amount ">$ </span>

                                        <Field
                                          className="amount-textfield"
                                          type="number"
                                          autocomplete="off"
                                          // step="any"
                                          step="0.01"
                                          disabled={sessionStorage.getItem("TotalAmount") <= 50}
                                          pattern="\d{1,}(\.\d{1,2})|\d{0,9}"
                                          id="payAmount"
                                          name="payAmount"
                                          value={values.PayTotalAmount ? "0.00" : values.payAmount}
                                          placeholder="0.00"
                                          onClick={(event) => {
                                            $("#paymentMethod").prop("disabled", "disabled");
                                            setFieldValue((values.payAmount = ""));
                                            $(".button-box .btn-primary").toggleClass("disable");
                                          }}
                                          onBlur={(event) => {
                                            if (Number(event.target.value) == 0) {
                                              sessionStorage.setItem("partialpaymentflag", true);
                                              $("#paymentMethod").prop("disabled", false);
                                            } else {
                                              $("#paymentMethod").prop("disabled", false);
                                              $(".chosenamount").val("0.00");
                                              sessionStorage.setItem("partialpaymentflag", true);
                                              sessionStorage.removeItem("accountdistribution_changeddata_sum");
                                              $(".button-box .btn-primary").removeClass("disable");

                                              if (
                                                Number(event.target.value) > Number(sessionStorage.getItem("TotalAmount"))
                                              ) {
                                                this.setState({
                                                  payAmount: Number(0).toFixed(2),
                                                });
                                                sessionStorage.setItem("amounttopay", 0);
                                                sessionStorage.setItem("exceedingAmountEntered", true);
                                                handleWarning(<>{ToastMsg.paymentSummary_warningotherAmtlessThan}</>);
                                              } else {
                                                this.setState({
                                                  payAmount: Number(event.target.value).toFixed(2),
                                                });
                                                sessionStorage.setItem("amounttopay", event.target.value);
                                              }
                                              if (this.state.totalAmount < 50) {
                                                this.setState({
                                                  amtdistributeflag: true,
                                                });

                                                setFieldValue(
                                                  ((values.payAmount = Number(event.target.value).toFixed(2)),
                                                  (values.PayTotalAmount = false),
                                                  (values.PayPartialAmount = true),
                                                  (values.showloader = true)),
                                                );
                                                sessionStorage.setItem("amounttopay", Number(event.target.value).toFixed(2));
                                                sessionStorage.setItem(
                                                  "apportionedamount",
                                                  Number(event.target.value).toFixed(2),
                                                );
                                                var userdatainfo = JSON.parse(sessionStorage.getItem("verifypayer"));
                                                var userpartyid = JSON.parse(sessionStorage.getItem("order_FP"));
                                                var distributeddata = "";
                                                if (sessionStorage.getItem("accountdistribution_changeddata") !== null) {
                                                  distributeddata = JSON.parse(
                                                    sessionStorage.getItem("accountdistribution_changeddata"),
                                                  );
                                                } else {
                                                  distributeddata = JSON.parse(sessionStorage.getItem("fulldistribution"));
                                                }
                                                const passValues = {
                                                  requestInfo: requestInfo_PP(
                                                    sessionStorage.getItem("jwt-token"),
                                                    sessionStorage.getItem("jwt-token"),
                                                  ),
                                                  billDistribution: {
                                                    billDistrubute: distributeddata,
                                                    partialAmount: Number(event.target.value).toFixed(2),
                                                  },
                                                };
                                                var userId = "1234";
                                                OutstandingPayerService.PP_Get_Apportioned_data(
                                                  passValues,
                                                  userId,
                                                  (res) => {
                                                    this.setState({
                                                      ...this.state,
                                                      payOtherAmountFlag: "pending",
                                                    });
                                                    if (res["data"].responseInfo.status === "SUCCESSFUL") {
                                                      this.setState({
                                                        ...this.state,
                                                        payOtherAmountFlag: "success",
                                                      });
                                                      sessionStorage.setItem(
                                                        "billingdata_partial",
                                                        res["data"].billDistribution.billDistrubute,
                                                      );

                                                      var totalAmount = 0;
                                                      var billinginformation = [];
                                                      var temp = [];

                                                      var rowjsonObj = [];
                                                      var rowjsonitem_order = [];
                                                      var rowjsonorderdetails = [];
                                                      var rowpartjsonObj = [];
                                                      var billingdata = res["data"].billDistribution.billDistrubute;
                                                      billingdata.map((billitem, index) => {
                                                        let temp1 = [];
                                                        temp1["accountno"] = billitem.tenantAccountReferenceNo;

                                                        totalAmount += Number(billitem.billDistributionAmount);
                                                        temp.push(temp1);

                                                        let item = {};
                                                        let item_order = {};
                                                        let item_orderdetails = {};
                                                        item["acno"] = billitem.accountReferenceNumber;
                                                        item["outstanding"] = billitem.totalAmount;
                                                        item_order["billId"] = billitem.billId;
                                                        item_order["paymentOrderStatus"] = "1";
                                                        item_order["orderChannel"] = "1";
                                                        item_order["isActive"] = "1";

                                                        item_orderdetails["mappedBillId"] = billitem.billId;
                                                        //   item_orderdetails['billAmount'] =  billitem.billAmount;
                                                        // item_orderdetails['billDate'] = (billitem.billDate).split(" ")[0];
                                                        item_orderdetails["billDate"] = billitem.billDate;
                                                        //item_orderdetails["billDate"] = "2020-09-11";
                                                        item_orderdetails["otherCharges"] = billitem.otherCharges;
                                                        item_orderdetails["tax"] = billitem.taxAmount;
                                                        item_orderdetails["totalBillAmount"] = billitem.totalAmount;
                                                        // item_orderdetails['currencyId'] = billitem.billCurrency;
                                                        item_orderdetails["expectedPaymentAmount"] =
                                                          billitem.billDistributionAmount;
                                                        item_orderdetails["isActive"] = "1";
                                                        //item_orderdetails['acno'] = billitem.tenantAccountReferenceNo

                                                        rowjsonObj.push(item);
                                                        rowjsonitem_order.push(item_order);
                                                        rowjsonorderdetails.push(item_orderdetails);

                                                        let partitem = {};

                                                        partitem["totalBillAmount"] = billitem.billDistributionAmount;
                                                        partitem["expectedPaymentAmount"] = billitem.totalAmount;
                                                        partitem["mappedBillId"] = billitem.billId;
                                                        partitem["billAmount"] = billitem.totalAmount;
                                                        partitem["otherCharges"] = billitem.otherCharges
                                                          ? billitem.otherCharges
                                                          : "00";
                                                        partitem["tax"] = billitem.tax ? billitem.tax : "00";
                                                        partitem["currencyid"] = billitem.billCurrency;
                                                        partitem["isActive"] = "1";
                                                        //item["billDate"] = pay.dataset.billdate;
                                                        var bill_date = billitem.billDate.split(" ")[0];
                                                        var billtempdate = bill_date.split("/");
                                                        // partitem['billDate'] = billtempdate[2]+"-"+ billtempdate[0] +"-"+ billtempdate[1];
                                                        partitem["billDate"] = billitem.billDate;
                                                        //item["billDate"] = "2020-09-11";

                                                        if (Number(billitem.billDistributionAmount) > 0) {
                                                          rowpartjsonObj.push(partitem);
                                                        }

                                                        // showgloader = false;

                                                        setFieldValue((values.showloader = false));
                                                        $('input[data-mappedbillid="' + billitem.billId + '"]').val(
                                                          Number(billitem.billDistributionAmount).toFixed(2),
                                                        );
                                                      });
                                                      sessionStorage.setItem(
                                                        "accountdistribution_partial",
                                                        JSON.stringify(rowjsonObj),
                                                      );
                                                      sessionStorage.setItem(
                                                        "order_partial",
                                                        JSON.stringify(rowjsonitem_order),
                                                      );
                                                      sessionStorage.setItem(
                                                        "orderdetails_partial",
                                                        JSON.stringify(rowjsonorderdetails),
                                                      );
                                                      sessionStorage.setItem(
                                                        "accountdistribution",
                                                        JSON.stringify(rowpartjsonObj),
                                                      );
                                                      sessionStorage.setItem(
                                                        "partaccountdistribution",
                                                        JSON.stringify(rowpartjsonObj),
                                                      );

                                                      this.setState({
                                                        amtdistributeflag: false,
                                                      });
                                                    } else {
                                                      this.setState({
                                                        amtdistributeflag: false,
                                                      });
                                                    }
                                                    // showgloader = false;

                                                    setFieldValue((values.showloader = false));
                                                  },
                                                );
                                              } else if (Number(event.target.value) < 50) {
                                                //  alert(this.state.allowpartial)
                                                if (this.state.allowpartial === false) {
                                                  setFieldValue((values.payAmount = ""));
                                                } else {
                                                  this.setState({
                                                    amtdistributeflag: true,
                                                  });

                                                  setFieldValue(
                                                    ((values.payAmount = Number(event.target.value).toFixed(2)),
                                                    (values.PayTotalAmount = false),
                                                    (values.PayPartialAmount = true),
                                                    (values.showloader = true)),
                                                  );
                                                  sessionStorage.setItem(
                                                    "amounttopay",
                                                    Number(event.target.value).toFixed(2),
                                                  );
                                                  sessionStorage.setItem(
                                                    "apportionedamount",
                                                    Number(event.target.value).toFixed(2),
                                                  );
                                                  var userdatainfo = JSON.parse(sessionStorage.getItem("verifypayer"));
                                                  var userpartyid = JSON.parse(sessionStorage.getItem("order_FP"));
                                                  var distributeddata = "";
                                                  if (sessionStorage.getItem("accountdistribution_changeddata") !== null) {
                                                    distributeddata = JSON.parse(
                                                      sessionStorage.getItem("accountdistribution_changeddata"),
                                                    );
                                                  } else {
                                                    distributeddata = JSON.parse(sessionStorage.getItem("fulldistribution"));
                                                  }
                                                  const passValues = {
                                                    requestInfo: requestInfo_PP(
                                                      sessionStorage.getItem("jwt-token"),
                                                      sessionStorage.getItem("jwt-token"),
                                                    ),
                                                    billDistribution: {
                                                      billDistrubute: distributeddata,
                                                      partialAmount: Number(event.target.value).toFixed(2),
                                                    },
                                                  };
                                                  var userId = "1234";
                                                  OutstandingPayerService.PP_Get_Apportioned_data(
                                                    passValues,
                                                    userId,
                                                    (res) => {
                                                      this.setState({
                                                        ...this.state,
                                                        payOtherAmountFlag: "pending",
                                                      });
                                                      if (res["data"].responseInfo.status === "SUCCESSFUL") {
                                                        this.setState({
                                                          ...this.state,
                                                          payOtherAmountFlag: "success",
                                                        });
                                                        sessionStorage.setItem(
                                                          "billingdata_partial",
                                                          res["data"].billDistribution.billDistrubute,
                                                        );

                                                        var totalAmount = 0;
                                                        var billinginformation = [];
                                                        var temp = [];

                                                        var rowjsonObj = [];
                                                        var rowjsonitem_order = [];
                                                        var rowjsonorderdetails = [];
                                                        var rowpartjsonObj = [];
                                                        var billingdata = res["data"].billDistribution.billDistrubute;
                                                        billingdata.map((billitem, index) => {
                                                          let temp1 = [];
                                                          temp1["accountno"] = billitem.tenantAccountReferenceNo;

                                                          totalAmount += Number(billitem.billDistributionAmount);
                                                          temp.push(temp1);

                                                          let item = {};
                                                          let item_order = {};
                                                          let item_orderdetails = {};
                                                          item["acno"] = billitem.accountReferenceNumber;
                                                          item["outstanding"] = billitem.totalAmount;
                                                          item_order["billId"] = billitem.billId;
                                                          item_order["paymentOrderStatus"] = "1";
                                                          item_order["orderChannel"] = "1";
                                                          item_order["isActive"] = "1";

                                                          item_orderdetails["mappedBillId"] = billitem.billId;
                                                          //   item_orderdetails['billAmount'] =  billitem.billAmount;
                                                          // item_orderdetails['billDate'] = (billitem.billDate).split(" ")[0];
                                                          item_orderdetails["billDate"] = billitem.billDate;
                                                          //item_orderdetails["billDate"] = "2020-09-11";
                                                          item_orderdetails["otherCharges"] = billitem.otherCharges;
                                                          item_orderdetails["tax"] = billitem.taxAmount;
                                                          item_orderdetails["totalBillAmount"] = billitem.totalAmount;
                                                          // item_orderdetails['currencyId'] = billitem.billCurrency;
                                                          item_orderdetails["expectedPaymentAmount"] =
                                                            billitem.billDistributionAmount;
                                                          item_orderdetails["isActive"] = "1";
                                                          //item_orderdetails['acno'] = billitem.tenantAccountReferenceNo

                                                          rowjsonObj.push(item);
                                                          rowjsonitem_order.push(item_order);
                                                          rowjsonorderdetails.push(item_orderdetails);

                                                          let partitem = {};

                                                          partitem["totalBillAmount"] = billitem.billDistributionAmount;
                                                          partitem["expectedPaymentAmount"] = billitem.totalAmount;
                                                          partitem["mappedBillId"] = billitem.billId;
                                                          partitem["billAmount"] = billitem.totalAmount;
                                                          partitem["otherCharges"] = billitem.otherCharges
                                                            ? billitem.otherCharges
                                                            : "00";
                                                          partitem["tax"] = billitem.tax ? billitem.tax : "00";
                                                          partitem["currencyid"] = billitem.billCurrency;
                                                          partitem["isActive"] = "1";
                                                          //item["billDate"] = pay.dataset.billdate;
                                                          var bill_date = billitem.billDate.split(" ")[0];
                                                          var billtempdate = bill_date.split("/");
                                                          // partitem['billDate'] = billtempdate[2]+"-"+ billtempdate[0] +"-"+ billtempdate[1];
                                                          partitem["billDate"] = billitem.billDate;
                                                          //item["billDate"] = "2020-09-11";

                                                          if (Number(billitem.billDistributionAmount) > 0) {
                                                            rowpartjsonObj.push(partitem);
                                                          }

                                                          // showgloader = false;

                                                          setFieldValue((values.showloader = false));
                                                          $('input[data-mappedbillid="' + billitem.billId + '"]').val(
                                                            Number(billitem.billDistributionAmount).toFixed(2),
                                                          );
                                                        });
                                                        sessionStorage.setItem(
                                                          "accountdistribution_partial",
                                                          JSON.stringify(rowjsonObj),
                                                        );
                                                        sessionStorage.setItem(
                                                          "order_partial",
                                                          JSON.stringify(rowjsonitem_order),
                                                        );
                                                        sessionStorage.setItem(
                                                          "orderdetails_partial",
                                                          JSON.stringify(rowjsonorderdetails),
                                                        );
                                                        sessionStorage.setItem(
                                                          "accountdistribution",
                                                          JSON.stringify(rowpartjsonObj),
                                                        );
                                                        sessionStorage.setItem(
                                                          "partaccountdistribution",
                                                          JSON.stringify(rowpartjsonObj),
                                                        );

                                                        this.setState({
                                                          amtdistributeflag: false,
                                                        });
                                                      } else {
                                                        this.setState({
                                                          amtdistributeflag: false,
                                                        });
                                                      }
                                                      // showgloader = false;

                                                      setFieldValue((values.showloader = false));
                                                    },
                                                  );
                                                }

                                                // showgloader = false;
                                              } else if (
                                                Number(event.target.value) > Number(sessionStorage.getItem("TotalAmount"))
                                              ) {
                                                setFieldValue((values.payAmount = ""));

                                                this.setState({
                                                  payAmount: "",
                                                });

                                                setTimeout(function () {
                                                  $(".amount3.grand-amt1.amount1 span.amount1").text("0.00");
                                                }, 300);

                                                // showgloader = false;
                                              } else {
                                                this.setState({
                                                  amtdistributeflag: true,
                                                });

                                                setFieldValue(
                                                  ((values.payAmount = Number(event.target.value).toFixed(2)),
                                                  (values.PayTotalAmount = false),
                                                  (values.PayPartialAmount = true),
                                                  (values.showloader = true)),
                                                );
                                                sessionStorage.setItem("amounttopay", Number(event.target.value).toFixed(2));
                                                sessionStorage.setItem(
                                                  "apportionedamount",
                                                  Number(event.target.value).toFixed(2),
                                                );
                                                var userdatainfo = JSON.parse(sessionStorage.getItem("verifypayer"));
                                                var userpartyid = JSON.parse(sessionStorage.getItem("order_FP"));
                                                var distributeddata = "";
                                                if (sessionStorage.getItem("accountdistribution_changeddata") !== null) {
                                                  distributeddata = JSON.parse(
                                                    sessionStorage.getItem("accountdistribution_changeddata"),
                                                  );
                                                } else {
                                                  distributeddata = JSON.parse(sessionStorage.getItem("fulldistribution"));
                                                }
                                                const passValues = {
                                                  requestInfo: requestInfo_PP(
                                                    sessionStorage.getItem("jwt-token"),
                                                    sessionStorage.getItem("jwt-token"),
                                                  ),
                                                  billDistribution: {
                                                    billDistrubute: distributeddata,
                                                    partialAmount: Number(event.target.value).toFixed(2),
                                                  },
                                                };
                                                var userId = "1234";
                                                OutstandingPayerService.PP_Get_Apportioned_data(
                                                  passValues,
                                                  userId,
                                                  (res) => {
                                                    this.setState({
                                                      ...this.state,
                                                      payOtherAmountFlag: "pending",
                                                    });
                                                    if (res["data"].responseInfo.status === "SUCCESSFUL") {
                                                      this.setState({
                                                        ...this.state,
                                                        payOtherAmountFlag: "success",
                                                      });
                                                      sessionStorage.setItem(
                                                        "billingdata_partial",
                                                        res["data"].billDistribution.billDistrubute,
                                                      );

                                                      var totalAmount = 0;
                                                      var billinginformation = [];
                                                      var temp = [];

                                                      var rowjsonObj = [];
                                                      var rowjsonitem_order = [];
                                                      var rowjsonorderdetails = [];
                                                      var rowpartjsonObj = [];
                                                      var billingdata = res["data"].billDistribution.billDistrubute;
                                                      billingdata.map((billitem, index) => {
                                                        let temp1 = [];
                                                        temp1["accountno"] = billitem.tenantAccountReferenceNo;

                                                        totalAmount += Number(billitem.billDistributionAmount);
                                                        temp.push(temp1);

                                                        let item = {};
                                                        let item_order = {};
                                                        let item_orderdetails = {};
                                                        item["acno"] = billitem.accountReferenceNumber;
                                                        item["outstanding"] = billitem.totalAmount;
                                                        item_order["billId"] = billitem.billId;
                                                        item_order["paymentOrderStatus"] = "1";
                                                        item_order["orderChannel"] = "1";
                                                        item_order["isActive"] = "1";

                                                        item_orderdetails["mappedBillId"] = billitem.billId;
                                                        //   item_orderdetails['billAmount'] =  billitem.billAmount;
                                                        // item_orderdetails['billDate'] = (billitem.billDate).split(" ")[0];
                                                        item_orderdetails["billDate"] = billitem.billDate;
                                                        //item_orderdetails["billDate"] = "2020-09-11";
                                                        item_orderdetails["otherCharges"] = billitem.otherCharges;
                                                        item_orderdetails["tax"] = billitem.taxAmount;
                                                        item_orderdetails["totalBillAmount"] = billitem.totalAmount;
                                                        // item_orderdetails['currencyId'] = billitem.billCurrency;
                                                        item_orderdetails["expectedPaymentAmount"] =
                                                          billitem.billDistributionAmount;
                                                        item_orderdetails["isActive"] = "1";
                                                        //item_orderdetails['acno'] = billitem.tenantAccountReferenceNo

                                                        rowjsonObj.push(item);
                                                        rowjsonitem_order.push(item_order);
                                                        rowjsonorderdetails.push(item_orderdetails);

                                                        let partitem = {};

                                                        partitem["totalBillAmount"] = billitem.billDistributionAmount;
                                                        partitem["expectedPaymentAmount"] = billitem.totalAmount;
                                                        partitem["mappedBillId"] = billitem.billId;
                                                        partitem["billAmount"] = billitem.totalAmount;
                                                        partitem["otherCharges"] = billitem.otherCharges
                                                          ? billitem.otherCharges
                                                          : "00";
                                                        partitem["tax"] = billitem.tax ? billitem.tax : "00";
                                                        partitem["currencyid"] = billitem.billCurrency;
                                                        partitem["isActive"] = "1";
                                                        //item["billDate"] = pay.dataset.billdate;
                                                        var bill_date = billitem.billDate.split(" ")[0];
                                                        var billtempdate = bill_date.split("/");
                                                        // partitem['billDate'] = billtempdate[2]+"-"+ billtempdate[0] +"-"+ billtempdate[1];
                                                        partitem["billDate"] = billitem.billDate;
                                                        //item["billDate"] = "2020-09-11";

                                                        if (Number(billitem.billDistributionAmount) > 0) {
                                                          rowpartjsonObj.push(partitem);
                                                        }

                                                        // showgloader = false;

                                                        setFieldValue((values.showloader = false));
                                                        $('input[data-mappedbillid="' + billitem.billId + '"]').val(
                                                          Number(billitem.billDistributionAmount).toFixed(2),
                                                        );
                                                      });
                                                      sessionStorage.setItem(
                                                        "accountdistribution_partial",
                                                        JSON.stringify(rowjsonObj),
                                                      );
                                                      sessionStorage.setItem(
                                                        "order_partial",
                                                        JSON.stringify(rowjsonitem_order),
                                                      );
                                                      sessionStorage.setItem(
                                                        "orderdetails_partial",
                                                        JSON.stringify(rowjsonorderdetails),
                                                      );
                                                      sessionStorage.setItem(
                                                        "accountdistribution",
                                                        JSON.stringify(rowpartjsonObj),
                                                      );
                                                      sessionStorage.setItem(
                                                        "partaccountdistribution",
                                                        JSON.stringify(rowpartjsonObj),
                                                      );

                                                      this.setState({
                                                        amtdistributeflag: false,
                                                      });
                                                    } else {
                                                      this.setState({
                                                        amtdistributeflag: false,
                                                      });
                                                    }
                                                    // showgloader = false;

                                                    setFieldValue((values.showloader = false));
                                                  },
                                                );
                                              }

                                              $(".grand-amt1 .amount1").text(
                                                Number(sessionStorage.getItem("amounttopay")).toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                }),
                                              );
                                              $(".button-box  .btn-primary").removeClass("disable");
                                              // $(".calculatedistribution").removeClass('active');
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-6 col-md-6 col-lg-12">
                                    <div className="pay-balance grand-total">
                                      <span className="para">Grand Total</span>
                                      {this.state.amtdistributeflag === true ? (
                                        <div className="col-md-2 loading-cp">
                                          <Oval
                                            ariaLabel="loading-indicator"
                                            height={25}
                                            width={25}
                                            strokeWidth={5}
                                            color="blue"
                                            secondaryColor="white"
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <div
                                        className={
                                          sessionStorage.getItem("TotalAmount") <= 50
                                            ? "grand-amt1 amount1"
                                            : "amount3 grand-amt1 amount1"
                                        }>
                                        ${" "}
                                        <span className="amount1">
                                          {/* {this.state.totalAmount.toLocaleString(undefined, { minimumFractionDigits: 2 })} */}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row pay-buttons">
                                  <button
                                    onClick={() => this.setState({ click: "Now" })}
                                    type="submit"
                                    disabled={this.state.totalAmount === 0}
                                    className={`primary-button mr-2 ${
                                      this.state.totalAmount === 0 ? "disabled-btn" : "themeing-buttons"
                                    }`}>
                                    Pay Now
                                  </button>

                                  <button
                                    onClick={() => this.setState({ click: "Future" })}
                                    type="submit"
                                    disabled={this.state.totalAmount === 0}
                                    className={`primary-button ml-2 ${
                                      this.state.totalAmount === 0 ? "disabled-btn" : "themeing-secondary-buttons"
                                    }`}>
                                    Pay in Future
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 col-lg-8 px-0">
                              <div className="billdetails-content makepayment-table pay-balance-card-two">
                                <div className="listed-description">
                                  <h5 aria-level="1">
                                    Below is a list of your accounts. If you would like to apply your payment to a specific
                                    account, enter the amount for that account. Otherwise, payments will be applied to the
                                    oldest account first.
                                  </h5>
                                </div>

                                {/* <div className="warning-message-card">
                              <span>
                                <WarningIcon color="warning" />
                              </span>{" "}
                              <span className="warning-message">
                                DISCLAIMER: The balance shown is not updated in real-time and may not reflect credit for all
                                previous payments and/or adjustments. If you have any questions about this account please
                                contact us.
                              </span>
                            </div> */}
                                <Accordion defaultExpanded className="accordion-section homebilling-accordion-section">
                                  <AccordionSummary
                                    className="accordion-header outstanding-balance-info"
                                    expandIcon={<ExapandMoreIcon />}>
                                    <div className="col-12">
                                      <p className="accordion-heading-style ml-0">Open Accounts</p>
                                    </div>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    role="region"
                                    className="homebilling-accordiontable homebilling-accordion-details qp-accordion-details">
                                    <div
                                      className="billDetails-header accordion-section open-accounts-header"
                                      aria-label="billDetails">
                                      <Table>
                                        <TableBody>
                                          <TableRow className="heading-title accordion-table  makepayment-accordion-table">
                                            <TableCell>Account Number</TableCell>
                                            {/* <TableCell> Patient Name</TableCell> */}
                                            <TableCell>Account Type</TableCell>
                                            <TableCell>Date of Service</TableCell>
                                            <TableCell>Outstanding Amt</TableCell>
                                            <TableCell>Payment Amt</TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </div>
                                    {this.state.newbillingdata?.map((seedItem, index) => {
                                      return (
                                        <Accordion
                                          defaultExpanded
                                          className="accordion-section"
                                          key={seedItem.seedAccountNumber}>
                                          <AccordionSummary
                                            className="accordion-header outstanding-balance-info"
                                            expandIcon={<ExapandMoreIcon />}>
                                            <p className="account-number">{seedItem?.seedAccountNumber}</p>
                                            <p>
                                              Outstanding Balance:{" "}
                                              <span className="text-bold">
                                                ${" "}
                                                {seedItem?.oustandingBalance.toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                })}
                                              </span>
                                            </p>
                                            {/* {seedItem?.recurringPaymentId !== null ? (
                                        <div>
                                          <Tooltip
                                            title={"To view plan details, Please login using credentials from secure login"}>
                                            <Button className="download-button" variant="outlined" color="disabled" disabled>
                                              View Plan Details
                                            </Button>
                                          </Tooltip>
                                          ) : (
                                          <Tooltip title={"Please contact support for more information"}>
                                            <Button
                                              className="download-button"
                                              variant="outlined"
                                              disabled={seedItem?.recurringPaymentId ? false : true}
                                              color="disabled">
                                              View Plan Details
                                            </Button>
                                          </Tooltip>
                                        </div>
                                      ) : (
                                        <Button
                                          className="download-button"
                                          variant="outlined"
                                          color="primary"
                                          onClick={() => this.handlePayPlanOptions(seedItem?.seedAccountNumber)}>
                                          Payment Options
                                        </Button>
                                      )} */}
                                            {seedItem?.inPayPlan ? (
                                              <div>
                                                {seedItem?.recurringPaymentId !== null ? (
                                                  <Tooltip
                                                    title={
                                                      "To view plan details, Please login using credentials from secure login"
                                                    }>
                                                    <div>
                                                      <Button
                                                        variant="outlined"
                                                        disabled={seedItem?.recurringPaymentId ? false : true}>
                                                        View Plan Details
                                                      </Button>
                                                    </div>
                                                  </Tooltip>
                                                ) : (
                                                  <Tooltip title={"Please contact support for more information"}>
                                                    <div className="viewplan-details">
                                                      <Button
                                                        variant="outlined"
                                                        disabled={seedItem?.recurringPaymentId ? false : true}
                                                        color="primary">
                                                        View Plan Details
                                                      </Button>
                                                    </div>
                                                  </Tooltip>
                                                )}
                                              </div>
                                            ) : (
                                              <div className="payment-options">
                                                <Button
                                                  className="download-button"
                                                  variant="outlined"
                                                  color="primary"
                                                  onClick={() => this.handlePayPlanOptions(seedItem?.seedAccountNumber)}>
                                                  Payment Options
                                                </Button>
                                              </div>
                                            )}
                                          </AccordionSummary>
                                          {sessionStorage.getItem("currentUserRole") === "Customer" ||
                                            (sessionStorage.getItem("currentUserRole") === "quickpay" &&
                                              this.state?.settlementData &&
                                              this.state?.settlementData?.settlementResponseModel?.map((item) => {
                                                //checking

                                                if (item.masterAccountReferenceNumber === seedItem.seedAccountNumber) {
                                                  return item.settlementStatus === "REVISED" ||
                                                    item.settlementStatus === "APPROVED" ||
                                                    item.settlementStatus === "REJECTED" ||
                                                    item.settlementStatus === "PENDING" ? (
                                                    <div className="settlement-offer">
                                                      <div className="settlement-offer-details">
                                                        <p className="mb-0">
                                                          Settlement offer is {item.settlementStatus}
                                                          {item.settlementStatus === "REVISED" && " to"}
                                                          <span className="amount-settle">
                                                            {" "}
                                                            {` ( $ ${
                                                              item.settlementStatus === "REVISED"
                                                                ? Number(item.revisedAmount).toLocaleString(undefined, {
                                                                    minimumFractionDigits: 2,
                                                                  })
                                                                : Number(item.proposedAmount).toLocaleString(undefined, {
                                                                    minimumFractionDigits: 2,
                                                                  })
                                                            } )`}
                                                          </span>
                                                          {/* eslint-disable jsx-a11y/click-events-have-key-events */}
                                                          {/* eslint-disable jsx-a11y/no-static-element-interactions */}
                                                        </p>
                                                        <p className="mb-0">
                                                          {" "}
                                                          <span
                                                            onClick={() => {
                                                              this.handleMannualSettlement(item, seedItem);
                                                            }}>
                                                            Details
                                                          </span>
                                                          {/* eslint-disable jsx-a11y/click-events-have-key-events */}
                                                          {/* eslint-disable jsx-a11y/no-static-element-interactions */}
                                                        </p>
                                                        {item.settlementExpiryDate && (
                                                          <p className="mb-0">
                                                            Expiration date:{" "}
                                                            <span class="date-settle">
                                                              {` ${moment(item.settlementExpiryDate).format("MM/DD/YYYY")}`}
                                                            </span>
                                                          </p>
                                                        )}
                                                      </div>
                                                    </div>
                                                  ) : null;
                                                }
                                              }))}
                                          <AccordionDetails role="region" className="qp-accordion-details">
                                            <table style={{ width: "100%" }}>
                                              <tbody>
                                                {seedItem?.bills?.map((billitem, index) => {
                                                  return (
                                                    <tr
                                                      className="account-info qp-open-accounts"
                                                      key={billitem?.billType + index}>
                                                      <td>
                                                        <div className={` ${billitem.billType === "Legal" ? "" : ""}`}>
                                                          {billitem.accountReferenceNumber}
                                                        </div>
                                                      </td>
                                                      {/* <td>
                                                    <div class={` ${billitem.billType === "Legal" ? "" : ""}`}>
                                                      {billitem?.payer?.firstName.charAt(0).toUpperCase() +
                                                        billitem?.payer?.firstName.slice(1) +
                                                        " " +
                                                        billitem?.payer?.lastName.charAt(0).toUpperCase() +
                                                        billitem?.payer?.lastName.slice(1)}
                                                    </div>
                                                  </td> */}
                                                      <td>
                                                        <div
                                                          className={` ${
                                                            billitem.billType === "Legal" ? "legal-text" : "collection"
                                                          }`}>
                                                          {billitem.billType}
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className={` ${billitem.billType === "Legal" ? "" : ""}`}>
                                                          {billitem?.billDate?.split(" ")[0]}
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className={` ${billitem.billType === "Legal" ? "" : ""}`}>
                                                          ${" "}
                                                          {Number(billitem.currentBalanceAmount).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                          })}
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div
                                                          className={` bill-payment-amt my-1  make-payment-input ${
                                                            billitem.billType === "Legal" ? "" : ""
                                                          }`}>
                                                          {/* <span class={`${billitem.billType === "Legal" ? 'red-text':""} bill-dollar`}>$</span> */}
                                                          <b
                                                            className={`${
                                                              billitem.billType === "Legal" ? "" : ""
                                                            } admin-bill-dollar-cp  mr-1`}>
                                                            {" "}
                                                            $
                                                          </b>
                                                          <Field
                                                            type="number"
                                                            id={"paymentAmount" + billitem.accountReferenceNumber + index}
                                                            name={"paymentAmount" + billitem.accountReferenceNumber + index}
                                                            step="0.01"
                                                            disabled={sessionStorage.getItem("TotalAmount") <= 50}
                                                            data-acno={billitem.accountno}
                                                            data-mappedBillId={billitem.billId}
                                                            data-billAmount={billitem.billAmount}
                                                            data-billDate={billitem.billDate}
                                                            data-othercharges={billitem.otherCharges}
                                                            data-tax={billitem.tax}
                                                            data-currencyid={billitem.currencyId}
                                                            data-isActive="1"
                                                            defaultValue="0.00"
                                                            min="0"
                                                            max={Number(billitem.currentBalanceAmount)}
                                                            className={
                                                              Number(billitem.outstandingamt) > "49.99"
                                                                ? `form-control  chosenamount`
                                                                : `form-control  chosenamount smallamount`
                                                            }
                                                            onClick={(event) => {
                                                              var x = event.target.getAttribute("id");
                                                              $("#" + x).val("");
                                                            }}
                                                            onChange={(event) => {
                                                              var minamount = Number(event.target.getAttribute("min"));
                                                              var maxamount = Number(event.target.getAttribute("max"));

                                                              var x = event.target.getAttribute("id");
                                                              $("#" + x).val(event.target.value);
                                                            }}
                                                            onBlur={(event) => {
                                                              var data = Number(event.target.value);
                                                              var amount = Number(this.state.outstandingAmount3);

                                                              var minamount = Number(event.target.getAttribute("min"));
                                                              var maxamount = Number(billitem.currentBalanceAmount);

                                                              var totaltobepaid = 0;
                                                              var rowdata = "";
                                                              var rowjsonObj = [];
                                                              var changeddata = [];
                                                              var x = event.target.getAttribute("id");

                                                              var curval = event.target.value.toLocaleString(undefined, {
                                                                minimumFractionDigits: 2,
                                                              });
                                                              //$("#"+x).val(curval);
                                                              $("#" + x).val(Number(event.target.value).toFixed(2));

                                                              if (event.target.value > maxamount) {
                                                                event.target.value = 0;

                                                                //$("#"+x).val(curval);
                                                                var paydata = document.querySelectorAll(".chosenamount");

                                                                var arr = Array.from(paydata);

                                                                arr.map((pay, index) => {
                                                                  totaltobepaid += Number(pay.valueAsNumber);

                                                                  let item = {};

                                                                  item["totalBillAmount"] = pay.valueAsNumber;
                                                                  item["expectedPaymentAmount"] = pay.max;
                                                                  item["mappedBillId"] = pay.dataset.mappedbillid;
                                                                  item["billAmount"] = pay.dataset.billamount;

                                                                  //item["billDate"] = pay.dataset.billdate;
                                                                  var bill_date = pay.dataset.billdate.split(" ")[0];
                                                                  var billtempdate = bill_date.split("/");

                                                                  item["billDate"] =
                                                                    billtempdate[2] +
                                                                    "-" +
                                                                    billtempdate[0] +
                                                                    "-" +
                                                                    billtempdate[1];
                                                                  //item["billDate"] = "2020-09-11";
                                                                  item["othercharges"] = pay.dataset.othercharges;
                                                                  item["tax"] = pay.dataset.tax;
                                                                  item["currencyid"] = pay.dataset.currencyid;
                                                                  item["isActive"] = "1";
                                                                  rowjsonObj.push(item);

                                                                  // Partial distribution data with new service

                                                                  let changeditem = {};
                                                                  changeditem["accountReferenceNumber"] = pay.dataset.acno;
                                                                  changeditem["billDate"] = pay.dataset.billdate;
                                                                  changeditem["mappedBillId"] = pay.dataset.mappedbillid;
                                                                  changeditem["tax"] = pay.dataset.tax;
                                                                  changeditem["otherCharges"] = pay.dataset.othercharges;
                                                                  changeditem["totalBillAmount"] = $(
                                                                    "#" + pay.attributes[2].nodeValue,
                                                                  ).val();
                                                                  changeditem["billAmount"] = $(
                                                                    "#" + pay.attributes[2].nodeValue,
                                                                  ).val();
                                                                  changeditem["isActive"] = "1";
                                                                  changeditem["expectedPaymentAmount"] = pay.max;

                                                                  if (
                                                                    Number($("#" + pay.attributes[2].nodeValue).val()) > 0
                                                                  ) {
                                                                    changeddata.push(changeditem);
                                                                  }

                                                                  this.setState({
                                                                    payAmount: Number(totaltobepaid).toFixed(2),
                                                                  });
                                                                });

                                                                $("#payAmount").val(Number(totaltobepaid).toFixed(2));
                                                                $(".grand-amt1 span.amount1").text(
                                                                  Number(totaltobepaid).toFixed(2),
                                                                );

                                                                $("#PayTotalAmount").prop("checked", false);
                                                                $("#PayPartialAmount").prop("checked", true);

                                                                //$("#"+x).val(curval);
                                                                var paydata = document.querySelectorAll(".chosenamount");

                                                                var arr = Array.from(paydata);

                                                                arr.map((pay, index) => {
                                                                  totaltobepaid += Number(pay.valueAsNumber);

                                                                  let item = {};

                                                                  item["totalBillAmount"] = pay.valueAsNumber;
                                                                  item["expectedPaymentAmount"] = pay.max;
                                                                  item["mappedBillId"] = pay.dataset.mappedbillid;
                                                                  item["billAmount"] = pay.dataset.billamount;

                                                                  //item["billDate"] = pay.dataset.billdate;
                                                                  var bill_date = pay.dataset.billdate.split(" ")[0];
                                                                  var billtempdate = bill_date.split("/");

                                                                  item["billDate"] =
                                                                    billtempdate[2] +
                                                                    "-" +
                                                                    billtempdate[0] +
                                                                    "-" +
                                                                    billtempdate[1];
                                                                  //item["billDate"] = "2020-09-11";
                                                                  item["othercharges"] = pay.dataset.othercharges;
                                                                  item["tax"] = pay.dataset.tax;
                                                                  item["currencyid"] = pay.dataset.currencyid;
                                                                  item["isActive"] = "1";
                                                                  rowjsonObj.push(item);

                                                                  // Partial distribution data with new service

                                                                  let changeditem = {};
                                                                  changeditem["accountReferenceNumber"] = pay.dataset.acno;
                                                                  changeditem["billDate"] = pay.dataset.billdate;
                                                                  changeditem["mappedBillId"] = pay.dataset.mappedbillid;
                                                                  changeditem["tax"] = pay.dataset.tax;
                                                                  changeditem["otherCharges"] = pay.dataset.othercharges;
                                                                  changeditem["totalBillAmount"] = $(
                                                                    "#" + pay.attributes[2].nodeValue,
                                                                  ).val();
                                                                  changeditem["billAmount"] = $(
                                                                    "#" + pay.attributes[2].nodeValue,
                                                                  ).val();
                                                                  changeditem["isActive"] = "1";
                                                                  changeditem["expectedPaymentAmount"] = pay.max;

                                                                  if (
                                                                    Number($("#" + pay.attributes[2].nodeValue).val()) > 0
                                                                  ) {
                                                                    changeddata.push(changeditem);
                                                                  }

                                                                  this.setState({
                                                                    payAmount: Number(totaltobepaid).toFixed(2),
                                                                  });
                                                                });

                                                                $("#payAmount").val(Number(totaltobepaid).toFixed(2));
                                                                $(".grand-amt1 span.amount1").text(
                                                                  Number(totaltobepaid).toFixed(2),
                                                                );

                                                                $("#PayTotalAmount").prop("checked", false);
                                                                $("#PayPartialAmount").prop("checked", true);

                                                                sessionStorage.setItem("partialpaymentflag", true);

                                                                this.setState({
                                                                  payAmount: Number(totaltobepaid).toFixed(2),
                                                                });

                                                                setFieldValue(
                                                                  (values.PayTotalAmount = false),
                                                                  (values.PayPartialAmount = true),
                                                                  (values.showloader = false),
                                                                  (values.payAmount = Number(totaltobepaid).toFixed(2)),
                                                                );

                                                                sessionStorage.setItem(
                                                                  "apportionedamount",
                                                                  Number(totaltobepaid),
                                                                );
                                                                sessionStorage.setItem("amounttopay", Number(totaltobepaid));
                                                                sessionStorage.setItem(
                                                                  "accountdistribution_sum",
                                                                  JSON.stringify(rowjsonObj),
                                                                );
                                                                sessionStorage.setItem(
                                                                  "accountdistribution_changeddata_sum",
                                                                  JSON.stringify(changeddata),
                                                                );
                                                                if (
                                                                  Number(totaltobepaid) <
                                                                  Number(sessionStorage.getItem("amounttopay"))
                                                                ) {
                                                                  console.log("warning");
                                                                }

                                                                setFieldValue((values.showloader = false));
                                                              } else if (event.target.value < 0) {
                                                                event.target.value = 0;

                                                                var paydata = document.querySelectorAll(".chosenamount");

                                                                var arr = Array.from(paydata);

                                                                arr.map((pay, index) => {
                                                                  totaltobepaid += Number(pay.valueAsNumber);

                                                                  let item = {};

                                                                  item["totalBillAmount"] = pay.valueAsNumber;
                                                                  item["expectedPaymentAmount"] = pay.max;
                                                                  item["mappedBillId"] = pay.dataset.mappedbillid;
                                                                  item["billAmount"] = pay.dataset.billamount;

                                                                  var bill_date = pay.dataset.billdate.split(" ")[0];
                                                                  var billtempdate = bill_date.split("/");

                                                                  item["billDate"] =
                                                                    billtempdate[2] +
                                                                    "-" +
                                                                    billtempdate[0] +
                                                                    "-" +
                                                                    billtempdate[1];
                                                                  item["othercharges"] = pay.dataset.othercharges;
                                                                  item["tax"] = pay.dataset.tax;
                                                                  item["currencyid"] = pay.dataset.currencyid;
                                                                  item["isActive"] = "1";
                                                                  rowjsonObj.push(item);

                                                                  let changeditem = {};
                                                                  changeditem["accountReferenceNumber"] = pay.dataset.acno;
                                                                  changeditem["billDate"] = pay.dataset.billdate;
                                                                  changeditem["mappedBillId"] = pay.dataset.mappedbillid;
                                                                  changeditem["tax"] = pay.dataset.tax;
                                                                  changeditem["otherCharges"] = pay.dataset.othercharges;
                                                                  changeditem["totalBillAmount"] = $(
                                                                    "#" + pay.attributes[2].nodeValue,
                                                                  ).val();
                                                                  changeditem["billAmount"] = $(
                                                                    "#" + pay.attributes[2].nodeValue,
                                                                  ).val();
                                                                  changeditem["isActive"] = "1";
                                                                  changeditem["expectedPaymentAmount"] = pay.max;

                                                                  if (
                                                                    Number($("#" + pay.attributes[2].nodeValue).val()) > 0
                                                                  ) {
                                                                    changeddata.push(changeditem);
                                                                  }

                                                                  this.setState({
                                                                    payAmount: Number(totaltobepaid).toFixed(2),
                                                                  });
                                                                });

                                                                $("#payAmount").val(Number(totaltobepaid).toFixed(2));
                                                                $(".grand-amt1 span.amount1").text(
                                                                  Number(totaltobepaid).toFixed(2),
                                                                );

                                                                $("#PayTotalAmount").prop("checked", false);
                                                                $("#PayPartialAmount").prop("checked", true);

                                                                sessionStorage.setItem("partialpaymentflag", true);

                                                                this.setState({
                                                                  payAmount: Number(totaltobepaid).toFixed(2),
                                                                });

                                                                setFieldValue(
                                                                  (values.PayTotalAmount = false),
                                                                  (values.PayPartialAmount = true),
                                                                  (values.showloader = false),
                                                                  (values.payAmount = Number(totaltobepaid).toFixed(2)),
                                                                );

                                                                sessionStorage.setItem(
                                                                  "apportionedamount",
                                                                  Number(totaltobepaid),
                                                                );
                                                                sessionStorage.setItem("amounttopay", Number(totaltobepaid));
                                                                sessionStorage.setItem(
                                                                  "accountdistribution_sum",
                                                                  JSON.stringify(rowjsonObj),
                                                                );
                                                                sessionStorage.setItem(
                                                                  "accountdistribution_changeddata_sum",
                                                                  JSON.stringify(changeddata),
                                                                );
                                                                if (
                                                                  Number(totaltobepaid) <
                                                                  Number(sessionStorage.getItem("amounttopay"))
                                                                ) {
                                                                  console.log("warning");
                                                                }

                                                                setFieldValue((values.showloader = false));
                                                              } else {
                                                                //$("#"+x).val(curval);
                                                                var paydata = document.querySelectorAll(".chosenamount");

                                                                var arr = Array.from(paydata);

                                                                arr.map((pay, index) => {
                                                                  totaltobepaid += Number(pay.valueAsNumber);

                                                                  let item = {};

                                                                  item["totalBillAmount"] = pay.valueAsNumber;
                                                                  item["expectedPaymentAmount"] = pay.max;
                                                                  item["mappedBillId"] = pay.dataset.mappedbillid;
                                                                  item["billAmount"] = pay.dataset.billamount;

                                                                  var bill_date = pay.dataset.billdate.split(" ")[0];
                                                                  var billtempdate = bill_date.split("/");

                                                                  item["billDate"] =
                                                                    billtempdate[2] +
                                                                    "-" +
                                                                    billtempdate[0] +
                                                                    "-" +
                                                                    billtempdate[1];
                                                                  item["othercharges"] = pay.dataset.othercharges;
                                                                  item["tax"] = pay.dataset.tax;
                                                                  item["currencyid"] = pay.dataset.currencyid;
                                                                  item["isActive"] = "1";
                                                                  rowjsonObj.push(item);

                                                                  // Partial distribution data with new service

                                                                  let changeditem = {};
                                                                  changeditem["accountReferenceNumber"] = pay.dataset.acno;
                                                                  changeditem["billDate"] = pay.dataset.billdate;
                                                                  changeditem["mappedBillId"] = pay.dataset.mappedbillid;
                                                                  changeditem["tax"] = pay.dataset.tax;
                                                                  changeditem["otherCharges"] = pay.dataset.othercharges;
                                                                  changeditem["totalBillAmount"] = $(
                                                                    "#" + pay.attributes[2].nodeValue,
                                                                  ).val();
                                                                  changeditem["billAmount"] = $(
                                                                    "#" + pay.attributes[2].nodeValue,
                                                                  ).val();
                                                                  changeditem["isActive"] = "1";
                                                                  changeditem["expectedPaymentAmount"] = pay.max;

                                                                  if (
                                                                    Number($("#" + pay.attributes[2].nodeValue).val()) > 0
                                                                  ) {
                                                                    changeddata.push(changeditem);
                                                                  }
                                                                });

                                                                $("#payAmount").val(Number(totaltobepaid).toFixed(2));
                                                                $(".grand-amt1 span.amount1").text(
                                                                  Number(totaltobepaid).toFixed(2),
                                                                );

                                                                $("#PayTotalAmount").prop("checked", false);
                                                                $("#PayPartialAmount").prop("checked", true);

                                                                sessionStorage.setItem("partialpaymentflag", true);

                                                                setFieldValue(
                                                                  (values.PayTotalAmount = false),
                                                                  (values.PayPartialAmount = true),
                                                                  (values.showloader = false),
                                                                  (values.payAmount = Number(totaltobepaid).toFixed(2)),
                                                                );

                                                                this.setState({
                                                                  payAmount: Number(totaltobepaid).toFixed(2),
                                                                });

                                                                sessionStorage.setItem(
                                                                  "apportionedamount",
                                                                  Number(totaltobepaid),
                                                                );
                                                                sessionStorage.setItem("amounttopay", Number(totaltobepaid));
                                                                sessionStorage.setItem(
                                                                  "accountdistribution_sum",
                                                                  JSON.stringify(rowjsonObj),
                                                                );
                                                                sessionStorage.setItem(
                                                                  "accountdistribution_changeddata_sum",
                                                                  JSON.stringify(changeddata),
                                                                );
                                                                if (
                                                                  Number(totaltobepaid) <
                                                                  Number(sessionStorage.getItem("amounttopay"))
                                                                ) {
                                                                  console.log("warning");
                                                                }

                                                                setFieldValue((values.showloader = false));
                                                              }
                                                            }}
                                                          />
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  );
                                                })}
                                              </tbody>
                                            </table>
                                          </AccordionDetails>
                                        </Accordion>
                                      );
                                    })}
                                  </AccordionDetails>
                                </Accordion>

                                {this?.state?.settledBills?.length ? (
                                  <Accordion defaultExpanded className="accordion-section homebilling-accordion-section">
                                    <AccordionSummary
                                      className="accordion-header outstanding-balance-info"
                                      expandIcon={<ExapandMoreIcon />}>
                                      <div className="col-12">
                                        <p className="accordion-heading-style ml-0">Settled accounts</p>
                                      </div>
                                    </AccordionSummary>

                                    <AccordionDetails
                                      role="region"
                                      className="homebilling-accordion-table homebilling-accordion-details qp-accordion-details">
                                      <Table>
                                        <div className="billDetails-header accordion-section" aria-label="billDetails">
                                          <TableBody>
                                            <TableRow className="heading-title billing-table accordion-table makepayment-accordion-table">
                                              <TableCell>Account Number</TableCell>
                                              {/* <TableCell>Patient Name</TableCell> */}
                                              <TableCell>Account Type</TableCell>
                                              <TableCell>Date of Service</TableCell>
                                              {/* <TableCell>Outstanding Amt</TableCell> */}
                                            </TableRow>
                                          </TableBody>
                                        </div>
                                        <TableBody>
                                          {this?.state?.settledBills?.map((item, index) => {
                                            return (
                                              <div className="qp-closed-accounts" key={index}>
                                                <TableRow className="accordion-table  qp-billing-data makepayment-accordion-table">
                                                  <TableCell>{item?.accountReferenceNumber}</TableCell>
                                                  {/* <TableCell>{item?.payer?.firstName}</TableCell> */}
                                                  <TableCell
                                                    class={` ${item.billType === "Legal" ? "legal-text" : "collection"}`}>
                                                    {" "}
                                                    {item?.billType}
                                                  </TableCell>
                                                  <TableCell>
                                                    {item?.billDate ? new Date(item?.billDate)?.toLocaleDateString() : "--"}
                                                  </TableCell>
                                                  {/* <TableCell className="outstanding-amount">
                                                  $
                                                  {Number(item?.currentBalanceAmount)?.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                  })}
                                                </TableCell> */}
                                                </TableRow>
                                              </div>
                                            );
                                          })}
                                        </TableBody>
                                      </Table>
                                    </AccordionDetails>
                                  </Accordion>
                                ) : null}
                                {this?.state?.restrictedBills?.length ? (
                                  <Accordion defaultExpanded className="accordion-section homebilling-accordion-section">
                                    <AccordionSummary
                                      className="accordion-header outstanding-balance-info"
                                      expandIcon={<ExapandMoreIcon />}>
                                      <div className="col-12">
                                        <p className="accordion-heading-style ml-0">Restricted accounts</p>
                                      </div>
                                    </AccordionSummary>

                                    <AccordionDetails
                                      role="region"
                                      className="homebilling-accordion-table homebilling-accordion-details qp-accordion-details">
                                      <Table>
                                        <div className="billDetails-header accordion-section" aria-label="billDetails">
                                          <TableBody>
                                            <TableRow className="heading-title billing-table accordion-table makepayment-accordion-table">
                                              <TableCell>Account Number</TableCell>
                                              {/* <TableCell>Patient Name</TableCell> */}
                                              <TableCell>Account Type</TableCell>
                                              <TableCell>Date of Service</TableCell>
                                              <TableCell>Outstanding Amt</TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </div>
                                        <TableBody>
                                          {this?.state?.restrictedBills?.map((item, index) => {
                                            return (
                                              <div className="qp-closed-accounts" key={index}>
                                                <TableRow className="accordion-table  qp-billing-data makepayment-accordion-table">
                                                  <TableCell>{item?.accountReferenceNumber}</TableCell>
                                                  {/* <TableCell>{item?.payer?.firstName}</TableCell> */}
                                                  <TableCell
                                                    class={` ${item.billType === "Legal" ? "legal-text" : "collection"}`}>
                                                    {" "}
                                                    {item?.billType}
                                                  </TableCell>
                                                  <TableCell>
                                                    {item?.billDate ? new Date(item?.billDate)?.toLocaleDateString() : "--"}
                                                  </TableCell>
                                                  <TableCell className="outstanding-amount">
                                                    ${" "}
                                                    {Number(item?.currentBalanceAmount)?.toLocaleString(undefined, {
                                                      minimumFractionDigits: 2,
                                                    })}
                                                  </TableCell>
                                                </TableRow>
                                              </div>
                                            );
                                          })}
                                        </TableBody>
                                      </Table>
                                    </AccordionDetails>
                                  </Accordion>
                                ) : null}

                                <Accordion defaultExpanded className="accordion-section homebilling-accordion-section">
                                  <AccordionSummary
                                    className="accordion-header outstanding-balance-info"
                                    expandIcon={<ExapandMoreIcon />}>
                                    <div className="col-12">
                                      <p className="accordion-heading-style ml-0">Closed Accounts</p>
                                    </div>
                                  </AccordionSummary>

                                  <AccordionDetails
                                    role="region"
                                    className="homebilling-accordion-table homebilling-accordion-details qp-accordion-details">
                                    <Table>
                                      <div className="billDetails-header accordion-section" aria-label="billDetails">
                                        <TableBody>
                                          <TableRow className="heading-title billing-table accordion-table makepayment-accordion-table">
                                            <TableCell>Account Number</TableCell>
                                            {/* <TableCell>Patient Name</TableCell> */}
                                            <TableCell>Account Type</TableCell>
                                            <TableCell>Date of Service</TableCell>
                                            <TableCell>Outstanding Amt</TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </div>
                                      <TableBody>
                                        {this?.state?.closedBillingData?.map((item, index) => {
                                          return (
                                            <div className="qp-closed-accounts" key={index}>
                                              <TableRow className="accordion-table  qp-billing-data makepayment-accordion-table">
                                                <TableCell>{item?.accountReferenceNumber}</TableCell>
                                                {/* <TableCell>{item?.payer?.firstName}</TableCell> */}
                                                <TableCell
                                                  class={` ${item.billType === "Legal" ? "legal-text" : "collection"}`}>
                                                  {" "}
                                                  {item?.billType}
                                                </TableCell>
                                                <TableCell>
                                                  {item?.billDate ? new Date(item?.billDate)?.toLocaleDateString() : "--"}
                                                </TableCell>
                                                <TableCell className="outstanding-amount">
                                                  ${" "}
                                                  {Number(item?.currentBalanceAmount)?.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                  })}
                                                </TableCell>
                                              </TableRow>
                                            </div>
                                          );
                                        })}
                                      </TableBody>
                                    </Table>
                                  </AccordionDetails>
                                </Accordion>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="heading9 qp-footer-section">
                  <p>
                    Need Help?{" "}
                    <a href="/contact-Us" className="heading10">
                      Contact Us
                    </a>
                  </p>
                  <p>Copyright {new Date().getFullYear()}. All Rights Reserved.</p>
                  <p>
                    WE ARE DEBT COLLECTORS. THIS IS AN ATTEMPT TO COLLECT A DEBT. ANY INFORMATION OBTAINED WILL BE USED FOR
                    THAT PURPOSE.
                  </p>
                </div> */}
                      </div>
                    </Form>
                  </div>
                  <div div className="footer-section-cp ml-0 mt-2">
                    <Admin_Footer />
                  </div>
                </div>
              </>
            )}
          </Formik>
          {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
          {/* <ToastContainer /> */}
        </>
      );
    }
  },
);

export default HomeBilling;
