import React, { useState } from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Checkbox, MenuItem, FormControl, FormControlLabel, Select, FormLabel, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { FormHelperText, IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import ImageTooltip from "../../../../components/ImageTooltip";
import echeckPaymentRoutingNumber from "../../../../assets/images/echeckPaymentRoutingNumber.jpg";
import CardPaymentTooltip from "../../../../assets/images/CardPaymentTooltip.jpg";
import { cityData, countryData, allStatesData } from "../../../../util/StatesData";
import { useHistory } from "react-router";
import { Grid } from "@material-ui/core";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import CreditCard from "../../../../components/CreditCard";
import cvvImage from "../../../../assets/Images2/images/cvv_marker.jpg";
import PP_QuickPayStepper from "../../../../Common/PP_QuickPayStepper";
import TermsAndConditions from "../../../../components/TermsAndConditions/TermsAndConditions";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import visa from "../../../../assets/images/visa.png";
import master from "../../../../assets/images/master-card.png";
import amx from "../../../../assets/images/amx.png";

// import SpinnerPage from "../../commons/PP_Loader";
import { requestInfo_PP } from "../../../../Common/CommonFn";
// import {
//   error,
//   success,
//   warning,
//   warningNotification,
//   errorNotification,
//   successNotification,
// } from "../../../../components/PP_Notification/PP_Notification";
import { paymentCreditCardService } from "../../../../services/paymentCreditCardService";
import $ from "jquery";
import InputMask from "react-input-mask";
import { RegionManagementService } from "../../../../admin_services/regionManagementService";

import { whiteLabelService } from "../../../../admin_services/whiteLabelService";

import { loginService } from "../../../../admin_services/loginService";

import { OutstandingPayerService } from "../../../../services/payerOutstandingService";

import { I18nextProvider } from "react-i18next";
import { Translation } from "react-i18next";
import i18n from "../../../../i18n";
import { PayButton, CancelButton, CheckBoxField } from "../../../../assets/MaterialControl";
// import DatePicker from "react-date-picker";
import moment from "moment";
import { countryList, stateList } from "../../../../containers/RegionList";
import Stepper from "../Stepper/HorizantalStepper";
import { handleSuccess, ToastContainer, handleError, handleWarning } from "../../../../components/Notifications/Toast";
import SpinnerPage from "../../../../Common/PP_loader";
import { settlementService } from "../../../../services/settlementServices";
import visaImage from "../../../../assets/images/visa.png";
import masterImage from "../../../../assets/images/master-card.png";
import amexImage from "../../../../assets/images/amx.png";
import discoverImage from "../../../../assets/images/discover.png";
import { capitalize } from "../../../../util/Captilize";
import CardFormatter from "../../../../util/CreditCardFormatter";

class MakePayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUserRole: "",
      showPassword: false,
      showForm: true,
      showloader: false,
      countryList: [],
      cityList: [],
      country: "united states of america",
      state: "",
      city: "",
      carddata: "",
      payeraddrdata: "",
      isMaskOne: true,
      instrumentid: "",
      newinstrumentid: "",
      cardId: "",
      currentyear: new Date().getFullYear(),
      ES_AppId: "",
      ES_PartyId: "",
      ES_Amount: "",
      ES_SubId: "",
      ES_Method: "",
      ES_OrderId: "",
      ES_url: "",
      ES_PlanId: "",
      ES_PriceSubId: "",
      ES_PurchaseParameters: "",
      ES_JsonData: "",
      futureDate: null,
      futureDate1: null,
      futureMonthDays: null,
      lastDayOfMonth: moment().endOf("month").format("MM/DD/YYYY"),
      secondLastDayOfMonth: moment(this.state?.lastDayOfMonth).subtract(1, "days"),
      thirdLastDayOfMonth: moment(this.state?.lastDayOfMonth).subtract(2, "days"),
      page: this.props?.location?.state?.page,
      selectedCardData: {
        cardNumber: "",
        cardMonth: "none",
        cardYear: "none",
        instrumentId: "",
        cardId: "",
        linkedPaymentMethodEntityId: "",
        cardType: "",
      },
      billingAddress: {
        billingAddressId: "",
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phoneNo: "",
        street: "",
        street2: "",
        city: "",
        state: "",
        country: "united states of america",
        postalCode: "",
      },
      showMaskedCardNumber: "",
      cardtype: false,
      // flag:true
    };
    this.myRef = React.createRef();
  }

  cardImages = [
    {
      name: "visaLogo",
      cardImage: visa,
    },
    {
      name: "masterLogo",
      cardImage: master,
    },
    {
      name: "amxLogo",
      cardImage: amx,
    },
  ];

  schema = Yup.object().shape({});
  componentWillMount() {
    sessionStorage.setItem("screen", "Paymentconfirmation");
  }

  _dateValidationHandeling() {
    var currentDate = moment().toDate();
    var futureMonth = moment(currentDate).add(1, "M");
    var futureMonthEnd = moment(futureMonth).endOf("month");
    var formattedFutureDate = moment(futureMonthEnd._d).format("MM/DD/YYYY");
    var daysInMonth = moment(formattedFutureDate).daysInMonth();
    this.setState({
      futureDate1: formattedFutureDate,
      futureMonthDays: daysInMonth,
    });
  }
  componentDidMount() {
    this._dateValidationHandeling();
    var queryString = "";
    queryString = window.location.search.split("&");
    var visapattern = /^4[0-9]{12}(?:[0-9]{3})?$/;
    var masterpattern = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/;
    var amexpattern = /^3[47][0-9]{13}$/;
    //var discoverpattern = /^6(?:011|5[0-9]{2})[0-9]{12}$/;
    var cardTypePattern = "";
    var payeruserdata = JSON.parse(sessionStorage.getItem("verifypayer"));
    const userRole = sessionStorage.getItem("currentUserRole");
    this.setState({ currentUserRole: userRole || "" });

    if (this.props.selectedCard && this.props.selectedCard.length > 0) {
      if (visapattern.exec(this.props.selectedCard[0].cardNumber.replace(/ /g, ""))) {
        cardTypePattern = "visa";
      } else if (masterpattern.exec(this.props.selectedCard[0].cardNumber.replace(/ /g, ""))) {
        cardTypePattern = "mast";
      } else if (amexpattern.exec(this.props.selectedCard[0].cardNumber.replace(/ /g, ""))) {
        cardTypePattern = "amex";
      }

      this.setState({
        cardtype: cardTypePattern,
      });
    }
    if (this.props.selectedCard && this.props.selectedCard.length > 0) {
      let getCardNumber = this.props.selectedCard[0].cardNumber;

      let getLastFourDigits = getCardNumber?.slice(-4);

      let maskedcardNumber = getLastFourDigits.padStart(getCardNumber.length, "*");
      this.setState({
        ...this.state,
        billingAddress: {
          ...this.state.billingAddress,
          billingAddressId: this.props.selectedCard[0].billingAddress?.billingAddressId || 0,
          firstName: this.props.selectedCard[0].billingAddress?.firstName || "",
          middleName: this.props.selectedCard[0].billingAddress?.middleName || "",
          lastName: this.props.selectedCard[0].billingAddress?.lastName || "",
          email: this.props.selectedCard[0].billingAddress?.email || "",
          phoneNo: this.props.selectedCard[0].billingAddress?.phoneNo || "",
          street: this.props.selectedCard[0].billingAddress?.street || "",
          street2: this.props.selectedCard[0].billingAddress?.street2 || "",
          city: this.props.selectedCard[0].billingAddress?.city || "",
          state: this.props.selectedCard[0].billingAddress?.state || "",
          country: this.props.selectedCard[0].billingAddress?.country || "united states of america",
          postalCode:
            this.props.selectedCard[0].billingAddress?.postalCode.length > 5 &&
            !this.props.selectedCard[0].billingAddress?.postalCode.includes("-")
              ? this.props.selectedCard[0].billingAddress?.postalCode.substring(0, 5).concat("-") +
                this.props.selectedCard[0].billingAddress?.postalCode.substring(
                  5,
                  this.props.selectedCard[0].billingAddress?.postalCode.length,
                )
              : this.props.selectedCard[0].billingAddress?.postalCode || "",
        },
        selectedCardData: {
          ...this.state.selectedCardData,
          cardNumber: this.props.selectedCard[0].cardNumber,
          cardId: this.props.selectedCard[0].cardId,
          cardMonth: this.props.selectedCard[0].cardExpiryMonth,
          cardYear: this.props.selectedCard[0].cardExpiryYear,
          instrumentId:
            this.props && this.props.selectedCard && this.props.selectedCard.length > 0
              ? this.props.selectedCard[0].paymentInstrument?.paymentInstrumentId
              : 0,
          linkedPaymentMethodEntityId:
            this.props && this.props.selectedCard && this.props.selectedCard.length > 0
              ? this.props.selectedCard[0].paymentInstrument?.linkedPaymentMethodEntityId
              : 0,
          cardType: this.props.selectedCard[0].cardType,
        },
        showMaskedCardNumber: maskedcardNumber,
      });
    } else if (payeruserdata) {
      this.setState({
        ...this.state,
        billingAddress: {
          ...this.state.billingAddress,
          firstName: payeruserdata.firstName,
          middleName: payeruserdata.middleName,
          lastName: payeruserdata?.lastName,
          email: payeruserdata?.contactEmail,
          phoneNo: payeruserdata?.contactNo,
          street: payeruserdata?.addressVo?.street,
          street2: payeruserdata?.addressVo?.street2,
          city: payeruserdata?.addressVo?.city,
          state: payeruserdata?.addressVo?.state,
          country: "united states of america",
          postalCode:
            payeruserdata?.addressVo?.postalCode?.length > 5 && !payeruserdata?.addressVo?.postalCode?.includes("-")
              ? payeruserdata?.addressVo?.postalCode?.substring(0, 5).concat("-") +
                payeruserdata?.addressVo?.postalCode?.substring(5, payeruserdata?.addressVo?.postalCode?.length)
              : payeruserdata?.addressVo?.postalCode,
        },
      });
    } else {
      alert("3");
    }

    if (this.props?.selectedCard?.length > 0) {
      const selectedState = stateList.filter(
        (item) =>
          item?.stateName?.toLocaleLowerCase() === this.props.selectedCard[0]?.billingAddress?.state?.toLocaleLowerCase(),
      );
      let PassValues = {
        stateId: selectedState[0]?.stateId,
      };
      const UserId = "1234";
      RegionManagementService.getCity(PassValues, UserId, (res) => {
        this.setState({ cityList: res.data.responseListObject });
      });
    } else {
      let PassValues = {
        stateId: payeruserdata?.addressVo?.stateId,
      };
      const UserId = "1234";
      RegionManagementService.getCity(PassValues, UserId, (res) => {
        this.setState({ cityList: res.data.responseListObject });
      });
    }

    // document.title = sessionStorage.getItem("userData") ? "MED-1 Secure Login - Credit Card" : "MED-1 Quick Pay - Credit Card"

    document.title = "MED-1 Payment";

    // GoogleAnalystics.GoogleAnalysticsLog("pageview");

    window.scrollTo(0, 0);
    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener('popstate', function (event) {
    //     window.history.pushState(null, document.title, window.location.href);
    // });
    // debugger;

    // Check if URL has Query parameters
    if (queryString[0] !== "" && queryString[0] !== null) {
      var urlAppId = queryString ? queryString[0].replace("?id=", "") : "";
      var urlPartyId = queryString[1] ? queryString[1].replace("p=", "") : "";
      var urlAmount = queryString[2] ? queryString[2].replace("a=", "") : "";
      var urlSubscriberId = queryString[3] ? queryString[3].replace("s=", "") : "";
      var urlMethod = queryString[4] ? queryString[4].replace("b=", "") : "";
      var urlOrderId = queryString[5] ? queryString[5].replace("o=", "") : "";
      var urlProxy = queryString[6] ? queryString[6].replace("u=", "") : "";
      var urlPlanId = queryString[7] ? queryString[7].replace("pl=", "") : "";
      var urlPriceSubId = queryString[8] ? queryString[8].replace("ps=", "") : "";
      var urlPurchaseParam = queryString[9] ? queryString[9].replace("pp=", "") : "";
      var urlJsonParam = queryString[10] ? queryString[10].replace("js=", "") : "";

      const ES_AppId = atob(urlAppId);
      const ES_PartyId = atob(urlPartyId);
      const ES_Amount = atob(urlAmount);
      const ES_SubId = atob(urlSubscriberId);
      const ES_Method = atob(urlMethod);
      const ES_url = atob(urlProxy);

      this.setState({
        ES_AppId: atob(urlAppId),
        ES_PartyId: atob(urlPartyId),
        ES_Amount: atob(urlAmount),
        ES_SubId: atob(urlSubscriberId),
        ES_Method: atob(urlSubscriberId),
        ES_OrderId: atob(urlOrderId),
        ES_PlanId: atob(urlPlanId),
        ES_PriceSubId: atob(urlPriceSubId),
        ES_PurchaseParameters: atob(urlPurchaseParam),
        ES_JsonData: atob(urlJsonParam),
      });
      sessionStorage.setItem("SIC_URL", ES_url);
      sessionStorage.setItem("buid", 6);

      loginService.TokenUser((res) => {
        if (res.status === "success") {
          // debugger
          sessionStorage.setItem("jwt-token", res.responseObject);
          sessionStorage.setItem("jwt-tokencore", res.responseObject);
          const passValues = {
            countryId: "",
            countryName: "",
            limit: -1,
          };
          const userId = "1212";
          // debugger
          RegionManagementService.getCountry(passValues, userId, (res) => {
            if (res["data"].status === "success") {
              sessionStorage.setItem("countryList", JSON.stringify(res.data.responseListObject));

              var PassValues = {
                countryId: 1,
                limit: -1,
              };
              const UserId = "1234";
              RegionManagementService.getState(PassValues, UserId, (res) => {
                sessionStorage.setItem("stateList", JSON.stringify(res.data.responseListObject));
              });
            }
          });
        }
      }, console.log("Token Error"));
    } else {
      this.setState({
        payeraddrdata: JSON.parse(sessionStorage.getItem("verifypayer")),
      });
    }
    // Get City List

    // $("#cardNumber").inputmask({"mask": "9999-9999-9999-9999"});
    // loginService.TokenUser((resToken) => {
    //     if (resToken["status"] === "success") {
    //         sessionStorage.setItem("Tokentype", "jwt");
    //         sessionStorage.setItem(
    //             "jti-tokencore",
    //             resToken.responseObject
    //         );
    //         loginService.TokenUser((resToken) => {
    //             if (resToken["status"] === "success") {
    //                 sessionStorage.setItem(
    //                     "jwt-tokencore",
    //                     resToken.responseObject
    //                 );

    //             }
    //         });
    //     }
    // });

    if (queryString && queryString.length > 0 && queryString[0] == "" && queryString[0] == null) {
      const passValues = {
        countryId: "",
        countryName: "",
        limit: -1,
      };
      const userId = "1212";

      // Get Credit Card Details

      var userinfo = JSON.parse(sessionStorage.getItem("verifypayer"));
      userId = "1234";
      const passValuescc = {
        requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        paymentInfoSearch: {
          paymentMethodId: 75001,
          partyId: this.state.ES_PartyId ? this.state.ES_PartyId : userinfo ? userinfo.partyId : "",
        },
      };

      OutstandingPayerService.Getcardecheckdata(passValuescc, userId, (res) => {
        if (res["data"].responseInfo.status === "SUCCESSFUL") {
          sessionStorage.setItem("cardecheckdata", JSON.stringify(res["data"].paymentInfos[0].cardAccount));
          this.setState({
            carddata: res["data"].paymentInfos[0].cardAccount,
          });
        }
      });
    }

    if (!this.state.selectedCardData?.instrumentId) {
      $(".showcard").on("click", function () {
        //  $("#maskdedcardNumber").toggle();
        //  $("#cardNumber").toggle();
        $("#cardNumber").toggleClass("maskfieldcc");
      });
      $("input").on("focus", function () {
        //  $("#maskdedcardNumber").toggle();
        //  $("#cardNumber").toggle();
        var curid = $(this).attr("id");
        if (curid !== "cardNumber") {
          $("#cardNumber").addClass("maskfieldcc");
        }
      });

      $("#maskdedcardNumber").hide();
    }

    // $("#submitButton").hide();
    // $("submitcheck").show();
    // $("#submitcheck").on("click", function(){
    //     warning(

    //           <Translation>
    //           {
    //               (t, { i18n }) => <label>{t("credit_echeck_consent")}</label>
    //           }
    //       </Translation> ,
    //           warningNotification

    //       );
    // });

    // $("#paymenttermconsent,#userdataconsent").on("change", function(){
    //     if(sessionStorage.getItem("paymenttermsconsent")=== "1" && sessionStorage.getItem("userdataconsent") === "1")
    //     {

    //         $("#submitButton").show();
    //         $("#submitcheck").hide();
    //     }
    //     else
    //     {

    //         $("#submitButton").hide();
    //         $("#submitcheck").show();
    //     }
    // })

    // Get current year
    var d = new Date();
    var n = d.getFullYear();

    this.setState({
      currentyear: n,
    });
  }
  futureDateSchema = Yup.object().shape({
    futureDate: this.props.payInFuture
      ? moment().daysInMonth() === 31
        ? Yup.date()
            .test("futureDate", "Schedule payment date must be in between 31 days", (val) => {
              if (val <= moment().add(30, "days").toDate()) {
                return true;
              } else {
                return false;
              }
            })
            .required("Future Payment Date is Required")
            .typeError("Please enter a valid date")
            .min(new Date(), "Date must be in the future")
            .nullable()
        : moment().daysInMonth() === 30
        ? Yup.date()
            .test("futureDate", "Schedule payment date must be in between 30 days", (val) => {
              if (val <= moment().add(29, "days").toDate()) {
                return true;
              } else {
                return false;
              }
            })
            .required("Future Payment Date is Required")
            .typeError("Please enter a valid date")
            .min(new Date(), "Date must be in the future")
            .nullable()
        : moment().daysInMonth() === 29
        ? Yup.date()
            .test("futureDate", "Schedule payment date must be in between 29 days", (val) => {
              if (val <= moment().add(28, "days").toDate()) {
                return true;
              } else {
                return false;
              }
            })
            .required("Future Payment Date is Required")
            .typeError("Please enter a valid date")
            .min(new Date(), "Date must be in the future")
            .nullable()
        : Yup.date()
            .test("futureDate", "Schedule payment date must be in between 28 days", (val) => {
              if (val <= moment().add(27, "days").toDate()) {
                return true;
              } else {
                return false;
              }
            })
            .required("Future Payment Date is Required")
            .typeError("Please enter a valid date")
            .min(new Date(), "Date must be in the future")
            .nullable()
      : Yup.string().nullable(),
    termsconditions:
      sessionStorage.getItem("currentUserRole") !== "Admin" &&
      Yup.boolean().oneOf([true], "You must agree to the Online Payment Terms and Conditions"),
  });
  _paymentConfirmationValidationWithOutCard = Yup.object().shape({
    // futureDate: this.props.payInFuture
    //   ? Yup.string().nullable().required("Future Payment Date is Required")
    //   : Yup.string().nullable(),

    firstname: Yup.string()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters ,dash(-), dot(.), tilled and apostrophes(') are allowed")
      .min(1, <Translation>{(t, { i18n }) => <label>{t("credit_minnameOnCard")} </label>}</Translation>)
      .max(20, <Translation>{(t, { i18n }) => <label>{t("credit_maxnameOnCard")} </label>}</Translation>)
      .required(<Translation>{(t, { i18n }) => <label>{t("credit_firstnameOnCard")} </label>}</Translation>),
    // middlename: Yup.string()
    //   .trim()
    //   .nullable()
    //   .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters, dash(-), dot(.), tilled and apostrophes(') are allowed")
    //   .min(1, <Translation>{(t, { i18n }) => <label>{t("credit_minnameOnCard")} </label>}</Translation>)
    //   .max(20, <Translation>{(t, { i18n }) => <label>{t("credit_maxnameOnCard")} </label>}</Translation>)
    //   .required(<Translation>{(t, { i18n }) => <label>{t("credit_middlenameOnCard")} </label>}</Translation>)
    //   .notRequired(),
    lastname: Yup.string()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters, dash(-), dot(.), tilled and apostrophes(') are allowed")
      .min(1, <Translation>{(t, { i18n }) => <label>{t("credit_minnameOnCard")} </label>}</Translation>)
      .max(20, <Translation>{(t, { i18n }) => <label>{t("credit_maxnameOnCard")} </label>}</Translation>)
      .required(<Translation>{(t, { i18n }) => <label>{t("credit_lastnameOnCard")} </label>}</Translation>),
    cardtype: Yup.string()
      .oneOf(["Master Card", "American Express", "Visa"], "Only Visa, Master and American Express cards are allowed")
      .required("Only Visa, Master and American Express cards are allowed"),

    year: Yup.string()
      .required(<Translation>{(t, { i18n }) => <label>{t("credit_selectyear")} </label>}</Translation>)
      .test(
        "year",
        <Translation>{(t, { i18n }) => <label>{t("credit_selectyear")} </label>}</Translation>,
        (val) => val !== "Year",
      ),
    month: Yup.number()
      .typeError(<Translation>{(t, { i18n }) => <label>{t("credit_selectmonth")} </label>}</Translation>)
      .when("year", (year, schema) => {
        return schema.test({
          test: (month) =>
            Number(year) === Number(new Date().getFullYear()) ? Number(month) > Number(new Date().getMonth()) : true,
          message: "Card is expired",
        });
      })
      .required(<Translation>{(t, { i18n }) => <label>{t("credit_selectmonth")} </label>}</Translation>),
    emailAddress: Yup.string()
      .nullable()
      .required(<Translation>{(t, { i18n }) => <label>{t("credit_emailaddress")} </label>}</Translation>)
      .email(<Translation>{(t, { i18n }) => <label>{t("credit_erremailaddmaching")} </label>}</Translation>),
    // cardCvvNumber: Yup.string().required(<Translation>
    //     {
    //         (t, { i18n }) => <label
    //         >{t("credit_cvvnumber")} </label>
    //     }
    // </Translation>),
    cardCvvNumber: Yup.string()
      .nullable()
      .matches(/^[0-9]*$/, "Please enter a valid Security Code")
      .required("Security Code is required")
      .when("cardtype", {
        is: "American Express",
        then: Yup.string().min(4, "Enter 4 digits only").max(4, "Enter 4 digits only"),
        otherwise: Yup.string().min(3, "Enter 3 digits only").max(3, "Enter 3 digits only"),
      }),
    phoneNumber: Yup.string()
      .nullable()
      .required(<Translation>{(t, { i18n }) => <label>{t("contactus_phnumber")} </label>}</Translation>)
      .min(10, <Translation>{(t, { i18n }) => <label>{t("contactus_errmincontactnum")} </label>}</Translation>)
      .max(10, <Translation>{(t, { i18n }) => <label>{t("contactus_errmaxcontactnum")} </label>}</Translation>),
    // .matches(/^([0-9])(\d{2})(\d{3})(\d{4})(?<!(\1{3}\1{3}\1{4}))$/, "Please enter a valid Phone Number"),

    address1: Yup.string()
      .nullable()
      .max(255, <Translation>{(t, { i18n }) => <label>{t("address_maxcharacters")} </label>}</Translation>)
      .required(<Translation>{(t, { i18n }) => <label>{t("credit_address")} </label>}</Translation>),
    address2: Yup.string()
      .nullable()
      .max(255, <Translation>{(t, { i18n }) => <label>{t("address_maxcharacters")} </label>}</Translation>),
    country: Yup.string()
      .nullable()
      .required(<Translation>{(t, { i18n }) => <label>{t("credit_errcountry")} </label>}</Translation>),
    state: Yup.string()
      .nullable()
      .required(<Translation>{(t, { i18n }) => <label>{t("credit_errstate")} </label>}</Translation>),
    city: Yup.string()
      .nullable()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters, dash(-), dot(.), tilled and apostrophes(') are allowed")
      .required(<Translation>{(t, { i18n }) => <label>{t("credit_errcity")} </label>}</Translation>),
    // zipCode: Yup.string().required(<Translation>
    //     {
    //         (t, { i18n }) => <label
    //         >{t("credit_errzipcode")} </label>
    //     }  ^[0-9]{5}(?:-[0-9]{4})?$
    // </Translation>),

    zipCode: Yup.string()
      .nullable()
      .trim()
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/g, "Please enter a valid ZIP Code")
      .test("Invalid ZIP Code", (value) => !/^([0-9])\1{4,}$/.test(value))
      .required(<Translation>{(t, { i18n }) => <label>{t("credit_errzipcode")} </label>}</Translation>),
    // userdata :Yup.boolean().oneOf([true]).required(<Translation>
    //     {
    //         (t, { i18n }) => <label
    //         >{t("userdata_consent")} </label>
    //     }
    // </Translation>),
    //userdata :Yup.boolean().oneOf([true], "Check Terms and Conditions"),
    termsconditions:
      sessionStorage.getItem("currentUserRole") !== "Admin" &&
      Yup.boolean().oneOf([true], "You must agree to the Online Payment Terms and Conditions"),
  });
  _paymentConfirmationValidation = Yup.object({
    firstname: Yup.string()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters ,dash(-), dot(.), tilled and apostrophes(') are allowed")
      .min(1, "Please enter at least 1 Character")
      .max(20, "Max 20 characters allowed")
      .required("First Name is required"),
    // futureDate: Yup.date().required("Future Payment Date is Required").nullable(),
    futureDate: this.props.payInFuture
      ? moment().daysInMonth() === 31
        ? Yup.date()
            .test("futureDate", "Schedule payment date must be in between 31 days", (val) => {
              if (val <= moment().add(30, "days").toDate()) {
                return true;
              } else {
                return false;
              }
            })
            .required("Future Payment Date is Required")
            .typeError("Please enter a valid date")
            .min(new Date(), "Date must be in the future")
            .nullable()
        : moment().daysInMonth() === 30
        ? Yup.date()
            .test("futureDate", "Schedule payment date must be in between 30 days", (val) => {
              if (val <= moment().add(29, "days").toDate()) {
                return true;
              } else {
                return false;
              }
            })
            .required("Future Payment Date is Required")
            .typeError("Please enter a valid date")
            .min(new Date(), "Date must be in the future")
            .nullable()
        : moment().daysInMonth() === 29
        ? Yup.date()
            .test("futureDate", "Schedule payment date must be in between 29 days", (val) => {
              if (val <= moment().add(28, "days").toDate()) {
                return true;
              } else {
                return false;
              }
            })
            .required("Future Payment Date is Required")
            .typeError("Please enter a valid date")
            .min(new Date(), "Date must be in the future")
            .nullable()
        : Yup.date()
            .test("futureDate", "Schedule payment date must be in between 28 days", (val) => {
              if (val <= moment().add(27, "days").toDate()) {
                return true;
              } else {
                return false;
              }
            })
            .required("Future Payment Date is Required")
            .typeError("Please enter a valid date")
            .min(new Date(), "Date must be in the future")
            .nullable()
      : Yup.string().nullable(),
    middlename: Yup.string()
      .nullable()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters, dash(-), dot(.), tilled and apostrophes(') are allowed")
      .min(1, "Please enter at least 1 Characters")
      .max(20, "Max 20 characters allowed")
      .notRequired(),
    lastname: Yup.string()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters, dash(-), dot(.), tilled and apostrophes(') are allowed")
      .min(1, "Please enter at least 1 Characters")
      .max(20, "Max 20 characters allowed")
      .required("Last Name is required"),
    // cardtype: Yup.string().oneOf(['mast', 'amex', 'visa'], "Only Visa, Master and American Express cards are allowed").required("Only Visa, Master and American Express cards are allowed"),
    cardNumber: Yup.string()
      .matches(
        /^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|3[47][0-9]{13})$/,
        "Only Visa, Master and American Express cards are allowed",
      )
      .required("Valid Card Number is required"),
    // ^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|3[47][0-9]{13})$
    year: Yup.string()
      .required("Select Year")
      .test("year", "Select Year", (val) => val !== "none"),
    month: Yup.string()
      .required("Select Month")
      .test("month", "Select Month", (val) => val !== "none")
      .when("year", (year, schema) => {
        return schema.test({
          test: (month) =>
            Number(year) === Number(new Date().getFullYear()) ? Number(month) > Number(new Date().getMonth()) : true,
          message: "Card is expired",
        });
      })
      .required("Select Month"),
    emailAddress: Yup.string()
      .when("accountnumber", (accountnumber, field) => {
        return sessionStorage.getItem("currentUserRole") && sessionStorage.getItem("currentUserRole") !== "Admin"
          ? field.required("Email Address Required")
          : field.email("Email Address must be valid");
      })
      .nullable(),
    // cardCvvNumber: Yup.string().required(<Translation>
    //   {
    //     (t, { i18n }) => <label style={{color:"red", fontWeight:1}}
    //     >{t("credit_cvvnumber")} </label>
    //   }
    // </Translation>),
    cardCvvNumber: Yup.string()
      .nullable()
      .matches(/^[0-9]*$/, "Please enter a valid Security Code")
      .when("cardtype", {
        is: "American Express",
        then: Yup.string().min(4, "Enter 4 digits only").max(4, "Enter 4 digits only"),
        otherwise: Yup.string().min(3, "Enter 3 digits only").max(3, "Enter 3 digits only"),
      })
      .when("currentUserRole", {
        is: (val) => val !== "Admin",
        then: Yup.string().required("Security Code is required"),
      }),
    phoneNumber: Yup.string()
      .nullable()
      .trim()
      .matches(/^\d+\.?\d*$/, "Enter digits only")

      .min(10, "Please enter 10 digits Phone Number")
      .max(10, "Please enter 10 digits Phone Number")
      // .matches(
      //   /^([0-9])(\d{2})(\d{3})(\d{4})(?<!(\1{3}\1{3}\1{4}))$/,
      //   'Please enter a valid Phone Number'
      // )
      .required("Phone Number is required"),

    address1: Yup.string().nullable().max(255, "Max 255 characters allowed").required("Billing Address is required"),
    address2: Yup.string().nullable().max(255, "Max 255 characters allowed"),
    country: Yup.string().nullable().required("Country is required"),
    state: Yup.string().nullable().required("State is required"),
    city: Yup.string()
      .nullable()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters, dash(-), dot(.), tilled and apostrophes(') are allowed")
      .required("City is required"),

    zipCode: Yup.string()
      .nullable()
      .trim()
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/g, "Please enter a valid ZIP Code")
      .test("Invalid ZIP Code", (value) => !/^([0-9])\1{4,}$/.test(value))
      .required("ZIP Code is required"),

    termsconditions:
      sessionStorage.getItem("currentUserRole") !== "Admin" &&
      Yup.boolean().oneOf([true], "You must agree to the Online Payment Terms and Conditions"),
  });
  existingCardSchema = Yup.object({
    termsconditions:
      sessionStorage.getItem("currentUserRole") !== "Admin" &&
      Yup.boolean().oneOf([true], "You must agree to the Online Payment Terms and Conditions"),
  });

  _handleClickShowPassword = () => {
    this.setState({
      showPassword: this.state.showPassword ? false : true,
    });
  };
  _handleNewCard = async (values, onSubmitProps) => {
    this.setState({
      ...this.state,
      showloader: true,
    });
    let modifieddata = new Date();
    let dateString =
      modifieddata.getUTCFullYear() +
      "/" +
      (modifieddata.getUTCMonth() + 1) +
      "/" +
      modifieddata.getUTCDate() +
      " " +
      modifieddata.getUTCHours() +
      ":" +
      modifieddata.getUTCMinutes() +
      ":" +
      modifieddata.getUTCSeconds();

    const partyids = JSON.parse(sessionStorage.getItem("verifypayer"));
    let partyid = partyids.partyId;

    const PassValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      paymentInfos: [
        {
          achAccount: null,
          cardAccount: [
            {
              cardId: this.state.selectedCardData.cardId || 0,
              payerPartyId: partyid,
              cardNumber: values.cardNumber,
              cardExpiryMonth: values.month,
              cardExpiryYear: `${values.year}`,
              cardVerificationCode: values.cardCvvNumber,
              cardName: values.firstname + " " + values.lastname,
              cardNetworkId: 72000,
              cardTypeId: 73000,
              paymentMethodId: "75001",
              isActive: 1,
              paymentInstrument: {
                paymentInstrumentId: this.state.selectedCardData.instrumentId || 0,
                linkedPaymentMethodEntityId: this.state.selectedCardData.linkedPaymentMethodEntityId || 0,
                payerPartyId: partyid,
                paymentMethodId: 75001,
                isDefault: null,
                isExpired: null,
                isBlocked: null,
                isBlacklisted: null,
                lastUsageDatetime: dateString,
              },
              billingAddress: {
                billingAddressId: this.state?.billingAddress?.billingAddressId || 0,
                firstName: values.firstname,
                middleName: values.middlename,
                lastName: values.lastname,
                email: values.emailAddress,
                phoneNo: values.phoneNumber,
                street: values.address1,
                street2: "",
                city: values.city,
                state: values.state,
                country: values.country,
                postalCode: values.zipCode.replace("-", ""),
              },
            },
          ],
          bankAccount: null,
          walletAccount: null,
          virtualAccount: null,
        },
      ],
      // Below items needed for update Card API's
      // cardId,
      // paymentInstrumentId,
      // linkedPaymentMethodEntityId
    };

    if (this?.props?.location?.state?.paymentMethod === "creditCard") {
      paymentCreditCardService.saveCreditDetails(PassValues, 1212, (res) => {
        if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
          // success("Card Successfully Added", successNotification);
          handleSuccess("Card Successfully Added");
          this.setState({
            newinstrumentid: res?.data?.paymentInfos[0]?.cardAccount[0]?.paymentInstrument?.paymentInstrumentId,
          });
          this._handlePayinFuture(values);
        } else {
          handleError(res?.data?.responseInfo?.responseMessage[0]);
        }
      });
    } else {
      this._handlePayinFuture(values);
    }
  };
  _handlePayinFuture = (values) => {
    let newPassValues = this.props.passValues;

    const partyids = JSON.parse(sessionStorage.getItem("verifypayer"));
    let partyId = partyids?.partyId;

    newPassValues.paymentOrders[0].paymentInstrumentId =
      this.state.selectedCardData?.instrumentId || this.state.newinstrumentid;
    newPassValues.paymentOrders[0].paymentDate = moment(values.futureDate).format("MM/DD/YYYY");
    newPassValues.paymentOrders[0].payerPartyId = partyId;
    newPassValues.paymentOrders[0].reminderEmail = values?.reminderEmail && values?.reminderEmail === true ? 1 : 0;
    newPassValues.paymentOrders[0].reminderSms = values?.reminderSms && values?.reminderSms === true ? 1 : 0;
    newPassValues.paymentOrders[0].paymentEmail = values?.paymentEmail && values?.paymentEmail === true ? 1 : 0;
    newPassValues.paymentOrders[0].paymentSms = values?.paymentSms && values?.paymentSms === true ? 1 : 0;
    OutstandingPayerService.PP_Create_Payment_Order(newPassValues, 1234, (res) => {
      if (res["data"]?.responseInfo.status === "SUCCESSFUL") {
        handleSuccess("Order Generated Successfully");
        this.setState({
          ...this.state,
          showloader: false,
        });
        this.props.history.push({
          pathname: "/payment-scheduled",
          state: {
            futureDate: moment(values.futureDate).format("MM/DD/YYYY"),
          },
        });
      } else if (res["data"]?.responseInfo.status === "FAILED") {
        let redirectURLTo = sessionStorage.getItem("currentUserRole") === "Customer" ? "/signin" : "/admin";

        handleError("Sorry Internal Error Occurred. Please login and try again.");
        this.props.history.push(redirectURLTo);
        this.setState({
          ...this.state,
          showloader: false,
        });
      } else {
        handleWarning("Something went wrong!!");
        this.setState({
          ...this.state,
          showloader: false,
        });
      }
    });
  };
  _handleSubmit = (values, { actions }) => {
    console.log("haloo");
    const jtiToken =
      sessionStorage.getItem("jti-token") === null
        ? sessionStorage.getItem("jwt-token")
        : sessionStorage.getItem("jti-token");
    const Token = sessionStorage.getItem("jwt-token") === null ? "" : sessionStorage.getItem("jwt-token");

    this.setState({
      showloader: true,
    });

    const partyids = JSON.parse(sessionStorage.getItem("verifypayer"));

    // debugger;
    var partyid = partyids === null ? this.state.ES_PartyId : partyids.partyId;
    var contactpartyid = partyids === null ? "" : partyids.partyTypeId;

    // Save Consent data

    const UserId = "1212";
    // var Token = "";
    // var jtiToken = ""

    const checkconsentValues = {
      requestInfo: requestInfo_PP(Token, jtiToken),
      partyConsentRequest: {
        partyId: partyid,
        consentObjectName: "CreditCardPayment",
      },
    };
    whiteLabelService.checkUserConsent(
      checkconsentValues,
      UserId,
      (res) => {
        // alert(JSON.stringify(res["data"]))
        if (res["data"].status === "Success") {
          // Update Consent
          var consentdata = res["data"].responseListObject;
          var updateconsent = "";
          if (consentdata !== null) {
            var consentjson = consentdata[0].consentSourceJson;

            var temp = [];
            temp = [
              {
                tag: "email",
                value: values.userdata,
              },
              {
                tag: "T&C",
                value: values.termsconditions,
              },
            ];
            consentjson.map((consentjson, index) => {
              if (consentjson.tag !== "email" && consentjson.tag !== "T&C") {
                temp.push(consentjson);
              }
            });

            //  consentjson.push(temp[0]);
            // consentjson.push(temp[1]);

            updateconsent = {
              requestInfo: requestInfo_PP(Token, jtiToken),
              buId: consentdata[0].buId,
              subBuId: consentdata[0].subBuId,
              partyId: consentdata[0].partyId,
              partyType: 3,
              partyConsentId: consentdata[0].partyConsentId,
              consentObjectName: "CreditCardPayment",
              consentActionValue: "1",
              consentRecordedDatetime: new Date(),
              consentSourceJson: temp,
            };
          } else {
            updateconsent = {
              requestInfo: requestInfo_PP(Token, jtiToken),
              buId: sessionStorage.getItem("buid") !== null ? sessionStorage.getItem("buid") : "2",
              subBuId: "1",
              partyId: partyid,
              partyType: 3,
              consentObjectName: "CreditCardPayment",
              consentActionValue: "1",
              consentRecordedDatetime: new Date(),
              consentSourceJson: [
                {
                  tag: "email",
                  value: values.userdata,
                },
                {
                  tag: "T&C",
                  value: values.termsconditions,
                },
              ],
            };
          }

          whiteLabelService.saveUserConsent(
            updateconsent,
            UserId,
            (res) => {
              // alert(JSON.stringify(res["data"]))
              if (res["data"].status === "Success") {
              } else if (res["data"].status === "fail") {
              } else {
              }
            },
            (error) => {
              console.log(error);
            },
          );
        } else {
          // Add Consent
          const consentValues = {
            requestInfo: requestInfo_PP(Token, jtiToken),

            buId: sessionStorage.getItem("buid") !== null ? sessionStorage.getItem("buid") : "2",
            subBuId: "1",
            partyId: partyid,
            partyType: 3,
            consentObjectName: "CreditCardPayment",
            consentActionValue: "1",
            consentRecordedDatetime: new Date(),
            consentSourceJson: [
              {
                tag: "email",
                value: values.userdata,
              },
              {
                tag: "T&C",
                value: values.termsconditions,
              },
            ],
          };
          whiteLabelService.saveUserConsent(
            consentValues,
            UserId,
            (res) => {
              // alert(JSON.stringify(res["data"]))
              if (res["data"].status === "Success") {
              } else if (res["data"].status === "fail") {
              } else {
              }
            },
            (error) => {
              console.log(error);
            },
          );
        }
      },
      (error) => {
        console.log(error);
      },
    );
    // Save Consent data end

    var modifieddata = new Date();
    var dateString =
      modifieddata.getUTCFullYear() +
      "/" +
      (modifieddata.getUTCMonth() + 1) +
      "/" +
      modifieddata.getUTCDate() +
      " " +
      modifieddata.getUTCHours() +
      ":" +
      modifieddata.getUTCMinutes() +
      ":" +
      modifieddata.getUTCSeconds();

    const PassValues = {
      requestInfo: requestInfo_PP(Token, jtiToken),
      paymentInfos: [
        {
          achAccount: null,
          cardAccount: [
            {
              cardId: this.state.selectedCardData.cardId || 0,
              payerPartyId: partyid,
              cardNumber: values.cardNumber,
              cardExpiryMonth: values.month,
              cardExpiryYear: `${values.year}`,
              cardVerificationCode: values.cardCvvNumber,
              cardName: values.firstname + " " + values.lastname,
              cardNetworkId: 72000,
              cardTypeId: 73000,
              paymentMethodId: "75001",
              isActive: 1,
              paymentInstrument: {
                paymentInstrumentId: this.state.selectedCardData.instrumentId || 0,
                linkedPaymentMethodEntityId: this.state.selectedCardData.linkedPaymentMethodEntityId || 0,
                payerPartyId: partyid,
                paymentMethodId: 75001,
                isDefault: null,
                isExpired: null,
                isBlocked: null,
                isBlacklisted: null,
                lastUsageDatetime: dateString,
              },
              billingAddress: {
                billingAddressId: this.state?.billingAddress?.billingAddressId || 0,
                firstName: values.firstname,
                middleName: values.middlename,
                lastName: values.lastname,
                email: values.emailAddress,
                phoneNo: values.phoneNumber,
                street: values.address1,
                street2: "",
                city: values.city,
                state: values.state,
                country: values.country,
                postalCode: values.zipCode.replace("-", ""),
              },
            },
          ],
          bankAccount: null,
          walletAccount: null,
          virtualAccount: null,
        },
      ],
    };
    // const UserId = "1212"
    if (this?.props?.location?.state?.paymentMethod === "creditCard") {
      paymentCreditCardService.saveCreditDetails(
        PassValues,
        UserId,
        (res) => {
          // alert(JSON.stringify(res["data"]))
          if (res["data"].responseInfo.status === "SUCCESSFUL") {
            // success("Success", successNotification);

            this.setState({
              instrumentid: res["data"].paymentInfos[0].cardAccount[0].paymentInstrument.paymentInstrumentId,
              cardId: res["data"].paymentInfos[0].cardAccount[0].cardId,
            });
            const partyIds = JSON.parse(sessionStorage.getItem("verifypayer"));

            var partyid = partyIds === null ? "" : partyids.partyId;
            var contactpartyid = partyIds === null ? "" : partyids.partyTypeId;
            // var nameoncard = values.nameOnCard;
            // nameoncard = nameoncard.split(" ");
            if (sessionStorage.getItem("SIC_Info") !== null) {
              // debugger;
              sessionStorage.setItem("buid", "6");
              loginService.TokenUser((res) => {
                if (res.status === "success") {
                  sessionStorage.setItem("jwt-token", res.responseObject);
                  sessionStorage.setItem("jwt-tokencore", res.responseObject);

                  var Token = res.responseObject;
                  var jtiToken = res.responseObject;

                  var TransactionValues = {
                    requestInfo: requestInfo_PP(Token, jtiToken),
                    transaction: {
                      paymentOrder: {
                        paymentOrderId: sessionStorage.getItem("Ordernumber")
                          ? sessionStorage.getItem("Ordernumber")
                          : this.state.ES_OrderId,
                        paymentSms: values?.paymentSms && values?.paymentSms === true ? 1 : 0,
                        paymentEmail: values?.paymentEmail && values?.paymentEmail === true ? 1 : 0,
                      },
                      gatewayName: "FORTE",
                      isFinalSettlement: this.props.history.location.state?.isSettled ? "Y" : null,
                      // "action": "sale",
                      // "gatewayId": "14578",
                      // "paymentGatewayId": "123", // need to send later

                      contactPartyId: contactpartyid,
                      payerPartyId: partyid ? partyid : this.state.ES_PartyId,
                      paymentInfo: {
                        action: "sale",
                        authorizationAmount:
                          Number(sessionStorage.getItem("amounttopay")) !== 0
                            ? sessionStorage.getItem("amounttopay")
                            : this.state.ES_Amount, // actual paid value partial or full amount
                        subtotalAmount:
                          Number(sessionStorage.getItem("TotalAmount")) !== 0
                            ? sessionStorage.getItem("TotalAmount")
                            : this.state.ES_Amount, // actual paid value partial or full amount
                        billingAddress: {
                          billingAddressId: this.state?.billingAddress?.billingAddressId || 0,
                          firstName: values.firstname,
                          lastName: values.lastname,
                          // "firstName": partyids.firstName,
                          // "lastName": partyids.lastName,
                          email: values.emailAddress,
                          phoneNo: values.phoneNumber,
                          street: values.address1,
                          street2: "",
                          city: values.city,
                          state: values.state,
                          country: values.country,
                          postalCode: values.zipCode.replace("-", ""),
                        },
                        card: {
                          cardId: this.state.selectedCardData.cardId || this.state.cardId,
                          cardType: values.cardtype,
                          nameOnCard: values.firstname + " " + values.lastname,
                          accountNumber: values.cardNumber,
                          expireMonth: values.month,
                          expireYear: values.year,
                          cardVerificationValue: values.cardCvvNumber,
                        },
                      },
                      mappedPayerPaymentPlanAgreementId: "0", // will get from fe
                      paymentGatewayId: "00", // for now 45
                      instrumentId: this.state?.selectedCardData?.instrumentid
                        ? this.state.selectedCardData?.instrumentid
                        : 0, // for now 99
                      paymentMethodId: "75001", //
                      targetAmount:
                        Number(sessionStorage.getItem("TotalAmount")) !== 0
                          ? sessionStorage.getItem("TotalAmount")
                          : this.state.ES_Amount,
                      paidAmount:
                        Number(sessionStorage.getItem("amounttopay")) !== 0
                          ? sessionStorage.getItem("amounttopay")
                          : this.state.ES_Amount,
                      paymentType: "Online",
                      paymentSubtype: "gateway",
                      paymentCode: "ADA", // for now ADA need to decide
                      refundedAmount: "0", // blank or 0
                      amountPaidAgency: "0", // for now 0
                      paidAgencyId: "0", // for now 0
                    },
                  };

                  if (this.state.instrumentid) {
                    paymentCreditCardService.paymentTransaction(
                      TransactionValues,
                      UserId,
                      (res) => {
                        // debugger;
                        // alert(JSON.stringify(res["data"]))
                        if (res["data"].responseInfo.status === "SUCCESSFUL") {
                          sessionStorage.setItem("buid", "2");
                          // success("Success", successNotification);
                          // // sessionStorage.setItem('payerinfo', JSON.stringify(PassValues.payerAccountSearch));
                          // this.props.history.push("/Transaction-Successful");
                          sessionStorage.setItem("transactiondata", JSON.stringify(res["data"].transaction));
                          sessionStorage.setItem("transactionId", res["data"].transaction.paymentTxnId);
                          // debugger;
                          if (res["data"].transaction.transactionStatus === "SUCCESS") {
                            if (this.props.history.location.state?.isSettled) {
                              let PassValues = {
                                requestInfo: requestInfo_PP(
                                  sessionStorage.getItem("jwt-token"),
                                  sessionStorage.getItem("jwt-token"),
                                ),
                                settlementRequest: {
                                  accountNumber: this.props.history.location.state?.seedAccountNumber,
                                  amountSettled: Number(sessionStorage.getItem("amounttopay")),
                                  settlementStatus: "SETTLED",
                                },
                              };
                              settlementService.saveSettlementOption(
                                PassValues,
                                0,
                                (res) => {
                                  if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
                                    // success("Settlement ", successNotification)
                                  } else {
                                    // error(res?.data?.responseMessage[0] || "Something went wrong!", errorNotification);
                                    // alert("5");
                                    handleError(res?.data?.responseMessage[0] || "Something went wrong!");
                                  }
                                },
                                (error) => {
                                  // error("Something went wrong! Please try again", errorNotification);
                                  // alert("6");
                                  handleError("Something went wrong! Please try again");
                                },
                              );
                            }
                            // success(
                            //   <Translation>
                            //     {(t, { i18n }) => <label>{t("credit_successTransactionProcessed")}</label>}
                            //   </Translation>,
                            //   successNotification,
                            // );
                            // alert("7");
                            handleSuccess("Transaction Processed Successfully");
                            this.setState({
                              showloader: false,
                            });
                            sessionStorage.setItem("screen", "Thankyou");
                            // debugger;
                            var applicationId = btoa(this.state.ES_AppId);
                            var partyId = btoa(this.state.ES_PartyId);
                            var transactionId = btoa(res.data.transaction.paymentTxnId);
                            var billDate = btoa(res.data.transaction.paymentOrder.paymentOrderDetails[0].billDate);
                            var isTransactionSuccess = btoa(res.data.transaction.transactionStatus);
                            var planAmount = btoa(res.data.transaction.paidAmount);
                            var planid = btoa(this.state.ES_PlanId);
                            var priceSubscriptionID = btoa(this.state.ES_PriceSubId);
                            var purchaseParm = btoa(this.state.ES_PurchaseParameters);
                            var jsonDetails = btoa(this.state.ES_JsonData);
                            var subsriberId = btoa(this.state.ES_SubId);

                            if (sessionStorage.getItem("SIC_Info") !== null) {
                              var proxy_url = sessionStorage.getItem("SIC_URL");
                              window.open(
                                proxy_url +
                                  "/accountActivate?id=" +
                                  applicationId +
                                  "&p=" +
                                  partyId +
                                  "&t=" +
                                  transactionId +
                                  "&b=" +
                                  billDate +
                                  "&s=" +
                                  isTransactionSuccess +
                                  "&pr=" +
                                  planAmount +
                                  "&pl=" +
                                  planid +
                                  "&ps=" +
                                  priceSubscriptionID +
                                  "&pp=" +
                                  purchaseParm +
                                  "&sb=" +
                                  subsriberId +
                                  "&js=" +
                                  jsonDetails,
                                "_self",
                              );
                            } else {
                              this.props.history.push({
                                pathname: "/thank-you",
                                state: { page: this.state?.page },
                              });
                            }
                          } else if (res["data"].transaction.transactionStatus === "PENDING") {
                            // warning(res["data"].transaction.transactionStatusMessage, warningNotification);
                            // alert("11");
                            handleWarning(res["data"].transaction.transactionStatusMessage);
                            sessionStorage.setItem("screen", "Thankyou");
                            this.props.history.push({
                              pathname: "/Transaction-Pending",
                              state: { page: this.state?.page },
                            });
                          } else {
                            this.setState({
                              showloader: false,
                            });
                            // error(res["data"].transaction.transactionStatusMessage, errorNotification);
                            // alert("8");
                            handleError(res["data"].transaction.transactionStatusMessage);
                            sessionStorage.setItem("screen", "Thankyou");
                            this.props.history.push({
                              pathname: "/Transaction-Fail",
                              state: { page: this.state?.page },
                            });
                          }
                        } else if (res["data"].responseInfo.status === "FAILED") {
                          var applicationId = btoa(this.state.ES_AppId);
                          var partyId = btoa(this.state.ES_PartyId);

                          // error(res["data"].responseInfo.responseMessage[0], errorNotification);
                          handleError(res["data"].responseInfo.responseMessage[0]);
                          // alert("9");
                          this.setState({
                            showloader: false,
                          });
                        } else {
                          // warning(
                          //   (
                          //     <Translation>
                          //       {(t, { i18n }) => <label>{t("credit_warningSomethingWrong")}</label>}
                          //     </Translation>
                          //   ) + res["data"].responseInfo.responseMessage[0],
                          //   warningNotification,
                          // );
                          // alert("10");
                          handleWarning("Something went wrong");
                          this.setState({
                            showloader: false,
                          });
                        }
                      },
                      (error) => {
                        console.log(error);
                      },
                    );
                  }
                }
              }, console.log("Token Error"));
            } else {
              var TransactionValues = {
                requestInfo: requestInfo_PP(Token, jtiToken),
                transaction: {
                  paymentOrder: {
                    paymentOrderId: sessionStorage.getItem("Ordernumber")
                      ? sessionStorage.getItem("Ordernumber")
                      : this.state.ES_OrderId,
                    paymentSms: values?.paymentSms && values?.paymentSms === true ? 1 : 0,
                    paymentEmail: values?.paymentEmail && values?.paymentEmail === true ? 1 : 0,
                  },
                  gatewayName: "FORTE",
                  isFinalSettlement: this.props.history.location.state?.isSettled ? "Y" : null,
                  // "action": "sale",
                  // "gatewayId": "14578",
                  // "paymentGatewayId": "123", // need to send later

                  contactPartyId: contactpartyid,
                  payerPartyId: partyid ? partyid : this.state.ES_PartyId,
                  paymentInfo: {
                    action: "sale",
                    authorizationAmount:
                      Number(sessionStorage.getItem("amounttopay")) !== 0
                        ? sessionStorage.getItem("amounttopay")
                        : this.state.ES_Amount, // actual paid value partial or full amount
                    subtotalAmount:
                      Number(sessionStorage.getItem("TotalAmount")) !== 0
                        ? sessionStorage.getItem("TotalAmount")
                        : this.state.ES_Amount, // actual paid value partial or full amount
                    billingAddress: {
                      billingAddressId: this.state?.billingAddress?.billingAddressId || 0,
                      firstName: values.firstname,
                      lastName: values.lastname,
                      // "firstName": partyids.firstName,
                      // "lastName": partyids.lastName,
                      email: values.emailAddress,
                      phoneNo: values.phoneNumber,
                      street: values.address1,
                      street2: "",
                      city: values.city,
                      state: values.state,
                      country: values.country,
                      postalCode: values.zipCode.replace("-", ""),
                    },
                    card: {
                      cardId: this.state.selectedCardData.cardId || this.state.cardId,
                      cardType: values.cardtype,
                      nameOnCard: values.firstname + " " + values.lastname,
                      accountNumber: values.cardNumber,
                      expireMonth: values.month,
                      expireYear: values.year,
                      cardVerificationValue: values.cardCvvNumber,
                    },
                  },
                  mappedPayerPaymentPlanAgreementId: "0", // will get from fe
                  paymentGatewayId: "00", // for now 45
                  instrumentId: this.state.instrumentid ? this.state.instrumentid : null, // for now 99
                  paymentMethodId: "75001", //
                  targetAmount:
                    Number(sessionStorage.getItem("TotalAmount")) !== 0
                      ? sessionStorage.getItem("TotalAmount")
                      : this.state.ES_Amount,
                  paidAmount:
                    Number(sessionStorage.getItem("amounttopay")) !== 0
                      ? sessionStorage.getItem("amounttopay")
                      : this.state.ES_Amount,
                  paymentType: "Online",
                  paymentSubtype: "gateway",
                  paymentCode: "ADA", // for now ADA need to decide
                  refundedAmount: "0", // blank or 0
                  amountPaidAgency: "0", // for now 0
                  paidAgencyId: "0", // for now 0
                },
              };

              if (this.state.instrumentid) {
                paymentCreditCardService.paymentTransaction(
                  TransactionValues,
                  UserId,
                  (res) => {
                    // debugger;
                    // alert(JSON.stringify(res["data"]))
                    if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
                      // success("Success", successNotification);
                      // // sessionStorage.setItem('payerinfo', JSON.stringify(PassValues.payerAccountSearch));
                      // this.props.history.push("/Transaction-Successful");
                      sessionStorage.setItem("transactiondata", JSON.stringify(res["data"].transaction));
                      sessionStorage.setItem("transactionId", res["data"].transaction.paymentTxnId);
                      // debugger;
                      if (res["data"]?.transaction?.transactionStatus === "SUCCESS") {
                        if (this.props.history.location.state?.isSettled) {
                          let PassValues = {
                            requestInfo: requestInfo_PP(
                              sessionStorage.getItem("jwt-token"),
                              sessionStorage.getItem("jwt-token"),
                            ),
                            settlementRequest: {
                              accountNumber: this.props.history.location.state?.seedAccountNumber,
                              amountSettled: Number(sessionStorage.getItem("amounttopay")),
                              settlementStatus: "SETTLED",
                            },
                          };
                          settlementService.saveSettlementOption(
                            PassValues,
                            0,
                            (res) => {
                              if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
                                // success("Settlement ", successNotification)
                              } else {
                                // error(res?.data?.responseMessage[0] || "Something went wrong!", errorNotification);
                                // alert("13");
                                handleError(res?.data?.responseMessage[0] || "Something went wrong!");
                              }
                            },
                            (error) => {
                              // error("Something went wrong! Please try again", errorNotification);
                              // alert("14");
                              handleError("Something went wrong! Please try again");
                            },
                          );
                        }
                        // success(
                        //   <Translation>
                        //     {(t, { i18n }) => <label>{t("credit_successTransactionProcessed")}</label>}
                        //   </Translation>,
                        //   successNotification,
                        // );
                        // alert("A");
                        handleSuccess("Transaction Processed Successfully");
                        this.setState({
                          showloader: false,
                        });
                        sessionStorage.setItem("screen", "Thankyou");
                        // debugger;
                        setTimeout(() => {
                          this.props.history.push("/thank-you");
                        }, 1500);
                      } else if (res["data"]?.transaction?.transactionStatus === "PENDING") {
                        // warning(res["data"].transaction.transactionStatusMessage, warningNotification);
                        sessionStorage.setItem("screen", "Thankyou");
                        this.props.history.push("/Transaction-Pending");
                      } else {
                        // error(res["data"].transaction.transactionStatusMessage, errorNotification);
                        // alert("15");
                        handleError(res["data"].transaction.transactionStatusMessage);
                        sessionStorage.setItem("screen", "Thankyou");
                        this.props.history.push("/Transaction-Fail");
                      }
                    } else if (res["data"]?.responseInfo?.status === "FAILED") {
                      var applicationId = btoa(this.state.ES_AppId);
                      var partyId = btoa(this.state.ES_PartyId);

                      // error(res["data"].responseInfo.responseMessage[0], errorNotification);
                      // alert("7");
                      handleError(res["data"].responseInfo.responseMessage[0]);
                      this.setState({
                        showloader: false,
                      });
                    } else {
                      // warning(
                      //   <Translation>{(t, { i18n }) => <label>{t("credit_warningSomethingWrong")}</label>}</Translation> +
                      //     res["data"]?.responseInfo?.responseMessage[0],
                      //   warningNotification,
                      // );
                      // alert("16");
                      handleWarning(res["data"]?.responseInfo?.responseMessage[0]);
                      this.setState({
                        showloader: false,
                      });
                    }
                  },
                  (error) => {
                    this.setState({
                      showloader: false,
                    });
                    console.log(error);
                  },
                );
              }
            }
            // sessionStorage.setItem('payerinfo', JSON.stringify(PassValues.payerAccountSearch));
            // this.props.history.push("/Transaction-Successful");
          } else if (res["data"].responseInfo.status === "FAILED") {
            // error(

            //     i18n.t("credit_errorMsgSomethingWrong")
            //     + res["data"].responseInfo.responseMessage[0],

            //     errorNotification
            // );
            // error(res["data"].responseInfo.status, errorNotification);
            // alert("77");
            handleError(res["data"].responseInfo.responseMessage[0]);
            this.setState({ showloader: false });
          } else {
            // warning(
            //     "Something wents wrong..! " + res["data"].reasonText,
            //     warningNotification
            // );
          }
        },
        (error) => {
          console.log(error);
        },
      );
    } else {
      // paymentCreditCardService.saveCreditDetails(
      //     PassValues,
      //     UserId,
      //     (res) => {
      //         // alert(JSON.stringify(res["data"]))
      //         if (res["data"].responseInfo.status === "SUCCESSFUL") {
      //             // success("Success", successNotification);

      //             this.setState({
      //                 instrumentid: res["data"].paymentInfos[0].cardAccount[0].paymentInstrument.paymentInstrumentId
      //             });
      //             const partyIds = JSON.parse(sessionStorage.getItem("verifypayer"))

      //             var partyid = partyIds === null ? "" : partyids.partyId;
      //             var contactpartyid = partyIds === null ? "" : partyids.partyTypeId;
      //             // var nameoncard = values.nameOnCard;
      //             // nameoncard = nameoncard.split(" ");
      if (sessionStorage.getItem("SIC_Info") !== null) {
        // debugger;
        sessionStorage.setItem("buid", "6");
        loginService.TokenUser((res) => {
          if (res.status === "success") {
            sessionStorage.setItem("jwt-token", res.responseObject);
            sessionStorage.setItem("jwt-tokencore", res.responseObject);

            var Token = res.responseObject;
            var jtiToken = res.responseObject;

            var TransactionValues = {
              requestInfo: requestInfo_PP(Token, jtiToken),
              transaction: {
                paymentOrder: {
                  paymentOrderId: sessionStorage.getItem("Ordernumber")
                    ? sessionStorage.getItem("Ordernumber")
                    : this.state.ES_OrderId,
                  paymentSms: values?.paymentSms && values?.paymentSms === true ? 1 : 0,
                  paymentEmail: values?.paymentEmail && values?.paymentEmail === true ? 1 : 0,
                },
                gatewayName: "FORTE",
                isFinalSettlement: this.props.history.location.state?.isSettled ? "Y" : null,
                // "action": "sale",
                // "gatewayId": "14578",
                // "paymentGatewayId": "123", // need to send later

                contactPartyId: contactpartyid,
                payerPartyId: partyid ? partyid : this.state.ES_PartyId,
                paymentInfo: {
                  action: "sale",
                  authorizationAmount:
                    Number(sessionStorage.getItem("amounttopay")) !== 0
                      ? sessionStorage.getItem("amounttopay")
                      : this.state.ES_Amount, // actual paid value partial or full amount
                  subtotalAmount:
                    Number(sessionStorage.getItem("TotalAmount")) !== 0
                      ? sessionStorage.getItem("TotalAmount")
                      : this.state.ES_Amount, // actual paid value partial or full amount
                  billingAddress: {
                    billingAddressId: this.state?.billingAddress?.billingAddressId || 0,
                    firstName: values.firstname,
                    lastName: values.lastname,
                    // "firstName": partyids.firstName,
                    // "lastName": partyids.lastName,
                    email: values.emailAddress,
                    phoneNo: values.phoneNumber,
                    street: values.address1,
                    street2: "",
                    city: values.city,
                    state: values.state,
                    country: values.country,
                    postalCode: values.zipCode.replace("-", ""),
                  },
                  card: {
                    cardId: this.state.selectedCardData.cardId || 0,
                    cardType: values.cardtype,
                    nameOnCard: values.firstname + " " + values.lastname,
                    accountNumber: values.cardNumber,
                    expireMonth: values.month,
                    expireYear: values.year,
                    cardVerificationValue: values.cardCvvNumber,
                  },
                },
                mappedPayerPaymentPlanAgreementId: "0", // will get from fe
                paymentGatewayId: "00", // for now 45
                instrumentId: this.state?.selectedCardData?.instrumentId ? this.state.selectedCardData?.instrumentId : 0, // for now 99
                paymentMethodId: "75001", //
                targetAmount:
                  Number(sessionStorage.getItem("TotalAmount")) !== 0
                    ? sessionStorage.getItem("TotalAmount")
                    : this.state.ES_Amount,
                paidAmount:
                  Number(sessionStorage.getItem("amounttopay")) !== 0
                    ? sessionStorage.getItem("amounttopay")
                    : this.state.ES_Amount,
                paymentType: "Online",
                paymentSubtype: "gateway",
                paymentCode: "ADA", // for now ADA need to decide
                refundedAmount: "0", // blank or 0
                amountPaidAgency: "0", // for now 0
                paidAgencyId: "0", // for now 0
              },
            };

            // if (this.state.instrumentid) {
            paymentCreditCardService.paymentTransaction(
              TransactionValues,
              UserId,
              (res) => {
                // debugger;
                // alert(JSON.stringify(res["data"]))
                if (res["data"].responseInfo.status === "SUCCESSFUL") {
                  sessionStorage.setItem("buid", "2");
                  // success("Success", successNotification);
                  // // sessionStorage.setItem('payerinfo', JSON.stringify(PassValues.payerAccountSearch));
                  // this.props.history.push("/Transaction-Successful");
                  sessionStorage.setItem("transactiondata", JSON.stringify(res["data"].transaction));
                  sessionStorage.setItem("transactionId", res["data"].transaction.paymentTxnId);
                  // debugger;
                  if (res["data"].transaction.transactionStatus === "SUCCESS") {
                    if (this.props.history.location.state?.isSettled) {
                      let PassValues = {
                        requestInfo: requestInfo_PP(
                          sessionStorage.getItem("jwt-token"),
                          sessionStorage.getItem("jwt-token"),
                        ),
                        settlementRequest: {
                          accountNumber: this.props.history.location.state?.seedAccountNumber,
                          amountSettled: Number(sessionStorage.getItem("amounttopay")),
                          settlementStatus: "SETTLED",
                        },
                      };
                      settlementService.saveSettlementOption(
                        PassValues,
                        0,
                        (res) => {
                          if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
                            // success("Settlement ", successNotification)
                          } else {
                            // error(res?.data?.responseMessage[0] || "Something went wrong!", errorNotification);
                            // alert("17");
                            handleError(res?.data?.responseMessage[0] || "Something went wrong!");
                          }
                        },
                        (error) => {
                          // error("Something went wrong! Please try again", errorNotification);
                        },
                      );
                    }
                    // success(
                    //   <Translation>{(t, { i18n }) => <label>{t("credit_successTransactionProcessed")}</label>}</Translation>,
                    //   successNotification,
                    // );
                    this.setState({
                      showloader: false,
                    });
                    sessionStorage.setItem("screen", "Thankyou");
                    // debugger;
                    var applicationId = btoa(this.state.ES_AppId);
                    var partyId = btoa(this.state.ES_PartyId);
                    var transactionId = btoa(res.data.transaction.paymentTxnId);
                    var billDate = btoa(res.data.transaction.paymentOrder.paymentOrderDetails[0].billDate);
                    var isTransactionSuccess = btoa(res.data.transaction.transactionStatus);
                    var planAmount = btoa(res.data.transaction.paidAmount);
                    var planid = btoa(this.state.ES_PlanId);
                    var priceSubscriptionID = btoa(this.state.ES_PriceSubId);
                    var purchaseParm = btoa(this.state.ES_PurchaseParameters);
                    var jsonDetails = btoa(this.state.ES_JsonData);
                    var subsriberId = btoa(this.state.ES_SubId);

                    if (sessionStorage.getItem("SIC_Info") !== null) {
                      var proxy_url = sessionStorage.getItem("SIC_URL");
                      window.open(
                        proxy_url +
                          "/accountActivate?id=" +
                          applicationId +
                          "&p=" +
                          partyId +
                          "&t=" +
                          transactionId +
                          "&b=" +
                          billDate +
                          "&s=" +
                          isTransactionSuccess +
                          "&pr=" +
                          planAmount +
                          "&pl=" +
                          planid +
                          "&ps=" +
                          priceSubscriptionID +
                          "&pp=" +
                          purchaseParm +
                          "&sb=" +
                          subsriberId +
                          "&js=" +
                          jsonDetails,
                        "_self",
                      );
                    } else {
                      this.props.history.push({
                        pathname: "/thank-you",
                        state: { page: this.state?.page },
                      });
                    }
                  } else if (res["data"].transaction.transactionStatus === "PENDING") {
                    // warning(res["data"].transaction.transactionStatusMessage, warningNotification);
                    sessionStorage.setItem("screen", "Thankyou");
                    this.props.history.push({
                      pathname: "/Transaction-Pending",
                      state: { page: this.state?.page },
                    });
                  } else {
                    this.setState({
                      showloader: false,
                    });
                    // error(res["data"].transaction.transactionStatusMessage, errorNotification);
                    sessionStorage.setItem("screen", "Thankyou");
                    this.props.history.push({
                      pathname: "/Transaction-Fail",
                      state: { page: this.state?.page },
                    });
                  }
                } else if (res["data"].responseInfo.status === "FAILED") {
                  var applicationId = btoa(this.state.ES_AppId);
                  var partyId = btoa(this.state.ES_PartyId);

                  // error(res["data"].responseInfo.responseMessage[0], errorNotification);
                  // alert("89");
                  handleError(res["data"].responseInfo.responseMessage[0]);
                  this.setState({
                    showloader: false,
                  });
                } else {
                  // warning(
                  //   <Translation>{(t, { i18n }) => <label>{t("credit_warningSomethingWrong")}</label>}</Translation> +
                  //     res["data"].responseInfo.responseMessage[0],
                  //   warningNotification,
                  // );
                  this.setState({
                    showloader: false,
                  });
                }
              },
              (error) => {
                console.log(error);
              },
            );
            // }
          }
        }, console.log("Token Error"));
      } else {
        var TransactionValues = {
          requestInfo: requestInfo_PP(Token, jtiToken),
          transaction: {
            paymentOrder: {
              paymentOrderId: sessionStorage.getItem("Ordernumber")
                ? sessionStorage.getItem("Ordernumber")
                : this.state.ES_OrderId,
              paymentSms: values?.paymentSms && values?.paymentSms === true ? 1 : 0,
              paymentEmail: values?.paymentEmail && values?.paymentEmail === true ? 1 : 0,
            },
            gatewayName: "FORTE",
            isFinalSettlement: this.props.history.location.state?.isSettled ? "Y" : null,
            // "action": "sale",
            // "gatewayId": "14578",
            // "paymentGatewayId": "123", // need to send later

            contactPartyId: contactpartyid,
            payerPartyId: partyid ? partyid : this.state.ES_PartyId,
            paymentInfo: {
              action: "sale",
              authorizationAmount:
                Number(sessionStorage.getItem("amounttopay")) !== 0
                  ? sessionStorage.getItem("amounttopay")
                  : this.state.ES_Amount, // actual paid value partial or full amount
              subtotalAmount:
                Number(sessionStorage.getItem("TotalAmount")) !== 0
                  ? sessionStorage.getItem("TotalAmount")
                  : this.state.ES_Amount, // actual paid value partial or full amount
              billingAddress: {
                billingAddressId: this.state?.billingAddress?.billingAddressId || 0,
                firstName: values.firstname,
                lastName: values.lastname,
                // "firstName": partyids.firstName,
                // "lastName": partyids.lastName,
                email: values.emailAddress,
                phoneNo: values.phoneNumber,
                street: values.address1,
                street2: "",
                city: values.city,
                state: values.state,
                country: values.country,
                postalCode: values.zipCode.replace("-", ""),
              },
              card: {
                cardId: this.state.selectedCardData.cardId || 0,
                cardType: values.cardtype,
                nameOnCard: values.firstname + " " + values.lastname,
                accountNumber: values.cardNumber,
                expireMonth: values.month,
                expireYear: values.year,
                cardVerificationValue: values.cardCvvNumber,
              },
            },
            mappedPayerPaymentPlanAgreementId: "0", // will get from fe
            paymentGatewayId: "00", // for now 45
            instrumentId: this.state?.selectedCardData?.instrumentId ? this.state?.selectedCardData?.instrumentId : null, // for now 99
            paymentMethodId: "75001", //
            targetAmount:
              Number(sessionStorage.getItem("TotalAmount")) !== 0
                ? sessionStorage.getItem("TotalAmount")
                : this.state.ES_Amount,
            paidAmount:
              Number(sessionStorage.getItem("amounttopay")) !== 0
                ? sessionStorage.getItem("amounttopay")
                : this.state.ES_Amount,
            paymentType: "Online",
            paymentSubtype: "gateway",
            paymentCode: "ADA", // for now ADA need to decide
            refundedAmount: "0", // blank or 0
            amountPaidAgency: "0", // for now 0
            paidAgencyId: "0", // for now 0
          },
        };

        // if (this.state.instrumentid) {
        paymentCreditCardService.paymentTransaction(
          TransactionValues,
          UserId,
          (res) => {
            // debugger;
            // alert(JSON.stringify(res["data"]))
            if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
              // success("Success", successNotification);
              // // sessionStorage.setItem('payerinfo', JSON.stringify(PassValues.payerAccountSearch));
              // this.props.history.push("/Transaction-Successful");
              sessionStorage.setItem("transactiondata", JSON.stringify(res["data"].transaction));
              sessionStorage.setItem("transactionId", res["data"].transaction.paymentTxnId);
              // debugger;
              if (res["data"]?.transaction?.transactionStatus === "SUCCESS") {
                if (this.props.history.location.state?.isSettled) {
                  let PassValues = {
                    requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
                    settlementRequest: {
                      accountNumber: this.props.history.location.state?.seedAccountNumber,
                      amountSettled: Number(sessionStorage.getItem("amounttopay")),
                      settlementStatus: "SETTLED",
                    },
                  };
                  settlementService.saveSettlementOption(
                    PassValues,
                    0,
                    (res) => {
                      if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
                        // success("Settlement ", successNotification)
                      } else {
                        // error(res?.data?.responseMessage[0] || "Something went wrong!", errorNotification);
                        // alert("18");
                        handleError(res?.data?.responseMessage[0] || "Something went wrong!");
                      }
                    },
                    (error) => {
                      // error("Something went wrong! Please try again", errorNotification);
                    },
                  );
                }
                // success(
                //   <Translation>{(t, { i18n }) => <label>{t("credit_successTransactionProcessed")}</label>}</Translation>,
                //   successNotification,
                // );
                handleSuccess("Transaction Processed Successfully");
                // alert("19");
                this.setState({
                  showloader: false,
                });
                sessionStorage.setItem("screen", "Thankyou");
                // debugger;
                setTimeout(() => {
                  this.props.history.push("/thank-you");
                }, 1500);
              } else if (res["data"]?.transaction?.transactionStatus === "PENDING") {
                // warning(res["data"].transaction.transactionStatusMessage, warningNotification);
                // alert("20");
                handleWarning(res["data"].transaction.transactionStatusMessage);
                sessionStorage.setItem("screen", "Thankyou");
                this.props.history.push("/Transaction-Pending");
              } else {
                // error(res["data"].transaction.transactionStatusMessage, errorNotification);
                // alert("21");
                handleError(res["data"].transaction.transactionStatusMessage);
                sessionStorage.setItem("screen", "Thankyou");
                this.props.history.push("/Transaction-Fail");
              }
            } else if (res["data"]?.responseInfo?.status === "FAILED") {
              var applicationId = btoa(this.state.ES_AppId);
              var partyId = btoa(this.state.ES_PartyId);

              // error(res["data"].responseInfo.responseMessage[0], errorNotification);
              // alert("22");
              handleError(res["data"].responseInfo.responseMessage[0]);

              this.setState({
                showloader: false,
              });
            } else {
              // warning(
              //   <Translation>{(t, { i18n }) => <label>{t("credit_warningSomethingWrong")}</label>}</Translation> +
              //     res["data"]?.responseInfo?.responseMessage[0],
              //   warningNotification,
              // );
              // alert("23");
              handleWarning(res["data"]?.responseInfo?.responseMessage[0]);
              this.setState({
                showloader: false,
              });
            }
          },
          (error) => {
            this.setState({
              showloader: false,
            });
            console.log(error);
          },
        );
        // }
      }
      // sessionStorage.setItem('payerinfo', JSON.stringify(PassValues.payerAccountSearch));
      // this.props.history.push("/Transaction-Successful");
    }
    //         else if (res["data"].responseInfo.status === "FAILED") {

    //             // error(

    //             //     i18n.t("credit_errorMsgSomethingWrong")
    //             //     + res["data"].responseInfo.responseMessage[0],

    //             //     errorNotification
    //             // );
    //             error(
    //             res["data"].responseInfo.status,errorNotification
    //             )
    //             this.setState({showloader: false})
    //         } else {
    //             // warning(
    //             //     "Something wents wrong..! " + res["data"].reasonText,
    //             //     warningNotification
    //             // );
    //         }
    //     },
    //     (error) => {
    //         console.log(error);
    //     }
    // );
    // }

    // const partyIds = sessionStorage.getItem("order_FP")  verifypayer
    //  var partyid = JSON.parse(partyIds)[0].partyId;
    //  var contactpartyid = JSON.parse(partyIds)[0].contactPartyId;
  };

  redirectToBack = () => {
    if (this.state.page === "quickPay") {
      this.props.history.push("payment-outstanding");
    } else {
      this.props.history.push("AccountDetails");
    }
  };

  onSubmit = (values) => {};
  handleCardNumber = () => {
    this.setState({ cardnumber: !this.state.cardnumber });
  };

  handleRoutingNumber = () => {
    this.setState({ routingNumber: !this.state.routingNumber });
  };

  handleAccountNumber = () => {
    this.setState({ accountNumber: !this.state.accountNumber });
  };

  handleReAccountNumber = () => {
    this.setState({ reAccountNumber: !this.state.reAccountNumber });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  _handlePay = (values) => {
    this._handleNewCard(values);
  };
  getAddressObject = (address_components) => {
    const ShouldBeComponent = {
      street_number: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      province: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      country: ["country"],
    };

    let address = {
      street_number: "",
      postal_code: "",
      street: "",
      province: "",
      city: "",
      country: "",
    };

    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "country") {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });

    // Fix the shape to match our schema
    address.address = address?.street_number + " " + address?.street;
    delete address.street_number;
    delete address.street;
    if (address.country === "US") {
      address.state = address.province;
      delete address.province;
    }
    return address;
  };
  getGoogleApiInfo = async (values, val) => {
    const geocodeObj = val && val.value && (await geocodeByPlaceId(val.value.place_id));
    const addressObject = geocodeObj && this.getAddressObject(geocodeObj[0].address_components);

    // setAddressObj(addressObject);
    addressObject
      ? this.setState({
          ...values,
          country:
            addressObject?.country.toLocaleLowerCase() === "us" || addressObject.country.toLocaleLowerCase() === "usa"
              ? "united states of america"
              : addressObject.country,
          state: addressObject?.state,
          city: addressObject?.city,
          zipCode: addressObject?.postal_code,
          address1: addressObject?.address.trim(),
          address2: "",
          googleaddress: val,
        })
      : this.setState({
          ...values,
          googleaddress: "clear",
        });
  };
  render() {
    const billing = this.state.billingAddress;
    const monthsData = [
      {
        value: "01",
        label: "Jan",
      },
      {
        value: "02",
        label: "Feb",
      },
      {
        value: "03",
        label: "Mar",
      },
      {
        value: "04",
        label: "Apr",
      },
      {
        value: "05",
        label: "May",
      },
      {
        value: "06",
        label: "Jun",
      },
      {
        value: "07",
        label: "Jul",
      },
      {
        value: "08",
        label: "Aug",
      },
      {
        value: "09",
        label: "Sep",
      },
      {
        value: "10",
        label: "Oct",
      },
      {
        value: "11",
        label: "Nov",
      },
      {
        value: "12",
        label: "Dec",
      },
    ];
    return (
      <>
        {this.state.showloader === true ? <SpinnerPage /> : ""}
        <Stepper />
        <div className="dashboard-content creditcard-content px-0" role="main">
          <div className="dashboard-content-card">
            <Formik
              enableReinitialize
              initialValues={{
                futureDate: this.state.futureDate,
                cardtype: this.state.selectedCardData.cardType || this.state.cardtype,
                nameOnCard: this.state.nameOnCard,
                firstname: (billing && billing.firstName) || this.state.firstName,
                lastname: this.state.lastName || billing.lastName,
                middlename: this.state.middleName || billing.middleName,
                // cardNumber: this.state.carddata ? this.state.carddata.cardNumber : "",
                cardNumber: this.state.cardNumber || this.state.selectedCardData.cardNumber,
                cardmaskedNumber: this.state.cardmaskedNumber || this.state.selectedCardData.cardNumber,
                // month: this.state.carddata ? this.state.carddata.cardExpiryMonth : "",
                // year: this.state.carddata ? this.state.carddata.cardExpiryYear : "",
                month: this.state.month || this.state.selectedCardData.cardMonth,
                year: this.state.year || this.state.selectedCardData.cardYear,
                cardCvvNumber: this.state.cardCvvNumber,
                emailAddress: this.state.emailAddress || billing.email,
                phoneNumber: this.state.phoneNumber || billing.phoneNo,
                address1: this.state.address1 || billing.street,
                address2: this.state.address2 || billing.street2,
                stateList: stateList ? stateList : [],
                cityList: this.state.cityList || [],
                country: this.state.country || billing.country,
                countryid: this.state.countryid ? this.state.countryid : "",
                state: this.state.state || billing.state,
                stateid: this.state.stateid ? this.state.stateid : "",
                city: this.state.city || billing.city,
                cityid: this.state.cityid ? this.state.cityid : "",
                zipCode: this.state.zipCode || billing.postalCode,
                userdata: sessionStorage.getItem("currentUserRole") === "Admin" ? false : true,
                termsconditions: sessionStorage.getItem("currentUserRole") === "Admin" ? false : true,
                flag: true,
                isActive: "",
                showCardNumber: false,
                currentUserRole: sessionStorage.getItem("currentUserRole") || "",
                paymentEmail: true,
                paymentSms: false,
                reminderEmail: true,
                reminderSms: false,
              }}
              key="myform"
              // validationSchema={
              //   this.props.location.state.paymentMethod === "creditCard" ? this._paymentConfirmationValidation : null
              // }
              validationSchema={
                this?.props?.location?.state?.paymentMethod === "creditCard"
                  ? this?._paymentConfirmationValidation
                  : this?.props?.payInFuture
                  ? this?.futureDateSchema
                  : this.existingCardSchema
              }
              onSubmit={this.props.payInFuture ? this._handlePay : this._handleSubmit}>
              {(props) => {
                const { values, touched, error, handleChange, setErrors, handleBlur, errors, setFieldValue } = props;

                return (
                  <Form className="input-field e-check-bg" noValidate="noValidate">
                    {/* <PP_QuickPayStepper {...this.props} /> */}
                    <div className="col-md-12 col-lg-10 col-xl-9 mx-auto">
                      <div className="echeck-card">
                        <div className="account-balance">
                          <p>Total Account Balance</p>
                          <h3>
                            {" "}
                            ${" "}
                            {this.state.ES_Amount
                              ? Number(this.state.ES_Amount).toLocaleString(undefined, { minimumFractionDigits: 2 })
                              : Number(sessionStorage.getItem("TotalAmount")).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                })}
                          </h3>
                        </div>
                        <div className="account-balance">
                          <p>Amount To Pay</p>
                          <h3>
                            {" "}
                            ${" "}
                            {this.state.ES_Amount
                              ? Number(this.state.ES_Amount).toLocaleString(undefined, { minimumFractionDigits: 2 })
                              : Number(sessionStorage.getItem("amounttopay")).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                })}
                          </h3>
                        </div>
                        {this?.props?.payInFuture && (
                          <>
                            <div className="form-row">
                              <div className="col-md-4 col-sm-4 col-xl-4 col-lg-4 form-group">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <Grid container className="custom-date-picker date-picker">
                                    <label htmlFor="futureDate" className="form-label required">
                                      Future Date
                                    </label>
                                    <DatePicker
                                      // disableFuture
                                      name="futureDate"
                                      id="futureDate"
                                      autoOk
                                      value={values.futureDate}
                                      minDate={moment().toDate()}
                                      maxDate={
                                        (moment(this.state.lastDayOfMonth).isSame(moment().toDate(), "day") &&
                                          moment(this.state.secondLastDayOfMonth).isSame(moment().toDate(), "day")) ||
                                        (moment(this.state.thirdLastDayOfMonth).isSame(moment().toDate(), "day") &&
                                          (this.state.futureMonthDays === 28 || this.state.futureMonthDays === 29))
                                          ? moment(this.state.futureDate1).endOf("month").toDate()
                                          : moment().daysInMonth() === 30
                                          ? moment().add(29, "days").toDate()
                                          : moment().daysInMonth() === 29
                                          ? moment().add(28, "days").toDate()
                                          : moment().daysInMonth() === 28
                                          ? moment().add(27, "days").toDate()
                                          : moment().add(30, "days").toDate()
                                      }
                                      InputProps={{
                                        startAdornment: (
                                          <IconButton
                                            position="end"
                                            onClick={() => setFieldValue("futureDate", null)}
                                            disabled={!values?.futureDate}
                                            style={{ order: 1, margin: "0px", padding: "0px" }}>
                                            <ClearIcon
                                              color={!values?.futureDate ? "disabled" : "#808080"}
                                              fontSize="small"
                                            />
                                          </IconButton>
                                        ),
                                      }}
                                      InputAdornmentProps={{
                                        position: "end",
                                        style: { order: 2 },
                                      }}
                                      onChange={(val) => setFieldValue("futureDate", val)}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          helperText={null}
                                          margin="dense"
                                          inputProps={{
                                            ...params.inputProps,
                                            placeholder: "MM/DD/YYYY",
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                </LocalizationProvider>
                                <FormHelperText error>
                                  {touched.futureDate && errors.futureDate ? <div>{errors.futureDate}</div> : null}
                                </FormHelperText>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="card-title-header">
                          <div role="heading" aria-level="1">
                            {" "}
                            <h2 class="content-title">Debit/Credit Card Payment</h2>
                          </div>
                          <div className="d-flex">
                            {this.cardImages.map((items) => {
                              return <img src={items.cardImage} alt={items.name} key={items.name} className="m-1" />;
                            })}
                          </div>
                        </div>
                        <div>
                          {this?.props?.location?.state?.paymentMethod === "creditCard" ? (
                            <>
                              {/* <CreditCard /> */}

                              <div className="form-row  debitcard">
                                <div className="col-md-4 col-sm-6 form-group">
                                  <FormControl variant="outlined" className="w-100" fullWidth>
                                    <FormLabel htmlFor="firstname" className="required">
                                      First Name
                                    </FormLabel>
                                    <OutlinedInput
                                      className="form-control"
                                      name="firstname"
                                      // value={values.firstname}
                                      // value={values?.firstname?.charAt(0).toUpperCase() + values?.firstname?.slice(1)}
                                      value={
                                        values?.firstname
                                          ? values?.firstname?.charAt(0).toUpperCase() + values?.firstname?.slice(1)
                                          : ""
                                      }
                                      onChange={handleChange}
                                      placeholder="Enter First Name"
                                      aria-label="text-field"
                                      fullWidth
                                      required></OutlinedInput>
                                  </FormControl>
                                  <FormHelperText error name="securitycode">
                                    {touched.firstname && errors.firstname ? <div>{errors.firstname}</div> : null}
                                  </FormHelperText>
                                </div>
                                <div className="col-md-4 col-sm-6 form-group">
                                  <FormControl variant="outlined" className="w-100" fullWidth>
                                    <FormLabel htmlFor="middleName" className="">
                                      Middle Name
                                    </FormLabel>
                                    <OutlinedInput
                                      className="form-control"
                                      name="middleName"
                                      value={values.middleName}
                                      onChange={handleChange}
                                      placeholder="Enter Middle Name"
                                      aria-label="text-field"
                                      fullWidth></OutlinedInput>
                                  </FormControl>
                                </div>
                                <div className="col-md-4 col-sm-6 form-group">
                                  <FormControl variant="outlined" className="w-100" fullWidth>
                                    <FormLabel htmlFor="lastname" className="required">
                                      Last Name
                                    </FormLabel>
                                    <OutlinedInput
                                      className="form-control"
                                      name="lastname"
                                      // value={values.lastname}
                                      value={
                                        values?.lastname
                                          ? values?.lastname.charAt(0).toUpperCase() + values?.lastname.slice(1)
                                          : ""
                                      }
                                      onChange={handleChange}
                                      placeholder="Enter Last Name"
                                      aria-label="text-field"
                                      fullWidth
                                      required></OutlinedInput>
                                  </FormControl>
                                  <FormHelperText error name="securitycode">
                                    {touched.lastname && errors.lastname ? <div>{errors.lastname}</div> : null}
                                  </FormHelperText>
                                </div>

                                <div className="col-xl-6 col-md-5 col-sm-6 form-group ">
                                  <FormLabel htmlFor="cardnumber" className="required">
                                    Card Number
                                  </FormLabel>

                                  {this.state.selectedCardData?.instrumentId ? (
                                    <>
                                      <OutlinedInput
                                        type="text"
                                        autocomplete="new-password"
                                        id="cardNumber"
                                        // maskChar=""
                                        ref={this.myRef}
                                        disabled
                                        value={values.cardNumber}
                                        name="cardNumber"
                                        placeholder="Enter Card Number"
                                        className="form-control"
                                        InputProps={{
                                          inputComponent: CardFormatter,
                                        }}
                                      />
                                      <FormHelperText error name="securitycode">
                                        {touched.cardNumber && errors.cardNumber ? <div>{errors.cardNumber}</div> : null}
                                      </FormHelperText>
                                    </>
                                  ) : (
                                    <>
                                      <TextField
                                        type={values.showCardNumber ? "text" : "password"}
                                        margin="dense"
                                        variant="outlined"
                                        name="cardNumber"
                                        ref={this.myRef}
                                        placeholder="Enter Card Number"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        className={`form-control text-field h-auto maskfieldcc ${
                                          touched.cardNumber && errors.cardNumber ? "is-invalid" : ""
                                        }`}
                                        value={values.cardNumber}
                                        onChange={(e) => {
                                          // e.preventDefault();
                                          setErrors({ ...errors, cardNumber: "" });
                                          let cardno = e.target.value;
                                          var cardtype = false;
                                          var visapattern = /^4[0-9]{12}(?:[0-9]{3})?$/;
                                          var masterpattern = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/;
                                          var amexpattern = /^3[47][0-9]{13}$/;
                                          if (visapattern.exec(cardno.replace(/ /g, ""))) {
                                            cardtype = "Visa";
                                          } else if (masterpattern.exec(cardno.replace(/ /g, ""))) {
                                            cardtype = "Master Card";
                                          } else if (amexpattern.exec(cardno.replace(/ /g, ""))) {
                                            cardtype = "American Express";
                                          } else {
                                            cardtype = false;
                                          }
                                          setFieldValue("cardNumber", cardno);
                                          setFieldValue("cardtype", cardtype);
                                          setFieldValue("cardCvvNumber", "");
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <>
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={() => {
                                                    setFieldValue((values.showCardNumber = !values.showCardNumber));
                                                  }}>
                                                  {values.showCardNumber ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                              </>
                                            </InputAdornment>
                                          ),
                                          endAdornment: (
                                            <InputAdornment position="end" className="cardnumber-input">
                                              <>
                                                <span className="cards">
                                                  <img
                                                    src={
                                                      values.cardtype === "Visa"
                                                        ? visaImage
                                                        : values.cardtype === "Master Card"
                                                        ? masterImage
                                                        : values.cardtype === "American Express"
                                                        ? amexImage
                                                        : values.cardtype === "Discover"
                                                        ? discoverImage
                                                        : ""
                                                    }
                                                    alt={""}
                                                  />
                                                </span>
                                              </>
                                              {/* <img src={visaImage} alt="visa" /> */}
                                            </InputAdornment>
                                          ),
                                          inputComponent: values.showCardNumber ? CardFormatter : "",
                                        }}
                                        onBlur={handleBlur}
                                        onKeyPress={(e) => {
                                          if (!/\d/.test(e.key) || values?.cardNumber?.length > 15) {
                                            e.preventDefault();
                                          }
                                        }}
                                        fullWidth
                                        disabled={this.state.selectedCardData?.instrumentId}
                                      />

                                      <FormHelperText error name="securitycode">
                                        {touched.cardNumber && errors.cardNumber ? <div>{errors.cardNumber}</div> : null}
                                      </FormHelperText>
                                    </>
                                  )}
                                </div>
                                <div className="col-xl-2 col-md-2 col-sm-3 form-group inputbg">
                                  <FormControl variant="outlined" className="w-100" fullWidth>
                                    <FormLabel htmlFor="securitycode" className="required">
                                      Month
                                    </FormLabel>
                                    <Select
                                      fullWidth
                                      name="month"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.month}
                                      className="form-select form-control">
                                      <MenuItem value="none" disabled>
                                        Month
                                      </MenuItem>
                                      {monthsData.map((month, index) => {
                                        return (
                                          <MenuItem value={month.value} key={index}>
                                            {month.label}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                  <FormHelperText error name="securitycode">
                                    {touched.month && errors.month ? <div>{errors.month}</div> : null}
                                  </FormHelperText>
                                  {/* <ErrorMessage component="div" name="month" className="text-danger" /> */}
                                </div>
                                <div className="col-xl-2 col-md-2 col-sm-3 form-group inputbg">
                                  <FormControl variant="outlined" className="w-100" fullWidth>
                                    <FormLabel htmlFor="securitycode" className="required">
                                      Year
                                    </FormLabel>
                                    <Select
                                      fullWidth
                                      name="year"
                                      onChange={handleChange("year")}
                                      onBlur={handleBlur}
                                      value={values.year}
                                      className="form-select form-control">
                                      <MenuItem value="none" disabled>
                                        Year
                                      </MenuItem>
                                      <MenuItem value={Number(this.state.currentyear)}>
                                        {Number(this.state.currentyear)}
                                      </MenuItem>
                                      <MenuItem value={Number(this.state.currentyear) + 1}>
                                        {Number(this.state.currentyear) + 1}
                                      </MenuItem>
                                      <MenuItem value={Number(this.state.currentyear) + 2}>
                                        {Number(this.state.currentyear) + 2}
                                      </MenuItem>
                                      <MenuItem value={Number(this.state.currentyear) + 3}>
                                        {Number(this.state.currentyear) + 3}
                                      </MenuItem>
                                      <MenuItem value={Number(this.state.currentyear) + 4}>
                                        {Number(this.state.currentyear) + 4}
                                      </MenuItem>
                                      <MenuItem value={Number(this.state.currentyear) + 5}>
                                        {Number(this.state.currentyear) + 5}
                                      </MenuItem>
                                      <MenuItem value={Number(this.state.currentyear) + 6}>
                                        {Number(this.state.currentyear) + 6}
                                      </MenuItem>
                                      <MenuItem value={Number(this.state.currentyear) + 7}>
                                        {Number(this.state.currentyear) + 7}
                                      </MenuItem>
                                      <MenuItem value={Number(this.state.currentyear) + 8}>
                                        {Number(this.state.currentyear) + 8}
                                      </MenuItem>
                                      <MenuItem value={Number(this.state.currentyear) + 9}>
                                        {Number(this.state.currentyear) + 9}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                  <FormHelperText error name="securitycode">
                                    {touched.year && errors.year ? <div>{errors.year}</div> : null}
                                  </FormHelperText>
                                </div>
                                <div className="col-xl-2 col-md-3 col-sm-6 form-group">
                                  <FormControl variant="outlined" className="w-100" fullWidth>
                                    <div className="label-spacing">
                                      <FormLabel
                                        htmlFor="securitycode"
                                        className={sessionStorage.getItem("currentUserRole") === "Admin" ? "" : "required"}>
                                        CVV
                                      </FormLabel>
                                      <ImageTooltip img={cvvImage} width="250px" placement="right">
                                        <i className="fas fa-question-circle circle-icon cvv-icon"></i>
                                      </ImageTooltip>
                                    </div>

                                    <OutlinedInput
                                      type="password"
                                      id="cardCvvNumber"
                                      name="cardCvvNumber"
                                      value={values.cardNumber === "" ? "" : values.cardCvvNumber}
                                      max="9999"
                                      placeholder={i18n.t("credit_placeholderSecurityCode")}
                                      className={`form-control text-field maskfieldRN ${
                                        touched.cardCvvNumber && errors.cardCvvNumber ? "is-invalid" : ""
                                      }`}
                                      onChange={(event) => {
                                        if (values.cardtype === "Visa" || values.cardtype === "Master Card") {
                                          if (event.target.value.length < 4) {
                                            setFieldValue((values.cardCvvNumber = event.target.value));
                                          } else {
                                            setFieldValue((values.cardCvvNumber = ""));
                                          }
                                        } else {
                                          if (event.target.value.length < 5) {
                                            setFieldValue((values.cardCvvNumber = event.target.value));
                                          } else {
                                            setFieldValue((values.cardCvvNumber = ""));
                                          }
                                        }
                                      }}
                                    />
                                  </FormControl>
                                  <FormHelperText error name="securitycode">
                                    {touched.cardCvvNumber && errors.cardCvvNumber ? (
                                      <div>{errors.cardCvvNumber}</div>
                                    ) : null}
                                  </FormHelperText>
                                </div>
                              </div>

                              <div className="form-row">
                                <div className="col-md-6 col-sm-12 form-group billing-address-cp">
                                  <FormControl variant="outlined" className="w-100" fullWidth>
                                    <FormLabel htmlFor="address1" className="required">
                                      Billing Address
                                    </FormLabel>
                                    {/* <OutlinedInput
                                    className="form-control"
                                    name="address1"
                                    value={values.address1}
                                    onChange={handleChange}
                                    rows={5}
                                    placeholder="Enter Your Billing Address"
                                    aria-label="text-field"
                                    fullWidth
                                    required></OutlinedInput>
                                  <FormHelperText error name="securitycode">
                                    {touched.address1 && errors.address1 ? <div>{errors.address1}</div> : null}
                                  </FormHelperText> */}
                                    <GooglePlacesAutocomplete
                                      apiKey="AIzaSyAymCr1DkF6p_ULBgBIZIM2VNu9pg-zrN0"
                                      autocompletionRequest={{
                                        componentRestrictions: {
                                          country: ["us"],
                                        },
                                      }}
                                      selectProps={{
                                        isClearable: true,
                                        value: this.state.googleaddress,
                                        onChange: (val) => {
                                          this.getGoogleApiInfo({ ...values }, val);
                                        },
                                      }}
                                    />
                                  </FormControl>
                                </div>

                                <div className="col-md-6 col-sm-12 form-group">
                                  <div className="label-spacing mb-md-3">
                                    <FormLabel></FormLabel>
                                  </div>
                                  <FormControl variant="outlined" className="w-100 mt-md-1" fullWidth>
                                    <TextField
                                      type="text"
                                      autocomplete="new-password"
                                      id="address1"
                                      name="address1"
                                      placeholder="Please Enter Street Address"
                                      value={values?.address2 ? values?.address1 + "," + values?.address2 : values?.address1}
                                      className={`text-field ${touched.address1 && errors.address1 ? "is-invalid" : ""}`}
                                      onChange={handleChange}></TextField>
                                  </FormControl>
                                  <FormHelperText error name="securitycode">
                                    {touched.address1 && errors.address1 ? <div>{errors.address1}</div> : null}
                                  </FormHelperText>
                                </div>
                                <div className="col-md-3 col-sm-6 form-group inputbg">
                                  <FormControl variant="outlined" className="w-100" fullWidth>
                                    <FormLabel htmlFor="country" className="form-label required">
                                      Country
                                    </FormLabel>
                                    <Select
                                      fullWidth
                                      name="country"
                                      value={values.country}
                                      onChange={(event, value) => {
                                        $(".prepopulatecountry").remove();
                                        $(".prepopulate").remove();
                                        setFieldValue(((values.city = ""), (values.state = ""), (values.zipCode = "")));
                                        var csid = $("select#country").find(":selected").data("role");
                                        if (event.target.value !== "") {
                                          // debugger
                                          values.country = event.target.value;
                                          var PassValues = {
                                            countryId: csid,
                                            limit: -1,
                                          };
                                          const UserId = "1234";
                                          RegionManagementService.getState(PassValues, UserId, (res) => {
                                            // debugger
                                            //    values.stateList =
                                            //          res.data.responseListObject
                                            setFieldValue(
                                              (values.stateList = res.data.responseListObject),
                                              (values.country = event.target.value),
                                            );
                                          });
                                        } else {
                                          setFieldValue((values.country = "select"));
                                        }
                                      }}
                                      className="form-select form-control">
                                      <MenuItem value="none" disabled>
                                        Select Country
                                      </MenuItem>
                                      {countryList.map((option, index) => {
                                        return (
                                          <MenuItem value={option.countryName} key={option.key}>
                                            {option.countryName.charAt(0).toUpperCase() + option.countryName.slice(1)}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                  <FormHelperText error name="securitycode">
                                    {touched.country && errors.country ? <div>{errors.country}</div> : null}
                                  </FormHelperText>
                                </div>
                                <div className="col-md-3 col-sm-6 form-group inputbg">
                                  <FormControl variant="outlined" className="w-100" fullWidth>
                                    <FormLabel htmlFor="state" className="form-label required">
                                      State/County
                                    </FormLabel>
                                    <Select
                                      fullWidth
                                      name="StateCounty"
                                      value={values?.state?.toLowerCase() || "none"}
                                      onChange={(event) => {
                                        $(".prepopulatestate").remove();
                                        $(".prepopulatecity").remove();
                                        setFieldValue(((values.city = ""), (values.zipCode = "")));
                                        var csid = $("select#state").find(":selected").data("role");

                                        if (event.target.value !== "") {
                                          values.state = event.target.value;
                                          setFieldValue((values.state = event.target.value));
                                          var PassValues = {
                                            stateId: csid,
                                          };
                                          const UserId = "1234";
                                          RegionManagementService.getCity(PassValues, UserId, (res) => {
                                            setFieldValue((values.cityList = res.data.responseListObject));
                                          });
                                          $("#city").focus();
                                        }
                                      }}
                                      className="form-select form-control">
                                      <MenuItem value="none" key="none" disabled>
                                        Select State/County
                                      </MenuItem>
                                      {values?.stateList?.map((option) => {
                                        return (
                                          <MenuItem value={option.stateName} key={option.key}>
                                            {option.stateName.charAt(0).toUpperCase() + option.stateName.slice(1)}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                  <FormHelperText error name="securitycode">
                                    {touched.StateCounty && errors.StateCounty ? <div>{errors.StateCounty}</div> : null}
                                  </FormHelperText>
                                </div>
                                <div className="col-md-3 col-sm-6 form-group inputbg">
                                  <FormControl variant="outlined" className="w-100" fullWidth>
                                    <FormLabel htmlFor="city" className="form-label required">
                                      City
                                    </FormLabel>
                                    <OutlinedInput
                                      onChange={(event) => {
                                        $(".prepopulatecity").remove();
                                        setFieldValue((values.zipCode = ""));
                                        setFieldValue((values.city = event.target.value));
                                      }}
                                      fullWidth
                                      name="city"
                                      value={capitalize(values?.city)}
                                      placeholder="Enter City"
                                      className="form-select form-control"></OutlinedInput>
                                  </FormControl>
                                  <FormHelperText error name="securitycode">
                                    {touched.city && errors.city ? <div>{errors.city}</div> : null}
                                  </FormHelperText>
                                </div>
                                <div className="col-md-3 col-sm-6 form-group inputbg">
                                  <FormControl variant="outlined" className="w-100" fullWidth>
                                    <FormLabel htmlFor="zipCode" className="required">
                                      ZIP Code
                                    </FormLabel>
                                    <OutlinedInput
                                      className="form-control"
                                      name="zipCode"
                                      value={values.zipCode}
                                      onBlur={handleBlur}
                                      onChange={(event) => {
                                        const zip = event.target.value;
                                        setFieldValue(
                                          (values.zipCode =
                                            zip.length > 5 && !zip.includes("-")
                                              ? zip.substring(0, 5).concat("-") + zip.substring(5, zip.length)
                                              : zip),
                                        );
                                      }}
                                      placeholder="Enter ZIP Code"
                                      aria-label="text-field"
                                      fullWidth
                                      inputProps={{ minLength: 5, maxLength: 10 }}
                                      required></OutlinedInput>
                                  </FormControl>
                                  <FormHelperText error name="securitycode">
                                    {touched.zipCode && errors.zipCode ? <div>{errors.zipCode}</div> : null}
                                  </FormHelperText>
                                </div>

                                <div className="col-md-6 col-sm-6 form-group">
                                  <FormControl variant="outlined" className="w-100" fullWidth>
                                    <FormLabel
                                      htmlFor="emailAddress"
                                      className={sessionStorage?.getItem("currentUserRole") === "Admin" ? "" : "required"}>
                                      Email Address
                                    </FormLabel>
                                    <OutlinedInput
                                      className="form-control"
                                      name="emailAddress"
                                      value={values.emailAddress}
                                      onChange={handleChange}
                                      type="email"
                                      placeholder="Enter Email Address"
                                      aria-label="text-field"
                                      fullWidth
                                      required></OutlinedInput>
                                  </FormControl>
                                  <FormHelperText error name="securitycode">
                                    {touched.emailAddress && errors.emailAddress ? <div>{errors.emailAddress}</div> : null}
                                  </FormHelperText>
                                </div>

                                <div className="col-md-6 col-sm-6 form-group">
                                  <FormControl variant="outlined" className="w-100" fullWidth>
                                    <FormLabel htmlFor="phoneNumber" className="required">
                                      Phone Number
                                    </FormLabel>
                                    <OutlinedInput
                                      className="form-control"
                                      name="phoneNumber"
                                      value={values.phoneNumber}
                                      onChange={handleChange}
                                      placeholder="Enter Phone Number"
                                      aria-label="text-field"
                                      fullWidth
                                      required></OutlinedInput>
                                  </FormControl>
                                  <FormHelperText error name="securitycode">
                                    {touched.phoneNumber && errors.phoneNumber ? <div>{errors.phoneNumber}</div> : null}
                                  </FormHelperText>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="form-row  debitcard">
                              <div className="col-md-4 col-sm-6 form-group">
                                <div className="debitcard-details">
                                  <div className="label">First Name : </div>
                                  <div className="value">
                                    {values.firstname &&
                                      values.firstname?.charAt(0).toUpperCase() + values.firstname?.slice(1)}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 form-group">
                                <div className="debitcard-details">
                                  <div className="label">Middle Name : </div>
                                  <div className="value">
                                    {(values.middlename &&
                                      values.middlename?.charAt(0).toUpperCase() + values.middlename?.slice(1)) ||
                                      "--"}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 form-group">
                                <div className="debitcard-details">
                                  <div className="label">Last Name : </div>
                                  <div className="value">
                                    {values.lastname && values.lastname?.charAt(0).toUpperCase() + values.lastname?.slice(1)}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4 col-sm-6 form-group">
                                <div className="debitcard-details">
                                  <div className="label">Card Number : </div>
                                  <div className="value">{values.cardNumber}</div>
                                </div>
                              </div>

                              <div className="col-md-4 col-sm-6 form-group">
                                <div className="debitcard-details">
                                  <div className="label">Expiration Date : </div>
                                  <div className="value">
                                    {values.month && values.year && <span>{`${values.month} / ${values.year}`}</span>}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-8  col-sm-12 form-group">
                                <div className="debitcard-details">
                                  <div className="label">Email Address : </div>
                                  <div className="value">{values.emailAddress}</div>
                                </div>
                              </div>

                              <div className="col-md-4 col-sm-6 form-group">
                                <div className="debitcard-details">
                                  <div className="label">Phone Number : </div>
                                  <div className="value">{values.phoneNumber}</div>
                                </div>
                              </div>

                              <div className="col-md-12 form-group">
                                <div className="debitcard-details">
                                  <div className="label">Billing Address : </div>
                                  <div className="value">
                                    {values.address1 &&
                                      `${
                                        values.address1.charAt(0).toUpperCase().replace(/,/g, "") +
                                        values.address1?.slice(1).replace(/,/g, "")
                                      },${values.address2.charAt(0).toUpperCase() + values.address2?.slice(1)} ${
                                        values?.address2?.length > 1 ? "," : ""
                                      }${values.city.charAt(0).toUpperCase() + values.city?.slice(1)},${
                                        values.state.charAt(0).toUpperCase() + values.state?.slice(1)
                                      },${values.country.charAt(0).toUpperCase() + values.country?.slice(1)},${
                                        values.zipCode
                                      }`.replace(/,/g, ", ")}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="agree-terms">
                          {this.props.payInFuture && (
                            <div className="row align-items-center">
                              <div className="col-sm-8 col-md-8">
                                Please send payment schedule reminders through Email/Text.
                              </div>
                              <div className="col-sm-4 col-md-4">
                                <FormControl component="fieldset" margin="normal">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="reminderEmail"
                                        defaultChecked
                                        value={values.reminderEmail}
                                        onChange={handleChange}
                                        color="primary"
                                      />
                                    }
                                    label={<span>Email</span>}
                                  />
                                </FormControl>
                                <FormControl component="fieldset" margin="normal">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="reminderSms"
                                        value={values.reminderSms}
                                        onChange={handleChange}
                                        color="primary"
                                      />
                                    }
                                    label={<span>Text</span>}
                                  />
                                </FormControl>
                              </div>
                            </div>
                          )}
                          <div className="row align-items-center mb-2">
                            <div className="col-md-8 col-sm-8">Please send payment receipt through Email/Text.</div>
                            <div className="col-md-4 col-sm-4">
                              <FormControl component="fieldset" margin="normal">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="paymentEmail"
                                      defaultChecked
                                      value={values.paymentEmail}
                                      onChange={handleChange}
                                      color="primary"
                                    />
                                  }
                                  label={<span>Email</span>}
                                />
                              </FormControl>
                              <FormControl component="fieldset" margin="normal">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="paymentSms"
                                      value={values.paymentSms}
                                      onChange={handleChange}
                                      color="primary"
                                    />
                                  }
                                  label={<span>Text</span>}
                                />
                              </FormControl>
                            </div>
                          </div>

                          <div className="check-FormLabel authorization-input">
                            <FormControl fullWidth component="fieldset" margin="normal">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="termsconditions"
                                    checked={values.termsconditions}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.termsconditions}
                                    color="primary"
                                  />
                                }
                                label={
                                  <span>
                                    <TermsAndConditions />
                                    <FormHelperText error name="termsconditions">
                                      {touched.termsconditions && errors.termsconditions ? (
                                        <div>{errors?.termsconditions}</div>
                                      ) : null}
                                    </FormHelperText>
                                  </span>
                                }
                              />
                            </FormControl>

                            <div className={"check-FormLabel authorization-input"}>
                              <FormControl fullWidth component="fieldset" margin="normal">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="userdata"
                                      value={values.userdata}
                                      checked={values.userdata}
                                      onChange={handleChange}
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <span>
                                      By checking this box, you acknowledge that this is your personal email address and you
                                      authorize us to send you emails, call your phone, or contact you via an automated
                                      dialer, leave voicemails, and/or send text messages, if necessary.
                                    </span>
                                  }
                                />
                              </FormControl>
                            </div>
                          </div>
                        </div>

                        <div className="proceed-cancel-buttons">
                          {this.props.payInFuture ? (
                            <button className="primary-button themeing-buttons mx-1" type="submit">
                              Schedule
                            </button>
                          ) : (
                            <button className="primary-button themeing-buttons mx-1" type="submit">
                              Pay
                            </button>
                          )}
                          <button
                            className="secondary-button mx-1"
                            type="button"
                            onClick={() => {
                              this.props.history.goBack();
                            }}>
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>

        {/* <ToastContainer /> */}
      </>
    );
  }
}
export default MakePayment;
