import React, { useState, useEffect } from "react";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { Formik, Form } from "formik";
import { Grid } from "@material-ui/core";
import { IconButton, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { InputLabel, MenuItem, FormControl, Select, Box } from "@material-ui/core";
import { getCommunicationByReportList } from "../../../../../action/ReportsAction";
import moment from "moment";
import { handleError, handleWarning } from "../../../../../components/Notifications/Toast";
import { ExportToExcel } from "../../../../../Common/exportToExcel";
import SpinnerPage from "../../../../../Common/PP_loader";
import ClearIcon from "@material-ui/icons/Clear";
import CommunicationTable from "./CommunicationTable";
import NumberFormat from "react-number-format";
const Communication = () => {
  let initialValues = {
    seedAccountReferenceNumber: "",
    firstName: "",
    lastName: "",
    startDate: null,
    endDate: null,
    letterCode: "",
  };
  const dispatch = useDispatch();
  const [communicationsByReportData, setCommunicationsByReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleCommunicationsByReportDownload = (values) => {
    setIsLoading(true);
    dispatch(
      getCommunicationByReportList(
        {
          firstName: values.firstName,
          lastName: values.lastName,
          seedAccountReferenceNumber: values.seedAccountReferenceNumber,
          letterCode: values.letterCode ? Number(values.letterCode) : 0,
          pageNo: 0,
          pageSize: -1,
          startDate: values?.startDate ? moment(values?.startDate).format("YYYY-MM-DD") : "",
          endDate: values?.endDate ? moment(values?.endDate).format("YYYY-MM-DD") : "",
        },
        (res) => {
          setIsLoading(false);
          if (res.data?.responseInfo?.status === "SUCCESSFUL") {
            ExcelData(res?.data?.communications);
            res.data.responseInfo?.responseMessage?.length > 0 && handleWarning(res.data.responseInfo?.responseMessage[0]);
          } else if (res.data?.responseInfo?.status === "FAILED") {
            handleError(res.data.responseInfo?.responseMessage[0]);
          } else {
            handleError(res?.message);
          }
        },
      ),
    );
  };

  const ExcelData = (data) => {
    const DataToExp = data.map((obj) => {
      const {
        seedAccountNumber,
        firstName,
        lastName,
        letterCode,
        letterData,
        letterDescription,
        lastModifiedDate,
        isDuffEmail,
        isSecureEmail,
        isSms,
      } = obj;

      const newObject = {
        "Seed Account Number": seedAccountNumber ? seedAccountNumber : "-",
        "First Name": firstName ? firstName[0]?.toUpperCase() + firstName?.slice(1) : "-",
        "Last Name": lastName ? lastName[0]?.toUpperCase() + lastName?.slice(1) : "-",
        "Letter Code": letterCode ? letterCode : "-",
        "Last Sent Date": lastModifiedDate ? lastModifiedDate : "-",
        "Letter Description": letterDescription ? letterDescription : "-",
        "Is Duff Email": isDuffEmail === false ? "False" : "True",
        "Is Secure Email": isSecureEmail === false ? "False" : "True",
        "Is SMS": isSms === false ? "False" : "True",
        "Letter Data": letterData ? letterData : "-",
      };
      return newObject;
    });

    ExportToExcel(DataToExp, "Communications By Report");
  };

  const handleSubmit = (values) => {
    let showAlert = true;
    for (let key in values) {
      if (showAlert && Boolean(values[key])) {
        showAlert = false;
      }
    }
    if (showAlert) {
      handleWarning("Please fill at least one field to search");
    } else {
      setSearchData(values);
      setIsSubmit(true);
    }
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        //   validationSchema={schema}
      >
        {(props) => {
          const { values, touched, error, handleChange, errors, setFieldValue } = props;
          return (
            <Form id="transaction-search" className="input-field" noValidate="noValidate">
              <div>
                <div className="form-row">
                  <div className="col-xl-3 col-lg-4 col-sm-6 col-md-4 form-group">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <label htmlFor="account" className="form-label w-100">
                        Seed Account Number
                      </label>

                      <Grid container className="date-picker">
                        <TextField
                          id="seedAccountReferenceNumber"
                          name="seedAccountReferenceNumber"
                          type="text"
                          placeholder="Enter Seed Account Number"
                          onChange={handleChange}
                          value={values["seedAccountReferenceNumber"]}
                          aria-label="text-field"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            inputComponent: NumberFormat,
                          }}></TextField>
                      </Grid>
                    </FormControl>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-sm-6 col-md-4 form-group">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid container className="custom-date-picker date-picker">
                          <label htmlFor="patientDOB" className="form-label w-100">
                            Start Date
                          </label>
                          <DatePicker
                            name="startDate"
                            id="startDate"
                            autoOk
                            value={values.startDate}
                            onChange={(value) => {
                              setFieldValue("startDate", value);
                            }}
                            InputProps={{
                              startAdornment: (
                                <IconButton
                                  position="end"
                                  onClick={() => setFieldValue("startDate", null)}
                                  style={{ order: 1, padding: 0 }}>
                                  <ClearIcon fontSize="small" className="p-0" />
                                </IconButton>
                              ),
                            }}
                            InputAdornmentProps={{
                              position: "end",
                              style: { order: 2 },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                helperText={null}
                                margin="dense"
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "MM/DD/YYYY",
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </LocalizationProvider>
                    </FormControl>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-sm-6 col-md-4  form-group">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid container className="custom-date-picker date-picker">
                          <label htmlFor="endDate" className="form-label w-100">
                            End Date
                          </label>
                          <DatePicker
                            name="endDate"
                            autoOk
                            value={values.endDate}
                            minDate={values.startDate - 1}
                            onChange={(val) => setFieldValue("endDate", val)}
                            InputProps={{
                              startAdornment: (
                                <IconButton
                                  position="end"
                                  onClick={() => setFieldValue("endDate", null)}
                                  style={{ order: 1, padding: 0 }}>
                                  <ClearIcon fontSize="small" className="p-0" />
                                </IconButton>
                              ),
                            }}
                            InputAdornmentProps={{
                              position: "end",
                              style: { order: 2 },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                helperText={null}
                                margin="dense"
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "MM/DD/YYYY",
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </LocalizationProvider>
                    </FormControl>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-sm-6 col-md-4 form-group">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <label htmlFor="letterCode" className="form-label w-100">
                        Letter Code
                      </label>

                      <Grid container className="date-picker">
                        <TextField
                          id="letterCode"
                          name="letterCode"
                          placeholder="Enter Letter Code"
                          onChange={handleChange}
                          value={values["letterCode"]}
                          aria-label="text-field"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}></TextField>
                      </Grid>
                    </FormControl>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-sm-6 col-md-4 form-group">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <label htmlFor="firstName" className="form-label w-100">
                        First Name
                      </label>

                      <Grid container className="date-picker">
                        <TextField
                          id="firstName"
                          name="firstName"
                          placeholder="Enter First Name"
                          onChange={handleChange}
                          value={values["firstName"]}
                          aria-label="text-field"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}></TextField>
                      </Grid>
                    </FormControl>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-sm-6 col-md-4 form-group">
                    <FormControl variant="outlined" className="w-100" fullWidth>
                      <label htmlFor="lastName" className="form-label w-100">
                        Last Name
                      </label>

                      <Grid container className="date-picker">
                        <TextField
                          id="lastName"
                          name="lastName"
                          placeholder="Enter Last Name"
                          onChange={handleChange}
                          value={values["lastName"]}
                          aria-label="text-field"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}></TextField>
                      </Grid>
                    </FormControl>
                  </div>
                </div>
                <div className="payment-history-btns">
                  <button aria-label="Generate Report" type="submit" className="primary-button themeing-buttons">
                    Search
                  </button>
                  <button
                    aria-label="Reset"
                    type="reset"
                    className="secondary-button mx-2"
                    onClick={() => {
                      setCommunicationsByReportData([]);
                      setSearchData(null);
                    }}>
                    Reset
                  </button>
                  {communicationsByReportData.length > 0 && (
                    <button
                      type="button"
                      aria-label="Generate Report"
                      className="primary-button themeing-secondary-buttons"
                      onClick={() => {
                        handleCommunicationsByReportDownload(values);
                      }}>
                      Download
                    </button>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      <Grid item>
        <div className="payplan-table">
          {searchData ? (
            <CommunicationTable
              setCommunicationsByReportData={setCommunicationsByReportData}
              searchData={searchData}
              isSubmit={isSubmit}
              setIsSubmit={setIsSubmit}
            />
          ) : null}
        </div>
      </Grid>
      {isLoading && <SpinnerPage />}
    </div>
  );
};
export default Communication;
