import React from "react";

import PAScreens from "../../../../routes/Pages";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {
  Checkbox,
  MenuItem,
  FormControl,
  FormControlLabel,
  Select,
  FormLabel,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import { getIn } from "formik";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import ClearIcon from "@material-ui/icons/Clear";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { IconButton } from "@material-ui/core";
import { ErrorMessage, Form, Formik, Field } from "formik";
import * as Yup from "yup";
import ImageTooltip from "../../../../components/ImageTooltip";
import echeckPaymentRoutingNumber from "../../../../assets/images/echeckPaymentRoutingNumber.jpg";
//import { cityData, countryData, allStatesData, stateList } from "../../../../util/StatesData";
import { countryList, stateList } from "../../../../containers/RegionList";
import { Grid } from "@material-ui/core";
// import { countryList } from "../../containers/RegionList";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import Signature from "../../../../components/Signature";
// import Signature from "../../containers/Admin_Report/Signature";
import { echequePaymentService } from "../../../../services/echequePaymentService";
import {
  error,
  success,
  warning,
  warningNotification,
  errorNotification,
  successNotification,
} from "../../../../components/PP_Notification/PP_Notification";
import { handleSuccess, ToastContainer, handleError, handleWarning } from "../../../../components/Notifications/Toast";
import { requestInfo_PP } from "../../../../Common/CommonFn";
import Stepper from "../Stepper/HorizantalStepper";
import { RegionManagementService } from "../../../../admin_services/regionManagementService";
import { OutstandingPayerService } from "../../../../services/payerOutstandingService";
import { loginService } from "../../../../admin_services/loginService";
import { whiteLabelService } from "../../../../admin_services/whiteLabelService";
import { paymentCreditCardService } from "../../../../services/paymentCreditCardService";
import { settlementService } from "../../../../services/settlementServices";
import moment from "moment";
import SpinnerPage from "../../../../Common/PP_loader";
import TermsAndConditions from "../../../../components/TermsAndConditions/TermsAndConditions";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import { capitalize } from "../../../../util/Captilize";
// routingnumber,accountType,accountnumber,reenteraccountNumber,emailAddress,phoneNumber,address1,country,state,city,zipCode

class EcheckCardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAnumberFlag: false,
      showForm: true,
      countryList: [],
      cityList: [],
      country: "united states of america",
      state: "",
      city: "",
      carddata: "",
      payeraddrdata: "",
      isMaskOne: true,
      instrumentid: "",
      ES_AppId: "",
      ES_PartyId: "",
      ES_Amount: "",
      ES_SubId: "",
      ES_Method: "",
      ES_OrderId: "",
      ES_url: "",
      ES_PlanId: "",
      ES_PriceSubId: "",
      ES_PurchaseParameters: "",
      ES_JsonData: "",
      instrumentId: "",
      futureDate: null,
      futureDate1: null,
      futureMonthDays: null,
      lastDayOfMonth: moment().endOf("month").format("MM/DD/YYYY"),
      secondLastDayOfMonth: moment(this.state?.lastDayOfMonth).subtract(1, "days"),
      thirdLastDayOfMonth: moment(this.state?.lastDayOfMonth).subtract(2, "days"),
      page: this.props?.location?.state?.page,
      achAccountId: "",
      linkedPaymentMethodEntityId: "",
      billingAddressId: "",
      italicSign: "",
      signature: "",
      maskedAccountNumber: "",
      maskedRoutingNumber: "",
      billingAddress: {
        billingAddressId: 0,
        city: "",
        country: "",
        email: "",
        firstName: "",
        lastName: "",
        middleName: "",
        phoneNo: "",
        postalCode: "",
        state: "",
        street: "",
        street2: "",
      },
      echeckId: "",
    };
    this.updateUserSignature = this.updateUserSignature.bind(this);
    this.toggleShowAnumber = this.toggleShowAnumber.bind(this);
    this.handleRoutingNumber = this.handleRoutingNumber.bind(this);
    this.handleReAccountNumber = this.handleReAccountNumber.bind(this);
  }
  _dateValidationHandeling() {
    var currentDate = moment().toDate();
    var futureMonth = moment(currentDate).add(1, "M");
    var futureMonthEnd = moment(futureMonth).endOf("month");
    var formattedFutureDate = moment(futureMonthEnd._d).format("MM/DD/YYYY");
    var daysInMonth = moment(formattedFutureDate).daysInMonth();
    this.setState({
      futureDate1: formattedFutureDate,
      futureMonthDays: daysInMonth,
    });
  }

  componentDidMount() {
    this._dateValidationHandeling();
    const instrumentIdState =
      this.props && this.props.selectedCard && this.props.selectedCard.length > 0
        ? this.props.selectedCard[0].paymentInstrument?.paymentInstrumentId
        : 0;
    if (instrumentIdState) {
      this.setState({
        instrumentId: instrumentIdState,
        achAccountId: this.props.selectedCard[0].achAccountId,
        linkedPaymentMethodEntityId: this.props.selectedCard[0].paymentInstrument.linkedPaymentMethodEntityId,
      });
    }

    var queryString = "";
    queryString = window.location.search.split("&");

    //GoogleAnalystics.GoogleAnalysticsLog("pageview");

    window.scrollTo(0, 0);
    document.title = "MED-1 Payment";

    // Check if URL has Query parameters
    if (queryString[0] !== "" && queryString[0] !== null) {
      var urlAppId = queryString ? queryString[0].replace("?id=", "") : "";
      var urlPartyId = queryString[1] ? queryString[1].replace("p=", "") : "";
      var urlAmount = queryString[2] ? queryString[2].replace("a=", "") : "";
      var urlSubscriberId = queryString[3] ? queryString[3].replace("s=", "") : "";
      var urlMethod = queryString[4] ? queryString[4].replace("b=", "") : "";
      var urlOrderId = queryString[5] ? queryString[5].replace("o=", "") : "";
      var urlProxy = queryString[6] ? queryString[6].replace("u=", "") : "";
      var urlPlanId = queryString[7] ? queryString[7].replace("pl=", "") : "";
      var urlPriceSubId = queryString[8] ? queryString[8].replace("ps=", "") : "";
      var urlPurchaseParam = queryString[9] ? queryString[9].replace("pp=", "") : "";
      var urlJsonParam = queryString[10] ? queryString[10].replace("js=", "") : "";

      const ES_AppId = atob(urlAppId);
      const ES_PartyId = atob(urlPartyId);
      const ES_Amount = atob(urlAmount);
      const ES_SubId = atob(urlSubscriberId);
      const ES_Method = atob(urlMethod);
      const ES_OrderId = atob(urlOrderId);
      const ES_url = atob(urlProxy);

      this.setState({
        ES_AppId: atob(urlAppId),
        ES_PartyId: atob(urlPartyId),
        ES_Amount: atob(urlAmount),
        ES_SubId: atob(urlSubscriberId),
        ES_Method: atob(urlSubscriberId),
        ES_OrderId: atob(urlOrderId),
        ES_PlanId: atob(urlPlanId),
        ES_PriceSubId: atob(urlPriceSubId),
        ES_PurchaseParameters: atob(urlPurchaseParam),
        ES_JsonData: atob(urlJsonParam),
      });
      sessionStorage.setItem("SIC_URL", ES_url);
      sessionStorage.setItem("buid", 6);
      // sessionStorage.setItem("TotalAmount", this.state.ES_Amount)
      // sessionStorage.setItem("amounttopay", this.state.ES_Amount)
      loginService.TokenUser((res) => {
        if (res.status === "success") {
          sessionStorage.setItem("jwt-token", res.responseObject);
          sessionStorage.setItem("jwt-tokencore", res.responseObject);
          const passValues = {
            countryId: "",
            countryName: "",
            limit: -1,
          };
          const userId = "1212";
        }
      }, console.log("Token Error"));
    } else {
      this.setState({
        payeraddrdata: JSON.parse(sessionStorage.getItem("verifypayer")),
      });
      var payeruserdata = JSON.parse(sessionStorage.getItem("verifypayer"));

      if (this.props?.selectedCard?.length > 0) {
        const selectedState = stateList.filter(
          (item) =>
            item?.stateName?.toLocaleLowerCase() === this.props.selectedCard[0]?.billingAddress?.state?.toLocaleLowerCase(),
        );
        let PassValues = {
          stateId: selectedState[0]?.stateId,
        };
        const UserId = "1234";
        this.setState({ showloader: true });
        RegionManagementService.getCity(PassValues, UserId, (res) => {
          this.setState({ cityList: res.data.responseListObject });
          this.setState({ showloader: false });
        });
      } else {
        let PassValues = {
          stateId: payeruserdata?.addressVo?.stateId,
        };
        const UserId = "1234";
        this.setState({ showloader: true });
        RegionManagementService.getCity(PassValues, UserId, (res) => {
          this.setState({ cityList: res.data.responseListObject });
          this.setState({ showloader: false });
        });
      }

      if (this.props && this.props.selectedCard && this.props.selectedCard.length > 0) {
        const card = this.props.selectedCard[0];
        const billAddress = this.props.selectedCard[0].billingAddress;

        let getAccountNumber = card?.accountNumber;

        let getRoutingNumber = card?.bankAchRoutingCode;

        let getLastFourDigits = getAccountNumber?.slice(-4);

        let getLastFourDigitsRouting = getRoutingNumber?.slice(-4);

        let maskedAccountNumber = getLastFourDigits.padStart(getAccountNumber.length, "*");

        let maskedRoutingNumber = getLastFourDigitsRouting.padStart(getRoutingNumber.length, "*");

        this.setState({
          accountNumber: card?.accountNumber || "",
          reenteraccountNumber: card?.accountNumber || "",
          routingnumber: card?.bankAchRoutingCode || "",
          maskedAccountNumber: maskedAccountNumber || "",
          maskedRoutingNumber: maskedRoutingNumber || "",
          accountType: card?.accountType === 76001 ? "Checking" : "Savings" || "",
          termsconditions: true,
          userdata: true,
          billingAddress: {
            billingAddressId: billAddress?.billingAddressId,
            email: billAddress?.email,
            phoneNo: billAddress?.phoneNo && billAddress?.phoneNo.replace(/[^\d]/g, ""),
            street: billAddress?.street,
            street2: billAddress?.street2,
            country: billAddress?.country || "united states of america",
            countryId: billAddress?.country,
            state: billAddress?.state,
            stateId: billAddress?.state,
            city: billAddress?.city,
            cityId: billAddress?.city,
            postalCode:
              billAddress?.postalCode.length > 5 && !billAddress?.postalCode.includes("-")
                ? billAddress?.postalCode.substring(0, 5).concat("-") +
                  billAddress?.postalCode.substring(5, billAddress?.postalCode.length)
                : billAddress?.postalCode,
            firstName: billAddress?.firstName,
            middleName: billAddress?.middleName || "",
            lastName: billAddress?.lastName,
          },
        });
      } else if (payeruserdata) {
        this.setState({
          stateList: this.state.stateList ? this.state.stateList : [],
          cityList: this.state.cityList ? this.state.cityList : [],
          termsconditions: true,
          userdata: true,
          billingAddress: {
            billingAddressId: 0,
            email: payeruserdata.contactEmail,
            phoneNo: payeruserdata?.contactNo?.replace(/[^\d]/g, ""),
            street: payeruserdata?.addressVo?.street,
            street2: payeruserdata?.addressVo?.street2,
            country: "united states of america",
            state: payeruserdata?.addressVo?.state,
            city: payeruserdata?.addressVo?.city,
            postalCode:
              payeruserdata?.addressVo?.postalCode?.length > 5 && !payeruserdata?.addressVo?.postalCode?.includes("-")
                ? payeruserdata?.addressVo?.postalCode?.substring(0, 5).concat("-") +
                  payeruserdata?.addressVo?.postalCode?.substring(5, payeruserdata?.addressVo?.postalCode?.length)
                : payeruserdata?.addressVo?.postalCode,
            firstName: payeruserdata.firstName,
            lastName: payeruserdata.lastName,
            middleName: payeruserdata.middleName,
          },
        });
      }
    }

    if (queryString[0] == "" && queryString[0] == null) {
      const passValues = {
        countryId: "",
        countryName: "",
        limit: -1,
      };
      const userId = "1212";
      RegionManagementService.getCountry(passValues, userId, (res) => {
        if (res["data"].status === "success") {
          sessionStorage.setItem("countryList", JSON.stringify(res.data.responseListObject));
          var PassValues = {
            countryId: 1,
            limit: -1,
          };
          const UserId = "1234";
          RegionManagementService.getState(PassValues, UserId, (res) => {
            sessionStorage.setItem("stateList", JSON.stringify(res.data.responseListObject));
            var payeraddrdata = JSON.parse(sessionStorage.getItem("billingpayerdata"));

            if (payeraddrdata[0].payer.addressVo.stateId) {
              var PassValues = {
                stateId: payeraddrdata[0].payer.addressVo.stateId,
              };
              const UserId = "1234";
              RegionManagementService.getCity(PassValues, UserId, (res) => {
                sessionStorage.setItem("cityList", JSON.stringify(res.data.responseListObject));
              });
            }
          });
        }
      });

      // Get Echeck details
      // Get Credit Card Details

      var userinfo = JSON.parse(sessionStorage.getItem("verifypayer"));

      const passValuescc = {
        requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        paymentInfoSearch: {
          paymentMethodId: 75003,
          partyId: userinfo ? userinfo.partyId : "",
        },
      };

      OutstandingPayerService.Getcardecheckdata(passValuescc, userId, (res) => {
        if (res["data"].responseInfo.status === "SUCCESSFUL") {
          sessionStorage.setItem("cardecheckdata", JSON.stringify(res["data"].paymentInfos[0].cardAccount));

          this.setState({
            echeckdata: res["data"].paymentInfos[0].achAccount,
            // payeraddrdata: JSON.parse(sessionStorage.getItem("billingpayerdata"))
          });
        }
      });
    }
  }
  updateUserSignature(nextState) {
    this.setState((prevState) => ({
      ...prevState,
      italicSign: nextState?.italicSign,
      signature: nextState?.signature,
      agreeToEmailUsage: prevState?.agreeToEmailUsage,
      agreeToterms: prevState?.agreeToterms,
      authorizeElectronicDebit: prevState?.authorizeElectronicDebit,
    }));
  }
  handleCardNumber = () => {
    this.setState({ showAnumber: !this.state.showAnumber });
  };

  handleRoutingNumber = () => {
    this.setState({ showRnumber: !this.state.showRnumber });
  };

  handleAccountNumber = () => {
    this.setState({ accountNumber: !this.state.accountNumber });
  };

  handleReAccountNumber = () => {
    this.setState({ showreAccountNumber: !this.state.showreAccountNumber });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  toggleShowAnumber = () => {
    this.setState({ showAnumber: !this.state.showAnumber });
  };
  schema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters,dash(-),dot(.),tilled and apostrophes(') are allowed")
      .required("First Name required")
      .nullable()
      .max(20, "Max 20 characters allowed"),
    middleName: Yup.string()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters,dash(-),dot(.),tilled and apostrophes(') are allowed")

      .nullable()
      .max(20, "Max 20 characters allowed"),
    lastName: Yup.string()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters,dash(-),dot(.),tilled and apostrophes(') are allowed")
      .required("Last Name required")
      .nullable()
      .max(20, "Max 20 characters allowed"),
    routingnumber: Yup.string().min(9, "Invalid Routing Number").required("Routing Number is required"),
    accountType: Yup.string().required("Account Type is required").nullable(),
    accountnumber: Yup.string()
      .min(6, "Invalid Account Number")
      .max(16, "More then 16 digits are not allowed")
      .required("Account Number is required"),
    reenteraccountNumber: Yup.string()
      .required("Re-enter Account Number is required")
      .oneOf([Yup.ref("accountnumber"), null], "Account Number must match"),
    emailAddress:
      sessionStorage.getItem("currentUserRole") !== "Admin" &&
      Yup.string().email("Email Address must be valid").required("Email Address required").nullable(),
    phoneNumber: Yup.string()
      .min(10, "Please enter 10 digits Phone Number")
      .max(10)
      .required("Phone Number required")
      .nullable(),
    address1: Yup.string().required("Billing Address is required").max(255, "Max 255 characters allowed").nullable(),
    address2: Yup.string().nullable().max(255, "Max 255 characters allowed"),
    country: Yup.string().required("Country required").nullable(),
    state: Yup.string().required("State is required").nullable(),
    city: Yup.string()
      .nullable()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters, dash(-), dot(.), tilled and apostrophes(') are allowed")
      .required("City is required"),
    zipCode: Yup.string()
      .nullable()
      .trim()
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/g, "Please enter a valid ZIP Code")
      .required("ZIP Code is required")
      .test("Invalid ZIP Code", (value) => !/^([0-9])\1{4,}$/.test(value)),
    italicSign: Yup.string(),
    signature: Yup.string(),
    italicorsignature: Yup.bool().when(["italicSign", "signature"], {
      is: (italicSign, signature) => !(italicSign || signature),
      then: this.props.location.state.paymentMethod === "echeck" ? Yup.bool().required("Signature is required") : Yup.bool(),
      otherwise: Yup.bool(),
    }),
    termsconditions:
      sessionStorage.getItem("currentUserRole") === "Customer" || sessionStorage.getItem("currentUserRole") === "quickpay"
        ? Yup.boolean().oneOf([true], "You must agree to the Online Payment Terms and Conditions")
        : null,
    agreeToEmailUsage:
      sessionStorage.getItem("currentUserRole") === "Customer" || sessionStorage.getItem("currentUserRole") === "quickpay"
        ? Yup.boolean().oneOf([true], "You must agree to the Online Payment Terms and Conditions")
        : null,
    authorizeElectronicDebit:
      sessionStorage.getItem("currentUserRole") === "Customer" || sessionStorage.getItem("currentUserRole") === "quickpay"
        ? Yup.boolean().oneOf([true], "You must agree to the Online Payment Terms and Conditions")
        : null,
  });
  futureSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters,dash(-),dot(.),tilled and apostrophes(') are allowed")
      .required("First Name required")
      .nullable()
      .max(20, "Max 20 characters allowed"),
    middleName: Yup.string()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters,dash(-),dot(.),tilled and apostrophes(') are allowed")
      .nullable()
      .max(20, "Max 20 characters allowed"),
    lastName: Yup.string()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters,dash(-),dot(.),tilled and apostrophes(') are allowed")
      .required("Last Name required")
      .nullable()
      .max(20, "Max 20 characters allowed"),
    routingnumber: Yup.string().min(9, "Invalid Routing Number").required("Routing Number is required"),
    futureDate:
      moment().daysInMonth() === 31
        ? Yup.date()
            .test("futureDate", "Schedule payment date must be in between 31 days", (val) => {
              if (val <= moment().add(30, "days").toDate()) {
                return true;
              } else {
                return false;
              }
            })
            .required("Future Payment Date is Required")
            .typeError("Please enter a valid date")
            .min(new Date(), "Date must be in the future")
            .nullable()
        : moment().daysInMonth() === 30
        ? Yup.date()
            .test("futureDate", "Schedule payment date must be in between 30 days", (val) => {
              if (val <= moment().add(29, "days").toDate()) {
                return true;
              } else {
                return false;
              }
            })
            .required("Future Payment Date is Required")
            .typeError("Please enter a valid date")
            .min(new Date(), "Date must be in the future")
            .nullable()
        : moment().daysInMonth() === 29
        ? Yup.date()
            .test("futureDate", "Schedule payment date must be in between 29 days", (val) => {
              if (val <= moment().add(28, "days").toDate()) {
                return true;
              } else {
                return false;
              }
            })
            .required("Future Payment Date is Required")
            .typeError("Please enter a valid date")
            .min(new Date(), "Date must be in the future")
            .nullable()
        : Yup.date()
            .test("futureDate", "Schedule payment date must be in between 28 days", (val) => {
              if (val <= moment().add(27, "days").toDate()) {
                return true;
              } else {
                return false;
              }
            })
            .required("Future Payment Date is Required")
            .typeError("Please enter a valid date")
            .min(new Date(), "Date must be in the future")
            .nullable(),
    accountType: Yup.string().required("Account Type is required").nullable(),
    accountnumber: Yup.string()
      .min(6, "Invalid Account Number")
      .max(16, "More then 16 digits are not allowed")
      .required("Account Number is required"),
    reenteraccountNumber: Yup.string()
      .required("Re-enter Account Number is required")
      .oneOf([Yup.ref("accountnumber"), null], "Account Number must match"),
    emailAddress: Yup.string()
      .when("accountnumber", (accountnumber, field) => {
        return sessionStorage.getItem("currentUserRole") && sessionStorage.getItem("currentUserRole") !== "Admin"
          ? field.required("Email Address Required")
          : field.email("Email Address must be valid");
      })
      .nullable(),
    phoneNumber: Yup.string()
      .min(10, "Please enter 10 digits Phone Number")
      .max(10)
      .required("Phone Number required")
      .nullable(),
    address1: Yup.string().required("Billing Address is required").max(255, "Max 255 characters allowed").nullable(),
    address2: Yup.string().nullable().max(255, "Max 255 characters allowed"),
    country: Yup.string().required("Country required").nullable(),
    state: Yup.string().required("State is required").nullable(),
    city: Yup.string()
      .nullable()
      .trim()
      .matches(/^[a-z-A-Z-'. ~]+$/g, "Only letters, dash(-), dot(.), tilled and apostrophes(') are allowed")
      .required("City is required"),
    zipCode: Yup.string()
      .nullable()
      .trim()
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/g, "Please enter a valid ZIP Code")
      .required("ZIP Code is required")
      .test("Invalid ZIP Code", (value) => !/^([0-9])\1{4,}$/.test(value)),
    italicSign: Yup.string(),
    signature: Yup.string(),
    italicorsignature: Yup.bool().when(["italicSign", "signature"], {
      is: (italicSign, signature) => !(italicSign || signature),
      then: this.props.location.state.paymentMethod === "echeck" ? Yup.bool().required("Signature is required") : Yup.bool(),
      otherwise: Yup.bool(),
    }),
    termsconditions:
      sessionStorage.getItem("currentUserRole") === "Customer" || sessionStorage.getItem("currentUserRole") === "quickpay"
        ? Yup.boolean().oneOf([true], "You must agree to the Online Payment Terms and Conditions")
        : null,
    agreeToEmailUsage:
      sessionStorage.getItem("currentUserRole") === "Customer" || sessionStorage.getItem("currentUserRole") === "quickpay"
        ? Yup.boolean().oneOf([true], "You must agree to the Online Payment Terms and Conditions")
        : null,
    authorizeElectronicDebit:
      sessionStorage.getItem("currentUserRole") === "Customer" || sessionStorage.getItem("currentUserRole") === "quickpay"
        ? Yup.boolean().oneOf([true], "You must agree to the Online Payment Terms and Conditions")
        : null,
  });
  _handleNewECheck = (values) => {
    this.setState({
      ...this.state,
      showloader: true,
    });
    let modifieddata = new Date();
    let dateString =
      modifieddata.getUTCFullYear() +
      "/" +
      (modifieddata.getUTCMonth() + 1) +
      "/" +
      modifieddata.getUTCDate() +
      " " +
      modifieddata.getUTCHours() +
      ":" +
      modifieddata.getUTCMinutes() +
      ":" +
      modifieddata.getUTCSeconds();

    const partyids = JSON.parse(sessionStorage.getItem("verifypayer"));
    let partyid = partyids.partyId;
    const firstName = values.firstName ? values.firstName + " " : "";
    const middleName = values.middleName ? values.middleName + " " : "";
    const lastName = values.lastName ? values.lastName : "";
    const accountName = firstName + middleName + lastName;

    const PassValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),

      paymentInfos: [
        {
          achAccount: [
            {
              achAccountId: this.state.achAccountId || 0,
              accountNumber: values.accountnumber,
              payerPartyId: partyid,
              accountName: accountName,
              bankAchRoutingCode: values.routingnumber,
              accountType: values.accountType === "Savings" ? 76002 : 76001,
              paymentMethodId: 75003,
              signature: values.signature,
              italicSign: values.italicSign,
              booleanItalicSign: values.italicSign ? true : false,
              isActive: 1,
              paymentInstrument: {
                paymentInstrumentId: this.state.instrumentId || 0,
                linkedPaymentMethodEntityId: this.state.linkedPaymentMethodEntityId || 0,
                payerPartyId: partyid,
                paymentMethodId: 75003,
                isDefault: null,
                isExpired: null,
                isBlocked: null,
                isBlacklisted: null,
                lastUsageDatetime: dateString,
              },
              billingAddress: {
                billingAddressId: this.state?.billingAddress?.billingAddressId || 0,
                firstName: values.firstName,
                middleName: values.middleName,
                lastName: values.lastName,
                email: values.emailAddress,
                phoneNo: values.phoneNumber,
                street: values.address1,
                street2: "",
                city: values.city,
                state: values.state,
                country: values.country,
                postalCode: values.zipCode.replace("-", ""),
              },
            },
          ],
          cardAccount: null,
          bankAccount: null,
          walletAccount: null,
          virtualAccount: null,
        },
      ],
    };
    if (this.props.location.state.paymentMethod === "echeck") {
      echequePaymentService.saveEchequePayment(PassValues, 1212, (res) => {
        if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
          handleSuccess("eCheck Successfully Added");
          this.setState({
            instrumentId: res?.data?.paymentInfos[0]?.achAccount[0]?.paymentInstrument?.paymentInstrumentId,
          });
          this._handlePayinFuture(values);
        } else {
          handleError(res?.data?.responseInfo?.responseMessage[0]);
        }
      });
    } else {
      this._handlePayinFuture(values);
    }
  };
  _handlePayinFuture = (values) => {
    let newPassValues = this.props.passValues;
    const partyids = JSON.parse(sessionStorage.getItem("verifypayer"));
    let partyId = partyids?.partyId;
    newPassValues.paymentOrders[0].paymentInstrumentId = this.state.instrumentId;
    newPassValues.paymentOrders[0].paymentDate = moment(values.futureDate).format("MM/DD/YYYY");
    newPassValues.paymentOrders[0].payerPartyId = partyId;
    newPassValues.paymentOrders[0].reminderEmail = values?.reminderEmail && values?.reminderEmail === true ? 1 : 0;
    newPassValues.paymentOrders[0].reminderSms = values?.reminderSms && values?.reminderSms === true ? 1 : 0;
    newPassValues.paymentOrders[0].paymentEmail = values?.paymentEmail && values?.paymentEmail === true ? 1 : 0;
    newPassValues.paymentOrders[0].paymentSms = values?.paymentSms && values?.paymentSms === true ? 1 : 0;
    OutstandingPayerService.PP_Create_Payment_Order(newPassValues, 1234, (res) => {
      // alert("API CALLED2")
      if (res["data"]?.responseInfo.status === "SUCCESSFUL") {
        this.setState({
          ...this.state,
          showloader: false,
        });
        handleSuccess("Order Generated Successfully");
        this.props.history.push({
          pathname: this.state?.page === "quickPay" ? "/paymentScheduledSuccessfullyQP" : "/payment-scheduled",
          search: `${res?.data?.paymentOrders[0]?.paymentDate}`,
          state: {
            futureDate: moment(values.futureDate).format("MM/DD/YYYY"),
          },
        });
      } else if (res["data"]?.responseInfo.status === "FAILED") {
        let redirectURLTo = sessionStorage.getItem("currentUserRole") === "Customer" ? "/signin" : "/admin";

        handleError("Sorry Internal Error Occurred. Please login and try again.");
        this.props.history.push(redirectURLTo);
        this.setState({
          ...this.state,
          showloader: false,
        });
      } else {
        handleWarning("Something went wrong!!");
        this.setState({
          ...this.state,
          showloader: false,
        });
      }
    });
  };

  _handlePay = (values) => {
    this._handleNewECheck(values);
  };

  _handleSubmit = (values, { actions }) => {
    const jtiToken =
      sessionStorage.getItem("jti-token") === null
        ? sessionStorage.getItem("jwt-token")
        : sessionStorage.getItem("jti-token");
    const Token = sessionStorage.getItem("jwt-token") === null ? "" : sessionStorage.getItem("jwt-token");

    const partyId = sessionStorage.getItem("order_FP");

    var userinfo = JSON.parse(sessionStorage.getItem("verifypayer"));

    var partyid = userinfo ? userinfo.partyId : this.state.ES_PartyId;
    // alert(partyId[0].partyId)

    this.setState({
      showloader: true,
    });

    const partyids = JSON.parse(sessionStorage.getItem("verifypayer"));
    var partyid = partyids ? partyids.partyId : this.state.ES_PartyId;
    var contactpartyid = partyids ? partyids.partyTypeId : this.state.ES_PartyId;

    // Save Consent data

    const UserId = "1212";
    // var Token = "";
    // var jtiToken = ""

    const checkconsentValues = {
      requestInfo: requestInfo_PP(Token, jtiToken),
      partyId: partyid,
    };
    whiteLabelService.checkUserConsent(
      checkconsentValues,
      UserId,
      (res) => {
        // alert(JSON.stringify(res["data"]))
        if (res["data"].status === "Success") {
          // Update Consent
          var consentdata = res["data"].responseListObject;
          var updateconsent = "";
          if (consentdata !== null) {
            var consentjson = consentdata[0].consentSourceJson;
            var temp = [];
            temp = [
              {
                tag: "email",
                value: values.userdata,
              },
              {
                tag: "T&C",
                value: values.termsconditions,
              },
            ];
            consentjson.map((consentjson, index) => {
              if (consentjson.tag !== "email" && consentjson.tag !== "T&C") {
                temp.push(consentjson);
              }
            });

            updateconsent = {
              requestInfo: requestInfo_PP(Token, jtiToken),
              buId: consentdata[0].buId,
              subBuId: consentdata[0].subBuId,
              partyId: consentdata[0].partyId,
              partyType: 3,
              partyConsentId: consentdata[0].partyConsentId,
              consentObjectName: "CreditCardPayment",
              consentActionValue: "1",
              consentRecordedDatetime: new Date(),
              consentSourceJson: temp,
            };
          } else {
            updateconsent = {
              requestInfo: requestInfo_PP(Token, jtiToken),
              buId: sessionStorage.getItem("buid") !== null ? sessionStorage.getItem("buid") : "2",
              subBuId: "1",
              partyId: partyid,
              partyType: 3,
              consentObjectName: "CreditCardPayment",
              consentActionValue: "1",
              consentRecordedDatetime: new Date(),
              consentSourceJson: [
                {
                  tag: "email",
                  value: values.userdata,
                },
                {
                  tag: "T&C",
                  value: values.termsconditions,
                },
              ],
            };
          }

          whiteLabelService.saveUserConsent(
            updateconsent,
            UserId,
            (res) => {
              if (res["data"].status === "Success") {
                this.setState({ showloader: false });
              } else if (res["data"].status === "fail") {
              } else {
              }
            },
            (error) => {
              console.log(error);
            },
          );
        } else {
          // Add Consent
          const consentValues = {
            requestInfo: requestInfo_PP(Token, jtiToken),

            buId: sessionStorage.getItem("buid") !== null ? sessionStorage.getItem("buid") : "2",
            subBuId: "1",
            partyId: partyid,
            partyType: 3,
            consentObjectName: "CreditCardPayment",
            consentActionValue: "1",
            consentRecordedDatetime: new Date(),
            consentSourceJson: [
              {
                tag: "email",
                value: values.userdata,
              },
              {
                tag: "T&C",
                value: values.termsconditions,
              },
            ],
          };
          whiteLabelService.saveUserConsent(
            consentValues,
            UserId,
            (res) => {
              // alert(JSON.stringify(res["data"]))
              if (res["data"].status === "Success") {
              } else if (res["data"].status === "fail") {
              } else {
              }
            },
            (error) => {
              console.log(error);
            },
          );
        }
      },
      (error) => {
        console.log(error);
      },
    );
    // Save Consent data end

    var modifieddata = new Date();
    var dateString =
      modifieddata.getUTCFullYear() +
      "/" +
      (modifieddata.getUTCMonth() + 1) +
      "/" +
      modifieddata.getUTCDate() +
      " " +
      modifieddata.getUTCHours() +
      ":" +
      modifieddata.getUTCMinutes() +
      ":" +
      modifieddata.getUTCSeconds();

    const firstName = values.firstName ? values.firstName + " " : "";
    const middleName = values.middleName ? values.middleName + " " : "";
    const lastName = values.lastName ? values.lastName : "";
    const accountName = firstName + middleName + lastName;
    const PassValues = {
      requestInfo: requestInfo_PP(Token, jtiToken),
      paymentInfos: [
        {
          achAccount: [
            {
              achAccountId: this.state.achAccountId || 0,
              accountNumber: values.accountnumber,
              payerPartyId: partyid,
              accountName: accountName,
              bankAchRoutingCode: values.routingnumber,
              accountType: values.accountType === "Savings" ? 76002 : 76001,
              paymentMethodId: 75003,
              signature: values.signature,
              italicSign: values.italicSign,
              booleanItalicSign: values.italicSign ? true : false,
              isActive: 1,
              paymentInstrument: {
                paymentInstrumentId: this.state.instrumentId || 0,
                linkedPaymentMethodEntityId: this.state.linkedPaymentMethodEntityId || 0,
                payerPartyId: partyid,
                paymentMethodId: 75003,
                isDefault: null,
                isExpired: null,
                isBlocked: null,
                isBlacklisted: null,
                lastUsageDatetime: dateString,
              },
              billingAddress: {
                billingAddressId: this.state?.billingAddress?.billingAddressId || 0,
                firstName: values.firstName,
                middleName: values.middleName,
                lastName: values.lastName,
                email: values.emailAddress,
                phoneNo: values.phoneNumber,
                street: values.address1,
                street2: "",
                city: values.city,
                state: values.state,
                country: values.country,
                postalCode: values.zipCode.replace("-", ""),
              },
            },
          ],
          cardAccount: null,
          bankAccount: null,
          walletAccount: null,
          virtualAccount: null,
          // "partyVo": {
          //     "salutation": "",
          //     "lastName": "",
          //     "middleName": "",
          //     "firstName": "",
          //     "companyName": "Med1",
          //     "contactNo": values.phoneNumber,
          //     "contactNo2": null,
          //     "contactEmail": values.emailAddress,
          //     "partyId": partyid !== null ? partyid : "",

          // }
        },
      ],
    };

    // var Userid = "1234"
    if (this.props.location.state.paymentMethod === "echeck") {
      echequePaymentService.saveEchequePayment(
        PassValues,
        UserId,
        (res) => {
          // alert(JSON.stringify(res["data"]))
          if (res["data"].responseInfo.status === "SUCCESSFUL") {
            this.setState({
              instrumentid: res["data"].paymentInfos[0].achAccount[0].paymentInstrument.paymentInstrumentId,
              echeckId: res["data"].paymentInfos[0].achAccount[0].achAccountId,
            });

            const partyIds = JSON.parse(sessionStorage.getItem("verifypayer"));
            // var userinfo = JSON.parse(sessionStorage.getItem('verifypayer'));

            var partyid = partyIds ? partyids.partyId : "";
            var contactpartyid = partyIds ? partyids.partyTypeId : "";

            if (sessionStorage.getItem("SIC_Info") !== null) {
              sessionStorage.setItem("buid", "6");
              loginService.TokenUser((res) => {
                if (res.status === "success") {
                  sessionStorage.setItem("jwt-token", res.responseObject);
                  sessionStorage.setItem("jwt-tokencore", res.responseObject);
                  var Token = res.responseObject;
                  var jtiToken = res.responseObject;
                  var TransactionValues = {
                    requestInfo: requestInfo_PP(Token, jtiToken),
                    transaction: {
                      paymentOrder: {
                        paymentOrderId: sessionStorage.getItem("Ordernumber")
                          ? sessionStorage.getItem("Ordernumber")
                          : this.state.ES_OrderId,
                        paymentSms: values?.paymentSms && values?.paymentSms === true ? 1 : 0,
                        paymentEmail: values?.paymentEmail && values?.paymentEmail === true ? 1 : 0,
                      },
                      gatewayName: "FORTE",
                      isFinalSettlement: this.props.history.location.state?.isSettled ? "Y" : null,

                      // "action": "sale",
                      // "gatewayId": "14578",
                      // "paymentGatewayId": "123", // need to send later
                      contactPartyId: contactpartyid,
                      payerPartyId: partyid ? partyid : this.state.ES_PartyId,
                      paymentInfo: {
                        action: "sale",
                        authorizationAmount: sessionStorage.getItem("amounttopay")
                          ? sessionStorage.getItem("amounttopay")
                          : this.state.ES_Amount, // actual paid value partial or full amount
                        subtotalAmount: sessionStorage.getItem("TotalAmount")
                          ? sessionStorage.getItem("TotalAmount")
                          : this.state.ES_Amount, // actual paid value partial or full amount
                        billingAddress: {
                          billingAddressId: this.state?.billingAddress?.billingAddressId || 0,
                          firstName: values.firstName,
                          lastName: values.lastName,
                          email: values.emailAddress,
                          phoneNo: values.phoneNumber,
                          street: values.address1,
                          street2: "",
                          city: values.city,
                          state: values.state,
                          country: values.country,
                          postalCode: values.zipCode.replace("-", ""),
                        },
                        //   "card":{
                        //      "cardType":values.cardtype,
                        //      "nameOnCard":values.nameOnCard,
                        //      "accountNumber":values.cardNumber,
                        //      "expireMonth":values.month,
                        //      "expireYear":values.year,
                        //      "cardVerificationValue":values.cardCvvNumber
                        //   },
                        echeck: {
                          achAccountId: this.state.achAccountId || 0,
                          secCode: "WEB",
                          accountType: values.accountType,
                          accountNumber: values.accountNumber,
                          routingNumber: values.routingnumber,
                          accountHolder: "Emmett Brown",
                        },
                      },
                      mappedPayerPaymentPlanAgreementId: "0", // will get from fe
                      paymentGatewayId: "00", // for now 45
                      instrumentId: this.state.instrumentid ? this.state.instrumentid : null, // for now 99
                      paymentMethodId: "75003", //
                      targetAmount:
                        Number(sessionStorage.getItem("TotalAmount")) !== 0
                          ? sessionStorage.getItem("TotalAmount")
                          : this.state.ES_Amount,
                      paidAmount:
                        Number(sessionStorage.getItem("amounttopay")) !== 0
                          ? sessionStorage.getItem("amounttopay")
                          : this.state.ES_Amount,
                      paymentType: "Online",
                      paymentSubtype: "gateway",
                      paymentCode: "ADA", // for now ADA need to decide
                      refundedAmount: "0", // blank or 0
                      amountPaidAgency: "0", // for now 0
                      paidAgencyId: "0", // for now 0
                    },
                  };

                  paymentCreditCardService.paymentTransaction(
                    TransactionValues,
                    UserId,
                    (res) => {
                      // alert(JSON.stringify(res["data"]))
                      if (res["data"].responseInfo.status === "SUCCESSFUL") {
                        // success("Success", successNotification);
                        // // sessionStorage.setItem('payerinfo', JSON.stringify(PassValues.payerAccountSearch));
                        // this.props.history.push("/Transaction-Successful");
                        sessionStorage.setItem("transactiondata", JSON.stringify(res["data"].transaction));
                        sessionStorage.setItem("transactionId", res["data"].transaction.paymentTxnId);
                        sessionStorage.setItem("screen", "Thankyou");
                        if (res["data"].transaction.transactionStatus === "SUCCESS") {
                          if (this.props.history.location.state?.isSettled) {
                            let PassValues = {
                              requestInfo: requestInfo_PP(
                                sessionStorage.getItem("jwt-token"),
                                sessionStorage.getItem("jwt-token"),
                              ),
                              settlementRequest: {
                                accountNumber: this.props.history.location.state?.seedAccountNumber,
                                amountSettled: Number(sessionStorage.getItem("amounttopay")),
                                settlementStatus: "SETTLED",
                              },
                            };
                            settlementService.saveSettlementOption(
                              PassValues,
                              0,
                              (res) => {
                                if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
                                  // success("Settlement ", successNotification)
                                } else {
                                  error(res?.data?.responseMessage[0] || "Something went wrong!", errorNotification);
                                }
                              },
                              (error) => {
                                error("Something went wrong! Please try again", errorNotification);
                              },
                            );
                          }

                          this.setState({
                            showloader: false,
                          });
                          sessionStorage.setItem("screen", "Thankyou");
                          var applicationId = btoa(this.state.ES_AppId);
                          var partyId = btoa(this.state.ES_PartyId);
                          var transactionId = btoa(res.data.transaction.paymentTxnId);
                          var billDate = btoa(res.data.transaction.paymentOrder.paymentOrderDetails[0].billDate);
                          var isTransactionSuccess = btoa(res.data.transaction.transactionStatus);
                          var planAmount = btoa(res.data.transaction.paidAmount);
                          var planid = btoa(this.state.ES_PlanId);
                          var priceSubscriptionID = btoa(this.state.ES_PriceSubId);
                          var purchaseParm = btoa(this.state.ES_PurchaseParameters);
                          var jsonDetails = btoa(this.state.ES_JsonData);
                          var subsriberId = btoa(this.state.ES_SubId);

                          if (sessionStorage.getItem("SIC_Info") !== null) {
                            var proxy_url = sessionStorage.getItem("SIC_URL");
                            window.open(
                              proxy_url +
                                "/accountActivate?id=" +
                                applicationId +
                                "&p=" +
                                partyId +
                                "&t=" +
                                transactionId +
                                "&b=" +
                                billDate +
                                "&s=" +
                                isTransactionSuccess +
                                "&pr=" +
                                planAmount +
                                "&pl=" +
                                planid +
                                "&ps=" +
                                priceSubscriptionID +
                                "&pp=" +
                                purchaseParm +
                                "&sb=" +
                                subsriberId +
                                "&js=" +
                                jsonDetails,
                              "_self",
                            );
                          } else {
                            this.props.history.push({
                              pathname: "/thank-you",
                              state: { page: this.state?.page },
                            });
                          }
                        } else if (res["data"].transaction.transactionStatus === "PENDING") {
                          warning(res["data"].transaction.transactionStatusMessage, warningNotification);
                          sessionStorage.setItem("screen", "Thankyou");
                          this.props.history.push({
                            pathname: "/Transaction-Pending",
                            state: { page: this.state?.page },
                          });
                        } else {
                          error(res["data"].transaction.transactionStatusMessage, errorNotification);
                          sessionStorage.setItem("screen", "Thankyou");
                          this.props.history.push({
                            pathname: "/Transaction-Fail",
                            state: { page: this.state?.page },
                          });
                        }
                      } else if (res["data"].responseInfo.status === "FAILED") {
                        error(res["data"].responseInfo.responseMessage[0], errorNotification);

                        this.setState({
                          showloader: false,
                        });
                      } else {
                        this.setState({
                          showloader: false,
                        });
                      }
                    },
                    (error) => {
                      console.log(error);
                    },
                  );
                }
              }, console.log("Token Error"));
            } else {
              var TransactionValues = {
                requestInfo: requestInfo_PP(Token, jtiToken),
                transaction: {
                  paymentOrder: {
                    paymentOrderId: sessionStorage.getItem("Ordernumber")
                      ? sessionStorage.getItem("Ordernumber")
                      : this.state.ES_OrderId,
                    paymentSms: values?.paymentSms && values?.paymentSms === true ? 1 : 0,
                    paymentEmail: values?.paymentEmail && values?.paymentEmail === true ? 1 : 0,
                  },
                  gatewayName: "FORTE",
                  isFinalSettlement: this.props.history.location.state?.isSettled ? "Y" : null,

                  // "action": "sale",
                  // "gatewayId": "14578",
                  // "paymentGatewayId": "123", // need to send later
                  contactPartyId: contactpartyid,
                  payerPartyId: partyid ? partyid : this.state.ES_PartyId,
                  paymentInfo: {
                    action: "sale",
                    authorizationAmount: sessionStorage.getItem("amounttopay")
                      ? sessionStorage.getItem("amounttopay")
                      : this.state.ES_Amount, // actual paid value partial or full amount
                    subtotalAmount: sessionStorage.getItem("TotalAmount")
                      ? sessionStorage.getItem("TotalAmount")
                      : this.state.ES_Amount, // actual paid value partial or full amount
                    billingAddress: {
                      billingAddressId: this.state?.billingAddress?.billingAddressId || 0,
                      firstName: values.firstName,
                      lastName: values.lastName,
                      email: values.emailAddress,
                      phoneNo: values.phoneNumber,
                      street: values.address1,
                      street2: "",
                      city: values.city,
                      state: values.state,
                      country: values.country,
                      postalCode: values.zipCode.replace("-", ""),
                    },

                    echeck: {
                      achAccountId: this.state.achAccountId || this.state.echeckId,
                      secCode: "WEB",
                      accountType: values.accountType,
                      accountNumber: values.accountnumber,
                      routingNumber: values.routingnumber,
                      accountHolder: "Emmett Brown",
                    },
                  },
                  mappedPayerPaymentPlanAgreementId: "0", // will get from fe
                  paymentGatewayId: "00", // for now 45
                  instrumentId: this.state.instrumentid ? this.state.instrumentid : null, // for now 99
                  paymentMethodId: "75003", //
                  targetAmount: sessionStorage.getItem("TotalAmount")
                    ? sessionStorage.getItem("TotalAmount")
                    : this.state.ES_Amount,
                  paidAmount: sessionStorage.getItem("amounttopay")
                    ? sessionStorage.getItem("amounttopay")
                    : this.state.ES_Amount,
                  paymentType: "Online",
                  paymentSubtype: "gateway",
                  paymentCode: "ADA", // for now ADA need to decide
                  refundedAmount: "0", // blank or 0
                  amountPaidAgency: "0", // for now 0
                  paidAgencyId: "0", // for now 0
                },
              };

              paymentCreditCardService.paymentTransaction(
                TransactionValues,
                UserId,
                (res) => {
                  if (res["data"].responseInfo.status === "SUCCESSFUL") {
                    // success("Success", successNotification);
                    // // sessionStorage.setItem('payerinfo', JSON.stringify(PassValues.payerAccountSearch));
                    // this.props.history.push("/Transaction-Successful");
                    sessionStorage.setItem("transactiondata", JSON.stringify(res["data"].transaction));
                    sessionStorage.setItem("transactionId", res["data"].transaction.paymentTxnId);
                    sessionStorage.setItem("screen", "Thankyou");

                    if (res["data"].transaction.transactionStatus === "SUCCESS") {
                      handleSuccess("Transaction Processed Successfully");
                      this.setState({
                        showloader: false,
                      });
                      sessionStorage.setItem("screen", "Thankyou");

                      this.props.history.push("/thank-you");
                    } else if (res["data"].transaction.transactionStatus === "PENDING") {
                      warning(res["data"].transaction.transactionStatusMessage, warningNotification);
                      sessionStorage.setItem("screen", "Thankyou");
                      this.props.history.push("/Transaction-Pending");
                    } else {
                      handleError(res["data"].transaction.transactionStatusMessage);
                      sessionStorage.setItem("screen", "Thankyou");
                    }
                  } else if (res["data"].responseInfo.status === "FAILED") {
                    handleError(res["data"].responseInfo.responseMessage[0]);
                    this.setState({
                      showloader: false,
                    });
                  } else {
                    this.setState({
                      showloader: false,
                    });
                  }
                },
                (error) => {
                  console.log(error);
                },
              );
            }
          } else if (res["data"].responseInfo.status === "FAILED") {
            this.setState({
              showloader: false,
            });
          } else {
            this.setState({
              showloader: false,
            });
          }
        },
        (error) => {
          console.log(error);

          this.setState({
            showloader: false,
          });
        },
      );
    } else {
      if (sessionStorage.getItem("SIC_Info") !== null) {
        sessionStorage.setItem("buid", "6");
        loginService.TokenUser((res) => {
          if (res.status === "success") {
            sessionStorage.setItem("jwt-token", res.responseObject);
            sessionStorage.setItem("jwt-tokencore", res.responseObject);

            var Token = res.responseObject;
            var jtiToken = res.responseObject;
            var TransactionValues = {
              requestInfo: requestInfo_PP(Token, jtiToken),
              transaction: {
                paymentOrder: {
                  paymentOrderId: sessionStorage.getItem("Ordernumber")
                    ? sessionStorage.getItem("Ordernumber")
                    : this.state.ES_OrderId,
                  paymentSms: values?.paymentSms && values?.paymentSms === true ? 1 : 0,
                  paymentEmail: values?.paymentEmail && values?.paymentEmail === true ? 1 : 0,
                },
                gatewayName: "FORTE",
                isFinalSettlement: this.props.history.location.state?.isSettled ? "Y" : null,

                // "action": "sale",
                // "gatewayId": "14578",
                // "paymentGatewayId": "123", // need to send later
                contactPartyId: contactpartyid,
                payerPartyId: partyid ? partyid : this.state.ES_PartyId,
                paymentInfo: {
                  action: "sale",
                  authorizationAmount: sessionStorage.getItem("amounttopay")
                    ? sessionStorage.getItem("amounttopay")
                    : this.state.ES_Amount, // actual paid value partial or full amount
                  subtotalAmount: sessionStorage.getItem("TotalAmount")
                    ? sessionStorage.getItem("TotalAmount")
                    : this.state.ES_Amount, // actual paid value partial or full amount
                  billingAddress: {
                    billingAddressId: this.state?.billingAddress?.billingAddressId || 0,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.emailAddress,
                    phoneNo: values.phoneNumber,
                    street: values.address1,
                    street2: "",
                    city: values.city,
                    state: values.state,
                    country: values.country,
                    postalCode: values.zipCode.replace("-", ""),
                  },

                  echeck: {
                    achAccountId: this.state.achAccountId || this.state.echeckId,
                    secCode: "WEB",
                    accountType: values.accountType,
                    accountNumber: values.accountNumber,
                    routingNumber: values.routingnumber,
                    accountHolder: "Emmett Brown",
                  },
                },
                mappedPayerPaymentPlanAgreementId: "0", // will get from fe
                paymentGatewayId: "00", // for now 45
                instrumentId: this.state.instrumentId ? this.state.instrumentId : null, // for now 99
                paymentMethodId: "75003", //
                targetAmount:
                  Number(sessionStorage.getItem("TotalAmount")) !== 0
                    ? sessionStorage.getItem("TotalAmount")
                    : this.state.ES_Amount,
                paidAmount:
                  Number(sessionStorage.getItem("amounttopay")) !== 0
                    ? sessionStorage.getItem("amounttopay")
                    : this.state.ES_Amount,
                paymentType: "Online",
                paymentSubtype: "gateway",
                paymentCode: "ADA", // for now ADA need to decide
                refundedAmount: "0", // blank or 0
                amountPaidAgency: "0", // for now 0
                paidAgencyId: "0", // for now 0
              },
            };

            paymentCreditCardService.paymentTransaction(
              TransactionValues,
              UserId,
              (res) => {
                // alert(JSON.stringify(res["data"]))
                if (res["data"].responseInfo.status === "SUCCESSFUL") {
                  // success("Success", successNotification);
                  // // sessionStorage.setItem('payerinfo', JSON.stringify(PassValues.payerAccountSearch));
                  // this.props.history.push("/Transaction-Successful");
                  sessionStorage.setItem("transactiondata", JSON.stringify(res["data"].transaction));
                  sessionStorage.setItem("transactionId", res["data"].transaction.paymentTxnId);
                  sessionStorage.setItem("screen", "Thankyou");
                  if (res["data"].transaction.transactionStatus === "SUCCESS") {
                    if (this.props.history.location.state?.isSettled) {
                      let PassValues = {
                        requestInfo: requestInfo_PP(
                          sessionStorage.getItem("jwt-token"),
                          sessionStorage.getItem("jwt-token"),
                        ),
                        settlementRequest: {
                          accountNumber: this.props.history.location.state?.seedAccountNumber,
                          amountSettled: Number(sessionStorage.getItem("amounttopay")),
                          settlementStatus: "SETTLED",
                        },
                      };
                      settlementService.saveSettlementOption(
                        PassValues,
                        0,
                        (res) => {
                          if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
                            // success("Settlement ", successNotification)
                          } else {
                            error(res?.data?.responseMessage[0] || "Something went wrong!", errorNotification);
                          }
                        },
                        (error) => {
                          error("Something went wrong! Please try again", errorNotification);
                        },
                      );
                    }

                    this.setState({
                      showloader: false,
                    });
                    sessionStorage.setItem("screen", "Thankyou");
                    var applicationId = btoa(this.state.ES_AppId);
                    var partyId = btoa(this.state.ES_PartyId);
                    var transactionId = btoa(res.data.transaction.paymentTxnId);
                    var billDate = btoa(res.data.transaction.paymentOrder.paymentOrderDetails[0].billDate);
                    var isTransactionSuccess = btoa(res.data.transaction.transactionStatus);
                    var planAmount = btoa(res.data.transaction.paidAmount);
                    var planid = btoa(this.state.ES_PlanId);
                    var priceSubscriptionID = btoa(this.state.ES_PriceSubId);
                    var purchaseParm = btoa(this.state.ES_PurchaseParameters);
                    var jsonDetails = btoa(this.state.ES_JsonData);
                    var subsriberId = btoa(this.state.ES_SubId);

                    if (sessionStorage.getItem("SIC_Info") !== null) {
                      var proxy_url = sessionStorage.getItem("SIC_URL");
                      window.open(
                        proxy_url +
                          "/accountActivate?id=" +
                          applicationId +
                          "&p=" +
                          partyId +
                          "&t=" +
                          transactionId +
                          "&b=" +
                          billDate +
                          "&s=" +
                          isTransactionSuccess +
                          "&pr=" +
                          planAmount +
                          "&pl=" +
                          planid +
                          "&ps=" +
                          priceSubscriptionID +
                          "&pp=" +
                          purchaseParm +
                          "&sb=" +
                          subsriberId +
                          "&js=" +
                          jsonDetails,
                        "_self",
                      );
                    } else {
                      this.props.history.push({
                        pathname: "/thank-you",
                        state: { page: this.state?.page },
                      });
                    }
                  } else if (res["data"].transaction.transactionStatus === "PENDING") {
                    warning(res["data"].transaction.transactionStatusMessage, warningNotification);
                    sessionStorage.setItem("screen", "Thankyou");
                    this.props.history.push({
                      pathname: "/Transaction-Pending",
                      state: { page: this.state?.page },
                    });
                  } else {
                    // error(res["data"].transaction.transactionStatusMessage, errorNotification);
                    handleError(res["data"].transaction.transactionStatusMessage);
                    sessionStorage.setItem("screen", "Thankyou");
                    this.props.history.push({
                      pathname: "/Transaction-Fail",
                      state: { page: this.state?.page },
                    });
                  }
                } else if (res["data"].responseInfo.status === "FAILED") {
                  // error(res["data"].responseInfo.responseMessage[0], errorNotification);
                  handleError(res["data"].responseInfo.responseMessage[0]);

                  this.setState({
                    showloader: false,
                  });
                } else {
                  this.setState({
                    showloader: false,
                  });
                }
              },
              (error) => {
                console.log(error);
              },
            );
          }
        }, console.log("Token Error"));
      } else {
        var TransactionValues = {
          requestInfo: requestInfo_PP(Token, jtiToken),
          transaction: {
            paymentOrder: {
              paymentOrderId: sessionStorage.getItem("Ordernumber")
                ? sessionStorage.getItem("Ordernumber")
                : this.state.ES_OrderId,
              paymentSms: values?.paymentSms && values?.paymentSms === true ? 1 : 0,
              paymentEmail: values?.paymentEmail && values?.paymentEmail === true ? 1 : 0,
            },
            gatewayName: "FORTE",
            isFinalSettlement: this.props.history.location.state?.isSettled ? "Y" : null,

            // "action": "sale",
            // "gatewayId": "14578",
            // "paymentGatewayId": "123", // need to send later
            contactPartyId: contactpartyid,
            payerPartyId: partyid ? partyid : this.state.ES_PartyId,
            paymentInfo: {
              action: "sale",
              authorizationAmount: sessionStorage.getItem("amounttopay")
                ? sessionStorage.getItem("amounttopay")
                : this.state.ES_Amount, // actual paid value partial or full amount
              subtotalAmount: sessionStorage.getItem("TotalAmount")
                ? sessionStorage.getItem("TotalAmount")
                : this.state.ES_Amount, // actual paid value partial or full amount
              billingAddress: {
                billingAddressId: this.state?.billingAddress?.billingAddressId || 0,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.emailAddress,
                phoneNo: values.phoneNumber,
                street: values.address1,
                street2: "",
                city: values.city,
                state: values.state,
                country: values.country,
                postalCode: values.zipCode.replace("-", ""),
              },

              echeck: {
                achAccountId: this.state.achAccountId || this.state.echeckId,
                secCode: "WEB",
                accountType: values.accountType,
                accountNumber: values.accountNumber,
                routingNumber: values.routingnumber,
                accountHolder: "Emmett Brown",
              },
            },
            mappedPayerPaymentPlanAgreementId: "0", // will get from fe
            paymentGatewayId: "00", // for now 45
            instrumentId: this.state.instrumentId ? this.state.instrumentId : null, // for now 99
            paymentMethodId: "75003", //
            targetAmount: sessionStorage.getItem("TotalAmount")
              ? sessionStorage.getItem("TotalAmount")
              : this.state.ES_Amount,
            paidAmount: sessionStorage.getItem("amounttopay") ? sessionStorage.getItem("amounttopay") : this.state.ES_Amount,
            paymentType: "Online",
            paymentSubtype: "gateway",
            paymentCode: "ADA", // for now ADA need to decide
            refundedAmount: "0", // blank or 0
            amountPaidAgency: "0", // for now 0
            paidAgencyId: "0", // for now 0
          },
        };

        paymentCreditCardService.paymentTransaction(
          TransactionValues,
          UserId,
          (res) => {
            // alert(JSON.stringify(res["data"]))
            if (res["data"].responseInfo.status === "SUCCESSFUL") {
              // success("Success", successNotification);
              // // sessionStorage.setItem('payerinfo', JSON.stringify(PassValues.payerAccountSearch));
              // this.props.history.push("/Transaction-Successful");
              sessionStorage.setItem("transactiondata", JSON.stringify(res["data"].transaction));
              sessionStorage.setItem("transactionId", res["data"].transaction.paymentTxnId);
              sessionStorage.setItem("screen", "Thankyou");
              if (res["data"].transaction.transactionStatus === "SUCCESS") {
                if (this.props.history.location.state?.isSettled) {
                  let PassValues = {
                    requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
                    settlementRequest: {
                      accountNumber: this.props.history.location.state?.seedAccountNumber,
                      amountSettled: Number(sessionStorage.getItem("amounttopay")),
                      settlementStatus: "SETTLED",
                    },
                  };
                  settlementService.saveSettlementOption(
                    PassValues,
                    0,
                    (res) => {
                      if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
                        // success("Settlement ", successNotification)
                      } else {
                        error(res?.data?.responseMessage[0] || "Something went wrong!", errorNotification);
                      }
                    },
                    (error) => {
                      error("Something went wrong! Please try again", errorNotification);
                    },
                  );
                }
                // success(
                //   <Translation>{(t, { i18n }) => <label>{t("credit_successTransactionProcessed")}</label>}</Translation>,
                //   successNotification,
                // );
                handleSuccess("Transaction Processed Successfully");
                this.setState({
                  showloader: false,
                });
                sessionStorage.setItem("screen", "Thankyou");

                setTimeout(() => {
                  this.props.history.push("/thank-you");
                }, 1500);
              } else if (res["data"].transaction.transactionStatus === "PENDING") {
                warning(res["data"].transaction.transactionStatusMessage, warningNotification);
                sessionStorage.setItem("screen", "Thankyou");
                this.props.history.push("/Transaction-Pending");
              } else {
                // error(res["data"].transaction.transactionStatusMessage, errorNotification);
                handleError(res["data"].transaction.transactionStatusMessage);
                sessionStorage.setItem("screen", "Thankyou");
                this.props.history.push("/Transaction-Fail");
              }
            } else if (res["data"].responseInfo.status === "FAILED") {
              // error(res["data"].responseInfo.responseMessage[0], errorNotification);
              handleError(res["data"].responseInfo.responseMessage[0]);

              this.setState({
                showloader: false,
              });
            } else {
              // warning(
              //   <Translation>{(t, { i18n }) => <label>{t("echeque__warningMsgwentWrong")}</label>}</Translation> +
              //     res["data"].responseInfo.responseMessage[0],
              //   warningNotification,
              // );
              this.setState({
                showloader: false,
              });
            }
          },
          (error) => {
            console.log(error);
          },
        );
      }
    }
  };
  getAddressObject = (address_components) => {
    const ShouldBeComponent = {
      street_number: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      province: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      country: ["country"],
    };

    let address = {
      street_number: "",
      postal_code: "",
      street: "",
      province: "",
      city: "",
      country: "",
    };

    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "country") {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });

    // Fix the shape to match our schema
    address.address = address?.street_number + " " + address?.street;
    delete address.street_number;
    delete address.street;
    if (address.country === "US") {
      address.state = address.province;
      delete address.province;
    }
    return address;
  };
  getGoogleApiInfo = async (values, val) => {
    const geocodeObj = val && val.value && (await geocodeByPlaceId(val.value.place_id));
    const addressObject = geocodeObj && this.getAddressObject(geocodeObj[0].address_components);
    // setAddressObj(addressObject);
    addressObject
      ? this.setState({
          ...values,
          country:
            addressObject?.country.toLocaleLowerCase() === "us" ||
            addressObject.country.toLocaleLowerCase() === "usa" ||
            addressObject === null
              ? "united states of america"
              : addressObject.country,
          state: addressObject?.state,
          city: addressObject?.city,
          zipCode: addressObject?.postal_code,
          address1: addressObject?.address.trim(),
          address2: "",
          googleaddress: val,
        })
      : this.setState({
          ...values,
          googleaddress: "clear",
        });
  };
  render() {
    const billing = this.state.billingAddress;
    const accountType = [
      { value: "Savings", key: "savings" },
      { value: "Checking", key: "checking" },
    ];
    return (
      <>
        {this.state.showloader === true ? <SpinnerPage /> : ""}
        <Stepper />
        <div className="creditcard-content col-lg-10 col-xl-8 mx-auto" role="main">
          <div className="dashboard-content-card">
            <Formik
              enableReinitialize
              initialValues={{
                accountnumber: this.state.accountNumber || "",
                reenteraccountNumber: this.state.reenteraccountNumber || "",
                routingnumber: this.state.routingnumber || "",
                accountType: this.state.accountType || "",
                stateList: stateList ? stateList : [],
                cityList: this.state.cityList || [],
                emailAddress: this.state.email || billing.email,
                phoneNumber: this.state.phoneNumber || billing.phoneNo,
                address1: this.state.address1 || billing.street,
                address2: this.state.address2 || billing.street2,
                country: this.state.country || billing.country || "none",
                countryid: this.state.countryid ? this.state.countryid : "",
                state: this.state.state || billing.state,
                stateid: this.state.stateid ? this.state.stateid : "",
                city: this.state.city || billing.city,
                cityid: this.state.cityid ? this.state.cityid : "",
                zipCode: this.state.zipCode || billing.postalCode,
                termsconditions: true,
                userdata: true,
                authorizeElectronicDebit: true,
                agreeToEmailUsage: true,
                agreeToterms: true,
                firstName: this.state.firstName ? this.state.firstName : billing.firstName,
                lastName: this.state.lastName || billing.lastName,
                middleName: this.state.middleName || billing.middleName,
                italicSign: this.state.italicSign || "",
                signature: this.state.signature || "",
                isItalicSign: false,
                showRoutingNumber: false,
                showAccountNumber1: false,
                showAccountNumber2: false,
                maskedAccountNumber: this.state.maskedAccountNumber,
                maskedRoutingNumber: this.state.maskedRoutingNumber,
                futureDate: this.state.futureDate,
                paymentEmail: true,
                paymentSms: false,
                reminderEmail: true,
                reminderSms: false,
              }}
              onSubmit={this.props.payInFuture ? this._handlePay : this._handleSubmit}
              validationSchema={this.props.payInFuture ? this.futureSchema : this.schema}>
              {(props) => {
                const { values, touched, error, handleChange, errors, setFieldValue, handleBlur } = props;

                return (
                  <Form className="input-field" noValidate="noValidate">
                    <div>
                      <div className="e-check-bg">
                        <div className="echeck-card">
                          <div className="account-balance">
                            <p>Total Account Balance</p>
                            <h3>
                              {" "}
                              ${" "}
                              {this.state.ES_Amount
                                ? Number(this.state.ES_Amount).toLocaleString(undefined, { minimumFractionDigits: 2 })
                                : Number(sessionStorage.getItem("TotalAmount")).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                            </h3>
                          </div>
                          <div className="account-balance">
                            <p>Amount To Pay</p>
                            <h3>
                              {" "}
                              ${" "}
                              {this.state.ES_Amount
                                ? Number(this.state.ES_Amount).toLocaleString(undefined, { minimumFractionDigits: 2 })
                                : Number(sessionStorage.getItem("amounttopay")).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                            </h3>
                          </div>
                          <div className="account-balance">
                            <div role="heading" aria-level="1">
                              {" "}
                              <h2 class="content-title">eCheck Details</h2>
                            </div>
                          </div>
                          {/* <h4>{this.state.editECheckData ? "Edit eCheck" : "eCheck Payment"}</h4> */}
                          <div>
                            {this.props.payInFuture && (
                              <>
                                <div className="form-row">
                                  <div className="col-sm-6 col-md-4 form-group mb-1">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <Grid container className="custom-date-picker date-picker">
                                        <label htmlFor="patientDOB" className="form-label required">
                                          Future Date
                                        </label>
                                        <DatePicker
                                          // disableFuture
                                          name="futureDate"
                                          id="futureDate"
                                          autoOk
                                          value={values.futureDate}
                                          minDate={moment().toDate()}
                                          maxDate={
                                            (moment(this.state.lastDayOfMonth).isSame(moment().toDate(), "day") &&
                                              moment(this.state.secondLastDayOfMonth).isSame(moment().toDate(), "day")) ||
                                            (moment(this.state.thirdLastDayOfMonth).isSame(moment().toDate(), "day") &&
                                              (this.state.futureMonthDays === 28 || this.state.futureMonthDays === 29))
                                              ? moment(this.state.futureDate1).endOf("month").toDate()
                                              : moment().daysInMonth() === 30
                                              ? moment().add(29, "days").toDate()
                                              : moment().daysInMonth() === 29
                                              ? moment().add(28, "days").toDate()
                                              : moment().daysInMonth() === 28
                                              ? moment().add(27, "days").toDate()
                                              : moment().add(30, "days").toDate()
                                          }
                                          InputProps={{
                                            startAdornment: (
                                              <IconButton
                                                position="end"
                                                onClick={() => setFieldValue("futureDate", null)}
                                                disabled={!values?.futureDate}
                                                style={{ order: 1 }}>
                                                <ClearIcon
                                                  color={!values?.futureDate ? "disabled" : "#808080"}
                                                  fontSize="small"
                                                />
                                              </IconButton>
                                            ),
                                          }}
                                          InputAdornmentProps={{
                                            position: "end",
                                            style: { order: 2 },
                                          }}
                                          onChange={(val) => this.setState({ futureDate: val })}
                                          // renderInput={(params) => (
                                          //   <TextField
                                          //     inputProps={{
                                          //       ...params.inputProps,
                                          //       placeholder: "MM/DD/YYYY",
                                          //     }}
                                          //     helperText={null}
                                          //     margin="dense"
                                          //   />
                                          // )}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              helperText={null}
                                              margin="dense"
                                              inputProps={{
                                                ...params.inputProps,
                                                placeholder: "MM/DD/YYYY",
                                              }}
                                              error={Boolean(getIn(touched, "futureDate") && getIn(errors, "futureDate"))}
                                            />
                                          )}
                                        />
                                      </Grid>
                                    </LocalizationProvider>
                                    <FormHelperText className="echeck-date-picker" error>
                                      {touched.futureDate && errors.futureDate ? <div>{errors.futureDate}</div> : null}
                                    </FormHelperText>
                                  </div>
                                </div>
                              </>
                            )}
                            {this.props.location.state.paymentMethod === "echeck" ? (
                              <>
                                <div className="form-row debitcard">
                                  <div className="col-md-4 col-sm-6 form-group">
                                    <FormLabel htmlFor="firstName" className="required">
                                      First Name
                                    </FormLabel>
                                    <OutlinedInput
                                      className="form-control"
                                      name="firstName"
                                      value={values?.firstName.charAt(0).toUpperCase() + values?.firstName.slice(1)}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      placeholder="Enter First Name"
                                      aria-label="text-field"
                                      fullWidth
                                      required></OutlinedInput>
                                    <FormHelperText error name="firstName">
                                      {touched.firstName && errors.firstName ? <div>{errors.firstName}</div> : null}
                                    </FormHelperText>

                                    {/* {this.schema.errors.firstName ? <div>{this.schema.errors.firstName}</div> : null} */}
                                  </div>
                                  <div className="col-md-4 col-sm-6 form-group">
                                    <FormLabel htmlFor="middleName">Middle Name</FormLabel>
                                    <OutlinedInput
                                      className="form-control"
                                      name="middleName"
                                      value={values.middleName}
                                      onChange={handleChange}
                                      placeholder="Enter Middle Name"
                                      aria-label="text-field"
                                      fullWidth></OutlinedInput>
                                    <FormHelperText error>
                                      {touched.middleName && errors.middleName ? <div>{errors.middleName}</div> : null}
                                    </FormHelperText>
                                  </div>
                                  <div className="col-md-4 col-sm-6 form-group">
                                    <FormLabel htmlFor="lastName" className="required">
                                      Last Name
                                    </FormLabel>
                                    <OutlinedInput
                                      className="form-control"
                                      name="lastName"
                                      value={values?.lastName.charAt(0).toUpperCase() + values?.lastName.slice(1)}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      placeholder="Enter Last Name"
                                      aria-label="text-field"
                                      fullWidth
                                      required></OutlinedInput>
                                    <FormHelperText error>
                                      {touched.lastName && errors.lastName ? <div>{errors.lastName}</div> : null}
                                    </FormHelperText>
                                  </div>
                                  <div className="col-md-6 col-sm-6 form-group inputbg">
                                    <div className="row m-0 justify-content-between align-items-center">
                                      <FormLabel htmlFor="routingNumber" className="required">
                                        Routing Number
                                      </FormLabel>
                                      <ImageTooltip img={echeckPaymentRoutingNumber} width="450px" placement="right">
                                        <i className="fas fa-question-circle circle-icon" style={{ cursor: "pointer" }}></i>
                                      </ImageTooltip>
                                    </div>
                                    <OutlinedInput
                                      className="form-control signup-fields"
                                      name="routingnumber"
                                      value={values.maskedRoutingNumber || this.state.routingnumber}
                                      maxLength="9"
                                      onChange={(e) => {
                                        this.setState({ routingnumber: e.target.value });
                                      }}
                                      onBlur={handleBlur}
                                      onKeyPress={(event) => {
                                        if (!/\d/.test(event.key) || values?.routingnumber?.length >= 9) {
                                          event.preventDefault();
                                        }
                                      }}
                                      disabled={this.state.editECheckData}
                                      type={
                                        this.state.editECheckData ? "text" : this.state.showRnumber ? "text" : "password"
                                      }
                                      readOnly={this.state.editECheckData ? "read only" : ""}
                                      placeholder="Enter Routing Number"
                                      aria-label="text-field"
                                      fullWidth
                                      required
                                      endAdornment={
                                        <InputAdornment position="end">
                                          {!this.state.editECheckData ? (
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={this.handleRoutingNumber}
                                              onMouseDown={this.handleMouseDownPassword}>
                                              {this.state.showRnumber ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                          ) : (
                                            " "
                                          )}
                                        </InputAdornment>
                                      }></OutlinedInput>
                                    <FormHelperText error>
                                      {touched.routingnumber && errors.routingnumber ? (
                                        <div>{errors.routingnumber}</div>
                                      ) : null}
                                    </FormHelperText>
                                  </div>
                                  <div className="col-md-6 col-sm-6 form-group inputbg">
                                    <FormLabel htmlFor="accountType" className="form-label required">
                                      Account Type
                                    </FormLabel>
                                    <Select
                                      fullWidth
                                      name="accountType"
                                      autocomplete="off"
                                      id="accountType"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.accountType || "none"}
                                      className="form-select form-control">
                                      <MenuItem value="none" disabled>
                                        Select Account Type
                                      </MenuItem>

                                      {accountType.map((option) => {
                                        return (
                                          <MenuItem value={option.value} key={option.key}>
                                            {option.value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>

                                    <FormHelperText error>
                                      {touched.accountType && errors.accountType ? <div>{errors.accountType}</div> : null}
                                    </FormHelperText>
                                  </div>

                                  <div className="col-md-6 col-sm-6 form-group inputbg">
                                    <div className="row m-0 justify-content-between align-items-center">
                                      <FormLabel htmlFor="accountnumber" className="required">
                                        Account Number
                                      </FormLabel>

                                      <ImageTooltip img={echeckPaymentRoutingNumber} width="450px" placement="right">
                                        <i className="fas fa-question-circle circle-icon" style={{ cursor: "pointer" }}></i>
                                      </ImageTooltip>
                                    </div>
                                    <OutlinedInput
                                      className="form-control signup-fields"
                                      name="accountnumber"
                                      maxLength="20"
                                      value={values.maskedAccountNumber || values.accountNumber}
                                      onChange={(e) => {
                                        this.setState({
                                          accountNumber: e.target.value,
                                          accountType: values.accountType,
                                        });
                                        // setFieldValue((values.accountNumber = e.target.value));
                                      }}
                                      onBlur={handleBlur}
                                      onKeyPress={(event) => {
                                        if (!/\d/.test(event.key) || values?.accountnumber?.length >= 20) {
                                          event.preventDefault();
                                        }
                                      }}
                                      disabled={this.state.editECheckData}
                                      placeholder="Enter Account Number"
                                      aria-label="text-field"
                                      fullWidth
                                      type={
                                        this.state.editECheckData ? "text" : this.state.showAnumberFlag ? "text" : "password"
                                      }
                                      required
                                      endAdornment={
                                        <InputAdornment position="end">
                                          {!this.state.editECheckData ? (
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={() => {
                                                if (!this.state.showAnumberFlag) {
                                                  this.setState({ showAnumberFlag: true, italicSign: values.italicSign });
                                                } else {
                                                  this.setState({ showAnumberFlag: false, italicSign: values.italicSign });
                                                }
                                              }}
                                              onMouseDown={this.handleMouseDownPassword}>
                                              {this.state.showAnumberFlag ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                          ) : (
                                            ""
                                          )}
                                        </InputAdornment>
                                      }></OutlinedInput>
                                    <FormHelperText error>
                                      {touched.accountnumber && errors.accountnumber ? (
                                        <div>{errors.accountnumber}</div>
                                      ) : null}
                                    </FormHelperText>
                                  </div>

                                  <div className="col-md-6 col-sm-6 form-group inputbg">
                                    <div className="label-spacing">
                                      <FormLabel htmlFor="reAccountNumber" className="required">
                                        Re-enter Account Number
                                      </FormLabel>
                                    </div>

                                    <OutlinedInput
                                      className="form-control signup-fields"
                                      name="reenteraccountNumber"
                                      value={this.state.reenteraccountNumber}
                                      disabled={this.state.editECheckData}
                                      onChange={(e) => {
                                        this.setState({ reenteraccountNumber: e.target.value });
                                      }}
                                      onBlur={handleBlur}
                                      onKeyPress={(event) => {
                                        if (!/\d/.test(event.key) || values?.reenteraccountNumber?.length >= 20) {
                                          event.preventDefault();
                                        }
                                      }}
                                      type={
                                        this.state.editECheckData
                                          ? "text"
                                          : this.state.showreAccountNumber
                                          ? "text"
                                          : "password"
                                      }
                                      placeholder="Re-enter Account Number"
                                      aria-label="text-field"
                                      fullWidth
                                      required
                                      endAdornment={
                                        <InputAdornment position="end">
                                          {!this.state.editECheckData ? (
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={this.handleReAccountNumber}
                                              onMouseDown={this.handleMouseDownPassword}>
                                              {this.state.showreAccountNumber ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                          ) : (
                                            ""
                                          )}
                                        </InputAdornment>
                                      }></OutlinedInput>
                                    <FormHelperText error>
                                      {touched.reenteraccountNumber && errors.reenteraccountNumber ? (
                                        <div>{errors.reenteraccountNumber}</div>
                                      ) : null}
                                    </FormHelperText>
                                  </div>
                                </div>

                                <div className="form-row">
                                  <div className="col-md-6 col-sm-12 form-group">
                                    <FormLabel htmlFor="billingAddress" className="required">
                                      Billing Address
                                    </FormLabel>
                                    {/* <OutlinedInput
                                      className="form-control"
                                      name="address1"
                                      value={values.address1}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      rows={5}
                                      placeholder="Enter Your Billing Address"
                                      aria-label="text-field"
                                      fullWidth
                                      required></OutlinedInput> */}
                                    {/* <FormHelperText error>
                                      {touched.address1 && errors.address1 ? <div>{errors.address1}</div> : null}
                                    </FormHelperText> */}
                                    <GooglePlacesAutocomplete
                                      apiKey="AIzaSyAymCr1DkF6p_ULBgBIZIM2VNu9pg-zrN0"
                                      autocompletionRequest={{
                                        componentRestrictions: {
                                          country: ["us"],
                                        },
                                      }}
                                      selectProps={{
                                        isClearable: true,
                                        value: this.state.googleaddress,
                                        onChange: (val) => {
                                          this.getGoogleApiInfo({ ...values }, val);
                                        },
                                      }}
                                    />
                                  </div>
                                  <div className="col-md-6 col-sm-12 form-group">
                                    <div className="label-spacing mb-md-3">
                                      <FormLabel></FormLabel>
                                    </div>
                                    <FormControl variant="outlined" className="w-100 mt-md-2" fullWidth>
                                      <TextField
                                        type="text"
                                        autocomplete="new-password"
                                        id="address1"
                                        name="address1"
                                        value={
                                          values?.address2 ? values?.address1 + "," + values?.address2 : values?.address1
                                        }
                                        placeholder="Please Enter Street Address"
                                        className={`text-field ${touched.address1 && errors.address1 ? "is-invalid" : ""}`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}></TextField>
                                    </FormControl>
                                    <FormHelperText error name="securitycode">
                                      {touched.address1 && errors.address1 ? <div>{errors.address1}</div> : null}
                                    </FormHelperText>
                                  </div>

                                  <div className="col-md-6 col-sm-6 col-lg-3 col-xl-3 form-group inputbg">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <FormLabel htmlFor="country" className="form-label required">
                                        Country
                                      </FormLabel>

                                      <Select
                                        fullWidth
                                        name="country"
                                        value={values.country}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-select form-control">
                                        {/* <MenuItem value="none" disabled>
              Select Country
            </MenuItem> */}
                                        {countryList.map((option) => {
                                          return (
                                            <MenuItem value={option.countryName} key={option.countryId}>
                                              {option?.countryName.charAt(0).toUpperCase() + option?.countryName.slice(1)}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                    <FormHelperText error>
                                      {touched.country && errors.country ? <div>{errors.country}</div> : null}
                                    </FormHelperText>
                                  </div>
                                  <div className="col-md-6 col-sm-6 col-lg-3 col-xl-3 form-group inputbg">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <FormLabel htmlFor="state" className="form-label required">
                                        State/County
                                      </FormLabel>
                                      <Select
                                        fullWidth
                                        name="state"
                                        value={values?.state?.toLowerCase() || "none"}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-select form-control">
                                        <MenuItem value="none" key="none" disabled>
                                          Select State/County
                                        </MenuItem>
                                        {stateList.map((option) => {
                                          return (
                                            <MenuItem value={option.stateName} key={option.stateId}>
                                              {option.stateName}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                    <FormHelperText error>
                                      {touched.state && errors.state ? <div>{errors.state}</div> : null}
                                    </FormHelperText>
                                  </div>

                                  <div className="col-md-6 col-sm-6 col-lg-3 col-xl-3 form-group inputbg">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <FormLabel htmlFor="city" className="form-label required">
                                        City
                                      </FormLabel>
                                      <OutlinedInput
                                        className="form-control"
                                        name="city"
                                        value={capitalize(values?.city)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter City"
                                        aria-label="text-field"
                                        fullWidth
                                        required></OutlinedInput>
                                    </FormControl>
                                    <FormHelperText error>
                                      {touched.city && errors.city ? <div>{errors.city}</div> : null}
                                    </FormHelperText>
                                  </div>
                                  <div className="col-md-6 col-sm-6 col-lg-3 col-xl-3 form-group inputbg">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <FormLabel htmlFor="zipCode" className="required">
                                        ZIP Code
                                      </FormLabel>
                                      <OutlinedInput
                                        className="form-control"
                                        name="zipCode"
                                        value={values.zipCode}
                                        onChange={(event) => {
                                          const zip = event.target.value;
                                          setFieldValue(
                                            (values.zipCode =
                                              zip.length > 5 && !zip.includes("-")
                                                ? zip.substring(0, 5).concat("-") + zip.substring(5, zip.length)
                                                : zip),
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        placeholder="Enter ZIP Code"
                                        inputProps={{ minLength: 5, maxLength: 10 }}
                                        aria-label="text-field"
                                        fullWidth
                                        required></OutlinedInput>
                                    </FormControl>
                                    <FormHelperText error>
                                      {touched.zipCode && errors.zipCode ? <div>{errors.zipCode}</div> : null}
                                    </FormHelperText>
                                  </div>
                                  <div className="col-md-6 col-sm-6 form-group">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <FormLabel
                                        htmlFor="emailAddress"
                                        className={sessionStorage?.getItem("currentUserRole") === "Admin" ? "" : "required"}>
                                        Email Address
                                      </FormLabel>
                                      <OutlinedInput
                                        className="form-control"
                                        name="emailAddress"
                                        value={values.emailAddress}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="email"
                                        placeholder="Enter Email Address"
                                        aria-label="text-field"
                                        fullWidth
                                        required></OutlinedInput>
                                    </FormControl>
                                    <FormHelperText error>
                                      {touched.emailAddress && errors.emailAddress ? <div>{errors.emailAddress}</div> : null}
                                    </FormHelperText>
                                  </div>
                                  <div className="col-md-6 col-sm-6 form-group">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <FormLabel htmlFor="phoneNumber" className="required">
                                        Phone Number
                                      </FormLabel>
                                      <OutlinedInput
                                        className="form-control"
                                        name="phoneNumber"
                                        value={values.phoneNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onKeyPress={(event) => {
                                          if (!/\d/.test(event.key) || values.phoneNumber.length >= 10) {
                                            event.preventDefault();
                                          }
                                        }}
                                        placeholder="Enter Phone Number"
                                        aria-label="text-field"
                                        type="number"
                                        fullWidth
                                        required></OutlinedInput>
                                    </FormControl>
                                    <FormHelperText error>
                                      {touched.phoneNumber && errors.phoneNumber ? <div>{errors.phoneNumber}</div> : null}
                                    </FormHelperText>
                                  </div>
                                </div>
                                <div>
                                  <Signature
                                    values={values}
                                    touched={touched}
                                    handleChange={handleChange}
                                    stateUpdate={() => {
                                      this.setState({
                                        ...this.state,
                                        ...values,
                                        email: values.emailAddress,
                                      });
                                    }}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                    updateUserSignature={this.updateUserSignature}
                                  />
                                  {/* <FormHelperText error>
                                    {touched.signature && errors.signature ? <div>{errors.signature}</div> : null}
                                  </FormHelperText> */}
                                </div>
                              </>
                            ) : (
                              <div className="form-row  debitcard">
                                <div className="col-md-4 col-sm-6 form-group">
                                  <div className="debitcard-details">
                                    <div className="label ">First Name :</div>{" "}
                                    <div className="value">
                                      {values.firstName &&
                                        values.firstName?.charAt(0).toUpperCase() + values.firstName?.slice(1)}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4 col-sm-6  form-group">
                                  <div className="debitcard-details">
                                    <div className="label ">Middle Name :</div>{" "}
                                    <div className="value">
                                      {(values.middleName &&
                                        values.middleName?.charAt(0).toUpperCase() + values.middleName?.slice(1)) ||
                                        "--"}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4 col-sm-6 form-group">
                                  <div className="debitcard-details">
                                    <div className="label ">Last Name :</div>{" "}
                                    <div className="value">
                                      {values.lastName &&
                                        values.lastName?.charAt(0).toUpperCase() + values.lastName?.slice(1)}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4 col-sm-6 form-group">
                                  <div className="debitcard-details">
                                    <div className="label ">Routing Number :</div>{" "}
                                    <div className="value">{values.maskedRoutingNumber || values.routingnumber}</div>
                                  </div>
                                  <span> </span>
                                </div>

                                <div className="col-md-4 col-sm-6 form-group">
                                  <div className="debitcard-details">
                                    <div className="label ">Account Type:</div>{" "}
                                    <div className="value">
                                      {values?.accountType?.charAt(0)?.toUpperCase() + values?.accountType?.slice(1)}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4 col-sm-6 form-group">
                                  <div className="debitcard-details">
                                    <div className="label ">Account Number:</div>{" "}
                                    <div className="value">{values.maskedAccountNumber || values.accountNumber}</div>
                                  </div>
                                </div>

                                <div className="col-md-8 col-sm-6 form-group">
                                  <div className="debitcard-details">
                                    <div className="label ">Email Address: </div>{" "}
                                    <div className="value">{values.emailAddress}</div>
                                  </div>
                                </div>

                                <div className="col-md-4 col-sm-6 form-group">
                                  <div className="debitcard-details">
                                    <div className="label ">Phone Number:</div>{" "}
                                    <div className="value"> {values.phoneNumber}</div>
                                  </div>
                                </div>

                                <div className="col-md-12 form-group">
                                  <div className="debitcard-details">
                                    <div className="label ">Billing Address:</div>{" "}
                                    <div className="value">
                                      {" "}
                                      {values?.address1 &&
                                        `${values.address1?.charAt(0).toUpperCase() + values.address1?.slice(1)},${
                                          values.address2?.charAt(0).toUpperCase() + values.address2?.slice(1)
                                        },${values.city?.charAt(0).toUpperCase() + values.city?.slice(1)},${
                                          values.state?.charAt(0).toUpperCase() + values.state?.slice(1)
                                        },${values.country?.charAt(0).toUpperCase() + values.country?.slice(1)},${
                                          values.zipCode
                                        }`}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="agree-terms">
                            {this.props.payInFuture && (
                              <div className="row align-items-center">
                                <div className="col-md-8 col-sm-8">
                                  Please send payment schedule reminders through Email/Text.
                                </div>
                                <div className="col-md-4 col-sm-4">
                                  <FormControl component="fieldset" margin="normal">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="reminderEmail"
                                          defaultChecked
                                          value={values.reminderEmail}
                                          // checked={values.agreeToterms}
                                          onChange={handleChange}
                                          color="primary"
                                        />
                                      }
                                      label={<span>Email</span>}
                                    />
                                  </FormControl>
                                  <FormControl component="fieldset" margin="normal">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="reminderSms"
                                          value={values.reminderSms}
                                          // checked={values.agreeToEmailUsage}
                                          onChange={handleChange}
                                          color="primary"
                                        />
                                      }
                                      label={<span>Text</span>}
                                    />
                                  </FormControl>
                                </div>
                              </div>
                            )}

                            <div className="row align-items-center mb-2">
                              <div className="col-md-8 col-sm-8">Please send payment receipt through Email/Text.</div>
                              <div className="col-md-4 col-sm-4">
                                <FormControl component="fieldset" margin="normal">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="paymentEmail"
                                        defaultChecked
                                        value={values.paymentEmail}
                                        // checked={values.agreeToterms}
                                        onChange={handleChange}
                                        color="primary"
                                      />
                                    }
                                    label={<span>Email</span>}
                                  />
                                </FormControl>
                                <FormControl component="fieldset" margin="normal">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="paymentSms"
                                        value={values.paymentSms}
                                        // checked={values.agreeToEmailUsage}
                                        onChange={handleChange}
                                        color="primary"
                                      />
                                    }
                                    label={<span>Text</span>}
                                  />
                                </FormControl>
                              </div>
                            </div>
                            <div className="agree-terms">
                              <div className=" check-FormLabel authorization-input">
                                <FormControl fullWidth component="fieldset" margin="normal">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="termsconditions"
                                        checked={values.termsconditions}
                                        onChange={handleChange}
                                        value={values.termsconditions}
                                        color="primary"
                                      />
                                    }
                                    label={
                                      <span>
                                        <TermsAndConditions />
                                        <FormHelperText error name="termsconditions">
                                          {touched.termsconditions && errors.termsconditions ? (
                                            <div>{errors.termsconditions}</div>
                                          ) : null}
                                        </FormHelperText>
                                      </span>
                                    }
                                  />
                                </FormControl>
                              </div>
                              <div className="check-FormLabel authorization-input">
                                <FormControl fullWidth component="fieldset" margin="normal">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="agreeToEmailUsage"
                                        value={values.agreeToEmailUsage}
                                        checked={values.agreeToEmailUsage}
                                        onChange={handleChange}
                                        color="primary"
                                      />
                                    }
                                    label={
                                      <span>
                                        By checking this box,you agree that this is your personal email address and you
                                        authorise us to send you emails,call your phone, or contact you via an automated
                                        dialer,leave voicemails, and/or send text messages if necessary.
                                      </span>
                                    }
                                  />
                                  <FormHelperText error name="agreeToEmailUsage">
                                    {touched.agreeToEmailUsage && errors.agreeToEmailUsage ? (
                                      <div style={{ "margin-left": "34px" }}>{errors.agreeToEmailUsage}</div>
                                    ) : null}
                                  </FormHelperText>
                                </FormControl>
                              </div>
                              <div className=" check-FormLabel authorization-input">
                                <FormControl fullWidth component="fieldset" margin="normal">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="authorizeElectronicDebit"
                                        checked={values.authorizeElectronicDebit}
                                        onChange={handleChange}
                                        value={values.authorizeElectronicDebit}
                                        color="primary"
                                      />
                                    }
                                    label={
                                      <span>
                                        I authorize MED-1 Solutions to electronically debit my checking/savings account for
                                        the amount and term indicated above. I understand that electronic debits will be
                                        returned if there are insufficient funds in the account at the time of the debit. I
                                        also understand that if my payment is returned to MED-1 Solutions as NSF for any
                                        reason by the bank that I authorize MED-1 Solutions to initiate an additional and
                                        separate fee in accordance with Oregon Revised Statutes §30.701 for the returned
                                        check expenses. This authorization is to remain in full force and effect until MED-1
                                        Solutions has received written notification from me of its termination in such manner
                                        as to afford MED-1 Solutions a reasonable opportunity to act on it (3 business days).
                                      </span>
                                    }
                                  />
                                  <FormHelperText error name="authorizeElectronicDebit">
                                    {touched.authorizeElectronicDebit && errors.authorizeElectronicDebit ? (
                                      <div style={{ "margin-left": "34px" }}>{errors.authorizeElectronicDebit}</div>
                                    ) : null}
                                  </FormHelperText>
                                </FormControl>
                              </div>
                            </div>
                          </div>
                          <div className="proceed-cancel-buttons">
                            <button type="submit" className="primary-button themeing-buttons mx-1">
                              {this.props.payInFuture ? "Schedule" : "Pay"}
                            </button>
                            <button
                              type="button"
                              className="secondary-button mx-1"
                              onClick={() => this.props.history.goBack()}>
                              cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  }
}

export default EcheckCardContainer;
