import React, { useContext } from "react";
import { ThemeContext } from "../../../../themes/ThemeProvider";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import { adminResetPasswordData } from "../../../../action/AdminResetPasswordAction";
import { logoutUser } from "../../../../action/loginAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getLogin } from "../../../../reducer/loginReducer";
import AdminRpSettlementsComponent from "./RpSettlements";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
const RPSettlementContainer = (props) => {
  const { ReusableInputStyles } = useContext(ThemeContext);
  const classes = ReusableInputStyles();
  return (
    <div className="transaction-card" role="main">
      <div className="main-container-card">
        <div role="heading" aria-level="1" className="heading-style mb-3">
          <AccountBalanceWalletIcon className="mr-2" />
          <h2 className="heading-style"> RP Settlements</h2>
        </div>

        <div>
          <AdminRpSettlementsComponent props={props} classes={classes} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...getLogin(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      adminResetPasswordData: adminResetPasswordData,
      logoutUser: logoutUser,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RPSettlementContainer);
