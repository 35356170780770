import React from "react";
import UserName from "./UserName";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

const LoginContainer = () => {
  const history = useHistory();
  const { securityImage } = useSelector(({ securityImageReducer }) => securityImageReducer);
  return (
    <div>
      <UserName securityImage={securityImage} history={history} />
    </div>
  );
};

export default LoginContainer;
