import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage, getIn, Field } from "formik";
import * as Yup from "yup";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import {
  MenuItem,
  Select,
  FormControl,
  TextField,
  FormLabel,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import $ from "jquery";
import calendar from "../../../../assets/images/calendar.png";
import moment from "moment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import leftSideMenu from "../../../../components/admin_header/leftSideMenu/leftSideMenu";
import { requestInfo_PP } from "../../../../Common/CommonFn";
import { useHistory } from "react-router";
import {
  error,
  success,
  warning,
  warningNotification,
  errorNotification,
  successNotification,
} from "../../../../components/PP_Notification/PP_Notification";
import TermsAndConditions from "../../../../components/TermsAndConditions/TermsAndConditions";
import { Grid } from "@material-ui/core";
import { handleSuccess, ToastContainer, handleError } from "../../../../components/Notifications/Toast";
import ClearIcon from "@material-ui/icons/Clear";
import outstandingBalanceCalculate from "../../../../util/outstandingBalanceCalculation";
const dayOptions = [
  { key: "Monday", value: "Monday" },
  { key: "Tuesday", value: "Tuesday" },
  { key: "Wednesday", value: "Wednesday" },
  { key: "Thursday", value: "Thursday" },
  { key: "Friday", value: "Friday" },
  { key: "Saturday", value: "Saturday" },
  { key: "Sunday", value: "Sunday" },
];

const WeeklyForm = ({
  seedAccNum,
  fetchPlan,
  payplanService,
  handleClose,
  totalInstallments,
  setSuccess,
  creditCardData,
  eChequeData,
  getCardListData,
  getEchecqueListData,
  outstandingBalance,
  setPaymentTransactionData,
  page,
  creditCardValue,
  ppValues,
  frequencyType,
  partyid,
  validateStartDate,
  day,
  disableSave,
  setDisableSave,
  setShowForm,
  isLegal,
  setOverRide,
}) => {
  const [paymentMethod, setPaymentMethod] = useState({
    paymentMethod: "",
    showCreditCard: true,
    showEcheck: true,
  });
  const history = useHistory();
  const [installments, setInstallments] = useState([]);
  const [textData, setTextData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isPaymentOptions, setPaymentOptions] = useState(true);
  const [futureDate, setFutureDate] = useState(null);
  const [futureMonthDays, setFutureMonthDays] = useState(null);
  const [lastDayOfMonth, setLastDayOfMonth] = useState(moment().endOf("month").format("MM/DD/YYYY"));
  const [secondLastDayOfMonth, setSecondLastDayOfMonth] = useState(moment(lastDayOfMonth).subtract(1, "days"));
  const [thirdLastDayOfMonth, setThirdLastDayOfMonth] = useState(moment(lastDayOfMonth).subtract(2, "days"));
  let sampleAmount = outstandingBalanceCalculate(outstandingBalance, totalInstallments);
  const minAmount = sampleAmount.toFixed(2);
  const userData = JSON.parse(sessionStorage?.getItem("userData"));
  console.log(userData?.role[0]?.roleName);
  const intialvalues = {
    noOfInstallments:
      ppValues?.noOfInstallments || fetchPlan?.recurringPaymentType === "Weekly" ? fetchPlan?.noOfPayments : "none",
    installmentStartDate:
      ppValues?.installmentStartDate || fetchPlan?.recurringPaymentType === "Weekly"
        ? moment().toDate(fetchPlan?.startDate)
        : null,
    paymentAmount:
      ppValues?.paymentAmount || fetchPlan?.recurringPaymentType === "Weekly" ? fetchPlan?.recurringAmmount : "",
    paymentMethod: creditCardValue || "none",
    agreeToTerms: sessionStorage.getItem("currentUserRole") === "Admin" ? false : true,
    agreeToEmailUsage: sessionStorage.getItem("currentUserRole") === "Admin" ? false : true,
    reccuringPaymentId: fetchPlan?.reccuringPaymentId || null,
    paymentEmail:
      fetchPlan?.paymentOrders !== null && fetchPlan?.reccuringPaymentId
        ? fetchPlan?.paymentOrders[0]?.paymentEmail === 1
          ? true
          : false
        : true,
    reminderEmail:
      fetchPlan?.paymentOrders !== null && fetchPlan?.reccuringPaymentId
        ? fetchPlan?.paymentOrders[0]?.reminderEmail === 1
          ? true
          : false
        : true,
    paymentSms: fetchPlan?.paymentOrders !== null && fetchPlan?.paymentOrders[0]?.paymentSms === 1 ? true : false,
    reminderSms: fetchPlan?.paymentOrders !== null && fetchPlan?.paymentOrders[0]?.reminderSms === 1 ? true : false,
  };
  const yesterday = new Date(Date.now() - 86400000);
  const schema = Yup.object().shape({
    noOfInstallments: Yup.string().notOneOf(["none"], "Select a Valid Option").required("Required"),
    paymentAmount: Yup.number()
      .min(
        minAmount,
        <span>
          <b>$ {minAmount}</b> is the minimum amount
        </span>,
      )
      .max(
        outstandingBalance,
        <span>
          <b>$ {outstandingBalance.toFixed(2)}</b> is the maximum amount
        </span>,
      )
      .typeError("Please Enter Valid Amount")
      .required("Payment Amount is Required"),
    installmentStartDate: Yup.date()
      .nullable()
      .min(yesterday, "Past date is not allowed")
      .typeError("Invalid Date")
      .required("Installment Start Date is required"),

    paymentMethod: Yup.string().notOneOf(["none"], "Select a Valid Payment Method").required("Select a Payment Method"),
    agreeToTerms:
      sessionStorage.getItem("currentUserRole") === "Admin"
        ? ""
        : Yup.boolean().oneOf([true], "Please agree to terms and conditions to continue").required("Checkbox is required"),
  });

  useEffect(() => {
    var currentDate = moment().toDate();
    var futureMonth = moment(currentDate).add(1, "M");
    var futureMonthEnd = moment(futureMonth).endOf("month");
    var formattedFutureDate = moment(futureMonthEnd._d).format("MM/DD/YYYY");
    var daysInMonth = moment(formattedFutureDate).daysInMonth();
    setFutureMonthDays(daysInMonth);
    setFutureDate(formattedFutureDate);
  }, []);

  useEffect(() => {
    let allInstallments = [];
    [...Array(totalInstallments).keys()].map((i) => {
      return allInstallments.push({ key: i + 1, value: i + 1 });
    });
    setInstallments(allInstallments);
  }, [totalInstallments]);

  useEffect(() => {
    if (history?.location?.state?.saveDetails) {
      setShowForm(false);
      setLoader(true);
      let queryString = window.location.search.split("?");
      let [, seedAccountNumber] = queryString;
      let PassValues = {
        requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        recurringPayment: {
          // Id must be 0 for new one
          reccuringPaymentId: 0,
          recurringPaymentType: frequencyType,
          recurringAmmount: history?.location?.state?.ppValues?.paymentAmount,
          noOfPayments: history?.location?.state?.ppValues?.noOfInstallments,
          startDate: moment(history?.location?.state?.ppValues?.installmentStartDate).format("MM/DD/YYYY"),
          paymentInstrumentId: history?.location?.state?.instrumentId,
          masterAccountReferenceNumber: seedAccountNumber,
          payerPartyId: partyid,
        },
      };
      payplanService.savePayPlan(
        PassValues,
        1234,
        (res) => {
          if (res?.data?.status === "Success") {
            setLoader(false);
            setShowForm(false);
            setDisableSave(true);
            setPaymentTransactionData(res.data.body);
          } else {
            setLoader(false);
            setShowForm(true);
            handleError("Can't save Payment Arrangement  details");
          }
        },
        (error) => {
          handleError("Something went wrong! Please try again");
          setLoader(false);
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values, onSubmitProps) => {
    const { setFieldError } = onSubmitProps;
    if (values.paymentMethod === "echeck" || values.paymentMethod.split("&")[0] === "echeque") {
      if (values.paymentMethod.split("$")[1]) {
        let selectedECheck = "";
        selectedECheck = eChequeData?.achAccount?.filter((item) => {
          return item.paymentInstrument?.paymentInstrumentId === Number(values.paymentMethod.split("$")[1]);
        });
        history.push({
          pathname: sessionStorage.getItem("currentUserRole") === "Admin" ? "/pa/add-echeck" : "/home/add-echeck",
          state: { seedAccNum, page, values, frequencyType, selectedECheck, isPaymentOptions },
        });
      } else {
        history.push({
          pathname: sessionStorage.getItem("currentUserRole") === "Admin" ? "/pa/add-echeck" : "/home/add-echeck",
          state: { seedAccNum, page, values, frequencyType, isPaymentOptions },
        });
      }
    } else {
      if (values.paymentMethod.split("$")[1]) {
        let selectedCard = "";
        selectedCard = creditCardData?.cardAccount?.filter((item) => {
          return item.paymentInstrument?.paymentInstrumentId === Number(values.paymentMethod.split("$")[1]);
        });
        history.push({
          pathname: sessionStorage.getItem("currentUserRole") === "Admin" ? "/pa/credit-card" : "/home/credit-card",
          state: { seedAccNum, page, values, frequencyType, selectedCard, isPaymentOptions },
        });
      } else {
        history.push({
          pathname: sessionStorage.getItem("currentUserRole") === "Admin" ? "/pa/credit-card" : "/home/credit-card",
          state: { seedAccNum, page, values, frequencyType, isPaymentOptions },
        });
      }
    }
  };

  return (
    <div className="payplan-forms">
      <div
        className="modal"
        id="termsConditions"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-width" role="document"></div>
      </div>
      <Formik initialValues={intialvalues} onSubmit={onSubmit} validationSchema={schema} enableReinitialize>
        {(props) => {
          // eslint-disable-next-line no-unused-vars
          const { values, touched, error, handleChange, errors, handleBlur, setFieldValue } = props;
          return (
            <Form id="profile-page" className="recurring-plans input-field" noValidate="noValidate" autocomplete="off">
              {" "}
              <div className="form-row payplan-options">
                <div className="col-md-6 col-lg-4 col-xl-3 col-sm-6 form-group">
                  <FormControl variant="outlined" className="w-100" fullWidth>
                    <label htmlFor="numberOfInstallments" className="form-label required">
                      Select Number of Installments
                    </label>
                    <Select
                      fullWidth
                      name="noOfInstallments"
                      id="numberOfInstallments"
                      onBlur={handleBlur}
                      className="form-select form-control"
                      onChange={(e) => {
                        handleChange(e);
                        let sampleAmount = outstandingBalanceCalculate(outstandingBalance, e.target.value);

                        setFieldValue("paymentAmount", sampleAmount.toFixed(2));
                      }}
                      value={values.noOfInstallments <= totalInstallments ? values["noOfInstallments"] : "none"}
                      error={Boolean(getIn(touched, "noOfInstallments") && getIn(errors, "noOfInstallments"))}>
                      <MenuItem value="none" key="none" disabled>
                        Select Installment
                      </MenuItem>
                      {installments.map((option) => {
                        return (
                          <MenuItem value={option.value} key={option.key}>
                            {option.value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormHelperText error>
                    {getIn(touched, "noOfInstallments") &&
                      getIn(errors, "noOfInstallments") &&
                      getIn(errors, "noOfInstallments")}
                  </FormHelperText>
                </div>
                <div className="col-md-6 col-lg-4 col-xl-3 col-sm-6 form-group">
                  <FormControl variant="outlined" className="w-100" fullWidth>
                    <label htmlFor="paymentAmount" className="required">
                      Payment Amount
                    </label>

                    <OutlinedInput
                      type="number"
                      className="form-control security-input"
                      name="paymentAmount"
                      autocomplete="new-password"
                      placeholder="Please Enter Amount"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        // handleChange(e);
                        const amount = Number(e.target.value).toLocaleString(undefined, { minimumFractionDigits: 2 });
                        setFieldValue(
                          (values.paymentAmount =
                            e.target.value.split(".")[1]?.length >= 3 ? Number(e.target.value).toFixed(2) : e.target.value),
                        );
                        setFieldValue("noOfInstallments", Math.ceil(outstandingBalance / e.target.value));
                      }}
                      value={values["paymentAmount"]}
                      aria-label="text-field"
                      fullWidth
                      error={Boolean(getIn(touched, "paymentAmount") && getIn(errors, "paymentAmount"))}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}></OutlinedInput>
                  </FormControl>
                  <FormHelperText error>
                    {getIn(touched, "paymentAmount") && getIn(errors, "paymentAmount") && getIn(errors, "paymentAmount")}
                  </FormHelperText>
                </div>
                <div className="col-md-6 col-lg-4 col-xl-3 col-sm-6 form-group">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid container className="date-picker">
                      <label htmlFor="dateTwo" className="form-label w-100 required">
                        Installment Start Date
                      </label>
                      <DatePicker
                        format="M/d/y"
                        monthPlaceholder="MM"
                        returnValue="end"
                        calendarIcon={<img src={calendar} alt="calendar" width="22px" />}
                        name="installmentStartDate"
                        minDate={moment().toDate()}
                        maxDate={
                          moment(lastDayOfMonth).isSame(moment().toDate(), "day") ||
                          moment(secondLastDayOfMonth).isSame(moment().toDate(), "day") ||
                          (moment(thirdLastDayOfMonth).isSame(moment().toDate(), "day") &&
                            (futureMonthDays === 28 || futureMonthDays === 29))
                            ? moment(futureDate).endOf("month").toDate()
                            : moment().daysInMonth() === 30
                            ? moment().add(29, "days").toDate()
                            : moment().daysInMonth() === 29
                            ? moment().add(28, "days").toDate()
                            : moment().daysInMonth() === 28
                            ? moment().add(27, "days").toDate()
                            : moment().add(30, "days").toDate()
                        }
                        dayPlaceholder="DD"
                        yearPlaceholder="YYYY"
                        className="visibledate"
                        value={values.installmentStartDate}
                        autoOk
                        onChange={(value) => {
                          setFieldValue("installmentStartDate", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={null}
                            margin="dense"
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "MM/DD/YYYY",
                            }}
                            error={Boolean(getIn(touched, "installmentStartDate") && getIn(errors, "installmentStartDate"))}
                          />
                        )}
                        InputProps={{
                          startAdornment: (
                            <IconButton
                              position="end"
                              onClick={() => setFieldValue("installmentStartDate", null)}
                              style={{ order: 1, padding: 0 }}>
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          ),
                        }}
                        InputAdornmentProps={{
                          position: "end",
                          style: { order: 2 },
                        }}
                      />
                    </Grid>
                  </LocalizationProvider>
                  <FormHelperText error>
                    {getIn(touched, "installmentStartDate") &&
                      getIn(errors, "installmentStartDate") &&
                      getIn(errors, "installmentStartDate")}
                  </FormHelperText>
                </div>
                <div className="col-md-6 col-lg-4 col-xl-3 col-sm-6 form-group">
                  <FormControl variant="outlined" className="w-100" fullWidth>
                    <label htmlFor="paymentMethod" className="form-label required">
                      Payment Method
                    </label>
                    <Select
                      autocomplete="off"
                      id="paymentMethod"
                      name="paymentMethod"
                      placeholder="Payment Method"
                      component="select"
                      onBlur={handleBlur}
                      value={values.paymentMethod}
                      className={`form-control ${touched.paymentMethod && errors.paymentMethod ? "is-invalid" : ""}`}
                      onChange={handleChange}
                      error={Boolean(getIn(touched, "paymentMethod") && getIn(errors, "paymentMethod"))}>
                      <MenuItem value="none" key="none" disabled>
                        Select Payment Method
                      </MenuItem>

                      {getCardListData(creditCardData)}
                      {getEchecqueListData(eChequeData)}
                      {paymentMethod.showCreditCard === true ? (
                        <MenuItem value="creditCard">Debit/Credit Card</MenuItem>
                      ) : (
                        ""
                      )}
                      {paymentMethod.showEcheck === true ? <MenuItem value="echeck">eCheck</MenuItem> : ""}
                    </Select>
                  </FormControl>
                  <FormHelperText error>
                    {getIn(touched, "paymentMethod") && getIn(errors, "paymentMethod") && getIn(errors, "paymentMethod")}
                  </FormHelperText>
                </div>
              </div>
              <div className="agree-terms">
                <div className="row align-items-center">
                  <div className="col-md-8 col-sm-8">Please send payment schedule reminders through Email/Text.</div>
                  <div className="col-md-4 col-sm-4">
                    <FormControl component="fieldset">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="reminderEmail"
                            defaultChecked
                            value={values.reminderEmail}
                            checked={values.reminderEmail}
                            onChange={handleChange}
                            color="primary"
                          />
                        }
                        label={<span>Email</span>}
                      />
                    </FormControl>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="reminderSms"
                            value={values.reminderSms}
                            checked={values.reminderSms}
                            onChange={handleChange}
                            color="primary"
                          />
                        }
                        label={<span>Text</span>}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="row align-items-center mb-2">
                  <div className="col-md-8 col-sm-8 ">Please send payment receipt through Email/Text.</div>
                  <div className="col-md-4 col-sm-4">
                    <FormControl component="fieldset">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="paymentEmail"
                            defaultChecked
                            value={values.paymentEmail}
                            checked={values.paymentEmail}
                            onChange={handleChange}
                            color="primary"
                          />
                        }
                        label={<span>Email</span>}
                      />
                    </FormControl>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="paymentSms"
                            value={values.paymentSms}
                            checked={values.paymentSms}
                            onChange={handleChange}
                            color="primary"
                          />
                        }
                        label={<span>Text</span>}
                      />
                    </FormControl>
                  </div>
                </div>

                <div className=" check-FormLabel authorization-input">
                  <FormControl fullWidth component="fieldset" margin="normal">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="agreeToTerms"
                          value={values.agreeToTerms}
                          checked={values.agreeToTerms}
                          onChange={handleChange}
                          color="primary"
                        />
                      }
                      label={
                        <span>
                          <TermsAndConditions />
                          <FormHelperText error>
                            {getIn(touched, "agreeToTerms") &&
                              getIn(errors, "agreeToTerms") &&
                              getIn(errors, "agreeToTerms")}
                          </FormHelperText>
                        </span>
                      }
                    />
                  </FormControl>
                </div>
                <div className="check-FormLabel authorization-input">
                  <FormControl fullWidth component="fieldset" margin="normal">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="agreeToEmailUsage"
                          value={values.agreeToEmailUsage}
                          checked={values.agreeToEmailUsage}
                          onChange={handleChange}
                          color="primary"
                        />
                      }
                      label={
                        <span>
                          By checking this box, you acknowledge that this is your personal email address and you authorize us
                          to send you emails, call your phone, or contact you via an automated dialer, leave voicemails,
                          and/or send text messages if necessary.
                        </span>
                      }
                    />
                  </FormControl>
                  <FormHelperText error>
                    {getIn(touched, "agreeToEmailUsage") &&
                      getIn(errors, "agreeToEmailUsage") &&
                      getIn(errors, "agreeToEmailUsage")}
                  </FormHelperText>
                </div>
              </div>
              <div className="cancel-save-btn">
                <button
                  className="btn secondary-button mr-1"
                  onClick={(e) => {
                    page === "QuickpayDB"
                      ? history.push("/payment-outstanding")
                      : page === "quickPay"
                      ? history.push(sessionStorage.getItem("currentUserRole") === "Admin" ? "/pa/billing" : "/home/billing")
                      : history.push(
                          sessionStorage.getItem("currentUserRole") === "Admin"
                            ? `/pa/payment-plan-details?${history?.location?.search?.split("=")[1]}`
                            : `/home/payment-plan-details?${history?.location?.search?.split("=")[1]}`,
                        );
                  }}
                  type="button">
                  Cancel
                </button>
                {(userData?.role[0]?.roleName === "PP Super Admin" ||
                  (isLegal && sessionStorage?.getItem("currentUserRole") === "Admin")) && (
                  <button
                    className="btn btn-info ml-2"
                    onClick={(e) => {
                      setOverRide(true);
                    }}
                    type="button">
                    Override Plan
                  </button>
                )}
                <button type="submit" className="btn primary-button themeing-buttons ml-1" disabled={disableSave}>
                  Save
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default WeeklyForm;
