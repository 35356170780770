import { useEffect } from "react";
import { UpdatePortalUsage } from "../util/purlFunctions";

const UpdateTrackingPage = ({ path }) => {
  useEffect(() => {
    if (window.location.pathname !== "/" && sessionStorage.getItem("billingpayerdata")) {
      var accRefNo = JSON.parse(sessionStorage.getItem("payerinfo"))?.accountReferenceNumber;
      var partyId = sessionStorage.getItem("Userpartyid")
        ? JSON.parse(sessionStorage.getItem("Userpartyid"))
        : JSON.parse(sessionStorage.getItem("verifypayer"))?.partyId;
      var portalUsageId = JSON.parse(sessionStorage.getItem("portalUsageId"));
      const accountRefAutologin = JSON.parse(sessionStorage.getItem("accountReferenceNumber"));
      const campaignCode = sessionStorage.getItem("campaignCode");
      if (accRefNo || accountRefAutologin) {
        let passValuesUPU = {
          accessMode: "", //Non-mandatory
          campaignCode: campaignCode || "", //Non-mandatory
          lastPageAccessed: path.includes("/") ? path.split("/")[path.split("/").length - 1] : path, //mandatory
          medAccountNumber: accRefNo || accountRefAutologin.split("/")[1], //Non-mandatory
          partyId: partyId, //mandatory
          portalUsageId: portalUsageId, //Mandatory(Pass the same id which is return at the time of create Portal Usage)
        };
        UpdatePortalUsage(passValuesUPU, (res) => console.log(res, "in update every portal api"));
      }
    }
  }, [path]);

  return null;
};

export default UpdateTrackingPage;
