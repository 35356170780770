import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
// import { login, logoutUser } from "../../action/loginAction";
// import { getImage } from "../../action/securityImageAction";
import { bindActionCreators } from "redux";
// import { warningNotification, warning } from "../Admin_Notification/Admin_Notification";
import { I18nextProvider } from "react-i18next";
import { Translation } from "react-i18next";
// import i18n from "../../i18n";
import SpinnerPage from "../../../Common/PP_loader";
import { loginService } from "../../../core_services/adminloginService";
import { getLogin } from "../../../reducer/loginReducer";
import { handleWarning } from "../../../components/Notifications/Toast";
import i18n from "../../../i18n";

export class AutoLoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trylogin: true,
    };
  }

  componentDidMount() {
    const values = this.props.location.search;

   

    loginService.AutoLogin(
      { key: values.split("key=")[1] },
      (res) => {
       
        this.setState({
          trylogin: true,
        });
        if (res["data"].responseInfo.status === "SUCCESSFUL") {
          // success(<Translation>
          this.setState({
            trylogin: false,
          });
          //     {
          //         (t, { i18n }) => <label >{t("getDetail_SucessPayerMsg")}</label>
          //     }
          // </Translation>, successNotification);
          //this.setState(this.initialState);
          //this._LoadDataTable();
         
     
          sessionStorage.setItem("campaignCode", res.data.campaignCode);
          sessionStorage.setItem("verifypayer", JSON.stringify(res.data.payer));
          sessionStorage.setItem(
            "accountReferenceNumber",
            JSON.stringify(res.data?.billGroups[0]?.bills[0]?.accountReferenceNumber),
          );
          sessionStorage.setItem("Verifieduser", res.data.payer.firstName + " " + res.data.payer.lastName);
          sessionStorage.setItem("payerinfo", JSON.stringify(res.data.payer));
          this.props.history.push("/payment-outstanding");
        } else if (res["data"].responseInfo.status === "FAILED") {
          this.setState({
            trylogin: false,
          });
          handleWarning("Please enter valid details!");
          // warning(

          //       <Translation>
          //       {
          //           (t, { i18n }) => <span>{t("wrongDetail_warningMsgWrong")}
          //             </span>
          //       }
          //   </Translation> ,
          //       warningNotification

          //   );
          // setTimeout(function(){ window.location.reload() }, 2000);
        } else {
          handleWarning("Please enter valid details!");
          // warning(

          //     <Translation>
          //         {
          //             (t, { i18n }) => <label>{t("wrongDetail_warningMsgWrong")}</label>
          //         }
          //     </Translation>,
          //     warningNotification

          // );
          // setTimeout(function(){ window.location.reload() }, 2000);
        }
      },
      (error) => {
        console.log(error);
      },
    );

    loginService.TokenUser((resToken) => {
      if (resToken["status"] === "success") {
        sessionStorage.setItem("jwt-token", resToken.responseObject);
        // sessionStorage.setItem("jwt-token", res.headers.token);
      }
    });
  }

  render() {
    return (
      // <I18nextProvider i18n={i18n}>
      <Formik
        initialValues={{
          username: "",
          password: "",
          handleContinueButton: "",
          validImage: false,
          seurityImage: "",
          showHideUsername: true,
          showPassword: false,
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string().required("Please enter the Username").max(100, "Must be less than 100 characters"),
          password: Yup.string().required("Please enter the Password"),
          validImage: Yup.boolean().oneOf([true], "You must accept the Security Image"),
        })}
        onSubmit={(values) => {}}
        render={({ errors, touched, handleSubmit, handleChange, isValid, values, setFieldValue }) => (
          <React.Fragment>{this.state.trylogin === true ? <SpinnerPage /> : ""}</React.Fragment>
        )}
      />
      //  </I18nextProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  ...getLogin(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // login: login,
      // getImage: getImage,
      // logoutUser: logoutUser,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AutoLoginPage);
