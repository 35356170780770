/* eslint-disable react/jsx-pascal-case */
import React from "react";
// import AutoLoginPage from "../../components/login/autoLogin";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import Admin_Footer from "../../components/admin_footer/footer"
import Admin_Footer from "../../components/admin_footer/footer";
// import FooterContainer from "../../containers/footer/footerContainer";
// import WhiteLableContainer from "../../containers/whiteLable/whiteLableContainer";
// import WhiteLableContainer from "../../containers/whiteLable/whiteLableContainer";
// import TrackingUSer from "containers/TrackingUser";
// import UpdateTrackingPage from "containers/UpdateTrackingPage";
import WhiteLableContainer from "../whiteLable/whiteLableContainer";
import { AutoLoginPage } from "../../routes/Auth/SignIn/autoLogin";
import { Link } from "react-router-dom";
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export class AutoLoginContainer extends React.Component {
  componentDidMount() {
    document.title = "MED-1 Quick Pay";
    const queryString = window.location.search;
    const urlParameter = new URLSearchParams(queryString);

    var businessid = "2";
    var bussid = urlParameter.get("buid");
    if (queryString && bussid) {
      sessionStorage.setItem("buid", bussid);
    } else {
      sessionStorage.setItem("buid", businessid);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-wrapper">
          <div className="admin-logo">
            <WhiteLableContainer></WhiteLableContainer>
          </div>
          <p style={{ fontSize: "22px" }}>Please wait we are retrieving details...</p>
          <AutoLoginPage {...this.props}></AutoLoginPage>

          <div className="copy-right">
            <p>
              Need Help?{" "}
              <Link to="/contact-us" aria-label="contact-us-link">
                Contact Us
              </Link>
            </p>
            <p>Copyright {new Date().getFullYear()}. All Rights Reserved.</p>
            <p>
              WE ARE DEBT COLLECTORS. THIS IS AN ATTEMPT TO COLLECT A DEBT. ANY INFORMATION OBTAINED WILL BE USED FOR THAT
              PURPOSE.
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AutoLoginContainer);
