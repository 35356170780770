import { Global_var } from "../global/global_var";
import RestDataSource from "./restdatasource";
import { loginService } from "./loginService";
// import { checkUserSession } from "../common/userSessionExpiry";

export const settlementService = {
  saveSettlementOption,
  getSettlementOptionInfo,
  sendSettlementOptionEmailRequest,
  trackSettlementOption,
  settlementOptionAmountValidate,
  getSettlementOptionStatus,
  rejectProposed,
  settlementOptionSaveRequest,
};

function saveSettlementOption(userData, userId, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_SAVE_SETELMENT_OPTION;
      return new RestDataSource(url, userId, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  }, fnError);
}

function getSettlementOptionInfo(userData, userId, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_SETELMENT_OPTION_INFO;
      return new RestDataSource(url, userId, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  }, fnError);
}

function sendSettlementOptionEmailRequest(userData, userId, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_SETELMENT_OPTION_EMAIL_REQUEST;
      return new RestDataSource(url, userId, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  }, fnError);
}

function trackSettlementOption(userData, userId, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_SETELMENT_OPTION_TRACKING;
      return new RestDataSource(url, userId, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  }, fnError);
}

function settlementOptionAmountValidate(userData, userId, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_VALIDATE_SETELMENT_OPTION_AMOUNT;
      return new RestDataSource(url, userId, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  }, fnError);
}

function getSettlementOptionStatus(userData, userId, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_SETELMENT_OPTION_STATUS;
      return new RestDataSource(url, userId, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  }, fnError);
}

function rejectProposed(userData, userId, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_REJECT_PROPOSED;
      return new RestDataSource(url, userId, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  }, fnError);
}

// new api for settlement save

function settlementOptionSaveRequest(userData, userId, fn, fnError) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_SAVE_SETTLEMENT_REQUEST;
      return new RestDataSource(url, userId, fn).Store(userData, (res) => {
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.headers.token);
          }
          fn(res);
        }
      });
    }
  }, fnError);
}
