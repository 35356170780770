import React, { useEffect } from "react";
import { Formik, Form, ErrorMessage, getIn } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { I18nextProvider } from "react-i18next";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import i18n from "../../../../../i18n";
import { Button, TextField, FormHelperText, FormControlLabel, FormControl, Checkbox } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import { clientMaintainanceService } from "../../../../../admin_services/clientMaintenanceService";
import { handleError, handleSuccess, handleWarning } from "../../../../../components/Notifications/Toast";
import { AddClientMainatinance } from "../../../../../action/ClientMaintenance";
import moment from "moment";
import { getHospitalList } from "../../../../../action/ClientManagementAction";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { Grid } from "@material-ui/core";
import SpinnerPage from "../../../../../Common/PP_loader";

const ClientMaintenancePopup = ({
  clientMaintenancePopUpData,
  isEdit,
  handleClose,
  title,
  HospitalData,
  buId,
  setHostipalList,
  setIsUpdate,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isInfo, setIsInfo] = React.useState(null);
  let initialValues = {
    hospitalName: isEdit
      ? clientMaintenancePopUpData?.hospitalName
      : HospitalData?.hospitalName
      ? HospitalData?.hospitalName
      : "",

    code: isEdit ? clientMaintenancePopUpData?.hospitalCode : HospitalData?.hospitalCode ? HospitalData?.hospitalCode : "",
    isActive: isEdit ? clientMaintenancePopUpData?.isActive === 1 && true : HospitalData?.isActive === 1 ? true : false,
    startDate: isEdit
      ? clientMaintenancePopUpData?.activationDate
      : HospitalData?.activationDate
      ? new Date(HospitalData?.activationDate)
      : null,
    isParentHospital: HospitalData && HospitalData?.isParentHospital ? HospitalData?.isParentHospital : "",
  };
  const dispatch = useDispatch();
  const validation = Yup.object().shape({
    code: Yup.string().required("Hospital Code is required"),
    startDate: Yup.date().typeError("Please enter a valid date").required("Activation  Date is required").nullable(),
    hospitalName: Yup.string().required("Hospital Name is required"),
    // isActive: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),
  });

  useEffect(() => {
    if (isEdit && isInfo === false && clientMaintenancePopUpData?.isActive === 1) {
      setIsLoading(true);
      clientMaintainanceService.isActiveMsg(
        {
          buId: buId,
          isActive: 0,
        },
        (res) => {
          setIsLoading(false);
          if (res?.data?.status === "SUCCESSFUL") {
            res.data?.responseMessage?.length > 0 && handleSuccess(res.data?.responseMessage[0]);
          } else if (res?.data?.status === "FAILED") {
            res.data?.responseMessage?.length > 0 && handleWarning(res.data?.responseMessage[0]);
          } else {
            handleError("Something went wrong");
          }
        },
      );
    }
  }, [isInfo, isEdit, clientMaintenancePopUpData, buId]);

  const handleSubmit = (values, onSubmitProps) => {
    const { setFieldError } = onSubmitProps;
    if (!values.startDate) {
      setFieldError("startDate", "Please Enter a Valid Activation Date");
    } else {
      setIsLoading(true);

      dispatch(
        AddClientMainatinance(
          {
            buId: buId ? buId : 0,
            hospitalName: values?.hospitalName ? values?.hospitalName : "",
            hospitalCode: values?.code ? values?.code : "",
            isActive: values?.isActive && values?.isActive === true ? 1 : 0,
            startDate: values?.startDate ? moment(values?.startDate).format("DD/MM/YYYY HH:MM:SS") : "",
            isParentHospital: values?.isParentHospital && values?.isParentHospital === true ? 1 : 0,
          },
          (res) => {
            setIsLoading(false);
            if (res.data?.status === "SUCCESSFUL") {
              setIsUpdate(true);
              handleClose();
              handleSuccess(res?.data?.responseMessage[0]);
              dispatch(
                getHospitalList((res) => {
                  if (res.status === 200) {
                    setHostipalList(res.data.hospitalDetails);
                  } else {
                    handleError("Something went wrong");
                  }
                }),
              );
            } else if (res.data?.status === "FAILED") {
              res.data?.responseMessage?.length > 0 && handleError(res.data?.responseMessage?.[0]);
            } else {
              handleError(res.data?.responseMessage?.[0]);
            }
          },
        ),
      );
    }
  };

  return (
    <I18nextProvider i18n={i18n}>
      <React.Fragment>
        {isLoading && <SpinnerPage />}
        <Formik enableReinitialize initialValues={initialValues} validationSchema={validation} onSubmit={handleSubmit}>
          {({ touched, errors, setFieldValue, values, resetForm, handleBlur, handleChange }) => (
            <Form autocomplete="new-password" className="input-field">
              <div>
                <div className="form-row align-items-center">
                  <div className="col-md-6 col-sm-6 form-group">
                    <label htmlFor="code" className="required">
                      Hospital Code
                    </label>
                    <OutlinedInput
                      type="text"
                      id="code"
                      name="code"
                      value={values.code}
                      placeholder="Enter  Hospital Code"
                      onInput={(e) => {}}
                      error={getIn(touched, "code") && getIn(errors, "code")}
                      className={`form-control admin-select-field ${touched.code && errors.code ? "is-invalid" : ""}`}
                      onChange={(event) => {
                        setFieldValue("code", event.target.value);
                      }}
                    />
                    <ErrorMessage component="div" name="code" className="error-text" />
                  </div>

                  <div className="col-md-6 col-sm-6 form-group">
                    <label htmlFor="hospitalName" className="required">
                      Hospital Name
                    </label>
                    <OutlinedInput
                      type="text"
                      id="hospitalName"
                      name={"hospitalName"}
                      autocomplete="new-password"
                      error={getIn(touched, "hospitalName") && getIn(errors, "hospitalName")}
                      value={values.hospitalName}
                      placeholder="Enter Hospital Name"
                      className={`form-control admin-select-field ${
                        touched.hospitalName && errors.hospitalName ? "is-invalid" : ""
                      }`}
                      onChange={(event) => {
                        setFieldValue("hospitalName", event.target.value);
                      }}
                    />
                    <ErrorMessage component="div" name="hospitalName" className="error-text" />
                  </div>

                  <div className="col-md-6 col-sm-6 form-group input-field">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Grid container className="date-picker">
                        <label htmlFor="startDate" className="required">
                          Activation Date
                        </label>
                        <DatePicker
                          // disableFuture
                          name="startDate"
                          autoOk
                          value={values.startDate}
                          onChange={(val) => setFieldValue("startDate", val)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              helperText={null}
                              margin="dense"
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "MM/DD/YYYY",
                              }}
                              error={getIn(touched, "startDate") && getIn(errors, "startDate")}
                              // className={`form-control admin-select-field ${
                              //   touched.startDate && errors.startDate ? "is-invalid" : ""
                              // }`}
                            />
                          )}
                        />
                      </Grid>

                      <FormHelperText error name="startDate">
                        {touched.startDate && errors.startDate ? <div>{errors.startDate}</div> : null}
                      </FormHelperText>
                      {/* <ErrorMessage component="div" name="startDate" className="error-text" /> */}
                    </LocalizationProvider>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <FormControl fullWidth component="fieldset" margin="normal">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="isActive"
                            value={values.isActive}
                            checked={values.isActive}
                            onClick={(e) => {
                              setFieldValue("isActive", e.target.checked);
                              setIsInfo(e.target.checked);
                            }}
                            color="primary"
                          />
                        }
                        label={"Is Active"}
                      />
                    </FormControl>
                    <FormHelperText error>
                      {getIn(touched, "isActive") && getIn(errors, "isActive") && getIn(errors, "isActive")}
                    </FormHelperText>
                  </div>
                </div>
                <DialogActions>
                  <Button type="submit" className="primary-button themeing-buttons">
                    Submit
                  </Button>
                  <Button className="secondary-button" variant="outlined" onClick={() => resetForm(initialValues)}>
                    Reset
                  </Button>
                </DialogActions>
                {/* <div className="d-flex justify-content-center my-2">
                  <Translation>
                    {(t, { i18n }) => (
                      <LoginButton type="submit" size="small" className="change-role-buttons">
                        Submit
                      </LoginButton>
                    )}
                  </Translation>
                  <Translation>
                    {(t, { i18n }) => (
                      <LoginButton size="small" className="change-role-buttons" onClick={() => resetForm(initialValues)}>
                        Reset
                      </LoginButton>
                    )}
                  </Translation>
                </div> */}
              </div>{" "}
            </Form>
          )}
        </Formik>
        {/* <ToastContainer /> */}
      </React.Fragment>
    </I18nextProvider>
  );
};

export default ClientMaintenancePopup;
