import React, { Component } from "react";
import { bindActionCreators } from "redux";
import PP_Transaction_Successful from "./PP_Transaction_Successful";
import { connect } from "react-redux";
import { getTransactionDataList, getTransactionDataListPending } from "../../../../reducer/pp_transactionSuccessReducer";

import { fetchTransactionDataSuccess, fetchTransactionData } from "../../../../action/pp_TransactionSuccessAction";
import { requestInfo_PP } from "../../../../Common/CommonFn";
import UpdateTrackingPage from "../../../../containers/UpdateTrackingPage";
const mapStateToProps = (state) => ({
  paymentSuccessData: getTransactionDataList(state.transactionSuccessReducer),
  pending: getTransactionDataListPending(state.transactionSuccessReducer),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchTransactionDataSuccess: fetchTransactionDataSuccess,
      fetchTransactionData: fetchTransactionData,
    },
    dispatch,
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const PP_TransactionSuccessContainer = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
    }
    componentWillMount() {
      // sessionStorage.setItem("screen","Thankyou");
    }
    componentDidMount() {
      // sessionStorage.setItem("screen","Thankyou");

      var transactiondata = JSON.parse(sessionStorage.getItem("transactiondata"));

      var userinfo = JSON.parse(sessionStorage.getItem("verifypayer"));
     
      var partyid = userinfo.partyId;

      const jtiToken =
        sessionStorage.getItem("jti-token") === null
          ? sessionStorage.getItem("jwt-token")
          : sessionStorage.getItem("jti-token");
      const Token = sessionStorage.getItem("jwt-token") === null ? "" : sessionStorage.getItem("jwt-token");
      var PassValues = {
        // "requestInfo": {
        //   "applicationId": "11102",
        //   "buId": "2",
        //   "subBuId": "1",
        //   "action": "",
        //   "issuer": "epi",
        //   "userlogin": "shantesh@privacydatasystems.com",
        //   "environment": "dev",
        //   "userId": "1234",
        //   "token": Token,
        //   "jtitoken": jtiToken

        // },
        requestInfo: requestInfo_PP(Token, jtiToken),
        receiptSearch: {
          //"paymentGatewayTxnRefNo": sessionStorage.getItem("transactionId"),
          paymentTxnId: sessionStorage.getItem("transactionId"),
          contactPartyId: transactiondata.paymentOrder.contactPartyId,
          payerPartyId: partyid,
          // "paymentTxnId": 8,
          // "contactPartyId": 15,
          // "payerPartyId": 10
        },
      };

      const UserId = 1234;
      const { fetchTransactionData } = this.props;
      fetchTransactionData(PassValues, UserId);

      
    }
    render() {
      const { paymentSuccessData } = this.props;
      return (
        <div id="outer-container">
          <React.Fragment>
            <main id="page-wrap">
              {/* {<Header {...this.props} />} */}
              <div id="slidepagearea" className="contentarea">
                <PP_Transaction_Successful {...this.props} paymentSuccessData={paymentSuccessData} />
              </div>
            </main>
            {/* {<PP_Footer />}
            <TrackingUSer /> */}
            <UpdateTrackingPage path={window.location.href.slice(22)} />
          </React.Fragment>
        </div>
      );
    }
  },
);

export default PP_TransactionSuccessContainer;
