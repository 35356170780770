import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core";

const DialogContent = withStyles((theme) => ({
  root: {
    padding: "20px 10px 10px 15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px !important",
    },
  },
}))(MuiDialogContent);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {},
  heading: {
    borderBottom: "1px solid #d3d3d3",
    padding: 10,
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div className={classes.heading}>{children}</div>
    </MuiDialogTitle>
  );
});

const ConfirmationDialog = ({
  open,
  handleClose,
  title,
  handleSubmit,
  handleCancel,
  billData,
  children,
  noActions,
  disableBackDropOnClick,
}) => {
  
  return (
    <Dialog
      disableBackdropClick={disableBackDropOnClick}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      {title && (
        <DialogTitle id="alert-dialog-title" class="message-box">
          {title}
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      {!title && !noActions && (
        <DialogActions>
          <button onClick={handleSubmit} type="button" className="btn themeing-buttons">
            Yes
          </button>
          <button className="btn btn-info" type="button" onClick={handleCancel}>
            No
          </button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ConfirmationDialog;
