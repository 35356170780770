import { UnlockUserService } from "../services/unlockUserService";

export const GET_USER_UNLOCK_SUCCESS = "GET_USER_UNLOCK_SUCCESS";

export function UnlockUserSuccess(userData) {
  return {
    type: GET_USER_UNLOCK_SUCCESS,
    payload: userData,
  };
}

export function unlockUserData(userData, fn) {
  return (dispatch) => {
    dispatch({
      type: GET_USER_UNLOCK_SUCCESS,
      payload: userData,
    });
    UnlockUserService.getUnlockUser(userData, (res) => fn(res));
  };
}
