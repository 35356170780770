export const Global_var = {
  //Client Machine

  // BASEURL: "https://uatservices.pdsnew.com",
  BASEURL: (function () {
    if (window.location.hostname === "cpdevpp.pdsnew.com") return "https://cpdevcoreservices.pdsnew.com";
    else if (window.location.hostname === "uatpp.pdsnew.com") return "https://uatservices.pdsnew.com";
    else if (window.location.hostname === "stagingpp2.pdsnew.com") return "https://stagingservices.pdsnew.com";
    else if (window.location.hostname === "med1solutions.pdsnew.com") return "https://prodservices.pdsnew.com";
    else return "https://testcoreservices.pdsnew.com";
  })(),

  URL_LOGIN: "/thor-authentication/api/v1/login", // done
  URL_LOGOUT: "/thor-authentication/api/v1/logout", //
  URL_JWT_TRUST: "/thor-token-management/api/v1/jwt-token",
  //   //LOGIN
  URL_GET_MACID: "/thor-authentication/api/v1/user/macAddress", // done
  URL_GET_SECURITY_IMAGE: "/thor-usermanagement/api/v1/securityImage/_get", //Security Image
  //WHITE LABEL
  URL_GET_WHITE_LABEL: "/thor-partywhitelabel/api/v1/party/whitelabel/_get", //get contentid
  URL_GET_CONTENT: "/thor-contentmanagement/api/v1/content/_get", // fetch logo the data using contentid
  //   //LOGIN
  URL_GET_MACID: "/thor-authentication/api/v1/user/macAddress", // done
  // URL_GETCOUNTRY: "/thor-regionmanagement/api/v1/country",
  URL_GETCOUNTRY: "/thor-regionmanagement/api/v1/country/_get",
  URL_GETSTATE: "/thor-regionmanagement/api/v1/state/_get",
  URL_GETCITY: "/thor-regionmanagement/api/v1/city/_get",

  URL_GET_PAYMENT_GATEWAY_PROFILE: "/pp-paymentgateway-service/gateway/api/v1/_get",
  URL_ADD_PAYMENT_GATEWAY_PROFILE: "/pp-paymentgateway-service/gateway/api/v1/_create",
  URL_GET_WHITE_LABEL_DATA: "/thor-partywhitelabel/api/v1/party/whitelabel/_get",
  URL_GET_CONTENT_LABEL_DATA: "/thor-contentmanagement/api/v1/content/_get",
  // Add Consent For Onlinepayment and Email use
  URL_ADD_CONSENT: "/thor-partyextendedservices/api/v1/party/consent/_add",
  URL_GET_PDF_DATA: "/thor-html2pdf/status",
  URL_GET_PUBLIC_URL_DATA: "/thor-documentmanagement/api/v1/document/publicurl",
  URL_GET_CONSENT: "/thor-partyextendedservices/api/v1/party/consent/_get",
  //Users
  URL_GET_USER_DATA: "/thor-usermanagement/api/v1/user/retrieveUser",

  // Get/Check User Consent For use of email and mobile no
  URL_GET_CONSENT: "/thor-partyextendedservices/api/v1/party/consent/_get",
  URL_UPDATE_CONSENT: "/thor-partyextendedservices/api/v1/party/consent/_update",

  // Verified email and mobile no
  URL_VERIFIED_EMAIL: "/thor-emailverification/api/v1/emailverification/isverified",
  URL_VERIFIED_MOBILE: "/thor-mobileverification/api/v1/isverified",

  //Search User Name
  URL_SEARCH_USER_NAME: "/thor-usermanagement/api/v1/user/get/userinfo/",
};
