/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React from "react";
import { Formik, Form, Field, ErrorMessage, getIn } from "formik";
import * as Yup from "yup";
// import "react-table/react-table.css";
// import "react-datepicker/dist/react-datepicker.css";
import { FormHelperText, MenuItem, Select } from "@material-ui/core";
import { LoginButton } from "../../../../assets/MaterialControl";
import { connect } from "react-redux";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { bindActionCreators } from "redux";
import { success, successNotification } from "../../../../components/Admin_Notification/Admin_Notification";
import { ToastContainer, handleSuccess } from "../../../../components/Notifications/Toast";
import { I18nextProvider } from "react-i18next";
import { Translation } from "react-i18next";
import i18n from "../../../../i18n";
import { searchPatient } from "../../../../action/patientDetailReport";
import { UserManagementService } from "../../../../services/UserManagementService";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "@material-ui/core";
const AddOrModify = (props) => {
  const getRoleIdByName1 = (role) => {
    for (let i in props?.lookupData) {
      if (props?.lookupData[i]?.roleName === role) {
        return props?.lookupData[i]?.roleId;
      }
    }
  };

  const getRoles = () => props?.lookupData?.filter((item) => item?.roleId !== props?.user.roleId);

  let initialValues = {
    userName: props.user[0] || props?.user?.userName || "",
    emailId: props.user[3] || props?.user?.email || "",
    userRoles: "none",
  };
  const validation = Yup.object().shape({
    userName: Yup.string().required("User Name is required"),
    emailId: Yup.string().email("Invalid email format").required("Email is required"),
    userRoles: Yup.string().notOneOf(["none"], "Select a Valid Option").required("Select a User Role"),
  });

  const handleSubmit = (values) => {
    const updateData = {
      userName: values.userName,
      emailId: values.emailId,
      roleId: getRoleIdByName1(values.userRoles),
    };
    UserManagementService.updateUserData(updateData, (res2) => {
      if (res2.data.status === "success") {
        handleSuccess(`${props?.user[0] || props?.user?.userName} role has been changed successfully!`);
        props.fetchTableApi();
        props.handleClose();
      }
      // setUserTableData(res2?.data?.responseListObject)
    });
  };

  return (
    <I18nextProvider i18n={i18n}>
      <React.Fragment>
        <Formik enableReinitialize initialValues={initialValues} validationSchema={validation} onSubmit={handleSubmit}>
          {({ touched, errors, setFieldValue, values, resetForm, handleBlur, handleChange }) => (
            <Form autocomplete="new-password" className="input-field">
              <div>
                <div className="form-row">
                  <div className="col-md-4 col-sm-6 form-group">
                    <label htmlFor="userName" className="required">
                      User Name
                    </label>
                    <OutlinedInput
                      type="text"
                      id="userName"
                      name={"a" + Math.random()}
                      value={values.userName}
                      placeholder="Enter Username"
                      disabled
                      onInput={(e) => {}}
                      className={`form-control admin-select-field ${
                        touched.userName && errors.userName ? "is-invalid" : ""
                      }`}
                      onChange={(event) => {
                        setFieldValue("userName", event.target.value);
                      }}
                    />

                    <ErrorMessage component="div" name="userName" className="error-text" />
                  </div>

                  <div className="col-md-4 col-sm-6 form-group">
                    <label htmlFor="emailId" className="required">
                      Email
                    </label>
                    <OutlinedInput
                      type="text"
                      id="emailId"
                      name={"a" + Math.random()}
                      disabled
                      value={values.emailId}
                      placeholder="Enter Email"
                      className={`form-control admin-select-field ${touched.emailId && errors.emailId ? "is-invalid" : ""}`}
                      onChange={(event) => {
                        setFieldValue("emailId", event.target.value);
                      }}
                    />
                    <ErrorMessage component="div" name="emailId" className="error-text" />
                  </div>
                  <div className="col-lg-4 col-sm-12 form-group">
                    <label htmlFor="userRoles" className="form-label required">
                      User Roles
                    </label>
                    <Select
                      autocomplete="off"
                      className={`form-control ${touched.userRoles && errors.userRoles ? "is-invalid" : ""}`}
                      name="userRoles"
                      placeholder="User Roles"
                      component="select"
                      // value={values.userRoles}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={getIn(touched, "userRoles") && getIn(errors, "userRoles")}
                      value={values["userRoles"]}>
                      <MenuItem value="none" key="none" disabled>
                        Select User Role
                      </MenuItem>
                      {getRoles().map((each) => {
                        return (
                          <MenuItem value={each.roleName} key={each.key}>
                            {`${each.roleName}`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText error>
                      {getIn(touched, "userRoles") && getIn(errors, "userRoles") && getIn(errors, "userRoles")}
                    </FormHelperText>
                  </div>
                </div>
                <div>
                  <DialogActions>
                    <Button type="submit" className="primary-button themeing-buttons">
                      Submit
                    </Button>
                    <Button className="secondary-button" variant="outlined" onClick={() => resetForm(initialValues)}>
                      Reset
                    </Button>
                  </DialogActions>
                </div>
              </div>{" "}
            </Form>
          )}
        </Formik>
        {/* <ToastContainer /> */}
      </React.Fragment>
    </I18nextProvider>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchPatient: searchPatient,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddOrModify);
