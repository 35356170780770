import { SECURITY_IMAGE_SUCCESS, SECURITY_IMAGE_FAILURE, SECURITY_IMAGE_REQUEST } from "../action/securityImageAction";

//const userdata = localStorage.getItem('userData') === "" ? "" : JSON.parse(localStorage.getItem('userData'))

const initialState = {
  pending: false,
  securityImage: "",
  error: null,
};

const securityImageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SECURITY_IMAGE_SUCCESS:
      // {console.log(action,'action')}
      return {
        ...state,
        pending: false,
        securityImage: action.payload,
      };
    case SECURITY_IMAGE_REQUEST:
      return {
        ...state,
        pending: true,
      };

    case SECURITY_IMAGE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    default:
      return state;
  }
};
export default securityImageReducer;
export const getSecurityImage = (state) => ({
  securityImage: state.securityImageReducer,
  pending: state.securityImageReducer,
  error: state.securityImageReducer,
});
