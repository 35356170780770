/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { Formik, Form, getIn } from "formik";
import * as Yup from "yup";
import { Grid } from "@material-ui/core";
import { TextField, FormControl, IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { FormHelperText } from "@material-ui/core";
import MaterialTable from "material-table";
import moment from "moment";
import SpinnerPage from "../../../../Common/PP_loader";
import { tableIcons } from "../../../../components/TableIcons";
import { getScheduledFuturepaymentList } from "../../../../action/ReportsAction";
import { requestInfo_PP } from "../../../../Common/CommonFn";
import { ExportToExcel } from "../../../../Common/exportToExcel";
import { handleWarning, ToastContainer, handleError } from "../../../../components/Notifications/Toast";
import { capitalize } from "../../../../util/Captilize";
import PatchedPagination from "../../../../Common/TablePagination";
import ClearIcon from "@material-ui/icons/Clear";
import { formatAmount, formatAmountWithOutDollar } from "../../../../util/formatAmount";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

const initialValues = {
  firstName: "",
  lastName: "",
  seedAccountReferenceNumber: "",
  startDate: null,
  endDate: null,
};

const ScheduledTransaction = () => {
  const dispatch = useDispatch();
  const [scheduledTransactionsData, setScheduledTransactionsData] = useState([]);
  const [formData, setFormdata] = useState(initialValues);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({
    page: null,
    rowsPerPage: null,
  });

  const handleScheduledFuturePayments = (values) => {
    if (
      values?.firstName ||
      values?.lastName ||
      values?.seedAccountReferenceNumber ||
      values?.startDate ||
      values?.endDate
    ) {
      setIsLoading(true);
      dispatch(
        getScheduledFuturepaymentList(
          {
            requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token")),
            scheduledTransactionReportModel: {
              firstName: values.firstName,
              lastName: values.lastName,
              seedAccountReferenceNumber: values?.seedAccountReferenceNumber,
              pageNo: 0,
              pageSize: 10,
              startPaymentDate: values?.startDate ? moment(values?.startDate).format("YYYY-MM-DD") : "",
              endPaymentDate: values?.endDate ? moment(values?.endDate).format("YYYY-MM-DD") : "",
            },
          },
          (res) => {
            setIsLoading(false);
            if (res.data?.responseInfo?.status === "SUCCESSFUL") {
              setScheduledTransactionsData(
                res?.data?.scheduledTransactionsReportResponse ? res?.data?.scheduledTransactionsReportResponse : [],
              );
              res.data.responseInfo?.responseMessage?.length > 0 && handleWarning(res.data.responseInfo?.responseMessage[0]);
            } else if (res.data?.responseInfo?.status === "FAILED") {
              setScheduledTransactionsData([]);
              handleError(res.data.responseInfo?.responseMessage[0]);
            } else {
              setScheduledTransactionsData([]);
              handleError(res?.message);
            }
          },
        ),
      );
    } else {
      handleWarning("Please fill atleast one of the fields");
    }
  };
  const ExcelData = (data) => {
    const DataToExp = data.map((obj) => {
      const {
        paymentOrderNumber,
        seedAccountNumber,
        accountReferenceNumber,
        firstName,
        lastName,
        billWiseAmount,
        scheduledPaymentDate,
        totalAmount,
      } = obj;

      const newObject = {
        "Payment Order Number": paymentOrderNumber ? paymentOrderNumber : "-",
        "Seed Account Number": seedAccountNumber ? seedAccountNumber : "-",
        "Account Number": accountReferenceNumber ? accountReferenceNumber : "-",
        "Scheduled Date": scheduledPaymentDate ? scheduledPaymentDate : "-",
        "Payment Amount($)": billWiseAmount || billWiseAmount === 0 ? formatAmountWithOutDollar(billWiseAmount) : "-",
        "Payment Order Amount($)": totalAmount || totalAmount === 0 ? formatAmountWithOutDollar(totalAmount) : "-",
        "First Name": firstName ? capitalize(firstName) : "-",
        "Last Name": lastName ? capitalize(lastName) : "-",
      };
      return newObject;
    });

    ExportToExcel(DataToExp, "Scheduled Future Payments Data");
  };

  const handleScheduledFuturePaymentsDownload = (values) => {
    setIsLoading(true);
    dispatch(
      getScheduledFuturepaymentList(
        {
          requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token")),
          scheduledTransactionReportModel: {
            firstName: values.firstName,
            lastName: values.lastName,
            seedAccountReferenceNumber: values?.seedAccountReferenceNumber,
            pageNo: 0,
            pageSize: -1,
            startPaymentDate: values?.startDate ? moment(values?.startDate).format("YYYY-MM-DD") : "",
            endPaymentDate: values?.endDate ? moment(values?.endDate).format("YYYY-MM-DD") : "",
          },
        },
        (res) => {
          setIsLoading(false);
          if (res.data?.responseInfo?.status === "SUCCESSFUL") {
            ExcelData(res?.data?.scheduledTransactionsReportResponse);
            res.data.responseInfo?.responseMessage?.length > 0 && handleWarning(res.data.responseInfo?.responseMessage[0]);
          } else if (res.data?.responseInfo?.status === "FAILED") {
            handleError(res.data.responseInfo?.responseMessage[0]);
          } else {
            handleError(res?.message);
          }
        },
      ),
    );
  };

  const handleSubmit = (values, onSubmitProps) => {
    handleScheduledFuturePayments(values);
  };
  const columns = [
    {
      title: "Payment Order Number",
      field: "paymentOrderNumber",
    },
    {
      title: "Seed Account Number",
      field: "seedAccountNumber",
    },
    {
      title: "Account Number",
      field: "accountReferenceNumber",
    },
    {
      title: "Scheduled Date",
      field: "scheduledPaymentDate",
    },
    {
      title: "Payment Amount",
      field: "billWiseAmount",
      type: "numeric",
      render: (rowData) => (rowData.billWiseAmount ? formatAmount(rowData.billWiseAmount) : "--"),
    },
    {
      title: "Payment Order Amount",
      field: "totalAmount",
      type: "numeric",
      render: (rowData) => (rowData.totalAmount || rowData.totalAmount === 0 ? formatAmount(rowData.totalAmount) : "--"),
    },
    {
      title: "First Name",
      field: "firstName",
      render: (rowData) => {
        return rowData?.firstName ? capitalize(rowData?.firstName) : "--";
      },
    },
    {
      title: "Last Name",
      field: "lastName",
      render: (rowData) => {
        return rowData?.lastName ? capitalize(rowData?.lastName) : "--";
      },
    },
  ];
  return (
    <div className="transaction-card" role="main">
      <div className="main-container-card">
        <div role="heading" aria-level="1" className="heading-style mb-3">
          <div className="scheduled-heading-icons">
            <CalendarTodayIcon className="scheduled-heading-calender" />{" "}
            <AttachMoneyIcon className="scheduled-heading-dollar" />{" "}
          </div>
          <h2 className="heading-style">Reports: Scheduled Transactions - Future Payments</h2>
        </div>
        <div>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {(props) => {
              const { values, touched, error, handleChange, errors, setFieldValue } = props;
              return (
                <Form id="transaction-search" className="input-field" noValidate="noValidate">
                  <div>
                    <div className="form-row">
                      <div className="col-xl-3 col-lg-3 col-sm-6 col-md-4 form-group">
                        <Grid container className="date-picker">
                          <FormControl variant="outlined" className="w-100" fullWidth>
                            <label htmlFor="seedAccountReferenceNumber" className="form-label w-100">
                              Seed Account Number
                            </label>
                            <TextField
                              type="text"
                              id="seedAccountReferenceNumber"
                              name="seedAccountReferenceNumber"
                              value={values.seedAccountReferenceNumber}
                              placeholder="Enter Seed Account Number"
                              onKeyPress={(event) => {
                                if (!/\d/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={handleChange}
                              aria-label="text-field"
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}></TextField>
                            <FormHelperText error>
                              {getIn(touched, "seedAccountReferenceNumber") &&
                                getIn(errors, "seedAccountReferenceNumber") &&
                                getIn(errors, "seedAccountReferenceNumber")}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </div>

                      <div className="col-xl-3 col-lg-3 col-sm-6 col-md-4 form-group">
                        <FormControl variant="outlined" className="w-100" fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Grid container className="custom-date-picker date-picker">
                              <label htmlFor="patientDOB" className="form-label w-100">
                                Start Date
                              </label>
                              <DatePicker
                                format="M-d-y"
                                name="startDate"
                                id="startDate"
                                monthPlaceholder="MM"
                                dayPlaceholder="DD"
                                yearPlaceholder="YYYY"
                                value={values.startDate}
                                showLeadingZeros={false}
                                onChange={(value) => {
                                  setFieldValue("startDate", value);
                                }}
                                autoOk
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    helperText={null}
                                    margin="dense"
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: "MM/DD/YYYY",
                                    }}
                                  />
                                )}
                                InputProps={{
                                  startAdornment: (
                                    <IconButton
                                      position="end"
                                      onClick={() => setFieldValue("startDate", null)}
                                      style={{ order: 1, padding: 0 }}>
                                      <ClearIcon fontSize="small" />
                                    </IconButton>
                                  ),
                                }}
                                InputAdornmentProps={{
                                  position: "end",
                                  style: { order: 2 },
                                }}
                              />
                              <FormHelperText error>
                                {getIn(touched, "startDate") && getIn(errors, "startDate") && getIn(errors, "startDate")}
                              </FormHelperText>
                            </Grid>
                          </LocalizationProvider>
                        </FormControl>
                      </div>

                      <div className="col-xl-3 col-lg-3 col-sm-6 col-md-4 form-group">
                        <FormControl variant="outlined" className="w-100" fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Grid container className="custom-date-picker date-picker">
                              <label htmlFor="endDate" className="form-label w-100">
                                End Date
                              </label>
                              <DatePicker
                                format="M-d-y"
                                monthPlaceholder="MM"
                                name="endDate"
                                id="endDate"
                                dayPlaceholder="DD"
                                yearPlaceholder="YYYY"
                                minDate={values.startDate}
                                value={values.endDate}
                                showLeadingZeros={false}
                                onChange={(value) => {
                                  setFieldValue("endDate", value);
                                }}
                                autoOk
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    helperText={null}
                                    margin="dense"
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: "MM/DD/YYYY",
                                    }}
                                  />
                                )}
                                InputProps={{
                                  startAdornment: (
                                    <IconButton
                                      position="end"
                                      onClick={() => setFieldValue("endDate", null)}
                                      style={{ order: 1, padding: 0 }}>
                                      <ClearIcon fontSize="small" />
                                    </IconButton>
                                  ),
                                }}
                                InputAdornmentProps={{
                                  position: "end",
                                  style: { order: 2 },
                                }}
                              />
                              <FormHelperText error>
                                {getIn(touched, "endDate") && getIn(errors, "endDate") && getIn(errors, "endDate")}
                              </FormHelperText>
                            </Grid>
                          </LocalizationProvider>
                        </FormControl>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-sm-6 col-md-4 form-group">
                        <Grid container className="date-picker">
                          <FormControl variant="outlined" className="w-100" fullWidth>
                            <label htmlFor="firstName" className="form-label w-100">
                              First Name
                            </label>
                            <TextField
                              type="text"
                              id="firstName"
                              name="firstName"
                              value={values.firstName}
                              placeholder="Enter First Name"
                              onChange={handleChange}
                              aria-label="text-field"
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}></TextField>
                            <FormHelperText error>
                              {getIn(touched, "firstName") && getIn(errors, "firstName") && getIn(errors, "firstName")}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </div>

                      <div className="col-xl-3 col-lg-3 col-sm-6 col-md-4 form-group">
                        <Grid container className="date-picker">
                          <FormControl variant="outlined" className="w-100" fullWidth>
                            <label htmlFor="lastName" className="form-label w-100">
                              Last Name
                            </label>
                            <TextField
                              type="text"
                              id="lastName"
                              name="lastName"
                              value={values.lastName}
                              placeholder="Enter Last Name"
                              onChange={handleChange}
                              aria-label="text-field"
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}></TextField>
                            <FormHelperText error>
                              {getIn(touched, "lastName") && getIn(errors, "lastName") && getIn(errors, "lastName")}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </div>
                    </div>

                    <div className="payment-history-btns">
                      <button type="submit" className="themeing-buttons primary-button">
                        Search
                      </button>
                      <button
                        aria-label="Reset"
                        type="reset"
                        className="secondary-button mx-2"
                        onClick={() => {
                          setScheduledTransactionsData([]);
                        }}>
                        Reset
                      </button>
                      {scheduledTransactionsData.length > 0 && (
                        <button
                          type="button"
                          className="primary-button themeing-secondary-buttons"
                          onClick={() => {
                            handleScheduledFuturePaymentsDownload(values);
                          }}>
                          Download
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>

          <Grid item xs={12}>
            <div className="payplan-table">
              <MaterialTable
                title=""
                localization={{
                  pagination: {
                    firstAriaLabel: "First Page",
                    previousAriaLabel: "Previous Page",
                    nextAriaLabel: "Next Page",
                    lastAriaLabel: "Last Page",
                  },
                }}
                columns={columns}
                icons={tableIcons}
                data={scheduledTransactionsData.length > 0 ? scheduledTransactionsData : []}
                options={{
                  selection: false,
                  search: false,
                  emptyRowsWhenPaging: false,
                  rowsPerPage: 5,
                  rowsPerPageOptions: [5, 10, 20, 30],
                  onChangeRowsPerPage: () => {
                    return 5;
                  },
                }}
                components={{
                  Pagination: PatchedPagination,
                }}
              />
            </div>
          </Grid>
        </div>

        {isLoading && <SpinnerPage />}
      </div>
    </div>
  );
};
export default ScheduledTransaction;
