import React from "react";
import { withStyles, createMuiTheme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { TextField, Checkbox } from "@material-ui/core";

// The `withStyles()` higher-order component is injecting a `classes`
// prop that is used by the `Button` component.
export const QuickPayButton = withStyles({
  root: {
    // background: "linear-gradient(45deg, #007bff  30%, #007bff 90%)",
    // borderRadius: 3,
    // border: 0,
    // color: "white",
    // height: 35,
    // padding: "6px 12px",
    // width: "auto",
    // margin:"0px 10px 0px 0px"
    fontSize: "16px",
    fontFamily: "poppinsregular  !important",
    fontWeight: "normal",
    padding: "25px 10px",
    border: 0,
    borderRadius: "5px",

    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#f1592a !important",
  },
  // label: {
  //   textTransform: "capitalize",
  // },
})(Button);

export const NextButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #0f75bc  30%, #0f75bc 90%)",
    borderRadius: 3,
    border: 0,
    color: "white !important",
    height: 35,
    padding: "6px 10px",
    width: "auto",
    margin: "0px 5px 0px 0px !important",
    outline: 0,
    fontFamily: "poppinsregular  !important",
  },

  // label: {

  // },
})(Button);

export const PayButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #0f75bc  30%, #0f75bc 90%)",
    borderRadius: 3,
    border: 0,
    color: "white !important",
    height: 35,
    padding: ".375rem .75rem",
    width: "auto",
    margin: "0px 10px 0px 0px  !important",
    fontFamily: "poppinsregular  !important",
  },
  // label: {
  //   textTransform: "capitalize !important",
  // },
})(Button);

export const CancelButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #17a2b8  30%, #17a2b8 90%)",
    borderRadius: 3,
    border: 0,
    color: "white !important",
    height: 35,
    // padding: "6 12px",
    width: "auto",
    outline: "0 !important",
    fontFamily: "poppinsregular  !important",
  },
  // label: {
  //   textTransform: "capitalize",
  // },
})(Button);
export const TxnDoneButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #0f75bc  30%, #0f75bc 90%)",
    borderRadius: 3,
    border: 0,
    color: "white !important",
    height: 35,
    padding: ".375rem .75rem",
    width: "auto",
    margin: "0px 0px 0px 0px !important",
    fontFamily: "poppinsregular  !important",
  },
  // label: {
  //   textTransform: "capitalize",
  // },
})(Button);
export const QuerySubmitButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #0f75bc  30%, #0f75bc 90%)",
    borderRadius: 3,
    border: 0,
    color: "white !important",
    height: 35,
    padding: ".375rem .75rem",
    width: "auto",
    margin: "0px 10px 0px 0px !important",
    fontFamily: "poppinsregular  !important",
  },
  // label: {
  //   textTransform: "capitalize",
  // },
})(Button);
export const TextBoxField = withStyles({
  root: {
    borderRadius: 3,
    border: "#111",
    color: "#111",
    width: "100%",
    marginRight: "5px",
  },
  label: {
    textTransform: "capitalize",
  },
})(TextField);

export const CheckBoxField = withStyles({
  root: {
    borderRadius: 3,
    border: "#111",
  },
  // label: {
  //   textTransform: "capitalize",
  // },
})(Checkbox);

export const ChangeCancelButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #138496  30%, #138496 90%)",
    borderRadius: 3,
    border: 0,
    color: "white !important",
    height: 35,
    padding: "6px 7px",
    width: "auto",
    outline: "0 !important",
    fontFamily: "poppinsregular  !important",
  },
  // label: {
  //   textTransform: "capitalize",
  // },
})(Button);

export const ChangeResetButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #0f75bc  30%, #0f75bc 90%)",
    borderRadius: 3,
    border: 0,
    color: "white !important",
    height: 35,
    padding: "6px 7px",
    width: "auto",
    margin: "0px 5px 0px 0px !important",
    outline: "0 !important",
    fontFamily: "poppinsregular  !important",
  },

  // label: {
  //   textTransform: "capitalize",
  // },
})(Button);

export const ExportToCsvButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #0f75bc  30%, #0f75bc 90%)",
    borderRadius: 3,
    border: 0,
    color: "white !important",
    height: 35,
    padding: "6px 7px",
    width: "auto",
    margin: "0px 5px 0px 0px !important",
    outline: "0 !important",
    fontFamily: "poppinsregular  !important",
  },

  // label: {
  //   textTransform: "capitalize",
  // },
})(Button);

export const ExportToExcelButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #0e9448  30%, #0e9448 90%)",
    borderRadius: 3,
    border: 0,
    color: "white !important",
    height: 35,
    padding: "6px 7px",
    width: "auto",
    margin: "0px 5px 0px 0px !important",
    outline: "0 !important",
    borderColor: "#0e9448",
    fontFamily: "poppinsregular  !important",
  },

  // label: {
  //   textTransform: "capitalize",
  // },
})(Button);

export const GeneratePdfButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #e95340  30%, #e95340 90%)",
    borderRadius: 3,
    border: 0,
    color: "white !important",
    height: 35,
    padding: "6px 7px",
    width: "auto",
    margin: "0px 5px 0px 0px !important",
    outline: "0 !important",
    borderColor: "#e95340",
    fontFamily: "poppinsregular  !important",
  },

  // label: {
  //   textTransform: "capitalize",
  // },
})(Button);

export const GenerateReportButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #0f75bc  30%, #0f75bc 90%)",
    borderRadius: 3,
    border: 0,
    color: "white !important",
    height: 35,
    padding: "6px 7px",
    width: "auto",
    margin: "0px 5px 0px 0px !important",
    outline: "0 !important",
    fontFamily: "poppinsregular  !important",
  },

  // label: {
  //   textTransform: "capitalize",
  // },
})(Button);

export const LoginButton = withStyles({
  root: {
    // background: "linear-gradient(45deg, #0f75bc  30%, #0f75bc 90%)",
    // borderRadius: 3,
    // border: 0,
    // color: "white",
    // height: 48,
    // padding: "0 30px",
    // width: "100%",
    // outline: 0

    background: "linear-gradient(45deg, #0f75bc  30%, #0f75bc 90%)",
    borderRadius: 3,
    border: 0,
    color: "white !important",
    height: 40,
    padding: ".375rem .75rem",
    width: "100%",
    margin: "0px 10px 0px 0px !important",
    outline: "0 !important",
    fontFamily: "poppinsregular  !important",
  },
  // label: {
  //   textTransform: "capitalize",
  // },
})(Button);

export const ResetButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #0f75bc  30%, #0f75bc 90%)",
    borderRadius: 3,
    border: 0,
    color: "white !important",
    height: 35,
    padding: "6px 7px",
    width: "auto",
    margin: "0px 5px 0px 0px !important",
    outline: "0 !important",
    fontFamily: "poppinsregular  !important",
  },

  // label: {
  //   textTransform: "capitalize",
  // },
})(Button);

// export const getMuiTheme = () =>
//   createMuiTheme({
//     overrides: {
//       MUIDataTable: {
//         root: {
//           backgroundColor: "#cccccc",
//           fontSize: "14px",
//           borderTopWidth: 1,
//           borderColor: "red",
//           borderStyle: "solid",
//         },
//         paper: {
//           boxShadow: "none",
//         },
//       },
//       MUIDataTableBodyCell: {
//         root: {
//           backgroundColor: "#eeeeee",
//           fontSize: "14px",
//         },
//       },
//       MUIDataTableHeadCell: {
//         root: {
//           backgroundColor: "#eeeeee",
//           fontSize: "14px",
//           fontWeight: "bold",
//         },
//       },
//     },
//   });

export const PayCsaButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #0f75bc  30%, #0f75bc 90%)",
    borderRadius: 100,
    border: 0,
    color: "white !important",
    height: 35,
    padding: ".375rem .75rem",
    width: "120px !important",
    margin: "0px 10px 0px 0px !important",
    fontFamily: "poppinsregular  !important",
  },
  // label: {
  //   textTransform: "capitalize",
  // },
})(Button);

export const ViewPatientbtn = withStyles({
  root: {
    background: "linear-gradient(45deg, #0f75bc  30%, #0f75bc 90%)",
    borderRadius: 100,
    border: 0,
    color: "white !important",
    height: 35,
    padding: ".375rem .75rem",
    width: "50px !important",
    margin: "0px 10px 0px 0px !important",
    fontFamily: "poppinsregular  !important",
  },
  // label: {
  //   textTransform: "capitalize",
  // },
})(Button);

export const CreateButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #0f75bc  30%, #0f75bc 90%)",
    borderRadius: 3,
    border: 0,
    // color: "white",
    height: 35,
    padding: "6px 7px",
    width: "auto",
    margin: "0px 5px 0px 0px !important",
    color: "white !important",
    outline: "0 !important",
    fontFamily: "poppinsregular  !important",
  },

  // label: {
  //   textTransform: "capitalize",
  // },
})(Button);
export const DashboardPersonalizationButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #0f75bc  30%, #0f75bc 90%)",
    borderRadius: 3,
    border: 0,
    color: "white !important",
    height: 35,
    padding: ".375rem .75rem",
    width: "auto",
    margin: "0px 10px 0px 0px !important",
    fontFamily: "poppinsregular  !important",
  },

  // label: {
  //   textTransform: "capitalize",
  // },
})(Button);
