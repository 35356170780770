import { Global_var } from "../global/global_var";
import RestDataSource from "./restdatasource";
import {
    error,
    success,
    warning,
    warningNotification,
    errorNotification,
    successNotification,
  } from "../components/Admin_Notification/Admin_Notification";

export const resetPasswordService = {
    adminResetPassword,
};

function adminResetPassword(userData, fn) {

    var url = Global_var.BASEURL + Global_var.URL_VERIFY_USER;
        return new RestDataSource(url,0, fn).Store(
          userData,
          (res) => {
            if (res != null) {
                fn(res.data);
              }
        }
        );
    //   }
    // });
  }
