import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

//import Footer from "../../components/admin_footer/footer";
import PP_paymenteCheck from "./ECheque";
import { geteChequePaymentList } from "../../../../reducer/pp_eChequePaymentReducer";
import { addEchaqueData, fetchEchaqueData } from "../../../../action/pp_eChequePaymentAction";
import { requestInfo_PP } from "../../../../Common/CommonFn";
import { loginService } from "../../../../services/loginService";
import UpdateTrackingPage from "../../../../containers/UpdateTrackingPage";

const mapStateToProps = (state) => ({
  eChequeData: geteChequePaymentList(state.eChequePaymentReducer),
  // pending: geteChequePaymentListPending(state.eChequePaymentReducer),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchEchaqueData: fetchEchaqueData,
      addEchaqueData: addEchaqueData,
      // getCountryData:getCountryData
    },
    dispatch,
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const PP_paymenteCheckContainer = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
    }
    componentWillMount() {
      sessionStorage.setItem("screen", "Paymentconfirmation");
    }
    componentDidMount() {
      var queryString = "";
      queryString = window.location.search.split("&");
      // alert(queryString)
      if (queryString[0] !== "" && queryString[0] !== null) {
        var urlAppId = queryString ? queryString[0].replace("?id=", "") : "";
        var urlPartyId = queryString[1] ? queryString[1].replace("p=", "") : "";
        var urlAmount = queryString[2] ? queryString[2].replace("a=", "") : "";
        var urlSubscriberId = queryString[3] ? queryString[3].replace("s=", "") : "";
        var urlMethod = queryString[4] ? queryString[4].replace("b=", "") : "";

        const AppId = atob(urlAppId);
        const PartyId = atob(urlPartyId);
        const Amount = atob(urlAmount);
        const SubId = atob(urlSubscriberId);
        const Method = atob(urlMethod);

        sessionStorage.setItem("SIC_Info", AppId);

        sessionStorage.setItem("buid", 6);
        // alert(QueryString)
        loginService.TokenUser((res) => {
          if (res.status === "success") {
            sessionStorage.setItem("jwt-token", res.responseObject);
            sessionStorage.setItem("jwt-tokencore", res.responseObject);
          }
        }, console.log("Token Error"));
      } else {
        const userinfo = JSON.parse(sessionStorage.getItem("verifypayer"));

        sessionStorage.setItem("screen", "Paymentconfirmation");
        const jtiToken =
          sessionStorage.getItem("jti-token") === null
            ? sessionStorage.getItem("jwt-token")
            : sessionStorage.getItem("jti-token");
        const Token = sessionStorage.getItem("jwt-token") === null ? "" : sessionStorage.getItem("jwt-token");

        var userpartyinfo = JSON.parse(sessionStorage.getItem("verifypayer"));
        if (userpartyinfo !== null) {
          var PassValues = {
            requestInfo: requestInfo_PP(Token, jtiToken),
            paymentInfoSearch: {
              paymentMethodId: 75003,
              partyId: userpartyinfo.partyId,
            },
          };
        }
        const UserId = 1234;
        const { fetchEchaqueData } = this.props;
        fetchEchaqueData(PassValues, UserId);
      }
    }
    render() {
      const { eChequeData } = this.props;
      
      let selectedCarddetails = this.props.location?.state?.paymentMethod;
      let passValues = this.props.location?.state?.PassValues;
      const payInFuture = this.props.location?.state?.payInFuture;

      let selectedCard = "";
      let accountNumber = "";
      let accountType = "";
      let bankAchRoutingCode = "";
      let paymentInstrumentId = "";
      if (selectedCarddetails && selectedCarddetails[0] !== "creditCard" && selectedCarddetails[0] !== "echeck") {
        paymentInstrumentId = selectedCarddetails && selectedCarddetails?.split("?")[1]?.split("/")[1]?.split("$")[1];
      }

      if (paymentInstrumentId) {
        selectedCard = eChequeData?.achAccount?.filter(
          (item) => item.paymentInstrument?.paymentInstrumentId === Number(paymentInstrumentId),
        );
      }

      return (
        <div id="outer-container">
          <React.Fragment>
            <main id="page-wrap">
              {/* {<Header {...this.props} />} */}

              <div id="slidepagearea" className="contentarea">
                <PP_paymenteCheck
                  passValues={passValues}
                  payInFuture={payInFuture}
                  selectedCard={selectedCard}
                  eChequeData={eChequeData}
                  {...this.props}
                />
              </div>
            </main>
            {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
          </React.Fragment>
        </div>
      );
    }
  },
);

export default PP_paymenteCheckContainer;
