import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DrawSign from "./DrawSign";
import UploadSign from "./UploadSign";
import GenerateSign from "./GenerateSign";
import { FormHelperText } from "@material-ui/core";
import { getIn } from "formik";
var _ = require("lodash");

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
    marginBottom: "10px",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: ["Poppins", "sans-serif"].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    "& .Component-root-21": {
      [theme.breakpoints.down("sm")]: {
        marginRight: "0px",
      },
    },
  },
  demo2: {
    backgroundColor: "#2e1534",
  },
  suggestion: {
    fontSize: "12px",
    textAlign: "justify",
    padding: "10px 25px 0 25px",
  },
  btnPrimary: {
    padding: "10px 10px",
    fontSize: "12px",
  },
  dialogueBox: {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "40vw",
      minHeight: "72vh",
      [theme.breakpoints.down("sm")]: {
        minWidth: "40vw",
        minHeight: "72vh",
      },
    },
  },
}));

export default function Signature({ setFieldValue, updateUserSignature, ...rest }) {
  const { values, errors, touched } = rest;
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);
  const [base64string, setBase64string] = React.useState(null);

  const handleBase64String = async (data) => {
    values.signature = data;
    values.isItalicSign = false;
    setFieldValue("signature", data);
    setFieldValue("isItalicSign", false);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setFieldValue("signature", "");
    setFieldValue("italicSign", "");
  };

  return (
    <div>
      <div className={classes.title}>
        <h5 className="content-title heading-style">EFT Signature</h5>
      </div>
      <div dividers className="form-row outline-cp">
        <div className={`col-sm-12 form-group ${classes.root}`}>
          <div className={classes.demo1}>
            <AntTabs value={tabValue} onChange={handleChange} aria-label="Create you Signature">
              <AntTab label="Create" setFieldValue={setFieldValue} />
              <AntTab label="Draw" setFieldValue={setFieldValue} />
              <AntTab label="Upload" setFieldValue={setFieldValue} />
            </AntTabs>
          </div>
          {tabValue === 0 && (
            <div>
              <GenerateSign setFieldValue={setFieldValue} updateUserSignature={updateUserSignature} {...rest} />
            </div>
          )}
          {tabValue === 1 && (
            <div>
              <DrawSign
                setFieldValue={setFieldValue}
                handleBase64String={handleBase64String}
                updateUserSignature={updateUserSignature}
              />
            </div>
          )}
          {tabValue === 2 && (
            <div>
              <UploadSign
                setFieldValue={setFieldValue}
                handleBase64String={handleBase64String}
                updateUserSignature={updateUserSignature}
              />
            </div>
          )}
          <FormHelperText error>
            {getIn(touched, "signature") &&
              getIn(touched, "italicSign") &&
              getIn(errors, "italicorsignature") &&
              getIn(errors, "italicorsignature")}
          </FormHelperText>
        </div>
      </div>
    </div>
  );
}
