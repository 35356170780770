import React, { createRef, useEffect, useState, useRef } from "react";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import { tableIcons } from "../../../../../components/TableIcons";
import { useDispatch } from "react-redux";
import { handleError } from "../../../../../components/Notifications/Toast";
import PatchedPagination from "../../../../../Common/TablePagination";
import { getSettlementOptionsById } from "../../../../../action/ClientManagementAction";
import { httpPaginationRequest } from "../../../../../Common/httpPaginationRequest";
import { Global_var } from "../../../../../global/new _admin_globalvar";

import EditIcon from "@material-ui/icons/Edit";

const SettlementOptionsTable = ({
  isSubmit,
  isUpdate,
  searchData,
  setIsEdit,
  setIsSubmit,
  setIsUpdate,
  setIsLoading,
  setSubBuid,

  setDialogOpen,
  setSettlementOptionsPopupData,
}) => {
  const [arrayLast, setArrayLast] = useState(false);
  const [rows, setRows] = useState(5);
  const tableRef = createRef();
  const dispatch = useDispatch();

  const isInitialRender = useRef(false);
  useEffect(() => {
    isInitialRender.current = true;
  }, []);
  useEffect(() => {
    if (tableRef?.current && isSubmit && isInitialRender.current === false) {
      if (searchData && Object.keys(searchData).length > 0) {
        tableRef.current.onQueryChange();
      }
    }
    if (isSubmit && isInitialRender.current) {
      isInitialRender.current = false;
    }
  }, [isSubmit, searchData]);

  useEffect(() => {
    if (tableRef?.current && isUpdate) {
      tableRef.current.onQueryChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate]);
  const columns = [
    {
      title: "Hospital Name",
      field: "hospital",
    },
    {
      title: "Hospital Code",
      field: "hospitalCode",
    },
    {
      title: "Settlement Type",
      field: "Settlement Type",
      render: (rowData) => (rowData?.settlementType ? rowData?.settlementType : "-"),
    },
    {
      title: "Minimum Settlement Percentage",
      field: "minimumSettlementPercentage",
      type: "numeric",
      // cellStyle: { textAlign: "right" },
      // headerStyle: { textAlign: "right" },
      // maxWidth: 195,
      render: (rowData) =>
        rowData.minimumSettlementPercentage || rowData.minimumSettlementPercentage === 0
          ? `${rowData.minimumSettlementPercentage}%`
          : "-",
    },
    {
      title: "Maximum Percentage to Show Settlement",
      field: "maximumPaymentLimit",
      type: "numeric",
      // cellStyle: { textAlign: "right" },
      // headerStyle: { textAlign: "right" },
      // maxWidth: 195,
      render: (rowData) =>
        rowData.maximumPaymentLimit || rowData.maximumPaymentLimit === 0 ? `${rowData.maximumPaymentLimit}%` : "-",
    },
  ];
  return (
    <Grid item>
      <div className="payplan-table ">
        <MaterialTable
          title=""
          localization={{
            pagination: {
              firstAriaLabel: "First Page",
              previousAriaLabel: "Previous Page",
              nextAriaLabel: "Next Page",
              lastAriaLabel: "Last Page",
            },
          }}
          columns={columns}
          icons={tableIcons}
          tableRef={tableRef}
          data={(query) =>
            new Promise((resolve) => {
              const bodyObj = {
                limit: query.pageSize ? query.pageSize : null,
                orderBy: "",
                orderDirection: "",
                page:
                  isSubmit || isUpdate
                    ? 0
                    : arrayLast && query?.page >= 0
                    ? query.page
                    : arrayLast
                    ? (query.page <= 0 && query.page + 1) || query.page - 1
                    : query.page,
                hospitalCode: searchData?.hospitalCode?.code || "",
              };
              httpPaginationRequest(Global_var.URL_SETTLEMENT_OPTIONS_LIST, bodyObj, (res) => {
                //setIsSubmit(false);
                setIsUpdate(false);
                if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
                  const data = res?.data?.response;
                  resolve({
                    data: data.length > 0 ? data : [],
                    page: res.data.page,
                    totalCount: res?.data?.count,
                  });
                  setArrayLast(data.length === 1);
                } else if (res.data?.responseInfo?.status === "FAILED") {
                  handleError(res?.data?.responseInfo?.responseMessage[0]);
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 5,
                  });
                } else {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 5,
                  });
                  dispatch({
                    type: "FETCH_ERROR",
                    payload: res && res.message ? res.message : null,
                  });
                }
              });
            })
          }
          actions={[
            {
              icon: () => (
                <>
                  <EditIcon className="paymentplan-icons" />
                </>
              ),
              tooltip: "Edit",
              onClick: (event, rowData) => {
                setIsLoading(true);
                dispatch(
                  getSettlementOptionsById(
                    {
                      id: rowData?.settlementOptionsId,
                    },
                    (res) => {
                      setIsLoading(false);
                      setSubBuid(rowData?.subBuId);
                      if (res?.status === 200) {
                        setDialogOpen(true);
                        setSettlementOptionsPopupData(res?.data?.settlementResponse);
                        setIsEdit(true);
                      } else {
                        handleError("Something went wrong");
                      }
                    },
                  ),
                );
              },
            },
          ]}
          options={{
            selection: false,
            search: true,
            sorting: false,
            emptyRowsWhenPaging: false,
            pageSize: rows,
          }}
          onChangeRowsPerPage={(params) => {
            setRows(params);
          }}
          components={{
            Pagination: PatchedPagination,
          }}
        />
      </div>
    </Grid>
  );
};

export default SettlementOptionsTable;
