/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import TableChartIcon from "@mui/icons-material/TableChart";
import moment from "moment";
import { I18nextProvider } from "react-i18next";
import i18n from "../../../../i18n";
import { ErrorMessage, Form, Formik } from "formik";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { FormControl, Grid, IconButton, TextField } from "@material-ui/core";
import SpinnerPage from "../../../../Common/PP_loader";

import { Autocomplete } from "@material-ui/lab";
import { campaignTrackingService } from "../../../../services/campaignTrackingService";

import * as Yup from "yup";
import { FormHelperText } from "@material-ui/core";
import { handleError, handleWarning } from "../../../../components/Notifications/Toast";
import ClearIcon from "@material-ui/icons/Clear";
import ViewedButNotPaidReportTable from "./ViewedButNotPaidReportTable";

const ViewedButNotPaidReport = () => {
  const initialValues = {
    startDate: null,
    endDate: null,
    campaign: "",
  };

  const [tableData, setTableData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [campaignList, setCampaignList] = useState();
  const [searchData, setSearchData] = useState(initialValues);
  const [isSubmit, setIsSubmit] = useState(false);
  useEffect(() => {
    campaignTrackingService.getCampaignList(
      (res) => {
        setCampaignList(res.data?.campaignList);
      },
      (err) => {
        if (err) {
          handleError("Something went wrong!");
          setIsLoading(false);
        }
      },
    );
  }, []);

  const handleSubmit = (values) => {
    //setIsLoading(true);
    let showAlert = true;
    for (let key in values) {
      if (showAlert && Boolean(values[key])) {
        showAlert = false;
      }
    }
    if (showAlert) {
      handleWarning("Please select at least one field to search");
    } else {
      setSearchData(values);
      setIsSubmit(true);
    }
    // campaignTrackingService.getViewedButNotPaidData(
    //   payload,
    //   (res) => {
    //     const data = res.data;
    //     setIsLoading(false);
    //     if (data.campaignList.length === 0) {
    //       setTableData(null);
    //       handleWarning("No records found");
    //     } else {
    //       setTableData(data.campaignList);
    //     }
    //   },
    //   (err) => {
    //     if (err) {
    //       handleWarning("Something went wrong!");
    //       setIsLoading(false);
    //     }
    //   },
    // );
  };
  const validationSchema = Yup.object().shape({
    endDate: Yup.date()
      .test("endDate", "End date should be greater than or equal to start date", function (val) {
        const { startDate } = this.parent;
        if (startDate > val) {
          return false;
        } else {
          return true;
        }
      })
      .nullable(),
  });
  return (
    <I18nextProvider i18n={i18n}>
      <React.Fragment>
        {isLoading && <SpinnerPage />}
        <div className="transaction-card" role="main">
          <div className="main-container-card">
            <div role="heading" aria-level="1" className="heading-style mb-3">
              <TableChartIcon className="mr-2" />
              <h2 className="heading-style mb-0"> Campaign Tracking: Viewed But Not Paid</h2>
            </div>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}>
              {({ touched, errors, setFieldValue, values, resetForm }) => (
                <Form className="input-field" noValidate="noValidate">
                  <div className="form-row">
                    <div className="col-lg-4 col-xl-4 col-sm-6 col-md-6 form-group">
                      <div>
                        <FormControl component="fieldset" variant="outlined" className="w-100" fullWidth>
                          <>
                            <label className="form-label" htmlFor="campaign">
                              Campaign
                            </label>
                            <Autocomplete
                              id="campaign"
                              options={campaignList && campaignList.length > 0 ? campaignList : []}
                              size="small"
                              className="form-autocomplete"
                              autoHighlight
                              value={values.campaign}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  fullWidth
                                  name="campaign"
                                  placeholder="Select Campaign"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "off", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                              onChange={(e, value) => {
                                setFieldValue("campaign", value);
                              }}
                            />
                          </>
                        </FormControl>
                        <ErrorMessage component="div" name="campaign" className="error-text" />
                      </div>
                    </div>
                    <div className="col-lg-4 col-xl-4 col-sm-6 col-md-6 form-group">
                      <FormControl component="fieldset" variant="outlined" className="w-100" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Grid container className="date-picker">
                            <label htmlFor="startDate" className="form-label w-100">
                              Start Date
                            </label>
                            <DatePicker
                              disableFuture
                              name="startDate"
                              autoOk
                              value={values.startDate}
                              onChange={(val) => setFieldValue("startDate", val)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helperText={null}
                                  margin="dense"
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "MM/DD/YYYY",
                                  }}
                                />
                              )}
                              InputProps={{
                                startAdornment: (
                                  <IconButton
                                    position="end"
                                    onClick={() => setFieldValue("startDate", null)}
                                    style={{ order: 1, padding: 0 }}>
                                    <ClearIcon fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                              InputAdornmentProps={{
                                position: "end",
                                style: { order: 2 },
                              }}
                            />
                          </Grid>
                        </LocalizationProvider>
                      </FormControl>
                    </div>
                    <div className="col-lg-4 col-xl-4 col-sm-6 col-md-6 form-group">
                      <FormControl component="fieldset" variant="outlined" className="w-100" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Grid container className="date-picker">
                            <label htmlFor="endDate" className="form-label w-100">
                              End Date
                            </label>
                            <DatePicker
                              disableFuture
                              name="endDate"
                              autoOk
                              value={values.endDate}
                              onChange={(val) => setFieldValue("endDate", val)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helperText={null}
                                  margin="dense"
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "MM/DD/YYYY",
                                  }}
                                />
                              )}
                              InputProps={{
                                startAdornment: (
                                  <IconButton
                                    position="end"
                                    onClick={() => setFieldValue("endDate", null)}
                                    style={{ order: 1, padding: 0 }}>
                                    <ClearIcon fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                              InputAdornmentProps={{
                                position: "end",
                                style: { order: 2 },
                              }}
                            />
                          </Grid>
                        </LocalizationProvider>
                      </FormControl>
                      <FormHelperText error name="endDate">
                        {touched.endDate && errors.endDate ? <div>{errors.endDate}</div> : null}
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="payment-history-btns">
                    <button type="submit" className="primary-button themeing-buttons mr-2">
                      Search
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        resetForm();
                        setTableData(null);
                        //setSearchData(null);
                        //setIsSubmit(true);
                        setIsSubmit(false);
                      }}
                      className="secondary-button">
                      Reset
                    </button>
                  </div>
                  {isSubmit && <ViewedButNotPaidReportTable searchData={searchData} isSubmit={isSubmit} />}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </React.Fragment>
    </I18nextProvider>
  );
};

export default ViewedButNotPaidReport;
