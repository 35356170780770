import React from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Checkbox, MenuItem, FormControl, FormControlLabel, Select, FormLabel, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { FormHelperText, IconButton } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ImageTooltip from "../ImageTooltip";
import echeckPaymentRoutingNumber from "../../assets/images/echeckPaymentRoutingNumber.jpg";
import CardPaymentTooltip from "../../assets/images/CardPaymentTooltip.jpg";
import { cityData, countryData, allStatesData } from "../../util/StatesData";
import { useHistory } from "react-router";
import { Grid } from "@material-ui/core";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import PASideNav from "../../routes/Pages/SideNav";
import UpdateTrackingPage from "../../containers/UpdateTrackingPage";
class CreditCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardnumber: true,
      routingNumber: true,
      accountNumber: true,
      reAccountNumber: true,
    };
  }
  intialvalues = {
    firstName: "",
    middleName: "",
    lastName: "",
    cardnumber: "",
    expirationyear: "none",
    expirationmonth: "none",
    securitycode: "",
    email: "",
    phoneNumber: "",
    message: "",
    country: "usa",
    StateCounty: "IN",
    city: "crystalLake",
    zipCode: "",
    agreeToterms: true,
    agreeToEmailUsage: true,
    authorizeElectronicDebit: true,
    accountType: "none",
    routingNumber: "",
    accountNumber: "",
    reAccountNumber: "",
    futureDate: null,
  };
  schema = Yup.object().shape({});
  onSubmit = (values) => {
    
  };
  handleCardNumber = () => {
    this.setState({ cardnumber: !this.state.cardnumber });
  };

  handleRoutingNumber = () => {
    this.setState({ routingNumber: !this.state.routingNumber });
  };

  handleAccountNumber = () => {
    this.setState({ accountNumber: !this.state.accountNumber });
  };

  handleReAccountNumber = () => {
    this.setState({ reAccountNumber: !this.state.reAccountNumber });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  render() {
    return (
      <>
        <div className="change-password dashboard-content" role="main" style={{ paddingTop: "60px", borderRadius: "4px" }}>
          <div className="dashboard-content-card">
            <Formik initialValues={this.intialvalues} onSubmit={this.onSubmit}>
              {(props) => {
                const { values, touched, error, handleChange, errors, setFieldValue } = props;
                return (
                  <Form className="input-field e-check-bg" noValidate="noValidate">
                    <div className="row">
                      <div className="col-md-7 mx-auto">
                        <div className="echeck-card">
                          <div className="row">
                            {this.props.futurePayment && (
                              <>
                                <div className="col-md-4 date-schedule">
                                  <div className="form-group">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <Grid container className="date-picker">
                                        <label htmlFor="futureDate" className="form-label">
                                          Future Date
                                        </label>
                                        <DatePicker
                                          disableFuture
                                          name="futureDate"
                                          autoOk
                                          value={values.futureDate}
                                          onChange={(val) => setFieldValue("futureDate", val)}
                                          renderInput={(params) => (
                                            <TextField {...params} helperText={null} margin="dense" />
                                          )}
                                        />
                                      </Grid>
                                    </LocalizationProvider>
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="row">
                              <div className="col-md-12">
                                <div className="row debitcard">
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <FormLabel htmlFor="firstName" className="required">
                                        First Name
                                      </FormLabel>
                                      <OutlinedInput
                                        className="form-control"
                                        name="firstName"
                                        value={values.firstName}
                                        onChange={handleChange}
                                        placeholder="Enter First Name"
                                        aria-label="text-field"
                                        fullWidth
                                        required></OutlinedInput>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <FormLabel htmlFor="middleName" className="required">
                                        Middle Name
                                      </FormLabel>
                                      <OutlinedInput
                                        className="form-control"
                                        name="middleName"
                                        value={values.middleName}
                                        onChange={handleChange}
                                        placeholder="Enter Middle Name"
                                        aria-label="text-field"
                                        fullWidth
                                        required></OutlinedInput>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <FormLabel htmlFor="lastName" className="required">
                                        Last Name
                                      </FormLabel>
                                      <OutlinedInput
                                        className="form-control"
                                        name="lastName"
                                        value={values.lastName}
                                        onChange={handleChange}
                                        placeholder="Enter Last Name"
                                        aria-label="text-field"
                                        fullWidth
                                        required></OutlinedInput>
                                    </div>
                                  </div>

                                  <div className="col-md-7">
                                    <div className="form-group inputbg">
                                      <div className="row m-0 justify-content-between align-items-center">
                                        <FormLabel htmlFor="cardnumber" className="required">
                                          Card Number
                                        </FormLabel>
                                      </div>
                                      <OutlinedInput
                                        className="form-control signup-fields"
                                        name="cardnumber"
                                        value={values.cardnumber}
                                        onChange={handleChange}
                                        type={this.state.cardnumber ? "password" : "text"}
                                        placeholder="Enter Card Number"
                                        aria-label="text-field"
                                        fullWidth
                                        required
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={this.handleCardNumber}
                                              onMouseDown={this.handleMouseDownPassword}>
                                              {this.state.cardnumber ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                          </InputAdornment>
                                        }></OutlinedInput>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group inputbg">
                                      <FormControl variant="outlined" className="w-100" fullWidth>
                                        <FormLabel htmlFor="Expiration Date" className="form-label required">
                                          Expiration Date
                                        </FormLabel>
                                        <div className="d-flex">
                                          <Select
                                            fullWidth
                                            name="expirationmonth"
                                            onChange={handleChange("expirationmonth")}
                                            value={values.expirationmonth}
                                            className="form-select form-control">
                                            <MenuItem value="none" disabled>
                                              MM
                                            </MenuItem>
                                          </Select>
                                          <Select
                                            style={{ backgroundColor: "lightgray" }}
                                            fullWidth
                                            name="expirationyear"
                                            onChange={handleChange("expirationyear")}
                                            value={values.expirationyear}
                                            className="form-select form-control">
                                            <MenuItem value="none" disabled>
                                              YYYY
                                            </MenuItem>
                                          </Select>
                                        </div>
                                      </FormControl>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="form-group">
                                      <div className="row m-0 justify-content-between align-items-center">
                                        <FormLabel htmlFor="securitycode" className="required">
                                          CVV
                                        </FormLabel>
                                        <ImageTooltip img={CardPaymentTooltip} width="300px" placement="right">
                                          <i
                                            className="fas fa-question-circle circle-icon"
                                            style={{ cursor: "pointer" }}></i>
                                        </ImageTooltip>
                                      </div>
                                      <OutlinedInput
                                        className="form-control signup-fields"
                                        name="securitycode"
                                        value={values.securitycode}
                                        onChange={handleChange}
                                        placeholder="CVV"
                                        aria-label="text-field"
                                        fullWidth
                                        type="password"
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 d-flex flex-wrap contact-details">
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <FormLabel htmlFor="emailAddress" className="required">
                                      Email Address
                                    </FormLabel>
                                    <OutlinedInput
                                      className="form-control"
                                      name="email"
                                      value={values.email}
                                      onChange={handleChange}
                                      type="email"
                                      placeholder="Enter Email Address"
                                      aria-label="text-field"
                                      fullWidth
                                      required></OutlinedInput>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <FormLabel htmlFor="phoneNumber" className="required">
                                      Phone Number
                                    </FormLabel>
                                    <OutlinedInput
                                      className="form-control"
                                      name="phoneNumber"
                                      value={values.phoneNumber}
                                      onChange={handleChange}
                                      placeholder="Enter Phone Number"
                                      aria-label="text-field"
                                      fullWidth
                                      required></OutlinedInput>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <FormLabel htmlFor="billingAddress" className="required">
                                      Billing Address
                                    </FormLabel>
                                    <OutlinedInput
                                      className="form-control"
                                      name="message"
                                      value={values.message}
                                      onChange={handleChange}
                                      rows={5}
                                      placeholder="Enter Your Billing Address"
                                      aria-label="text-field"
                                      fullWidth
                                      required></OutlinedInput>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group inputbg">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <FormLabel htmlFor="country" className="form-label required">
                                        Country
                                      </FormLabel>
                                      <Select
                                        fullWidth
                                        name="country"
                                        value={values.country}
                                        onChange={handleChange}
                                        className="form-select form-control">
                                        <MenuItem value="none" disabled>
                                          Select Country
                                        </MenuItem>
                                        {countryData.map((option) => {
                                          return (
                                            <MenuItem value={option.key} key={option.key}>
                                              {option.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group inputbg">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <FormLabel htmlFor="state" className="form-label required">
                                        State/County
                                      </FormLabel>
                                      <Select
                                        fullWidth
                                        name="StateCounty"
                                        value={values.StateCounty}
                                        onChange={handleChange}
                                        className="form-select form-control">
                                        <MenuItem value="none" key="none" disabled>
                                          Select State/County
                                        </MenuItem>
                                        {allStatesData.map((option) => {
                                          return (
                                            <MenuItem value={option.key} key={option.key}>
                                              {option.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group inputbg">
                                    <FormControl variant="outlined" className="w-100" fullWidth>
                                      <FormLabel htmlFor="city" className="form-label required">
                                        City
                                      </FormLabel>
                                      <Select
                                        onChange={handleChange}
                                        fullWidth
                                        name="city"
                                        value={values.city}
                                        className="form-select form-control">
                                        <MenuItem value="none" key="none" disabled>
                                          Select City
                                        </MenuItem>
                                        {cityData.map((option) => {
                                          return (
                                            <MenuItem value={option.key} key={option.key}>
                                              {option.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group inputbg">
                                    <FormLabel htmlFor="zipCode" className="required">
                                      ZIP Code
                                    </FormLabel>
                                    <OutlinedInput
                                      className="form-control"
                                      name="zipCode"
                                      value={values.zipCode}
                                      onChange={handleChange}
                                      placeholder="Enter ZIP Code"
                                      aria-label="text-field"
                                      fullWidth
                                      required></OutlinedInput>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="agree-terms">
                            <div className=" check-FormLabel">
                              <FormControl fullWidth component="fieldset" margin="normal">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="agreeToterms"
                                      checked={values.agreeToterms}
                                      onChange={handleChange}
                                      value={values.agreeToterms}
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <span>
                                      By checking this box,you signify you have reviewed,understand,meet and agree to the{" "}
                                      <a href="/">Online Payment Terms and Conditions</a>
                                    </span>
                                  }
                                />
                              </FormControl>
                            </div>
                            <div className="check-FormLabel">
                              <FormControl fullWidth component="fieldset" margin="normal">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="agreeToEmailUsage"
                                      value={values.agreeToEmailUsage}
                                      checked={values.agreeToEmailUsage}
                                      onChange={handleChange}
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <span>
                                      By checking this box,you agree that this is your personal email address and you
                                      authorise us to send you emails,call your phone, or contact you via an automated
                                      dialer,leave voicemails, and/or send text messages if necessary.
                                    </span>
                                  }
                                />
                              </FormControl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
          </div>
        </div>
      </>
    );
  }
}
export default CreditCard;
