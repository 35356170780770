import React, { createRef, useEffect, useState } from "react";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { Formik, Form } from "formik";
import { TextField, FormControl } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { handleWarning, handleError, handleSuccess } from "../../../../../components/Notifications/Toast";
import SpinnerPage from "../../../../../Common/PP_loader";
import { getHospitalList, deletePaymentPlanOptions } from "../../../../../action/ClientManagementAction";
import { Autocomplete } from "@material-ui/lab";
import ConfirmationDialog from "../../../../../components/ConfirmationDialog/index";
import PaymentPlanOptionsPopUp from "./PaymentPlanOptionsPopUp";
import PaymentPlanOptionsTable from "./PaymentPlanOptionsTable";
import Icons from "../../../../../util/Icons";
const PaymentPlanOptions = () => {
  let initialValues = {
    hospitalCode: "",
  };

  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState(null);
  const [hospitalList, setHostipalList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [paymentPlansOptPopUpData, setPaymentPlansOptPopUpData] = useState({});
  const [isDelete, setIsDelete] = useState(false);
  const [subBuId, setSubBuid] = useState(null);
  const [paymentPlansOptionsId, setPaymentPlansOptionsId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [deletePlanData, setDeletePlanData] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const tableRef = createRef();
  const handleClose = () => {
    setDeletePlanData(null);
    setIsEdit(false);
    setPaymentPlansOptPopUpData(null);
    setSubBuid(null);
    setDialogOpen(false);
    setIsDelete(false);
    setPaymentPlansOptionsId(null);
  };
  const dollarFormat = (value) => {
    if (value != null) {
      let amount = value;
      return `$ ${Number(amount).toLocaleString("us-en", {
        minimumFractionDigits: 2,
      })}`;
    } else {
      return value;
    }
  };

  useEffect(() => {
    dispatch(
      getHospitalList((res) => {
        if (res?.status === 200) {
          setHostipalList(res?.data?.hospitalDetails);
        } else {
          handleError("Something went wrong");
        }
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = () => {
    setIsLoading(true);
    dispatch(
      deletePaymentPlanOptions(paymentPlansOptionsId, (res) => {
        setIsLoading(false);
        if (res?.status === 200) {
          setIsUpdate(true);
          setIsDelete(false);
          handleSuccess(res?.data);
        } else {
          handleError(res?.message);
        }
      }),
    );
  };

  const handleSubmit = (values) => {
    if (values?.hospitalCode?.code) {
      setIsSubmit(true);
      setSearchData(values);
    } else {
      handleWarning("Please select a hospital");
    }
  };
  return (
    <div className="transaction-card patient-details" role="main">
      <div className="main-container-card">
        <div role="heading" aria-level="1" className="heading-style mb-3">
          <Icons type="paymentPlan" classNames="icon-heading-styles" />
          <h2 className="heading-style mb-0">Client Management: Payment Plan Options</h2>
        </div>
        <div>
          <div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {(props) => {
                const { values, setFieldValue } = props;
                return (
                  <Form id="transaction-search" className="input-field" noValidate="noValidate">
                    <div className="form-row">
                      <div className="col-lg-4 col-md-4 col-sm-6 form-group ">
                        <FormControl variant="outlined" className="w-100" fullWidth>
                          <label htmlFor="hospitalCode" className="form-label">
                            Hospital
                          </label>
                          {/* <Grid container className="date-picker"> */}
                          <Autocomplete
                            {...props}
                            id="hospitalCode"
                            options={hospitalList}
                            size="small"
                            autoHighlight
                            getOptionDisabled={(option) => option?.code === null}
                            value={values.hospitalCode}
                            getOptionLabel={(option) => {
                              return option && option?.code ? option?.code + " - " + option?.hospitalName : "";
                            }}
                            className="form-autocomplete"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                fullWidth
                                name="hospitalCode"
                                placeholder="Select Hospital Name"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "disabled",
                                }}
                              />
                            )}
                            onChange={(e, value) => {
                              setFieldValue("hospitalCode", value);
                            }}
                          />
                          {/* </Grid> */}
                        </FormControl>
                      </div>
                    </div>
                    <div className="payment-history-btns">
                      <button aria-label="Generate Report" type="submit" className="primary-button themeing-buttons">
                        Search
                      </button>
                      <button
                        aria-label="Reset"
                        type="reset"
                        className="secondary-button mx-2"
                        onClick={() => {
                          setSearchData(initialValues);
                          setIsSubmit(true);
                        }}>
                        Reset
                      </button>
                      <button
                        aria-label="Generate Report"
                        type="button"
                        className="primary-button themeing-secondary-buttons"
                        onClick={() => {
                          setDialogOpen(true);
                          setIsEdit(false);
                        }}>
                        Add
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>

          <PaymentPlanOptionsTable
            searchData={searchData}
            setIsUpdate={setIsUpdate}
            dollarFormat={dollarFormat}
            setIsLoading={setIsLoading}
            isSubmit={isSubmit}
            isUpdate={isUpdate}
            setIsEdit={setIsEdit}
            setIsDelete={setIsDelete}
            setDeletePlanData={setDeletePlanData}
            setPaymentPlansOptionsId={setPaymentPlansOptionsId}
            setIsSubmit={setIsSubmit}
            setSubBuid={setSubBuid}
            setDialogOpen={setDialogOpen}
            setPaymentPlansOptPopUpData={setPaymentPlansOptPopUpData}
          />

          <ConfirmationDialog
            open={dialogOpen}
            handleClose={handleClose}
            title={isEdit ? "Edit Payment Plans Options" : "Add Payment Plans Options"}
            noActionButtons={true}
            maxWidth="md">
            <PaymentPlanOptionsPopUp
              handleClose={handleClose}
              isEdit={isEdit}
              hospitalList={hospitalList}
              paymentPlansOptPopUpData={paymentPlansOptPopUpData}
              tableRef={tableRef}
              subBuId={subBuId}
              setIsUpdate={setIsUpdate}
            />
          </ConfirmationDialog>
          <ConfirmationDialog
            open={isDelete}
            handleClose={handleClose}
            title={"Delete Payment Plan Options"}
            maxWidth="sm"
            handleSubmit={handleDelete}>
            Are you sure, You want to delete Payment Plan options for{" "}
            <b>{deletePlanData && deletePlanData.buCode + "-" + deletePlanData.buName}</b>
          </ConfirmationDialog>
          {isLoading && <SpinnerPage />}
        </div>
      </div>
    </div>
  );
};

export default PaymentPlanOptions;
