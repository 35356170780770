/* eslint-disable consistent-return */
import React from "react";
import SignUp from "./SignUpContainer";

const SignUpContainer = () => {
  return (
    <>
      <SignUp values={JSON.parse(sessionStorage.getItem("values"))} />
    </>
  );
};

export default SignUpContainer;
