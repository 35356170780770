const config = {
  baseURL: "cpdevcoreservices.pdsnew.com",
  apiLabel: "api",
  apiVersion: "v1",
  sslEnabled: true,
};

export const ApiUrl = (serviceName, url) =>
  "https://" + config["baseURL"] + "/" + serviceName + "/" + config["apiLabel"] + "/" + config["apiVersion"] + "/" + url;

//ref urls
// https://devcoreservices.pdsnew.com/thor-contentmanagement/api/v1/content/_get
// https://devcoreservices.pdsnew.com/thor-token-management/api/v1/jwt-token
