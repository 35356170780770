import React, { Component } from "react";
import "./App.css";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryComponent from "../util/NewErrorBoundary";
import { handleWarning, ToastContainer } from "../components/Notifications/Toast";
import { identifyPayerService } from "../services/identifyPayerService";
import $ from "jquery";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      setOpen: false,
      showMenu: false,
      logourl: "",
      timeleft: 240,
      sessiontime: 180,
      warning: false,
      expire: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
  }
  componentDidMount() {
    let { history } = this.props;
    var is_root = window.location.pathname === "/";

    if (!is_root && sessionStorage.getItem("verifypayer") === null && sessionStorage.getItem("buid") === null) {
      if (window.location.href.indexOf("get-details") > -1) {
      } else {
        if (window.location.pathname === "/forgot-password") return;
        if (window.location.pathname === "/Reset-Password") return;
        if (window.location.pathname === "/admin") return;
        if (window.location.pathname === "/signin") return;
        if (window.location.pathname === "/autoLogin") return;

        handleWarning("Login to continue!");
      }
      setTimeout(function () {
        window.location.replace("/signin");
      }, 2000);
    }

    // Idle timer
    var context = this;
    var idleTime = 0;
    var timeleft = 1800;
    var timeForInterval = sessionStorage.getItem("currentUserRole") === "Admin" ? 1000 * 300 : 1000 * 30;
    $(document).ready(function () {
      // uncomment brloe line to start session timer
      //Increment the idle time counter every minute.
      var idleInterval = setInterval(timerIncrement, timeForInterval); // 1 minute

      //Zero the idle timer on mouse movement.
      $(this).mousemove(function (e) {
        idleTime = 0;
        timeleft = 1800;
      });
      $(this).keypress(function (e) {
        idleTime = 0;
        timeleft = 1800;
      });

      // uncomment brloe line to start session timer time tracker
      var interval = setInterval(decrement, 1000);
    });

    function decrement() {
      timeleft = timeleft - 1;
    }

    function timerIncrement() {
      idleTime = Number(idleTime) + 1;
      timeleft = timeleft - 1;

      if (idleTime > 60) {
        // 20 minutes
        context.setState({
          open: true,
          setOpen: true,
          warning: true,
        });
      }

      if (timeleft < 90) {
        let currentUserRole = sessionStorage.getItem("currentUserRole");
        currentUserRole === "Admin" ? history.push("/admin") : history.push("/signin");
        sessionStorage.removeItem("jwt-token");

        context.setState({
          warning: false,
          error: true,
        });
      } else {
        context.setState({
          warning: true,
          error: false,
        });
      }
    }
  }

  handleClose = () => {
    // alert()

    this.setState({
      open: false,
      sessiontime: 180,
      timeleft: 240,
    });

    identifyPayerService.refreshToken("1234");
  };

  handleClickOpen = () => {
    this.setState({ open: false });
    window.location.href = "/signin";
  };
  movetolanding = () => {
    sessionStorage.clear();

    window.location.href = "/signin";
  };

  render() {
    return (
      <>
        <ErrorBoundary FallbackComponent={ErrorBoundaryComponent}>
          <Dialog
            fullWidth
            maxWidth="sm"
            className="session-expiry-dialog"
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            {this.state.warning === true ? (
              <DialogContent>
                <DialogTitle id="alert-dialog-title" className="session-expiry-box-styles">
                  Your session is about to expire in 10 minutes
                </DialogTitle>
              </DialogContent>
            ) : (
              <DialogContent>
                <DialogTitle id="alert-dialog-title" className="session-expiry-box-styles">
                  Your session is expired
                </DialogTitle>
              </DialogContent>
            )}

            {this.state.expire === false ? (
              <DialogActions className="login-continue-btn">
                <Button onClick={this.movetolanding} color="primary" size="large" className="themeing-buttons mx-1">
                  Login
                </Button>
                <Button
                  onClick={this.handleClose}
                  color="primary"
                  size="large"
                  variant="outlined"
                  className="secondary-button continue-btn mr-1">
                  Continue
                </Button>
              </DialogActions>
            ) : (
              <DialogActions>
                <Button onClick={this.movetolanding} color="primary" size="large" type="button" className="themeing-buttons">
                  Login
                </Button>
              </DialogActions>
            )}
          </Dialog>
        </ErrorBoundary>
      </>
    );
  }
}

export default withRouter(App);
