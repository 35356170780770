/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from "react";
import { AppContext } from "../../context/AppProvider";
import { useHistory } from "react-router";

const SideBar = ({ logo, children, isSidebarCollapsed }) => {
  const { sidebar } = useContext(AppContext);
  const history = useHistory();
  const role = sessionStorage.getItem("currentUserRole");
  const backTOAccountDeatails = () => {
    if (role === "Customer") {
      history.push("/home/billing");
      sessionStorage.setItem("verifyAndPay", true);
    } else if (role === "Admin") {
      history.push(sessionStorage.getItem("homePage"));
      sessionStorage.setItem("verifyAndPay", "No");
    }
  };
  return (
    <aside>
      <div className={sidebar ? "sidenav-section" : "sidenav-section-collapse"} role="navigation" aria-label="navBar">
        <div className="row ">
          <div className="col-md-12 ">
            <div className="logo-section">
              {isSidebarCollapsed ? (
                <img src={logo} alt="Med-1 Solutions" className="img-fluid" onClick={backTOAccountDeatails} />
              ) : (
                <img src={logo} alt="Med-1 Solutions" className="img-fluid med-logo" onClick={backTOAccountDeatails} />
              )}
            </div>
            {children}
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
