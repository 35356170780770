import React, { useState } from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Checkbox, MenuItem, FormControl, FormControlLabel, Select, FormLabel, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { FormHelperText, IconButton } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ImageTooltip from "../../../components/ImageTooltip";
import echeckPaymentRoutingNumber from "../../../assets/images/echeckPaymentRoutingNumber.jpg";
import { cityData, countryData, allStatesData } from "../../../util/StatesData";
import { useHistory } from "react-router";
import { Grid } from "@material-ui/core";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";

import DateFnsUtils from "@date-io/date-fns";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";

const Echeck = () => {
  const history = useHistory();
  const intialvalues = {
    firstName: "",
    middleName: "",
    lastName: "",
    routingNumber: "",
    accountNumber: "",
    reAccountNumber: "",
    email: "",
    phoneNumber: "",
    message: "",
    accountType: "none",
    country: "usa",
    StateCounty: "IN",
    city: "crystalLake",
    zipCode: "",
    agreeToterms: true,
    agreeToEmailUsage: true,
    futureDate: null,
  };

  const schema = Yup.object().shape({});

  const [isVisible, setIsVisible] = useState({
    routingNumber: true,
    accountNumber: true,
    reAccountNumber: true,
  });

  const isUser = localStorage.getItem("role") === "user";
  const isPA = sessionStorage.getItem("currentUserRole") === "Admin";
  const payNow = history?.location?.state?.payNow;

  const handleRoutingNumber = () => {
    setIsVisible({ ...isVisible, routingNumber: !isVisible.routingNumber });
  };

  const handleAccountNumber = () => {
    setIsVisible({ ...isVisible, accountNumber: !isVisible.accountNumber });
  };
  const handleReAccountNumber = () => {
    setIsVisible({ ...isVisible, reAccountNumber: !isVisible.reAccountNumber });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCancel = () => {
    if (isUser) {
      history.push("/home/billing");
    } else if (isPA) {
      history.push("/pa/billing");
    }
  };

  const onSubmit = async (values, onSubmitProps) => {
    history.push(payNow ? "pay-now-success" : "pay-future-success");
  };

  return (
    <div className="change-password dashboard-content" role="main">
      <div role="heading" aria-level="1">
        {" "}
        <h2 class="content-title">eCheck</h2>
      </div>

      <div className="dashboard-content-card">
        <Formik initialValues={intialvalues} validationSchema={schema} onSubmit={onSubmit}>
          {(props) => {
            const { values, touched, error, handleChange, errors, setFieldValue } = props;
            return (
              <Form className="input-field e-check-bg" noValidate="noValidate">
                <div className="row">
                  <div className="col-md-12 mx-auto">
                    <div className="echeck-card">
                      <div className="account-balance">
                        <p>Total Outstanding Amount</p>
                        <h3>$ 2,200.00</h3>
                      </div>
                      <div className="account-balance mb-4">
                        <p>Amount To Pay</p>
                        <h3>$ 50.00</h3>
                      </div>
                      <div className="row">
                        {!payNow && (
                          <>
                            <div className="col-md-4 date-schedule">
                              <div className="form-group input-field">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <Grid container className="date-picker">
                                    <label htmlFor="futureDate" className="form-label">
                                      Future Date
                                    </label>
                                    <DatePicker
                                      disableFuture
                                      name="futureDate"
                                      autoOk
                                      value={values.futureDate}
                                      onChange={(val) => setFieldValue("futureDate", val)}
                                      renderInput={(params) => <TextField {...params} helperText={null} margin="dense" />}
                                    />
                                  </Grid>
                                </LocalizationProvider>
                              </div>
                            </div>
                            <div className="col-md-6" />
                          </>
                        )}
                        <div className="col-md-4">
                          <div className="form-group">
                            <FormLabel htmlFor="firstName" className="required">
                              First Name
                            </FormLabel>
                            <OutlinedInput
                              className="form-control"
                              name="firstName"
                              value={values.firstName}
                              onChange={handleChange}
                              placeholder="Enter First Name"
                              aria-label="text-field"
                              fullWidth
                              required></OutlinedInput>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <FormLabel htmlFor="middleName" className="required">
                              Middle Name
                            </FormLabel>
                            <OutlinedInput
                              className="form-control"
                              name="middleName"
                              value={values.middleName}
                              onChange={handleChange}
                              placeholder="Enter Middle Name"
                              aria-label="text-field"
                              fullWidth
                              required></OutlinedInput>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <FormLabel htmlFor="lastName" className="required">
                              Last Name
                            </FormLabel>
                            <OutlinedInput
                              className="form-control"
                              name="lastName"
                              value={values.lastName}
                              onChange={handleChange}
                              placeholder="Enter Last Name"
                              aria-label="text-field"
                              fullWidth
                              required></OutlinedInput>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row m-0 justify-content-between align-items-center">
                              <FormLabel htmlFor="routingNumber" className="required">
                                Routing Number
                              </FormLabel>
                              <ImageTooltip img={echeckPaymentRoutingNumber} width="450px" placement="right">
                                <i className="fas fa-question-circle circle-icon" style={{ cursor: "pointer" }}></i>
                              </ImageTooltip>
                            </div>
                            <OutlinedInput
                              className="form-control signup-fields"
                              name="routingNumber"
                              value={values.routingNumber}
                              onChange={handleChange}
                              type={isVisible.routingNumber ? "text" : "password"}
                              placeholder="Enter Routing Number"
                              aria-label="text-field"
                              fullWidth
                              required
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleRoutingNumber}
                                    onMouseDown={handleMouseDownPassword}>
                                    {isVisible.routingNumber ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }></OutlinedInput>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <FormControl variant="outlined" className="w-100" fullWidth>
                              <FormLabel htmlFor="accountType" className="form-label required">
                                Account Type
                              </FormLabel>
                              <Select
                                fullWidth
                                name="accountType"
                                onChange={handleChange("accountType")}
                                value={values.accountType}
                                className="form-select form-control">
                                <MenuItem value="none" disabled>
                                  Select Card Type
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="row m-0 justify-content-between align-items-center">
                              <FormLabel htmlFor="accountNumber" className="required">
                                Account Number
                              </FormLabel>

                              <ImageTooltip img={echeckPaymentRoutingNumber} width="450px" placement="right">
                                <i className="fas fa-question-circle circle-icon" style={{ cursor: "pointer" }}></i>
                              </ImageTooltip>
                            </div>
                            <OutlinedInput
                              className="form-control signup-fields"
                              name="accountNumber"
                              value={values.accountNumber}
                              onChange={handleChange}
                              placeholder="Enter Account Number"
                              aria-label="text-field"
                              fullWidth
                              type={isVisible.accountNumber ? "text" : "password"}
                              required
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleAccountNumber}
                                    onMouseDown={handleMouseDownPassword}>
                                    {isVisible.accountNumber ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }></OutlinedInput>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <FormLabel htmlFor="reAccountNumber" className="required">
                              Re-enter Account Number
                            </FormLabel>
                            <OutlinedInput
                              className="form-control signup-fields"
                              name="reAccountNumber"
                              value={values.reAccountNumber}
                              onChange={handleChange}
                              type={isVisible.reAccountNumber ? "text" : "password"}
                              placeholder="Re-enter Account Number"
                              aria-label="text-field"
                              fullWidth
                              required
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleReAccountNumber}
                                    onMouseDown={handleMouseDownPassword}>
                                    {isVisible.reAccountNumber ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }></OutlinedInput>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <FormLabel htmlFor="emailAddress" className="required">
                              Email Address
                            </FormLabel>
                            <OutlinedInput
                              className="form-control"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              type="email"
                              placeholder="Enter Email Address"
                              aria-label="text-field"
                              fullWidth
                              required></OutlinedInput>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <FormLabel htmlFor="phoneNumber" className="required">
                              Phone Number
                            </FormLabel>
                            <OutlinedInput
                              className="form-control"
                              name="phoneNumber"
                              value={values.phoneNumber}
                              onChange={handleChange}
                              placeholder="Enter Phone Number"
                              aria-label="text-field"
                              fullWidth
                              required></OutlinedInput>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <FormLabel htmlFor="billingAddress" className="required">
                              Billing Address
                            </FormLabel>
                            <OutlinedInput
                              className="form-control"
                              name="message"
                              value={values.message}
                              onChange={handleChange}
                              rows={5}
                              placeholder="Enter Your Billing Address"
                              aria-label="text-field"
                              fullWidth
                              required></OutlinedInput>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <FormControl variant="outlined" className="w-100" fullWidth>
                              <FormLabel htmlFor="country" className="form-label required">
                                Country
                              </FormLabel>
                              <Select
                                fullWidth
                                name="country"
                                value={values.country}
                                onChange={handleChange}
                                className="form-select form-control">
                                <MenuItem value="none" disabled>
                                  Select Country
                                </MenuItem>
                                {countryData.map((option) => {
                                  return (
                                    <MenuItem value={option.key} key={option.key}>
                                      {option.value}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <FormControl variant="outlined" className="w-100" fullWidth>
                              <FormLabel htmlFor="state" className="form-label required">
                                State/County
                              </FormLabel>
                              <Select
                                fullWidth
                                name="StateCounty"
                                value={values.StateCounty}
                                onChange={handleChange}
                                className="form-select form-control">
                                <MenuItem value="none" key="none" disabled>
                                  Select State/County
                                </MenuItem>
                                {allStatesData.map((option) => {
                                  return (
                                    <MenuItem value={option.key} key={option.key}>
                                      {option.value}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <FormControl variant="outlined" className="w-100" fullWidth>
                              <FormLabel htmlFor="city" className="form-label required">
                                City
                              </FormLabel>
                              <Select
                                onChange={handleChange}
                                fullWidth
                                name="city"
                                value={values.city}
                                className="form-select form-control">
                                <MenuItem value="none" key="none" disabled>
                                  Select City
                                </MenuItem>
                                {cityData.map((option) => {
                                  return (
                                    <MenuItem value={option.key} key={option.key}>
                                      {option.value}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <FormLabel htmlFor="zipCode" className="required">
                              ZIP Code
                            </FormLabel>
                            <OutlinedInput
                              className="form-control"
                              name="zipCode"
                              value={values.zipCode}
                              onChange={handleChange}
                              placeholder="Enter ZIP Code"
                              aria-label="text-field"
                              fullWidth
                              required></OutlinedInput>
                          </div>
                        </div>
                      </div>
                      <div className="agree-terms">
                        <div className=" check-FormLabel">
                          <FormControl fullWidth component="fieldset" margin="normal">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="agreeToterms"
                                  checked={values.agreeToterms}
                                  onChange={handleChange}
                                  value={values.agreeToterms}
                                  color="primary"
                                />
                              }
                              label={
                                <span>
                                  By checking this box,you signify you have reviewed,understand,meet and agree to the{" "}
                                  <a href="/">Online Payment Terms and Conditions</a>
                                </span>
                              }
                            />
                          </FormControl>
                        </div>
                        <div className="check-FormLabel">
                          <FormControl fullWidth component="fieldset" margin="normal">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="agreeToEmailUsage"
                                  value={values.agreeToEmailUsage}
                                  checked={values.agreeToEmailUsage}
                                  onChange={handleChange}
                                  color="primary"
                                />
                              }
                              label={
                                <span>
                                  By checking this box,you agree that this is your personal email address and you authorise
                                  us to send you emails,call your phone, or contact you via an automated dialer,leave
                                  voicemails, and/or send text messages if necessary.
                                </span>
                              }
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="proceed-cancel-buttons">
                        <button className="secondary-button" onClick={handleCancel}>
                          Cancel
                        </button>
                        <button className="primary-button">{payNow ? "Proceed to Pay" : "Schedule Payment"}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
        {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
      </div>
    </div>
  );
};

export default Echeck;
