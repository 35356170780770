import React from "react";
import UserName from "./UserName";
import { useSelector } from "react-redux";

const LoginContainer = () => {
  const { securityImage } = useSelector(({ securityImageReducer }) => securityImageReducer);

  return (
    <div>
      <UserName securityImage={securityImage} />
    </div>
  );
};

export default LoginContainer;
