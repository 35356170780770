import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import * as Yup from "yup";
import Med1Logo from "../../../assets/images/Med1Logo.png";
import RocketIcon from "../../../assets/images/quick-pay-rocket-icon.svg";
import PamentMethod from "./PaymentMethodRadio";
import CreditCard from "./CreditCard";
import ECheck from "./ECheck";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  creditcard: {
    textAlign: "left !important",
  },
  inputfield: {
    textAlign: "left !important",
    fontSize: 14,
    color: "#536777",
    fontWeight: "normal",
    paddingTop: 10,
  },
}));

const options = [
  { key: "Master Card", value: "Master Card" },
  { key: "Visa", value: "Visa" },
  { key: "American Card", value: "American Card" },
];

const intialvalues = {
  cardType: "Master Card",
  expiryDate: null,
  cvv: "",
  cardNumber: "",
  nameOnCard: "",
  futureDate: null,
  agree: false,
  country: "usa",
  StateCounty: "IN",
  city: "crystalLake",
  zipCode: "",
  agreeToterms: true,
  agreeToEmailUsage: true,
  billingAddress: "1193 MALVINA AVE",
};

const schema = Yup.object().shape({
  cardType: Yup.string().trim().required("Card Type is required"),
});

const QuickPayPaymentMethod = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [cardImg, setCardImg] = useState("");
  const [payType, setPayType] = useState("creditDebitCards");

  const payNow = history?.location?.state?.payNow;

  const onSubmit = async (values, onSubmitProps) => {
    history.push(payNow ? "/successfull-payment" : "/payment-schedule");
    // dispatch(postLoginDetails({ username: email, password }));
  };

  const handleCancel = () => {
    history.push("/bill-details");
  };

  return (
    <div className="page-wrapper" role="main">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="card-md">
            <div className="header">
              <div className="rocket-icon">
                <img src={RocketIcon} alt="" />
              </div>
              <div className="logo">
                <img src={Med1Logo} className="img-fluid" alt="Med-1 Solutions Logo" />
              </div>
            </div>
            <div className="main-heading">
              <h1>
                Quick<span>Pay</span>
              </h1>
            </div>
            <div className="credit-card-section">
              <div className={classes.creditcard}>
                <h2>Payment Method Information</h2>
                {/* <p className="card-content">Lorem ipsumu dolor sit amet,consectutor sadipscing elitr</p> */}

                <div className="account-balance">
                  <p className="p-0">Total Outstanding Amount</p>
                  <h3>$ 2,200.00</h3>
                </div>
                <div className="account-balance">
                  <p>Amount To Pay</p>
                  <h3 className="pt-3">$ 50.00</h3>
                </div>
                <PamentMethod payType={payType} setPayType={setPayType} />
                <hr className="mb-0" />
                {payType === "creditDebitCards" && <CreditCard />}
                {payType === "eCheck" && <ECheck />}
              </div>
            </div>
          </div>
        </div>
      </div>
      {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
    </div>
  );
};

export default QuickPayPaymentMethod;
