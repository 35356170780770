import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const DialogContent = withStyles((theme) => ({
  root: {
    padding: "20px 10px 10px 15px",
  },
}))(MuiDialogContent);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: 0,
    color: theme.palette.grey[500],
  },
  heading: {
    borderBottom: "1px solid #d3d3d3",
    padding: 10,
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div className={classes.heading}>{children}</div>
      {onClose ? (
        <IconButton style={{ position: "absolute" }} aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ConfirmationDialog = ({
  open,
  handleClose,
  title,
  handleSubmit,
  handleCancel,
  billData,
  children,
  noActions,
  disableBackDropOnClick,
}) => {
  return (
    <Dialog
      disableBackdropClick={disableBackDropOnClick}
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={"sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      {title && (
        <DialogTitle id="alert-dialog-title" class="message-box" onClose={handleClose}>
          {title}
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <div className="proceed-cancel-buttons">
          <button type="button" onClick={handleSubmit} className="primary-button themeing-buttons">
            Yes
          </button>
          <button className="secondary-button" type="button" onClick={handleCancel}>
            No
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
