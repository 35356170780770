import React, { useState, useContext } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getIn } from "formik";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { Grid, TextField } from "@material-ui/core";
import { FormControl, FormHelperText, FormControlLabel, MenuItem, Select } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";
const intialvalues = {
  med1AccNum: "",
  ssn: "",
  patientDOB: null,
  email: "",
  phone: "",
};

const schema = Yup.object().shape({
  med1AccNum: Yup.string().trim().required("Account Number is required"),
});

const LandingPage = () => {
  const onSubmit = async (values, onSubmitProps) => {
  };

  return (
    <div className="page-wrapper" role="main">
      <div className="container-fluid">
        <div className="accordion-card row m-0">
          <div className="col-md-7">
            <Formik initialValues={intialvalues} onSubmit={onSubmit} validationSchema={schema}>
              {(props) => {
                const { values, touched, error, handleChange, errors, setFieldValue } = props;
                return (
                  <Form id="form" className="input-field" noValidate="noValidate">
                    <Accordion className="accordion-section">
                      <AccordionSummary
                        className="accordion-headerup-arrow"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography role="heading" aria-level="1">
                          My Details
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className="col-md-8">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row m-0 justify-content-between align-items-center">
                                <label htmlFor="med1AccountNumber">MED-1 Account Number</label>
                                <i className="fas fa-question-circle circle-icon"></i>
                              </div>
                              <div className="med1-input ">
                                <OutlinedInput
                                  className="form-control"
                                  id="med1AccountNumber"
                                  name="med1AccNum"
                                  placeholder="Please Enter MED-1 Account"
                                  onChange={handleChange}
                                  value={values["med1AccNum"]}
                                  aria-label="text-field"
                                  fullWidth
                                  error={Boolean(getIn(touched, "med1AccNum") && getIn(errors, "med1AccNum"))}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <i className="fas fa-lock lock-icon"></i>
                                    </InputAdornment>
                                  }
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <i className="fas fa-eye eye-icon"></i>
                                    </InputAdornment>
                                  }></OutlinedInput>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="firstName">First Name</label>
                              <OutlinedInput
                                className="form-control"
                                id="firstName"
                                name="firstName"
                                placeholder="Enter First Name"
                                onChange={handleChange}
                                value={values["firstName"]}
                                aria-label="text-field"
                                fullWidth
                                type="firstName"
                                error={Boolean(getIn(touched, "firstName") && getIn(errors, "firstName"))}></OutlinedInput>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="lastName">Last Name</label>
                              <OutlinedInput
                                className="form-control"
                                id="lastName"
                                name="lastname"
                                placeholder="Enter Last Name"
                                onChange={handleChange}
                                value={values["lastname"]}
                                aria-label="text-field"
                                fullWidth
                                type="lastname"
                                error={Boolean(getIn(touched, "lastname") && getIn(errors, "lastname"))}></OutlinedInput>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="socialSecurityNumber">Social Security Number</label>

                              <div className="security-input">
                                <OutlinedInput
                                  className="form-control security-input"
                                  id="socialSecurityNumber"
                                  name="ssn"
                                  placeholder="Please Enter SSN"
                                  onChange={handleChange}
                                  value={values["ssn"]}
                                  aria-label="text-field"
                                  fullWidth
                                  error={Boolean(getIn(touched, "ssn") && getIn(errors, "ssn"))}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <i className="fas fa-eye eye-icon"></i>
                                    </InputAdornment>
                                  }></OutlinedInput>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Grid container>
                                  <label htmlFor="patientDOB" className="form-label">
                                    Start Date
                                  </label>
                                  <DatePicker
                                    disableFuture
                                    className="date-picker"
                                    name="patientDOB"
                                    autoOk
                                    value={values.patientDOB}
                                    onChange={(val) => setFieldValue("patientDOB", val)}
                                    renderInput={(params) => <TextField {...params} helperText={null} margin="normal" />}
                                  />
                                </Grid>
                              </LocalizationProvider>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="emailId">Email Address</label>
                              <OutlinedInput
                                className="form-control security-input"
                                id="emailId"
                                name="email"
                                type="email"
                                placeholder="Please Enter Email"
                                onChange={handleChange}
                                value={values["email"]}
                                aria-label="text-field"
                                fullWidth
                                error={Boolean(getIn(touched, "email") && getIn(errors, "email"))}></OutlinedInput>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="phoneNumber">Phone Number</label>
                              <OutlinedInput
                                className="form-control security-input"
                                id="phoneNumber"
                                name="phone"
                                placeholder="Please Enter Phone Number"
                                onChange={handleChange}
                                value={values["phone"]}
                                aria-label="text-field"
                                fullWidth
                                error={Boolean(getIn(touched, "phone") && getIn(errors, "phone"))}></OutlinedInput>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <button className="secondary-button w-100">Cancel</button>
                          </div>
                          <div className="col-md-7">
                            <button className="primary-button w-100">Save</button>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="accordion-card row m-0">
          <div className="col-md-7">
            <Formik initialValues={intialvalues} onSubmit={onSubmit} validationSchema={schema}>
              {(props) => {
                const { values, touched, error, handleChange, errors, setFieldValue } = props;
                return (
                  <Form id="landing-page" className="input-field" noValidate="noValidate">
                    <Accordion>
                      <AccordionSummary
                        className="up-arrow"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>Preferences</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          <div className=" form-group">
                            <label htmlFor="cars">Choose a car:</label>

                            <select name="cars" id="cars" className="d-block">
                              <option value="volvo">Volvo</option>
                              <option value="saab">Saab</option>
                              <option value="mercedes">Mercedes</option>
                              <option value="audi">Audi</option>
                            </select>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </Form>
                );
              }}
            </Formik>
            {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
