import { Global_var } from "../global/global_var";
import RestDataSource from "./restdatasource";
import { loginService } from "./loginService";

export const resetPasswordService = {
  getResetPassword,
  getResetPasswordByEmail,
};

function getResetPassword(userData, fn) {
  loginService.TokenUser((res) => {
    debugger;
    if (res.status === "success") {
      sessionStorage.setItem("jwt-tokencore", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_RESET_PASSWORD;
      return new RestDataSource(url, 0, fn).Store(userData, (res) => {
        //   heckUserSession.UserSession(res);
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-tokencore", res.responseObject);
          }
          fn(res);
        }
      });
    }
  });
}

function getResetPasswordByEmail(userData, fn) {
  loginService.TokenUser((res) => {
    if (res.status === "success") {
      sessionStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_RESET_PASSWORD_BY_EMAIL;
      return new RestDataSource(url, 0, fn).Store(userData, (res) => {
        //   heckUserSession.UserSession(res);
        if (res != null) {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-token", res.responseObject);
          }
          fn(res);
        }
      });
    }
  });
}
