import React from "react";
import DatePicker from "@material-ui/lab/DatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { Formik, Form, getIn } from "formik";
import { Grid } from "@material-ui/core";
import { IconButton, TextField } from "@material-ui/core";
import { InputLabel, MenuItem, FormControl, Select, Box } from "@material-ui/core";
import { requestInfo_PP } from "../../../../Common/CommonFn";
import { handleSuccess, handleWarning } from "../../../../components/Notifications/Toast";
import SpinnerPage from "../../../../Common/PP_loader";
import * as Yup from "yup";
import { FormHelperText } from "@material-ui/core";

class UnlockUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      isLoading: false,
    };
  }
  _handleSubmit = (values) => {
    this.setState({
      isLoading: true,
    });

    const passValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      userLoginName: values.userName,
      roleLevel: JSON.parse(sessionStorage.getItem("roleData"))?.level,
    };

    this.props.unlockUserData(passValues, (res) => {
      if (res?.data?.responseInfo.status === "SUCCESSFUL") {
        handleSuccess("User has been unlocked successfully");
        this.setState({
          isLoading: false,
        });
      } else if (res?.data?.responseInfo?.status === "FAILED") {
        handleWarning(`${res?.data?.responseInfo?.responseMessage}`);
        this.setState({
          isLoading: false,
        });
      } else {
        handleWarning("User not found");
        this.setState({
          isLoading: false,
        });
      }
    });
    values.userName = "";
  };
  scheema = Yup.object().shape({
    userName: Yup.string().trim().required("Username is required"),
  });
  render() {
    const paymentMethodOptions = [
      { key: "Opt-in", value: "Opt-in" },
      { key: "Opt-out", value: "Opt-out" },
    ];
    return (
      <div>
        <Formik
          initialValues={{
            userName: this.state.userName,
          }}
          validationSchema={this.scheema}
          onSubmit={this._handleSubmit}>
          {(props) => {
            const { values, touched, error, handleChange, errors, setFieldValue } = props;
            return (
              <Form id="transaction-search" className="input-field" noValidate="noValidate">
                {this.state.isLoading && <SpinnerPage />}
                <div className="form-row">
                  <div className="col-lg-3 col-md-4 col-sm-6 form-group">
                    <label htmlFor="userName" className="form-label w-100 required">
                      User Name
                    </label>
                    <Grid container>
                      <TextField
                        id="userName"
                        name="userName"
                        placeholder="Enter User Name"
                        onChange={handleChange}
                        value={values["userName"]}
                        aria-label="text-field"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}></TextField>
                    </Grid>
                    <FormHelperText className="component-error-text">
                      {getIn(touched, "userName") && getIn(errors, "userName") && getIn(errors, "userName")}
                    </FormHelperText>
                  </div>
                </div>
                <div>
                  <button aria-label="Generate Report" type="submit" className="themeing-buttons primary-button">
                    Unlock
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}
export default UnlockUser;
