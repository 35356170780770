export const formatAmount = (value) => {
  return value || value === 0
    ? `$ ${Number(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      })}`
    : "";
};

export const formatAmountWithOutDollar = (value) => {
  return value || value === 0
    ? `${Number(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      })}`
    : "";
};
