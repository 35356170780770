import React, { useEffect, useState } from "react";
import axios from "axios";

import jsonData from "./data.json";
import { Button } from "@material-ui/core";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";

const url = "http://localhost:8088/api/getpatient";

const headers = {
  "Content-Type": "application/json",
};

const data = {
  family: "Argonaut",
  given: "jason",
  identifier: "203579",
};
const data1 = {
  family: "Kirk",
  given: "James",
  identifier: "203604",
};

export default function EPIC() {
  const [loader, setLoader] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [name, setName] = useState("json");

  useEffect(() => {
    const invokeApi = async () => {
      setLoader(true);

      const options = {
        method: "POST",
        headers,
        data: name === "james" ? data1 : data,
        url,
      };

      const { data: resData } = await axios(options);
      setLoader(false);
      setPatientData(resData.patientsInfo ? resData.patientsInfo : []);
    };

    invokeApi();
  }, [name]);

  

  return (
    <div className="dashboard-content" role="main">
      <div role="heading" aria-level="1">
        <h2 className="content-title">EPIC POC</h2>
      </div>
      <div>
        <Button
          variant={name === "json" ? "contained" : "outlined"}
          color="primary"
          onClick={() => setName("json")}
          className="mr-3">
          Json Argonaut
        </Button>
        <Button variant={name === "james" ? "contained" : "outlined"} color="primary" onClick={() => setName("james")}>
          James Kirk
        </Button>
      </div>
      {patientData.map((item, i) => {
        return (
          <div key={i}>
            {patientDemographic(item.patientDemographic, i)}
            {patientCarePlans(item.patientCarePlans, i)}
          </div>
        );
      })}
    </div>
  );
}

const patientDemographic = (data, i) => {
  const { name, identifier, gender, birthDate, maritalStatus, communication, active, careProvider, telecom, address } = data;
  const patientName = name[0].given[0] + " " + name[0].family[0];
  const mrn = identifier[0].value;
  return (
    <div className="mt-3">
      <p className="text-primary">Patient Demographic - {i + 1}</p>
      <div className="bg-white p-3 rounded">
        <div className="d-flex justify-content-between align-items-center border">
          <div className="p-2">Patient Name: </div>
          <div className="p-2 w-75">{patientName}</div>
        </div>
        <div className="d-flex align-items-center justify-content-between border">
          <div className="p-2">Active: </div>
          <div className="p-2 w-75">{active ? "True" : "False"}</div>
        </div>
        <div className="d-flex align-items-center justify-content-between border">
          <div className="p-2">Medical Record Number: </div>
          <div className="w-75">
            {identifier.map((identifier, i) => {
              return (
                <div className="border p-2" key={i}>
                  MRN-{i + 1}:{" " + identifier.value}
                </div>
              );
            })}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center border">
          <div className="p-2">Gender: </div>
          <div className="p-2 w-75">{gender}</div>
        </div>
        <div className="d-flex justify-content-between align-items-center border">
          <div className="p-2">Date of Birth: </div>
          <div className="p-2 w-75">{birthDate}</div>
        </div>
        <div className="d-flex justify-content-between align-items-center border">
          <div className="p-2">Marital Status: </div>
          <div className="p-2 w-75">{maritalStatus.text}</div>
        </div>
        <div className="d-flex align-items-center border justify-content-between">
          <div className="p-2">Spoken Language: </div>
          <div className="p-2 w-75">
            {communication
              ? communication.length > 1
                ? communication.map((lang) => {
                    return lang.language.text + "  ";
                  })
                : communication[0].language.text
              : "--"}
          </div>
        </div>
        <div className="d-flex align-items-center border justify-content-between">
          <div className="p-2">Care Provider: </div>
          <div className="p-2 w-75">
            {careProvider ? (
              careProvider.length > 1 ? (
                careProvider.map((careProv) => {
                  return careProv.display + ", ";
                })
              ) : (
                <div>{careProvider[0].display}</div>
              )
            ) : (
              "--"
            )}
          </div>
        </div>
        <div className="d-flex align-items-center border justify-content-between">
          <div className="p-2">Phone Number: </div>
          <div className="p-2" className="w-75">
            {telecom.length > 1 ? (
              telecom.map((num, i) => {
                return (
                  <div key={i} className="border p-2">
                    {num.use}: &nbsp;{num.value}
                  </div>
                );
              })
            ) : (
              <div>
                {telecom[0].use} : &nbsp; {telecom[0].value}
              </div>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center border justify-content-between">
          <div className="p-2">Address: </div>
          <div className="w-75">
            {address.length > 1 ? (
              address.map((ad) => {
                return (
                  <div key={ad.line} className="border p-2 w-100">
                    {ad.use +
                      ": " +
                      ad.line[0] +
                      ", " +
                      ad.city +
                      ", " +
                      ad.state +
                      ", " +
                      ad.country +
                      ". " +
                      ad.postalCode}
                  </div>
                );
              })
            ) : (
              <div className="border p-2">
                {address[0].line[0] +
                  ", " +
                  address[0].city +
                  ", " +
                  address[0].state +
                  ", " +
                  address[0].country +
                  ". " +
                  address[0].postalCode}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const patientCarePlans = (data, i) => {
  return (
    <div className="my-5">
      <p className="text-primary">Patient Care Plans - {i + 1}</p>

      {data.map((plan, i) => {
        const { activity, addresses, goal, subject } = plan;

        return (
          <div className="bg-white rounded p-3" key={i}>
            <p>Care Plan {i + 1}</p>
            <div className="d-flex justify-content-between align-items-center border">
              <div className="p-2">Subject Name: </div>
              <div className="p-2 w-75 border">{subject.display}</div>
            </div>
            <div className="d-flex align-items-center justify-content-between border">
              <div className="p-2">Activity: </div>

              <div className="w-75">
                {activity != null
                  ? activity.map((act) => {
                      const { category, code } = act.detail;
                      return (
                        <div key={code.text} className="d-flex align-items-center border w-100">
                          <div className="p-2">Category: {category.text}</div>
                          <div className="p-2">{code.text}</div>
                        </div>
                      );
                    })
                  : "--"}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between border">
              <div className="p-2">Addresses: </div>
              <div className="w-75">
                {addresses != null
                  ? addresses.map((add, index) => {
                      return (
                        <div className="border p-2" key={index}>
                          {add.display}
                        </div>
                      );
                    })
                  : "--"}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between border">
              <div className="p-2">Goals: </div>
              <div className="w-75">
                {goal != null
                  ? goal.map((goal) => {
                      return (
                        <div key={goal.display} className="border p-2">
                          {goal.display}
                        </div>
                      );
                    })
                  : "--"}
              </div>
            </div>
          </div>
        );
      })}
      {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
    </div>
  );
};
