import { Global_var } from "../global/global_var";
import RestDataSource from "./restdatasource";

export const campaignTrackingService = {
  getPaymentsReceivedData,
  getPurlPaidData,
  getPurlViewedData,
  getViewedButNotPaidData,
  getCampaignList,
};

function getPaymentsReceivedData(payload, fn, errorCallack) {
  var url = Global_var.BASEURL + Global_var.URL_PAYMENTS_RECEIVED;
  return new RestDataSource(url, null, errorCallack).Store(payload, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        localStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
}

function getPurlPaidData(payload, fn, errorCallback) {
  var url = Global_var.BASEURL + Global_var.URL_PURL_PAID;
  return new RestDataSource(url, null, errorCallback).Store(payload, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        localStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
}
function getPurlViewedData(payload, fn, errorCallback) {
  var url = Global_var.BASEURL + Global_var.URL_PURL_VIEWED;
  return new RestDataSource(url, null, errorCallback).Store(payload, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        localStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
}

// http://localhost:8000/pp-paymenttransaction-service/api/v1/purl/report/portalusage/paid
// http://localhost:8000/pp-paymenttransaction-service/api/v1/purl/report/portalusage/viewed

function getViewedButNotPaidData(payload, fn, errorCallback) {
  var url = Global_var.BASEURL + Global_var.URL_VIEWED_BUT_NOT_PAID;
  return new RestDataSource(url, null, errorCallback).Store(payload, (res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        localStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
}

function getCampaignList(fn, errorCallback) {
  var url = Global_var.BASEURL + Global_var.URL_CAMPAIGN_LIST;
  return new RestDataSource(url, null, errorCallback).GetData((res) => {
    if (res != null) {
      if (res.headers["token"] != null) {
        localStorage.setItem("jwt-tokencore", res.headers.token);
      }
      fn(res);
    }
  });
}
