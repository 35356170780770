/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Tooltip } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import MaterialTable, { MaterialTableProps } from "material-table";
import { TablePagination, TablePaginationProps } from "@material-ui/core";
import { Link } from "react-router-dom";
import { tableIcons } from "../../../../components/TableIcons";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import { Translation } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import PatchedPagination from "../../../../Common/TablePagination";
import { payplanService } from "../../../../services/payplanService";
import { requestInfo_PP } from "../../../../Common/CommonFn";
import { getCreditCardDataList } from "../../../../reducer/pp_PaymentCreditCardReducer";
import { geteChequePaymentList } from "../../../../reducer/pp_eChequePaymentReducer";
import { fetchCreditCardData } from "../../../../action/pp_PaymentCreditCardAction";
import { fetchEchaqueData } from "../../../../action/pp_eChequePaymentAction";
import {
  success,
  successNotification,
  warning,
  error,
  errorNotification,
  warningNotification,
} from "../../../../components/PP_Notification/PP_Notification";
import { DeletePlan } from "./CancelPlan";
import EditPaymentOrder from "./EditPaymentOrder";
import { handleSuccess, handleWarning, ToastContainer, handleError } from "../../../../components/Notifications/Toast";
import ConfirmationDialog from "../../../../components/ConfirmationDialog/index";
const mapStateToProps = (state) => ({
  creditCardData: getCreditCardDataList(state.paymentCreditCardReducer),
  eChequeData: geteChequePaymentList(state.eChequePaymentReducer),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCreditCardData: fetchCreditCardData,
      fetchEchaqueData: fetchEchaqueData,
    },
    dispatch,
  );

const tableTitle = {
  padding: "10px 0",
  color: "#644ad2",
  fontSize: "18px",
  fontWeight: 500,
  alignItems: "center",
  justifyContent: "flex-start",
  display: "flex",
  backgroundColor: "inherit",
};

const tableHeaderStyle = {
  textAlign: "inherit",
  color: "#000000",
  fontWeight: "500",
  padding: "15px",
  fontSize: "14px",
  fontFamily: "Poppins",
  border: "none",
};

const rowStyle = {
  height: "30px",
  fontSize: "14px",
};

const loaderStyles = {
  position: "fixed",
  zIndex: 9999,
  top: "0px",
  left: "0px",
  width: "100%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const connectFunction = connect(mapStateToProps, mapDispatchToProps);
const FuturePaymentMethod = connectFunction(
  ({ creditCardData, eChequeData, fetchCreditCardData, fetchEchaqueData, handleScheduledAmount }) => {
    const history = useHistory();
    const [futurePaymentData, setFuturePaymentData] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [billData, setBillData] = useState(false);
    const [updatePaymentMethod, setUpdatePaymentMethod] = useState(false);
    const [deleteFuturePayment, setDeleteFuturePayment] = useState(false);
    const [dialogopen, setDialogOpen] = useState({
      editDialog: false,
      deleteDialog: false,
    });

    const getCardListData = (data) => {
      const visapattern = /^4[0-9]{12}(?:[0-9]{3})?$/;
      const masterpattern = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/;
      const amexpattern = /^3[47][0-9]{13}$/;

      return (
        data &&
        data.cardAccount &&
        data.cardAccount.length > 0 &&
        data.cardAccount.map((item, index) => {
          if (item.cardType === "Visa") {
            return (
              <MenuItem value={`card&${item.paymentInstrument?.paymentInstrumentId}`} key={index}>
                Visa Card Ending With{" "}
                {item.cardNumber.substring(item.cardNumber.toString().length - 4, item.cardNumber.toString().length)}
              </MenuItem>
            );
          } else if (item.cardType === "Master Card") {
            return (
              <MenuItem value={`card&${item.paymentInstrument?.paymentInstrumentId}`} key={index}>
                Visa Card Ending With{" "}
                {item.cardNumber.substring(item.cardNumber.toString().length - 4, item.cardNumber.toString().length)}
              </MenuItem>
            );
          } else if (item.cardType === "American Express") {
            return (
              <MenuItem value={`card&${item.paymentInstrument?.paymentInstrumentId}`} key={index}>
                Visa Card Ending With{" "}
                {item.cardNumber.substring(item.cardNumber.toString().length - 4, item.cardNumber.toString().length)}
              </MenuItem>
            );
          } else {
            return "";
          }
        })
      );
    };

    const getEchecqueListData = (data) => {
      return (
        data &&
        data.achAccount &&
        data.achAccount.length > 0 &&
        data.achAccount.map((item, index) => {
          return (
            <MenuItem value={`echeque&${item.paymentInstrument?.paymentInstrumentId}`} key={index}>
              eCheck ending with{" "}
              {item.accountNumber.substring(item.accountNumber.toString().length - 4, item.accountNumber.toString().length)}
            </MenuItem>
          );
        })
      );
    };

    const getFuturePayments = () => {
      const partyids = JSON.parse(sessionStorage.getItem("verifypayer"));
      let partyid = partyids.partyId;
      payplanService.getAllFuturePlans(
        requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        partyid,
        1234,
        (res) => {
          if (res.status === 200 && res?.data?.statusCodeValue === 200) {
            setLoader(false);
            setFuturePaymentData(res?.data.body.futurePayments);
            handleScheduledAmount(res?.data.body.scheduledAmount);
          } else {
            handleWarning("Something went wrong!");
            setLoader(false);
            sessionStorage.getItem("currentUserRole") === "Admin"
              ? history.push("/pa/payment-history")
              : history.push("/home/payment-history");
          }
        },
        (error) => {
          handleError("Something went wrong! Please try again");
          setLoader(false);
        },
      );
    };

    const handleEditOrder = (orderId, planId, instrument) => {
      if (instrument.split("&")[0] === "echeque" || instrument === "echeck") {
        if (instrument.split("&")[0] === "echeque") {
          const selectedECheck = eChequeData?.achAccount?.filter((item) => {
            return item.paymentInstrument?.paymentInstrumentId === Number(instrument.split("&")[1]);
          });

          history.push({
            pathname: sessionStorage.getItem("currentUserRole") === "Admin" ? "add-echeck" : "/home/add-echeck",
            state: { page: "FuturePayment", orderId, planId, selectedECheck },
          });
        } else {
          history.push({
            pathname: sessionStorage.getItem("currentUserRole") === "Admin" ? "add-echeck" : "/home/add-echeck",
            state: { page: "FuturePayment", orderId, planId },
          });
        }
      } else {
        if (instrument.split("&")[0] === "card") {
          const selectedCard = creditCardData?.cardAccount?.filter((item) => {
            return item.paymentInstrument?.paymentInstrumentId === Number(instrument.split("&")[1]);
          });
          history.push({
            pathname: sessionStorage.getItem("currentUserRole") === "Admin" ? "credit-Card" : "/home/credit-Card",
            state: { page: "FuturePayment", orderId, selectedCard },
          });
        } else {
          history.push({
            pathname: sessionStorage.getItem("currentUserRole") === "Admin" ? "credit-Card" : "/home/credit-Card",
            state: { page: "FuturePayment", orderId },
          });
        }
      }
    };

    const handleDeleteOrder = (paymentOrderId) => {
      setLoader(true);
      payplanService.deleteSingleOrder(
        requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
        paymentOrderId,
        1234,
        (res) => {
          if (res.status === 200 && res.data === "deleted successfully") {
            setLoader(false);
            handleClose();
            getFuturePayments();
            handleSuccess(`Order ${paymentOrderId} Cancelled Successfully`);
          } else {
            setLoader(false);
            handleWarning("Something went wrong!");
          }
        },
        (error) => {
          handleError("Something went wrong! Please try again");
          setLoader(false);
        },
      );
    };

    const handleClose = () => {
      setDialogOpen({
        editDialog: false,
        deleteDialog: false,
      });
    };

    const handleDialogOpen = (data, type) => {
      type === "edit"
        ? setDialogOpen({
            editDialog: true,
          })
        : setDialogOpen({
            deleteDialog: true,
          });
      setBillData(data);
    };

    useEffect(() => {
      var userpartyinfo = JSON.parse(sessionStorage.getItem("verifypayer"));
      if (userpartyinfo !== null) {
        let CardPassValues = {
          requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
          paymentInfoSearch: {
            paymentMethodId: "75001",
            partyId: userpartyinfo.partyId,
          },
        };

        let EChequePassValues = {
          requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
          paymentInfoSearch: {
            paymentMethodId: 75003,
            partyId: userpartyinfo.partyId,
          },
        };
        fetchCreditCardData(CardPassValues, 1234);
        fetchEchaqueData(EChequePassValues, 1234);
      }
    }, []);

    useEffect(() => {
      setLoader(true);
      getFuturePayments();

      const getRoleData = JSON.parse(sessionStorage.getItem("roleData"))?.pages;

      for (let i in getRoleData) {
        if (getRoleData[i]?.functionalities?.["Payment methods"]?.["update"] === "true") {
          sessionStorage.getItem("currentUserRole") === "Admin" && setUpdatePaymentMethod(true);
        }
      }
      for (let i in getRoleData) {
        if (getRoleData[i]?.functionalities?.["Pay in Future"]?.["delete"] === "true") {
          sessionStorage.getItem("currentUserRole") === "Admin" && setDeleteFuturePayment(true);
        }
      }
    }, []);

    const columns = [
      {
        title: "Order Id",
        field: "orderid",
        cellStyle: { padding: "0px 25px" },
      },
      {
        title: "Payment Arrangement",
        field: "paymentarrangement",
        cellStyle: { padding: "0px 80px" },
        render: (rowData) => {
          return rowData.paymentarrangement !== "S" ? (
            <Link to={`/home/payment-plan-details?${rowData.paymentarrangement}`}>{rowData.paymentarrangement}</Link>
          ) : (
            <Tooltip title={"Single Payment"}>
              <b>{rowData.paymentarrangement}</b>
            </Tooltip>
          );
        },
      },
      {
        title: "Payment Type",
        field: "paymenttype",
      },
      {
        title: "Date of Payment",
        field: "dateofpayment",
        cellStyle: { padding: "0px 35px", textAlign: "center" },
      },
      {
        title: "Plan Amount",
        field: "planamount",
        cellStyle: { padding: "0px 50px", textAlign: "right" },
        headerStyle: {
          textAlign: "right",
        },
      },
      {
        title: "Status",
        field: "status",
      },
    ];

    const options = {
      selection: false,
      search: true,
      emptyRowsWhenPaging: false,
      headerStyle: tableHeaderStyle,
      rowStyle: rowStyle,
      rowsPerPage: 5,
      rowsPerPageOptions: [5, 10, 20, 30],
      onChangeRowsPerPage: () => {
        return 5;
      },
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
    };

    const actions = [
      {
        icon: () => <EditIcon className="paymentplan-icons" />,
        tooltip: "Edit Payment",
        disabled: sessionStorage.getItem("currentUserRole") === "Admin" ? !updatePaymentMethod : false,
        onClick: (event, rowData) => {
          return handleDialogOpen(rowData, "edit");
        },
      },
      {
        icon: () => <CancelIcon className="paymentplan-icons" />,
        tooltip: "Cancel Payment",
        disabled: sessionStorage.getItem("currentUserRole") === "Admin" ? !deleteFuturePayment : false,
        onClick: (event, rowData) => {
          return handleDialogOpen(rowData, "delete");
        },
      },
    ];

    useEffect(() => {
      const PaymentOrderData =
        futurePaymentData &&
        futurePaymentData.map((data) => {
          const {
            paymentOrderId,
            recurringPaymentId,
            paymentDate,
            paymentType,
            cardNumber,
            paymentOrderAmount,
            paymentOrderStatus,
          } = data;
          return {
            orderid: paymentOrderId,
            paymentarrangement: recurringPaymentId ? recurringPaymentId : "S",
            paymenttype:
              (paymentType === "Card" ? "Card" : paymentType === "Echeck" ? "eCheck" : paymentType) +
              (" ending with " + cardNumber?.substr(-4)),
            dateofpayment: moment(paymentDate).format("MM/DD/YYYY"),
            planamount:
              "$ " +
              paymentOrderAmount.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              }),
            status: paymentOrderStatus ? paymentOrderStatus : "Scheduled",
          };
        });
      setRowData(PaymentOrderData);
    }, [futurePaymentData]);

    return (
      <>
        <Grid item xs={12}>
          <div className="payplan-table futurepayment-table">
            <MaterialTable
              title={<div style={tableTitle}>Future Payment</div>}
              aria-level="2"
              localization={{}}
              columns={columns}
              icons={tableIcons}
              data={rowData}
              actions={actions}
              options={options}
              components={{
                Pagination: PatchedPagination,
              }}
            />
          </div>
        </Grid>

        <ConfirmationDialog
          open={dialogopen.editDialog || dialogopen.deleteDialog}
          handleClose={handleClose}
          handleSubmit={() => handleDeleteOrder(billData.orderid)}
          fullWidth={true}
          title={
            (dialogopen.editDialog && "Are you sure you want to edit Payment Method for this order?") ||
            (dialogopen.deleteDialog && "Are you sure you want to delete the Order?")
          }
          noActionButtons={dialogopen.editDialog ? true : false}>
          {dialogopen.deleteDialog && <DeletePlan plan={billData.paymentarrangement} billData={billData} />}
          {dialogopen.editDialog && (
            <EditPaymentOrder
              billData={billData}
              creditCardData={creditCardData}
              eChequeData={eChequeData}
              getCardListData={getCardListData}
              getEchecqueListData={getEchecqueListData}
              handleClose={handleClose}
              handleEditOrder={handleEditOrder}
            />
          )}
        </ConfirmationDialog>

        {loader && (
          <div style={loaderStyles}>
            <CircularProgress />
          </div>
        )}
        {/* <ToastContainer /> */}
      </>
    );
  },
);
export default FuturePaymentMethod;
