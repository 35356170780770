import { TwoFactorService } from "../services/twoFactorService";

export const GET_QR_CODE = "GET_QR_CODE";
export const VERIFY_QR_CODE = "VERIFY_QR_CODE";
export const DISABLE_2FA = "DISABLE_2FA";

export function getQrImg(userData, fn) {
  return (dispatch) => {
    dispatch({
      type: GET_QR_CODE,
      payload: userData,
    });
    TwoFactorService.getQrcode(userData, (res) => fn(res));
  };
}

export function verifyQr(userData, fn) {
  return (dispatch) => {
    dispatch({
      type: VERIFY_QR_CODE,
      payload: userData,
    });
    TwoFactorService.verifyQrcode(userData, (res) => fn(res));
  };
}

export function disable2Fa(userData, fn) {
  return (dispatch) => {
    dispatch({
      type: DISABLE_2FA,
      payload: userData,
    });
    TwoFactorService.disableTwoFactor(userData, (res) => fn(res));
  };
}
