import React from "react";
import { Link } from "react-router-dom";

import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";

export const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: " 100% !important",
        },
        root: {
          backgroundColor: "#cccccc",
          fontSize: "14px",
          borderTopWidth: 1,
          borderColor: "red",
          borderStyle: "solid",
        },
        paper: {
          boxShadow: "none",
        },
        responsiveStacked: {
          maxHeight: "none",
          overflowX: "auto",
        },
      },
      MUIDataTableBodyCell: {
        root: {
          backgroundColor: "white",
          fontSize: "14px",
        },
      },
      MUIDataTableHeadCell: {
        root: {
          backgroundColor: "#eeeeee",
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
    },
  });
