import React, { useState, createRef, useRef } from "react";
import { tableIcons } from "../../../../../components/TableIcons";
import MaterialTable from "material-table";
import { handleError } from "../../../../../components/Notifications/Toast";
import PatchedPagination from "../../../../../Common/TablePagination";
import { httpPaginationRequest } from "../../../../../Common/httpPaginationRequest";
import { Global_var } from "../../../../../global/new _admin_globalvar";

const PaymentPlanDetailsPopup = ({ paymentPlanId, handleClose }) => {
  const tableRef = createRef();
  const [rows, setRows] = useState(5);
  const page = useRef(null);

  const authStatus = [
    { status: 80001, value: "INITIATED" },
    { status: 80002, value: "SUCCESS" },
    { status: 80003, value: "FAILED" },
    { status: 80004, value: "PENDING" },
    { status: 80005, value: "SETTLED" },
    { status: 80006, value: "REJECTED" },
    { status: 80007, value: "DENIED" },
  ];
  const getValue = (status) => {
    const property = authStatus?.find((obj) => {
      if (obj.status === status) {
        return obj;
      } else {
        return null;
      }
    });
    return property?.value;
  };

  const statusArray = [
    { status: "78000", value: "Pending" },
    { status: "78001", value: "Completed" },
    { status: "78002", value: "Cancelled" },
    { status: "78003", value: "Failed" },
    { status: "78004", value: "Future" },
  ];
  const getStatusValue = (status) => {
    const property = statusArray?.find((obj) => {
      if (obj.status === status) {
        return obj;
      } else {
        return null;
      }
    });
    return property?.value;
  };

  const columns = [
    {
      title: "Mapped Order Id",
      field: "mappedOrderNumber",
      render: (rowData) => (rowData.mappedOrderNumber ? rowData.mappedOrderNumber : "--"),
    },
    {
      title: "Payment Date",
      field: "paymentTxnDatetime",
      render: (rowData) => (rowData.paymentTxnDatetime ? rowData.paymentTxnDatetime : "--"),
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) => (rowData?.status ? getStatusValue(rowData?.status) : "--"),
    },
    {
      title: "Gateway Transaction Id",
      field: "paymentGatewayTxnRefNo",
      render: (rowData) => (rowData?.paymentGatewayTxnRefNo ? rowData?.paymentGatewayTxnRefNo : "--"),
    },
    {
      title: "Auth Status",
      field: "paymentGatewayStatus",
      render: (rowData) => (rowData?.paymentGatewayStatus ? getValue(rowData?.paymentGatewayStatus) : "--"),
    },
    {
      title: "Settlement Date",
      field: "settlementDatetime",
      render: (rowData) => (rowData?.settlementDatetime ? rowData?.settlementDatetime : "--"),
    },
    {
      title: "Settlement Status",
      field: "settlementStatus",
      render: (rowData) => (rowData?.settlementStatus ? rowData?.settlementStatus : "--"),
    },
  ];

  return (
    <>
      <div className="payplan-table">
        <MaterialTable
          title=""
          localization={{
            pagination: {
              firstAriaLabel: "First Page",
              previousAriaLabel: "Previous Page",
              nextAriaLabel: "Next Page",
              lastAriaLabel: "Last Page",
            },
          }}
          columns={columns}
          tableRef={tableRef}
          icons={tableIcons}
          data={(query) =>
            new Promise((resolve) => {
              if (!paymentPlanId) {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0,
                });
              } else {
                page.current = query.page;
                const passValues = {
                  reccuringPaymentId: paymentPlanId,
                  pageNo: query?.page,
                  pageSize: query.pageSize,
                };
                httpPaginationRequest(Global_var.URL_PAYMENT_PLANS_REPORT_DETAILS, passValues, (res) => {
                  if (res?.status === 200 && res.data?.responseInfo?.status === "SUCCESSFUL") {
                    resolve({
                      data: res?.data?.paymentPlanOrderResponse ? res?.data?.paymentPlanOrderResponse : [],
                      page: page.current,
                      totalCount: Number(res.data.totalCount),
                    });
                  } else {
                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0,
                    });
                    handleError(res?.data?.responseInfo?.responseMessage?.[0]);
                  }
                });
              }
            })
          }
          options={{
            selection: false,
            search: true,
            emptyRowsWhenPaging: false,
            sorting: false,
            draggable: false,
            pageSize: rows,
            pageSizeOptions: [5, 10, 15, 20],
          }}
          onChangeRowsPerPage={(params) => {
            setRows(params);
          }}
          components={{
            Pagination: PatchedPagination,
          }}
        />
      </div>
      <div className="my-2 d-flex justify-content-center">
        <button size="small" className="secondary-button mx-2" onClick={handleClose}>
          Close
        </button>
      </div>
    </>
  );
};

export default PaymentPlanDetailsPopup;
