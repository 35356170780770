import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import moment from "moment";
import logo from "../../../../assets/images/Med1Logo.png";
import paymentscheduled from "../../../../assets/images/paymentscheduled.png";
import HeaderQP from "../../../../components/admin_header/headerQP";
import Admin_Footer from "../../../../components/admin_footer/footer";
import { updateCampignViewPayment } from "../../../../util/purlFunctions";
import UpdateTrackingPage from "../../../../containers/UpdateTrackingPage";

const PaymentSchedule2 = (props) => {
  const history = useHistory();

  useEffect(() => {
    const campaignCode = sessionStorage.getItem("campaignCode");

    let passValuesUCVPC = {
      campaignCode: campaignCode || "",
      campaignViewId: sessionStorage.getItem("campaignViewId") ? JSON.parse(sessionStorage.getItem("campaignViewId")) : "", //mandatory
      medAccountNumber: sessionStorage.getItem("payerinfo")
        ? JSON.parse(sessionStorage.getItem("payerinfo"))?.accountReferenceNumber
        : "", // Non-Mandatory
      paidAmount: 0, //mandatory
      partyId: sessionStorage.getItem("order_FP")
        ? JSON.parse(sessionStorage.getItem("order_FP"))[0].partyId
        : JSON.parse(sessionStorage.getItem("verifypayer"))
        ? JSON.parse(sessionStorage.getItem("verifypayer")).partyId
        : null, //mandatory
      payPlanAdded: "Y", //mandatory
      paymentComplete: "N", //mandatory
      scheduledAmount: sessionStorage.getItem("amounttopay") ? JSON.parse(sessionStorage.getItem("amounttopay")) : null,
    };
    updateCampignViewPayment(passValuesUCVPC, (res) => {
    });
  }, []);
  return (
    <>
      <HeaderQP />
      <div>
        <div className="successful-payment-container">
          <div className="scheduled-content">
            <h2 className="heading-style">Payment Scheduled Successfully</h2>
            <h3 class="payment-processed">
              Your payment has been successfully scheduled for processing on
              <p>{moment(history?.location?.state?.futureDate).format("MM/DD/YYYY")}.</p>
            </h3>

            <button
              onClick={() => {
                sessionStorage.getItem("loginDetails")
                  ? history.push({
                      pathname:
                        sessionStorage.getItem("currentUserRole") === "Admin" ? "/pa/billing" : "/home/payment-history",
                      state: {
                        payInFuture: true,
                        PassValues: {},
                      },
                    })
                  : history.push({
                      pathname: "/payment-outstanding",
                      state: {
                        payInFuture: true,
                        PassValues: {},
                      },
                    });
                // history.push({
                //   pathname:
                //     sessionStorage.getItem("currentUserRole") === "Admin" ? "/pa/payment-history" : "/home/payment-history",
                //   state: {
                //     payInFuture: true,
                //     PassValues: {},
                //   },
                // });
              }}
              className="btn themeing-buttons primary-button scheduled-buttons mt-4">
              Go Back
            </button>
          </div>

          <div className="payment-banner-img">
            <img src={paymentscheduled} alt="payment" className="img-fluid payment-scheduled-img" />
          </div>
        </div>
        <div className="footer-section-cp ml-0">
          <Admin_Footer />
        </div>
        {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
      </div>
    </>
  );
};

export default PaymentSchedule2;
