import React from "react";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { withStyles, Button } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
const DialogContent = withStyles((theme) => ({
  root: {
    padding: "20px 10px 10px 15px",
  },
}))(MuiDialogContent);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {},
  heading: {
    borderBottom: "1px solid #d3d3d3",
    padding: 10,
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
});

const PaymentConfirmationDialog = ({ open, handleClose, handleContinue }) => {
  return (
    <Dialog
      disableBackdropClick
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="dialog-box">
      <DialogContent>
        {" "}
        <div className="modal-body">
          You already made a payment today. Do you want to make another payment?
          <div className="d-flex flex-row-reverse">
            <DialogActions>
              <Button onClick={handleContinue} type="button" className="btn themeing-buttons mr-2">
                YES
              </Button>
            </DialogActions>
            <DialogActions>
              <Button onClick={handleClose} type="button" className="btn themeing-buttons mr-2">
                NO
              </Button>
            </DialogActions>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentConfirmationDialog;
