/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { Box, IconButton, Button, makeStyles, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { ThemeContext } from "./ThemeProvider";
import ThemeCard from "./ThemeCard";

const useStyles = makeStyles((theme) => ({
  customizerRoot: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
  },
  customizerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 8px 16px 16px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.background.paper,
    minHeight: 64,
    position: "relative",
    zIndex: 1,
    [theme.breakpoints.up("md")]: {
      minHeight: 72,
    },
    "& .MuiTypography-body1": {
      fontSize: "1.25rem",
      fontWeight: "500",
      lineHeight: "1.5",
      letterSpacing: "1px",
    },
  },
  customizerContentArea: {
    flex: 1,
    padding: "24px 16px 16px",
    "& > *": {
      marginBottom: 16,
    },
  },
  scrollbarRoot: {
    height: "calc(100vh - 64px)",
    overflowX: "hidden",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 72px)",
    },
  },
  checkIcon: {
    color: "#f5f5f5",
  },
}));

const CustomizerContent = ({ onClose, children }) => {
  const classes = useStyles();

  const { toggleTheme, themeMode, sidebarTheme, toggleSideBarTheme, toggleBorderRadius } = useContext(ThemeContext);

  const handleThemeChange = (theme) => {
    toggleTheme(theme);
  };

  const handleToggleRadius = (radius) => {
    toggleBorderRadius(radius);
  };

  const handleSidebarTheme = (theme) => {
    toggleSideBarTheme(theme);
  };

  return (
    <Box className={classes.customizerRoot}>
      <Box className={classes.customizerHeader}>
        <Typography>Theme Settings</Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={classes.scrollbarRoot}>
        <Box className={classes.customizerContentArea}>
          <div>
            <ThemeCard header="Theme colors" reset="color">
              <div className="d-flex justify-content-between p-2">
                <Button
                  className={themeMode === "darkTheme" ? "active-theme dark-btn" : "dark-btn"}
                  onClick={() => {
                    handleThemeChange("darkTheme");
                  }}>
                  {/* Dark */}
                  {themeMode === "darkTheme" && <CheckBoxIcon />}
                </Button>

                <Button
                  className={themeMode === "lightTheme" ? "light-btn active-theme" : "light-btn"}
                  onClick={() => handleThemeChange("lightTheme")}>
                  {/* Light */}
                  {themeMode === "lightTheme" && <CheckBoxIcon />}
                </Button>

                <Button
                  className={themeMode === "redTheme" ? "red-btn active-theme" : "red-btn"}
                  onClick={() => handleThemeChange("redTheme")}>
                  {/* Red */}
                  {themeMode === "redTheme" && <CheckBoxIcon />}
                </Button>

                <Button
                  className={themeMode === "greenTheme" ? "green-btn active-theme" : "green-btn"}
                  onClick={() => handleThemeChange("greenTheme")}>
                  {/* Green */}
                  {themeMode === "greenTheme" && <CheckBoxIcon />}
                </Button>
                <Button
                  className={themeMode === "darkenBlueTheme" ? "darkenblue-btn active-theme" : "darkenblue-btn"}
                  onClick={() => handleThemeChange("darkenBlueTheme")}>
                  {themeMode === "darkenBlueTheme" && <CheckBoxIcon />}
                </Button>
                <Button
                  className={themeMode === "blueGreyTheme" ? "blue-grey-btn active-theme" : "blue-grey-btn"}
                  onClick={() => handleThemeChange("blueGreyTheme")}>
                  {themeMode === "blueGreyTheme" && <CheckBoxIcon />}
                </Button>
              </div>
            </ThemeCard>

            <ThemeCard header="Font color" reset="sidebar">
              <div className="d-flex justify-content-between p-2">
                <Button
                  className="theme-sidebar-btn black-btn-sidebar"
                  onClick={() => handleSidebarTheme("blackSideBarTheme")}>
                  {sidebarTheme === "blackSideBarTheme" && <CheckCircleIcon className={classes.checkIcon} />}
                </Button>
                <Button
                  className="theme-sidebar-btn lightBlue-btn-sidebar"
                  onClick={() => handleSidebarTheme("lightBlueSideBarTheme")}>
                  {sidebarTheme === "lightBlueSideBarTheme" && <CheckCircleIcon className={classes.checkIcon} />}
                </Button>
                <Button
                  className="theme-sidebar-btn darkBlue-btn-sidebar"
                  onClick={() => handleSidebarTheme("darkBlueSideBarTheme")}>
                  {sidebarTheme === "darkBlueSideBarTheme" && <CheckCircleIcon className={classes.checkIcon} />}
                </Button>

                <Button className="theme-sidebar-btn red-btn-sidebar" onClick={() => handleSidebarTheme("redSideBarTheme")}>
                  {sidebarTheme === "redSideBarTheme" && <CheckCircleIcon className={classes.checkIcon} />}
                </Button>
                <Button
                  className="theme-sidebar-btn green-btn-sidebar"
                  onClick={() => handleSidebarTheme("greenSideBarTheme")}>
                  {sidebarTheme === "greenSideBarTheme" && <CheckCircleIcon className={classes.checkIcon} />}
                </Button>
                <Button
                  className="theme-sidebar-btn skyblue-btn-sidebar"
                  onClick={() => handleSidebarTheme("skyBlueSideBarTheme")}>
                  {sidebarTheme === "skyBlueSideBarTheme" && <CheckCircleIcon className={classes.checkIcon} />}
                </Button>
              </div>
            </ThemeCard>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomizerContent;
