import RestDataSource from "./restdatasource";
import { Global_var } from "../global/global_var";
import { loginService } from "./loginService";
import {
  error,
  success,
  warning,
  warningNotification,
  errorNotification,
  successNotification,
} from "../components/PP_Notification/PP_Notification";

export const payplanService = {
  savePayPlan,
  getPayPlan,
  getAllPayPlans,
  getAllFuturePlans,
  getSinglePayPlanDetails,
  updatePaymentPlan,
  updateSingleOrder,
  deletePayPlan,
  deleteSingleOrder,
  editPayPlan,
};

// API for save payment plan, weekly, bi-weekly, monthly
function savePayPlan(recurringPayment, userId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_SAVE_PAYMENT_PLAN;
  return new RestDataSource(url, userId, fn).Store(
    recurringPayment,
    (res) => {
      if (res != null) {
        if (res["data"].status === "fail") {
          if (res["data"].reasonText === "Invalid Token") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else if (res["data"].reasonCode === "SM-02") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else if (res["data"].reasonCode === "SM-10") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else {
            warning("Something went wrong!" + res["data"].reasonText, warningNotification);
            window.location("/");
          }
        } else {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-token", res.headers.token);
          }
          fn(res);
        }
      }
    },
    fnError,
  );
}

function editPayPlan(recurringPayment, userId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_EDIT_PAYMENT_PLAN;
  return new RestDataSource(url, userId, fn).Store(
    recurringPayment,
    (res) => {
      if (res != null) {
        if (res["data"].status === "fail") {
          if (res["data"].reasonText === "Invalid Token") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else if (res["data"].reasonCode === "SM-02") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else if (res["data"].reasonCode === "SM-10") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else {
            warning("Something went wrong!" + res["data"].reasonText, warningNotification);
            window.location("/");
          }
        } else {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-token", res.headers.token);
          }
          fn(res);
        }
      }
    },
    fnError,
  );
}

// API for get payplan Info according to hospital
function getPayPlan(seedAccountNumber, userId, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_PAYMENT_PLAN;
  return new RestDataSource(url, userId, fn).Store(
    seedAccountNumber,
    (res) => {
      if (res != null) {
        if (res["data"].status === "fail") {
          if (res["data"].reasonText === "Invalid Token") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else if (res["data"].reasonCode === "SM-02") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else if (res["data"].reasonCode === "SM-10") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else {
            warning("Something went wrong!" + res["data"].reasonText, warningNotification);
            window.location("/");
          }
        } else {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-token", res.headers.token);
          }
          fn(res);
        }
      }
    },
    fnError,
  );
}

// API to get all Pay plans based on payerPartyId
function getAllPayPlans(passValues, payerpartyId, userId, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_GET_ALL_PAYPLAN + `/${payerpartyId}`;
  return new RestDataSource(url, userId, fn).Store(
    passValues,
    (res) => {
      if (res != null) {
        if (res["data"].status === "fail") {
          if (res["data"].reasonText === "Invalid Token") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else if (res["data"].reasonCode === "SM-02") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else if (res["data"].reasonCode === "SM-10") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else {
            warning("Something went wrong!" + res["data"].reasonText, warningNotification);
            window.location("/");
          }
        } else {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-token", res.headers.token);
          }
          fn(res);
        }
      }
    },
    fnError,
  );
}

// API to get all future payment orders
function getAllFuturePlans(passValues, recurringPaymentId, userid, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_GET_FUTURE_PAYPLANS + `/${recurringPaymentId}`;
  return new RestDataSource(url, userid, fn).Store(
    passValues,
    (res) => {
      if (res != null) {
        if (res["data"].status === "fail") {
          if (res["data"].reasonText === "Invalid Token") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else if (res["data"].reasonCode === "SM-02") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else if (res["data"].reasonCode === "SM-10") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else {
            warning("Something went wrong!" + res["data"].reasonText, warningNotification);
            window.location("/");
          }
        } else {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-token", res.headers.token);
          }
          fn(res);
        }
      }
    },
    fnError,
  );
}

// API to get payplan details by payplanId need to change to GET
function getSinglePayPlanDetails(passValues, payplanId, userid, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_GET_SINGLE_PAYPLAN + `/${payplanId}`;
  return new RestDataSource(url, userid, fn).Store(
    passValues,
    (res) => {
      if (res != null) {
        if (res["data"].status === "fail") {
          if (res["data"].reasonText === "Invalid Token") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else if (res["data"].reasonCode === "SM-02") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else if (res["data"].reasonCode === "SM-10") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else {
            warning("Something went wrong!" + res["data"].reasonText, warningNotification);
            window.location("/");
          }
        } else {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-token", res.headers.token);
          }
          fn(res);
        }
      }
    },
    fnError,
  );
}

// API to update the entire payment plan
function updatePaymentPlan(passvalues, payplanId, instrumentId, userid, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_UPDATE_PAYMENT_PLAN + `/${payplanId}` + `/${instrumentId}`;
  return new RestDataSource(url, userid, fn).Store(
    passvalues,
    (res) => {
      if (res != null) {
        if (res["data"].status === "fail") {
          if (res["data"].reasonText === "Invalid Token") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else if (res["data"].reasonCode === "SM-02") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else if (res["data"].reasonCode === "SM-10") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else {
            warning("Something went wrong!" + res["data"].reasonText, warningNotification);
            window.location("/");
          }
        } else {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-token", res.headers.token);
          }
          fn(res);
        }
      }
    },
    fnError,
  );
}

// API to update one payment order
function updateSingleOrder(passvalues, orderId, instrumentId, userid, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_UPDATE_RECURRING_PAYMENT + `/${orderId}` + `/${instrumentId}`;
  return new RestDataSource(url, userid, fn).Store(
    passvalues,
    (res) => {
      if (res != null) {
        if (res["data"].status === "fail") {
          if (res["data"].reasonText === "Invalid Token") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else if (res["data"].reasonCode === "SM-02") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else if (res["data"].reasonCode === "SM-10") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else {
            warning("Something went wrong!" + res["data"].reasonText, warningNotification);
            window.location("/");
          }
        } else {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-token", res.headers.token);
          }
          fn(res);
        }
      }
    },
    fnError,
  );
}

// API to delete single payplan
function deletePayPlan(passValues, payplanId, userid, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_DELETE_PAYPLAN + `/${payplanId}`;
  return new RestDataSource(url, userid, fn).Store(
    passValues,
    (res) => {
      if (res != null) {
        if (res["data"].status === "fail") {
          if (res["data"].reasonText === "Invalid Token") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else if (res["data"].reasonCode === "SM-02") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else if (res["data"].reasonCode === "SM-10") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else {
            warning("Something went wrong!" + res["data"].reasonText, warningNotification);
            window.location("/");
          }
        } else {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-token", res.headers.token);
          }
          fn(res);
        }
      }
    },
    fnError,
  );
}

// API to delete Single Payment Order
function deleteSingleOrder(passValues, paymentOrderId, userid, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_DELETE_RECURRING_PAYMENT + `/${paymentOrderId}`;
  return new RestDataSource(url, userid, fn).Store(
    passValues,
    (res) => {
      if (res != null) {
        if (res["data"].status === "fail") {
          if (res["data"].reasonText === "Invalid Token") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else if (res["data"].reasonCode === "SM-02") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else if (res["data"].reasonCode === "SM-10") {
            sessionStorage.clear();
            warning("Token Expired, Login/Verify again", warningNotification);
            window.location.replace("/");
          } else {
            warning("Something went wrong!" + res["data"].reasonText, warningNotification);
            window.location("/");
          }
        } else {
          if (res.headers["token"] != null) {
            sessionStorage.setItem("jwt-token", res.headers.token);
          }
          fn(res);
        }
      }
    },
    fnError,
  );
}
