export const Global_var = {
  //Client Machine

  // BASEURL: "https://uatservices.pdsnew.com",
  // BASEURL: "https://stagingservices.pdsnew.com",
  // BASEURL: "https://prodservices.pdsnew.com",
  // BASEURL: "https://cpdevcoreservices.pdsnew.com",
  // BASEURL: "https://uatservices.pdsnew.com",

  BASEURL: (function () {
    if (window.location.hostname === "cpdevadmin.pdsnew.com") return "https://cpdevcoreservices.pdsnew.com";
    // else if (window.location.hostname === "uatadmin.pdsnew.com") return "https://uatservices.pdsnew.com";
    else if (window.location.hostname === "stagingpp2.pdsnew.com") return "https://stagingservices.pdsnew.com";
    else if (window.location.hostname === "med1solutions.pdsnew.com") return "https://prodservices.pdsnew.com";
    else if (window.location.hostname === "med1solutions.pdsnew.com") return "https://prodservices.pdsnew.com";
    else return "https://testcoreservices.pdsnew.com";
  })(),

  //Reports
  URL_PP_ACCOUNTING_REPORT: "/pp-paymenttransaction-service/report/api/v1/reconcilationReport",
  URL_RECONCILIATION_ORDER_REPORT: "/pp-paymenttransaction-service/report/api/v1/reconciliationOrderReport",
  URL_PAYMENT_PLANS_REPORT: "/pp-paymenttransaction-service/report/api/v1/paymentPlansReport",
  URL_PAYMENT_PLANS_REPORT_DETAILS: "/pp-paymenttransaction-service/report/api/v1/paymentPlansOrderDetailsReport",
  URL_COMMUNICATION_TYPE_REPORT: "/pp-paymenttransaction-service/report/api/v1/optOutInReport",
  URL_SCHEDULED_TRANSACTION_REPORT: "/pp-paymenttransaction-service/report/api/v1/scheduledTransactionReport",
  URL_SCHEDULED_FUTURE_PAYMENT_REPORT: "/pp-paymenttransaction-service/report/api/v1/scheduledFuturePayment",
  URL_COMMUNICATION_BY_REPORT: "/pp-paymenttransaction-service/report/api/v1/communicationsReport",
  URL_SCHEDULED_PAYMENT_PLAN_REPORT: "/pp-paymenttransaction-service/report/api/v1/scheduledPaymentPlan",

  //Client Management
  URL_CLIENT_MAINTAINANCE: "/pp-paymenttransaction-service/transaction/api/v1/findall/businessUnit",
  URL_CLIENT_MAINTAINANCE_SAVE: "/pp-paymenttransaction-service/transaction/api/v1/_saveBusinessUnit",
  URL_CLIENT_MAINTAINANCE_HOSPITAL: "/pp-paymenttransaction-service/transaction/api/v1/_getBusinessUnit",
  URL_ISACTIVE: "/pp-paymenttransaction-service/paymentplanoption/api/v1/disable/isAcitve",
  URL_GET_PAYMENTPLAN_OPTIONS: "/pp-paymenttransaction-service/paymentplanoption/api/v1/get/all/paymentplanoption",
  URL_GET_SETTLEMENT_OPTIONS_BY_ID: "/pp-paymenttransaction-service/settlementOption/api/v1/settlement/id",
  URL_GET_PAYMENTPLAN_OPTIONS_BY_ID: "/pp-paymenttransaction-service/paymentplanoption/api/v1/get",
  URL_PAYMENT_PLAN_OPTIONS_SAVE: "/pp-paymenttransaction-service/paymentplanoption/api/v1/save/paymentPlanConditions",
  URL_GET_HOSPITAL_LIST: "/pp-paymenttransaction-service/transaction/api/v1/_getHospitalList",
  URL_DELETE_PATMENT_PLAN: "/pp-paymenttransaction-service/paymentplanoption/api/v1/delete",
  URL_PAYMENT_PLAN_OPTIONS_UPDATE: "/pp-paymenttransaction-service/paymentplanoption/api/v1/save",
  URL_SETTLEMENT_OPTIONS_ADD: "/pp-paymenttransaction-service/settlementOption/api/v1/settlement/save",
  URL_SETTLEMENT_OPTIONS_LIST: "/pp-paymenttransaction-service/settlementOption/api/v1/settlement/list",

  // Two-Factor Authentication
  URL_GET_QRCODE: "/pp-paymenttransaction-service/api/v1/auth/_getQrcode",
  URL_VERIFY_QRCODE: "/pp-paymenttransaction-service/api/v1/auth/_verifyQrcode",
  URL_DISABLE_2FA: "/pp-paymenttransaction-service/api/v1/auth/_disableAuthenticator",

  // user management
  URL_GET_USER_TABLE_DATA: "/thor-usermanagement/api/v1/user/getuser",
  URL_ADD_USER_TO_TABLE: "/thor-usermanagement/api/v1/user/adduser",
  URL_UPDATE_USER: "/thor-usermanagement/api/v1/user/update/role",
  URL_DELETE_USER: "/thor-usermanagement/api/v1/user/delete/user/",
  URL_RESTORE_USER: "/thor-usermanagement/api/v1/user/restore/user/",
  URL_DISABLE2FA_TO_USER: "/pp-paymenttransaction-service/api/v1/auth/_disableAuthByAdmin",
  URL_ROLES_LOOKUP: "/thor-usermanagement/api/v1/user/getrolename/",
  URL_ROLES_ACCESS: "/thor-usermanagement/api/v1/user/permissions/",

  //Quick-Survey
  URL_GET_SURVEY_QUESTIONS: "/pp-paymenttransaction-service/paymentrpsurvey/api/v1/get/surveyquestions",
  URL_POST_SURVEY_ANSWERS: "/pp-paymenttransaction-service/paymentrpsurvey/api/v1/save",
  URL_GET_RP_SURVEY_ANSWERS: "/pp-paymenttransaction-service/paymentrpsurvey/api/v1/get/all/rpsurveyanswers",
  URL_GET_RP_SURVEY_ANSWERS_BY_RP_ID: "/pp-paymenttransaction-service/paymentrpsurvey/api/v1/get/surveyanswers",
  URL_GET_SURVEY_CHART_DATA: "/pp-paymenttransaction-service/paymentrpsurvey/api/v1/get/surveychart",

  URL_LOGIN: "/thor-authentication/api/v1/login", // done
  URL_LOGOUT: "/thor-authentication/api/v1/logout", //
  URL_JWT_TRUST: "/thor-token-management/api/v1/jwt-token",
  //   //LOGIN
  URL_GET_MACID: "/thor-authentication/api/v1/user/macAddress", // done
  URL_CHANGEPASSWORD: "/thor-authentication/api/v1/user/changePassword",

  URL_GET_SECURITY_IMAGE: "/thor-usermanagement/api/v1/securityImage/_get", //Security Image
  URL_GET_SECURITY_IMAGE_LIST: "/thor-usermanagement/api/v1/securityImage/_getList",
  URL_UPDATE_SECURITY_IMAGE_LIST: "/thor-usermanagement/api/v1/securityImage/_update",
  //WHITE LABEL
  URL_GET_WHITE_LABEL: "/thor-partywhitelabel/api/v1/party/whitelabel/_get", //get contentid
  URL_GET_CONTENT: "/thor-contentmanagement/api/v1/content/_get", // fetch logo the data using contentid

  // URL_GETCOUNTRY: "/thor-regionmanagement/api/v1/country",
  URL_GETCOUNTRY: "/thor-regionmanagement/api/v1/country/_get",
  URL_GETSTATE: "/thor-regionmanagement/api/v1/state/_get",
  URL_GETCITY: "/thor-regionmanagement/api/v1/city/_get",

  URL_GET_PAYMENT_GATEWAY_PROFILE: "/pp-paymentgateway-service/gateway/api/v1/_get",
  URL_ADD_PAYMENT_GATEWAY_PROFILE: "/pp-paymentgateway-service/gateway/api/v1/_create",
  URL_GET_WHITE_LABEL_DATA: "/thor-partywhitelabel/api/v1/party/whitelabel/_get",
  URL_GET_CONTENT_LABEL_DATA: "/thor-contentmanagement/api/v1/content/_get",
  // Add Consent For Onlinepayment and Email use
  URL_ADD_CONSENT: "/thor-partyextendedservices/api/v1/party/consent/_add",
  URL_GET_PDF_DATA: "/thor-html2pdf/status",
  URL_GET_PUBLIC_URL_DATA: "/thor-documentmanagement/api/v1/document/publicurl",

  URL_GET_VERIFY_PAYER: "/pp-billing-service/bill/api/v1/_verify",
  URL_ADD_VERIFY_PAYER: "/pp-billing-service/bill/api/v1/_verify",
  URL_UNLOCK_USER: "/pp-paymenttransaction-service/userenhancement/api/v1/unlockuser",

  RESEND_EMAIL_CONFIRMATION: "/pp-paymenttransaction-service/userenhancement/api/v1/resendemail",

  //SETTLEMENT INFO

  // Verify User
  URL_VERIFY_USER: "/thor-authentication/api/v1/user/verifyEmail",

  // settlement info api
  URL_SETELMENT_INFO: "/pp-paymenttransaction-service/settlementOption/api/v1/settlementInfo",
  URL_TRACK_SETTLEMENT_INFO: "/pp-paymenttransaction-service/settlementOption/api/v1/tracksettlementview",
  URL_SAVE_SETTLEMENT: "/pp-paymenttransaction-service/settlementOption/api/v1/saveSettlement",

  // settlement api
  URL_SEND_APPROVED_SETTLEMENT: "/pp-paymenttransaction-service/settlementOption/api/v1/sendApprovedEmail",
  URL_SEND_REJECT_SETTLEMENT: "/pp-paymenttransaction-service/settlementOption/api/v1/sendRejectEmail",
  URL_SEND_REVISED_SETTLEMENT: "/pp-paymenttransaction-service/settlementOption/api/v1/sendRevisedEmail",
  URL_RP_SETTLEMENT_TABLE: "/pp-paymenttransaction-service/settlementOption/api/v1/allSettlementData",

  // Payment Credit Card
  //URL_SAVE_CREDIT_CARD_DETAILS:"https://devcoreservices.pdsnew.com/pp-payersaccount-service/paymentinfo/api/v1/_save",
  URL_SAVE_CREDIT_CARD_DETAILS: "/pp-payersaccount-service/paymentinfo/api/v1/_save",

  //URL_GET_PAYMENT_DETAILS: "https://devcoreservices.pdsnew.com/pp-payersaccount-service/paymentinfo/api/v1/_get",
  URL_GET_PAYMENT_DETAILS: "/pp-payersaccount-service/paymentinfo/api/v1/_get",

  //  Get Billing details Details
  //URL_GET_PAYER_ACCOUNT_DEAILS:"https://devcoreservices.pdsnew.com/pp-billing-service/bill/api/v1/_get",
  URL_GET_PAYER_ACCOUNT_DEAILS: "/pp-billing-service/bill/api/v1/party/_get",

  // Delete Payment Methods
  // /pp-payersaccount-service/paymentinfo/api/v1/delete/{PaymentType}/{instrumentId}
  URL_DELETE_PAYMENT_METHOD: "/pp-payersaccount-service/paymentinfo/api/v1/delete",

  //var url = Global_var.BASEURL + Global_var.URL_CREATE_PAYMENT_ORDER;
  //URL_CREATE_PAYMENT_ORDER : "https://devcoreservices.pdsnew.com/pp-billing-service/paymentorder/api/v1/_create",
  URL_CREATE_PAYMENT_ORDER: "/pp-billing-service/paymentorder/api/v1/_create",
  // URL_GET_APPORTIONED_DATA;
  //  URL_GET_APPORTIONED_DATA : "https://devcoreservices.pdsnew.com/pp-billing-service/bill/api/v1/_distribution",
  URL_GET_APPORTIONED_DATA: "/pp-billing-service/bill/api/v1/_distribution",

  // var url =  Global_var.URL_URL_PAYMENT_TRANSACTION;
  //URL_URL_PAYMENT_TRANSACTION : "https://devcoreservices.pdsnew.com/pp-paymenttransaction-service/transaction/api/v1/_create",
  URL_URL_PAYMENT_TRANSACTION: "/pp-paymenttransaction-service/transaction/api/v1/_create",

  //  Transaction Successful Details
  //URL_GET_PAYMENT_DEAILS: "https://devcoreservices.pdsnew.com/pp-paymenttransaction-service/paymentreceipt/api/v1/_generate",
  URL_GET_PAYMENT_DEAILS: "/pp-paymenttransaction-service/paymentreceipt/api/v1/_generate",

  //PAYMENT ECHAQUE
  URL_GET_ECHEQUE_DETAILS: "/pp-payersaccount-service/paymentinfo/api/v1/_get",
  //URL_SAVE_ECHEQUE_DETAILS:"https://devcoreservices.pdsnew.com/pp-payersaccount-service/paymentinfo/api/v1/_save",
  URL_SAVE_ECHEQUE_DETAILS: "/pp-payersaccount-service/paymentinfo/api/v1/_save",

  //contactus
  // URL_ADD_CONTACT_US:"http://192.168.0.106:9053/pp-paymentquery-service/query/api/v1/_post"
  //URL_ADD_CONTACT_US:"http://192.168.12.115:8054/pp-query-service/query/api/v1/_post",

  URL_ADD_CONTACT_US: "/pp-query-service/query/api/v1/_post",

  // Get Creditcard/Echeque details
  URL_GET_CARD_DETAILS: "/pp-payersaccount-service/paymentinfo/api/v1/_get",

  // Get Fees URL
  URL_GET_FEES: "/pp-billing-service/fee/api/v1/_get",

  URL_CHECK_RECEIPT_STATUS: "/pp-paymenttransaction-service/paymentreceipt/api/v1/_isgenerated",

  URL_DOWNLOAD_PDF_DEAILS: "/downloadPdf",
  URL_RECEIPT_CONVERT_DEAILS: "/pp-payersaccount-service/paymentreceipt/api/v1/_convert",

  // report
  URL_GET_TRANSACTION_DATA: "/pp-paymenttransaction-service/transaction/api/v1/_get",
  URL_GET_PATIENT_DATA: "/pp-billing-service/bill/api/v1/_get",

  //Users
  URL_GET_USER_DATA: "/thor-usermanagement/api/v1/user/retrieveUser",
  URL_PARTYGET: "/thor-partyservices/api/v1/party/_get",
  URL_PUBLIC_URL_GET: "/thor-documentmanagement/api/v1/document/publicurl",

  URL_FORGOT_PASSWORD: "/thor-authentication/api/v1/user/forgotPassword",
  URL_RESET_PASSWORD: "/thor-authentication/api/v1/user/resetPassword",
  URL_RESET_PASSWORD_BY_EMAIL: "/thor-authentication/api/v1/user/resetPasswordByEmail",

  // Payplan Info
  URL_GET_PAYMENT_PLAN: "/pp-paymenttransaction-service/paymentplanoption/api/v1/planinfo",
  URL_SAVE_PAYMENT_PLAN: "/pp-paymenttransaction-service/recurringPayment/api/v1/save",
  URL_EDIT_PAYMENT_PLAN: "/pp-paymenttransaction-service/recurringPayment/api/v1/editPlan",

  // Update Payment Plan
  // pp-paymenttransaction-service/recurringPayment/api/v1/updateinstrument/{reccuringPaymentId}/{paymentInstrumentId}"
  // /recurringPayment/api/v1/updateinstrument/{reccuringPaymentId}/{paymentInstrumentId}
  URL_UPDATE_PAYMENT_PLAN: "/pp-paymenttransaction-service/recurringPayment/api/v1/updateinstrument",

  // GET ALL PAYPLANS BY PAYERPARTYID
  // "/pp-paymenttransaction-service/recurringPayment/api/v1/getplans/{payerPartyId}",
  // /recurringPayment/api/v1/plans
  URL_GET_ALL_PAYPLAN: "/pp-paymenttransaction-service/recurringPayment/api/v1/plans",

  // GET SINGLE PAYPLAN BY RECURRINGID
  // /recurringPayment/api/v1/get/{recurringId}
  // /recurringPayment/api/v1/get/{recurringId}
  URL_GET_SINGLE_PAYPLAN: "/pp-paymenttransaction-service/recurringPayment/api/v1/get",

  // Get all Future payment history by PayerpartyID
  // pp-paymenttransaction-service/recurringPayment/api/v1/getfutureplans/12804
  // /recurringPayment/api/v1/payments/future
  URL_GET_FUTURE_PAYPLANS: "/pp-paymenttransaction-service/recurringPayment/api/v1/payments/future",

  // DELETE PAYPLAN by recurringId
  // /recurringPayment/api/v1/delete/{recurringPaymentId}
  URL_DELETE_PAYPLAN: "/pp-paymenttransaction-service/recurringPayment/api/v1/delete",

  // DELETE ONE RECURRING PAYMENT
  // /paymentplanoption/api/v1/delete/{paymentPlanOptionsId}
  // /recurringPayment/api/v1/delete/payment/{paymentOrderId}
  URL_DELETE_RECURRING_PAYMENT: "/pp-paymenttransaction-service/recurringPayment/api/v1/delete/payment",

  // UPDATE ONE RECURRING PAYMENT
  // /recurringPayment/api/v1/update/payment/{paymentOrderId}/{paymentInstrumentId}
  URL_UPDATE_RECURRING_PAYMENT: "/pp-paymenttransaction-service/recurringPayment/api/v1/update/payment",

  // settlement api
  URL_SEND_APPROVED_SETTLEMENT: "/pp-paymenttransaction-service/settlementOption/api/v1/sendApprovedEmail",
  URL_SEND_REJECT_SETTLEMENT: "/pp-paymenttransaction-service/settlementOption/api/v1/sendRejectEmail",
  URL_SEND_REVISED_SETTLEMENT: "/pp-paymenttransaction-service/settlementOption/api/v1/sendRevisedEmail",
  URL_RP_SETTLEMENT_TABLE: "/pp-paymenttransaction-service/settlementOption/api/v1/allSettlementData",
};
