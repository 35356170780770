import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SpinnerPage from "../../../Common/PP_loader";
import { requestInfo_PP } from "../../../Common/CommonFn";
import { OutstandingPayerService } from "../../../services/payerOutstandingService";
import HomeBillinContainer from "./HomeBillingContainer";
import { CreateCampaignView, CreatePortalUsage } from "../../../util/purlFunctions";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";
class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showloader: true,
      SocialSecurityNumber: "",
      partyId: "",
      nobills: false,
      errors: false,
      closedBillingData: [],
    };
  }

  purlTrackingUser(accRefNo) {
    // const accRefNo = this.state.masterAccNo;
    const partyId = sessionStorage.getItem("Userpartyid");
    if (accRefNo) {
      const passValuesCPU = {
        accessMode: "CUSTOMER LOGIN", //Mandatory
        campaignCode: "", //If Purl then it's mandatory
        lastPageAccessed: "AccountDetails", //Mandatory
        medAccountNumber: accRefNo, //Mandatory
        partyId: partyId, //Mandatory
        portalUsageId: sessionStorage.getItem("portalUsageId") ? JSON.parse(sessionStorage.getItem("portalUsageId")) : 0, //It should be 0
      };

      CreatePortalUsage(passValuesCPU, (res) => {
        if (res.data.responseInfo.status === "SUCCESSFUL") {
          !sessionStorage.getItem("portalUsageId") &&
            sessionStorage.setItem("portalUsageId", res.data.portalUsage.portalUsageId);
          if (accRefNo) {
            const passValuesCCV = {
              campaignCode: "",
              campaignViewId: sessionStorage.getItem("campaignViewId")
                ? JSON.parse(sessionStorage.getItem("campaignViewId"))
                : 0,
              medAccountNumber: accRefNo,
              paidAmount: 0,
              partyId: partyId,
              payPlanAdded: "",
              paymentComplete: "",
              purlViewCount: 0,
            };
            CreateCampaignView(passValuesCCV, (res) => {
              !sessionStorage.getItem("campaignViewId") &&
                sessionStorage.setItem("campaignViewId", res.data.compaignView.campaignViewId);
              this.setState({ isCalledPurl: true });
            });
          }
        }
      });
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "MED-1 Secure Login - Account Details";
    const userInfo = JSON.parse(sessionStorage.getItem("verifypayer"));
    const userData = JSON.parse(sessionStorage.getItem("userData"));

    var partyid = sessionStorage?.getItem("currentUserRole") === "Admin" ? userInfo?.partyId : userData?.partyId;
    this.setState({
      partyId: partyid,
    });
    let userId = "1212";
    const passValues = {
      requestInfo: requestInfo_PP(sessionStorage.getItem("jwt-token"), sessionStorage.getItem("jwt-token")),
      partyId: partyid,
      isActive: [1],
    };
    OutstandingPayerService.GetOutstandingPayerdata(passValues, userId, (res) => {
      if (res["data"]?.responseInfo?.status === "SUCCESSFUL") {
        let completeBills = [];
        res.data.billGroups.map((ele, ind) => {
          let tempArr = ele?.bills;
         
          if (ind === 0) {
            completeBills = tempArr;
          } else {
            completeBills = [...completeBills, ...tempArr];
          }
        });

        this.setState({
          newbillingdata: res?.data?.billGroups,
          closedBillingData: res?.data?.closedBills,
          billinginformation: completeBills,
          billinginfosummary: completeBills,
        });
        this.purlTrackingUser(res?.data?.payer.uniqueIdentifierId);

        const PassedValues = {
          payerBillSearch: {
            accountReferenceNumber: res?.data?.payer.uniqueIdentifierId,
            uniqueIdentityId: "",
            dob: "",
          },
        };

        sessionStorage.setItem("new", completeBills);
        sessionStorage.setItem("billingpayerdata", JSON.stringify(completeBills));
      
        sessionStorage.setItem("verifypayer", JSON.stringify(res?.data?.payer));
        sessionStorage.setItem("Verifieduser", res?.data?.payer.firstName + " " + res?.data?.payer.lastName);
        sessionStorage.setItem("payerinfo", JSON.stringify(PassedValues.payerBillSearch));
       

        this.setState({
          SocialSecurityNumber: res?.data?.payer.uniqueIdentifierId,
          showloader: false,
          partyId: res?.data?.payer?.partyId,
        });
      } else if (res["data"]?.responseInfo.status === "FAILED") {
        this.setState({ nobills: true });
        this.setState({ showloader: false });
      } else {
        this.setState({ errors: true });
        this.setState({ showloader: false });
      }
    });
  }
  render() {
    return (
      <React.Fragment>
        {this.state.showloader === true ? <SpinnerPage /> : ""}
        {this.state.billinginformation && (
          <HomeBillinContainer
            {...this.props}
            partyId={this.state.partyId}
            billinginformation={this.state.billinginformation}
            billinginfosummary={this.state.billinginfosummary}
            SocialSecurityNumber={this.state.SocialSecurityNumber}
            newbillingdata={this.state.newbillingdata}
          />
        )}
        {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
      </React.Fragment>
    );
  }
}

export default Billing;
