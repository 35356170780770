import { clientMaintainanceService } from "../admin_services/clientMaintenanceService";

export const GET_CLIENT_MAINTENANCE_RECORD = "GET_CLIENT_MAINTENANCE_RECORD";
export const ADD_CLIENT_MAINTENANCE_RECORD = "ADD_CLIENT_MAINTENANCE_RECORD";
export const UPDATE_CLIENT_MAINTENANCE_RECORD = "UPDATE_CLIENT_MAINTENANCE_RECORD";
export function getClientMaintenanceList(Data, fn) {
  return (dispatch) => {
    dispatch({
      type: GET_CLIENT_MAINTENANCE_RECORD,
      payload: Data,
    });
    clientMaintainanceService.getClientMainatinanceData(Data, (res) => fn(res));
  };
}
export function AddClientMainatinance(Data, fn) {
  return (dispatch) => {
    dispatch({
      type: ADD_CLIENT_MAINTENANCE_RECORD,
      payload: Data,
    });
    clientMaintainanceService.AddClientMainatinanceData(Data, (res) => fn(res));
  };
}

export function getHospitalDetails(Data, fn) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CLIENT_MAINTENANCE_RECORD,
      payload: Data,
    });
    clientMaintainanceService.getHospitalData(Data, (res) => fn(res));
  };
}
