import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getIn } from "formik";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Checkbox, FormControl, FormHelperText, FormControlLabel, MenuItem, Select, FormLabel } from "@material-ui/core";
import ImageTooltip from "../../../components/ImageTooltip";
import CardPaymentTooltip from "../../../assets/images/CardPaymentTooltip.jpg";
import CardAddedDialog from "./CardAddedDialog";
import disabledCard from "../../../assets/images/disabled-card.png";

import { countryData, allStatesData, cityData } from "../../../util/StatesData";

import InputAdornment from "@material-ui/core/InputAdornment";
import amexImg from "../../../assets/images/amex.jpg";
import masterCardImg from "../../../assets/images/masterCard.png";
import visaCardImg from "../../../assets/images/visa.png";
import { validateCard } from "../../../util/ValidateCard";
import { Link } from "react-router-dom";
import UpdateTrackingPage from "../../../containers/UpdateTrackingPage";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  creditcard: {
    textAlign: "left !important",
  },
  inputfield: {
    textAlign: "left !important",
    fontSize: 14,
    color: "#536777",
    fontWeight: "normal",
    paddingTop: 10,
  },
}));

const options = [
  { key: "Master Card", value: "Master Card" },
  { key: "Visa", value: "Visa" },
  { key: "American Card", value: "American Card" },
];

const intialvalues = {
  cardType: "Master Card",
  expiryDate: "",
  cvv: "",
  cardNumber: "",
  nameOnCard: "",
  futureDate: null,
  agreeToterms: true,
  agreeToEmailUsage: true,
  country: "none",
  StateCounty: "none",
  city: "none",
  zipCode: "",
};

const schema = Yup.object().shape({
  cardType: Yup.string().trim().required("Card Type is required"),
  zipCode: Yup.string()
    .nullable()
    .trim()
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/g, "Please enter a valid ZIP Code")
    .test("Invalid ZIP Code", (value) => !(/^([0-9])\1{4,}$/.test(value)))
    .required("ZIP Code is  required"),
});

const AddCreditCard = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [cardImg, setCardImg] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const payNow = history?.location?.state?.payNow;

  return (
    <div className="change-password dashboard-content">
      <CardAddedDialog open={open} handleClose={handleClose} />
      <h3 className="content-title">Credit/Debit Card Information</h3>
      <div className="dashboard-content-card">
        <Formik initialValues={intialvalues} validationSchema={schema}>
          {(props) => {
            const { values, touched, error, handleChange, handleBlur, errors, setFieldValue } = props;
            return (
              <Form id="login-form" className="input-field" noValidate="noValidate">
                <div className={classes.inputfield}>
                  <div className="row">
                    <div className="form-group col-md-12 col-lg-12">
                      <label htmlFor="exampleInputPassword1" className="required">
                        Name on Card
                      </label>
                      <div className="row">
                        <div className="col-12 col-sm-4 mb-3">
                          <OutlinedInput
                            className="form-control"
                            id="nameOnCardFirstName"
                            placeholder="Enter First Name"
                            onChange={handleChange}
                            value={values["nameOnCardFirstName"]}
                            aria-label="text-field"
                            type="text"
                            error={Boolean(getIn(touched, "nameOnCardFirstName") && getIn(errors, "nameOnCardFirstName"))}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            required="true"></OutlinedInput>
                        </div>
                        <div className="col-12 col-sm-4 mb-3">
                          <OutlinedInput
                            className="form-control"
                            id="nameOnCardMiddleName"
                            placeholder="Enter Middle Name"
                            onChange={handleChange}
                            value={values["nameOnCardMiddleName"]}
                            aria-label="text-field"
                            type="text"
                            error={Boolean(getIn(touched, "nameOnCardMiddleName") && getIn(errors, "nameOnCardMiddleName"))}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            required="true"></OutlinedInput>
                        </div>
                        <div className="col-12 col-sm-4 mb-3">
                          <OutlinedInput
                            className="form-control"
                            id="nameOnCardLastName"
                            placeholder="Enter Last Name"
                            onChange={handleChange}
                            value={values["nameOnCardLastName"]}
                            aria-label="text-field"
                            type="text"
                            error={Boolean(getIn(touched, "nameOnCardLastName") && getIn(errors, "nameOnCardLastName"))}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            required="true"></OutlinedInput>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="cardNumber" className="required">
                          Card Number
                        </label>
                        <OutlinedInput
                          className="form-control"
                          id="cardNumber"
                          name="cardNumber"
                          placeholder="5333 3902 4532 0000"
                          onChange={(e) => {
                            handleChange(e);
                            validateCard(e.target.name, e.target.value, setCardImg);
                          }}
                          value={values["cardNumber"]}
                          aria-label="text-field"
                          fullWidth
                          error={Boolean(getIn(touched, "cardNumber") && getIn(errors, "cardNumber"))}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <img
                                className="mr-2"
                                src={
                                  cardImg === "visa"
                                    ? visaCardImg
                                    : cardImg === "mastercard"
                                    ? masterCardImg
                                    : cardImg === "amex"
                                    ? amexImg
                                    : disabledCard
                                }
                                alt="Card Type"
                                width="50"
                                height="auto"
                              />
                            </InputAdornment>
                          }
                          required="true"></OutlinedInput>
                      </div>
                    </div>
                    <div className=" col-lg-3 col-md-6 form-group">
                      <FormControl variant="outlined" className="w-100" fullWidth>
                        <label htmlFor="cardType" className="form-label required">
                          Credit Card Type
                        </label>
                        <Select
                          fullWidth
                          name="cardType"
                          className="form-select form-control"
                          onChange={handleChange("cardType")}
                          value={values["cardType"]}
                          error={Boolean(getIn(touched, "cardType") && getIn(errors, "cardType"))}>
                          <MenuItem value="none" key="none" disabled>
                            Select Card Type
                          </MenuItem>
                          {options.map((option) => {
                            return (
                              <MenuItem value={option.value} key={option.key}>
                                {option.value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group">
                        <label htmlFor="expiryDate" className="required">
                          Expiry date
                        </label>
                        <OutlinedInput
                          className="form-control"
                          id="expiryDate"
                          name="expiryDate"
                          placeholder="01/22"
                          onChange={handleChange}
                          value={values["expiryDate"]}
                          aria-label="text-field"
                          fullWidth
                          type="expiryDate"
                          error={Boolean(getIn(touched, "expiryDate") && getIn(errors, "expiryDate"))}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required="true"
                          inputProps={{
                            maxLength: 5,
                          }}></OutlinedInput>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group">
                        <label htmlFor="cvv" className="mr-4 required">
                          CVV
                        </label>

                        <ImageTooltip img={CardPaymentTooltip} width="300px" alt="Credit Card Payment" placement="right">
                          <i className="fas fa-question-circle circle-icon" style={{ cursor: "pointer" }}></i>
                        </ImageTooltip>
                        <OutlinedInput
                          className="form-control"
                          id="cvv"
                          name="cvv"
                          placeholder="234"
                          onChange={handleChange}
                          value={values["cvv"]}
                          aria-label="text-field"
                          fullWidth
                          type="cvv"
                          error={Boolean(getIn(touched, "cvv") && getIn(errors, "cvv"))}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required="true"
                          inputProps={{
                            maxLength: 3,
                          }}></OutlinedInput>
                      </div>
                    </div>
                    <div className="form-group col-md-12">
                      <label htmlFor="Billing Address" className="required">
                        Billing Address
                      </label>
                      <OutlinedInput
                        name="message"
                        className="form-control"
                        value={values.message}
                        onChange={handleChange}
                        placeholder="Enter Your Billing Address"
                        aria-label="text-field"
                        fullWidth
                        required></OutlinedInput>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <FormControl variant="outlined" className="w-100" fullWidth>
                          <FormLabel for="country" className="form-label required">
                            Country
                          </FormLabel>
                          <Select
                            fullWidth
                            name="country"
                            value={values.country}
                            onChange={handleChange}
                            className="form-select form-control">
                            <MenuItem value="none" disabled>
                              Select Country
                            </MenuItem>
                            {countryData.map((option) => {
                              return (
                                <MenuItem value={option.key} key={option.key}>
                                  {option.value}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <FormControl variant="outlined" className="w-100" fullWidth>
                          <FormLabel for="state" className="form-label required">
                            State/County
                          </FormLabel>
                          <Select
                            fullWidth
                            name="StateCounty"
                            value={values.StateCounty}
                            onChange={handleChange}
                            className="form-select form-control">
                            <MenuItem value="none" key="none" disabled>
                              Select State/County
                            </MenuItem>
                            {allStatesData.map((option) => {
                              return (
                                <MenuItem value={option.key} key={option.key}>
                                  {option.value}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <FormControl variant="outlined" className="w-100" fullWidth>
                          <FormLabel for="city" className="form-label required">
                            City
                          </FormLabel>
                          <Select
                            onChange={handleChange}
                            fullWidth
                            name="city"
                            value={values.city}
                            className="form-select form-control">
                            <MenuItem value="none" key="none" disabled>
                              Select City
                            </MenuItem>
                            {cityData.map((option) => {
                              return (
                                <MenuItem value={option.key} key={option.key}>
                                  {option.value}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <FormLabel for="zipCode" className="required">
                          ZIP Code
                        </FormLabel>
                        <OutlinedInput
                          className="form-control"
                          name="zipCode"
                          value={values.zipCode}
                          onChange={(event) => {
                            const zip = event.target.value;
                            setFieldValue(
                                (values.zipCode = zip.length > 5 && !zip.includes("-") ? zip.substring(0, 5).concat("-") + zip.substring(5, zip.length) : zip)
                            );
                        }}
                          onBlur={handleBlur}
                          inputProps={{
                            maxLength: 10,
                          }}
                          placeholder="Enter ZIP Code"
                          aria-label="text-field"
                          fullWidth
                          required></OutlinedInput>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="agree-terms">
                  <div className=" check-FormLabel">
                    <FormControl fullWidth component="fieldset" margin="normal">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="agreeToterms"
                            checked={values.agreeToterms}
                            onChange={handleChange}
                            value={values.agreeToterms}
                            color="primary"
                          />
                        }
                        label={
                          <span>
                            By checking this box,you signify you have reviewed,understand,meet and agree to the{" "}
                            <a href="/">Online Payment Terms and Conditions</a>
                          </span>
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="check-FormLabel">
                    <FormControl fullWidth component="fieldset" margin="normal">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="agreeToEmailUsage"
                            value={values.agreeToEmailUsage}
                            checked={values.agreeToEmailUsage}
                            onChange={handleChange}
                            color="primary"
                          />
                        }
                        label={
                          <span>
                            By checking this box,you agree that this is your personal email address and you authorise us to
                            send you emails,call your phone, or contact you via an automated dialer,leave voicemails, and/or
                            send text messages if necessary.
                          </span>
                        }
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="proceed-cancel-buttons">
                  <button
                    onClick={() =>
                      sessionStorage.getItem("currentUserRole") === "Admin"
                        ? history.push("/pa/account")
                        : history.push("/home/account")
                    }
                    className="secondary-button">
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={() => {
                      setOpen(true);
                    }}
                    className="primary-button">
                    Add Card
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
        {sessionStorage.getItem("portalUsageId") && <UpdateTrackingPage path={window.location.href.slice(22)} />}
      </div>
    </div>
  );
};

export default AddCreditCard;
