import React, { createRef, useEffect, useState } from "react";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import { tableIcons } from "../../../../../components/TableIcons";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { Formik, Form } from "formik";
import { TextField, FormControl } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { handleWarning, handleError } from "../../../../../components/Notifications/Toast";
import SpinnerPage from "../../../../../Common/PP_loader";
import PatchedPagination from "../../../../../Common/TablePagination";
import { getHospitalList } from "../../../../../action/ClientManagementAction";
import { httpPaginationRequest } from "../../../../../Common/httpPaginationRequest";
import { Global_var } from "../../../../../global/new _admin_globalvar";
import { Autocomplete } from "@material-ui/lab";
import EditIcon from "@material-ui/icons/Edit";
import ConfirmationDialog from "../../../../../components/ConfirmationDialog/index";
import ClientMaintenancePopUp from "./clientMaintenance";
import { getHospitalDetails } from "../../../../../action/ClientMaintenance";
import Icons from "../../../../../util/Icons";
const ClientMaintenance = () => {
  let initialValues = {
    hospitalCode: "",
  };

  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState(initialValues);
  const [hospitalList, setHostipalList] = useState([]);
  const tableRef = createRef();
  const [arrayLast, setArrayLast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [clientMaintenancePopUpData, setClientMaintenancePopUpData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [title, setTitle] = useState(false);
  const [buId, setBuId] = useState(null);
  const [hospitalDetailsData, setHospitalDetailsData] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [rows, setRows] = useState(5);
  const handleClose = () => {
    setIsEdit(false);
    setClientMaintenancePopUpData({});
    setDialogOpen(false);
    setBuId(null);
  };

  useEffect(() => {
    dispatch(
      getHospitalList((res) => {
        if (res?.status === 200) {
          setHostipalList(res.data.hospitalDetails);
        } else {
          handleError("Something went wrong");
        }
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (tableRef?.current && (isSubmit || isUpdate)) {
      tableRef.current.onQueryChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData, isUpdate]);

  const columns = [
    {
      title: "Hospital Name",
      field: "hospitalName",
    },
    {
      title: "Hospital Code",
      field: "hospitalCode",
    },
    {
      title: "Start Date",
      field: "startDate",
    },
    {
      title: "Active",
      field: "isActive",
      render: (rowData) => {
        return rowData?.isActive === 0 ? "False" : "True";
      },
    },
  ];

  const handleSubmit = (values) => {
    if (values?.hospitalCode?.code) {
      setIsSubmit(true);
      setSearchData(values);
    } else {
      handleWarning("Please select a hospital");
    }
  };

  return (
    <div className="transaction-card patient-details" role="main">
      <div className="main-container-card">
        <div role="heading" aria-level="1" className="heading-style mb-3">
          <Icons type="clientManagement" className="mr-2" />
          <h2 className="heading-style mb-0">Client Management: Client Maintenance</h2>
        </div>
        <div>
          <div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {(props) => {
                const { values, setFieldValue } = props;
                return (
                  <Form id="transaction-search" className="input-field" noValidate="noValidate">
                    <div className="form-row">
                      <div className="col-lg-4 col-md-4 col-sm-6 form-group">
                        <FormControl variant="outlined" className="w-100" fullWidth>
                          <label htmlFor="hospitalCode" className="form-label">
                            Hospital
                          </label>

                          <Autocomplete
                            className="form-autocomplete"
                            size="small"
                            {...props}
                            id="hospitalCode"
                            options={hospitalList}
                            autoHighlight
                            getOptionDisabled={(option) => option.code === null}
                            value={values.hospitalCode}
                            getOptionLabel={(option) => {
                              return option && option.code ? option.code + " - " + option?.hospitalName : "";
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                fullWidth
                                name="hospitalCode"
                                placeholder="Select Hospital Name"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "disabled",
                                }}
                              />
                            )}
                            onChange={(e, value) => {
                              setFieldValue("hospitalCode", value);
                            }}
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div className="payment-history-btns">
                      <button aria-label="Generate Report" type="submit" className="primary-button themeing-buttons">
                        Search
                      </button>
                      <button
                        aria-label="Reset"
                        type="reset"
                        className="secondary-button mx-2"
                        onClick={() => {
                          setIsSubmit(true);
                          setSearchData(initialValues);
                        }}>
                        Reset
                      </button>
                      <button
                        type="button"
                        aria-label="Generate Report"
                        className="primary-button themeing-secondary-buttons"
                        onClick={() => {
                          setTitle(true);
                          setDialogOpen(true);
                        }}>
                        Add
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <Grid item>
            <div className="payplan-table ">
              <MaterialTable
                title=""
                localization={{
                  pagination: {
                    firstAriaLabel: "First Page",
                    previousAriaLabel: "Previous Page",
                    nextAriaLabel: "Next Page",
                    lastAriaLabel: "Last Page",
                  },
                }}
                columns={columns}
                icons={tableIcons}
                tableRef={tableRef}
                data={(query) =>
                  new Promise((resolve) => {
                    const bodyObj = {
                      limit: query.pageSize ? query.pageSize : null,
                      orderBy: "",
                      orderDirection: "",
                      page: isUpdate
                        ? query?.page
                        : isSubmit
                        ? 0
                        : arrayLast && query?.page >= 1
                        ? query.page
                        : arrayLast
                        ? (query.page <= 1 && query.page + 1) || query.page - 1
                        : query.page,
                      hospitalCode: searchData?.hospitalCode?.code || "",
                      hospitalName: "",
                    };
                    httpPaginationRequest(Global_var.URL_CLIENT_MAINTAINANCE, bodyObj, (res) => {
                      setIsSubmit(false);
                      setIsUpdate(false);
                      if (res?.data?.responseInfo?.status === "SUCCESSFUL") {
                        const data = res?.data?.resultObjects;
                        resolve({
                          data: data.length > 0 ? data : [],
                          page: res.data.page,
                          totalCount: res?.data?.count,
                        });
                        setArrayLast(data.length === 1);
                      } else if (res.data?.responseInfo?.status === "FAILED") {
                        handleError(res?.data?.responseInfo?.responseMessage[0]);
                        resolve({
                          data: [],
                          page: 0,
                          totalCount: 5,
                        });
                      } else {
                        resolve({
                          data: [],
                          page: 0,
                          totalCount: 5,
                        });
                        dispatch({
                          type: "FETCH_ERROR",
                          payload: res && res.message ? res.message : null,
                        });
                      }
                    });
                  })
                }
                actions={[
                  {
                    icon: () => (
                      <>
                        <EditIcon className="paymentplan-icons" />
                      </>
                    ),
                    tooltip: "Edit",
                    onClick: (event, rowData) => {
                      setIsLoading(true);
                      setBuId(rowData.buId);
                      dispatch(
                        getHospitalDetails(
                          {
                            buId: rowData.buId,
                          },
                          (res) => {
                            setIsLoading(false);
                            setIsEdit(true);
                            if (res?.status === 200) {
                              setDialogOpen(true);
                              setClientMaintenancePopUpData(res.data);
                            } else {
                              handleError("Something went wrong");
                            }
                          },
                        ),
                      );
                    },
                  },
                ]}
                options={{
                  selection: false,
                  search: false,
                  emptyRowsWhenPaging: false,
                  sorting: false,
                  pageSize: rows,
                  pageSizeOptions: [5, 10, 20],
                }}
                onChangeRowsPerPage={(params) => {
                  setRows(params);
                }}
                components={{
                  Pagination: PatchedPagination,
                }}
              />
            </div>
          </Grid>
          <ConfirmationDialog
            open={dialogOpen}
            handleClose={handleClose}
            title={isEdit ? "Edit Client Maintenance" : "Add Client Maintenance"}
            noActionButtons={true}>
            <ClientMaintenancePopUp
              handleClose={handleClose}
              isEdit={isEdit}
              title={title}
              HospitalData={hospitalDetailsData}
              hospotalList={hospitalList}
              setHostipalList={setHostipalList}
              searchData={searchData}
              buId={buId}
              setIsUpdate={setIsUpdate}
              clientMaintenancePopUpData={clientMaintenancePopUpData}
            />
          </ConfirmationDialog>

          {isLoading && <SpinnerPage />}
        </div>
      </div>
    </div>
  );
};

export default ClientMaintenance;
